<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <div class="container-fluid px-4 py-4 min-vh-85">
    <div class="row d-flex justify-content-center mt-2 mb-2">
      <div class="col-md-12">
        <div class="media">
          <div class="media-body pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 営業所保守編集</strong></h5>
              <router-link to="/office-list" class="btn btn-cancel m-0"><span class="text-white oi oi-circle-x"></span> キャンセル</router-link>
            </div>
          </div>
        </div>
        <div class="main-card mb-3 card">
          <div class="card-header">
            <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
              <ul v-for="(message,index) in successMessages" :key="index" style="list-style: none;">
                <li>{{message}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="warningMessages.length">
              <ul v-for="(message,index) in warningMessages" :key="index" style="list-style: none;">
                <li>{{message}}</li>
              </ul>
            </b-alert>
          </div>
          <div class="card-body">
            <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
            <validation-observer ref="observer">
              <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
                <div class="row mt-2">
                  <!-- 営業所コード -->
                  <div class="col col-6 form-group">
                    <div class=""><label for="text-input" class="form-control-label"><strong>営業所コード</strong></label></div>
                    <div class="">
                      <input type="text" class="form-control" v-model="office.id" readonly>
                    </div>
                  </div>
                </div>
                  <div class="row mt-2">
                    <!-- 営業所名（漢字） -->
                    <div class="col col-6 form-group">
                      <validation-provider name="name_kanji" rules="required|max:40" v-slot="{ classes,errors }">
                        <div class=""><label for="text-input" class="form-control-label"><strong>営業所名（漢字）</strong></label></div>
                        <div :class="classes">
                          <input type="text" class="form-control" v-model="office.name_kanji" maxLength="40">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 営業所名（カナ） -->
                    <div class="col col-6 form-group">
                      <validation-provider name="name_kana" rules="required|max:40" v-slot="{ classes,errors }">
                        <div class=""><label for="text-input" class="form-control-label"><strong>営業所名（カナ）</strong></label></div>
                        <div :class="classes">
                          <input type="text" class="form-control" v-model="office.name_kana" maxLength="40">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 郵便番号 -->
                    <div class="col col-6 form-group">
                      <validation-provider name="zip_code" rules="regex:^([0-9]{3}-[0-9]{4})$" v-slot="{ classes,errors }">
                        <div class=""><label for="text-input" class="form-control-label"><strong>郵便番号</strong></label></div>
                        <div :class="classes">
                          <input type="text" class="form-control" v-model="office.zip_code" maxLength="8">
                          <small class="form-text text-muted">「nnn-nnnn」形式</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 住所１ -->
                    <div class="col col-6 form-group">
                      <validation-provider name="address1" rules="max:80" v-slot="{ classes,errors }">
                        <div class=""><label for="text-input" class="form-control-label"><strong>住所１</strong></label></div>
                        <div :class="classes">
                          <input type="text" class="form-control" v-model="office.address_1" maxLength="80">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- 住所２ -->
                    <div class="col col-6 form-group">
                      <validation-provider name="address2" rules="max:80" v-slot="{ classes,errors }">
                        <div class=""><label for="text-input" class="form-control-label"><strong>住所２</strong></label></div>
                        <div :class="classes">
                          <input type="text" class="form-control" v-model="office.address_2" maxLength="80">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- 電話番号 -->
                    <div class="col col-6 form-group">
                      <validation-provider name="phone_number" rules="max:21|phone" v-slot="{ classes,errors }">
                        <div class=""><label for="text-input" class="form-control-label"><strong>電話番号</strong></label></div>
                        <div :class="classes">
                          <input type="text" class="form-control" v-model="office.phone_number"  maxLength="21">
                          <small class="form-text text-muted">ハイフン区切り</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <!-- FAX番号 -->
                    <div class="col col-6 form-group">
                      <validation-provider name="fax_number" rules="max:21|phone" v-slot="{ classes,errors }">
                        <div class=""><label for="text-input" class="form-control-label"><strong>FAX番号</strong></label></div>
                        <div :class="classes">
                          <input type="text" class="form-control" v-model="office.fax_number"  maxLength="21">
                          <small class="form-text text-muted">ハイフン区切り</small>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- E-MAIL -->
                    <div class="col col-6 form-group">
                      <validation-provider name="email" rules="email" v-slot="{ classes,errors }">
                        <div class=""><label for="text-input" class="form-control-label"><strong>Eメールアドレス</strong></label></div>
                        <div :class="classes">
                          <input type="text" class="form-control" v-model="office.e_mail" maxLength="256">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
              </form>
            </validation-observer>
          </div>
          <!-- 保存ボタン -->
          <div class="card-footer">
            <div class="row justify-content-md-center pb-4">
              <div class="col-lg-2">
                <button type="button" class="btn btn-primary btn-block" @click="saveOffice"><span class="oi oi-circle-check"></span> 保存</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import CONFIRM from '@/components/modal/confirm.vue';
import { init, addOperationLogs, isSystemEditable, escapeQuote, selectOneTable } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { executeTransactSql } from '@/graphql/mutations';

// モジュール名
const MODULE_NAME = 'office-edit';

export default {
  name: 'OFFICE-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  props:['officeId'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '営業所保守編集',
      // 営業所
      office:{
        id: +this.$route.query.officeId,
        name_kanji: '',
        name_kana: '',
        zip_code: '',
        address_1: '',
        address_2: '',
        phone_number: '',
        fax_number: '',
        e_mail: ''
      },
      // テーブル定義
      productList:[],
      fields:DataTblDef.office_input_fields,
      // メッセージ
      successMessages:[],
      warningMessages:[],
      // 確認ダイアログ用
      confirmMessage: [],
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods:{
    async fetchData(){
      try {
        this.busy = true;
        // 検索処理(初期表示は全件取得)
        //console.log('this.officeId:'+this.office.id);
        let where_clause = 'AND office_id = ' + this.office.id + ' ';
        let resultData = await selectOneTable('m_offices', where_clause);
        //console.log('初期表示テーブルデータ');
        //console.log(resultData);
        this.office.id = resultData[0].office_id;
        this.office.name_kanji = resultData[0].office_name_kanji;
        this.office.name_kana = resultData[0].office_name_kana;
        this.office.zip_code = resultData[0].zip_code;
        this.office.address_1 = resultData[0].address_1;
        this.office.address_2 = resultData[0].address_2;
        this.office.phone_number = resultData[0].phone_number;
        this.office.fax_number = resultData[0].fax_number;
        this.office.e_mail = resultData[0].e_mail;
        this.busy = false;
      } catch (error) {
        console.log(error);
      }
    },
    /* 保存ボタンの押下 */
    async saveOffice(){
      // 保存処理
      console.log('保存処理');
      this.successMessages = [];
      this.warningMessages = [];
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();
      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if(success){
        this.$store.commit('setLoading', true);
        console.log('更新処理開始');
        await this.saveConfirm();
        console.log('登録処理終了');
        this.$store.commit('setLoading', false);
      }else{
        scrollTo(0,0);
        this.$store.commit('setLoading', false);
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.warningMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      console.log('保存処理開始');
      await this.execUpdate();
      scrollTo(0,0);
      console.log('保存処理終了');
    },
    /* 更新処理 */
    async execUpdate(){
      try {
        // 営業所データの更新
        await this.updateOffice();
      } catch (error) {
        console.log(error);
        // 異常系操作ログの登録
        let param = {};
        addOperationLogs( 'Error', 'office-input', 'execInsert', param, error );
      }
    },
    /* 営業所データ更新処理 */
    async updateOffice(){
      const functionName = 'updateOffice';

      let date = new Date();
      let now = date.getFullYear() + '-' + ('00' + (date.getMonth()+1)).slice(-2) + '-' + ('00' + date.getDate()).slice(-2);
      now += ' ' + ('00' + (date.getHours())).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2);

      let sqlList = [
        'UPDATE m_offices SET '
        + 'office_name_kanji = \'' + await escapeQuote(this.office.name_kanji) +'\','
        + 'office_name_kana = \'' + await escapeQuote(this.office.name_kana) +'\','
        + 'zip_code = \'' + await escapeQuote(this.office.zip_code) +'\','
        + 'address_1 = \'' + await escapeQuote(this.office.address_1) +'\','
        + 'address_2 = \'' + await escapeQuote(this.office.address_2) +'\','
        + 'phone_number = \'' + await escapeQuote(this.office.phone_number) +'\','
        + 'fax_number = \'' + await escapeQuote(this.office.fax_number) +'\','
        + 'e_mail = \'' + await escapeQuote(this.office.e_mail) +'\','
        + 'updated = \'' + now +'\','
        + 'updated_user = \'user\' '
        + 'WHERE office_id = ' + this.office.id
      ];
      console.info(sqlList[0]);
      // 引数のプロパティは「SQLs」
      let condition = { SQLs: sqlList };
      console.info({condition});

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.warningMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.warningMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      try {
        // executeTransactSqlで実行
        const result = await API.graphql(graphqlOperation(executeTransactSql, condition));
        if (result.errors) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqlList
          });
          this.warningMessages.push(DISP_MESSAGES.DANGER['3003']);
          return;
        }
        // result.data.executeTransactSql.bodyにJSON形式で結果が返ってくる
        const responseBody = JSON.parse(result.data.executeTransactSql.body);

        // SQL実行でエラーが発生した場合の処理
        if(result.data.executeTransactSql.statusCode > 200) {
          this.warningMessages.push(DISP_MESSAGES.DANGER['3003']);
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqlList,
            result: result
          });
        } else {
          // SQLが正常に終了した際の処理
          this.successMessages.push(DISP_MESSAGES.SUCCESS['1003']);
          if (responseBody.data) {
            // SELECT文の結果はresponseBody.dataとして返却される
            // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
            for (const rows of responseBody.data) {
              console.dir(rows, {depth: null});
            }
          }
        }
        console.info(JSON.parse(result.data.executeTransactSql.body));
      } catch (error) {
        this.warningMessages.push(DISP_MESSAGES.DANGER['3003']);
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqlList
        }, error);
      }
    },
  }
}
</script>
<style scoped>
  table {
    display: table !important;
    width: 100% !important;
    border-collapse: collapse;
    table-layout: fixed;
    white-space: nowrap;
  }
  .datatable{
    table-layout:auto;
  }
</style>