<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container fluid class="px-4 py-4 min-vh-85">
    <b-row class="d-flex justify-content-center mb-2">
      <b-col>
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
                <strong> 見積入力</strong>
                <b-form-text class="text-muted d-inline-flex">(編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください)</b-form-text>
              </h5>
              <b-button pill v-b-tooltip.hover.noninteractive.bottom="'見積・発注一覧に戻る'" @click="clickBack" class="btn-cancel m-0">
                <span class="oi oi-circle-x"></span> キャンセル
              </b-button>
            </div>
          </b-media-body>
        </b-media>
        <div class="main-card mb-3 card">
          <b-card-header v-if="getMessageFlg==true">
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <div class="card-body">
            <validation-observer ref="observer">
              <b-form @submit.prevent="clearAlert(); saveData();" id="InputForm" class="form-horizontal">
                <b-row>
                  <!-- 営業所コードプルダウン -->
                  <div class="col-sm-12 col-md-4 col-lg-3 col-xl-2 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="selectSalesOffice">
                          <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='変更される場合、関連項目の製品が自動でクリアされます'>
                            <strong class='text-body'>営業所 <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-select
                        id="selectSalesOffice"
                        v-model="selectSalesOffice"
                        :options="salesOffice"
                        value-field="id"
                        @change="initProductTable();setFormChanged();"
                      />
                    </b-input-group>
                  </div>
                  <!-- 取引先コード -->
                  <div class="col-sm-12 col-md-8 col-lg-6 col-xl-5 form-group px-1">
                    <validation-provider name="suppliersCode" rules="numeric|min:6|max:6" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="suppliersCode">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='情報ボタンは入力されたコードに紐づく詳細情報を表示します。変更される場合、受渡場所や製品売価等の関連項目が自動でクリアされます'>
                              <strong class='text-body'>取引先コード <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input 
                          type="text" 
                          id="suppliersCode" 
                          :class="{'border-danger': errors[0]}"
                          @input="searchClient(changeEstimateBrankId(suppliers.code, false))" 
                          maxlength="6"
                          v-model="suppliers.code"
                        />
                        <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                            <span class="oi oi-magnifying-glass"></span>
                            <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                          </b-button>
                        </b-input-group-text>
                        <b-button class="mr-1" size="sm" pill variant="success" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが空白以外の場合、利用可能です。）" :disabled="suppliers.code==''" @click="clientInfoModal(suppliers.class, suppliers.code)">
                          <span class="oi oi-eye"></span> 情報
                        </b-button>
                        <b-button size="sm" pill variant="success" title="取引先をクリアします。受渡場所や製品売価等の関連項目も同時にクリアされるため、注意してください。" @click="clientClear()">
                          <span class="oi oi-circle-x"></span> クリア
                        </b-button>
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                      </b-input-group>
                    </validation-provider>
                  </div>
                </b-row>
                <b-row>
                  <!-- 取引先名 -->
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-7 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="suppliersName">
                          <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='見積登録時点の取引先名です。帳票に出力する取引先は「取引先印刷用宛名」において設定してください'>
                            <strong class='text-body'>取引先名 <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input id="suppliersName" type="text" v-model="suppliers.name" readonly />
                    </b-input-group>
                  </div>
                  <!-- 取引先担当者コード -->
                  <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="clientStaffId">
                          <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='取引先設定時に自動設定されます。帳票の「担当」の部分に出力されます'>
                            <strong class='text-body'>取引先担当者 <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input id="clientStaffId" type="text" v-model="clientStaffId" readonly />
                      <b-input-group-text @click="showStaffSearchModal(1)" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                          <span class="oi oi-magnifying-glass"></span>
                          <button type="button" v-shortkey="['alt', '2']" @shortkey="showStaffSearchModal(1)" class="d-none"></button>
                        </b-button>
                      </b-input-group-text>
                      <b-button pill size="sm" variant="success" title="取引先担当者をクリアします。" @click="clientStaffClear()">
                        <span class="oi oi-circle-x"></span> クリア
                      </b-button>
                    </b-input-group>
                  </div>
                </b-row>
                <b-row>
                  <!-- 取引先印刷用宛名 -->
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-7 form-group px-1">
                    <validation-provider name="clientPrintName" rules="max:34" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="clientPrintName">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='帳票の「宛先」の部分に出力されます。取引先名の代わりにこちらを変更してください'>
                              <strong class='text-body'>取引先印刷用宛名 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="clientPrintName" name="clientPrintName" :class="{'border-danger': errors[0]}" type="text" v-model="suppliers.printName" @input="setFormChanged();" />
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                      </b-input-group>
                    </validation-provider>
                  </div>
                  <!-- 作成担当者コード -->
                  <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="createdStaffId">
                          <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='取引先担当者と異なる場合、帳票の「担当」の右側に括弧括りで出力されます'>
                            <strong class='text-body'>作成担当者 <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input id="createdStaffId" type="text" v-model="createdStaffId" readonly />
                      <b-input-group-text @click="showStaffSearchModal(2)" v-b-tooltip.hover.noninteractive title="「ALT+3」ボタンで呼出可能">
                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                          <span class="oi oi-magnifying-glass"></span>
                          <button type="button" v-shortkey="['alt', '3']" @shortkey="showStaffSearchModal(2)" class="d-none"></button>
                        </b-button>
                      </b-input-group-text>
                      <b-button pill size="sm" variant="success" title="作成担当者をクリアします。" @click="createdStaffClear()">
                        <span class="oi oi-circle-x"></span> クリア
                      </b-button>
                    </b-input-group>
                  </div>
                </b-row>
                <b-row>
                  <!-- 見積種類 -->
                  <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 form-group pl-2 pr-0">
                    <b-input-group>
                      <template #prepend>
                        <label>
                          <b-input-group-text  class="px-0" v-b-tooltip.hover.noninteractive  title='帳票の「単価御見積」の部分に出力される内容です（1：売上合計＋消費税、2：文言「単価御見積」'>
                            <strong class='text-body'>見積種類 <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-col sm="8" md="8" lg="7" xl="8">
                        <b-form-radio-group
                          id="checkEstimateKind"
                          v-model="checkEstimateKind"
                          :options="estimateKindList"
                          value-field="id"
                          @change="setFormChanged();"
                        ></b-form-radio-group>
                        <validation-provider name="checkEstimateKind" rules="required" v-slot="{ classes,errors }">
                          <input name="checkEstimateKind" type="hidden" v-model="checkEstimateKind" />
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </validation-provider>
                      </b-col>
                    </b-input-group>
                  </div>
                  <!-- 消費税区分 -->
                  <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 form-group pl-2 pr-0">
                    <b-input-group>
                      <template #prepend>
                        <label>
                          <b-input-group-text  class="px-0" v-b-tooltip.hover.noninteractive  title='消費税を金額とは別に設定するかを設定します（1：売上合計に応じた消費税を算出、2：消費税は0）'>
                            <strong class='text-body'>消費税区分 <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-col sm="8" md="8" lg="8" xl="8">
                        <b-form-radio-group
                          id="checkTaxType"
                          v-model="checkTaxType"
                          :options="taxTypeList"
                          value-field="id"
                          @change="calcTotal();setFormChanged();"
                        ></b-form-radio-group>
                        <validation-provider name="checkTaxType" rules="required" v-slot="{ classes,errors }">
                          <input name="checkTaxType" type="hidden" v-model="checkTaxType" />
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </validation-provider>
                      </b-col>
                    </b-input-group>
                  </div>
                  <!-- 単価登録区分 -->
                  <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 form-group pl-2 pr-0">
                    <b-input-group>
                      <template #prepend>
                        <label>
                          <b-input-group-text  class="px-0" v-b-tooltip.hover.noninteractive  title='見積作成時に選択された取引先に単価登録を行うかの設定です（3：親取引先で設定した親子取引先にも単価登録を行う）'>
                            <strong class='text-body'>単価登録区分 <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-col sm="8" md="8" lg="7" xl="9">
                        <b-form-radio-group
                          id="checkUnitPriceRegisterClass"
                          v-model="checkUnitPriceRegisterClass"
                          :options="unitPriceRegisterClassList"
                          value-field="id"
                          @change="setFormChanged();"
                        ></b-form-radio-group>
                        <validation-provider name="checkUnitPriceRegisterClass" rules="required" v-slot="{ classes,errors }">
                          <input name="checkUnitPriceRegisterClass" type="hidden" v-model="checkUnitPriceRegisterClass" />
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </validation-provider>
                      </b-col>
                    </b-input-group>
                  </div>
                </b-row>
                <b-row>
                  <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 form-group pl-2 pr-0">
                    <b-input-group>
                      <template #prepend>
                        <label for="isUnitPrice">
                          <b-input-group-text  class="px-0" v-b-tooltip.hover.noninteractive  title='チェックONの場合、取引先や見積日付の変更時に単価登録情報で売価を設定し直します。'>
                            <strong class='text-body'>単価適用チェック <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-col sm="8" md="8" lg="7" xl="9">
                        <b-form-checkbox
                          id="isUnitPrice"
                          name="isUnitPrice"
                          v-model="isUnitPrice"
                        >
                        </b-form-checkbox>
                      </b-col>
                    </b-input-group>
                  </div>
                  <!-- 登録区分 -->
                  <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 form-group pl-2 pr-0" v-if="(checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertMine || checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertAll)">
                    <b-input-group>
                      <template #prepend>
                        <label>
                          <b-input-group-text  class="px-0" v-b-tooltip.hover.noninteractive  title='製品単価情報の登録に同製品グループも含めるかどうかを設定します（0：同製品グループも含め登録、1：自身のみ登録）'>
                            <strong class='text-body'>登録区分 <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-col sm="8" md="8" lg="7" xl="9">
                        <b-form-radio-group
                          id="checkRegisterClass"
                          v-model="checkRegisterClass"
                          :options="registerClassList"
                          value-field="id"
                          @change="setFormChanged();"
                        ></b-form-radio-group>
                        <validation-provider name="checkRegisterClass" rules="required" v-slot="{ classes,errors }">
                          <input name="checkRegisterClass" type="hidden" v-model="checkRegisterClass" />
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </validation-provider>
                      </b-col>
                    </b-input-group>
                  </div>
                </b-row>
                <b-row>
                  <div class="col-sm-12 col-md-5 col-lg-5 col-xl-4 form-group px-1">
                    <!-- 見積日付 -->
                    <validation-provider name="dateEstimateDate" :rules="{required: true}" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="dateEstimateDate">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='変更される場合、製品の売価が自動で取り直されます'>
                              <strong class='text-body'>見積日付 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-datepicker
                          id="dateEstimateDate"
                          name="dateEstimateDate"
                          :class="{'border-danger': errors[0]}"
                          v-model="dateEstimateDate"
                          calendar-width="50%"
                          @input="refreshSalesAmount();setFormChanged();"
                          class="mb-2"
                        ></b-form-datepicker>
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                      </b-input-group>
                    </validation-provider>
                    <!-- 適用日 -->
                    <validation-provider name="dateUnitPriceEffectiveDate" :rules="{required_if: (checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertMine || checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertAll), oldDate: true}" v-slot="{ classes,errors }">
                      <b-input-group class="mb-2">
                        <template #prepend>
                          <label for="dateUnitPriceEffectiveDate">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='登録された単価の適用開始日です。単価登録を行う場合は必ず設定してください'>
                              <strong class='text-body'>適用日 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-datepicker
                          id="dateUnitPriceEffectiveDate"
                          name="dateUnitPriceEffectiveDate"
                          :class="{'border-danger': errors[0]}"
                          v-model="dateUnitPriceEffectiveDate"
                          calendar-width="50%"
                          @input="setFormChanged();"
                        ></b-form-datepicker>
                        <b-input-group-append>
                          <b-button size="sm" variant="outline-secondary" @click="setFormChanged();dateUnitPriceEffectiveDate=''">
                            <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                          </b-button>
                        </b-input-group-append>
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                      </b-input-group>
                    </validation-provider>
                    <!-- 納期 -->
                    <b-input-group>
                      <template #prepend>
                        <label for="dateDeliveryDate">
                          <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='帳票の「受渡期日」の部分に出力されます'>
                            <strong class='text-body'>納期 <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-datepicker
                        id="dateDeliveryDate"
                        name="dateDeliveryDate"
                        v-model="dateDeliveryDate"
                        calendar-width="50%"
                        @input="setFormChanged();"
                      ></b-form-datepicker>
                      <b-input-group-append>
                        <b-button size="sm" variant="outline-secondary" @click="setFormChanged();dateDeliveryDate=''">
                          <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <div class="col-sm-12 col-md-7 col-lg-7 col-xl-7 form-group px-1">
                    <!-- 備考 -->
                    <validation-provider name="textUnitPriceNote" rules="max:20" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="textUnitPriceNote">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='単価登録に関する備考です。帳票には出力されません'>
                              <strong class='text-body'>備考 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="textUnitPriceNote" name="textUnitPriceNote" :class="{'border-danger': errors[0]}" type="text" v-model="textUnitPriceNote" @input="setFormChanged();" />
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                      </b-input-group>
                    </validation-provider>
                    <!-- 内容 -->
                    <validation-provider name="textContents" rules="required|max:50" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="textContents">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='作成した見積の内容です。帳票には出力されません'>
                              <strong class='text-body'>内容 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="textContents" name="textContents" style="background-color: yellow" :class="{'border-danger': errors[0]}" type="text" v-model="textContents" @input="setFormChanged();" />
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                      </b-input-group>
                    </validation-provider>
                    <!-- 現場コード -->
                    <b-input-group>
                      <template #prepend>
                        <label for="onSiteCode">
                          <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='検索ボタンから選択してください。情報ボタンは入力されたコードに紐づく詳細情報を表示します'>
                            <strong class='text-body'>現場コード <span class="oi oi-flag"/></strong>
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input 
                        id="onSiteCode"
                        type="text"
                        @change="searchSite(changeEstimateBrankId(onSite.code, false))"
                        v-model="onSite.code"
                        :readonly="suppliers.code==''"
                        maxlength="4"
                      />
                      <b-input-group-text @click="siteSearchModal()" v-if="suppliers.code!=''" v-b-tooltip.hover.noninteractive title="「ALT+4」ボタンで呼出可能">
                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                          <span class="oi oi-magnifying-glass"></span>
                          <button type="button" v-shortkey="['alt', '4']" @shortkey="siteSearchModal()" class="d-none"></button>
                        </b-button>
                      </b-input-group-text>
                      <b-button pill size="sm" variant="success" title="受渡場所をクリアします。" @click="siteClear()">
                        <span class="oi oi-circle-x"></span> クリア
                      </b-button>
                    </b-input-group>
                  </div>
                </b-row>
                <b-row>
                  <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5 form-group px-1">
                    <!-- 受渡場所 -->
                    <validation-provider name="clientSiteName" rules="max:50" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="clientSiteName">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='帳票の「受渡場所」の部分に出力されます'>
                              <strong class='text-body'>受渡場所 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="clientSiteName" name="clientSiteName" :class="{'border-danger': errors[0]}" type="text" v-model="onSite.name" @input="setFormChanged();" />
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                      </b-input-group> 
                    </validation-provider>
                  </div>
                  <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5 form-group px-1">
                    <!-- 支払方法 -->
                    <validation-provider name="textPaymentWay" rules="max:30" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="textPaymentWay">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='帳票の「支払方法」の部分に出力されます'>
                              <strong class='text-body'>支払方法 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input :class="{'border-danger': errors[0]}" id="textPaymentWay" name="textPaymentWay" type="text" v-model="textPaymentWay" @input="setFormChanged();" />
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                      </b-input-group> 
                    </validation-provider>
                  </div>
                </b-row>
                <b-row>
                  <!-- 見積期限／見積期限（文章） -->
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
                    <b-form-group
                      label-for="dateEstimatePeriod"
                      label="見積期限"
                      label-cols-sm="2"
                      label-cols-md="2"
                      label-cols-lg="2"
                      label-cols-xl="2"
                      label-align-sm="left"
                      label-class="font-weight-bold px-0"
                    >
                      <b-row>
                        <b-col lg="7">
                          <b-input-group class="input-daterange">
                            <b-form-datepicker
                              id="dateEstimatePeriod"
                              v-model="dateEstimatePeriod"
                              calendar-width="50%"
                              @input="setFormChanged();"
                            ></b-form-datepicker>
                            <b-input-group-append>
                              <b-button size="sm" variant="outline-secondary" @click="setFormChanged();dateEstimatePeriod=''">
                                <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-col>
                        <b-col lg="5">
                          <validation-provider name="textEstimatePeriodSentence" rules="max:15" v-slot="{ classes,errors }">
                            <b-row>
                              <b-col :class="classes">
                                <b-form-input name="textEstimatePeriodSentence" type="text" v-model="textEstimatePeriodSentence" @input="setFormChanged();" />
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col :class="classes">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-row>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-form-text class="text-muted">帳票の「有効期限」の部分に「日付＋文章」の形式で出力されます</b-form-text>
                    </b-form-group>
                  </div>
                  <!-- 摘要 -->
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-1">
                    <validation-provider name="textSummary" rules="max_row:3,60" v-slot="{ classes,errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="textSummary">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='帳票の「摘要」の部分に出力されます'>
                              <strong class='text-body'>摘要 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-textarea :class="{'border-danger': errors[0]}" id="textSummary" rows="3" v-model="textSummary" @input="setFormChanged();" />
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>
                      </b-input-group> 
                    </validation-provider>
                  </div>
                </b-row>
                <b-row>
                  <!-- 製品 -->
                  <b-col lg="12">
                    <b-row>
                      <b-col lg="12">
                        <b-table
                          :items="productList"
                          :fields="fields"
                          :small="true"
                        >
                          <!--<template #table-colgroup>
                            <col style="width:250px;"/>
                            <col style="width:300px;"/>
                            <col style="width:200px;"/>
                            <col style="width:100px;"/>
                            <col style="width:50px;"/>
                            <col style="width:130px;"/>
                            <col style="width:130px;"/>
                            <col style="width:100px;"/>
                            <col style="width:40px;"/>
                          </template>-->
                          <template #head(Sort)>
                            移<br>動
                          </template>
                          <template #cell(Sort)="data">
                            <div                                
                              style="cursor: pointer; height: 100%; width: 100%"
                              draggable
                              @dragstart="onRowMoveDragstart($event, data.index)"
                              @drop="onRowMoveDrop($event, data.index)"
                              @dragover.prevent
                              @dragenter.prevent
                            >
                              <span class="oi oi-move"></span>
                            </div>
                          </template>
                          <template #cell(ProductCode)="data">
                            <validation-provider rules="numeric|min:8" v-slot="{ classes,errors }">
                              <div :class="classes">
                                <b-input-group style="width: 250px;">
                                  <b-form-input 
                                    type="text" 
                                    name="productId" 
                                    maxlength="8"
                                    v-bind:value="data.item.ProductCode"
                                    v-on:input="if ($event != null && ($event.length==0||$event.length==8)) {data.item.ProductCode=$event;searchProduct(changeEstimateBrankId(data.item.ProductCode, false), data.index);}"
                                    style="width: 110px;"
                                  />
                                  <b-input-group-text @click="showProductSearchModal(data.index)">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                    </b-button>
                                  </b-input-group-text>
                                  <!-- 製品クリアボタン -->
                                  <b-button size="sm" pill variant="success" title="製品をクリアします。" @click="productClear(data.index)">
                                    <span class="oi oi-circle-x"></span> クリア
                                  </b-button>
                                </b-input-group>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </div>
                            </validation-provider>
                          </template>
                          <template #cell(ProductName)="data">
                            <b-input-group style="width: 340px;">
                              <span style="font-size: 20px;" v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark : '' }}</span>
                              <b-form-input maxlength="35" size="sm" class="px-1" type="text" name="productName" v-bind:value="data.item.ProductName" v-on:blur="data.item.ProductName=$event.target.value;" v-b-tooltip.hover.noninteractive.right="data.item.ProductName" @input="setFormChanged();" />
                            </b-input-group>
                          </template>
                          <template #cell(Note)="data">
                            <b-input-group style="width: 260px;">
                              <b-form-input maxlength="30" size="sm" class="px-1" type="text" name="productNote" v-bind:value="data.item.Note" v-on:blur="data.item.Note=$event.target.value;" v-b-tooltip.hover.noninteractive.right="data.item.Note" @input="setFormChanged();" />
                            </b-input-group>
                          </template>
                          <template #cell(Quantity)="data">
                            <validation-provider rules="required|between:0,99999" v-slot="{ classes,errors }">
                              <b-input-group style="width: 70px;">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-form-input size="sm" class="px-1" type="number" :id="'productQuantity' + data.index" name="productQuantity" v-bind:value="data.item.Quantity" v-on:blur="data.item.Quantity=$event.target.value==''?'':Number($event.target.value);calc(data.index);" @input="setFormChanged();" />
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </b-input-group>
                            </validation-provider>
                          </template>
                          <template #cell(Unit)="data">
                            <b-input-group style="width: 50px;">
                              <b-form-input maxlength="5" size="sm" class="px-1" type="text" name="productUnit" v-bind:value="data.item.Unit" v-on:blur="data.item.Unit=$event.target.value;" @input="setFormChanged();" />
                            </b-input-group>
                          </template>
                          <template #cell(SellingPrice)="data">
                            <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                              <b-input-group style="width: 120px;">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-input-group class="d-flex align-items-center">
                                      <b-form-input size="sm" class="px-1" type="number" name="productSellingPrice" v-bind:value="data.item.SellingPrice" v-on:blur="data.item.SellingPrice=$event.target.value==''?'':Number($event.target.value);calc(data.index);" @input="setFormChanged();" />
                                      <b style="font-size: 20px;">{{ data.item.ClientAmountClass }}</b>
                                    </b-input-group>
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </b-input-group>
                            </validation-provider>
                          </template>
                          <template #cell(SalesUnitPrice)="data">
                            {{ data.item.SalesUnitPrice.toLocaleString() }}
                          </template>
                          <template #cell(PurchasePrime)="data">
                            <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                              <b-input-group style="width: 100px;">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-form-input size="sm" class="px-1" type="number" name="productPurchasePrime" v-bind:value="data.item.PurchasePrime" v-on:blur="data.item.PurchasePrime=$event.target.value==''?'':Number($event.target.value);calc(data.index);" @input="setFormChanged();" />
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </b-input-group>
                            </validation-provider>
                          </template>
                          <template #cell(Money)="data">
                            {{ data.item.Money.toLocaleString() }}
                            <validation-provider :rules="{set_val_between:{min:-99999999,max:99999999,title:'金額'}}" v-slot="{ classes,errors }">
                              <b-input-group style="width: 90px;">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <input type="hidden" name="Money" v-bind:value="data.item.Money" />
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </b-input-group>
                            </validation-provider>
                            <validation-provider :rules="{set_val_between:{min:-99999999,max:99999999,title:'仕入金額'}}" v-slot="{ classes,errors }">
                              <b-input-group style="width: 90px;">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <input type="hidden" name="TotalPurchasePrime" v-bind:value="data.item.TotalPurchasePrime" />
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </b-input-group>
                            </validation-provider>
                          </template>
                          <template #cell(delete)="data">
                            <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'製品行を挿入します。'" @click="insertTableRow(data.index);setFormChanged();" class="mr-1">
                              <span class="oi oi-plus"></span> 行挿入
                            </b-button>
                            <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'この行を削除をします。'" @click="deleteBtn(data.index);setFormChanged();">
                              <span class="oi oi-delete"></span> 削除
                            </b-button>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form>
                          <b-form-group :label="textTotalMoneyText" label-align="right"></b-form-group>
                        </b-form>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-btn-toolbar class="mt-2">
                          <!-- 行追加ボタン -->
                          <b-button class="mr-2" pill size="sm" variant="success" title="製品行を追加します。" @click="addTableRow();setFormChanged();">
                            <span class="oi oi-plus"></span> 行追加
                          </b-button>
                          <!-- 行追加ボタン -->
                          <b-button class="mr-2" pill size="sm" variant="success" title="製品行を5行追加します。" @click="addTableRow(5);setFormChanged();">
                            <span class="oi oi-plus"></span> 5行追加
                          </b-button>
                          <!-- 取引先製品単価履歴照会ボタン -->
                          <b-button class="mr-2" pill size="sm" variant="success" title="取引先製品単価履歴照会モーダルを表示します。" @click="clickInquryClientProduct">
                            <span class="oi oi-eye"></span> 取引先製品単価履歴照会
                          </b-button>
                        </b-btn-toolbar>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
          <!-- 保存ボタン -->
          <b-card-footer>
            <b-row class="justify-content-md-center pb-4">
              <b-col lg="2">
                <b-button  class="mr-2" pill block variant="success" title="入力された見積データを保存します。" type="submit" form="InputForm" @click="delListLastBlankRow">
                  <span class="oi oi-circle-check"></span> 保存
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </div>
      </b-col>
    </b-row>
  </b-container>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●取引先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="htmlConst.ClientClass.customer"/>
  <!-- ●●●取引先照会モーダル●●● -->
  <CLIENTINQUIRY :clientProp="propClientInquiry"/>
  <!-- ●●●担当者検索モーダル●●● -->
  <STAFFSEARCH @from-child="closeStaffSearchModal"/>
  <!-- ●●●製品検索モーダル●●● -->
  <PRODUCTSEARCH @from-child="closeProductSearchModal" :productSearchProp="productSearchProp"/>
  <!-- ●●●現場検索モーダル●●● -->
  <SITESEARCH @from-child="closeSiteSearchModal" :clientInfo="siteSearchProp" />
  <!-- ●●●取引先製品単価履歴照会モーダル●●● -->
  <CLIENTPRODUCTINQUIRY :clientProductProp="clientProductProp" />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { searchProductsModal, searchSiteModal, searchClientModal } from '@/assets/js/searchModal.js';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import STAFFSEARCH from '@/components/modal/staff-search.vue';
import SITESEARCH from '@/components/modal/site-search.vue';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import CLIENTPRODUCTINQUIRY from '@/components/modal/client-product-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, formatCurDate, getControlMaster, addOperationLogs, calcTaxNew, executeSelectSql, escapeQuote, CreateInsertSql, CreateColRow, isSystemEditable, getNullStr, refreshSalesAmountProductList, refreshClientAmountClassProductList, getInsertUnitPriceSqlList, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';

const MODULE_NAME = 'estimate-input';

export default {
  name: 'ESTIMATE-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    CLIENTINQUIRY,
    STAFFSEARCH,
    SITESEARCH,
    PRODUCTSEARCH,
    CLIENTPRODUCTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '見積入力',
      // アラート
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOffice: null,
      salesOffice: [],
      // 取引先製品単価適用の有無
      isUnitPrice: false,
      // 見積日付
      dateEstimateDate: '',
      // 取引先系
      suppliers:{
        class: 0,
        code: '',
        name: '',
        printName: '',
      },
      // 取引先担当者
      clientStaff:{
        id: 0,
        name: ' ',
        nameStamp: '',
      },
      // 作成担当者
      createdStaff:{
        id: 0,
        name: ' ',
        nameStamp: '',
      },
      // 見積種類ラジオボタン
      checkEstimateKind: Const.EstimateKind.normal,
      estimateKindList: Const.EstimateKindList,
      // 消費税区分ラジオボタン
      checkTaxType: Const.TaxType.noTax,
      taxTypeList: Const.TaxTypeList,
      // 単価登録区分ラジオボタン
      checkUnitPriceRegisterClass: Const.UnitPriceRegisterClass.insertNo,
      unitPriceRegisterClassList: Const.UnitPriceRegisterClassList,
      // 登録区分ラジオボタン
      checkRegisterClass: null,
      registerClassList: Const.ClientsProductsMasterRegisterClassList,
      // 単価適用日
      dateUnitPriceEffectiveDate: '',
      // 単価備考
      textUnitPriceNote: '',
      // 内容
      textContents: '',
      // 納期
      dateDeliveryDate: '',
      // 現場系
      onSite:{
        deliveryCode: '',
        code: '',
        name: '',
      },
      // 支払方法
      textPaymentWay: '',
      // 見積期限
      dateEstimatePeriod: '',
      // 見積期限（文章）
      textEstimatePeriodSentence: '',
      // 摘要
      textSummary: '',
      // テーブル定義
      productList:[],
      fields:[
        {
          key: 'Sort',
          tdClass: 'text-center align-middle',
        },
        {
          key: 'ProductCode',
          label: '製品コード',
        },
        {
          key: 'ProductName',
          label: '製品名',
        },
        {
          key: 'Note',
          label: '備考',
        },
        {
          key: 'Quantity',
          label: '数量',
        },
        {
          key: 'Unit',
          label: '単位',
        },
        {
          key: 'SalesUnitPrice',
          label: 'BM価格',
          tdClass: 'text-right',
        },
        {
          key: 'SellingPrice',
          label: '売価',
        },
        {
          key: 'PurchasePrime',
          label: '仕入単価',
        },
        {
          key: 'Money',
          label: '金額',
          tdClass: 'text-right',
        },
        {
          key: 'delete',
          label: ' ',
        },
      ],
      dataIndex: 0,
      totalFee: 0,
      totalPurchasePrime: 0,
      grossProfit: 0,
      tax: 0,
      // 現在日付(yyyy-mm-dd)
      today: '',
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        lightTaxMark: '',
      },
      // 見積番号（AUTO_INCREMENTで自動採番するため、最初は0を設定。）
      estimateId: 0,
      // ログイン情報
      loginId: '',
      // トランザクションSQLリスト
      transactSqlList: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
        // 単価登録区分
        UnitPriceRegisterClass: {
          // 登録しない
          insertNo: Const.UnitPriceRegisterClass.insertNo,
          // 自身のみ登録
          insertMine: Const.UnitPriceRegisterClass.insertMine,
          // 親子全て登録
          insertAll: Const.UnitPriceRegisterClass.insertAll,
        },
        // 諸口区分
        SundriesClass: {
          normal: Const.SundriesClass.normal,
          shokuchi: Const.SundriesClass.shokuchi,
        },
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 取引先別製品照会に渡すパラメータ
      clientProductProp: {
        clientClass: null,
        clientId: null,
        clientNameKanji: '',
        productIdCsv: '',
      },
      // 確認ダイアログ用
      confirmMessage: [],
      // 取引先製品一括更新CSVから遷移した場合の初期内容
      defaultClientsProductsCsvTextContents: '取引先製品単価一括更新から登録',
      // パラメータ
      propEstimateId: 0,
      propReceivedOrderId: 0,
      propWorkId: '',
      propClientId: '',
      propClientIndex: '',
      propDate: '',
      propClientProductMax: '',
      // 変更チェック
      // ※beforeunloadで「閉じる/再読込」抑止
      // ※beforeRouteLeaveで「戻る/進む/ページ遷移」抑止
      isFormChanged: false,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // 画面を閉じるときや再読み込みの時に確認ダイアログを出すイベントを追加
    window.addEventListener('beforeunload', this.confirmChangedUnload);
    // パラメータ取得
    this.propEstimateId = this.$route.query.propEstimateId;
    this.propReceivedOrderId = this.$route.query.propReceivedOrderId;
    this.propWorkId = this.$route.query.propWorkId;
    this.propClientId = this.$route.query.propClientId;
    this.propClientIndex = this.$route.query.propClientIndex;
    this.propDate = this.$route.query.propDate;
    this.propClientProductMax = this.$route.query.propClientProductMax;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 取引先担当者コード */
    clientStaffId: function() {
      return `${this.clientStaff.id}:${this.clientStaff.name}`
    },
    /* 作成担当者コード */
    createdStaffId: function() {
      return `${this.createdStaff.id}:${this.createdStaff.name}`
    },
    /* 製品モーダルのパラメータ用 */
    productSearchProp: function() {
      return {
        office_id: this.selectSalesOfficeData == null ? null : this.selectSalesOfficeData.id,
        office_name: this.selectSalesOfficeData == null ? null : this.selectSalesOfficeData.name,
        client_class: this.suppliers.class,
        client_id: this.changeEstimateBrankId(this.suppliers.code, false),
        client_name: this.suppliers.name,
        multiSelectFlg: '1',
        kijunDate: this.dateEstimateDate,
      };
    },
    /* 現場モーダルのパラメータ用 */
    siteSearchProp: function() {
      return {
        client_id: this.changeEstimateBrankId(this.suppliers.code, false),
        client_name: this.suppliers.name,
      };
    },
    /* 選択中の営業所情報取得用 */
    selectSalesOfficeData: function() {
      for (let i = 0; i < this.salesOffice.length; i++) {
        if (this.salesOffice[i].id == this.selectSalesOffice) {
          return this.salesOffice[i];
        }
      }
      return null;
    },
    /* 合計金額テキスト */
    textTotalMoneyText: function() {
      let retTotalMoneyText = '消費税額：' + this.tax.toLocaleString() + ' 円';
      retTotalMoneyText += '　';
      retTotalMoneyText += '粗利：' + (!Number.isFinite(this.grossProfit) ? 0 : this.grossProfit) + ' %';
      retTotalMoneyText += '　';
      retTotalMoneyText += '合計仕入単価：' + this.totalPurchasePrime.toLocaleString() + ' 円';
      retTotalMoneyText += '　';
      retTotalMoneyText += '合計金額：' + this.totalFee.toLocaleString() + ' 円';
      return retTotalMoneyText;
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let controlData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData, controlData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
          getControlMaster(),
        ]);
        //console.log(staffListData);
        // 現在日付を「yyyy-mm-dd」の形式で作成
        this.today = formatCurDate('YYYY-MM-DD');
        // 製品テーブル初期表示データ用意
        this.addTableRow();
        // 営業所データ取得
        //console.log(officeListData);
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.salesOffice.push(office);
        }
        // 営業所データ初期値セット
        for(let i = 0; i < this.salesOffice.length; i++){
          //console.log(this.salesOffice[i].id);
          if(this.salesOffice[i].id == staffListData[0].office_id){
            this.selectSalesOffice = this.salesOffice[i].id;
            break;
          }
        }
        // 作成担当者の初期値セット
        this.createdStaff = { id: staffListData[0].staff_id, name: staffListData[0].staff_name_kanji, nameStamp: staffListData[0].staff_name_stamp };
        // 見積日付に現在日を設定
        this.dateEstimateDate = this.today;
        // コントロールマスタ
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.taxRate = controlData.tax_rate;
        this.controlMasterData.newTaxRate = controlData.new_tax_rate;
        this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
        this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
        this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 既存データのコピー登録の場合（パラメータを渡された場合）
        if (this.propEstimateId != null && this.propEstimateId != '0') {
          //console.log('見積コピー');
          await this.setEstimate();
        } else if(this.propReceivedOrderId != null && this.propReceivedOrderId != '0') {
          //console.log('受注コピー');
          await this.setReceivedOrder();
        } else if (getNullStr(this.propWorkId) != '' && getNullStr(this.propClientId) != '' && getNullStr(this.propClientIndex) != '' && getNullStr(this.propDate) != '' ) {
          await this.setClientProduct();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 見積データ設定 */
    async setEstimate() {
      const functionName = 'setEstimate';
      // 検索条件作成
      let where_clause = '';
      where_clause = await this.conditionMakeEstimate();
      //console.log(where_clause);
      let dataEstimate = null;
      try {
        dataEstimate = await selectOneTable('t_estimate', where_clause);
      } catch (error) {
        let param = {
          graphqlOperation: 'list_t_estimate',
          where_clause: where_clause
        };
        await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      //console.log(dataEstimate);
      if(dataEstimate != null && dataEstimate.length > 0){
        await this.setResultEstimate(dataEstimate);
      }else{
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 受注データ設定 */
    async setReceivedOrder() {
      // 検索条件作成
      let selectSql = '';
      selectSql = await this.makeSelectSqlReceivedOrder();
      //console.log(selectSql);
      let dataOrdersReceives = await executeSelectSql(selectSql);
      //console.log(dataOrdersReceives);
      if(dataOrdersReceives != null){
        await this.setResultReceivedOrder(dataOrdersReceives);
      }else{
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
        scrollTo(0,0);
      }
    },
    /* 検索条件文字列作成（見積コピー） */
    async conditionMakeEstimate() {
      let where_clause = '';
      // 引数の見積番号で絞り込む
      where_clause += 'AND estimate_id = ' + this.propEstimateId + ' ';
      // 製品一覧の行番号でソート
      where_clause += 'ORDER BY estimate_row';

      return where_clause;
    },
    /* 検索SQL作成（受注コピー） */
    async makeSelectSqlReceivedOrder() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.office_id';
      selectSql += ',orders_receives.client_class';
      selectSql += ',orders_receives.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',staffs.staff_name_stamp';
      selectSql += ',orders_receives.site_id';
      selectSql += ',clients_sites.client_site_name_kanji';
      selectSql += ',orders_receives.product_id';
      selectSql += ',products.product_name_kanji';
      selectSql += ',orders_receives.order_receive_quantity';
      selectSql += ',orders_receives.unit';
      selectSql += ',orders_receives.order_receive_unit_amount';
      selectSql += ',orders_receives.order_unit_amount';
      selectSql += ',products.sales_unit_price';
      selectSql += ',products.sundries_class AS product_sundries_class';
      selectSql += ',products.product_tax_rate_class_sales';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN m_clients AS clients USING(client_class,client_id)';
      selectSql += 'INNER JOIN m_clients_sites AS clients_sites USING(client_id,site_id)';
      selectSql += 'INNER JOIN m_products AS products USING(product_id)';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON clients.staff_id = staffs.staff_id';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 引数の受注番号で絞り込む
      selectSql += ' order_receive_id = ' + this.propReceivedOrderId + ' ';
      // 製品一覧の行番号でソート
      selectSql += 'ORDER BY order_receive_row';

      return selectSql;
    },
    /* 取得結果セット（見積コピー） */
    async setResultEstimate(result) {
      if (result.length > 0){
        // 営業所プルダウン
        this.selectSalesOffice = result[0].office_id;
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = this.changeEstimateBrankId(result[0].client_id, true);
        this.suppliers.name = result[0].client_name_kanji;
        this.suppliers.printName = result[0].client_print_name;
        // 取引先担当者
        this.clientStaff = {id:result[0].client_staff_id,name:result[0].client_staff_name_kanji,nameStamp:result[0].client_staff_name_stamp};
        // 見積種類ラジオボタン
        this.checkEstimateKind = result[0].estimate_kind;
        // 消費税区分ラジオボタン
        this.checkTaxType = result[0].tax_type;
        // 内容
        this.textContents = result[0].contents;
        // 現場系
        this.onSite.deliveryCode = this.changeEstimateBrankId(result[0].client_id, true);
        this.onSite.code = this.changeEstimateBrankId(result[0].site_id, true);
        this.onSite.name = result[0].client_site_name_kanji;
        // 支払方法
        this.textPaymentWay = result[0].payment_way;
        // 見積期限
        this.dateEstimatePeriod = result[0].estimate_period;
        if(this.dateEstimatePeriod == null){
          this.dateEstimatePeriod = '';
        }
        // 見積期限（文章）
        this.textEstimatePeriodSentence = result[0].estimate_period_sentence;
        // 摘要
        this.textSummary = result[0].summary;
        // 全件参照して製品一覧を作成
        let productIdCsv = '';
        for(let i = 0; i < result.length; i++){
          if(i == this.productList.length){
            this.addTableRow();
          }
          this.productList[i].ProductCode = this.changeEstimateBrankId(result[i].product_id, true);
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i].Note = result[i].product_note;
          this.productList[i].Quantity = result[i].product_quantity;
          this.productList[i].Unit = result[i].product_unit;
          this.productList[i].SellingPrice = result[i].product_sales_unit_price;
          this.productList[i].PurchasePrime = result[i].product_purchase_price;
          this.productList[i].TotalPurchasePrime = this.productList[i].Quantity * this.productList[i].PurchasePrime;
          this.productList[i].Money = this.productList[i].Quantity * this.productList[i].SellingPrice;
          if (getNullStr(this.productList[i].ProductCode) != '' && isNaN(getNullStr(this.productList[i].ProductCode)) == false) {
            if (productIdCsv != '') {
              productIdCsv += ',';
            }
            // 製品コード
            productIdCsv += this.productList[i].ProductCode;
          }
        }
        // 売上単価、諸口区分を製品マスターから取得
        if (productIdCsv != '') {
          let where_clause = 'AND product_id IN (' + productIdCsv + ') ';
          let productsResultData = await selectOneTable('m_products', where_clause);
          //console.log(productsResultData);
          if (productsResultData != null) {
            for (let i = 0; i < this.productList.length; i++) {
              let data = productsResultData.find(el => el.product_id == this.productList[i].ProductCode);
              if (data != undefined) {
                this.productList[i].SalesUnitPrice = data.sales_unit_price;
                this.productList[i].SundriesClass = data.sundries_class;
                this.productList[i].ProductTaxRateClass = data.product_tax_rate_class_sales;
              }
            }
          }
        }
        this.calcTotal();
        // 取引先製品単価区分を設定
        await refreshClientAmountClassProductList(this.productList, this.suppliers.class, this.changeEstimateBrankId(this.suppliers.code, false), this.dateEstimateDate);
        // 最終行を空行とする
        this.addTableRow();
      }
    },
    /* 取得結果セット（受注コピー） */
    async setResultReceivedOrder(result) {
      if (result.length > 0){
        // 営業所プルダウン
        for (let i = 0; i < this.salesOffice.length; i++) {
          if (result[0].office_id == this.salesOffice[i].id) {
            this.selectSalesOffice = result[0].office_id;
          }
        }
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        this.suppliers.printName = result[0].client_name_kanji;
        // 取引先担当者
        this.clientStaff = {id:result[0].staff_id,name:result[0].staff_name_kanji,nameStamp:result[0].staff_name_stamp};
        // 現場系
        this.onSite.deliveryCode = result[0].client_id;
        this.onSite.code = result[0].site_id;
        this.onSite.name = result[0].client_site_name_kanji;
        // 全件参照して製品一覧を作成
        for(let i = 0; i < result.length; i++){
          if(i == this.productList.length){
            this.addTableRow();
          }
          this.productList[i].ProductCode = result[i].product_id;
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i].ProductTaxRateClass = result[i].product_tax_rate_class_sales;
          this.productList[i].Note = '';
          this.productList[i].Quantity = result[i].order_receive_quantity;
          this.productList[i].Unit = result[i].unit;
          this.productList[i].SellingPrice = result[i].order_receive_unit_amount;
          this.productList[i].SalesUnitPrice = result[i].sales_unit_price;
          this.productList[i].PurchasePrime = result[i].order_unit_amount;
          this.productList[i].SundriesClass = result[i].product_sundries_class;
          this.productList[i].TotalPurchasePrime = this.productList[i].Quantity * this.productList[i].PurchasePrime;
          this.productList[i].Money = this.productList[i].Quantity * this.productList[i].SellingPrice;
        }
        this.calcTotal();
        // 取引先製品単価区分を設定
        await refreshClientAmountClassProductList(this.productList, this.suppliers.class, this.suppliers.code, this.dateEstimateDate);
        // 最終行を空行とする
        this.addTableRow();
      }
    },
    // 取引先製品設定
    async setClientProduct() {
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSqlClientProduct();
      //console.log(selectSql);
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if(dataResult != null){
        await this.setResultClientProduct(dataResult);
      }else{
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
        scrollTo(0,0);
      }
    },
    // 検索SQL作成（取引先製品）
    makeSelectSqlClientProduct: function() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' clients.client_class';
      selectSql += ',clients.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',staffs.staff_name_stamp';
      selectSql += ',clients_products_csv.product_id';
      selectSql += ',products.product_name_kanji';
      selectSql += ',products.unit';
      selectSql += ',clients_products_csv.sales_unit_price AS sales_unit_price_csv';
      selectSql += ',clients_products_csv.old_sales_unit_price AS old_sales_unit_price_csv';
      selectSql += ',products.sales_unit_price';
      selectSql += ',products.is_9A';
      selectSql += ',products_details.purchase_price';
      selectSql += ',products_details.cost_price';
      selectSql += ',products.sundries_class AS product_sundries_class';
      selectSql += ',products.product_tax_rate_class_sales';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 'w_clients_products_csv AS clients_products_csv ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND clients.client_id = clients_products_csv.client_id ';
      selectSql += 'INNER JOIN m_products AS products ';
      selectSql += 'ON products.product_id = clients_products_csv.product_id ';
      selectSql += 'INNER JOIN m_products_details AS products_details ';
      selectSql += 'ON products_details.product_id = clients_products_csv.product_id ';
      selectSql += 'AND products_details.office_id = ' + this.selectSalesOffice + ' ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON clients.staff_id = staffs.staff_id ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 'clients_products_csv.id = ' + this.propWorkId + ' ';
      selectSql += 'AND clients_products_csv.client_id = ' + this.propClientId + ' ';
      selectSql += 'AND clients_products_csv.csv_row BETWEEN ' + (Number(this.propClientIndex) * Number(this.propClientProductMax) + 1) + ' AND ' + ((Number(this.propClientIndex) + 1) * Number(this.propClientProductMax)) + ' ';
      // 行番号でソート
      selectSql += 'ORDER BY clients_products_csv.csv_row';

      return selectSql;
    },
    // 取得結果セット（CSVコピー）
    async setResultClientProduct(result) {
      if (result.length > 0){
        // 取引先
        this.suppliers.class = Const.ClientClass.customer;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        this.suppliers.printName = result[0].client_name_kanji;
        // 取引先担当者
        this.clientStaff = {id:result[0].staff_id,name:result[0].staff_name_kanji,nameStamp:result[0].staff_name_stamp};
        // 単価登録区分ラジオボタン
        this.checkUnitPriceRegisterClass = Const.UnitPriceRegisterClass.insertAll;
        // 登録区分ラジオボタン
        this.checkRegisterClass = Const.ClientsProductsMasterRegisterClass.group;
        // 単価適用日
        this.dateUnitPriceEffectiveDate = this.propDate;
        // 内容
        this.textContents = this.defaultClientsProductsCsvTextContents;
        // 全件参照して製品一覧を作成
        for(let i = 0; i < result.length; i++){
          if(i == this.productList.length){
            this.addTableRow();
          }
          this.productList[i].ProductCode = result[i].product_id;
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i].ProductTaxRateClass = result[i].product_tax_rate_class_sales;
          // 備考にUP率、旧価格の情報を設定
          if (getNullStr(result[i].old_sales_unit_price_csv) == '') {
            this.productList[i].Note = '';
          } else {
            if (result[i].sales_unit_price_csv == 0 ||
              result[i].old_sales_unit_price_csv == 0) {
              // 新価格、または、旧価格が0の場合
              // UP率を設定しない（計算しない）
              this.productList[i].Note = '旧価格：' + result[i].old_sales_unit_price_csv;
            } else {
              // 旧価格も新価格も0でない場合
              // UP率と旧価格を設定する
              let note = 'UP率：' + (Math.round((result[i].sales_unit_price_csv - result[i].old_sales_unit_price_csv) * 1000 / result[i].sales_unit_price_csv) / 10).toString() + '％';
              note += ' 旧価格：' + result[i].old_sales_unit_price_csv;
              this.productList[i].Note = note;
            }
          }
          if (result[i].product_sundries_class == Const.SundriesClass.shokuchi) {
            this.productList[i].Quantity = 0;
          } else {
            this.productList[i].Quantity = 1;
          }
          this.productList[i].Unit = result[i].unit;
          this.productList[i].SellingPrice = result[i].sales_unit_price_csv;
          this.productList[i].SalesUnitPrice = result[i].sales_unit_price;
          if (this.productList[i].is_9A == 0) {
            // 9A製品以外の場合は原価単価
            this.productList[i].PurchasePrime = result[i].cost_price;
          } else {
            // 9A製品の場合は仕入単価
            this.productList[i].PurchasePrime = result[i].purchase_price;
          }
          this.productList[i].SundriesClass = result[i].product_sundries_class;
          this.productList[i].TotalPurchasePrime = this.productList[i].Quantity * this.productList[i].PurchasePrime;
          this.productList[i].Money = this.productList[i].Quantity * this.productList[i].SellingPrice;
        }
        this.calcTotal();
        // 取引先製品単価区分を設定
        await refreshClientAmountClassProductList(this.productList, this.suppliers.class, this.suppliers.code, this.dateEstimateDate);
        // 最終行を空行とする
        this.addTableRow();
      }
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      if (await this.execSave() == true) {
        // 保存成功時、値の変更の記録をクリア
        this.setFormChanged(false);
        // 見積照会画面へ遷移
        this.$router.push({ name: 'ESTIMATE-INQUIRY', query: { estimateId: this.estimateId, parentKbn: 1 } });
      }
      //console.log('保存処理終了');
    },
    /* 登録処理 */
    async execSave() {
      const functionName = 'execSave';

      let retResult = false;
      this.transactSqlList = [];
      // 見積データの登録
      await this.insertEstimate();
      // 単価登録
      await this.insertUnitPrice();

      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }

      // 作成した登録用SQLを全実行
      if (await this.executeAllSql() == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }
      retResult = true;

      return retResult;
    },
    /* 見積データ登録処理 */
    async insertEstimate() {
      //console.log('見積データ登録処理');
      // CRUD処理
      // 製品の分だけループして登録SQLを作成
      let bulkInsertSql = '';
      for(let i = 0; i < this.productList.length; i++){
        let colList = [];
        if (i == 0) {
          // 見積行番号
          colList.push(CreateColRow('estimate_row', i+1, 'NUMBER'));
        } else {
          // 見積番号
          colList.push(CreateColRow('estimate_id', 'LAST_INSERT_ID()', 'NUMBER'));
          // 見積行番号
          colList.push(CreateColRow('estimate_row', i+1, 'NUMBER'));
        }
        // 営業所コード
        colList.push(CreateColRow('office_id', this.selectSalesOfficeData.id, 'NUMBER'));
        // 営業所名（漢字）
        colList.push(CreateColRow('office_name_kanji', await escapeQuote(this.selectSalesOfficeData.name), 'VARCHAR'));
        // 取引先区分
        colList.push(CreateColRow('client_class', this.suppliers.class, 'NUMBER'));
        // 取引先コード
        colList.push(CreateColRow('client_id', this.changeEstimateBrankId(this.suppliers.code, false), 'NUMBER'));
        // 取引先名（漢字）
        colList.push(CreateColRow('client_name_kanji', await escapeQuote(this.suppliers.name), 'VARCHAR'));
        // 取引先印刷用宛名
        colList.push(CreateColRow('client_print_name', await escapeQuote(this.suppliers.printName), 'VARCHAR'));
        // 見積日
        colList.push(CreateColRow('estimate_date', this.dateEstimateDate, 'DATE'));
        // 取引先担当者コード
        colList.push(CreateColRow('client_staff_id', this.clientStaff.id, 'NUMBER'));
        // 取引先担当者名（漢字）
        colList.push(CreateColRow('client_staff_name_kanji', await escapeQuote(this.clientStaff.name), 'VARCHAR'));
        // 取引先担当者名（印鑑）
        colList.push(CreateColRow('client_staff_name_stamp', await escapeQuote(this.clientStaff.nameStamp), 'VARCHAR'));
        // 作成担当者コード
        colList.push(CreateColRow('created_staff_id', this.createdStaff.id, 'NUMBER'));
        // 作成担当者名（漢字）
        colList.push(CreateColRow('created_staff_name_kanji', await escapeQuote(this.createdStaff.name), 'VARCHAR'));
        // 作成担当者名（印鑑）
        colList.push(CreateColRow('created_staff_name_stamp', await escapeQuote(this.createdStaff.nameStamp), 'VARCHAR'));
        // 見積種類
        colList.push(CreateColRow('estimate_kind', this.checkEstimateKind, 'NUMBER'));
        // 消費税区分
        colList.push(CreateColRow('tax_type', this.checkTaxType, 'NUMBER'));
        // 単価登録区分
        colList.push(CreateColRow('unit_price_register_class', this.checkUnitPriceRegisterClass, 'NUMBER'));
        // 単価適用日
        colList.push(CreateColRow('unit_price_effective_date', this.dateUnitPriceEffectiveDate, 'DATE'));
        // 単価備考
        colList.push(CreateColRow('unit_price_note', await escapeQuote(this.textUnitPriceNote), 'VARCHAR'));
        // 内容
        colList.push(CreateColRow('contents', await escapeQuote(this.textContents), 'VARCHAR'));
        // 納期
        colList.push(CreateColRow('delivery_date', this.dateDeliveryDate, 'DATE'));
        // 見積金額
        colList.push(CreateColRow('estimate_amount', this.totalFee+this.tax, 'NUMBER'));
        // 消費税
        colList.push(CreateColRow('tax', this.tax, 'NUMBER'));
        // 現場コード
        colList.push(CreateColRow('site_id', this.changeEstimateBrankId(this.onSite.code, false), 'NUMBER'));
        // 現場名（漢字）
        colList.push(CreateColRow('client_site_name_kanji', await escapeQuote(this.onSite.name), 'VARCHAR'));
        // 支払方法
        colList.push(CreateColRow('payment_way', await escapeQuote(this.textPaymentWay), 'VARCHAR'));
        // 見積期限
        colList.push(CreateColRow('estimate_period', this.dateEstimatePeriod, 'DATE'));
        // 見積期限（文章）
        colList.push(CreateColRow('estimate_period_sentence', await escapeQuote(this.textEstimatePeriodSentence), 'VARCHAR'));
        // 摘要
        colList.push(CreateColRow('summary', await escapeQuote(this.textSummary), 'VARCHAR'));
        // 製品コード
        colList.push(CreateColRow('product_id', this.changeEstimateBrankId(this.productList[i].ProductCode, false), 'NUMBER'));
        // 製品名（漢字）
        colList.push(CreateColRow('product_name_kanji', await escapeQuote(this.productList[i].ProductName), 'VARCHAR'));
        // 製品備考
        colList.push(CreateColRow('product_note', await escapeQuote(this.productList[i].Note), 'VARCHAR'));
        // 製品数量
        colList.push(CreateColRow('product_quantity', this.productList[i].Quantity, 'NUMBER'));
        // 製品単位
        colList.push(CreateColRow('product_unit', await escapeQuote(this.productList[i].Unit), 'VARCHAR'));
        // 製品売上単価
        colList.push(CreateColRow('product_sales_unit_price', this.productList[i].SellingPrice, 'NUMBER'));
        // 製品仕入単価
        colList.push(CreateColRow('product_purchase_price', this.productList[i].PurchasePrime, 'NUMBER'));
        // 製品金額
        colList.push(CreateColRow('product_amount', this.productList[i].Money, 'NUMBER'));
        // 作成年月日
        colList.push(CreateColRow('created_date', 'CURDATE()', 'DATE'));
        if (this.checkUnitPriceRegisterClass != Const.UnitPriceRegisterClass.insertNo) {
          // 単価登録区分が「1:登録しない」以外の場合、画面の選択値を設定
          // グループ登録区分
          colList.push(CreateColRow('group_register_class', this.checkRegisterClass, 'NUMBER'));
        } else {
          // 単価登録区分が「1:登録しない」の場合、デフォルト値（「0：グループ」）
          // グループ登録区分
          colList.push(CreateColRow('group_register_class', Const.ClientsProductsMasterRegisterClass.group, 'NUMBER'));
        }
        // 作成ユーザー
        colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));

        if (i == 0) {
          let insertSql = CreateInsertSql(colList, 'full', 't_estimate');
          this.transactSqlList.push(insertSql);
          //console.log(insertSql)
        } else {
          if (bulkInsertSql == '') {
            bulkInsertSql += 'INSERT INTO t_estimate (' + CreateInsertSql(colList, 'col', 't_estimate') + ') VALUES ';
          } else {
            bulkInsertSql += ',';
          }
          bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_estimate') + ')';
          if (bulkInsertSql.length >= Const.SqlMaxLength) {
            this.transactSqlList.push(bulkInsertSql);
            bulkInsertSql = '';
          }
        }
      }
      if (bulkInsertSql != '') {
        //console.log(bulkInsertSql)
        this.transactSqlList.push(bulkInsertSql);
      }
    },
    /* 単価登録処理 */
    async insertUnitPrice() {
      // 単価登録区分が「1:登録しない」以外の場合は単価登録を行う
      if (this.checkUnitPriceRegisterClass != Const.UnitPriceRegisterClass.insertNo){
        //console.log('自身の単価登録処理');
        // 単価登録用に製品一覧を作り直す（無効な製品と重複製品の除外（重複は後優先））
        let unitPriceProductList = [];
        for (let i = this.productList.length - 1; i >= 0; i--) {
          // 製品コードが空白の場合は登録しない
          if (getNullStr(this.productList[i].ProductCode) != '') {
            let productIndex = unitPriceProductList.findIndex(el => el.ProductCode == this.productList[i].ProductCode);
            if (productIndex == -1) {
              // 一覧に未登録の製品の場合
              // 一番前に追加
              unitPriceProductList.unshift(this.productList[i]);
            }
          }
        }
        //console.log(unitPriceProductList);
        if (unitPriceProductList.length > 0) {
          // 有効な製品がある場合
          // 単価登録用SQL作成
          let sqlList = await getInsertUnitPriceSqlList(unitPriceProductList, this.changeEstimateBrankId(this.suppliers.code, false), this.dateUnitPriceEffectiveDate, this.checkRegisterClass, this.checkUnitPriceRegisterClass, this.loginId);
          for (let i = 0; i < sqlList.length; i++) {
            this.transactSqlList.push(sqlList[i]);
          }
        }
      }
    },
    /* 作成したSQL文を全実行 */
    async executeAllSql() {
      const functionName = 'executeAllSql';
      let retResult = false;
      // 引数のプロパティは「SQLs」
      let condition = { SQLs: this.transactSqlList };
      //console.info({condition});
      try {
        // executeTransactSqlで実行
        let result = await API.graphql(graphqlOperation(executeTransactSql, condition));
        // レスポンスデータを取得できた際の処理
        if (result) {
          // result.data.executeTransactSql.bodyにJSON形式で結果が返ってくる
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.info({responseBody});
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({message});
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({errorMessage});
          } else {
            // SQLが正常に終了した際の処理
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              /*
              for (const rows of responseBody.data) {
                console.dir(rows, {depth: null});
              }
              */
              this.estimateId = responseBody.data[0][0]['LAST_INSERT_ID()'];
              //console.log('estimateId:' + this.estimateId);
            }

            retResult = true;
          }
          if (result.errors) {
            await addOperationLogs('Error', MODULE_NAME, functionName, {
              graphqlOperation: 'executeTransactSql',
              SQLs: this.transactSqlList,
              result: result
            });    
          }
          //console.info(JSON.parse(result.data.executeTransactSql.body));
        } else {
          // レスポンスデータを取得できなかった際の処理
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: this.transactSqlList
          });
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: this.transactSqlList
        }, error);
        console.error(error);
      }

      return retResult;
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      const functionName = 'searchClient';
      const result = await searchClientModal(client_id, this.htmlConst.ClientClass.customer, functionName, MODULE_NAME);
      if (typeof result != 'undefined') {
        await this.closeClientSearchModal(result);
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6 || client_id == '0') {
          // 取引先クリア
          await this.clientClear();
        }
      }
    },
    // 取引先検索モーダル表示
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先検索モーダルを閉じた時
    async closeClientSearchModal(clientItems) {
      //console.log(clientItems);
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined'){
        this.suppliers.class = clientItems.detail.client_class;
        this.suppliers.code = clientItems.detail.client_id;
        this.suppliers.name = clientItems.detail.client_name_kanji;
        this.suppliers.printName = clientItems.detail.client_name_kanji;
        this.clientStaff = {id:clientItems.detail.staff_id, name: clientItems.detail.staff_name_kanji, nameStamp: clientItems.detail.staff_name_stamp};
        // 受渡場所クリア
        this.siteClear();
        // 単価をリフレッシュ
        await this.refreshSalesAmount();
        // 値の変更を記録
        this.setFormChanged();
      }
    },
    // 取引先クリア
    async clientClear() {
      //console.log('取引先クリア');
      this.suppliers.class = 0;
      this.suppliers.code = '';
      this.suppliers.name = '';
      this.suppliers.printName = '';
      this.clientStaff = {id:0,name:' '};
      // 受渡場所クリア
      this.siteClear();
      // 単価をリフレッシュ
      await this.refreshSalesAmount();
      // 値の変更を記録
      this.setFormChanged();
    },
    // 取引先照会
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    // 担当者検索モーダル表示
    showStaffSearchModal: function(index) {
      this.dataIndex = index;
      this.$bvModal.show('staffSearchModal');
    },
    // 担当者検索モーダルを閉じた時
    closeStaffSearchModal: function(staffItems) {
      //console.log(staffItems);
      // モーダルから渡された値の有無チェック
      if(typeof staffItems != 'undefined'){
        if (this.dataIndex == 1) {
          this.clientStaff.id = staffItems.detail.staff_id;
          this.clientStaff.name = staffItems.detail.staff_name_kanji;
          this.clientStaff.nameStamp = staffItems.detail.staff_name_stamp;
        } else {
          this.createdStaff.id = staffItems.detail.staff_id;
          this.createdStaff.name = staffItems.detail.staff_name_kanji;
          this.createdStaff.nameStamp = staffItems.detail.staff_name_stamp;
        }
        // 値の変更を記録
        this.setFormChanged();
      }
    },
    // 取引先担当者クリア
    clientStaffClear: function() {
      //console.log('取引先担当者クリア');
      this.clientStaff.id = 0;
      this.clientStaff.name = ' ';
      this.clientStaff.nameStamp = '';
      // 値の変更を記録
      this.setFormChanged();
    },
    // 作成担当者クリア
    createdStaffClear: function() {
      //console.log('作成担当者クリア');
      this.createdStaff.id = 0;
      this.createdStaff.name = ' ';
      this.createdStaff.nameStamp = '';
      // 値の変更を記録
      this.setFormChanged();
    },
    // 製品コード直接入力 - 製品検索
    async searchProduct(product_id, index) {
      const functionName = 'searchProduct';
      const result = await searchProductsModal(product_id, this.productSearchProp, functionName, MODULE_NAME);
      this.dataIndex = index;
      if (typeof result != 'undefined') {
        this.closeProductSearchModal(result);
      } else {
        // 製品コード8桁かつデータ取得失敗の場合
        if (product_id.length == 8 || product_id == '0') {
          // 製品クリア
          this.productClear(index);
        }
      }
    },
    // 製品検索モーダル表示
    showProductSearchModal:function(index) {
      this.dataIndex = index;
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    closeProductSearchModal: function(productItems) {
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if (typeof productItems != 'undefined') {
        let focusFlg = false;
        if (productItems.length == undefined) {
          // 選択ボタン押下でクローズ
          this.setProductSearchModal(this.dataIndex, productItems.detail);
          focusFlg = true;
        } else {
          // 一括選択ボタン押下でクローズ
          if (productItems.length == 1) {
            // 1件の場合のみフォーカスを数量に当てる
            focusFlg = true;
          }
          for (let i = 0; i < productItems.length; i++) {
            if (i == 0) {
              this.setProductSearchModal(this.dataIndex, productItems[i].detail);
            } else {
              this.productList.splice(this.dataIndex + i, 0, this.createNewRow());
              this.setProductSearchModal(this.dataIndex + i, productItems[i].detail);
            }
          }
        }
        this.calcTotal();
        // 最終行が空行でない場合は空行追加
        if (this.isListBlankRow(this.productList.length - 1) == false) {
          this.addTableRow();
        }
        if (focusFlg == true) {
          // 製品検索を行った行の数量にフォーカスを移動
          setTimeout(() => {
            if (this.dataIndex < this.productList.length) {
              this.setFocus('productQuantity' + this.dataIndex);
            }
          }, 500);
        }
        // 値の変更を記録
        this.setFormChanged();
      }
    },
    // 製品検索モーダルの返却値設定
    setProductSearchModal: function(index, productItemsDetail) {
      this.productList[index].ProductCode = productItemsDetail.product_id;
      this.productList[index].ProductName = productItemsDetail.product_name_kanji;
      this.productList[index].ProductTaxRateClass = productItemsDetail.product_tax_rate_class_sales;
      this.productList[index].Quantity = 1;
      this.productList[index].Unit = productItemsDetail.unit;
      if(productItemsDetail.cp_sales_unit_price != null){
        this.productList[index].SellingPrice = productItemsDetail.cp_sales_unit_price;
        this.productList[index].ClientAmountClass = '*';
      }else{
        this.productList[index].SellingPrice = productItemsDetail.sales_unit_price;
        this.productList[index].ClientAmountClass = '';
      }
      this.productList[index].SalesUnitPrice = productItemsDetail.sales_unit_price;
      if (productItemsDetail.is_9A == 0) {
        // 9A製品以外の場合は原価単価
        this.productList[index].PurchasePrime = productItemsDetail.cost_price;
      } else {
        // 9A製品の場合は仕入単価
        this.productList[index].PurchasePrime = productItemsDetail.purchase_price;
      }
      this.productList[index].SundriesClass = productItemsDetail.sundries_class;
      this.productList[index].TotalPurchasePrime = this.productList[index].Quantity * this.productList[index].PurchasePrime;
      this.productList[index].Money = this.productList[index].Quantity * this.productList[index].SellingPrice;
    },
    // 製品クリア
    productClear: function(rowNum) {
      //console.log(rowNum);
      this.productList[rowNum].ProductCode = '';
      this.productList[rowNum].ProductName = '';
      this.productList[rowNum].ProductTaxRateClass = Const.ProductTaxRateClass.normalTax;
      this.productList[rowNum].Quantity = 0;
      this.productList[rowNum].Unit = '';
      this.productList[rowNum].SellingPrice = 0;
      this.productList[rowNum].ClientAmountClass = '';
      this.productList[rowNum].SalesUnitPrice = 0;
      this.productList[rowNum].PurchasePrime = 0;
      this.productList[rowNum].TotalPurchasePrime = 0;
      this.productList[rowNum].Money = 0;
      this.productList[rowNum].SundriesClass = Const.SundriesClass.shokuchi;
      // 合計金額、合計仕入単価、粗利、消費税額計算
      this.calcTotal();
      // 値の変更を記録
      this.setFormChanged();
    },
    // 現場検索モーダルを開く
    siteSearchModal:function() {
      //console.log('現場検索モーダル');
      this.$bvModal.show('siteSearchModal');
    },
    // 現場コード直接入力 - 現場検索
    async searchSite(site_id) {
      const functionName = 'searchSite';
      const client_id = this.changeEstimateBrankId(this.suppliers.code, false);
      const result = await searchSiteModal(site_id, client_id, functionName, MODULE_NAME);
      if (typeof result != 'undefined') {
        // 現場情報セット
        this.closeSiteSearchModal(result);
      } else {
        // 現場クリア
        this.siteClear();
      }
    },
    // 現場検索モーダルを閉じた時
    closeSiteSearchModal: function(siteItems) {
      //console.log(siteItems);
      // モーダルから渡された値の有無チェック
      if(typeof siteItems != 'undefined'){
        this.onSite.deliveryCode = siteItems.detail.client_id;
        this.onSite.code = siteItems.detail.site_id;
        this.onSite.name = siteItems.detail.client_site_name_kanji;
        // 値の変更を記録
        this.setFormChanged();
      }
    },
    // 現場クリア
    siteClear: function() {
      //console.log('受渡場所クリア');
      this.onSite.deliveryCode = '';
      this.onSite.code = '';
      this.onSite.name = '';
      // 値の変更を記録
      this.setFormChanged();
    },
    // 取引先製品単価履歴照会モーダルを開く
    clickInquryClientProduct: function() {
      //console.log('取引先製品単価履歴照会モーダル');

      let productIdCsv = '';
      for (let i = 0; i < this.productList.length; i++) {
        if (getNullStr(this.productList[i].ProductCode) != '' && isNaN(getNullStr(this.productList[i].ProductCode)) == false) {
          if (productIdCsv != '') {
            productIdCsv += ',';
          }
          // 製品コード
          productIdCsv += this.productList[i].ProductCode;
        }
      }
      this.clientProductProp.clientClass = this.suppliers.class;
      this.clientProductProp.clientId = this.changeEstimateBrankId(this.suppliers.code, false);
      this.clientProductProp.clientNameKanji = this.suppliers.name;
      this.clientProductProp.productIdCsv = productIdCsv;

      this.$bvModal.show('clientProductInquiryModal');
    },
    // 再計算イベント
    calc: function(index) {
      // 対象行の項目計算
      this.productList[index].TotalPurchasePrime = this.productList[index].Quantity * this.productList[index].PurchasePrime;
      this.productList[index].Money = this.productList[index].Quantity * this.productList[index].SellingPrice;
      // 合計金額、合計仕入単価、粗利、消費税額計算
      this.calcTotal();
    },
    // 合計金額、合計仕入単価、粗利、消費税額計算
    calcTotal: function() {
      let moneySubTotalNormal = 0;
      let moneySubTotalLight = 0;
      this.totalFee = 0;
      this.totalPurchasePrime = 0;
      this.grossProfit = 0;
      this.tax = 0;
      for(let i = 0; i < this.productList.length;i++){
        this.totalFee += this.productList[i].Money;
        this.totalPurchasePrime += this.productList[i].TotalPurchasePrime;
        if (this.productList[i].ProductTaxRateClass == Const.ProductTaxRateClass.normalTax) {
          moneySubTotalNormal += this.productList[i].Money;
        } else if (this.productList[i].ProductTaxRateClass == Const.ProductTaxRateClass.lightTax) {
          moneySubTotalLight += this.productList[i].Money;
        }
      }
      this.grossProfit = Math.round((this.totalFee - this.totalPurchasePrime) * 1000 / this.totalFee) / 10;
      // 消費税区分が「1:消費税あり」の場合、消費税を計算（見積日付を基準日とする）
      if (this.checkTaxType == Const.TaxType.tax &&
      this.dateEstimateDate != '') {
        this.tax = calcTaxNew(this.dateEstimateDate, moneySubTotalNormal, moneySubTotalLight, this.controlMasterData.taxRate, this.controlMasterData.newTaxRate, this.controlMasterData.lightTaxRate, this.controlMasterData.newLightTaxRate, this.controlMasterData.newTaxStartDate);
      } else {
        this.tax = 0;
      }
    },
    // 行追加ボタンイベント
    addTableRow: function(rows = 1) {
      let newProduct = this.createNewRow();
      for (let index = 0; index < rows; index++) {
        this.productList.push({...newProduct});
      }
    },
    // 行作成
    createNewRow: function() {
      let newProduct = {
        ProductCode: '',
        ProductName: '',
        ProductTaxRateClass: Const.ProductTaxRateClass.normalTax,
        Note: '',
        Quantity: 0,
        Unit: '',
        SellingPrice: 0,
        ClientAmountClass: '',
        SalesUnitPrice: 0,
        PurchasePrime: 0,
        TotalPurchasePrime: 0,
        Money: 0,
        SundriesClass: Const.SundriesClass.shokuchi,
      };
      return newProduct;
    },
    // テーブル行削除イベント
    deleteBtn: function(rowNum) {
      //console.log(rowNum);
      if(this.productList.length > 1){
        this.productList.splice(rowNum,1);
        // 合計金額、合計仕入単価、粗利、消費税額計算
        this.calcTotal();
      }
    },
    // テーブル初期化処理
    initProductTable: function() {
      this.productList = [];
      this.addTableRow();
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* キャンセルボタン押下時 */
    async clickBack(){
      // 見積・発注一覧画面へ遷移
      this.$router.push({ name: 'ESTIMATE-LIST' });
    },
    // 一覧の指定行が空かどうかチェック
    isListBlankRow: function(index) {
      if (this.productList[index].ProductCode == '' && this.productList[index].ProductName == '') {
        return true;
      } else {
        return false;
      }
    },
    // 一覧の後ろの空行を削除
    delListLastBlankRow: function() {
      //console.log('空行削除開始');
      for (let i = this.productList.length - 1; i > 0; i--) {
        if (this.isListBlankRow(i) == true) {
          // 後ろにある空行は削除
          this.productList.splice(i, 1);
        } else {
          // 1件でも空行以外の行があった場合は削除処理終了
          break;
        }
      }
      // 最後に合計金額、合計仕入単価、粗利計算
      this.calcTotal();
      //console.log('空行削除終了');
    },
    // 売価をリフレッシュ
    async refreshSalesAmount() {
      const functionName = 'refreshSalesAmount';
      if (this.isUnitPrice == false) {
        // 単価適用チェックがOFFの場合はリフレッシュしない
        return;
      }
      if (getNullStr(this.dateEstimateDate) != '') {
        this.$store.commit('setLoading', true);
        try {
          await refreshSalesAmountProductList(this.productList, this.suppliers.class, this.changeEstimateBrankId(this.suppliers.code, false), this.dateEstimateDate, 'ProductCode', true);
          for (let i = 0; i < this.productList.length; i++) {
            this.productList[i].Money = this.productList[i].Quantity * this.productList[i].SellingPrice;
          }
          // 合計金額、合計仕入単価、粗利、消費税額計算
          this.calcTotal();
        } catch (error) {
          console.log(error);
          await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        }
        if (this.getMessageFlg == true) {
          scrollTo(0,0);
        }
        this.$store.commit('setLoading', false);
      }
    },
    // 行挿入ボタンイベント
    insertTableRow: function(index) {
      this.productList.splice(index, 0, this.createNewRow());
    },
    // 見積の空白設定コードの変換
    changeEstimateBrankId: function(id, isChangeBrank) {
      if (isChangeBrank == true) {
        // 空白に変更フラグが立っている場合
        // 0の場合、空白に変換して返却
        return getNullStr(id) == '0' ? '' : id;
      } else {
        // 空白に変換フラグが立っていない場合
        return getNullStr(id) == '' ? '0' : id;
      }
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
    // 行移動のためのドラッグ開始イベント
    onRowMoveDragstart: function(event, index) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.setData('drag-index', index);
    },
    // 行移動のためのドロップイベント
    onRowMoveDrop(event, index) {
      const moveRowData = this.productList.splice(event.dataTransfer.getData('drag-index'), 1)[0];
      this.productList.splice(index, 0, moveRowData);
      // 値の変更を記録
      this.setFormChanged();
    },
    // 変更フラグを設定
    // ※引数を設定しない場合はtrueを設定
    setFormChanged: function(isFormChanged=true) {
      //console.log('変更フラグ設定：' + isFormChanged);
      this.isFormChanged = isFormChanged;
    },
    // 変更中の画面移動直前の確認メッセージ表示
    confirmChangedUnload(event) {
      if (this.isFormChanged == true) {
        // 画面を変更している場合
        event.returnValue = '行った変更が保存されない可能性があります。';
      }
    },
  },
  // ページ遷移直前のイベント
  async beforeRouteLeave (to, form, next) {
    if (this.isFormChanged == true) {
      let confirmMessage = [];
      confirmMessage.push(this.$createElement('div','行った変更が保存されない可能性があります。'));
      this.$store.commit('setLoading', false);
      let retConfirm = await this.$bvModal.msgBoxConfirm(confirmMessage, {title: '画面遷移確認'});
      if (retConfirm != true) {
        // ページ遷移を抑止
        next(false);
        return;
      }
    }
    // 除外しないと別ページでも画面抑止が残る
    window.removeEventListener('beforeunload', this.confirmChangedUnload);
    // 想定の通りにページ遷移
    next();
  },
}
</script>
<style scoped>
</style>