<template>
  <!-- 9A製品保守一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row">
        <div class="col-lg-12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <div class="card border">
              <div class="card-header mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip">
                  <span class="oi oi-magnifying-glass"></span> 検索条件
                  <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                  <router-link to="/products9A-input" class="btn btn-sm btn-primary mx-2" style="font-size:60%;float:right;" title="9A製品データの新規登録を行います。">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </router-link>
                </h5>
              </div>
              <div id="collapse1" class="collapse show">
                <div class="card-body p-2">
                  <div class="container-fluid">
                    <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                      <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="warning" class="mt-2" v-if="errorMessages.length">
                      <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <div class="row mt-2">
                        <!-- 9A製品コード入力欄 -->
                        <div class="col-md-3 form-group">
                          <label for="productId" class="form-label">9A製品コード</label>
                          <validation-provider name="productId" rules="numeric|max:8|between:0,99999999" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="productId" name="productId" class="form-control" v-model="searchConditions.productId" maxlength="8">
                              <small class="form-text text-muted">前方一致検索です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 9A製品名入力欄 -->
                        <div class="col-md-9 form-group">
                          <label for="productName" class="form-label">9A製品名</label>
                          <validation-provider rules="max:40" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="productName" name="productName" class="form-control" v-model.trim="searchConditions.productName" maxlength="40">
                              <small class="form-text text-muted">部分一致検索です。登録済みの製品名（漢字）または製品名（カナ）と一致するデータを検索します。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                      </div>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <div class="row justify-content-md-center my-2">
                      <div class="col-lg-2">
                        <button type="button" class="btn btn-primary btn-block" @click="searchButton()"><span class="oi oi-magnifying-glass"></span> 検索</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ●●●検索結果●●● -->
      <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <div class="row">
          <div class="col-sm-12">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="4"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-col cols="12" class="px-0">
                <b-table
                  show-empty
                  :head-variant="headVariant"
                  :responsive="true"
                  :items="productsList"
                  :fields="fields"
                  :busy="busy"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :bordered="true"
                  :empty-text="emptyText"
                  :empty-filtered-text="emptyFilterdText"
                  @filtered="onFiltered"
                >
                  <!-- テーブル読み込み時表示html -->
                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>読み込んでいます...</strong>
                    </div>
                  </template>
                  <!-- 操作列 -->
                  <template #cell(operation)="operation">
                    <div v-show="!printStatus">
                      <!-- 照会ボタン -->
                      <b-button size="sm" @click="clickDetailBtn(operation.item)" class="mr-1">
                        <span class="oi oi-list"></span> 照会
                      </b-button>&nbsp;
                      <!-- 編集ボタン -->
                      <b-button size="sm" @click="clickEditBtn(operation.item)" class="mr-1">
                        <span class="oi oi-pencil"></span> 編集
                      </b-button>&nbsp;
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- 表示レコードをラベル表示 -->
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : productsList.length"
                :per-page="perPage == -1 ? productsList.length : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, windowPrint, convertSqlLikeSpecialChar, init, executeTransactSqlList, CreateColRow, CreateInsertSql, getUserCol, getControlMaster, isSystemEditable, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'products9A-maintenance';

export default {
  name: 'PRODUCTS9A-MAINTENANCE',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '9A製品保守',
      // ユーザ名
      username: this.$store.getters.user.username,
      // 画面メッセージ
      successMessages: [],
      errorMessages: [],
      // 検索条件
      searchConditions: {
        productId: '',
        productName: ''
      },
      // 検索結果
      resultError: [],
      // 結果リスト
      productsList: [],
      // トランザクションSQLリスト
      transactSqlList: [],
      // 現在処理年月
      processMonthYear: null,
      fields: DataTblDef.products_list_fields,
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText:DataTblDef.emptyFilteredText,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // プリントアウト状態
      printStatus: false,
    };
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.productsList.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  /* 関数群 */
  methods: {
    shortkey() {
      this.$router.push({ name: 'PRODUCTS9A-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData(){
      // 現在処理年月
      let controlData = await getControlMaster();
      this.processMonthYear = controlData.process_month_year;
      // 最新製品データ1000件を取得
      // 一般製品が対象外
      //const listMProductsConditions = 'AND is_9A = 1 ORDER BY updated DESC LIMIT 1000';
      //await this.search(listMProductsConditions);
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * 検索処理
     * @param {String} listMProductsConditions - 製品マスタ検索条件
     */
    async search(listMProductsConditions) {
      this.productsList = [];

      // 製品マスタの曖昧検索を行います。
      let listMProducts = null;
      if (listMProductsConditions !== null && listMProductsConditions.length > 0) {
        listMProducts = await this.searchProducts(listMProductsConditions);
        if (listMProducts == null) {
          this.resultError.push(DISP_MESSAGES.WARNING['2001']);
          return;
        } 
        listMProducts.forEach(Product => {
          this.productsList.push(
            {
              id: Product.id,
              product_id: Product.product_id,
              product_name_kanji: Product.product_name_kanji,
              product_name_kana: Product.product_name_kana,
            }
          )
        });
      }
      // 検索結果が1000件の場合は1000件を超えている場合があるのでメッセージを表示します。
      if (this.productsList.length === 1000) {
        this.resultError.push(DISP_MESSAGES.WARNING['2002']);
      }
    },
    /**
     * 製品マスタ検索
     * @param {String} whereClause - 検索条件
     * @returns {Array<Object>} 検索結果
     */
    async searchProducts(whereClause) {
      const functionName = 'searchProducts';

      let result = null;
      try {
        result = await selectOneTable('m_products', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products',
          whereClause: whereClause
        }, error);
        return null;
      }
      if (result != null && result.length > 0) {
        return result;
      } else {
        return [];
      }
    },
    /**
     * 検索ボタン押下時
     */
    async searchButton() {
      this.busy = true;
      this.resultError = [];

      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const isValid = await this.$refs.observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (isValid) {
        // 製品コード
        let listMProductsConditions = '';
        if (this.searchConditions.productId !== '') {
          listMProductsConditions += `AND product_id LIKE '${this.searchConditions.productId}%' `;
        }
        // 製品名(あいまい検索対象)
        if (this.searchConditions.productName !== '') {
          // 特殊文字変換
          const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
          listMProductsConditions += `AND (REPLACE(REPLACE(CONVERT(product_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
          listMProductsConditions += `OR REPLACE(REPLACE(CONVERT(product_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '')) `;
        }
        // 一般製品が対象外
        listMProductsConditions += 'AND is_9A = 1 ';
        // 1000件までが上限になります
        listMProductsConditions += 'LIMIT 1000';
        await this.search(listMProductsConditions);
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });
      }
      this.busy = false;
    },
    /**
     * 照会ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickDetailBtn(item) {
      let route = this.$router.resolve({ name: 'PRODUCTS9A-INQUIRY', query: { id: item.id }});
      window.open(route.href, '_blank');
    },
    /**
     * 編集ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickEditBtn(item) {
      let route = this.$router.resolve({ name: 'PRODUCTS9A-EDIT', query: { id: item.id }});
      window.open(route.href, '_blank');
    },
    /**
     * 削除ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickDeleteBtn(item) {
      this.$bvModal.msgBoxConfirm('選択された製品データを削除します。関連データ削除され、復元できません。\nよろしいですか？').then(async value => {
        if (value) {
          this.$store.commit('setLoading', true);
          this.successMessages = [];
          this.errorMessages = [];
          await this.execDelProduct(item.product_id)
          this.searchButton();
          this.$store.commit('setLoading', false);
          scrollTo(0,0);
        }
      });
    },
    /**
     * 削除処理
     * @param {Int} product_id - 選択された製品コード
     */
    async execDelProduct(product_id) {
      const functionName = 'execDelProduct';
      // 製品
      const sqlDelProduct = `DELETE FROM m_products WHERE product_id = ${product_id}`
      this.transactSqlList.push(sqlDelProduct)
      // 製品詳細
      const sqlDelProductsDetails = `DELETE FROM m_products_details WHERE product_id = ${product_id}`
      this.transactSqlList.push(sqlDelProductsDetails)
      // 製品単価履歴
      const sqlDelProductsPricesHistories = `DELETE FROM t_products_prices_histories WHERE product_id = ${product_id}`
      this.transactSqlList.push(sqlDelProductsPricesHistories)
      // 製品発注
      const sqlDelProductsOrders = `DELETE FROM m_products_orders WHERE product_id = ${product_id}`
      this.transactSqlList.push(sqlDelProductsOrders)
      // 製品単位変換
      const sqlDelProductsUnitsConversions = `DELETE FROM m_products_units_conversions WHERE case_product_id = ${product_id} or loose_product_id = ${product_id}`
      this.transactSqlList.push(sqlDelProductsUnitsConversions)
      // 製品単価グループ
      const sqlDelProductsPricesGroupss = `DELETE FROM m_products_prices_groups WHERE product_id = ${product_id} or product_group_id = ${product_id}`
      this.transactSqlList.push(sqlDelProductsPricesGroupss)
      // 製品構成
      const sqlDelProductsCompositions = `DELETE FROM m_products_compositions WHERE product_id = ${product_id}`
      this.transactSqlList.push(sqlDelProductsCompositions)
      // 製品カテゴリ
      const sqlDelProductsCategories = `DELETE FROM m_products_categories WHERE product_id = ${product_id}`
      this.transactSqlList.push(sqlDelProductsCategories)
      // 製品在庫
      const sqlDelStocks = `DELETE FROM m_stocks WHERE product_id = ${product_id} AND month_year = ${this.processMonthYear}`
      this.transactSqlList.push(sqlDelStocks)

      await this.insertProductDeletedLog(product_id)
      
      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
      } else {
        // 成功時にログを出力
        await addOperationLogs('Info', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: this.transactSqlList,
        });
      }
      if (this.errorMessages.length == 0) {
        this.successMessages.push(DISP_MESSAGES.SUCCESS['1002']);
      }
    },

    /**
     * トランザクション削除テーブルに記録処理
     * @param {Int} product_id - 選択された製品コード
     */
    async insertProductDeletedLog(product_id) {
      let colList = []
      // 製品コード
      colList.push(CreateColRow('product_id', product_id, 'INT'));
      // 削除フラグ
      colList.push(CreateColRow('is_deleted', 1, 'INT'));
      // 新規と更新ユーザー
      const colBothUser = await getUserCol(this.username, 'both')

      // sql insert カラム部分
      const sqlInsertCol = CreateInsertSql(colList.concat(colBothUser), 'col', 't_products_deleted_log').split(',');
      // sql insert カラム部分
      const sqlInsertVal = CreateInsertSql(colList.concat(colBothUser), 'val', 't_products_deleted_log').split(',');
      // トランザクション削除テーブル
      let sql = `INSERT INTO t_products_deleted_log (${sqlInsertCol}) VALUES (${sqlInsertVal.join()})`

      this.transactSqlList.push(sql)
    }
  }
};
</script>