<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container class="px-4 py-4 min-vh-85" fluid>
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 処理範囲指定</strong></h5>
            </div>
          </b-media-body>
        </b-media>
        <b-card>
          <b-card-header v-if="getMessageFlg">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <validation-observer ref="observer">
              <b-form @submit.prevent="clearAlert(); saveData();" id="InputForm" class="form-horizontal">
                <!-- ●●●入力欄●●● -->
                <b-container>
                  <b-row>
                    <!-- 営業所コードプルダウン -->
                    <b-col lg="6">
                      <b-form-group
                        label="営業所"
                        label-for="selectSalesOffice"
                      >
                        <b-form-select
                          id="selectSalesOffice"
                          v-model="searchConditions.selectSalesOffice"
                          :options="searchConditions.salesOfficeOption"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 得意先コード -->
                    <b-col lg="12">
                      <b-form-group
                        label="得意先コード"
                        label-for="clientIdStart"
                      >
                        <b-input-group>
                          <validation-provider name="clientIdStart" :rules="{numeric:true,consistency:searchConditions.clientIdEnd}" v-slot="{ classes,errors }">
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <b-input-group>
                                  <b-form-input type="text" id="clientIdStart" name="clientIdStart" v-model.number="searchConditions.clientIdStart" maxlength="6" @blur="searchConditions.clientIdEnd = searchConditions.clientIdStart"/>
                                  <b-input-group-text @click="showClientSearchModal(1);" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(1);" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                </b-input-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-row>
                          </validation-provider>
                          <b-input-group-text>～</b-input-group-text>
                          <validation-provider name="clientIdEnd" rules="numeric" v-slot="{ classes,errors }">
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <b-input-group>
                                  <b-input type="text" v-model.number="searchConditions.clientIdEnd" maxlength="6"/>
                                  <b-input-group-text @click="showClientSearchModal(2);" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(2);" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                </b-input-group>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col lg="12" :class="classes">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-row>
                          </validation-provider>
                        </b-input-group>
                        <b-form-text class="text-muted">得意先の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 配送種別 -->
                    <b-col lg="6">
                      <b-form-group
                        label="配送種別"
                        label-for="shippingTypeClassStart"
                      >
                        <validation-provider name="shippingTypeClassStart" :rules="{consistency:searchConditions.shippingTypeClassEnd}" v-slot="{ classes,errors }">
                          <b-input-group :class="classes">
                            <b-form-input type="text" id="shippingTypeClassStart" name="shippingTypeClassStart" v-model.number="searchConditions.shippingTypeClassStart" maxlength="1" @blur="searchConditions.shippingTypeClassEnd = searchConditions.shippingTypeClassStart"/>
                            <b-input-group-text>～</b-input-group-text>
                            <b-form-input type="text" name="shippingTypeClassEnd" v-model.number="searchConditions.shippingTypeClassEnd" maxlength="1"/>
                          </b-input-group>
                          <b-input-group :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-input-group>
                        </validation-provider>
                        <b-form-text class="text-muted">配送種別の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- ルート -->
                    <b-col lg="6">
                      <b-form-group
                        label="ルート"
                        label-for="shippingCodeStart"
                      >
                        <validation-provider name="shippingCodeStart" :rules="{consistency:searchConditions.shippingCodeEnd}" v-slot="{ classes,errors }">
                          <b-input-group :class="classes">
                            <b-form-input type="text" id="shippingCodeStart" name="shippingCodeStart" v-model="searchConditions.shippingCodeStart" @blur="searchConditions.shippingCodeEnd = searchConditions.shippingCodeStart" />
                            <b-input-group-text>～</b-input-group-text>
                            <b-form-input type="text" name="shippingCodeEnd" v-model="searchConditions.shippingCodeEnd" />
                          </b-input-group>
                          <b-input-group :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-input-group>
                        </validation-provider>
                        <b-form-text class="text-muted">配送コードの範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form>
            </validation-observer>
          </b-card-body>
          <b-card-footer>
            <!-- 実行ボタン -->
            <b-row class="justify-content-md-center pb-4">
              <b-col lg="2">
                <b-button  class="mr-2" pill block variant="success" v-b-tooltip.hover.noninteractive title="指定された範囲の未確定の受注を確定します。" type="submit" form="InputForm">
                  <span class="oi oi-circle-check"></span> 実行
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <Footer />
  <!-- ●●●取引先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="1"/>
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, formatDate, addOperationLogs, executeSelectSql, CreateColRow, CreateInsertSql, setOfficeListOption, executeSqlList, isSystemEditable, getControlMaster } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'ships-confirm-auto';

export default {
  name: 'SHIPS-CONFIRM-AUTO',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    CONFIRM
  },
  /** データ */
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '自動出荷確定',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 検索項目
      searchConditions: {
        // 営業所プルダウン
        selectSalesOffice: null,
        salesOfficeOption: [],
        // 取引先コード（開始）
        clientIdStart: '',
        // 取引先コード（終了）
        clientIdEnd: '',
        // 配送コード（開始）
        shippingCodeStart: '',
        // 配送コード（終了）
        shippingCodeEnd: '',
        // 配送種別（開始）
        shippingTypeClassStart: '',
        // 配送種別（終了）
        shippingTypeClassEnd: '',
      },
      // 変更後引数
      changeSearchConditions: {
        clientIdStart: '',
        clientIdEnd: '',
        shippingCodeStart: '',
        shippingCodeEnd: '',
        shippingTypeClassStart: '',
        shippingTypeClassEnd: '',
      },
      // モーダルからの戻り値を反映先
      openModalIndex: 0,
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // 確認ダイアログ用
      confirmMessage: [],
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertSuccess.length > 0 || this.alertWarning.length > 0 || this.alertDanger.length > 0
    },
  },
  methods: {
    async fetchData(){
      const functionName = 'initData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
      
        // 営業所データ取得
        await setOfficeListOption()
        // 営業所プルダウン作成
        this.searchConditions.salesOfficeOption = this.$store.getters.officeOptionEscapeAll
        // 営業所データ初期値セット
        this.searchConditions.selectSalesOffice = user.office_id

        // 現在処理年月取得
        let controlData = await getControlMaster();
        this.controlMasterData.processMonthYear = controlData.process_month_year;
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.WARNING['3005']);
      }
      this.$store.commit('setLoading', false);
    },
    /* 取引先検索 */
    showClientSearchModal: function(index) {
      //console.log('取引先検索モーダル');
      this.openModalIndex = index;
      this.$bvModal.show('clientSearchModal');
    },
    /* 取引先検索モーダルを閉じた時 */
    closeClientSearchModal:function(clientItems){
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined'){
        if (this.openModalIndex == 1) {
          this.searchConditions.clientIdStart = clientItems.detail.client_id;
        } else {
          this.searchConditions.clientIdEnd = clientItems.detail.client_id;
        }
      }
    },
    /* 保存ボタンの押下 */
    async saveData() {
      //console.log(this.searchConditions);
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 確認modal */
    async confirmSave() {
      // 保存処理
      //console.log('自動出荷確定処理開始');
      // 受注データの登録(SQLの作成)
      await this.execSaveData();
      //console.log('自動出荷確定処理終了');
    },
    /* 保存実行 */
    async execSaveData() {
      const functionName = 'execSaveData';
      this.transactSqlList = [];
      try {
        // 範囲指定の文字列引数の変換（9を「ﾟ」に変換）
        this.changeSearchConditionsStrRange();
        // 保存対象受注データ取得
        let selectSql = '';
        selectSql = this.getSaveDataSql();
        let dataResult = await executeSelectSql(selectSql);
        //console.log(dataResult);
        if (dataResult != null && dataResult.length > 0) {
          // 出荷データの登録
          this.insertShips();
          //console.log(this.transactSqlList);

          // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
          try {
            const msg = await isSystemEditable();
            if (msg !== null) {
              this.alertDanger.push(msg);
              return false;
            }
          } catch (error) {
            await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
            this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
            return false;
          }

          await executeSqlList(this.transactSqlList);
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        } else {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%', '受注データ'));
        }
      } catch (error) {
        // 異常系操作ログの登録
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
      }
    },
    /* 範囲指定の文字列引数の変換（9を「ﾟ」に変換） */
    changeSearchConditionsStrRange: function() {
      // 得意先コード
      if (this.searchConditions.clientIdStart == '') {
        this.changeSearchConditions.clientIdStart = '000000';
      } else {
        this.changeSearchConditions.clientIdStart = this.searchConditions.clientIdStart;
      }
      if (this.searchConditions.clientIdEnd == '') {
        this.changeSearchConditions.clientIdEnd = '999999';
      } else {
        this.changeSearchConditions.clientIdEnd = this.searchConditions.clientIdEnd;
      }
      // ルート（配送コード）
      this.changeSearchConditions.shippingCodeStart = String(this.searchConditions.shippingCodeStart);
      if (this.changeSearchConditions.shippingCodeStart == '*') {
        this.changeSearchConditions.shippingCodeStart = '';
      }
      this.changeSearchConditions.shippingCodeEnd = String(this.searchConditions.shippingCodeEnd).replace(/9/g, Const.Char.halfMax);
      this.changeSearchConditions.shippingCodeEnd = this.changeSearchConditions.shippingCodeEnd.padEnd(5, Const.Char.halfMax);
      // 配送種別
      this.changeSearchConditions.shippingTypeClassStart = String(this.searchConditions.shippingTypeClassStart);
      if (this.changeSearchConditions.shippingTypeClassStart == '*') {
        this.changeSearchConditions.shippingTypeClassStart = '';
      }
      this.changeSearchConditions.shippingTypeClassEnd = String(this.searchConditions.shippingTypeClassEnd).replace(/9/g, Const.Char.halfMax);
      this.changeSearchConditions.shippingTypeClassEnd = this.changeSearchConditions.shippingTypeClassEnd.padEnd(1, Const.Char.halfMax);
    },
    /* 更新対象データSQL取得処理 */
    getSaveDataSql: function() {
      //console.log('受注データロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.order_receive_id';
      selectSql += ',orders_receives.order_receive_row';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN ' + this.createSaveQuerySql() + 'AS orders_receives_QUERY ';
      selectSql += 'ON orders_receives.order_receive_id = orders_receives_QUERY.order_receive_id ';
      selectSql += 'AND orders_receives.order_receive_row = orders_receives_QUERY.order_receive_row ';

      return selectSql;
    },
    /* 更新対象データFROMSQL作成処理 */
    createSaveQuerySql: function() {
      // 2つのSQLのUNION結果をQUERYとする
      // ・1：受注数が0以外（伝票発行未の出荷確定入力がある場合は除外）
      // ・2：受注数が0（出荷確定入力済みの場合は除外）
      let selectSql = '(';
      for (let i = 0; i < 2; i++) {
        if (i == 1) {
          selectSql += ' UNION ';
        }
        /* SELECT句 */
        selectSql += 'SELECT';
        selectSql += ' orders_receives.order_receive_id';
        selectSql += ',orders_receives.order_receive_row';
        /* FROM句 */
        selectSql += ' FROM ';
        selectSql += 't_orders_receives AS orders_receives ';
        selectSql += 'LEFT JOIN t_ships AS ships ';
        selectSql += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
        selectSql += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
        if (i == 0) {
          // 伝票発行済みは除外
          selectSql += 'AND ships.sales_issue_class = 0 ';
        }
        /* WHERE句 */
        selectSql += ' WHERE ';
        // 営業所コード
        selectSql += 'orders_receives.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
        // 取引先
        selectSql += 'AND orders_receives.client_class = 1 ';
        selectSql += 'AND orders_receives.client_id BETWEEN ' + this.changeSearchConditions.clientIdStart + ' AND ' + this.changeSearchConditions.clientIdEnd + ' ';
        // 配送種別
        selectSql += 'AND orders_receives.shipping_type_class BETWEEN \'' + this.changeSearchConditions.shippingTypeClassStart + '\' AND \'' + this.changeSearchConditions.shippingTypeClassEnd + '\' ';
        // ルート（配送コード）
        selectSql += 'AND orders_receives.shipping_code BETWEEN \'' + this.changeSearchConditions.shippingCodeStart + '\' AND \'' + this.changeSearchConditions.shippingCodeEnd + '\' ';
        // 削除されていない製品
        selectSql += 'AND orders_receives.is_deleted = 0 ';
        // 直送以外
        selectSql += 'AND orders_receives.shipping_type_class <> \'4\' ';
        // 処理年月よりも過去のデータは除外
        selectSql += 'AND orders_receives.order_receive_date >= \'' + formatDate(this.controlMasterData.processMonthYear + '01') + '\' ';
        // 出荷確定入力がされていない受注製品
        selectSql += 'AND ships.order_receive_id IS NULL ';
        if (i == 0) {
          // 受注数が0以外の製品
          // ・受注残が0以外（マイナスの返品含む）
          // ・引当数が0以外（引当済数、または、入荷予定引当数受注）
          selectSql += 'AND orders_receives.order_receive_quantity <> 0 ';
          selectSql += 'AND orders_receives.remaining_quantity <> 0 ';
          selectSql += 'AND (orders_receives.reserve_quantity <> 0 ';
          selectSql += 'OR orders_receives.stock_scheduled_reserve_receive - orders_receives.shipping_quantity > 0) ';
        } else {
          // 受注数が0の製品
          selectSql += 'AND orders_receives.order_receive_quantity = 0 ';
        }
      }
      selectSql += ')';
      return selectSql;
    },
    /* 出荷データ登録処理 */
    insertShips: function() {
      //console.log('出荷データ登録SQL作成');
      // CRUD処理
      let insertSqlStart = '';
      let colList = [];
      // 受注番号
      colList.push(CreateColRow('order_receive_id', null, 'NUMBER'));
      // 受注行番号
      colList.push(CreateColRow('order_receive_row', null, 'NUMBER'));
      // 出荷確定日
      colList.push(CreateColRow('shipping_confirm_date', null, 'DATE'));
      // 売上計上日
      colList.push(CreateColRow('sales_record_date', null, 'DATE'));
      // 営業所コード
      colList.push(CreateColRow('office_id', null, 'NUMBER'));
      // 配送種別区分
      colList.push(CreateColRow('shipping_type_class', null, 'VARCHAR'));
      // 配送コード
      colList.push(CreateColRow('shipping_code', null, 'VARCHAR'));
      // 配送順
      colList.push(CreateColRow('shipping_order_number', null, 'NUMBER'));
      // 取引先区分
      colList.push(CreateColRow('client_class', null, 'NUMBER'));
      // 取引先コード
      colList.push(CreateColRow('client_id', null, 'NUMBER'));
      // 現場コード
      colList.push(CreateColRow('site_id', null, 'NUMBER'));
      // 製品コード
      colList.push(CreateColRow('product_id', null, 'NUMBER'));
      // 出荷数
      colList.push(CreateColRow('shipping_quantity', null, 'NUMBER'));
      // 受注数
      colList.push(CreateColRow('order_receive_quantity', null, 'NUMBER'));
      // 引当数
      colList.push(CreateColRow('reserve_quantity', null, 'NUMBER'));
      // 売上伝票発行区分
      colList.push(CreateColRow('sales_issue_class', null, 'NUMBER'));
      // 売上伝票番号
      colList.push(CreateColRow('sales_number', null, 'NUMBER'));
      // 売上伝票行No
      colList.push(CreateColRow('sales_number_row', null, 'NUMBER'));
      // 在庫置き場所1
      colList.push(CreateColRow('place_1', null, 'VARCHAR'));
      // 在庫置き場所2
      colList.push(CreateColRow('place_2', null, 'VARCHAR'));
      // 在庫置き場所3
      colList.push(CreateColRow('place_3', null, 'VARCHAR'));
      // 在庫置き場所4
      colList.push(CreateColRow('place_4', null, 'VARCHAR'));
      // 明細単位区分
      colList.push(CreateColRow('details_unit_class', null, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      insertSqlStart = 'INSERT INTO t_ships (' + CreateInsertSql(colList, 'col', 't_ships') + ') ';
      //console.log(insertSqlStart);
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      // 受注番号
      selectSql += ' orders_receives.order_receive_id';
      // 受注行番号
      selectSql += ',orders_receives.order_receive_row';
      // 出荷確定日
      selectSql += ',CURDATE()';
      // 売上計上日
      selectSql += ',orders_receives.order_receive_date';
      // 営業所コード
      selectSql += ',orders_receives.office_id';
      // 配送種別区分
      selectSql += ',orders_receives.shipping_type_class';
      // 配送コード
      selectSql += ',orders_receives.shipping_code';
      // 配送順
      selectSql += ',1';
      // 取引先区分
      selectSql += ',orders_receives.client_class';
      // 取引先コード
      selectSql += ',orders_receives.client_id';
      // 現場コード
      selectSql += ',orders_receives.site_id'
      // 製品コード
      selectSql += ',orders_receives.product_id';
      // 出荷数
      selectSql += ',CASE WHEN orders_receives.reserve_quantity <> 0 THEN orders_receives.reserve_quantity ELSE orders_receives.stock_scheduled_reserve_receive - orders_receives.shipping_quantity END';
      // 受注数
      selectSql += ',orders_receives.remaining_quantity';
      // 引当数
      selectSql += ',CASE WHEN orders_receives.reserve_quantity <> 0 THEN orders_receives.reserve_quantity ELSE orders_receives.stock_scheduled_reserve_receive - orders_receives.shipping_quantity END';
      // 売上伝票発行区分
      selectSql += ',0';
      // 売上伝票番号
      selectSql += ',0';
      // 売上伝票行No
      selectSql += ',0';
      // 在庫置き場所1
      selectSql += ',orders_receives.place_1';
      // 在庫置き場所2
      selectSql += ',orders_receives.place_2';
      // 在庫置き場所3
      selectSql += ',orders_receives.place_3';
      // 在庫置き場所4
      selectSql += ',orders_receives.place_4';
      // 明細単位区分
      selectSql += ',' + Const.DetailsUnitClass.auto;
      // 作成ユーザー
      selectSql += ',\'' + this.loginId + '\'';
      // 更新ユーザー
      selectSql += ',\'' + this.loginId + '\'';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN ' + this.createSaveQuerySql() + 'AS orders_receives_QUERY ';
      selectSql += 'ON orders_receives.order_receive_id = orders_receives_QUERY.order_receive_id ';
      selectSql += 'AND orders_receives.order_receive_row = orders_receives_QUERY.order_receive_row ';
      //console.log(selectSql);
      this.transactSqlList.push({sql: insertSqlStart + selectSql, forUpdateFlg: 0});
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で実行します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
  }
}
</script>
<!-- ●●●画面固有●●● -->
<style scoped>
</style>