<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="mb-2">
            <b-card>
              <div class="mb-0 p-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                    <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive title="入荷確定画面への遷移" @click="clickStockConfirmBtn" class="mx-2 float-right" style="font-size: 60%;">
                      <span class="oi oi-plus"></span> 入荷確定(F10)
                      <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                    </b-button>
                    <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                     <span class="oi oi-print"></span>
                    </b-button>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-1" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-1" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-0">
                  <validation-observer ref="observer">
                    <b-form @submit.prevent="clearAlert(); searchButton();" id="orderStockListForm" class="form-horizontal">
                      <b-container fluid>
                        <!-- ●●●検索条件●●● -->
                        <b-row class="mt-1">
                          <!-- 営業所入力欄 -->
                          <b-col lg="3">
                            <b-form-group
                              label="営業所"
                              lable-for="selectSalesOffice"
                            >
                              <b-form-select
                                id="selectSalesOffice"
                                v-model="searchConditions.selectSalesOffice"
                                :options="searchConditions.salesOffice"
                                value-field="id"
                                text-field="name"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <!-- 注文番号入力欄 -->
                          <b-col lg="3">
                            <validation-provider name="orderNumber" rules="numeric" v-slot="{ classes,errors }">
                              <label for="orderNumber">注文番号<b-form-text class="text-muted d-inline-flex">(完全一致検索)</b-form-text></label>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input
                                  type="text"
                                  id="orderNumber"
                                  name="orderNumber"
                                  v-model="searchConditions.orderNumber"
                                  v-shortkey="['alt', '3']"
                                  @shortkey="setFocus('orderNumber')"
                                  v-b-tooltip.hover.noninteractive
                                  title="「ALT+3」ボタンで移動可能">
                                </b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </validation-provider>
                          </b-col>
                          <!-- 入荷予定日入力欄 -->
                          <b-col lg="6">
                            <validation-provider name="stockScheduledStart" :rules="{dateConsistency:searchConditions.stockScheduledEnd}" v-slot="{ classes,errors }">
                              <b-form-group
                                label="入荷予定日"
                                label-for="stockScheduledStart"
                              >
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <b-input-group class="input-daterange" id="datepicker">
                                    <b-form-datepicker id="stockScheduledStart" name="stockScheduledStart" class="form-control" v-model="searchConditions.stockScheduledStart"
                                      :hide-header = true
                                    ></b-form-datepicker>
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.stockScheduledStart=''">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                    <b-input-group-text>～</b-input-group-text>
                                    <b-form-datepicker id="stockScheduledEnd" name="stockScheduledEnd" class="form-control" v-model="searchConditions.stockScheduledEnd"
                                      :hide-header = true
                                    ></b-form-datepicker>
                                    <b-input-group-append>
                                      <b-button size="sm" variant="outline-secondary" @click="searchConditions.stockScheduledEnd=''">
                                        <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                </b-col>
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row class="mt-1">
                          <!-- 取引先コード入力欄 -->
                          <b-col lg="3">
                            <validation-provider name="customerCode" rules="numeric" v-slot="{ classes,errors }">
                              <label for="customerCode">取引先コード<b-form-text class="text-muted d-inline-flex">(完全一致検索)</b-form-text></label>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group>
                                  <b-form-input type="text" id="customerCode" name="customerCode" v-model="searchConditions.clientCode"></b-form-input>
                                  <b-input-group-text @click="showClientSearchModal(1);" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(1);" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                </b-input-group>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </validation-provider>
                          </b-col>
                          <!-- 取引先名入力欄 -->
                          <b-col lg="9">
                            <label for="customerName">取引先名<b-form-text class="text-muted d-inline-flex">(部分一致検索です。登録済みの取引先名（漢字）または取引先名（カナ）と一致するデータを検索します)</b-form-text></label>
                            <b-input-group>
                              <b-form-input type="text" id="customerName" name="customerName" v-model="searchConditions.clientName"></b-form-input>
                              <b-input-group-text @click="showClientSearchModal(2);" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(2);" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                            </b-input-group>
                          </b-col>
                        </b-row>
                        <b-row class="mt-1">
                          <!-- 製品コード入力欄 -->
                          <b-col lg="3">
                            <validation-provider name="productCode" rules="numeric" v-slot="{ classes,errors }">
                              <label for="productCode">製品コード<b-form-text class="text-muted d-inline-flex">(前方一致検索)</b-form-text></label>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="productCode" name="productCode" v-model="searchConditions.productCode"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </validation-provider>
                          </b-col>
                          <!-- 製品名入力欄 -->
                          <b-col lg="9">
                            <label for="productName">製品名<b-form-text class="text-muted d-inline-flex">(部分一致検索です。登録済みの製品名（漢字）と一致するデータを検索します)</b-form-text></label>
                            <b-form-input type="text" id="productName" name="productName" v-model="searchConditions.productName"></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row class="mt-1">
                          <!-- 未入荷チェック入力欄 -->
                          <b-col lg="2">
                            <label for="isNoStock" v-b-tooltip.hover.noninteractive  title='チェックONの場合、入荷予定日が過ぎていて入荷入力されていない発注データのみが表示されます'>未入荷<span class="px-1 oi oi-flag"/></label>
                            <b-form-checkbox
                              id="isNoStock"
                              name="isNoStock"
                              v-model="searchConditions.isNoStock"
                            ></b-form-checkbox>
                          </b-col>
                          <!-- 入力区分 -->
                          <b-col lg="4">
                            <label v-b-tooltip.hover.noninteractive  :title="getStockInputClassMessageList">入力区分<span class="px-1 oi oi-flag"/></label>
                            <b-form-radio-group
                              id="selectStockInputClass"
                              v-model="searchConditions.selectStockInputClass"
                              :options="searchConditions.stockInputClassList"
                              value-field="id"
                            ></b-form-radio-group>
                          </b-col>
                          <!-- 入荷予定日確定フラグ -->
                          <b-col lg="4">
                            <label v-b-tooltip.hover.noninteractive  :title="getStockScheduledConfirmClassList">入荷予定日確定フラグ<span class="px-1 oi oi-flag"/></label>
                            <b-form-radio-group
                              id="selectStockScheduledConfirmClass"
                              v-model="searchConditions.selectStockScheduledConfirmClass"
                              :options="searchConditions.stockScheduledConfirmClassList"
                              value-field="id"
                            ></b-form-radio-group>
                          </b-col>
                        </b-row>
                        <b-row class="mt-1" v-if="searchConditions.selectStockInputClass == htmlConst.StockInputClass.confirm">
                          <!-- 入荷確定日入力欄 -->
                          <b-col lg="6">
                            <validation-provider name="stockConfirmDateStart" :rules="{dateConsistency:searchConditions.stockConfirmDateEnd}" v-slot="{ classes,errors }">
                              <label for="stockConfirmDateStart" v-b-tooltip.hover.noninteractive title="パフォーマンスの関係で過去の全データを表示することは不可能です。日付の範囲は1か月以内としてください。または、注文番号を指定してください。">入荷確定日<span class="px-1 oi oi-flag"/></label>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group class="input-daterange" id="datepicker">
                                  <b-form-datepicker id="stockConfirmDateStart" name="stockConfirmDateStart" class="form-control" v-model="searchConditions.stockConfirmDateStart"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.stockConfirmDateStart=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                  <b-input-group-text>～</b-input-group-text>
                                  <b-form-datepicker id="stockConfirmDateEnd" name="stockConfirmDateEnd" class="form-control" v-model="searchConditions.stockConfirmDateEnd"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.stockConfirmDateEnd=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <!-- 検索ボタン -->
                        <b-row class="justify-content-md-center mt-2">
                          <b-col lg="3">
                            <b-button block pill variant="success" type="submit" form="orderStockListForm">
                              <span class="oi oi-magnifying-glass"></span> 検 索 
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-form>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-0" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-0">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="mb-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="mb-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :small="true"
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <!-- 注文番号 -->
              <template #cell(OrderNumber)="data">
                {{ data.item.OrderNumber }}
                <!-- 入荷入力修正ボタン -->
                <b-button v-show="!printStatus" size="sm" v-b-tooltip.hover.noninteractive.left="'指定された注文番号別に' + (data.item.StockInputClass == htmlConst.StockInputClass.new ? '入荷入力' : '入荷修正') + 'を行います。'" @click="clickEditBtn(data.item.StockInputClass, data.item.OfficeId, data.item.OrderNumber, 0, 0)" v-if="data.item.StockInputClass != htmlConst.StockInputClass.confirm" class="mr-1">
                  <span class="oi oi-pencil"></span>
                </b-button>
                <!-- コピー仕入入力ボタン -->
                <b-button v-show="!printStatus" size="sm" v-b-tooltip.hover.noninteractive.left="'コピーして仕入伝票を新規登録します。'" @click="clickPurchaseCopy(data.item.OrderNumber, data.item.CustomerCode)" v-if="data.item.StockInputClass == htmlConst.StockInputClass.confirm">
                  <span class="oi oi-tag"></span>
                </b-button>
              </template>
              <!-- 取引先コード -->
              <template #cell(CustomerCode)="data">
                {{ data.item.CustomerCode }}
                <!-- 入荷入力修正ボタン -->
                <b-button v-show="!printStatus" size="sm" v-b-tooltip.hover.noninteractive.left="'指定された取引先別に' + (data.item.StockInputClass == htmlConst.StockInputClass.new ? '入荷入力' : '入荷修正') + 'を行います。'" @click="clickEditBtn(data.item.StockInputClass, data.item.OfficeId, 0, data.item.CustomerCode, 0)" v-if="data.item.StockInputClass != htmlConst.StockInputClass.confirm">
                  <span class="oi oi-pencil"></span>
                </b-button>
              </template>
              <!-- 製品コード -->
              <template #cell(ProductCode)="data">
                {{ data.item.ProductCode }}
                <!-- 入荷入力修正ボタン（注文番号別） -->
                <b-button v-show="!printStatus" size="sm" v-b-tooltip.hover.noninteractive.left="'指定された注文番号、製品別に' + (data.item.StockInputClass == htmlConst.StockInputClass.new ? '入荷入力' : '入荷修正') + 'を行います。'" @click="clickEditBtn(data.item.StockInputClass, data.item.OfficeId, data.item.OrderNumber, 0, data.item.ProductCode)" v-if="data.item.StockInputClass != htmlConst.StockInputClass.confirm" class="mr-1">
                  <span class="oi oi-pencil"></span>
                </b-button>
                <!-- 入荷入力修正ボタン（取引先別） -->
                <b-button v-show="!printStatus" size="sm" v-b-tooltip.hover.noninteractive.left="'指定された取引先、製品別に' + (data.item.StockInputClass == htmlConst.StockInputClass.new ? '入荷入力' : '入荷修正') + 'を行います。'" @click="clickEditBtn(data.item.StockInputClass, data.item.OfficeId, 0, data.item.CustomerCode, data.item.ProductCode)" v-if="data.item.StockInputClass != htmlConst.StockInputClass.confirm">
                  <span class="oi oi-pencil"></span>
                </b-button>
              </template>
              <template #cell(CustomerName)="data">
                <div style="min-width:200px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.CustomerName }}
                    </span>
                  </div>
                </div>
              </template>
              <template #cell(ProductName)="data">
                <div style="min-width:200px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.ProductName }}
                    </span>
                  </div>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(Operation)="data">
                <div v-show="!printStatus">
                  <!-- 削除ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'発注の削除を行います。'" @click="clearAlert(); clickOrderDelete(data.item.OrderId, data.item.OrderRow)" v-if="data.item.OrderClass==htmlConst.OrderClass.noStock||data.item.OrderClass==htmlConst.OrderClass.input">
                    <span class="oi oi-delete"></span> 削除
                  </b-button>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="mb-1">
            <b-pagination
              v-model="currentPage"
              size="sm"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●得意先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class='htmlConst.ClientClass.supplier'/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { init, windowPrint, addOperationLogs, convertSqlLikeSpecialChar, formatDate, formatCurDate, formatDateCalc, getListValue, executeSelectSql, CreateColRow, CreateUpdateSql, isSystemEditable, isAfterMonthlyUpdate, getControlMaster, executeSqlList, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'order-stock-list';

export default {
  name: 'ORDER-STOCK-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    CONFIRM,
  },
  props: ['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '入荷一覧',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions:{
        selectSalesOffice: '',
        salesOffice: [],
        orderNumber: '',
        clientCode: '',
        clientName: '',
        stockScheduledStart: '',
        stockScheduledEnd: '',
        productCode: '',
        productName: '',
        selectStockInputClass: Const.StockInputClass.new,
        stockInputClassList: Const.StockInputClassList,
        isNoStock: false,
        selectStockScheduledConfirmClass: Const.stockScheduledConfirmInputClass.all,
        stockScheduledConfirmClassList: Const.stockScheduledConfirmClassList,
        stockConfirmDateStart: '',
        stockConfirmDateEnd: '',
      },
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // テーブル構成
      fields: [],
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // モーダルからの戻り値を反映先
      openModalIndex: 0,
      // 定数（htmlで使用）
      htmlConst: {
        // 入荷入力区分
        StockInputClass: {
          // 全て
          all: Const.StockInputClass.all,
          // 新規
          new: Const.StockInputClass.new,
          // 訂正
          edit: Const.StockInputClass.edit,
          // 確定参照
          confirm: Const.StockInputClass.confirm,
        },
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
        // 発注種別
        OrderClass: {
          // 受注発注
          orderReceive: Const.OrderClass.orderReceive,
          // 在庫切れ
          noStock: Const.OrderClass.noStock,
          // 直送発注
          direct: Const.OrderClass.direct,
          // 発注入力
          input: Const.OrderClass.input,
          // 特別発注
          special: Const.OrderClass.special,
        },
      },
      // プリントアウト状態
      printStatus: false,
      // 確認ダイアログ用
      confirmMessage: [],
      // 削除対象発注番号
      delOrderId: null,
      delOrderRow: null,
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    // 入力区分の補足メッセージ返却
    getStockInputClassMessageList: function() {
      let messageList = [];
      messageList.push(getListValue(Const.StockInputClassList, Const.StockInputClass.new) + '：発注書発行済み、且つ、入荷予定数の残件があって、入荷入力されていないデータのみが表示されます。 ');
      messageList.push(getListValue(Const.StockInputClassList, Const.StockInputClass.edit) + '：入荷入力されたが、入荷確定入力されていないデータのみが表示されます。 ');
      messageList.push(getListValue(Const.StockInputClassList, Const.StockInputClass.confirm) + '：入荷入力済みのデータを表示します。編集はできませんが、仕入伝票へのコピーが可能です。');
      return messageList;
    },
    // 入荷予定日確定区分の補足メッセージ返却
    getStockScheduledConfirmClassList: function() {
      let messageList = [];
      messageList.push(getListValue(Const.stockScheduledConfirmClassList, Const.stockScheduledConfirmInputClass.all) + '：全てのデータが表示されます。 ');
      messageList.push(getListValue(Const.stockScheduledConfirmClassList, Const.stockScheduledConfirmInputClass.mi) + '：入荷予定日が確定していないデータのみ表示されます。 ');
      messageList.push(getListValue(Const.stockScheduledConfirmClassList, Const.stockScheduledConfirmInputClass.sumi) + '：入荷予定日確定済みのデータのみ表示されます。');
      return messageList;
    },
  },
  /* 関数群 */
  methods: {
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
        ]);
        let loginOfficeId = staffListData[0].office_id;
        //console.log(staffListData);
        //console.log(officeListData);
        // default値挿入
        this.searchConditions.selectSalesOffice = 0;
        // 営業所プルダウン作成
        this.searchConditions.salesOffice.push({id: 0, name: '全て'});
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            name: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji
          };
          this.searchConditions.salesOffice.push(office);
          // default値挿入
          if (loginOfficeId == officeListData[i].office_id) {
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
        // 入荷確定日に１か月前～現在日を設定
        this.searchConditions.stockConfirmDateEnd = formatCurDate('YYYY-MM-DD');
        this.searchConditions.stockConfirmDateStart = formatDateCalc(this.searchConditions.stockConfirmDateEnd, 0, -1, 0, false, 'YYYY-MM-DD');
        // 初期検索
        //await this.search();
        // パラメータによってメッセージを表示
        if(this.parentKbn == 1){
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      // 注文番号にフォーカスを移動
      document.getElementById('orderNumber').focus();
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      if (this.busy == true) {
        // 連続して押下された場合は処理を行わない
        return;
      }
      this.itemList = [];
      // ページング機能の初期化
      this.initPaging();
      this.busy = true;
      try {
        // テーブル構成変更
        this.fields = this.getFields();
        // CRUD処理
        let selectSql = '';
        selectSql = this.makeSelectSql();
        //console.log(selectSql);
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        //console.log(resultData);
        if (resultData != null) {
          await this.setResult(resultData);
          if (resultData.length >= 1000) {
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.totalRows = 0;
      }
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result) {
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          StockScheduled: formatDate(result[i].stock_scheduled) + (result[i].stock_scheduled_confirm_class == 1 ? ' *' : ''),
          OrderNumber: result[i].order_number,
          OrderDate: formatDate(result[i].order_date),
          OfficeId: result[i].office_id,
          CustomerCode: result[i].client_id,
          CustomerName: result[i].client_name_kanji,
          ProductCode: result[i].product_id,
          ProductName: result[i].product_name,
          StockScheduledQuantity: result[i].stock_scheduled_quantity,
          StockQuantity: result[i].stock_quantity,
          StockInputClass: result[i].stock_input_class,
          StockInputClassText: getListValue(Const.StockInputClassList ,result[i].stock_input_class, 'id', 'text'),
          StockConfirmDate: formatDate(result[i].stock_confirm_date),
          OrderId: result[i].order_id,
          OrderRow: result[i].order_row,
          OrderClass: result[i].order_class,
        };
        this.itemList.push(searchResult);
      }
    },
    /* SELECT文字列作成 */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders.order_number';
      selectSql += ',orders.stock_scheduled';
      selectSql += ',orders.order_date';
      selectSql += ',orders.office_id';
      selectSql += ',orders.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',orders.product_id';
      selectSql += ',orders.product_name';
      selectSql += ',orders.remaining_quantity AS stock_scheduled_quantity';
      selectSql += ',IfNull(stocks.stock_quantity,0) AS stock_quantity';
      selectSql += ',(CASE WHEN stocks.stock_confirm_class IS NULL THEN ' + Const.StockInputClass.new + ' WHEN stocks.stock_confirm_class = ' + Const.StockConfirmClass.mi + ' THEN ' + Const.StockInputClass.edit + ' ELSE ' + Const.StockInputClass.confirm + ' END) AS stock_input_class';
      selectSql += ',orders.stock_scheduled_confirm_class';
      selectSql += ',stocks.stock_confirm_date';
      selectSql += ',orders.order_id';
      selectSql += ',orders.order_row';
      selectSql += ',orders.order_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON orders.client_class = clients.client_class ';
      selectSql += 'AND orders.client_id = clients.client_id ';
      if (this.searchConditions.selectStockInputClass == Const.StockInputClass.new) {
        selectSql += 'LEFT JOIN t_stocks AS stocks ';
      } else {
        selectSql += 'INNER JOIN t_stocks AS stocks ';
      }
      selectSql += 'ON orders.order_id = stocks.order_id ';
      selectSql += 'AND orders.order_row = stocks.order_row ';
      if (this.searchConditions.selectStockInputClass == Const.StockInputClass.new) {
        selectSql += 'AND stocks.stock_confirm_class <> ' + Const.StockConfirmClass.sumi + ' ';
      }
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 削除済フラグ
      selectSql += ' orders.is_deleted = 0 ';
      // 発注書発行区分
      selectSql += 'AND orders.order_issue_class > ' + Const.OrderIssueClass.mi + ' ';
      // 発注種別（直送除外）
      selectSql += 'AND orders.order_class <> ' + Const.OrderClass.direct + ' ';
      // 未入荷チェック
      if (this.searchConditions.isNoStock == true) {
        // 入荷入力が未、且つ、入荷予定日が当日よりも過去
        selectSql += 'AND stocks.order_id IS NULL ';
        selectSql += 'AND orders.stock_scheduled < \'' + formatCurDate() + '\'';
      }
      // 入荷確定区分
      if (this.searchConditions.selectStockInputClass == Const.StockInputClass.new) {
        // 「1：新規」（入荷データ未登録）
        selectSql += 'AND stocks.order_id IS NULL ';
        // 発注数が入荷確定数よりも大きい場合（入荷予定数が残っている）
        selectSql += 'AND orders.remaining_quantity > 0 ';
      } else if (this.searchConditions.selectStockInputClass == Const.StockInputClass.edit) {
        // 「2：訂正」（入荷データ登録、且つ、入荷確定入力未）
        selectSql += 'AND stocks.stock_confirm_class = ' + Const.StockConfirmClass.mi + ' ';
      } else if (this.searchConditions.selectStockInputClass == Const.StockInputClass.confirm){
        // 「3：確定参照」（入荷確定入力済）
        selectSql += 'AND stocks.stock_confirm_class = ' + Const.StockConfirmClass.sumi + ' ';
        // 入荷確定日
        if (this.searchConditions.stockConfirmDateStart != '' && this.searchConditions.stockConfirmDateEnd == '') {
          selectSql += 'AND stocks.stock_confirm_date >= ' + '\'' + this.searchConditions.stockConfirmDateStart + '\' ';
        } else if (this.searchConditions.stockConfirmDateStart == '' && this.searchConditions.stockConfirmDateEnd != '') {
          selectSql += 'AND stocks.stock_confirm_date <= ' + '\'' + this.searchConditions.stockConfirmDateEnd + '\' ';
        } else if (this.searchConditions.stockConfirmDateStart != '' && this.searchConditions.stockConfirmDateEnd != '') {
          selectSql += 'AND stocks.stock_confirm_date BETWEEN ' + '\'' + this.searchConditions.stockConfirmDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.stockConfirmDateEnd + '\' ';
        }
      }
      // 入荷予定日確定区分
      // 「1：全て」の場合は検索条件に追加しない
      if (this.searchConditions.selectStockScheduledConfirmClass == Const.stockScheduledConfirmInputClass.mi) {
        // 「2：未」
        selectSql += 'AND orders.stock_scheduled_confirm_class = ' + Const.stockScheduledConfirmClass.mi + ' ';
      } else if (this.searchConditions.selectStockScheduledConfirmClass == Const.stockScheduledConfirmInputClass.sumi){
        // 「3：済」
        selectSql += 'AND orders.stock_scheduled_confirm_class = ' + Const.stockScheduledConfirmClass.sumi + ' ';
      }
      // 営業所コード
      if (this.searchConditions.selectSalesOffice != 0) {
        selectSql += 'AND orders.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
        if (this.searchConditions.selectStockInputClass == Const.StockInputClass.edit ||
        this.searchConditions.selectStockInputClass == Const.StockInputClass.confirm) {
          selectSql += 'AND stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
        }
      }
      // 注文番号
      if (this.searchConditions.orderNumber != '') {
        selectSql += 'AND orders.order_number = ' + this.searchConditions.orderNumber + ' ';
      }
      // 入荷予定日
      if (this.searchConditions.stockScheduledStart != '' && this.searchConditions.stockScheduledEnd == '') {
        selectSql += 'AND orders.stock_scheduled >= ' + '\'' + this.searchConditions.stockScheduledStart + '\' ';
      } else if (this.searchConditions.stockScheduledStart == '' && this.searchConditions.stockScheduledEnd != '') {
        selectSql += 'AND orders.stock_scheduled <= ' + '\'' + this.searchConditions.stockScheduledEnd + '\' ';
      } else if (this.searchConditions.stockScheduledStart != '' && this.searchConditions.stockScheduledEnd != '') {
        selectSql += 'AND orders.stock_scheduled BETWEEN ' + '\'' + this.searchConditions.stockScheduledStart + '\' ' + 'AND ' + '\'' + this.searchConditions.stockScheduledEnd + '\' ';
      }
      // 取引先コード
      if (this.searchConditions.clientCode != '') {
        selectSql += 'AND orders.client_id = ' + this.searchConditions.clientCode + ' ';
      }
      // 取引先名(あいまい検索対象)
      if (this.searchConditions.clientName != '') {
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        selectSql += `AND (REPLACE(REPLACE(CONVERT(clients.client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
        selectSql += `OR REPLACE(REPLACE(CONVERT(clients.client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      // 製品コード
      if (this.searchConditions.productCode != '') {
        selectSql += 'AND orders.product_id LIKE \'' + this.searchConditions.productCode + '%\' ';
      }
      // 製品名(あいまい検索対象)
      if (this.searchConditions.productName != '') {
        const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
        selectSql += `AND REPLACE(REPLACE(CONVERT(orders.product_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' orders.order_number';
      selectSql += ',orders.client_id';
      selectSql += ',orders.product_id';

      /* LIMIT */
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    /* 入荷入力修正ボタン押下時 */
    async clickEditBtn(stockInputClass, officeId, orderNumber, clientId, productId) {
      if (stockInputClass == Const.StockInputClass.new) {
        //console.log('入荷入力');
        let route = this.$router.resolve({ name: 'ORDER-STOCK-INPUT', query: { officeId: officeId, orderNumber: orderNumber, clientId: clientId, productId: productId } });
        window.open(route.href, '_blank');
      } else {
        //console.log('入荷修正');
        let route = this.$router.resolve({ name: 'ORDER-STOCK-EDIT', query: { officeId: officeId, orderNumber: orderNumber, clientId: clientId, productId: productId } });
        window.open(route.href, '_blank');
      }
      //console.log(this.$router);
    },
    /* コピー仕入入力ボタン押下時 */
    async clickPurchaseCopy(orderNumber, clientId) {
      //console.log('コピー仕入入力');
      // 仕入入力画面へ遷移
      //console.log(orderNumber);
      let route = this.$router.resolve({ name: 'PURCHASE-INPUT', query: { propOrderNumber: orderNumber, propClientId: clientId, propDirectFlg: 0 } });
      window.open(route.href, '_blank');
    },
    /* 入荷確定画面ボタン押下時 */
    async clickStockConfirmBtn() {
      window.open(this.$router.resolve({ name: 'ORDER-STOCK-CONFIRM-INPUT' }).href, '_blank');
    },
    /* 入荷確定画面ボタン（F10）押下時 */
    shortkey() {
      this.clickStockConfirmBtn()
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    /* フィールド取得 */
    getFields: function() {
      if (this.searchConditions.selectStockInputClass == Const.StockInputClass.confirm) {
        return [
          {
            key: 'StockScheduled',
            label: '入荷予定日',
            sortable: true,
          },
          {
            key: 'OrderDate',
            label: '発注日',
            sortable: true,
          },
          {
            key: 'OrderNumber',
            label: '注文番号',
            sortable: true,
          },
          {
            key: 'CustomerCode',
            label: '取引先コード',
            sortable: true,
          },
          {
            key: 'CustomerName',
            label: '取引先名',
            sortable: true,
          },
          {
            key: 'ProductCode',
            label: '製品コード',
            sortable: true,
          },
          {
            key: 'ProductName',
            label: '製品名',
            sortable: true,
          },
          {
            key: 'StockScheduledQuantity',
            label: '入荷予定数',
            sortable: true,
          },
          {
            key: 'StockQuantity',
            label: '入荷数',
            sortable: true,
          },
          {
            key: 'StockInputClassText',
            label: '入力区分',
            sortable: true,
          },
          {
            key: 'StockConfirmDate',
            label: '入荷確定日',
            sortable: true,
          },
        ];
      } else if (this.searchConditions.selectStockInputClass == Const.StockInputClass.edit) {
        return [
          {
            key: 'StockScheduled',
            label: '入荷予定日',
            sortable: true,
          },
          {
            key: 'OrderDate',
            label: '発注日',
            sortable: true,
          },
          {
            key: 'OrderNumber',
            label: '注文番号',
            sortable: true,
          },
          {
            key: 'CustomerCode',
            label: '取引先コード',
            sortable: true,
          },
          {
            key: 'CustomerName',
            label: '取引先名',
            sortable: true,
          },
          {
            key: 'ProductCode',
            label: '製品コード',
            sortable: true,
          },
          {
            key: 'ProductName',
            label: '製品名',
            sortable: true,
          },
          {
            key: 'StockScheduledQuantity',
            label: '入荷予定数',
            sortable: true,
          },
          {
            key: 'StockQuantity',
            label: '入荷数',
            sortable: true,
          },
          {
            key: 'StockInputClassText',
            label: '入力区分',
            sortable: true,
          },
        ];
      } else {
        return [
          {
            key: 'StockScheduled',
            label: '入荷予定日',
            sortable: true,
          },
          {
            key: 'OrderDate',
            label: '発注日',
            sortable: true,
          },
          {
            key: 'OrderNumber',
            label: '注文番号',
            sortable: true,
          },
          {
            key: 'CustomerCode',
            label: '取引先コード',
            sortable: true,
          },
          {
            key: 'CustomerName',
            label: '取引先名',
            sortable: true,
          },
          {
            key: 'ProductCode',
            label: '製品コード',
            sortable: true,
          },
          {
            key: 'ProductName',
            label: '製品名',
            sortable: true,
          },
          {
            key: 'StockScheduledQuantity',
            label: '入荷予定数',
            sortable: true,
          },
          {
            key: 'StockQuantity',
            label: '入荷数',
            sortable: true,
          },
          {
            key: 'StockInputClassText',
            label: '入力区分',
            sortable: true,
          },
          {
            key: 'Operation',
            label: '操作',
          },
        ];
      }
    },
    //「取引先検索」アイコン押下処理
    showClientSearchModal: function(index) {
      //console.log('取引先検索モーダル');
      this.openModalIndex = index;
      this.$bvModal.show('clientSearchModal');
    },
    //「取引先検索」モーダルのクローズ押下処理
    closeClientSearchModal: function(clientItems) {
      // モーダルから渡された値の有無チェック
      if (typeof clientItems != 'undefined') {
        if (this.openModalIndex == 1) {
          this.searchConditions.clientCode = clientItems.detail.client_id;
        } else {
          this.searchConditions.clientName = clientItems.detail.client_name_kanji;
        }
      }
    },
    // 発注削除ボタン押下時
    async clickOrderDelete(orderId, orderRow) {
      //console.log('発注削除');
      this.delOrderId = orderId;
      this.delOrderRow = orderRow;
      this.confirmMessage = [];
      this.confirmMessage.push('選択された発注データを削除します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    // 確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if(typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          // 発注削除
          await this.execOrderDelete();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 発注削除実行
    async execOrderDelete() {
      const functionName = 'execOrderDelete';
      if (await this.prepareOrderDelete() == false) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2046'].replace('%arg1%','発注').replace('%arg2%','削除'));
        return;
      }
      // 実行するSQL保持
      let sqlList = [];
      /* 在庫マスタ更新 */
      this.updateStocksMaster(sqlList);
      /* 発注データ更新 */
      // 数量を0にして削除フラグを立てる
      let updateSql = '';
      let colList = [];
      // 発注数
      colList.push(CreateColRow('order_quantity', 0, 'NUMBER'));
      // 発注金額
      colList.push(CreateColRow('order_amount', 0, 'NUMBER'));
      // 発注残数
      colList.push(CreateColRow('remaining_quantity', 0, 'NUMBER'));
      // 削除済フラグ
      colList.push(CreateColRow('is_deleted', 1, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      updateSql += CreateUpdateSql(colList, 't_orders');
      updateSql += ' WHERE ';
      updateSql += 'order_id = ' + this.delOrderId + ' ';
      updateSql += 'AND order_row = ' + this.delOrderRow + ' ';
      updateSql += 'AND is_deleted = 0 ';
      //console.log(updateSql);
      sqlList.push({sql: updateSql, forUpdateFlg: 0});

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      // 月次更新後チェック
      if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
        return;
      }
      try {
        await executeSqlList(sqlList);
        // 発注一覧画面へ遷移
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } catch(error) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    // 在庫マスタ更新SQL作成
    updateStocksMaster: function(sqlList) {
      //console.log('在庫マスタ更新SQL作成');
      let updateSql = '';
      let colList = [];
      // 入荷予定数
      colList.push(CreateColRow('stocks.quantity_stock', 'stocks.quantity_stock - orders_QUERY.remaining_quantity', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' orders.office_id';
      updateQuery += ',orders.product_id';
      updateQuery += ',orders.remaining_quantity';
      updateQuery += ' FROM ';
      updateQuery += 't_orders AS orders ';
      updateQuery += ' WHERE ';
      updateQuery += 'orders.order_id = ' + this.delOrderId + ' ';
      updateQuery += 'AND orders.order_row = ' + this.delOrderRow + ' ';
      updateQuery += 'AND orders.order_issue_class > 0 ';
      updateQuery += 'AND orders.is_deleted = 0 ';
      updateQuery += 'AND orders.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
      updateQuery += ') AS orders_QUERY';
      updateSql += CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      updateSql += 'AND stocks.office_id = orders_QUERY.office_id ';
      updateSql += 'AND stocks.product_id = orders_QUERY.product_id ';
      //console.log(updateSql);
      sqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    // 発注削除準備
    async prepareOrderDelete() {
      // 各種データ取得（非同期でまとめて取得した方が早いため）
      let checkOrderDel = false;
      let controlData = null;
      [checkOrderDel, controlData] = await Promise.all([
        this.checkOrderDelete(),
        getControlMaster(),
      ]);
      this.controlMasterData.processMonthYear = controlData.process_month_year;

      return checkOrderDel;
    },
    // 削除可能チェック
    async checkOrderDelete() {
      let selectSql = '';
      // SELECT句
      selectSql = 'SELECT ';
      selectSql += ' orders.order_receive_id';
      selectSql += ',CASE WHEN stocks.order_id IS NULL THEN 0 ELSE 1 END AS stocks_input_class';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      selectSql += 'LEFT JOIN t_stocks AS stocks ';
      selectSql += 'ON orders.order_id = stocks.order_id ';
      selectSql += 'AND orders.order_row = stocks.order_row ';
      // WHERE句
      selectSql += ' WHERE ';
      // 引数の発注行で絞り込む
      selectSql += ' orders.order_id = ' + this.delOrderId + ' ';
      selectSql += 'AND orders.order_row = ' + this.delOrderRow + ' ';
      // 削除済フラグ
      selectSql += 'AND orders.is_deleted = 0 ';
      // GROUP BY句
      selectSql += 'GROUP BY orders.order_row ';
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      if (resultData != null && resultData.length > 0) {
        for (let i = 0; i < resultData.length; i++) {
          if (resultData[i].order_receive_id != 0 ||
          resultData[i].stocks_input_class == 1) {
            // 他の受注データと紐づいている場合、
            // 入荷済みの行がある場合、
            // 削除不可
            return false;
          }
        }
      } else {
        // 有効な行が存在しない場合、削除不可
        return false;
      }
      return true;
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
  },
}
</script>
<style scoped>
</style>