<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2">
            <b-card>
              <div class="mb-0 p-2" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-2">
                  <validation-observer ref="observer">
                    <b-container>
                      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                          <li>{{error}}</li>
                        </ul>
                      </b-alert>
                      <!-- ●●●検索条件●●● -->
                      <b-container fluid>
                        <b-row class="mt-2">
                          <!-- 営業所入力欄 -->
                          <b-col lg="5">
                            <b-form-group
                              label="営業所"
                            >
                              <b-form-select
                                v-model="searchConditions.selectSalesOffice"
                                :options="salesOffice"
                                value-field="id"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <!-- 仕入入力欄 -->
                          <b-col lg="7">
                            <b-form-group
                              label="仕入先"
                            >
                              <validation-provider name="selectClientId" :rules="{numeric:true, required: true}" v-slot="{ classes,errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-form-input v-model="searchConditions.selectClientId" />
                                    <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                        <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row class="mt-2">
                        <!-- 製品コード入力欄 -->
                          <b-col lg="5">
                            <b-form-group
                              label="製品コード"
                            >
                              <validation-provider name="selectProductId" :rules="{numeric:true}" v-slot="{ classes,errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-form-input v-model="searchConditions.selectProductId"/>
                                    <b-input-group-text @click="showProductSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                        <button type="button" v-shortkey="['alt', '2']" @shortkey="showProductSearchModal()" class="d-none"></button>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- 伝票日付入力欄 -->
                          <b-col lg="7">
                            <b-container fluid>
                              <b-row>
                                <b-col class="px-0">
                                  <b-form-group
                                    label="伝票日付"
                                    label-for="datepicker"
                                  >
                                    <b-input-group>
                                      <validation-provider name="selectBillingDateEnd" :rules="{dateConsistency:searchConditions.selectBillingDateEnd}" v-slot="{ classes,errors }">
                                        <div :class="classes">
                                          <b-input-group class="input-daterange" id="datepicker">
                                            <b-form-datepicker
                                              id="datepicker1"
                                              name="selectBillingDateStart"
                                              class="form-control"
                                              v-model="searchConditions.selectBillingDateStart"
                                            ></b-form-datepicker>
                                            <b-input-group-append>
                                              <b-button size="sm" variant="outline-secondary" @click="searchConditions.selectBillingDateStart=''">
                                                <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                              </b-button>
                                            </b-input-group-append>
                                          </b-input-group>
                                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                        </div>
                                      </validation-provider>
                                      <b-input-group-text>～</b-input-group-text>
                                      <div>
                                        <b-input-group class="input-daterange" id="datepicker">
                                          <b-form-datepicker
                                            id="datepicker2"
                                            name="selectBillingDateEnd"
                                            class="form-control"
                                            v-model="searchConditions.selectBillingDateEnd"
                                          ></b-form-datepicker>
                                          <b-input-group-append>
                                            <b-button size="sm" variant="outline-secondary" @click="searchConditions.selectBillingDateEnd=''">
                                              <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                            </b-button>
                                          </b-input-group-append>
                                        </b-input-group>
                                      </div>
                                    </b-input-group>
                                  </b-form-group>
                                  <b-form-text class="text-muted">パフォーマンスの関係で過去の全データを表示することは不可能です。日付は基本的に指定してください。</b-form-text>
                                </b-col>
                              </b-row>
                            </b-container>
                          </b-col>
                        </b-row>
                      </b-container>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center my-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-card-header>
          <b-table-simple
            v-if="Object.keys(searchResultDispConditions).length !== 0"
            :bordered='false'
            :borderless='true'
            :outlined='false'
            small
            caption-top
          >
            <b-tbody class="container">
              <b-tr class="row">
                <div class="col-lg-8">
                  <b-th>仕入先</b-th>
                  <b-td>{{searchResultDispConditions.clientId + ' ' + searchResultDispConditions.clientName}}</b-td>
                </div>
                <div class="col-lg-2">
                  <b-th>締日</b-th>
                  <b-td>{{searchResultDispConditions.closingDate}}</b-td>
                </div>
                <div class="col-lg-2">
                  <b-th>金種</b-th>
                  <b-td>{{searchResultDispConditions.denominations}}</b-td>
                </div>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
          <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        </b-card-header>
          <b-col lg="12" class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="4"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <template #head(billingDate)>伝票日付<br>&nbsp;</template>
              <template #head(free_col_1)>伝票番号<br>&nbsp;</template>
              <template #head(free_col_2)>摘要<br>製品</template>
              <template #head(free_col_3)>&nbsp;<br>備考</template>
              <template #head(free_col_4)>&nbsp;<br>数量</template>
              <template #head(free_col_5)>&nbsp;<br>単価</template>
              <template #head(free_col_6)>&nbsp;<br>金額</template>
              <template #cell(free_col_1)="data">
                {{ data.item.billingNo }}<br/>
                <b-button size="sm" v-b-tooltip.hover.noninteractive.right="'売上伝票照会画面に遷移します。'" @click="clickDetailBtn(data.item)" class="mr-2">
                  <span class="oi oi-pencil"> 編集</span>
                </b-button>
              </template>
              <template #cell(free_col_2)="data">
                <div style="max-width:300px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                    <span v-if="data.item.transactionId==htmlConst.TransactionId.stocking" v-b-tooltip.hover.noninteractive :title="data.item.summary">
                      {{ data.item.summary }}<br/>
                    </span>
                  </div>
                  <div v-for="(productName,index) in data.item.productName" :key="index" style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                    <span v-b-tooltip.hover.noninteractive :title="productName">{{ productName }}</span>
                  </div>
                </div>
              </template>
              <template #cell(free_col_3)="data">
                <div style="max-width:300px;">
                  <span v-if="data.item.transactionId==htmlConst.TransactionId.stocking"><br/></span>
                  <div v-for="(productNote,index) in data.item.productNote" :key="index" style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                    <span v-b-tooltip.hover.noninteractive :title="productNote">{{ productNote }}<br/></span>
                  </div>
                </div>
              </template>
              <template #cell(free_col_4)="data">
                <span v-if="data.item.transactionId==htmlConst.TransactionId.stocking"><br/></span>
                <ul v-for="(productQuantity,index) in data.item.productQuantity" :key="index" style="list-style: none;" class="text-right">
                  <li>{{productQuantity}}<br/></li>
                </ul>
              </template>
              <template #cell(free_col_5)="data">
                <span v-if="data.item.transactionId==htmlConst.TransactionId.stocking"><br/></span>
                <ul v-for="(productPurchasePrice,index) in data.item.productPurchasePrice" :key="index" style="list-style: none;" class="text-right">
                  <li>{{productPurchasePrice}}<br/></li>
                </ul>
              </template>
              <template #cell(free_col_6)="data">
                <span v-if="data.item.transactionId==htmlConst.TransactionId.stocking"><br/></span>
                <ul v-for="(productPurchaseAmount,index) in data.item.productPurchaseAmount" :key="index" style="list-style: none;" class="text-right">
                  <li>{{productPurchaseAmount}}<br/></li>
                </ul>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
  <!-- ●●●仕入先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="2"/>
  <!-- ●●●製品検索モーダル●●● -->
  <PRODUCTSEARCH @from-child="closeProductSearchModal" :productSearchProp="productSearchProp"/>
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { executeTransactSql } from '@/graphql/mutations';
import { init, addOperationLogs, executeSelectSql, getListValue, formatDate, formatCurDate, formatDateCalc, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
// モジュール名
const MODULE_NAME = 'client-ledger-inquiry';
export default {
  name: 'CLIENT-LEDGER-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    PRODUCTSEARCH
  },
  props:['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '仕入先元帳照会',
      // アラート
      alertDanger: [],
      alertWarning: [],
      // コンボボックス
      salesOffice: [],
      // 検索条件
      searchConditions:{
        selectSalesOffice: '',
        selectClientId: '',
        selectProductId: null,
        selectBillingDateStart: '',
        selectBillingDateEnd: '',
      },
      // 全件検索用
      addConditions:{id: 0, name: '全て', text: '全て'},
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 製品検索情報
      productSearchProp:{
        office_id: null,
        office_name: null,
      },
      // ヘッダー項目
      searchResultDispConditions: {},
      // 定数（htmlで使用）
      htmlConst: {
        // トランザクションID
        TransactionId: {
          // 売上
          sales: 'UR',
          // 仕入
          stocking: 'SI',
          // 入金
          deposit: 'NK',
          // 支払
          payment: 'SK',
          // 入出庫
          storage: 'NS',
        },
      },
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // コンボボックスの作成
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'billingDate',
        },
        {
          key: 'free_col_1',
        },
        {
          key: 'free_col_2',
          thStyle: 'width:250px;',
        },
        {
          key: 'free_col_3',
          thStyle: 'width:250px;',
        },
        {
          // 製品数量
          key: 'free_col_4',
        },
        {
          // 製品単価
          key: 'free_col_5',
        },
        {
          // 製品金額
          key: 'free_col_6',
        },
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods:{
    /* フェッチ */
    async fetchData(){
      // ローディングの開始
      this.$store.commit('setLoading', true);
      // storeからログイン中ユーザーの情報を取得
      this.loginStaffInfo = store.getters.user.username;
      // 営業所データ取得
      await this.getOfficesData();
      // 伝票日付に１か月前～現在日を設定
      this.searchConditions.selectBillingDateEnd = formatCurDate('YYYY-MM-DD');
      this.searchConditions.selectBillingDateStart = formatDateCalc(this.searchConditions.selectBillingDateEnd, 0, -1, 0, false, 'YYYY-MM-DD');
      // ローディングの解除
      this.$store.commit('setLoading', false);
    },
    /* 営業所情報を取得 */
    async getOfficesData() {
      // ログ出力用メソッド名
      const functionName = 'getOfficesData';
      // 担当者データ取得
      let staffListData = null;
      // storeからログイン中ユーザーの情報を取得
      let user = store.getters.user;
      this.loginId = user.username;
      // 検索条件を設定
      let where_clause = `AND login_id = '${this.loginId}'`;
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await selectOneTable('m_staffs', where_clause);
        if (result != null && result.length > 0) {
          // データを取得できた場合、取得データを返却
          staffListData = result;
        } else {
          // データを取得できなかった場合
          await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_staffs'}, 'result is null');
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
          return null;
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_staffs'}, 'result.data is null');
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return null;
      }
      let loginOfficeId = staffListData[0].office_id;
      try {
        // 営業所データ取得
        let officeListData = await selectOneTable('m_offices');
        //console.log(officeListData);
        // default値挿入
        this.searchConditions.selectSalesOffice = 0;
        // 営業所プルダウン作成
        for(const officeData of officeListData){
          this.salesOffice.push({
            id: officeData.office_id,
            name: officeData.office_name_kanji,
            text: officeData.office_id + '：' + officeData.office_name_kanji,
          });
          // default値挿入
          if (loginOfficeId == officeData.office_id) {
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
        // 検索条件「全て」を追加
        this.salesOffice.unshift(this.addConditions);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_offices'}, 'result.data is null');
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return null;
      }
    },
    /* 検索ボタン押下時 */
    async searchButton(){
      // ログ出力用メソッド名
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        } else {
          // ローディングの開始
          this.$store.commit('setLoading', true);
          // 検索開始
          await this.search();

        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
      }
      // ローディングの終了
      this.$store.commit('setLoading', false);
    },
    /* 検索処理 */
    async search() {
      // ログ出力用メソッド名
      const functionName = 'search';
      // 初期化を行う
      this.itemList = [];
      this.searchResultDispConditions = {};
      // 明細テーブルのローディングを開始
      this.busy = true;
      // ページング機能の初期化
      this.initPaging();
      // CRUD処理
      let selectSql;
      selectSql = await this.makeSelectSql();
      let result;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: selectSql }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql,
          result: result
        });
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      // データ取得時
      let resultData;
      const responseBody = JSON.parse(result.data.executeTransactSql.body)
      resultData = responseBody.data[0];
      if(resultData){
        await this.setResult(resultData);
        if(resultData.length >= 3000){
          this.alertWarning.push(DISP_MESSAGES.WARNING['2009'].replace('%arg1%','3000').replace('%arg2%','製品'));
        }
      } else {
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
      }
      // 明細テーブルのローディングを解除
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result){
      // ヘッダーをセット
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' closing_date'; // 締日
      selectSql += ',business_class'; // 金種
      selectSql += ',client_name_kanji'; // 取引先名
      // FROM句
      selectSql += ' FROM ';
      selectSql += 'm_clients ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 'client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql += `AND client_id = ${this.searchConditions.selectClientId} `;
      let dataClientResult = await executeSelectSql(selectSql);
      //console.log(dataClientResult);
      this.searchResultDispConditions.clientId = this.searchConditions.selectClientId;
      this.searchResultDispConditions.clientName = dataClientResult[0].client_name_kanji;
      this.searchResultDispConditions.denominations= getListValue(Const.businessClassList, dataClientResult[0].business_class);
      this.searchResultDispConditions.closingDate= dataClientResult[0].closing_date;
      let searchResult = null;
      for(const data of result){
        // 伝票番号毎に１件として検索結果に表示するリストを作成
        if (searchResult == null || searchResult.transactionId != data.transaction_id || searchResult.billingNo != data.billing_no) {
          if (searchResult != null) {
            this.itemList.push(searchResult);
          }
          // テーブルデータをセット
          searchResult = {
            transactionId: data.transaction_id,
            billingDate : formatDate(data.billing_date),
            billingNo : data.billing_no,
            summary : data.billing_summary,
            monthlyMonthYear : data.monthly_month_year,
            _cellVariants: { productName: getListValue(Const.ProductCheckClass, data.product_check_class, 'value', 'color')},
            productName: [],
            productNote: [],
            productQuantity: [],
            productPurchasePrice: [],
            productPurchaseAmount: [],
          };
        }
        // 製品、消費税、入金の３パターン
        if (data.transaction_id == Const.TransactionId.payment) {
          // トランザクションIDがSKの場合は支払
          searchResult['productName'].push(data.kind + '　（　' + (getListValue(Const.denominationList, data.kind) + '　　　').substr(0, 3) + '　）　' + data.account_support);
          searchResult['productNote'].push(data.summary);
          searchResult['productQuantity'].push('');
          searchResult['productPurchasePrice'].push('');
          searchResult['productPurchaseAmount'].push(data.amount.toLocaleString());
        } else if (data.product_id == 0) {
          // 製品コードが0の場合は消費税
          searchResult['productName'].push('消費税');
          searchResult['productNote'].push('');
          searchResult['productQuantity'].push('');
          searchResult['productPurchasePrice'].push('');
          searchResult['productPurchaseAmount'].push(data.product_tax.toLocaleString());
        } else {
          // 上記以外の場合（製品コードが0以外、且つ、トランザクションIDがUR）の場合は製品
          searchResult['productName'].push(!data.product_name ? data.product_id : data.product_id + '：' + data.product_name);
          searchResult['productNote'].push(data.product_note);
          searchResult['productQuantity'].push(data.product_quantity.toLocaleString() + (data.product_unit == null ? '' : ' ' + data.product_unit));
          searchResult['productPurchasePrice'].push(data.product_purchase_price.toLocaleString());
          searchResult['productPurchaseAmount'].push(data.product_purchase_amount.toLocaleString());
        }
      }
      if (searchResult != null) {
        this.itemList.push(searchResult);
      }
      // 総件数をdataTableの総件数にセット
      this.totalRows = this.itemList.length;
    },
    /* SELECT文字列作成 */
    async makeSelectSql(){
      // 検索条件
      let whereClauses = [];
      // 営業所コード
      if (this.searchConditions.selectSalesOffice) {
        whereClauses.push(`cumulative_transaction.office_id = ${this.searchConditions.selectSalesOffice}`);
      }
      // 伝票日付開始&伝票日付終了
      if (this.searchConditions.selectBillingDateStart && this.searchConditions.selectBillingDateEnd ) {
        // 伝票日付開始
        whereClauses.push(`cumulative_transaction.billing_date BETWEEN '${this.searchConditions.selectBillingDateStart}'`);
        // 伝票日付終了
        whereClauses.push(`'${this.searchConditions.selectBillingDateEnd}'`);
      } else if (this.searchConditions.selectBillingDateStart) {
        whereClauses.push(`cumulative_transaction.billing_date >= '${this.searchConditions.selectBillingDateStart}'`);
      } else if (this.searchConditions.selectBillingDateEnd) {
        whereClauses.push(`cumulative_transaction.billing_date <= '${this.searchConditions.selectBillingDateEnd}'`);
      }
      // 製品コードが入力された場合は、結合条件に加える
      if(this.searchConditions.selectProductId){
        whereClauses.push(`cumulative_transaction.product_id = ${this.searchConditions.selectProductId} `);
      }
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.transaction_id'; // ﾄﾗﾝｻﾞｸｼｮﾝID
      selectSql += ',cumulative_transaction.billing_date'; // 伝票日付
      selectSql += ',cumulative_transaction.billing_no'; // 伝票番号
      selectSql += ',cumulative_transaction.billing_summary'; // 摘要
      selectSql += ',cumulative_transaction.product_id'; // 製品コード
      selectSql += ',cumulative_transaction.product_name'; // 製品名
      selectSql += ',cumulative_transaction.product_quantity'; // 数量
      selectSql += ',cumulative_transaction.product_unit'; // 製品単位
      selectSql += ',cumulative_transaction.product_purchase_price'; // 単価
      selectSql += ',cumulative_transaction.product_purchase_amount'; // 金額
      selectSql += ',cumulative_transaction.monthly_month_year'; // 月次年月
      selectSql += ',cumulative_transaction.product_tax'; // 製品消費税
      selectSql += ',cumulative_transaction.product_note'; // 製品備考
      selectSql += ',cumulative_transaction.kind'; // 金種
      selectSql += ',cumulative_transaction.account_support'; // 勘定一補助
      selectSql += ',cumulative_transaction.amount'; // 金額
      selectSql += ',cumulative_transaction.summary'; // 摘要
      selectSql += ',IfNull(products.product_check_class,0) AS product_check_class'; // 製品チェック区分
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';

      /* 製品マスタ（m_products）と結合 */
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON cumulative_transaction.product_id = products.product_id '
      // 製品コードが入力された場合は、結合条件に加える
      if(this.searchConditions.selectProductId){
        selectSql += `AND products.product_id = ${this.searchConditions.selectProductId} `;
      }

      /* WHERE句 */
      if(whereClauses.length !== 0){
        selectSql += `WHERE cumulative_transaction.transaction_id IN ('${Const.TransactionId.stocking}','${Const.TransactionId.payment}') `
        selectSql += 'AND cumulative_transaction.client_class = ' + Const.ClientClass.supplier + ' ';
        selectSql += `AND cumulative_transaction.client_id = ${this.searchConditions.selectClientId} `;
        selectSql += 'AND (transaction_id = \'' + Const.TransactionId.stocking + '\' OR amount <> 0) ';
        selectSql += 'AND '
        selectSql += `${whereClauses.join(' AND ')} `
      }
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += 'billing_date';
      selectSql += ',transaction_id';
      selectSql += ',billing_no';
      selectSql += ',billing_row';
      /* LIMIT */
      selectSql += ' LIMIT 3000';

      return selectSql;
    },
    /* 編集ボタン押下時 */
    async clickDetailBtn(item){
      let route = null;
      if (item.transactionId == Const.TransactionId.stocking) {
        // 仕入修正へ
        route = this.$router.resolve({ name: 'PURCHASE-EDIT', query: { billingNo: item.billingNo, monthlyMonthYear: item.monthlyMonthYear, parentKbn: 0 } });
      } else {
        // 支払修正へ
        route = this.$router.resolve({ name: 'PAYMENT-EDIT', query: { reqBillingNo: item.billingNo, reqMonthlyMonthYear: item.monthlyMonthYear } });
      }
      //console.log(this.$router);
      window.open(route.href, '_blank');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * 「取引先検索」アイコン押下処理
     */
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    /**
     * 「取引先検索」モーダルのクローズ押下処理
     */
    closeClientSearchModal:function(clientItems){
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined'){
        this.searchConditions.selectClientId = clientItems.detail.client_id;
      }
    },
    /**
     * 「製品検索」アイコン押下処理
     */
    showProductSearchModal:function() {
      this.productSearchProp.office_id = this.searchConditions.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.searchConditions.selectSalesOffice);
      this.$bvModal.show('productSearchModal');
    },
    /**
     * 「製品検索」アイコン押下処理
     */
    closeProductSearchModal:function(productItems){
      // モーダルから渡された値の有無チェック
      if(typeof productItems != 'undefined'){
        this.searchConditions.selectProductId = productItems.detail.product_id;
      }
    },
  },
}
</script>
<style scoped>
.input-daterange{
  min-width: 12.5vw;
}
</style>