<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <div class="container-fluid px-4 py-4 min-vh-85">
    <div class="row d-flex justify-content-center mt-2 mb-2">
      <div class="col-md-12">
        <div class="media">
          <div class="media-body pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 営業所保守入力</strong></h5>
              <router-link to="/office-list" class="btn btn-cancel m-0"><span class="text-white oi oi-circle-x"></span> キャンセル</router-link>
            </div>
          </div>
        </div>
        <div class="main-card mb-3 card">
          <div class="card-header">
            <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
              <ul v-for="(message,index) in successMessages" :key="index" style="list-style: none;">
                <li>{{message}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="warningMessages.length">
              <ul v-for="(message,index) in warningMessages" :key="index" style="list-style: none;">
                <li>{{message}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="dangerMessages.length">
              <ul v-for="(message,index) in dangerMessages" :key="index" style="list-style: none;">
                <li>{{message}}</li>
              </ul>
            </b-alert>
          </div>
          <div class="card-body">
            <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
            <validation-observer ref="observer">
              <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
                <div class="row mt-2">
                  <!-- 営業所コード -->
                  <div class="col col-6 form-group">
                    <validation-provider name="offieId" rules="required|numeric|max:2" v-slot="{ classes,errors }">
                      <div class=""><label for="text-input" class="form-control-label"><strong>営業所コード</strong></label></div>
                      <div :class="classes">
                        <input type="text" class="form-control" v-model="office.id" maxLength="2">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 営業所名（漢字） -->
                  <div class="col col-6 form-group">
                    <validation-provider name="name_kanji" rules="required|max:40" v-slot="{ classes,errors }">
                      <div class=""><label for="text-input" class="form-control-label"><strong>営業所名（漢字）</strong></label></div>
                      <div :class="classes">
                        <input type="text" class="form-control" v-model="office.name_kanji" maxLength="40">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 営業所名（カナ） -->
                  <div class="col col-6 form-group">
                    <validation-provider name="name_kana" rules="required|max:40" v-slot="{ classes,errors }">
                      <div class=""><label for="text-input" class="form-control-label"><strong>営業所名（カナ）</strong></label></div>
                      <div :class="classes">
                        <input type="text" class="form-control" v-model="office.name_kana" maxLength="40">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 郵便番号 -->
                  <div class="col col-6 form-group">
                    <validation-provider name="zip_code" rules="regex:^([0-9]{3}-[0-9]{4})$" v-slot="{ classes,errors }">
                      <div class=""><label for="text-input" class="form-control-label"><strong>郵便番号</strong></label></div>
                      <div :class="classes">
                        <input type="text" class="form-control" v-model="office.zip_code" maxLength="8">
                        <small class="form-text text-muted">「nnn-nnnn」形式</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 住所１ -->
                  <div class="col col-6 form-group">
                    <validation-provider name="address1" rules="max:80" v-slot="{ classes,errors }">
                      <div class=""><label for="text-input" class="form-control-label"><strong>住所１</strong></label></div>
                      <div :class="classes">
                        <input type="text" class="form-control" v-model="office.address_1" maxLength="80">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 住所２ -->
                  <div class="col col-6 form-group">
                    <validation-provider name="address2" rules="max:80" v-slot="{ classes,errors }">
                      <div class=""><label for="text-input" class="form-control-label"><strong>住所２</strong></label></div>
                      <div :class="classes">
                        <input type="text" class="form-control" v-model="office.address_2" maxLength="80">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 電話番号 -->
                  <div class="col col-6 form-group">
                    <validation-provider name="phone_number" rules="max:21|phone" v-slot="{ classes,errors }">
                      <div class=""><label for="text-input" class="form-control-label"><strong>電話番号</strong></label></div>
                      <div :class="classes">
                        <input type="text" class="form-control" v-model="office.phone_number" maxLength="21">
                        <small class="form-text text-muted">ハイフン区切り</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- FAX番号 -->
                  <div class="col col-6 form-group">
                    <validation-provider name="fax_number" rules="max:21|phone" v-slot="{ classes,errors }">
                      <div class=""><label for="text-input" class="form-control-label"><strong>FAX番号</strong></label></div>
                      <div :class="classes">
                        <input type="text" class="form-control" v-model="office.fax_number" maxLength="21">
                        <small class="form-text text-muted">ハイフン区切り</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- E-MAIL -->
                  <div class="col col-6 form-group">
                    <validation-provider name="email" rules="email" v-slot="{ classes,errors }">
                      <div class=""><label for="text-input" class="form-control-label"><strong>Eメールアドレス</strong></label></div>
                      <div :class="classes">
                        <input type="text" class="form-control" v-model="office.e_mail" maxLength="256">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
              </form>
            </validation-observer>
          </div>
          <!-- 保存ボタン -->
          <div class="card-footer">
            <div class="row justify-content-md-center pb-4">
              <div class="col-lg-2">
                <button type="button" class="btn btn-primary btn-block" @click="saveOffice"><span class="oi oi-circle-check"></span> 保存</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, requireCheck, escapeQuote, CreateColRow, CreateInsertSql, getUserCol, executeSelectSql, executeTransactSqlList, isSystemEditable, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
// モジュール名
const MODULE_NAME = 'office-input';
export default {
  name: 'OFFICE-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '営業所保守入力',
      // 営業所
      office:{
        id: '',
        name_kanji: '',
        name_kana: '',
        zip_code: '',
        address_1: '',
        address_2: '',
        phone_number: '',
        fax_number: '',
        e_mail: ''
      },
      // テーブル定義
      productList:[],
      fields:DataTblDef.office_input_fields,
      // メッセージ
      successMessages:[],
      warningMessages:[],
      dangerMessages:[],
      // 確認ダイアログ用
      confirmMessage: [],
      // ユーザ名
      username: this.$store.getters.user.username,
      // トランザクションSQLリスト
      transactSqlList: [],
      // トランザクションIDリスト取得
      transactionIdList: Const.TransactionIdList,
      // 登録用帳票最小カウント
      countMin: null,
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    this.$store.commit('setLoading', false);
  },
  methods:{
    /* 保存ボタンの押下 */
    async saveOffice(){
      // 保存処理
      console.log('保存処理');
      this.successMessages = [];
      this.warningMessages = [];
      this.dangerMessages = []
      // sqlをリセット
      this.transactSqlList = []
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();
      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if(await this.dataCheck() && this.warningMessages.length == 0 && success){
        await this.saveConfirm();
      }else{
        scrollTo(0,0);
        this.$store.commit('setLoading', false);
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      console.log('保存処理開始');
      this.$store.commit('setLoading', true);
      console.log('登録処理開始');
      if (await this.execInsert() == true) {
        console.log('登録処理終了');
        this.successMessages.push(DISP_MESSAGES.SUCCESS['1001']);
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
      console.log('保存処理終了');
    },
    /* 登録処理 */
    async execInsert(){
      const functionName = 'execInsert'
      try {
        // 帳票カウンタマスタ(m_form_counter)登録用の帳票カウンターを取得
        let countMin = await executeSelectSql(this.selectCountMin());
        this.countMin = countMin[0].count_max;
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, 'executeSelectSqlでエラーが発生しました。', error);
        this.dangerMessages.push(DISP_MESSAGES.DANGER['3007']);
        return false;
      }
      if (this.countMin && this.countMin.toString().length <= 7) {
        // 帳票カウンタデータの登録
        await this.insertCounter();
      } else if(this.countMin.toString().length > 7) {
        this.dangerMessages.push(DISP_MESSAGES.DANGER['3007']);
        return false;
      } else {
        this.warningMessages.push(DISP_MESSAGES.WARNING['2003']);
        return false;
      }
      // 営業所データの登録
      await this.insertOffice();

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.dangerMessages.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.dangerMessages.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }
      
      // エラー発生、中断します
      if (this.successMessages.length != 0 || this.warningMessages.length != 0 || this.dangerMessages.length != 0) {
        return false;
      }
      // 登録実行
      if (!await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName)) {
        this.dangerMessages.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }
      return true;
    },
    /* 帳票カウント取得SQL作成処理 */
    selectCountMin(){
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' (MAX(form_counter.count_max) + 1) AS count_max'; // カウント（最大）+1
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_form_counter AS form_counter ';
      selectSql += `WHERE counter_class = ${Const.CounterClass.billingNo} ` 
      selectSql += 'AND free_class_1 <> 0 '
      return selectSql;
    },
    /* 営業所データ登録SQL作成処理 */
    async insertOffice(){
      let colList = [];
      colList.push(CreateColRow('office_id', this.office.id, 'NUMBER'));
      colList.push(CreateColRow('office_name_kanji', await escapeQuote(this.office.name_kanji), 'VARCHAR'));
      colList.push(CreateColRow('office_name_kana', await escapeQuote(this.office.name_kana), 'VARCHAR'));
      colList.push(CreateColRow('zip_code', await escapeQuote(this.office.zip_code), 'VARCHAR'));
      colList.push(CreateColRow('address_1', await escapeQuote(this.office.address_1), 'VARCHAR'));
      colList.push(CreateColRow('address_2', await escapeQuote(this.office.address_2), 'VARCHAR'));
      colList.push(CreateColRow('phone_number', await escapeQuote(this.office.phone_number), 'VARCHAR'));
      colList.push(CreateColRow('fax_number', await escapeQuote(this.office.fax_number), 'VARCHAR'));
      colList.push(CreateColRow('e_mail', await escapeQuote(this.office.e_mail), 'VARCHAR'));
      // 登録・更新ユーザー
      const colUser = await getUserCol(this.username, 'both')
      const sql = `${CreateInsertSql(colList.concat(colUser), 'full', 'm_offices')} `
      this.transactSqlList.push(sql);
    },
    /* 帳票カウンタデータ登録SQL作成処理 */
    async insertCounter(){
      let bulkInsertSqlCol = null;
      let bulkInsertSqlVal = [];
      for (const data of this.transactionIdList) {
        if(data.id === 0) continue
        let colList = [];
        colList.push(CreateColRow('counter_class', 1, 'NUMBER'));
        colList.push(CreateColRow('count_cur', this.countMin, 'NUMBER'));
        colList.push(CreateColRow('count_min', this.countMin, 'NUMBER'));
        colList.push(CreateColRow('count_max', this.countMin + 199999, 'NUMBER'));
        colList.push(CreateColRow('free_class_1', this.office.id, 'NUMBER'));
        colList.push(CreateColRow('free_class_2', data.id, 'VARCHAR'));
        // 登録・更新ユーザー
        const colUser = await getUserCol(this.username, 'both')
        if(!bulkInsertSqlCol){
          bulkInsertSqlCol = CreateInsertSql(colList.concat(colUser), 'col', 'm_form_counter').split(',');
        }
        bulkInsertSqlVal.push(CreateInsertSql(colList.concat(colUser), 'val', 'm_form_counter').split(','));
      }
      let sql = `INSERT INTO m_form_counter (${bulkInsertSqlCol}) VALUES `
      let insertArr = []
      for (const key in bulkInsertSqlVal) {
        insertArr.push(`(${bulkInsertSqlVal[key].join()})`)
      }
      sql = `${sql} ${insertArr.join()}`
      this.transactSqlList.push(sql);
    },
    /* 入力データのチェック */
    async dataCheck(){
      // 営業所コード登録済みチェック ※入力時のみ
      if(requireCheck(this.office.id)){
        if(!await this.registeredCheck()){
          this.warningMessages.push(DISP_MESSAGES.WARNING['2004']);
          return false;
        }
      }
      return true;
    },
    /* 営業所コードの登録済みチェック */
    async registeredCheck(){
      const functionName = 'registeredCheck'
      this.officeList = [];
      // CRUD処理
      let where_clause = '';
      where_clause = 'AND office_id = ' + this.office.id;
      try {
        //console.log('条件取得');
        let resultData = await selectOneTable('m_offices', where_clause);
        if (resultData == null || resultData.length == 0){
          //console.log(resultData);
          return true;
        }else{
          return false;
        }
      } catch (error) {
        this.warningMessages.push(DISP_MESSAGES.WARNING['2001']);
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_offices'}, error);
        return false;
      }
    },
  }
}
</script>
<style scoped>
  table {
    display: table !important;
    width: 100% !important;
    border-collapse: collapse;
    table-layout: fixed;
    white-space: nowrap;
  }
  .datatable{
    table-layout:auto;
  }
</style>