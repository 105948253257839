<template>
  <!-- 納品指定区分保守一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row">
        <div class="col-lg-12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <div class="card border">
              <div class="card-header mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip">
                  <span class="oi oi-magnifying-glass"></span> 検索条件
                  <b-button size="sm" v-b-tooltip.hover title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                  <router-link to="/deliverys-designs-classes-input" class="btn btn-sm btn-primary mx-2" style="font-size:60%;float:right;" title="納品指定区分データの新規登録を行います。">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </router-link>
                </h5>
              </div>
              <div id="collapse1" class="collapse show">
                <div class="card-body p-2">
                  <div class="container-fluid">
                    <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                      <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="warning" class="mt-2" v-if="errorMessages.length">
                      <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <div class="row mt-2">
                        <!-- 納品指定区分入力欄 -->
                        <div class="col-md-3 form-group">
                          <label for="deliveryDesignClass" class="form-label">納品指定区分</label>
                          <validation-provider name="deliveryDesignClass" rules="max:1" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="deliveryDesignClass" name="deliveryDesignClass" class="form-control" v-model.trim="searchConditions.deliveryDesignClass" maxlength="1">
                              <small class="form-text text-muted">完全一致検索です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 納品指定区分名入力欄 -->
                        <div class="col-md-9 form-group">
                          <label for="deliveryDesignName" class="form-label">納品指定区分名</label>
                          <validation-provider rules="max:15" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="deliveryDesignName" name="deliveryDesignName" class="form-control" v-model.trim="searchConditions.deliveryDesignName" maxlength="15">
                              <small class="form-text text-muted">部分一致検索です。登録済みの納品指定区分名一致するデータを検索します。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                      </div>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <div class="row justify-content-md-center my-2">
                      <div class="col-lg-2">
                        <button type="button" class="btn btn-primary btn-block" @click="searchButton()"><span class="oi oi-magnifying-glass"></span> 検索</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ●●●検索結果●●● -->
      <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <div class="row">
          <div class="col-sm-12">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="4"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-col cols="12" class="px-0">
                <b-table
                  show-empty
                  :head-variant="headVariant"
                  :responsive="true"
                  :items="resultList"
                  :fields="fields"
                  :busy="busy"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :bordered="true"
                  :empty-text="emptyText"
                  :empty-filtered-text="emptyFilterdText"
                  @filtered="onFiltered"
                >
                  <!-- テーブル読み込み時表示html -->
                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>読み込んでいます...</strong>
                    </div>
                  </template>
                  <!-- 操作列 -->
                  <template #cell(operation)="operation">
                    <div v-show="!printStatus">
                      <!-- 照会ボタン -->
                      <b-button size="sm" v-b-tooltip.hover @click="clickDetailBtn(operation.item)" class="mr-1">
                        <span class="oi oi-list"></span> 照会
                      </b-button>&nbsp;
                      <!-- 編集ボタン -->
                      <b-button size="sm" v-b-tooltip.hover @click="clickEditBtn(operation.item)" class="mr-1">
                        <span class="oi oi-pencil"></span> 編集
                      </b-button>&nbsp;
                      <!-- 削除ボタン -->
                      <b-button size="sm" v-b-tooltip.hover @click="clickDeleteBtn(operation.item)" class="mr-1">
                        <span class="oi oi-delete"></span> 削除
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- 表示レコードをラベル表示 -->
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : resultList.length"
                :per-page="perPage == -1 ? resultList.length : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, windowPrint, convertSqlLikeSpecialChar, init, isSystemEditable, selectOneTable } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'deliverys-designs-classes-maintenance';

export default {
  name: 'DELIVERYS-DESIGNS-CLASSES-MAINTENANCE',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '納品指定区分保守',
      // ユーザ名
      username: this.$store.getters.user.username,
      // 画面メッセージ
      successMessages: [],
      errorMessages: [],
      // 検索条件
      searchConditions: {
        deliveryDesignClass: '',
        deliveryDesignName: ''
      },
      // 検索結果
      resultError: [],
      //  結果リスト
      resultList: [],
      fields: DataTblDef.deliverys_designs_classe_list_fields,
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText:DataTblDef.emptyFilteredText,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // プリントアウト状態
      printStatus: false,
    };
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.resultList.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    // 新規か編集画面から成功メッセージ
    this.successMessages = this.$route.params.successMessages || []
    scrollTo(0,0);
    //await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  /* 関数群 */
  methods: {
    shortkey() {
      this.$router.push({ name: 'DELIVERYS-DESIGNS-CLASSES-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData(){
      // 最新製品データ1000件を取得
      // 一般製品が対象外
      const listConditions = 'AND 1 = 1 ORDER BY updated DESC LIMIT 1000';
      await this.search(listConditions);
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * 検索処理
     * @param {String} listConditions - 検索条件
     */
    async search(listConditions) {
      this.resultList = [];

      // リストの検索を行います。
      let resultList = null;
      if (listConditions !== null && listConditions.length > 0) {
        resultList = await this.searchDeliverysDesignsClasses(listConditions);
        if (resultList == null) {
          this.resultError.push(DISP_MESSAGES.WARNING['2001']);
          return;
        } 
        resultList.forEach(item => {
          this.resultList.push(
            {
              delivery_design_class: item.delivery_design_class,
              delivery_design_name: item.delivery_design_name,
            }
          )
        });
      }
      // 検索結果が1000件の場合は1000件を超えている場合があるのでメッセージを表示します。
      if (this.resultList.length === 1000) {
        this.resultError.push(DISP_MESSAGES.WARNING['2002']);
      }
    },
    /**
     * 9Aコード管理マスタ検索
     * @param {String} whereClause - 検索条件
     * @returns {Array<Object>} 検索結果
     */
    async searchDeliverysDesignsClasses(whereClause) {
      const functionName = 'searchDeliverysDesignsClasses';

      let result = null;
      try {
        result = await selectOneTable('m_deliverys_designs_classes', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_deliverys_designs_classes',
          whereClause: whereClause
        }, error);
        return null;
      }
      return result;
    },
    /**
     * 検索ボタン押下時
     */
    async searchButton() {
      this.busy = true;
      this.resultError = [];

      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const isValid = await this.$refs.observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (isValid) {
        // 納品指定区分
        let listConditions = '';
        if (this.searchConditions.deliveryDesignClass !== '') {
          listConditions += `AND delivery_design_class = '${this.searchConditions.deliveryDesignClass}' `;
        }
        // 納品指定区分名(あいまい検索対象)
        if (this.searchConditions.deliveryDesignName !== '') {
          const deliveryDesignName = convertSqlLikeSpecialChar(this.searchConditions.deliveryDesignName);
          listConditions += `AND REPLACE(REPLACE(CONVERT(delivery_design_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${deliveryDesignName}%', 'ﾞ', ''), 'ﾟ', '') `;
        }
        // 1000件までが上限になります
        listConditions += 'ORDER BY updated DESC LIMIT 1000';
        await this.search(listConditions);
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });
      }
      this.busy = false;
    },
    /**
     * 照会ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickDetailBtn(item) {
      let route = this.$router.resolve({ name: 'DELIVERYS-DESIGNS-CLASSES-INQUIRY', query: { delivery_design_class: item.delivery_design_class }});
      window.open(route.href, '_blank');
    },
    /**
     * 編集ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickEditBtn(item) {
      let route = this.$router.resolve({ name: 'DELIVERYS-DESIGNS-CLASSES-EDIT', query: { delivery_design_class: item.delivery_design_class }});
      window.open(route.href, '_blank');
    },
    /**
     * 削除ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickDeleteBtn(item) {
      this.$bvModal.msgBoxConfirm('選択された納品指定区分データを削除します。\nよろしいですか？').then(async value => {
        if (value) {
          this.$store.commit('setLoading', true);
          this.successMessages = [];
          this.errorMessages = [];
          await this.execDelItem(item.delivery_design_class)
          this.searchButton();
          this.$store.commit('setLoading', false);
          scrollTo(0,0);
        }
      });
    },
    /**
     * 削除処理
     * @param {Int} delivery_design_class - 選択された納品指定区分
     */
    async execDelItem(delivery_design_class) {
      const functionName = 'execDelItem';
      // 納品指定区分マスタ
      const SQLs = `DELETE FROM m_deliverys_designs_classes WHERE delivery_design_class = '${delivery_design_class}'`

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        result: result
      });

      if (this.errorMessages.length == 0) {
        this.successMessages.push(DISP_MESSAGES.SUCCESS['1002']);
      }
    },
  }
};
</script>