<template>
  <div>
    <!-- ヘッダー ここから -->
    <header>
      <b-navbar id="nav-bar" toggleable type="light" variant="light" fixed="top" class="border-bottom px-2 navbar-expand-md">
        <!-- ●●●ハンバーガーメニューアイコン●●● -->
        <b-navbar-toggle target="nav-collapse" v-show="type !== 'login' && showHamburger" style="display: inline-block;"></b-navbar-toggle>

        <!-- ●●●ロゴ●●● -->
        <b-navbar-brand to="/menu" id="logo" class="mx-2" style="cursor: pointer">
          <img src="@/assets/img/logo.png" width="" height="30" class="d-inline-block align-top" />
        </b-navbar-brand>
        <!-- ●●●タイトル●●● -->
        <b-navbar-nav>
          <b-nav-item href="javascript:location.reload()" class="text-secondary">
            <span id="header_title">{{ menuTitle }}</span>
          </b-nav-item>
        </b-navbar-nav>
        <!-- メッセージ --->
        <b-alert v-if="message" class="mx-2 my-1 py-1" show :variant="message.variant">
          <span v-if="message.icon" :class="message.icon"></span>
          <span class="ml-2">{{ message.message }}</span>
        </b-alert>
        <!-- ナビゲーションバーのコンテンツ -->
        <b-navbar-nav class="ml-auto" v-show="type != 'login'">
          <b-nav-item class="user-area"><span class="oi oi-person"></span> {{userName}}</b-nav-item>
          <b-nav-item class="user-area" @click="logOut"><span class="oi oi-account-logout"></span> ログアウト</b-nav-item>
        </b-navbar-nav>
      </b-navbar>
    </header>
    <!-- ヘッダー ここまで -->

    <!-- サイドメニュー ここから -->
    <b-sidebar id="nav-collapse" width="300px" bg-variant="light" text-variant="light" shadow>
      <div class="px-3 py-5">
        <nav class="mb-3">
          <b-nav vertical>
            <ul>
            <b-nav-item><i class="px=0 py=0 oi oi-home"></i> メニュー</b-nav-item>
            <li class="px-3 py=0">
                <a href="#sales-management" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <i class="oi oi-cart"></i> 販売管理
                </a>
                <ul class="px-3 collapse list-unstyled" id="sales-management">
                    <li><a href="/menu-sales-daily"><i class="oi oi-cog"></i> 日次処理メニュー</a></li>
                    <li><a href="/menu-sales-claim"><i class="oi oi-cog"></i> 請求締次処理メニュー</a></li>
                    <li><a href="/menu-sales-pay"><i class="oi oi-cog"></i> 支払締次処理メニュー</a></li>
                    <li><a href="/menu-sales-monthly"><i class="oi oi-cog"></i> 月次処理メニュー</a></li>
                    <li><a href="/menu-sales-ordership"><i class="oi oi-cog"></i> 受注・出荷処理メニュー</a></li>
                    <li><a href="/menu-sales-orderstock"><i class="oi oi-cog"></i> 発注・入荷処理メニュー</a></li>
                    <li><a href="/menu-sales-query"><i class="oi oi-cog"></i> QUERYメニュー</a></li>
                    <li><a href="/menu-sales-inquiry"><i class="oi oi-cog"></i> 問合せ処理メニュー</a></li>
                </ul>
            </li>
            <li class="px-3">
                <a href="#estimate-management" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <i class="oi oi-calculator"></i> 見積管理
                </a>
                <ul class="px-3 collapse list-unstyled" id="estimate-management">
                    <li><a href="/estimate-list"><i class="oi oi-cog"></i> 見積書・発注入力</a></li>
                    <li><a href="/clients-products-master-list"><i class="oi oi-cog"></i> 取引先製品マスター保守</a></li>
                    <li><a href="/clients-parent-child-master-list"><i class="oi oi-cog"></i> 親取引先保守</a></li>
                    <li><a href="/sales-unit-price-copy"><i class="oi oi-cog"></i> 単価自動コピー（個別）</a></li>
                </ul>
            </li>
            <li class="px-3">
                <a href="#inventory" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <i class="oi oi-task"></i> 棚卸
                </a>
                <ul class="px-3 collapse list-unstyled" id="inventory">
                    <li><a href="/menu"><i class="oi oi-cog"></i> 棚卸メニュー</a></li>
                </ul>
            </li>
            <li class="px-3">
                <a href="#others" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
                    <i class="oi oi-task"></i> その他
                </a>
                <ul class="px-3 collapse list-unstyled" id="others">
                    <li><a href="/menu-master-maintenance"><i class="oi oi-cog"></i> マスタ保守メニュー</a></li>
                    <li><a href="https://www.sanwa-clean.jp/"><i class="oi oi-external-lin"></i> サンワ ウェブサイト</a></li>
                </ul>
            </li>
            </ul>
          </b-nav>
        </nav>
      </div>
      <footer id='side-footer'>
        <div type="button" class="d-flex side-footer align-items-center px-3 py-2">
          <span class="mr-auto"><i class="oi oi-person"></i> {{userName}}</span>
        </div>
        <div type="button" class="d-flex side-footer footer-logout align-items-center px-3 py-2">
          <span class="mr-auto" @click="logOut"><i class="oi oi-account-logout"></i> ログアウト</span>
        </div>
      </footer>
    </b-sidebar>
    <!-- サイドメニュー ここまで -->
  </div>
</template>
<script>
import { Auth } from 'aws-amplify';
import { addOperationLogs, getControlMaster } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const';

const MODULE_NAME = 'header';

export default {
  name: 'Header',
  props: {
    type: { type: String },
    title: { type: String }
  },
  data() {
    return {
      showHamburger: true,
      FacilityName: '',
      ConnectedPhoneNumber: '',
      showSidebar: true,
      userName: '',
      BNavbarVariant: 'light',
      BNavbarType: 'light',
      message: null, // Array<{ variant: 'variant', icon: 'アイコン名', message: 'メッセージ' }>
    };
  },
  computed: {
    menuTitle() {
      return this.title;
    },
  },
  created() {
    if (this.title !== 'ログイン') this.getUserInfo();
  },
  /**
   * beforeMountライフサイクルフック
   */
  async beforeMount() {
    const functionName = 'beforeMount';

    // ログイン画面以外はシステム状態を確認します。
    if (this.$router.history.current.name !== 'LOGIN') {
      // コントロールマスタの情報を取得します。
      let result = null;
      try {
        result = await getControlMaster();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control'
        }, error);
        this.message = {
          variant: 'danger',
          message: DISP_MESSAGES.DANGER['3005']
        };
        return;
      }
      const mControl = result;

      // 月次更新／取引先コード切替／製品コード切替、請求／支払締更新、売価・仕入単価一括更新が実行中かどうかを確認します。
      if (mControl.monthly_update_state === Const.MonthlyUpdateStateClass.RUNNING) {
        this.message = {
          variant: 'warning',
          icon: 'oi oi-warning',
          message: DISP_MESSAGES.WARNING['2025']
        };
      } else if (mControl.switch_client_id_state === Const.ClientCodeSwitchStateClass.RUNNING) {
        this.message = {
          variant: 'warning',
          icon: 'oi oi-warning',
          message: DISP_MESSAGES.WARNING['2033']
        };
      } else if (mControl.switch_client_id_state === Const.ProductCodeSwitchStateClass.RUNNING) {
        this.message = {
          variant: 'warning',
          icon: 'oi oi-warning',
          message: DISP_MESSAGES.WARNING['2034']
        };
      } else if (mControl.closing_update_state === Const.ClosingUpdateStateClass.RUNNING) {
        this.message = {
          variant: 'warning',
          icon: 'oi oi-warning',
          message: DISP_MESSAGES.WARNING['2052']
        };
      } else if (mControl.products_bulk_update_state === Const.ClosingUpdateStateClass.RUNNING) {
        this.message = {
          variant: 'warning',
          icon: 'oi oi-warning',
          message: DISP_MESSAGES.WARNING['2060']
        };
      }
    }
  },
  methods: {
    logOut() {
      this.signOut();
    },
    async signOut() {
      try {
        await Auth.signOut();
        this.$router.push('/login');
      } catch (error) {
        console.log(error);
      }
    },
    async getUserInfo() {
      // ユーザー情報取得
      try {
        // const user = await Auth.currentUserInfo();
        // this.userName = user.username;
        // let condition = {where_clause: `AND login_id = '${user.username}'`};
        // let staffListResult = await API.graphql(graphqlOperation(list_m_staffs, condition));
        // let staffListData = staffListResult.data.list_m_staffs;
        // this.userName = staffListData[0].staff_name_kanji;
        this.userName = this.$store.getters.user.staff_name_kanji;
      } catch (error) {
        await addOperationLogs('Error', 'header', 'getUserInfo', {}, error);
      }
    },
  },
};
</script>
<style scoped>
#nav-bar {
  padding-right: 0px !important;
}
#nav-collapse {
  font-size: 14px;
}
#default-menuset {
  font-size: 14px;
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
    justify-content: flex-end;
  }
}
@media (max-width: 768px) {
  .navbar-collapse .navbar-nav {
    display: none;
  }
}
@media (max-width: 768px) {
  .user-area {
    display: none;
  }
}
/* ヘッダー部分のユーザー名：電話番号 */
.menu-user-label {
  display: block;
  width: 100%;
  padding: 0;
  border-top: 1px solid var(--secondary25p);
  border-bottom: 1px solid var(--secondary25p);
}
.menu-user-label .user-name {
  display: inline-block;
  padding: 0.15rem 1rem;
  background-color: var(--secondary10p);
}
.menu-user-label .user-telephone {
  display: inline-block;
  padding: 0.15rem 1rem;
  background-color: var(--secondary05p);
}
@media (max-width: 768px) {
  .menu-user-label .user-name {
    display: block;
    padding: 0.15rem 1rem;
    background-color: var(--secondary10p);
  }
  .menu-user-label .user-telephone {
    display: block;
    padding: 0.15rem 1rem;
    background-color: var(--secondary05p);
  }
}

/* サイドメニュー */
.side-footer {
  color: rgba(0,0,0,.55);
  font-size: 14px;
}

.side-footer i {
  color: var(--primary);
}

.footer-logout {
  background-color: var(--secondary10p);
}

@media (min-width: 768px) {
  #side-footer {
    display: none;
  }
}
</style>