<template>
<div>
  <Header :type="menu_type" :title="title" />
  <b-container class="px-4 py-4 min-vh-85" fluid>
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-card>
          <b-card-header v-if="getMessageFlg">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <p>請求前処理{{billingPaymentClass==htmlConst.BillingPaymentClass.billing2 ? addTitle2: addTitle1 }}で登録されたデータとその該当件数を表示します。</p>
            <p v-if="updateFlg==true">以下の条件の請求締更新は実施済みです。</p>
            <b-container>
              <b-row>
                <b-col lg="6">
                  <b-table
                    show-empty
                    :empty-text="'データが登録されていません。請求前処理' + (billingPaymentClass==htmlConst.BillingPaymentClass.billing2 ? addTitle2: addTitle1) + '画面で登録できます。'"
                    :fields="fields"
                    :items="results"
                  >
                  </b-table>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
          <!-- 削除ボタン -->
          <b-card-footer>
            <b-row class="justify-content-md-center pb-4">
              <b-col lg="2">
                <b-button pill block variant="danger" v-b-tooltip.hover.noninteractive title="表示中の請求マスタデータを全削除します。" @click="clearAlert(); clickDeleteBtn();" :disabled="results.length==0">
                  <span class="oi oi-delete"></span> 削除
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <Footer />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="['表示中の請求マスタデータを削除します。','よろしいですか？']" />
</div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, formatDate, formatDateCalc, addOperationLogs, executeTransactSqlList, executeSelectSql, isSystemEditable, executeSelectSqlNoLimit, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'invoice-check';

export default ({
  name: 'INVOICE-CHECK',
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  data() {
    return{
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      baseTitle: '請求マスタ状況チェック',
      title: '',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 検索結果
      results: [],
      updateFlg: false,
      // トランザクションSQLリスト
      transactSqlList: [],
      // パラメータ
      billingPaymentClass: 0,
      // テーブル名
      billingsTableName: '',
      // タイトル用の固定値
      addTitle1: '（本社）',
      addTitle2: '２（横浜）',
      // 定数（htmlで使用）
      htmlConst: {
        // 請求支払区分
        BillingPaymentClass: {
          // 請求
          billing: Const.BillingPaymentClass.billing,
          // 支払
          payment: Const.BillingPaymentClass.payment,
          // 請求2
          billing2: Const.BillingPaymentClass.billing2,
        },
      },
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    if (this.$route.query.billingPaymentClass == Const.BillingPaymentClass.billing2) {
      this.billingPaymentClass = Const.BillingPaymentClass.billing2;
      this.title = this.baseTitle + this.addTitle2;
      this.billingsTableName = 'm_billings2';
    } else {
      this.billingPaymentClass = Const.BillingPaymentClass.billing;
      this.title = this.baseTitle + this.addTitle1;
      this.billingsTableName = 'm_billings';
    }
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /** フィールド */
    fields() {
      return [
        {
          key    : 'invoiceCount',
          label  : '件数',
          tdClass: 'text-right',
        },
        {
          key     : 'closingDate',
          label   : '締日',
        },
        {
          key     : 'deadlineDate',
          label   : '締切日',
        },
      ];
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 初期データを設定
        await this.setInitData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      // 最初に初期化
      this.updateFlg = false;
      this.results = [];
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if(dataResult != null) {
        await this.setResult(dataResult);
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' (SELECT COUNT(*) FROM ' + this.billingsTableName + ') AS billings_cnt';
      selectSql += ',closing_update_range.closing_date';
      selectSql += ',closing_update_range.process_month_year';
      selectSql += ',closing_update_range.client_id_start';
      selectSql += ',closing_update_range.client_id_end';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_closing_update_range AS closing_update_range ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'closing_update_range.billing_payment_class = ' + this.billingPaymentClass + ' ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        let deadlineDate = null;
        if (result[0].closing_date == 99) {
          deadlineDate = formatDateCalc(('000000' + result[0].process_month_year).slice(-6) + '01', 0, 0, 0, true);
        } else {
          deadlineDate = formatDate(('000000' + result[0].process_month_year).slice(-6) + ('00' + result[0].closing_date).slice(-2));
        }
        let searchResult = {
          invoiceCount: result[0].billings_cnt.toLocaleString(),
          closingDate: result[0].closing_date,
          deadlineDate: deadlineDate,
        };
        this.results.push(searchResult);
        // 更新済みかどうかの値を設定
        this.updateFlg = await this.checkUpdateCompletion(result[0].process_month_year, result[0].closing_date, result[0].client_id_start, result[0].client_id_end);
      }
    },
    // 更新完了チェック
    async checkUpdateCompletion(processMonthYear, closingDate, clientIdStart, clientIdEnd) {
      let where_clause = '';
      where_clause += 'AND billing_payment_class = ' + Const.BillingPaymentClass.billing + ' ';
      where_clause += 'AND process_month_year = ' + processMonthYear + ' ';
      where_clause += 'AND closing_date = ' + closingDate + ' ';
      where_clause += 'ORDER BY client_id_start ';
      let resultData = await selectOneTable('t_closing_update_completion', where_clause);
      //console.log(resultData);
      if (resultData == null || resultData.length == 0) {
        // 指定した年月と締日でまだ締更新を行っていない
        return false;
      }
      // 得意先コードが重複しないかを確認するループ
      for (let i = 0; i < resultData.length; i++) {
        if ((resultData[i].client_id_start <= clientIdStart && clientIdStart <= resultData[i].client_id_end) ||
            (resultData[i].client_id_start <= clientIdEnd && clientIdEnd <= resultData[i].client_id_end) ||
            (clientIdStart <= resultData[i].client_id_start && resultData[i].client_id_start <= clientIdEnd) ||
            (clientIdStart <= resultData[i].client_id_end && resultData[i].client_id_end <= clientIdEnd)) {
          // 締更新済みの取引先コードと重複している部分あり
          return true;
        }
      }

      // 締更新済みの取引先コードと重複している部分なし
      return false;
    },
    /* 削除ボタン押下時 */
    async clickDeleteBtn() {
      //console.log('削除');
      this.$bvModal.show('confirmModal');
    },
    // 削除確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.execDeleteBtn();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 削除実行 */
    async execDeleteBtn() {
      const functionName = 'execDeleteBtn';
      // 最初に初期化
      this.transactSqlList = [];

      // 累積トランザクション削除（特別値引）のために前の請求前処理で設定した取引先を取得
      let selectSql = this.makeSelectSqlPreBillingsClients();
      let dataClientIdResult = await executeSelectSqlNoLimit(selectSql, 10000);
      if (dataClientIdResult != null && dataClientIdResult.length > 0) {
        // 累積トランザクション削除（特別値引）
        this.deleteCumulativeTransaction(dataClientIdResult);
      }
      // 請求マスタを削除
      let deleteSql = 'DELETE FROM ' + this.billingsTableName;
      //console.log(deleteSql);
      this.transactSqlList.push(deleteSql);
      // 締更新範囲を削除
      deleteSql = 'DELETE FROM t_closing_update_range WHERE billing_payment_class = ' + this.billingPaymentClass;
      //console.log(deleteSql);
      this.transactSqlList.push(deleteSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable(1);
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let retResult = await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.setInitData();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    // 検索SQL作成（更新前請求マスタの取引先）
    makeSelectSqlPreBillingsClients: function() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' billings.client_id';
      selectSql += ',billings.billing_end_date';
      selectSql += ',CASE WHEN billings_issue_input_site.client_id IS NULL THEN 0 ELSE 1 END AS input_site_flg';
      // FROM句
      selectSql += ' FROM ';
      selectSql += this.billingsTableName + ' AS billings ';
      selectSql += 'LEFT JOIN t_billings_issue_input_site AS billings_issue_input_site ';
      selectSql += 'ON billings_issue_input_site.billing_month_year = CAST(DATE_FORMAT(billings.billing_end_date,\'%Y%m\') AS SIGNED) ';
      selectSql += 'AND billings.client_id = billings_issue_input_site.client_id ';
      // WHERE
      selectSql += ' WHERE ';
      selectSql += 'NOT EXISTS (';
      selectSql += 'SELECT * FROM t_billings_issue_input AS billings_issue_input WHERE ';
      selectSql += 'billings_issue_input.billing_month_year = CAST(DATE_FORMAT(billings.billing_end_date,\'%Y%m\') AS SIGNED) ';
      selectSql += 'AND billings_issue_input.client_id = billings.client_id) ';
      // GROUP BY句
      selectSql += ' GROUP BY ';
      selectSql += ' billings.client_id';
      return selectSql;
    },
    /* 累積トランザクション削除SQL作成 */
    deleteCumulativeTransaction: function(dataClientIdResult) {
      //console.log('累積トランザクション削除SQL作成');
      let preBillingEndDate = dataClientIdResult[0].billing_end_date;
      let clientIdList = [];
      let clientIdInputSiteList = [];
      for (let i = 0; i < dataClientIdResult.length; i++) {
        if (dataClientIdResult[i].input_site_flg == 1) {
          clientIdInputSiteList.push(dataClientIdResult[i].client_id);
        } else {
          clientIdList.push(dataClientIdResult[i].client_id);
          if (clientIdList.length >= 7000) {
            let deleteSql = this.createDeleteCumulativeTransaction(preBillingEndDate, clientIdList);
            this.transactSqlList.push(deleteSql);
            clientIdList = [];
          }
        }
      }
      if (clientIdList.length > 0) {
        let deleteSql = this.createDeleteCumulativeTransaction(preBillingEndDate, clientIdList);
        this.transactSqlList.push(deleteSql);
      }
      if (clientIdInputSiteList.length > 0) {
        let deleteSql = this.createDeleteCumulativeTransactionInputSite(preBillingEndDate, clientIdInputSiteList);
        this.transactSqlList.push(deleteSql);
      }
    },
    // 累積トランザクションの削除SQL作成
    createDeleteCumulativeTransaction: function(preBillingEndDate, clientIdList) {
      let csvClientId = clientIdList.join(',');
      // 累積トランザクションの削除SQL
      let deleteSql = 'DELETE FROM ';
      deleteSql += 't_cumulative_transaction';
      deleteSql += ' WHERE ';
      deleteSql += 'product_id IN (' + Const.specialSalesDiscount.productId + ',' + Const.specialSalesDiscount.productIdLightTax + ',' + Const.specialSalesDiscount.productIdNoTax + ') ';
      deleteSql += 'AND billing_date = \'' + preBillingEndDate + '\' ';
      deleteSql += 'AND client_id IN (' + csvClientId + ') ';
      deleteSql += 'AND closing_month_year = 0 ';
      deleteSql += 'AND transaction_id = \'' + Const.TransactionId.sales + '\' ';
      //console.log(deleteSql);

      return deleteSql;
    },
    // 累積トランザクションの削除SQL作成（請求書発行登録済みの現場は削除しない）
    createDeleteCumulativeTransactionInputSite: function(preBillingEndDate, clientIdList) {
      let csvClientId = clientIdList.join(',');
      // 累積トランザクションの削除SQL
      let deleteSql = 'DELETE cumulative_transaction FROM ';
      deleteSql += 't_cumulative_transaction AS cumulative_transaction';
      deleteSql += ' WHERE ';
      deleteSql += 'cumulative_transaction.product_id IN (' + Const.specialSalesDiscount.productId + ',' + Const.specialSalesDiscount.productIdLightTax + ',' + Const.specialSalesDiscount.productIdNoTax + ') ';
      deleteSql += 'AND cumulative_transaction.billing_date = \'' + preBillingEndDate + '\' ';
      deleteSql += 'AND cumulative_transaction.client_id IN (' + csvClientId + ') ';
      deleteSql += 'AND cumulative_transaction.closing_month_year = 0 ';
      deleteSql += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      deleteSql += 'AND NOT EXISTS (';
      deleteSql += 'SELECT * FROM t_billings_issue_input_site AS billings_issue_input_site WHERE ';
      deleteSql += 'billings_issue_input_site.billing_month_year = ' + formatDate(preBillingEndDate,'YYYYMM') + ' ';
      deleteSql += 'AND billings_issue_input_site.client_id = cumulative_transaction.client_id ';
      deleteSql += 'AND billings_issue_input_site.site_id = cumulative_transaction.site_id) ';
      //console.log(deleteSql);

      return deleteSql;
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
  },
});
</script>
<style scoped>
</style>