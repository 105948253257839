<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
                  <strong> 売上修正（仮伝票）</strong>
                  <b-form-text class="text-muted d-inline-flex">(編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください)</b-form-text>
                </h5>
                <b-button pill v-b-tooltip.hover.bottom.noninteractive title="売上一覧(仮伝票)に戻る" @click="cancel" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </div>
            </b-media-body>
          </b-media>
          <div class="main-card card">
            <b-card-header v-if="getMessageFlg">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveData();" id="inputForm" class="form-horizontal">
                  <b-row>
                    <!-- 伝票番号 -->
                    <b-col lg="3">
                      <b-form-group label="伝票番号" label-for="billingNo">
                        <b-form-input id="billingNo" type="text" v-model="billingNo" readonly />
                      </b-form-group>
                    </b-col>
                    <!-- 営業所 -->
                    <b-col lg="3">
                      <b-form-group label="営業所" label-for="selectSalesOfficeName">
                        <b-input id="selectSalesOfficeName" readonly :value="`${selectSalesOffice}:${selectSalesOfficeName}`" />
                      </b-form-group>
                    </b-col>
                    <!-- 伝票日付 -->
                    <b-col lg="3">
                      <validation-provider name="dateBillingDate" rules="required" v-slot="{ classes,errors }">
                        <label for="dateBillingDate" v-b-tooltip.hover.noninteractive title="変更される場合、製品の売価が自動で取り直されます">
                          <span>伝票日付</span>
                          <b-form-text class="text-muted d-inline-flex">(変更される場合、売価...)</b-form-text>
                        </label>
                        <b-row :class="classes">
                          <b-col lg="12">
                            <b-input-group class="input-daterange">
                              <b-form-datepicker
                                id="dateBillingDate"
                                name="dateBillingDate"
                                v-model="dateBillingDate"
                                calendar-width="50%"
                                @input="refreshSalesAmount"
                              ></b-form-datepicker>
                            </b-input-group>
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <!-- 伝票種別 -->
                    <b-col lg="3">
                      <b-form-group label="伝票種別">
                        <b-select id="selectTradition" v-model="selectBillClass" :options="billClassOption"/>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <!-- 取引先コード -->
                    <b-col lg="6">
                      <validation-provider name="suppliersCode" rules="required|numeric|min:6|max:6" v-slot="{ classes,errors }">
                        <label for="suppliersCode">取引先コード<b-form-text class="text-muted d-inline-flex">(変更される場合、現場や製品売価等の関連項目が自動でクリアされます)</b-form-text></label>
                        <b-row :class="classes">
                          <b-col lg="8">
                            <b-input-group>
                              <b-form-input
                                type="text"
                                id="suppliersCode"
                                name="suppliersCode"
                                @input="searchClient(suppliers.code)" 
                                maxlength="6"
                                v-model="suppliers.code"
                              />
                              <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                              <b-button 
                                class="mr-1" 
                                size="sm" 
                                pill 
                                variant="success" 
                                title="取引先コードに入力された取引先の情報を照会します。（取引先コードが空欄以外の場合、利用可能です。）" 
                                :disabled="suppliers.code == ''" 
                                @click="clientInfoModal(suppliers.class, suppliers.code)">
                                <span class="oi oi-eye"></span> 情報
                              </b-button>
                            </b-input-group>
                          </b-col>
                          <b-col lg="8" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <!-- 締日 -->
                    <b-col lg="3">
                      <b-form-group label="締日" label-for="suppliersClosingDate">
                        <b-form-input type="text" id="suppliersClosingDate" v-model="suppliers.closingDate" readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                    <!-- 受注番号 -->
                    <b-col lg="3">
                      <b-form-group v-if="receivedOrderId != 0" label="受注番号" label-for="receivedOrderId">
                        <b-input id="receivedOrderId" readonly :value="receivedOrderId" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <!-- 取引先名 -->
                    <b-col lg="6">
                      <b-form-group label="取引先名" label-for="suppliersName">
                        <validation-provider name="suppliersName" rules="max:40" v-slot="{ classes,errors }">
                           <div :class="classes">
                            <b-form-input type="text" id="suppliersName" v-model="suppliers.name" maxlength="40" />
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                           </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- 郵便番号 -->
                    <b-col lg="3">
                      <label for="addressCode">郵便番号<b-form-text class="text-muted d-inline-flex">(｢nnn-nnnn｣形式)</b-form-text></label>
                      <validation-provider name="addressCode" rules="regex:^([0-9]{3}-[0-9]{4})$" v-slot="{ classes,errors }">
                          <div :class="classes">
                          <b-form-input type="text" id="addressCode" v-model="suppliers.zipCode" maxlength="8"  />
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </b-col>
                    <!-- 取引先製品単価適用チェック -->
                    <b-col lg="3">
                      <label for="isUnitPrice" v-b-tooltip.hover.noninteractive title='チェックONの場合、取引先や伝票日付の変更時に単価登録情報で売価を設定し直します。'>
                        単価適用チェック <span class="oi oi-flag"/>
                      </label>
                      <b-form-checkbox
                        id="isUnitPrice"
                        name="isUnitPrice"
                        v-model="isUnitPrice"
                        ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <!-- 取引先住所１ -->
                    <b-col lg="6">
                      <b-form-group label="取引先住所１" label-for="suppliersAddress1">
                        <validation-provider name="suppliersAddress1" rules="max:36" v-slot="{ classes,errors }">
                          <div :class="classes">
                            <b-form-input type="text" id="suppliersAddress1" v-model="suppliers.address1" maxlength="36"  />
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>         
                    <!-- 取引先住所２ -->
                    <b-col lg="6">
                      <b-form-group label="取引先住所２" label-for="suppliersAddress2">
                        <validation-provider name="suppliersAddress2" rules="max:36" v-slot="{ classes,errors }">
                          <div :class="classes">
                            <b-form-input type="text" id="suppliersAddress2" v-model="suppliers.address2" maxlength="36"  />
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <!-- 取引先部署１ -->
                    <b-col lg="3">
                      <b-form-group label="部署１" label-for="suppliersDepartment1">
                        <validation-provider name="suppliersDepartment1" rules="max:22" v-slot="{ classes,errors }">
                          <div :class="classes">
                            <b-form-input type="text" id="suppliersDepartment1" v-model="suppliers.department1" maxlength="22"  />
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- 取引先部署２ -->
                    <b-col lg="3">
                      <b-form-group label="部署２" label-for="suppliersDepartment2">
                        <validation-provider name="suppliersDepartment2" rules="max:22" v-slot="{ classes,errors }">
                          <div :class="classes">
                            <b-form-input type="text" id="suppliersDepartment2" v-model="suppliers.department2" maxlength="22"  />
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- 取引先TEL -->
                    <b-col lg="3">
                      <label for="suppliersTel">TEL</label>
                      <validation-provider name="suppliersTel" rules="phone" v-slot="{ classes,errors }">
                          <div :class="classes">
                          <b-form-input type="text" id="suppliersTel" v-model="suppliers.tel" maxlength="21"  />
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </b-col>
                    <!-- 担当者 -->
                    <b-col lg="3">
                      <b-form-group label="担当者" label-for="supplierPersonInCharge">
                        <b-form-input type="text" id="supplierPersonInCharge" v-model="suppliers.personInCharge" readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 製品 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :small="true"
                            :items="productList"
                            :fields="fields"
                          >
                            <template #table-caption>
                              <b-btn-toolbar>
                                <span class="mt-2 mr-2">&nbsp;製品テーブル</span> 
                                <!-- 行追加ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" type="button" @click="addTableRow()">
                                  <span class="oi oi-plus"></span> 行追加
                                </b-button>&nbsp;
                                <!-- 取引先製品単価履歴照会ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" title="取引先製品単価履歴照会モーダルを表示します。" @click="clickInquryClientProduct">
                                  <span class="oi oi-eye"></span> 取引先製品単価履歴照会
                                </b-button>
                              </b-btn-toolbar>
                            </template>
                            <template #cell(productCode)="data">
                              <validation-provider rules="required|numeric|min:8|max:8" v-slot="{ classes,errors }">
                                <div :class="classes" style="min-width: 130px;">
                                  <b-input-group>
                                    <b-form-input
                                      type="text"
                                      name="productId"
                                      size="sm"
                                      class="px-1"
                                      @input="searchProduct(data.item.productCode, data.index)"
                                      maxlength="8"
                                      :readonly="!checkClientId(suppliers.code)"
                                      v-model="data.item.productCode"
                                      style="width: 80px;"
                                    />
                                    <b-input-group-text @click="showProductSearchModal(data.index)" v-if="checkClientId(suppliers.code)">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                            <template #cell(productName)="data">
                              <validation-provider rules="max:35" v-slot="{ classes,errors }">
                                <div :class="classes" style="min-width: 240px;">
                                  <b-input-group>
                                    <span style="font-size: 20px;" v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.productTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark : '' }}</span>
                                    <b-form-input size="sm" class="px-1" type="text" name="productName" v-model="data.item.productName" :readonly="data.item.sundriesClass != 1" v-b-tooltip.hover.noninteractive.right="data.item.productName" />
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                            <template #cell(serviceClass)="data">
                              <b-input-group style="min-width: 110px;">
                                <b-form-select
                                  size="sm" 
                                  class="px-1"
                                  v-model="data.item.serviceClass"
                                  :options="serviceList"
                                  value-field="id"
                                  @change="changeService(data.index)"
                                />
                              </b-input-group>
                            </template>
                            <template #cell(quantity)="data">
                              <validation-provider :rules="{required: true, orders_receives_between: {orderReceiveBillClass: selectBillClass, productSundriesClass: data.item.sundriesClass, shippingQuantity: 0, isDeleted: 0}}" v-slot="{ classes,errors }">
                                <b-input-group style="min-width: 70px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="number" :id="'productQuantity' + data.index" name="productQuantity" v-model.number="data.item.quantity" @blur="calc(data.index)" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(unit)="data">
                              <validation-provider rules="max:5" v-slot="{ classes,errors }">
                                <b-input-group style="min-width: 45px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="text" name="productUnit" v-model="data.item.unit" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(sellingPrice)="data">
                              <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                                <b-input-group style="min-width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group class="d-flex align-items-center">
                                        <b-form-input size="sm" class="px-1" type="number" name="productSellingPrice" v-model.number="data.item.sellingPrice" @blur="calc(data.index)" />
                                        &nbsp;
                                        <b style="font-size: 20px;">{{ data.item.clientAmountClass }}</b>
                                      </b-input-group>
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(purchasePrime)="data">
                              <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                                <b-input-group style="min-width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="number" name="productPurchasePrime" v-model.number="data.item.purchasePrime" @blur="calc(data.index)" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(money)="data">
                              {{ data.item.money.toLocaleString() }}
                              <validation-provider :rules="{set_val_between:{min:-99999999,max:99999999,title:'金額'}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <input type="hidden" name="money" v-model.number="data.item.money" />
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                              <validation-provider :rules="{set_val_between:{min:-99999999,max:99999999,title:'仕入金額'}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <input type="hidden" name="totalPurchasePrime" v-model.number="data.item.totalPurchasePrime" />
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(delete)="data">
                              <b-button size="sm" v-b-tooltip.hover.noninteractive title="この行を削除をします。" @click="deleteBtn(data.index)">
                                <span class="oi oi-delete"></span> 削除
                              </b-button>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form>
                            <b-form-text label-align="left" class="text-muted">取引先を指定すると製品の検索入力可能になります。</b-form-text>
                          </b-form>
                        </b-col>
                        <b-col>
                          <b-form>
                            <b-form-group :label="textTotalMoneyText" label-align="right"></b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 納品指定日 -->
                    <b-col lg="3">
                      <b-form-group label="納品指定日" label-for="orderDate">
                        <b-input-group>
                          <b-form-datepicker id="orderDate" v-model="orderDate"></b-form-datepicker>
                          <b-input-group-append>
                            <b-button size="sm" variant="outline-secondary" @click="orderDate=''">
                              <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- 納品指定区分プルダウン -->
                    <b-col lg="3">
                      <b-form-group label="納品指定区分" label-for="selectDeliveryDesignation">
                        <b-form-select
                          id="selectDeliveryDesignation"
                          v-model="selectDeliveryDesignation"
                          :options="deliveryDesignationOption"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- 納品指定 -->
                    <b-col lg="6">
                      <validation-provider name="deliveryNote" rules="max:34" v-slot="{ classes,errors }">
                        <b-form-group label="納品指定" label-for="deliveryNote">
                          <b-row>
                            <b-col lg="12">
                              <b-form-input id="deliveryNote" name="deliveryNote" type="text" v-model="deliveryNote" maxlength="34" />
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col lg="3">
                      <validation-provider name="onSiteCode" rules="required" v-slot="{ classes,errors }">
                        <label for="onSiteCode" v-b-tooltip.hover.noninteractive title="取引先を指定すると検索入力可能になります">
                          <span>現場コード</span>
                          <b-form-text class="text-muted d-inline-flex">(取引先を指定する...)</b-form-text>
                        </label>
                        <b-row :class="classes">
                          <b-col lg="12">
                            <b-input-group>
                              <b-form-input
                                id="onSiteCode"
                                name="onSiteCode"
                                type="text"
                                v-model="onSite.code"
                                @change="searchSite(onSite.code)" 
                                maxlength="4" 
                                :readonly="!checkClientId(suppliers.code)"
                              />
                              <b-input-group-text @click="showSiteSearchModal()" v-if="checkClientId(suppliers.code)" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '2']" @shortkey="showSiteSearchModal()" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                            </b-input-group>
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <b-col lg="3">
                      <!-- 配送コード -->
                      <b-form-group label="配送コード" label-for="onSiteDeliveryCode">
                        <b-form-input type="text" id="onSiteDeliveryCode" readonly v-model="onSite.deliveryCode" />
                      </b-form-group>
                    </b-col>
                    <b-col lg="3">
                      <validation-provider name="onSiteZipCode" rules="zip_code" v-slot="{ classes,errors }">
                        <!-- 現場郵便番号 -->
                        <label for="onSiteZipCode" v-b-tooltip.hover.noninteractive title="「nnn-nnnn」形式で入力してください。現場コードを指定すると入力中の情報が上書きされます。ご注意下さい。">
                          <span>郵便番号</span>
                          <b-form-text class="text-muted d-inline-flex">(｢nnn-nnnn｣形式で...)</b-form-text>
                        </label>
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="onSiteZipCode" name="onSiteZipCode" v-model="onSite.zipCode"></b-form-input>
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col lg="6">
                      <validation-provider name="onSiteName" rules="max:50" v-slot="{ classes,errors }">
                        <!-- 現場名（漢字） -->
                        <label for="onSiteName">現場名<b-form-text class="text-muted d-inline-flex">(現場コードを指定すると入力中の情報が上書きされます。ご注意下さい)</b-form-text></label>
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="onSiteName" name="onSiteName" v-model="onSite.name"  maxlength="50" />
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <b-col lg="6">
                      <!-- 印刷用宛名 -->
                      <b-form-group label="印刷用宛名" label-for="clientPrintNameText">
                        <b-form-input type="text" id="clientPrintNameText" readonly v-model="onSite.clientPrintNameText" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col lg="6">
                      <validation-provider name="onSiteAddress1" rules="max:34" v-slot="{ classes,errors }">
                        <!-- 現場住所１ -->
                        <label for="onSiteAddress1">住所１<b-form-text class="text-muted d-inline-flex">(現場コードを指定すると入力中の情報が上書きされます。ご注意下さい)</b-form-text></label>
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="onSiteAddress1" name="onSiteAddress1" v-model="onSite.address1" maxlength="34" />
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <b-col lg="6">
                      <validation-provider name="onSiteAddress2" rules="max:34" v-slot="{ classes,errors }">
                        <!-- 現場住所２ -->
                        <label for="onSiteAddress2">住所２<b-form-text class="text-muted d-inline-flex">(現場コードを指定すると入力中の情報が上書きされます。ご注意下さい)</b-form-text></label>
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="onSiteAddress2" name="onSiteAddress2" v-model="onSite.address2" maxlength="34" />
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col lg="6">
                      <validation-provider name="onSiteDeliveryPlace" rules="max:54" v-slot="{ classes,errors }">
                        <!-- 納品場所 -->
                        <label for="onSiteDeliveryPlace">納品場所<b-form-text class="text-muted d-inline-flex">(現場コードを指定すると入力中の情報が上書きされます。ご注意下さい)</b-form-text></label>
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="onSiteDeliveryPlace" name="onSiteDeliveryPlace" v-model="onSite.deliveryPlace" maxlength="54" />
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <b-col lg="6">
                      <!-- チェック -->
                      <b-form-group label="チェック内容" label-for="onSiteCheck">
                        <b-form-input type="text" id="onSiteCheck" readonly v-model="suppliers.check"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col lg="3">
                      <validation-provider name="onSiteTel" rules="phone" v-slot="{ classes,errors }">
                        <!-- 現場TEL -->
                        <label for="onSiteTel" v-b-tooltip.hover.noninteractive title="現場コードを指定すると入力中の情報が上書きされます。ご注意下さい">
                          <span>TEL</span>
                          <b-form-text class="text-muted d-inline-flex">(情報が上書き...)</b-form-text>
                        </label>
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="onSiteTel" name="onSiteTel" v-model="onSite.tel" maxlength="21" />
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <!-- 処理日 -->
                    <b-col lg="3">
                      <validation-provider name="dateProcessDate" rules="required" v-slot="{ classes,errors }">
                        <label for="dateProcessDate">処理日<b-form-text class="text-muted d-inline-flex">(必須項目です)</b-form-text></label>
                        <b-row>
                          <b-col lg="12" :class="classes">
                            <b-input-group class="input-daterange">
                              <b-form-datepicker
                                id="dateProcessDate"
                                name="dateProcessDate"
                                v-model="dateProcessDate"
                                calendar-width="50%"
                              ></b-form-datepicker>
                            </b-input-group>
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <!-- 処理担当者 -->
                    <b-col lg="3">
                      <validation-provider name="orderReceiveStaff" rules="required" v-slot="{ classes,errors }">
                        <b-form-group label="処理担当者" label-for="orderReceiveStaff">
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-input-group>
                                <b-form-input type="text" id="orderReceiveStaff" name="orderReceiveStaff" v-model="orderReceiveStaff.text" readonly />
                                <b-input-group-text @click="showStaffSearchModal" v-b-tooltip.hover.noninteractive title="「ALT+3」ボタンで呼出可能">
                                  <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                    <span class="oi oi-magnifying-glass"></span>
                                    <button type="button" v-shortkey="['alt', '3']" @shortkey="showStaffSearchModal" class="d-none"></button>
                                  </b-button>
                                </b-input-group-text>
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- 伝票発行の有無 -->
                    <b-col lg="3">
                      <b-form-group label="伝票発行の有無" label-for="isPrint">
                        <b-form-checkbox id="isPrint" name="isPrint" v-model="isPrint"/>
                        <b-form-text class="text-muted">チェックされている場合、保存後に伝票が発行されます。</b-form-text>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- 保存ボタン -->
                  <b-row class="justify-content-md-center">
                    <b-col lg="2">
                      <b-button pill variant="success" type="submit" form="inputForm" class="btn btn-block" @click="delListLastBlankRow"><span class="oi oi-circle-check"></span> 保存</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●担当者検索モーダル●●● -->
    <STAFFSEARCH @from-child="closeStaffSearchModal"/>
    <!-- ●●●取引先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="propClientClass"/>
    <!-- ●●●取引先照会モーダル●●● -->
    <CLIENTINQUIRY :clientProp="propClientInquiry"/>
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :product-search-prop="productSearchProp"/>
    <!-- ●●●現場検索モーダル●●● -->
    <SITESEARCH @from-child="closeSiteSearchModal" :client-info="siteSearchInfo" />
    <!-- ●●●取引先製品単価履歴照会モーダル●●● -->
    <CLIENTPRODUCTINQUIRY :clientProductProp="clientProductProp" />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import STAFFSEARCH from '@/components/modal/staff-search.vue';
import { searchProductsModal, searchSiteModal, searchClientModal } from '@/assets/js/searchModal.js';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import SITESEARCH from '@/components/modal/site-search.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import CLIENTPRODUCTINQUIRY from '@/components/modal/client-product-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { executeTransactSql } from '@/graphql/mutations';
import { init, addOperationLogs, formatDate, checkClientId, getListValue, getNullStr, getControlMaster, formatDateCalc, CreateInsertSql, CreateColRow, calcTaxNew, escapeQuote, getUserCol, isSystemEditable, refreshSalesAmountProductList, executeSelectSql, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';

const MODULE_NAME = 'tentative-sales-edit';

export default {
  name: 'TENTATIVE-SALES-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    PRODUCTSEARCH,
    SITESEARCH,
    CLIENTSEARCH,
    CLIENTINQUIRY,
    STAFFSEARCH,
    CLIENTPRODUCTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '売上修正（仮伝票）',
      username: '',
      // 取引先コード確認関数
      checkClientId: checkClientId,
      // アラート
      alertWarning: [],
      alertDanger: [],
      // 処理結果成功メッセージ
      successMessages: [],
      // 営業所
      selectSalesOffice: '',
      selectSalesOfficeName: '',
      // 伝票日付
      dateBillingDate: '',
      // 伝票種別
      selectBillClass: null,
      // 伝票種別Option
      billClassOption: [],
      // 受注番号
      receivedOrderId: 0,
      // 取引先系
      suppliers: {
        closingDate: '',
        code: '',
        name: '',
        class: '',
        address1: '',
        address2: '',
        zipCode: '',
        department1: '',
        department2: '',
        tel: '',
        personInChargeId: '',
        personInCharge: '',
        sundriesClass: '',
        firstDigit: '',
        taxClass: '',
        taxCalculationClass: '',
        taxFracClass: '',
        check: '',
      },
      // 納品指定日
      orderDate: '',
      // 納品指定区分
      selectDeliveryDesignation: null,
      deliveryDesignationOption: [],
      // 納品メモ
      deliveryNote: '',
      // 現場系
      onSite: {
        deliveryCode: '',
        code: '',
        name: '',
        zipCode: '',
        address1: '',
        address2: '',
        deliveryPlace: '',
        tel: '',
        clientPrintClass: '',
        clientPrintName: '',
        clientPrintNameText: '',
      },
      // 処理日
      dateProcessDate: '',
      // 処理担当者
      orderReceiveStaff: {
        id: '',
        text: '',      
      },
      // 取引先モーダル用の引数, 得意先
      propClientClass: Const.ClientClass.customer,
      // テーブル定義
      productList: [],
      // 確認ダイアログ用
      confirmMessage: [],
      // 製品ダイアログ用
      dataIndex: 0,
      totalFee: 0,
      totalPurchasePrime: 0,
      grossProfit: 0,
      subTotalNormal: 0,
      subTotalLight: 0,
      // サービス区分
      serviceList: [],
      // 製品検索情報
      productSearchProp: {
        office_id: '',
        office_name: '',
        client_id: '',
        client_class: '',
        client_name: '',
        multiSelectFlg: '1',
        kijunDate: '',
      },
      // 現場検索情報
      siteSearchInfo: {
        client_id: '',
        client_name: '',
      },
      // 伝票発行の有無
      isPrint: true,
      // 取引先製品単価適用の有無
      isUnitPrice: false,
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        lightTaxMark: '',
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 取引先別製品照会に渡すパラメータ
      clientProductProp: {
        clientClass: null,
        clientId: null,
        clientNameKanji: '',
        productIdCsv: '',
      },
      // 別タブを開く
      newWin: null,
      // 定数（htmlで使用）
      htmlConst: {
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // パラメータ
      billingNo: 0,
      parentKbn: 0,
    }
  },
  watch: {
    // 納品指定区分変更時
    selectDeliveryDesignation: {
      handler(selectDeliveryDesignation,oldVal) {
        if (oldVal != null) {
          // 区分値を納品指定に設定
          const deliveryDesignation = this.deliveryDesignationOption.find((deliveryDesignation) => deliveryDesignation.value == selectDeliveryDesignation)
          this.deliveryNote = deliveryDesignation.text
        }
      },
      immediate: false
    },
  },
  computed:{
    fields: function() {
      return [
        {
          key: 'productCode',
          label: '製品コード',
        },
        {
          key: 'productName',
          label: '製品名',
        },
        {
          key: 'serviceClass',
          label: 'サービス区分',
        },
        {
          key: 'quantity',
          label: '数量',
        },
        {
          key: 'unit',
          label: '単位',
        },
        {
          key: 'sellingPrice',
          label: '売価',
        },
        {
          key: 'purchasePrime',
          label: '仕入単価',
        },
        {
          key: 'money',
          label: '金額',
          tdClass: 'text-right',
        },
        {
          key: 'delete',
          label: ' ',
        },
      ]
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertWarning.length > 0 || this.alertDanger.length > 0 || this.successMessages.length > 0
    },
    /* 合計値のテキスト */
    textTotalMoneyText: function() {
      let retTotalMoneyText = '粗利：' + (!Number.isFinite(this.grossProfit) ? 0 : this.grossProfit) + ' %';
      retTotalMoneyText += '　';
      retTotalMoneyText += '合計仕入単価：' + this.totalPurchasePrime.toLocaleString() + ' 円';
      retTotalMoneyText += '　';
      retTotalMoneyText += '合計金額：' + this.totalFee.toLocaleString() + ' 円';
      return retTotalMoneyText;
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.billingNo = this.$route.query.billingNo;
    this.parentKbn = this.$route.query.parentKbn;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // #region  事前取得データ
        // ログインユーザーの情報を取得
        let user = store.getters.user;
        this.username = user.username;
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let deliveryListData = null;
        let serviceListData = null;
        let controlData = null;
        [deliveryListData, serviceListData, controlData] = await Promise.all([
          selectOneTable('m_deliverys_designs_classes'),
          selectOneTable('m_service_classes'),
          getControlMaster(),
        ]);
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        for(let i = 0; i < serviceListData.length; i++) {
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // 納品指定区分を取得
        this.deliveryDesignationOption.push({value: '', text: ''});
        for(let i = 0; i < deliveryListData.length; i++) {
          this.deliveryDesignationOption.push({
            value: deliveryListData[i].delivery_design_class,
            text: `${deliveryListData[i].delivery_design_class}：${deliveryListData[i].delivery_design_name}`,
          });
        }
        // 現在処理年月、消費税率取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.taxRate = controlData.tax_rate;
        this.controlMasterData.newTaxRate = controlData.new_tax_rate;
        this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
        this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
        this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 入力画面からの遷移の場合
        if (this.parentKbn == 1) {
          this.successMessages.push(DISP_MESSAGES.SUCCESS['1001']);
        }
        // #endregion 

        // 初期データ
        const initDataList = await this.getInitData()
        const initData = initDataList[0]
        if (initData.order_receive_id != 0) {
          this.receivedOrderId = initData.order_receive_id
        }
        // 伝票種別
        this.billClassOption = Const.TraditionListValue.filter((tradition) => {
          return tradition.value == Const.Tradition.salesNormal || 
              tradition.value == Const.Tradition.salesReturn || 
              tradition.value == Const.Tradition.salesDirect
        })
        // 伝票番号初期値セット
        this.selectBillClass = initData.bill_class
        // 営業所データ初期値セット
        this.selectSalesOffice = initData.office_id
        this.selectSalesOfficeName = initData.office_name_kanji
        // 伝票日付データ初期値セット
        this.dateBillingDate= initData.billing_date
        // 取引先コード
        this.suppliers.code = initData.client_id
        // 締日
        if (initData.closing_date == 99) {
          this.suppliers.closingDate = formatDateCalc(String(this.controlMasterData.processMonthYear) + '01', 0, 0, 0, true);
        } else {
          // todo test
          this.suppliers.closingDate = formatDate(String(this.controlMasterData.processMonthYear) + initData.closing_date);
        }
        // 取引先名
        this.suppliers.name = initData.client_name
        // 郵便番号
        this.suppliers.zipCode = initData.client_zipCode
        // 取引先住所１
        this.suppliers.address1 = initData.client_address_1
        // 取引先住所２
        this.suppliers.address2 = initData.client_address_2
        // 部署１
        this.suppliers.department1 = initData.client_department_1
        // 部署２
        this.suppliers.department2 = initData.client_department_2
        // TEL
        this.suppliers.tel = initData.client_phone_number
        // 担当者
        this.suppliers.personInChargeId = initData.staff_id
        this.suppliers.personInCharge = initData.staff_id + ':' + initData.staff_name_kanji
        // 税額端数計算処理区分
        this.suppliers.taxFracClass = initData.tax_fractionation_class
        // 税額端数計算処理区分
        this.suppliers.taxFracClass = initData.tax_fractionation_class
        // 税額計算区分
        this.suppliers.taxCalculationClass = initData.tax_calculation_class
        //  売上課税区分
        this.suppliers.taxClass = initData.sales_tax_class
        //  取引先分類コード
        this.suppliers.firstDigit = initData.client_id_first_digit
        // 製品諸口区分
        this.suppliers.sundriesClass = initData.product_mouth_class
        // 取引先区分
        this.suppliers.class = initData.client_class

        // 製品テーブル初期表示データ用意
        initDataList.forEach(item => {
          this.productList.push({
            billingRow: item.billing_row,
            productCode: item.product_id,
            productClass: item.product_class_id,
            productName: item.product_name_kanji,
            productTaxRateClass: item.product_tax_rate_class_sales,
            productCheckClass: item.product_check_class,
            _cellVariants: { productName: getListValue(Const.ProductCheckClass, item.product_check_class, 'value', 'color') },
            serviceClass: item.service_class,
            quantity: Number(item.product_quantity),
            unit: item.unit,
            sellingPrice: Number(item.product_sales_unit_price),
            clientAmountClass: item.client_amount_class,
            purchasePrime: Number(item.product_purchase_price),
            sundriesClass: item.product_mouth_class,
            inventoryClass: item.inventory_control_class,
            totalPurchasePrime: Number(item.product_purchase_amount),
            money: Number(item.product_quantity) * Number(item.product_sales_unit_price),
          })
        });
        // 初期データの計算を行う
        this.calc(0)
        // 最終行を空行とする
        this.addTableRow();

        // 納品指定日
        this.orderDate = initData.delivery_design_date
        // 納品指定区分
        this.selectDeliveryDesignation = initData.delivery_design_class
        // 納品指定
        this.deliveryNote = initData.delivery_design

        // 現場コード
        this.onSite.code = initData.site_id
        // 配送コード
        this.onSite.deliveryCode = initData.shipping_code
        // 現場名
        this.onSite.name = initData.client_site_name_kanji
        // 印刷用宛名
        this.onSite.clientPrintClass = Number(initData.order_print_class)
        this.onSite.clientPrintName = initData.order_print_client_name
        this.onSite.clientPrintNameText = this.onSite.clientPrintClass == 0 ? '' : initData.order_print_client_name
        // 現場郵便番号
        this.onSite.zipCode = initData.client_site_zip_code
        // 住所１
        this.onSite.address1 = initData.client_site_address_1
        // 住所２
        this.onSite.address2 = initData.client_site_address_2
        // 納品場所
        this.onSite.deliveryPlace = initData.delivery_location
        // TEL
        this.onSite.tel = initData.client_site_phone_number

        // チェック内容
        this.suppliers.check = initData.check_contents
        // 処理日付
        this.dateProcessDate = initData.process_date
        // 処理担当者
        this.orderReceiveStaff.id = initData.order_receive_staff_id
        this.orderReceiveStaff.text = initData.order_receive_staff_id + ':' + initData.order_receive_staff_name_kanji
     
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg) scrollTo(0,0);
      this.$store.commit('setLoading', false);
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        // 入力チェックが完了した場合、保存確認ダイアログを表示
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg && this.successMessages.length == 0) {
        if (this.isPrint) {
          // タブを閉じる
          this.newWin.close()
        }
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg){
        // ios safari ための対応
        setTimeout(() => {
          scrollTo(0,0)
        }, 500);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      if (this.isPrint) {
        // 別タブ事前に開く
        this.newWin = window.open('', '_blank');
      }
      // データの登録(SQLの作成)
      if (await this.execSave() == true) {
        if (this.isPrint) {
          // 納品書PDF出力
          this.outputSalesDirectPdf();
        }
        this.successMessages.push(DISP_MESSAGES.SUCCESS['1003']);
      }
      //console.log('保存処理終了');
    },
    /* 保存実行 */
    async execSave() {
      let retResult = false;
      // 累積トランザクションの更新
      retResult = await this.insertAndUpdateCumulativeTransaction();

      return retResult;
    },
    /* 検索SQL（初期値） */
    async getInitData() {
      let functionName = 'getInitData'
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.billing_no';
      selectSql += ',cumulative_transaction.billing_row';
      selectSql += ',cumulative_transaction.bill_class';
      selectSql += ',cumulative_transaction.office_id';
      selectSql += ',offices.office_name_kanji';
      selectSql += ',cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.client_class';
      selectSql += ',cumulative_transaction.client_id';
      selectSql += ',clients.closing_date';
      selectSql += ',cumulative_transaction.client_name';
      selectSql += ',cumulative_transaction.client_zipCode';
      selectSql += ',cumulative_transaction.client_address_1';
      selectSql += ',cumulative_transaction.client_address_2';
      selectSql += ',cumulative_transaction.client_department_1';
      selectSql += ',cumulative_transaction.client_department_2';
      selectSql += ',cumulative_transaction.client_phone_number';
      selectSql += ',clients.sundries_class';
      selectSql += ',cumulative_transaction.client_id_first_digit';
      selectSql += ',clients.sales_tax_class';
      selectSql += ',clients.tax_calculation_class';
      selectSql += ',clients.tax_fractionation_class';
      selectSql += ',cumulative_transaction.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',clients.check_contents';
      selectSql += ',cumulative_transaction.order_receive_staff_id';
      selectSql += ',order_receive_staffs.staff_name_kanji AS order_receive_staff_name_kanji';
      selectSql += ',cumulative_transaction.process_date';
      selectSql += ',cumulative_transaction.delivery_design_date';
      selectSql += ',cumulative_transaction.delivery_design_class';
      selectSql += ',cumulative_transaction.delivery_design';
      selectSql += ',cumulative_transaction.site_id';
      selectSql += ',cumulative_transaction.shipping_code';
      selectSql += ',cumulative_transaction.client_site_name AS client_site_name_kanji';
      selectSql += ',cumulative_transaction.client_site_zip_code';
      selectSql += ',cumulative_transaction.client_site_address_1';
      selectSql += ',cumulative_transaction.client_site_address_2';
      selectSql += ',cumulative_transaction.delivery_location';
      selectSql += ',cumulative_transaction.client_site_phone_number';
      selectSql += ',IfNull(clients_sites.order_print_class,' + Const.OrderPrintClass.customerPrint + ') AS order_print_class';
      selectSql += ',IfNull(clients_sites.order_print_client_name,\'\') AS order_print_client_name';
      selectSql += ',cumulative_transaction.product_id';
      selectSql += ',cumulative_transaction.product_name AS product_name_kanji';
      selectSql += ',cumulative_transaction.product_check_class';
      selectSql += ',cumulative_transaction.service_class';
      selectSql += ',cumulative_transaction.product_class_id';
      selectSql += ',cumulative_transaction.product_quantity';
      selectSql += ',cumulative_transaction.product_unit AS unit';
      selectSql += ',cumulative_transaction.product_sales_unit_price';
      selectSql += ',cumulative_transaction.client_amount_class';
      selectSql += ',cumulative_transaction.product_purchase_price';
      selectSql += ',cumulative_transaction.product_purchase_amount';
      selectSql += ',cumulative_transaction.product_mouth_class';
      selectSql += ',cumulative_transaction.inventory_control_class';
      selectSql += ',cumulative_transaction.order_receive_id';
      selectSql += ',products.product_tax_rate_class_sales';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction_tentative AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON cumulative_transaction.client_class = clients.client_class ';
      selectSql += 'AND cumulative_transaction.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON cumulative_transaction.staff_id = staffs.staff_id ';
      selectSql += 'LEFT JOIN m_offices AS offices ';
      selectSql += 'ON cumulative_transaction.office_id = offices.office_id ';
      selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
      selectSql += 'ON cumulative_transaction.client_id = clients_sites.client_id ';
      selectSql += 'AND cumulative_transaction.site_id = clients_sites.site_id ';
      selectSql += 'LEFT JOIN m_staffs AS order_receive_staffs ';
      selectSql += 'ON cumulative_transaction.order_receive_staff_id = order_receive_staffs.staff_id ';
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON cumulative_transaction.product_id = products.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID
      selectSql += ' cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      // 引数の伝票番号で絞り込む
      selectSql += 'AND cumulative_transaction.billing_no = ' + this.billingNo + ' ';
      // 消費税行は出さない
      selectSql += 'AND cumulative_transaction.product_id <> 0 ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY cumulative_transaction.billing_row ';
      
      let SQLs = selectSql
      let result = null
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
        }, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0];
    },
    /* 仮累積トランザクション更新 */
    async insertAndUpdateCumulativeTransaction() {
      const functionName = 'insertAndUpdateCumulativeTransaction';
      //console.log('仮累積トランザクション更新');
      let tax = 0;
      if (this.suppliers.taxCalculationClass == Const.TaxCalculationClass.salesSlip) {
        // 税額計算区分が1の場合、消費税を計算
        tax = calcTaxNew(this.dateBillingDate, this.subTotalNormal, this.subTotalLight, this.controlMasterData.taxRate, this.controlMasterData.newTaxRate, this.controlMasterData.lightTaxRate, this.controlMasterData.newLightTaxRate, this.controlMasterData.newTaxStartDate);
      }
      let sqlInsertCol = ''
      let sqlInsertVal = []

      for (let i = 0; i < this.productList.length + 1; i++) {
        const product = this.productList[i]
        const colList = [];
        // 製品行の行数分＋税額計算区分が1の場合の消費税行分
        if (i < this.productList.length || this.suppliers.taxCalculationClass == Const.TaxCalculationClass.salesSlip) {
          // ﾄﾗﾝｻﾞｸｼｮﾝID
          colList.push(CreateColRow('transaction_id', Const.TransactionId.sales, 'VARCHAR'));
          // 伝票No
          colList.push(CreateColRow('billing_no', this.billingNo, 'NUMBER'));
          // 行No
          colList.push(CreateColRow('billing_row', i + 1, 'NUMBER'));
          // 伝票日付
          colList.push(CreateColRow('billing_date', this.dateBillingDate, 'DATE'));
          // 伝票種別
          colList.push(CreateColRow('bill_class', this.selectBillClass, 'NUMBER'));
          // 営業所コード
          colList.push(CreateColRow('office_id', this.selectSalesOffice, 'NUMBER'));
          // 取引先区分
          colList.push(CreateColRow('client_class', this.suppliers.class, 'NUMBER'));
          // 取引先コード
          colList.push(CreateColRow('client_id', this.suppliers.code, 'NUMBER'));
          // 取引先名
          colList.push(CreateColRow('client_name', await escapeQuote(this.suppliers.name), 'VARCHAR'));
          // 取引先郵便番号
          colList.push(CreateColRow('client_zipCode', await escapeQuote(this.suppliers.zipCode), 'VARCHAR'));
          // 取引先住所１
          colList.push(CreateColRow('client_address_1', await escapeQuote(this.suppliers.address1), 'VARCHAR'));
          // 取引先住所２
          colList.push(CreateColRow('client_address_2', await escapeQuote(this.suppliers.address2), 'VARCHAR'));
          // 部署１
          colList.push(CreateColRow('client_department_1', await escapeQuote(this.suppliers.department1), 'VARCHAR'));
          // 部署２
          colList.push(CreateColRow('client_department_2', await escapeQuote(this.suppliers.department2), 'VARCHAR'));
          // TEL
          colList.push(CreateColRow('client_phone_number', await escapeQuote(this.suppliers.tel), 'VARCHAR'));
          // 納品指定日
          colList.push(CreateColRow('delivery_design_date', this.orderDate, 'DATE'));
          // 納品指定区分
          colList.push(CreateColRow('delivery_design_class', await escapeQuote(this.selectDeliveryDesignation), 'VARCHAR'));
          // 納品指定
          colList.push(CreateColRow('delivery_design', await escapeQuote(this.deliveryNote), 'VARCHAR'));
          // 現場コード
          colList.push(CreateColRow('site_id', this.onSite.code, 'NUMBER'));
          // 配送コード
          colList.push(CreateColRow('shipping_code', await escapeQuote(this.onSite.deliveryCode), 'VARCHAR'));
          // 現場名称
          colList.push(CreateColRow('client_site_name', await escapeQuote(this.onSite.name), 'VARCHAR'));
          // 現場郵便番号
          colList.push(CreateColRow('client_site_zip_code', await escapeQuote(this.onSite.zipCode), 'VARCHAR'));
          // 現場住所1
          colList.push(CreateColRow('client_site_address_1', await escapeQuote(this.onSite.address1), 'VARCHAR'));
          // 現場住所2
          colList.push(CreateColRow('client_site_address_2', await escapeQuote(this.onSite.address2), 'VARCHAR'));
          // 納品場所
          colList.push(CreateColRow('delivery_location', await escapeQuote(this.onSite.deliveryPlace), 'VARCHAR'));
          // 現場TEL
          colList.push(CreateColRow('client_site_phone_number', await escapeQuote(this.onSite.tel), 'VARCHAR'));
          // 処理日付
          colList.push(CreateColRow('process_date', this.dateProcessDate, 'DATE'));
          // 受注処理担当者コード
          colList.push(CreateColRow('order_receive_staff_id', this.orderReceiveStaff.id, 'NUMBER'));
          // 担当者コード
          colList.push(CreateColRow('staff_id', this.suppliers.personInChargeId, 'NUMBER'));
          // 取引先分類コード
          colList.push(CreateColRow('client_id_first_digit', this.suppliers.firstDigit, 'NUMBER'));
          // 製品テーブル
          if (i == this.productList.length) {
            // 消費税行（追加）
            // 製品コード
            colList.push(CreateColRow('product_id', 0, 'NUMBER'));
            // 製品名
            colList.push(CreateColRow('product_name', '', 'VARCHAR'));
            // 製品チェック区分
            colList.push(CreateColRow('product_check_class', 0, 'NUMBER'));
            // サービス区分
            colList.push(CreateColRow('service_class', '', 'VARCHAR'));
            // 製品数量
            colList.push(CreateColRow('product_quantity', 0, 'NUMBER'));
            // 製品単位
            colList.push(CreateColRow('product_unit', '', 'VARCHAR'));
            // 製品売上単価
            colList.push(CreateColRow('product_sales_unit_price', 0, 'NUMBER'));
            // 製品仕入単価
            colList.push(CreateColRow('product_purchase_price', 0, 'NUMBER'));
            // 製品売上金額
            colList.push(CreateColRow('product_sales_unit_amount', 0, 'NUMBER'));
            // 製品仕入金額
            colList.push(CreateColRow('product_purchase_amount', 0, 'NUMBER'));
            // 製品粗利額
            colList.push(CreateColRow('product_gross_profit', 0, 'NUMBER'));
            // 製品消費税
            colList.push(CreateColRow('product_tax', tax, 'NUMBER'));
            // 製品分類コード
            colList.push(CreateColRow('product_class_id', 0, 'NUMBER'));
            // 製品諸口区分
            colList.push(CreateColRow('product_mouth_class', 0, 'NUMBER'));
            // 在庫管理区分
            colList.push(CreateColRow('inventory_control_class', 0, 'NUMBER'));
            // 取引先製品単価区分
            colList.push(CreateColRow('client_amount_class', '', 'VARCHAR'));
          } else {
            // 製品行
            // 製品コード
            colList.push(CreateColRow('product_id', product.productCode, 'NUMBER'));
            // 製品名
            colList.push(CreateColRow('product_name', await escapeQuote(product.productName), 'VARCHAR'));
            // 製品チェック区分
            colList.push(CreateColRow('product_check_class', product.productCheckClass, 'NUMBER'));
            // サービス区分
            colList.push(CreateColRow('service_class', await escapeQuote(product.serviceClass), 'VARCHAR'));
            // 製品数量
            colList.push(CreateColRow('product_quantity', product.quantity, 'NUMBER'));
            // 製品単位
            colList.push(CreateColRow('product_unit', product.unit, 'VARCHAR'));
            // 製品売上単価
            colList.push(CreateColRow('product_sales_unit_price', product.sellingPrice, 'NUMBER'));
            // 製品仕入単価
            colList.push(CreateColRow('product_purchase_price', product.purchasePrime, 'NUMBER'));
            // 製品売上金額
            colList.push(CreateColRow('product_sales_unit_amount', product.money, 'NUMBER'));
            // 製品仕入金額
            colList.push(CreateColRow('product_purchase_amount', product.totalPurchasePrime, 'NUMBER'));
            // 製品粗利額
            colList.push(CreateColRow('product_gross_profit', product.money - product.totalPurchasePrime, 'NUMBER'));
            // 製品消費税
            colList.push(CreateColRow('product_tax', 0, 'NUMBER'));
            // 製品分類コード
            colList.push(CreateColRow('product_class_id', product.productClass, 'NUMBER'));
            // 製品諸口区分
            colList.push(CreateColRow('product_mouth_class', product.sundriesClass, 'NUMBER'));
            // 在庫管理区分
            colList.push(CreateColRow('inventory_control_class', product.inventoryClass, 'NUMBER'));
            // 取引先製品単価区分
            colList.push(CreateColRow('client_amount_class', product.clientAmountClass, 'VARCHAR'));
          }
          // 売上課税区分
          colList.push(CreateColRow('sales_tax_class', this.suppliers.taxClass, 'NUMBER'));
          // 税額計算区分
          colList.push(CreateColRow('tax_calculation_class', this.suppliers.taxCalculationClass, 'NUMBER'));
          // 税額端数計算処理区分
          colList.push(CreateColRow('tax_fractionation_class', this.suppliers.taxFracClass, 'NUMBER'));
          // 受注番号
          colList.push(CreateColRow('order_receive_id', this.receivedOrderId, 'NUMBER'));
          // 受注行番号
          colList.push(CreateColRow('order_receive_row', i + 1, 'NUMBER'));

          // 新規と更新ユーザー
          const colBothUser = await getUserCol(this.username, 'both')

          // sql insert カラム部分
          if (i == 0) sqlInsertCol = CreateInsertSql(colList.concat(colBothUser), 'col', 't_cumulative_transaction_tentative').split(',');
          // sql insert values部分
          sqlInsertVal.push(CreateInsertSql(colList.concat(colBothUser), 'val', 't_cumulative_transaction_tentative').split(','));
        }
      }
      let startSql = `INSERT INTO t_cumulative_transaction_tentative (${sqlInsertCol}) VALUES `
      let insertArr = []
      let SQLs = [];
      // 製品リストから削除
      SQLs.push(this.execDelCumulativeTransaction());
      // INSERT文追加
      for (const key in sqlInsertVal) {
        insertArr.push(`(${sqlInsertVal[key].join()})`)
        if (insertArr.join().length >= Const.SqlMaxLength) {
          SQLs.push(`${startSql} ${insertArr.join()}`);
          insertArr = [];
        }
      }
      if (insertArr.length > 0) {
        SQLs.push(`${startSql} ${insertArr.join()}`);
      }
      //console.log(SQLs);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return true;
    },
    /* 仮累積トランザクション削除 */
    execDelCumulativeTransaction: function() {
      // 仮累積トランザクション
      let sql = `DELETE FROM t_cumulative_transaction_tentative 
                  WHERE 
                  transaction_id = '${Const.TransactionId.sales}' 
                  AND billing_no = ${this.billingNo}
                `
      return sql;
    },
    /* 納品書PDF出力 */
    outputSalesDirectPdf: function() {
      // 得意先元帳PDF出力処理
      //console.log('納品書PDF出力処理');
      let query = {
        billingNo: this.billingNo,
      };
      let route = this.$router.resolve({ name: 'SALES-TENTATIVE-NOUHIN', query});
      this.newWin.location = route.href
    },
    // キャンセルボタン
    cancel: function() {
      this.$router.push({ name: 'TENTATIVE-SALES-LIST'});
    },
    // 担当者検索
    showStaffSearchModal: function() {
      this.$bvModal.show('staffSearchModal');
    },
    // 担当者検索モーダルを閉じた時
    closeStaffSearchModal: function(staffItems) {
      //console.log(staffItems);
      // モーダルから渡された値の有無チェック
      if (typeof staffItems != 'undefined') {
        this.orderReceiveStaff.id = staffItems.detail.staff_id;
        this.orderReceiveStaff.text = staffItems.detail.staff_id + '：' + staffItems.detail.staff_name_kanji;
      }
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      const functionName = 'searchClient';
      const result = await searchClientModal(client_id, this.propClientClass, functionName, MODULE_NAME);
      if (typeof result != 'undefined') {
        await this.closeClientSearchModal(result);
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6) {
          // 取引先クリア
          await this.closeClientSearchModal({detail:{}});
        }
      }
    },
    // 取引先検索
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先検索モーダルを閉じた時
    async closeClientSearchModal(clientItems) {
      //console.log(clientItems);
      // モーダルから渡された値の有無チェック
      if (typeof clientItems != 'undefined') {
        // 取引先（得意先）から呼出
        if (clientItems.detail.closing_date == 99) {
          this.suppliers.closingDate = formatDateCalc(String(this.controlMasterData.processMonthYear) + '01', 0, 0, 0, true);
        } else {
          this.suppliers.closingDate = formatDate(String(this.controlMasterData.processMonthYear) + clientItems.detail.closing_date);
        }
        this.suppliers.code = clientItems.detail.client_id;
        this.suppliers.name = clientItems.detail.client_name_kanji;
        this.suppliers.address1 = clientItems.detail.address_1;
        this.suppliers.address2 = clientItems.detail.address_2;
        this.suppliers.department1 = clientItems.detail.department_1;
        this.suppliers.department2 = clientItems.detail.department_2;
        this.suppliers.tel = clientItems.detail.phone_number;
        this.suppliers.personInChargeId = clientItems.detail.staff_id;
        this.suppliers.personInCharge = clientItems.detail.staff_id + '：' + clientItems.detail.staff_name_kanji;
        this.suppliers.zipCode = clientItems.detail.zip_code;
        this.suppliers.class = clientItems.detail.client_class;
        this.suppliers.sundriesClass = clientItems.detail.sundries_class;
        this.suppliers.firstDigit = clientItems.detail.client_id_first_digit;
        this.suppliers.taxClass = clientItems.detail.sales_tax_class;
        this.suppliers.taxCalculationClass = clientItems.detail.tax_calculation_class;
        this.suppliers.taxFracClass = clientItems.detail.tax_fractionation_class;
        this.suppliers.check = clientItems.detail.check_contents;
        // 現場クリア
        this.siteClear();
        // 単価をリフレッシュ
        await this.refreshSalesAmount();
      }
    },
    /* 取引先照会 */
    clientInfoModal: function(clientClass, clientId) {
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    // 製品コード直接入力 - 製品検索
    async searchProduct(product_id, index) {
      const functionName = 'searchProduct';
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = this.selectSalesOfficeName;
      this.productSearchProp.client_id = this.suppliers.code;
      this.productSearchProp.client_class = this.suppliers.class;
      this.productSearchProp.client_name = this.suppliers.name;
      this.productSearchProp.kijunDate = this.dateBillingDate;
      const result = await searchProductsModal(product_id, this.productSearchProp, functionName, MODULE_NAME);
      this.dataIndex = index;
      if (typeof result != 'undefined') {
        this.closeProductSearchModal(result);
      } else {
        // 製品コード8桁かつデータ取得失敗の場合
        if (product_id.length == 8) {
          // 製品コードクリア
          this.productList[index].productCode = '';
        }
      }
    },
    // 製品検索
    showProductSearchModal: function(index) {
      this.dataIndex = index;
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = this.selectSalesOfficeName
      this.productSearchProp.client_id = this.suppliers.code;
      this.productSearchProp.client_class = this.suppliers.class;
      this.productSearchProp.client_name = this.suppliers.name;
      this.productSearchProp.kijunDate = this.dateBillingDate;
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    closeProductSearchModal: function(productItems) {
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if (typeof productItems != 'undefined') {
        let focusFlg = false;
        if (productItems.length == undefined) {
          // 選択ボタン押下でクローズ
          this.setProductSearchModal(this.dataIndex, productItems.detail);
          focusFlg = true;
        } else {
          if (productItems.length == 1) {
            // 1件の場合のみフォーカスを数量に当てる
            focusFlg = true;
          }
          // 一括選択ボタン押下でクローズ
          for (let i = 0; i < productItems.length; i++) {
            if (i == 0) {
              this.setProductSearchModal(this.dataIndex, productItems[i].detail);
            } else {
              this.productList.splice(this.dataIndex + i, 0, this.createNewRow());
              this.setProductSearchModal(this.dataIndex + i, productItems[i].detail);
            }
          }
        }
        // 合計金額、合計仕入額、粗利率を計算
        this.calcTotal();
        // 最終行が空行でない場合は空行追加
        if (this.isListBlankRow(this.productList.length - 1) == false) {
          this.addTableRow();
        }
        if (focusFlg == true) {
          // 製品検索を行った行の数量にフォーカスを移動
          setTimeout(() => {
            if (this.dataIndex < this.productList.length) {
              this.setFocus('productQuantity' + this.dataIndex);
            }
          }, 500);
        }
      }
    },
    // 製品検索モーダルの返却値設定
    setProductSearchModal: function(index, productItemsDetail) {
      // 製品コード
      this.productList[index].productCode = productItemsDetail.product_id;
      // 製品分類コード
      this.productList[index].productClass = productItemsDetail.product_class_id;
      // 製品名（漢字）
      this.productList[index].productName = productItemsDetail.product_name_kanji;
      this.productList[index].productTaxRateClass = productItemsDetail.product_tax_rate_class_sales;
      // 製品チェック区分
      this.productList[index].productCheckClass = productItemsDetail.product_check_class;
      this.productList[index]._cellVariants = { productName: getListValue(Const.ProductCheckClass, productItemsDetail.product_check_class, 'value', 'color') };
      // 数量
      if (this.selectBillClass == Const.Tradition.salesReturn) {
        // 返品の場合
        // 数量
        this.productList[index].quantity = -1;
      } else {
        // 返品以外の場合
        // 数量
        this.productList[index].quantity = 1;
      }
      // 単位
      this.productList[index].unit = productItemsDetail.unit;
      // 売上単価
      if (productItemsDetail.cp_sales_unit_price != null) {
        this.productList[index].sellingPrice = productItemsDetail.cp_sales_unit_price;
        this.productList[index].clientAmountClass = '*';
      } else {
        this.productList[index].sellingPrice = productItemsDetail.sales_unit_price;
        this.productList[index].clientAmountClass = '';
      }
      // 仕入単価
      if (productItemsDetail.is_9A == 0) {
        // 9A製品以外の場合は原価単価
        this.productList[index].purchasePrime = productItemsDetail.cost_price;
      } else {
        // 9A製品の場合は仕入単価
        this.productList[index].purchasePrime = productItemsDetail.purchase_price;
      }
      // 諸口区分
      this.productList[index].sundriesClass = productItemsDetail.sundries_class;
      // 在庫管理区分
      this.productList[index].inventoryClass = productItemsDetail.inventory_control_class;
      // 合計仕入単価
      this.productList[index].totalPurchasePrime = this.productList[index].quantity * this.productList[index].purchasePrime;
      // 金額
      this.productList[index].money = this.productList[index].quantity * this.productList[index].sellingPrice;
    },
    // 現場検索モーダルを開く
    showSiteSearchModal: function() {
      //console.log('現場検索モーダル');
      this.siteSearchInfo.client_id = this.suppliers.code;
      this.siteSearchInfo.client_name = this.suppliers.name;
      this.$bvModal.show('siteSearchModal');
    },
    // 現場コード直接入力 - 現場検索
    async searchSite(site_id) {
      const functionName = 'searchSite';
      const client_id = this.suppliers.code;
      const result = await searchSiteModal(site_id, client_id, functionName, MODULE_NAME);
      if (typeof result != 'undefined') {
        // 現場情報セット
        this.closeSiteSearchModal(result);
      } else {
        // 現場クリア
        this.siteClear();
      }
    },
    // 現場検索モーダルを閉じた時
    closeSiteSearchModal: function(siteItems) {
      //console.log(siteItems);
      // モーダルから渡された値の有無チェック
      if (typeof siteItems != 'undefined') {
        this.onSite.deliveryCode = siteItems.detail.shipping_code;
        this.onSite.code = siteItems.detail.site_id;
        this.onSite.name = siteItems.detail.client_site_name_kanji;
        this.onSite.zipCode = siteItems.detail.client_site_zip_code;
        this.onSite.address1 = siteItems.detail.client_site_address_1;
        this.onSite.address2 = siteItems.detail.client_site_address_2;
        this.onSite.deliveryPlace = siteItems.detail.delivery_location;
        this.onSite.tel = siteItems.detail.client_site_phone_number;
        this.onSite.clientPrintClass = siteItems.detail.order_print_class;
        this.onSite.clientPrintName = siteItems.detail.order_print_client_name;
        this.onSite.clientPrintNameText = this.onSite.clientPrintClass == 0 ? '' : siteItems.detail.order_print_client_name;
      }
    },
    // 現場クリア
    siteClear: function() {
      //console.log('現場クリア');
      this.onSite.deliveryCode = '';
      this.onSite.code = '';
      this.onSite.name = '';
      this.onSite.zipCode = '';
      this.onSite.address1 = '';
      this.onSite.address2 = '';
      this.onSite.deliveryPlace = '';
      this.onSite.tel = '';
      this.onSite.clientPrintClass = '';
      this.onSite.clientPrintName = '';
      this.onSite.clientPrintNameText = '';
    },
    // 取引先製品単価履歴照会モーダルを開く
    clickInquryClientProduct: function() {
      let productIdCsv = '';
      for (let i = 0; i < this.productList.length; i++) {
        if (getNullStr(this.productList[i].productCode) != '' && isNaN(getNullStr(this.productList[i].productCode)) == false) {
          if (productIdCsv != '') {
            productIdCsv += ',';
          }
          // 製品コード
          productIdCsv += this.productList[i].productCode;
        }
      }
      this.clientProductProp.clientClass = this.suppliers.class;
      this.clientProductProp.clientId = this.suppliers.code;
      this.clientProductProp.clientNameKanji = this.suppliers.name;
      this.clientProductProp.productIdCsv = productIdCsv;

      this.$bvModal.show('clientProductInquiryModal');
    },
    // 再計算イベント
    calc: function(index) {
      // 対象行の項目計算
      this.productList[index].totalPurchasePrime = this.productList[index].quantity * this.productList[index].purchasePrime;
      this.productList[index].money = this.productList[index].quantity * this.productList[index].sellingPrice;
      // 合計金額、合計仕入単価、粗利計算
      this.calcTotal();
    },
    // 合計金額、合計仕入単価、粗利計算
    calcTotal: function() {
      this.totalFee = 0;
      this.totalPurchasePrime = 0;
      this.grossProfit = 0;
      let moneySubTotalNormal = 0;
      let moneySubTotalLight = 0;
      for(let i = 0; i < this.productList.length;i++) {
        this.totalFee += this.productList[i].money;
        this.totalPurchasePrime += this.productList[i].totalPurchasePrime;
        if (this.productList[i].productTaxRateClass == Const.ProductTaxRateClass.normalTax) {
          moneySubTotalNormal += this.productList[i].money;
        } else if (this.productList[i].productTaxRateClass == Const.ProductTaxRateClass.lightTax) {
          moneySubTotalLight += this.productList[i].money;
        }
      }
      this.subTotalNormal = moneySubTotalNormal;
      this.subTotalLight = moneySubTotalLight;
      this.grossProfit = Math.round((this.totalFee - this.totalPurchasePrime) * 1000 / this.totalFee) / 10;
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = this.createNewRow();
      this.productList.push(newProduct);
    },
    // 行作成
    createNewRow: function() {
      let newProduct = {
        productCode: null,
        productClass: null,
        productName: '',
        productTaxRateClass: Const.ProductTaxRateClass.normalTax,
        serviceClass: '',
        quantity: 0,
        unit: '',
        sellingPrice: 0,
        clientAmountClass: '',
        purchasePrime: 0,
        sundriesClass: null,
        inventoryClass: null,
        totalPurchasePrime: 0,
        money: 0,
      };
      return newProduct;
    },
    // テーブル行削除イベント
    deleteBtn: function(rowNum) {
      if (this.productList.length > 1) {
        this.productList.splice(rowNum, 1);
        // 合計金額、合計仕入単価、粗利計算
        this.calcTotal();
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
      this.successMessages = [];
    },
    // 一覧の指定行が空かどうかチェック
    isListBlankRow: function(index) {
      //console.log(getNullStr(this.productList[index].productCode));
      if (getNullStr(this.productList[index].productCode) == '') {
        return true;
      } else {
        return false;
      }
    },
    // 一覧の後ろの空行を削除
    delListLastBlankRow: function() {
      //console.log('空行削除開始');
      for (let i = this.productList.length - 1; i > 0; i--) {
        if (this.isListBlankRow(i) == true) {
          // 後ろにある空行は削除
          this.productList.splice(i, 1);
        } else {
          // 1件でも空行以外の行があった場合は削除処理終了
          break;
        }
      }
      // 最後に合計金額、合計仕入単価、粗利計算
      this.calcTotal();
      //console.log('空行削除終了');
    },
    // 売価をリフレッシュ
    async refreshSalesAmount() {
      const functionName = 'refreshSalesAmount';
      if (this.isUnitPrice == false) {
        // 単価適用チェックがOFFの場合はリフレッシュしない
        return;
      }
      if (getNullStr(this.dateBillingDate) != '') {
        this.$store.commit('setLoading', true);
        try {
          await refreshSalesAmountProductList(this.productList, this.suppliers.class, this.suppliers.code, this.dateBillingDate, 'productCode', true, 'sellingPrice', 'clientAmountClass', true, 'serviceClass');
          for (let i = 0; i < this.productList.length; i++) {
            this.productList[i].money = this.productList[i].quantity * this.productList[i].sellingPrice;
          }
          // 合計金額、合計仕入単価、粗利
          this.calcTotal();
        } catch (error) {
          console.log(error);
          await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        }
        if (this.getMessageFlg == true) {
          scrollTo(0,0);
        }
        this.$store.commit('setLoading', false);
      }
    },
    // サービス変更
    async changeService(index) {
      const functionName = 'changeService';
      try {
        if (this.productList[index].serviceClass != '') {
          // サービス区分が空白以外
          this.productList[index].sellingPrice = 0;
          this.productList[index].money = 0;
        } else {
          // サービス区分が空白
          if (getNullStr(this.dateBillingDate) != '') {
            // 受注日付が設定されている場合のみ
            if (getNullStr(this.productList[index].productCode) != '' && isNaN(getNullStr(this.productList[index].productCode)) == false) {
              // 製品コードが数値の場合のみ
              this.$store.commit('setLoading', true);
              // マスタに登録されている製品の売価を取得
              let selectSqlQuery = 'SELECT';
              selectSqlQuery += ' product_id';
              selectSqlQuery += ',sales_unit_price';
              selectSqlQuery += ' FROM ';
              selectSqlQuery += 'm_clients_products ';
              selectSqlQuery += ' WHERE ';
              selectSqlQuery += 'client_class = ' + this.suppliers.class + ' ';
              selectSqlQuery += 'AND client_id = ' + this.suppliers.code + ' ';
              selectSqlQuery += 'AND product_id = ' + this.productList[index].productCode + ' ';
              selectSqlQuery += 'AND unit_price_effective_date <= \'' + this.dateBillingDate + '\' ';
              selectSqlQuery += 'ORDER BY unit_price_effective_date DESC ';
              selectSqlQuery += 'LIMIT 1 ';
              // SELECT文
              let selectSql = 'SELECT';
              selectSql += ' products.product_id';
              selectSql += ',IfNull(clients_products_QUERY.sales_unit_price,products.sales_unit_price) AS sales_unit_price';
              selectSql += ' FROM ';
              selectSql += 'm_products AS products ';
              selectSql += 'LEFT JOIN (' + selectSqlQuery + ') AS clients_products_QUERY ';
              selectSql += 'ON clients_products_QUERY.product_id = products.product_id ';
              selectSql += ' WHERE ';
              selectSql += 'products.product_id = ' + this.productList[index].productCode + ' ';
              //console.log(selectSql);
              let resultData = await executeSelectSql(selectSql);
              //console.log(resultData);
              if (resultData != null) {
                this.productList[index].sellingPrice = resultData[0].sales_unit_price;
                this.productList[index].money = this.productList[index].quantity * this.productList[index].sellingPrice;
              }
            }
          }
        }
        // 合計金額、合計仕入単価、粗利
        this.calcTotal();
      } catch (error) {
        console.log(error);
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
  }
}
</script>