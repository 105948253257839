<template>
  <div>
    <div v-if="getMessageFlg == true">
      <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
        <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="route in listRoute" v-bind:key="route.shippingCode">
      <div v-for="chouhyou in route.listChouhyou" v-bind:key="route.shippingCode + '_' + chouhyou.page">
        <div v-if="chouhyou.tempKbn == constData.tempKbnStart">
          <TemplateShipsScheduledRouteStart :id="route.shippingCode + '_' + constData.chouhyouId + '_' + chouhyou.page" />
        </div>
        <div v-if="chouhyou.tempKbn == constData.tempKbnEnd">
          <TemplateShipsScheduledRouteEnd :id="route.shippingCode + '_' + constData.chouhyouId + '_' + chouhyou.page" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TemplateShipsScheduledRouteStart from '@/assets/svg/shipsScheduledRoute_Start.svg';
import TemplateShipsScheduledRouteEnd from '@/assets/svg/shipsScheduledRoute_End.svg';
import { setPaperA4Landscape, setChouhyouBodyStyle, addOperationLogs, getControlMaster, formatDate, formatCurDate, getListValue, executeSelectSql, getNullStr, selectOneTable } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'ships-scheduled-route-print';

export default {
  name: 'SHIPS-SCHEDULED-ROUTE-PRINT',
  /* コンポーネント */
  components: {
    TemplateShipsScheduledRouteStart,
    TemplateShipsScheduledRouteEnd,
  },
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntStart: 46,
        cntEnd: 52,
        tempKbnStart: 1,
        tempKbnEnd: 2,
        chouhyouId: 'idChouhyou',
        rowKbnRow1: 1,
        rowKbnRow2: 2,
        rowKbnRow3: 3,
        rowKbnRow4: 4,
        rowKbnRow5: 5,
        rowKbnRowBlank: 6,
        blankRowTitle: '＜空＞',
        totalLineX: 83,
        totalLineWidth: 2360,
        totalLineHeight: 5,
        totalLineMaxY: 1690,
      },
      // ヘッダ
      menu_type: 'user',
      title: 'ルート別出荷予定表（帳票）',
      // 表示帳票のサイズ（A4）ルート別出荷予定表
      // 以下のサイズで画面に表示されるように調整
      chouhyouSize: {
        width: '445mm',
        height: '314mm',
      },
      // 検索項目
      searchResultDispConditions: {
        // 営業所プルダウン
        salesOfficeText: null,
        // 配送種別（開始／終了）
        shippingTypeClass: '',
        // ルート（配送コード）（開始／終了）
        shippingCode: '',
        // 伝票種類（開始／終了）
        billingTypeClass: '',
      },
      // 変更後引数
      changeConditions: {
        billingTypeClassStart: '',
        billingTypeClassEnd: '',
        shippingTypeClassStart: '',
        shippingTypeClassEnd: '',
        shippingCodeStart: '',
        shippingCodeEnd: '',
      },
      listRoute: [],
      replacementsCommon: [],
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // 合計行のY座標
      lineY: 0,
      // アラート
      alertWarning: [],
      alertDanger: [],
      // 配送種別区分取得
      shippingTypeClass: Const.ShippingTypeClass,
      // 伝種（受注伝票種別区分）取得
      orderReceiveBillClass: Const.OrderReceiveBillClass,
      // パラメータ
      selectSalesOffice: null,
      shippingCodeStart: null,
      shippingCodeEnd: null,
      shippingTypeClassStart: null,
      shippingTypeClassEnd: null,
      billingTypeClassStart: null,
      billingTypeClassEnd: null,
      orderDateStart: null,
      orderDateEnd: null,
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* マウント */
  mounted() {
    // パラメータ取得
    this.selectSalesOffice = this.$route.query.selectSalesOffice;
    this.shippingCodeStart = this.$route.query.shippingCodeStart;
    this.shippingCodeEnd = this.$route.query.shippingCodeEnd;
    this.shippingTypeClassStart = this.$route.query.shippingTypeClassStart;
    this.shippingTypeClassEnd = this.$route.query.shippingTypeClassEnd;
    this.billingTypeClassStart = this.$route.query.billingTypeClassStart;
    this.billingTypeClassEnd = this.$route.query.billingTypeClassEnd;
    this.orderDateStart = this.$route.query.orderDateStart;
    this.orderDateEnd = this.$route.query.orderDateEnd;
    // 印刷レイアウト設定
    setPaperA4Landscape();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    // テーブルからデータを取得して、設定
    this.fetchData();
    // 印刷ファイルのデフォルト名
    document.title = 'ルート別出荷予定表_' + formatCurDate('YYYY/MM/DD');
  },
  /* 関数群 */
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        //console.time('timerAll');
        // 検索条件を設定
        await this.setSearchConditions();
        // 検索値を変更
        this.changeSearchConditionsStrRange();
        // 現在処理年月取得
        let controlData = await getControlMaster();
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        // 表示データ設定
        //console.time('timerDisp');
        await this.setDispData();
        //console.timeEnd('timerDisp');
        //console.timeEnd('timerAll');
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        this.listRoute = [];
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索条件を設定 */
    async setSearchConditions() {
      const functionName = 'setSearchConditions';
      let strWork = '';
      // 営業所データ取得
      try {
        let officeListData = await selectOneTable('m_offices');
        //console.log(officeListData);
        this.searchResultDispConditions.salesOfficeText = this.selectSalesOffice;
        this.searchResultDispConditions.salesOfficeText += '：';
        this.searchResultDispConditions.salesOfficeText += getListValue(officeListData, this.selectSalesOffice, 'office_id', 'office_name_kanji');
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // 配送種別
      if (this.shippingTypeClassStart == this.shippingTypeClassEnd) {
        if (this.shippingTypeClassStart == '*') {
          strWork = '';
        } else {
          strWork = getListValue(Const.DeliveryList, this.shippingTypeClassStart, 'id', 'text');
          if (strWork == null) {
            strWork = this.shippingTypeClassStart;
          }
        }
        this.searchResultDispConditions.shippingTypeClass = strWork;
      } else {
        if (this.shippingTypeClassStart == '*') {
          strWork = '';
        } else {
          strWork = getListValue(Const.DeliveryList, this.shippingTypeClassStart, 'id', 'text');
          if (strWork == null) {
            strWork = this.shippingTypeClassStart;
          }
        }
        this.searchResultDispConditions.shippingTypeClass = strWork;
        this.searchResultDispConditions.shippingTypeClass += ' ～ ';
        strWork = getListValue(Const.DeliveryList, this.shippingTypeClassEnd, 'id', 'text');
        if (strWork == null) {
          strWork = this.shippingTypeClassEnd;
        }
        this.searchResultDispConditions.shippingTypeClass += strWork;
      }
      // ルート
      if (this.shippingCodeStart == this.shippingCodeEnd) {
        if (this.shippingCodeStart == '*') {
          strWork = '';
        } else {
          strWork = this.shippingCodeStart;
        }
        this.searchResultDispConditions.shippingCode = strWork;
      } else {
        if (this.shippingCodeStart == '*') {
          strWork = '';
        } else {
          strWork = this.shippingCodeStart;
        }
        this.searchResultDispConditions.shippingCode = strWork;
        this.searchResultDispConditions.shippingCode += ' ～ ';
        this.searchResultDispConditions.shippingCode += this.shippingCodeEnd;
      }
      // 伝票種類
      if (this.billingTypeClassStart == this.billingTypeClassEnd) {
        strWork = getListValue(Const.BillingTypeClassList, this.billingTypeClassStart, 'value', 'text');
        if (strWork == null) {
          strWork = this.billingTypeClassStart;
        }
        this.searchResultDispConditions.billingTypeClass = strWork;
      } else {
        strWork = getListValue(Const.BillingTypeClassList, this.billingTypeClassStart, 'value', 'text');
        if (strWork == null) {
          strWork = this.billingTypeClassStart;
        }
        this.searchResultDispConditions.billingTypeClass = strWork;
        this.searchResultDispConditions.billingTypeClass += ' ～ ';
        strWork = getListValue(Const.BillingTypeClassList, this.billingTypeClassEnd, 'value', 'text');
        if (strWork == null) {
          strWork = this.billingTypeClassEnd;
        }
        this.searchResultDispConditions.billingTypeClass += strWork;
      }
    },
    /* 範囲指定の文字列引数の変換（9を「ﾟ」に変換） */
    changeSearchConditionsStrRange: function() {
      // ルート（配送コード）
      this.changeConditions.shippingCodeStart = this.shippingCodeStart;
      if (this.changeConditions.shippingCodeStart == '*') {
        this.changeConditions.shippingCodeStart = '';
      }
      this.changeConditions.shippingCodeEnd = this.shippingCodeEnd.replace(/9/g, Const.Char.halfMax);
      this.changeConditions.shippingCodeEnd = this.changeConditions.shippingCodeEnd.padEnd(5, Const.Char.halfMax);
      // 配送種別
      this.changeConditions.shippingTypeClassStart = this.shippingTypeClassStart;
      if (this.changeConditions.shippingTypeClassStart == '*') {
        this.changeConditions.shippingTypeClassStart = '';
      }
      this.changeConditions.shippingTypeClassEnd = this.shippingTypeClassEnd.replace(/9/g, Const.Char.halfMax);
      this.changeConditions.shippingTypeClassEnd = this.changeConditions.shippingTypeClassEnd.padEnd(1, Const.Char.halfMax);
      // 伝票種類
      this.changeConditions.billingTypeClassStart = this.billingTypeClassStart;
      this.changeConditions.billingTypeClassEnd = this.billingTypeClassEnd;
    },
    /* 表示データ設定 */
    async setDispData() {
      //console.time('timerSql1');
      // 検索SQL作成
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      //console.timeEnd('timerSql1');
      //console.time('timerSelect1');
      let dataResult = await executeSelectSql(selectSql);
      //console.timeEnd('timerSelect1');
      //console.log(dataResult);
      if (dataResult != null && dataResult.length > 0) {
        //console.time('timer1');
        this.setItemList(dataResult);
        //console.timeEnd('timer1');
        // 共通の置換文字列設定
        // 現在日時
        this.replacementsCommon.push({key: '%現在日時%', value: formatCurDate('YYYY/MM/DD HH:mm'), textAnchor: 'start', textLength: 270, chkWidth: false});
        // ページ毎の置換文字列設定
        //console.time('timer2');
        await this.createReplacementsPage();
        //console.timeEnd('timer2');
        // 作成した置換文字データをSVGファイルに設定
        //console.time('timer4');
        await this.setChouhyou();
        //console.timeEnd('timer4');
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','出荷予定'));
        return;
      }
    },
    /* 検索条件文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.order_receive_id';
      selectSql += ',orders_receives.order_receive_date';
      selectSql += ',orders_receives.order_receive_staff_id';
      selectSql += ',orders_receives.client_id';
      selectSql += ',orders_receives.client_name AS client_name_kanji';
      selectSql += ',orders_receives.staff_id';
      selectSql += ',clients.billing_type_class';
      selectSql += ',orders_receives.shipping_type_class';
      selectSql += ',orders_receives.order_receive_bill_class';
      selectSql += ',orders_receives.input_date';
      selectSql += ',orders_receives.delivery_design_date';
      selectSql += ',orders_receives.delivery_design_class';
      selectSql += ',orders_receives.delivery_design';
      selectSql += ',orders_receives.site_id';
      selectSql += ',orders_receives.shipping_code';
      selectSql += ',orders_receives.client_site_name AS client_site_name_kanji';
      selectSql += ',orders_receives.client_site_zip_code';
      selectSql += ',orders_receives.client_site_address_1';
      selectSql += ',orders_receives.client_site_address_2';
      selectSql += ',orders_receives.client_site_phone_number';
      selectSql += ',orders_receives.delivery_location';
      selectSql += ',clients.check_contents';
      selectSql += ',orders_receives.product_id';
      selectSql += ',orders_receives.product_name AS product_name_kanji';
      selectSql += ',orders_receives.unit';
      selectSql += ',orders_receives.order_receive_quantity';
      selectSql += ',orders_receives.remaining_quantity';
      selectSql += ',orders_receives.reserve_quantity';
      selectSql += ',orders_receives.stock_scheduled_reserve_receive - orders_receives.shipping_quantity AS stock_scheduled_reserve_receive';
      selectSql += ',IfNull(stocks.balance,0) AS balance';
      selectSql += ',IfNull(stocks.inventory_reserve_count,0) AS inventory_reserve_count';
      selectSql += ',orders_QUERY.stock_scheduled';
      selectSql += ',orders_QUERY.stock_scheduled_staff_id';
      selectSql += ',CASE WHEN orders_QUERY.stock_scheduled_confirm_class = 1 THEN \'*\' ELSE \'\' END AS stock_scheduled_confirm_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'LEFT JOIN (' + this.createSubOrdersQuerySql() + ') AS orders_QUERY ';
      selectSql += 'ON (orders_receives.order_receive_id = orders_QUERY.order_receive_id ';
      selectSql += 'AND orders_receives.order_receive_row = orders_QUERY.order_receive_row ';
      selectSql += 'AND orders_receives.order_id = orders_QUERY.order_id ';
      selectSql += 'AND orders_receives.order_row = orders_QUERY.order_row) ';
      selectSql += 'OR (orders_receives.office_id = orders_QUERY.office_id ';
      selectSql += 'AND orders_receives.product_id = orders_QUERY.product_id ';
      selectSql += 'AND orders_receives.order_id = 0) ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON orders_receives.client_class = clients.client_class ';
      selectSql += 'AND orders_receives.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_stocks AS stocks ';
      selectSql += 'ON orders_receives.office_id = stocks.office_id ';
      selectSql += 'AND orders_receives.product_id = stocks.product_id ';
      selectSql += 'AND stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 伝票種別
      selectSql += 'clients.billing_type_class BETWEEN ' + this.changeConditions.billingTypeClassStart + ' AND ' + this.changeConditions.billingTypeClassEnd + ' ';
      // 営業所コード
      selectSql += 'AND orders_receives.office_id = ' + this.selectSalesOffice + ' ';
      // 配送種別
      selectSql += 'AND orders_receives.shipping_type_class BETWEEN \'' + this.changeConditions.shippingTypeClassStart + '\' AND \'' + this.changeConditions.shippingTypeClassEnd + '\' ';
      // ルート（配送コード）
      selectSql += 'AND orders_receives.shipping_code BETWEEN \'' + this.changeConditions.shippingCodeStart + '\' AND \'' + this.changeConditions.shippingCodeEnd + '\' ';
      // 受注日付
      if (this.orderDateStart != '' && this.orderDateEnd == '') {
        selectSql += 'AND orders_receives.order_receive_date >= ' + '\'' + this.orderDateStart + '\' ';
      } else if (this.orderDateStart == '' && this.orderDateEnd != '') {
        selectSql += 'AND orders_receives.order_receive_date <= ' + '\'' + this.orderDateEnd + '\' ';
      } else if (this.orderDateStart != '' && this.orderDateEnd != '') {
        selectSql += 'AND orders_receives.order_receive_date BETWEEN ' + '\'' + this.orderDateStart + '\' ' + 'AND ' + '\'' + this.orderDateEnd + '\' ';
      }
      // 削除されていない製品
      selectSql += 'AND orders_receives.is_deleted = 0 ';
      // 受注残数が0以外（伝票未発行の受注）
      selectSql += 'AND orders_receives.remaining_quantity <> 0 ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY orders_receives.shipping_code, orders_receives.order_receive_id,orders_receives.order_receive_row ';
      /* LIMIT */
      selectSql += ' LIMIT 2500';
      return selectSql;
    },
    // 対象データSubOrdersQuerySQL作成処理
    createSubOrdersQuerySql: function() {
      // 受注番号に紐づかない発注データ（入荷予定のみ）
      let selectSql1 = 'SELECT';
      selectSql1 += ' 0 AS order_receive_id';
      selectSql1 += ',0 AS order_receive_row';
      selectSql1 += ',0 AS order_id';
      selectSql1 += ',0 AS order_row';
      selectSql1 += ',office_id';
      selectSql1 += ',product_id';
      selectSql1 += ',MIN(stock_scheduled) AS stock_scheduled';
      selectSql1 += ',stock_scheduled_staff_id';
      selectSql1 += ',stock_scheduled_confirm_class';
      selectSql1 += ' FROM ';
      selectSql1 += 't_orders';
      selectSql1 += ' WHERE ';
      selectSql1 += 'order_receive_id = 0 ';
      selectSql1 += 'AND is_deleted = 0 ';
      selectSql1 += 'AND remaining_quantity > 0 ';
      selectSql1 += 'AND order_issue_class > 0 ';
      selectSql1 += 'AND office_id = ' + this.selectSalesOffice + ' ';
      selectSql1 += 'GROUP BY office_id,product_id ';
      // 受注番号と紐づく発注データ（入荷予定のみ）
      let selectSql2 = 'SELECT';
      selectSql2 += ' order_receive_id';
      selectSql2 += ',order_receive_row';
      selectSql2 += ',order_id';
      selectSql2 += ',order_row';
      selectSql2 += ',0 AS office_id';
      selectSql2 += ',0 AS product_id';
      selectSql2 += ',stock_scheduled';
      selectSql2 += ',stock_scheduled_staff_id';
      selectSql2 += ',stock_scheduled_confirm_class';
      selectSql2 += ' FROM ';
      selectSql2 += 't_orders';
      selectSql2 += ' WHERE ';
      selectSql2 += 'order_receive_id > 0 ';
      selectSql2 += 'AND is_deleted = 0 ';
      selectSql2 += 'AND remaining_quantity > 0 ';
      selectSql2 += 'AND order_issue_class > 0 ';
      selectSql2 += 'AND office_id = ' + this.selectSalesOffice + ' ';
      // 2つのSELECT文をUNION
      let selectSql = selectSql1 + ' UNION ALL ' + selectSql2;

      return selectSql;
    },
    /* 取得結果セット */
    async setItemList(result) {
      let itemList = [];
      let preOrderReceiveId = null;
      let preShippingCode = null;
      let clientSitePhoneNumber = '';
      for(let i = 0; i < result.length; i++){
        if (preShippingCode != null && preShippingCode != getNullStr(result[i].shipping_code)) {
          this.listRoute.push({shippingCode: preShippingCode, itemList: itemList, listChouhyou: this.createListChouhyou(itemList.length)});
          itemList = [];
          preOrderReceiveId = null;
        }
        // 受注番号毎に１件として検索結果に表示するリストを作成
        if (preOrderReceiveId == null || preOrderReceiveId != result[i].order_receive_id) {
          if (preOrderReceiveId != null) {
            // 次の受注に切り替わる前に1行空行を入れる
            itemList.push(
              this.createRow(this.constData.rowKbnRowBlank, '', '', '', this.constData.blankRowTitle, '', '', '', '', '')
            );
          }
          // 受注番号１行目
          itemList.push(
            this.createRow(this.constData.rowKbnRow1, result[i].order_receive_id, getNullStr(result[i].client_name_kanji),
              result[i].site_id + '      ' + getNullStr(result[i].shipping_code) + '      ' + getNullStr(result[i].client_site_name_kanji), '', result[i].staff_id, getListValue(Const.BillingTypeClassList, result[i].billing_type_class, 'value', 'text'),
              getListValue(Const.OrderReceiveBillClassList, result[i].order_receive_bill_class, 'id', 'text'), '', '')
          );
          // 受注番号２行目
          itemList.push(
            this.createRow(this.constData.rowKbnRow2, '', result[i].client_id,
              getNullStr(result[i].client_site_address_1) + ' ' + getNullStr(result[i].client_site_address_2), '', '', '',
              '', '', '', '')
          );
          // 受注番号３行目
          let deliveryLocation = getNullStr(result[i].client_site_zip_code);
          if (deliveryLocation == '') {
            deliveryLocation += getNullStr(result[i].delivery_location);
          } else {
            deliveryLocation = '〒' + getNullStr(deliveryLocation) + ' ' + getNullStr(result[i].delivery_location);
          }
          itemList.push(
            this.createRow(this.constData.rowKbnRow3, formatDate(result[i].input_date), '',
              deliveryLocation, '', '', '',
              '', '', '')
          );
          // 受注番号４行目
          let deliveryDesignDate = result[i].delivery_design_date == null ? '0000/00/00' : formatDate(result[i].delivery_design_date);
          let deliveryDesign = getNullStr(result[i].delivery_design_class) + ' ' + getNullStr(result[i].delivery_design);
          itemList.push(
            this.createRow(this.constData.rowKbnRow4, getNullStr(result[i].order_receive_staff_id), '',
              deliveryDesignDate + ' ' + deliveryDesign, '', '', '',
              '', result[i].check_contents, '')
          );
          clientSitePhoneNumber = getNullStr(result[i].client_site_phone_number);
        }
        // 受注番号５行目以降
        let productReserveQuantity = 0;
        let productStockScheduled = result[i].stock_scheduled == null ? '0000/00/00' : formatDate(result[i].stock_scheduled);
        if (result[i].stock_scheduled_staff_id != null && result[i].stock_scheduled_staff_id != '') {
          productStockScheduled += ' ' + result[i].stock_scheduled_staff_id;
        }
        if (result[i].stock_scheduled_confirm_class != '') {
          productStockScheduled += ' ' + result[i].stock_scheduled_confirm_class;
        }
        if (result[i].shipping_type_class == Const.ShippingTypeClass.direct || result[i].order_receive_bill_class == Const.OrderReceiveBillClass.special) {
          productReserveQuantity = result[i].stock_scheduled_reserve_receive.toLocaleString();
        } else {
          productReserveQuantity = result[i].reserve_quantity.toLocaleString();
        }
        let quantity = result[i].order_receive_quantity.toLocaleString();
        quantity += result[i].unit == '' ? '' : ' ' + result[i].unit;
        itemList.push(
          this.createRow(this.constData.rowKbnRow5, '', '',
            clientSitePhoneNumber, result[i].product_id + ' ' + result[i].product_name_kanji, quantity, productReserveQuantity,
            (result[i].balance + result[i].inventory_reserve_count).toLocaleString(), productStockScheduled, result[i].remaining_quantity.toLocaleString())
        );
        clientSitePhoneNumber = '';
        preOrderReceiveId = result[i].order_receive_id;
        preShippingCode = getNullStr(result[i].shipping_code);
      }
      this.listRoute.push({shippingCode: preShippingCode, itemList: itemList, listChouhyou: this.createListChouhyou(itemList.length)});
      itemList = [];
      preOrderReceiveId = null;
    },
    /* 行作成 */
    createRow: function(kbn, col1, col2, col3, col4, col5, col6, col7, col8, col9) {
      let row = {
        kbn: kbn,
        col1: col1,
        col2: col2,
        col3: col3,
        col4: col4,
        col5: col5,
        col6: col6,
        col7: col7,
        col8: col8,
        col9: col9,
      }
      return row;
    },
    /* 帳票リスト作成 */
    createListChouhyou: function(productCnt) {
      //console.log('createListChouhyou');
      let listChouhyou = [];
      if (productCnt <= this.constData.cntStart) {
        // 製品が通常テンプレートの件数で収まる場合（開始帳票1ページのみ）
        listChouhyou.push({page: 1, tempKbn: this.constData.tempKbnStart, replacements: []});
      } else {
        let productCntNokori = productCnt;
        let page = 1;
        // 開始帳票
        listChouhyou.push({page: page, tempKbn: this.constData.tempKbnStart, replacements: []});
        productCntNokori -= this.constData.cntStart;
        page++;
        // 中間帳票（終了帳票）
        while (productCntNokori > this.constData.cntEnd) {
          // 残りの製品件数が最終帳票の件数に収まらない場合、中間帳票を出力し続ける
          listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
          productCntNokori -= this.constData.cntEnd;
          page++;
        }
        // 終了帳票
        listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
      }
      return listChouhyou;
    },
    /* 置換配列セット */
    async createReplacementsPage() {
      //console.log('createReplacementsPage');
      let replacements;
      let index = 0;
      let isChkWidth = false;
      let textAnchor = '';
      let isBold = false;
      for (let j = 0; j < this.listRoute.length; j++) {
        index = 0;
        for (let i = 0; i < this.listRoute[j].listChouhyou.length; i++) {
          let cntByChouhyou = 0;
          // SVGファイルの置換用文字列
          replacements = [];
          // ページ番号
          replacements.push({key: '%P%', value: this.listRoute[j].listChouhyou[i].page + ' / ' + this.listRoute[j].listChouhyou.length, textAnchor: 'end', textLength: 160, chkWidth: false});
          // 開始帳票
          if (this.listRoute[j].listChouhyou[i].tempKbn == this.constData.tempKbnStart) {
            // タイトル
            replacements.push({key: '%タイトル%', value: 'ルート別出荷予定表', textAnchor: 'middle', textLength: 900, chkWidth: false});
            // 営業所
            replacements.push({key: '%営業%', value: this.searchResultDispConditions.salesOfficeText, textAnchor: 'start', textLength: 500, chkWidth: false});
            // 配送種別
            replacements.push({key: '%配送%', value: this.searchResultDispConditions.shippingTypeClass, textAnchor: 'start', textLength: 500, chkWidth: false});
            // ルート
            replacements.push({key: '%ルート%', value: this.listRoute[j].shippingCode, textAnchor: 'start', textLength: 500, chkWidth: false});
          }
          if (i == 0) {
            cntByChouhyou = this.constData.cntStart;
          } else {
            cntByChouhyou = this.constData.cntEnd;
          }
          // 一覧
          for (let k = 0; k < cntByChouhyou; k++) {
            if (index < this.listRoute[j].itemList.length) {
              // 列１
              if (this.listRoute[j].itemList[index].col1 == null || this.listRoute[j].itemList[index].col1.length <= 10) {
                isChkWidth = false;
              } else {
                isChkWidth = true;
              }
              replacements.push({key: '%C1_' + (k + 1).toString() + '%', value: this.listRoute[j].itemList[index].col1, textAnchor: 'start', textLength: 160, chkWidth: isChkWidth});
              // 列２
              if (this.listRoute[j].itemList[index].col2 == null || this.listRoute[j].itemList[index].col2.length <= 15) {
                isChkWidth = false;
              } else {
                isChkWidth = true;
              }
              replacements.push({key: '%C2_' + (k + 1).toString() + '%', value: this.listRoute[j].itemList[index].col2, textAnchor: 'start', textLength: 390, chkWidth: isChkWidth});
              // 列３
              if (this.listRoute[j].itemList[index].col3 == null || this.listRoute[j].itemList[index].col3.length <= 30) {
                isChkWidth = false;
              } else {
                isChkWidth = true;
              }
              if (this.listRoute[j].itemList[index].kbn == this.constData.rowKbnRow4) {
                isBold = true;
              } else {
                isBold = false;
              }
              replacements.push({key: '%C3_' + (k + 1).toString() + '%', value: this.listRoute[j].itemList[index].col3, textAnchor: 'start', textLength: 1010, chkWidth: isChkWidth, isBold: isBold});
              // 列４
              if (this.listRoute[j].itemList[index].col4 == null || this.listRoute[j].itemList[index].col4.length <= 15) {
                isChkWidth = false;
              } else {
                isChkWidth = true;
              }
              replacements.push({key: '%C4_' + (k + 1).toString() + '%', value: this.listRoute[j].itemList[index].col4, textAnchor: 'start', textLength: 510, chkWidth: isChkWidth});
              // 列５
              if (this.listRoute[j].itemList[index].col5 == null || this.listRoute[j].itemList[index].col5.length <= 5) {
                isChkWidth = false;
              } else {
                isChkWidth = true;
              }
              if (this.listRoute[j].itemList[index].kbn == this.constData.rowKbnRow5) {
                textAnchor = 'end';
              } else {
                textAnchor = 'start';
              }
              replacements.push({key: '%C5_' + (k + 1).toString() + '%', value: this.listRoute[j].itemList[index].col5, textAnchor: textAnchor, textLength: 140, chkWidth: isChkWidth});
              // 列９
              if (this.listRoute[j].itemList[index].col9 == null || this.listRoute[j].itemList[index].col9.length <= 4) {
                isChkWidth = false;
              } else {
                isChkWidth = true;
              }
              replacements.push({key: '%9_' + (k + 1).toString() + '%', value: this.listRoute[j].itemList[index].col9, textAnchor: 'end', textLength: 90, chkWidth: isChkWidth});
              // 列６
              if (this.listRoute[j].itemList[index].col6 == null || this.listRoute[j].itemList[index].col6.length <= 4) {
                isChkWidth = false;
              } else {
                isChkWidth = true;
              }
              if (this.listRoute[j].itemList[index].kbn == this.constData.rowKbnRow5) {
                textAnchor = 'end';
              } else {
                textAnchor = 'start';
              }
              replacements.push({key: '%6_' + (k + 1).toString() + '%', value: this.listRoute[j].itemList[index].col6, textAnchor: textAnchor, textLength: 100, chkWidth: isChkWidth});
              // 列７
              if (this.listRoute[j].itemList[index].col7 == null || this.listRoute[j].itemList[index].col7.length <= 4) {
                isChkWidth = false;
              } else {
                isChkWidth = true;
              }
              if (this.listRoute[j].itemList[index].kbn == this.constData.rowKbnRow5) {
                textAnchor = 'end';
              } else {
                textAnchor = 'start';
              }
              replacements.push({key: '%7_' + (k + 1).toString() + '%', value: this.listRoute[j].itemList[index].col7, textAnchor: textAnchor, textLength: 100, chkWidth: isChkWidth});
              // 列８
              if (this.listRoute[j].itemList[index].col8 == null || this.listRoute[j].itemList[index].col8.length <= 12) {
                isChkWidth = false;
              } else {
                isChkWidth = true;
              }
              replacements.push({key: '%C8_' + (k + 1).toString() + '%', value: this.listRoute[j].itemList[index].col8, textAnchor: 'start', textLength: 290, chkWidth: isChkWidth});
              index++;
            } else {
              // 列１
              replacements.push({key: '%C1_' + (k + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 160, chkWidth: false});
              // 列２
              replacements.push({key: '%C2_' + (k + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 390, chkWidth: false});
              // 列３
              replacements.push({key: '%C3_' + (k + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 1010, chkWidth: false});
              // 列４
              replacements.push({key: '%C4_' + (k + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 510, chkWidth: false});
              // 列５
              replacements.push({key: '%C5_' + (k + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 140, chkWidth: false});
              // 列９
              replacements.push({key: '%9_' + (k + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 90, chkWidth: false});
              // 列６
              replacements.push({key: '%6_' + (k + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 100, chkWidth: false});
              // 列７
              replacements.push({key: '%7_' + (k + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 100, chkWidth: false});
              // 列８
              replacements.push({key: '%C8_' + (k + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 290, chkWidth: false});
            }
          }
          //console.log('replacements');
          //console.log(replacements);
          this.listRoute[j].listChouhyou[i].replacements = replacements;
        }
      }
    },
    /* 帳票に各種値セット */
    setChouhyou: function() {
      //console.log('setChouhyou');
      for (let j = 0; j < this.listRoute.length; j++) {
        for (let i = 0; i < this.listRoute[j].listChouhyou.length; i++){
          let svgDoc = document.getElementById(this.listRoute[j].shippingCode + '_' + this.constData.chouhyouId + '_' + this.listRoute[j].listChouhyou[i].page);
          this.setReplacements(svgDoc, this.listRoute[j].listChouhyou[i].replacements, svgDoc);
          this.setSize(svgDoc);
        }
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements: function(node, replacements, topNode) {
      //console.log('setReplacements');
      for(let i = 0; i < node.children.length; i++){
        if(node.children[i].tagName == 'text'){
          for(let j = 0; j < node.children[i].children.length; j++){
            if(node.children[i].children[j].tagName == 'tspan'){
              for(let k = 0; k < this.replacementsCommon.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(this.replacementsCommon[k].key) != -1){
                  this.setTspan(node.children[i].children[j], this.replacementsCommon[k]);
                  break;
                }
              }
              for(let k = 0; k < replacements.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1){
                  this.setTspan(node.children[i].children[j], replacements[k]);
                  if (this.lineY > 0) {
                    if (this.lineY <= this.constData.totalLineMaxY) {
                      // 最終行に線を追加しないため
                      topNode.append(this.createLine(this.lineY));                  
                    }
                    this.lineY = 0;
                  }
                  replacements.splice(k, 1);
                  break;
                }
              }
            }
          }
        } else if(node.children[i].tagName == 'g'){
          this.setReplacements(node.children[i], replacements, topNode);
        }
      }
    },
    /* Textタグ内のテキストを設定 */
    setTspan: function(tagTspan, config) {
      // 空行の場合
      if (config.key.indexOf('%C4_') != -1 &&
        config.value == this.constData.blankRowTitle) {
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, '');
      } else {
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(config.value));
      }
      if (config.isBold == true) {
        // 太字変換
        tagTspan.setAttribute('font-weight', 'bold');
      }
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if (config.chkWidth == true &&
        tagTspan.getBBox().width > config.textLength) {
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
      // 空行の場合
      if (config.key.indexOf('%C4_') != -1 &&
        config.value == this.constData.blankRowTitle) {
        /* 線を追加 */
        this.lineY = parseFloat(tagTspan.getAttribute('y')) + this.constData.totalLineHeight;
      }
    },
    /* 合計行を示す線を作成 */
    createLine: function(y) {
      let line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      line.setAttribute('x1', this.constData.totalLineX);
      line.setAttribute('x2', this.constData.totalLineX + this.constData.totalLineWidth);
      line.setAttribute('y1', y);
      line.setAttribute('y2', y);
      line.setAttribute('stroke', '#000000');
      line.setAttribute('stroke-width', 1);
      //line.setAttribute('stroke-dasharray', '10,10');
      return line;
    },
    /* 取得結果セット */
    setSize: function(svgDoc) {
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
  },
}
</script>
<style scoped>
</style>