<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container class="px-4 py-4 min-vh-85">
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 取引先製品マスタ入力</strong></h5>
              <b-button pill v-b-tooltip.hover.noninteractive title="取引先製品マスタ一覧に戻る" @click="clickBack" class="btn-cancel m-0">
                <span class="oi oi-circle-x"></span> キャンセル
              </b-button>
            </div>
          </b-media-body>
        </b-media>
        <b-card class="main-card mb-3">
          <b-card-header v-if="getMessageFlg==true">
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
            <validation-observer ref="observer">
              <b-form @submit.prevent="clearAlert(); saveData();" id="InputForm" class="form-horizontal">
                <b-row>
                    <!-- 取引先コード -->
                    <b-col lg="12">
                      <b-form-group
                        label="取引先コード"
                        label-for="clientCode"
                      >
                        <b-row>
                          <b-col lg="6">
                            <validation-provider name="suppliersCode" rules="numeric|min:6|max:6" v-slot="{ classes,errors }">
                            <b-input-group>
                              <b-form-input 
                                type="text" 
                                id="clientCode" 
                                v-model="suppliers.code" 
                                @input="searchClient(changeEstimateBrankId(suppliers.code, false))" 
                                maxlength="6"
                              />
                              <b-input-group-text @click="clearAlert(); showClientSearchModal();" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '1']" @shortkey="clearAlert(); showClientSearchModal();" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                              <b-button class="mr-1" size="sm" pill variant="success" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが空白以外の場合、利用可能です。）" :disabled="suppliers.code == ''" @click="clientInfoModal(suppliers.class, suppliers.code)">
                                <span class="oi oi-eye"></span> 情報
                              </b-button>
                              <b-button class="mr-1" size="sm" pill variant="success" title="取引先をクリアします。" @click="clientClear()">
                                <span class="oi oi-circle-x"></span> クリア
                              </b-button>
                            </b-input-group>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">検索ボタンから選択してください<br>「0：未選択」の登録も可能です（見積データ登録時は取引先を選択せずに登録される可能性あり）<br>情報ボタンは入力されたコードに紐づく詳細情報を表示します</b-form-text>
                      </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                  <!-- 取引先名 -->
                  <b-col lg="12">
                    <b-form-group
                      label="取引先名"
                      label-for="clientName"
                    >
                      <b-row>
                        <b-col lg="10">
                          <b-form-input type="text" id="clientName" v-model="suppliers.name" readonly />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 製品コード -->
                  <b-col lg="6">
                    <validation-provider name="productCode" rules="required|numeric|min:8" v-slot="{ classes,errors }">
                      <b-form-group
                        label="製品コード"
                        label-for="productCode"
                      >
                        <b-row>
                          <b-col lg="7" :class="classes">
                            <b-input-group>
                              <b-form-input 
                                id="productCode" 
                                name="productCode" 
                                type="text" 
                                v-model="products.code" 
                                @input="searchProduct(products.code)" 
                                maxlength="8" 
                              />
                              <b-input-group-text @click="clearAlert(); showProductSearchModal();" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '2']" @shortkey="clearAlert(); showProductSearchModal();" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                            </b-input-group>
                          </b-col>
                          <b-col lg="7" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">検索ボタンから選択してください</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- 製品名 -->
                  <b-col lg="6">
                    <b-form-group
                      label="製品名"
                      label-for="productName"
                    >
                      <b-row>
                        <b-col lg="12">
                          <b-form-input type="text" id="productName" v-model="products.name" readonly />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 適用年月日 -->
                  <b-col lg="6">
                    <validation-provider name="dpUnitPriceEffectiveDate" rules="required|oldDate" v-slot="{ classes,errors }">
                      <b-form-group
                        label="適用年月日"
                        label-for="dpUnitPriceEffectiveDate"
                      >
                        <b-row>
                          <b-col lg="10" :class="classes">
                            <b-input-group class="input-daterange">
                              <b-form-datepicker
                                id="dpUnitPriceEffectiveDate"
                                name="dpUnitPriceEffectiveDate"
                                v-model="dateUnitPriceEffectiveDate"
                                calendar-width="50%"
                              ></b-form-datepicker>
                            </b-input-group>
                          </b-col>
                          <b-col lg="10" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">単価の適用開始日を設定します</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- 登録区分 -->
                  <b-col lg="6">
                    <b-form-group
                      label="登録区分"
                      label-for="checkRegisterClass"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-row>
                        <b-col lg="12">
                          <b-form-radio-group
                            id="checkRegisterClass"
                            v-model="checkRegisterClass"
                            :options="registerClassList"
                            :aria-describedby="ariaDescribedby"
                            value-field="id"
                          ></b-form-radio-group>
                        </b-col>
                        <validation-provider name="checkRegisterClass" rules="required" v-slot="{ classes,errors }">
                          <b-col lg="12">
                            <input name="checkRegisterClass" type="hidden" v-model="checkRegisterClass" />
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </validation-provider>
                      </b-row>
                      <b-form-text class="text-muted">製品単価情報の登録に同製品グループも含めるかどうかを設定します<br>（0：同製品グループも含め登録、1：自身のみ登録）</b-form-text>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 適用単価 -->
                  <b-col lg="6">
                    <validation-provider name="salesUnitPrice" rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                      <b-form-group
                        label="適用単価"
                        label-for="salesUnitPrice"
                      >
                        <b-row>
                          <b-col lg="10" :class="classes">
                            <b-form-input id="salesUnitPrice" name="salesUnitPrice" type="number" v-model.number="textSalesUnitPrice" />
                          </b-col>
                          <b-col lg="10" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">指定された取引先に適用される単価を設定します</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- 単価登録区分 -->
                  <b-col lg="6">
                    <b-form-group
                      label="単価登録区分"
                      label-for="checkUnitPriceRegisterClass"
                      v-slot="{ ariaDescribedby }"
                    >
                      <b-row>
                        <b-col lg="12">
                          <b-form-radio-group
                            id="checkUnitPriceRegisterClass"
                            v-model="checkUnitPriceRegisterClass"
                            :options="unitPriceRegisterClassList"
                            :aria-describedby="ariaDescribedby"
                            value-field="id"
                          ></b-form-radio-group>
                        </b-col>
                        <validation-provider name="checkUnitPriceRegisterClass" rules="required" v-slot="{ classes,errors }">
                          <b-col lg="12">
                            <input name="checkUnitPriceRegisterClass" type="hidden" v-model="checkUnitPriceRegisterClass" />
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </validation-provider>
                      </b-row>
                      <b-form-text class="text-muted">製品単価情報の登録に取引先の親子も含めるかどうかを設定します<br>（3:親取引先で設定した親子取引先にも単価登録を行う）</b-form-text>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 理由 -->
                  <b-col lg="12">
                    <validation-provider name="reason" rules="max:25" v-slot="{ classes,errors }">
                      <b-form-group
                        label="理由"
                        label-for="reason"
                      >
                        <b-row>
                          <b-col lg="10" :class="classes">
                            <b-form-input id="reason" name="reason" type="text" v-model="textReason" />
                          </b-col>
                          <b-col lg="10" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">指定された取引先に適用される単価を設定します</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <!-- 保存ボタン -->
                <b-row class="justify-content-md-center pb-4">
                  <b-col lg="2">
                    <b-button class="mr-2" block pill variant="success" title="入力されたデータを保存します。" type="submit" form="InputForm">
                      <span class="oi oi-circle-check"></span> 保存
                    </b-button>
                  </b-col>
                </b-row>
                <b-form-text class="text-muted mt-3">※登録区分「0：グループ」にして保存した場合、同製品グループにも登録単価情報が登録されます。上書きされるため、注意してください。</b-form-text>
              </b-form>
            </validation-observer>
          </b-card-body>
          <b-card-footer>
            <b-row class="mt-2">
              <!-- 製品 -->
              <b-col lg="12">
                <b-table
                  :items="curData"
                  :fields="fields"
                >
                  <template #table-caption>
                    <b-btn-toolbar>
                      <span class="mt-2 mr-2">&nbsp;現在情報</span>
                      <!-- 取引先製品単価履歴照会ボタン -->
                      <b-button pill variant="success" size="sm" class="mr-2" title="取引先製品単価履歴照会モーダルを表示します。" @click="clickInquryClientProduct">
                        <span class="oi oi-eye"></span> 取引先製品単価履歴照会
                      </b-button>
                    </b-btn-toolbar>
                  </template>
                  <template #cell(SalesUnitPrice)="data">
                    <span :style="data.item.Style">{{data.item.SalesUnitPrice}}</span>
                  </template>
                  <template #cell(UnitPriceEffectiveDate)="data">
                    <span :style="data.item.Style">{{data.item.UnitPriceEffectiveDate}}</span>
                  </template>
                </b-table>
                <b-form-text class="text-muted">適用年月日が未来の情報は赤文字で表示されます。</b-form-text>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●取引先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="htmlConst.ClientClass.customer"/>
  <!-- ●●●取引先照会モーダル●●● -->
  <CLIENTINQUIRY :clientProp="propClientInquiry"/>
  <!-- ●●●製品検索モーダル●●● -->
  <PRODUCTSEARCH @from-child="closeProductSearchModal" :productSearchProp="productSearchProp"/>
  <!-- ●●●取引先製品単価履歴照会モーダル●●● -->
  <CLIENTPRODUCTINQUIRY :clientProductProp="clientProductProp" />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { searchClientModal, searchProductsModal } from '@/assets/js/searchModal.js';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import CLIENTPRODUCTINQUIRY from '@/components/modal/client-product-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, formatDate, formatCurDate, checkClientId, dateConsistency, addOperationLogs, CreateUpdateSql, CreateColRow, executeTransactSqlList, isSystemEditable, getInsertUnitPriceSqlList, escapeQuote, getNullStr, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'clients-products-master-input';

export default {
  name: 'CLIENTS-PRODUCTS-MASTER-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    CLIENTINQUIRY,
    PRODUCTSEARCH,
    CLIENTPRODUCTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '取引先製品マスタ入力',
      // アラート
      alertWarning: [],
      alertDanger: [],
      // 取引先コード確認関数
      checkClientId: checkClientId,
      // 取引先系
      suppliers:{
        class: 0,
        code: '',
        name: '',
      },
      // 製品系
      products:{
        code: null,
        name: '',
      },
      // 適用年月日
      dateUnitPriceEffectiveDate: null,
      // 適用単価
      textSalesUnitPrice: 0,
      // 登録区分ラジオボタン
      checkRegisterClass: null,
      registerClassList: Const.ClientsProductsMasterRegisterClassList,
      // 単価登録区分ラジオボタン
      checkUnitPriceRegisterClass: null,
      unitPriceRegisterClassList: [],
      // 理由
      textReason: '',
      // 現在日付(yyyy-mm-dd)
      today: '',
      // 検索結果
      curData: [],
      fields: [],
      // ログイン情報
      loginId: '',
      // 営業所情報
      officeListData: null,
      // トランザクションSQLリスト
      transactSqlList: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 取引先別製品照会モーダルのパラメータ
      clientProductProp: {
        clientClass: null,
        clientId: null,
        clientNameKanji: null,
        productIdCsv: null,
      },
      // 確認ダイアログ用
      confirmMessage: [],
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed:{
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 製品モーダルのパラメータ用 */
    productSearchProp: function() {
      return {
        office_id: null,
        office_name: null,
        client_class: this.suppliers.class,
        client_id: this.changeEstimateBrankId(this.suppliers.code, false),
        client_name: this.suppliers.name,
      };
    },
  },
  methods:{
    async fetchData(){
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 現在日付を「yyyy-mm-dd」の形式で作成
        this.today = formatCurDate('YYYY-MM-DD');
        // 単価登録区分リストを作成
        for (let i = 0; i < Const.UnitPriceRegisterClassList.length; i++) {
          if (Const.UnitPriceRegisterClassList[i].id != Const.UnitPriceRegisterClass.insertNo) {
            this.unitPriceRegisterClassList.push(Const.UnitPriceRegisterClassList[i]);
          }
        }
        // 現在情報フィールド初期化
        await this.initCurDataFields();
        // 現在情報テーブルに値を設定
        await this.setCurDataTable();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 現在情報フィールド初期化 */
    async initCurDataFields(){
      /* フィールド作成 */
      // 3列目までは固定値
      this.fields.push({ key: 'RowTitle', label: ' '});
      this.fields.push({ key: 'SalesUnitPrice', label: '単価', tdClass: 'text-right'});
      this.fields.push({ key: 'UnitPriceEffectiveDate', label: '適用年月日'});
      // 4列目以降は全営業所を設定
      this.officeListData = await selectOneTable('m_offices');
      //console.log(this.officeListData);
      for(let i = 0; i < this.officeListData.length; i++){
        let officeCol = {
          key: 'KeyOffice' + (i + 1).toString(),
          label: '原価' + this.officeListData[i].office_name_kanji,
          tdClass: 'text-right'
        };
        this.fields.push(officeCol);
      }
      let rowCurData1 = {};
      let rowCurData2 = {};
      let rowCurData3 = {};
      let rowCurData4 = {};
      // 最新
      rowCurData1['RowTitle'] = '最新';
      rowCurData1['SalesUnitPrice'] = '';
      rowCurData1['UnitPriceEffectiveDate'] = '';
      rowCurData1['Style'] = '';
      // 前回
      rowCurData2['RowTitle'] = '前回';
      rowCurData2['SalesUnitPrice'] = '';
      rowCurData2['UnitPriceEffectiveDate'] = '';
      rowCurData2['Style'] = '';
      // 製品マスター
      rowCurData3['RowTitle'] = '製品マスター';
      rowCurData3['SalesUnitPrice'] = '';
      rowCurData3['UnitPriceEffectiveDate'] = '';
      // QAマスター
      rowCurData4['RowTitle'] = 'QAマスター';
      rowCurData4['SalesUnitPrice'] = '';
      rowCurData4['UnitPriceEffectiveDate'] = '';
      for(let i = 0; i < this.officeListData.length; i++){
        rowCurData1['KeyOffice'+ (i + 1).toString()] = '';
        rowCurData2['KeyOffice'+ (i + 1).toString()] = '';
        rowCurData3['KeyOffice'+ (i + 1).toString()] = '';
        rowCurData4['KeyOffice'+ (i + 1).toString()] = '';
      }
      // curDataに追加
      this.curData.push(rowCurData1);
      this.curData.push(rowCurData2);
      this.curData.push(rowCurData3);
      this.curData.push(rowCurData4);
    },
    /* 現在情報テーブルに値を設定 */
    async setCurDataTable() {
      // 取引先製品マスタ設定
      await this.setCurDataClientsProducts();
      // 製品マスタ設定
      await this.setCurDataProducts();
      // QAマスタ設定
      await this.setCurDataQA();
    },
    /* 取引先製品マスタの現在情報を取得 */
    async setCurDataClientsProducts() {
      // 最新
      let salesUnitPrice1 = '';
      let unitPriceEffectiveDate1 = '';
      let style1 = '';
      // 前回
      let salesUnitPrice2 = '';
      let unitPriceEffectiveDate2 = '';
      let style2 = '';
      // 製品コードに数値が入力されている場合のみ
      if (isNaN(Number(this.products.code)) == false) {
        let where_clause = '';
        // 取引先区分
        where_clause += 'AND client_class = ' + this.suppliers.class + ' ';
        // 取引先コード
        where_clause += 'AND client_id = ' + this.changeEstimateBrankId(this.suppliers.code, false) + ' ';
        // 製品コード
        where_clause += 'AND product_id = ' + this.products.code + ' ';
        // ソート（適用日の直近と2番目の日付）
        where_clause += 'ORDER BY unit_price_effective_date DESC ';
        where_clause += 'LIMIT 2 ';

        let dataClientsProducts = await selectOneTable('m_clients_products', where_clause);
        //console.log(dataClientsProducts);

        if (dataClientsProducts != null) {
          // 最新
          if (dataClientsProducts.length > 0) {
            salesUnitPrice1 = dataClientsProducts[0].sales_unit_price.toLocaleString();
            unitPriceEffectiveDate1 = formatDate(dataClientsProducts[0].unit_price_effective_date);
            if (dateConsistency(unitPriceEffectiveDate1, this.today) == false) {
              // 適用日が未来日の場合
              style1 = 'color: red;';
            }
          }
          // 前回
          if (dataClientsProducts.length > 1) {
            salesUnitPrice2 = dataClientsProducts[1].sales_unit_price.toLocaleString();
            unitPriceEffectiveDate2 = formatDate(dataClientsProducts[1].unit_price_effective_date);
            if (dateConsistency(unitPriceEffectiveDate2, this.today) == false) {
              // 適用日が未来日の場合
              style2 = 'color: red;';
            }
          }
        }
      }
      // 最新
      this.curData[0]['SalesUnitPrice'] = salesUnitPrice1;
      this.curData[0]['UnitPriceEffectiveDate'] = unitPriceEffectiveDate1;
      this.curData[0]['Style'] = style1;
      // 前回
      this.curData[1]['SalesUnitPrice'] = salesUnitPrice2;
      this.curData[1]['UnitPriceEffectiveDate'] = unitPriceEffectiveDate2;
      this.curData[1]['Style'] = style2;
    },
    /* 製品マスタの現在情報を取得 */
    async setCurDataProducts() {
      // 全原価を初期化
      for(let i = 0; i < this.officeListData.length; i++){
        // 製品コードに数値が入力されている場合のみ
        if (isNaN(Number(this.products.code)) == false) {
          this.curData[2]['KeyOffice'+ (i + 1).toString()] = '0';
        } else {
          this.curData[2]['KeyOffice'+ (i + 1).toString()] = '';
        }
      }
      // 製品コードに数値が入力されている場合のみ
      if (isNaN(Number(this.products.code)) == false) {
        let where_clause = '';
        // 製品コード
        where_clause += 'AND product_id = ' + this.products.code + ' ';
        // 9Aフラグ
        where_clause += 'AND is_9A = false ';

        let dataProducts = await selectOneTable('v_products_01', where_clause);
        //console.log(dataProducts);
        if (dataProducts != null) {
          for (let i = 0; i < dataProducts.length; i++) {
            if (i == 0) {
              this.curData[2]['SalesUnitPrice'] = dataProducts[0].sales_unit_price.toLocaleString();
            }
            for(let j = 0; j < this.officeListData.length; j++){
              if (dataProducts[i].office_id == this.officeListData[j].office_id) {
                this.curData[2]['KeyOffice'+ (j + 1).toString()] = dataProducts[i].cost_price.toLocaleString();
                break;
              }
            }
          }
        }
      } else {
        this.curData[2]['SalesUnitPrice'] = '';
      }
    },
    /* QAマスタの現在情報を取得 */
    async setCurDataQA() {
      // 製品コードに数値が入力されている場合のみ
      if (isNaN(Number(this.products.code)) == false) {
        this.curData[3]['KeyOffice1'] = '0';
        this.curData[3]['SalesUnitPrice'] = '0';
        let where_clause = '';
        // 製品コード
        where_clause += 'AND product_id = ' + this.products.code + ' ';
        // 9Aフラグ
        where_clause += 'AND is_9A = true ';

        let dataProducts = await selectOneTable('v_products_01', where_clause);
        //console.log(dataProducts);
        if (dataProducts != null) {
          for (let i = 0; i < dataProducts.length; i++) {
            if (i == 0) {
              this.curData[3]['SalesUnitPrice'] = dataProducts[0].sales_unit_price.toLocaleString();
            }
            for(let j = 0; j < this.officeListData.length; j++){
              if (j == 0) {
                if (dataProducts[i].office_id == this.officeListData[j].office_id) {
                  this.curData[3]['KeyOffice'+ (j + 1).toString()] = dataProducts[i].purchase_price.toLocaleString();
                  break;
                }
              } else {
                break;
              }
            }
          }
        }
      } else {
        this.curData[3]['KeyOffice1'] = '';
        this.curData[3]['SalesUnitPrice'] = '';
      }
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存前に重複チェック
      if (await this.checkDuplicate() == true) {
        // 保存処理
        //console.log('保存処理開始');
        if (await this.execSave() == true) {
          // 取引先製品マスタ修正へ遷移
          this.$router.push({ name: 'CLIENTS-PRODUCTS-MASTER-EDIT', query: { propClientClass: this.suppliers.class, propClientId: this.changeEstimateBrankId(this.suppliers.code, false), propProductId: this.products.code, propUnitPriceEffectiveDate: this.dateUnitPriceEffectiveDate, propInsertFlg: true } });
        }
        //console.log('保存処理終了');
      } else {
        this.alertWarning.push('指定された適用年月日は' + DISP_MESSAGES.WARNING['2004']);
      }
    },
    /* 重複チェック */
    async checkDuplicate() {
      let where_clause = '';
      // 取引先区分
      where_clause += 'AND client_class = ' + this.suppliers.class + ' ';
      // 取引先コード
      where_clause += 'AND client_id = ' + this.changeEstimateBrankId(this.suppliers.code, false) + ' ';
      // 製品コード
      where_clause += 'AND product_id = ' + this.products.code + ' ';
      // 単価適用日
      where_clause += 'AND unit_price_effective_date = \'' + this.dateUnitPriceEffectiveDate + '\'';

      let dataClientsProducts = await selectOneTable('m_clients_products', where_clause);
      //console.log(dataClientsProducts);

      // 1件以上の場合は重複
      if (dataClientsProducts != null && dataClientsProducts.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    /* 保存処理 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      // 単価登録
      await this.insertUnitPrice();

      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return retResult;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return retResult;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return retResult;
      }

      retResult = true;

      return retResult;
    },
    /* 単価登録処理 */
    async insertUnitPrice(){
      //console.log('自身の単価登録処理');
      // 単価登録用に製品一覧を作り直す（無効な製品と重複製品の除外（重複は後優先））
      let unitPriceProductList = [];
      let productRow = {
        ProductCode: this.products.code,
        SellingPrice: this.textSalesUnitPrice,
      };
      unitPriceProductList.push(productRow);
      //console.log(unitPriceProductList);
      // 単価登録用SQL作成
      let sqlList = await getInsertUnitPriceSqlList(unitPriceProductList, this.changeEstimateBrankId(this.suppliers.code, false), this.dateUnitPriceEffectiveDate, this.checkRegisterClass, this.checkUnitPriceRegisterClass, this.loginId);
      for (let i = 0; i < sqlList.length; i++) {
        this.transactSqlList.push(sqlList[i]);
      }
      // 更新後に画面入力の理由で更新
      await this.updateClientsProducts();
    },
    /* 取引先製品更新処理 */
    async updateClientsProducts() {
      let updateSql = '';
      let colList = [];
      // 理由
      colList.push(CreateColRow('reason', await escapeQuote(this.textReason), 'VARCHAR'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      // WHERE
      let where_clause = ' WHERE ';
      // 取引先区分
      where_clause += 'client_class = ' + this.suppliers.class + ' ';
      // 取引先コード
      where_clause += 'AND client_id = ' + this.changeEstimateBrankId(this.suppliers.code, false) + ' ';
      // 製品コード
      where_clause += 'AND product_id = ' + this.products.code + ' ';
      // 単価適用日
      where_clause += 'AND unit_price_effective_date = \'' + this.dateUnitPriceEffectiveDate + '\'';

      // 更新SQL作成
      updateSql = CreateUpdateSql(colList, 'm_clients_products') + where_clause;

      //console.log(updateSql)
      this.transactSqlList.push(updateSql);
    },
    // 取引先検索
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      const result = await searchClientModal(client_id, this.htmlConst.ClientClass.customer, 'searchClient', MODULE_NAME)
      if (typeof result != 'undefined') {
        this.closeClientSearchModal(result)
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6 || client_id == '0') {
          // 取引先クリア
          await this.clientClear();
        }
      }
    },
    // 取引先検索モーダルを閉じた時
    async closeClientSearchModal(clientItems) {
      const functionName = 'closeClientSearchModal';
      //console.log(clientItems);
      try {
        // モーダルから渡された値の有無チェック
        if(typeof clientItems != 'undefined'){
          this.suppliers.class = clientItems.detail.client_class;
          this.suppliers.code = clientItems.detail.client_id;
          this.suppliers.name = clientItems.detail.client_name_kanji;
          // 現在情報を再取得
          await this.setCurDataTable();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
    },
    // 取引先クリア
    async clientClear() {
      //console.log('取引先クリア');
      this.suppliers.class = 0;
      this.suppliers.code = '';
      this.suppliers.name = '';
      // 現在情報を再取得
      await this.setCurDataTable();
    },
    /* 取引先照会 */
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    // 製品検索
    showProductSearchModal: function() {
      this.$bvModal.show('productSearchModal');
    },
    // 製品コード直接入力 - 製品検索
    async searchProduct(product_id) {
      this.productSearchProp.office_id = this.$store.getters.user.office_id;
      this.productSearchProp.office_name = null
      this.productSearchProp.client_id = this.suppliers.code;
      this.productSearchProp.client_class = this.suppliers.class;
      this.productSearchProp.client_name = this.suppliers.name;
      const result = await searchProductsModal(product_id, this.productSearchProp, 'searchProduct', MODULE_NAME)
      if (typeof result != 'undefined') {
        this.closeProductSearchModal(result)
      } else {
        // 製品コード8桁かつデータ取得失敗の場合
        if (product_id.length == 8 || getNullStr(product_id) == '') {
          // 製品コードクリア
          await this.productClear();
        }
      }
    },
    // 製品検索モーダルを閉じた時
    async closeProductSearchModal(productItems) {
      const functionName = 'closeProductSearchModal';
      //console.log(productItems);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof productItems != 'undefined') {
          this.products.code = productItems.detail.product_id;
          this.products.name = productItems.detail.product_name_kanji;
          // 現在情報を再取得
          await this.setCurDataTable();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
    },
    // 取引先製品単価履歴照会モーダルを開く
    clickInquryClientProduct:function() {
      //console.log('取引先製品単価履歴照会モーダル');
      // 取引先別製品照会モーダルのパラメータ
      this.clientProductProp.clientClass = this.suppliers.class;
      this.clientProductProp.clientId = this.changeEstimateBrankId(this.suppliers.code, false);
      this.clientProductProp.clientNameKanji = this.suppliers.name;
      this.clientProductProp.productIdCsv = this.products.code;
      this.$bvModal.show('clientProductInquiryModal');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* キャンセルボタン押下時 */
    async clickBack() {
      // 親取引先マスタ一覧画面へ遷移
      this.$router.push({ name: 'CLIENTS-PRODUCTS-MASTER-LIST' });
    },
    // 見積の空白設定コードの変換
    changeEstimateBrankId: function(id, isChangeBrank) {
      if (isChangeBrank == true) {
        // 空白に変更フラグが立っている場合
        // 0の場合、空白に変換して返却
        return getNullStr(id) == '0' ? '' : id;
      } else {
        // 空白に変換フラグが立っていない場合
        return getNullStr(id) == '' ? '0' : id;
      }
    },
    // 製品クリア
    async productClear() {
      this.products.code = null;
      this.products.name = '';
      // 現在情報を再取得
      await this.setCurDataTable();
    },
  }
}
</script>
<style scoped>
</style>