<template>
  <!-- 営業所保守一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row">
        <div class="col-lg-12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <div class="card border">
              <div class="card-header mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip">
                  <a data-bs-toggle="collapse" class="text-secondary text-body" style="display: block;float:left;" href="#collapse1" role="tablist" aria-expanded="true" aria-controls="collapse1" title="クリックすると検索条件表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                    <b-button size="sm" v-b-tooltip.hover title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                      <span class="oi oi-print"></span>
                    </b-button>
                  <router-link to="/office-input" class="btn btn-sm btn-primary mx-2" style="font-size:60%;float:right;" title="営業所マスタの新規登録を行います。">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </router-link>
                </h5>
              </div>
              <div id="collapse1" class="collapse show">
                <div class="card-body p-2">
                  <div class="container-fluid">
                    <div class="alert alert-danger" role="alert" v-if="searchErrors.length">
                      <ul v-for="(error,index) in searchErrors" :key="index" style="list-style: none;">
                        <li>{{error}}</li>
                      </ul>
                    </div>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <div class="row mt-2">
                        <!-- 営業所コード入力欄 -->
                        <div class="col col-6 form-group">
                          <validation-provider name="offieId" rules="numeric" v-slot="{ classes,errors }">
                            <div class=""><label for="text-input" class="form-control-label">営業所コード</label></div>
                            <div :class="classes">
                              <input type="text" id="offieId" name="offieId" class=" form-control" v-model="searchConditions.officeId">
                              <small class="form-text text-muted">完全一致検索です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 営業所名入力欄 -->
                        <div class="col-md-6">
                          <div class="form-group ">
                            <label class="control-label">営業所名</label>
                            <input type="text" id="offieName" name="offieName" class=" form-control" v-model="searchConditions.officeName">
                            <small class="form-text text-muted">部分一致検索です。登録済みの営業所名（漢字）または営業所名（カナ）と一致するデータを検索します。</small>
                          </div>
                        </div>
                      </div>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <div class="row justify-content-md-center my-2">
                      <div class="col-lg-2">
                        <button type="button" class="btn btn-primary btn-block" @click="searchButton()"><span class="oi oi-magnifying-glass"></span> 検索</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ●●●検索結果●●● -->
      <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <div class="row">
          <div class="col-sm-12">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col  lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="4"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-table hover
                table-class="datatable"
                show-empty
                :head-variant="headVariant"
                :responsive= true
                :items="officeList"
                :fields="fields"
                :busy="busy"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                :bordered= true
                :empty-text="emptyText"
                :empty-filtered-text="emptyFilterdText"
              >
                <template #custom-foot="data">
                  {{ totalRows + ' 件中 ' + (currentPage==1?currentPage:(((currentPage*perPage) - perPage)) + 1) + ' から ' + (((((currentPage*perPage) - perPage)) + 1) + data.items.length -1) + ' まで表示'}}
                </template>
                <!-- テーブル読み込み時表示html -->
                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>読み込んでいます...</strong>
                  </div>
                </template>
                <!-- 操作列 -->
                <template #cell(operation)="operation">
                  <div v-show="!printStatus">
                    <!-- 編集ボタン -->
                    <b-button size="sm" v-b-tooltip.hover title="営業所マスタの編集を行います。" @click="clickEditBtn(operation.item.officeId)" class="mr-1">
                      <span class="oi oi-pencil"></span> 編集
                    </b-button>    
                  </div>
                </template>
              </b-table>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import {init, windowPrint, convertSqlLikeSpecialChar, selectOneTable} from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

export default {
  name: 'OFFICE-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '営業所保守',
      // 検索条件エラー
      searchErrors: [],
      // 検索条件
      searchConditions:{
        officeId: '',
        officeName: ''
      },
      // 検索結果
      officeList: [],
      // 検索結果
      resultError: [],
      fields:DataTblDef.office_list_fields,
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText:DataTblDef.emptyFilteredText,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // プリントアウト状態
      printStatus: false,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  /* 関数群 */
  methods:{
    shortkey() {
      this.$router.push({ name: 'OFFICE-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },

    /* フェッチ */
    async fetchData(){
      try {
        this.busy = true;
        // 検索処理(初期表示は全件取得)
        let where_clause = '';
        let resultData = await selectOneTable('m_offices', where_clause);
        //console.log('初期表示テーブルデータ');
        //console.log(resultData);
        await this.setResult(resultData);
        this.busy = false;
      } catch (error) {
        console.log(error);
      }
    },
    /* 検索ボタン押下時 */
    async searchButton(){
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
        console.info('validationエラーあり');
      }else{
        this.$store.commit('setLoading', true);
        //await this.dataCheck();
        this.busy = true;
        await this.search();
        this.busy = false;
        this.$store.commit('setLoading', false);
      }
    },
    /* 検索処理 */
    async search(){
      this.officeList = [];
      // CRUD処理
      let where_clause = '';
      where_clause = await this.makeCondition();
      // where_clause += 'GROUP BY officeId';
      //console.log(where_clause);
      try {
        //console.log('条件取得');
        let resultData = await selectOneTable('m_offices', where_clause);
        if(resultData != null){
          await this.setResult(resultData);
        }else{
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        console.log(error);
      }
    },
    /* 取得結果セット */
    async setResult(result){
      this.resultError = []
      // 検索結果が1000件の場合は1000件を超えている場合があるのでメッセージを表示します。
      if (result.length > 1000) {
        this.resultError.push(DISP_MESSAGES.WARNING['2002']);
      }
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for(let i = 0; i < result.length; i++){
        let searchResult = {
          officeId: result[i].office_id,
          officeName: result[i].office_name_kanji,
          Operation: result[i],
        };
        this.officeList.push(searchResult);
      }
    },
    /* 検索条件文字列作成 */
    async makeCondition(){
      let where_clause = '';
      // 営業所コード
      if(this.searchConditions.officeId != ''){
        where_clause += 'AND office_id = ' + Number(this.searchConditions.officeId) + ' ';
      }
      // 営業所名(あいまい検索対象)
      if(this.searchConditions.officeName != ''){
        const officeName = convertSqlLikeSpecialChar(this.searchConditions.officeName);
        where_clause += `AND (REPLACE(REPLACE(CONVERT(office_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${officeName}%', 'ﾞ', ''), 'ﾟ', '') `;
        where_clause += `OR REPLACE(REPLACE(CONVERT(office_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${officeName}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }

      return where_clause;
    },

    /* 詳細ボタン押下時 */
    async clickEditBtn(officeId){
      console.log('詳細');
      console.log('営業所コード：' + officeId);
      let route = this.$router.resolve({ name: 'OFFICE-EDIT', query: { officeId: officeId } });
      console.log(this.$router);
      window.open(route.href, '_blank');
    },
  },
}
</script>
<style scoped>
  table {
    width: 100% !important;
    table-layout: auto;
  }

  table thead tr th{
    background-color: gray;
  }
</style>