<template>
  <!-- 照会レポート定義一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row">
        <div class="col-lg-12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <div class="card border">
              <div class="card-header mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip">
                  <a v-b-toggle.collapse-1 title="クリックすると検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                    <b-button size="sm" v-b-tooltip.hover title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                      <span class="oi oi-print"></span>
                    </b-button>
                  <router-link to="/inquiry-report-input" class="btn btn-sm btn-primary mx-2" style="font-size:60%;float:right;" title="照会レポート定義の新規登録を行います。">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </router-link>
                </h5>
              </div>
              <b-collapse id="collapse-1" :visible="true">
                <div class="card-body p-2">
                  <div class="container-fluid">
                    <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                      <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="warning" class="mt-2" v-if="errorMessages.length">
                      <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <div class="row mt-2">
                        <div class="col-md-6 form-group">
                          <label for="inquiryName" class="form-label">定義名</label>
                          <div><b-form-input id="inquiryName" v-model="inquiryName"></b-form-input></div>
                        </div>
                        <div class="col-md-6 form-group">
                          <label for="description" class="form-label">説明</label>
                          <div><b-form-input id="description" v-model="description"></b-form-input></div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-6 form-group">
                          <label for="createdUser" class="form-label">作成者</label>
                          <div><b-form-input id="createdUser" v-model="createdUser"></b-form-input></div>
                        </div>
                        <div class="col-md-6 form-group">
                          <label for="updatedUser" class="form-label">更新者</label>
                          <div><b-form-input id="updatedUser" v-model="updatedUser"></b-form-input></div>
                        </div>
                      </div>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <div class="row justify-content-md-center my-2">
                      <div class="col-lg-2">
                        <button type="button" class="btn btn-primary btn-block" @click="onSearchButtonClick"><span class="oi oi-magnifying-glass"></span> 検索</button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
      <!-- ●●●検索結果●●● -->
      <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <div class="row">
          <div class="col-sm-12">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="4"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-col cols="12" class="px-0">
                <b-table
                  show-empty
                  :head-variant="headVariant"
                  :responsive="true"
                  :items="items"
                  :fields="fields"
                  :busy="busy"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :bordered="true"
                  :empty-text="emptyText"
                  :empty-filtered-text="emptyFilterdText"
                  @filtered="onFiltered"
                >
                  <!-- テーブル読み込み時表示html -->
                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>読み込んでいます...</strong>
                    </div>
                  </template>
                  <template #cell(index)="data">
                    <div>{{ perPage * currentPage - perPage + data.index + 1 }}</div>
                  </template>
                <!-- 操作列 -->
                  <template #cell(operation)="data">
                    <div v-show="!printStatus">
                      <!-- 編集ボタン -->
                      <!-- <b-button size="sm" v-b-tooltip.hover title="照会レポート定義を編集します。" @click="onEditButtonClick($event, data.item)" style="width: 63px;"> -->
                      <b-button size="sm" @click="onEditButtonClick($event, data.item)" style="width: 63px;">
                        <span class="oi oi-pencil"></span> 編集
                      </b-button>
                      <!-- 複製ボタン -->
                      <b-button size="sm" @click="onCopyButtonClick($event, data.item)" style="width: 63px;" class="ml-2">
                        <span class="oi oi-plus"></span> 複製
                      </b-button>
                      <!-- 削除ボタン -->
                      <b-button size="sm" @click="onDeleteButtonClick($event, data.item)" style="width: 63px;" class="ml-2">
                        <span class="oi oi-delete"></span> 削除
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- 表示レコードをラベル表示 -->
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null || '' ? filterRows : items.length"
                :per-page="perPage == -1 ? items.length : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, windowPrint, escapeQuote, convertSqlLikeSpecialChar, init, isSystemEditable, CreateColRow, CreateInsertSql, executeTransactSqlList, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'inquiry-report-maintenance';

export default {
  name: 'INQUIRY-REPORT-MAINTENANCE',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '照会レポート定義一覧',
      inquiryName: '',
      description: '',
      createdUser: '',
      updatedUser: '',
      items: [],
      // 画面メッセージ
      successMessages: [],
      errorMessages: [],
      // 検索結果
      resultError: [],
      fields: DataTblDef.inquiry_report_list_fields,
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText:DataTblDef.emptyFilteredText,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // プリントアウト状態
      printStatus: false,
    };
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.items.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    },
  },
  /**
   * mountedライフルサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    //await this.search('');
    this.$store.commit('setLoading', false);
  },
  /* 関数群 */
  methods: {
    shortkey() {
      this.$router.push({ name: 'INQUIRY-REPORT-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * ページング変数の初期化
     */
    initPaging: function() {
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * 検索処理
     * @param {String} whereClause - 検索条件
     * @returns {Array} 検索に成功した場合は検索結果のリスト、失敗した場合はnull
     */
    async search(whereClause) {
      const functionName = 'searchInquiryDefinition';
      let result = null;
      try {
        result = await selectOneTable('m_inquiries_definitions', `${whereClause} LIMIT 1000`);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_inquiries_definitions',
          where_clause: whereClause
        }, error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }
      // ページングの初期化
      this.initPaging();
      if (result != null && result.length > 0) {
        this.items = result;
        // 検索結果が1000件の場合は1000件を超えている場合があるのでメッセージを表示します。
        if (this.items.length === 1000) {
          this.resultError.push(DISP_MESSAGES.WARNING['2002']);
        }
      } else {
        this.items = [];
      }
    },
    /**
     * 画面から検索条件を取得して検索を行います。
     */
    async onSearchButtonClick() {
      this.busy = true;
      this.resultError = [];

      let whereClause = '';
      if (this.inquiryName !== '') {
        whereClause += `AND REPLACE(REPLACE(CONVERT(inquiry_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${convertSqlLikeSpecialChar(this.inquiryName)}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      if (this.description !== '') {
        whereClause += `AND REPLACE(REPLACE(CONVERT(description USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${convertSqlLikeSpecialChar(this.description)}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      if (this.createdUser !== '') {
        whereClause += `AND created_user LIKE '%${await escapeQuote(this.createdUser)}%' `
      }
      if (this.updatedUser !== '') {
        whereClause += `AND updated_user LIKE '%${await escapeQuote(this.updatedUser)}%' `
      }
      await this.search(whereClause);
      this.busy = false;
    },
    /**
     * 削除ボタンがクリックされた定義を削除します。
     * @param {Object} event - イベントオブジェクト
     * @param {Object} item - 削除対象の定義
     */
    async onDeleteButtonClick(event, item) {
      const functionName = 'onDeleteButtonClick';

      this.successMessages = [];
      this.errorMessages = [];
      if (await this.$bvModal.msgBoxConfirm('選択された照会定義を削除します。\nよろしいですか？')) {
        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.errorMessages.push(msg);
            scrollTo(0,0);
            return;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
          scrollTo(0,0);
          return;
        }

        // 削除SQL文作成
        let deleteSql = 'DELETE FROM m_inquiries_definitions WHERE ';
        deleteSql += 'id = ' + item.id + ' ';

        // 削除します。
        let result = await executeTransactSqlList([deleteSql], MODULE_NAME, functionName);
        let logLevel = 'Info';
        if (result == true) {
          // 削除成功
          this.successMessages.push(DISP_MESSAGES.SUCCESS['1002']);
        } else {
          // 削除失敗
          logLevel = 'Error';
          this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
        }
        await addOperationLogs(logLevel, MODULE_NAME, functionName, {
          graphqlOperation: 'delete_m_inquiries_definitions',
          variables: { id: item.id },
          result: result
        });
        await this.onSearchButtonClick();
        scrollTo(0,0);
      }
    },
    /**
     * 編集ボタンがクリックされた定義を開きます。
     * @param {Object} event - イベントオブジェクト
     * @param {Object} item - 削除対象の定義
     */
    onEditButtonClick(event, item) {
      let route = this.$router.resolve({ name: 'INQUIRY-REPORT-EDIT', query: { id: item.id } });
      window.open(route.href, '_blank');
    },
    /**
     * 複製ボタンがクリックされた定義を複製します。
     * @param {Object} event - イベントオブジェクト
     * @param {Object} item - 複製対象の定義
     */
    async onCopyButtonClick(event, item) {
      const functionName = 'onCopyButtonClick';

      this.successMessages = [];
      this.errorMessages = [];
      if (await this.$bvModal.msgBoxConfirm('選択された照会定義を複製します。\nよろしいですか？')) {
        this.$store.commit('setLoading', true);
        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.errorMessages.push(msg);
            scrollTo(0,0);
            this.$store.commit('setLoading', false);
            return;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
          scrollTo(0,0);
          this.$store.commit('setLoading', false);
          return;
        }

        // 複製INSERT文
        let sqlList = [];
        let insertSql = await this.copyInquiriesDefinitions(item.id);
        sqlList.push(insertSql);
        let result = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);

        if (result == false) {
          this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        } else {
          this.successMessages.push(DISP_MESSAGES.SUCCESS['1001']);
        }
        await this.onSearchButtonClick();
        scrollTo(0,0);
        this.$store.commit('setLoading', false);
      }
    },
    /**
     * 既存の照会定義マスタをコピーするINSERT文作成
     */
    async copyInquiriesDefinitions(id) {
      // 作成ユーザー
      const username = await escapeQuote(this.$store.getters.user.username);
      let colList = [];
      // 定義名
      colList.push(CreateColRow('inquiry_name', null, 'VARCHAR'));
      // 説明
      colList.push(CreateColRow('description', null, 'VARCHAR'));
      // 参照テーブル
      colList.push(CreateColRow('froms', null, 'VARCHAR'));
      // 検索条件
      colList.push(CreateColRow('wheres', null, 'VARCHAR'));
      // 読み込み方法
      colList.push(CreateColRow('load_method', null, 'NUMBER'));
      // 読み込み方向
      colList.push(CreateColRow('load_direction', null, 'NUMBER'));
      // 出力項目
      colList.push(CreateColRow('output_items', null, 'VARCHAR'));
      // クロス左側キー項目
      colList.push(CreateColRow('cross_left_keys', null, 'NUMBER'));
      // クロス上側キー項目
      colList.push(CreateColRow('cross_top_keys', null, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      let insertSql = 'INSERT INTO m_inquiries_definitions (' + CreateInsertSql(colList, 'col', 'm_inquiries_definitions') + ') ';
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      // 定義名
      selectSql += ' inquiry_name';
      // 説明
      selectSql += ',description';
      // 参照テーブル
      selectSql += ',froms';
      // 検索条件
      selectSql += ',wheres';
      // 読み込み方法
      selectSql += ',load_method';
      // 読み込み方向
      selectSql += ',load_direction';
      // 出力項目
      selectSql += ',output_items';
      // クロス左側キー項目
      selectSql += ',cross_left_keys';
      // クロス上側キー項目
      selectSql += ',cross_top_keys'
      // 作成ユーザー
      selectSql += ',\'' + username + '\'';
      // 更新ユーザー
      selectSql += ',\'' + username + '\'';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 'm_inquiries_definitions ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 'ID = ' + id + ' ';

      insertSql += selectSql;

      //console.log(insertSql);

      return insertSql;
    },
  }
};
</script>