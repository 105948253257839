<template>
  <!-- 9A製品保守照会画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 9A製品保守照会</strong></h5>
                <router-link to="/products9A-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <!-- 製品コード -->
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                  <label for="product_class_id" class="form-label"><strong>製品分類コード</strong></label>
                  <input type="text" id="product_class_id" class="form-control" v-model="productObj.product_class_id" readonly>
                </div>
                <!-- 枝番 -->
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                  <label for="branchNumber" class="form-label"><strong>枝番</strong></label>
                  <input type="text" id="branchNumber" class="form-control" v-model="productObj.branchNumber" readonly>
                </div>
                <!-- 製品名(漢字) -->
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 form-group" v-b-tooltip.hover.noninteractive :title="productObj.product_name_kanji">
                  <label for="product_name_kanji" class="form-label"><strong>製品名(漢字)</strong></label>
                  <input type="text" id="product_name_kanji" class="form-control" v-model="productObj.product_name_kanji" readonly>
                </div>
                <!-- 製品名(カナ) -->
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 form-group" v-b-tooltip.hover.noninteractive :title="productObj.product_name_kana">
                  <label for="product_name_kana" class="form-label"><strong>製品名(カナ)</strong></label>
                  <input type="text" id="product_name_kana" class="form-control" v-model="productObj.product_name_kana" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 単位 -->
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                  <label for="unit" class="form-label"><strong>単位</strong></label>
                  <input type="text" id="unit" class="form-control" v-model="productObj.unit" readonly>
                </div>
                <!-- 売上単価 -->
                 <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                  <label for="sales_unit_price" class="form-label"><strong>売上単価</strong></label>
                  <input type="text" id="sales_unit_price" class="form-control" v-model="productObj.sales_unit_price" readonly>
                </div>
                <!-- 製品チェック区分 -->
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                  <label for="product_check_class" class="form-label"><strong>製品チェック区分</strong></label>
                  <input type="text" id="product_check_class" class="form-control" v-model="productObj.product_check_class" readonly>
                </div>
                <!-- 在庫管理区分 -->
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                  <label for="inventory_control_class" class="form-label"><strong>在庫管理区分</strong></label>
                  <input type="text" id="inventory_control_class" class="form-control" v-model="productObj.inventory_control_class" readonly>
                </div>
                <!-- 売上消費税率区分 -->
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                  <label for="product_tax_rate_class_sales" class="form-label"><strong>売上消費税率区分</strong></label>
                  <input type="text" id="product_tax_rate_class_sales" class="form-control" v-model="productObj.product_tax_rate_class_sales" readonly>
                </div>
                <!-- 仕入消費税率区分 -->
                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                  <label for="product_tax_rate_class_purchase" class="form-label"><strong>仕入消費税率区分</strong></label>
                  <input type="text" id="product_tax_rate_class_purchase" class="form-control" v-model="productObj.product_tax_rate_class_purchase" readonly>
                </div>
              </div>
              <!-- 営業所テーブル -->
              <div class="row mt-2">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 form-group">
                  <b-table
                    show-empty
                    :head-variant="headVariant"
                    :responsive="true"
                    :items="productInfos"
                    :fields="fields"
                    :busy="busy"
                    :bordered="true"
                    :empty-text="emptyText"
                  >
                    <!-- テーブル読み込み時表示html -->
                    <template #table-busy>
                      <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>読み込んでいます...</strong>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>  
              <div class="card-footer">
                <div class="row justify-content-md-center">
                  <div class="col-lg-2">
                    <button type="button" class="btn btn-primary btn-block" @click="clickEditBtn(productObj.id)"><span class="oi oi-pencil"></span> 編集</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, init, selectOneTable } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'product9A-inquiry';

export default {
  name: 'PRODUCT9A-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '9A製品保守照会',
      // 営業所ごとテーブル
      fields: DataTblDef.products_office_list_fields_9A,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      busy: false,
      // 製品対象
      productObj: {},
      // 営業所リスト
      officeList: [],
      // 製品対象詳細
      productInfos:[],
      // 処理結果成功メッセージ
      successMessages: [],
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  /** 監視 */
  watch: {
    productObj: {
      handler(productObj) {
        // 売上単価表示処理
        productObj.sales_unit_price = productObj.sales_unit_price.toLocaleString()
        // 製品チェック区分リスト
        productObj.product_check_class = Const.ProductCheckClass9A[productObj.product_check_class].text
        // 在庫管理区分リスト
        productObj.inventory_control_class = Const.InventoryControlClass[productObj.inventory_control_class].text
        // 売上消費税率区分
        productObj.product_tax_rate_class_sales = Const.ProductTaxRateClassList[productObj.product_tax_rate_class_sales].text
        // 仕入消費税率区分
        productObj.product_tax_rate_class_purchase = Const.ProductTaxRateClassList[productObj.product_tax_rate_class_purchase].text
      },
      immediate: false
    },
    productInfos: {
      handler(productInfos) {
        for (const key in productInfos) {
          // 仕入単価表示処理
          productInfos[key].purchase_price = 'purchase_price' in productInfos[key] ? productInfos[key].purchase_price.toLocaleString() : ''
          // 置き場所
          productInfos[key].place = 'place_1' in productInfos[key] ? `${productInfos[key].place_1 || ''} - ${productInfos[key].place_2 || ''} - ${productInfos[key].place_3 || ''} - ${productInfos[key].place_4 || ''}` : ''
          // ケース換算区分リスト
          productInfos[key].case_conversion_class = 'case_conversion_class' in productInfos[key] ? Const.CaseConversionClass[productInfos[key].case_conversion_class].text : ''
          // ピッキング印刷区分リスト
          productInfos[key].picking_print_class = 'picking_print_class' in productInfos[key] ? Const.PickingPrintClass[productInfos[key].picking_print_class].text : ''
          // サービス区分リスト
          productInfos[key].service_whether_class = 'service_whether_class' in productInfos[key] ? Const.ServiceWhetherClass[productInfos[key].service_whether_class].text : ''
        }
      },
      immediate: false,
    },
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    // 営業所リストを取得
    this.officeList = await selectOneTable('m_offices');
    // 新規か編集画面から成功メッセージ
    this.successMessages = this.$route.params.successMessages || []
    await this.fetchData(this.$route.query.id);
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} ID - 製品ID
     */
    async fetchData(ID) {
      this.busy = true;
      // 製品詳細検索SQL
      ID = Number(ID)
      // 製品マスタのデータを請求
      let product = await this.searchProduct(ID)
      // 製品発注マスタのデータを請求
      // 製品詳細マスタのデータを請求
      var [productOrders, productDetails] = await Promise.all([
        this.searchProductOrders(product.product_id), 
        this.searchProductDetails(product.product_id)
      ])
      this.officeList.forEach(office => {
        // 営業所ごと製品詳細をセットする
        const officeId = office.office_id
        let matchProductOrder = productOrders.find((productOrder) => {
          return productOrder.office_id == officeId
        })
        let matchProductDetail = productDetails.find((productDetail) => {
          return productDetail.office_id == officeId
        })
        this.productInfos.push({...matchProductOrder, ...matchProductDetail, ...office})
      });
      for (const key in this.productInfos) {
        if ('client_id' in this.productInfos[key]) {
          // 製品最新単価履歴摘要を取得
          // 発注先名を取得
          var [productPricesHistories, client] = await Promise.all([
            this.searchProductsPricesHistories(product.product_id ,this.productInfos[key].office_id),
            this.searchClient(this.productInfos[key].client_id)
          ])
          this.productInfos[key].summary = productPricesHistories.summary
          if (client != undefined) {
            this.productInfos[key].client_name_kanji = client.client_name_kanji
          }
        } else {
          // 製品最新単価履歴摘要を取得
          const productPricesHistories = await this.searchProductsPricesHistories(product.product_id ,this.productInfos[key].office_id)
          this.productInfos[key].summary = productPricesHistories.summary
        }
      }
      // 9A製品枝番
      product.branchNumber = String(product.product_id).slice(4)

      this.productObj = {...product}
      this.busy = false;
    },

    /**
     * 製品発注マスタを検索します。
     * @param {int} product_id 
     * @returns {Object} 製品発注マスタデータ
     */
    async searchProductOrders(product_id) {
      const functionName = 'searchProductOrders';

      let result = null;
      let where_clause = `and product_id = ${product_id} and (`
      for (const key in this.officeList) {
        where_clause += ` office_id = ${this.officeList[key].office_id}`
        if (this.officeList.length !== (Number(key)+1) ){
          where_clause += ' or'
        }
      }
      where_clause += ')'

      try {
        result = await selectOneTable('m_products_orders', where_clause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products_orders',
          where_clause: where_clause
        }, error);
        return null;
      }
      return result;
    },
    /**
     * 製品詳細マスタを検索します。
     * @param {int} product_id
     * @returns {Object} 製品詳細マスタデータ
     */
    async searchProductDetails(product_id) {
      const functionName = 'searchProductDetails';

      let result = null;
      let where_clause = `and product_id = ${product_id} and (`
      for (const key in this.officeList) {
        where_clause += ` office_id = ${this.officeList[key].office_id}`
        if (this.officeList.length !== (Number(key)+1) ){
          where_clause += ' or'
        }
      }
      where_clause += ')'

      try {
        result = await selectOneTable('m_products_details', where_clause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products_details',
          where_clause: where_clause
        }, error);
        return null;
      }
      return result;
    },
    /**
     * 製品マスタを検索します。
     * @param {Int} Id - 製品ID
     * @returns {Object} 製品マスタデータ
     */
    async searchProduct(ID) {
      const functionName = 'searchProduct';

      let result = null;
      try {
        result = await selectOneTable('m_products', 'AND id = ' + ID);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'getM_products',
          where_clause: ID
        }, error);
        return null;
      }
      if (result == null || result.length == 0) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return {};
      }
      return result[0];
    },
    /**
     * 最新製品単価履歴を検索します。
     * @param {Int} product_id - 製品コード
     * @param {Int}} office_id - 営業所コード
     * @returns {Object} 最新製品単価履歴
     */
    async searchProductsPricesHistories(product_id, office_id) {
      const functionName = 'searchProductsPricesHistories';
      product_id = Number(product_id)
      office_id = Number(office_id)

      //  最新製品単価履歴
      const where_clause = `and product_id = ${product_id} and office_id = ${office_id} order by updated DESC limit 1`

      let result = null;
      try {
        result = await selectOneTable('t_products_prices_histories', where_clause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_t_products_prices_histories',
          where_clause: where_clause
        }, error);
        return null;
      }
      if (result != null && result.length > 0) {
        return result[0];
      }
      return {}
    },
    /**
     * 発注先を検索します。
     * @param {Int} client_id - 発注先コード
     * @returns {Object} 発注先マスタデータ
     */
    async searchClient(client_id) {
      const functionName = 'searchClient';

      const where_clause = `and client_id = ${client_id} and client_class = 2`

      let result = null;
      try {
        result = await selectOneTable('m_clients', where_clause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_t_products_prices_histories',
          where_clause,
        }, error);
        return null;
      }
      return result[0];
    },
    /**
     * 編集ボタン押下時
     * @param {Int} ID - 製品ID
     */
    clickEditBtn(id) {
      this.$router.push({ name: 'PRODUCTS9A-EDIT', query: { id }});
    },
  }
}
</script>
