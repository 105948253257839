<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
                  <strong> 発注修正</strong>
                  <b-form-text class="text-muted d-inline-flex">(編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください)</b-form-text>
                </h5>
                <b-button pill v-b-tooltip.hover.noninteractive.bottom="'発注入力照会に戻る'" @click="cancel" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </div>
            </b-media-body>
          </b-media>
          <div class="main-card card">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveData();" id="inputForm" class="form-horizontal">
                  <b-row>
                    <!-- 発注番号 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <b-form-group
                        label="発注番号"
                        label-for="orderId"
                      >
                        <b-form-input id="orderId" type="text" v-model="orderId" readonly />
                      </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <b-form-group
                        label="注文番号"
                        label-for="orderNumber"
                      >
                        <b-form-input id="orderNumber" type="text" v-model="orderNumber" readonly />
                      </b-form-group>
                    </div>
                    <!-- 営業所コードプルダウン -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <b-form-group
                        label="営業所"
                        label-for="selectSalesOffice"
                      >
                        <b-form-input id="selectSalesOffice" type="text" v-model="selectSalesOfficeText" readonly />
                      </b-form-group>
                    </div>
                  </b-row>
                  <b-row class="mt-0">
                    <!-- 発注日付 -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 form-group px-2">
                      <validation-provider name="dateOrderDate" :rules="{required: true, oldProcessMonthYearChange: {processMonthYear: controlMasterData.processMonthYear, dateInit: dateOrderDateInit}}" v-slot="{ classes,errors }">
                        <b-form-group
                          label="発注日付"
                          label-for="dateOrderDate"
                        >
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-input-group class="input-daterange">
                                <b-form-datepicker
                                  id="dateOrderDate"
                                  name="dateOrderDate"
                                  v-model="dateOrderDate"
                                  calendar-width="50%"
                                  :readonly="statusFlg.nyukaFlg==true||statusFlg.orderReceiveFlg==true"
                                ></b-form-datepicker>
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <!-- 担当者 -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 form-group px-2">
                      <validation-provider name="staff" rules="required" v-slot="{ classes,errors }">
                        <b-form-group
                          label="担当者"
                          label-for="staff"
                        >
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-input-group>
                                <b-form-input type="text" id="staff" name="staff" v-model="staff.text" readonly />
                                <b-input-group-text @click="showStaffSearchModal" v-if="statusFlg.nyukaFlg == false||statusFlg.orderReceiveFlg==true" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                  <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                    <span class="oi oi-magnifying-glass"></span>
                                    <button type="button" v-shortkey="['alt', '1']" @shortkey="showStaffSearchModal" class="d-none"></button>
                                  </b-button>
                                </b-input-group-text>
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <!-- 発注種別 -->
                    <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3 form-group px-2">
                      <b-form-group
                        label="発注種別"
                        label-for="selectOrderClass"
                      >
                        <b-input-group>
                          <b-form-input type="tel" id="selectOrderClass" name="selectOrderClass" v-model="selectOrderClassText" readonly></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </b-row>
                  <b-row class="mt-0">
                    <!-- 取引先コード -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group px-2">
                      <validation-provider name="suppliersCode" :rules="{numeric: true, min: 6, max: 6, required: true, client_sundries_class: suppliers.sundriesClass}" v-slot="{ classes,errors }">
                        <label for="suppliersCode">取引先コード</label>
                        <b-row :class="classes">
                          <b-col lg="12">
                            <b-input-group>
                              <b-form-input
                                type="tel"
                                id="suppliersCode"
                                name="suppliersCode"
                                :class="{'border-danger': errors[0]}"
                                @input="searchClient(suppliers.code)"
                                maxlength="6"
                                v-model="suppliers.code"
                                :readonly="statusFlg.nyukaFlg==true||statusFlg.orderReceiveFlg==true"
                              />
                              <b-input-group-text @click="showClientSearchModal()" v-if="statusFlg.nyukaFlg == false && statusFlg.orderReceiveFlg == false" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal()" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                              <b-button class="mr-1" size="sm" pill variant="success" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが空欄以外の場合、利用可能です。）" :disabled="suppliers.code == ''" @click="clientInfoModal(suppliers.class, suppliers.code)">
                                <span class="oi oi-eye"></span> 情報
                              </b-button>
                            </b-input-group>
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </div>
                    <!-- 取引先名 -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-2">
                      <b-form-group
                        label="取引先名"
                        label-for="suppliersName"
                      >
                        <b-form-input type="text" id="suppliersName" readonly v-model="suppliers.name"></b-form-input>
                      </b-form-group>
                    </div>
                    <!-- 郵便番号 -->
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 form-group px-2">
                      <b-form-group
                        label="郵便番号"
                        label-for="addressCode"
                      >
                        <b-form-input type="text" id="addressCode" readonly v-model="suppliers.zipCode"></b-form-input>
                      </b-form-group>
                    </div>
                  </b-row>
                  <b-row class="mt-0">
                    <!-- 取引先住所１ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-2">
                      <b-form-group
                        label="取引先住所１"
                        label-for="suppliersAddress1"
                      >
                        <b-form-input type="text" id="suppliersAddress1" readonly v-model="suppliers.address1"></b-form-input>
                      </b-form-group>
                    </div>
                    <!-- 取引先住所２ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-2">
                      <b-form-group
                        label="取引先住所２"
                        label-for="suppliersAddress2"
                      >
                        <b-form-input type="text" id="suppliersAddress2" readonly v-model="suppliers.address2"></b-form-input>
                      </b-form-group>
                    </div>
                  </b-row>
                  <b-row class="mt-0">
                    <!-- 取引先部署１ -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <b-form-group
                        label="部署１"
                        label-for="suppliersDepartment1"
                      >
                        <b-form-input type="text" id="suppliersDepartment1" readonly v-model="suppliers.department1"></b-form-input>
                      </b-form-group>
                    </div>
                    <!-- 取引先部署２ -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <b-form-group
                        label="部署２"
                        label-for="suppliersDepartment2"
                      >
                        <b-form-input type="text" id="suppliersDepartment2" readonly v-model="suppliers.department2"></b-form-input>
                      </b-form-group>
                    </div>
                    <!-- 取引先TEL -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <b-form-group
                        label="TEL"
                        label-for="suppliersTel"
                      >
                        <b-form-input type="text" id="suppliersTel" readonly v-model="suppliers.tel"></b-form-input>
                      </b-form-group>
                    </div>
                    <!-- FAX -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <b-form-group
                        label="FAX"
                        label-for="suppliersFax"
                      >
                        <b-form-input type="text" id="suppliersFax" readonly v-model="suppliers.fax"></b-form-input>
                      </b-form-group>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 製品 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :small="true"
                            :items="productList"
                            :fields="fields"
                          >
                            <template #table-caption>
                              <b-btn-toolbar>
                                <span class="mt-2 mr-2">&nbsp;製品テーブル</span> 
                                <!-- 行追加ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" type="button" @click="addTableRow()" v-if="statusFlg.hakkoFlg==false&&statusFlg.orderReceiveFlg==false">
                                  <span class="oi oi-plus"></span> 行追加
                                </b-button>&nbsp;
                                <!-- 在庫照会ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" title="在庫照会モーダルを表示します。" @click="openStockModal()">
                                  <span class="oi oi-eye"></span> 在庫照会
                                </b-button>
                              </b-btn-toolbar>
                            </template>
                            <template #cell(ProductCode)="data">
                              <validation-provider :rules="{numeric: true, min: 8, max: 8, required: true, case_conversion_class: {caseConversionClass: data.item.CaseConversion, checkCaseFlg: true}}" v-slot="{ classes,errors }">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-input-group style="width: 125px;">
                                      <b-form-input
                                        size="sm"
                                        class="px-1"
                                        type="text"
                                        name="productId"
                                        @input="searchProduct(data.item.ProductCode, data.index)"
                                        maxlength="8"
                                        v-model="data.item.ProductCode"
                                        :readonly="productListHidden.length > data.index"
                                      />
                                      <b-input-group-text @click="showProductSearchModal(data.index)" v-if="productListHidden.length <= data.index" >
                                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                          <span class="oi oi-magnifying-glass"></span>
                                        </b-button>
                                      </b-input-group-text>
                                    </b-input-group>
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </validation-provider>
                            </template>
                            <template #cell(ProductName)="data">
                              <validation-provider rules="max:35" v-slot="{ classes,errors }">
                                <b-input-group style="width: 200px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <span style="font-size: 20px;" v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark : '' }}</span>
                                        <b-form-input size="sm" class="px-1" type="text" name="productName" v-model="data.item.ProductName" :readonly="data.item.SundriesClass != htmlConst.SundriesClass.shokuchi || data.item.IsDeleted == 1 || data.item.StocksInputClass == 1 || statusFlg.orderReceiveFlg == true" v-b-tooltip.hover.noninteractive.right="data.item.ProductName" />
                                      </b-input-group>
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(Note)="data">
                              <validation-provider rules="max:30" v-slot="{ classes,errors }">
                                <b-input-group style="width: 160px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="text" name="productNote" v-model="data.item.Note" :readonly="data.item.IsDeleted == 1" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(ServiceClass)="data">
                              <b-input-group style="width: 110px;">
                                <b-form-select
                                  size="sm" 
                                  class="px-1"
                                  v-model="data.item.ServiceClass"
                                  :options="serviceList"
                                  value-field="id"
                                  :disabled="data.item.IsDeleted == 1 || data.item.StocksInputClass == 1 || statusFlg.orderReceiveFlg == true"
                                  @change="changeService(data.index)"
                                />
                              </b-input-group>
                            </template>
                            <template #cell(Quantity)="data">
                              <validation-provider :rules="{required: true, orders_between: {productSundriesClass: data.item.SundriesClass, stockQuantity: data.item.StockQuantity, isDeleted: data.item.IsDeleted}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 70px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="number" :id="'productQuantity' + data.index" name="productQuantity" v-model.number="data.item.Quantity" @blur="calc(data.index)" :readonly="data.item.IsDeleted == 1 || statusFlg.orderReceiveFlg == true" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(Unit)="data">
                              <validation-provider rules="max:5" v-slot="{ classes,errors }">
                                <b-input-group style="width: 40px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="text" name="productUnit" v-model="data.item.Unit" :readonly="data.item.IsDeleted == 1 || data.item.StocksInputClass == 1 || statusFlg.orderReceiveFlg == true" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(PurchasePrime)="data">
                              <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                                <b-input-group style="width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="number" name="productPurchasePrime" v-model.number="data.item.PurchasePrime" @blur="calc(data.index)" :readonly="data.item.IsDeleted == 1 || data.item.StocksInputClass == 1 || statusFlg.orderReceiveFlg == true" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(PurchasePrimeMaster)="data">
                              {{ data.item.PurchasePrimeMaster.toLocaleString() }}
                            </template>
                            <template #cell(TotalPurchasePrime)="data">
                              {{ data.item.TotalPurchasePrime.toLocaleString() }}
                              <validation-provider rules="between:-99999999,99999999" v-slot="{ classes,errors }">
                                <b-input-group style="width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <input type="hidden" name="TotalPurchasePrime" v-model.number="data.item.TotalPurchasePrime" />
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(StockScheduled)="data">
                              <validation-provider :rules="{oldProcessMonthYearChange: {processMonthYear: controlMasterData.processMonthYear, dateInit: data.item.StockScheduledInit}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 160px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group class="input-daterange">
                                        <b-form-input
                                          id="productStockScheduled"
                                          name="productStockScheduled"
                                          size="sm" 
                                          class="px-1"
                                          v-model="data.item.StockScheduled"
                                          readonly
                                        ></b-form-input>
                                        <b-input-group-append v-if="data.item.IsDeleted == 0">
                                          <b-button @click="openModal(data)" size="sm">
                                            <span class="oi oi-calendar"></span>
                                          </b-button>
                                        </b-input-group-append>
                                        <b-input-group-append v-if="data.item.IsDeleted == 0">
                                          <b-button size="sm" variant="outline-secondary" @click="data.item.StockScheduled=''">
                                            <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                          </b-button>
                                        </b-input-group-append>
                                      </b-input-group>
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(StockScheduledStaffText)="data">
                              <b-input-group style="width: 170px;">
                                <b-form-input size="sm" class="px-1" type="text" v-model="data.item.StockScheduledStaffText" readonly />
                                <b-input-group-text @click="showStockScheduledStaffSearchModal(data.index)" v-if="data.item.IsDeleted == 0">
                                  <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                    <span class="oi oi-magnifying-glass"></span>
                                  </b-button>
                                </b-input-group-text>
                              </b-input-group>
                            </template>
                            <template #cell(StockScheduledConfirmClass)="data">
                              <b-form-checkbox
                                v-model="data.item.StockScheduledConfirmClass"
                                value="1"
                                unchecked-value="0"
                                :disabled="data.item.IsDeleted == 1"
                              ></b-form-checkbox>
                            </template>
                            <template #cell(Delete)="data">
                              <b-button size="sm" v-b-tooltip.hover.noninteractive title="この行を削除をします。" @click="deleteBtn(data.index)" v-if="productListHidden.length <= data.index" class="mr-1">
                                <span class="oi oi-delete"></span> 削除
                              </b-button>
                              <b-form-checkbox
                                v-model="data.item.IsDeleted"
                                v-if="productListHidden.length > data.index"
                                :disabled="data.item.StocksInputClass==1||statusFlg.orderReceiveFlg==true"
                                value="1"
                                unchecked-value="0"
                                @change="data.item.Quantity=0; calc(data.index)"
                              ></b-form-checkbox>
                            </template>
                            <template #cell(StockQuantity)="data">
                              {{ data.item.StockQuantity.toLocaleString() }}
                            </template>
                            <template #cell(StockConfirmQuantity)="data">
                              {{ data.item.StockConfirmQuantity.toLocaleString() }}
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form>
                            <b-form-group :label="textTotalMoneyText" label-align="right"></b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <!-- 保存ボタン -->
                  <b-row class="justify-content-md-center">
                    <b-col lg="2">
                      <b-button pill variant="success" type="submit" form="inputForm" class="btn btn-block" :disabled="statusFlg.editFlg==false" @click="delListLastBlankRow"><span class="oi oi-circle-check"></span> 保存</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <b-modal id='datepickerModal' :val="postItem" size="sm" hide-footer hide-header centered no-close-on-esc>
      <div v-if="postItem">
        <b-calendar
          name="tblSettlementScheduled"
          v-model="postItem.item.StockScheduled"
          @input="$bvModal.hide('datepickerModal')"
          hide-header
        >
          <div class="d-flex" dir="ltr">
            <b-button
              size="sm"
              variant="outline-danger"
              v-if="postItem.item.StockScheduled"
              @click="postItem.item.StockScheduled='';"
            >
              {{htmlConst.Calendar.clearButtonName}}
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-auto"
              @click="postItem.item.StockScheduled=getToday();"
            >
              {{htmlConst.Calendar.todayButtonName}}
            </b-button>
          </div>
        </b-calendar>
      </div>
    </b-modal>
    <!-- ●●●担当者検索モーダル●●● -->
    <STAFFSEARCH @from-child="closeStaffSearchModal"/>
    <!-- ●●●取引先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="propClientClass"/>
    <!-- ●●●取引先照会モーダル●●● -->
    <CLIENTINQUIRY :clientProp="propClientInquiry"/>
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :product-search-prop="productSearchProp"/>
    <!-- 在庫照会モーダル -->
    <STOCKINQUIRY :stock-info="stockInfo" />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '@/store';
import { searchClientModal, searchProductsModal } from '@/assets/js/searchModal.js';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import STAFFSEARCH from '@/components/modal/staff-search.vue';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import STOCKINQUIRY from '@/components/modal/stock-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, getControlMaster, formatDate, formatCurDate, formatDateCalc, getListValue, executeSelectSql, escapeQuote, getNullStr, CreateInsertSql, CreateUpdateSql, CreateColRow, executeSqlList, isSystemEditable, isOtherSessionUpdated, isAfterMonthlyUpdate, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'order-edit';

export default {
  name: 'ORDER-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    PRODUCTSEARCH,
    CLIENTSEARCH,
    CLIENTINQUIRY,
    STAFFSEARCH,
    STOCKINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '発注修正',
      loginId: '',
      listUpdatedSelect: [],
      // アラート
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOffice: null,
      selectSalesOfficeText: '',
      salesOffice: [],
      // 発注日付
      dateOrderDate: '',
      dateOrderDateInit: '',
      // 注文番号
      orderNumber: null,
      // ログイン担当者
      loginStaff: {
        id: '',
        text: '',      
      },
      // 担当者
      staff: {
        id: '',
        text: '',      
      },
      // 発注種別
      selectOrderClass: null,
      selectOrderClassText: '',
      // 取引先系
      suppliers: {
        class: '',
        code: '',
        name: '',
        address1: '',
        address2: '',
        zipCode: '',
        department1: '',
        department2: '',
        tel: '',
        fax: '',
        sundriesClass: '',
        taxClass: '',
        taxCalcClass: '',
        taxFracClass: '',
        unionId: null,
      },
      // 取引先モーダル用の引数
      propClientClass: null,
      // テーブル定義
      productList:[],
      dataIndex: 0,
      totalPurchasePrime: 0,
      // サービス区分
      serviceList: [],
      // 製品検索情報
      productSearchProp: {
        office_id: '',
        office_name: '',
        client_id: '',
        client_class: '',
        client_name: '',
        multiSelectFlg: '1',
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 在庫照会情報
      stockInfo: {
        office_id: '',
        product_id: [],
      },
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        lightTaxMark: '',
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 諸口区分
        SundriesClass: {
          normal: Const.SundriesClass.normal,
          shokuchi: Const.SundriesClass.shokuchi,
        },
        Calendar: {
          // 今日を選択するボタン名
          todayButtonName: Const.Calendar.todayButtonName,
          // 日付クリアボタンのボタン名
          clearButtonName: Const.Calendar.clearButtonName,
        },
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // トランザクションSQLリスト
      transactSqlList:[],
      // 確認ダイアログ用
      confirmMessage: [],
      // モーダルに渡す値
      postItem: '',
      // ステータスフラグ
      statusFlg: {
        hakkoFlg: false,
        nyukaFlg: false,
        orderReceiveFlg: false,
        editFlg: true,
        allDeletedFlg: true,
      },
      // テーブル構成
      fields: [],
      // 裏製品情報（新規か修正かを確認）
      productListHidden: [],
      // 紐づいている受注番号
      orderReceiveId: 0,
      // パラメータ
      orderId: 0,
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 合計値のテキスト */
    textTotalMoneyText: function() {
      let retTotalMoneyText = '合計金額：' + this.totalPurchasePrime.toLocaleString() + ' 円';
      return retTotalMoneyText;
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.orderId = this.$route.query.orderId;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 更新時の排他制御用の変数作成
        await this.createUpdatedSelect();
        // 製品テーブル初期表示データ用意
        this.addTableRow();
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let serviceListData = null;
        let controlData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData, serviceListData, controlData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
          selectOneTable('m_service_classes'),
          getControlMaster(),
        ]);
        //console.log(staffListData);
        //console.log(officeListData);
        for (let i = 0; i < officeListData.length; i++) {
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.salesOffice.push(office);
        }
        // ログイン担当者
        this.loginStaff.id = staffListData[0].staff_id;
        this.loginStaff.text = staffListData[0].staff_id + '：' + staffListData[0].staff_name_kanji;
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        //console.log(serviceListData);
        for (let i = 0; i < serviceListData.length; i++) {
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // コントロールマスタ
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 初期データ設定
        await this.setData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* データ設定 */
    async setData() {
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null) {
        await this.setResult(dataResult);
        // 他の受注データと紐づいている場合、編集不可
        if (this.statusFlg.orderReceiveFlg == true) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2023'].replace('%arg1%', this.orderReceiveId));
        }
        if (this.statusFlg.allDeletedFlg == true) {
          this.statusFlg.editFlg = false;
          this.alertWarning.push(DISP_MESSAGES.WARNING['2032']);
        }
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索SQL作成 */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders.office_id';
      selectSql += ',orders.order_id';
      selectSql += ',orders.order_date';
      selectSql += ',orders.order_class';
      selectSql += ',orders.client_class';
      selectSql += ',orders.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.zip_code';
      selectSql += ',orders.client_tax_class AS sales_tax_class';
      selectSql += ',orders.tax_calculation_class';
      selectSql += ',orders.tax_fractionation_class';
      selectSql += ',clients.address_1';
      selectSql += ',clients.address_2';
      selectSql += ',clients.department_1';
      selectSql += ',clients.department_2';
      selectSql += ',clients.phone_number';
      selectSql += ',clients.fax_number';
      selectSql += ',clients.sundries_class';
      selectSql += ',orders.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',orders.union_id';
      selectSql += ',orders.product_id';
      selectSql += ',orders.product_class_id';
      selectSql += ',orders.product_name AS product_name_kanji';
      selectSql += ',orders.note';
      selectSql += ',orders.service_class';
      selectSql += ',orders.order_quantity';
      selectSql += ',orders.unit';
      selectSql += ',orders.order_unit_amount';
      selectSql += ',products_details.purchase_price';
      selectSql += ',orders.stock_scheduled';
      selectSql += ',orders.stock_scheduled_confirm_class';
      selectSql += ',orders.stock_scheduled_staff_id';
      selectSql += ',stock_scheduled_staffs.staff_name_kanji AS stock_scheduled_staff_name';
      selectSql += ',orders.product_manual_input_class AS product_sundries_class';
      selectSql += ',orders.inventory_control_class';
      selectSql += ',orders.case_conversion_class';
      selectSql += ',orders.is_deleted';
      selectSql += ',orders.order_number';
      selectSql += ',CASE WHEN orders.order_issue_class > 0 THEN 1 ELSE 0 END AS order_issue_class';
      selectSql += ',CASE WHEN stocks.order_id IS NULL THEN 0 ELSE 1 END AS stocks_input_class';
      selectSql += ',SUM(IfNull(stocks.stock_quantity,0)) AS stocks_input_quantity';
      selectSql += ',SUM(CASE WHEN stocks.stock_confirm_class = 1 THEN stocks.stock_quantity ELSE 0 END) AS stocks_confirm_quantity';
      selectSql += ',orders.stock_quantity';
      selectSql += ',orders.order_receive_id';
      selectSql += ',products.product_tax_rate_class_purchase';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON orders.client_class = clients.client_class ';
      selectSql += 'AND orders.client_id = clients.client_id ';
      selectSql += 'INNER JOIN m_products AS products ';
      selectSql += 'ON orders.product_id = products.product_id ';
      selectSql += 'INNER JOIN m_products_details AS products_details ';
      selectSql += 'ON orders.product_id = products_details.product_id ';
      selectSql += 'AND orders.office_id = products_details.office_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON orders.staff_id = staffs.staff_id ';
      selectSql += 'LEFT JOIN m_staffs AS stock_scheduled_staffs ';
      selectSql += 'ON orders.stock_scheduled_staff_id = stock_scheduled_staffs.staff_id ';
      selectSql += 'LEFT JOIN t_stocks AS stocks ';
      selectSql += 'ON orders.order_id = stocks.order_id ';
      selectSql += 'AND orders.order_row = stocks.order_row ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 引数の発注番号で絞り込む
      selectSql += ' orders.order_id = ' + this.orderId + ' ';
      /* GROUP BY句 */
      selectSql += 'GROUP BY orders.order_row ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY orders.order_row ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        // 営業所プルダウン
        for (let i = 0; i < this.salesOffice.length; i++) {
          if (result[0].office_id == this.salesOffice[i].id) {
            this.selectSalesOffice = result[0].office_id;
            this.selectSalesOfficeText = this.salesOffice[i].text;
          }
        }
        // 注文番号
        this.orderNumber = !result[0].order_number ? null : result[0].order_number;
        // 発注日
        this.dateOrderDate = result[0].order_date;
        this.dateOrderDateInit = result[0].order_date;
        // 担当者
        this.staff.id = result[0].staff_id;
        this.staff.text = result[0].staff_id + '：' + result[0].staff_name_kanji;
        // 発注種別
        this.selectOrderClass = result[0].order_class;
        this.selectOrderClassText = getListValue(Const.OrderClassList, this.selectOrderClass, 'id', 'text');
        this.fields = this.getFields();
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        this.suppliers.zipCode = result[0].zip_code;
        this.suppliers.address1 = result[0].address_1;
        this.suppliers.address2 = result[0].address_2;
        this.suppliers.department1 = result[0].department_1;
        this.suppliers.department2 = result[0].department_2;
        this.suppliers.tel = result[0].phone_number;
        this.suppliers.fax = result[0].fax_number;
        this.suppliers.sundriesClass = result[0].sundries_class;
        this.suppliers.taxClass = result[0].sales_tax_class;
        this.suppliers.taxCalcClass = result[0].tax_calculation_class;
        this.suppliers.taxFracClass = result[0].tax_fractionation_class;
        this.suppliers.unionId = result[0].union_id;
        // 受注番号
        this.orderReceiveId = result[0].order_receive_id;
        if (this.orderReceiveId != 0) {
          // 他の受注データと紐づいている場合
          this.statusFlg.orderReceiveFlg = true;
        }
        // 全件参照して製品一覧を作成
        for(let i = 0; i < result.length; i++){
          if(i == this.productList.length){
            this.addTableRow();
          }
          this.productList[i].ProductCode = result[i].product_id;
          this.productList[i].ProductClass = result[i].product_class_id;
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i].ProductTaxRateClass = result[i].product_tax_rate_class_purchase;
          this.productList[i].Note = result[i].note;
          this.productList[i].ServiceClass = result[i].service_class;
          this.productList[i].Quantity = result[i].order_quantity;
          this.productList[i].Unit = result[i].unit;
          this.productList[i].PurchasePrime = result[i].order_unit_amount;
          this.productList[i].PurchasePrimeMaster = result[i].purchase_price;
          this.productList[i].StockScheduled = result[i].stock_scheduled;
          this.productList[i].StockScheduledInit = result[i].stock_scheduled;
          this.productList[i].StockScheduledConfirmClass = result[i].stock_scheduled_confirm_class;
          this.productList[i].StockScheduledStaffId = result[i].stock_scheduled_staff_id;
          this.productList[i].StockScheduledStaffText = result[i].stock_scheduled_staff_id == null ? '' : result[i].stock_scheduled_staff_id + '：' + result[i].stock_scheduled_staff_name;
          this.productList[i].IsDeleted = result[i].is_deleted;
          if (this.productList[i].IsDeleted == 0) {
            this.statusFlg.allDeletedFlg = false;
          }
          this.productList[i].OrderNumber = result[i].order_number;
          if (this.selectOrderClass == Const.OrderClass.direct) {
            this.productList[i].StockQuantity = result[i].stock_quantity;
            this.productList[i].StockConfirmQuantity = result[i].stock_quantity;
          } else {
            this.productList[i].StockQuantity = result[i].stocks_input_quantity;
            this.productList[i].StockConfirmQuantity = result[i].stocks_confirm_quantity;
          }
          this.productList[i].StocksInputClass = result[i].stocks_input_class;
          this.productList[i].SundriesClass = result[i].product_sundries_class;
          this.productList[i].InventoryClass = result[i].inventory_control_class;
          this.productList[i].CaseConversion = result[i].case_conversion_class;
          this.productList[i].OrderIssueClass = result[i].order_issue_class;
          this.productList[i].TotalPurchasePrime = this.productList[i].Quantity * this.productList[i].PurchasePrime;
          // 発注書発行フラグ
          if (result[i].order_issue_class == 1) {
            this.statusFlg.hakkoFlg = true;
          }
          // 入荷フラグ
          if (result[i].stocks_input_class == 1) {
            this.statusFlg.nyukaFlg = true;
          }
          // 表示時の情報を裏製品情報でも保持
          this.addProductListHidden(
            this.productList[i].ProductCode,
            this.productList[i].Quantity,
          );
        }
        this.calcTotal();
        if (this.statusFlg.hakkoFlg == false && this.statusFlg.orderReceiveFlg == false) {
          // 最終行を空行とする
          this.addTableRow();
        }
      }
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      } else {
        if (this.checkAllDelete() == true) {
          await this.saveConfirm();
        } else {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2047']);
        }
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.WARNING['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 排他制御チェック
      if (await isOtherSessionUpdated(this.listUpdatedSelect) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2027']);
        return;
      }
      // 保存処理
      //console.log('保存処理開始');
      // 発注データの登録(SQLの作成)
      if (await this.execSave() == true) {
        // 発注入力照会画面へ遷移
        this.$router.push({ name: 'ORDER-INPUT-INQUIRY', query: { orderId: this.orderId, parentKbn: 1 } });
      }
      //console.log('保存処理終了');
    },
    /* 登録実行 */
    async execSave() {
      const functionName = 'execSave';
      try {
        this.transactSqlList = [];
        // 在庫マスタロック
        this.lockStocks();
        // 発注データロック
        this.lockOrder();
        // 発注データの更新
        await this.updateOrder();
        // 発注データの登録
        await this.insertOrder();
        // 在庫マスタの更新
        this.updateStocksMaster();

        //console.log(this.transactSqlList);

        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.alertDanger.push(msg);
            return false;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
          return false;
        }
        // 月次更新後チェック
        if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
          return false;
        }
        // 作成した登録用SQLを全実行
        await executeSqlList(this.transactSqlList);
        return true;
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        console.log(error);
        return false;
      }
    },
    /* 在庫マスタロックSQL作成 */
    lockStocks: function() {
      //console.log('在庫マスタロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' stocks.month_year';
      selectSql += ',stocks.office_id';
      selectSql += ',stocks.product_id';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_stocks AS stocks ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      selectSql += 'AND stocks.office_id = ' + this.selectSalesOffice + ' ';
      let csvProductId = '';
      for (let i = 0; i < this.productList.length; i++) {
        if (csvProductId != '') {
          csvProductId += ',';
        }
        csvProductId += this.productList[i].ProductCode;
      }
      selectSql += 'AND stocks.product_id IN (' + csvProductId + ') ';

      //console.log(selectSql)
      this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
    },
    /* 発注データロックSQL作成 */
    lockOrder: function() {
      //console.log('発注データロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders.order_id';
      selectSql += ',orders.order_row';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'orders.order_id = ' + this.orderId + ' ';
      //console.log(selectSql)
      this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
    },
    /* 発注データ更新SQL作成 */
    async updateOrder() {
      //console.log('発注データ更新SQL作成');
      let colList = [];
      // 発注日
      colList.push(CreateColRow('orders.order_date', this.dateOrderDate, 'DATE'));
      // 取引先区分
      colList.push(CreateColRow('orders.client_class', this.suppliers.class, 'NUMBER'));
      // 取引先コード
      colList.push(CreateColRow('orders.client_id', this.suppliers.code, 'NUMBER'));
      // 担当者コード
      colList.push(CreateColRow('orders.staff_id', this.staff.id, 'NUMBER'));
      // 組合コード
      colList.push(CreateColRow('union_id', this.suppliers.unionId, 'NUMBER'));
      // 仕入課税区分
      colList.push(CreateColRow('client_tax_class', this.suppliers.taxClass, 'NUMBER'));
      // 税額計算区分
      colList.push(CreateColRow('tax_calculation_class', this.suppliers.taxCalcClass, 'NUMBER'));
      // 税額端数計算処理区分
      colList.push(CreateColRow('tax_fractionation_class', this.suppliers.taxFracClass, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('orders.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders.updated_user', this.loginId, 'VARCHAR'));
      // 製品関係の更新部分
      let strWorkField = 'FIELD(orders.order_row';
      let strWorkProductName = '';
      let strWorkNote = '';
      let strWorkServiceClass = '';
      let strWorkOrderQuantity = '';
      let strWorkUnit = '';
      let strWorkOrderUnitAmount = '';
      let strWorkOrderAmount = '';
      let strWorkRemainingQuantity = '';
      let strWorkStockScheduled = '';
      let strWorkStockScheduledConfirmClass = '';
      let strWorkStockScheduledStaffId = '';
      let strWorkIsDeleted = '';
      for(let i = 0; i < this.productListHidden.length; i++) {
        strWorkField += ',' + (i + 1).toString();
        // 製品名
        strWorkProductName += ',\'' + await escapeQuote(this.productList[i].ProductName) + '\'';
        // 備考
        strWorkNote += ',\'' + await escapeQuote(this.productList[i].Note) + '\'';
        // サービス区分
        strWorkServiceClass += ',\'' + getNullStr(this.productList[i].ServiceClass) + '\'';
        // 発注数
        strWorkOrderQuantity += ',' + this.productList[i].Quantity;
        // 単位
        strWorkUnit += ',\'' + await escapeQuote(this.productList[i].Unit) + '\'';
        // 発注単価
        strWorkOrderUnitAmount += ',' + this.productList[i].PurchasePrime;
        // 発注金額
        strWorkOrderAmount += ',' + this.productList[i].TotalPurchasePrime;
        // 発注残数
        strWorkRemainingQuantity += ',orders.remaining_quantity + ' + (this.productList[i].Quantity - this.productListHidden[i].Quantity).toString();
        // 入荷予定日
        strWorkStockScheduled += ',\'' + (this.productList[i].StockScheduled == '' ? '9999-12-31' : this.productList[i].StockScheduled) + '\'';
        // 入荷予定日確定区分
        strWorkStockScheduledConfirmClass += ',' + this.productList[i].StockScheduledConfirmClass;
        // 入荷予定処理担当者コード
        strWorkStockScheduledStaffId += ',' + this.productList[i].StockScheduledStaffId;
        // 削除済フラグ
        strWorkIsDeleted += ',' + this.productList[i].IsDeleted;
      }
      strWorkField += ')';
      // 製品名
      colList.push(CreateColRow('orders.product_name', 'ELT(' + strWorkField + strWorkProductName + ')', 'NUMBER'));
      // 備考
      colList.push(CreateColRow('orders.note', 'ELT(' + strWorkField + strWorkNote + ')', 'NUMBER'));
      // サービス区分
      colList.push(CreateColRow('orders.service_class', 'ELT(' + strWorkField + strWorkServiceClass + ')', 'NUMBER'));
      // 発注数
      colList.push(CreateColRow('orders.order_quantity', 'ELT(' + strWorkField + strWorkOrderQuantity + ')', 'NUMBER'));
      // 単位
      colList.push(CreateColRow('orders.unit', 'ELT(' + strWorkField + strWorkUnit + ')', 'NUMBER'));
      // 発注単価
      colList.push(CreateColRow('orders.order_unit_amount', 'ELT(' + strWorkField + strWorkOrderUnitAmount + ')', 'NUMBER'));
      // 発注金額
      colList.push(CreateColRow('orders.order_amount', 'ELT(' + strWorkField + strWorkOrderAmount + ')', 'NUMBER'));
      // 発注残数
      colList.push(CreateColRow('orders.remaining_quantity', 'ELT(' + strWorkField + strWorkRemainingQuantity + ')', 'NUMBER'));
      // 入荷予定日
      colList.push(CreateColRow('orders.stock_scheduled', 'ELT(' + strWorkField + strWorkStockScheduled + ')', 'NUMBER'));
      // 入荷予定日確定区分
      colList.push(CreateColRow('orders.stock_scheduled_confirm_class', 'ELT(' + strWorkField + strWorkStockScheduledConfirmClass + ')', 'NUMBER'));
      // 入荷予定処理担当者コード
      colList.push(CreateColRow('orders.stock_scheduled_staff_id', 'ELT(' + strWorkField + strWorkStockScheduledStaffId + ')', 'NUMBER'));
      // 削除済フラグ
      colList.push(CreateColRow('orders.is_deleted', 'ELT(' + strWorkField + strWorkIsDeleted + ')', 'NUMBER'));
      let updateSql = CreateUpdateSql(colList, 't_orders AS orders');
      updateSql += ' WHERE ';
      updateSql += 'orders.order_id = ' + this.orderId + ' ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 発注データ登録処理 */
    async insertOrder() {
      //console.log('発注データ登録SQL作成');
      // CRUD処理
      let bulkInsertSql = '';
      for(let i = this.productListHidden.length; i < this.productList.length; i++) {
        let colList = [];
        // 発注番号
        colList.push(CreateColRow('order_id', this.orderId, 'NUMBER'));
        // 発注行番号
        colList.push(CreateColRow('order_row', i + 1, 'NUMBER'));
        // 発注日
        colList.push(CreateColRow('order_date', this.dateOrderDate, 'DATE'));
        // 発注種別
        colList.push(CreateColRow('order_class', this.selectOrderClass, 'NUMBER'));
        // 営業所コード
        colList.push(CreateColRow('office_id', this.selectSalesOffice, 'NUMBER'));
        // 取引先区分
        colList.push(CreateColRow('client_class', this.suppliers.class, 'NUMBER'));
        // 取引先コード
        colList.push(CreateColRow('client_id', this.suppliers.code, 'NUMBER'));
        // 担当者コード
        colList.push(CreateColRow('staff_id', this.staff.id, 'NUMBER'));
        // 製品コード
        colList.push(CreateColRow('product_id', this.productList[i].ProductCode, 'NUMBER'));
        // 製品名
        colList.push(CreateColRow('product_name', await escapeQuote(this.productList[i].ProductName), 'VARCHAR'));
        // 備考
        colList.push(CreateColRow('note', await escapeQuote(this.productList[i].Note), 'VARCHAR'));
        // サービス区分
        colList.push(CreateColRow('service_class', this.productList[i].ServiceClass, 'VARCHAR'));
        // 発注数
        colList.push(CreateColRow('order_quantity', this.productList[i].Quantity, 'NUMBER'));
        // 単位
        colList.push(CreateColRow('unit', await escapeQuote(this.productList[i].Unit), 'VARCHAR'));
        // 発注単価
        colList.push(CreateColRow('order_unit_amount', this.productList[i].PurchasePrime, 'NUMBER'));
        // 発注金額
        colList.push(CreateColRow('order_amount', this.productList[i].TotalPurchasePrime, 'NUMBER'));
        // 消費税
        colList.push(CreateColRow('tax', '0', 'NUMBER'));
        // 発注残数
        colList.push(CreateColRow('remaining_quantity', this.productList[i].Quantity, 'NUMBER'));
        // 入荷数
        colList.push(CreateColRow('stock_quantity', '0', 'NUMBER'));
        // 入荷予定日
        colList.push(CreateColRow('stock_scheduled', this.productList[i].StockScheduled == '' ? '9999-12-31' : this.productList[i].StockScheduled, 'DATE'));
        // 入荷予定日確定区分
        colList.push(CreateColRow('stock_scheduled_confirm_class', this.productList[i].StockScheduledConfirmClass, 'NUMBER'));
        // 入荷予定処理担当者コード
        colList.push(CreateColRow('stock_scheduled_staff_id', this.productList[i].StockScheduledStaffId, 'NUMBER'));
        // 入荷予定引当数
        colList.push(CreateColRow('stock_scheduled_reserve', '0', 'NUMBER'));
        // 組合コード
        colList.push(CreateColRow('union_id', this.suppliers.unionId, 'NUMBER'));
        // セット品区分
        colList.push(CreateColRow('set_class', '0', 'NUMBER'));
        // 現場コード
        colList.push(CreateColRow('site_id', '0', 'NUMBER'));
        // 製品分類コード
        colList.push(CreateColRow('product_class_id', this.productList[i].ProductClass, 'NUMBER'));
        // 製品手入力区分
        colList.push(CreateColRow('product_manual_input_class', this.productList[i].SundriesClass, 'NUMBER'));
        // 在庫管理区分
        colList.push(CreateColRow('inventory_control_class', this.productList[i].InventoryClass, 'NUMBER'));
        // 取引先製品単価区分
        colList.push(CreateColRow('client_amount_class', null, 'VARCHAR'));
        // ケース換算区分
        colList.push(CreateColRow('case_conversion_class', this.productList[i].CaseConversion, 'NUMBER'));
        // 仕入課税区分
        colList.push(CreateColRow('client_tax_class', this.suppliers.taxClass, 'NUMBER'));
        // 税額計算区分
        colList.push(CreateColRow('tax_calculation_class', this.suppliers.taxCalcClass, 'NUMBER'));
        // 税額端数計算処理区分
        colList.push(CreateColRow('tax_fractionation_class', this.suppliers.taxFracClass, 'NUMBER'));
        // 発注書発行区分
        colList.push(CreateColRow('order_issue_class', '0', 'NUMBER'));
        // 注文番号
        colList.push(CreateColRow('order_number', '0', 'NUMBER'));
        // 受注番号
        colList.push(CreateColRow('order_receive_id', '0', 'NUMBER'));
        // 受注行番号
        colList.push(CreateColRow('order_receive_row', '0', 'NUMBER'));
        // 削除済フラグ
        colList.push(CreateColRow('is_deleted', '0', 'NUMBER'));
        // 作成ユーザー
        colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));

        if (bulkInsertSql == '') {
          bulkInsertSql += 'INSERT INTO t_orders (' + CreateInsertSql(colList, 'col', 't_orders') + ') VALUES ';
        } else {
          bulkInsertSql += ',';
        }
        bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_orders') + ')';
      }
      if (bulkInsertSql != '') {
        //console.log(bulkInsertSql)
        this.transactSqlList.push({sql: bulkInsertSql, forUpdateFlg: 0});
      }
    },
    /* 在庫マスタ更新SQL作成 */
    updateStocksMaster: function() {
      //console.log('在庫マスタ更新SQL作成');
      let updateSql = '';
      let colList = [];
      let updateSubQuery = '';
      for(let i = 0; i < this.productListHidden.length; i++) {
        if (this.productList[i].OrderIssueClass == 1) {
          if (updateSubQuery != '') {
            updateSubQuery += ' UNION ALL ';
          }
          updateSubQuery += 'SELECT';
          updateSubQuery += ' orders.product_id';
          updateSubQuery += ',orders.order_quantity - ' + this.productListHidden[i].Quantity.toString() + ' AS change_cnt';
          updateSubQuery += ' FROM ';
          updateSubQuery += 't_orders AS orders ';
          updateSubQuery += ' WHERE ';
          updateSubQuery += 'orders.order_id = ' + this.orderId + ' ';
          updateSubQuery += 'AND orders.order_row = ' + (i+1).toString() + ' ';
          updateSubQuery += 'AND orders.order_issue_class > 0 ';
          updateSubQuery += 'AND orders.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
        }
      }
      if (updateSubQuery != '') {
        let updateQuery = '(';
        updateQuery += 'SELECT';
        updateQuery += ' ' + this.controlMasterData.processMonthYear + ' AS process_month_year';
        updateQuery += ',' + this.selectSalesOffice + ' AS office_id';
        updateQuery += ',SUB_QUERY.product_id';
        updateQuery += ',SUM(SUB_QUERY.change_cnt) AS change_cnt';
        updateQuery += ' FROM ';
        updateQuery += '(' + updateSubQuery + ') AS SUB_QUERY ';
        updateQuery += 'GROUP BY product_id ';
        updateQuery += ') AS PRODUCT_QUERY';
        // 入荷予定数
        colList.push(CreateColRow('stocks.quantity_stock', 'stocks.quantity_stock + PRODUCT_QUERY.change_cnt', 'NUMBER'));
        // 更新日
        colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
        updateSql += CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
        updateSql += ' WHERE ';
        updateSql += 'stocks.month_year = PRODUCT_QUERY.process_month_year ';
        updateSql += 'AND stocks.office_id = PRODUCT_QUERY.office_id ';
        updateSql += 'AND stocks.product_id = PRODUCT_QUERY.product_id ';
        //console.log(updateSql);
        this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
      }
    },
    /* テーブル構成取得 */
    getFields: function() {
      if (this.selectOrderClass != Const.OrderClass.direct) {
        // 直送以外
        return [
          {
            key: 'ProductCode',
            label: '製品コード',
          },
          {
            key: 'ProductName',
            label: '製品名',
          },
          {
            key: 'Note',
            label: '備考',
          },
          {
            key: 'ServiceClass',
            label: 'サービス区分',
          },
          {
            key: 'Quantity',
            label: '数量',
          },
          {
            key: 'Unit',
            label: '単位',
          },
          {
            key: 'PurchasePrime',
            label: '仕入単価',
          },
          {
            key: 'PurchasePrimeMaster',
            label: '仕入単価（マスタ）',
            tdClass: 'text-right',
          },
          {
            key: 'TotalPurchasePrime',
            label: '金額',
            tdClass: 'text-right',
          },
          {
            key: 'StockScheduled',
            label: '入荷予定日',
          },
          {
            key: 'StockScheduledStaffText',
            label: '入荷予定処理担当者',
          },
          {
            key: 'StockScheduledConfirmClass',
            label: '入荷予定日確定',
          },
          {
            key: 'StockQuantity',
            label: '入荷数',
            tdClass: 'text-right',
          },
          {
            key: 'StockConfirmQuantity',
            label: '入荷確定数',
            tdClass: 'text-right',
          },
          {
            key: 'Delete',
            label: '削除',
          },
        ];
      } else {
        // 直送
        return [
          {
            key: 'ProductCode',
            label: '製品コード',
          },
          {
            key: 'ProductName',
            label: '製品名',
          },
          {
            key: 'Note',
            label: '備考',
          },
          {
            key: 'ServiceClass',
            label: 'サービス区分',
          },
          {
            key: 'Quantity',
            label: '数量',
          },
          {
            key: 'Unit',
            label: '単位',
          },
          {
            key: 'PurchasePrime',
            label: '仕入単価',
          },
          {
            key: 'PurchasePrimeMaster',
            label: '仕入単価（マスタ）',
            tdClass: 'text-right',
          },
          {
            key: 'TotalPurchasePrime',
            label: '金額',
            tdClass: 'text-right',
          },
          {
            key: 'StockScheduled',
            label: '入荷予定日',
          },
          {
            key: 'StockScheduledStaffText',
            label: '入荷予定処理担当者',
          },
          {
            key: 'StockScheduledConfirmClass',
            label: '入荷予定日確定',
          },
          {
            key: 'StockQuantity',
            label: '入荷数',
            tdClass: 'text-right',
          },
          {
            key: 'Delete',
            label: '削除',
          },
        ];
      }
    },
    // キャンセルボタン
    cancel: function() {
      this.$router.push({ name: 'ORDER-INPUT-INQUIRY', query: { orderId: this.orderId, parentKbn: 0 }});
    },
    // 担当者検索
    showStaffSearchModal: function() {
      //console.log('担当者検索モーダル');
      this.dataIndex = -1;
      this.$bvModal.show('staffSearchModal');
    },
    // 入荷予定処理担当者検索
    showStockScheduledStaffSearchModal: function(index) {
      //console.log('入荷予定処理担当者検索モーダル');
      //console.log(index);
      this.dataIndex = index;
      this.$bvModal.show('staffSearchModal');
    },
    // 担当者検索モーダルを閉じた時
    closeStaffSearchModal: function(staffItems) {
      //console.log(staffItems);
      // モーダルから渡された値の有無チェック
      if(typeof staffItems != 'undefined'){
        if (this.dataIndex == -1) {
          this.staff.id = staffItems.detail.staff_id;
          this.staff.text = staffItems.detail.staff_id + '：' + staffItems.detail.staff_name_kanji;
        } else {
          this.productList[this.dataIndex].StockScheduledStaffId = staffItems.detail.staff_id;
          this.productList[this.dataIndex].StockScheduledStaffText = staffItems.detail.staff_id + '：' + staffItems.detail.staff_name_kanji;
        }
      }
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      const functionName = 'searchClient';
      this.propClientClass = Const.ClientClass.supplier;
      const result = await searchClientModal(client_id, this.propClientClass, functionName, MODULE_NAME);
      if (typeof result != 'undefined') {
        this.closeClientSearchModal(result);
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6) {
          this.closeClientSearchModal({detail:{}});
        }
      }
    },
    // 取引先検索
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.propClientClass = Const.ClientClass.supplier;
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal: function(clientItems) {
      //console.log(clientItems);
      // モーダルから渡された値の有無チェック
      if (typeof clientItems != 'undefined') {
        this.suppliers.class = clientItems.detail.client_class;
        this.suppliers.code = clientItems.detail.client_id;
        this.suppliers.name = clientItems.detail.client_name_kanji;
        this.suppliers.address1 = clientItems.detail.address_1;
        this.suppliers.address2 = clientItems.detail.address_2;
        this.suppliers.department1 = clientItems.detail.department_1;
        this.suppliers.department2 = clientItems.detail.department_2;
        this.suppliers.tel = clientItems.detail.phone_number;
        this.suppliers.fax = clientItems.detail.fax_number;
        this.suppliers.sundriesClass = clientItems.detail.sundries_class;
        this.suppliers.zipCode = clientItems.detail.zip_code;
        this.suppliers.taxClass = clientItems.detail.sales_tax_class;
        this.suppliers.taxCalcClass = clientItems.detail.tax_calculation_class;
        this.suppliers.taxFracClass = clientItems.detail.tax_fractionation_class;
        this.suppliers.unionId = clientItems.detail.union_id;
      }
    },
    /* 取引先照会 */
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    // 製品コード直接入力 - 製品検索
    async searchProduct(product_id, index) {
      const functionName = 'searchProduct';
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.selectSalesOffice);
      this.productSearchProp.client_id = this.suppliers.code;
      this.productSearchProp.client_class = this.suppliers.class;
      this.productSearchProp.client_name = this.suppliers.name;
      const result = await searchProductsModal(product_id, this.productSearchProp, functionName, MODULE_NAME);
      this.dataIndex = index;
      if (typeof result != 'undefined') {
        this.closeProductSearchModal(result);
      } else {
        // 製品コード8桁かつデータ取得失敗の場合
        if (product_id.length == 8) {
          // 製品コードクリア
          this.productList[index].ProductCode = '';
        }
      }
    },
    // 製品検索
    showProductSearchModal: function(index) {
      //console.log('製品検索モーダル');
      //console.log(index);
      this.dataIndex = index;
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.selectSalesOffice);
      this.productSearchProp.client_id = this.suppliers.code;
      this.productSearchProp.client_class = this.suppliers.class;
      this.productSearchProp.client_name = this.suppliers.name;
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    closeProductSearchModal: function(productItems) {
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if (typeof productItems != 'undefined') {
        let focusFlg = false;
        if (productItems.length == undefined) {
          // 選択ボタン押下でクローズ
          this.setProductSearchModal(this.dataIndex, productItems.detail);
          focusFlg = true;
        } else {
          // 一括選択ボタン押下でクローズ
          if (productItems.length == 1) {
            // 1件の場合のみフォーカスを数量に当てる
            focusFlg = true;
          }
          for (let i = 0; i < productItems.length; i++) {
            if (i == 0) {
              this.setProductSearchModal(this.dataIndex, productItems[i].detail);
            } else {
              this.productList.splice(this.dataIndex + i, 0, this.createNewRow());
              this.setProductSearchModal(this.dataIndex + i, productItems[i].detail);
            }
          }
        }
        // 合計仕入額を計算
        this.calcTotal();
        // 最終行が空行でない場合は空行追加
        if (this.isListBlankRow(this.productList.length - 1) == false) {
          if (this.statusFlg.hakkoFlg == false && this.statusFlg.orderReceiveFlg == false) {
            this.addTableRow();
          }
        }
        if (focusFlg == true) {
          // 製品検索を行った行の数量にフォーカスを移動
          setTimeout(() => {
            if (this.dataIndex < this.productList.length) {
              this.setFocus('productQuantity' + this.dataIndex);
            }
          }, 500);
        }
      }
    },
    // 製品検索モーダルの返却値設定
    async setProductSearchModal(index, productItemsDetail) {
      // 製品コード
      this.productList[index].ProductCode = productItemsDetail.product_id;
      // 製品分類コード
      this.productList[index].ProductClass = productItemsDetail.product_class_id;
      // 製品名（漢字）
      this.productList[index].ProductName = productItemsDetail.product_name_kanji;
      this.productList[index].ProductTaxRateClass = productItemsDetail.product_tax_rate_class_purchase;
      // 製品備考
      this.productList[index].Note = '';
      // サービス区分
      this.productList[index].ServiceClass = '';
      // 数量
      this.productList[index].Quantity = 1;
      // 単位
      this.productList[index].Unit = productItemsDetail.unit;
      // 仕入単価
      this.productList[index].PurchasePrime = productItemsDetail.purchase_price;
      this.productList[index].PurchasePrimeMaster = productItemsDetail.purchase_price;
      // 合計仕入単価
      this.productList[index].TotalPurchasePrime = this.productList[index].Quantity * productItemsDetail.purchase_price;
      // 入荷予定日
      this.productList[index].StockScheduled = formatDateCalc(formatCurDate(), 0, 0, productItemsDetail.lead_time, false, 'YYYY-MM-DD');
      this.productList[index].StockScheduledInit = this.productList[index].StockScheduled;
      // 入荷予定処理担当者
      this.productList[index].StockScheduledStaffId = this.loginStaff.id;
      this.productList[index].StockScheduledStaffText = this.loginStaff.text;
      // 諸口区分
      this.productList[index].SundriesClass = productItemsDetail.sundries_class;
      // 在庫管理区分
      this.productList[index].InventoryClass = productItemsDetail.inventory_control_class;
      // ケース換算区分
      this.productList[index].CaseConversion = productItemsDetail.case_conversion_class;
    },
    /* カレンダーボタン押下時 */
    async openModal(item) {
      this.postItem = item;
      this.$bvModal.show('datepickerModal');
    },
    // 当日取得
    getToday: function() {
      return formatCurDate('YYYY-MM-DD');
    },
    // 再計算イベント
    calc: function(index) {
      // 対象行の項目計算
      this.productList[index].TotalPurchasePrime = this.productList[index].Quantity * this.productList[index].PurchasePrime;
      // 合計金額
      this.calcTotal();
    },
    // 合計金額
    calcTotal: function() {
      this.totalPurchasePrime = 0;
      for(let i = 0; i < this.productList.length;i++){
        this.totalPurchasePrime += this.productList[i].TotalPurchasePrime;
      }
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = this.createNewRow();
      this.productList.push(newProduct);
    },
    // 行作成
    createNewRow: function() {
      let newProduct = {
        ProductCode: null,
        ProductClass: null,
        ProductName: '',
        ProductTaxRateClass: Const.ProductTaxRateClass.normalTax,
        Note: '',
        ServiceClass: '',
        Quantity: 0,
        Unit: '',
        PurchasePrime: 0,
        PurchasePrimeMaster: 0,
        TotalPurchasePrime: 0,
        StockScheduled: '',
        StockScheduledInit: '',
        StockScheduledStaffId: null,
        StockScheduledStaffText: '',
        StockScheduledConfirmClass: 0,
        IsDeleted: 0,
        OrderNumber: 0,
        StockQuantity: 0,
        StockConfirmQuantity: 0,
        StocksInputClass: 0,
        SundriesClass: null,
        InventoryClass: null,
        CaseConversion: '',
        OrderIssueClass: 0,
      };
      return newProduct;
    },
    /* 裏製品情報に追加 */
    addProductListHidden: function(productId, quantity) {
      let hdnProduct = {
        ProductId: productId,
        Quantity: quantity,
      };
      this.productListHidden.push(hdnProduct);
    },
    // テーブル行削除イベント
    deleteBtn: function(rowNum) {
      //console.log(rowNum);
      if (this.productList.length > 1) {
        this.productList.splice(rowNum,1);
        // 合計金額、合計仕入単価、粗利計算
        this.calcTotal();
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* 更新日取得用SELECT文作成 */
    async createUpdatedSelect() {
      let selectSql = '';
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(orders.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' orders.order_id = ' + this.orderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(stocks.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_stocks AS stocks ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' stocks.order_id = ' + this.orderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});

      let dataResult = null;
      for (let i = 0; i < this.listUpdatedSelect.length; i++) {
        dataResult = await executeSelectSql(this.listUpdatedSelect[i].select);
        if (dataResult != null && dataResult.length > 0) {
          this.listUpdatedSelect[i].initUpdated = formatDate(dataResult[0].updated, 'YYYY-MM-DD HH:mm:ss');
        } else {
          this.listUpdatedSelect[i].initUpdated = '0000-01-01 00:00:00';
        }
      }
    },
    // 一覧の指定行が空かどうかチェック
    isListBlankRow: function(index) {
      if (getNullStr(this.productList[index].ProductCode) == '') {
        return true;
      } else {
        return false;
      }
    },
    // 一覧の後ろの空行を削除
    delListLastBlankRow: function() {
      //console.log('空行削除開始');
      for (let i = this.productList.length - 1; i > 0; i--) {
        if (this.isListBlankRow(i) == true) {
          // 後ろにある空行は削除
          this.productList.splice(i, 1);
        } else {
          // 1件でも空行以外の行があった場合は削除処理終了
          break;
        }
      }
      // 最後に合計金額、合計仕入単価、粗利計算
      this.calcTotal();
      //console.log('空行削除終了');
    },
    // 全削除チェック
    checkAllDelete: function() {
      for (let i = 0; i < this.productList.length; i++) {
        if (this.productList[i].IsDeleted == 0) {
          // 1件でも削除されていない場合はOK
          return true;
        }
      }
      return false;
    },
    // 在庫照会モーダルを開く
    openStockModal: function() {
      //console.log('在庫照会モーダル');
      //console.log(this.$bvModal);
      this.stockInfo.office_id = this.selectSalesOffice.toString();
      this.stockInfo.product_id = [];
      for (let i = 0; i < this.productList.length; i++) {
        if (this.productList[i].ProductCode != null) {
          this.stockInfo.product_id.push(this.productList[i].ProductCode.toString());
        }
      }
      this.$bvModal.show('stockModal');
    },
    // サービス変更
    changeService: function(index) {
      if (this.productList[index].ServiceClass != '') {
        // サービス区分が空白以外
        this.productList[index].PurchasePrime = 0;
        this.productList[index].TotalPurchasePrime = 0;
      } else {
        this.productList[index].PurchasePrime = this.productList[index].PurchasePrimeMaster;
        this.productList[index].TotalPurchasePrime = this.productList[index].Quantity * this.productList[index].PurchasePrime;
      }
      // 合計金額、合計仕入単価、粗利
      this.calcTotal();
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
  }
}
</script>
<style scoped>
</style>