<template>
  <!-- 9A製品保守編集画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 9A製品保守編集</strong></h5>
                <router-link to="/products9A-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <div class="row mt-2">
                  <!-- 製品コード -->
                  <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                    <label for="product_class_id" class="form-label"><strong>製品分類コード</strong></label>
                    <input type="text" id="product_class_id" class="form-control" v-model="productObj.product_class_id" readonly>
                  </div>
                  <!-- 枝番 -->
                  <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                    <label for="branchNumber" class="form-label"><strong>枝番</strong></label>
                    <input type="text" id="branchNumber" class="form-control" v-model="productObj.branchNumber" readonly>
                  </div>
                  <!-- 製品名(漢字) -->
                  <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 form-group">
                    <validation-provider rules="max:35" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="product_name_kanji" class="form-label"><strong>製品名(漢字)</strong></label>
                        <input type="text" id="product_name_kanji" class="form-control" v-model="productObj.product_name_kanji" maxlength="35" v-b-tooltip.hover.noninteractive :title="productObj.product_name_kanji">
                        <small class="form-text text-muted">任意項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 製品名(カナ) -->
                  <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 form-group">
                    <validation-provider rules="max:40" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="product_name_kana" class="form-label"><strong>製品名(カナ)</strong></label>
                        <input type="text" id="product_name_kana" class="form-control" v-model="productObj.product_name_kana" maxlength="40" v-b-tooltip.hover.noninteractive :title="productObj.product_name_kana">
                        <small class="form-text text-muted">任意項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 単位 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="max:5" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="unit" class="form-label"><strong>単位</strong></label>
                        <input type="text" id="unit" class="form-control" v-model="productObj.unit" maxlength="5">
                        <small class="form-text text-muted">任意項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 売上単価 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required|numeric|max:8" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="sales_unit_price" class="form-label"><strong>売上単価</strong></label>
                        <input type="text" id="sales_unit_price" class="form-control" v-model="productObj.sales_unit_price" maxlength="8">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 製品チェック区分 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="product_check_class" class="form-label"><strong>製品チェック区分</strong></label>
                        <b-select id="product_check_class" class="form-control" v-model="productObj.product_check_class" :options="Const.ProductCheckClass9A">
                          <template #first>
                            <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                          </template>
                        </b-select>
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 在庫管理区分 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="inventory_control_class" class="form-label"><strong>在庫管理区分</strong></label>
                        <b-select id="inventory_control_class" class="form-control" v-model="productObj.inventory_control_class" :options="Const.InventoryControlClass">
                          <template #first>
                            <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                          </template>
                        </b-select>
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 売上消費税率区分 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <label for="product_tax_rate_class_sales" class="form-label">
                      <strong>売上消費税率区分</strong>
                    </label>
                    <b-select
                      id="product_tax_rate_class_sales"
                      class="form-control"
                      v-model="productObj.product_tax_rate_class_sales"
                      :options="productTaxRateClassList"
                      value-field="id"
                    >
                    </b-select>
                  </div>
                  <!-- 仕入消費税率区分 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <label for="product_tax_rate_class_purchase" class="form-label">
                      <strong>仕入消費税率区分</strong>
                    </label>
                    <b-select
                      id="product_tax_rate_class_purchase"
                      class="form-control"
                      v-model="productObj.product_tax_rate_class_purchase"
                      :options="productTaxRateClassList"
                      value-field="id"
                    >
                    </b-select>
                  </div>
                </div>
                <!-- 営業所テーブル -->
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 form-group">
                    <b-table
                      show-empty
                      :head-variant="headVariant"
                      :responsive="true"
                      :items="productInfos"
                      :fields="productInfosTableFields"
                      :busy="busy"
                      :bordered="true"
                      :empty-text="emptyText"
                    >
                      <!-- テーブル読み込み時表示html -->
                      <template #table-busy>
                        <div class="text-center text-info my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>読み込んでいます...</strong>
                        </div>
                      </template>
                      <!-- 置き場所 -->
                      <template #cell(place)="data">
                        <validation-provider rules="max:11|place" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <input type="text" :id="'place' + data.index" class="form-control" v-model.lazy="data.item.place" maxlength="11" @blur="toUpperPlace(data.item);">
                            <small class="form-text text-muted">ハイフン区切りで入力してください。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 仕入単価 -->
                      <template #cell(purchase_price)="data">
                        <validation-provider rules="required|numeric|max:8" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.purchase_price">
                            <input type="text" :id="'purchase_price' + data.index" class="form-control" v-model="data.item.purchase_price" maxlength="8">
                            <small class="form-text text-muted">必須項目です。最大8桁</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 仕入単価修正摘要 -->
                      <template #cell(summary)="data">
                        <validation-provider rules="max:50" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.summary">
                            <input type="text" :id="'summary' + data.index" class="form-control" v-model="data.item.summary" maxlength="50">
                            <small class="form-text text-muted">仕入単価を訂正した場合に日付を入力</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 仕入先製品コード -->
                      <template #cell(supplier_product_id)="data">
                        <validation-provider rules="max:25" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.supplier_product_id">
                            <input type="text" :id="'supplier_product_id' + data.index" class="form-control" v-model="data.item.supplier_product_id" maxlength="25">
                            <small class="form-text text-muted">仕入先製品コード</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- ピッキング表印刷区分 -->
                      <template #cell(picking_print_class)="data">
                        <validation-provider rules="required" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-select :id="'picking_print_class' + data.index" class="form-control" v-model="data.item.picking_print_class" :options="Const.PickingPrintClass">
                              <template #first>
                                <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                              </template>
                            </b-select>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 発注先コード -->
                      <template #cell(client_id)="data">
                        <validation-provider rules="numeric|min:6|max:6" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.client_id">
                            <div class="input-group" style="width: 210px;">
                              <input
                                type="text"
                                :id="'client_id' + data.index"
                                class="form-control"
                                :disabled="relationInventoryControlClass"
                                @input="searchClientInput(data.item.client_id, data.item.office_id)" 
                                maxlength="6"
                                v-model="data.item.client_id"
                              >
                              <!-- 発注先コード検索ボタン -->
                              <b-input-group-text v-show="!relationInventoryControlClass" @click="showClientSearchModal(data.item.office_id)">
                                <span class="oi oi-magnifying-glass"></span>
                              </b-input-group-text>
                              <b-button v-show="!relationInventoryControlClass" size="sm" pill variant="success" title="発注先をクリアします。" @click="clientClear(data.item.office_id)">
                                <span class="oi oi-circle-x"></span> クリア
                              </b-button>
                            </div>
                            <small class="form-text text-muted">必須項目。検索ボタンで入力。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- サービス区分 -->
                      <template #cell(service_whether_class)="data">
                        <validation-provider rules="required" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-select :id="'service_whether_class' + data.index" :disabled="relationInventoryControlClass" class="form-control" v-model="data.item.service_whether_class" :options="Const.ServiceWhetherClass">
                              <template #first>
                                <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                              </template>
                            </b-select>
                            <small class="form-text text-muted">必須項目。発注書の際にサービス分は分けて表記</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- サービス個数 -->
                      <template #cell(service_quantity)="data">
                        <!-- サービス区分が「1:なし」の場合 -->
                        <validation-provider v-if="data.item.service_whether_class == 1" rules="required|numeric|max:3" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.service_quantity">
                            <input readonly type="text" :id="'service_quantity' + data.index" class="form-control" v-model="data.item.service_quantity">
                            <small class="form-text text-muted">必須項目。無償でもらえる数量。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                        <!-- サービス区分が「0:あり」の場合 -->
                        <validation-provider v-else rules="required|numeric|min_value:1|max:3" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.service_quantity">
                            <input type="text" :id="'service_quantity' + data.index" class="form-control" v-model="data.item.service_quantity"  maxlength="3">
                            <small class="form-text text-muted">必須項目。無償でもらえる数量。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- ケース換算区分 -->
                      <template #cell(case_conversion_class)="data">
                        <validation-provider rules="required" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-select :id="'case_conversion_class' + data.index" :disabled="relationInventoryControlClass" class="form-control" v-model="data.item.case_conversion_class" :options="Const.CaseConversionClass">
                              <template #first>
                                <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                              </template>
                            </b-select>
                            <small class="form-text text-muted">必須項目。ケースで発注する場合</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 適正在庫数 -->
                      <template #cell(appropriate_stock)="data">
                        <validation-provider rules="required|numeric|max:4" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.appropriate_stock">
                            <input type="text" :id="'appropriate_stock' + data.index" :disabled="relationInventoryControlClass" class="form-control" v-model="data.item.appropriate_stock" maxlength="4">
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 最低発注数 -->
                      <template #cell(minimum_quantity)="data">
                        <!-- ケース換算区分が0:換算するで設定する場合 -->
                        <div v-if="data.item.case_conversion_class == 0">
                          <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                            <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.minimum_quantity">
                              <input type="text" :id="'minimum_quantity' + data.index" class="form-control" v-model="data.item.minimum_quantity" readonly>
                              <small class="form-text text-muted">必須項目です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <div v-else>
                          <validation-provider rules="required|numeric|between:1,9999" v-slot="{ classes, errors }">
                            <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.minimum_quantity">
                              <input type="text" :id="'minimum_quantity' + data.index" :disabled="relationInventoryControlClass" class="form-control" v-model="data.item.minimum_quantity" maxlength="4">
                              <small class="form-text text-muted">必須項目です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                      </template>
                      <!-- 発注ロット数 -->
                      <template #cell(lots_quantity)="data">
                        <validation-provider rules="required|numeric|max:4" v-slot="{ classes, errors }">
                          <div :class="classes"  v-b-tooltip.hover.noninteractive :title="data.item.lots_quantity">
                            <input type="text" :id="'lots_quantity' + data.index" :disabled="relationInventoryControlClass" class="form-control" v-model="data.item.lots_quantity" maxlength="4">
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 入庫リードタイム -->
                      <template #cell(lead_time)="data">
                        <validation-provider rules="required|numeric|between:0,99" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <input type="text" :id="'lead_time' + data.index" :disabled="relationInventoryControlClass" class="form-control" v-model="data.item.lead_time" maxlength="2">
                            <small class="form-text text-muted">必須項目。0:当日,1:翌日...</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <template #cell(operation)="operation">
                        <!-- コピーボタン -->
                        <b-button size="sm" @click="clickCopyBtn(operation.item.office_id)" class="mr-1">
                          <span class="oi oi-task"></span> コピー
                        </b-button>
                        <!-- 貼り付けボタン -->
                        <b-button size="sm" @click="clickPasteBtn(operation.item.office_id)" class="mr-1">
                          <span class="oi oi-pencil"></span> 貼り付け
                        </b-button>
                        <!-- スキップボタン -->
                        <b-button size="sm" v-b-tooltip.hover.noninteractive title="入れるデータ分からない場合はスキップしてください" @click="clickSkipOffice(operation.item.office_id)" class="mr-1">
                          <span class="oi oi-delete"></span> スキップ
                        </b-button>
                      </template>
                    </b-table>
                  </div>
                </div>  
              </validation-observer>
            </div>
             <!-- 保存ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-lg-2">
                  <button type="button" class="btn btn-primary btn-block" @click="save"><span class="oi oi-circle-check"></span> 保存</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●取引先マスタ検索モーダル●●● -->
    <ClientSearch @from-child="closeClientSearchModal" :client-class="2"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { addOperationLogs, init, getNullStr, CreateColRow, CreateUpdateSql, CreateInsertSql, escapeQuote, getUserCol, isSystemEditable, executeTransactSqlList, selectOneTable } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import ClientSearch from '@/components/modal/client-search.vue';
import { searchClientModal } from '@/assets/js/searchModal.js';
import moment from 'moment';

// ログ出力用モジュール名
const MODULE_NAME = 'product9A-edit';

export default {
  name: 'PRODUCT9A-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    ClientSearch,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '9A製品保守編集',
      // 営業所ごとテーブル
      productInfosTableFields: DataTblDef.productInfosTableFields_9A,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      busy: false,
      // ユーザ名
      username: this.$store.getters.user.username,
      // 製品ID
      ID: Number(this.$route.query.id),
      // 今日の日にち
      today: '',
      // トランザクションSQLリスト
      transactSqlList: [],
      // 営業所別仕入単価の記録
      productOfficePurchasePrice: {},
      // 製品対象
      productObj: {
        inventory_control_class: null,
      },
      // 消費税率区分
      productTaxRateClassList: Const.ProductTaxRateClassList,
      // 製品対象詳細
      productInfos:[],
      // 製品対象詳細のコピー対象
      productInfoCopy:{},
      // 区分リスト対象
      Const: {...Const},
      // 営業所リスト
      officeList: [],
      // 在庫管理区分が「しない」で設定する場合
      relationInventoryControlClass: false,
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 確認ダイアログ用
      confirmMessage: [],
    }
  },
  /** 監視 */
  watch: {
    productObj: {
      handler(productObj) {
        // 在庫管理区分がしないに設定される時の対応
        if (productObj.inventory_control_class == 1) {
          this.relationInventoryControlClass = true
          this.productInfos = this.productInfos.map((product) => {
            // 発注先コード
            product.client_id = ''
            product.client_name_kanji = ''
            // サービス区分
            product.service_whether_class = 1
            // ケース換算区分
            product.case_conversion_class = 1
            // 適正在庫数
            product.appropriate_stock = 0
            // 最低発注数
            product.minimum_quantity = 1
            // 発注ロット数
            product.lots_quantity = 0
            // 入庫リードタイム
            product.lead_time = 0
            return product
          })
        } else {
          this.relationInventoryControlClass = false
        }
      },
      deep: true,
      immediate: false,
    },
    productInfos: {
      handler(productInfos) {
        // サービス区分の監視
        for (const key in productInfos) {
          if (productInfos[key].service_whether_class == 1) {
            // 無償サービス個数を0に設定
            productInfos[key].service_quantity = 0
          }
        }
        // 置き場所の監視
        for (const key in productInfos) {
          if (productInfos[key].place != undefined) {
            if (productInfos[key].place === '') {
              productInfos[key].place_1 = ''
              productInfos[key].place_2 = ''
              productInfos[key].place_3 = ''
              productInfos[key].place_4 = ''
              break
            }
            const placeArr = productInfos[key].place.split('-')
            productInfos[key].place_1 = placeArr[0]
            productInfos[key].place_2 = placeArr[1]
            productInfos[key].place_3 = placeArr[2]
            productInfos[key].place_4 = placeArr[3]
          }
        }
        // ケース換算区分が0:換算するに設定される時の対応
        for (const key in productInfos) {
          if (productInfos[key].case_conversion_class == 0) {
            // 無償サービス個数を0に設定
            productInfos[key].minimum_quantity = 0
          }
        }
      },
      deep: true,
      immediate: false,
    },
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    // 営業所リストを取得
    this.officeList = await selectOneTable('m_offices');
    await this.fetchData();
    init(); // common.jsにて初期化処理
    this.today = moment(new Date()).format('YYYY/MM/DD');
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    // 発注先コード直接入力 - 取引先検索
    async searchClientInput(client_id, office_id) {
      const functionName = 'searchClientInput';
      if (client_id != '') {
        const result = await searchClientModal(client_id, Const.ClientClass.supplier, functionName, MODULE_NAME);
        for (const key in this.productInfos) {
          if (this.productInfos[key].office_id == office_id) {
            this.productInfos[key].clientResearch = true;
          } else {
            this.productInfos[key].clientResearch = false;
          }
        }
        if (typeof result != 'undefined') {
          this.closeClientSearchModal(result);
        } else {
          // 取引先コード6桁かつデータ取得失敗の場合
          if (client_id.length == 6) {
            // 取引先クリア
            this.closeClientSearchModal({});
          }
        }
      } else {
        for (const key in this.productInfos) {
          if (this.productInfos[key].office_id == office_id) {
            this.productInfos[key].client_id = '';
            this.productInfos[key].client_name_kanji = '';
            break;
          }
        }
      }
    },
    /**
     * 発注先検索モーダル表示
     */
    showClientSearchModal(office_id) {
      for (const key in this.productInfos) {
        if (this.productInfos[key].office_id == office_id) {
          this.productInfos[key].clientResearch = true
        } else {
          this.productInfos[key].clientResearch = false
        }
      }
      this.$bvModal.show('clientSearchModal');
    },
    /**
     * 発注先検索モーダルコールバック
     * @param {Object} choiceData - 選択されたデータ
     */
    closeClientSearchModal(choiceData) {
      for (const key in this.productInfos) {
        if (this.productInfos[key].clientResearch) {
          this.productInfos[key].client_id = choiceData.clientId;
          this.productInfos[key].client_name_kanji = choiceData.clientNameKanji;
          this.productInfos[key].clientResearch = false
        }
      }
    },
    // 発注先クリア
    clientClear: function(office_id) {
      for (const key in this.productInfos) {
        if (this.productInfos[key].office_id == office_id) {
          this.productInfos[key].client_id = '';
          this.productInfos[key].client_name_kanji = '';
          break;
        }
      }
    },
    /**
     * 画面に表示するデータを取得します。
     */
    async fetchData() {
      this.busy = true;
      // 製品マスタのデータを請求
      let product = await this.searchProduct(this.ID)
      // 製品発注マスタのデータを請求
      // 製品詳細マスタのデータを請求
      var [productOrders, productDetails] = await Promise.all([
        this.searchProductOrders(product.product_id), 
        this.searchProductDetails(product.product_id)
      ])

      let productInfos = []
      for (const key in this.officeList) {
        let office = this.officeList[key]
        const officeId = office.office_id
        let matchProductOrder = productOrders.find((productOrder) => {
          return productOrder.office_id == officeId
        })
        if (matchProductOrder === undefined) {
          matchProductOrder = {
            client_id: '',
            minimum_quantity: '',
            service_quantity: '',
            lots_quantity: '',
            lead_time: '',
            service_whether_class: '',
          }
        } else {
          if (matchProductOrder.client_id == 0) {
            matchProductOrder.client_id = '';
          }
        }
        let matchProductDetail = productDetails.find((productDetail) => {
          return productDetail.office_id == officeId
        })
        
        if (matchProductDetail === undefined) {
          matchProductDetail = {
            purchase_price: '',
            place: '',
            place_1: '',
            place_2: '',
            place_3: '',
            place_4: '',
            picking_print_class: '',
            appropriate_stock: '',
            case_conversion_class: '',
            supplier_product_id: '',
          }
        } else {
          matchProductDetail.place = `${matchProductDetail.place_1 || ''}-${matchProductDetail.place_2 || ''}-${matchProductDetail.place_3 || ''}-${matchProductDetail.place_4 || ''}`
        }
        productInfos.push({...matchProductOrder, ...matchProductDetail, ...office})
      }

      // 返すデータの空文字を処理する
      Object.keys(product).forEach(key => {
        product[key] = product[key] === ' ' ? '' : product[key]
      });
      for (const key in productInfos) {
        // 営業所別の仕入単価編集摘要
        productInfos[key].summary = ''
        // 営業所別仕入単価の記録を追加
        this.productOfficePurchasePrice = {...this.productOfficePurchasePrice, [productInfos[key].office_id]: productInfos[key].purchase_price}
        // 返すデータの空文字を処理する
        Object.keys(productInfos[key]).forEach(element => {
          productInfos[key][element] = productInfos[key][element] === ' ' ? '' : productInfos[key][element]
          productInfos[key].place = productInfos[key].place.replace(/ /g, '')
        });
        // 発注先名を取得
        if ('client_id' in productInfos[key] && productInfos[key].client_id != '') {
          const client = await this.searchClient(productInfos[key].client_id)
          if (client != undefined) {
            productInfos[key].client_name_kanji = client.client_name_kanji
          }
        }
      }

      // 9A製品枝番
      product.branchNumber = String(product.product_id).slice(4)

      // データセット 
      this.productObj = {...product}
      this.productInfos = [...productInfos] 
      this.busy = false;
    },
    
    /**
     * 製品発注マスタを検索します。
     * @param {Int} product_id
     * @returns {Object} 製品発注マスタデータ
     */
    async searchProductOrders(product_id) {
      const functionName = 'searchProductOrders';

      let result = null;
      let where_clause = `and product_id = ${product_id} and (`
      for (const key in this.officeList) {
        where_clause += ` office_id = ${this.officeList[key].office_id}`
        if (this.officeList.length !== (Number(key)+1) ){
          where_clause += ' or'
        }
      }
      where_clause += ')'

      try {
        result = await selectOneTable('m_products_orders', where_clause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products_orders',
          where_clause: where_clause
        }, error);
        return null;
      }
      return result;
    },
    /**
     * 製品詳細マスタを検索します。
     * @param {Int} product_id
     * @returns {Object} 製品マスタデータ
     */
    async searchProductDetails(product_id) {
      const functionName = 'searchProductDetails';

      let result = null;
      let where_clause = `and product_id = ${product_id} and (`
      for (const key in this.officeList) {
        where_clause += ` office_id = ${this.officeList[key].office_id}`
        if (this.officeList.length !== (Number(key)+1) ){
          where_clause += ' or'
        }
      }
      where_clause += ')'

      try {
        result = await selectOneTable('m_products_details', where_clause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products_details',
          where_clause: where_clause
        }, error);
        return null;
      }
      return result;
    },
    /**
     * 発注先を検索します。
     * @param {Int} client_id - 発注先コード
     * @returns {Object} 発注先マスタデータ
     */
    async searchClient(client_id) {
      const functionName = 'searchClient';

      const where_clause = `and client_id = ${client_id} and client_class = 2`

      let result = null;
      try {
        result = await selectOneTable('m_clients', where_clause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_t_products_prices_histories',
          where_clause,
        }, error);
        return null;
      }
      return result[0];
    },
    /**
     * 製品マスタを検索します。
     * @param {String} Id - 製品ID
     * @returns {Object} 製品マスタデータ
     */
    async searchProduct(ID) {
      const functionName = 'searchProduct';

      let result = null;
      try {
        result = await selectOneTable('m_products', 'AND id = ' + ID);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'getM_products',
          where_clause: ID
        }, error);
        return null;
      }
      if (result == null || result.length == 0) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return 
      }
      return result[0];
    },
    /**
     * 営業所をスキップ
     */
    async clickSkipOffice (officeId) {
      this.$bvModal.msgBoxConfirm('この営業所の製品登録をスキップします。\nよろしいですか？').then(async value => {
        if (value) {
          this.productInfos = this.productInfos.filter((productInfo) => {
            return productInfo.office_id != officeId
          })
        }
      });
    },
    /**
     * 営業所詳細をコピー
     */
    async clickCopyBtn (officeId) {
      const copyObj = this.productInfos.filter((productInfo) => {
        return productInfo.office_id == officeId
      })
      this.productInfoCopy = {...copyObj[0]}
      this.$bvToast.toast('コピー成功しました', {
        title: `営業所:${this.productInfoCopy.office_name_kanji}`,
        variant: 'success',
        autoHideDelay: 300,
        toaster: 'b-toaster-bottom-right',
        appendToast: true,
      })
    },
    /**
     * 営業所詳細を貼り付け
     */
    async clickPasteBtn (officeId) {
      for (const key in this.productInfos) {
        if (this.productInfos[key].office_id === officeId) {
          this.productInfoCopy.office_id = officeId
          // 各項目の貼り付け
          this.productInfos[key] = {
            ...this.productInfos[key],
            client_id: this.productInfoCopy.client_id,
            client_name_kanji: this.productInfoCopy.client_name_kanji,
            minimum_quantity: this.productInfoCopy.minimum_quantity,
            service_quantity: this.productInfoCopy.service_quantity,
            lots_quantity: this.productInfoCopy.lots_quantity,
            lead_time: this.productInfoCopy.lead_time,
            service_whether_class: this.productInfoCopy.service_whether_class,
            purchase_price: this.productInfoCopy.purchase_price,
            place: this.productInfoCopy.place,
            place_1: this.productInfoCopy.place_1,
            place_2: this.productInfoCopy.place_2,
            place_3: this.productInfoCopy.place_3,
            place_4: this.productInfoCopy.place_4,
            picking_print_class: this.productInfoCopy.picking_print_class,
            appropriate_stock: this.productInfoCopy.appropriate_stock,
            case_conversion_class: this.productInfoCopy.case_conversion_class,
            supplier_product_id: this.productInfoCopy.supplier_product_id,
            summary: this.productInfoCopy.summary,
          }
        }
      }
      this.productInfos = [...this.productInfos]
    },
    /**
     * 保存ボタンの押下
     */
    async save() {
      this.$store.commit('setLoading', true);
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        await this.saveConfirm();
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }

      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          //console.log('保存処理開始');
          this.$store.commit('setLoading', true);
          await this.execUpdate();
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'PRODUCTS9A-INQUIRY', 
              query: { id: this.productObj.id },
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1003']] }  
            });
          }
          this.$store.commit('setLoading', false);
          //console.log('保存処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 更新処理
     */
    async execUpdate() {
      const functionName = 'execUpdate'
      // 最初に初期化
      this.transactSqlList = [];
      // 製品マスタ更新
      await this.execUpdateProducts()

      // 製品マスタのみを更新する場合
      if (this.productInfos.length != 0) {
        // 製品発注マスタ更新と追加
        await this.execUpdateProductsOrders()

        // 製品詳細マスタ更新と追加
        await this.execUpdateProductsDetails()

        // 仕入単価変更した場合、変更履歴に追加します
        for (const key in this.productInfos) {
          if (this.productOfficePurchasePrice[this.productInfos[key].office_id] != this.productInfos[key].purchase_price) {
            await this.execInsertPurchasePrice(this.productInfos[key].office_id, this.productInfos[key].purchase_price, this.productInfos[key].summary)
          }
        }
      }

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
      } else {
        // 成功時にログを出力
        await addOperationLogs('Info', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: this.transactSqlList,
        });
      }
    },

    /**
     * 製品マスタ更新処理
     */
    async execUpdateProducts(){
      const colList = [];
      // 単位
      if (getNullStr(this.productObj.unit).trim() == '') {
        // 空白の場合は全角スペースを入れる
        colList.push(CreateColRow('unit', '　', 'VARCHAR'));
      } else {
        colList.push(CreateColRow('unit', await escapeQuote(this.productObj.unit), 'VARCHAR'));
      }
      // 製品名(漢字)
      if (getNullStr(this.productObj.product_name_kanji).trim() == '') {
        // 空白の場合は全角スペースを入れる
        colList.push(CreateColRow('product_name_kanji', '　', 'VARCHAR'));
      } else {
        colList.push(CreateColRow('product_name_kanji', await escapeQuote(this.productObj.product_name_kanji), 'VARCHAR'));
      }
      // 製品名(カナ)
      if (getNullStr(this.productObj.product_name_kana).trim() == '') {
        // 空白の場合は全角スペースを入れる
        colList.push(CreateColRow('product_name_kana', '　', 'VARCHAR'));
      } else {
        colList.push(CreateColRow('product_name_kana', await escapeQuote(this.productObj.product_name_kana), 'VARCHAR'));
      }
      // 定価 暗黙値0入れます
      colList.push(CreateColRow('regular_price', 0, 'INT'));
      // 売上単価
      colList.push(CreateColRow('sales_unit_price', Number(this.productObj.sales_unit_price), 'INT'));
      // 製品チェック区分
      colList.push(CreateColRow('product_check_class', Number(this.productObj.product_check_class), 'INT'));
      // 諸口区分 暗黙値0入れます
      colList.push(CreateColRow('sundries_class', 0, 'INT'));
      // 在庫管理区分
      colList.push(CreateColRow('inventory_control_class', Number(this.productObj.inventory_control_class), 'INT'));
      // 売上消費税率区分
      colList.push(CreateColRow('product_tax_rate_class_sales', Number(this.productObj.product_tax_rate_class_sales), 'INT'));
      // 仕入消費税率区分
      colList.push(CreateColRow('product_tax_rate_class_purchase', Number(this.productObj.product_tax_rate_class_purchase), 'INT'));

      // 更新ユーザー
      const colUser = await getUserCol(this.username, 'update')

      const sql = `${CreateUpdateSql(colList.concat(colUser), 'm_products')} WHERE id = ${this.ID}`;
      this.transactSqlList.push(sql)
    },
    /**
     * 製品発注マスタ更新と追加処理
     */
    async execUpdateProductsOrders(){
      let sqlInsertCol = ''
      let sqlInsertVal = []
      let sqlUpdateCol = []
      let sqlUpdateVal = []
      for (const key in this.productInfos) {
        const productInfo = this.productInfos[key]
        const colList = [];
        // 製品コード
        colList.push(CreateColRow('product_id', Number(this.productObj.product_id), 'INT'));
        // 営業所コード
        colList.push(CreateColRow('office_id', Number(productInfo.office_id), 'INT'));
        // 発注先コード
        colList.push(CreateColRow('client_id', getNullStr(productInfo.client_id) == '' ? 0 : Number(productInfo.client_id), 'INT'));
        // 最低発注数
        colList.push(CreateColRow('minimum_quantity', Number(productInfo.minimum_quantity), 'INT'));
        // 発注時ｻｰﾋﾞｽ数
        colList.push(CreateColRow('service_quantity', Number(productInfo.service_quantity), 'INT'));
        // 発注ロット数
        colList.push(CreateColRow('lots_quantity', Number(productInfo.lots_quantity), 'INT'));
        // 入庫ﾘｰﾄﾞﾀｲﾑ
        colList.push(CreateColRow('lead_time', Number(productInfo.lead_time), 'INT'));
        // サービス有無区分
        colList.push(CreateColRow('service_whether_class', Number(productInfo.service_whether_class), 'INT'));

        // 新規と更新ユーザー
        const colBothUser = await getUserCol(this.username, 'both')
        // 更新ユーザー
        const colUpdateUser = await getUserCol(this.username, 'update')

        // sql insert カラム部分
        sqlInsertCol = CreateInsertSql(colList.concat(colBothUser), 'col', 'm_products_orders').split(',');
        // sql insert values部分
        sqlInsertVal.push(CreateInsertSql(colList.concat(colBothUser), 'val', 'm_products_orders').split(','));
        // sql update カラム部分
        sqlUpdateCol = CreateInsertSql(colList.concat(colUpdateUser), 'col', 'm_products_orders').split(',');
      }
      for (const key in sqlUpdateCol) {
        sqlUpdateVal.push(`${sqlUpdateCol[key]} = values(${sqlUpdateCol[key]})`)
      }
      let sql = `INSERT INTO m_products_orders (${sqlInsertCol}) VALUES `
      let insertArr = []
      for (const key in sqlInsertVal) {
        insertArr.push(`(${sqlInsertVal[key].join()})`)
      }
      // ON DUPLICATE KEY UPDATEの結合
      sql = `${sql} ${insertArr.join()} ON DUPLICATE KEY UPDATE ${sqlUpdateVal.join()}`
      this.transactSqlList.push(sql)
    },
    /**
     * 製品詳細マスタ更新と追加処理
     */
    async execUpdateProductsDetails(){
      let sqlInsertCol = ''
      let sqlInsertVal = []
      let sqlUpdateCol = []
      let sqlUpdateVal = []
      for (const key in this.productInfos) {
        const productInfo = this.productInfos[key]
        const colList = [];
        // 製品コード
        colList.push(CreateColRow('product_id', Number(this.productObj.product_id), 'INT'));
        // 営業所コード
        colList.push(CreateColRow('office_id', Number(productInfo.office_id), 'INT'));
        // 仕入単価
        colList.push(CreateColRow('purchase_price', Number(productInfo.purchase_price), 'INT'));
        // 原価単価 暗黙値0入れます
        colList.push(CreateColRow('cost_price', 0, 'INT'));
        // 置き場所1
        colList.push(CreateColRow('place_1', await escapeQuote(productInfo.place_1), 'VARCHAR'));
        // 置き場所2
        colList.push(CreateColRow('place_2', await escapeQuote(productInfo.place_2), 'VARCHAR'));
        // 置き場所3
        colList.push(CreateColRow('place_3', await escapeQuote(productInfo.place_3), 'VARCHAR'));
        // 置き場所4
        colList.push(CreateColRow('place_4', await escapeQuote(productInfo.place_4), 'VARCHAR'));
        // ピッキング表印刷区分
        colList.push(CreateColRow('picking_print_class', Number(productInfo.picking_print_class), 'INT'));
        // 適正在庫数
        colList.push(CreateColRow('appropriate_stock', Number(productInfo.appropriate_stock), 'INT'));
        // ケース換算区分
        colList.push(CreateColRow('case_conversion_class', Number(productInfo.case_conversion_class), 'INT'));
        // 仕入先製品コード
        colList.push(CreateColRow('supplier_product_id', await escapeQuote(productInfo.supplier_product_id), 'VARCHAR'));

        // 新規と更新ユーザー
        const colBothUser = await getUserCol(this.username, 'both')
        // 更新ユーザー
        const colUpdateUser = await getUserCol(this.username, 'update')

        // sql insert カラム部分
        sqlInsertCol = CreateInsertSql(colList.concat(colBothUser), 'col', 'm_products_details').split(',');
        // sql insert values部分
        sqlInsertVal.push(CreateInsertSql(colList.concat(colBothUser), 'val', 'm_products_details').split(','));
        // sql update カラム部分
        sqlUpdateCol = CreateInsertSql(colList.concat(colUpdateUser), 'col', 'm_products_details').split(',');
     
      }

      for (const key in sqlUpdateCol) {
        sqlUpdateVal.push(`${sqlUpdateCol[key]} = values(${sqlUpdateCol[key]})`)
      }
      let sql = `INSERT INTO m_products_details (${sqlInsertCol}) VALUES `
      let insertArr = []
      for (const key in sqlInsertVal) {
        insertArr.push(`(${sqlInsertVal[key].join()})`)
      }
      // ON DUPLICATE KEY UPDATEの結合
      sql = `${sql} ${insertArr.join()} ON DUPLICATE KEY UPDATE ${sqlUpdateVal.join()}`
      this.transactSqlList.push(sql)
    },
    /**
     * 仕入単価履歴登録処理
     */
    async execInsertPurchasePrice(office_id, purchase_price, summary){
      const colList = [];
      // 製品コード
      colList.push(CreateColRow('product_id', Number(this.productObj.product_id), 'INT'));
      // 営業所コード
      colList.push(CreateColRow('office_id', office_id, 'INT'));
      // 製品単価修正日時
      colList.push(CreateColRow('price_update_datetime', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 仕入単価
      colList.push(CreateColRow('purchase_price', purchase_price, 'INT'));
      // 摘要
      colList.push(CreateColRow('summary', await escapeQuote(summary), 'VARCHAR'));
      // 作成ユーザー
      const username = await escapeQuote(this.$store.getters.user.username);
      colList.push(CreateColRow('created_user', username, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', username, 'VARCHAR'));

      // SQL実行
      const sql = CreateInsertSql(colList, 'full', 't_products_prices_histories');
      this.transactSqlList.push(sql)
    },
    // 置き場所の小文字を大文字に置換
    toUpperPlace: function(row) {
      if (getNullStr(row.place) != '') {
        row.place = row.place.toUpperCase();
      }
    },
  }
}
</script>
