<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <div class="container-fluid px-4 py-4 min-vh-85">
    <div class="row d-flex justify-content-center mt-2 mb-2">
      <div class="col-md-12">
        <div class="media">
          <div class="media-body pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> {{ title }}</strong></h5>
              <router-link to="/staff-list" class="btn btn-cancel m-0"><span class="text-white oi oi-circle-x"></span> キャンセル</router-link>
            </div>
          </div>
        </div>
        <div class="main-card mb-3 card">
          <div class="card-header">
            <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
              <ul v-for="(message,index) in successMessages" :key="index" style="list-style: none;">
                <li>{{message}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="warningMessages.length">
              <ul v-for="(message,index) in warningMessages" :key="index" style="list-style: none;">
                <li>{{message}}</li>
              </ul>
            </b-alert>
          </div>
          <div class="card-body">
            <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
            <validation-observer ref="observer">
              <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
                <div class="row mt-2">
                  <!-- 担当者コード -->
                  <div class="col col-6 form-group">
                    <div class=""><label for="staffId" class="form-control-label"><strong>担当者コード</strong></label></div>
                    <div class="">
                      <input type="text" id="staffId" class="form-control" v-model="staff.id" readonly>
                    </div>
                  </div>
                  <!-- 営業所コードプルダウン -->
                  <div class="col col-6 form-group">
                    <label for="select1" class=" form-control-label"><strong>営業所</strong></label>
                    <select id="select1" class="form-control" v-model="selectOffice">
                      <option v-for="offices in office" :value="offices" :key="offices.id">
                        {{ offices.id + '：' + offices.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 担当者名（漢字） -->
                  <div class="col col-6 form-group">
                    <validation-provider name="name_kanji" rules="required|max:20" v-slot="{ classes,errors }">
                      <div class=""><label for="name_kanji" class="form-control-label"><strong>担当者名（漢字）</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="name_kanji" class="form-control" v-model="staff.name_kanji">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 担当者名（カナ） -->
                  <div class="col col-6 form-group">
                    <validation-provider name="name_kana" rules="required|max:20" v-slot="{ classes,errors }">
                      <div class=""><label for="name_kana" class="form-control-label"><strong>担当者名（カナ）</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="name_kana" class="form-control" v-model="staff.name_kana">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 担当者名（印鑑） -->
                  <div class="col col-6 form-group">
                    <validation-provider name="name_stamp" rules="required|max:5" v-slot="{ classes,errors }">
                      <div class=""><label for="name_stamp" class="form-control-label"><strong>担当者名（印鑑）</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="name_stamp" class="form-control" v-model="staff.name_stamp">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- ログインID（編集） -->
                  <div class="col col-6 form-group" v-if="restoreFlg==false">
                    <validation-provider name="login_id" v-slot="{ classes,errors }">
                      <div class=""><label for="login_id" class="form-control-label"><strong>ログインID</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="login_id" class="form-control" v-model="staff.login_id" readonly>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- ログインID（復旧） -->
                  <div class="col col-6 form-group" v-if="restoreFlg==true">
                    <validation-provider name="login_id" rules="required|min:8|max:128|login_id" v-slot="{ classes,errors }">
                      <div class=""><label for="login_id" class="form-control-label"><strong>ログインID</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="login_id" class="form-control" v-model="staff.login_id">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- メールアドレス（編集） -->
                  <div class="col-6 form-group" v-if="restoreFlg==false">
                    <validation-provider name="mail_address" v-slot="{ classes,errors }">
                      <div class=""><label for="mail_address" class="form-control-label"><strong>メールアドレス</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="mail_address" class="form-control" v-model="staff.mail_address" readonly>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- メールアドレス（復旧） -->
                  <div class="col-6 form-group" v-if="restoreFlg==true">
                    <validation-provider name="mail_address" rules="required|max:255|email" v-slot="{ classes,errors }">
                      <div class=""><label for="mail_address" class="form-control-label"><strong>メールアドレス</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="mail_address" class="form-control" v-model="staff.mail_address">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
              </form>
            </validation-observer>
          </div>
          <!-- 保存ボタン -->
          <div class="card-footer">
            <div class="row justify-content-md-center pb-4">
              <div class="col-lg-2">
                <button type="button" class="btn btn-primary btn-block" :disabled="statusFlg.editFlg==false" @click="saveStaff"><span class="oi oi-circle-check"></span> 保存</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import CONFIRM from '@/components/modal/confirm.vue';
import { init, addOperationLogs, isSystemEditable, escapeQuote, CreateColRow, CreateUpdateSql, executeTransactSqlList, selectOneTable } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { createCognitoUser, deleteCognitoUser } from '@/assets/aws/cognito';
import { sendEmail } from '@/assets/aws/ses';
import { LOGIN_URL, REGISTER_COMPLETE_FROM_ADDRESS, REGISTER_COMPLETE_MAIL_SUBJECT } from '@/assets/js/const';

// モジュール名
const MODULE_NAME = 'staff-edit';

export default {
  name: 'STAFF-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  watch: {
    /**
     * 入力画面から編集画面へ遷移した時に入力画面で登録したデータを読み込みます。
     * @param {Object} to - 遷移先情報
     * @param {Object} from - 遷移元情報
     */
    async $route(to, from) {
      if (to.name === 'STAFF-EDIT' && from.name === 'STAFF-RESTORE') {
        window.location.reload();
      }
    }
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      // ヘッダタイトル
      baseTitle: '担当者保守',
      title: '',
      // 担当者
      staff:{
        id: +this.$route.query.staffId,
        office_id: '',
        name_kanji: '',
        name_kana: '',
        name_stamp: '',
        login_id: '',
        mail_address: ''
      },
      // 営業所プルダウン
      selectOffice:{
        id: null,
        name: null
      },
      office: [],
      // テーブル定義
      fields:DataTblDef.staff_input_fields,
      // メッセージ
      successMessages:[],
      warningMessages:[],
      // 確認ダイアログ用
      confirmMessage: [],
      // ステータスフラグ
      statusFlg: {
        editFlg: true,
      },
      // パラメータ
      parentKbn: 0,
      restoreFlg: false,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.parentKbn = this.$route.query.parentKbn;
    this.restoreFlg = this.$route.query.restoreFlg == '1' ? true : false;
    if (this.restoreFlg == true) {
      // 復旧画面
      this.title = this.baseTitle + '復旧';
    } else {
      // 編集画面
      this.title = this.baseTitle + '編集';
    }
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods:{
    async fetchData(){
      try {
        // 入力画面からの遷移の場合
        if (this.parentKbn == '1') {
          this.successMessages.push(DISP_MESSAGES.SUCCESS['1001']);
        }
        // 検索処理(初期表示は全件取得)
        //console.log('this.staffId:'+this.staff.id);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let resultData = null;
        let officeListData = null;
        let where_clause = `AND staff_id = ${this.staff.id} `;
        if (this.restoreFlg == true) {
          // 復旧画面
          where_clause += 'AND is_deleted = 1';
        } else {
          // 編集画面
          where_clause += 'AND is_deleted = 0';          
        }
        [resultData, officeListData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
        ]);
        //console.log('初期表示テーブルデータ');
        //console.log(resultData);
        if (resultData.length == 1) {
          this.staff.id = resultData[0].staff_id;
          this.staff.office_id = resultData[0].office_id;
          this.staff.name_kanji = resultData[0].staff_name_kanji;
          this.staff.name_kana = resultData[0].staff_name_kana;
          this.staff.name_stamp = resultData[0].staff_name_stamp;
          this.staff.login_id = resultData[0].login_id;
          this.staff.mail_address = resultData[0].mail_address;
        } else {
          this.warningMessages.push(DISP_MESSAGES.WARNING['2003']);
          this.statusFlg.editFlg = false;
          return;
        }
        // 営業所データ取得
        //console.log(officeListData);
        for(let i = 0; i < officeListData.length; i++){
          let temp_office = {
            id: officeListData[i].office_id,
            name: officeListData[i].office_name_kanji
          };
          this.office.push(temp_office);
        }
        // 営業所データ初期値セット
        for(let i = 0; i < this.office.length; i++){
          if(this.office[i].id == this.staff.office_id){
            this.selectOffice = this.office[i];
            break;
          }
        }
      } catch (error) {
        console.log(error);
        this.warningMessages.push(DISP_MESSAGES.WARNING['2003']);
        this.statusFlg.editFlg = false;
        return;
      }
    },
    /* 保存ボタンの押下 */
    async saveStaff(){
      // 保存処理
      //console.log('保存処理');
      this.successMessages = [];
      this.warningMessages = [];
      //console.log(this.warningMessages);

      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();
      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (success) {
        if (this.restoreFlg == true) {
          // 復旧画面
          // ログインIDをチェック
          await this.dataCheck();
        }
        if (this.warningMessages.length == 0) {
          await this.saveConfirm();
          scrollTo(0,0);
        } else {
          scrollTo(0,0);
          this.$store.commit('setLoading', false);
        }
      } else {
        scrollTo(0,0);
        this.$store.commit('setLoading', false);
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      if (this.restoreFlg == true) {
        // 復旧画面
        this.confirmMessage.push('入力された情報で復旧します。');
      } else {
        // 編集画面
        this.confirmMessage.push('入力された情報で保存します。');
      }
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          //console.log('保存処理開始');
          this.$store.commit('setLoading', true);
          if (this.restoreFlg == true) {
            // 復旧画面
            await this.execRestore();
          } else {
            // 編集画面
            await this.execUpdate();
          }
          this.$store.commit('setLoading', false);
          //console.log('保存処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.warningMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.warningMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 更新処理 */
    async execUpdate(){
      try {
        // 担当者すでに削除されたかどうかを確認
        const existStaff = await this.existStaffId()
        if (!existStaff) {
          this.warningMessages.push(DISP_MESSAGES.DANGER['3003']);
          return
        }
        // 担当者データの更新
        await this.updateStaff();
      } catch (error) {
        console.log(error);
        // 異常系操作ログの登録
        let param = {};
        addOperationLogs( 'Error', 'staff-edit', 'execUpdate', param, error );
      }
    },
    /* 担当者データ更新処理 */
    async updateStaff(){
      const functionName = 'updateStaff';

      let colList = [];
      // 担当者名（漢字）
      colList.push(CreateColRow('staff_name_kanji', await escapeQuote(this.staff.name_kanji), 'VARCHAR'));
      // 担当者名（カナ）
      colList.push(CreateColRow('staff_name_kana', await escapeQuote(this.staff.name_kana), 'VARCHAR'));
      // 担当者名（印鑑）
      colList.push(CreateColRow('staff_name_stamp', await escapeQuote(this.staff.name_stamp), 'VARCHAR'));
      // メールアドレス
      colList.push(CreateColRow('mail_address', await escapeQuote(this.staff.mail_address), 'VARCHAR'));
      // 営業所コード
      colList.push(CreateColRow('office_id', this.selectOffice.id, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.$store.getters.user.username, 'VARCHAR'));
      let updateSql = CreateUpdateSql(colList, 'm_staffs');
      updateSql += ' WHERE ';
      updateSql += 'staff_id = ' + this.staff.id + ' ';
      updateSql += 'AND is_deleted = 0 ';
      let sqlList = [updateSql];
      //console.info(sqlList[0]);
      // 引数のプロパティは「SQLs」
      let condition = { SQLs: sqlList };
      //console.info({condition});

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.warningMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.warningMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      try {
        // executeTransactSqlで実行
        let result = await API.graphql(graphqlOperation(executeTransactSql, condition));
        // レスポンスデータを取得できた際の処理
        if(result) {
          // result.data.executeTransactSql.bodyにJSON形式で結果が返ってくる
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if(result.data.executeTransactSql.statusCode > 200) {
            this.warningMessages.push(DISP_MESSAGES.WARNING['2001']);
            console.info({responseBody});
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({message});
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({errorMessage});
          } else {
            // SQLが正常に終了した際の処理
            this.successMessages.push(DISP_MESSAGES.SUCCESS['1003']);
            /*
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, {depth: null});
              }
            }
            */
          }
          //console.info(JSON.parse(result.data.executeTransactSql.body));
        } else {
          // レスポンスデータを取得できなかった際の処理
        }
      } catch (error) {
        this.warningMessages.push(DISP_MESSAGES.WARNING['2001']);
        console.error(error);
      }
    },
    // 担当者すでに削除されたかどうかを確認
    async existStaffId(){
      try {
        let where_clause = `AND staff_id = ${this.staff.id} AND is_deleted = 0`;
        let resultData = await selectOneTable('m_staffs', where_clause);
        return resultData.length != 0
      } catch (error) {
        console.log(error);
        return false
      }
    },
    // 入力データのチェック
    async dataCheck() {
      // ログインIDシングルクオーテーションチェック
      if (this.singleQuoteCheck(this.staff.login_id) == true) {
        this.warningMessages.push(DISP_MESSAGES.WARNING['2030'].replace('%arg1%','ログインID'));
        return;
      }
      // ログインIDダブルクオーテーションチェック
      if (this.doubleQuoteCheck(this.staff.login_id) == true) {
        this.warningMessages.push(DISP_MESSAGES.WARNING['2031'].replace('%arg1%','ログインID'));
        return;
      }
      // ログインID登録済みチェック
      if (await this.registeredLoginIdCheck() == true) {
        this.warningMessages.push('登録済みのログインIDです。');
      }
    },
    // シングルクオートチェック
    singleQuoteCheck: function(loginID) {
      return (loginID.match(/'/g)|| []).length > 0;
    },
    // ダブルクオートチェック
    doubleQuoteCheck: function(loginID) {
      return (loginID.match(/"/g)|| []).length > 0;
    },
    // ログインIDの登録済みチェック
    async registeredLoginIdCheck() {
      this.staffList = [];
      // CRUD処理
      let where_clause = '';
      where_clause = `AND login_id = '${this.staff.login_id}' `;
      where_clause += 'AND staff_id <> ' + this.staff.id + ' ';
      //console.log(where_clause);
      try {
        //console.log('条件取得');
        let resultData = await selectOneTable('m_staffs', where_clause);
        if(resultData.length === 0){
          //console.log(resultData);
          return false;
        }else{
          //console.log(resultData);
          return true;
        }
      } catch (error) {
        this.warningMessages.push(DISP_MESSAGES.DANGER['3005']);
        console.error(error);
        return false;
      }
    },
    // 復旧処理
    async execRestore() {
      const functionName = 'execRestore';
      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          //console.log(msg);
          this.warningMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.warningMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      try {
        let sqlList = [];
        // 担当者データの登録
        let updateSql = await this.getRestoreStaffSql();
        sqlList.push(updateSql);
        // 作成した登録用SQLを全実行
        if (await executeTransactSqlList(sqlList, MODULE_NAME, functionName) == false) {
          this.warningMessages.push(DISP_MESSAGES.DANGER['3001']);
          return
        }
        // サインアップ処理
        await this.signUp();
      } catch (error) {
        console.log(error);
        // 異常系操作ログの登録
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error );
      }
    },
    // 担当者データ復旧SQL取得
    async getRestoreStaffSql() {
      let colList = [];
      // 担当者名（漢字）
      colList.push(CreateColRow('staff_name_kanji', await escapeQuote(this.staff.name_kanji), 'VARCHAR'));
      // 担当者名（カナ）
      colList.push(CreateColRow('staff_name_kana', await escapeQuote(this.staff.name_kana), 'VARCHAR'));
      // 担当者名（印鑑）
      colList.push(CreateColRow('staff_name_stamp', await escapeQuote(this.staff.name_stamp), 'VARCHAR'));
      // ログインID
      colList.push(CreateColRow('login_id', this.staff.login_id, 'VARCHAR'));
      // メールアドレス
      colList.push(CreateColRow('mail_address', await escapeQuote(this.staff.mail_address), 'VARCHAR'));
      // 営業所コード
      colList.push(CreateColRow('office_id', this.selectOffice.id, 'NUMBER'));
      // 削除済フラグ
      colList.push(CreateColRow('is_deleted', 0, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.$store.getters.user.username, 'VARCHAR'));
      let updateSql = CreateUpdateSql(colList, 'm_staffs');
      updateSql += ' WHERE ';
      updateSql += 'staff_id = ' + this.staff.id + ' ';
      updateSql += 'AND is_deleted = 1 ';

      return updateSql;
    },
    // 新規担当者のコグニートユーザーを作成し、メールを送信する処理
    async signUp() {
      const functionName = 'signUp';
      try {
        // コグニートユーザー作成
        let userPass = this.getPassword(8);
        await createCognitoUser(this.staff.login_id, this.staff.mail_address, userPass);
        // メール送信
        const mailText = this.getMailText(userPass);
        await this.sendMail(mailText);

        // ユーザー作成ログの登録
        let param = {
          Username: this.staff.login_id,
          Email: this.staff.mail_address,
        };
        await addOperationLogs('Info', MODULE_NAME, functionName, param);
        // 担当者修正画面へ遷移
        this.$router.push({ 
          name: 'STAFF-EDIT', 
          query: { staffId: this.staff.id, parentKbn: 1 } });
      } catch (e) {
        this.successMessages = [];
        switch (e?.code) {
        case 'UsernameExistsException':
          this.warningMessages.push('登録済みのログインIDです。');
          break;
        default:
          // その他のエラー
          this.warningMessages.push(DISP_MESSAGES.DANGER['3005']);
          break;
        }
        console.log('error cognito signup ' + this.staff.login_id + ':' + e.message);
        // 異常系操作ログの登録
        addOperationLogs('Error', MODULE_NAME, functionName, {}, e);
        await this.deleteUser();
      }
    },
    // パスワード取得
    getPassword(length) {
      let letters = 'abcdefghijklmnopqrstuvwxyz';
      let numbers = '0123456789';
      let symbol_chars = '!#$%&()*+,.:;=?@[\\]^_{}-';
      let string = letters + letters.toUpperCase() + numbers + symbol_chars;

      // 先頭4文字に数・英小・英大・記号をつけて、残りはランダム文字列
      let pass = '';
      pass += letters.charAt(Math.floor(Math.random() * letters.length));
      pass += letters.toUpperCase().charAt(Math.floor(Math.random() * letters.toUpperCase().length));
      pass += numbers.charAt(Math.floor(Math.random() * numbers.length));
      pass += symbol_chars.charAt(Math.floor(Math.random() * symbol_chars.length));
      for (let i = 0; i < length-4; i++) {
        pass += string.charAt(Math.floor(Math.random() * string.length));
      }
      return pass;
    },
    getMailText(userPass) {
      let emailText = '';
      emailText += this.staff.name_kanji + 'さん\n';
      emailText += '\n';
      emailText += '販売管理システムのアカウント登録が完了しました。\n';
      emailText += 'ログイン情報は下記のとおりです。\n';
      emailText += 'ログインURL：' + LOGIN_URL + '\n';
      emailText += '初期パスワード: ' + userPass + '\n';
      emailText += '\n';
      emailText += '初回ログイン時にパスワードの変更が必要となります。\n';
      emailText += '数字、アルファベットの大文字・小文字、記号をそれぞれ1文字以上使用し、\n';
      emailText += '8文字以上のパスワードを設定して下さい。\n';
      emailText += '\n';
      emailText += '以上です。\n';
      emailText += 'どうぞよろしくお願いいたします。\n';
      emailText += '\n';
      emailText += '\n';
      emailText += '※本メールは販売管理システムから自動送信されています';
      return emailText;
    },
    // メール送信処理
    async sendMail(message) {
      const functionName = 'sendMail';
      // console.log('sendMail called.');
      // メール送信処理
      try {
        const mailInfo = {
          ToAddresses: [this.staff.mail_address],
          fromAddress: REGISTER_COMPLETE_FROM_ADDRESS,
          message: message,
          subject: REGISTER_COMPLETE_MAIL_SUBJECT,
        }
        await sendEmail(mailInfo);
      } catch (error) {
        this.warningMessages.push(DISP_MESSAGES.DANGER['3005']);
        console.log('error send mail ' + error.message);
        // 異常系操作ログの登録
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);

        try {
          // ユーザー削除実行
          await deleteCognitoUser(this.staff.login_id);
          // console.log('cognito削除戻り値');

          await this.deleteUser();
        } catch (error) {
          console.log('cognito error deleting user ' + this.staff.login_id + ':' + error.message);
          // 異常系操作ログの登録
          await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        }
      }
    },
    // 担当者データ削除（
    async deleteUser() {
      const functionName = 'deleteUser';
      let colList = [];
      // 削除済フラグ
      colList.push(CreateColRow('is_deleted', 1, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.$store.getters.user.username, 'VARCHAR'));
      let updateSql = CreateUpdateSql(colList, 'm_staffs');
      updateSql += ' WHERE ';
      updateSql += 'staff_id = ' + this.staff.id + ' ';
      updateSql += 'AND is_deleted = 0 ';

      let sqlList = [];
      sqlList.push(updateSql);

      // 作成した更新用SQLを全実行
      await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
    },
  }
}
</script>
<style scoped>
  table {
    display: table !important;
    width: 100% !important;
    border-collapse: collapse;
    table-layout: fixed;
    white-space: nowrap;
  }
  .datatable{
    table-layout:auto;
  }
</style>