<template>
  <b-modal
    id="staffSearchModal"
    size="xl"
    centered
    @show="searchButton('init')"
    title="担当者検索"
  >
    <b-container class="px-0">
      <b-row>
        <b-col>
          <!-- ●●●検索条件●●● -->
          <div class="main-card card px-3 pt-2">
            <div class="mb-0" id="heading1">
              <h5 class="mb-0">
                <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                  <span class="oi oi-magnifying-glass"></span> 検索条件</a>
              </h5>
            </div>
            <b-collapse id="collapse-1" visible>
              <b-card-body class="p-1">
                <b-form @submit.prevent="searchButton('click');" id="staffSearchForm" class="form-horizontal">
                  <validation-observer ref="observer">
                    <b-row>
                      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 form-group px-1">
                        <b-input-group>
                          <template #prepend>
                            <label for="salesOffice">
                              <b-input-group-text  class="px-1">
                                営業所
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-col class="pl-0">
                            <b-form-select
                              id="salesOffice"
                              v-model="searchConditions.salesOffice"
                              :options="salesOfficeList"
                              value-field="id"
                              :disabled="!!this.salesOfficeId"
                            />
                        </b-col>
                        </b-input-group>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 form-group px-1">
                        <validation-provider name="staffId" rules="numeric" v-slot="{ classes,errors }">
                          <b-input-group>
                            <template #prepend>
                              <label for="staffId">
                                <b-input-group-text  class="px-1">
                                  担当者コード
                                </b-input-group-text>
                              </label>
                            </template>
                            <b-form-input type="search" id="staffId" name="staffId" v-model.trim="searchConditions.staffId"></b-form-input>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-input-group>
                        </validation-provider>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 form-group px-1">
                        <b-input-group>
                          <template #prepend>
                            <label for="staffName">
                              <b-input-group-text  class="px-1">
                                担当者名
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-col class="pl-0">
                            <b-form-input type="search" id="staffName" v-model.trim="searchConditions.staffName"></b-form-input>
                          </b-col>
                        </b-input-group>
                      </div>
                    </b-row>
                  </validation-observer>
                  <!-- 検索ボタン -->
                  <b-row class="justify-content-md-center">
                    <b-col lg="3">
                      <b-button block pill variant="success" size="sm" type="submit" form="staffSearchForm"><span class="oi oi-magnifying-glass"></span> 検 索 </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-collapse>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col class="mt-0">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col lg="6" class="my-1">
              <b-form-group
                label="1ページの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="5" class="pl-0">
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            show-empty
            :small="true"
            :items="staffList"
            :fields="fields"
            :busy="busy"
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            :sticky-header= true
            @filtered="onFiltered"
          >
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>読み込んでいます...</strong>
              </div>
            </template>
            <template #cell(detail)="detail">
              <b-button size="sm" v-b-tooltip.hover.noninteractive.right="'対象のデータで入力します。'" @click="choice(detail.item)" class="mr-1">
                <span class="oi oi-check"></span> 選択
              </b-button>
            </template>
          </b-table>
          <!-- テーブルページネーション -->
          <b-col class="my-0">
            <b-pagination
              size="sm"
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <template #modal-footer>
      <b-button size="sm" @click="$bvModal.hide('staffSearchModal')" class="mr-1">キャンセル</b-button>
    </template>
  </b-modal>
</template>
<script>
import { init, addOperationLogs, convertSqlLikeSpecialChar, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import DataTblDef from '@/assets/js/dataTableDef.js';

const MODULE_NAME = 'staff-search';

export default {
  name: 'STAFF-SEARCH',
  props:['salesOfficeId'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '担当者検索',
      // 検索条件
      searchConditions:{
        staffName: '',
        staffId: '',
        salesOffice: '',
      },
      fields:[
        {
          key: 'detail',
          label: ''
        },
        {
          key: 'salesOffice',
          label: '営業所',
        },
        {
          key: 'staffId',
          label: '担当者コード',
        },
        {
          key: 'staffNameKanji',
          label: '担当者名（漢字）',
        },
        {
          key: 'staffNameKana',
          label: '担当者名（カナ）',
        },
      ],
      // 検索結果
      resultError: [],
      staffList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,

      // 営業所プルダウン用リスト
      salesOfficeList: [],
      // ログインユーザー
      loginStaffData: null,
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    this.fetchData();
  },
  /* 関数群 */
  methods:{
    choice:function(choiceData){
      this.$emit('from-child', choiceData);
      this.$bvModal.hide('staffSearchModal');
    },
    /* 検索ボタン押下時 */
    async searchButton(key){
      const functionName = 'searchButton';
      try {
        if (key == 'init') {
          // 初期表示時
          this.searchConditions.staffName = '';
          this.searchConditions.staffId = '';
          this.resultError = [];
          this.staffList = [];
          // ページング機能の初期化
          this.initPaging();
          this.busy = false;
          this.totalRows = 0;
          // 各画面から営業所の指定がない場合、ログイン中ユーザーの営業所をセット
          this.searchConditions.salesOffice = this.salesOfficeId ? this.salesOfficeId : this.loginStaffData.office_id;
        } else if(key == 'click') {
          // 検索ボタン押下時
          const observer = this.$refs.observer;
          const success = await observer.validate();
          if (!success) {
            const el = document.querySelector('#error:first-of-type');
            el.scrollIntoView({ block: 'center', inline: 'nearest' });
          } else {
            if (this.busy == true) {
              // 連続して押下された場合は処理を行わない
              return;
            }
            this.busy = true;
            await this.search();
            this.busy = false;
          }
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.busy = false;
    },
    /* 検索処理 */
    async search(){
      this.resultError = [];
      this.staffList = [];
      // ページング機能の初期化
      this.initPaging();
      // CRUD処理
      let where_clause = '';
      where_clause = await this.conditionMake();
      where_clause += ' ORDER BY staff_id';
      where_clause += ' LIMIT 1000';
      //console.log(where_clause);
      // 検索条件がある場合、条件取得
      //console.log('条件取得');
      let resultData = await selectOneTable('m_staffs', where_clause);
      if(resultData != null){
        await this.setResult(resultData);
        if(resultData.length >= 1000){
          this.resultError.push(DISP_MESSAGES.WARNING['2002']);
        }
      }else{
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
      }
    },
    /* 取得結果セット */
    async setResult(result){
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      let salesOffice = null;
      for(let i = 0; i < result.length; i++){
        // 検索結果の「営業所」に表示する営業所の情報をリストから参照
        salesOffice = this.salesOfficeList.find((input) => input.id == result[i].office_id);
        let searchResult = {
          salesOffice: (salesOffice ? salesOffice.text : ''), // 営業所が存在しない場合は空、存在する場合は「コード：営業所名」を表示
          staffId: result[i].staff_id,
          staffNameKanji: result[i].staff_name_kanji,
          staffNameKana: result[i].staff_name_kana,
          detail: result[i],
        };
        this.staffList.push(searchResult);
      }
    },
    /* 検索条件文字列作成 */
    async conditionMake(){
      let where_clause = '';
      const { staffName, staffId, salesOffice } = this.searchConditions
      // 担当者名（漢字／カナ）
      if(staffName != ''){
        where_clause += `AND (REPLACE(REPLACE(CONVERT(staff_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${convertSqlLikeSpecialChar(staffName)}%', 'ﾞ', ''), 'ﾟ', '') `;
        where_clause += `OR REPLACE(REPLACE(CONVERT(staff_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${convertSqlLikeSpecialChar(staffName)}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      // 担当者コード
      if(staffId != ''){
        where_clause += 'AND staff_id = ' + staffId + ' ';
      }
      // 営業所（入力無が空でなく、「0：全部」以外の場合のみ指定）
      if (salesOffice != '' && salesOffice !== 0) {
        where_clause += 'AND office_id = ' + salesOffice + ' ';
      }
      // 削除済みフラグ
      where_clause += 'AND is_deleted = 0 ';
      return where_clause;
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * 画面の初期化処理
     */
    async fetchData() {

      // storeからログイン中ユーザーの情報を取得
      //console.log(this.$store.getters.user)
      this.loginStaffInfo = this.$store.getters.user;

      /* データ取得 */
      // 担当者データ
      this.loginStaffData = await this.getStaffInfo();
      if (!this.loginStaffData) {
        return;
      }

      // 営業所データ
      let officeListData = await this.getOfficesData();
      if (!officeListData) {
        return;
      }

      /* プルダウンのリスト生成 */
      // 営業所
      this.salesOfficeList.push({
        id: 0,
        text: 0 + '：全て',
        name: '全て'
      });
      for (const officeItem of officeListData) {
        this.salesOfficeList.push({
          id: officeItem.office_id,
          text: officeItem.office_id + '：' + officeItem.office_name_kanji,
          name: officeItem.office_name_kanji
        });
      }
    },
    /**
     * 担当者リストの取得
     */
    async getStaffInfo() {
      const functionName = 'getStaffInfo';
      // storeからログイン中ユーザーの情報を取得
      let loginId = this.$store.getters.user.username;
      // 検索条件を設定
      let where_clause = `AND login_id = '${loginId}'`;
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await selectOneTable('m_staffs', where_clause);
        if (result != null && result.length > 0) {
          // データを取得できた場合、取得データを返却
          return result[0];
        } else {
          // データを取得できなかった場合
          await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_staffs'}, 'result is null');
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
          return null;
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_staffs'}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return null;
      }
    },
    /**
     * 営業所情報の取得
     */
    async getOfficesData() {
      const functionName = 'getOfficesData';
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await selectOneTable('m_offices');
        if (result != null && result.length > 0) {
          // データを取得できた場合、取得データを返却
          return result;
        } else {
          // データを取得できなかった場合
          await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_offices'}, 'result is null');
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_offices'}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
    },
  },
}
</script>
<style scoped>
</style>