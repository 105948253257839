<template>
  <!-- 取引先保守照会画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 取引先保守照会</strong></h5>
                <router-link to="/client-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <!-- 取引先区分 -->
                <div class="col-md-6 form-group">
                  <label for="clientClassName" class="form-label"><strong>取引先区分</strong></label>
                  <input type="text" id="clientClassName" class="form-control" v-model="clientClassName" readonly>
                </div>
                <!-- 取引先コード -->
                <div class="col-md-6 form-group">
                  <label for="clientId" class="form-label"><strong>取引先コード</strong></label>
                  <input type="text" id="clientId" class="form-control" v-model="clientId" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 取引先名（漢字） -->
                <div class="col-md-6 form-group">
                  <label for="clientNameKanji" class="form-label"><strong>取引先名（漢字）</strong></label>
                  <input type="text" id="clientNameKanji" class="form-control" v-model="clientNameKanji" readonly>
                </div>
                <!-- 取引先名（カナ） -->
                <div class="col-md-6 form-group">
                  <label for="clientNameKana" class="form-label"><strong>取引先名（カナ）</strong></label>
                  <input type="text" id="clientNameKana" class="form-control" v-model="clientNameKana" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 部署１ -->
                <div class="col-md-6 form-group">
                  <label for="department1" class="form-label"><strong>部署１</strong></label>
                  <input type="text" id="department1" class="form-control" v-model="department1" readonly>
                </div>
                <!-- 部署２ -->
                <div class="col-md-6 form-group">
                  <label for="department2" class="form-label"><strong>部署２</strong></label>
                  <input type="text" id="department2" class="form-control" v-model="department2" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 郵便番号 -->
                <div class="col-md-6 form-group">
                  <label for="zipCode" class="form-label"><strong>郵便番号</strong></label>
                  <input type="text" id="zipCode" class="form-control" v-model="zipCode" readonly>
                </div>
                <!-- 住所１ -->
                <div class="col-md-6 form-group">
                  <label for="address1" class="form-label"><strong>住所１</strong></label>
                  <input type="text" id="address1" class="form-control" v-model="address1" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- TEL -->
                <div class="col-md-6 form-group">
                  <label for="phoneNumber" class="form-label"><strong>TEL</strong></label>
                  <input type="text" id="phoneNumber" class="form-control" v-model="phoneNumber" readonly>
                </div>
                <!-- 住所２ -->
                <div class="col-md-6 form-group">
                  <label for="address2" class="form-label"><strong>住所２</strong></label>
                  <input type="text" id="address2" class="form-control" v-model="address2" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- FAX -->
                <div class="col-md-6 form-group">
                  <label for="faxNumber" class="form-label"><strong>FAX</strong></label>
                  <input type="text" id="faxNumber" class="form-control" v-model="faxNumber" readonly>
                </div>
                <!-- 担当者 -->
                <div class="col-md-6 form-group" v-if="clientClass === '1'">
                  <label for="staffName" class="form-label"><strong>担当者</strong></label>
                  <input type="text" id="staffName" class="form-control" v-model="staffName" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- チェック内容 -->
                <div class="col-md-6 form-group">
                  <label for="checkContents" class="form-label"><strong>チェック内容</strong></label>
                  <input type="text" id="checkContents" class="form-control" v-model="checkContents" readonly>
                </div>
                <!-- 最新現場コード -->
                <div class="col-md-6 form-group">
                  <label for="latestSiteName" class="form-label"><strong>最新現場コード</strong></label>
                  <input type="text" id="latestSiteName" class="form-control" v-model="latestSiteName" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 組合コード -->
                <div class="col-md-6 form-group" v-if="clientClass === clientClassSupplier">
                  <label for="unionId" class="form-label"><strong>組合コード</strong></label>
                  <input type="text" id="unionId" class="form-control" v-model="unionId" readonly>
                </div>
                <!-- 伝票種類 -->
                <div class="col-md-6 form-group">
                  <label for="billingTypeClass" class="form-label" :title="htmlConst.BillingTypeClassTitle"><strong>伝票種類 <span class="oi oi-flag"/></strong></label>
                  <input type="text" id="billingTypeClass" class="form-control" v-model="billingTypeClass" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 発注書回数 -->
                <div class="col-md-6 form-group" v-if="clientClass === clientClassSupplier">
                  <label for="orderCountClass" class="form-label"><strong>発注書回数</strong></label>
                  <input type="text" id="orderCountClass" class="form-control" v-model="orderCountClass" readonly>
                </div>
                <!-- 値引率 -->
                <div class="col-md-6 form-group">
                  <label for="discountRate" class="form-label"><strong>値引率</strong></label>
                  <div class="input-group">
                    <input type="text" id="discountRate" class="form-control" v-model="discountRate" readonly><span class="ml-3 d-flex align-items-center">%</span>
                  </div>
                </div>
              </div>
              <div class="row mt-2" v-if="clientClass === clientClassSupplier">
                <!-- サービス区分 -->
                <div class="col-md-6 form-group">
                  <label for="serviceOrderClass" class="form-label"><strong>サービス区分</strong></label>
                  <input type="text" id="serviceOrderClass" class="form-control" v-model="serviceOrderClass" readonly>
                </div>
              </div>
              <div class="row mt-2" v-if="clientClass === clientClassSupplier">
                <!-- 発注条件 -->
                <div class="col form-group">
                  <label for="order" class="form-label"><strong>発注条件</strong></label>
                  <b-table hover
                    table-class="datatable"
                    :head-variant="headVariant"
                    :responsive="true"
                    :items="orderCondition"
                    :fields="orderConditionTableFields"
                    :bordered="true"
                  >
                    <!-- 発注条件 数量 -->
                    <template #cell(orderConditionQuantity)="data">
                      <input type="text" id="orderConditionQuantity" class="form-control" v-model="data.item.orderConditionQuantity" readonly>
                    </template>
                    <!-- 発注条件 金額 -->
                    <template #cell(orderConditionAmount)="data">
                      <input type="text" id="orderConditionAmount" class="form-control" v-model="data.item.orderConditionAmount" readonly>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 締日 -->
                <div class="col-md-6 form-group">
                  <label for="closingDate" class="form-label"><strong>締日</strong></label>
                  <input type="text" id="closingDate" class="form-control" v-model="closingDate" readonly>
                </div>
                <!-- 請求書出力単位区分 -->
                <div class="col-md-6 form-group">
                  <label for="billingOutputClass" class="form-label"><strong>請求書出力単位区分</strong></label>
                  <input type="text" id="billingOutputClass" class="form-control" v-model="billingOutputClass" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 請求書区分 -->
                <div class="col-md-6 form-group">
                  <label for="billingClass" class="form-label"><strong>請求書区分</strong></label>
                  <input type="text" id="billingClass" class="form-control" v-model="billingClass" readonly>
                </div>
                <!-- 前回請求締切日 -->
                <div class="col-md-6 form-group">
                  <label for="previousBillingClosingDate" class="form-label"><strong>前回請求締切日</strong></label>
                  <b-form-datepicker id="previousBillingClosingDate" class="form-control" v-model="previousBillingClosingDate"
                    locale="ja"
                    :hide-header = true
                    calendar-width="50%"
                    :today-button="false"
                    :reset-button="false"
                    readonly
                  ></b-form-datepicker>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 取引区分 -->
                <div class="col-md-6 form-group">
                  <label for="businessClass" class="form-label"><strong>取引区分</strong></label>
                  <input type="text" id="businessClass" class="form-control" v-model="businessClass" readonly>
                </div>
                <!-- 相殺相手先 -->
                <div class="col-md-6 form-group">
                  <label for="offsetClientName" class="form-label"><strong>相殺相手先</strong></label>
                  <input type="text" id="offsetClientName" class="form-control" v-model="offsetClientName" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 入金（支払）区分 -->
                <div class="col-md-6 form-group">
                  <label for="paymentClass" class="form-label"><strong>入金（支払）区分</strong></label>
                  <input type="text" id="paymentClass" class="form-control" v-model="paymentClass" readonly>
                </div>
                <!-- 入金（支払）予定日 -->
                <div class="col-md-6 form-group">
                  <label for="paymentScheduled" class="form-label"><strong>入金（支払）予定日</strong></label>
                  <input type="text" id="paymentScheduled" class="form-control" v-model="paymentScheduled" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 売上課税区分 -->
                <div class="col-md-6 form-group">
                  <label for="salesTaxClass" class="form-label"><strong>売上課税区分</strong></label>
                  <input type="text" id="salesTaxClass" class="form-control" v-model="salesTaxClass" readonly>
                </div>
                <!-- 税額計算区分 -->
                <div class="col-md-6 form-group">
                  <label for="taxCalculationClass" class="form-label"><strong>税額計算区分</strong></label>
                  <input type="text" id="taxCalculationClass" class="form-control" v-model="taxCalculationClass" readonly>
                </div>
              </div>
              <div class="row mt-2" v-if="clientClass === clientClassSupplier">
                <!-- 振り込み手数料 -->
                <div class="col-md-6 form-group">
                  <label for="transferFee" class="form-label"><strong>振り込み手数料</strong></label>
                  <div class="input-group">
                    <input type="text" id="transferFee" class="form-control" v-model="transferFee" readonly><span class="ml-3 d-flex align-items-center">円</span>
                  </div>
                </div>
                <!-- 振り込み手数料２ -->
                <div class="col-md-6 form-group">
                  <label for="transferFee2" class="form-label"><strong>振り込み手数料２</strong></label>
                  <div class="input-group">
                    <input type="text" id="transferFee2" class="form-control" v-model="transferFee2" readonly><span class="ml-3 d-flex align-items-center">円</span>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 振り込み手数料 -->
                <div class="col-md-6 form-group" v-if="clientClass === clientClassSupplier">
                  <label for="transferFee3" class="form-label"><strong>振り込み手数料３</strong></label>
                  <div class="input-group">
                    <input type="text" id="transferFee3" class="form-control" v-model="transferFee3" readonly><span class="ml-3 d-flex align-items-center">円</span>
                  </div>
                </div>
                <!-- WEB発行区分 -->
                <div class="col-md-6 form-group" v-if="clientClass == clientClassClient">
                  <label for="webIssueClass" class="form-label"><strong>WEB発行区分</strong></label>
                  <input type="text" id="webIssueClass" class="form-control" v-model="webIssueClassText" readonly>
                </div>
              </div>
              <div v-if="clientClass === clientClassSupplier" class="row mt-2">
                <!-- 取引先サンワ顧客コード -->
                <div class="col-12 form-group">
                  <label for="suppliersOffices" class="form-label"><strong>取引先サンワ顧客コード</strong></label>
                  <b-row>
                    <!-- 1ページあたりの表示選択 -->
                    <b-col lg="6" class="my-1">
                      <b-form-group
                        label="1ページあたりの表示件数"
                        label-for="per-page-select"
                        label-cols-sm="4"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-1"
                      >
                        <b-form-select id="per-page-select" v-model="suppliersOfficesTablePerPage" :options="suppliersOfficesTablePageOptions" size="sm"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <!-- 検索結果検索 -->
                    <b-col lg="6" class="my-1">
                      <b-form-group
                        label="Filter"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-1"
                      >
                        <b-input-group size="sm">
                          <b-form-input id="filter-input" v-model="suppliersOfficesTableFilter" type="search"></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-table
                        id="suppliersOffices"
                        show-empty
                        :head-variant="headVariant"
                        :responsive="true"
                        :items="suppliersOffices"
                        :fields="suppliersOfficesTableFields"
                        :busy="suppliersOfficesTableBusy"
                        :filter="suppliersOfficesTableFilter"
                        :per-page="suppliersOfficesTablePerPage"
                        :current-page="suppliersOfficesTableCurrentPage"
                        :bordered="true"
                        :empty-text="emptyText"
                        :empty-filtered-text="emptyFilterdText"
                        @filtered="onSuppliersOfficesTableFiltered"
                      >
                        <template #cell(index)="data">
                          {{ data.index + 1 }}
                        </template>
                        <template #cell(supplierControlOfficeId)="data">
                          <b-input v-model="data.item.supplierControlOfficeId" size="sm" length="10" :readonly="true"></b-input>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6">
                      <b-form-group
                        :label="getSuppliersOfficesTablePagingMessage"
                        class="mt-0 mb-0"
                      />
                    </b-col>
                  </b-row>
                  <!-- テーブルページネーション -->
                  <b-row>
                    <b-col class="my-1">
                      <b-pagination
                        v-model="suppliersOfficesTableCurrentPage"
                        :total-rows="suppliersOfficesTableFilter != null ? suppliersOfficesTableFilterRows : suppliersOffices.length"
                        :per-page="suppliersOfficesTablePerPage == -1 ? suppliersOffices.length : suppliersOfficesTablePerPage"
                        align="center"
                        class="my-0"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { addOperationLogs, init, getListValue, selectOneTable } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'client-inquiry';

export default {
  name: 'CLIENT-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '取引先保守照会',
      clientClassClient: Const.ClientClassList[0].value,
      clientClassSupplier: Const.ClientClassList[1].value,
      headVariant: DataTblDef.headerVariant,
      orderConditionTableFields: DataTblDef.clients_order_condition_fields,
      suppliersOfficesTableFields: DataTblDef.clients_suppliers_offices_fields,
      suppliersOfficesTableBusy: false,
      suppliersOfficesTableFilter: null,
      // フィルタリングデータの総件数
      suppliersOfficesTableFilterRows: 0,
      // 表示件数のdefault値
      suppliersOfficesTablePerPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      suppliersOfficesTablePageOptions: DataTblDef.pageOptions,
      // ページネーションの初期表示位置
      suppliersOfficesTableCurrentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText: DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText: DataTblDef.emptyFilteredText,
      // ID
      id: '',
      // 取引先区分
      clientClass: '',
      // 取引先区分
      clientClassName: '',
      // 取引先コード
      clientId: '',
      // 取引先名（漢字）
      clientNameKanji: '',
      // 取引先名（カナ）
      clientNameKana: '',
      // 部署１
      department1: '',
      // 部署２
      department2: '',
      // 郵便番号
      zipCode: '',
      // 住所１
      address1: '',
      // 住所２
      address2: '',
      // TEL
      phoneNumber: '',
      // FAX
      faxNumber: '',
      // 担当者コード
      staffId: '',
      // 最新現場コード
      latestSiteId: '0',
      // 最新現場表示名
      latestSiteName: '',
      // チェック内容
      checkContents: '',
      // 諸口区分
      //sundriesClass: '',
      // 締日
      closingDate: '',
      // 請求書区分
      billingClass: '',
      // 前回請求締切日
      previousBillingClosingDate: '',
      // 取引区分
      businessClass: '',
      // 相殺相手先コード
      offsetClientId: '',
      // 相殺相手先名
      offsetClientName: '',
      // 請求書出力単位区分
      billingOutputClass: '',
      // 入金（支払）区分
      paymentClass: '',
      // 入金（支払）予定日
      paymentScheduled: '',
      // 売上課税区分
      salesTaxClass: '',
      // 税額計算区分
      taxCalculationClass: '',
      // 税額端数処理区分
      taxFractionationClass: Const.TaxFractionationClass.truncate,
      // 伝票種類区分
      billingTypeClass: '',
      // 組合コード
      unionId: '',
      // サービス発注区分
      serviceOrderClass: '',
      // 取引先サンワ顧客コード
      suppliersOffices: [],
      orderCondition: [
        {
          // 発注条件（数量）
          orderConditionQuantity: '',
          // 発注条件（金額）
          orderConditionAmount: '',
        }
      ],
      // 値引率
      discountRate: '',
      // 発注書回数区分
      orderCountClass: '',
      // 振り込み手数料
      transferFee: '',
      transferFee2: '',
      transferFee3: '',
      // WEB発行区分
      webIssueClassText: '',
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 伝票種類吹き出し
        BillingTypeClassTitle: Const.BillingTypeClassTitle,
      },
    }
  },
  computed: {
    /**
     * ページの表示件数
     */
    getSuppliersOfficesTablePagingMessage() {
      const tableLength = (this.suppliersOfficesTableFilter != null) ? this.suppliersOfficesTableFilterRows : this.suppliersOffices.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.suppliersOfficesTablePerPage !== -1) {
        end = this.suppliersOfficesTableCurrentPage * this.suppliersOfficesTablePerPage;
        start = end - this.suppliersOfficesTablePerPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    await this.fetchData(this.$route.query.clientClass, this.$route.query.clientId);
    this.$store.commit('setLoading', false);
  },
  methods:{
    async fetchData(clientClass, clientId) {
      this.$store.commit('setLoading', true);
      // 取引先マスタ検索
      const client = await this.searchMClients(clientClass, clientId);
      if (client === null) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return false;
      }

      // 担当者マスタ検索
      const staff = await this.searchMClientsSites(client.staff_id);

      // 現場マスタ検索（最新現場コード）
      const latest_site = await this.seachMClientsSites(client.client_id, client.latest_site_id);

      // 取引先マスタ検索（相殺相手先）
      const offsetClient = await this.searchMClients(client.client_class == 1 ? 2: 1, client.offset_client_id);

      // 営業所マスタ全データ取得
      let offices = null;
      // 仕入先営業所コードマスタ検索（取引先サンワ顧客コード）
      let supplierOffices = null;
      if (Number(clientClass) === Number(this.clientClassSupplier)) {
        offices = await this.listMOffices();
        supplierOffices = await this.searchMSuppliersOffices(client.client_id);
      }

      // ID
      this.id = client.id;
      // 取引先区分
      this.clientClass = String(clientClass);
      this.clientClassName = Const.ClientClassList.find(item => item.value === this.clientClass).text;
      // 取引先コード
      this.clientId = String(clientId);
      // 取引先名（漢字）
      this.clientNameKanji = client.client_name_kanji;
      // 取引先名（カナ）
      this.clientNameKana = client.client_name_kana;
      // 部署１
      this.department1 = client.department_1;
      // 部署２
      this.department2 = client.department_2;
      // 郵便番号
      this.zipCode = client.zip_code;
      // 住所１
      this.address1 = client.address_1;
      // 住所２
      this.address2 = client.address_2;
      // TEL
      this.phoneNumber = client.phone_number;
      // FAX
      this.faxNumber = client.fax_number;
      // 担当者コード
      this.staffId = String(client.staff_id);
      // 担当者名
      if (staff === null) {
        this.staffName = `${client.staff_id}：担当者マスタに該当なし`;
      } else {
        if (staff.staff_name_kanji.trim() === '') {
          this.staffName = `${client.staff_id}：${staff.staff_name_kana}`;
        } else {
          this.staffName = `${client.staff_id}：${staff.staff_name_kanji}`;
        }
      }
      // 最新現場コード
      this.latestSiteId = client.latest_site_id;
      // 最新現場名
      if (latest_site === null) {
        this.latestSiteName = `${client.latest_site_id}：現場マスタに該当なし`;
      } else {
        this.latestSiteName = `${client.latest_site_id}：${latest_site.client_site_name_kanji}`;
      }
      // チェック内容
      this.checkContents = client.check_contents;
      // 諸口区分
      //const sundriesClass = String(client.sundries_class);
      //this.sundriesClass = Const.SundriesClassList.find(item => item.value === sundriesClass).text;
      // 締日
      const closingDate = String(client.closing_date);
      this.closingDate = Const.closingDateList.find(item => item.value === closingDate).text;
      // 請求書区分
      const billingClass = String(client.billing_class);
      this.billingClass = Const.BillingClassList.find(item => item.value === billingClass).text;
      // 前回請求締切日
      this.previousBillingClosingDate = client.previous_billing_closing_date;
      // 取引区分
      const businessClass = String(client.business_class);
      this.businessClass = Const.BusinessClassList.find(item => item.value === businessClass).text;
      // 相殺相手先コード
      this.offsetClientId = String(client.offset_client_id);
      // 相殺相手先名
      if (offsetClient === null) {
        this.offsetClientName = `${client.offset_client_id}：取引先マスタに該当なし`;
      } else {
        this.offsetClientName = `${client.offset_client_id}：${offsetClient.client_name_kanji}`;
      }
      // 請求書出力単位区分
      const billingOutputClass = String(client.billing_output_class);
      this.billingOutputClass = Const.BillingOutputClassList.find(item => item.value === billingOutputClass).text;
      // 入金（支払）区分
      const paymentClass = String(client.payment_class);
      this.paymentClass = Const.PaymentClassList.find(item => item.value === paymentClass).text;
      // 入金（支払）予定日
      const paymentScheduled = String(client.payment_scheduled);
      this.paymentScheduled = Const.PaymentDateList.find(item => item.value === paymentScheduled).text;
      // 売上課税区分
      const salesTaxClass = String(client.sales_tax_class);
      this.salesTaxClass = Const.SalesTaxClassList.find(item => item.value === salesTaxClass).text;
      // 税額計算区分
      const taxCalculationClass = String(client.tax_calculation_class);
      this.taxCalculationClass = Const.TaxCalculationClassList.find(item => item.value === taxCalculationClass).text;
      // 税額端数処理区分
      const taxFractionationClass = String(client.tax_fractionation_class);
      this.taxFractionationClass = Const.TaxFractionationClassList.find(item => item.value === taxFractionationClass).text;
      // 伝票種類区分
      const billingTypeClass = String(client.billing_type_class);
      this.billingTypeClass = Const.BillingTypeClassList.find(item => item.value === billingTypeClass).text;
      // 組合コード
      this.unionId = String(client.union_id);
      // サービス発注区分
      const serviceOrderClass = String(client.service_order_class);
      this.serviceOrderClass = Const.ServiceOrderClassList.find(item => item.value === serviceOrderClass).text;
      this.orderCondition = [
        {
          // 発注条件（数量）
          orderConditionQuantity: String(client.order_condition_quantity),
          // 発注条件（金額）
          orderConditionAmount: String(client.order_condition_amount),
        }
      ],
      // 値引率
      this.discountRate = String(client.discount_rate);
      // 発注書回数区分
      for (let i = 0; i < Const.OrderCountClassList.length; i++) {
        const orderCountClass = Const.OrderCountClassList[i];
        if (orderCountClass.value == client.order_count_class) {
          this.orderCountClass = orderCountClass.text
          break;
        }
      }
      // 振り込み手数料
      this.transferFee = String(client.transfer_fee);
      this.transferFee2 = String(client.transfer_fee_2);
      this.transferFee3 = String(client.transfer_fee_3);
      // WEB発行区分
      this.webIssueClassText = getListValue(Const.WebIssueClassList, client.web_issue_class, 'id', 'text');
      // 取引先サンワ顧客コード
      if (Number(clientClass) === Number(this.clientClassSupplier)) {
        const suppliersOffices = [];
        for (const office of offices) {
          const supplierOffice = supplierOffices.find(item => item.office_id === office.office_id);
          suppliersOffices.push({
            officeId: office.office_id,
            officeNameKanji: office.office_name_kanji,
            supplierControlOfficeId: supplierOffice ? supplierOffice.supplier_control_office_id : ''
          });
        }
        this.suppliersOffices = suppliersOffices;
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 取引先マスタを検索します。
     * @param {String} clientClass - 取引先区分
     * @param {String} clientId - 取引先コード
     * @returns {Array<Object>} 取引先データ
     */
    async searchMClients(clientClass, clientId) {
      const functionName = 'searchMClients';

      const whereClause = `AND client_class = ${clientClass} AND client_id = ${clientId}`;
      let listMClientsResult = null;
      try {
        listMClientsResult = await selectOneTable('m_clients', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients',
          where_clause: whereClause
        }, error);
        return null;
      }

      if (listMClientsResult != null && listMClientsResult.length > 0) {
        return listMClientsResult[0];
      } else {
        return null;
      }
    },
    /**
     * 担当者マスタを検索します。
     * @param {String} staffId - 担当者コード
     * @returns {Object} 担当者データ
     */
    async searchMClientsSites(staffId) {
      const functionName = 'searchMClientsSites';

      const whereClause = `AND staff_id = ${staffId}`;
      let listMStaffsResult = null;
      try {
        listMStaffsResult = await selectOneTable('m_staffs', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_staffs',
          where_clause: whereClause
        }, error);
        return null;
      }

      if (listMStaffsResult != null && listMStaffsResult.length > 0) {
        return listMStaffsResult[0];
      } else {
        return null;
      }
    },
    /**
     * 現場マスタを検索します。
     * @param {String} clientId - 取引先コード
     * @param {String} siteId - 現場コード
     * @returns {Object} 現場データ
     */
    async seachMClientsSites(clientId, siteId) {
      const functionName = 'seachMClientsSites';

      const whereClause = `AND client_id = ${clientId} AND site_id = ${siteId}`;
      let result = null;
      try {
        result = await selectOneTable('m_clients_sites', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients_sites',
          where_clause: whereClause
        }, error);
        return null;
      }
      if (result != null && result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    },
    /**
     * 営業所マスタから全データを取得します。
     */
    async listMOffices() {
      const functionName = 'listMOffices';

      let result = null;
      try {
        result = await selectOneTable('m_offices');
      } catch (error) {
        console.error(error);
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_offices'
        }, error);
        return null;
      }
      return result;
    },
    /**
     * 仕入先営業所コードマスタを検索します。
     * @param {Number} clientId - 取引先コード
     */
    async searchMSuppliersOffices(clientId) {
      const functionName = 'searchMSuppliersOffices';

      const whereClause = `AND supplier_id = ${clientId}`;
      let result = null;
      try {
        result = await selectOneTable('m_suppliers_offices', whereClause);
      } catch (error) {
        console.error(error);
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_suppliers_offices',
          where_clause: whereClause
        }, error);
        return null;
      }

      if (result != null && result.length > 0) {
        return result;
      } else {
        return [];
      }
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onSuppliersOfficesTableFiltered(filteredItems) {
      this.suppliersOfficesTableFilterRows= filteredItems.length;
      this.suppliersOfficesTableCurrentPage= DataTblDef.currentPage;
    }
  }
}
</script>
