<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container class="px-4 py-4 min-vh-85" fluid>
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 処理範囲指定</strong></h5>
            </div>
          </b-media-body>
        </b-media>
        <b-card>
          <b-card-header v-if="getMessageFlg">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <validation-observer ref="observer">
              <b-form @submit.prevent="clearAlert(); saveData();" id="InputForm" class="form-horizontal">
                <!-- ●●●入力欄●●● -->
                <b-container>
                  <b-row>
                    <!-- 営業所コードプルダウン -->
                    <b-col lg="6">
                      <b-form-group
                        label="営業所"
                        label-for="selectSalesOffice"
                      >
                        <b-form-select
                          id="selectSalesOffice"
                          v-model="searchConditions.selectSalesOffice"
                          :options="searchConditions.salesOffice"
                          value-field="id"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form>
            </validation-observer>
          </b-card-body>
          <b-card-footer>
            <!-- 実行ボタン -->
            <b-row class="justify-content-md-center pb-4">
              <b-col lg="2">
                <b-button  class="mr-2" pill block variant="success" v-b-tooltip.hover title="指定された範囲の未確定の受注を確定します。" type="submit" form="InputForm">
                  <span class="oi oi-circle-check"></span> 実行
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <Footer />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, getControlMaster, executeSelectSql, CreateColRow, CreateUpdateSql, executeSqlList, reserveStockProductIdList, isSystemEditable, isAfterMonthlyUpdate, modStockProductIdListCase, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'order-stock-confirm-input';

export default {
  name: 'ORDER-STOCK-CONFIRM-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '入荷確定入力',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 検索項目
      searchConditions: {
        // 営業所プルダウン
        selectSalesOffice: null,
        salesOffice: [],
      },
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // 確認ダイアログ用
      confirmMessage: [],
      // 在庫増加製品
      stockProductId: [],
      // 特別受注引当製品
      specialReserveProductIdList: [],
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let controlData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData, controlData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
          getControlMaster(),
        ]);
        //console.log(staffListData);
        //console.log(officeListData);
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.searchConditions.salesOffice.push(office);
        }
        // 営業所データ初期値セット
        for(let i = 0; i < this.searchConditions.salesOffice.length; i++){
          //console.log(this.searchConditions.salesOffice[i].id);
          if(this.searchConditions.salesOffice[i].id == staffListData[0].office_id){
            this.searchConditions.selectSalesOffice = this.searchConditions.salesOffice[i].id;
            break;
          }
        }
        // 現在処理年月取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 保存ボタンの押下 */
    async saveData() {
      //console.log(this.searchConditions);
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      } else {
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('指定された営業所の入荷入力情報を確定します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if(typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('入荷確定入力処理開始');
      if (await this.execSave() == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
      }
      //console.log('入荷確定入力処理終了');
    },
    /* 保存実行 */
    async execSave() {
      const functionName = 'execSave';
      this.transactSqlList = [];
      this.stockProductId = [];
      this.specialReserveProductIdList = [];
      // 保存対象データ取得（受注と紐づく）
      let selectSql = '';
      selectSql = this.getSaveDataSql();
      //console.log(selectSql);
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null && dataResult.length > 0) {
        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.alertDanger.push(msg);
            return false;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
          return false;
        }
        // 月次更新後チェック
        if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
          return false;
        }
        // 入荷確定処理
        if (await this.orderStockConfirm(dataResult) == false) {
          this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
          return false;
        }
        // ケース換算製品追加
        let csvStockProductIdList = await modStockProductIdListCase(this.stockProductId);
        // 在庫が増加した製品の引当
        let errCsvProductId = '';
        if (csvStockProductIdList.length > 0) {
          errCsvProductId = await reserveStockProductIdList(this.searchConditions.selectSalesOffice, csvStockProductIdList, this.controlMasterData.processMonthYear, this.loginId, MODULE_NAME);
          if (errCsvProductId != '') {
            this.alertWarning.push(DISP_MESSAGES.WARNING['2021'].replace('%arg1%', errCsvProductId));
          }
        }
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%', '入荷データ'));
        return false
      }
      return true;
    },
    /* 更新対象データSQL取得処理 */
    getSaveDataSql: function() {
      //console.log('更新対象データSQL取得処理');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders.order_receive_id';
      selectSql += ',orders.order_receive_row';
      selectSql += ',orders.product_id';
      selectSql += ',orders.order_id';
      selectSql += ',orders.order_row';
      selectSql += ',stocks.stock_quantity';
      selectSql += ',IfNull(orders_receives.product_id,0) AS order_receive_product_id';
      selectSql += ',IfNull(orders_receives.remaining_quantity,0) AS remaining_quantity';
      selectSql += ',IfNull(orders_receives.converted_quantity,0) AS converted_quantity';
      selectSql += ',IfNull(orders_receives.case_conversion_class,' + Const.CaseConversionClassDef.noConversion + ') AS case_conversion_class';
      selectSql += ',IfNull(orders_receives.set_class,' + Const.SetClass.noSet + ') AS set_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_stocks AS stocks ';
      selectSql += 'INNER JOIN t_orders AS orders ';
      selectSql += 'ON stocks.order_id = orders.order_id ';
      selectSql += 'AND stocks.order_row = orders.order_row ';
      selectSql += 'LEFT JOIN t_orders_receives AS orders_receives ';
      selectSql += 'ON orders.order_receive_id = orders_receives.order_receive_id ';
      selectSql += 'AND orders.order_receive_row = orders_receives.order_receive_row ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 営業所コード
      selectSql += 'stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      // 入荷確定区分
      selectSql += 'AND stocks.stock_confirm_class = ' + Const.StockConfirmClass.mi + ' ';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' orders.order_receive_id';
      selectSql += ',orders.order_receive_row';
      selectSql += ',orders.product_id';

      return selectSql;
    },
    /* 入荷確定処理 */
    async orderStockConfirm(dataResult) {
      const functionName = 'orderStockConfirm';
      // 入荷データのロック
      this.lockStocks();
      // 発注データの更新
      this.updateOrders();
      // 受注データの更新（ケース換算無し）
      this.updateOrdersReceives();
      // 受注セット品データの更新（部材製品）
      this.updateOrdersReceivedSetCompositions();
      // 受注データ更新SQL作成（セット製品）
      this.updateOrdersReceivesSet();
      // 在庫マスタの更新
      this.updateStocks();
      for (let i = 0; i < dataResult.length; i++) {
        if (dataResult[i].order_receive_id != 0) {
          // 受注と紐づく場合
          if (dataResult[i].set_class == Const.SetClass.noSet &&
            dataResult[i].case_conversion_class == Const.CaseConversionClassDef.conversion) {
            // バラ⇒ケース製品（セット品区分:0、且つ、ケース換算区分「0:する」）
            // 受注データの更新（バラ⇒ケース）
            this.updateOrdersReceivesLoose(dataResult[i]);
            // ケースから出したバラの数が受注数を超過した場合は余った分は在庫へ
            if (dataResult[i].stock_quantity * dataResult[i].converted_quantity > dataResult[i].remaining_quantity) {
              // 在庫マスタの更新（バラ⇒ケース）
              this.updateStocksLoose(dataResult[i]);
              // 在庫増加製品コードリスト追加
              this.addStockProductIdList(dataResult[i].order_receive_product_id);
              // 引当てた製品の数量を特別受注引当リストに追加
              this.addSpecialReserveProductIdList(dataResult[i].order_receive_product_id, dataResult[i].remaining_quantity);
            } else {
              // 引当てた製品の数量を特別受注引当リストに追加
              this.addSpecialReserveProductIdList(dataResult[i].order_receive_product_id, dataResult[i].stock_quantity * dataResult[i].converted_quantity);
            }
          } else {
            // 引当てた製品の数量を特別受注引当リストに追加
            this.addSpecialReserveProductIdList(dataResult[i].product_id, dataResult[i].stock_quantity);
          }
        } else if (dataResult[i].order_receive_id == 0) {
          // 受注と紐づかない
          // 在庫増加製品コードリスト追加
          this.addStockProductIdList(dataResult[i].product_id);
        }
      }
      if (this.specialReserveProductIdList.length > 0) {
        // 在庫データの更新（特別受注の引当数）
        this.updateStocksSpecialReserve();
      }
      // 入荷データの更新
      this.updateOrdersStocks();
      //console.log(this.transactSqlList);
      try {
        await executeSqlList(this.transactSqlList);
        this.transactSqlList = [];
      } catch (error) {
        console.log(error);
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        return false;
      }
      return true;
    },
    /* 入荷データロックSQL作成 */
    lockStocks: function(){
      //console.log('入荷データロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' stocks.order_id';
      selectSql += ',stocks.order_row';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_stocks AS stocks ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 営業所コード
      selectSql += 'stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      // 入荷確定区分
      selectSql += 'AND stocks.stock_confirm_class = ' + Const.StockConfirmClass.mi + ' ';
      //console.log(selectSql)
      this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
    },
    /* 入荷データ更新SQL作成 */
    updateOrdersStocks: function() {
      //console.log('入荷データ更新SQL作成');
      let colList = [];
      let updateSql = '';
      // 入荷確定区分
      colList.push(CreateColRow('stocks.stock_confirm_class', Const.StockConfirmClass.sumi, 'NUMBER'));
      // 入荷確定日
      colList.push(CreateColRow('stocks.stock_confirm_date', 'CURDATE()', 'DATE'));
      // 更新日
      colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
      updateSql = CreateUpdateSql(colList, 't_stocks AS stocks');
      updateSql += ' WHERE ';
      updateSql += 'stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      updateSql += 'AND stocks.stock_confirm_class = ' + Const.StockConfirmClass.mi + ' ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 発注データ更新SQL作成 */
    updateOrders: function() {
      //console.log('発注データ更新SQL作成');
      let colList = [];
      let updateSql = '';
      // 発注残数
      colList.push(CreateColRow('orders.remaining_quantity', 'orders.remaining_quantity - stocks.stock_quantity', 'NUMBER'));
      // 入荷数
      colList.push(CreateColRow('orders.stock_quantity', 'orders.stock_quantity + stocks.stock_quantity', 'NUMBER'));
      // 入荷予定引当数
      colList.push(CreateColRow('orders.stock_scheduled_reserve', 'CASE WHEN orders.order_receive_id = 0 THEN orders.stock_scheduled_reserve ELSE orders.stock_scheduled_reserve - stocks.stock_quantity END', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('orders.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders.updated_user', this.loginId, 'VARCHAR'));
      updateSql = CreateUpdateSql(colList, 't_orders AS orders', 't_stocks AS stocks');
      updateSql += ' WHERE ';
      updateSql += 'stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      updateSql += 'AND stocks.stock_confirm_class = ' + Const.StockConfirmClass.mi + ' ';
      updateSql += 'AND orders.order_id = stocks.order_id ';
      updateSql += 'AND orders.order_row = stocks.order_row ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 受注データ更新SQL作成（ケース換算無し） */
    updateOrdersReceives: function() {
      //console.log('受注データ更新SQL作成（ケース換算無し）');
      let colList = [];
      let updateSql = '';
      // 入荷予定引当数受注
      colList.push(CreateColRow('orders_receives.stock_scheduled_reserve_receive', 'orders_receives.stock_scheduled_reserve_receive + orders_QUERY.stock_quantity', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('orders_receives.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders_receives.updated_user', this.loginId, 'VARCHAR'));
      // 更新用の副問い合わせテーブル作成
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' orders.order_receive_id';
      updateQuery += ',orders.order_receive_row';
      updateQuery += ',stocks.stock_quantity';
      updateQuery += ' FROM ';
      updateQuery += 't_orders AS orders ';
      updateQuery += 'INNER JOIN t_stocks AS stocks ';
      updateQuery += 'ON orders.order_id = stocks.order_id ';
      updateQuery += 'AND orders.order_row = stocks.order_row ';
      updateQuery += ' WHERE ';
      updateQuery += 'orders.order_receive_id <> 0 ';
      updateQuery += 'AND stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      updateQuery += 'AND stocks.stock_confirm_class = ' + Const.StockConfirmClass.mi + ' ';
      updateQuery += ') AS orders_QUERY';
      updateSql = CreateUpdateSql(colList, 't_orders_receives AS orders_receives', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'orders_receives.order_receive_id = orders_QUERY.order_receive_id ';
      updateSql += 'AND orders_receives.order_receive_row = orders_QUERY.order_receive_row ';
      updateSql += 'AND orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.noConversion + ' ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 受注セット品データ更新SQL作成（部材製品） */
    updateOrdersReceivedSetCompositions: function() {
      //console.log('受注セット品データ更新SQL作成（部材製品）');
      let colList = [];
      let updateSql = '';
      // 入荷予定引当数受注
      colList.push(CreateColRow('orders_received_set.stock_scheduled_reserve_receive', 'orders_received_set.stock_scheduled_reserve_receive + orders_QUERY.stock_quantity', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('orders_received_set.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders_received_set.updated_user', this.loginId, 'VARCHAR'));
      // 更新用の副問い合わせテーブル作成
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' orders.order_receive_id';
      updateQuery += ',orders.order_receive_row';
      updateQuery += ',orders.order_id';
      updateQuery += ',orders.order_row';
      updateQuery += ',stocks.stock_quantity';
      updateQuery += ' FROM ';
      updateQuery += 't_orders AS orders ';
      updateQuery += 'INNER JOIN t_stocks AS stocks ';
      updateQuery += 'ON orders.order_id = stocks.order_id ';
      updateQuery += 'AND orders.order_row = stocks.order_row ';
      updateQuery += ' WHERE ';
      updateQuery += 'orders.order_receive_id <> 0 ';
      updateQuery += 'AND stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      updateQuery += 'AND stocks.stock_confirm_class = ' + Const.StockConfirmClass.mi + ' ';
      updateQuery += ') AS orders_QUERY';
      updateSql = CreateUpdateSql(colList, 't_orders_received_set AS orders_received_set', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'orders_received_set.order_receive_id = orders_QUERY.order_receive_id ';
      updateSql += 'AND orders_received_set.order_receive_row = orders_QUERY.order_receive_row ';
      updateSql += 'AND orders_received_set.order_id = orders_QUERY.order_id ';
      updateSql += 'AND orders_received_set.order_row = orders_QUERY.order_row ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 受注データ更新SQL作成（セット製品） */
    updateOrdersReceivesSet: function() {
      //console.log('受注データ更新SQL作成（セット製品）');
      let colList = [];
      let updateSql = '';
      // 入荷予定引当数受注
      colList.push(CreateColRow('orders_receives.stock_scheduled_reserve_receive', 'orders_QUERY.stock_scheduled_reserve_receive', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('orders_receives.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders_receives.updated_user', this.loginId, 'VARCHAR'));
      // 更新用の副問い合わせテーブル作成
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' orders.order_receive_id';
      updateQuery += ',orders.order_receive_row';
      updateQuery += ',MIN(TRUNCATE(orders_received_set.stock_scheduled_reserve_receive / products_compositions.quantity,0)) AS stock_scheduled_reserve_receive';
      updateQuery += ' FROM ';
      updateQuery += 't_orders AS orders ';
      updateQuery += 'INNER JOIN t_stocks AS stocks ';
      updateQuery += 'ON orders.order_id = stocks.order_id ';
      updateQuery += 'AND orders.order_row = stocks.order_row ';
      updateQuery += 'INNER JOIN t_orders_receives AS orders_receives ';
      updateQuery += 'ON orders.order_receive_id = orders_receives.order_receive_id ';
      updateQuery += 'AND orders.order_receive_row = orders_receives.order_receive_row ';
      updateQuery += 'INNER JOIN t_orders_received_set AS orders_received_set ';
      updateQuery += 'ON orders.order_receive_id = orders_received_set.order_receive_id ';
      updateQuery += 'AND orders.order_receive_row = orders_received_set.order_receive_row ';
      updateQuery += 'INNER JOIN m_products_compositions AS products_compositions ';
      updateQuery += 'ON orders_receives.product_id = products_compositions.product_id ';
      updateQuery += 'AND orders_received_set.product_id = products_compositions.component_product_id ';
      updateQuery += ' WHERE ';
      updateQuery += 'orders.order_receive_id <> 0 ';
      updateQuery += 'AND stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      updateQuery += 'AND stocks.stock_confirm_class = ' + Const.StockConfirmClass.mi + ' ';
      updateQuery += 'GROUP BY orders.order_receive_id,orders.order_receive_row';
      updateQuery += ') AS orders_QUERY';
      updateSql = CreateUpdateSql(colList, 't_orders_receives AS orders_receives', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'orders_receives.order_receive_id = orders_QUERY.order_receive_id ';
      updateSql += 'AND orders_receives.order_receive_row = orders_QUERY.order_receive_row ';
      updateSql += 'AND orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.conversion + ' ';
      updateSql += 'AND orders_receives.set_class = ' + Const.SetClass.set + ' ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 受注データ更新SQL作成（バラ⇒ケース） */
    updateOrdersReceivesLoose: function(dataItem) {
      //console.log('受注データ更新SQL作成（バラ⇒ケース）');
      let colList = [];
      let updateSql = '';
      // ケースから出したバラの数が受注数を超過する可能性あり（超過した場合は受注残を埋める）
      if (dataItem.stock_quantity * dataItem.converted_quantity > dataItem.remaining_quantity) {
        // 入荷予定引当数受注
        colList.push(CreateColRow('orders_receives.stock_scheduled_reserve_receive', 'orders_receives.stock_scheduled_reserve_receive + ' + dataItem.remaining_quantity, 'NUMBER'));
      } else {
        // 入荷予定引当数受注
        colList.push(CreateColRow('orders_receives.stock_scheduled_reserve_receive', 'orders_receives.stock_scheduled_reserve_receive + ' + dataItem.stock_quantity * dataItem.converted_quantity, 'NUMBER'));
      }
      // 更新日
      colList.push(CreateColRow('orders_receives.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders_receives.updated_user', this.loginId, 'VARCHAR'));
      // 更新用の副問い合わせテーブル作成
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' orders.order_receive_id';
      updateQuery += ',orders.order_receive_row';
      updateQuery += ' FROM ';
      updateQuery += 't_orders AS orders ';
      updateQuery += 'INNER JOIN t_stocks AS stocks ';
      updateQuery += 'ON orders.order_id = stocks.order_id ';
      updateQuery += 'AND orders.order_row = stocks.order_row ';
      updateQuery += ' WHERE ';
      updateQuery += 'orders.order_receive_id <> 0 ';
      updateQuery += 'AND stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      updateQuery += 'AND stocks.stock_confirm_class = ' + Const.StockConfirmClass.mi + ' ';
      updateQuery += ') AS orders_QUERY';
      updateSql = CreateUpdateSql(colList, 't_orders_receives AS orders_receives', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'orders_receives.order_receive_id = ' + dataItem.order_receive_id + ' ';
      updateSql += 'AND orders_receives.order_receive_row = ' + dataItem.order_receive_row + ' ';
      updateSql += 'AND orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.conversion + ' ';
      updateSql += 'AND orders_receives.set_class = ' + Const.SetClass.noSet + ' ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 在庫マスタの更新（バラ⇒ケース） */
    updateStocksLoose: function(dataItem) {
      //console.log('在庫マスタの更新（バラ⇒ケース）');
      let colList = [];
      let updateSql = '';
      // 残高数
      colList.push(CreateColRow('stocks.balance', 'stocks.balance + ' + (dataItem.stock_quantity * dataItem.converted_quantity - dataItem.remaining_quantity).toString(), 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
      updateSql = CreateUpdateSql(colList, 'm_stocks AS stocks', 't_stocks AS ordersStocks');
      updateSql += ' WHERE ';
      updateSql += 'stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      updateSql += 'AND stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      updateSql += 'AND stocks.product_id = ' + dataItem.order_receive_product_id + ' ';
      updateSql += 'AND ordersStocks.order_id = ' + dataItem.order_id + ' ';
      updateSql += 'AND ordersStocks.order_row = ' + dataItem.order_row + ' ';
      updateSql += 'AND ordersStocks.stock_confirm_class = ' + Const.StockConfirmClass.mi + ' ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 在庫マスタ更新SQL作成 */
    updateStocks: function() {
      //console.log('在庫マスタ更新SQL作成');
      let colList = [];
      let updateSql = '';
      // 残高数
      colList.push(CreateColRow('stocks.balance', 'stocks.balance + PRODUCT_QUERY.balance', 'NUMBER'));
      // 月次仕入入庫数
      colList.push(CreateColRow('stocks.monthly_purchase_stock', 'stocks.monthly_purchase_stock + PRODUCT_QUERY.quantity_stock', 'NUMBER'));
      // 入荷予定数
      colList.push(CreateColRow('stocks.quantity_stock', 'stocks.quantity_stock - PRODUCT_QUERY.quantity_stock', 'NUMBER'));
      // 入荷予定引当数
      colList.push(CreateColRow('stocks.quantity_stock_reserve', 'stocks.quantity_stock_reserve - PRODUCT_QUERY.quantity_stock_reserve', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
      // 更新用の副問い合わせテーブル作成
      let updateSubQuery = '';
      for(let i = 0; i < 2; i++) {
        // 2つのSELECT文をUNION
        // ・受注番号なし（残高と入荷予定数）
        // ・受注番号あり（入荷予定数と入荷予定引当数）
        if (updateSubQuery != '') {
          updateSubQuery += ' UNION ';
        }
        updateSubQuery += 'SELECT';
        updateSubQuery += ' orders.order_id';
        updateSubQuery += ',orders.order_row';
        updateSubQuery += ',orders.product_id';
        if (i == 0) {
          updateSubQuery += ',stocks.stock_quantity AS balance';
          updateSubQuery += ',stocks.stock_quantity AS quantity_stock';
          updateSubQuery += ',0 AS quantity_stock_reserve';
        } else {
          updateSubQuery += ',0 AS balance';
          updateSubQuery += ',stocks.stock_quantity AS quantity_stock';
          updateSubQuery += ',stocks.stock_quantity AS quantity_stock_reserve';
        }
        updateSubQuery += ' FROM ';
        updateSubQuery += 't_orders AS orders ';
        updateSubQuery += 'INNER JOIN t_stocks AS stocks ';
        updateSubQuery += 'ON orders.order_id = stocks.order_id ';
        updateSubQuery += 'AND orders.order_row = stocks.order_row ';
        updateSubQuery += ' WHERE ';
        if (i == 0) {
          updateSubQuery += 'orders.order_receive_id = 0 ';
        } else {
          updateSubQuery += 'orders.order_receive_id <> 0 ';
        }
        updateSubQuery += 'AND stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
        updateSubQuery += 'AND stocks.stock_confirm_class = ' + Const.StockConfirmClass.mi + ' ';
      }
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' SUB_QUERY.product_id';
      updateQuery += ',SUM(SUB_QUERY.balance) AS balance';
      updateQuery += ',SUM(SUB_QUERY.quantity_stock) AS quantity_stock';
      updateQuery += ',SUM(SUB_QUERY.quantity_stock_reserve) AS quantity_stock_reserve';
      updateQuery += ' FROM ';
      updateQuery += '(' + updateSubQuery + ') AS SUB_QUERY ';
      updateQuery += 'GROUP BY product_id ';
      updateQuery += ') AS PRODUCT_QUERY';
      updateSql = CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      updateSql += 'AND stocks.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      updateSql += 'AND stocks.product_id = PRODUCT_QUERY.product_id ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    // 在庫データの更新（特別受注の引当数）
    updateStocksSpecialReserve: function() {
      let csvProductId = '';
      let colList = [];
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      // 製品関係の更新部分
      let strWorkField = 'FIELD(product_id';
      let strWorkInventoryReserveCount = '';
      for (let i = 0; i < this.specialReserveProductIdList.length; i++) {
        strWorkField += ',' + this.specialReserveProductIdList[i].productId;
        // 在庫引当数
        strWorkInventoryReserveCount += ',' + this.specialReserveProductIdList[i].reserveCnt;
        // カンマ区切り製品コード
        if (csvProductId != '') {
          csvProductId += ',';
        }
        csvProductId += this.specialReserveProductIdList[i].productId;
      }
      strWorkField += ')';
      // 在庫引当数
      colList.push(CreateColRow('inventory_reserve_count', 'inventory_reserve_count + ELT(' + strWorkField + strWorkInventoryReserveCount + ')', 'NUMBER'));
      // WHERE句
      let where_clause = ' WHERE ';
      where_clause += 'month_year = ' + this.controlMasterData.processMonthYear + ' ';
      where_clause += 'AND office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      where_clause += 'AND product_id IN (' + csvProductId + ') ';
      let updateSql = CreateUpdateSql(colList, 'm_stocks') + where_clause;
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 在庫増加製品コードリスト追加 */
    addStockProductIdList: function(productId) {
      if (this.stockProductId.length == 0) {
        this.stockProductId.push(productId);
      } else {
        // 重複しないように製品コードをリストに追加
        for (let j = 0; j < this.stockProductId.length; j++) {
          if (this.stockProductId[j] == productId) {
            break;
          }
          if (j == this.stockProductId.length - 1) {
            // 在庫マスタの増加があった製品はリストに保持（後で未引当の受注への引当に使用）
            this.stockProductId.push(productId);
          }
        }
      }
    },
    // 特別受注引当製品コードリスト追加
    addSpecialReserveProductIdList: function(productId, reserveCnt) {
      let data = this.specialReserveProductIdList.find(el => el.productId == productId);
      if (data != undefined) {
        data.reserveCnt += reserveCnt;
      } else {
        this.specialReserveProductIdList.push({productId: productId, reserveCnt: reserveCnt});
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
  }
}
</script>
<!-- ●●●画面固有●●● -->
<style scoped>
</style>