<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="mb-2">
            <b-card>
              <div class="mb-0 p-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                  <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive title="発注データの新規登録" @click="clickInputBtn" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </b-button>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-0">
                  <validation-observer ref="observer">
                    <b-form @submit.prevent="clearAlert(); searchButton();" id="orderListForm" class="form-horizontal">
                      <b-container fluid>
                        <!-- ●●●検索条件●●● -->
                        <b-row class="mt-1">
                          <!-- 営業所入力欄 -->
                          <b-col lg="3">
                            <b-form-group
                              label="営業所"
                              lable-for="selectSalesOffice"
                            >
                              <b-form-select
                                id="selectSalesOffice"
                                v-model="searchConditions.selectSalesOffice"
                                :options="searchConditions.salesOffice"
                                value-field="id"
                                text-field="name"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <!-- 発注種別入力欄 -->
                          <b-col lg="3">
                            <b-form-group
                              label="発注種別"
                              label-for="selectOrderClass"
                            >
                              <b-form-select
                                id="selectOrderClass"
                                v-model="searchConditions.selectOrderClass"
                                :options="searchConditions.orderClassList"
                                value-field="id"
                              ></b-form-select>
                            </b-form-group>
                          </b-col>
                          <!-- 発注番号入力欄 -->
                          <b-col lg="3">
                            <validation-provider name="orderId" rules="numeric" v-slot="{ classes,errors }">
                              <label for="orderId">発注番号<b-form-text class="text-muted d-inline-flex">(完全一致検索)</b-form-text></label>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="orderId" name="orderId" v-model="searchConditions.orderId"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </validation-provider>
                          </b-col>
                        </b-row>
                        <b-row class="mt-1">
                          <!-- 発注日付入力欄 -->
                          <b-col lg="6"><b-form-text class="text-muted d-inline-flex"></b-form-text>
                            <label for="orderDateStart" v-b-tooltip.hover.noninteractive  title='パフォーマンスの関係で過去の全データを表示することは不可能です。日付は基本的に指定してください。または、発注番号か注文番号か取引先コードを指定してください。'>発注日付<span class="px-1 oi oi-flag"/></label>
                            <validation-provider name="orderDateStart" :rules="{dateConsistency:searchConditions.orderDateEnd}" v-slot="{ classes,errors }">
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group class="input-daterange" id="datepicker">
                                  <b-form-datepicker id="orderDateStart" name="orderDateStart" class="form-control" v-model="searchConditions.orderDateStart"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.orderDateStart=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                  <b-input-group-text>～</b-input-group-text>
                                  <b-form-datepicker id="datepicker2" name="orderDateEnd" class="form-control" v-model="searchConditions.orderDateEnd"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.orderDateEnd=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </validation-provider>
                          </b-col>
                          <!-- 注文番号入力欄 -->
                          <b-col lg="6">
                            <label for="orderNumber">注文番号</label>
                            <b-input-group>
                              <validation-provider name="orderNumber" rules="numeric" v-slot="{ classes,errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-form-input
                                      type="text"
                                      id="orderNumber"
                                      name="orderNumber"
                                      v-model.number="searchConditions.orderNumber"
                                      v-shortkey="['alt', '1']"
                                      @shortkey="setFocus('orderNumber')"
                                      v-b-tooltip.hover.noninteractive
                                      title="「ALT+1」ボタンで移動可能"
                                    />
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-input-group>
                          </b-col>
                        </b-row>
                        <b-row class="mt-1">
                          <!-- 取引先コード入力欄 -->
                          <b-col lg="3">
                            <validation-provider name="customerCode" rules="numeric" v-slot="{ classes,errors }">
                              <label for="customerCode">取引先コード<b-form-text class="text-muted d-inline-flex">(完全一致検索)</b-form-text></label>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="customerCode" name="customerCode" v-model="searchConditions.clientCode"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </validation-provider>
                          </b-col>
                          <!-- 取引先名入力欄 -->
                          <b-col lg="9">
                            <label for="customerName">取引先名<b-form-text class="text-muted d-inline-flex">(部分一致検索です。登録済みの取引先名（漢字）または取引先名（カナ）と一致するデータを検索します)</b-form-text></label>
                            <b-form-input type="text" id="customerName" name="customerName" v-model="searchConditions.clientName"></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row class="mt-1">
                          <!-- 製品コード入力欄 -->
                          <b-col lg="3">
                            <validation-provider name="productCode" rules="numeric" v-slot="{ classes,errors }">
                              <label for="productCode">製品コード<b-form-text class="text-muted d-inline-flex">(前方一致検索)</b-form-text></label>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="productCode" name="productCode" v-model="searchConditions.productCode"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </validation-provider>
                          </b-col>
                          <!-- 製品名入力欄 -->
                          <b-col lg="9">
                            <label for="productName">製品名<b-form-text class="text-muted d-inline-flex">(部分一致検索です。登録済みの製品名（漢字）と一致するデータを検索します)</b-form-text></label>
                            <b-form-input type="text" id="productName" name="productName" v-model="searchConditions.productName"></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row class="mt-1">
                          <!-- 担当者コード入力欄 -->
                          <b-col lg="3">
                            <validation-provider name="personInCharge" rules="numeric" v-slot="{ classes,errors }">
                              <label for="personInCharge">担当者コード<b-form-text class="text-muted d-inline-flex">(完全一致検索)</b-form-text></label>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="personInCharge" name="personInCharge" v-model="searchConditions.personInCharge"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </validation-provider>
                          </b-col>
                          <!-- 削除チェック入力欄 -->
                          <b-col lg="2">
                            <label for="isDeleted" v-b-tooltip.hover.noninteractive  title='チェックONの場合、削除された発注データが表示されます'>削除<span class="px-1 oi oi-flag"/></label>
                            <b-form-checkbox
                            id="isDeleted"
                            name="isDeleted"
                            v-model="searchConditions.isDeleted"
                            ></b-form-checkbox>
                          </b-col>
                          <!-- 未入荷チェック入力欄 -->
                          <b-col lg="2">
                            <label for="isNoStock" v-b-tooltip.hover.noninteractive  title='チェックONの場合、入荷入力されていない発注データのみが表示されます'>未入荷<span class="px-1 oi oi-flag"/></label>
                            <b-form-checkbox
                              id="isNoStock"
                              name="isNoStock"
                              v-model="searchConditions.isNoStock"
                            ></b-form-checkbox>
                          </b-col>
                          <!-- 入荷予定日超過チェック入力欄 -->
                          <b-col lg="2">
                            <label for="orderDeadline" v-b-tooltip.hover.noninteractive  title='チェックONの場合、入荷予定日が過ぎている発注データのみが表示されます'>入荷予定日超過<span class="px-1 oi oi-flag"/></label>
                            <b-form-checkbox
                              id="orderDeadline"
                              name="orderDeadline"
                              v-model="searchConditions.orderDeadline"
                            ></b-form-checkbox>
                          </b-col>
                        </b-row>
                        <!-- 検索ボタン -->
                        <b-row class="justify-content-md-center">
                          <b-col lg="3">
                            <b-button block pill variant="success" type="submit" form="orderListForm">
                              <span class="oi oi-magnifying-glass"></span> 検 索 
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-form>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-0" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-0">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="mb-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="mb-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :small="true"
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <template #cell(CustomerName)="data">
                <div style="max-width:250px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                    <span v-b-tooltip.hover.noninteractive :title="data.item.CustomerName">
                      {{ data.item.CustomerName }}
                    </span>
                  </div>
                </div>
              </template>
              <!-- 注文番号 -->
              <template #cell(OrderNumber)="data">
                {{ data.item.OrderNumber }}
                <!-- 入荷予定情報編集ボタン -->
                <b-button v-show="!printStatus" size="sm" v-b-tooltip.hover.noninteractive.left="'指定された注文番号の製品の入荷予定情報を編集するためのモーダルを開きます。'" @click="clickStockScheduledEditBtn(data.item.OrderNumber)" v-if="data.item.OrderNumber!=null&&searchConditions.isDeletedSearch==false">
                  <span class="oi oi-pencil"></span>
                </b-button>&nbsp;
                <!-- コピー仕入入力ボタン -->
                <b-button v-show="!printStatus" size="sm" v-b-tooltip.hover.noninteractive.left="'コピーして仕入伝票を新規登録します。'" @click="clickPurchaseCopy(data.item.OrderNumber, data.item.CustomerCode, data.item.OrderClassId)" v-if="data.item.OrderNumber!=null&&searchConditions.isDeletedSearch==false">
                  <span class="oi oi-tag"></span>
                </b-button>
              </template>
              <template #cell(ProductCode)="data">
                <div style="max-width:250px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                    <span v-b-tooltip.hover.noninteractive :title="data.item.ProductCode">
                      {{ data.item.ProductCode }}
                    </span>
                  </div>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(Operation)="operation">
                <div v-show="!printStatus">
                  <!-- 照会ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'発注内容の照会と各種操作を行います。'" @click="clickDetailBtn(operation.item.OrderId)" class="mr-2">
                    <span class="oi oi-list"></span> 照会
                  </b-button>
                  <!-- 編集ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'発注の編集を行います。'" @click="clickOrderEdit(operation.item.OrderId)" v-if="operation.item.IsDeleted==0" class="mr-2">
                    <span class="oi oi-pencil"></span> 編集
                  </b-button>
                  <!-- コピー発注ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'コピーして発注を新規登録します。'" @click="clickOrderCopy(operation.item.OrderId)" class="mr-2">
                    <span class="oi oi-tag"></span> コピー発注
                  </b-button>
                  <!-- 削除ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'発注の削除を行います。'" @click="clearAlert(); clickOrderDelete(operation.item.OrderId)" v-if="(operation.item.OrderClassId==htmlConst.OrderClass.noStock||operation.item.OrderClassId==htmlConst.OrderClass.input)&&searchConditions.isDeletedSearch==false" class="mr-1">
                    <span class="oi oi-delete"></span> 削除
                  </b-button>
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="mt-1">
            <b-pagination
              v-model="currentPage"
              size="sm"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●入荷予定編集モーダル●●● -->
    <STOCKSCHEDULEDEDIT :propOrderNumber="propOrderNumber"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import STOCKSCHEDULEDEDIT from '@/components/modal/stock-scheduled-edit.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, windowPrint, convertSqlLikeSpecialChar, addOperationLogs, formatDate, getListValue, executeSelectSql, executeSqlList, CreateColRow, CreateUpdateSql, isSystemEditable, isAfterMonthlyUpdate, getControlMaster, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'order-list';

export default {
  name: 'ORDER-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    STOCKSCHEDULEDEDIT,
    CONFIRM,
  },
  props:['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '発注一覧',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions:{
        selectSalesOffice: '',
        salesOffice: [],
        selectOrderClass: 0,
        orderClassList: [],
        orderId: '',
        orderDateStart: '',
        orderDateEnd: '',
        clientCode: '',
        clientName: '',
        personInCharge: '',
        productCode: '',
        productName: '',
        isDeleted: false,
        isDeletedSearch: false,
        isNoStock: false,
        orderDeadline: false,
        orderNumber: '',
      },
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
      // 入荷予定情報編集に渡すパラメータ
      propOrderNumber: 0,
      // 確認ダイアログ用
      confirmMessage: [],
      // 削除対象発注番号
      delOrderId: null,
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 発注種別
        OrderClass: {
          // 受注発注
          orderReceive: Const.OrderClass.orderReceive,
          // 在庫切れ
          noStock: Const.OrderClass.noStock,
          // 直送発注
          direct: Const.OrderClass.direct,
          // 発注入力
          input: Const.OrderClass.input,
          // 特別発注
          special: Const.OrderClass.special,
        },
      },
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'OrderDate',
          label: '発注日付',
          sortable: true,
        },
        {
          key: 'OrderId',
          label: '発注番号',
          sortable: true,
        },
        {
          key: 'OrderClass',
          label: '発注種別',
          sortable: true,
        },
        {
          key: 'SalesOffice',
          label: '営業所名',
          sortable: true,
        },
        {
          key: 'CustomerCode',
          label: '取引先コード',
          sortable: true,
        },
        {
          key: 'CustomerName',
          label: '取引先名',
          sortable: true,
        },
        {
          key: 'StaffId',
          label: '担当者コード',
          sortable: true,
        },
        {
          key: 'StaffName',
          label: '担当者名',
          sortable: true,
        },
        {
          key: 'OrderNumber',
          label: '注文番号',
          sortable: true,
        },
        {
          key: 'ProductCode',
          label: '製品',
          sortable: true,
        },
        {
          key: 'Operation',
          label: '操作',
        }
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods: {
    shortkey() {
      this.$router.push({ name: 'ORDER-INPUT', query: { propOrderId: 0 } });
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
        ]);
        let loginOfficeId = staffListData[0].office_id;
        //console.log(staffListData);
        //console.log(officeListData);
        // default値挿入
        this.searchConditions.selectSalesOffice = 0;
        // 営業所プルダウン作成
        this.searchConditions.salesOffice.push({id: 0, name: '全て'});
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            name: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji
          };
          this.searchConditions.salesOffice.push(office);
          // default値挿入
          if (loginOfficeId == officeListData[i].office_id) {
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
        // 発注種別
        this.searchConditions.orderClassList.push({id: 0, text: '全て'});
        Array.prototype.push.apply(this.searchConditions.orderClassList, Const.OrderClassList);
        // パラメータによってメッセージを表示
        if(this.parentKbn == 1){
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      // 注文番号にフォーカスを移動
      document.getElementById('orderNumber').focus();
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      if (this.busy == true) {
        // 連続して押下された場合は処理を行わない
        return;
      }
      this.itemList = [];
      this.searchConditions.isDeletedSearch = this.searchConditions.isDeleted;
      // ページング機能の初期化
      this.initPaging();
      this.busy = true;
      try {
        // CRUD処理
        let selectSql = '';
        selectSql = this.makeSelectSql();
        //console.log(selectSql);
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        //console.log(resultData);
        if (resultData != null) {
          await this.setResult(resultData);
          if (resultData.length >= 1000) {
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.totalRows = 0;
      }
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result) {
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          OrderDate: formatDate(result[i].order_date),
          OrderId: result[i].order_id,
          OrderClass: getListValue(Const.OrderClassList ,result[i].order_class, 'id', 'text'),
          OrderClassId: result[i].order_class,
          SalesOffice: result[i].office_name_kanji,
          StaffId: result[i].staff_id,
          StaffName: result[i].staff_name_kanji,
          CustomerCode: result[i].client_id,
          CustomerName: result[i].client_name_kanji,
          IsDeleted: result[i].is_deleted,
          Operation: result[i],
          OrderNumber: result[i].order_number ? result[i].order_number : null,
          ProductCode: result[i].product_id + ' ' + result[i].product_name,
        };
        this.itemList.push(searchResult);
      }
    },
    /* SELECT文字列作成 */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders.order_id';
      selectSql += ',orders.order_date';
      selectSql += ',orders.order_class';
      selectSql += ',offices.office_name_kanji';
      selectSql += ',orders.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',orders.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',orders_QUERY.is_deleted';
      selectSql += ',orders.order_number';
      selectSql += ',orders.product_id';
      selectSql += ',orders.product_name';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      selectSql += 'INNER JOIN ' + this.makeSelectQuerySql() + ' AS orders_QUERY ';
      selectSql += 'ON orders.order_id = orders_QUERY.order_id ';
      selectSql += 'AND orders.order_row = orders_QUERY.order_row ';
      selectSql += 'INNER JOIN m_offices AS offices ';
      selectSql += 'ON orders.office_id = offices.office_id ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON orders.client_class = clients.client_class ';
      selectSql += 'AND orders.client_id = clients.client_id ';
      selectSql += 'INNER JOIN m_staffs AS staffs ';
      selectSql += 'ON orders.staff_id = staffs.staff_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 削除済フラグ
      if (this.searchConditions.isDeleted == false) {
        selectSql += ' orders_QUERY.is_deleted = 0 ';
      } else {
        selectSql += ' orders_QUERY.is_deleted = 1 ';
      }
      // 営業所コード
      if (this.searchConditions.selectSalesOffice != 0) {
        selectSql += 'AND orders.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      }
      // 発注番号
      if (this.searchConditions.orderId != '') {
        selectSql += 'AND orders.order_id = ' + this.searchConditions.orderId + ' ';
      }
      // 発注日付
      if (this.searchConditions.orderDateStart != '' && this.searchConditions.orderDateEnd == '') {
        selectSql += 'AND orders.order_date >= ' + '\'' + this.searchConditions.orderDateStart + '\' ';
      } else if (this.searchConditions.orderDateStart == '' && this.searchConditions.orderDateEnd != '') {
        selectSql += 'AND orders.order_date <= ' + '\'' + this.searchConditions.orderDateEnd + '\' ';
      } else if (this.searchConditions.orderDateStart != '' && this.searchConditions.orderDateEnd != '') {
        selectSql += 'AND orders.order_date BETWEEN ' + '\'' + this.searchConditions.orderDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.orderDateEnd + '\' ';
      }
      // 発注種別
      if (this.searchConditions.selectOrderClass != 0) {
        selectSql += 'AND orders.order_class = ' + this.searchConditions.selectOrderClass + ' ';
      }
      // 取引先コード
      if (this.searchConditions.clientCode != '') {
        selectSql += 'AND orders.client_class = ' + Const.ClientClass.supplier + ' ';
        selectSql += 'AND orders.client_id = ' + this.searchConditions.clientCode + ' ';
      }
      // 取引先名(あいまい検索対象)
      if (this.searchConditions.clientName != '') {
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        selectSql += `AND (REPLACE(REPLACE(CONVERT(clients.client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
        selectSql += `OR REPLACE(REPLACE(CONVERT(clients.client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      // 担当者コード
      if (this.searchConditions.personInCharge != '') {
        selectSql += 'AND orders.staff_id = ' + this.searchConditions.personInCharge + ' ';
      }
      // 製品コード
      if (this.searchConditions.productCode != '') {
        selectSql += 'AND orders.product_id LIKE \'' + this.searchConditions.productCode + '%\' ';
      }
      // 製品名(あいまい検索対象)
      if (this.searchConditions.productName != '') {
        const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
        selectSql += `AND REPLACE(REPLACE(CONVERT(orders.product_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // 未入荷フラグ
      selectSql += !this.searchConditions.isNoStock ? '' : 'AND orders.remaining_quantity > 0 ';
      // 入荷予定日超過フラグ
      selectSql += !this.searchConditions.orderDeadline ? '' : 'AND orders.stock_scheduled < CURDATE() ';
      // 注文番号
      if (this.searchConditions.orderNumber != '') {
        selectSql += (`AND orders.order_number = ${this.searchConditions.orderNumber} `);
      }
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' orders.order_id';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' order_date DESC';
      selectSql += ',order_id DESC';
      /* LIMIT */
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    /* SELECT用クエリ文字列作成（削除データ用（全製品削除フラグ1）） */
    makeSelectQuerySql: function() {
      let selectSql = '(';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders.order_id';
      selectSql += ',orders.order_row';
      selectSql += ',MIN(orders.is_deleted) is_deleted';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      /* WHERE句 */
      let where_clause = '';
      // 営業所コード
      if (this.searchConditions.selectSalesOffice != 0) {
        where_clause += 'AND orders.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      }
      // 発注番号
      if (this.searchConditions.orderId != '') {
        where_clause += 'AND orders.order_id = ' + this.searchConditions.orderId + ' ';
      }
      // 発注日付
      if (this.searchConditions.orderDateStart != '' && this.searchConditions.orderDateEnd == '') {
        where_clause += 'AND orders.order_date >= ' + '\'' + this.searchConditions.orderDateStart + '\' ';
      } else if (this.searchConditions.orderDateStart == '' && this.searchConditions.orderDateEnd != '') {
        where_clause += 'AND orders.order_date <= ' + '\'' + this.searchConditions.orderDateEnd + '\' ';
      } else if (this.searchConditions.orderDateStart != '' && this.searchConditions.orderDateEnd != '') {
        where_clause += 'AND orders.order_date BETWEEN ' + '\'' + this.searchConditions.orderDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.orderDateEnd + '\' ';
      }
      // 発注種別
      if (this.searchConditions.selectOrderClass != 0) {
        // 発注種別が「全て」以外
        where_clause += 'AND orders.order_class = ' + this.searchConditions.selectOrderClass + ' ';
      }
      // 取引先コード
      if (this.searchConditions.clientCode != '') {
        where_clause += 'AND orders.client_class = ' + Const.ClientClass.supplier + ' ';
        where_clause += 'AND orders.client_id = ' + this.searchConditions.clientCode + ' ';
      }
      // 担当者コード
      if (!(this.searchConditions.personInCharge == '')) {
        where_clause += 'AND orders.staff_id = ' + this.searchConditions.personInCharge + ' ';
      }
      // 製品コード
      if (this.searchConditions.productCode != '') {
        where_clause += 'AND orders.product_id LIKE \'' + this.searchConditions.productCode + '%\' ';
      }
      if (where_clause != '') {
        where_clause = 'WHERE ' + where_clause.substr(4);
        selectSql += where_clause;
      }
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' orders.order_id';

      selectSql += ')';

      return selectSql;
    },
    /* 新規登録ボタン押下時 */
    async clickInputBtn() {
      //console.log('新規登録');
      this.$router.push({ name: 'ORDER-INPUT', query: { propOrderId: 0 } });
    },
    /* 照会ボタン押下時 */
    async clickDetailBtn(orderId) {
      //console.log('照会');
      //console.log(orderId);
      let route = this.$router.resolve({ name: 'ORDER-INPUT-INQUIRY', query: { orderId: orderId, parentKbn: 0 } });
      //console.log(this.$router);
      window.open(route.href, '_blank');
    },
    // 発注編集ボタン押下時
    clickOrderEdit: function(orderId) {
      // 発注修正画面へ遷移
      let route = this.$router.resolve({ name: 'ORDER-EDIT', query: { orderId: orderId } });
      window.open(route.href, '_blank');
    },
    /* コピー発注ボタン押下時 */
    async clickOrderCopy(orderId) {
      //console.log('コピー発注');
      // 発注入力画面へ遷移
      //console.log(orderId);
      let route = this.$router.resolve({ name: 'ORDER-INPUT', query: { propOrderId: orderId } });
      window.open(route.href, '_blank');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    // 発注削除ボタン押下時
    async clickOrderDelete(orderId) {
      //console.log('発注削除');
      this.delOrderId = orderId;
      this.confirmMessage = [];
      this.confirmMessage.push('選択された発注データを削除します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    // 確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if(typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          // 発注削除
          await this.execOrderDelete();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 発注削除実行
    async execOrderDelete() {
      const functionName = 'execOrderDelete';
      if (await this.prepareOrderDelete() == false) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2046'].replace('%arg1%','発注').replace('%arg2%','削除'));
        return;
      }
      // 実行するSQL保持
      let sqlList = [];
      /* 在庫マスタ更新 */
      this.updateStocksMaster(sqlList);
      /* 発注データ更新 */
      // 削除フラグを立てる
      let updateSql = '';
      let colList = [];
      // 削除済フラグ
      colList.push(CreateColRow('is_deleted', 1, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      updateSql += CreateUpdateSql(colList, 't_orders');
      updateSql += ' WHERE ';
      updateSql += 'order_id = ' + this.delOrderId + ' ';
      updateSql += 'AND is_deleted = 0 ';
      //console.log(updateSql);
      sqlList.push({sql: updateSql, forUpdateFlg: 0});

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      // 月次更新後チェック
      if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
        return;
      }
      try {
        await executeSqlList(sqlList);
        // 発注一覧画面へ遷移
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } catch(error) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    // 在庫マスタ更新SQL作成
    updateStocksMaster: function(sqlList) {
      //console.log('在庫マスタ更新SQL作成');
      let updateSql = '';
      let colList = [];
      // 入荷予定数
      colList.push(CreateColRow('stocks.quantity_stock', 'stocks.quantity_stock - orders_QUERY.remaining_quantity', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' orders.office_id';
      updateQuery += ',orders.product_id';
      updateQuery += ',SUM(orders.remaining_quantity) AS remaining_quantity';
      updateQuery += ' FROM ';
      updateQuery += 't_orders AS orders ';
      updateQuery += ' WHERE ';
      updateQuery += 'orders.order_id = ' + this.delOrderId + ' ';
      updateQuery += 'AND orders.order_issue_class > 0 ';
      updateQuery += 'AND orders.is_deleted = 0 ';
      updateQuery += 'AND orders.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
      updateQuery += 'GROUP BY orders.office_id,orders.product_id ';
      updateQuery += ') AS orders_QUERY';
      updateSql += CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      updateSql += 'AND stocks.office_id = orders_QUERY.office_id ';
      updateSql += 'AND stocks.product_id = orders_QUERY.product_id ';
      //console.log(updateSql);
      sqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    // 発注削除準備
    async prepareOrderDelete() {
      // 各種データ取得（非同期でまとめて取得した方が早いため）
      let checkOrderDel = false;
      let controlData = null;
      [checkOrderDel, controlData] = await Promise.all([
        this.checkOrderDelete(),
        getControlMaster(),
      ]);
      this.controlMasterData.processMonthYear = controlData.process_month_year;

      return checkOrderDel;
    },
    // 削除可能チェック
    async checkOrderDelete() {
      let selectSql = '';
      // SELECT句
      selectSql = 'SELECT ';
      selectSql += ' orders.order_receive_id';
      selectSql += ',CASE WHEN stocks.order_id IS NULL THEN 0 ELSE 1 END AS stocks_input_class';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      selectSql += 'LEFT JOIN t_stocks AS stocks ';
      selectSql += 'ON orders.order_id = stocks.order_id ';
      selectSql += 'AND orders.order_row = stocks.order_row ';
      // WHERE句
      selectSql += ' WHERE ';
      // 引数の発注番号で絞り込む
      selectSql += ' orders.order_id = ' + this.delOrderId + ' ';
      // 削除済フラグ
      selectSql += 'AND orders.is_deleted = 0 ';
      // GROUP BY句
      selectSql += 'GROUP BY orders.order_row ';
      // ORDER BY句
      selectSql += 'ORDER BY orders.order_row ';
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      if (resultData != null && resultData.length > 0) {
        for (let i = 0; i < resultData.length; i++) {
          if (resultData[i].order_receive_id != 0 ||
          resultData[i].stocks_input_class == 1) {
            // 他の受注データと紐づいている場合、
            // 入荷済みの行がある場合、
            // 削除不可
            return false;
          }
        }
      } else {
        // 有効な行が存在しない場合、削除不可
        return false;
      }
      return true;
    },
    // 入荷予定編集ボタン押下
    clickStockScheduledEditBtn: function(orderNumber) {
      this.propOrderNumber = orderNumber;
      this.$bvModal.show('stockScheduledEditModal');
    },
    /* コピー仕入入力ボタン押下時 */
    async clickPurchaseCopy(orderNumber, clientId, orderClass) {
      //console.log('コピー仕入入力');
      // 仕入入力画面へ遷移
      //console.log(orderNumber);
      let propDirectFlg;
      if (orderClass == Const.OrderClass.direct) {
        // 直送
        propDirectFlg = 1;
      } else {
        // 直送以外
        propDirectFlg = 0;
      }
      let route = this.$router.resolve({ name: 'PURCHASE-INPUT', query: { propOrderNumber: orderNumber, propClientId: clientId, propDirectFlg: propDirectFlg } });
      window.open(route.href, '_blank');
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
  },
}
</script>
<style scoped>
</style>