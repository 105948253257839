import { render, staticRenderFns } from "./ships-confirm-inquiry.vue?vue&type=template&id=4062c79b&scoped=true&"
import script from "./ships-confirm-inquiry.vue?vue&type=script&lang=js&"
export * from "./ships-confirm-inquiry.vue?vue&type=script&lang=js&"
import style0 from "./ships-confirm-inquiry.vue?vue&type=style&index=0&id=4062c79b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4062c79b",
  null
  
)

export default component.exports