<template>
<div>
  <Header :type="menu_type" :title="title" />
  <b-container class="px-4 py-4 min-vh-85" fluid>
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-card>
          <b-card-header v-if="getMessageFlg">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <p>支払前処理で登録されたデータとその該当件数を表示します。</p>
            <p v-if="updateFlg==true">以下の条件の支払締更新は実施済みです。</p>
            <b-container>
              <b-row>
                <b-col lg="6">
                  <b-table
                    show-empty
                    empty-text="データが登録されていません。支払前処理画面で登録できます。"
                    :fields="fields"
                    :items="results"
                  >
                  </b-table>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
          <!-- 削除ボタン -->
          <b-card-footer>
            <b-row class="justify-content-md-center pb-4">
              <b-col lg="2">
                <b-button pill block variant="danger" v-b-tooltip.hover title="表示中の支払マスタデータを全削除します。" @click="clearAlert(); clickDeleteBtn();" :disabled="results.length==0">
                  <span class="oi oi-delete"></span> 削除
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <Footer />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="['表示中の支払マスタデータを削除します。','よろしいですか？']" />
</div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, formatDate, formatDateCalc, addOperationLogs, executeTransactSqlList, executeSelectSql, isSystemEditable, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'payment-check';

export default ({
  name: 'PAYMENT-CHECK',
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  data() {
    return{
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '支払マスタ状況チェック',
      // アラート
      alertSuccess: [],
      alertDanger: [],
      // 検索結果
      results: [],
      updateFlg: false,
      // トランザクションSQLリスト
      transactSqlList: [],
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /** フィールド */
    fields() {
      return [
        {
          key    : 'invoiceCount',
          label  : '件数',
          tdClass: 'text-right',
        },
        {
          key     : 'closingDate',
          label   : '締日',
        },
        {
          key     : 'deadlineDate',
          label   : '締切日',
        },
      ];
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 初期データを設定
        await this.setInitData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      // 最初に初期化
      this.updateFlg = false;
      this.results = [];
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if(dataResult != null) {
        await this.setResult(dataResult);
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' (SELECT COUNT(*) FROM m_payments) AS payments_cnt';
      selectSql += ',closing_update_range.process_month_year';
      selectSql += ',closing_update_range.closing_date';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_closing_update_range AS closing_update_range ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'closing_update_range.billing_payment_class = ' + Const.BillingPaymentClass.payment + ' ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        let deadlineDate = null;
        if (result[0].closing_date == 99) {
          deadlineDate = formatDateCalc(('000000' + result[0].process_month_year).slice(-6) + '01', 0, 0, 0, true);
        } else {
          deadlineDate = formatDate(('000000' + result[0].process_month_year).slice(-6) + ('00' + result[0].closing_date).slice(-2));
        }
        let searchResult = {
          invoiceCount: result[0].payments_cnt.toLocaleString(),
          closingDate: result[0].closing_date,
          deadlineDate: deadlineDate,
        };
        this.results.push(searchResult);
        // 更新済みかどうかの値を設定
        this.updateFlg = await this.checkUpdateCompletion(result[0].process_month_year, result[0].closing_date);
      }
    },
    // 更新完了チェック
    async checkUpdateCompletion(processMonthYear, closingDate) {
      let where_clause = '';
      where_clause += 'AND billing_payment_class = ' + Const.BillingPaymentClass.payment + ' ';
      where_clause += 'AND process_month_year = ' + processMonthYear + ' ';
      where_clause += 'AND closing_date = ' + closingDate + ' ';
      let resultData = await selectOneTable('t_closing_update_completion', where_clause);
      //console.log(resultData);
      if (resultData == null || resultData.length == 0) {
        // 指定した年月と締日でまだ締更新を行っていない
        return false;
      } else {
        // 指定した年月と締日で締更新実行済み
        return true;
      }
    },
    /* 削除ボタン押下時 */
    async clickDeleteBtn() {
      //console.log('削除');
      this.$bvModal.show('confirmModal');
    },
    // 削除確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if(typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.execDeleteBtn();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 削除実行 */
    async execDeleteBtn() {
      const functionName = 'execDeleteBtn';
      // 最初に初期化
      this.transactSqlList = [];

      // 支払マスタを削除
      let deleteSql = 'DELETE FROM m_payments';
      //console.log(deleteSql);
      this.transactSqlList.push(deleteSql);

      // 締更新範囲を削除
      deleteSql = 'DELETE FROM t_closing_update_range WHERE billing_payment_class = ' + Const.BillingPaymentClass.payment;
      //console.log(deleteSql);
      this.transactSqlList.push(deleteSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable(1);
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let retResult = await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.setInitData();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertDanger = [];
    },
  },
});
</script>
<style scoped>
</style>