<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <div class="container-fluid px-4 py-4 min-vh-85">
    <div class="row d-flex justify-content-center mt-2 mb-2">
      <div class="col-md-12">
        <div class="media">
          <div class="media-body pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 担当者保守入力</strong></h5>
              <router-link to="/staff-list" class="btn btn-cancel m-0"><span class="text-white oi oi-circle-x"></span> キャンセル</router-link>
            </div>
          </div>
        </div>
        <div class="main-card mb-3 card">
          <div class="card-header">
            <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
              <ul v-for="(message,index) in successMessages" :key="index" style="list-style: none;">
                <li>{{message}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="warningMessages.length">
              <ul v-for="(message,index) in warningMessages" :key="index" style="list-style: none;">
                <li>{{message}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="errorMessages.length">
              <ul v-for="(message,index) in errorMessages" :key="index" style="list-style: none;">
                <li>{{message}}</li>
              </ul>
            </b-alert>
          </div>
          <div class="card-body">
            <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
            <validation-observer ref="observer">
              <form action="" method="post" enctype="multipart/form-data" class="form-horizontal">
                <div class="row mt-2">
                  <!-- 担当者コード -->
                  <div class="col col-6 form-group">
                    <validation-provider name="staffId" rules="required|numeric|max:4" v-slot="{ classes,errors }">
                      <div class=""><label for="staffId" class="form-control-label"><strong>担当者コード</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="staffId" class="form-control" v-model="staff.id" maxlength="4">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 営業所コードプルダウン -->
                  <div class="col col-6 form-group">
                    <label for="select1" class=" form-control-label"><strong>営業所</strong></label>
                    <select id="select1" class="form-control" v-model="selectOffice">
                      <option v-for="offices in office" :value="offices" :key="offices.id">
                        {{ offices.id + '：' + offices.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 担当者名（漢字） -->
                  <div class="col col-6 form-group">
                    <validation-provider name="name_kanji" rules="required|max:20" v-slot="{ classes,errors }">
                      <div class=""><label for="name_kanji" class="form-control-label"><strong>担当者名（漢字）</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="name_kanji" class="form-control" v-model="staff.name_kanji">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 担当者名（カナ） -->
                  <div class="col col-6 form-group">
                    <validation-provider name="name_kana" rules="required|max:20" v-slot="{ classes,errors }">
                      <div class=""><label for="name_kana" class="form-control-label"><strong>担当者名（カナ）</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="name_kana" class="form-control" v-model="staff.name_kana">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 担当者名（印鑑） -->
                  <div class="col col-6 form-group">
                    <validation-provider name="name_stamp" rules="required|max:5" v-slot="{ classes,errors }">
                      <div class=""><label for="name_stamp" class="form-control-label"><strong>担当者名（印鑑）</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="name_stamp" class="form-control" v-model="staff.name_stamp">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- ログインID -->
                  <div class="col col-6 form-group">
                    <validation-provider name="login_id" rules="required|min:8|max:128|login_id" v-slot="{ classes,errors }">
                      <div class=""><label for="login_id" class="form-control-label"><strong>ログインID</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="login_id" class="form-control" v-model="staff.login_id">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- メールアドレス -->
                  <div class="col-6 form-group">
                    <validation-provider name="mail_address" rules="required|max:255|email" v-slot="{ classes,errors }">
                      <div class=""><label for="mail_address" class="form-control-label"><strong>メールアドレス</strong></label></div>
                      <div :class="classes">
                        <input type="text" id="mail_address" class="form-control" v-model="staff.mail_address">
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
              </form>
            </validation-observer>
          </div>
          <!-- 保存ボタン -->
          <div class="card-footer">
            <div class="row justify-content-md-center pb-4">
              <div class="col-lg-2">
                <button type="button" class="btn btn-primary btn-block" @click="saveStaff"><span class="oi oi-circle-check"></span> 保存</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import CONFIRM from '@/components/modal/confirm.vue';
import { init, addOperationLogs, CreateColRow, escapeQuote, CreateInsertSql, getUserCol, isSystemEditable, executeTransactSqlList, selectOneTable } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { createCognitoUser, deleteCognitoUser } from '@/assets/aws/cognito';
import { sendEmail } from '@/assets/aws/ses';
import { LOGIN_URL, REGISTER_COMPLETE_FROM_ADDRESS, REGISTER_COMPLETE_MAIL_SUBJECT } from '@/assets/js/const';

// ログ出力用モジュール名
const MODULE_NAME = 'staff-input';

export default {
  name: 'STAFF-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '担当者保守入力',
      // 担当者
      staff:{
        id: '',
        office_id: '',
        name_kanji: '',
        name_kana: '',
        name_stamp: '',
        login_id: '',
        mail_address: ''
      },
      // 営業所プルダウン
      selectOffice:{
        id: null,
        name: null
      },
      // ユーザ名
      username: this.$store.getters.user.username,
      office: [],
      // テーブル定義
      fields:DataTblDef.staff_input_fields,
      // メッセージ
      successMessages:[],
      warningMessages:[],
      errorMessages:[],
      // 確認ダイアログ用
      confirmMessage: [],
      // トランザクションSQLリスト
      transactSqlList: [],
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods:{
    async fetchData(){
      // 営業所データ取得
      let officeListData = await selectOneTable('m_offices');
      //console.log(officeListData);
      for(let i = 0; i < officeListData.length; i++){
        let temp_office = {
          id: officeListData[i].office_id,
          name: officeListData[i].office_name_kanji
        };
        this.office.push(temp_office);
      }
      // 営業所データ初期値セット
      this.selectOffice = this.office[0];
    },
    /* 保存ボタンの押下 */
    async saveStaff(){
      // 保存処理
      //console.log('保存処理');
      this.successMessages = [];
      this.warningMessages = [];
      this.errorMessages = [];
      await this.dataCheck();
      //console.log(this.warningMessages);

      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();
      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if(this.warningMessages.length == 0 && success){
        await this.saveConfirm()
        if (this.warningMessages.length === 0 && this.errorMessages === 0) {
          this.successMessages.push(DISP_MESSAGES.SUCCESS['1001']);
        }
      }
      this.$store.commit('setLoading', false);
      scrollTo(0, 0);
    },
    
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          //console.log('登録処理開始');
          this.$store.commit('setLoading', true);
          await this.execInsert();
          this.$store.commit('setLoading', false);
          //console.log('登録処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 登録処理 */
    async execInsert(){
      const functionName = 'execInsert';

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          //console.log(msg);
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }

      try {
        // 担当者データの登録
        await this.insertStaff();
        // 担当者実績データの登録
        await this.execInsertStaffResult();
        // 作成した登録用SQLを全実行
        if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
          this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
          return
        }
        // サインアップ処理
        await this.signUp();
      } catch (error) {
        console.log(error);
        // 異常系操作ログの登録
        let param = {};
        addOperationLogs( 'Error', 'staff-input', 'execInsert', param, error );
      }
    },
    /* 担当者データ登録処理 */
    async insertStaff(){
      const colList = [];
      // 担当者コード
      colList.push(CreateColRow('staff_id', Number(this.staff.id), 'INT'));
      // 担当者名（漢字）
      colList.push(CreateColRow('staff_name_kanji', await escapeQuote(this.staff.name_kanji), 'VARCHAR'));
      // 担当者名（カナ）
      colList.push(CreateColRow('staff_name_kana', await escapeQuote(this.staff.name_kana), 'VARCHAR'));
      // 担当者名（印鑑）
      colList.push(CreateColRow('staff_name_stamp', await escapeQuote(this.staff.name_stamp), 'VARCHAR'));
      // ログインID
      colList.push(CreateColRow('login_id', this.staff.login_id, 'VARCHAR'));
      // メールアドレス
      colList.push(CreateColRow('mail_address', await escapeQuote(this.staff.mail_address), 'VARCHAR'));
      // 営業所コード
      colList.push(CreateColRow('office_id', Number(this.selectOffice.id), 'INT'));
      // 削除済フラグ
      colList.push(CreateColRow('is_deleted', 0, 'INT'));
      
      // 新規ユーザー
      const colUser = await getUserCol(this.username, 'both')

      const sql = CreateInsertSql(colList.concat(colUser), 'full', 'm_staffs');
      //console.log(sql);
      this.transactSqlList.push(sql)
    },
    /* 担当者実績データ登録処理 */
    async execInsertStaffResult(){
      const colList = [];
      // 担当者コード
      colList.push(CreateColRow('staff_id', Number(this.staff.id), 'INT'));
      // 売上額
      colList.push(CreateColRow('sales', 0, 'INT'));
      // 売上返品額
      colList.push(CreateColRow('returned', 0, 'INT'));
      // 売上値引額
      colList.push(CreateColRow('discount', 0, 'INT'));
      // 純売上額
      colList.push(CreateColRow('net_sales', 0, 'INT'));
      // 粗利額
      colList.push(CreateColRow('gross_profit', 0, 'INT'));
      // 翌月・当期各項目
      ['sales', 'returned', 'discount', 'net_sales', 'gross_profit'].forEach(key => {
        const keyCurrentTerm = `current_term_${key}`
        const keyNextMonth = `next_month_${key}`
        colList.push(CreateColRow(keyNextMonth, 0, 'INT'));
        colList.push(CreateColRow(keyCurrentTerm, 0, 'INT'));
      });
      for (let index = 1; index <= 12; index++) {
        //  純売上高
        colList.push(CreateColRow(`net_sales_${index}`, 0, 'INT'));
      }
      
      // 新規ユーザー
      const colUser = await getUserCol(this.username, 'both')

      const sql = CreateInsertSql(colList.concat(colUser), 'full', 't_staffs_results');
      //console.log(sql);
      this.transactSqlList.push(sql)
    },
    /* 入力データのチェック */
    async dataCheck(){
      // ログインIDシングルクオーテーションチェック
      if(await this.singleQuoteCheck(this.staff.login_id)) {
        this.warningMessages.push(DISP_MESSAGES.WARNING['2030'].replace('%arg1%','ログインID'));
        return;
      }
      // ログインIDダブルクオーテーションチェック
      if(await this.doubleQuoteCheck(this.staff.login_id)) {
        this.warningMessages.push(DISP_MESSAGES.WARNING['2031'].replace('%arg1%','ログインID'));
        return;
      }
      // 担当者コード登録済みチェック ※入力時のみ
      if(this.staff.id){
        if(await this.registeredStaffIdCheck()){
          this.warningMessages.push('登録済みの担当者コードです。');
        }
      }
      // ログインID登録済みチェック ※入力時のみ
      if(this.staff.login_id){
        if(await this.registeredLoginIdCheck()){
          this.warningMessages.push('登録済みのログインIDです。');
        }
      }
    },
    /* シングルクオートチェック */
    singleQuoteCheck(loginID) {
      //console.log('singleQuoteCheck');
      //console.log('singleQuote length', (loginID.match(/'/g)|| []).length);
      return (loginID.match(/'/g)|| []).length > 0;
    },
    doubleQuoteCheck(loginID) {
      //console.log('doubleQuoteCheck');
      //console.log('doubleQuote length', (loginID.match(/"/g)|| []).length);
      return (loginID.match(/"/g)|| []).length > 0;
    },
    /* 担当者コードの登録済みチェック */
    async registeredStaffIdCheck(){
      this.staffList = [];
      // CRUD処理
      let where_clause = '';
      where_clause = `AND staff_id = ${Number(this.staff.id)}`;
      //console.log(where_clause);
      try {
        //console.log('条件取得');
        let resultData = await selectOneTable('m_staffs', where_clause);
        if(resultData.length === 0){
          //console.log(resultData);
          return false;
        }
        //console.log(resultData);
        return true;
      } catch (error) {
        this.warningMessages.push(DISP_MESSAGES.WARNING['2001']);
        console.error(error);
      }
    },
    /* ログインIDの登録済みチェック */
    async registeredLoginIdCheck(){
      this.staffList = [];
      // CRUD処理
      let where_clause = '';
      where_clause = `AND login_id = '${this.staff.login_id}'`;
      //console.log(where_clause);
      try {
        //console.log('条件取得');
        let resultData = await selectOneTable('m_staffs', where_clause);
        if(resultData.length === 0){
          //console.log(resultData);
          return false;
        }else{
          //console.log(resultData);
          return true;
        }
      } catch (error) {
        this.warningMessages.push(DISP_MESSAGES.WARNING['2001']);
        console.error(error);
      }
    },
    async signUp() {
      try {
        let userPass = this.getPassword(8);

        await createCognitoUser(this.staff.login_id, this.staff.mail_address, userPass);

        const mailText = this.getMailText(userPass);

        await this.sendMail(mailText);

        // ユーザー作成ログの登録
        let param = {
          Username: this.staff.login_id,
          Email: this.staff.mail_address,
        };
        addOperationLogs('Info', MODULE_NAME, 'signUp', param);
        // 担当者修正画面へ遷移
        this.$router.push({ 
          name: 'STAFF-EDIT', 
          query: { staffId: this.staff.id, parentKbn: 1 } });
      } catch (e) {
        this.successMessages = [];
        switch (e?.code) {
        case 'UsernameExistsException':
          this.warningMessages.push('登録済みのログインIDです。');
          break;
        default:
          // その他のエラー
          this.warningMessages.push(DISP_MESSAGES.DANGER['3005']);
        }
        console.log('error cognito signup ' + this.staff.login_id + ':' + e.message);
        // 異常系操作ログの登録
        let param = {};
        addOperationLogs('Error', MODULE_NAME, 'signUp', param, e);
        await this.deleteUser();
      }
    },
    getPassword(length) {
      let letters = 'abcdefghijklmnopqrstuvwxyz';
      let numbers = '0123456789';
      let symbol_chars = '!#$%&()*+,.:;=?@[\\]^_{}-';
      let string = letters + letters.toUpperCase() + numbers + symbol_chars;

      // 先頭4文字に数・英小・英大・記号をつけて、残りはランダム文字列
      let pass = '';
      pass += letters.charAt(Math.floor(Math.random() * letters.length));
      pass += letters.toUpperCase().charAt(Math.floor(Math.random() * letters.toUpperCase().length));
      pass += numbers.charAt(Math.floor(Math.random() * numbers.length));
      pass += symbol_chars.charAt(Math.floor(Math.random() * symbol_chars.length));
      for (let i = 0; i < length-4; i++) {
        pass += string.charAt(Math.floor(Math.random() * string.length));
      }
      return pass;
    },
    getMailText(userPass) {
      let emailText = '';
      emailText += this.staff.name_kanji + 'さん\n';
      emailText += '\n';
      emailText += '販売管理システムのアカウント登録が完了しました。\n';
      emailText += 'ログイン情報は下記のとおりです。\n';
      emailText += 'ログインURL：' + LOGIN_URL + '\n';
      emailText += '初期パスワード: ' + userPass + '\n';
      emailText += '\n';
      emailText += '初回ログイン時にパスワードの変更が必要となります。\n';
      emailText += '数字、アルファベットの大文字・小文字、記号をそれぞれ1文字以上使用し、\n';
      emailText += '8文字以上のパスワードを設定して下さい。\n';
      emailText += '\n';
      emailText += '以上です。\n';
      emailText += 'どうぞよろしくお願いいたします。\n';
      emailText += '\n';
      emailText += '\n';
      emailText += '※本メールは販売管理システムから自動送信されています';
      return emailText;
    },
    async sendMail(message) {
      // console.log('sendMail called.');
      // メール送信処理
      try {
        const mailInfo = {
          ToAddresses: [this.staff.mail_address],
          fromAddress: REGISTER_COMPLETE_FROM_ADDRESS,
          message: message,
          subject: REGISTER_COMPLETE_MAIL_SUBJECT,
        }
        await sendEmail(mailInfo);
      } catch (error) {
        this.warningMessages.push(DISP_MESSAGES.DANGER['3005']);
        console.log('error send mail ' + error.message);
        // 異常系操作ログの登録
        let param = {};
        addOperationLogs('Error', 'staff-input', 'sendMail', param, error);

        try {
          // ユーザー削除実行
          await deleteCognitoUser(this.staff.login_id);
          // console.log('cognito削除戻り値');

          await this.deleteUser();
        } catch (error) {
          console.log('cognito error deleting user ' + this.staff.login_id + ':' + error.message);
          // 異常系操作ログの登録
          let param = {};
          addOperationLogs('Error', 'staff-input', 'sendMail', param, error);
        }
      }
    },
    /* 担当者データ削除 */
    async deleteUser(){
      let sqlList = [
        'DELETE FROM m_staffs WHERE staff_id = ' + this.staff.id,
        'DELETE FROM t_staffs_results WHERE staff_id = ' + this.staff.id,
      ];
      //console.info(sqlList[0]);
      // 引数のプロパティは「SQLs」
      let condition = { SQLs: sqlList };
      //console.info({condition});
      try {
        // executeTransactSqlで実行
        let result = await API.graphql(graphqlOperation(executeTransactSql, condition));
        // レスポンスデータを取得できた際の処理
        if(result) {
          // result.data.executeTransactSql.bodyにJSON形式で結果が返ってくる
          const responseBody = JSON.parse(result.data.executeTransactSql.body);

          // SQL実行でエラーが発生した場合の処理
          if(result.data.executeTransactSql.statusCode > 200) {
            this.successMessages = [];
            this.warningMessages.push(DISP_MESSAGES.WARNING['2001']);
            console.info({responseBody});
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({message});
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({errorMessage});
          } else {
            // SQLが正常に終了した際の処理
            this.login_id_before = this.selectOffice.id;
            /*
            if (responseBody.data) {
              // SELECT文の結果はresponseBody.dataとして返却される
              // 複数SELECT文を投げた場合responseBody.data[0]、responseBody.data[1]と配列で返却される
              for (const rows of responseBody.data) {
                console.dir(rows, {depth: null});
              }
            }
            */
          }
          //console.info(JSON.parse(result.data.executeTransactSql.body));
        } else {
          // レスポンスデータを取得できなかった際の処理
        }
      } catch (error) {
        this.successMessages = [];
        this.warningMessages.push(DISP_MESSAGES.WARNING['2001']);
        console.error(error);
      }
    },
  }
}
</script>
<style scoped>
</style>