<template>
  <!-- 納品指定区分保守照会画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 納品指定区分保守照会</strong></h5>
                <router-link to="/deliverys-designs-classes-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div v-if="getMessageFlg" class="card-header">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <!-- 納品指定区分 -->
                <div class="col-12 form-group">
                  <label for="delivery_design_class" class="form-label">納品指定区分： {{obj.delivery_design_class}}</label>
                </div>
              </div>
              <div class="row">
                <!-- 納品指定区分 -->
                <div class="col-12 form-group">
                  <label for="delivery_design_name" class="form-label">納品指定区分名： {{obj.delivery_design_name}}</label>
                </div>
              </div>
            </div>
            <!-- 削除・編集ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                  <button type="button" class="btn btn-cancel btn-block" @click="clickDeleteBtn"><span class="oi oi-circle-x"></span> 削除</button>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                  <button type="button" class="btn btn-primary btn-block" @click="clickEditBtn"><span class="oi oi-circle-check"></span> 編集</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, init, isSystemEditable, selectOneTable } from '@/assets/js/common.js';

import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'deliverys-designs-classes-inquiry';

export default {
  name: 'DELIVERYS-DESIGNS-CLASSES-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '納品指定区分保守照会',
      // ユーザ名
      username: this.$store.getters.user.username,
      // 納品指定区分対象
      obj: {},
      // 処理結果成功メッセージ
      successMessages: [],
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.errorMessages.length > 0 || this.successMessages.length > 0
    },
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    // 新規か編集画面から成功メッセージ
    this.successMessages = this.$route.params.successMessages || []
    await this.fetchData(this.$route.query);
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     */
    async fetchData({delivery_design_class}) {
      const functionName = 'fetchData'
      this.busy = true;
      try {
        // 納品指定区分マスタのデータを請求
        const objResult = await this.searchDeliverysDesignsClasses(delivery_design_class)
  
        // データセット 
        this.obj = {...objResult}
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']); 
      }
      this.busy = false;
    },
    /**
     * 納品指定区分マスタを検索します。
     * @param {Int} delivery_design_class
     * @returns {Object} 納品指定区分マスタデータ
     */
    async searchDeliverysDesignsClasses(delivery_design_class) {
      const functionName = 'searchDeliverysDesignsClasses';

      let result = null;
      let where_clause = `AND delivery_design_class = '${delivery_design_class}'`
      
      try {
        result = await selectOneTable('m_deliverys_designs_classes', where_clause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_deliverys_designs_classes',
          where_clause: where_clause
        }, error);
        return null;
      }
      if(result == null || result.length == 0) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return
      }
      return result[0];
    },
    /**
     * 編集ボタンの押下
     */
    async clickEditBtn() {
      this.$router.push({ 
        name: 'DELIVERYS-DESIGNS-CLASSES-EDIT', 
        query: { 
          delivery_design_class: this.obj.delivery_design_class,
        },
      });
    },
    /**
     * 削除ボタン押下時
     */
    clickDeleteBtn() {
      this.$bvModal.msgBoxConfirm('納品指定区分データを削除します。\nよろしいですか？').then(async value => {
        if (value) {
          this.$store.commit('setLoading', true);
          this.successMessages = [];
          this.errorMessages = [];
          await this.execDel()
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'DELIVERYS-DESIGNS-CLASSES-MAINTENANCE',
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1002']] }  
            });
          }
          this.$store.commit('setLoading', false);
        }
      });
    },
    /**
     * 削除処理
     */
    async execDel() {
      const functionName = 'execDel';

      // 納品指定区分マスタ 
      const SQLs = `DELETE FROM m_deliverys_designs_classes 
      WHERE delivery_design_class = '${this.obj.delivery_design_class}'`

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
  }
}
</script>
