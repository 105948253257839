<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container class="px-4 py-4 min-vh-85">
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 単価自動コピー（個別）</strong></h5>
            </div>
          </b-media-body>
        </b-media>
        <b-card class="main-card mb-3">
          <b-card-header v-if="getMessageFlg">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[実行]ボタンを押してください。</p>
            <validation-observer ref="observer">
              <b-form @submit.prevent="clearAlert(); saveData();" id="InputForm" class="form-horizontal">
                <b-row>
                  <!-- 取引先コード（子） -->
                  <b-col lg="12">
                    <validation-provider name="suppliersCode" rules="required|numeric|min:6" v-slot="{ classes,errors }">
                      <b-form-group
                        label="取引先コード（子）"
                        label-for="suppliersCode"
                      >
                        <b-row>
                          <b-col lg="6" :class="classes">
                            <b-input-group>
                              <b-form-input 
                                type="text" 
                                id="suppliersCode" 
                                name="suppliersCode" 
                                v-model="suppliers.code" 
                                @input="searchClient(suppliers.code)" 
                                maxlength="6"
                              />
                              <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                              <b-button class="mr-1" size="sm" pill variant="success" title="取引先コード（子）に入力された取引先の情報を照会します。（取引先コードが空欄以外の場合、利用可能です。）" :disabled="suppliers.code == null" @click="clientInfoModal(suppliers.class, suppliers.code)">
                                <span class="oi oi-eye"></span> 情報
                              </b-button>
                            </b-input-group>
                          </b-col>
                          <b-col lg="7" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">検索ボタンから選択してください<br>情報ボタンは入力されたコードに紐づく詳細情報を表示します</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 取引先名（子） -->
                  <b-col lg="12">
                    <b-form-group
                      label="取引先名（子）"
                      label-for="suppliersName"
                    >
                      <b-row>
                        <b-col lg="10">
                          <b-form-input type="text" id="suppliersName" v-model="suppliers.name" readonly />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- 実行ボタン -->
                <b-row class="justify-content-md-center pb-4">
                  <b-col lg="2">
                    <b-button  class="mr-2" pill block variant="success" title="入力されたデータを保存します。" type="submit" form="InputForm">
                      <span class="oi oi-circle-check"></span> 実行
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
          <template #footer>
            <!-- 説明文 -->
            <small class="form-text text-muted mt-3">※指定された取引先（子）の単価情報は取引先（親）の単価情報で上書きされます。注意してください。</small>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●取引先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="htmlConst.ClientClass.customer"/>
  <!-- ●●●取引先照会モーダル●●● -->
  <CLIENTINQUIRY :clientProp="propClientInquiry"/>
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { searchClientModal } from '@/assets/js/searchModal.js';
import { init, addOperationLogs, CreateInsertSql, CreateColRow, executeTransactSqlList, isSystemEditable, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'sales-unit-price-copy';

export default {
  name: 'SALES-UNIT-PRICE-COPY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    CLIENTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '単価自動コピー（個別）',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 取引先系
      suppliers: {
        class: null,
        code: null,
        name: '',
      },
      // 取引先系（親）
      suppliersParent: {
        class: null,
        code: null,
        name: '',
      },
      // ログイン情報
      loginId: '',
      // トランザクションSQLリスト
      transactSqlList: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 確認ダイアログ用
      confirmMessage: [],
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if ( this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 実行ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('選択された取引先は親取引先の情報で上書きされます。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('コピー処理開始');
      await this.execSave();
      //console.log('コピー処理終了');
    },
    /* 保存処理 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      // 単価コピー
      if (await this.copyUnitPrice() == false) {
        return retResult;
      }
      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return retResult;
      }

      this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
      retResult = true;

      return retResult;
    },
    /* 単価コピー処理 */
    async copyUnitPrice() {
      //console.log('子取引先の単価コピー処理');
      let where_clause = '';
      // 取引先区分（子）
      where_clause += 'AND client_class_branch = ' + this.suppliers.class + ' ';
      // 取引先コード（子）
      where_clause += 'AND client_id_branch = \'' + this.suppliers.code + '\'';
      let dataClientsParentChild = await selectOneTable('v_clients_parent_child_01', where_clause);
      //console.log(where_clause);
      //console.log(dataClientsParentChild);
      // 親が取得できた場合
      if (dataClientsParentChild.length > 0) {
        // 親と子が同じ場合はコピーしない（意味がない）
        if (this.suppliers.class != dataClientsParentChild[0].client_class_parent ||
          this.suppliers.code != dataClientsParentChild[0].client_id_parent){
          this.suppliersParent.class = dataClientsParentChild[0].client_class_parent;
          this.suppliersParent.code = dataClientsParentChild[0].client_id_parent;
          this.suppliersParent.name = dataClientsParentChild[0].client_name_kanji_parent;
          // 取引先製品マスタのコピー
          await this.copyChildClientsProducts();
        } else {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2005']);
          return false;
        }
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2006']);
        return false;
      }
      return true;
    },
    /* 取引先製品子取引先にコピー */
    async copyChildClientsProducts() {
      // 最初にdelete（指定された子取引先の全単価情報を削除）
      let delSql = 'DELETE FROM m_clients_products WHERE ';
      delSql += 'client_class=' + this.suppliers.class + ' ';
      delSql += 'AND client_id=' + this.suppliers.code + ' ';
      //console.log(delSql)
      this.transactSqlList.push(delSql);
      // 親取引先の同製品の単価情報を全適用日分コピーするSQL作成
      let copySql = '';
      let colList = [];
      // 取引先区分
      colList.push(CreateColRow('client_class', null, 'NUMBER'));
      // 取引先コード
      colList.push(CreateColRow('client_id', null, 'NUMBER'));
      // 製品コード
      colList.push(CreateColRow('product_id', null, 'NUMBER'));
      // 単価適用日
      colList.push(CreateColRow('unit_price_effective_date', null, 'DATE'));
      // 売上単価
      colList.push(CreateColRow('sales_unit_price', null, 'NUMBER'));
      // 理由
      colList.push(CreateColRow('reason', null, 'VARCHAR'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      copySql += 'INSERT INTO m_clients_products (' + CreateInsertSql(colList, 'col', 'm_clients_products') + ') ';
      copySql += 'SELECT ';
      copySql += this.suppliers.class;
      copySql += ',' + this.suppliers.code;
      copySql += ',product_id';
      copySql += ',unit_price_effective_date';
      copySql += ',sales_unit_price';
      copySql += ',\'自動\'';
      copySql += ',\'' + this.loginId + '\'';
      copySql += ',\'' + this.loginId + '\'';
      copySql += ' FROM m_clients_products ';
      copySql += ' WHERE ';
      copySql += 'client_class=' + this.suppliersParent.class + ' ';
      copySql += 'AND client_id=' + this.suppliersParent.code + ' ';
      //console.log(copySql)
      this.transactSqlList.push(copySql);
    },
    // 取引先検索
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      const result = await searchClientModal(client_id, this.htmlConst.ClientClass.customer, 'searchClient', MODULE_NAME)
      if (typeof result != 'undefined') {
        this.closeClientSearchModal(result)
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6) {
          // 取引先クリア
          this.closeClientSearchModal({detail:{}})
        }
      }
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal: function(clientItems) {
      //console.log(clientItems);
      // モーダルから渡された値の有無チェック
      if (typeof clientItems != 'undefined') {
        this.suppliers.class = clientItems.detail.client_class;
        this.suppliers.code = clientItems.detail.client_id;
        this.suppliers.name = clientItems.detail.client_name_kanji;
      }
    },
    /* 取引先照会 */
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
  }
}
</script>
<style scoped>
</style>