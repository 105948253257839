<template>
  <div>
    <div v-if="getMessageFlg == true">
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="set in listSet" v-bind:key="set.orderNumber + set.clientId">
      <div v-for="chouhyou in set.listChouhyou" v-bind:key="set.orderNumber + '_' + set.clientId + '_' + chouhyou.page">
        <TemplateOrderNormal :id="constData.chouhyouId + set.orderNumber + '_' + set.clientId + '_' + chouhyou.page" />
      </div>
    </div>
  </div>
</template>
<script>
import TemplateOrderNormal from '@/assets/svg/order_Normal.svg';
import { setPaperA4, setChouhyouBodyStyle, formatDate, executeSelectSql, getFormCounter, addOperationLogs, getNullStr, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';
import { executeTransactSql } from '@/graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

const MODULE_NAME = 'purchase-order-normal-reissue';

export default {
  name: 'PURCHASE-ORDER-NORMAL-REISSUE',
  /* コンポーネント */
  components: {
    TemplateOrderNormal,
  },
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 20,
        chouhyouId: 'idChouhyou',
      },
      // ヘッダ
      menu_type: 'user',
      title: '発注書（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      // ※height:297mmだと2ページ目にはみ出してしまうので微調整
      chouhyouSize: {
        width: '315mm',
        height: '445mm',
      },
      stampFlg: false,
      colStampCX: 0,
      colStampCY: 0,
      addProductCnt: 0,
      listSet: [],
      // 営業所情報
      officeList: [],
      // コントロールマスタの情報
      controlMasterData: null,
      // アラート
      alertDanger: [],

      // URLパラメータ
      urlParams: null,
      // 再発行フラグ
      isReissue: false,
      // ログイン中ユーザー情報
      loginStaffInfo: null,
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertDanger.length > 0;
    },
  },
  /* マウント */
  mounted() {
    this.urlParams = this.$route.query;
    this.isReissue = this.urlParams.orderSlipClass == 2;
    this.loginStaffInfo = this.$store.getters.user;
    // 印刷レイアウト設定
    setPaperA4();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    // 初期設定
    this.fetchData();
    // 印刷ファイルのデフォルト名 $発注書_yyyyMMddHHmmss
    const date = new Date();
    let MM = ('0' + (date.getMonth() + 1)).slice(-2);
    let DD = ('0' + date.getDate()).slice(-2);
    let hh = ('0' + date.getHours()).slice(-2);
    let mm = ('0' + date.getMinutes()).slice(-2);
    let ss = ('0' + date.getSeconds()).slice(-2);
    document.title = `発注書_${date.getFullYear()}${MM}${DD}${hh}${mm}${ss}`;
  },
  /* 関数群 */
  methods:{
    async fetchData(){
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      let sqls = [];
      try {
        // 発注書に出力するデータを取得
        sqls.push(await this.makeSelectSql());
        const resultDatas = await this.executeSqls(sqls, functionName);
        const outputOrderDatas = resultDatas[0];
        //console.log(outputOrderDatas);

        // 出力用データの存在チェック
        if (!(outputOrderDatas != null && outputOrderDatas.length > 0)) {
          this.alertDanger.push('出力対象のデータがありません。');
          scrollTo(0,0);
          this.$store.commit('setLoading', false);
          return;
        }
        /* 前処理 */
        // コントロールマスタ
        await this.getControlMasterData();
        // 営業所情報取得
        await this.getOfficesData();

        /* 帳票作成 */
        // 発注毎の一覧を作成
        await this.initListSet(outputOrderDatas);
        // ページ毎の置換文字列設定
        await this.createReplacementsPage(outputOrderDatas);
        // 作成した置換文字データをSVGファイルに設定
        await this.setChouhyou();

      } catch(error) {
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        this.listSet = [];
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索SELECT文字列作成 */
    async makeSelectSql(){
      //console.log('makeSelectSql ' + JSON.stringify(this.$route.query));
      const query = this.$route.query;
      const whereClauses = [];

      // 営業所
      if (query.salesOffice && query.salesOffice !== '') {
        whereClauses.push(`T_ORDERS.office_id = ${query.salesOffice}`);
      }
      // 発注書回数
      if (query.orderSlipOutoutNum && query.orderSlipOutoutNum !== '') {
        switch (query.orderSlipOutoutNum) {
        case '1':
          whereClauses.push('M_CLIENTS_SUPPLIER.order_count_class IN (0, 1)');
          break;
        case '2':
          whereClauses.push('M_CLIENTS_SUPPLIER.order_count_class IN (0, 2)');
          break;
        }
      }
      // 発注先コード（開始）
      if (query.supplierCodeStart && query.supplierCodeStart !== '') {
        whereClauses.push(`M_CLIENTS_SUPPLIER.client_id >= ${query.supplierCodeStart}`);
      }
      // 発注先コード（終了）
      if (query.supplierCodeEnd && query.supplierCodeEnd !== '') {
        whereClauses.push(`M_CLIENTS_SUPPLIER.client_id <= ${query.supplierCodeEnd}`);
      }
      // 注文番号
      if ((query.orderNumberStart && query.orderNumberStart !== '') || (query.orderNumberEnd && query.orderNumberEnd !== '')) {
        // 再発行時は範囲指定で抽出
        if ((query.orderNumberStart && query.orderNumberStart !== '') && (query.orderNumberEnd && query.orderNumberEnd !== '')) {
          whereClauses.push(`T_ORDERS.order_number BETWEEN ${query.orderNumberStart} AND ${query.orderNumberEnd}`);
        } else if ((query.orderNumberStart && query.orderNumberStart !== '')) {
          whereClauses.push(`T_ORDERS.order_number >= ${query.orderNumberStart}`);
        } else {
          whereClauses.push(`T_ORDERS.order_number <= ${query.orderNumberEnd}`);
        }
      }

      let selectSql = 'SELECT';
      selectSql += ' T_ORDERS.client_id'; // 取引先ID
      selectSql += ',M_CLIENTS_SUPPLIER.client_name_kanji'; // 取引先名
      selectSql += ',T_ORDERS.product_id'; // 製品ID
      selectSql += ',T_ORDERS.product_name'; // 製品名
      selectSql += ',T_ORDERS.order_quantity'; // 数量
      selectSql += ',T_ORDERS.unit'; // 単位
      selectSql += ',T_ORDERS.note'; // 備考
      selectSql += ',T_ORDERS.order_number'; // 注文番号
      selectSql += ',T_ORDERS.order_id'; // 発注ID
      selectSql += ',T_ORDERS.order_row'; // 発注行番号
      selectSql += ',T_ORDERS.order_date'; // 発注日付
      selectSql += ',T_ORDERS.order_amount'; // 発注金額
      selectSql += ',T_ORDERS.site_id'; // 現場コード
      selectSql += ',T_ORDERS.office_id'; // 営業所コード
      selectSql += ',M_PRODUCTS_DETAILS.supplier_product_id'; // 仕入先顧客コード
      selectSql += ',M_STAFFS.staff_name_kanji'; // 担当者名（漢字）
      selectSql += ',M_STAFFS.staff_name_stamp'; // 担当者名（印鑑）
      selectSql += ', M_CLIENTS_SUPPLIER_SITES.client_site_name_kanji AS client_site_name'; // 現場名
      selectSql += ', M_CLIENTS_SUPPLIER_SITES.client_site_address_1'; // 現場住所1
      selectSql += ', M_CLIENTS_SUPPLIER_SITES.client_site_address_2'; // 現場住所2
      selectSql += ', M_CLIENTS_SUPPLIER_SITES.client_site_phone_number'; // 現場TEL
      /* FROM句 */
      selectSql += ' FROM';
      selectSql += ' t_orders AS T_ORDERS';
      selectSql += ' LEFT JOIN'; // 製品マスタ 主キー（product_id）で結合
      selectSql += ' m_products AS M_PRODUCTS';
      selectSql += ' ON';
      selectSql += ' T_ORDERS.product_id = M_PRODUCTS.product_id';
      selectSql += ' LEFT JOIN'; // 担当者マスタ 主キー（staff_id）で結合
      selectSql += ' m_staffs AS M_STAFFS';
      selectSql += ' ON';
      selectSql += ' T_ORDERS.staff_id = M_STAFFS.staff_id'; // 主キー（staff_id）で結合
      selectSql += ' LEFT JOIN'; // 製品発注マスタ 主キー（product_id,office_id）で結合
      selectSql += ' m_products_orders AS M_PRODUCTS_ORDERS';
      selectSql += ' ON';
      selectSql += ' T_ORDERS.product_id = M_PRODUCTS_ORDERS.product_id AND T_ORDERS.office_id = M_PRODUCTS_ORDERS.office_id';
      selectSql += ' LEFT JOIN'; // 取引先マスタ 主キー（client_id,client_class）で結合
      selectSql += ' m_clients AS M_CLIENTS_SUPPLIER';
      selectSql += ' ON';
      selectSql += ' T_ORDERS.client_id = M_CLIENTS_SUPPLIER.client_id AND M_CLIENTS_SUPPLIER.client_class = T_ORDERS.client_class';
      selectSql += ' LEFT JOIN'; // 製品詳細マスタ 主キー（product_id,office_id）で結合
      selectSql += ' m_products_details AS M_PRODUCTS_DETAILS';
      selectSql += ' ON';
      selectSql += ' T_ORDERS.product_id = M_PRODUCTS_DETAILS.product_id AND T_ORDERS.office_id = M_PRODUCTS_DETAILS.office_id';
      selectSql += ' LEFT JOIN'; // 現場マスタ（発注先） 主キー（client_id,site_id）で結合
      selectSql += ' m_clients_sites AS M_CLIENTS_SUPPLIER_SITES';
      selectSql += ' ON  T_ORDERS.client_id = M_CLIENTS_SUPPLIER_SITES.client_id';
      selectSql += ' AND T_ORDERS.site_id = M_CLIENTS_SUPPLIER_SITES.site_id';
      /* WHERE句 */
      selectSql += ' WHERE';
      selectSql += ' T_ORDERS.order_class = 2'; // 発注種別「2:在庫切れ」
      selectSql += ' AND T_ORDERS.is_deleted = 0'; // 削除フラグ「0:未削除」
      selectSql += ` AND ${whereClauses.join(' AND ')}`;
      // 仕入先コード・製品コードでソート
      selectSql += ' ORDER BY T_ORDERS.order_number ASC, T_ORDERS.client_id ASC, T_ORDERS.product_id ASC';
      return selectSql;
    },
    // コントロールマスタ情報取得
    async getControlMasterData() {
      // 現在処理年月取得
      let controlData = await selectOneTable('m_control');
      if (controlData.length > 0) {
        this.controlMasterData = {
          processMonthYear: controlData[0].process_month_year,
          taxRate: controlData[0].tax_rate,
          newTaxRate: controlData[0].new_tax_rate,
          newTaxStartDate: controlData[0].new_tax_start_date,
        };
      }
    },
    /* 営業所情報を取得 */
    async getOfficesData() {
      // 営業所データ取得
      let where_clause = ' AND office_id = ' + this.urlParams.salesOffice + ' ';
      let officeListData = await selectOneTable('m_offices', where_clause);
      for(const officeData of officeListData) {
        this.officeList.push({
          keyNo : 1,
          officeNameKanji : getNullStr(officeData.office_name_kanji),
          zipCode : '〒' + getNullStr(officeData.zip_code),
          address : getNullStr(officeData.address_1) + getNullStr(officeData.address_2),
          phoneNumber : 'TEL ' + getNullStr(officeData.phone_number),
          faxNumber : 'FAX ' + getNullStr(officeData.fax_number),
        });
      }
    },
    /* 仕入先顧客情報を取得 */
    async getSuppliersKokyakuData(supplierId) {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' offices.office_name_kanji';
      selectSql += ',suppliers_offices.supplier_control_office_id';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_suppliers_offices AS suppliers_offices ';
      selectSql += 'INNER JOIN m_offices AS offices ';
      selectSql += 'ON suppliers_offices.office_id = offices.office_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 引数の仕入先コードで絞り込む
      selectSql += 'suppliers_offices.supplier_id = ' + supplierId + ' ';
      // 営業所コードで絞り込む
      selectSql += 'AND suppliers_offices.office_id = ' + this.urlParams.salesOffice + ' ';
      // 営業所コードでソート
      selectSql += 'ORDER BY suppliers_offices.office_id';
      // console.log(selectSql);
      // 仕入先毎の営業所情報取得
      let dataResult = await executeSelectSql(selectSql);
      let suppliersKokyakuId = '';
      for (let i = 0; i < dataResult.length; i++) {
        if (i != 0) {
          suppliersKokyakuId += '、';
        }
        suppliersKokyakuId += getNullStr(dataResult[i].office_name_kanji);
        suppliersKokyakuId += getNullStr(dataResult[i].supplier_control_office_id);
      }

      return suppliersKokyakuId;
    },
    /* 発注データ毎の一覧を作成 */
    initListSet: function(result) {
      //console.log('initListSet');
      let preOrderNumber = 0; // 前行の注文番号
      let preClientId = 0; // 前行の仕入先コード
      let chouhyouRowCnt = 0; // 帳票の必要行数
      // DBの結果分ループ
      for (const selectData of result) {
        // 前行も同じ発注番号
        if (selectData.order_number == preOrderNumber
         && selectData.client_id == preClientId
        ) {
          chouhyouRowCnt++; // 製品行
        } else {
          if (preOrderNumber !== 0) {
            // 初回以外の場合、別の発注番号、仕入先コードに移ったタイミングでまとめて一覧に追加
            this.listSet.push({ orderNumber: preOrderNumber, clientId: preClientId, listChouhyou: this.createListChouhyou(chouhyouRowCnt) });
          }
          // 帳票情報の初期化と次の顧客を記録
          chouhyouRowCnt = 1; // 製品行
          preOrderNumber = selectData.order_number;
          preClientId = selectData.client_id;
        }
      }
      // 初回以外の場合、別の発注番号、仕入先コードに移ったタイミングでまとめて一覧に追加
      //console.log(`chouhyouRowCnt = ${chouhyouRowCnt}`);
      this.listSet.push({ orderNumber: preOrderNumber, clientId: preClientId, listChouhyou: this.createListChouhyou(chouhyouRowCnt) });
    },
    /* 帳票リスト初期化 */
    createListChouhyou: function(productCnt){
      let listChouhyou = [];
      let productCntNokori = productCnt;
      let page = 1;
      while (productCntNokori > 0) {
        // 残りの製品件数が最終帳票の件数に収まらない場合、中間帳票を出力し続ける
        listChouhyou.push({page: page, replacements: []});
        productCntNokori -= this.constData.cntNormal;
        page++;
      }
      //console.log('createListChouhyou');
      return listChouhyou;
    },
    /* 製品の置換配列セット */
    async createReplacementsPage(result) {
      //console.log('createReplacementsPage');
      let productsIndex = 0;
      let whiteFlg = false;
      let curOrderNumber = 0;
      let curClientId = 0;
      let workIndex = 0;
      try {
        for (const printSet of this.listSet) {
          curOrderNumber = printSet.orderNumber;
          curClientId = printSet.clientId;
          whiteFlg = false;
          for (let i = 0; i < printSet.listChouhyou.length; i++) {
            if (productsIndex < result.length && result[productsIndex].order_number == curOrderNumber
            && result[productsIndex].client_id == curClientId) {
              workIndex = productsIndex;
            } else {
              // 製品行のないページ（途中、または、最終ページ）
              workIndex = productsIndex - 1;
            }
            // SVGファイルの置換用文字列
            let replacements = [];
            // 注文番号
            //console.log(result)
            //console.log(workIndex)
            //console.log(result[workIndex])
            replacements.push({key: '%発注番%', value: result[workIndex].order_number, textAnchor: 'end', textLength: 190, chkWidth: true});
            // 営業
            replacements.push({key: '%営業%', value: this.officeList[0].officeNameKanji, textAnchor: 'start', textLength: 160, chkWidth: true});
            // 発注日付
            replacements.push({key: '%発注日付%', value: formatDate(result[workIndex].order_date), textAnchor: 'end', textLength: 251, chkWidth: false});
            // ページ番号
            replacements.push({key: '%P%', value: printSet.listChouhyou[i].page + ' / ' + printSet.listChouhyou.length, textAnchor: 'end', textLength: 178, chkWidth: false});
            // タイトル
            replacements.push({key: '%タイトル%', value: '御 発 注 書' + '（' + this.officeList[0].officeNameKanji + '）', textAnchor: 'middle', textLength: 925, chkWidth: true});
            // 取引先名（発注書印字区分が「1:印字なし」の場合、発注書用取引先名を指定）
            let clientName = result[workIndex].order_print_class === 1 ?
              result[workIndex].order_print_client_name : result[workIndex].client_name_kanji;
            replacements.push({key: '%取引先名%', value: clientName, textAnchor: 'start', textLength: 769, chkWidth: true});
            // 営業所情報
            await this.setOfficesData(replacements);
            // 担当者名
            let staffName = getNullStr(result[workIndex].staff_name_kanji).trim();
            replacements.push({key: '%担当者名%', value: staffName, textAnchor: 'start', textLength: 416, chkWidth: true});
            // 担当者印
            let staffStamp = getNullStr(result[workIndex].staff_name_stamp).trim();
            replacements.push({key: '%社判%', value: staffStamp, textAnchor: 'middle', textLength: 100, chkWidth: false});
            if (staffStamp != '') {
              this.stampFlg = true;
            }
            // 帳票毎に設定可能な製品の件数
            let productCntByChouhyou = this.constData.cntNormal;
            // 仕入先顧客コード
            replacements.push({key: '%仕入先顧客コード%', value: await this.getSuppliersKokyakuData(result[workIndex].client_id), textAnchor: 'start', textLength: 1320, chkWidth: true});
            // 受注情報
            replacements.push({key: '%受注情報%', value: '', textAnchor: 'start', textLength: 1560, chkWidth: false});
            // 備考（出力しない）
            this.setSummary(replacements);
            // 製品
            for (let j = 0; j < productCntByChouhyou; j++){
              if (productsIndex < result.length && result[productsIndex].order_number == curOrderNumber
              && result[productsIndex].client_id == curClientId
              ) {
                // 品番
                replacements.push({key: '%品番' + (j + 1).toString() + '%', value: result[productsIndex].supplier_product_id == null ? '' : result[productsIndex].supplier_product_id, textAnchor: 'start', textLength: 240, chkWidth: true});
                // 商品名
                replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: result[productsIndex].product_name, textAnchor: 'start', textLength: 710, chkWidth: true});
                // 数量
                let productQuantity = result[productsIndex].order_quantity.toLocaleString();
                productQuantity += getNullStr(result[productsIndex].unit) == '' ? '' : ' ' + result[productsIndex].unit;
                replacements.push({key: '%数量' + (j + 1).toString() + '%', value: productQuantity, textAnchor: 'end', textLength: 210, chkWidth: true});
                // 備考
                replacements.push({key: '%備考' + (j + 1).toString() + '%', value: result[productsIndex].note, textAnchor: 'start', textLength: 360, chkWidth: true});

                productsIndex++;
              }else{
                // 品番
                replacements.push({key: '%品番' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 240, chkWidth: false});
                // 数量
                replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
                // 備考
                replacements.push({key: '%備考' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 360, chkWidth: false});
                if (whiteFlg == true) {
                  // 以下余白を設定済み
                  // 商品名
                  replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 710, chkWidth: false});
                } else {
                  // 以下余白が入る箇所
                  if (i + 1 == printSet.listChouhyou.length &&
                    j + 1 == productCntByChouhyou) {
                    // 最終帳票、かつ、最終行の場合、余白がないため、「以上」とする。
                    // 商品名
                    replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '以上', textAnchor: 'middle', textLength: 710, chkWidth: false});
                  } else {
                    // 商品名
                    replacements.push({key: '%商品名' + (j + 1).toString() + '%', value: '以下余白', textAnchor: 'middle', textLength: 710, chkWidth: false});
                  }
                  whiteFlg = true;
                }
              }
            }
            printSet.listChouhyou[i].replacements = replacements;
          }
        }
      } catch(error) {
        console.log(error);
        throw error;
      }
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements) {
      for (const office of this.officeList){
        // 営業名
        replacements.push({ key: '%営業名' + office.keyNo + '%', value: office.officeNameKanji, textAnchor: 'start', textLength: 561, chkWidth: true });
        // 郵便
        replacements.push({key: '%郵便' + office.keyNo + '%', value: office.zipCode, textAnchor: 'start', textLength: 501, chkWidth: true});
        // 住所
        replacements.push({key: '%住所' + office.keyNo + '%', value: office.address, textAnchor: 'start', textLength: 501, chkWidth: true});
        // TEL
        replacements.push({key: '%TEL' + office.keyNo + '%', value: office.phoneNumber, textAnchor: 'start', textLength: 501, chkWidth: true});
        // FAX
        replacements.push({key: '%FAX' + office.keyNo + '%', value: office.faxNumber, textAnchor: 'start', textLength: 501, chkWidth: true});
      }
    },
    /* 置換文字列に備考を設定 */
    async setSummary(replacements) {
      for (let i = 0; i < 4; i++) {
        // 摘要
        replacements.push({key: '%摘要' + (i + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 1322, chkWidth: false});
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou(){
      //console.log('setChouhyou');
      for (const listItem of this.listSet) {
        for (let i = 0; i < listItem.listChouhyou.length; i++){
          let svgDoc = document.getElementById(this.constData.chouhyouId + listItem.orderNumber + '_' + listItem.clientId + '_' + listItem.listChouhyou[i].page);
          // console.log(svgDoc);
          await this.setReplacements(svgDoc, listItem.listChouhyou[i].replacements);
          await this.setSize(svgDoc);
        }
      }
    },
    /* 置換値をSVGファイルに設定 */
    async setReplacements(node, replacements){
      // console.log('setReplacements');
      for(let i = 0; i < node.children.length; i++){
        if (node.children[i].tagName == 'text'){
          for(let j = 0; j < node.children[i].children.length; j++){
            if(node.children[i].children[j].tagName == 'tspan'){
              for(let k = 0; k < replacements.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1){
                  if(replacements[k].key == '%社判%'){
                    await this.setStamp(node.children[i], node.children[i].children[j], replacements[k]);
                  }else{
                    await this.setTspan(node.children[i], node.children[i].children[j], replacements[k]);
                  }
                  replacements.splice(k, 1);
                  break;
                }
              }
            }
          }
        } else if (node.children[i].tagName == 'circle') {
          // 社判の赤丸
          if (node.children[i].getAttribute('stroke') == 'red') {
            if (this.stampFlg == true) {
              // 社判のテキストに使用するため中心の座標を取得しておく
              this.colStampCX = node.children[i].getAttribute('cx');
              this.colStampCY = node.children[i].getAttribute('cy');
            } else {
              // 赤丸を削除
              node.removeChild(node.children[i]);
              i--; 
            }
          }
        } else if (node.children[i].tagName == 'g'){
          await this.setReplacements(node.children[i], replacements);
        }
      }
    },
    /* Textタグ内のテキストを設定 */
    async setTspan(tagText, tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, getNullStr(config.value));
      // 製品一覧の文字を太字化
      if (getNullStr(config.value).length > 0 &&
      (config.key.indexOf('%商品名') != -1 || config.key.indexOf('%数量') != -1 || config.key.indexOf('%備考') != -1 || config.key.indexOf('%品番') != -1)
      ) {
        tagTspan.setAttribute('font-weight', 'bold');
      }
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if(config.chkWidth == true &&
        tagText.getBBox().width > config.textLength){
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 印鑑を設定 */
    /* ※注：伸ばし棒は縦書きされない（指摘があれば修正） */
    async setStamp(tagText, tagTspan, config) {
      switch (config.value.length) {
      case 0:
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
        break;
      case 1:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '65');
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', 'middle');
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) + 20);
        break;
      case 2:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '40');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', 'middle');
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 5);
        // 2文字目
        tagText.append(await this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 35, config.value.substr(1, 1)));
        break;
      case 3:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '30');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 20);
        // 2文字目
        tagText.append(await this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 10, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 40, config.value.substr(2, 1)));
        break;
      case 4:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '35');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', Number(this.colStampCX) + 18);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 8);
        // 2文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) + 18, Number(this.colStampCY) + 28, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 18, Number(this.colStampCY) - 8, config.value.substr(2, 1)));
        // 4文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 18, Number(this.colStampCY) + 28, config.value.substr(3, 1)));
        break;
      default:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '28');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', Number(this.colStampCX) + 15);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 9);
        // 2文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) + 15, Number(this.colStampCY) + 19, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) - 18, config.value.substr(2, 1)));
        // 4文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) + 10, config.value.substr(3, 1)));
        // 5文字目
        tagText.append(await this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) + 38, config.value.substr(4, 1)));
        break;
      }
    },
    /* 印鑑用のtspan作成 */
    async createStampTspan(x, y, chr) {
      let tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      tspan.innerHTML = chr;
      tspan.setAttribute('x', x);
      tspan.setAttribute('y', y);
      tspan.setAttribute('text-anchor', 'middle');
      return tspan;
    },
    /* 取得結果セット */
    async setSize(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
    /* 伝票番号取得 */
    async getOrderNumber() {
      const functionName = 'getOrderNumber';
      //console.log(this.loginStaffInfo.login_id)
      let orderNumber = await getFormCounter(Const.CounterClass.orderNumber, 0, 0, this.loginStaffInfo.login_id, MODULE_NAME, functionName);
      if (orderNumber != null) {
        return orderNumber;
      } else {
        throw '伝票番号の取得に失敗しました。';
      }
    },
    /*
     * executeTransactSql実行用の共通関数
     */
    async executeSqls(sqls, timing) {
      const functionName = `executeSqls (${timing})`;
      //console.log(functionName);
      //console.log(sqls);
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        throw error;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        throw result.errors;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        throw body.error;
      }
      return body.data;
    },
  },
}
</script>
<style scoped>
</style>