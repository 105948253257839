<template>
  <!-- 在庫保守照会画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 在庫保守照会</strong></h5>
                <router-link to="/stocks-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
               <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <!-- 処理年月 -->
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                  <label for="month_year" class="form-label"><strong>処理年月</strong></label>
                  <input type="text" id="month_year" class="form-control" :value="stockObj | month_year" readonly>
                </div>
                <!-- 営業所コード -->
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                  <label for="office_id" class="form-label"><strong>営業所コード</strong></label>
                  <input type="text" id="office_id" class="form-control" v-model="stockObj.office_name_kanji" readonly>
                </div>
                <!-- 製品コード -->
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                  <label for="productId" class="form-label"><strong>製品コード</strong></label>
                  <input type="text" id="productId" class="form-control" v-model="stockObj.product_id" readonly>
                </div>
                <!-- 製品名 -->
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 form-group" v-b-tooltip.hover :title="stockObj.product_name_kanji">
                  <label for="productId" class="form-label"><strong>製品名</strong></label>
                  <input type="text" id="productId" class="form-control" v-model="stockObj.product_name_kanji" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 残高数 -->
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                  <label for="balance" class="form-label"><strong>残高数</strong></label>
                  <input type="text" id="balance" class="form-control" v-model="stockObj.balance" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 月次仕入入庫数 -->
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                  <label for="monthly_purchase_stock" class="form-label"><strong>月次仕入入庫数</strong></label>
                  <input type="text" id="monthly_purchase_stock" class="form-control" v-model="stockObj.monthly_purchase_stock" readonly>
                </div>
                <!-- 月次調整入庫数 -->
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                  <label for="monthly_tuning_stock" class="form-label"><strong>月次調整入庫数</strong></label>
                  <input type="text" id="monthly_tuning_stock" class="form-control" v-model="stockObj.monthly_tuning_stock" readonly>
                </div>
                <!-- 月次売上出庫数 -->
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                  <label for="monthly_salse_ship" class="form-label"><strong>月次売上出庫数</strong></label>
                  <input type="text" id="monthly_salse_ship" class="form-control" v-model="stockObj.monthly_salse_ship" readonly>
                </div>
                <!-- 月次調整出庫数 -->
                <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                  <label for="monthly_tuning_ship" class="form-label"><strong>月次調整出庫数</strong></label>
                  <input type="text" id="monthly_tuning_ship" class="form-control" v-model="stockObj.monthly_tuning_ship" readonly>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, init, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'stocks-inquiry';

export default {
  name: 'STOCKS-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '在庫保守照会',
      // 在庫対象
      stockObj: {},
      // 営業所リスト
      officeList: [],
      // 処理結果成功メッセージ
      successMessages: [],
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  /** フィルター */
  filters: {
    month_year: function (stock) {
      // 処理年月の表示方法
      return String(stock.month_year).slice(0,4)+'/'+ String(stock.month_year).slice(4)
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    // 営業所リストを取得
    this.officeList = await selectOneTable('m_offices');
    // 新規か編集画面からメッセージ
    this.successMessages = this.$route.params.successMessages || []
    this.errorMessages = this.$route.params.errorMessages || []
    await this.fetchData(this.$route.query);
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} product_id - 製品コード
     * @param {String} office_id - 営業所コード
     */
    async fetchData({product_id, office_id, month_year}) {
      // 在庫検索SQL
      product_id = Number(product_id)
      office_id = Number(office_id)
      month_year = Number(month_year)
      // 在庫マスタのデータを請求
      let stock = await this.searchStocks(product_id, office_id, month_year)
      const office = this.officeList.find((office) => {
        return office.office_id === stock.office_id
      })
      stock.office_name_kanji = `${stock.office_id}: ${office.office_name_kanji}`
      stock.balance = stock.balance.toLocaleString();
      stock.monthly_purchase_stock = stock.monthly_purchase_stock.toLocaleString();
      stock.monthly_tuning_stock = stock.monthly_tuning_stock.toLocaleString();
      stock.monthly_salse_ship = stock.monthly_salse_ship.toLocaleString();
      stock.monthly_tuning_ship = stock.monthly_tuning_ship.toLocaleString();

      // 在庫データをセット
      this.stockObj = {...stock}
    },

    /**
     * 在庫マスタを検索します。
     * @param {Int} product_id - 製品コード
     * @param {Int} office_id - 営業所コード
     * @param {Int} month_year - 処理年月
     * @returns {Object} 在庫マスタデータ
     */
    async searchStocks(product_id, office_id, month_year) {
      const functionName = 'searchProduct';

      let result = null;
      let sqls = `select * from m_stocks left join m_products using(product_id) 
        where product_id = ${product_id} and office_id = ${office_id} and month_year = ${month_year}
       `
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: [sqls] }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if(result === null) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      return body.data[0][0]
    },

  }
}
</script>
