<template>
  <!-- 得意先別売掛実績表 -->
  <div>
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="p-4 min-vh-85">
      <b-row class="mt-2">
        <b-col>
          <b-card no-body>
            <b-card-body class="p-2">
              <h5>
                <a v-b-toggle.collapse-1 title="クリックすると出力範囲指定を表示/非表示できます。"><span class="oi oi-magnifying-glass"></span> 出力範囲指定</a>
                <b-button size="sm" v-b-tooltip.hover title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                  <span class="oi oi-print"></span>
                </b-button>
              </h5>
              <b-collapse id="collapse-1" :visible="true" class="mt-4">
                <validation-observer ref="observer">
                  <b-container fluid>
                    <b-row v-if="alertSuccess.length">
                      <b-col>
                        <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                          <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                            <li>{{error}}</li>
                          </ul>
                        </b-alert>
                      </b-col>
                    </b-row>
                    <b-row v-if="validationMessage.length">
                      <b-col>
                        <b-alert show variant="warning" class="mt-2">
                          <ul v-for="(message, index) in validationMessage" :key="index" style="list-style: none;">
                            <li>{{ message }}</li>
                          </ul>
                        </b-alert>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4" lg="3" xl="2">
                        <b-form-group label="年月" description="集計対象年月です。">
                          <validation-provider rules="required" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <b-input type="month" id="monthYear" v-model="searchConditions.monthYear"></b-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="8" lg="9" xl="10">
                        <b-form-group label="取引先コード" description="取引先コードの範囲を指定します。">
                          <b-row>
                            <b-col>
                              <validation-provider :rules="{numeric:true,max:6,between:[0,999999],consistency:searchConditions.clientIdTo}" v-slot="{ classes, errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-input id="clientIdFrom" v-model="searchConditions.clientIdFrom" maxlength="6"></b-input>
                                    <b-input-group-text @click="showClientSearchModal(true);" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                        <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(true);" class="d-none"></button>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-col>
                            <b-col cols="1"><div class="pt-2 text-center">～</div></b-col>
                            <b-col>
                              <validation-provider rules="numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-input type="text" v-model="searchConditions.clientIdTo" maxlength="6"></b-input>
                                    <b-input-group-text @click="showClientSearchModal(false);" v-b-tooltip.hover title="「ALT+2」ボタンで呼出可能">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                        <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(false);" class="d-none"></button>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row align-h="center" class="mt-2">
                      <b-col sm="5" lg="4" xl="3" class="pb-2">
                        <!-- 検索ボタン -->
                        <b-button class="btn-block" pill variant="success" @click="onSearchButtonClick"><span class="oi oi-magnifying-glass"></span> 検索</b-button>
                      </b-col>
                      <b-col sm="5" lg="4" xl="3" class="pb-2">
                        <!-- 作表ボタン -->
                        <b-button class="btn-block" pill variant="success" @click="onPrintButtonClick"><span class="oi oi-document"></span> 作表</b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </validation-observer>
              </b-collapse>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
            <b-alert show variant="warning" class="mt-2" v-if="dangerMessages.length">
              <ul v-for="(message, index) in dangerMessages" :key="index" style="list-style: none;">
                <li>{{ message }}</li>
              </ul>
            </b-alert>
            <div class="row">
              <div class="col-sm-12">
                <b-row>
                  <!-- 1ページあたりの表示選択 -->
                  <b-col lg="6" class="my-1">
                    <b-form-group
                      label="1ページあたりの表示件数"
                      label-for="per-page-select"
                      label-cols-sm="4"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                    </b-form-group>
                  </b-col>
                  <!-- 検索結果検索 -->
                  <b-col lg="6" class="my-1">
                    <b-form-group
                      label="Filter"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- 検索結果 -->
                <b-row>
                  <b-col cols="12" class="px-0">
                    <b-table
                      :bordered="true"
                      :busy="isBusy"
                      :current-page="currentPage"
                      :fields="fields"
                      :filter="filter"
                      foot-clone
                      :items="items"
                      :per-page="perPage"
                      :responsive="true"
                      show-empty
                      @filtered="onFiltered"
                    >
                      <!-- テーブル読み込み時表示html -->
                      <template #table-busy>
                        <div class="text-center text-info my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>読み込んでいます...</strong>
                        </div>
                      </template>
                      <!-- 得意先・担当者 -->
                      <template #head(client_id)><div>得意先</div><div class="ml-5">担当者</div></template>
                      <template #cell(client_id)="data">
                        <div>{{String(data.item.client_id).padStart(6, '0')}}&emsp;{{data.item.client_name_kanji}}</div>
                        <div class="ml-5">{{String(data.item.staff_id).padStart(4, '0')}}&emsp;{{data.item.staff_name_kanji.trim() === '' ? data.item.staff_name_kana : data.item.staff_name_kanji}}</div>
                      </template>
                      <template #foot(client_id)>
                        <div>＜&emsp;合&emsp;計&emsp;＞</div>
                      </template>
                      <!-- 売掛税抜残高・売掛消費税残高・売上額 -->
                      <template #head(last_month_receivable_no_tax_balance_result)>
                        <div>売掛税抜残高</div>
                        <div>売掛消費税残高</div>
                        <div>売上額</div>
                      </template>
                      <template #cell(last_month_receivable_no_tax_balance_result)="data">
                        <div>{{data.item.last_month_receivable_no_tax_balance_result.toLocaleString()}}</div>
                        <div>{{data.item.last_month_receivable_tax_balance_result.toLocaleString()}}</div>
                        <div>{{data.item.monthly_sales.toLocaleString()}}</div>
                      </template>
                      <template #foot(last_month_receivable_no_tax_balance_result)>
                        <div>{{total.last_month_receivable_no_tax_balance_result.toLocaleString()}}</div>
                        <div>{{total.last_month_receivable_tax_balance_result.toLocaleString()}}</div>
                        <div>{{total.monthly_sales.toLocaleString()}}</div>
                      </template>
                      <!-- 現金入金額・小切手入金額・振込入金額 -->
                      <template #head(monthly_deposit)>
                        <div>現金入金額</div>
                        <div>小切手入金額</div>
                        <div>振込入金額</div>
                      </template>
                      <template #cell(monthly_deposit)="data">
                        <div>{{data.item.monthly_deposit.toLocaleString()}}</div>
                        <div>{{data.item.monthly_check_deposit.toLocaleString()}}</div>
                        <div>{{data.item.monthly_transfer_deposit.toLocaleString()}}</div>
                      </template>
                      <template #foot(monthly_deposit)>
                        <div>{{total.monthly_deposit.toLocaleString()}}</div>
                        <div>{{total.monthly_check_deposit.toLocaleString()}}</div>
                        <div>{{total.monthly_transfer_deposit.toLocaleString()}}</div>
                      </template>
                      <!-- 手形入金額・相殺入金額・その他入金額 -->
                      <template #head(monthly_bill_deposit)>
                        <div>手形入金額</div>
                        <div>相殺入金額</div>
                        <div>その他入金額</div>
                      </template>
                      <template #cell(monthly_bill_deposit)="data">
                        <div>{{data.item.monthly_bill_deposit.toLocaleString()}}</div>
                        <div>{{data.item.monthly_offset_deposit.toLocaleString()}}</div>
                        <div>{{data.item.monthly_other_deposit.toLocaleString()}}</div>
                      </template>
                      <template #foot(monthly_bill_deposit)>
                        <div>{{total.monthly_bill_deposit.toLocaleString()}}</div>
                        <div>{{total.monthly_offset_deposit.toLocaleString()}}</div>
                        <div>{{total.monthly_other_deposit.toLocaleString()}}</div>
                      </template>
                      <!-- 消費税入金額・一括消費税額・伝票消費税額 -->
                      <template #head(monthly_tax_deposit)>
                        <div>消費税入金額</div>
                        <div>一括消費税額</div>
                        <div>伝票消費税額</div>
                      </template>
                      <template #cell(monthly_tax_deposit)="data">
                        <div>{{data.item.monthly_tax_deposit.toLocaleString()}}</div>
                        <div>{{data.item.monthly_bulk_tax.toLocaleString()}}</div>
                        <div>{{data.item.monthly_billing_tax.toLocaleString()}}</div>
                      </template>
                      <template #foot(monthly_tax_deposit)>
                        <div>{{total.monthly_tax_deposit.toLocaleString()}}</div>
                        <div>{{total.monthly_bulk_tax.toLocaleString()}}</div>
                        <div>{{total.monthly_billing_tax.toLocaleString()}}</div>
                      </template>
                      <!-- 消費税額・粗利額・今回請求額 -->
                      <template #head(monthly_tax)>
                        <div>消費税額</div>
                        <div>粗利額</div>
                        <div>今回請求額</div>
                      </template>
                      <template #cell(monthly_tax)="data">
                        <div>{{data.item.monthly_tax.toLocaleString()}}</div>
                        <div>{{data.item.monthly_gross_profit.toLocaleString()}}</div>
                        <div>{{data.item.billing_amount.toLocaleString()}}</div>
                      </template>
                      <template #foot(monthly_tax)>
                        <div>{{total.monthly_tax.toLocaleString()}}</div>
                        <div>{{total.monthly_gross_profit.toLocaleString()}}</div>
                        <div>{{total.billing_amount.toLocaleString()}}</div>
                      </template>
                      <!-- 操作 -->
                      <template #head(operation)>
                        <div>操作</div>
                      </template>
                      <template #cell(operation)="data">
                        <div v-if="data.item.client_id !== subtotalId">
                          <b-button size="sm" @click="onMentainanceButtonClick(data)"><span class="oi oi-pencil"></span> 売掛実績・残高保守</b-button>
                        </div>
                      </template>
                      <template #foot(operation)>
                        <!-- 何も出力しません -->
                        {{''}}
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <!-- 表示レコードをラベル表示 -->
                <b-row>
                  <b-col lg="6">
                    <b-form-group
                      :label="getPagingMessage"
                      class="mt-0 mb-0"
                    />
                  </b-col>
                </b-row>
                <!-- テーブルページネーション -->
                <b-row>
                  <b-col class="my-1">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="filter != null ? filterRows : items.length"
                      :per-page="perPage == -1 ? items.length : perPage"
                      align="center"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
    <ClientSearch @from-child="closeClientSearchModal" :client-class="1"/>
  </div>    
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import ClientSearch from '@/components/modal/client-search.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, windowPrint, init, selectOneTable } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

// モジュール名
const MODULE_NAME = 'accounts-receivable-supply-list';
// テーブル項目テンプレート
const TBALE_ITEMS_TEMPLATE = {
  last_month_receivable_no_tax_balance_result: 0, // 前月末売掛税抜残高
  last_month_receivable_tax_balance_result: 0, // 前月末売掛消費税残高
  monthly_sales: 0, // 月次売上額
  monthly_deposit: 0, // 月次現金入金額
  monthly_check_deposit: 0, // 月次小切手入金額
  monthly_transfer_deposit: 0, // 月次振込入金額
  monthly_bill_deposit: 0, // 月次手形入金額
  monthly_offset_deposit: 0, // 月次相殺入金額
  monthly_other_deposit: 0, // 月次その他入金額
  monthly_tax_deposit: 0, // 月次消費税入金額
  monthly_bulk_tax: 0, // 月次一括消費税額
  monthly_billing_tax: 0, // 月次伝票消費税額
  monthly_tax: 0, // 月次消費税額
  monthly_gross_profit: 0, // 月次粗利額
  billing_amount: 0 // 今回請求額
};
// テーブル最大レコード数
const TABLE_ITEMS_MAX = 1000;

export default {
  name: 'ACCOUNTS-RECEIVABLE-SUPPLY-LIST',
  components: {
    Header,
    Footer,
    ClientSearch
  },
  data() {
    return {
      menu_type: 'user',
      title: '得意先別売掛実績表',
      // 合計識別子
      subtotalId: 'subtotal',
      // 検索条件
      searchConditions: {
        monthYear: '',
        clientIdFrom: '',
        clientIdTo: ''
      },
      // 開始取引先コード側で取引先選択モーダルを呼び出したかどうかフラグ
      isClientIdFrom: true,
      // 成功メッセージ
      alertSuccess: [],
      // バリデーションエラーメッセージ
      validationMessage: [],
      // 検索エラーメッセージ
      dangerMessages: [],
      // テーブルに表示するデータ
      items: [],
      // テーブルフッターに表示する総合計
      total: { ...TBALE_ITEMS_TEMPLATE },
      // テーブルフィールド定義
      fields: DataTblDef.accounts_receivable_supply_list_fields,
      // テーブルビジーフラグ
      isBusy: false,
      // テーブルフィルターキーワード
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
    };
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.items.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init(); // common.jsにて初期化処理
    // 保守画面から成功メッセージ
    if (this.$route.query.successMessageId) {
      this.alertSuccess.push(DISP_MESSAGES.SUCCESS[this.$route.query.successMessageId]);
    }
    scrollTo(0, 0);
    this.$store.commit('setLoading', false);
  },
  methods: {
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;

      const newTotal = { ...TBALE_ITEMS_TEMPLATE };
      for (const item of filteredItems) {
        if (item.clientId !== this.subtotalId) {
          newTotal.last_month_receivable_no_tax_balance_result += item.last_month_receivable_no_tax_balance_result;
          newTotal.last_month_receivable_tax_balance_result += item.last_month_receivable_tax_balance_result;
          newTotal.monthly_sales += item.monthly_sales;
          newTotal.monthly_deposit += item.monthly_deposit;
          newTotal.monthly_check_deposit += item.monthly_check_deposit;
          newTotal.monthly_transfer_deposit += item.monthly_transfer_deposit;
          newTotal.monthly_bill_deposit += item.monthly_bill_deposit;
          newTotal.monthly_offset_deposit += item.monthly_offset_deposit;
          newTotal.monthly_other_deposit += item.monthly_other_deposit;
          newTotal.monthly_tax_deposit += item.monthly_tax_deposit;
          newTotal.monthly_bulk_tax += item.monthly_bulk_tax;
          newTotal.monthly_billing_tax += item.monthly_billing_tax;
          newTotal.monthly_tax += item.monthly_tax;
          newTotal.monthly_gross_profit += item.monthly_gross_profit;
          newTotal.billing_amount += item.billing_amount;
        }
      }
      this.total = newTotal;
    },
    /**
     * 取引先選択モーダルを開きます。
     * @param {Number} isClientIdFrom - 開始取引先コードはtrue、終了取引先コードはfalse
     */
    showClientSearchModal(isClientIdFrom) {
      this.isClientIdFrom = isClientIdFrom;
      this.$bvModal.show('clientSearchModal');
    },
    /**
     * 取引先選択モーダルを閉じます。
     * @param {Object} item - 選択された取引先データ
     */
    closeClientSearchModal(item){
      if (this.isClientIdFrom) {
        this.searchConditions.clientIdFrom = item.detail.client_id;
      } else {
        this.searchConditions.clientIdTo = item.detail.client_id;
      }
    },
    /**
     * 検索ボタンクリックイベント処理
     */
    async onSearchButtonClick() {
      this.alertSuccess = [];
      this.validationMessage = [];
      this.dangerMessages = [];

      if (await this.$refs.observer.validate()) {
        this.isBusy = true;
        await this.search();
        this.isBusy = false;
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }
    },
    /**
     * 整合性チェックを行います。
     * @returns {Boolean} 整合性に問題が無い場合はtrue、問題がある場合はfalse
     */
    async checkConsistency() {
      // コントロールマスタから現在処理年月を取得
      const processMonthYear = await this.getProcessMonthYear();
      if (processMonthYear === null) {
        this.dangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }

      // 画面で選択された処理年月がコントロールマスタの処理年月以降の場合はNG
      const monthYear = this.getMonthYear();
      if (processMonthYear <= monthYear) {
        const monthYearString = `${Math.floor(monthYear / 100)}年${monthYear % 100}月`;
        this.validationMessage.push(
          DISP_MESSAGES.WARNING['2012'].replace('%arg1%', monthYearString)
        );
        return false;
      }

      return true;
    },
    /**
     * コントロールマスタから現在処理年月を取得します。
     * @returns {Number} 現在処理年月
     */
    async getProcessMonthYear() {
      const functionName = 'getProcessMonthYear';
      let result = null;
      try {
        result = await selectOneTable('m_control');
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control'
        }, error);
        return null;
      }
      if (result == null || result.length == 0) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control',
          result: result
        });
        return null;
      }
      return result[0].process_month_year;
    },
    /**
     * 画面の検索条件で得意先別売掛実績一覧データを検索し、テーブルに結果を表示します。
     */
    async search() {
      const functionName = 'search'; 

      // SQL文を作成
      const sql = this.createSQL();
      const sqls = [ sql ];

      // 検索
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.dangerMessages.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.dangerMessages.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.dangerMessages.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }

      this.setTableData(body.data[0]);
    },
    /**
     * 画面の検索条件で売掛実績・残高マスタを検索するSQL文を作成します。
     * @returns {String} SQL文
     */
    createSQL() {
      const whereClauses = [];
      // 処理年月
      whereClauses.push(`trbr.month_year = ${this.getMonthYear()}`);
      // 得意先コード
      if (this.searchConditions.clientIdFrom !== '' && this.searchConditions.clientIdTo !== '') {
        whereClauses.push(`trbr.client_id BETWEEN ${this.searchConditions.clientIdFrom} AND ${this.searchConditions.clientIdTo}`);
      } else if (this.searchConditions.clientIdFrom !== '') {
        whereClauses.push(`trbr.client_id >= ${this.searchConditions.clientIdFrom}`);
      } else if (this.searchConditions.clientIdTo !== '') {
        whereClauses.push(`trbr.client_id <= ${this.searchConditions.clientIdTo}`);
      }

      return 'SELECT' +
        ' trbr.month_year' +
        ',trbr.client_id' +
        ',trbr.staff_id' +
        ',trbr.last_month_receivable_no_tax_balance_result' +
        ',trbr.last_month_receivable_tax_balance_result' +
        ',trbr.monthly_sales' +
        ',trbr.monthly_deposit' +
        ',trbr.monthly_check_deposit' +
        ',trbr.monthly_transfer_deposit' +
        ',trbr.monthly_bill_deposit' +
        ',trbr.monthly_offset_deposit' +
        ',trbr.monthly_other_deposit' +
        ',trbr.monthly_tax_deposit' +
        ',trbr.monthly_bulk_tax' +
        ',trbr.monthly_billing_tax' +
        ',trbr.monthly_tax' +
        ',trbr.monthly_gross_profit' +
        ',trbr.billing_amount' +
        ',mc.client_name_kanji' +
        ',ms.staff_name_kanji' +
        ',ms.staff_name_kana ' +
        'FROM t_receivables_balances_results AS trbr' +
        ' JOIN m_clients mc ON mc.client_class = 1 AND trbr.client_id = mc.client_id' +
        ' JOIN m_staffs ms ON trbr.staff_id = ms.staff_id ' +
        `WHERE ${whereClauses.join(' AND ')} ` +
        `ORDER BY trbr.client_id ASC, trbr.staff_id ASC LIMIT ${TABLE_ITEMS_MAX}`;
    },
    /**
     * 売掛実績・残高保守ボタンクリックイベント処理
     * @param {Object} data - 選択された売掛実績・残高データ
     */
    onMentainanceButtonClick(data) {
      let route = this.$router.resolve({ name: 'RECIVABLE-BALANCE-RESULT-MAINTENANCE', query: { monthYear: data.item.month_year, clientId: data.item.client_id } });
      window.open(route.href, '_blank');
    },
    /**
     * 画面で入力された年月を取得して数値で返します。
     * @returns {Number} 年月
     */
    getMonthYear() {
      return Number(this.searchConditions.monthYear.replace('-', ''));
    },
    /**
     * 作表ボタンクリックイベント処理
     */
    async onPrintButtonClick() {
      this.alertSuccess = [];
      this.validationMessage = [];
      this.dangerMessages = [];
      var newWin = window.open('', '_blank');
      if (await this.$refs.observer.validate()) {
        if (await this.checkConsistency()) {
          // 得意先別売掛実績表印刷プレビュー画面へ遷移します。
          let route = this.$router.resolve({
            name: 'ACCOUNTS-RECEIVABLE-SUPPLY-LIST-PRINT',
            query: {
              monthYear: this.getMonthYear(),
              clientIdFrom: this.searchConditions.clientIdFrom,
              clientIdTo: this.searchConditions.clientIdTo
            }
          });
          newWin.location = route.href
        } else {
          newWin.close()
          // ios safari ための対応
          setTimeout(() => {
            scrollTo(0,0)
          }, 500);
        }
      } else {
        newWin.close()
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }
    },
    /**
     * 売掛実績・残高マスタから取得したデータでテーブルに表示するデータを作成します。
     * @param {Array} records - 売掛実績・残高マスタから取得したデータ
     */
    setTableData(records) {
      const total = { ...TBALE_ITEMS_TEMPLATE };
      for (const record of records) {
        // 前月末売掛税抜残高
        total.last_month_receivable_no_tax_balance_result += record.last_month_receivable_no_tax_balance_result;
        // 前月末売掛消費税残高
        total.last_month_receivable_tax_balance_result += record.last_month_receivable_tax_balance_result;
        // 月次売上額
        total.monthly_sales += record.monthly_sales;
        // 月次現金入金額
        total.monthly_deposit += record.monthly_deposit;
        // 月次小切手入金額
        total.monthly_check_deposit += record.monthly_check_deposit;
        // 月次振込入金額
        total.monthly_transfer_deposit += record.monthly_transfer_deposit;
        // 月次手形入金額
        total.monthly_bill_deposit += record.monthly_bill_deposit;
        // 月次相殺入金額
        total.monthly_offset_deposit += record.monthly_offset_deposit;
        // 月次その他入金額
        total.monthly_other_deposit += record.monthly_other_deposit;
        // 月次消費税入金額
        total.monthly_tax_deposit += record.monthly_tax_deposit;
        // 月次一括消費税額
        total.monthly_bulk_tax += record.monthly_bulk_tax;
        // 月次伝票消費税額
        total.monthly_billing_tax += record.monthly_billing_tax;
        // 月次消費税額
        total.monthly_tax += record.monthly_tax;
        // 月次粗利額
        total.monthly_gross_profit += record.monthly_gross_profit;
        // 今回請求額
        total.billing_amount += record.billing_amount;
      }

      this.items = records;
      this.total = total;

      // 検索結果が1000件の場合は1000件を超えている場合があるのでメッセージを表示します。
      if (records.length === TABLE_ITEMS_MAX) {
        this.dangerMessages.push(DISP_MESSAGES.WARNING['2002']);
      }
    }
  }
}
</script>
