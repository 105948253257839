<template>
 <b-modal
  id="productSearchModal"
  size="xl"
  centered
  @show="clearAlert(); searchButton('init')"
  title="製品検索"
  title-class="m-0 p-0"
 >
  <b-container class="px-0">
    <!-- ●●●検索条件●●● -->
    <div>
      <div class="main-card card px-3 pt-2">
        <div class="mb-0" id="heading1">
          <h5 class="mb-0">
              <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                <span class="oi oi-magnifying-glass"></span> 検索条件</a>
          </h5>
        </div>
        <b-collapse id="collapse-1" visible>
          <b-card-body class="p-0">
            <b-form @submit.prevent="clearAlert(); searchButton('click');" id="productSearchForm" class="form-horizontal">
              <validation-observer ref="observer">
                <b-row>
                  <div v-if="this.productSearchProp.office_id!=null && this.productSearchProp.office_id!='0'" class="col-sm-12 col-md-12 col-lg-3 col-xl-3 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="office">
                          <b-input-group-text  class="px-1">
                            営業所
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input type="text" id="office" v-model="office" readonly></b-form-input>
                    </b-input-group>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="clientCode">
                          <b-input-group-text  class="px-1">
                            取引先コード
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input type="text" id="clientCode" v-model="this.productSearchProp.client_id" readonly></b-form-input>
                    </b-input-group>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-5 col-xl-6 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="clientName">
                          <b-input-group-text  class="px-1">
                            取引先名
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input type="text" id="clientName"  v-b-tooltip.hover.noninteractive :title="this.productSearchProp.client_name" v-model="this.productSearchProp.client_name" readonly></b-form-input>
                    </b-input-group>
                  </div>
                </b-row>
                <b-row>
                  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="productName">
                          <b-input-group-text  class="px-1">
                            製品名
                          </b-input-group-text>
                        </label>
                      </template>
                      <b-form-input type="search" id="productName" name="productName" v-model="searchConditions.productName" autofocus></b-form-input>
                    </b-input-group>
                  </div>
                  <div class="col-sm-10 col-md-10 col-lg-4 col-xl-4 form-group px-1">
                    <validation-provider name="productCode" rules="numeric" v-slot="{ classes, errors }">
                      <b-input-group>
                        <template #prepend>
                          <label for="productCode">
                            <b-input-group-text  class="px-1">
                              製品コード
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="search" id="productCode" name="productCode" v-model="searchConditions.productCode"></b-form-input>
                        <b-col lg="12" :class="classes">
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </b-col>    
                      </b-input-group>
                    </validation-provider>
                  </div>
                  <div class="col-sm-2 col-md-2 col-lg-2 col-xl-2 form-group px-1">
                    <b-input-group>
                      <template #prepend>
                        <label for="is9A">
                          9A
                        </label>
                      </template>
                      <b-form-checkbox
                        id="is9A"
                        name="is9A"
                        v-model="searchConditions.is9A"
                        v-b-tooltip.hover.noninteractive
                        title="現金で購入する仕入先の場合はチェックを入れる。掛けで購入する仕入先の場合はチェックを外す。"
                      ></b-form-checkbox>
                    </b-input-group>
                  </div>
                </b-row>
                <!-- 検索ボタン -->
                <b-row class="justify-content-md-center mb-1">
                  <b-col lg="3">
                    <b-button block pill variant="success" size="sm" type="submit" form="productSearchForm"><span class="oi oi-magnifying-glass"></span> 検 索 </b-button>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-form>
          </b-card-body>
        </b-collapse>
      </div>
    </div>
    <!-- ●●●検索条件●●● -->
    <!-- ●●●検索結果●●● -->
    <b-card id="resultArea">
      <b-alert show variant="warning" v-if="resultError.length">
        <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-col>
        <b-row>
          <!-- 1ページあたりの表示選択 -->
          <b-col lg="6">
            <b-form-group
              label="1ページの表示件数"
              label-for="per-page-select"
              label-cols-sm="5"
              label-size="sm"
              class="mb-0"
            >
              <b-col lg="5" class="pl-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-col>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Filter"
              label-for="filter-input"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          show-empty
          :items="productList"
          :fields="fields"
          :busy="busy"
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          :sticky-header= true
          @filtered="onFiltered"
          :small="true"
        >
          <template #table-busy>
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>読み込んでいます...</strong>
            </div>
          </template>
          <template #cell(detail)="detail">
            <b-button size="sm" v-b-tooltip.hover.noninteractive.right="'対象のデータで入力します。'" @click="clearAlert(); choice(detail.item)">
              <span class="oi oi-check"></span> 選択
            </b-button>
          </template>
          <template #cell(check)="detail">
            <span v-if="isMultiSelect">
              <b-check v-model="checkedIndex" :value="detail.item.index"/>
            </span>
          </template>          
          <template #cell(productNameKanji)="data">
            <div style="max-width:280px;">
              <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                <span>
                  {{data.item.productNameKanji}}
                </span>
              </div>
            </div>
          </template>          
          <template #cell(productNameKana)="data">
            <div style="max-width:280px;">
              <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                <span>
                  {{data.item.productNameKana}}
                </span>
              </div>
            </div>
          </template>          
        </b-table>
        <!-- テーブルページネーション -->
        <b-col class="my-0">
          <b-pagination
            size="sm"
            v-model="currentPage"
            :total-rows="filter != null ? filterRows : totalRows"
            :per-page="perPage == -1 ? totalRows : perPage"
            align="center"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-col>
    </b-card>
  </b-container>
  <template #modal-footer>
    <b-button size="sm" v-if="isMultiSelect" @click="clearAlert(); bulkChoice();" class="mr-1">一括選択</b-button>
    <b-button size="sm" @click="$bvModal.hide('productSearchModal')" class="mr-1">キャンセル</b-button>
  </template>
 </b-modal>
</template>
<script>
import { init, addOperationLogs, getNullStr, convertSqlLikeSpecialChar, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';

const MODULE_NAME = 'product-search';

export default {
  name: 'PRODUCT-SEARCH',
  props:['productSearchProp'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '製品検索',
      // 検索条件
      office: '',
      searchConditions:{
        productName: '',
        productCode: '',
        is9A: '',
      },
      // 検索時のパラメータ
      searchParms:{
        officeId: null,
        clientClass: null,
        clientId: null,
        kijunDate: null,
      },
      resultError: [],
      fields: [],
      productList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 各行のチェック状態
      isMultiSelect: false,
      checkedIndex: [],
      maxLength: 10,
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
  },
  /* 関数群 */
  methods: {
    async choice(choiceData) {
      const functionName = 'choice';
      this.$store.commit('setLoading', true);
      // 最初にnullを入れる
      choiceData.detail['cp_client_class'] = null;
      choiceData.detail['cp_client_id'] = null;
      choiceData.detail['cp_sales_unit_price'] = null;
      if (getNullStr(this.productSearchProp.kijunDate) != '') {
        // 取引先が指定されている場合のみ検索
        let where_clause = this.conditionMakeClientsProducts(choiceData.detail.product_id);
        //console.log(where_clause);
        let dataClientsProducts = null;
        try {
          dataClientsProducts = await selectOneTable('m_clients_products', where_clause);
        } catch (error) {
          let param = {
            graphqlOperation: 'list_m_clients_products',
            where_clause: where_clause
          };
          await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
          console.log(error);
          this.resultError.push(DISP_MESSAGES.DANGER['3005']);
          this.$store.commit('setLoading', false);
          return;
        }
        //console.log(dataClientsProducts);
        if (dataClientsProducts != null && dataClientsProducts.length > 0) {
          // 取引先製品が存在する場合
          choiceData.detail['cp_client_class'] = dataClientsProducts[0].client_class;
          choiceData.detail['cp_client_id'] = dataClientsProducts[0].client_id;
          choiceData.detail['cp_sales_unit_price'] = dataClientsProducts[0].sales_unit_price;
        }
      }
      this.$store.commit('setLoading', false);
      this.$emit('from-child', choiceData);
      this.$bvModal.hide('productSearchModal');
    },
    // 検索条件文字列作成（取引先製品単価）
    conditionMakeClientsProducts: function(productId) {
      // 取引先が指定されている場合のみ検索
      let where_clause = '';
      // 取引先区分
      where_clause += 'AND client_class = ' + this.productSearchProp.client_class + ' ';
      // 取引先コード
      where_clause += 'AND client_id = ' + this.productSearchProp.client_id + ' ';
      // 製品コード
      where_clause += 'AND product_id = ' + productId + ' ';
      // 単価適用日（適用中のデータを取得するため未来は除外）
      where_clause += 'AND unit_price_effective_date <= \'' + this.productSearchProp.kijunDate + '\' ';
      // ソート（適用日の直近）
      where_clause += 'ORDER BY unit_price_effective_date DESC ';
      where_clause += 'LIMIT 1 ';

      return where_clause;
    },
    // 一括選択
    async bulkChoice() {
      const functionName = 'bulkChoice';
      this.$store.commit('setLoading', true);
      // エラーチェック
      //console.log(this.checkedIndex);
      if (this.checkedIndex.length == 0 || this.checkedIndex.length > this.maxLength) {
        this.resultError.push(DISP_MESSAGES.WARNING['2042'].replace('%arg1%','1').replace('%arg2%',this.maxLength));
        this.$store.commit('setLoading', false);
        return;
      }
      // 返却値作成
      let emitList = [];
      for (let i = 0; i < this.checkedIndex.length; i++) {
        emitList.push({detail: this.productList[this.checkedIndex[i]].detail});
        // 最初にnullを入れる
        emitList[i].detail['cp_client_class'] = null;
        emitList[i].detail['cp_client_id'] = null;
        emitList[i].detail['cp_sales_unit_price'] = null;
      }
      if (getNullStr(this.productSearchProp.kijunDate) != '') {
        for (let i = 0; i < emitList.length; i++) {
          // 取引先が指定されている場合のみ検索
          let where_clause = this.conditionMakeClientsProducts(emitList[i].detail.product_id);
          //console.log(where_clause);
          let dataClientsProducts = null;
          try {
            dataClientsProducts = await selectOneTable('m_clients_products', where_clause);
          } catch (error) {
            let param = {
              graphqlOperation: 'list_m_clients_products',
              where_clause: where_clause
            };
            await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
            console.log(error);
            this.resultError.push(DISP_MESSAGES.DANGER['3005']);
            this.$store.commit('setLoading', false);
            return;
          }
          //console.log(dataClientsProducts);
          if (dataClientsProducts != null && dataClientsProducts.length > 0) {
            // 取引先製品が存在する場合
            emitList[i].detail['cp_client_class'] = dataClientsProducts[0].client_class;
            emitList[i].detail['cp_client_id'] = dataClientsProducts[0].client_id;
            emitList[i].detail['cp_sales_unit_price'] = dataClientsProducts[0].sales_unit_price;
          }
        }
      }
      this.$store.commit('setLoading', false);
      this.$emit('from-child', emitList);
      this.$bvModal.hide('productSearchModal');
    },
    /* 検索 */
    async searchButton(key) {
      const functionName = 'searchButton';
      try {
        this.checkedIndex = [];
        if (key == 'init') {
          // 初期表示の場合
          if (this.productSearchProp.multiSelectFlg == '1') {
            this.isMultiSelect = true;
          }
          // フィールド作成
          this.fields = this.getFields();
          // クリア判定
          if (this.checkClear() == true) {
            this.searchConditions.productCode = '';
            this.searchConditions.productName = '';
            if (this.productSearchProp.office_id != null && this.productSearchProp.office_id != '0') {
              this.office = this.productSearchProp.office_id + '：' + this.productSearchProp.office_name;
            }
            // ページング機能の初期化
            this.initPaging();
            this.busy = false;
          }
        }else if(key == 'click'){
          // 検索ボタン押下時
          const observer = this.$refs.observer;
          const success = await observer.validate();
          if (!success) {
            const el = document.querySelector('#error:first-of-type');
            el.scrollIntoView({ block: 'center', inline: 'nearest' });
          }else{
            await this.search();
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 検索処理 */
    async search(){
      if (this.busy == true) {
        // 連続して押下された場合は処理を行わない
        return;
      }
      this.busy = true;
      this.productList = [];
      // ページング機能の初期化
      this.initPaging();
      // CRUD処理
      let where_clause = '';
      where_clause = await this.conditionMake();
      where_clause += ' LIMIT 1000';
      //console.log(where_clause);
      if (where_clause == '') {
        // 検索条件がない場合、全件取得
        this.$store.commit('setLoading', true);
        try {
          //console.log('全件取得');
          let resultData = await selectOneTable('v_products_02');
          //console.log(resultData);
          if (resultData != null) {
            await this.setResult(resultData);
            if (this.productList.length >= 1000) {
              this.resultError.push(DISP_MESSAGES.WARNING['2002']);
            }
            this.busy = false;
          }else{
            // 総件数をdataTableの総件数にセット
            this.totalRows = 0;
            this.busy = false;
          }
        } catch (error) {
          console.log(error);
          this.resultError.push(DISP_MESSAGES.WARNING['2001']);
          this.busy = false;
        }
        this.$store.commit('setLoading', false);
      }else{
        this.$store.commit('setLoading', true);
        // 検索条件がある場合、条件取得
        try {
          //console.log('条件取得');
          let resultData = await selectOneTable('v_products_02', where_clause);
          //console.log(resultData);
          if (resultData != null) {
            await this.setResult(resultData);
            if (this.productList.length >= 1000) {
              this.resultError.push(DISP_MESSAGES.WARNING['2002']);
            }
            this.busy = false;
          }else{
            // 総件数をdataTableの総件数にセット
            this.totalRows = 0;
            this.busy = false;
          }
        } catch (error) {
          console.log(error);
          this.resultError.push(DISP_MESSAGES.WARNING['2001']);
          this.busy = false;
        }
        this.$store.commit('setLoading', false);
      }
    },
    /* 取得結果セット */
    async setResult(result){
      // 追加された行の合計をdataTableの総件数にセット
      this.totalRows = result.length;
      for(let i = 0; i < result.length; i++){
        let mark = [];
        if (result[i].set_flg == 1) {
          mark.push(Const.ProductParentClass.set)
        } 
        if (result[i].case_flg == 1) {
          mark.push(Const.ProductParentClass.case)
        } 
        if (result[i].loose_flg == 1){
          mark.push(Const.ProductParentClass.loose)
        } 
        if (result[i].group_flg == 1 || result[i].group_flg_2 == 1){
          mark.push(Const.ProductParentClass.group)
        }
        let searchResult = {
          productId: result[i].product_id,
          mark: mark.join('/'),
          productNameKanji: result[i].product_name_kanji,
          productNameKana: result[i].product_name_kana,
          is9A: result[i].is_9A,
          index: i,
          detail: result[i],
        };
        this.productList.push(searchResult);
      }
    },
    /* 検索条件文字列作成 */
    async conditionMake(){
      let where_clause = '';
      // 検索条件
      const { productName, productCode, is9A } = this.searchConditions
      // 営業所コード
      if(this.productSearchProp.office_id != null && this.productSearchProp.office_id != '0'){
        where_clause += 'AND office_id = ' + this.productSearchProp.office_id + ' ';
      }
      // 製品名
      if(productName != ''){
        where_clause += `AND (REPLACE(REPLACE(CONVERT(product_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${convertSqlLikeSpecialChar(productName)}%', 'ﾞ', ''), 'ﾟ', '') `;
        where_clause += `OR REPLACE(REPLACE(CONVERT(product_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${convertSqlLikeSpecialChar(productName)}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      // コード
      if(productCode != ''){
        where_clause += 'AND product_id LIKE \'' + productCode + '%\' ';
      }
      // 9Aフラグ
      if(is9A != ''){
        where_clause += 'AND is_9A = ' + is9A + ' ';
      } else {
        where_clause += 'AND is_9A = false ';
      }
      // 営業所コードがnullの場合、製品詳細マスタの値（仕入単価、原価単価）、製品発注マスタ	の値（発注先コード、入庫リードタイム）は不正となるが、それ以外の値を取りたい場合
      if(this.productSearchProp.office_id == null || this.productSearchProp.office_id == '0'){
        where_clause += 'GROUP BY product_id ';
      }
      // ソート
      where_clause += 'ORDER BY product_id,office_id ';

      // 検索時点のパラメータを記録しておく
      this.saveParms();

      return where_clause;
    },
    /* フィールド取得 */
    getFields: function() {
      let fields = [];
      fields.push({key: 'detail', label: ''});
      if (this.isMultiSelect == true) {
        fields.push({key: 'check', label: ''});
      }
      fields.push({key: 'productId', label: 'コード', sortable: true});
      fields.push({key: 'mark', label: ''});
      fields.push({key: 'productNameKanji', label: '製品名漢字', sortable: true});
      fields.push({key: 'productNameKana', label: '製品名カナ', sortable: true});
      fields.push({key: 'is9A', label: '9Aフラグ', sortable: true});
      return fields;
    },
    // パラメータ保存
    saveParms: function() {
      // 営業所コード
      this.searchParms.officeId = this.productSearchProp.office_id;
      // 取引先区分
      this.searchParms.clientClass = this.productSearchProp.client_class;
      // 取引先コード
      this.searchParms.clientId = this.productSearchProp.client_id;
      // 基準日
      this.searchParms.kijunDate = this.productSearchProp.kijunDate;
    },
    /* クリア判定 */
    checkClear: function() {
      // 前回検索時と比較してパラメータに変更があった場合
      if(this.searchParms.officeId != this.productSearchProp.office_id ||
      this.searchParms.clientClass != this.productSearchProp.client_class ||
      this.searchParms.clientId != this.productSearchProp.client_id ||
      this.searchParms.kijunDate != this.productSearchProp.kijunDate){
        // 検索結果をクリア
        this.productList = [];
        this.totalRows = 0;
        return true;
      }
      return false;
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.resultError = [];
    },
  },
}
</script>