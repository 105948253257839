<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mt-2 mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 入荷入力</strong></h5>
                <b-button pill v-b-tooltip.hover.noninteractive title="画面を閉じる" @click="cancel" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </div>
            </b-media-body>
          </b-media>
          <b-card class="mb-3">
            <b-card-header v-if="getMessageFlg">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveData();" id="inputForm" class="form-horizontal">
                  <b-row>
                    <b-col>
                      <b-table-simple
                        small
                      >
                        <b-tbody>
                          <b-tr>
                            <b-th>営業所</b-th>
                            <b-td>{{searchConditions.selectSalesOfficeText}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>注文番号</b-th>
                            <b-td>{{searchConditions.orderNumber == 0 ? '': searchConditions.orderNumber}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>取引先</b-th>
                            <b-td>{{searchConditions.clientText == 0 ? '': searchConditions.clientText}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>製品</b-th>
                            <b-td>{{searchConditions.productText == 0 ? '': searchConditions.productText}}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 製品 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :items="itemList"
                            :fields="fields"
                          >
                            <template #table-caption>製品テーブル</template>
                            <template #cell(ProductName)="data">
                              <span v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark + ' ' : '' }}</span>
                              {{ data.item.ProductName }}
                            </template>
                            <template #cell(StockScheduledQuantity)="data">
                              {{ data.item.StockScheduledQuantity.toLocaleString() }}
                            </template>
                            <template #cell(StockDate)="data">
                              <validation-provider :rules="{required: data.item.StockQuantity != 0, oldProcessMonthYear: data.item.StockQuantity != 0 ? controlMasterData.processMonthYear : null}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 240px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group class="input-daterange">
                                        <b-form-input
                                          id="productStockDate"
                                          name="productStockDate"
                                          v-model="data.item.StockDate"
                                          readonly
                                          tabindex="-1"
                                        ></b-form-input>
                                        <b-input-group-append>
                                          <b-button @click="openModal(data)" tabindex="-1">
                                            <span class="oi oi-calendar"></span>
                                          </b-button>
                                        </b-input-group-append>
                                      </b-input-group>
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(StockQuantity)="data">
                              <validation-provider :rules="{required: true, between: {min: 0, max: data.item.StockScheduledQuantity}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 80px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input type="number" :id="'stockQuantity' + data.index" v-model.number="data.item.StockQuantity" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <!-- 保存ボタン -->
                  <b-row class="mt-2 justify-content-md-center">
                    <b-col lg="2">
                      <b-button pill variant="success" type="submit" form="inputForm"  class="btn btn-block" :disabled="statusFlg.editFlg==false">
                        <span class="oi oi-circle-check"></span> 保存
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <b-modal id='datepickerModal' :val="postItem" size="sm" hide-footer hide-header centered no-close-on-esc>
      <div v-if="postItem">
        <b-calendar
          v-model="postItem.item.StockDate"
          @input="$bvModal.hide('datepickerModal')"
          hide-header
        >
          <div class="d-flex" dir="ltr">
            <b-button
              size="sm"
              variant="outline-danger"
              v-if="postItem.item.StockDate"
              @click="postItem.item.StockDate='';"
            >
              {{htmlConst.Calendar.clearButtonName}}
            </b-button>
            <b-button
              size="sm"
              variant="outline-primary"
              class="ml-auto"
              @click="postItem.item.StockDate=getToday();"
            >
              {{htmlConst.Calendar.todayButtonName}}
            </b-button>
          </div>
        </b-calendar>
      </div>
    </b-modal>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, formatDate, formatCurDate, getListValue, addOperationLogs, getControlMaster, executeSelectSql, CreateColRow, CreateInsertSql, executeSqlList, isSystemEditable, isOtherSessionUpdatedBySqlResultObj, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'order-stock-input';

export default {
  name: 'ORDER-STOCK-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '入荷入力',
      loginId: '',
      listUpdatedSelect: [],
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 検索条件
      searchConditions: {
        selectSalesOfficeText: '',
        orderNumber: '',
        clientText: '',
        productText: '',
      },
      // テーブル定義
      itemList: [],
      // サービス区分
      serviceList: [],
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        lightTaxMark: '',
      },
      // 定数（htmlで使用）
      htmlConst: {
        Calendar: {
          // 今日を選択するボタン名
          todayButtonName: Const.Calendar.todayButtonName,
          // 日付クリアボタンのボタン名
          clearButtonName: Const.Calendar.clearButtonName,
        },
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // トランザクションSQLリスト
      transactSqlList: [],
      // 確認ダイアログ用
      confirmMessage: [],
      // モーダルに渡す値
      postItem: '',
      // パラメータ
      officeId: 0,
      orderNumber: 0,
      clientId: 0,
      productId: 0,
      // ステータスフラグ
      statusFlg: {
        editFlg: true,
      },
    }
  },
  computed: {
    fields: function() {
      return [
        {
          key: 'StockScheduled',
          label: '入荷予定日',
        },
        {
          key: 'OrderNumber',
          label: '注文番号',
        },
        {
          key: 'ProductCode',
          label: '製品コード',
        },
        {
          key: 'ProductName',
          label: '製品名',
        },
        {
          key: 'ServiceClassText',
          label: 'サービス区分',
        },
        {
          key: 'Unit',
          label: '単位',
        },
        {
          key: 'StockScheduledQuantity',
          label: '入荷予定数',
          tdClass: 'text-right',
        },
        {
          key: 'StockQuantity',
          label: '入荷数',
        },
        {
          key: 'StockDate',
          label: '入荷日',
        },
      ]
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.officeId = this.$route.query.officeId;
    this.orderNumber = this.$route.query.orderNumber;
    this.clientId = this.$route.query.clientId;
    this.productId = this.$route.query.productId;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let serviceListData = null;
        let controlData = null;
        [serviceListData, controlData] = await Promise.all([
          selectOneTable('m_service_classes'),
          getControlMaster(),
        ]);
        // サービス区分を取得
        //console.log(serviceListData);
        for(let i = 0; i < serviceListData.length; i++){
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // コントロールマスタ
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 既存データ設定
        await this.setInitData();
        if (this.itemList.length > 0) {
          // 一覧が存在する場合
          // 先頭行の入荷数にフォーカスを移動
          document.getElementById('stockQuantity0').focus();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null && dataResult.length > 0) {
        await this.setResult(dataResult);
        // 更新時の排他制御用の変数作成
        await this.createUpdatedSelect();
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','入荷未入力の発注データ'));
        this.statusFlg.editFlg = false;
        // 営業所
        this.searchConditions.selectSalesOfficeText = this.officeId;
        // 注文番号
        this.searchConditions.orderNumber = this.orderNumber;
        // 取引先
        this.searchConditions.clientText = this.clientId;
        // 製品
        this.searchConditions.productText = this.productId;
      }
    },
    /* SELECT文字列作成 */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders.order_id';
      selectSql += ',orders.order_row';
      selectSql += ',orders.order_number';
      selectSql += ',orders.stock_scheduled';
      selectSql += ',offices.office_name_kanji';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',orders.product_id';
      selectSql += ',orders.product_name';
      selectSql += ',products.product_name_kanji AS product_name_master';
      selectSql += ',orders.service_class';
      selectSql += ',orders.unit';
      selectSql += ',orders.remaining_quantity AS stock_scheduled_quantity';
      selectSql += ',orders.stock_scheduled_confirm_class';
      selectSql += ',products.product_tax_rate_class_purchase';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      selectSql += 'INNER JOIN m_offices AS offices ';
      selectSql += 'ON orders.office_id = offices.office_id ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON orders.client_class = clients.client_class ';
      selectSql += 'AND orders.client_id = clients.client_id ';
      selectSql += 'INNER JOIN m_products AS products ';
      selectSql += 'ON orders.product_id = products.product_id ';
      selectSql += 'LEFT JOIN t_stocks AS stocks ';
      selectSql += 'ON orders.order_id = stocks.order_id ';
      selectSql += 'AND orders.order_row = stocks.order_row ';
      selectSql += 'AND stocks.stock_confirm_class <> ' + Const.StockConfirmClass.sumi + ' ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 削除済フラグ
      selectSql += ' orders.is_deleted = 0 ';
      // 発注書発行区分
      selectSql += 'AND orders.order_issue_class > ' + Const.OrderIssueClass.mi + ' ';
      // 発注種別（直送除外）
      selectSql += 'AND orders.order_class <> ' + Const.OrderClass.direct + ' ';
      // 「1：新規」（入荷データ未登録）
      selectSql += 'AND stocks.order_id IS NULL ';
      // 発注数が入荷確定数よりも大きい場合（入荷予定数が残っている）
      selectSql += 'AND orders.remaining_quantity > 0 ';
      // 営業所コード
      selectSql += 'AND orders.office_id = ' + this.officeId + ' ';
      // 注文番号
      if (this.orderNumber != '0') {
        selectSql += 'AND orders.order_number = ' + this.orderNumber + ' ';
      }
      // 取引先コード
      if (this.clientId != '0') {
        selectSql += 'AND orders.client_id = ' + this.clientId + ' ';
      }
      // 製品コード
      if (this.productId != '0') {
        selectSql += 'AND orders.product_id = ' + this.productId + ' ';
      }
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' orders.order_number';
      selectSql += ',orders.client_id';
      selectSql += ',orders.product_id';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0){
        // 営業所
        this.searchConditions.selectSalesOfficeText = this.officeId + '：' + result[0].office_name_kanji;
        // 注文番号
        this.searchConditions.orderNumber = this.orderNumber;
        // 取引先
        if (this.clientId == '0') {
          this.searchConditions.clientText = this.clientId;
        } else {
          this.searchConditions.clientText = this.clientId + '：' + result[0].client_name_kanji;
        }
        // 製品
        if (this.productId == '0') {
          this.searchConditions.productText = this.productId;
        } else {
          this.searchConditions.productText = this.productId + '：' + result[0].product_name_master;
        }
        // 総件数をdataTableの総件数にセット
        for(let i = 0; i < result.length; i++){
          let searchResult = {
            OrderId: result[i].order_id,
            OrderRow: result[i].order_row,
            StockScheduled: formatDate(result[i].stock_scheduled) + (result[i].stock_scheduled_confirm_class == 1 ? ' *' : ''),
            OrderNumber: result[i].order_number,
            ProductCode: result[i].product_id,
            ProductName: result[i].product_name,
            ProductTaxRateClass: result[i].product_tax_rate_class_purchase,
            ServiceClassText: getListValue(this.serviceList, result[i].service_class, 'id', 'text'),
            Unit: result[i].unit,
            StockScheduledQuantity: result[i].stock_scheduled_quantity,
            StockDate: formatCurDate('YYYY-MM-DD'),
            StockQuantity: 0,
          };
          this.itemList.push(searchResult);
        }
      }
    },
    // キャンセルボタン
    cancel: function() {
      //this.$router.push({ name: 'ORDER-STOCK-LIST' });
      window.close();
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.confirmMessage.push('※入荷数が0の行は保存されません。');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 排他制御チェック
      if (await isOtherSessionUpdatedBySqlResultObj(this.listUpdatedSelect) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2027']);
        return;
      }
      // 保存処理
      //console.log('保存処理開始');
      // 入荷データの登録(SQLの作成)
      if (await this.execSave() == true) {
        // 入荷一覧画面へ遷移
        //this.$router.push({ name: 'ORDER-STOCK-LIST', params: { parentKbn: 1 } });
        // 保存に成功した場合、画面を閉じる
        window.close();
      }
      //console.log('保存処理終了');
    },
    /* 保存実行 */
    async execSave() {
      const functionName = 'execSave';
      try {
        this.transactSqlList = [];
        // 入荷データの登録
        if (this.insertStocks() == false) {
          return false;
        }

        //console.log(this.transactSqlList);

        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.alertDanger.push(msg);
            return false;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
          return false;
        }

        // 作成した登録用SQLを全実行
        await executeSqlList(this.transactSqlList);

        return true;
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        console.log(error);
        return false;
      }
    },
    /* 入荷データ登録処理 */
    insertStocks: function() {
      //console.log('入荷データ登録SQL作成');
      // CRUD処理
      let bulkInsertSqlStart = '';
      let bulkInsertSqlSelect = '';
      for (let i = 0; i < this.itemList.length; i++) {
        // 入荷数が0以外
        if (this.itemList[i].StockQuantity != 0) {
          let colList = [];
          // INSERT文の先頭部分、初回のみ作成
          if (bulkInsertSqlStart == '') {
            // 発注番号
            colList.push(CreateColRow('order_id', null, 'NUMBER'));
            // 発注行番号
            colList.push(CreateColRow('order_row', null, 'NUMBER'));
            // 入荷日
            colList.push(CreateColRow('stock_date', null, 'DATE'));
            // 営業所コード
            colList.push(CreateColRow('office_id', null, 'NUMBER'));
            // 取引先区分
            colList.push(CreateColRow('client_class', null, 'NUMBER'));
            // 取引先コード
            colList.push(CreateColRow('client_id', null, 'NUMBER'));
            // 製品コード
            colList.push(CreateColRow('product_id', null, 'NUMBER'));
            // 入荷数
            colList.push(CreateColRow('stock_quantity', null, 'NUMBER'));
            // 入荷予定日
            colList.push(CreateColRow('stock_scheduled', null, 'DATE'));
            // 入荷予定日確定区分
            colList.push(CreateColRow('stock_scheduled_confirm_class', null, 'NUMBER'));
            // 入荷予定処理担当者コード
            colList.push(CreateColRow('stock_scheduled_staff_id', null, 'NUMBER'));
            // 注文番号
            colList.push(CreateColRow('order_number', null, 'NUMBER'));
            // 入荷確定区分
            colList.push(CreateColRow('stock_confirm_class', null, 'NUMBER'));
            // 作成ユーザー
            colList.push(CreateColRow('created_user', null, 'VARCHAR'));
            // 更新ユーザー
            colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
            bulkInsertSqlStart = 'INSERT INTO t_stocks (' + CreateInsertSql(colList, 'col', 't_stocks') + ') ';
            //console.log(bulkInsertSqlStart);
          }
          if (this.itemList[i].StockQuantity > this.itemList[i].StockScheduledQuantity) {
            // 入荷数が入荷予定数よりも大きい場合（入力チェックを超過した場合）
            // 入荷数と入荷予定数をログに出力
            this.alertWarning.push('入荷数が入荷予定数を超過しています。（' + (i + 1).toString() + '行目）');
            return false;
          }
          let selectSql = '';
          /* SELECT句 */
          selectSql += 'SELECT ';
          // 発注番号
          selectSql += ' order_id';
          // 発注行番号
          selectSql += ',order_row';
          // 入荷日
          selectSql += ',\'' + this.itemList[i].StockDate + '\'';
          // 営業所コード
          selectSql += ',office_id';
          // 取引先区分
          selectSql += ',client_class';
          // 取引先コード
          selectSql += ',client_id';
          // 製品コード
          selectSql += ',product_id';
          // 入荷数
          selectSql += ',' + this.itemList[i].StockQuantity;
          // 入荷予定日
          selectSql += ',stock_scheduled';
          // 入荷予定日確定区分
          selectSql += ',stock_scheduled_confirm_class';
          // 入荷予定処理担当者コード
          selectSql += ',stock_scheduled_staff_id';
          // 注文番号
          selectSql += ',order_number';
          // 入荷確定区分
          selectSql += ',' + Const.StockConfirmClass.mi;
          // 作成ユーザー
          selectSql += ',\'' + this.loginId + '\'';
          // 更新ユーザー
          selectSql += ',\'' + this.loginId + '\'';
          /* FROM句 */
          selectSql += ' FROM ';
          selectSql += 't_orders ';
          /* WHERE句 */
          selectSql += ' WHERE ';
          selectSql += 'order_id = ' + this.itemList[i].OrderId + ' ';
          selectSql += 'AND order_row = ' + this.itemList[i].OrderRow + ' ';
          //console.log(selectSql);
          if (bulkInsertSqlSelect != '') {
            bulkInsertSqlSelect += 'UNION ';
          }
          bulkInsertSqlSelect += selectSql;
        }
      }
      if (bulkInsertSqlStart != '') {
        this.transactSqlList.push({sql: bulkInsertSqlStart + bulkInsertSqlSelect, forUpdateFlg: 0});
      }
      return true;
    },
    /* カレンダーボタン押下時 */
    async openModal(item) {
      this.postItem = item;
      this.$bvModal.show('datepickerModal');
    },
    // 当日取得
    getToday: function() {
      return formatCurDate('YYYY-MM-DD');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* 更新日取得用SELECT文作成 */
    async createUpdatedSelect() {
      const functionName = 'createUpdatedSelect'
      let selectSqlOrders = '';
      let selectSqlStocks = '';
      let conditionOrders = []
      let conditionStocks = []
      /* SELECT句 Orders*/
      selectSqlOrders = 'SELECT';
      selectSqlOrders += ' updated';
      selectSqlOrders += ' FROM ';
      selectSqlOrders += 't_orders AS orders ';
      selectSqlOrders += ' WHERE ';

      /* SELECT句 Stocks*/
      selectSqlStocks = 'SELECT';
      selectSqlStocks += ' updated';
      selectSqlStocks += ' FROM ';
      selectSqlStocks += 't_stocks AS stocks ';
      selectSqlStocks += ' WHERE ';
      for (let i = 0; i < this.itemList.length; i++) {
        conditionOrders.push(`(orders.order_id = ${this.itemList[i].OrderId} AND orders.order_row = ${this.itemList[i].OrderRow})`)
        conditionStocks.push(`(stocks.order_id = ${this.itemList[i].OrderId} AND stocks.order_row = ${this.itemList[i].OrderRow})`)
      }
      selectSqlOrders += conditionOrders.join('OR')
      selectSqlStocks += conditionStocks.join('OR')

      try {
        // テーブルが空ではない場合
        if (conditionOrders.length > 0) {
          var [resultOrders, resultStocks] = await Promise.all([
            executeSelectSql(selectSqlOrders),
            executeSelectSql(selectSqlStocks)
          ])
          this.listUpdatedSelect.push({
            select: selectSqlOrders,
            resultArray: resultOrders
          })
          this.listUpdatedSelect.push({
            select: selectSqlStocks,
            resultArray: resultStocks
          })
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
  }
}
</script>