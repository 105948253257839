<template>
  <!-- 9A製品保守入力画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 9A製品保守入力</strong></h5>
                <router-link to="/products9A-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <div class="row mt-2">
                  <!-- 製品分類コード -->
                  <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required|numeric|between:9000,9999" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="product_class_id" class="form-label"><strong>製品分類コード</strong></label>
                        <input type="text" id="product_class_id" class="form-control" v-model="productObj.product_class_id" @input="searchProductId($event)" maxlength="4">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 枝番 -->
                  <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required|numeric|min:4|max:4" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="branchNumber" class="form-label"><strong>枝番</strong></label>
                        <input type="text" id="branchNumber" class="form-control" v-model="productObj.branchNumber" maxlength="4">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 製品名(漢字) -->
                  <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 form-group">
                    <validation-provider rules="max:35" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="product_name_kanji" class="form-label"><strong>製品名(漢字)</strong></label>
                        <input type="text" id="product_name_kanji" class="form-control" v-model="productObj.product_name_kanji" maxlength="35" v-b-tooltip.hover.noninteractive :title="productObj.product_name_kanji">
                        <small class="form-text text-muted">任意項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 製品名(カナ) -->
                  <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 form-group">
                    <validation-provider rules="max:40" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="product_name_kana" class="form-label"><strong>製品名(カナ)</strong></label>
                        <input type="text" id="product_name_kana" class="form-control" v-model="productObj.product_name_kana" maxlength="40" v-b-tooltip.hover.noninteractive :title="productObj.product_name_kana">
                        <small class="form-text text-muted">任意項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <!-- 単位 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="max:5" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="unit" class="form-label"><strong>単位</strong></label>
                        <input type="text" id="unit" class="form-control" v-model="productObj.unit" maxlength="5">
                        <small class="form-text text-muted">任意項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 売上単価 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required|numeric|max:8" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="sales_unit_price" class="form-label"><strong>売上単価</strong></label>
                        <input type="text" id="sales_unit_price" class="form-control" v-model="productObj.sales_unit_price" maxlength="8">
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 製品チェック区分 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="product_check_class" class="form-label"><strong>製品チェック区分</strong></label>
                          <b-select id="product_check_class" class="form-control" v-model="productObj.product_check_class" :options="Const.ProductCheckClass9A">
                            <template #first>
                              <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                            </template>
                          </b-select>
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 在庫管理区分 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <validation-provider rules="required" v-slot="{ classes, errors }">
                      <div :class="classes">
                        <label for="inventory_control_class" class="form-label"><strong>在庫管理区分</strong></label>
                        <b-select id="inventory_control_class" class="form-control" v-model="productObj.inventory_control_class" :options="Const.InventoryControlClass">
                          <template #first>
                            <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                          </template>
                        </b-select>
                        <small class="form-text text-muted">必須項目です。</small>
                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <!-- 売上消費税率区分 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <label for="product_tax_rate_class_sales" class="form-label">
                      <strong>売上消費税率区分</strong>
                    </label>
                    <b-select
                      id="product_tax_rate_class_sales"
                      class="form-control"
                      v-model="productObj.product_tax_rate_class_sales"
                      :options="productTaxRateClassList"
                      value-field="id"
                    >
                    </b-select>
                  </div>
                  <!-- 仕入消費税率区分 -->
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-2 col-xxl-2 form-group">
                    <label for="product_tax_rate_class_purchase" class="form-label">
                      <strong>仕入消費税率区分</strong>
                    </label>
                    <b-select
                      id="product_tax_rate_class_purchase"
                      class="form-control"
                      v-model="productObj.product_tax_rate_class_purchase"
                      :options="productTaxRateClassList"
                      value-field="id"
                    >
                    </b-select>
                  </div>
                </div>
                <!-- 営業所テーブル -->
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 form-group">
                    <b-table
                      show-empty
                      :head-variant="headVariant"
                      :responsive="true"
                      :items="productInfos"
                      :fields="productInfosTableFields"
                      :busy="busy"
                      :bordered="true"
                      :empty-text="emptyText"
                    >
                      <!-- テーブル読み込み時表示html -->
                      <template #table-busy>
                        <div class="text-center text-info my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>読み込んでいます...</strong>
                        </div>
                      </template>
                      <!-- 置き場所 -->
                      <template #cell(place)="data">
                        <validation-provider rules="max:11|place" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <input type="text" :id="'place' + data.index" class="form-control" v-model.lazy="data.item.place" maxlength="11" @blur="toUpperPlace(data.item);">
                            <small class="form-text text-muted">ハイフン区切りで入力してください。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 仕入単価 -->
                      <template #cell(purchase_price)="data">
                        <validation-provider rules="required|numeric|max:8" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.purchase_price">
                            <input type="text" :id="'purchase_price' + data.index" class="form-control" v-model="data.item.purchase_price" maxlength="8">
                            <small class="form-text text-muted">必須項目です。最大8桁</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 仕入単価修正摘要 -->
                      <template #cell(summary)="data">
                        <validation-provider rules="max:50" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.summary">
                            <input type="text" :id="'summary' + data.index" class="form-control" v-model="data.item.summary" maxlength="50">
                            <small class="form-text text-muted">仕入単価を訂正した場合に日付を入力</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 仕入先製品コード -->
                      <template #cell(supplier_product_id)="data">
                        <validation-provider rules="max:25" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.supplier_product_id">
                            <input type="text" :id="'supplier_product_id' + data.index" class="form-control" v-model="data.item.supplier_product_id" maxlength="25">
                            <small class="form-text text-muted">仕入先製品コード</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- ピッキング表印刷区分 -->
                      <template #cell(picking_print_class)="data">
                        <validation-provider rules="required" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-select :id="'picking_print_class' + data.index" class="form-control" v-model="data.item.picking_print_class" :options="Const.PickingPrintClass">
                              <template #first>
                                <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                              </template>
                            </b-select>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 発注先コード -->
                      <template #cell(client_id)="data">
                        <validation-provider rules="numeric|min:6|max:6" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.client_id">
                            <div class="input-group">
                              <input 
                                type="text" 
                                :id="'client_id' + data.index" 
                                class="form-control" 
                                :disabled="relationInventoryControlClass"
                                @input="searchClient(data.item.client_id, data.item.office_id)" 
                                maxlength="6"
                                v-model="data.item.client_id"
                              >
                              <!-- 発注先コード検索ボタン -->
                              <b-input-group-text v-show="!relationInventoryControlClass" @click="showClientSearchModal(data.item.office_id)">
                                <span class="oi oi-magnifying-glass"></span>
                              </b-input-group-text>
                            </div>
                            <small class="form-text text-muted">必須項目。検索ボタンで入力。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- サービス区分 -->
                      <template #cell(service_whether_class)="data">
                        <validation-provider rules="required" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-select :id="'service_whether_class' + data.index" :disabled="relationInventoryControlClass" class="form-control" v-model="data.item.service_whether_class" :options="Const.ServiceWhetherClass">
                              <template #first>
                                <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                              </template>
                            </b-select>
                            <small class="form-text text-muted">必須項目。発注書の際にサービス分は分けて表記</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- サービス個数 -->
                      <template #cell(service_quantity)="data">
                        <!-- サービス区分が「1:なし」の場合 -->
                        <validation-provider v-if="data.item.service_whether_class == 1" rules="required|numeric|max:3" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.service_quantity">
                            <input readonly type="text" :id="'service_quantity' + data.index" class="form-control" v-model="data.item.service_quantity">
                            <small class="form-text text-muted">必須項目。無償でもらえる数量。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                        <!-- サービス区分が「0:あり」の場合 -->
                        <validation-provider v-else rules="required|numeric|min_value:1|max:3" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.service_quantity">
                            <input type="text" :id="'service_quantity' + data.index" class="form-control" v-model="data.item.service_quantity"  maxlength="3">
                            <small class="form-text text-muted">必須項目。無償でもらえる数量。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- ケース換算区分 -->
                      <template #cell(case_conversion_class)="data">
                        <validation-provider rules="required" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-select :id="'case_conversion_class' + data.index" :disabled="relationInventoryControlClass" class="form-control" v-model="data.item.case_conversion_class" :options="Const.CaseConversionClass">
                              <template #first>
                                <b-form-select-option :value="''" disabled>選択してください</b-form-select-option>
                              </template>
                            </b-select>
                            <small class="form-text text-muted">必須項目。ケースで発注する場合</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 適正在庫数 -->
                      <template #cell(appropriate_stock)="data">
                        <validation-provider rules="required|numeric|max:4" v-slot="{ classes, errors }">
                          <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.appropriate_stock">
                            <input type="text" :id="'appropriate_stock' + data.index" :disabled="relationInventoryControlClass" class="form-control" v-model="data.item.appropriate_stock" maxlength="4">
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 最低発注数 -->
                      <template #cell(minimum_quantity)="data">
                        <!-- ケース換算区分が0:換算するで設定する場合 -->
                        <div v-if="data.item.case_conversion_class == 0">
                          <validation-provider rules="required|numeric" v-slot="{ classes, errors }">
                            <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.minimum_quantity">
                              <input type="text" :id="'minimum_quantity' + data.index" class="form-control" v-model="data.item.minimum_quantity" readonly>
                              <small class="form-text text-muted">必須項目です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <div v-else>
                          <validation-provider rules="required|numeric|between:1,9999" v-slot="{ classes, errors }">
                            <div :class="classes" v-b-tooltip.hover.noninteractive :title="data.item.minimum_quantity">
                              <input type="text" :id="'minimum_quantity' + data.index" :disabled="relationInventoryControlClass" class="form-control" v-model="data.item.minimum_quantity" maxlength="4">
                              <small class="form-text text-muted">必須項目です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                      </template>
                      <!-- 発注ロット数 -->
                      <template #cell(lots_quantity)="data">
                        <validation-provider rules="required|numeric|max:4" v-slot="{ classes, errors }">
                          <div :class="classes"  v-b-tooltip.hover.noninteractive :title="data.item.lots_quantity">
                            <input type="text" :id="'lots_quantity' + data.index" :disabled="relationInventoryControlClass" class="form-control" v-model="data.item.lots_quantity" maxlength="4">
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- 入庫リードタイム -->
                      <template #cell(lead_time)="data">
                        <validation-provider rules="required|numeric|between:0,99" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <input type="text" :id="'lead_time' + data.index" :disabled="relationInventoryControlClass" class="form-control" v-model="data.item.lead_time" maxlength="2">
                            <small class="form-text text-muted">必須項目。0:当日,1:翌日...</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <template #cell(operation)="operation">
                        <!-- コピーボタン -->
                        <b-button size="sm" @click="clickCopyBtn(operation.item.office_id)" class="mr-1">
                          <span class="oi oi-task"></span> コピー
                        </b-button>
                        <!-- 貼り付けボタン -->
                        <b-button size="sm" @click="clickPasteBtn(operation.item.office_id)" class="mr-1">
                          <span class="oi oi-pencil"></span> 貼り付け
                        </b-button>
                        <!-- スキップボタン -->
                        <b-button size="sm" v-b-tooltip.hover.noninteractive title="入れるデータ分からない場合はスキップしてください" @click="clickSkipOffice(operation.item.office_id)" class="mr-1">
                          <span class="oi oi-delete"></span> スキップ
                        </b-button>
                      </template>
                    </b-table>
                  </div>
                </div>  
              </validation-observer>
            </div>
             <!-- 保存ボタン -->
            <div class="card-footer">
              <div class="row justify-content-md-center pb-4">
                <div class="col-lg-2">
                  <button type="button" class="btn btn-primary btn-block" @click="save"><span class="oi oi-circle-check"></span> 保存</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●取引先マスタ検索モーダル●●● -->
    <ClientSearch @from-child="closeClientSearchModal" :client-class="propClientClass"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { addOperationLogs, init, getNullStr, CreateColRow, CreateInsertSql, CreateUpdateSql, escapeQuote, getUserCol, getControlMaster, isSystemEditable, executeTransactSqlList, selectOneTable } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import ClientSearch from '@/components/modal/client-search.vue';
import { searchClientModal } from '@/assets/js/searchModal.js';
import moment from 'moment';

// ログ出力用モジュール名
const MODULE_NAME = 'product9A-input';

export default {
  name: 'PRODUCT9A-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    ClientSearch,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '9A製品保守入力',
      // 営業所ごとテーブル
      productInfosTableFields: DataTblDef.productInfosTableFields_9A,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      busy: false,
      // ユーザ名
      username: this.$store.getters.user.username,
      // 今日の日にち
      today: '',
      // 現在処理年月
      processMonthYear: null,
      // 製品対象
      productObj: {
        product_class_id: null,
        branchNumber: null,
        inventory_control_class: null,
      },
      // 消費税率区分
      productTaxRateClassList: Const.ProductTaxRateClassList,
      // 取引先モーダル用の引数, 仕入先
      propClientClass: Const.ClientClass.supplier,
      // トランザクションSQLリスト
      transactSqlList: [],
      // 製品対象詳細
      productInfos:[],
      // 製品対象詳細のコピー対象
      productInfoCopy:{},
      // 対象コード採番フラグ
      searchCode: true,
      // 新規できない対象コードリスト
      insertDisableArray: [],
      // 区分リスト対象
      Const: {...Const},
      // 営業所リスト
      officeList: [],
      // 在庫管理区分が「しない」で設定する場合
      relationInventoryControlClass: false,
      // 処理結果エラーメッセージ
      errorMessages: [],
      // 確認ダイアログ用
      confirmMessage: [],
    }
  },
  /** 監視 */
  watch: {
    productObj: {
      handler(productObj) {
        // 在庫管理区分がしないに設定される時の対応
        if (productObj.inventory_control_class == 1) {
          this.relationInventoryControlClass = true
          this.productInfos = this.productInfos.map((product) => {
            // 発注先コード
            product.client_id = ''
            product.client_name_kanji = ''
            // サービス区分
            product.service_whether_class = 1
            // ケース換算区分
            product.case_conversion_class = 1
            // 適正在庫数
            product.appropriate_stock = 0
            // 最低発注数
            product.minimum_quantity = 1
            // 発注ロット数
            product.lots_quantity = 0
            // 入庫リードタイム
            product.lead_time = 0
            return product
          })
        } else {
          this.relationInventoryControlClass = false
        }
      },
      deep: true,
      immediate: false,
    },
    productInfos: {
      handler(productInfos) {
        // サービス区分の監視
        for (const key in productInfos) {
          if (productInfos[key].service_whether_class == 1) {
            // 無償サービス個数を0に設定
            productInfos[key].service_quantity = 0
          }
        }
        // 置き場所の監視
        for (const key in productInfos) {
          if (productInfos[key].place != undefined) {
            if (productInfos[key].place === '') {
              productInfos[key].place_1 = ''
              productInfos[key].place_2 = ''
              productInfos[key].place_3 = ''
              productInfos[key].place_4 = ''
              break
            }
            const placeArr = productInfos[key].place.split('-')
            productInfos[key].place_1 = placeArr[0]
            productInfos[key].place_2 = placeArr[1]
            productInfos[key].place_3 = placeArr[2]
            productInfos[key].place_4 = placeArr[3]
          }
        }
        // ケース換算区分が0:換算するに設定される時の対応
        for (const key in productInfos) {
          if (productInfos[key].case_conversion_class == 0) {
            // 無償サービス個数を0に設定
            productInfos[key].minimum_quantity = 0
          }
        }
      },
      deep: true,
      immediate: false,
    },
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    // 営業所リストを取得
    this.officeList = await selectOneTable('m_offices');
    await this.fetchData();
    init(); // common.jsにて初期化処理
    this.today = moment(new Date()).format('YYYY/MM/DD');
    scrollTo(0,0);
    this.$store.commit('setLoading', false);
  },
  methods:{
    // 発注先コード直接入力 - 取引先検索
    async searchClient(client_id, office_id) {
      const functionName = 'searchClient';
      if (client_id != '') {
        const result = await searchClientModal(client_id, this.propClientClass, functionName, MODULE_NAME);
        for (const key in this.productInfos) {
          if (this.productInfos[key].office_id == office_id) {
            this.productInfos[key].clientResearch = true;
          } else {
            this.productInfos[key].clientResearch = false;
          }
        }
        if (typeof result != 'undefined') {
          this.closeClientSearchModal(result);
        } else {
          // 取引先コード6桁かつデータ取得失敗の場合
          if (client_id.length == 6) {
            // 取引先クリア
            this.closeClientSearchModal({});
          }
        }
      } else {
        for (const key in this.productInfos) {
          if (this.productInfos[key].office_id == office_id) {
            this.productInfos[key].client_id = '';
            this.productInfos[key].client_name_kanji = '';
            break;
          }
        }
      }
    },
    /**
     * 発注先検索モーダル表示
     */
    showClientSearchModal(office_id) {
      for (const key in this.productInfos) {
        if (this.productInfos[key].office_id == office_id) {
          this.productInfos[key].clientResearch = true
        } else {
          this.productInfos[key].clientResearch = false
        }
      }
      this.$bvModal.show('clientSearchModal');
    },
    /**
     * 発注先検索モーダルコールバック
     * @param {Object} choiceData - 選択されたデータ
     */
    closeClientSearchModal(choiceData) {
      for (const key in this.productInfos) {
        if (this.productInfos[key].clientResearch) {
          this.productInfos[key].client_id = choiceData.clientId == undefined ? '' : choiceData.clientId;
          this.productInfos[key].client_name_kanji = choiceData.clientNameKanji == undefined ? '' : choiceData.clientNameKanji;
          this.productInfos[key].clientResearch = false
        }
      }
    },
    /**
     * 画面に表示するデータを取得します。
     */
    async fetchData() {
      this.busy = true;
      let productInfos = []
      // 現在処理年月
      let controlData = await getControlMaster();
      this.processMonthYear = controlData.process_month_year;
      // 営業所ごと製品詳細をセットする
      for (const key in this.officeList) {
        let office = this.officeList[key]
        const officeProductInfo = {
          client_id: '', //発注先コード
          client_name_kanji: '', //発注先名
          minimum_quantity: '', //最低発注数
          service_quantity: '', //無償サービス個数
          lots_quantity: '', //発注ロット数
          lead_time: '', //入庫リードタイム
          service_whether_class: '', //サービス区分
          purchase_price: '', //仕入単価
          place: '', //置き場所
          place_1: '', //置き場所1
          place_2: '', //置き場所2
          place_3: '', //置き場所3
          place_4: '', //置き場所4
          picking_print_class: '', //ピッキング表印刷区分
          appropriate_stock: '', //適正在庫数
          case_conversion_class: '', //ケース換算区分
          supplier_product_id: '', //仕入先製品コード
          summary: '', //摘要
        }
        productInfos.push({...officeProductInfo, ...office})
      }
     
      // データセット 
      this.productInfos = [...productInfos] 
      // 初期値セット
      // 製品チェック区分
      this.productObj.product_check_class = '0';
      // 在庫管理区分
      this.productObj.inventory_control_class = Const.InventoryControlClassDef.inventory;
      // 売上消費税率区分
      this.productObj.product_tax_rate_class_sales = Const.ProductTaxRateClass.normalTax;
      // 仕入消費税率区分
      this.productObj.product_tax_rate_class_purchase = Const.ProductTaxRateClass.normalTax;
      this.busy = false;
    },
  
    /**
     * 製品コードの自動採番。
     * @returns {Int} 製品コード
     */
    async searchProductId(e) {
      const functionName = 'searchProductId';
      const product_class_id = e.target.value
      // 4桁から自動採番する
      if (product_class_id.length < 4) {
        this.searchCode = true
        this.insertDisableArray = []
      }
      if (product_class_id.length === 4 && this.searchCode){
        this.errorMessages = []
        this.searchCode = false
        let result = null;
        let resultDeletedProductId = null;
        let resultProducts9AClasses = null;
        // 製品マスタから検索
        let sqls = `select product_id from m_products
        where product_class_id  = ${product_class_id}`
        // 製品削除トランザクションから検索
        let sqlsDeletedProductId = `select product_id from t_products_deleted_log
        where product_id  LIKE '${product_class_id}%'`
        // 9Aコード管理マスタから検索
        let sqlsProducts9AClasses = `select sequence, max from m_9a_products_classes
        where product_class_id  = ${product_class_id}`
        try {
          // sql実行
          [result, resultDeletedProductId, resultProducts9AClasses] = await Promise.all([
            API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls })),
            API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqlsDeletedProductId })),
            API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqlsProducts9AClasses }))
          ])
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqls
          }, error);
          this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
          return;
        }
        if (result.errors) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqls,
            result: result
          });
          this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
          return;
        } else if (resultDeletedProductId.errors) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqlsDeletedProductId,
            result: result
          });
          this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
          return;
        } else if (resultProducts9AClasses.errors) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'executeTransactSql',
            SQLs: sqlsProducts9AClasses,
            result: result
          });
          this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
          return;
        }
        const bodyProducts9AClasses = JSON.parse(resultProducts9AClasses.data.executeTransactSql.body);
        
        //  9Aコード作成された場合
        if (bodyProducts9AClasses.data[0].length != 0) {
          // 現在No.
          const productsNumSequence = bodyProducts9AClasses.data[0][0].sequence
          const productsNumSequenceLength = String(productsNumSequence).length
          this.productObj.branchNumber = '0000'.slice(0, 4-productsNumSequenceLength) + productsNumSequence
          return
        }

        //  9Aコード作成されていない場合
        const body = JSON.parse(result.data.executeTransactSql.body);
        const bodyDeletedProductId = JSON.parse(resultDeletedProductId.data.executeTransactSql.body);
        // 二つテーブル結果を一時結合する
        const temp = body.data[0].concat(bodyDeletedProductId.data[0])
        if (temp.length === 0) {
          // このコード範囲内まだ未新規場合
          this.productObj.product_class_id = Number(String(product_class_id))
          this.productObj.branchNumber = '0000'
          return
        }
        // 新規できない製品コードを追加
        this.insertDisableArray = temp.map((item) => {
          return item.product_id
        })
        // 最大コードを採番
        const max = Math.max.apply(Math, this.insertDisableArray) + 1
        // 9999まで使われた
        if ((max % 10000) == 0) {
          this.errorMessages.push('枝番9999まで使われました、別の製品分類コード使ってください。');
          return
        }
        const branchNumber = String(max).slice(4,8)
        this.productObj.branchNumber = branchNumber
        return
      }
    },
    /**
     * 製品コードで検索します。
     * @param {Int} productId  - 製品コード
     * @returns {boolean} 製品ID重複してるかの確認
     */
    async productIdConfirm(product_id) {
      const functionName = 'productIdConfirm';

      let result = null;
      // 製品マスタと製品削除履歴結合検索
      let sqls = `select product_id from m_products left join t_products_deleted_log using(product_id) 
       where product_id = ${product_id} 
       union
       select product_id from m_products right join t_products_deleted_log using(product_id) 
       where product_id = ${product_id} 
       `
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      return body.data[0].length == 0
    },
    /**
     * 営業所をスキップ
     */
    async clickSkipOffice (officeId) {
      this.$bvModal.msgBoxConfirm('この営業所の製品登録をスキップします。\nよろしいですか？').then(async value => {
        if (value) {
          this.productInfos = this.productInfos.filter((productInfo) => {
            return productInfo.office_id != officeId
          })
        }
      });
    },
    /**
     * 営業所詳細をコピー
     */
    async clickCopyBtn (officeId) {
      const copyObj = this.productInfos.filter((productInfo) => {
        return productInfo.office_id == officeId
      })
      this.productInfoCopy = {...copyObj[0]}
      this.$bvToast.toast('コピー成功しました', {
        title: `営業所:${this.productInfoCopy.office_name_kanji}`,
        variant: 'success',
        autoHideDelay: 300,
        toaster: 'b-toaster-bottom-right',
        appendToast: true,
      })
    },
    /**
     * 営業所詳細を貼り付け
     */
    async clickPasteBtn (officeId) {
      for (const key in this.productInfos) {
        if (this.productInfos[key].office_id === officeId) {
          this.productInfoCopy.office_id = officeId
          // 各項目の貼り付け
          this.productInfos[key] = {
            ...this.productInfos[key],
            client_id: this.productInfoCopy.client_id,
            client_name_kanji: this.productInfoCopy.client_name_kanji,
            minimum_quantity: this.productInfoCopy.minimum_quantity,
            service_quantity: this.productInfoCopy.service_quantity,
            lots_quantity: this.productInfoCopy.lots_quantity,
            lead_time: this.productInfoCopy.lead_time,
            service_whether_class: this.productInfoCopy.service_whether_class,
            purchase_price: this.productInfoCopy.purchase_price,
            place: this.productInfoCopy.place,
            place_1: this.productInfoCopy.place_1,
            place_2: this.productInfoCopy.place_2,
            place_3: this.productInfoCopy.place_3,
            place_4: this.productInfoCopy.place_4,
            picking_print_class: this.productInfoCopy.picking_print_class,
            appropriate_stock: this.productInfoCopy.appropriate_stock,
            case_conversion_class: this.productInfoCopy.case_conversion_class,
            supplier_product_id: this.productInfoCopy.supplier_product_id,
            summary: this.productInfoCopy.summary,
          }
        }
      }
      this.productInfos = [...this.productInfos]
    },
    /**
     * 保存ボタンの押下
     */
    async save() {
      this.$store.commit('setLoading', true);
      this.errorMessages = [];

      if (await this.$refs.observer.validate()) {
        await this.saveConfirm();
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }

      this.$store.commit('setLoading', false);
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          //console.log('保存処理開始');
          this.$store.commit('setLoading', true);
          await this.execInsert();
          if (this.errorMessages.length === 0) {
            const product = await this.searchProduct()
            this.$router.push({ 
              name: 'PRODUCTS9A-INQUIRY', 
              query: { id: product.id },
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1001']] }  
            });
          }
          this.$store.commit('setLoading', false);
          //console.log('保存処理終了');
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.errorMessages.length != 0) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 新規処理
     */
    async execInsert() {
      const functionName = 'execInsert';
      // 最初に初期化
      this.transactSqlList = [];
      // 製品コード
      // 重複あるかどうか確認
      // 製品コードの作成
      this.productObj.product_id = Number(String(this.productObj.product_class_id) + this.productObj.branchNumber)
      const productIdConfirm = await this.productIdConfirm(this.productObj.product_id)
      if (!productIdConfirm) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
        }, 'ProductId is not available');
        this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2013']);
        return;
      } 
      // 枝番が最小No.～最大No.の範囲内確認
      const products9AClasses = await this.searchProducts9AClasses()
      // 9Aコード存在する場合
      if (products9AClasses != null) {
        if (Number(this.productObj.branchNumber) < Number(products9AClasses.min) || Number(this.productObj.branchNumber) > Number(products9AClasses.max)) {
          this.errorMessages.push(`枝番が最小${products9AClasses.min}～最大${products9AClasses.max}の範囲内必要があります。`);
          return
        }
        // 9Aコードマスタ更新
        if (this.productObj.branchNumber == products9AClasses.sequence) {
          let result = null 
          let resultDeletedProductId = null 
          // 製品マスタから検索
          let sqls = `select product_id from m_products
          where product_class_id  = ${this.productObj.product_class_id}`
          // 製品削除トランザクションから検索
          let sqlsDeletedProductId = `select product_id from t_products_deleted_log
          where product_id  LIKE '${this.productObj.product_class_id}%'`
          try {
          // sql実行
            [result, resultDeletedProductId] = await Promise.all([
              API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls })),
              API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqlsDeletedProductId })),
            ])
          } catch (error) {
            await addOperationLogs('Error', MODULE_NAME, functionName, {
              graphqlOperation: 'executeTransactSql',
              SQLs: sqls
            }, error);
            this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
            return;
          }
          if (result.errors) {
            await addOperationLogs('Error', MODULE_NAME, functionName, {
              graphqlOperation: 'executeTransactSql',
              SQLs: sqls,
              result: result
            });
            this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
            return;
          } else if (resultDeletedProductId.errors) {
            await addOperationLogs('Error', MODULE_NAME, functionName, {
              graphqlOperation: 'executeTransactSql',
              SQLs: sqlsDeletedProductId,
              result: result
            });
            this.errorMessages.push(DISP_MESSAGES.DANGER['3003']);
            return;
          }
          //  9Aコード作成されていない場合
          const body = JSON.parse(result.data.executeTransactSql.body);
          const bodyDeletedProductId = JSON.parse(resultDeletedProductId.data.executeTransactSql.body);
          // 二つテーブル結果を一時結合する
          const temp = body.data[0].concat(bodyDeletedProductId.data[0])

          if (temp.length === 0) {
            // 次のシーケンス使われっていない場合プラス1
            await this.execUpdateProducts9AClasses(Number(products9AClasses.sequence) + 1)
          } else {
            // 新規できない製品コードを追加
            const insertDisableArray = temp.map((item) => {
              return item.product_id
            })
            const some = insertDisableArray.some((item) => {
              return Number(String(item).slice(4, 8)) == Number(products9AClasses.sequence) + 1
            })
            // 次のシーケンス使われた場合最大の枝番プラス1
            if (some) {
              const max = Math.max.apply(Math, insertDisableArray) + 1
              // 9999まで使われていない、更新します
              if ((max % 10000) != 0) {
                const sequence = String(max).slice(4, 8)
                await this.execUpdateProducts9AClasses(sequence)
              }
            } else {
              // 次のシーケンス使われっていない場合プラス1
              await this.execUpdateProducts9AClasses(Number(products9AClasses.sequence) + 1)
            }
          }
        }
      }
     
      await Promise.all([
        // 製品マスタ新規
        this.execInsertProduct(),
        // 在庫マスタ新規
        this.execInsertStock(),
        // 製品実績マスタ新規
        this.execInsertProductsResults(),
      ])
     
      // 製品発注マスタと製品詳細マスタの登録
      for (const key in this.productInfos) {
        await this.execInsertOfficeProduct(this.productInfos[key])
      }

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
      } else {
        // 成功時にログを出力
        await addOperationLogs('Info', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: this.transactSqlList,
        });
      }
    },

    /**
     * 製品の登録処理
     */
    async execInsertProduct(){
      const productClassId = Number(this.productObj.product_id.toString().slice(0, 4))
      const productColList = [];
     
      // 製品コード
      productColList.push(CreateColRow('product_id', this.productObj.product_id, 'INT'));
      // 製品分類コード
      productColList.push(CreateColRow('product_class_id', productClassId, 'INT'));
      // 9Aフラグ--9A製品です
      productColList.push(CreateColRow('is_9A', 1, 'INT'));
      // 単位
      if (getNullStr(this.productObj.unit).trim() == '') {
        // 空白の場合は全角スペースを入れる
        productColList.push(CreateColRow('unit', '　', 'VARCHAR'));
      } else {
        productColList.push(CreateColRow('unit', await escapeQuote(this.productObj.unit), 'VARCHAR'));
      }
      // 製品名(漢字)
      if (getNullStr(this.productObj.product_name_kanji).trim() == '') {
        // 空白の場合は全角スペースを入れる
        productColList.push(CreateColRow('product_name_kanji', '　', 'VARCHAR'));
      } else {
        productColList.push(CreateColRow('product_name_kanji', await escapeQuote(this.productObj.product_name_kanji), 'VARCHAR'));
      }
      // 製品名(カナ)
      if (getNullStr(this.productObj.product_name_kana).trim() == '') {
        // 空白の場合は全角スペースを入れる
        productColList.push(CreateColRow('product_name_kana', '　', 'VARCHAR'));
      } else {
        productColList.push(CreateColRow('product_name_kana', await escapeQuote(this.productObj.product_name_kana), 'VARCHAR'));
      }
      // 定価 暗黙値0入れます
      productColList.push(CreateColRow('regular_price', 0, 'INT'));
      // 売上単価
      productColList.push(CreateColRow('sales_unit_price', Number(this.productObj.sales_unit_price), 'INT'));
      // 製品チェック区分
      productColList.push(CreateColRow('product_check_class', Number(this.productObj.product_check_class), 'INT'));
      // 諸口区分 暗黙値0入れます
      productColList.push(CreateColRow('sundries_class', 0, 'INT'));
      // 在庫管理区分
      productColList.push(CreateColRow('inventory_control_class', Number(this.productObj.inventory_control_class), 'INT'));
      // 売上消費税率区分
      productColList.push(CreateColRow('product_tax_rate_class_sales', Number(this.productObj.product_tax_rate_class_sales), 'INT'));
      // 仕入消費税率区分
      productColList.push(CreateColRow('product_tax_rate_class_purchase', Number(this.productObj.product_tax_rate_class_purchase), 'INT'));
      // 新規と更新ユーザー
      const colBothUser = await getUserCol(this.username, 'both')
      
      // 製品マスタ
      // SQL実行
      const sqlProduct = CreateInsertSql(productColList.concat(colBothUser), 'full', 'm_products');
      this.transactSqlList.push(sqlProduct)
    },

    /**
      * 在庫マスタ
      * 登録処理
      */
    async execInsertStock() {
      let sqlInsertCol = ''
      let sqlInsertVal = []
      for (const key in this.officeList) {
        const office = this.officeList[key]
        const colList = [];
        // 年月
        colList.push(CreateColRow('month_year', Number(this.processMonthYear), 'INT'));
        // 営業所コード
        colList.push(CreateColRow('office_id', Number(office.office_id), 'INT'));
        // 製品コード
        colList.push(CreateColRow('product_id', Number(this.productObj.product_id), 'INT'));
        // 残高数
        colList.push(CreateColRow('balance', 0, 'INT'));
        // 月次仕入入庫数
        colList.push(CreateColRow('monthly_purchase_stock', 0, 'INT'));
        // 月次調整入庫数
        colList.push(CreateColRow('monthly_tuning_stock', 0, 'INT'));
        // 月次売上出庫数
        colList.push(CreateColRow('monthly_salse_ship', 0, 'INT'));
        // 月次調整出庫数
        colList.push(CreateColRow('monthly_tuning_ship', 0, 'INT'));
        // 在庫引当数 暗黙値0
        colList.push(CreateColRow('inventory_reserve_count', 0, 'INT'));
        // 入荷予定数 暗黙値0
        colList.push(CreateColRow('quantity_stock', 0, 'INT'));
        // 入荷予定引当数 暗黙値0
        colList.push(CreateColRow('quantity_stock_reserve', 0, 'INT'));
        // 新規と更新ユーザー
        const colBothUser = await getUserCol(this.username, 'both')

        // sql insert カラム部分
        sqlInsertCol = CreateInsertSql(colList.concat(colBothUser), 'col', 'm_stocks').split(',');
        // sql insert values部分
        sqlInsertVal.push(CreateInsertSql(colList.concat(colBothUser), 'val', 'm_stocks').split(','));
      }

      let sql = `INSERT INTO m_stocks (${sqlInsertCol}) VALUES `
      let insertArr = []
      for (const key in sqlInsertVal) {
        insertArr.push(`(${sqlInsertVal[key].join()})`)
      }
      sql = `${sql} ${insertArr.join()}`
      this.transactSqlList.push(sql)
    },
    /**
      * 製品実績マスタ
      * 登録処理
      */
    async execInsertProductsResults() {
      let sqlInsertCol = ''
      let sqlInsertVal = []
      for (const key in this.officeList) {
        const office = this.officeList[key]
        const colList = [];
        // 処理年月
        colList.push(CreateColRow('month_year', Number(this.processMonthYear), 'INT'));
        // 営業所分類コード
        colList.push(CreateColRow('office_class_id', Number(office.office_id), 'INT'));
        // 製品コード
        colList.push(CreateColRow('product_id', Number(this.productObj.product_id), 'INT'));
        // 製品分類コード
        colList.push(CreateColRow('product_class_id', Number(this.productObj.product_class_id), 'INT'));
        // 仕入数量
        colList.push(CreateColRow('purchase_stock_count', 0, 'INT'));
        // 仕入返品数量
        colList.push(CreateColRow('purchase_stock_return_count', 0, 'INT'));
        // 総仕入数量
        colList.push(CreateColRow('purchase_stock_total_count', 0, 'INT'));
        // 仕入額
        colList.push(CreateColRow('purchase_stock_amount', 0, 'INT'));
        // 仕入返品額
        colList.push(CreateColRow('purchase_stock_return_amount', 0, 'INT'));
        // 総仕入額
        colList.push(CreateColRow('purchase_stock_total_amount', 0, 'INT'));
        // 売上数量
        colList.push(CreateColRow('sales_count', 0, 'INT'));
        // 売上返品数量
        colList.push(CreateColRow('sales_return_count', 0, 'INT'));
        // 総売上数量
        colList.push(CreateColRow('sales_total_count', 0, 'INT'));
        // 売上額
        colList.push(CreateColRow('sales_amount', 0, 'INT'));
        // 売上返品額
        colList.push(CreateColRow('sales_return_amount', 0, 'INT'));
        // 総売上額
        colList.push(CreateColRow('sales_total_amount', 0, 'INT'));
        // 粗利額
        colList.push(CreateColRow('gross_profit', 0, 'INT'));
        // 新規と更新ユーザー
        const colBothUser = await getUserCol(this.username, 'both')
        // sql insert カラム部分
        sqlInsertCol = CreateInsertSql(colList.concat(colBothUser), 'col', 't_products_results').split(',');
        // sql insert values部分
        sqlInsertVal.push(CreateInsertSql(colList.concat(colBothUser), 'val', 't_products_results').split(','));
      }

      let sql = `INSERT INTO t_products_results (${sqlInsertCol}) VALUES `
      let insertArr = []
      for (const key in sqlInsertVal) {
        insertArr.push(`(${sqlInsertVal[key].join()})`)
      }
      sql = `${sql} ${insertArr.join()}`
      this.transactSqlList.push(sql)
    },
    /**
      * 9Aコードマスタ
      * 更新処理
      */
    async execUpdateProducts9AClasses(sequence) {
      const colList = [];
      // シーケンス
      colList.push(CreateColRow('sequence', sequence, 'INT'));
      // 更新ユーザー
      const colUser = await getUserCol(this.username, 'update')

      const sql = `${CreateUpdateSql(colList.concat(colUser), 'm_9a_products_classes')} 
                    WHERE 
                    product_class_id = ${this.productObj.product_class_id}`;

      this.transactSqlList.push(sql)
    },

    /**
     * 営業所に製品の登録処理
     */
    async execInsertOfficeProduct(officeProduct){
      const product_id = this.productObj.product_id
      const office_id = officeProduct.office_id

      // 新規と更新ユーザー
      const colBothUser = await getUserCol(this.username, 'both')

      // 製品発注マスタを追加 
      const colProductsOrders = [];
      // 製品コード
      colProductsOrders.push(CreateColRow('product_id', product_id, 'INT'));
      // 営業所コード
      colProductsOrders.push(CreateColRow('office_id', office_id, 'INT'));
      // 発注先コード
      colProductsOrders.push(CreateColRow('client_id', getNullStr(officeProduct.client_id) == '' ? 0 : officeProduct.client_id, 'INT'));
      // 最低発注数
      colProductsOrders.push(CreateColRow('minimum_quantity', officeProduct.minimum_quantity, 'INT'));
      // 発注時ｻｰﾋﾞｽ数
      colProductsOrders.push(CreateColRow('service_quantity', officeProduct.service_quantity, 'INT'));
      // 発注ロット数
      colProductsOrders.push(CreateColRow('lots_quantity', officeProduct.lots_quantity, 'INT'));
      // 入庫ﾘｰﾄﾞﾀｲﾑ
      colProductsOrders.push(CreateColRow('lead_time', officeProduct.lead_time, 'INT'));
      // サービス有無区分
      colProductsOrders.push(CreateColRow('service_whether_class', officeProduct.service_whether_class, 'INT'));
      // SQL実行
      const sqlProductsOrders = CreateInsertSql(colProductsOrders.concat(colBothUser), 'full', 'm_products_orders');

      // 製品詳細マスタを追加  
      const colProductsDetails = [];
      // 製品コード
      colProductsDetails.push(CreateColRow('product_id', product_id, 'INT'));
      // 営業所コード
      colProductsDetails.push(CreateColRow('office_id', office_id, 'INT'));
      // 仕入単価
      colProductsDetails.push(CreateColRow('purchase_price', officeProduct.purchase_price, 'INT'));
      // 原価単価 暗黙値0入れます
      colProductsDetails.push(CreateColRow('cost_price', 0, 'INT'));
      // 置き場所1
      colProductsDetails.push(CreateColRow('place_1', officeProduct.place_1, 'VARCHAR'));
      // 置き場所2
      colProductsDetails.push(CreateColRow('place_2', officeProduct.place_2, 'VARCHAR'));
      // 置き場所3
      colProductsDetails.push(CreateColRow('place_3', officeProduct.place_3, 'VARCHAR'));
      // 置き場所4
      colProductsDetails.push(CreateColRow('place_4', officeProduct.place_4, 'VARCHAR'));
      // ピッキング表印刷区分
      colProductsDetails.push(CreateColRow('picking_print_class', officeProduct.picking_print_class, 'INT'));
      // 適正在庫数
      colProductsDetails.push(CreateColRow('appropriate_stock', officeProduct.appropriate_stock, 'INT'));
      // ケース換算区分
      colProductsDetails.push(CreateColRow('case_conversion_class', officeProduct.case_conversion_class, 'INT'));
      // 仕入先製品コード
      colProductsDetails.push(CreateColRow('supplier_product_id', await escapeQuote(officeProduct.supplier_product_id), 'VARCHAR'));
      // SQL実行
      const sqlProductsDetails = CreateInsertSql(colProductsDetails.concat(colBothUser), 'full', 'm_products_details');
      // // 製品単価履歴を追加 
      await this.execInsertPurchasePrice(office_id, officeProduct.purchase_price, officeProduct.summary)

      this.transactSqlList.push(sqlProductsOrders)
      this.transactSqlList.push(sqlProductsDetails)
    },
   
    /**
     * 仕入単価履歴登録処理
     */
    async execInsertPurchasePrice(office_id, purchase_price, summary){
      const colList = [];
      // 製品コード
      colList.push(CreateColRow('product_id', this.productObj.product_id, 'INT'));
      // 営業所コード
      colList.push(CreateColRow('office_id', office_id, 'INT'));
      // 製品単価修正日時
      colList.push(CreateColRow('price_update_datetime', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 仕入単価
      colList.push(CreateColRow('purchase_price', purchase_price, 'INT'));
      // 摘要
      colList.push(CreateColRow('summary', await escapeQuote(summary), 'VARCHAR'));
      // 新規と更新ユーザー
      const colBothUser = await getUserCol(this.username, 'both')

      // SQL実行
      const sql = CreateInsertSql(colList.concat(colBothUser), 'full', 't_products_prices_histories');
      this.transactSqlList.push(sql)
    },
    /**
     * 製品検索
     */
    async searchProduct(){
      const functionName = 'searchProduct'
      let result = null;
      let sql = `SELECT id FROM m_products WHERE product_id = ${this.productObj.product_id} `
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: [sql] }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sql
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sql,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if(result === null) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      return body.data[0][0]
    },
    /**
     * 9Aコード検索
     */
    async searchProducts9AClasses(){
      const functionName = 'searchProducts9AClasses'
      let sqlsProducts9AClasses = `select min, max, sequence from m_9a_products_classes
        where product_class_id  = ${Number(this.productObj.product_class_id)}`

      let result = null
      try {
        // sql実行
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqlsProducts9AClasses }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqlsProducts9AClasses
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqlsProducts9AClasses,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      const bodyProducts9AClasses = JSON.parse(result.data.executeTransactSql.body);
      //  9Aコード作成された場合
      if (bodyProducts9AClasses.data[0].length != 0) {
        return bodyProducts9AClasses.data[0][0]
      }
      return null
    },
    // 置き場所の小文字を大文字に置換
    toUpperPlace: function(row) {
      if (getNullStr(row.place) != '') {
        row.place = row.place.toUpperCase();
      }
    },
  }
}
</script>
