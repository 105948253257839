<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2">
            <b-card>
              <div class="mb-0 p-2" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-2">
                  <validation-observer ref="observer">
                    <b-container fluid>
                      <!-- ●●●検索条件●●● -->
                      <b-row class="mt-2">
                        <!-- 営業所入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="営業所"
                          >
                            <b-form-select
                              v-model="searchConditions.selectSalesOffice"
                              :options="searchConditions.salesOffice"
                              value-field="id"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <!-- 製品コード -->
                        <b-col lg="4">
                          <validation-provider name="productCode" rules="required|numeric|min:8" v-slot="{ classes,errors }">
                            <b-form-group
                              label="製品コード"
                              label-for="productCode"
                            >
                              <b-row>
                                <b-col lg="12" :class="classes">
                                  <b-input-group>
                                    <b-form-input 
                                      id="productCode" 
                                      name="productCode" 
                                      type="text" 
                                      v-model="searchConditions.productCode" 
                                      @input="searchProduct(searchConditions.productCode)" 
                                      maxlength="8"
                                    />
                                    <b-input-group-text @click="showProductSearchModal()" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                        <button type="button" v-shortkey="['alt', '1']" @shortkey="showProductSearchModal()" class="d-none"></button>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                </b-col>
                                <b-col lg="12" :class="classes">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-row>
                              <b-form-text class="text-muted">検索ボタンから選択してください</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 製品名 -->
                        <b-col lg="8">
                          <b-form-group
                            label="製品名"
                            label-for="productName"
                          >
                            <b-row>
                              <b-col lg="12">
                                <b-form-input id="productName" type="text" v-model="searchConditions.productName" readonly />
                              </b-col>
                            </b-row>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center my-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(Operation)="operation">
                <!-- 受注入力照会ボタン -->
                <b-button size="sm" v-b-tooltip.hover.left="'受注製品の引当を別の受注の同製品に移動します。'" @click="clickChangeBtn(operation.item.ReceivedOrderId, operation.item.ReceivedOrderRow)" class="mr-1">
                  <span class="oi oi-list"></span> 引当変更
                </b-button>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :productSearchProp="productSearchProp"/>
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { searchProductsModal } from '@/assets/js/searchModal.js';
import { init, formatDate, getListValue, executeSelectSql, addOperationLogs, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'received-order-stock-reserve-list';

export default {
  name: 'RECEIVED-ORDER-STOCK-RESERVE-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    PRODUCTSEARCH,
  },
  props: ['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '受注在庫引当一覧',
      // アラート
      alertSuccess: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions:{
        selectSalesOffice: '',
        salesOffice: [],
        productCode: '',
        productName: '',
      },
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    this.alertSuccess = this.$route.params.successMessages || []
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'InputDate',
          label: '入力日',
          sortable: true,
        },
        {
          key: 'OrderReceiveStaff',
          label: '受注処理担当者',
          sortable: true,
        },
        {
          key: 'ReceivedOrderId',
          label: '受注番号',
          sortable: true,
        },
        {
          key: 'ReceivedOrderRow',
          label: '行番号',
          sortable: true,
        },
        {
          key: 'SalesOffice',
          label: '営業所名',
          sortable: true,
        },
        {
          key: 'CustomerName',
          label: '得意先名',
          sortable: true,
        },
        {
          key: 'Quantity',
          label: '受注数',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'ReserveQuantity',
          label: '引当数',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'Tradition',
          label: '伝種',
          sortable: true,
        },
        {
          key: 'Operation',
          label: '操作',
        }
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* 製品モーダルのパラメータ用 */
    productSearchProp: function() {
      return {
        office_id: this.searchConditions.selectSalesOffice,
        office_name: getListValue(this.searchConditions.salesOffice, this.searchConditions.selectSalesOffice),
        client_class: null,
        client_id: null,
        client_name: null,
      };
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods: {
    /* フェッチ */
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
        ]);
        let loginOfficeId = staffListData[0].office_id;
        //console.log(staffListData);
        //console.log(officeListData);
        // default値挿入
        this.searchConditions.selectSalesOffice = 0;
        // 営業所プルダウン作成
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            name: officeListData[i].office_name_kanji,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
          };
          this.searchConditions.salesOffice.push(office);
          // default値挿入
          if (loginOfficeId == officeListData[i].office_id) {
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        } else {
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      this.itemList = [];
      // ページング機能の初期化
      this.initPaging();
      this.busy = true;
      try {
        // CRUD処理
        let selectSql = '';
        selectSql = await this.makeSelectSql();
        //console.log(selectSql);
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        //console.log(resultData);
        if(resultData != null){
          await this.setResult(resultData);
          if(resultData.length >= 1000){
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
        }else{
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2002']);
      }
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result) {
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for(let i = 0; i < result.length; i++){
        let searchResult = {
          InputDate: formatDate(result[i].input_date),
          OrderReceiveStaff: result[i].order_receive_staff_id + '：' + result[i].order_receive_staff_name_kanji,
          ReceivedOrderId: result[i].order_receive_id,
          ReceivedOrderRow: result[i].order_receive_row,
          SalesOffice: result[i].office_name_kanji,
          CustomerName: result[i].client_name,
          Quantity: result[i].order_receive_quantity,
          ReserveQuantity: result[i].reserve_quantity,
          Tradition: getListValue(Const.OrderReceiveBillClassList, result[i].order_receive_bill_class, 'id', 'text'),
          Operation: result[i],
        };
        this.itemList.push(searchResult);
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.input_date';
      selectSql += ',orders_receives.order_receive_id';
      selectSql += ',orders_receives.order_receive_row';
      selectSql += ',orders_receives.order_receive_staff_id';
      selectSql += ',orders_receives_staffs.staff_name_kanji AS order_receive_staff_name_kanji';
      selectSql += ',offices.office_name_kanji';
      selectSql += ',orders_receives.client_name';
      selectSql += ',orders_receives.order_receive_quantity';
      selectSql += ',orders_receives.reserve_quantity - IfNull(ships.shipping_quantity,0) AS reserve_quantity';
      selectSql += ',orders_receives.order_receive_bill_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN m_offices AS offices ';
      selectSql += 'ON orders_receives.office_id = offices.office_id ';
      selectSql += 'INNER JOIN m_staffs AS orders_receives_staffs ';
      selectSql += 'ON orders_receives.order_receive_staff_id = orders_receives_staffs.staff_id ';
      selectSql += 'LEFT JOIN t_ships AS ships ';
      selectSql += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
      selectSql += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
      selectSql += 'AND ships.sales_issue_class = ' + Const.SalesIssueClass.mi + ' ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 削除済フラグ
      selectSql += ' orders_receives.is_deleted = 0 ';
      // 営業所コード
      selectSql += 'AND orders_receives.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      // 製品コード
      selectSql += 'AND orders_receives.product_id = ' + this.searchConditions.productCode + ' ';
      // 受注伝票種別（返品も特別受注も引当数は0以下のため未指定でも問題なし）
      selectSql += 'AND orders_receives.order_receive_bill_class = ' + Const.OrderReceiveBillClass.normal + ' ';
      // 配送種別区分（直送の引当数は0のため未指定でも問題なし）
      selectSql += 'AND orders_receives.shipping_type_class <> \'' + Const.ShippingTypeClass.direct + '\' ';
      // 引当数（未出荷の出荷数を減算）
      selectSql += 'AND orders_receives.reserve_quantity - IfNull(ships.shipping_quantity,0) > 0 ';
      // セット品区分
      selectSql += 'AND orders_receives.set_class = ' + Const.SetClass.noSet + ' ';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' orders_receives.input_date';
      selectSql += ',orders_receives.order_receive_id';
      selectSql += ',orders_receives.order_receive_row';
      /* LIMIT */
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    /* 引当変更ボタン押下時 */
    async clickChangeBtn(receivedOrderId, receivedOrderRow) {
      //console.log('引当変更');
      //console.log(receivedOrderId);
      //console.log(receivedOrderRow);
      let route = this.$router.resolve({ name: 'RECEIVED-ORDER-STOCK-RESERVE-CHANGE', query: { receivedOrderId: receivedOrderId, receivedOrderRow: receivedOrderRow } });
      //console.log(this.$router);
      window.open(route.href, '_blank');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    // 製品検索
    showProductSearchModal:function() {
      this.$bvModal.show('productSearchModal');
    },
    // 製品コード直接入力 - 製品検索
    async searchProduct(product_id) {
      const result = await searchProductsModal(product_id, this.productSearchProp, 'searchProduct', MODULE_NAME)
      if (typeof result != 'undefined') {
        this.closeProductSearchModal(result)
      } else {
        // 製品コード8桁かつデータ取得失敗の場合
        if (product_id.length == 8) {
          // 製品コードクリア
          this.closeProductSearchModal({detail:{}})
        }
      }
    },
    // 製品検索モーダルを閉じた時
    closeProductSearchModal:function(productItems){
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if(typeof productItems != 'undefined'){
        this.searchConditions.productCode = productItems.detail.product_id;
        this.searchConditions.productName = productItems.detail.product_name_kanji;
      }
    },
  },
}
</script>