<template>
  <!-- 見積・発注一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <b-card class="border">
              <b-card-header class="mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip" title="クリックすると検索条件表示/非表示できます。">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" style="display: block; float: left;" role="tablist" aria-expanded="true">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                  <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive title="発注データの新規登録" @click="clickInputBtn(htmlConst.EstimateOrder.order);" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-plus"></span> 発注新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey(htmlConst.EstimateOrder.order)" class="d-none"></button>
                  </b-button>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive title="見積データの新規登録" @click="clickInputBtn(htmlConst.EstimateOrder.estimate);" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-plus"></span> 見積新規登録(F9)
                    <button v-shortkey="['f9']" @shortkey="shortkey(htmlConst.EstimateOrder.estimate)" class="d-none"></button>
                  </b-button>
                </h5>
              </b-card-header>
              <br>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-2">
                  <b-container>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <b-row>
                        <!-- 見積・発注区分入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="機能選択"
                            v-slot="{ ariaDescribedby }"
                          >
                            <b-form-radio-group
                              id="selectEstimateOrder"
                              v-model="searchConditions.selectEstimateOrder"
                              :options="searchConditions.estimateOrder"
                              :aria-describedby="ariaDescribedby"
                              value-field="id"
                            ></b-form-radio-group>
                          </b-form-group>
                        </b-col>
                        <!-- 営業所入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="営業所"
                            label-for="selectSalesOffice"
                          >
                            <b-form-select
                              id="selectSalesOffice"
                              v-model="searchConditions.selectSalesOffice"
                              :options="searchConditions.salesOffice"
                              value-field="id"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- 見積・発注番号入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="estimateId" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group
                              label="見積・発注番号"
                              label-for="estimateId"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="estimateId" name="estimateId" v-model="searchConditions.estimateId" />
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 取引先コード入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="clientCode" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group
                              label="取引先コード"
                              label-for="clientCode"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="clientCode" name="clientCode" v-model="searchConditions.clientCode" />
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- 取引先名入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="取引先名"
                            label-for="clientName"
                          >
                            <b-col lg="12" class="pl-0">
                              <b-form-input type="text" id="clientName" v-model="searchConditions.clientName" />
                            </b-col>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの取引先名（漢字）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                        <!-- 取引先印刷用宛名入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="取引先印刷用宛名"
                            label-for="clientPrintName"
                          >
                            <b-col lg="12" class="pl-0">
                              <b-form-input type="text" id="clientPrintName" v-model="searchConditions.clientPrintName" />
                            </b-col>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの取引先印刷用宛名と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- 製品コード入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="productCode" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group
                              label="製品コード"
                              label-for="productCode"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="productCode" name="productCode" v-model="searchConditions.productCode" />
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">前方一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 製品名入力欄 -->
                        <b-col  lg="6">
                          <b-form-group
                            label="製品名"
                            label-for="productName"
                          >
                            <b-col lg="12" class="pl-0">
                              <b-form-input type="text" id="productName" v-model="searchConditions.productName" />
                            </b-col>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの製品名（漢字）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- 担当者コード入力欄 -->
                        <b-col  lg="6">
                          <validation-provider name="createdStaffId" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group
                              label="担当者コード"
                              label-for="createdStaffId"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="createdStaffId" name="createdStaffId" v-model="searchConditions.createdStaffId" />
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 担当者名入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="担当者名"
                            label-for="createdStaffName"
                          >
                            <b-col lg="12" class="pl-0">
                              <b-form-input type="text" id="createdStaffName" v-model="searchConditions.createdStaffName" />
                            </b-col>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの作成担当者名（漢字）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- 作成年月日入力欄 -->
                        <b-col lg="12">
                          <validation-provider name="createdDateStart" :rules="{dateConsistency:searchConditions.createdDateEnd}" v-slot="{ classes,errors }">
                            <b-form-group
                              label="作成年月日"
                              label-for="createdDateStart"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group class="input-daterange" id="datepicker">
                                  <b-form-datepicker
                                    id="createdDateStart"
                                    name="createdDateStart"
                                    v-model="searchConditions.createdDateStart"
                                    calendar-width="50%"
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.createdDateStart=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                  <span class="input-group-text">～</span>
                                  <b-form-datepicker
                                    v-model="searchConditions.createdDateEnd"
                                    calendar-width="50%"
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.createdDateEnd=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-form-group>
                            <b-form-text class="text-muted">パフォーマンスの関係で過去の全データを表示することは不可能です。日付は基本的に指定してください。または、見積・発注番号か取引先コードを指定してください。</b-form-text>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <!-- 取引先の担当者名入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="取引先の担当者名"
                            label-for="clientStaffName"
                          >
                            <b-col lg="12" class="pl-0">
                              <b-form-input type="text" id="clientStaffName" v-model="searchConditions.clientStaffName" />
                            </b-col>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの取引先担当者名（漢字）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                        <!-- 受渡場所入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="受渡場所"
                            label-for="siteName"
                          >
                            <b-col lg="12" class="pl-0">
                              <b-form-input type="text" id="siteName" v-model="searchConditions.siteName" :readonly="searchConditions.selectEstimateOrder!=htmlConst.EstimateOrder.estimate" />
                            </b-col>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの受渡場所と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <b-row class="justify-content-md-center my-2">
                      <b-col lg="3">
                        <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                          <span class="oi oi-magnifying-glass"></span> 検 索 
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col class="mt-2">
          <b-row>
            <b-col lg="6">
              <b-form-group
                :label="tableCaption"
                class="mb-0 mt-0"
              />
            </b-col>
          </b-row>
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col lg="6" class="my-1">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-table
            show-empty
            :small="true"
            :items="estimateList"
            :fields="fields"
            :busy="busy"
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            @filtered="onFiltered"
          >
            <template #head(EstimateDate)>{{searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate ? '見積日付':'発注日付'}}<br>&nbsp;</template>
            <template #head(EstimateId)>{{searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate ? '見積番号':'発注番号'}}<br>&nbsp;</template>
            <template #head(SalesOffice)>営業所名<br>&nbsp;</template>
            <template #head(CustomerCode)>取引先<br>コード</template>
            <template #head(CustomerName)>取引先名<br>&nbsp;</template>
            <template #head(CustomerPrintName)>取引先<br>印刷用宛名</template>
            <template #head(CreatedStaffId)>担当者<br>コード</template>
            <template #head(CreatedStaffName)>担当者名<br>&nbsp;</template>
            <template #head(CreatedDate)>作成年月日<br>&nbsp;</template>
            <template #head(ClientStaffName)>取引先<br>担当者名</template>
            <template #head(SiteName)>受渡場所<br>&nbsp;</template>
            <template #head(Contents)>内容<br>&nbsp;</template>
            <template #head(Operation)>操作<br>&nbsp;</template>
            <!-- テーブル読み込み時表示html -->
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>読み込んでいます...</strong>
              </div>
            </template>
            <!-- printイベント用のレイアウト -->
            <template v-if="printStatus" #cell(CustomerName)="data">
              <div style="max-width:100px;">
                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                  <span>{{ data.item.CustomerName }}</span>
                </div>
              </div>
            </template>
            <template v-if="printStatus" #cell(CustomerPrintName)="data">
              <div style="max-width:100px;">
                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                  <span>{{ data.item.CustomerPrintName }}</span>
                </div>
              </div>
            </template>
            <template v-if="printStatus" #cell(CreatedStaffName)="data">
              <div style="max-width:100px;">
                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                  <span>{{ data.item.CreatedStaffName }}</span>
                </div>
              </div>
            </template>
            <template v-if="printStatus" #cell(SiteName)="data">
              <div style="max-width:100px;">
                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                  <span>{{ data.item.SiteName }}</span>
                </div>
              </div>
            </template>
            <template v-if="printStatus" #cell(Contents)="data">
              <div style="max-width:100px;">
                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;">
                  <span>{{ data.item.Contents }}</span>
                </div>
              </div>
            </template>
            <!-- 操作列 -->
            <template #cell(Operation)="operation">
              <div v-show="!printStatus" >
                <!-- 照会ボタン -->
                <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'同レコードの照会を行います。'" @click="clickDetailBtn(operation.item.EstimateId)" class="mr-1">
                  <span class="oi oi-list"></span> 照会
                </b-button>&nbsp;
                <!-- 見積書PDF出力ボタン -->
                <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'同レコードのPDF出力を行います。'" @click="clickOutputEstimatePdf(operation.item.EstimateId)" class="mr-1">
                  <span class="oi oi-document"></span> {{ searchConditions.selectEstimateOrder == htmlConst.EstimateOrder.estimate ? '見積書PDF出力' : '発注書PDF出力' }}
                </b-button>&nbsp;
                <!-- 編集ボタン -->
                <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'同レコードの編集を行います。（日付が処理年月を超過した場合は編集不可能です。）'" @click="clickEditBtn(operation.item.EstimateId)" class="mr-1">
                  <span class="oi oi-pencil"></span> 編集
                </b-button>&nbsp;
                <!-- 削除ボタン -->
                <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'同レコードの削除を行います。（日付が処理年月を超過した場合は削除不可能です。）'" @click="clearAlert(); clickDeleteBtn(operation.item.EstimateId)" class="mr-1">
                  <span class="oi oi-delete"></span> 削除
                </b-button>&nbsp;
                <!-- コピー見積・発注ボタン -->
                <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'コピーして' + (searchConditions.selectEstimateOrder == htmlConst.EstimateOrder.estimate ? '見積' : '発注') + 'を新規登録します。'" @click="clickEstimateCopyBtn(operation.item.EstimateId)" class="mr-1">
                  <span class="oi oi-plus"></span> {{ searchConditions.selectEstimateOrder == htmlConst.EstimateOrder.estimate ? 'コピー見積' : 'コピー発注' }}
                </b-button>&nbsp;
                <!-- コピー受注ボタン -->
                <b-button v-if="searchConditions.selectEstimateOrder==htmlConst.EstimateOrder.estimate" size="sm" v-b-tooltip.hover.noninteractive.left="'コピーして受注を新規登録します。'" @click="clearAlert(); clickReceivedOrderCopyBtn(operation.item.EstimateId);" class="mr-1">
                  <span class="oi oi-plus"></span> コピー受注
                </b-button>
              </div>
            </template>
          </b-table>
          <b-row>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="['選択されたデータを削除します。','よろしいですか？']" />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, windowPrint, addOperationLogs, convertSqlLikeSpecialChar, getNullStr, getListValue, formatDate, formatCurDate, formatDateCalc, executeTransactSqlList, isSystemEditable, checkReceivedOrderCopy, getEstimateDeleteUnitPriceSqlList, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'estimate-list';

export default {
  name: 'ESTIMATE-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM
  },
  props:['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      Const: {...Const},
      title: '見積・発注一覧',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 削除対象見積番号
      delEstimateId: null,
      // 検索条件
      searchConditions:{
        selectEstimateOrder: Const.EstimateOrder.estimate,
        estimateOrder: Const.EstimateOrderList,
        selectSalesOffice: '',
        salesOffice: [],
        estimateId: '',
        clientCode: '',
        clientName: '',
        clientPrintName: '',
        productCode: '',
        productName: '',
        createdStaffId: '',
        createdStaffName: '',
        createdDateStart: '',
        createdDateEnd: '',
        clientStaffName: '',
        siteName: '',
      },
      // 検索結果
      tableCaption: '',
      estimateList: [],
      fields: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: 0,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 定数（htmlで使用）
      htmlConst: {
        // 見積書・発注書
        EstimateOrder: {
          // 見積書
          estimate: Const.EstimateOrder.estimate,
          // 発注書
          order: Const.EstimateOrder.order,
        },
      },
      // プリントアウト状態
      printStatus: false,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
  },
  /* 関数群 */
  methods:{
    shortkey(estimateOrderKbn) {
      if (estimateOrderKbn == Const.EstimateOrder.estimate) {
        // 見積入力画面へ遷移
        this.$router.push({ name: 'ESTIMATE-INPUT', query: { propEstimateId: 0, propReceivedOrderId: 0 }});
      } else {
        // 発注入力画面へ遷移
        this.$router.push({ name: 'ESTIMATE-ORDERS-INPUT', query: { propOrderId: 0 }});
      }
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
        ]);
        //console.log(staffListData);
        //console.log(officeListData);
        let loginOfficeId = staffListData[0].office_id;
        // default値挿入
        this.searchConditions.selectSalesOffice = 0;
        // 営業所プルダウン作成
        this.searchConditions.salesOffice.push({id: 0, text: '全て'});
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji
          };
          this.searchConditions.salesOffice.push(office);
          // default値挿入
          if (loginOfficeId == officeListData[i].office_id) {
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
        // 作成年月日に１か月前～現在日を設定
        this.searchConditions.createdDateEnd = formatCurDate('YYYY-MM-DD');
        this.searchConditions.createdDateStart = formatDateCalc(this.searchConditions.createdDateEnd, 0, -1, 0, false, 'YYYY-MM-DD');
        // 初期検索
        //await this.search();
        // パラメータによってメッセージを表示
        if(this.parentKbn == 1){
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      let param = {};
      this.estimateList = [];
      // ページング機能の初期化
      this.initPaging();
      // フィールドを機能選択と合わせる
      this.fields = this.getFields();
      // CRUD処理
      let where_clause = '';
      where_clause = await this.makeCondition();
      if (this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate) {
        where_clause += 'GROUP BY estimate_id ';
        where_clause += 'ORDER BY estimate_date DESC,estimate_id ';
      } else {
        where_clause += 'GROUP BY order_id ';
        where_clause += 'ORDER BY order_date DESC,order_id ';
      }
      where_clause += 'LIMIT 1000';
      //console.log(where_clause);
      this.busy = true;
      try {
        //console.log('条件取得');
        let resultData;
        if (this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate) {
          param = {
            graphqlOperation: 'list_t_estimate',
            where_clause: where_clause
          };
          resultData = await selectOneTable('t_estimate', where_clause);
          param = {};
        } else {
          param = {
            graphqlOperation: 'list_t_estimate',
            where_clause: where_clause
          };
          resultData = await selectOneTable('t_estimate_orders', where_clause);
          param = {};
        }
        if(resultData != null){
          await this.setResult(resultData);
          if(resultData.length >= 1000){
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
          //console.log(resultData);
        }else{
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result){
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for(let i = 0; i < result.length; i++){
        let searchResult = {
          EstimateId: this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate ? result[i].estimate_id : result[i].order_id,
          EstimateDate: this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate ? formatDate(result[i].estimate_date) : formatDate(result[i].order_date),
          SalesOffice: result[i].office_name_kanji,
          CustomerCode:result[i].client_id,
          CustomerName: result[i].client_name_kanji,
          CustomerPrintName: result[i].client_print_name,
          CreatedStaffId: result[i].created_staff_id,
          CreatedStaffName: result[i].created_staff_name_kanji,
          CreatedDate: formatDate(result[i].created_date),
          ClientStaffName: result[i].client_staff_name_kanji,
          SiteName: this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate ? result[i].client_site_name_kanji : undefined,
          Contents: result[i].contents,
          Operation: result[i],
        };
        this.estimateList.push(searchResult);
      }
      this.tableCaption = getListValue(Const.EstimateOrderList, this.searchConditions.selectEstimateOrder);
    },
    /* 検索条件文字列作成 */
    async makeCondition() {
      let where_clause = '';
      // 営業所コード
      if(this.searchConditions.selectSalesOffice != 0){
        where_clause += 'AND office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      }
      // 見積番号
      if(this.searchConditions.estimateId != ''){
        if (this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate) {
          where_clause += 'AND estimate_id = ' + this.searchConditions.estimateId + ' ';
        } else {
          where_clause += 'AND order_id = ' + this.searchConditions.estimateId + ' ';
        }
      }
      // 取引先コード
      if(this.searchConditions.clientCode != ''){
        //where_clause += 'AND client_class = ' + Const.ClientClass.customer + ' ';
        where_clause += 'AND client_id = ' + this.searchConditions.clientCode + ' ';
      }
      // 取引先名(あいまい検索対象)
      if(this.searchConditions.clientName != ''){
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        where_clause += `AND REPLACE(REPLACE(CONVERT(client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // 取引先印刷用宛名(あいまい検索対象)
      if(this.searchConditions.clientPrintName != ''){
        const clientPrintName = convertSqlLikeSpecialChar(this.searchConditions.clientPrintName);
        where_clause += `AND REPLACE(REPLACE(CONVERT(client_print_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientPrintName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // 製品コード
      if(this.searchConditions.productCode != ''){
        where_clause += 'AND product_id LIKE \'' + this.searchConditions.productCode + '%\' ';
      }
      // 製品名(あいまい検索対象)
      if(this.searchConditions.productName != ''){
        const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
        where_clause += `AND REPLACE(REPLACE(CONVERT(product_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // 担当者コード
      if(this.searchConditions.createdStaffId != ''){
        where_clause += 'AND created_staff_id = ' + this.searchConditions.createdStaffId + ' ';
      }
      // 担当者名(あいまい検索対象)
      if(this.searchConditions.createdStaffName != ''){
        const createdStaffName = convertSqlLikeSpecialChar(this.searchConditions.createdStaffName);
        where_clause += `AND REPLACE(REPLACE(CONVERT(created_staff_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${createdStaffName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // 作成年月日
      if(this.searchConditions.createdDateStart != '' && this.searchConditions.createdDateEnd == ''){
        where_clause += 'AND created_date >= ' + '\'' + this.searchConditions.createdDateStart + '\' ';
      }else if(this.searchConditions.createdDateStart == '' && this.searchConditions.createdDateEnd != ''){
        where_clause += 'AND created_date <= ' + '\'' + this.searchConditions.createdDateEnd + '\' ';
      }else if(this.searchConditions.createdDateStart != '' && this.searchConditions.createdDateEnd != ''){
        where_clause += 'AND created_date BETWEEN ' + '\'' + this.searchConditions.createdDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.createdDateEnd + '\' ';
      }
      // 取引先の担当者名(あいまい検索対象)
      if(this.searchConditions.clientStaffName != ''){
        const clientStaffName = convertSqlLikeSpecialChar(this.searchConditions.clientStaffName);
        where_clause += `AND REPLACE(REPLACE(CONVERT(client_staff_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientStaffName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // 受渡場所(あいまい検索対象)（見積検索の場合のみ）
      if(this.searchConditions.siteName != '' && this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate){
        const siteName = convertSqlLikeSpecialChar(this.searchConditions.siteName);
        where_clause += `AND REPLACE(REPLACE(CONVERT(client_site_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${siteName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }

      return where_clause;
    },
    /* 新規登録ボタン押下時 */
    async clickInputBtn(estimateOrderKbn) {
      //console.log('新規登録');
      if (estimateOrderKbn == Const.EstimateOrder.estimate) {
        // 見積入力画面へ遷移
        this.$router.push({ name: 'ESTIMATE-INPUT', query: { propEstimateId: 0, propReceivedOrderId: 0 }});
      } else {
        // 発注入力画面へ遷移
        this.$router.push({ name: 'ESTIMATE-ORDERS-INPUT', query: { propOrderId: 0 }});
      }
    },
    /* 照会ボタン押下時 */
    async clickDetailBtn(estimateId) {
      //console.log('照会');
      //console.log(estimateId);
      if (this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate) {
        // 見積照会画面へ遷移
        let route = this.$router.resolve({ name: 'ESTIMATE-INQUIRY', query: { estimateId: estimateId, parentKbn: 0 } });
        window.open(route.href, '_blank');
      } else {
        // 発注照会画面へ遷移
        let route = this.$router.resolve({ name: 'ESTIMATE-ORDERS-INQUIRY', query: { orderId: estimateId, parentKbn: 0 } });
        window.open(route.href, '_blank');
      }
    },
    /* 見積書・発注書PDF出力ボタンの押下 */
    async clickOutputEstimatePdf(estimateId) {
      if (this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate) {
        // 見積書PDF出力処理
        //console.log('見積書PDF出力処理');
        let route = this.$router.resolve({ name: 'ESTIMATE', query: { estimateId: estimateId }});
        window.open(route.href, '_blank');
      } else {
        // 発注書PDF出力処理
        //console.log('発注書PDF出力処理');
        let route = this.$router.resolve({ name: 'ORDER', query: { orderId: estimateId }});
        window.open(route.href, '_blank');
      }
    },
    /* 編集ボタン押下時 */
    async clickEditBtn(estimateId) {
      //console.log('編集');
      //console.log(estimateId);
      if (this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate) {
        // 見積修正画面へ遷移
        let route = this.$router.resolve({ name: 'ESTIMATE-EDIT', query: { estimateId: estimateId } });
        window.open(route.href, '_blank');
      } else {
        // 発注修正画面へ遷移
        let route = this.$router.resolve({ name: 'ESTIMATE-ORDERS-EDIT', query: { orderId: estimateId } });
        window.open(route.href, '_blank');
      }
    },
    /* 削除ボタン押下時 */
    async clickDeleteBtn(estimateId) {
      //console.log('削除');
      this.delEstimateId = estimateId;
      this.$bvModal.show('confirmModal');
    },
    /* 削除処理 */
    async execDeleteBtn() {
      const functionName = 'execDeleteBtn';
      let deleteSql = 'DELETE FROM ';
      if (this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate) {
        // 見積データの削除SQL
        deleteSql += 't_estimate';
        deleteSql += ' WHERE ';
        // 見積番号
        deleteSql += 'estimate_id = ' + this.delEstimateId + ' ';
      } else {
        // 発注データの削除SQL
        deleteSql += 't_estimate_orders';
        deleteSql += ' WHERE ';
        // 発注番号
        deleteSql += 'order_id = ' + this.delEstimateId + ' ';
      }
      //console.log(deleteSql);

      let sqlList = [];

      // 見積データの削除前に単価登録情報を削除
      let deleteUnitPriceSql = await getEstimateDeleteUnitPriceSqlList(this.delEstimateId);
      if (getNullStr(deleteUnitPriceSql) != '') {
        sqlList.push(deleteUnitPriceSql);
      }

      sqlList.push(deleteSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    // 削除確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          // 削除実行
          await this.execDeleteBtn();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* コピー見積ボタン押下時 */
    async clickEstimateCopyBtn(estimateId){
      //console.log('コピー見積・発注');
      //console.log(estimateId);
      if (this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate) {
        // 見積入力画面へ遷移
        let route = this.$router.resolve({ name: 'ESTIMATE-INPUT', query: { propEstimateId: estimateId, propReceivedOrderId: '0' } });
        window.open(route.href, '_blank');
      } else {
        // 発注入力画面へ遷移
        let route = this.$router.resolve({ name: 'ESTIMATE-ORDERS-INPUT', query: { propOrderId: estimateId } });
        window.open(route.href, '_blank');
      }
    },
    /* コピー受注ボタン押下時 */
    async clickReceivedOrderCopyBtn(estimateId){
      //console.log('コピー受注');
      // 受注入力画面へ遷移
      //console.log(estimateId);
      var newWin = window.open('', '_blank');
      if (await this.checkEstimateIdReceivedOrderCopy(estimateId) == true) {
        let route = this.$router.resolve({ name: 'RECEIVED-ORDER-INPUT', query: { estimateId: estimateId } });
        newWin.location = route.href;
      } else {
        newWin.close();
      }
    },
    // コピー受注可能チェック
    async checkEstimateIdReceivedOrderCopy(estimateId) {
      const functionName = 'checkEstimateIdReceivedOrderCopy';
      this.$store.commit('setLoading', true);
      let retEstimateIdCheck = false;
      try {
        let where_clause = 'AND estimate_id = ' + estimateId + ' ';
        where_clause += 'ORDER BY estimate_row';
        let resultData = await selectOneTable('t_estimate', where_clause);
        //console.log(resultData);
        if (resultData != null && resultData.length > 0) {
          let productList = [];
          for (let i = 0; i < resultData.length; i++) {
            productList.push({ProductCode: resultData[i].product_id, ProductName: resultData[i].product_name_kanji});
          }
          let retCheck = await checkReceivedOrderCopy(productList);
          if (retCheck != null) {
            if (retCheck.msgId == '2057') {
              this.alertWarning.push(DISP_MESSAGES.WARNING[retCheck.msgId]);
            } else {
              this.alertWarning.push(DISP_MESSAGES.WARNING[retCheck.msgId].replace('%arg1%',retCheck.productId));
            }
            retEstimateIdCheck = false;
          } else {
            retEstimateIdCheck = true;
          }
        } else {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2057']);
          retEstimateIdCheck = false;
        }
      } catch(error) {
        retEstimateIdCheck = false;
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2057']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
      return retEstimateIdCheck;
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* 機能選択の選択値毎に検索結果のカラムを変更 */
    getFields: function() {
      if (this.searchConditions.selectEstimateOrder == Const.EstimateOrder.estimate) {
        return [
          {
            key: 'EstimateDate',
            label: '見積日付',
            sortable: true,
          },
          {
            key: 'EstimateId',
            label: '見積番号',
            sortable: true,
          },
          {
            key: 'SalesOffice',
            label: '営業所名',
            sortable: true,
          },
          {
            key: 'CustomerCode',
            label: '取引先コード',
            sortable: true,
          },
          {
            key: 'CustomerName',
            label: '取引先名',
            sortable: true,
          },
          {
            key: 'CustomerPrintName',
            label: '取引先印刷用宛名',
            sortable: true,
          },
          {
            key: 'CreatedStaffId',
            label: '担当者コード',
            sortable: true,
          },
          {
            key: 'CreatedStaffName',
            label: '担当者名',
            sortable: true,
          },
          {
            key: 'CreatedDate',
            label: '作成年月日',
            sortable: true,
          },
          {
            key: 'ClientStaffName',
            label: '取引先の担当者名',
            sortable: true,
          },
          {
            key: 'SiteName',
            label: '受渡場所',
            sortable: true,
          },
          {
            key: 'Contents',
            label: '内容',
            sortable: true,
          },
          {
            key: 'Operation',
            label: '操作',
          }
        ];
      } else {
        return [
          {
            key: 'EstimateDate',
            label: '発注日付',
            sortable: true,
          },
          {
            key: 'EstimateId',
            label: '発注番号',
            sortable: true,
          },
          {
            key: 'SalesOffice',
            label: '営業所名',
            sortable: true,
          },
          {
            key: 'CustomerCode',
            label: '取引先コード',
            sortable: true,
          },
          {
            key: 'CustomerName',
            label: '取引先名',
            sortable: true,
          },
          {
            key: 'CustomerPrintName',
            label: '取引先印刷用宛名',
            sortable: true,
          },
          {
            key: 'CreatedStaffId',
            label: '担当者コード',
            sortable: true,
          },
          {
            key: 'CreatedStaffName',
            label: '担当者名',
            sortable: true,
          },
          {
            key: 'CreatedDate',
            label: '作成年月日',
            sortable: true,
          },
          {
            key: 'ClientStaffName',
            label: '取引先の担当者名',
            sortable: true,
          },
          {
            key: 'Contents',
            label: '内容',
            sortable: true,
          },
          {
            key: 'Operation',
            label: '操作',
          }
        ];
      }
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>