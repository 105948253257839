<template>
  <!-- 取引先製品マスタ一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <b-card class="border">
              <b-card-header class="mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip" title="クリックすると検索条件表示/非表示できます。">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" style="display: block; float: left;" role="tablist" aria-expanded="true">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                  <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                   <span class="oi oi-print"></span>
                  </b-button>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive title="新規登録" @click="clickInputBtn();" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </b-button>
                </h5>
              </b-card-header>
              <br>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-2">
                  <b-container>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <b-row>
                        <!-- 取引先コード入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="clientCode" :rules="{required: searchConditions.isOnlyNew&&searchConditions.clientName==''&&searchConditions.productId==''&&searchConditions.productName=='',numeric: true}" v-slot="{ classes,errors }">
                            <b-form-group
                              label="取引先コード"
                              label-for="clientCode"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group>
                                  <b-form-input type="text" id="clientCode" name="clientCode" v-model="searchConditions.clientId" />
                                  <b-button class="mr-1" size="sm" pill variant="success" :title="titleClientsParent" :disabled="searchConditions.clientId.length<6" @click="clearAlert(); getClientParent();">
                                    <span class="oi oi-eye"></span> 親子検索
                                  </b-button>
                                </b-input-group>
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。取引先コードを入力して、親子検索ボタンを押下した後でカーソルをボタン上に配置すると、親子情報がポップアップ表示されます。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 取引先名入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="取引先名"
                            label-for="clientName"
                          >
                            <b-col lg="12" class="pl-0">
                              <b-form-input type="text" id="clientName" v-model="searchConditions.clientName" />
                            </b-col>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの取引先名（漢字）または取引先名（カナ）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- 製品コード入力欄 -->
                        <b-col lg="3">
                          <validation-provider name="productCode" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group
                              label="製品コード"
                              label-for="productCode"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="productCode" name="productCode" v-model="searchConditions.productId" />
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">前方一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 製品名入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="製品名"
                            label-for="productName"
                          >
                            <b-col lg="12" class="pl-0">
                              <b-form-input type="text" id="productName" v-model="searchConditions.productName" />
                            </b-col>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの製品名（漢字）または製品名（カナ）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                        <!-- 最新日付のみ入力欄 -->
                        <b-col lg="3">
                          <b-form-group
                            label="最新日付のみ"
                            label-for="isOnlyNew"
                          >
                            <b-form-checkbox
                              id="isOnlyNew"
                              name="isOnlyNew"
                              v-model="searchConditions.isOnlyNew"
                            ></b-form-checkbox>
                            <b-form-text class="text-muted">チェックONの場合、最新日付の行のみが表示されます。検索条件を1つ以上指定して下さい。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <b-row class="justify-content-md-center my-2">
                      <b-col lg="3">
                        <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                          <span class="oi oi-magnifying-glass"></span> 検 索 
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-row>
          <b-col>
            <b-button pill size="sm" variant="danger" v-b-tooltip.hover.noninteractive title="一覧の削除チェックONの行の一括削除を行います。" @click="clearAlert(); clickBulkDeleteBtn();" :disabled="checkedIndexs.length==0">
              <span class="oi oi-delete"></span> 一括削除
            </b-button>
          </b-col>
        </b-row>
        <b-col class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm" @change="checkedIndexs = []"></b-form-select>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table
              show-empty
              :small="true"
              :items="clientsProductsMasterList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <template #head(Check)  v-if="!printStatus">
                削除
              </template>
              <template #cell(Check)="data" v-if="!printStatus">
                <b-check v-model="checkedIndexs" :value="data.item.Index"/>
              </template>
              <!-- printイベント用のレイアウト -->
              <template v-if="printStatus" #cell(ClientNameKanji)="data">
                <div style="max-width:140px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.ClientNameKanji }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-if="printStatus" #cell(ClientNameKana)="data">
                <div style="max-width:140px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.ClientNameKana }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-if="printStatus" #cell(ProductNameKanji)="data">
                <div style="max-width:140px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.ProductNameKanji }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-if="printStatus" #cell(ProductNameKana)="data">
                <div style="max-width:140px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.ProductNameKana }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-if="printStatus" #cell(Reason)="data">
                <div style="max-width:140px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.Reason }}
                    </span>
                  </div>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(Operation)="data">
                <div v-show="!printStatus" >
                  <!-- 編集ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'同レコードのデータの編集を行います。'" @click="clickEditBtn(data.item.ClientClass,data.item.ClientId,data.item.ProductId,data.item.UnitPriceEffectiveDate)" class="mr-1">
                    <span class="oi oi-pencil"></span> 編集
                  </b-button>&nbsp;
                  <!-- 削除ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'同レコードのデータの削除を行います。'" @click="clearAlert(); clickDeleteBtn(data.item.ClientClass,data.item.ClientId,data.item.ProductId,data.item.UnitPriceEffectiveDate)" class="mr-1">
                    <span class="oi oi-delete"></span> 削除
                  </b-button>&nbsp;
                </div>
              </template>
            </b-table>
          </b-row>
          <b-row>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
              @change="checkedIndexs = []"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="['選択された取引先製品単価を削除します。','よろしいですか？']" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, windowPrint, formatDate, addOperationLogs, getNullStr, convertSqlLikeSpecialChar, executeTransactSqlList, isSystemEditable, executeSelectSql, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'clients-products-master-list';

export default {
  name: 'CLIENTS-PRODUCTS-MASTER-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '取引先製品マスタ一覧',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 削除対象データ
      delData: {
        ClientClass: null,
        ClientId: null,
        ProductId: null,
        UnitPriceEffectiveDate: null,
      },
      // 検索条件
      searchConditions:{
        clientId: '',
        clientName: '',
        productId: '',
        productName: '',
        isOnlyNew: true,
      },
      // 検索結果
      clientsProductsMasterList: [],
      fields:[
        {
          key: 'Check',
        },
        {
          key: 'ClientId',
          label: '取引先コード',
          sortable: true,
        },
        {
          key: 'ClientNameKanji',
          label: '取引先名（漢字）',
          sortable: true,
        },
        {
          key: 'ClientNameKana',
          label: '取引先名（カナ）',
          sortable: true,
        },
        {
          key: 'ProductId',
          label: '製品コード',
          sortable: true,
        },
        {
          key: 'ProductNameKanji',
          label: '製品名（漢字）',
          sortable: true,
        },
        {
          key: 'ProductNameKana',
          label: '製品名（カナ）',
          sortable: true,
        },
        {
          key: 'UnitPriceEffectiveDate',
          label: '適用年月日',
          sortable: true,
        },
        {
          key: 'SalesUnitPrice',
          label: '適用単価',
          sortable: true,
          tdClass: 'text-right',
        },
        {
          key: 'Reason',
          label: '理由',
          sortable: true,
        },
        {
          key: 'Operation',
          label: '操作',
        }
      ],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
      // 各行のチェック状態
      checkedIndexs: [],
      // チェック最大数
      maxCheckCountClients: 1000,
      maxCheckCountProducts: 5000,
      // 最大数
      maxCheckBulkDelCount: 50,
      maxClientsParentChildCount: 10,
      // 取引先親子マスタ情報ポップアップ
      titleClientsParent: '親子未検索',
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1 || this.perPage == -1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
  },
  /* 関数群 */
  methods:{
    shortkey() {
      this.$router.push({ name: 'CLIENTS-PRODUCTS-MASTER-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      let param = {};
      this.clientsProductsMasterList = [];
      if (this.busy == true) {
        return;
      }
      this.busy = true;
      try {
        // ページング機能の初期化
        this.initPaging();
        if (this.searchConditions.isOnlyNew == true) {
          // 最新日付のみチェックON
          let selectSql = await this.makeSelectSqlOnlyNew();
          //console.log(selectSql);
          if (getNullStr(selectSql) == '') {
            this.busy = false;
            this.alertWarning.push(DISP_MESSAGES.WARNING['2071']);
            return;
          } else if (getNullStr(selectSql) == '0') {
            this.busy = false;
            return;
          }
          let resultData = await executeSelectSql(selectSql);
          if (resultData != null) {
            await this.setResult(resultData);
            if(resultData.length >= 1000){
              this.resultError.push(DISP_MESSAGES.WARNING['2002']);
            }
            //console.log(resultData);
          }else{
            // 総件数をdataTableの総件数にセット
            this.totalRows = 0;
          }
        } else {
          // CRUD処理
          let where_clause = '';
          where_clause = await this.makeCondition();
          where_clause += 'LIMIT 1000';
          //console.log(where_clause);
          //console.log('条件取得');
          // 製品名が検索条件に入らない場合、list_v_clients_products_02を使う事
          if (this.searchConditions.productName != '') {
            param = {
              graphqlOperation: 'list_v_clients_products_01',
              where_clause: where_clause
            };
            let resultData = await selectOneTable('v_clients_products_01', where_clause);
            param = {};
            if (resultData != null && resultData.length > 0) {
              await this.setResult(resultData);
              if(resultData.length >= 1000){
                this.resultError.push(DISP_MESSAGES.WARNING['2002']);
              }
              //console.log(resultData);
            }else{
              // 総件数をdataTableの総件数にセット
              this.totalRows = 0;
            }
          } else {
            param = {
              graphqlOperation: 'list_v_clients_products_02',
              where_clause: where_clause
            };
            let resultData = await selectOneTable('v_clients_products_02', where_clause);
            param = {};
            if (resultData != null && resultData.length > 0) {
              await this.setResult(resultData);
              if(resultData.length >= 1000){
                this.resultError.push(DISP_MESSAGES.WARNING['2002']);
              }
              //console.log(resultData);
            }else{
              // 総件数をdataTableの総件数にセット
              this.totalRows = 0;
            }
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result) {
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for(let i = 0; i < result.length; i++){
        let searchResult = {
          Index: i,
          ClientClass:result[i].client_class,
          ClientId:result[i].client_id,
          ClientNameKanji: result[i].client_name_kanji,
          ClientNameKana: result[i].client_name_kana,
          ProductId: result[i].product_id,
          ProductNameKanji: result[i].product_name_kanji,
          ProductNameKana: result[i].product_name_kana,
          UnitPriceEffectiveDate: formatDate(result[i].unit_price_effective_date),
          SalesUnitPrice: result[i].sales_unit_price.toLocaleString(),
          Reason: result[i].reason,
          Operation: result[i],
        };
        this.clientsProductsMasterList.push(searchResult);
      }
    },
    /* 検索条件文字列作成 */
    async makeCondition() {
      let where_clause = '';
      // 取引先コード
      if (this.searchConditions.clientId != '') {
        where_clause += 'AND client_id = ' + this.searchConditions.clientId + ' ';
      }
      // 取引先名(あいまい検索対象)
      if (this.searchConditions.clientName != '') {
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        where_clause += `AND (REPLACE(REPLACE(CONVERT(client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
        where_clause += `OR REPLACE(REPLACE(CONVERT(client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      // 製品コード
      if (this.searchConditions.productId != '') {
        where_clause += 'AND product_id LIKE \'' + this.searchConditions.productId + '%\' ';
      }
      // 製品名(あいまい検索対象)
      if (this.searchConditions.productName != '') {
        const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
        where_clause += `AND (REPLACE(REPLACE(CONVERT(product_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
        where_clause += `OR REPLACE(REPLACE(CONVERT(product_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }

      return where_clause;
    },
    // SELECT文字列作成（最新のみ）
    async makeSelectSqlOnlyNew() {
      let listClientId = [];
      let listProductId = [];
      // 取引先コード、または、取引先名(あいまい検索対象)
      let whereClauseClients = '';
      if (this.searchConditions.clientId != '' || this.searchConditions.clientName != '') {
        if (this.searchConditions.clientId != '') {
          whereClauseClients += 'AND client_id = ' + this.searchConditions.clientId + ' ';
        }
        if (this.searchConditions.clientName != '') {
          const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
          whereClauseClients += `AND (REPLACE(REPLACE(CONVERT(client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
          whereClauseClients += `OR REPLACE(REPLACE(CONVERT(client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '')) `;
        }
      }
      // 製品コード、または、製品名(あいまい検索対象)
      let whereClauseProducts = '';
      if (this.searchConditions.productId != '' || this.searchConditions.productName != '') {
        if (this.searchConditions.productId != '') {
          whereClauseProducts += 'AND product_id LIKE \'' + this.searchConditions.productId + '%\' ';
        }
        if (this.searchConditions.productName != '') {
          const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
          whereClauseProducts += `AND (REPLACE(REPLACE(CONVERT(product_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
          whereClauseProducts += `OR REPLACE(REPLACE(CONVERT(product_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '')) `;
        }
      }
      let selectClientsSql = '';
      let selectProductsSql = '';
      if (whereClauseClients != '') {
        if (this.searchConditions.clientId != '' && this.searchConditions.clientName == '') {
          listClientId.push(this.searchConditions.clientId);
        } else {
          selectClientsSql = 'SELECT client_id FROM m_clients WHERE ';
          selectClientsSql += 'client_class = ' + Const.ClientClass.customer + ' ';
          selectClientsSql += whereClauseClients;          
        }
      }
      if (whereClauseProducts != '') {
        selectProductsSql = 'SELECT product_id FROM m_products WHERE ';
        selectProductsSql += whereClauseProducts.slice(3);
      }
      let productsResult = null;
      let clientsResult = null;
      if (selectClientsSql != '' && selectProductsSql != '') {
        [clientsResult, productsResult]= await Promise.all([
          executeSelectSql(selectClientsSql),
          executeSelectSql(selectProductsSql),
        ]);
      } else if (selectClientsSql != '') {
        clientsResult = await executeSelectSql(selectClientsSql);
      } else if (selectProductsSql != '') {
        productsResult = await executeSelectSql(selectProductsSql);
      }
      if (clientsResult != null) {
        for (let i = 0; i < clientsResult.length; i++) {
          listClientId.push(clientsResult[i].client_id);
        }
      }
      if (productsResult != null) {
        for (let i = 0; i < productsResult.length; i++) {
          listProductId.push(productsResult[i].product_id);
        }
      }
      if (listClientId.length == 0 && listProductId.length == 0) {
        return '0';
      } else if ((listClientId.length == 0 || listClientId.length > this.maxCheckCountClients) &&
      (listProductId.length == 0 || listProductId.length > this.maxCheckCountProducts)) {
        return '';
      }
      let selectSqlQuery = '';
      // SELECT
      selectSqlQuery += 'SELECT ';
      selectSqlQuery += ' clients_products.client_class';
      selectSqlQuery += ',clients_products.client_id';
      selectSqlQuery += ',clients.client_name_kanji';
      selectSqlQuery += ',clients.client_name_kana';
      selectSqlQuery += ',clients_products.product_id';
      selectSqlQuery += ',products.product_name_kanji';
      selectSqlQuery += ',products.product_name_kana';
      // FROM句
      selectSqlQuery += ' FROM ';
      selectSqlQuery += '(SELECT client_class,client_id,product_id ';
      selectSqlQuery += 'FROM m_clients_products WHERE ';
      if (0 < listClientId.length && listClientId.length <= this.maxCheckCountClients) {
        selectSqlQuery += 'client_id IN (' + listClientId.join(',') + ') ';
        if (0 < listProductId.length && listProductId.length <= this.maxCheckCountProducts) {
          selectSqlQuery += 'AND product_id IN (' + listProductId.join(',') + ') ';
        }
      } else {
        if (0 < listProductId.length && listProductId.length <= this.maxCheckCountProducts) {
          selectSqlQuery += 'product_id IN (' + listProductId.join(',') + ') ';
        }
      }
      selectSqlQuery += 'GROUP BY client_class,client_id,product_id ';
      if (listClientId.length <= this.maxCheckCountClients && listProductId.length <= this.maxCheckCountProducts) {
        selectSqlQuery += 'LIMIT 1000 ';
      }
      selectSqlQuery += ') AS clients_products ';
      selectSqlQuery += 'LEFT JOIN m_clients AS clients ';
      selectSqlQuery += 'ON clients_products.client_class = clients.client_class ';
      selectSqlQuery += 'AND clients_products.client_id = clients.client_id ';
      selectSqlQuery += 'INNER JOIN m_products AS products ';
      selectSqlQuery += 'ON clients_products.product_id = products.product_id ';
      if (listClientId.length > this.maxCheckCountClients || listProductId.length > this.maxCheckCountProducts) {
        selectSqlQuery += ' WHERE ';
        if (listClientId.length > this.maxCheckCountClients) {
          whereClauseClients = '';
          if (this.searchConditions.clientId != '') {
            whereClauseClients += 'AND clients_products.client_id = ' + this.searchConditions.clientId + ' ';
          }
          if (this.searchConditions.clientName != '') {
            const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
            whereClauseClients += `AND (REPLACE(REPLACE(CONVERT(clients.client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
            whereClauseClients += `OR REPLACE(REPLACE(CONVERT(clients.client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '')) `;
          }
          selectSqlQuery += whereClauseClients.slice(3);
        }
        if (listProductId.length > this.maxCheckCountProducts) {
          whereClauseProducts = '';
          if (this.searchConditions.productId != '') {
            whereClauseProducts += 'AND clients_products.product_id LIKE \'' + this.searchConditions.productId + '%\' ';
          }
          if (this.searchConditions.productName != '') {
            const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
            whereClauseProducts += `AND (REPLACE(REPLACE(CONVERT(products.product_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
            whereClauseProducts += `OR REPLACE(REPLACE(CONVERT(products.product_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '')) `;
          }
          selectSqlQuery += whereClauseProducts.slice(3);
        }
      }
      // 最新日付の金額と理由を取得するためにサブクエリとしてそれと結合
      let selectSql = '';
      selectSql += 'SELECT';
      selectSql += ' clients_products.client_class';
      selectSql += ',clients_products.client_id';
      selectSql += ',sub_QUERY.client_name_kanji';
      selectSql += ',sub_QUERY.client_name_kana';
      selectSql += ',clients_products.product_id';
      selectSql += ',sub_QUERY.product_name_kanji';
      selectSql += ',sub_QUERY.product_name_kana';
      selectSql += ',clients_products.unit_price_effective_date';
      selectSql += ',clients_products.sales_unit_price';
      selectSql += ',clients_products.reason';
      selectSql += ' FROM ';
      selectSql += 'm_clients_products AS clients_products ';
      // サブクエリ（開始）
      selectSql += 'INNER JOIN (';
      // SELECT
      selectSql += 'SELECT ';
      selectSql += ' clients_products.client_class';
      selectSql += ',clients_products.client_id';
      selectSql += ',max_QUERY.client_name_kanji';
      selectSql += ',max_QUERY.client_name_kana';
      selectSql += ',clients_products.product_id';
      selectSql += ',max_QUERY.product_name_kanji';
      selectSql += ',max_QUERY.product_name_kana';
      selectSql += ',MAX(clients_products.unit_price_effective_date) AS unit_price_effective_date';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 'm_clients_products AS clients_products ';
      selectSql += 'INNER JOIN ('+ selectSqlQuery + ') AS max_QUERY ';
      selectSql += 'ON clients_products.client_class = max_QUERY.client_class ';
      selectSql += 'AND clients_products.client_id = max_QUERY.client_id ';
      selectSql += 'AND clients_products.product_id = max_QUERY.product_id ';
      selectSql += 'GROUP BY clients_products.client_class,clients_products.client_id,clients_products.product_id ';
      selectSql += 'LIMIT 1000 ';
      // サブクエリ（終了）
      selectSql += ') AS sub_QUERY ';
      selectSql += 'ON clients_products.client_class = sub_QUERY.client_class ';
      selectSql += 'AND clients_products.client_id = sub_QUERY.client_id ';
      selectSql += 'AND clients_products.product_id = sub_QUERY.product_id ';
      selectSql += 'AND clients_products.unit_price_effective_date = sub_QUERY.unit_price_effective_date ';
      selectSql += 'ORDER BY clients_products.client_class,clients_products.client_id,clients_products.product_id,clients_products.unit_price_effective_date ';

      return selectSql;
    },
    /* 新規登録ボタン押下時 */
    async clickInputBtn() {
      //console.log('新規登録');
      // 取引先製品マスタ入力へ遷移
      this.$router.push({ name: 'CLIENTS-PRODUCTS-MASTER-INPUT' });
    },
    /* 編集ボタン押下時 */
    async clickEditBtn(clientClass, clientId, productId, unitPriceEffectiveDate) {
      //console.log('編集');
      // 取引先製品マスタ修正画面へ遷移
      //console.log(clientClass);
      //console.log(clientId);
      //console.log(productId);
      //console.log(unitPriceEffectiveDate);
      let route = this.$router.resolve({ name: 'CLIENTS-PRODUCTS-MASTER-EDIT', query: { propClientClass: clientClass, propClientId: clientId, propProductId: productId, propUnitPriceEffectiveDate: unitPriceEffectiveDate, propInsertFlg: false } });
      window.open(route.href, '_blank');
    },
    /* 削除ボタン押下時 */
    async clickDeleteBtn(clientClass, clientId, productId, unitPriceEffectiveDate) {
      //console.log('削除');
      this.delData = { ClientClass: clientClass, ClientId: clientId, ProductId: productId, UnitPriceEffectiveDate: unitPriceEffectiveDate };
      this.$bvModal.show('confirmModal');
    },
    /* 削除実行 */
    async execDeleteBtn() {
      const functionName = 'execDeleteBtn';
      // 取引先製品マスタの削除SQL
      let deleteSql = 'DELETE FROM m_clients_products';
      deleteSql += ' WHERE ';
      // 取引先区分
      deleteSql += 'client_class = ' + this.delData.ClientClass + ' ';
      // 取引先コード
      deleteSql += 'AND client_id = ' + this.delData.ClientId + ' ';
      // 製品コード
      deleteSql += 'AND product_id = ' + this.delData.ProductId + ' ';
      // 単価適用日
      deleteSql += 'AND unit_price_effective_date = ' + '\'' + this.delData.UnitPriceEffectiveDate + '\' ';
      //console.log(deleteSql);

      let sqlList = [];
      sqlList.push(deleteSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    /* 削除確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.execDeleteBtn();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 一括削除イベント
    async clickBulkDeleteBtn() {
      const functionName = 'clickBulkDeleteBtn';
      if (this.checkedIndexs.length > this.maxCheckBulkDelCount) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2042'].replace('%arg1%', 1).replace('%arg2%', this.maxCheckBulkDelCount));
        scrollTo(0,0);
        return;
      }
      let confirmMessage = 'チェックONの取引先製品単価情報を全て削除します。';
      confirmMessage += 'よろしいですか？';
      let title = '一括削除確認';
      if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: title}) == true) {
        try {
          // モーダルから渡された値の有無チェック
          this.$store.commit('setLoading', true);
          await this.execBulkDeleteBtn();
        } catch(error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
          console.log(error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        }
        // メッセージが１件でもある場合は一番上へスクロール
        if (this.getMessageFlg == true) {
          scrollTo(0,0);
        }
        this.$store.commit('setLoading', false);
      }
    },
    // 一括削除実行
    async execBulkDeleteBtn() {
      const functionName = 'execBulkDeleteBtn';
      let sqlList = [];
      for (let i = 0; i < this.checkedIndexs.length; i++) {
        let delData = this.clientsProductsMasterList[this.checkedIndexs[i]];
        //console.log(delData);
        // 取引先製品マスタの削除SQL
        let deleteSql = 'DELETE FROM m_clients_products';
        deleteSql += ' WHERE ';
        deleteSql += 'client_class = ' + delData.ClientClass + ' ';
        deleteSql += 'AND client_id = ' + delData.ClientId + ' ';
        deleteSql += 'AND product_id = ' + delData.ProductId + ' ';
        deleteSql += 'AND unit_price_effective_date = ' + '\'' + delData.UnitPriceEffectiveDate + '\' ';
        //console.log(deleteSql);
        sqlList.push(deleteSql);
      }
      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    // 取引先親子取得
    async getClientParent() {
      const functionName = 'getClientParent';
      let clientId = this.searchConditions.clientId;
      let selectSql = 'SELECT';
      selectSql += ' clients_parent_child.client_id_parent';
      selectSql += ',clients_parent_child.client_id_branch';
      selectSql += ' FROM ';
      selectSql += 'm_clients_parent_child AS clients_parent_child ';
      selectSql += 'INNER JOIN (SELECT client_id_parent FROM m_clients_parent_child WHERE ';
      selectSql += '(client_class_parent = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND client_id_parent = ' + clientId + ') ';
      selectSql += 'OR (client_class_branch = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND client_id_branch = ' + clientId + ') ';
      selectSql += 'LIMIT 1) AS clients_parent ';
      selectSql += 'ON clients_parent_child.client_class_parent = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND clients_parent.client_id_parent = clients_parent_child.client_id_parent ';
      selectSql += 'ORDER BY clients_parent_child.client_id_branch';
      //console.log(selectSql);
      try {
        this.$store.commit('setLoading', true);
        let resultData = await executeSelectSql(selectSql);
        this.titleClientsParent = '検索取引先コード：' + clientId;
        if (resultData != null && resultData.length > 0) {
          this.titleClientsParent += '\r\n親コード：' + resultData[0].client_id_parent + '\r\n子コード：';
          for (let i = 0; i < resultData.length; i++) {
            if (i >= this.maxClientsParentChildCount) {
              // 最大数を超えた場合は「等...全XX件」を表示
              this.titleClientsParent += '\r\n等...全' + resultData.length + '件';
              break;
            }
            if (i > 0) {
              this.titleClientsParent += '\r\n　　　：';
            }
            this.titleClientsParent += resultData[i].client_id_branch;
          }
        } else {
          this.titleClientsParent += '\r\n親子無し';
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
      this.checkedIndexs = [];
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
      this.checkedIndexs = [];
    },
  },
}
</script>