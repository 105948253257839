<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" />
  <b-container class="px-4 py-4 min-vh-85">
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 親取引先マスタ入力</strong></h5>
              <b-button pill v-b-tooltip.hover title="親取引先マスタ一覧に戻る" @click="clickBack" class="btn-cancel m-0">
                <span class="oi oi-circle-x"></span> キャンセル
              </b-button>
            </div>
          </b-media-body>
        </b-media>
        <b-card class="main-card mb-3">
          <b-card-header v-if="getMessageFlg==true">
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
            <validation-observer ref="observer">
              <b-form @submit.prevent="clearAlert(); saveData();" id="InputForm" class="form-horizontal">
                <b-row>
                  <!-- 取引先コード（親） -->
                  <b-col lg="12">
                    <validation-provider name="suppliersCodeParent" rules="required|numeric|min:6" v-slot="{ classes,errors }">
                      <b-form-group
                        label="取引先コード（親）"
                        label-for="suppliersCodeParent"
                      >
                        <b-row>
                          <b-col lg="6" :class="classes">
                            <b-input-group>
                              <b-form-input 
                                type="text" 
                                id="suppliersCodeParent" 
                                name="suppliersCodeParent" 
                                v-model="suppliersParent.code"
                                @input="searchClient(suppliersParent.code, {openModalIndex: 1})" 
                                maxlength="6" 
                              />
                              <b-input-group-text @click="showClientSearchModal(1)" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(1)" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                              <b-button class="mr-1" size="sm" pill variant="success" title="取引先コード（親）に入力された取引先の情報を照会します。（取引先コードが空欄以外の場合、利用可能です。）" :disabled="suppliersParent.code == null" @click="clientInfoModal(suppliersParent.class, suppliersParent.code)">
                                <span class="oi oi-eye"></span> 情報
                              </b-button>
                            </b-input-group>
                          </b-col>
                          <b-col lg="7" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">検索ボタンから選択してください<br>情報ボタンは入力されたコードに紐づく詳細情報を表示します</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 取引先名（親） -->
                  <b-col lg="12">
                    <b-form-group
                      label="取引先名（親）"
                      label-for="suppliersNameParent"
                    >
                      <b-row>
                        <b-col lg="10">
                          <b-form-input type="text" id="suppliersNameParent" v-model="suppliersParent.name" readonly />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 取引先名 -->
                  <b-col lg="12">
                    <validation-provider name="clientName" rules="max:34" v-slot="{ classes,errors }">
                      <b-form-group
                        label="取引先名"
                        label-for="clientName"
                      >
                        <b-row>
                          <b-col lg="10" :class="classes">
                            <b-form-input id="clientName" name="clientName" type="text" v-model="textClientName" />
                          </b-col>
                          <b-col lg="10" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">取引先マスタの取引先名とは別の親取引先マスタ独自の確認用の名称を設定してください。</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 取引先コード（子） -->
                  <b-col lg="12">
                    <validation-provider name="suppliersCodeBranch" :rules="{required:true,numeric:true,is_not:suppliersParent.code,min:6}" v-slot="{ classes,errors }">
                      <b-form-group
                        label="取引先コード（子）"
                        label-for="suppliersCodeBranch"
                      >
                        <b-row>
                          <b-col lg="6" :class="classes">
                            <b-input-group>
                              <b-form-input 
                                type="text" 
                                id="suppliersCodeBranch" 
                                name="suppliersCodeBranch" 
                                v-model="suppliersBranch.code" 
                                @input="searchClient(suppliersBranch.code, {openModalIndex: 2})" 
                                maxlength="6" 
                              />
                              <b-input-group-text @click="showClientSearchModal(2)" v-b-tooltip.hover title="「ALT+2」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(2)" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                              <b-button class="mr-1" size="sm" pill variant="success" title="取引先コード（子）に入力された取引先の情報を照会します。（取引先コードが空欄以外の場合、利用可能です。）" :disabled="suppliersBranch.code == null" @click="clientInfoModal(suppliersBranch.class, suppliersBranch.code)">
                                <span class="oi oi-eye"></span> 情報
                              </b-button>
                            </b-input-group>
                          </b-col>
                          <b-col lg="7" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                        <b-form-text class="text-muted">検索ボタンから選択してください<br>情報ボタンは入力されたコードに紐づく詳細情報を表示します</b-form-text>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- 取引先名（子） -->
                  <b-col lg="12">
                    <b-form-group
                      label="取引先名（子）"
                      label-for="suppliersNameBranch"
                    >
                      <b-row>
                        <b-col lg="10">
                          <b-form-input type="text" id="suppliersNameBranch" v-model="suppliersBranch.name" readonly />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- 保存ボタン -->
                <b-row class="justify-content-md-center pb-4">
                  <b-col lg="2">
                    <b-button  class="mr-2" pill block variant="success" title="入力されたデータを保存します。" type="submit" form="InputForm">
                      <span class="oi oi-circle-check"></span> 保存
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <!-- ●●●フッター●●● -->
  <Footer />
  <!-- ●●●取引先検索モーダル●●● -->
  <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="htmlConst.ClientClass.customer"/>
  <!-- ●●●取引先照会モーダル●●● -->
  <CLIENTINQUIRY :clientProp="propClientInquiry"/>
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { searchClientModal } from '@/assets/js/searchModal.js';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, CreateInsertSql, CreateColRow, executeTransactSqlList, isSystemEditable, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'clients-parent-child-master-input';

export default {
  name: 'CLIENTS-PARENT-CHILD-MASTER-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    CLIENTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '親取引先マスタ入力',
      // アラート
      alertWarning: [],
      alertDanger: [],
      // 取引先系（親）
      suppliersParent:{
        class: null,
        code: null,
        name: '',
      },
      // 取引先名
      textClientName: '',
      // 取引先系（子）
      suppliersBranch:{
        class: null,
        code: null,
        name: '',
      },
      // モーダルからの戻り値を反映先
      openModalIndex: 0,
      // ログイン情報
      loginId: '',
      // トランザクションSQLリスト
      transactSqlList: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 確認ダイアログ用
      confirmMessage: [],
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // チェック
      if (await this.checkDuplicate() == false) {
        // 重複チェック
        this.alertWarning.push(DISP_MESSAGES.WARNING['2007']);
      } else if (await this.checkParent() == false) {
        // 親登録済みチェック
        this.alertWarning.push(DISP_MESSAGES.WARNING['2008']);
      } else {
        // 保存処理
        //console.log('保存処理開始');
        if (await this.execSave() == true) {
          // 親取引先マスタ一覧へ遷移
          this.$router.push({ name: 'CLIENTS-PARENT-CHILD-MASTER-LIST', params: { propInsertFlg: true } });
        }
        //console.log('保存処理終了');
      }
    },
    /* 重複チェック */
    async checkDuplicate() {
      let where_clause = '';
      // 取引先区分（子）
      where_clause += 'AND client_class_branch = ' + this.suppliersBranch.class + ' ';
      // 取引先コード（子）
      where_clause += 'AND client_id_branch = \'' + this.suppliersBranch.code + '\'';

      let dataClientsProducts = await selectOneTable('m_clients_parent_child', where_clause);
      //console.log(dataClientsProducts);

      // 1件以上の場合は重複
      if (dataClientsProducts != null && dataClientsProducts.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    /* 親登録済みチェック */
    async checkParent() {
      let where_clause = '';
      // 取引先区分（子）
      where_clause += 'AND client_class_parent = ' + this.suppliersBranch.class + ' ';
      // 取引先コード（子）
      where_clause += 'AND client_id_parent = \'' + this.suppliersBranch.code + '\'';

      let dataClientsProducts = await selectOneTable('m_clients_parent_child', where_clause);
      //console.log(dataClientsProducts);

      // 1件以上の場合は重複
      if (dataClientsProducts != null && dataClientsProducts.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    /* 登録処理 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];

      // 親取引先マスタ登録処理
      await this.insertClientsParentChild();
      
      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return retResult;
      }
      retResult = true;

      return retResult;
    },
    /* 親取引先マスタ登録SQL作成 */
    async insertClientsParentChild() {
      //console.log('親取引先マスタ登録SQL作成');
      let colList = [];
      // 取引先区分（親）
      colList.push(CreateColRow('client_class_parent', this.suppliersParent.class, 'NUMBER'));
      // 取引先コード（親）
      colList.push(CreateColRow('client_id_parent', this.suppliersParent.code, 'NUMBER'));
      // 取引先名
      colList.push(CreateColRow('client_name_kanji', this.textClientName, 'VARCHAR'));
      // 取引先区分（子）
      colList.push(CreateColRow('client_class_branch', this.suppliersBranch.class, 'NUMBER'));
      // 取引先コード（子）
      colList.push(CreateColRow('client_id_branch', this.suppliersBranch.code, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      this.transactSqlList.push(CreateInsertSql(colList, 'full', 'm_clients_parent_child'));
    },
    // 取引先検索
    showClientSearchModal: function(index) {
      //console.log('取引先検索モーダル');
      this.openModalIndex = index;
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id, {openModalIndex}) {
      this.openModalIndex = openModalIndex;
      const result = await searchClientModal(client_id, this.htmlConst.ClientClass.customer, 'searchClient', MODULE_NAME)
      if (typeof result != 'undefined') {
        this.closeClientSearchModal(result)
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6) {
          // 取引先クリア
          this.closeClientSearchModal({detail:{}})
        }
      }
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal: function(clientItems) {
      // モーダルから渡された値の有無チェック
      if (typeof clientItems != 'undefined') {
        if (this.openModalIndex == 1) {
          this.suppliersParent.class = clientItems.detail.client_class;
          this.suppliersParent.code = clientItems.detail.client_id;
          this.suppliersParent.name = clientItems.detail.client_name_kanji;
        } else {
          this.suppliersBranch.class = clientItems.detail.client_class;
          this.suppliersBranch.code = clientItems.detail.client_id;
          this.suppliersBranch.name = clientItems.detail.client_name_kanji;
        }
      }
    },
    /* 取引先照会 */
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* キャンセルボタン押下時 */
    async clickBack() {
      // 親取引先マスタ一覧画面へ遷移
      this.$router.push({ name: 'CLIENTS-PARENT-CHILD-MASTER-LIST' });
    },
  }
}
</script>
<style scoped>
  /* 設定編集のドラッグアンドドロップボタン */
  .handle {
    cursor: move;
    position: relative;
  }

  .modalButton {
    cursor: pointer;
  }

  table {
    display: table !important;
    width: 100% !important;
    border-collapse: collapse;
    table-layout: fixed;
    white-space: nowrap;
  }
  .datatable{
    table-layout:auto;
  }
</style>