<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2">
            <b-card>
              <div class="mb-0 p-2" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-2">
                  <validation-observer ref="observer">
                    <b-container>
                      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                          <li>{{error}}</li>
                        </ul>
                      </b-alert>
                      <!-- ●●●検索条件●●● -->
                      <b-container fluid>
                        <b-row class="mt-2">
                          <!-- 営業所入力欄 -->
                          <b-col lg="6">
                            <b-form-group
                              label="営業所"
                            >
                              <b-form-select
                                v-model="searchConditions.selectSalesOffice"
                                :options="salesOffice"
                                value-field="id"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <!-- トランザクションID入力欄 -->
                          <b-col lg="6">
                            <b-form-group
                              label="トランザクションID"
                            >
                              <b-form-select
                                v-model="searchConditions.selectTransactionId"
                                :options="transactionIdList"
                                value-field="id"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-container>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center my-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
          <ul v-for="(message,index) in alertWarning" :key="index" style="list-style: none;">
            <li>{{message}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="4"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(operation)="operation">
                <!-- 編集ボタン -->
                <b-button size="sm" v-b-tooltip.hover.left="'伝票番号の設定を行います。'" @click="clickDetailBtn(operation)" class="mr-1">
                  <span class="oi oi-pencil"></span> 編集
                </b-button>&nbsp;
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { executeTransactSql } from '@/graphql/mutations';
import { init, addOperationLogs, getListValue, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
// モジュール名
const MODULE_NAME = 'slip-no-maintenance';
export default {
  name: 'SLIP-NO-MAINTENANCE',
  /** コンポーネント */
  components: {
    Header,
    Footer,
  },
  props:['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '伝票No管理保守',
      // アラート
      alertWarning:[],
      alertDanger : [],
      // コンボボックス
      salesOffice: [],
      // トランザクションIDリスト取得
      transactionIdList: Const.TransactionIdList.map( list => ({...list})),
      // 検索条件
      searchConditions:{
        selectSalesOffice: '',
        selectTransactionId: '',
      },
      // 全件検索用
      addConditions:{id: 0, name: '全て', text: '全て'},
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // コンボボックスの作成
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'salesOffice',
          label: '営業所',
          sortable: true,
        },
        {
          key: 'billingType',
          label: '伝票種類（トランザクションID）',
          sortable: true,
        },
        {
          key: 'currentNo',
          label: '現在No',
          sortable: true,
        },
        {
          key: 'minNo',
          label: '最小No',
          sortable: true,
        },
        {
          key: 'maxNo',
          label: '最大No',
          sortable: true,
        },
        {
          key: 'operation',
          label: '操作',
          sortable: true,
        },
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods:{
    /* フェッチ */
    async fetchData(){
      // ローディングの開始
      this.$store.commit('setLoading', true);
      // storeからログイン中ユーザーの情報を取得
      this.loginStaffInfo = store.getters.user.username;

      // 営業所データ取得
      if(!await this.getOfficesData()){
        // ローディングの解除
        this.$store.commit('setLoading', false);
        return;
      }
      if(!this.transactionIdList.some(({ id }) => id === 0)){
        this.transactionIdList.unshift(this.addConditions)
      }
      // default値挿入
      this.searchConditions.selectTransactionId = this.addConditions.id;

      // ローディングの解除
      this.$store.commit('setLoading', false);
    },
    /* 営業所情報を取得 */
    async getOfficesData() {
      // ログ出力用メソッド名
      const functionName = 'getOfficesData';
      // 担当者データ取得
      let staffListData = null;
      // storeからログイン中ユーザーの情報を取得
      let user = store.getters.user;
      this.loginId = user.username;
      // 検索条件を設定
      let where_clause = `AND login_id = '${this.loginId}'`;
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await selectOneTable('m_staffs', where_clause);
        if (result != null && result.length > 0) {
          // データを取得できた場合、取得データを返却
          staffListData = result;
        } else {
          // データを取得できなかった場合
          await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_staffs'}, 'result is null');
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_staffs'}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      let loginOfficeId = staffListData[0].office_id;
      try {
        // 営業所データ取得
        let officeListData = await selectOneTable('m_offices');
        // default値挿入
        this.searchConditions.selectSalesOffice = 0;
        // 営業所プルダウン作成
        for(const officeData of officeListData){
          this.salesOffice.push({
            id: officeData.office_id,
            name: officeData.office_name_kanji,
            text: officeData.office_id + '：' + officeData.office_name_kanji,
          });
          // default値挿入
          if (loginOfficeId == officeData.office_id) {
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
        // 検索条件「全て」を追加
        this.salesOffice.unshift(this.addConditions);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_offices'}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      return true;
    },
    /* 検索ボタン押下時 */
    async searchButton(){
      // ログ出力用メソッド名
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        } else {
          // ローディングの開始
          this.$store.commit('setLoading', true);
          // 検索開始
          await this.search();
        }
      } catch (error) {
        // Exception発生の場合
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
      }
      // ローディングの終了
      this.$store.commit('setLoading', false);
    },
    /* 検索処理 */
    async search(){
      // ログ出力用メソッド名
      const functionName = 'search';
      this.itemList = [];
      // 明細テーブルのローディングを開始
      this.busy = true;
      // CRUD処理
      let selectSql;
      selectSql = await this.makeSelectSql();
      let result;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: selectSql }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql
        }, error);
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql,
          result: result
        });
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: selectSql,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
        // 明細テーブルのローディングを終了
        this.busy = false;
        return;
      }
      // データ取得時
      let resultData;
      const responseBody = JSON.parse(result.data.executeTransactSql.body)
      resultData = responseBody.data[0];      
      if(resultData){
        await this.setResult(resultData);
        if(resultData.length >= 1000){
          this.alertWarning.push(DISP_MESSAGES.WARNING['2002']);
        }
      } else {
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
      }
      // 明細テーブルのローディングを解除
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result){
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for(const data of result){
        this.itemList.push({
          salesOffice : getListValue(this.salesOffice, data.free_class_1, 'id', 'text'),
          billingType : getListValue(this.transactionIdList, data.free_class_2, 'id', 'name') + getListValue(Const.CounterClassList, data.counter_class, 'id', 'name') + `（${data.free_class_2}）`,
          currentNo : data.count_cur,
          minNo : data.count_min,
          maxNo : data.count_max,
          reqSalesOffice : data.free_class_1,
          transactionId : data.free_class_2,
          counterClass : data.counter_class,
        });
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql(){
      // 検索条件
      let whereClauses = [];
      // カウンター区分
      whereClauses.push(`form_counter.counter_class = ${Const.CounterClass.billingNo}`);
      // 営業所コード
      if (this.searchConditions.selectSalesOffice) {
        whereClauses.push(`form_counter.free_class_1 = ${this.searchConditions.selectSalesOffice}`);
      }
      // トランザクションID区分
      if (this.searchConditions.selectTransactionId) {
        whereClauses.push(`form_counter.free_class_2 = '${this.searchConditions.selectTransactionId}'`);
      }
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' form_counter.count_cur'; // カウント（現在）
      selectSql += ',form_counter.count_min'; // カウント（最小）
      selectSql += ',form_counter.count_max'; // カウント（最大）
      selectSql += ',form_counter.counter_class'; // カウンター区分
      selectSql += ',form_counter.free_class_1'; // フリー区分（営業所）
      selectSql += ',form_counter.free_class_2'; // フリー区分（トランザクション）
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_form_counter AS form_counter ';

      /* WHERE句 */
      // 検索条件が指定された場合
      if(whereClauses.length !== 0){
        selectSql += `WHERE ${whereClauses.join(' AND ')} ` 
      }
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' form_counter.count_cur';
      /* LIMIT */
      selectSql += ' LIMIT 1000';
      return selectSql;
    },
    /* 編集ボタン押下時 */
    async clickDetailBtn(item){
      let route = this.$router.resolve({ name: 'SLIP-NO-MAINTENANCE-EDIT', query: { reqSalesOffice : item.item.reqSalesOffice, reqTransactionId: item.item.transactionId, reqCounterClass: item.item.counterClass } });
      window.open(route.href, '_blank');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>
<style scoped>
.input-daterange{
  min-width: 27vw;
}
</style>