<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
                  <strong> 入出庫入力</strong>
                  <b-form-text class="text-muted d-inline-flex">(編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください)</b-form-text>
                </h5>
                <b-button pill v-b-tooltip.hover.noninteractive.bottom title="入出庫一覧に戻る" @click="cancelPurchaseInput" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </div>
            </b-media-body>
          </b-media>
          <div class="main-card card">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveOrder();" id="receivedOrderInputForm" class="form-horizontal">
                  <b-row>
                    <!-- 営業所コードプルダウン -->
                    <b-col lg="3">
                      <b-form-group
                        label="営業所"
                        label-for="selectSalesOffice"
                      >
                        <b-form-select
                          id="selectSalesOffice"
                          v-model="selectSalesOffice"
                          :options="salesOffice"
                          value-field="id"
                          @change="setStocksBalance"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- 伝票日付 -->
                    <b-col lg="3">
                      <validation-provider name="dateOrderReceiveDate" :rules="{required: true, oldProcessMonthYear: controlMasterData.processMonthYear}" v-slot="{ classes,errors }">
                        <b-form-group
                          label="伝票日付"
                          label-for="dateOrderReceiveDate"
                        >
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-input-group class="input-daterange">
                                <b-form-datepicker
                                  id="dateOrderReceiveDate"
                                  name="dateOrderReceiveDate"
                                  v-model="dateOrderReceiveDate"
                                  calendar-width="50%"
                                ></b-form-datepicker>
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <!-- 伝票種別区分プルダウン -->
                    <b-col lg="3">
                      <validation-provider name="selectBillClass" rules="required" v-slot="{ classes,errors }">
                        <b-form-group
                          label="伝票種別"
                          label-for="selectBillClass"
                        >
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-input-group>
                                <b-form-select
                                  id="selectBillClass"
                                  v-model="selectBillClass"
                                  :options="purchaseBillClassList"
                                  value-field="id"
                                  @change="changeDestination()"
                                />
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- 移動先コード -->
                    <validation-provider name="destinationId" :rules="{required:selectBillClass==htmlConst.Tradition.storageMove,is_not:selectSalesOffice}" v-slot="{ classes,errors }">
                      <b-col :class="classes">
                        <b-form-group
                          label="移動先"
                          label-for="destinationId"
                        >
                          <b-form-select
                            id="destinationId"
                            name="destinationId"
                            v-model="destinationId"
                            :options="destinationList"
                            value-field="id"
                            :disabled="selectBillClass!=htmlConst.Tradition.storageMove"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col lg="8" :class="classes">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                      </b-col>
                    </validation-provider>
                  </b-row>
                  <b-row>
                    <!-- 製品 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :small="true"
                            :items="productList"
                            :fields="fields"
                            :responsive="true"
                          >
                            <template #table-caption>
                              <b-btn-toolbar>
                                <span class="mt-2 mr-2">&nbsp;製品テーブル</span> 
                                <!-- 行追加ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" type="button" @click="addTableRow()">
                                  <span class="oi oi-plus"></span> 行追加
                                </b-button>&nbsp;
                              </b-btn-toolbar>
                            </template>
                            <template #cell(ProductCode)="data">
                              <validation-provider :rules="{numeric: true, min: 8, max: 8, required: true,duplicate:{title:'製品コード',idList:productList,key:'ProductCode',NoCheckList:[0]}}" v-slot="{ classes,errors }">
                                <div :class="classes" style="min-width: 140px;">
                                  <b-input-group>
                                    <b-form-input 
                                      size="sm" 
                                      class="px-1"
                                      type="text" 
                                      name="productId" 
                                      maxlength="8"
                                      @input="searchProduct(data.item.ProductCode, data.index)"
                                      v-model="data.item.ProductCode" 
                                      style="width: 80px;"
                                      />
                                    <b-input-group-text @click="showProductSearchModal(data.index)">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                            <template #cell(ProductName)="data">
                              <validation-provider rules="max:35" v-slot="{ classes,errors }">
                                <div :class="classes" style="min-width: 240px;">
                                  <b-input-group>
                                    <b-form-input size="sm" class="px-1" type="text" name="productName" v-model="data.item.ProductName" readonly v-b-tooltip.hover.noninteractive.right="data.item.ProductName" />
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                            <template #cell(Quantity)="data">
                              <validation-provider :rules="{required: true, storage_between: {billClass: selectBillClass, balance: data.item.Balance}}" v-slot="{ classes,errors }">
                                <div :class="classes" style="min-width: 70px;">
                                  <b-input-group>
                                    <b-form-input size="sm" class="px-1" type="number" :id="'productQuantity' + data.index" name="productQuantity" v-model.number="data.item.Quantity" />
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                            <template #cell(StockQuantity)="data">
                              {{ data.item.StockQuantity.toLocaleString() }}
                            </template>
                            <template #cell(Balance)="data">
                              {{ data.item.Balance.toLocaleString() }}
                            </template>
                            <template #cell(TotalBalance)="data">
                              {{ getTotalBalance(data.item.Quantity, data.item.StockQuantity).toLocaleString() }}
                            </template>
                            <template #cell(delete)="data">
                              <b-button size="sm" v-b-tooltip.hover.noninteractive title="この行を削除をします。" @click="deleteBtn(data.index)">
                                <span class="oi oi-delete"></span> 削除
                              </b-button>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <!-- 摘要 -->
                      <b-form-group
                        label="摘要"
                        label-for="billingSummary"
                      >
                        <validation-provider name="billingSummary" rules="max:40" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <b-form-input type="text" id="billingSummary" name="billingSummary" v-model="billingSummary" maxlength="40"></b-form-input>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- 保存ボタン -->
                  <b-row class="justify-content-md-center">
                    <b-col lg="2">
                      <b-button pill variant="success" type="submit" form="receivedOrderInputForm" class="btn btn-block" @click="delListLastBlankRow"><span class="oi oi-circle-check"></span> 保存</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :product-search-prop="productSearchProp"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import { searchProductsModal } from '@/assets/js/searchModal.js';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, getControlMaster, formatCurDate, getListValue, getNullStr, escapeQuote, CreateInsertSql, CreateUpdateSql, CreateColRow, getFormCounter, executeTransactSqlList, reserveStockProductIdList, isSystemEditable, isAfterMonthlyUpdate, modStockProductIdListCase, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
const MODULE_NAME = 'loading-unloading-input';
export default {
  name: 'LOADING-UNLOADING-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    PRODUCTSEARCH,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '入出庫入力',
      loginId: '',
      // アラート
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOffice: null,
      salesOffice: [],
      // 移動先プルダウン
      destinationId: 0,
      destinationList: [],
      // 伝票日付
      dateOrderReceiveDate: '',
      // 伝票種別
      selectBillClass: null,
      purchaseBillClassList: [],
      // 伝票摘要
      billingSummary: '',
      // テーブル定義
      productList:[],
      dataIndex: 0,
      // 製品検索情報
      productSearchProp:{
        office_id: '',
        office_name: '',
        client_id: '',
        client_class: '',
        client_name: '',
        multiSelectFlg: '1',
      },
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // トランザクションSQLリスト
      transactSqlList:[],
      // 確認ダイアログ用
      confirmMessage: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 伝票種別
        Tradition: {
          // 調整入庫
          storageAdd: Const.Tradition.storageAdd,
          // 調整出庫
          storageDel: Const.Tradition.storageDel,
          // 移動
          storageMove: Const.Tradition.storageMove,
        },
      },
      // 在庫増加製品
      stockProductId: [],
    }
  },
  computed: {
    fields: function() {
      return [
        {
          key: 'ProductCode',
          label: '製品コード',
        },
        {
          key: 'ProductName',
          label: '製品名',
        },
        {
          key: 'Quantity',
          label: '数量',
        },
        {
          key: 'Unit',
          label: '単位',
        },
        {
          key: 'StockQuantity',
          label: '現在庫数',
        },
        {
          key: 'Balance',
          label: '使用可能在庫数',
        },
        {
          key: 'TotalBalance',
          label: '合計数量',
        },
        {
          key: 'delete',
          label: ' ',
        },
      ]
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
        this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 製品テーブル初期表示データ用意
        this.addTableRow();
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
        ]);
        //console.log(staffListData);
        //console.log(officeListData);
        // 移動先コードは未選択も選択可能とする。
        this.destinationList.push({id: 0, text: '未選択'});
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.salesOffice.push(office);
          this.destinationList.push(office);
        }
        // 営業所データ初期値セット
        for(let i = 0; i < this.salesOffice.length; i++){
          //console.log(this.salesOffice[i].id);
          if(this.salesOffice[i].id == staffListData[0].office_id){
            this.selectSalesOffice = this.salesOffice[i].id;
            break;
          }
        }
        // 日付に現在日を設定
        this.dateOrderReceiveDate = formatCurDate('YYYY-MM-DD');
        // 伝票種別プルダウン
        this.purchaseBillClassList.push({id: null, text: '選択してください。'});
        for (let i = 0; i < Const.TraditionList.length; i++) {
          // 「51:調整入庫」「52:調整出庫」「53:移動」
          if (Const.TraditionList[i].id == Const.Tradition.storageAdd ||
          Const.TraditionList[i].id == Const.Tradition.storageDel ||
          Const.TraditionList[i].id == Const.Tradition.storageMove) {
            this.purchaseBillClassList.push(Const.TraditionList[i]);
          }
        }
        // 現在処理年月
        let controlData = await getControlMaster();
        this.controlMasterData.processMonthYear = controlData.process_month_year;
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 保存ボタンの押下 */
    async saveOrder() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      // 仕入データの登録(SQLの作成)
      if (await this.execSave() == true) {
        let reserveErrFlg = 0;
        let errCsvProductId = '';
        if (this.selectBillClass == Const.Tradition.storageAdd ||
        this.selectBillClass == Const.Tradition.storageMove) {
          // 伝票種別が「51:調整入庫」「53:移動」の場合は製品を未引当の受注に引当てる
          for(let i = 0; i < this.productList.length; i++) {
            // 在庫増加製品コードリスト追加
            this.addStockProductIdList(this.productList[i].ProductCode);
          }
          // ケース換算製品追加
          let csvStockProductIdList = await modStockProductIdListCase(this.stockProductId);
          // 伝票種別毎に引当を行う営業所を指定
          let officeId = 0;
          if (this.selectBillClass == Const.Tradition.storageAdd) {
            // 伝票種別が「51:調整入庫」の場合は営業所コードの在庫が増加
            officeId = this.selectSalesOffice;
          } else {
            // 伝票種別が「53:移動」の場合は移動先コードの在庫が増加
            officeId = this.destinationId;
          }
          // 在庫が増加した製品の引当
          if (csvStockProductIdList.length > 0) {
            errCsvProductId = await reserveStockProductIdList(officeId, csvStockProductIdList, this.controlMasterData.processMonthYear, this.loginId, MODULE_NAME);
            if (errCsvProductId != '') {
              reserveErrFlg = 1;
            }
          }
        }
        // 仕入修正画面へ遷移
        this.$router.push({ name: 'LOADING-UNLOADING-INQUIRY', query: { billingNo: this.billingNo, monthlyMonthYear: 0, parentKbn: 1, reserveErrFlg: reserveErrFlg, errCsvProductId: errCsvProductId } });
      }
      //console.log('保存処理終了');
    },
    /* 登録処理 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      // 仕入データの登録
      await this.insertCumulativeTransaction();
      // 在庫マスタ更新
      await this.execUpdateStocks();

      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }
      // 月次更新後チェック
      if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
        return false;
      }
      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }

      retResult = true;

      return retResult;
    },
    /* 累積トランザクション登録SQL作成 */
    async insertCumulativeTransaction() {
      //console.log('累積トランザクション登録SQL作成');
      // CRUD処理
      let bulkInsertSql = '';
      this.billingNo = await this.getBillingNo();
      for(let i = 0; i < this.productList.length; i++) {
        let colList = [];
        // ﾄﾗﾝｻﾞｸｼｮﾝID
        colList.push(CreateColRow('transaction_id', Const.TransactionId.storage, 'VARCHAR'));
        // 伝票No
        colList.push(CreateColRow('billing_no', this.billingNo, 'NUMBER'));
        // 行No
        colList.push(CreateColRow('billing_row', i + 1, 'NUMBER'));
        // 伝票日付
        colList.push(CreateColRow('billing_date', this.dateOrderReceiveDate, 'DATE'));
        // 伝票種別
        colList.push(CreateColRow('bill_class', this.selectBillClass, 'NUMBER'));
        // 解体組立区分
        colList.push(CreateColRow('dismantling_assembly_class', 0, 'NUMBER'));
        // 営業所コード
        colList.push(CreateColRow('office_id', this.selectSalesOffice, 'NUMBER'));
        // 移動先コード
        colList.push(CreateColRow('destination_id', this.destinationId, 'NUMBER'));
        // 取引先区分
        colList.push(CreateColRow('client_class', 0, 'NUMBER'));
        // 取引先コード
        colList.push(CreateColRow('client_id', 0, 'NUMBER'));
        // 現場コード
        colList.push(CreateColRow('site_id', 0, 'NUMBER'));
        // 伝票摘要
        colList.push(CreateColRow('billing_summary', await escapeQuote(this.billingSummary), 'VARCHAR'));
        // 担当者コード
        colList.push(CreateColRow('staff_id', 0, 'NUMBER'));
        // 取引先分類コード
        colList.push(CreateColRow('client_id_first_digit', 0, 'NUMBER'));
        // 相殺伝票No
        colList.push(CreateColRow('offset_billing_no', 0, 'NUMBER'));
        // 製品コード
        colList.push(CreateColRow('product_id', this.productList[i].ProductCode, 'NUMBER'));
        // 製品名
        colList.push(CreateColRow('product_name', await escapeQuote(this.productList[i].ProductName), 'VARCHAR'));
        // 製品数量
        colList.push(CreateColRow('product_quantity', this.productList[i].Quantity, 'NUMBER'));
        // 製品単位
        colList.push(CreateColRow('product_unit', this.productList[i].Unit, 'VARCHAR'));
        // 製品売上単価
        colList.push(CreateColRow('product_sales_unit_price', 0, 'NUMBER'));
        // 製品仕入単価
        colList.push(CreateColRow('product_purchase_price', 0, 'NUMBER'));
        // 製品売上金額
        colList.push(CreateColRow('product_sales_unit_amount', 0, 'NUMBER'));
        // 製品仕入金額
        colList.push(CreateColRow('product_purchase_amount', 0, 'NUMBER'));
        // 製品粗利額
        colList.push(CreateColRow('product_gross_profit', 0, 'NUMBER'));
        // 製品消費税
        colList.push(CreateColRow('product_tax', 0, 'NUMBER'));
        // 金額
        colList.push(CreateColRow('amount', 0, 'NUMBER'));
        // 製品分類コード
        colList.push(CreateColRow('product_class_id', this.productList[i].ProductClass, 'NUMBER'));
        // 製品諸口区分
        colList.push(CreateColRow('product_mouth_class', 0, 'NUMBER'));
        // 在庫管理区分
        colList.push(CreateColRow('inventory_control_class', 0, 'NUMBER'));
        // 売上課税区分
        colList.push(CreateColRow('sales_tax_class', 0, 'NUMBER'));
        // 税額計算区分
        colList.push(CreateColRow('tax_calculation_class', 0, 'NUMBER'));
        // 月次更新フラグ
        colList.push(CreateColRow('is_update_monthly', 0, 'NUMBER'));
        // 月次年月
        colList.push(CreateColRow('monthly_month_year', 0, 'NUMBER'));
        // 締日更新フラグ
        colList.push(CreateColRow('is_update_closing_date', 0, 'NUMBER'));
        // 締次年月
        colList.push(CreateColRow('closing_month_year', 0, 'NUMBER'));
        // 受注番号
        colList.push(CreateColRow('order_receive_id', 0, 'NUMBER'));
        // 受注行番号
        colList.push(CreateColRow('order_receive_row', 0, 'NUMBER'));
        // 発注番号
        colList.push(CreateColRow('order_id', 0, 'NUMBER'));
        // 発注行番号
        colList.push(CreateColRow('order_row', 0, 'NUMBER'));
        // 注文番号
        colList.push(CreateColRow('order_number', 0, 'NUMBER'));
        // 注文行番号
        colList.push(CreateColRow('order_number_row', 0, 'NUMBER'));
        // 組合CD発注取引先
        colList.push(CreateColRow('union_cd_order_client', 0, 'NUMBER'));
        // 仕入単価チェックフラグ
        colList.push(CreateColRow('is_check_purchase_price', 0, 'NUMBER'));
        // 作成ユーザー
        colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
        if (bulkInsertSql == '') {
          bulkInsertSql += 'INSERT INTO t_cumulative_transaction (' + CreateInsertSql(colList, 'col', 't_cumulative_transaction') + ') VALUES ';
        } else {
          bulkInsertSql += ',';
        }
        bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_cumulative_transaction') + ')';
        if (bulkInsertSql.length >= Const.SqlMaxLength) {
          this.transactSqlList.push(bulkInsertSql);
          bulkInsertSql = '';
        }
      }
      if (bulkInsertSql != '') {
        //console.log(bulkInsertSql);
        this.transactSqlList.push(bulkInsertSql);
      }
    },
    /* 伝票番号取得 */
    async getBillingNo() {
      const functionName = 'getBillingNo';
      let billingNo = await getFormCounter(Const.CounterClass.billingNo, this.selectSalesOffice, Const.TransactionId.storage, this.loginId, MODULE_NAME, functionName);
      if (billingNo != null) {
        return billingNo;
      } else {
        throw '伝票番号の取得に失敗しました。';
      }
    },
    /**
     * 在庫マスタ更新
     */
    async execUpdateStocks() {
      //console.log('在庫マスタ更新SQL作成');
      let colList = [];
      for(let i = 0; i < this.productList.length; i++) {
        colList = [];
        if (this.selectBillClass == Const.Tradition.storageAdd) {
          // 伝票種別が「51:調整入庫」
          // 残高数
          colList.push(CreateColRow('balance', 'balance + ' + this.productList[i].Quantity, 'NUMBER'));
          // 月次調整入庫数
          colList.push(CreateColRow('monthly_tuning_stock', 'monthly_tuning_stock + ' + this.productList[i].Quantity, 'NUMBER'));
        } else {
          // 上記以外（「52:調整出庫」「53:移動」）
          // 残高数
          colList.push(CreateColRow('balance', 'balance - ' + this.productList[i].Quantity, 'NUMBER'));
          // 月次調整出庫数
          colList.push(CreateColRow('monthly_tuning_ship', 'monthly_tuning_ship + ' + this.productList[i].Quantity, 'NUMBER'));
        }
        // 更新日
        colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
        /* WHERE句 */
        let where_clause = ' WHERE ';
        where_clause += ' month_year = ' + this.controlMasterData.processMonthYear + ' ';
        where_clause += 'AND office_id = ' + this.selectSalesOffice + ' ';
        where_clause += 'AND product_id = ' + this.productList[i].ProductCode + ' ';
        let updateSql = CreateUpdateSql(colList, 'm_stocks') + where_clause;
        //console.log(updateSql);
        this.transactSqlList.push(updateSql);
      }
      // 「53:移動」の場合は逆向きの営業所に在庫を追加
      if (this.selectBillClass == Const.Tradition.storageMove) {
        for(let i = 0; i < this.productList.length; i++) {
          colList = [];
          // 残高数
          colList.push(CreateColRow('balance', 'balance + ' + this.productList[i].Quantity, 'NUMBER'));
          // 月次調整入庫数
          colList.push(CreateColRow('monthly_tuning_stock', 'monthly_tuning_stock + ' + this.productList[i].Quantity, 'NUMBER'));
          // 更新日
          colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
          /* WHERE句 */
          let where_clause = ' WHERE ';
          where_clause += ' month_year = ' + this.controlMasterData.processMonthYear + ' ';
          where_clause += 'AND office_id = ' + this.destinationId + ' ';
          where_clause += 'AND product_id = ' + this.productList[i].ProductCode + ' ';
          let updateSql = CreateUpdateSql(colList, 'm_stocks') + where_clause;
          //console.log(updateSql);
          this.transactSqlList.push(updateSql);
        }
      }
    },
    // キャンセルボタン
    cancelPurchaseInput: function() {
      this.$router.push({ name: 'LOADING-UNLOADING-LIST'});
    },
    // 製品コード直接入力 - 製品検索
    async searchProduct(product_id, index) {
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.selectSalesOffice);
      this.productSearchProp.client_id = null;
      this.productSearchProp.client_class = null;
      this.productSearchProp.client_name = null;
      const result = await searchProductsModal(product_id, this.productSearchProp, 'searchProduct', MODULE_NAME)
      this.dataIndex = index;
      if (typeof result != 'undefined') {
        this.closeProductSearchModal(result)
      } else {
        // 製品コード8桁かつデータ取得失敗の場合
        if (product_id.length == 8) {
          // 製品コードクリア
          this.productList[index].ProductCode = ''
        }
      }
    },
    // 製品検索
    showProductSearchModal: function(index) {
      //console.log('製品検索モーダル');
      //console.log(index);
      this.dataIndex = index;
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.selectSalesOffice);
      this.productSearchProp.client_id = null;
      this.productSearchProp.client_class = null;
      this.productSearchProp.client_name = null;
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    async closeProductSearchModal(productItems) {
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if (typeof productItems != 'undefined') {
        this.$store.commit('setLoading', true);
        let focusFlg = false;
        if (productItems.length == undefined) {
          // 選択ボタン押下でクローズ
          this.setProductSearchModal(this.dataIndex, productItems.detail);
          focusFlg = true;
        } else {
          // 一括選択ボタン押下でクローズ
          if (productItems.length == 1) {
            // 1件の場合のみフォーカスを数量に当てる
            focusFlg = true;
          }
          for (let i = 0; i < productItems.length; i++) {
            if (i == 0) {
              this.setProductSearchModal(this.dataIndex, productItems[i].detail);
            } else {
              this.productList.splice(this.dataIndex + i, 0, this.createNewRow());
              this.setProductSearchModal(this.dataIndex + i, productItems[i].detail);
            }
          }
        }
        // 最終行が空行でない場合は空行追加
        if (this.isListBlankRow(this.productList.length - 1) == false) {
          this.addTableRow();
        }
        // 現在庫数設定
        await this.setStocksBalance();
        if (focusFlg == true) {
          // 製品検索を行った行の数量にフォーカスを移動
          setTimeout(() => {
            if (this.dataIndex < this.productList.length) {
              this.setFocus('productQuantity' + this.dataIndex);
            }
          }, 500);
        }
        this.$store.commit('setLoading', false);
      }
    },
    // 製品検索モーダルの返却値設定
    async setProductSearchModal(index, productItemsDetail) {
      // 製品コード
      this.productList[index].ProductCode = productItemsDetail.product_id;
      // 製品分類コード
      this.productList[index].ProductClass = productItemsDetail.product_class_id;
      // 製品名（漢字）
      this.productList[index].ProductName = productItemsDetail.product_name_kanji;
      // 数量
      this.productList[index].Quantity = 1;
      // 単位
      this.productList[index].Unit = productItemsDetail.unit;
      // 諸口区分
      this.productList[index].SundriesClass = productItemsDetail.sundries_class;
      // 在庫管理区分
      this.productList[index].InventoryClass = productItemsDetail.inventory_control_class;
      // ケース換算区分
      this.productList[index].CaseConversion = productItemsDetail.case_conversion_class;
    },
    // 現在庫数設定
    async setStocksBalance() {
      //console.log('現在在庫数設定');
      // 指定された営業所と現在処理年月の在庫マスタを確認
      let where_clause = 'AND month_year = ' + this.controlMasterData.processMonthYear + ' ';
      where_clause += 'AND office_id = ' + this.selectSalesOffice + ' ';
      let csvProductId = '';
      for (let i = 0; i < this.productList.length; i++) {
        if (csvProductId != '') {
          csvProductId += ',';
        }
        csvProductId += this.productList[i].ProductCode;
      }
      where_clause += 'AND product_id IN (' + csvProductId + ') ';
      where_clause += 'ORDER BY product_id ';
      let stocksResultData = await selectOneTable('m_stocks', where_clause);
      //console.log(stocksResultData);
      if (stocksResultData != null && stocksResultData.length > 0) {
        // 製品一覧に設定
        for (let i = 0; i < this.productList.length; i++) {
          for (let j = 0; j < stocksResultData.length; j++) {
            // 製品コードが等しい場合
            if (this.productList[i].ProductCode == stocksResultData[j].product_id) {
              // 現在庫数を設定
              this.productList[i].StockQuantity = stocksResultData[j].balance + stocksResultData[j].inventory_reserve_count;
              // 残高数を設定
              this.productList[i].Balance = stocksResultData[j].balance;
              break;
            }
          }
        }
      }
    },
    // 合計在庫数取得
    getTotalBalance: function(quantity, balance) {
      if (this.selectBillClass == Const.Tradition.storageAdd) {
        // 伝票種別が「51:調整入庫」
        // 数量をプラス
        return Number(balance) + Number(quantity);
      } else {
        // 上記以外（「52:調整出庫」「53:移動」）
        // 数量をマイナス
        return Number(balance) - Number(quantity);
      }
    },
    /* 伝票種別イベント */
    async changeDestination() {
      // 伝票種別が「53：移動」以外の場合
      if (this.selectBillClass != Const.Tradition.storageMove) {
        // 移動先をクリア
        this.destinationId = 0;
      }
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = this.createNewRow();
      this.productList.push(newProduct);
    },
    // 行作成
    createNewRow: function() {
      let newProduct = {
        ProductCode: null,
        ProductClass: null,
        ProductName: '',
        Quantity: 0,
        Unit: '',
        StockQuantity: 0,
        Balance: 0,
        SundriesClass: null,
        InventoryClass: null,
        CaseConversion: '',
      };
      return newProduct;
    },
    // テーブル行削除イベント
    deleteBtn: function(rowNum) {
      //console.log(rowNum);
      if (this.productList.length > 1) {
        this.productList.splice(rowNum,1);
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* 在庫増加製品コードリスト追加 */
    addStockProductIdList: function(productId) {
      if (this.stockProductId.length == 0) {
        this.stockProductId.push(productId);
      } else {
        // 重複しないように製品コードをリストに追加
        for (let j = 0; j < this.stockProductId.length; j++) {
          if (this.stockProductId[j] == productId) {
            break;
          }
          if (j == this.stockProductId.length - 1) {
            // 在庫マスタの増加があった製品はリストに保持（後で未引当の受注への引当に使用）
            this.stockProductId.push(productId);
          }
        }
      }
    },
    // 一覧の指定行が空かどうかチェック
    isListBlankRow: function(index) {
      if (getNullStr(this.productList[index].ProductCode) == '') {
        return true;
      } else {
        return false;
      }
    },
    // 一覧の後ろの空行を削除
    delListLastBlankRow: function() {
      //console.log('空行削除開始');
      for (let i = this.productList.length - 1; i > 0; i--) {
        if (this.isListBlankRow(i) == true) {
          // 後ろにある空行は削除
          this.productList.splice(i, 1);
        } else {
          // 1件でも空行以外の行があった場合は削除処理終了
          break;
        }
      }
      //console.log('空行削除終了');
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
  }
}
</script>
<style scoped>
</style>