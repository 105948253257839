<template>
  <!-- 現場保守一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row">
        <div class="col-lg-12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <div class="card border">
              <div class="card-header mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip">
                  <a v-b-toggle.collapse-1 title="クリックすると検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                    <b-button size="sm" v-b-tooltip.hover title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                      <span class="oi oi-print"></span>
                    </b-button>
                  <router-link to="/site-input" class="btn btn-sm btn-primary mx-2" style="font-size:60%;float:right;" title="現場データの新規登録を行います。">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </router-link>
                </h5>
              </div>
              <b-collapse id="collapse-1" :visible="true">
                <div class="card-body p-2">
                  <div class="container-fluid">
                    <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                      <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="warning" class="mt-2" v-if="errorMessages.length">
                      <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                        <li>{{ message }}</li>
                      </ul>
                    </b-alert>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <div class="row mt-2">
                        <!-- 取引先コード入力欄 -->
                        <div class="col-md-3 form-group">
                          <label for="clientId" class="form-label">取引先コード</label>
                          <validation-provider name="clientId" rules="numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="clientId" name="clientId" class="form-control" v-model="searchConditions.clientId" maxlength="6">
                              <small class="form-text text-muted">完全一致検索です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 取引先名入力欄 -->
                        <div class="col-md-9 form-group">
                          <label for="clientName" class="form-label">取引先名</label>
                          <validation-provider rules="max:40" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="clientName" name="clientName" class="form-control" v-model="searchConditions.clientName" maxlength="40">
                              <small class="form-text text-muted">部分一致検索です。登録済みの取引先名（漢字）または取引先名（カナ）と一致するデータを検索します。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <!-- 現場コード -->
                        <div class="col-md-3 form-group">
                          <label for="siteId" class="form-label">現場コード</label>
                          <validation-provider rules="numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="siteId" name="siteId" class="form-control" v-model="searchConditions.siteId" maxlength="6">
                              <small class="form-text text-muted">完全一致検索です。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                        <!-- 現場名入力欄 -->
                        <div class="col-md-9 form-group ">
                          <label for="siteName" class="form-label">現場名</label>
                          <validation-provider rules="max:50" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <input type="text" id="siteName" name="siteName" class="form-control" v-model="searchConditions.siteName" maxlength="50">
                              <small class="form-text text-muted">部分一致検索です。登録済みの現場名（漢字）または現場名（カナ）と一致するデータを検索します。</small>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 form-group px-1">
                          <b-input-group>
                            <template #prepend>
                              <label for="selectTradition">
                                <b-input-group-text  class="px-1">
                                  表示範囲
                                </b-input-group-text>
                              </label>
                            </template>
                            <b-form-select
                              id="selectTradition"
                              v-model="searchLimit"
                              :options="searchLimitList"
                            >
                            </b-form-select>
                          </b-input-group>
                        </div>
                      </div>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <div class="row justify-content-md-center my-2">
                      <div class="col-lg-2">
                        <button type="button" class="btn btn-primary btn-block" @click="searchButton()"><span class="oi oi-magnifying-glass"></span> 検索</button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
      <!-- ●●●検索結果●●● -->
      <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <div class="row">
          <div class="col-sm-12">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="4"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-col cols="12" class="px-0">
                <b-table
                  show-empty
                  :head-variant="headVariant"
                  :responsive="true"
                  :items="clientsSitesList"
                  :fields="fields"
                  :busy="busy"
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :bordered="true"
                  :empty-text="emptyText"
                  :empty-filtered-text="emptyFilterdText"
                  @filtered="onFiltered"
                >
                  <!-- テーブル読み込み時表示html -->
                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>読み込んでいます...</strong>
                    </div>
                  </template>
                  <!-- 操作列 -->
                  <template #cell(operation)="operation">
                    <div v-show="!printStatus">
                      <!-- 照会ボタン -->
                      <!-- <b-button size="sm" v-b-tooltip.hover title="現場保守内容の照会を行います。" @click="clickDetailBtn(operation.item)" class="mr-1"> -->
                      <b-button size="sm" v-b-tooltip.hover @click="clickDetailBtn(operation.item)" class="mr-1">
                        <span class="oi oi-list"></span> 照会
                      </b-button>&nbsp;
                      <!-- 編集ボタン -->
                      <!-- <b-button size="sm" v-b-tooltip.hover title="現場保守内容の編集を行います。" @click="clickEditBtn(operation.item)" class="mr-1"> -->
                      <b-button size="sm" v-b-tooltip.hover @click="clickEditBtn(operation.item)" class="mr-1">
                        <span class="oi oi-pencil"></span> 編集
                      </b-button>&nbsp;
                      <!-- 削除ボタン -->
                      <!-- <b-button size="sm" v-b-tooltip.hover title="現場保守内容の削除を行います。" @click="clickDeleteBtn(operation.item)" class="mr-1"> -->
                      <b-button size="sm" v-b-tooltip.hover @click="clickDeleteBtn(operation.item)" class="mr-1">
                        <span class="oi oi-delete"></span> 削除
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <!-- 表示レコードをラベル表示 -->
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : clientsSitesList.length"
                :per-page="perPage == -1 ? clientsSitesList.length : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, windowPrint, convertSqlLikeSpecialChar, init, executeTransactSqlList, isSystemEditable, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'site-maintenance';

export default {
  name: 'SITE-MAINTENANCE',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '現場保守',
      // 画面メッセージ
      successMessages: [],
      errorMessages: [],
      // 検索条件
      searchConditions: {
        clientId: '',
        clientName: '',
        siteId: '',
        siteName: ''
      },
      // 検索結果
      resultError: [],
      clientsSitesList: [],
      fields: DataTblDef.clients_sites_list_fields,
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // フィルター検索の結果が0件の場合の表示メッセージ
      emptyFilterdText:DataTblDef.emptyFilteredText,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // プリントアウト状態
      printStatus: false,
      // 検索上限値
      searchLimitList: [],
      searchLimit: 0,
      limitCnt: 1000,
      limitMax: 10000,
    };
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.clientsSitesList.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    this.searchLimitList = this.createSearchLimitList();
    this.$store.commit('setLoading', false);
    this.successMessages = this.$route.params.successMessages || []
  },
  /* 関数群 */
  methods: {
    shortkey() {
      this.$router.push({ name: 'SITE-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * 検索処理
     * @param {String} listMClientsConditions - 取引先マスタ検索条件
     * @param {String} listMClientsSitesConditions - 現場マスタ検索条件
     */
    async search(listMClientsConditions, listMClientsSitesConditions) {
      this.clientsSitesList = [];
      let additionalListMClientsSitesConditions = null;

      // 取引先マスタの曖昧検索を行います。
      let listMClients = null;
      if (listMClientsConditions !== null && listMClientsConditions.length > 0) {
        listMClients = await this.searchClients(listMClientsConditions + 'AND client_class = 1 ORDER BY client_id ASC');
        if (listMClients === null) {
          this.resultError.push(DISP_MESSAGES.WARNING['2001']);
          return;
        } else if (listMClients.length === 0) {
          this.clientsSitesList = [];
          return;
        }
        const clientIdList = [];
        for (const mClients of listMClients) {
          clientIdList.push(mClients.client_id);
        }
        // 現場マスタの追加検索条件を作成します。
        additionalListMClientsSitesConditions = `AND client_id in (${clientIdList.join(',')}) `;
      }

      // 現場マスタの検索を行います。
      let searchClientsSitesWhereClause = '';
      if (listMClientsSitesConditions !== null) {
        searchClientsSitesWhereClause += listMClientsSitesConditions;
      }
      if (additionalListMClientsSitesConditions !== null) {
        searchClientsSitesWhereClause += additionalListMClientsSitesConditions;
      }
      searchClientsSitesWhereClause += 'ORDER BY client_id ASC, site_id ASC ';
      searchClientsSitesWhereClause += 'LIMIT ' + this.limitCnt + ' OFFSET ' + (this.searchLimit * this.limitCnt).toString() + ' ';

      const listMClientsSites = await this.searchClientsSites(searchClientsSitesWhereClause);
      if (listMClientsSites === null) {
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        return;
      } else if (listMClientsSites.length === 0) {
        this.clientsSitesList = [];
        return;
      }

      // 取引先マスタの曖昧検索を行っていない場合、取引先名を取得するために取引先マスタの検索を行ます。
      if (listMClients === null) {
        const clientIdList = [];
        for (const clientsSites of listMClientsSites) {
          if (!clientIdList.includes(clientsSites.client_id)) {
            clientIdList.push(clientsSites.client_id);
          }
        }
        listMClients = await this.searchClients(`AND client_id in (${clientIdList.join(',')}) AND client_class = 1 ORDER BY client_id ASC`);
        if (listMClients === null) {
          this.resultError.push(DISP_MESSAGES.WARNING['2001']);
          return;
        }
      }

      // 検索結果を表示用リストに詰め替えます。
      for (const clientsSites of listMClientsSites) {
        const client = listMClients.find(mClient => mClient.client_id === clientsSites.client_id);
        this.clientsSitesList.push({
          clientId: clientsSites.client_id, // 取引先コード
          clientName: client ? client.client_name_kanji : '取引先マスタに該当なし', // 取引先名
          siteId: clientsSites.site_id, // 現場コード
          clientSiteNameKanji: clientsSites.client_site_name_kanji // 現場名（漢字）
        });
      }

      // 検索結果が1000件の場合は1000件を超えている場合があるのでメッセージを表示します。
      if (this.clientsSitesList.length === this.limitCnt) {
        this.resultError.push(DISP_MESSAGES.WARNING['2009'].replace('%arg1%',this.limitCnt).replace('%arg2%','検索結果'));
      }
    },
    /**
     * 取引先マスタ検索
     * @param {String} whereClause - 検索条件
     * @returns {Array<Object>} 検索結果
     */
    async searchClients(whereClause) {
      const functionName = 'searchClients';

      let result = null;
      try {
        result = await selectOneTable('m_clients', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients',
          whereClause: whereClause
        }, error);
        return null;
      }
      return result;
    },
    /**
     * 現場マスタ検索
     * @param {Object} whereClause - 検索条件
     * @returns {Array<Object>} 検索結果
     */
    async searchClientsSites(whereClause) {
      const functionName = 'searchClientsSites';

      let result = null;
      try {
        result = await selectOneTable('m_clients_sites', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients_sites',
          whereClause: whereClause
        }, error);
        return null;
      }
      return result;
    },
    /**
     * 検索ボタン押下時
     */
    async searchButton() {
      this.busy = true;
      this.resultError = [];

      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const isValid = await this.$refs.observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (isValid) {
        // 取引先コード
        let listMClientConditions = '';
        if (this.searchConditions.clientId !== '') {
          listMClientConditions += `AND client_id = ${this.searchConditions.clientId} `;
        }
        // 取引先名(あいまい検索対象)
        if (this.searchConditions.clientName !== '') {
          const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
          listMClientConditions += `AND (REPLACE(REPLACE(CONVERT(client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
          listMClientConditions += `OR REPLACE(REPLACE(CONVERT(client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '')) `;
        }

        let listMClientsSitesConditions = '';
        // 現場コード
        if (this.searchConditions.siteId !== '') {
          listMClientsSitesConditions += `AND site_id = ${this.searchConditions.siteId} `;
        }
        // 現場名（あいまい検索対象）
        if (this.searchConditions.siteName !== '') {
          const siteName = convertSqlLikeSpecialChar(this.searchConditions.siteName);
          listMClientsSitesConditions += `AND (REPLACE(REPLACE(CONVERT(client_site_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${siteName}%', 'ﾞ', ''), 'ﾟ', '') `;
          listMClientsSitesConditions += `OR REPLACE(REPLACE(CONVERT(client_site_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${siteName}%', 'ﾞ', ''), 'ﾟ', '')) `;
        }

        await this.search(listMClientConditions, listMClientsSitesConditions);
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });
      }
      this.busy = false;
    },
    /**
     * 照会ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickDetailBtn(item) {
      let route = this.$router.resolve({ name: 'SITE-INQUIRY', query: { clientId: item.clientId, siteId: item.siteId }});
      window.open(route.href, '_blank');
    },
    /**
     * 編集ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickEditBtn(item) {
      let route = this.$router.resolve({ name: 'SITE-EDIT', query: { clientId: item.clientId, siteId: item.siteId } });
      window.open(route.href, '_blank');
    },
    /**
     * 削除ボタン押下時
     * @param {Object} item - 選択された項目
     */
    clickDeleteBtn(item) {
      const functionName = 'clickDeleteBtn';

      this.$bvModal.msgBoxConfirm('選択された現場データを削除します。\nよろしいですか？').then(async value => {
        if (value) {
          this.successMessages = [];
          this.errorMessages = [];

          let deleteSql = this.makeDeleteSql(item);
          // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
          try {
            const msg = await isSystemEditable();
            if (msg !== null) {
              this.errorMessages.push(msg);
              return;
            }
          } catch (error) {
            await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
            this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
            return;
          }

          let sqlList = [];
          sqlList.push(deleteSql);
          let result = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
          let logLevel = 'Info';
          if (result == false) {
            logLevel = 'Error';
            this.errorMessages.push(DISP_MESSAGES.DANGER['3002']);
          } else {
            this.successMessages.push(DISP_MESSAGES.SUCCESS['1002']);
          }
          await addOperationLogs(logLevel, MODULE_NAME, functionName, {
            graphqlOperation: 'delete_m_clients_sites',
            variables: {
              client_id: item.clientId,
              site_id: item.siteId
            },
            result: result
          });
          await this.searchButton();
          scrollTo(0,0);
        }
      });
    },
    /**
     * 削除SQL文作成
     * @param {Object} item - 選択された項目
     */
    makeDeleteSql: function(item) {
      let deleteSql = 'DELETE FROM ';
      // 見積データの削除SQL
      deleteSql += 'm_clients_sites';
      deleteSql += ' WHERE ';
      // 見積番号
      deleteSql += 'client_id = ' + item.clientId + ' ';
      deleteSql += 'AND site_id = ' + item.siteId + ' ';
      //console.log(deleteSql);

      return deleteSql;
    },
    // 検索条件の表示開始～上限リスト作成
    createSearchLimitList: function() {
      let retList = [];
      for (let i = 0; i < 20 && i * this.limitCnt < this.limitMax; i++) {
        let rowLimit = {
          value: i,
          text: (i * this.limitCnt + 1).toString() + '～' + ((i + 1) * this.limitCnt).toString(),
        };
        retList.push(rowLimit);
      }
      return retList;
    },
  }
};
</script>