<template>
<div>
  <!-- ●●●上部メニュー●●● -->
  <Header :type="menu_type" :title="title" :key="resetKey" />
  <b-container class="px-4 py-4 min-vh-85" fluid>
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-media>
          <b-media-body class="pb-3">
            <div class="d-flex justify-content-between">
              <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 支払範囲指定</strong></h5>
            </div>
          </b-media-body>
        </b-media>
        <b-card>
          <b-card-header v-if="getMessageFlg==true">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
            <validation-observer ref="observer">
              <b-form @submit.prevent="clearAlert(); saveData();" id="InputForm" class="form-horizontal">
                <!-- ●●●入力欄●●● -->
                <b-container>
                  <b-row>
                    <!-- 締日プルダウン -->
                    <b-col cols="6">
                      <validation-provider name="closingDate" rules="required" v-slot="{ classes,errors }">
                        <b-form-group
                          label="締日"
                          label-for="closingDate"
                        >
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-form-select
                                id="closingDate"
                                name="closingDate"
                                v-model="closingDate"
                                :options="closingDates"
                                @change="calcDate"
                              >
                              </b-form-select>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                          <b-form-text class="text-muted">締日と締切日から開始日と終了日を計算します。</b-form-text>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- 締切年月 -->
                    <b-col cols="6">
                      <validation-provider name="closingYm" :rules="{required: true, regex: /^[0-9]{4}-(0[1-9]|1[0-2])$/, oldProcessMonthYearYm: controlMasterData.processMonthYear}" v-slot="{ classes,errors }">
                        <b-form-group
                          label="締切年月"
                          label-for="closingYm"
                        >
                          <b-row>
                            <b-col lg="8" :class="classes">
                              <b-form-input id="closingYm" name="closingYm" type="month" v-model="closingYm" @change="calcDate" />
                            </b-col>
                            <b-col lg="8" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 開始日 -->
                    <b-col lg="6">
                      <b-form-group
                        label="開始日"
                        label-for="paymentStartDate"
                      >
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="paymentStartDate" v-model="paymentStartDate" readonly />
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                    <!-- 締切日 -->
                    <b-col lg="6">
                      <b-form-group
                        label="締切日"
                        label-for="paymentEndDate"
                      >
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="paymentEndDate" v-model="paymentEndDate" readonly />
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form>
            </validation-observer>
          </b-card-body>
          <!-- 保存ボタン -->
          <b-card-footer>
            <b-row class="justify-content-md-center pb-4">
              <b-col lg="3">
                <b-form style="text-align: center;">
                  <b-button class="mr-2" pill variant="success" title="入力された支払マスタデータを保存します。" type="submit" form="InputForm">
                    <span class="oi oi-circle-check"></span> 保存
                  </b-button>
                </b-form>
              </b-col>
              <b-col lg="5">
                <b-form style="text-align: center;">
                  <b-button class="mr-2" pill :variant="this.controlMasterData.closingUpdateState == htmlConst.ClosingUpdateStateClass.RUNNING ? 'danger' : 'success'" :title="closingUpdateButtonTitle" @click="clearAlert(); changeClosingUpdateState();">
                    <span class="oi oi-circle-check"></span> {{closingUpdateButtonName}}
                  </b-button>
                  <label style="color: red;">締更新実施開始は繰越処理において使用するボタンです。それ以外の用途では使用しないで下さい。誤って開始させた場合は締更新実施終了を押下して下さい。</label>
                </b-form>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <Footer />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
</div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import moment from 'moment';
import { init, formatDate, addOperationLogs, getControlMaster, CreateInsertSql, CreateUpdateSql, CreateColRow, executeTransactSqlList, isSystemEditable, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'pre-payment-process';

export default {
  name: 'PRE-PAYMENT-PROCESS',
  /* コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /* データ */
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '支払前処理',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 締日
      closingDate: '',
      // 締日選択肢
      closingDates: Const.closingDateList,
      // 締切年月
      closingYm: '',
      // 開始日
      paymentStartDate: '',
      // 締切日
      paymentEndDate: '',
      // モーダルからの戻り値の反映先
      openModalIndex: 0,
      // トランザクションSQLリスト
      transactSqlList: [],
      // コントロールマスタの現在処理年月、締更新状態
      controlMasterData: {
        processMonthYear: 0,
        closingUpdateState: null,
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 締更新状態状態区分
        ClosingUpdateStateClass: {
          NOT_RUNNING: Const.ClosingUpdateStateClass.NOT_RUNNING, // 停止中
          RUNNING: Const.ClosingUpdateStateClass.RUNNING, // 実行中
        },
      },
      // 確認ダイアログ用
      confirmMessage: [],
      // ヘッダー更新キー
      resetKey: 0,
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    // 締更新ボタン名
    closingUpdateButtonName() {
      if (this.controlMasterData.closingUpdateState == Const.ClosingUpdateStateClass.RUNNING) {
        return '締更新実施終了';
      } else {
        return '締更新実施開始';
      }
    },
    // 締更新ボタン吹き出し
    closingUpdateButtonTitle() {
      if (this.controlMasterData.closingUpdateState == Const.ClosingUpdateStateClass.RUNNING) {
        return '請求/支払締更新に関係のない画面の登録・更新・削除の制限を解除します。';
      } else {
        return '請求/支払締更新に関係のない画面の登録・更新・削除を制限します。';
      }
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // コントロールマスタ取得
        let controlData = await getControlMaster();
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.closingUpdateState = controlData.closing_update_state;
        // 初期データを設定
        await this.setInitData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      const functionName = 'setInitData';
      // 検索条件作成
      let where_clause = '';
      where_clause = await this.conditionMake();
      //console.log(where_clause);
      let dataResult = null;
      try {
        dataResult = await selectOneTable('t_closing_update_range', where_clause);
      } catch (error) {
        let param = {
          graphqlOperation: 'list_t_closing_update_range',
          where_clause: where_clause
        };
        await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      //console.log(dataResult);
      if (dataResult != null) {
        await this.setResult(dataResult);
      }
    },
    /* 検索条件文字列作成 */
    async conditionMake() {
      let where_clause = '';
      // 支払のレコードを取得
      where_clause += 'AND billing_payment_class = ' + Const.BillingPaymentClass.payment + ' ';

      return where_clause;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        // 締日
        this.closingDate = result[0].closing_date.toString();
        // 締切年月
        this.closingYm = formatDate(('000000' + result[0].process_month_year).slice(-6) + '01', 'YYYY-MM');
        // 開始日と締切日を計算
        this.calcDate();
      }
    },
    /* 開始日、締切日を計算 */
    calcDate: function() {
      // 開始日、締切日を計算
      const format = 'YYYY/MM/DD';
      if (this.closingDate == '' || this.closingYm == '') {
        // 計算に必要な値が未入力の場合
        this.paymentStartDate = '';
        this.paymentEndDate = '';
      } else if (this.closingDate === '99') {
        // 末日の場合
        // 開始日：決算年月の月初日、締切日：決算年月の月末日
        this.paymentStartDate = moment(this.closingYm + '-01').format(format);
        this.paymentEndDate = moment(this.closingYm + '-01').endOf('month').format(format);
      } else {
        // 末日でない場合
        // 開始日：決算年月の先月の締日の翌日、締切日：決算年月の締日
        this.paymentStartDate = moment(this.closingYm + '-' + ('00' + this.closingDate).slice(-2)).add(-1,'months').add(1,'days').format(format);
        this.paymentEndDate = moment(this.closingYm + '-' + ('00' + this.closingDate).slice(-2)).format(format);
      }
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      if (await this.execSave() == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
      }
      //console.log('保存処理終了');
    },
    /* 登録処理 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      // 支払マスタ更新SQL作成
      await this.updatePaymentsSql();
      // 締更新範囲更新SQL作成
      this.updateClosingUpdateRange();

      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable(1);
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return false;
      }
      retResult = true;

      return retResult;
    },
    /* 支払マスタ更新SQL作成処理 */
    async updatePaymentsSql() {
      //console.log('支払マスタ更新SQL作成処理');
      // CRUD処理
      // 最初にdelete
      this.transactSqlList.push('DELETE FROM m_payments');
      // 登録SQLを作成（INSERT文の前部分のため設定値不要）
      let insertSql = '';
      let colList = [];
      // 仕入先コード
      colList.push(CreateColRow('client_id', null, 'NUMBER'));
      // 営業所コード
      colList.push(CreateColRow('office_id', null, 'NUMBER'));
      // 締日
      colList.push(CreateColRow('closing_date', null, 'NUMBER'));
      // 支払開始日
      colList.push(CreateColRow('payment_start_date', null, 'DATE'));
      // 支払締切日
      colList.push(CreateColRow('payment_end_date', null, 'DATE'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      insertSql += 'INSERT INTO m_payments (' + CreateInsertSql(colList, 'col', 'm_payments') + ') ';
      // 取引先
      let selectSql = '';
      selectSql += 'SELECT';
      selectSql += ' clients.client_id';
      selectSql += ',0';
      selectSql += ',' + this.closingDate + ' ';
      selectSql += ',\'' + this.paymentStartDate + '\'';
      selectSql += ',\'' + this.paymentEndDate + '\'';
      selectSql += ',\'' + this.loginId + '\'';
      selectSql += ',\'' + this.loginId + '\'';
      selectSql += ' FROM m_clients AS clients ';
      selectSql += ' WHERE ';
      selectSql += 'clients.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql += 'AND clients.closing_date=' + this.closingDate + ' ';

      insertSql += selectSql;

      //console.log(insertSql)
      this.transactSqlList.push(insertSql);
    },
    // 締更新範囲更新SQL作成
    async updateClosingUpdateRange() {
      //console.log('締更新範囲更新SQL作成');
      // CRUD処理
      // 最初にdelete
      this.transactSqlList.push('DELETE FROM t_closing_update_range WHERE billing_payment_class = ' + Const.BillingPaymentClass.payment);
      // 登録SQLを作成
      let colList = [];
      // 請求支払区分
      colList.push(CreateColRow('billing_payment_class', Const.BillingPaymentClass.payment, 'NUMBER'));
      // 処理年月
      colList.push(CreateColRow('process_month_year', this.closingYm.replace('-', ''), 'NUMBER'));
      // 締日
      colList.push(CreateColRow('closing_date', this.closingDate, 'NUMBER'));
      // 取引先コード開始
      colList.push(CreateColRow('client_id_start', 0, 'NUMBER'));
      // 取引先コード終了
      colList.push(CreateColRow('client_id_end', 999999, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      let insertSql = CreateInsertSql(colList, 'full', 't_closing_update_range');
      //console.log(insertSql)
      this.transactSqlList.push(insertSql);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // 締更新状態の変更
    async changeClosingUpdateState() {
      const functionName = 'changeClosingUpdateState';
      try {
        // 現在処理年月取得
        let controlData = await getControlMaster();
        let closingUpdateState = controlData.closing_update_state;
        if (this.controlMasterData.closingUpdateState == closingUpdateState) {
          let confirmMessage = '';
          let title = '';
          let changeClosingUpdateState = null;
          // コントロールマスタの締更新状態を変更
          if (closingUpdateState == Const.ClosingUpdateStateClass.RUNNING) {
            confirmMessage = '請求/支払締更新に関係のない画面の登録・更新・削除の制限を解除します。';
            confirmMessage += 'よろしいですか？';
            title = '締更新終了';
            changeClosingUpdateState = Const.ClosingUpdateStateClass.NOT_RUNNING;
          } else {
            confirmMessage = '請求/支払締更新に関係のない画面の登録・更新・削除を制限します。';
            confirmMessage += 'よろしいですか？';
            title = '締更新開始';
            changeClosingUpdateState = Const.ClosingUpdateStateClass.RUNNING;
          }
          if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: title}) == true) {
            this.$store.commit('setLoading', true);
            await this.execClosingUpdateStateChange(changeClosingUpdateState);
          }
        } else {
          this.controlMasterData.closingUpdateState = closingUpdateState;
        }
        // ヘッダー部分を再描画
        if (this.resetKey != 0) {
          this.resetKey = 0;
        } else {
          this.resetKey++;
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 締更新状態変更処理実行
    async execClosingUpdateStateChange(closingUpdateState) {
      const functionName = 'execClosingUpdateStateChange';
      this.transactSqlList = [];
      // コントロールマスタ更新SQL作成
      await this.updateControlMaster(closingUpdateState);
      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable(1);
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      // 作成した登録用SQLを全実行
      let retResult = await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.controlMasterData.closingUpdateState = closingUpdateState;
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
      }
    },
    // コントロールマスタ更新SQL作成
    async updateControlMaster(closingUpdateState) {
      //console.log('コントロールマスタ更新SQL作成');
      let colList = [];
      // 締更新状態
      colList.push(CreateColRow('closing_update_state', closingUpdateState, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      this.transactSqlList.push(CreateUpdateSql(colList, 'm_control'));
    },
  }
}
</script>
<!-- ●●●画面固有●●● -->
<style scoped>
</style>