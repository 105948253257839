<template>
  <div>
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 pt-4 min-vh-85">
      <b-row>
        <b-col>
          <b-media>
            <b-media-body class="pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 棚卸範囲指定</strong></h5>
              </div>
            </b-media-body>
          </b-media>
          <b-card>
            <b-card-header v-if="isAlertMessages">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccessMessages.length > 0">
                <ul v-for="(message,index) in alertSuccessMessages" :key="index" style="list-style: none;">
                  <li>{{message}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDangerMessages.length > 0">
                <ul v-for="(message,index) in alertDangerMessages" :key="index" style="list-style: none;">
                  <li>{{message}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body class="pb-0">
              <b-row>
                <b-col>
                  <validation-observer ref="observer">
                    <b-row>
                      <b-col md="6">
                        <b-form-group description="対象の営業所を選択してください。" label="営業所" label-cols-sm="3" label-cols-xl="2" label-size="sm" size="sm">
                          <b-form-select v-model="officeId" :options="officeList" size="sm"></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group description="ハイフン区切りで入力してください。ブランクにすると全置き場指定となります。" label="置き場所" label-cols-sm="3" label-cols-xl="2" label-size="sm" size="sm">
                          <validation-provider rules="max:11|place" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <b-form-input maxlength="11" size="sm" v-model="place"></b-form-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </b-col>
              </b-row>
              <b-row class="justify-content-sm-center">
                <b-col class="text-center" sm="4" lg="3" xl="2">
                  <b-button block pill title="指定された営業所の棚卸前処理を行います。" variant="success" v-b-tooltip.hover @click="onExecuteButtonClick">
                    <span class="oi oi-circle-check"></span> 実行
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="pt-5">
                <b-col>
                  <b-table class="pb-0" :fields="tableFields" :items="tableItems" show-empty small>
                    <!-- 営業所 -->
                    <template #cell(office)="data">
                      {{ `${data.item.office_id}：${data.item.office_name_kanji}` }}
                    </template>
                    <!-- 置き場所 -->
                    <template #cell(place)="data">
                      {{ data.item.place === '' ? '全置き場' : data.item.place }}
                    </template>
                    <!-- 棚卸前処理 -->
                    <template #cell(inventoryPreprocessDatetime)="data">
                      {{ data.item.preprocess_datetime === null ? '' : data.item.preprocess_datetime.toLocaleString() }}
                    </template>
                    <!-- 棚卸表出力 -->
                    <template #cell(inventoryTablePrintDatetime)="data">
                      {{ data.item.print_inventory_sheet_datetime === null ? '' : data.item.print_inventory_sheet_datetime.toLocaleString() }}
                    </template>
                    <!-- 棚卸入力 -->
                    <template #cell(inventoryInputDatetime)="data">
                      {{ data.item.input_inventory_datetime === null ? '' : data.item.input_inventory_datetime.toLocaleString() }}
                    </template>
                    <!-- 棚卸差異表 -->
                    <template #cell(inventoryDifferenceTableDatetime)="data">
                      {{ data.item.print_stock_variance_datetime === null ? '' : data.item.print_stock_variance_datetime.toLocaleString() }}
                    </template>
                    <!-- 棚卸No. -->
                    <template #cell(inventoryNo)="data">
                      {{ data.item.inventory_no }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { addOperationLogs, executeSelectSql, executeTransactSqlList, init, selectOneTable } from '@/assets/js/common.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import store from '../store';

// モジュール名
const MODULE_NAME = 'inventory-preprocess';

export default {
  name: 'INVENTORY-PREPROCESS',
  components: {
    Header,
    Footer,
  },
  data() {
    return{
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '棚卸前処理',
      // 営業所コード
      officeList: [],
      officeId: '0',
      // 置き場所
      place: '',
      // 状態表示関連
      tableFields: DataTblDef.inventory_preprocess_table_fields,
      tableItems: [],
      // Successアラートメッセージ
      alertSuccessMessages: [],
      // Dangerアラートメッセージ
      alertDangerMessages: [],
    };
  },
  computed: {
    /**
     * 表示するアラートメッセージかあるかどうかを確認します。
     * @returns {Boolean} メッセージがある場合はtrue、無い場合はfalse
     */
    isAlertMessages() {
      return (this.alertSuccessMessages.length > 0 || this.alertDangerMessages.length > 0);
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    if (await this.setOfficeList() && await this.setOffficeListDefault()) {
      await this.fetchData();
    }
    this.$store.commit('setLoading', false);
  },
  methods: {
    /**
     * 営業所リストを更新します。
     * @returns {Boolean} 正常に終了した場合はtrue、エラーが発生した場合はfalse
     */
    async setOfficeList() {
      const functionName = 'setOfficeList';

      const whereClause = 'ORDER BY office_id';
      let listMOfficesResult = null;
      try {
        listMOfficesResult = await selectOneTable('m_offices', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_offices',
          whereClause: whereClause
        }, error);
        this.alertDangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      for (const mOffice of listMOfficesResult) {
        this.officeList.push({
          value: mOffice.office_id,
          text: `${mOffice.office_id}：${mOffice.office_name_kanji}`
        });
      }
      return true;
    },
    /**
     * 営業所リストの初期値を選択します。
     * @returns {Boolean} 正常に終了した場合はtrue、エラーが発生した場合はfalse
     */
    async setOffficeListDefault() {
      const functionName = 'setOffficeListDefault';

      const whereClause = `AND login_id = '${store.getters.user.username}'`;
      let listMStaffsResult = null;
      try {
        listMStaffsResult = await selectOneTable('m_staffs', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_staffs',
          whereClause: whereClause
        }, error);
        this.alertDangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      if (listMStaffsResult == null || listMStaffsResult.length == 0) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_staffs',
          whereClause: whereClause,
          result: listMStaffsResult
        });
        this.dangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      this.officeId = String(listMStaffsResult[0].office_id);
      return true;
    },
    /**
     * 各種機能の実行日を取得します。
     */
    async fetchData() {
      const functionName = 'fetchData';

      this.tableItems = [];
      try {
        this.tableItems = await executeSelectSql(
          'SELECT' +
            ' tih.inventory_no AS inventory_no' +
            ',tih.office_id AS office_id' +
            ',tih.place AS place' +
            ',tih.preprocess_datetime AS preprocess_datetime' +
            ',tih.print_inventory_sheet_datetime AS print_inventory_sheet_datetime' +
            ',tih.input_inventory_datetime AS input_inventory_datetime' +
            ',tih.print_stock_variance_datetime AS print_stock_variance_datetime' +
            ',mo.office_name_kanji AS office_name_kanji ' +
          'FROM ' +
            't_inventories_histories tih ' +
            'INNER JOIN m_offices mo ' +
              'ON mo.office_id = tih.office_id ' +
          'WHERE ' +
            'tih.inventory_no IN ' +
              '(' +
                'SELECT' +
                  ' MAX(inventory_no) AS inventory_no ' +
                'FROM ' +
                  't_inventories_histories ' +
                'GROUP BY' +
                  ' office_id' +
              ') ' +
          'ORDER BY' +
            ' tih.office_id;'
        );
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, error);
        this.dangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
    },
    /**
     * 実行ボタンクリック処理
     */
    async onExecuteButtonClick() {
      // 表示メッセージのクリアとバリデーションチェック
      this.alertSuccessMessages = [];
      this.alertDangerMessages = [];
      if (!await this.$refs.observer.validate()) {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
        return;
      }

      // 実行確認
      if (!await this.$bvModal.msgBoxConfirm('棚卸前処理を実行します。よろしいですか？', {
        title: '棚卸前処理の実行',
        okTitle: 'はい',
        cancelTitle: 'いいえ',
      })) {
        return;
      }

      // 実行開始
      this.$store.commit('setLoading', true);
      const result = await this.executePreprocess();
      let message = null;
      if (result) {
        await this.fetchData();
        message = '終了しました。';
      } else {
        message = '異常終了しました。';
      }
      this.$store.commit('setLoading', false);

      // 終了メッセージ
      this.$bvModal.msgBoxOk(message, {
        title: '棚卸前処理の実行',
        okTitle: 'はい',
      });
    },
    /**
     * 棚卸前処理を実行します。
     * @returns {boolean} 実行に終了した場合はtrue、エラーが発生した場合はfalse
     */
    async executePreprocess() {
      const functionName = 'executePreprocess';

      // 棚卸履歴テーブルに履歴を登録
      const sql1 = 'INSERT INTO t_inventories_histories (' +
          'office_id' + // 営業所コード
          ',place' + // 置き場所
          ',preprocess_datetime' + // 棚卸前処理実施日時
          ',created' + // 作成日
          ',created_user' + // 作成ユーザー
          ',updated' + // 更新日
          ',updated_user' + // 更新ユーザー
        ') VALUES (' +
          `'${this.officeId}'` + // 営業所コード
          `,'${this.place}'` + // 置き場所
          ',CURRENT_TIMESTAMP()' + // 棚卸前処理実施日時
          ',CURRENT_TIMESTAMP()' + // 作成日
          `,'${this.$store.getters.user.username}'` + // 作成ユーザー
          ',CURRENT_TIMESTAMP()' + // 更新日
          `,'${this.$store.getters.user.username}'` + // 更新ユーザー
        ')';

      // 棚卸前処理の結果を棚卸商品テーブルに登録
      let sql2 = 'SET @sort_order=0;';
      let sql3 = 'INSERT INTO t_inventories ' +
          '(' +
            'inventory_no' + // 棚卸No.
            ',sort_order' + // 並び順
            ',office_id' + // 営業所コード
            ',product_id' + // 製品コード
            ',product_name' + // 製品名
            ',inventory_count' + // 総棚卸数
            ',shelves_count' + // 総実棚数
            ',inventory_entry_user_id' + // 棚卸入力者
            ',input_inventory_datetime' + // 棚卸入力日
            ',add_flg' + // 行追加フラグ
            ',finished_flg' + // 済フラグ
            ',created' + // 作成日
            ',created_user' + // 作成ユーザー
            ',updated' + // 更新日
            ',updated_user' + // 更新ユーザー
          ') ' +
        'SELECT' +
          ' subquery.inventory_no' + // 棚卸No.
          ',@sort_order:=@sort_order+1' + // 並び順
          ',subquery.office_id' + // 営業所コード
          ',subquery.product_id' + // 製品コード
          ',subquery.product_name_kanji' + // 製品名
          ',subquery.balance' + // 総棚卸数
          ',subquery.balance' + // 掃除棚数
          ',NULL' + // 棚卸入力者
          ',NULL' + // 棚卸入力日
          ',0' + // 行追加フラグ
          ',0' + // 済フラグ
          ',CURRENT_TIMESTAMP()' + // 作成日
          `,'${this.$store.getters.user.username}'` + // 作成ユーザー
          ',CURRENT_TIMESTAMP()' + // 更新日
          `,'${this.$store.getters.user.username}' ` + // 更新ユーザー
        'FROM ' +
          '(' +
            'SELECT' +
              ' tih.inventory_no' + // 棚卸No.
              ',ms.office_id' + // 営業所コード
              ',ms.product_id' + // 製品コード
              ',mp.product_name_kanji' + // 製品名
              ',ms.balance + ms.inventory_reserve_count AS balance ' + // 総棚卸数
            'FROM ' +
              'm_stocks ms ' +
              'INNER JOIN m_control mc ' +
                'ON mc.process_month_year = ms.month_year ' +
              'INNER JOIN m_products mp ' +
                'ON mp.product_id = ms.product_id ' +
              'INNER JOIN m_products_details mpd ' +
                'ON mpd.product_id = ms.product_id ' +
                'AND mpd.office_id = ms.office_id ' +
              'INNER JOIN (' +
                'SELECT' +
                  ' MAX(inventory_no) AS inventory_no ' +
                'FROM ' +
                  't_inventories_histories' +
              ') tih ' +
                'ON 1 = 1 ' +
            'WHERE ' +
              'ms.balance + ms.inventory_reserve_count != 0 ' +
              `AND ms.office_id = ${this.officeId} `;
      if (this.place.length > 0) {
        const words = this.place.split('-'); 
        sql3 += (words[0] === '') ? 'AND (mpd.place_1 IS NULL OR TRIM(mpd.place_1) = \'\') ' : `AND mpd.place_1 = '${words[0]}' `;
        sql3 += (words[1] === '') ? 'AND (mpd.place_2 IS NULL OR TRIM(mpd.place_2) = \'\') ' : `AND mpd.place_2 = '${words[1]}' `;
        sql3 += (words[2] === '') ? 'AND (mpd.place_3 IS NULL OR TRIM(mpd.place_3) = \'\') ' : `AND mpd.place_3 = '${words[2]}' `;
        sql3 += (words[3] === '') ? 'AND (mpd.place_4 IS NULL OR TRIM(mpd.place_4) = \'\') ' : `AND mpd.place_4 = '${words[3]}' `;
      }
      sql3 += 'ORDER BY' +
              ' mpd.place_1 ASC' +
              ',mpd.place_2 ASC' +
              ',mpd.place_3 ASC' +
              ',mpd.place_4 ASC' +
              ',ms.office_id ASC' +
              ',ms.product_id ASC' +
          ') subquery;'

      if (!await executeTransactSqlList([sql1, sql2, sql3], MODULE_NAME, functionName)) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '棚卸前処理SQLの実行でエラーが発生しました。');
        return false;
      }

      return true;
    }
  }
}
</script>