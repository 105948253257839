<template>
  <!-- 製品構成保守照会画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 製品構成保守照会</strong></h5>
                <router-link to="/products-compositions-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <b-alert show variant="success" class="mt-2" v-if="successMessages.length">
                <ul v-for="(message, index) in successMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <!-- セット製品コード -->
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 form-group">
                  <label for="productId" class="form-label"><strong>セット製品コード</strong></label>
                  <input type="text" id="productId" class="form-control" v-model="producCompositionsObj.product_id" readonly>
                </div>
                <!-- 製品名 -->
                <div class="col-sm-12 col-md-8 col-lg-8 col-xl-6 col-xxl-4 form-group">
                  <label for="productId" class="form-label"><strong>製品名</strong></label>
                  <input type="text" id="productId" class="form-control" v-model="producCompositionsObj.product_name_kanji" readonly v-b-tooltip.hover.noninteractive :title="producCompositionsObj.product_name_kanji">
                </div>
              </div>
              <!-- 構成テーブル -->
              <div class="row mt-2">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 form-group">
                  <b-table
                    show-empty
                    :small="true"
                    :head-variant="headVariant"
                    :responsive="true"
                    :items="producCompositionsObj.compositions"
                    :fields="producCompositionsTableFields"
                    :busy="busy"
                    :bordered="true"
                    :empty-text="emptyText"
                  >
                    <!-- テーブル読み込み時表示html -->
                    <template #table-busy>
                      <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>読み込んでいます...</strong>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>  
              <div class="card-footer">
                <div class="row justify-content-md-center">
                  <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                    <button type="button" class="btn btn-cancel btn-block" @click="clickDeleteBtn(producCompositionsObj)"><span class="oi oi-circle-x"></span> 削除</button>
                  </div>
                  <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                    <button type="button" class="btn btn-primary btn-block" @click="clickEditBtn(producCompositionsObj)"><span class="oi oi-pencil"></span> 編集</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, init, isSystemEditable, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'products-compositions-inquiry';

export default {
  name: 'PRODUCTS-COMPOSITIONS-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '製品構成保守照会',
      busy: false,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // 製品構成対象
      producCompositionsObj: {},
      // 構成テーブル
      producCompositionsTableFields: DataTblDef.produc_compositions_inquiry_table_fields,
      // 処理結果成功メッセージ
      successMessages: [],
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  /** 監視 */
  watch: {
  },
  /**
   * beforeMountライフサイクルフック
   */
  async beforeMount() {
    // 新規か編集画面から成功メッセージ
    this.successMessages = this.$route.params.successMessages || []
    await this.fetchData(this.$route.query);
    this.$store.commit('setLoading', false);
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} id - 製品コード
     */
    async fetchData({product_id}) {
      this.busy = true;
      // 構成検索SQL
      product_id = Number(product_id)
      // 製品のデータを請求
      // 構成マスタのデータを請求
      var [product, producCompositions] = await Promise.all([
        this.searchProducts(product_id),
        this.searchCompositions(product_id),
      ])
      // 構成データをセット
      product.compositions = producCompositions
      this.producCompositionsObj = {...product}
      this.busy = false;
    },

    /**
     * 製品マスタ検索
     * @param {String} whereClause - 検索条件
     * @returns {<Object>} 検索結果
     */
    async searchProducts(product_id) {
      const functionName = 'searchProducts';
      const whereClause = `and product_id = ${product_id}`
      let result = null;
      try {
        result = await selectOneTable('m_products', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products',
          where_clause: whereClause
        }, error);
        return null;
      }
      if (result != null && result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    },
    /**
     *  構成マスタを検索します。
     * @param {Int} product_id - 製品コード
     * @returns {Array<Object>} 構成マスタデータ
     */
    async searchCompositions(product_id) {
      const functionName = 'searchCompositions';

      let result = null;
      const SQLs = `select * from m_products_compositions mc left join m_products mp on mc.component_product_id = mp.product_id 
                  where mc.product_id=${product_id}`;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }))
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return body.data[0]
    },
   
    /**
     * 編集ボタン押下時
     * @param {Int} product_id - 製品コード
     */
    clickEditBtn({product_id}) {
      this.$router.push({ name: 'PRODUCTS-COMPOSITIONS-EDIT', query: { product_id }});
    },
    /**
     * 削除ボタン押下時
     * @param {Int} product_id - 製品コード
     */
    clickDeleteBtn({product_id}) {
      this.$bvModal.msgBoxConfirm('製品構成データを削除します。\nよろしいですか？').then(async value => {
        if (value) {
          this.$store.commit('setLoading', true);
          this.successMessages = [];
          this.errorMessages = [];
          await this.execDelProductsCompositions(product_id)
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'PRODUCTS-COMPOSITIONS-MAINTENANCE',
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1002']] }  
            });
          }
          this.$store.commit('setLoading', false);
        }
      });
    },
    /**
     * 削除処理
     * @param {Int} product_id - 選択された製品コード
     */
    async execDelProductsCompositions(product_id) {
      const functionName = 'execDelProductsCompositions';
      // 製品構成マスタマスタ
      const SQLs = `delete from m_products_compositions where product_id = ${product_id}`

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.errorMessages.push('削除でエラーが発生しました。');
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push('削除でエラーが発生しました。');
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push('削除でエラーが発生しました。');
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
  }
}
</script>
