<template>
  <div>
    <Header :type="menu_type" :title="title" />
    <b-container class="p-4 min-vh-85" fluid>
      <b-row>
        <b-col>
          <b-media>
            <b-media-body class="pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> {{title}}</strong></h5>
              </div>
            </b-media-body>
          </b-media>
          <b-card>
            <!-- 月次更新が実行中ではない場合 -->
            <b-container fluid v-if="isNotRunning">
              <b-row v-if="errorMessages.length">
                <b-col>
                  <div class="alert alert-danger" role="alert">
                    <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                      <li>{{ message }}</li>
                    </ul>
                  </div>
                </b-col>
              </b-row>
              <b-row class="pb-3">
                <b-col class="text-center">
                  <div class="d-inline-block text-left">
                    <div class="pb-3">
                      <h5>
                        <span class="mr-1 text-success font-weight-bold">{{ monthYear }}</span>分の月次更新を行います。<br>
                        確認後、更新開始ボタンをクリックしてください。
                      </h5>
                    </div>
                    <div v-if="monthlyUpdateStartDate !== null || monthlyUpdateEndDate !== null" class="text-center">
                      <div v-if="monthlyUpdateStartDate !== null">前回開始日時：{{monthlyUpdateStartDate}}</div>
                      <div v-if="isNotRunning && monthlyUpdateEndDate !== null">前回終了日時：{{monthlyUpdateEndDate}}</div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row align-h="center">
                <b-col md="4" lg="3" xl="2">
                  <b-button block :disabled="isStartButtonDisabled||updateDisabled" pill variant="success" @click="onStartButtonClick"><span class="mr-2 oi oi-circle-check"></span>更新開始</b-button>
                </b-col>
              </b-row>
            </b-container>
            <!-- 月次更新実行中の場合 -->
            <b-container fluid v-if="isRunning">
              <b-row>
                <b-col class="text-center">
                  <div class="d-inline-block text-left">
                    <div class="pb-3">
                      <h5><span class="mr-1 text-success font-weight-bold">{{ monthYear }}</span>分の月次更新を実施中です。しばらしてから再度本画面を確認して下さい。</h5>
                      <div class="text-center"><b>本画面は自動的に更新されません。</b></div>
                    </div>
                    <div v-if="monthlyUpdateStartDate !== null || monthlyUpdateEndDate !== null" class="text-center">
                      <div>開始日時：{{monthlyUpdateStartDate}}</div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-3 text-center">
                  <b-button pill variant="success" @click="checkRunningState">状況を確認する</b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { addOperationLogs, init, getControlMaster, selectOneTable } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { execute_monthly_update } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';
import moment from 'moment';
import Const from '@/assets/js/const';

// モジュール名
const MODULE_NAME = 'monthly-update';

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      menu_type: 'user',
      title: '月次更新',
      processMonthYear: 0,
      beginningMonth: 0,
      monthlyUpdateState: Const.MonthlyUpdateStateClass.NOT_RUNNING,
      monthlyUpdateStartDate: null,
      monthlyUpdateEndDate: null,
      isStartMsgBoxConfirmShow: false,
      updateDisabled: true,
      errorMessages: []
    };
  },
  computed: {
    monthYear() {
      return this.processMonthYear === 0 ? '' : moment(String(this.processMonthYear)).format('YY/MM');
    },
    nextMonthYear() {
      return this.processMonthYear === 0 ? '' : moment(String(this.processMonthYear)).add(1, 'month').format('YY/MM');
    },
    isStartButtonDisabled() {
      return this.isStartMsgBoxConfirmShow;
    },
    isRunning() {
      return this.monthlyUpdateState === Const.MonthlyUpdateStateClass.RUNNING;
    },
    isNotRunning() {
      return this.monthlyUpdateState === Const.MonthlyUpdateStateClass.NOT_RUNNING || this.monthlyUpdateState === Const.MonthlyUpdateStateClass.ERROR_STOP;
    }
  },
  /**
   * createdライフサイクルフック
   */
  async created() {
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init();
    scrollTo(0, 0);
  },
  methods: {
    /**
     * 必要なデータを取得して画面に反映します。
     */
    async fetchData() {
      const functionName = 'fetchData';

      let m_control = null;
      try {
        m_control = await getControlMaster();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control'
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }

      this.processMonthYear = m_control.process_month_year;
      this.beginningMonth = m_control.beginning_month;
      this.monthlyUpdateState = m_control.monthly_update_state;
      this.monthlyUpdateStartDate = m_control.monthly_update_start_date;
      this.monthlyUpdateEndDate = m_control.monthly_update_end_date;
      if (m_control.monthly_update_state === Const.MonthlyUpdateStateClass.ERROR_STOP) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2022']);
      }
      // 処理年月の請求/支払締更新が終わっているかチェック
      if (await this.checkClosingUpdateCompletion() == true) {
        this.updateDisabled = false;
      } else {
        this.updateDisabled = true;
      }
    },
    /**
     * 締更新の完了確認。
     */
    async checkClosingUpdateCompletion() {
      const functionName = 'checkClosingUpdateCompletion';
      let where_clause = '';
      where_clause += 'AND billing_payment_class IN (' + Const.BillingPaymentClass.billing + ',' + Const.BillingPaymentClass.payment + ') ';
      where_clause += 'AND process_month_year = ' + this.processMonthYear + ' ';
      where_clause += 'ORDER BY billing_payment_class,process_month_year,closing_date,client_id_start ';
      let resultData = null;
      let alertMessage = Const.BillingPaymentClassList.find(el => el.id == Const.BillingPaymentClass.billing).name;
      try {
        resultData = await selectOneTable('t_closing_update_completion', where_clause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_t_closing_update_completion',
          where_clause: where_clause
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      if (resultData == null || resultData.length == 0) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2051'].replace('%arg1%', alertMessage));
        return false;
      }
      let dataClientId;
      for (let i = 0; i < Const.BillingPaymentClassList.length; i++) {
        alertMessage = Const.BillingPaymentClassList.find(el => el.id == i).name;
        // 請求支払区分（0:請求、1:支払）
        for (let j = 0; j < Const.closingDateList.length; j++) {
          if (Const.closingDateList[j].value == '') {
            // 空欄の要素はスキップ
            continue;
          }
          // 請求支払区分と締日が等しいレコードを取得
          dataClientId = resultData.filter(el => el.billing_payment_class == i &&
                                                 el.closing_date == Number(Const.closingDateList[j].value));
          if (dataClientId == undefined) {
            this.errorMessages.push(DISP_MESSAGES.WARNING['2051'].replace('%arg1%', alertMessage));
            return false;
          }
          // 取引先コードが000000～999999まで終わっていることを確認するためのループ
          let clientIdStart = null;
          let clientIdEnd = null;
          for (let k = 0; k < dataClientId.length; k++) {
            if (clientIdStart == null) {
              clientIdStart = dataClientId[k].client_id_start;
              clientIdEnd = dataClientId[k].client_id_end;
            } else {
              if (clientIdEnd + 1 == dataClientId[k].client_id_start) {
                clientIdEnd = dataClientId[k].client_id_end;
              }
            }
          }
          if (clientIdStart != 0 || clientIdEnd != 999999) {
            // 取引先コード000000～999999の範囲の締更新が終わっていない場合はエラー
            this.errorMessages.push(DISP_MESSAGES.WARNING['2051'].replace('%arg1%', alertMessage));
            return false;
          }
        }
      }

      return true;
    },
    /**
     * 月次更新の開始確認と実行を行います。
     */
    async onStartButtonClick() {
      const functionName = 'onStartButtonClick';

      // 月次更新の開始確認メッセージボックス表示フラグをON（更新開始ボタン連打対策）
      if (this.isStartMsgBoxConfirmShow) {
        return;
      }
      this.isStartMsgBoxConfirmShow = true;
      // エラーメッセージをクリア
      this.errorMessages = [];

      if (!await this.$bvModal.msgBoxConfirm(`${this.monthYear} 分の月次更新を開始します。よろしいですか？`, {
        title: '月次更新の開始',
        okTitle: 'はい',
        cancelTitle: 'いいえ'
      })) {
        // キャンセルボタンがクリックされた場合はここで終了
        this.isStartMsgBoxConfirmShow = false;
        return;
      }

      await addOperationLogs('Info', MODULE_NAME, functionName, `${this.monthYear} 分の月次更新API呼び出しを開始します。`);
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(execute_monthly_update, {
          input: {
            month_year: this.processMonthYear,
            beginning_month: this.beginningMonth
          }}));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'execute_monthly_update'
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        this.isStartMsgBoxConfirmShow = false;
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'execute_monthly_update',
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        this.isStartMsgBoxConfirmShow = false;
        return;
      }
      await addOperationLogs('Info', MODULE_NAME, functionName, `${this.monthYear} 分の月次更新API呼び出しが成功しました。実行ID：${result.data.execute_monthly_update.execution_id}`);

      await this.$bvModal.msgBoxOk(`${this.monthYear} 分の月次更新を開始しました。`, {
        title: '月次更新の開始',
        okTitle: 'はい'
      });
      this.isStartMsgBoxConfirmShow = false;
      this.monthlyUpdateState = Const.MonthlyUpdateStateClass.RUNNING;
    },
    /**
     * 月次更新の実行状況を確認します。
     */
    async checkRunningState() {
      const functionName = 'checkRunningState';

      let m_control = null;
      try {
        m_control = await getControlMaster();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control'
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }

      this.processMonthYear = m_control.process_month_year;
      this.monthlyUpdateState = m_control.monthly_update_state;
      this.monthlyUpdateStartDate = m_control.monthly_update_start_date;
      this.monthlyUpdateEndDate = m_control.monthly_update_end_date;
      let message = '';
      // 処理年月の請求/支払締更新が終わっているかチェック
      if (await this.checkClosingUpdateCompletion() == true) {
        this.updateDisabled = false;
      } else {
        this.updateDisabled = true;
      }
      switch (m_control.monthly_update_state) {
      case Const.MonthlyUpdateStateClass.NOT_RUNNING:
        message = `${moment(String(this.processMonthYear)).add(-1, 'month').format('YY/MM')} 分の月次更新を終了しました。`
        break;
      case Const.MonthlyUpdateStateClass.RUNNING:
        message = `${moment(String(this.processMonthYear)).format('YY/MM')} 分の月次更新を実行中です。`
        break;
      case Const.MonthlyUpdateStateClass.ERROR_STOP:
        message = `${moment(String(this.processMonthYear)).add(-1, 'month').format('YY/MM')} 分の月次更新はエラー終了しました。`
        break;
      }
      await this.$bvModal.msgBoxOk(message, {
        title: '月次更新の状況確認',
        okTitle: 'はい'
      });
    }
  }
}
</script>