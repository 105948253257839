<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
                  <strong> 売上修正</strong>
                  <b-form-text class="text-muted d-inline-flex">(編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください)</b-form-text>
                </h5>
                <b-button pill v-b-tooltip.hover.bottom.noninteractive title="売上一覧に戻る" @click="cancel" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </div>
            </b-media-body>
          </b-media>
          <div class="main-card card">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveData();" id="inputForm" class="form-horizontal">
                  <b-row>
                    <!-- 伝票番号 -->
                    <b-col lg="3">
                      <b-form-group
                        label="伝票番号"
                        label-for="billingNo"
                      >
                        <b-form-input id="billingNo" type="text" v-model="billingNo" readonly />
                      </b-form-group>
                    </b-col>
                    <!-- 営業所コードプルダウン -->
                    <b-col lg="3">
                      <b-form-group
                        label="営業所"
                        label-for="selectSalesOffice"
                      >
                        <b-form-input id="selectSalesOffice" type="text" v-model="selectSalesOfficeText" readonly />
                      </b-form-group>
                    </b-col>
                    <!-- 伝票日付 -->
                    <b-col lg="3">
                      <validation-provider name="dateBillingDate" :rules="{required: true, oldProcessMonthYear: controlMasterData.processMonthYear}" v-slot="{ classes,errors }">
                        <label for="dateBillingDate" v-b-tooltip.hover.noninteractive title="変更される場合、製品の売価が自動で取り直されます">
                          <span>伝票日付</span>
                          <b-form-text class="text-muted d-inline-flex">(変更される場合、製品の売価...)</b-form-text>
                        </label>
                        <b-row>
                          <b-col lg="12" :class="classes">
                            <b-input-group class="input-daterange">
                              <b-form-datepicker
                                id="dateBillingDate"
                                name="dateBillingDate"
                                v-model="dateBillingDate"
                                calendar-width="50%"
                                @input="refreshSalesAmount"
                              ></b-form-datepicker>
                            </b-input-group>
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <!-- 伝票種別 -->
                    <b-col lg="3">
                      <b-form-group
                        label="伝票種別"
                      >
                        <b-form-select
                          id="selectBillClass"
                          v-model="selectBillClass"
                          :options="billClassList"
                          value-field="id"
                          v-if="orderReceiveId==0"
                        >
                        </b-form-select>
                        <b-input-group v-if="orderReceiveId!=0">
                          <b-input-group-text>{{ selectBillClassText }}</b-input-group-text>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <!-- 取引先コード -->
                    <b-col lg="6">
                      <validation-provider name="suppliersCode" :rules="{numeric: true, min: 6, max: 6, required: true, client_sundries_class: suppliers.sundriesClass}" v-slot="{ classes,errors }">
                        <label for="suppliersCode">取引先コード<b-form-text class="text-muted d-inline-flex">(変更される場合、現場や製品売価等の関連項目が自動でクリアされます)</b-form-text></label>
                        <b-row :class="classes">
                          <b-col lg="8">
                            <b-input-group>
                              <b-form-input
                                type="tel"
                                id="suppliersCode"
                                name="suppliersCode"
                                @input="searchClient(suppliers.code)"
                                maxlength="6"
                                v-model="suppliers.code"
                              />
                              <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                              <b-button class="mr-1" size="sm" pill variant="success" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが空欄以外の場合、利用可能です。）" :disabled="suppliers.code == null" @click="clientInfoModal(suppliers.class, suppliers.code)">
                                <span class="oi oi-eye"></span> 情報
                              </b-button>
                            </b-input-group>
                          </b-col>
                          <b-col lg="8" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <!-- 取引先製品単価適用チェック -->
                    <b-col lg="3">
                      <label for="isUnitPrice" v-b-tooltip.hover.noninteractive title='チェックONの場合、取引先や伝票日付の変更時に単価登録情報で売価を設定し直します。'>
                        単価適用チェック <span class="oi oi-flag"/>
                      </label>
                      <b-form-checkbox
                        id="isUnitPrice"
                        name="isUnitPrice"
                        v-model="isUnitPrice"
                        ></b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                     <!-- 取引先名 -->
                    <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9 form-group pl-3 pr-2">
                      <label class="form-label"><strong>取引先名: </strong>{{suppliers.name}}</label>
                    </div>
                    <!-- 締日 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group pl-3 pr-2">
                      <label class="form-label"><strong>締日: </strong>{{suppliers.closingDate}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 郵便番号 + 取引先住所１ + 取引先住所２ -->
                    <div class="col-12 form-group pl-3 pl-3 pr-2">
                      <label class="form-label"><strong>取引先住所: </strong>{{suppliers.zipCode}} {{suppliers.address1}} {{suppliers.address2}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 取引先部署１ + 取引先部署２ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group pl-3 pr-2">
                      <label class="form-label"><strong>部署: </strong>{{suppliers.department1}} {{suppliers.department2}}</label>
                    </div>
                    <!-- 取引先TEL -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group pl-3 pr-2">
                      <label class="form-label"><strong>TEL: </strong>{{suppliers.tel}}</label>
                    </div>
                    <!-- 担当者 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group pl-3 pr-2">
                      <label class="form-label"><strong>担当者: </strong>{{suppliers.personInCharge}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 製品 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :small="true"
                            :items="productList"
                            :fields="fields"
                            table-responsive
                          >
                            <template #table-caption>
                              <b-btn-toolbar>
                                <span class="mt-2 mr-2">&nbsp;製品テーブル</span> 
                                <!-- 行追加ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" type="button" @click="addTableRow()">
                                  <span class="oi oi-plus"></span> 行追加
                                </b-button>&nbsp;
                                <!-- 在庫照会ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" title="在庫照会モーダルを表示します。" @click="openStockModal()">
                                  <span class="oi oi-eye"></span> 在庫照会
                                </b-button>&nbsp;
                                <!-- 取引先製品単価履歴照会ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" title="取引先製品単価履歴照会モーダルを表示します。" @click="clickInquryClientProduct">
                                  <span class="oi oi-eye"></span> 取引先製品単価履歴照会
                                </b-button>
                              </b-btn-toolbar>
                            </template>
                            <template #cell(ProductCode)="data">
                              <validation-provider :rules="{required: true, numeric: true, min: 8, max: 8, minimum_quantity: {minimumQuantity: data.item.MinimumQuantity, caseConversionClass: data.item.CaseConversion, inventoryControlClass: data.item.InventoryClass}}" v-slot="{ classes,errors }">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-input-group style="min-width: 140px;">
                                      <b-form-input
                                        size="sm"
                                        class="px-1"
                                        type="text"
                                        name="productId"
                                        v-model="data.item.ProductCode"
                                        @input="searchProduct(data.item.ProductCode, data.index)"
                                        maxlength="8"
                                        :readonly="!checkClientId(suppliers.code)"
                                        style="width: 80px;"
                                      />
                                      <b-input-group-text @click="showProductSearchModal(data.index)" v-if="checkClientId(suppliers.code)">
                                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                          <span class="oi oi-magnifying-glass"></span>
                                        </b-button>
                                      </b-input-group-text>
                                    </b-input-group>
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </validation-provider>
                            </template>
                            <template #cell(ProductName)="data">
                              <validation-provider rules="max:35" v-slot="{ classes,errors }">
                                <div :class="classes" style="min-width: 240px;">
                                  <b-input-group>
                                    <span style="font-size: 20px;" v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark : '' }}</span>
                                    <b-form-input size="sm" class="px-1" type="text" name="productName" v-model="data.item.ProductName" :readonly="data.item.SundriesClass != 1" v-b-tooltip.hover.noninteractive.right="data.item.ProductName" />
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </template>
                            <template #cell(ServiceClass)="data">
                              <b-input-group style="min-width: 120px;">
                                <b-form-select
                                  size="sm" 
                                  class="px-1"
                                  v-model="data.item.ServiceClass"
                                  :options="serviceList"
                                  value-field="id"
                                  @change="changeService(data.index)"
                                />
                              </b-input-group>
                            </template>
                            <template #cell(Quantity)="data">
                              <validation-provider :rules="{required: true, orders_receives_between: {orderReceiveBillClass: selectBillClass, productSundriesClass: data.item.SundriesClass, shippingQuantity: 0, isDeleted: 0}}" v-slot="{ classes,errors }">
                                <b-input-group style="min-width: 70px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="number" :id="'productQuantity' + data.index" name="productQuantity" v-model.number="data.item.Quantity" @blur="calc(data.index)" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(Unit)="data">
                              <validation-provider rules="max:5" v-slot="{ classes,errors }">
                                <b-input-group style="min-width: 45px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="text" name="productUnit" v-model="data.item.Unit" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(SellingPrice)="data">
                              <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                                <b-input-group style="min-width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group class="d-flex align-items-center">
                                        <b-form-input size="sm" class="px-1" type="number" name="productSellingPrice" v-model.number="data.item.SellingPrice" @blur="calc(data.index)" />
                                        &nbsp;
                                        <b style="font-size: 20px;">{{ data.item.ClientAmountClass }}</b>
                                      </b-input-group>
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(PurchasePrime)="data">
                              <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                                <b-input-group style="min-width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="number" name="productPurchasePrime" v-model.number="data.item.PurchasePrime" @blur="calc(data.index)" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(Money)="data">
                              {{ data.item.Money.toLocaleString() }}
                              <validation-provider :rules="{set_val_between:{min:-99999999,max:99999999,title:'金額'}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <input type="hidden" name="Money" v-model.number="data.item.Money" />
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                              <validation-provider :rules="{set_val_between:{min:-99999999,max:99999999,title:'仕入金額'}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <input type="hidden" name="TotalPurchasePrime" v-model.number="data.item.TotalPurchasePrime" />
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(Delete)="data">
                              <b-button size="sm" v-b-tooltip.hover.noninteractive title="この行を削除をします。" @click="deleteBtn(data.index)">
                                <span class="oi oi-delete"></span> 削除
                              </b-button>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form>
                            <b-form-text label-align="left" class="text-muted">取引先を指定すると製品の検索入力可能になります。</b-form-text>
                          </b-form>
                        </b-col>
                        <b-col>
                          <b-form>
                            <b-form-group :label="textTotalMoneyText" label-align="right"></b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 納品指定日 -->
                    <b-col lg="3">
                      <b-form-group
                        label="納品指定日"
                        label-for="orderDate"
                      >
                        <b-input-group>
                          <b-form-datepicker id="orderDate" v-model="orderDate"></b-form-datepicker>
                          <b-input-group-append>
                            <b-button size="sm" variant="outline-secondary" @click="orderDate=''">
                              <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- 納品指定区分プルダウン -->
                    <b-col lg="3">
                      <b-form-group
                        label="納品指定区分"
                        label-for="selectDeliveryDesignation"
                      >
                        <b-form-select
                          id="selectDeliveryDesignation"
                          v-model="selectDeliveryDesignation"
                          :options="deliveryDesignation"
                          value-field="id"
                          @change="inputDeliveryNote"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- 納品指定 -->
                    <b-col lg="6">
                      <validation-provider name="deliveryNote" rules="max:34" v-slot="{ classes,errors }">
                        <b-form-group
                          label="納品指定"
                          label-for="deliveryNote"
                        >
                          <b-row>
                            <b-col lg="12">
                              <b-form-input id="deliveryNote" name="deliveryNote" type="text" v-model="deliveryNote" maxlength="34"/>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col lg="3">
                      <validation-provider name="onSiteCode" rules="required|numeric" v-slot="{ classes,errors }">
                        <label for="onSiteCode" v-b-tooltip.hover.noninteractive title="取引先を指定すると検索入力可能になります">
                          <span>現場コード</span>
                          <b-form-text class="text-muted d-inline-flex">(取引先を指定すると検索...)</b-form-text>
                        </label>
                        <b-row :class="classes">
                          <b-col lg="8">
                            <b-input-group>
                              <b-form-input
                                id="onSiteCode"
                                name="onSiteCode"
                                type="text"
                                v-model="onSite.code"
                                @change="searchSite(onSite.code)" 
                                :readonly="!checkClientId(suppliers.code)"
                                maxlength="4"
                              />
                              <b-input-group-text @click="showSiteSearchModal()" v-if="checkClientId(suppliers.code)" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '2']" @shortkey="showSiteSearchModal()" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                            </b-input-group>
                          </b-col>
                          <b-col lg="8" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <b-col lg="3">
                      <validation-provider name="onSiteZipCode" rules="zip_code" v-slot="{ classes,errors }">
                        <!-- 現場郵便番号 -->
                        <label for="onSiteZipCode" v-b-tooltip.hover.noninteractive title="nnn-nnnn」形式で入力してください。現場コードを指定すると入力中の情報が上書きされます。ご注意下さい。">
                          <span>郵便番号</span>
                          <b-form-text class="text-muted d-inline-flex">(nnn-nnnn」形式で入力して...)</b-form-text>
                        </label>
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="onSiteZipCode" name="onSiteZipCode" v-model="onSite.zipCode"></b-form-input>
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <b-col lg="3">
                      <!-- 配送コード -->
                      <b-form-group
                        label="配送コード"
                        label-for="onSiteDeliveryCode"
                      >
                        <b-form-input type="text" id="onSiteDeliveryCode" readonly v-model="onSite.deliveryCode"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col lg="6">
                      <validation-provider name="onSiteName" rules="max:50" v-slot="{ classes,errors }">
                        <!-- 現場名（漢字） -->
                        <label for="onSiteName">現場名<b-form-text class="text-muted d-inline-flex">(現場コードを指定すると入力中の情報が上書きされます。ご注意下さい)</b-form-text></label>
                        <b-row>
                          <b-col>
                            <b-form-input type="text" id="onSiteName" name="onSiteName" v-model="onSite.name" maxlength="50"></b-form-input>
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <b-col lg="6">
                      <!-- 印刷用宛名 -->
                      <b-form-group
                        label="印刷用宛名"
                        label-for="clientPrintNameText"
                      >
                        <b-form-input type="text" id="clientPrintNameText" readonly v-model="onSite.clientPrintNameText"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col lg="6">
                      <validation-provider name="onSiteAddress1" rules="max:34" v-slot="{ classes,errors }">
                        <!-- 現場住所１ -->
                        <label for="onSiteAddress1">住所１<b-form-text class="text-muted d-inline-flex">(現場コードを指定すると入力中の情報が上書きされます。ご注意下さい)</b-form-text></label>
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="onSiteAddress1" name="onSiteAddress1" v-model="onSite.address1"></b-form-input>
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <b-col lg="6">
                      <validation-provider name="onSiteAddress2" rules="max:34" v-slot="{ classes,errors }">
                        <!-- 現場住所２ -->
                        <label for="onSiteAddress2">住所２<b-form-text class="text-muted d-inline-flex">(現場コードを指定すると入力中の情報が上書きされます。ご注意下さい)</b-form-text></label>
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="onSiteAddress2" name="onSiteAddress2" v-model="onSite.address2"></b-form-input>
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col  lg="6">
                      <validation-provider name="onSiteDeliveryPlace" rules="max:54" v-slot="{ classes,errors }">
                        <!-- 納品場所 -->
                        <label for="onSiteDeliveryPlace">納品場所<b-form-text class="text-muted d-inline-flex">(現場コードを指定すると入力中の情報が上書きされます。ご注意下さい)</b-form-text></label>
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="onSiteDeliveryPlace" name="onSiteDeliveryPlace" v-model="onSite.deliveryPlace"></b-form-input>
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <b-col lg="6">
                      <!-- チェック -->
                      <b-form-group
                        label="チェック内容"
                        label-for="onSiteCheck"
                      >
                        <b-form-input type="text" id="onSiteCheck" readonly v-model="suppliers.check"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col lg="3">
                      <validation-provider name="onSiteTel" rules="phone" v-slot="{ classes,errors }">
                        <!-- 現場TEL -->
                        <label for="onSiteTel" v-b-tooltip.hover.noninteractive title="現場コードを指定すると入力中の情報が上書きされます。ご注意下さい">
                          <span>TEL</span>
                          <b-form-text class="text-muted d-inline-flex">(現場コードを指定すると入力中の...)</b-form-text>
                        </label>
                        <b-row>
                          <b-col lg="12">
                            <b-form-input type="text" id="onSiteTel" name="onSiteTel" v-model="onSite.tel" maxlength="21" />
                          </b-col>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                    <!-- 処理日 -->
                    <b-col lg="3">
                      <validation-provider name="dateProcessDate" :rules="{required: true, oldProcessMonthYear: controlMasterData.processMonthYear}" v-slot="{ classes,errors }">
                        <b-form-group
                          label="処理日"
                          label-for="dateProcessDate"
                        >
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-input-group class="input-daterange">
                                <b-form-datepicker
                                  id="dateProcessDate"
                                  name="dateProcessDate"
                                  v-model="dateProcessDate"
                                  calendar-width="50%"
                                ></b-form-datepicker>
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- 処理担当者 -->
                    <b-col lg="3">
                      <validation-provider name="orderReceiveStaff" rules="required" v-slot="{ classes,errors }">
                        <b-form-group
                          label="処理担当者"
                          label-for="orderReceiveStaff"
                        >
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-input-group>
                                <b-form-input type="text" id="orderReceiveStaff" name="orderReceiveStaff" v-model="orderReceiveStaff.text" readonly />
                                <b-input-group-text @click="showStaffSearchModal" v-b-tooltip.hover.noninteractive title="「ALT+3」ボタンで呼出可能">
                                  <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                    <span class="oi oi-magnifying-glass"></span>
                                    <button type="button" v-shortkey="['alt', '3']" @shortkey="showStaffSearchModal" class="d-none"></button>
                                  </b-button>
                                </b-input-group-text>
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- 伝票発行の有無 -->
                    <b-col lg="3">
                      <b-form-group
                        label="伝票発行の有無"
                        label-for="isPrint"
                      >
                        <b-form-checkbox
                          id="isPrint"
                          name="isPrint"
                          v-model="isPrint"
                          ></b-form-checkbox>
                        <b-form-text class="text-muted">チェックされている場合、保存後に伝票が発行されます。</b-form-text>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- 保存ボタン -->
                  <b-row class="justify-content-md-center">
                    <b-col lg="2">
                      <b-button pill variant="success" type="submit" form="inputForm" class="btn btn-block" :disabled="statusFlg.editFlg==false" @click="delListLastBlankRow"><span class="oi oi-circle-check"></span> 保存</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●担当者検索モーダル●●● -->
    <STAFFSEARCH @from-child="closeStaffSearchModal"/>
    <!-- ●●●取引先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="propClientClass"/>
    <!-- ●●●取引先照会モーダル●●● -->
    <CLIENTINQUIRY :clientProp="propClientInquiry"/>
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :product-search-prop="productSearchProp"/>
    <!-- ●●●現場検索モーダル●●● -->
    <SITESEARCH @from-child="closeSiteSearchModal" :client-info="siteSearchInfo" />
    <!-- 在庫照会モーダル -->
    <STOCKINQUIRY :stock-info="stockInfo" />
    <!-- ●●●取引先製品単価履歴照会モーダル●●● -->
    <CLIENTPRODUCTINQUIRY :clientProductProp="clientProductProp" />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import STAFFSEARCH from '@/components/modal/staff-search.vue';
import { searchProductsModal, searchSiteModal, searchClientModal } from '@/assets/js/searchModal.js';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import SITESEARCH from '@/components/modal/site-search.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import STOCKINQUIRY from '@/components/modal/stock-inquiry.vue';
import CLIENTPRODUCTINQUIRY from '@/components/modal/client-product-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, getControlMaster, dateConsistency, formatDate, checkClientId, formatDateCalc, getListValue, getNullStr, executeSelectSql, CreateInsertSql, CreateUpdateSql, CreateColRow, calcTaxNew, escapeQuote, executeSqlList, isSystemEditable, isOtherSessionUpdated, isAfterMonthlyUpdate, refreshSalesAmountProductList, checkOldClosingDate, checkInvoiceIssue, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'sales-edit';

export default {
  name: 'SALES-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    PRODUCTSEARCH,
    SITESEARCH,
    CLIENTSEARCH,
    CLIENTINQUIRY,
    STAFFSEARCH,
    STOCKINQUIRY,
    CLIENTPRODUCTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '売上修正',
      loginId: '',
      listUpdatedSelect: [],
      // 取引先コード確認関数
      checkClientId: checkClientId,
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOffice: null,
      selectSalesOfficeText: '',
      salesOffice: [],
      // 伝票日付
      dateBillingDate: '',
      // 伝票種別
      selectBillClass: null,
      selectBillClassText: '',
      billClassList: [],
      // 取引先系
      suppliers: {
        closingDate: '',
        closingDay: 0,
        code: '',
        name: '',
        class: '',
        address1: '',
        address2: '',
        zipCode: '',
        department1: '',
        department2: '',
        tel: '',
        personInChargeId: '',
        personInCharge: '',
        sundriesClass: '',
        firstDigit: '',
        taxClass: '',
        taxCalculationClass: '',
        taxFracClass: '',
        check: '',
      },
      // 納品指定日
      orderDate: '',
      // 納品指定区分
      selectDeliveryDesignation: '',
      deliveryDesignation: [],
      // 納品メモ
      deliveryNote: '',
      // 現場系
      onSite: {
        deliveryCode: '',
        code: '',
        name: '',
        zipCode: '',
        address1: '',
        address2: '',
        deliveryPlace: '',
        tel: '',
        clientPrintClass: '',
        clientPrintName: '',
        clientPrintNameText: '',
      },
      // 処理日
      dateProcessDate: '',
      // 処理担当者
      orderReceiveStaff: {
        id: '',
        text: '',      
      },
      // 受注番号
      orderReceiveId: 0,
      // 取引先モーダル用の引数
      propClientClass: Const.ClientClass.customer,
      // テーブル定義
      productList: [],
      // 確認ダイアログ用
      confirmMessage: [],
      // 製品ダイアログ用
      dataIndex: 0,
      totalFee: 0,
      totalPurchasePrime: 0,
      grossProfit: 0,
      subTotalNormal: 0,
      subTotalLight: 0,
      // サービス区分
      serviceList: [],
      // 製品検索情報
      productSearchProp: {
        office_id: '',
        office_name: '',
        client_id: '',
        client_class: '',
        client_name: '',
        multiSelectFlg: '1',
        kijunDate: '',
      },
      // 現場検索情報
      siteSearchInfo: {
        client_id: '',
        client_name: '',
      },
      // 在庫照会情報
      stockInfo: {
        office_id: '',
        product_id: [],
      },
      // 伝票発行の有無
      isPrint: true,
      // 取引先製品単価適用の有無
      isUnitPrice: false,
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        lightTaxMark: '',
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 取引先別製品照会に渡すパラメータ
      clientProductProp: {
        clientClass: null,
        clientId: null,
        clientNameKanji: '',
        productIdCsv: '',
      },
      // トランザクションSQLリスト
      transactSqlList: [],
      // ステータスフラグ
      statusFlg: {
        editFlg: true,
      },
      // 在庫一覧
      stockList: [],
      // 別タブを開く
      newWin: null,
      // 定数（htmlで使用）
      htmlConst: {
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // パラメータ
      billingNo: 0,
      monthlyMonthYear: 0,
      // 伝票作成情報（「YYYYMMDD(8桁) + 半角スペース + ログイン担当者コード（最大4桁）」）
      createdUserData: '',
    }
  },
  computed: {
    fields: function() {
      return [
        {
          key: 'ProductCode',
          label: '製品コード',
        },
        {
          key: 'ProductName',
          label: '製品名',
        },
        {
          key: 'ServiceClass',
          label: 'サービス区分',
        },
        {
          key: 'Quantity',
          label: '数量',
        },
        {
          key: 'Unit',
          label: '単位',
        },
        {
          key: 'SellingPrice',
          label: '売価',
        },
        {
          key: 'PurchasePrime',
          label: '仕入単価',
        },
        {
          key: 'Money',
          label: '金額',
          tdClass: 'text-right',
        },
        {
          key: 'Delete',
          label: ' ',
        },
      ]
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 合計値のテキスト */
    textTotalMoneyText: function() {
      let retTotalMoneyText = '粗利：' + (!Number.isFinite(this.grossProfit) ? 0 : this.grossProfit) + ' %';
      retTotalMoneyText += '　';
      retTotalMoneyText += '合計仕入単価：' + this.totalPurchasePrime.toLocaleString() + ' 円';
      retTotalMoneyText += '　';
      retTotalMoneyText += '合計金額：' + this.totalFee.toLocaleString() + ' 円';
      return retTotalMoneyText;
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.billingNo = this.$route.query.billingNo;
    this.monthlyMonthYear = this.$route.query.monthlyMonthYear;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 製品テーブル初期表示データ用意
        this.addTableRow();
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let deliveryListData = null;
        let serviceListData = null;
        let controlData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData, deliveryListData, serviceListData, controlData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
          selectOneTable('m_deliverys_designs_classes'),
          selectOneTable('m_service_classes'),
          getControlMaster(),
        ]);
        //console.log(staffListData);
        // 営業所データ取得
        //console.log(officeListData);
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.salesOffice.push(office);
        }
        // 営業所データ初期値セット
        for(let i = 0; i < this.salesOffice.length; i++){
          //console.log(this.salesOffice[i].id);
          if(this.salesOffice[i].id == staffListData[0].office_id){
            this.selectSalesOffice = this.salesOffice[i].id;
            break;
          }
        }
        // 伝票種別
        for (let i = 0; i < Const.TraditionList.length; i++) {
          // 「11:通常売上」「12:返品伝票」
          if (Const.TraditionList[i].id == Const.Tradition.salesNormal ||
          Const.TraditionList[i].id == Const.Tradition.salesReturn) {
            this.billClassList.push(Const.TraditionList[i]);
          }
        }
        // 納品指定区分を取得
        //console.log(deliveryListData);
        this.deliveryDesignation.push({id: '', name: '', text: ''});
        for(let i = 0; i < deliveryListData.length; i++){
          let deliveryDesignation = {
            id: deliveryListData[i].delivery_design_class,
            name: deliveryListData[i].delivery_design_name,
            text: deliveryListData[i].delivery_design_class + '：' + deliveryListData[i].delivery_design_name,
          };
          this.deliveryDesignation.push(deliveryDesignation);
        }
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        //console.log(serviceListData);
        for(let i = 0; i < serviceListData.length; i++){
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // 現在処理年月、消費税率取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.taxRate = controlData.tax_rate;
        this.controlMasterData.newTaxRate = controlData.new_tax_rate;
        this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
        this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
        this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 既存データ設定
        await this.setInitData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      // 初期化
      this.statusFlg.editFlg = true;
      this.productList = [];
      this.stockList = [];
      this.listUpdatedSelect = [];
      // 更新時の排他制御用の変数作成
      await this.createUpdatedSelect();
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null) {
        await this.setResult(dataResult);
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索SQL作成（初期値） */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.office_id';
      selectSql += ',cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.bill_class';
      selectSql += ',cumulative_transaction.client_class';
      selectSql += ',cumulative_transaction.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.closing_date';
      selectSql += ',clients.zip_code';
      selectSql += ',clients.sundries_class';
      selectSql += ',cumulative_transaction.client_id_first_digit';
      selectSql += ',clients.sales_tax_class';
      selectSql += ',clients.tax_calculation_class';
      selectSql += ',clients.tax_fractionation_class';
      selectSql += ',clients.address_1';
      selectSql += ',clients.address_2';
      selectSql += ',clients.department_1';
      selectSql += ',clients.department_2';
      selectSql += ',clients.phone_number';
      selectSql += ',cumulative_transaction.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',clients.check_contents';
      selectSql += ',cumulative_transaction.order_receive_staff_id';
      selectSql += ',order_receive_staffs.staff_name_kanji AS order_receive_staff_name_kanji';
      selectSql += ',cumulative_transaction.process_date';
      selectSql += ',cumulative_transaction.delivery_design_date';
      selectSql += ',cumulative_transaction.delivery_design_class';
      selectSql += ',cumulative_transaction.delivery_design';
      selectSql += ',cumulative_transaction.site_id';
      selectSql += ',cumulative_transaction.shipping_code';
      selectSql += ',cumulative_transaction.client_site_name AS client_site_name_kanji';
      selectSql += ',cumulative_transaction.client_site_zip_code';
      selectSql += ',cumulative_transaction.client_site_address_1';
      selectSql += ',cumulative_transaction.client_site_address_2';
      selectSql += ',cumulative_transaction.delivery_location';
      selectSql += ',cumulative_transaction.client_site_phone_number';
      selectSql += ',IfNull(clients_sites.order_print_class,' + Const.OrderPrintClass.customerPrint + ') AS order_print_class';
      selectSql += ',IfNull(clients_sites.order_print_client_name,\'\') AS order_print_client_name';
      selectSql += ',cumulative_transaction.product_id';
      selectSql += ',cumulative_transaction.product_name AS product_name_kanji';
      selectSql += ',cumulative_transaction.service_class';
      selectSql += ',cumulative_transaction.product_class_id';
      selectSql += ',cumulative_transaction.product_quantity';
      selectSql += ',cumulative_transaction.product_unit AS unit';
      selectSql += ',cumulative_transaction.product_sales_unit_price';
      selectSql += ',cumulative_transaction.client_amount_class';
      selectSql += ',cumulative_transaction.product_purchase_price';
      selectSql += ',cumulative_transaction.product_mouth_class';
      selectSql += ',cumulative_transaction.inventory_control_class';
      selectSql += ',MAX(orders_receives.is_bill_return_count) AS is_bill_return_count';
      selectSql += ',cumulative_transaction.order_receive_id';
      selectSql += ',cumulative_transaction.order_receive_row';
      selectSql += ',cumulative_transaction.order_id';
      selectSql += ',cumulative_transaction.order_row';
      selectSql += ',products_details.case_conversion_class';
      selectSql += ',products_orders.minimum_quantity';
      selectSql += ',cumulative_transaction.billing_summary';
      selectSql += ',products.product_tax_rate_class_sales';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON cumulative_transaction.client_class = clients.client_class ';
      selectSql += 'AND cumulative_transaction.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON cumulative_transaction.staff_id = staffs.staff_id ';
      selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
      selectSql += 'ON cumulative_transaction.client_id = clients_sites.client_id ';
      selectSql += 'AND cumulative_transaction.site_id = clients_sites.site_id ';
      selectSql += 'LEFT JOIN m_staffs AS order_receive_staffs ';
      selectSql += 'ON cumulative_transaction.order_receive_staff_id = order_receive_staffs.staff_id ';
      selectSql += 'LEFT JOIN t_orders_receives AS orders_receives ';
      selectSql += 'ON cumulative_transaction.order_receive_id = orders_receives.order_receive_id ';
      selectSql += 'INNER JOIN m_products AS products ';
      selectSql += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql += 'INNER JOIN m_products_details AS products_details ';
      selectSql += 'ON cumulative_transaction.product_id = products_details.product_id ';
      selectSql += 'AND cumulative_transaction.office_id = products_details.office_id ';
      selectSql += 'LEFT JOIN m_products_orders AS products_orders ';
      selectSql += 'ON cumulative_transaction.product_id = products_orders.product_id ';
      selectSql += 'AND cumulative_transaction.office_id = products_orders.office_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID
      selectSql += ' cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      // 引数の伝票番号で絞り込む
      selectSql += 'AND cumulative_transaction.billing_no = ' + this.billingNo + ' ';
      // 引数の月次年月で絞り込む
      selectSql += 'AND cumulative_transaction.monthly_month_year = ' + this.monthlyMonthYear + ' ';
      // 消費税行は出さない
      selectSql += 'AND cumulative_transaction.product_id <> 0 ';
      /* GROUP BY句 */
      selectSql += 'GROUP BY cumulative_transaction.billing_row ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY cumulative_transaction.billing_row ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        // 営業所プルダウン
        for (let i = 0; i < this.salesOffice.length; i++) {
          if (result[0].office_id == this.salesOffice[i].id) {
            this.selectSalesOffice = result[0].office_id;
            this.selectSalesOfficeText = this.salesOffice[i].text;
          }
        }
        // 伝票日付
        this.dateBillingDate = result[0].billing_date;
        if (dateConsistency(this.controlMasterData.processMonthYear + '01', this.dateBillingDate) == false) {
          // 伝票日付が処理年月よりも過去日付の場合
          this.statusFlg.editFlg = false;
          this.alertWarning.push(DISP_MESSAGES.WARNING['2017']);
        }
        // 伝票種別
        this.selectBillClass = result[0].bill_class;
        this.selectBillClassText = getListValue(this.billClassList, this.selectBillClass,'id', 'text');
        // 伝票日付が処理年月よりも過去日付以外
        if (this.statusFlg.editFlg == true) {
          // 返品伝票、且つ、在庫計上入力が「0:返品在庫計上未」の場合、編集不可
          if (result[0].bill_class == Const.Tradition.salesReturn && result[0].is_bill_return_count == 0) {
            this.statusFlg.editFlg = false;
            this.alertWarning.push(DISP_MESSAGES.WARNING['2018']);
          }
        }
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        if (result[0].closing_date == 99) {
          this.suppliers.closingDate = formatDateCalc(('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01', 0, 0, 0, true);
        } else {
          this.suppliers.closingDate = formatDate(('000000' + this.controlMasterData.processMonthYear).slice(-6) + ('00' + result[0].closing_date).slice(-2));
        }
        this.suppliers.closingDay = result[0].closing_date;
        // 編集可能な場合
        if (this.statusFlg.editFlg == true) {
          // 請求書発行登録済みの場合、編集不可
          let errMessage = await checkInvoiceIssue(result[0].client_id, result[0].billing_date, result[0].closing_date, result[0].site_id, this.billingNo);
          if (errMessage != '') {
            this.statusFlg.editFlg = false;
            this.alertWarning.push(errMessage);
          }
        }
        this.suppliers.address1 = result[0].address_1;
        this.suppliers.address2 = result[0].address_2;
        this.suppliers.department1 = result[0].department_1;
        this.suppliers.department2 = result[0].department_2;
        this.suppliers.tel = result[0].phone_number;
        this.suppliers.personInChargeId = result[0].staff_id;
        this.suppliers.personInCharge = result[0].staff_id + '：' + result[0].staff_name_kanji;
        this.suppliers.zipCode = result[0].zip_code;
        this.suppliers.sundriesClass = result[0].sundries_class;
        this.suppliers.firstDigit = result[0].client_id_first_digit;
        this.suppliers.taxClass = result[0].sales_tax_class;
        this.suppliers.taxCalculationClass = result[0].tax_calculation_class;
        this.suppliers.taxFracClass = result[0].tax_fractionation_class;
        this.suppliers.check = result[0].check_contents;
        // 現場系
        this.onSite.deliveryCode = result[0].shipping_code;
        this.onSite.code = result[0].site_id;
        this.onSite.name = result[0].client_site_name_kanji;
        this.onSite.zipCode = result[0].client_site_zip_code;
        this.onSite.address1 = result[0].client_site_address_1;
        this.onSite.address2 = result[0].client_site_address_2;
        this.onSite.deliveryPlace = result[0].delivery_location;
        this.onSite.tel = result[0].client_site_phone_number;
        this.onSite.clientPrintClass = Number(result[0].order_print_class);
        this.onSite.clientPrintName = result[0].order_print_client_name;
        this.onSite.clientPrintNameText = this.onSite.clientPrintClass == 0 ? '' : this.onSite.clientPrintName;
        // 納品指定日
        this.orderDate = result[0].delivery_design_date==null ? '' : result[0].delivery_design_date;
        // 納品指定区分
        this.selectDeliveryDesignation = result[0].delivery_design_class;
        // 納品メモ
        this.deliveryNote = result[0].delivery_design;
        // 処理日
        this.dateProcessDate = result[0].process_date;
        // 処理担当者
        this.orderReceiveStaff.id = result[0].order_receive_staff_id;
        this.orderReceiveStaff.text = result[0].order_receive_staff_id + '：' + result[0].order_receive_staff_name_kanji;
        // 受注番号
        this.orderReceiveId = result[0].order_receive_id;
        // 伝票摘要（伝票作成者情報を保持）
        this.createdUserData = result[0].billing_summary;
        // 全件参照して製品一覧を作成
        for (let i = 0; i < result.length; i++) {
          if (i == this.productList.length) {
            this.addTableRow();
          }
          this.productList[i].ProductCode = result[i].product_id;
          this.productList[i].ProductClass = result[i].product_class_id;
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i].ProductTaxRateClass = result[i].product_tax_rate_class_sales;
          this.productList[i].ServiceClass = result[i].service_class;
          this.productList[i].Quantity = result[i].product_quantity;
          this.productList[i].Unit = result[i].unit;
          this.productList[i].SellingPrice = result[i].product_sales_unit_price;
          this.productList[i].ClientAmountClass = result[i].client_amount_class;
          this.productList[i].PurchasePrime = result[i].product_purchase_price;
          this.productList[i].SundriesClass = result[i].product_mouth_class;
          this.productList[i].InventoryClass = result[i].inventory_control_class;
          this.productList[i].OrderReceiveRow = result[i].order_receive_row;
          this.productList[i].OrderId = result[i].order_id;
          this.productList[i].OrderRow = result[i].order_row;
          this.productList[i].CaseConversion = result[i].case_conversion_class,
          this.productList[i].MinimumQuantity = result[i].minimum_quantity;
          this.productList[i].TotalPurchasePrime = this.productList[i].Quantity * this.productList[i].PurchasePrime;
          this.productList[i].Money = this.productList[i].Quantity * this.productList[i].SellingPrice;
          // 在庫管理区分が「0:する」の場合
          if (this.productList[i].InventoryClass == Const.InventoryControlClassDef.inventory) {
            // 製品一覧に追加
            this.addStockList(result[i].product_id, result[i].product_quantity);
          }
        }
        this.calcTotal();
        // 最終行を空行とする
        this.addTableRow();
      }
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      } else {
        // 請求書発行登録済みの場合、保存不可
        let errMessage = await checkInvoiceIssue(this.suppliers.code, this.dateBillingDate, this.suppliers.closingDay, this.onSite.code, this.billingNo);
        if (errMessage != '') {
          this.alertWarning.push(errMessage);
          scrollTo(0,0);
        } else {
          // 入力チェックが完了した場合、保存確認ダイアログを表示
          await this.saveConfirm();
        }
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          if (await this.confirmOldClosingDate() == false) {
            // 伝票日付が締日よりも過去日で確認ダイアログでNGとした場合は保存せずに終わる
            return;
          }
          this.$store.commit('setLoading', true);
          if (this.isPrint) {
            // 別タブ事前に開く
            this.newWin = window.open('', '_blank');
          }
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg && this.alertSuccess.length == 0) {
        if (this.isPrint) {
          // タブを閉じる
          this.newWin.close()
        }
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg) {
        // ios safari ための対応
        setTimeout(() => {
          scrollTo(0,0)
        }, 500);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 排他制御チェック
      if (await isOtherSessionUpdated(this.listUpdatedSelect) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2027']);
        return;
      }
      // 月次更新後チェック
      if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
        return false;
      }
      // 製品情報整理
      this.organizeProduct();
      // 在庫確認
      if (await this.checkStock() == true) {
        // 保存処理
        //console.log('保存処理開始');
        // データの保存(SQLの作成)
        if (await this.execSave() == true) {
          if (this.isPrint) {
            // 納品書PDF出力
            this.outputSalesDirectPdf();
          }
          // 保存成功メッセージを表示
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
          await this.setInitData();
        }
      } else {
        // 在庫がないため更新不可
        this.alertWarning.push(DISP_MESSAGES.WARNING['2016']);
      }
      //console.log('保存処理終了');
    },
    /* 製品情報整理 */
    organizeProduct: function() {
      //console.log('製品情報整理');
      // 最初に全数量を初期化
      for (let j = 0; j < this.stockList.length; j++) {
        this.stockList[j].Count = 0;
      }
      for (let i = 0; i < this.productList.length; i++) {
        // 在庫管理区分が「0:する」の場合
        if (this.productList[i].InventoryClass == Const.InventoryControlClassDef.inventory) {
          for (let j = 0; j < this.stockList.length; j++) {
            if (this.productList[i].ProductCode == this.stockList[j].ProductCode) {
              this.stockList[j].Count += Number(this.productList[i].Quantity);
              break;
            }
          }
        }
      }
      //console.log(this.stockList);
    },
    /* 在庫チェック */
    async checkStock() {
      let retResult = false;
      //console.log('在庫チェック');
      let csvProductId = '';
      for (let i = 0; i < this.stockList.length; i++) {
        // 初期値よりも数量が大きくなっている製品のみ
        if (this.stockList[i].Count - this.stockList[i].PreCount > 0) {
          if (csvProductId != '') {
            csvProductId += ',';
          }
          csvProductId += this.stockList[i].ProductCode;
        }
      }
      // 在庫から取得する必要のある製品が１つでもある場合、在庫マスタチェック
      if (csvProductId != '') {
        // 在庫マスタから在庫情報取得
        let where_clause = '';
        // 年月
        where_clause += 'AND month_year = ' + this.controlMasterData.processMonthYear + ' ';
        // 営業所コード
        where_clause += 'AND office_id = ' + this.selectSalesOffice + ' ';
        // 製品コード
        where_clause += 'AND product_id IN (' + csvProductId + ') ';
        //console.log(where_clause);
        let stocksResultData = await selectOneTable('m_stocks', where_clause);
        //console.log(stocksResultData);
        // 在庫がない場合は完了
        if (stocksResultData == null || stocksResultData.length == 0) {
          return retResult;
        }
        for (let i = 0; i < this.stockList.length; i++) {
          // 在庫から取り出す必要のある製品数
          let needStockCount = this.stockList[i].Count - this.stockList[i].PreCount;
          if (needStockCount > 0) {
            for (let j = 0; j < stocksResultData.length; j++) {
              // 取得した在庫マスタの対象の製品
              if (this.stockList[i].ProductCode == stocksResultData[j].product_id) {
                if (stocksResultData[j].balance >= needStockCount) {
                  // 必要な在庫数が在庫にある場合、同製品はOKとして、次の製品へ
                  break;
                } else {
                  // 必要な在庫数が在庫にない場合、falseを返却
                  return retResult;
                }
              }
            }
          }
        }
      }

      retResult = true;

      return retResult;
    },
    /* 保存実行 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      // 在庫マスタロック
      this.lockStocks();
      // 累積トランザクションの更新
      await this.updateCumulativeTransaction();
      // 在庫更新
      this.updateStocksMaster();

      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }

      try {
        // 作成した登録用SQLを全実行
        await executeSqlList(this.transactSqlList);

        retResult = true;
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        console.log(error);
      }

      return retResult;
    },
    /* 在庫マスタロックSQL作成 */
    lockStocks: function() {
      //console.log('在庫マスタロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' stocks.month_year';
      selectSql += ',stocks.office_id';
      selectSql += ',stocks.product_id';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_stocks AS stocks ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      selectSql += 'AND stocks.office_id = ' + this.selectSalesOffice + ' ';
      let csvProductId = '';
      for (let i = 0; i < this.stockList.length; i++) {
        // 初期値よりも数量が変更されている製品のみ
        if (this.stockList[i].Count - this.stockList[i].PreCount != 0) {
          if (csvProductId != '') {
            csvProductId += ',';
          }
          csvProductId += this.stockList[i].ProductCode;
        }
      }
      selectSql += 'AND stocks.product_id IN (' + csvProductId + ') ';
      //console.log(selectSql)
      if (csvProductId != '') {
        this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
      }
    },
    /* 累積トランザクション更新SQL作成 */
    async updateCumulativeTransaction() {
      //console.log('累積トランザクション更新SQL作成');
      // CRUD処理
      // 累積トランザクションの削除SQL
      let deleteSql = 'DELETE FROM ';
      deleteSql += 't_cumulative_transaction';
      deleteSql += ' WHERE ';
      deleteSql += 'billing_no = ' + this.billingNo + ' ';
      deleteSql += 'AND monthly_month_year = ' + this.monthlyMonthYear + ' ';
      deleteSql += 'AND transaction_id = \'' + Const.TransactionId.sales + '\' ';
      deleteSql += 'AND is_update_monthly = 0 ';
      //console.log(deleteSql);
      this.transactSqlList.push({sql: deleteSql, forUpdateFlg: 0});
      // 累積トランザクションの登録SQL
      let bulkInsertSql = '';
      let tax = 0;
      if (this.suppliers.taxCalculationClass == Const.TaxCalculationClass.salesSlip) {
        // 税額計算区分が1の場合、消費税を計算
        tax = calcTaxNew(this.dateBillingDate, this.subTotalNormal, this.subTotalLight, this.controlMasterData.taxRate, this.controlMasterData.newTaxRate, this.controlMasterData.lightTaxRate, this.controlMasterData.newLightTaxRate, this.controlMasterData.newTaxStartDate);
      }
      for (let i = 0; i < this.productList.length + 1; i++) {
        let colList = [];
        // 製品行の行数分＋税額計算区分が1の場合の消費税行分
        if (i < this.productList.length || this.suppliers.taxCalculationClass == Const.TaxCalculationClass.salesSlip) {
          // ﾄﾗﾝｻﾞｸｼｮﾝID
          colList.push(CreateColRow('transaction_id', Const.TransactionId.sales, 'VARCHAR'));
          // 伝票No
          colList.push(CreateColRow('billing_no', this.billingNo, 'NUMBER'));
          // 行No
          colList.push(CreateColRow('billing_row', i + 1, 'NUMBER'));
          // 伝票日付
          colList.push(CreateColRow('billing_date', this.dateBillingDate, 'DATE'));
          // 伝票種別
          colList.push(CreateColRow('bill_class', this.selectBillClass, 'NUMBER'));
          // 解体組立区分
          colList.push(CreateColRow('dismantling_assembly_class', 0, 'NUMBER'));
          // 営業所コード
          colList.push(CreateColRow('office_id', this.selectSalesOffice, 'NUMBER'));
          // 移動先コード
          colList.push(CreateColRow('destination_id', 0, 'NUMBER'));
          // 取引先区分
          colList.push(CreateColRow('client_class', this.suppliers.class, 'NUMBER'));
          // 取引先コード
          colList.push(CreateColRow('client_id', this.suppliers.code, 'NUMBER'));
          // 納品指定日
          colList.push(CreateColRow('delivery_design_date', this.orderDate, 'DATE'));
          // 納品指定区分
          colList.push(CreateColRow('delivery_design_class', this.selectDeliveryDesignation, 'VARCHAR'));
          // 納品指定
          colList.push(CreateColRow('delivery_design', await escapeQuote(this.deliveryNote), 'VARCHAR'));
          // 現場コード
          colList.push(CreateColRow('site_id', this.onSite.code, 'NUMBER'));
          // 配送コード
          colList.push(CreateColRow('shipping_code', await escapeQuote(this.onSite.deliveryCode), 'VARCHAR'));
          // 現場名称
          colList.push(CreateColRow('client_site_name', await escapeQuote(this.onSite.name), 'VARCHAR'));
          // 現場郵便番号
          colList.push(CreateColRow('client_site_zip_code', await escapeQuote(this.onSite.zipCode), 'VARCHAR'));
          // 現場住所1
          colList.push(CreateColRow('client_site_address_1', await escapeQuote(this.onSite.address1), 'VARCHAR'));
          // 現場住所2
          colList.push(CreateColRow('client_site_address_2', await escapeQuote(this.onSite.address2), 'VARCHAR'));
          // 納品場所
          colList.push(CreateColRow('delivery_location', await escapeQuote(this.onSite.deliveryPlace), 'VARCHAR'));
          // 現場TEL
          colList.push(CreateColRow('client_site_phone_number', await escapeQuote(this.onSite.tel), 'VARCHAR'));
          // 伝票摘要
          colList.push(CreateColRow('billing_summary', this.createdUserData, 'VARCHAR'));
          // 処理日付
          colList.push(CreateColRow('process_date', this.dateProcessDate, 'DATE'));
          // 受注処理担当者コード
          colList.push(CreateColRow('order_receive_staff_id', this.orderReceiveStaff.id, 'NUMBER'));
          // 担当者コード
          colList.push(CreateColRow('staff_id', this.suppliers.personInChargeId, 'NUMBER'));
          // 取引先分類コード
          colList.push(CreateColRow('client_id_first_digit', this.suppliers.firstDigit, 'NUMBER'));
          // 相殺伝票No
          colList.push(CreateColRow('offset_billing_no', 0, 'NUMBER'));
          if (i == this.productList.length) {
            // 消費税行（追加）
            // 製品コード
            colList.push(CreateColRow('product_id', 0, 'NUMBER'));
            // 製品名
            colList.push(CreateColRow('product_name', '', 'VARCHAR'));
            // 製品備考
            colList.push(CreateColRow('product_note', '','VARCHAR'));
            // サービス区分
            colList.push(CreateColRow('service_class', '', 'VARCHAR'));
            // 製品数量
            colList.push(CreateColRow('product_quantity', 0, 'NUMBER'));
            // 製品単位
            colList.push(CreateColRow('product_unit', '', 'VARCHAR'));
            // 製品売上単価
            colList.push(CreateColRow('product_sales_unit_price', 0, 'NUMBER'));
            // 製品仕入単価
            colList.push(CreateColRow('product_purchase_price', 0, 'NUMBER'));
            // 製品売上金額
            colList.push(CreateColRow('product_sales_unit_amount', 0, 'NUMBER'));
            // 製品仕入金額
            colList.push(CreateColRow('product_purchase_amount', 0, 'NUMBER'));
            // 製品粗利額
            colList.push(CreateColRow('product_gross_profit', 0, 'NUMBER'));
            // 製品消費税
            colList.push(CreateColRow('product_tax', tax, 'NUMBER'));
            // 金額
            colList.push(CreateColRow('amount', 0, 'NUMBER'));
            // 製品分類コード
            colList.push(CreateColRow('product_class_id', 0, 'NUMBER'));
            // 製品諸口区分
            colList.push(CreateColRow('product_mouth_class', 0, 'NUMBER'));
            // 在庫管理区分
            colList.push(CreateColRow('inventory_control_class', 0, 'NUMBER'));
            // 取引先製品単価区分
            colList.push(CreateColRow('client_amount_class', '', 'VARCHAR'));
          } else {
            // 製品行
            // 製品コード
            colList.push(CreateColRow('product_id', this.productList[i].ProductCode, 'NUMBER'));
            // 製品名
            colList.push(CreateColRow('product_name', await escapeQuote(this.productList[i].ProductName), 'VARCHAR'));
            // 製品備考
            colList.push(CreateColRow('product_note', '','VARCHAR'));
            // サービス区分
            colList.push(CreateColRow('service_class', this.productList[i].ServiceClass, 'VARCHAR'));
            // 製品数量
            colList.push(CreateColRow('product_quantity', this.productList[i].Quantity, 'NUMBER'));
            // 製品単位
            colList.push(CreateColRow('product_unit', this.productList[i].Unit, 'VARCHAR'));
            // 製品売上単価
            colList.push(CreateColRow('product_sales_unit_price', this.productList[i].SellingPrice, 'NUMBER'));
            // 製品仕入単価
            colList.push(CreateColRow('product_purchase_price', this.productList[i].PurchasePrime, 'NUMBER'));
            // 製品売上金額
            colList.push(CreateColRow('product_sales_unit_amount', this.productList[i].Money, 'NUMBER'));
            // 製品仕入金額
            colList.push(CreateColRow('product_purchase_amount', this.productList[i].TotalPurchasePrime, 'NUMBER'));
            // 製品粗利額
            colList.push(CreateColRow('product_gross_profit', this.productList[i].Money - this.productList[i].TotalPurchasePrime, 'NUMBER'));
            // 製品消費税
            colList.push(CreateColRow('product_tax', 0, 'NUMBER'));
            // 金額
            colList.push(CreateColRow('amount', 0, 'NUMBER'));
            // 製品分類コード
            colList.push(CreateColRow('product_class_id', this.productList[i].ProductClass, 'NUMBER'));
            // 製品諸口区分
            colList.push(CreateColRow('product_mouth_class', this.productList[i].SundriesClass, 'NUMBER'));
            // 在庫管理区分
            colList.push(CreateColRow('inventory_control_class', this.productList[i].InventoryClass, 'NUMBER'));
            // 取引先製品単価区分
            colList.push(CreateColRow('client_amount_class', this.productList[i].ClientAmountClass, 'VARCHAR'));
          }
          // 売上課税区分
          colList.push(CreateColRow('sales_tax_class', this.suppliers.taxClass, 'NUMBER'));
          // 税額計算区分
          colList.push(CreateColRow('tax_calculation_class', this.suppliers.taxCalculationClass, 'NUMBER'));
          // 税額端数計算処理区分
          colList.push(CreateColRow('tax_fractionation_class', this.suppliers.taxFracClass, 'NUMBER'));
          // 月次更新フラグ
          colList.push(CreateColRow('is_update_monthly', 0, 'NUMBER'));
          // 月次年月
          colList.push(CreateColRow('monthly_month_year', this.monthlyMonthYear, 'NUMBER'));
          // 締日更新フラグ
          colList.push(CreateColRow('is_update_closing_date', 0, 'NUMBER'));
          // 締次年月
          colList.push(CreateColRow('closing_month_year', 0, 'NUMBER'));
          // 受注番号
          colList.push(CreateColRow('order_receive_id', this.orderReceiveId, 'NUMBER'));
          if (i == this.productList.length) {
            // 受注行番号
            colList.push(CreateColRow('order_receive_row', 0, 'NUMBER'));
            // 発注番号
            colList.push(CreateColRow('order_id', 0, 'NUMBER'));
            // 発注行番号
            colList.push(CreateColRow('order_row', 0, 'NUMBER'));
          } else {
            // 受注行番号
            colList.push(CreateColRow('order_receive_row', this.productList[i].OrderReceiveRow, 'NUMBER'));
            // 発注番号
            colList.push(CreateColRow('order_id', this.productList[i].OrderId, 'NUMBER'));
            // 発注行番号
            colList.push(CreateColRow('order_row', this.productList[i].OrderRow, 'NUMBER'));
          }
          // 注文番号
          colList.push(CreateColRow('order_number', 0, 'NUMBER'));
          // 注文行番号
          colList.push(CreateColRow('order_number_row', 0, 'NUMBER'));
          // 組合CD発注取引先
          colList.push(CreateColRow('union_cd_order_client', 0, 'NUMBER'));
          // 仕入単価ﾁｪｯｸﾌﾗｸﾞ
          colList.push(CreateColRow('is_check_purchase_price', 0, 'NUMBER'));
          // 作成ユーザー
          colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
          if (bulkInsertSql == '') {
            bulkInsertSql += 'INSERT INTO t_cumulative_transaction (' + CreateInsertSql(colList, 'col', 't_cumulative_transaction') + ') VALUES ';
          } else {
            bulkInsertSql += ',';
          }
          bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_cumulative_transaction') + ')';
          if (bulkInsertSql.length >= Const.SqlMaxLength) {
            this.transactSqlList.push({sql: bulkInsertSql, forUpdateFlg: 0});
            bulkInsertSql = '';
          }
        }
      }
      if (bulkInsertSql != '') {
        //console.log(bulkInsertSql);
        this.transactSqlList.push({sql: bulkInsertSql, forUpdateFlg: 0});
      }
    },
    /* 在庫マスタ更新SQL作成 */
    updateStocksMaster: function() {
      //console.log('在庫マスタ更新SQL作成');
      let colList = [];
      for (let i = 0; i < this.stockList.length; i++) {
        // 初期値から数量が変更されている製品のみ
        if (this.stockList[i].Count - this.stockList[i].PreCount != 0) {
          colList = [];
          // 残高数
          colList.push(CreateColRow('balance', 'balance - ' + (this.stockList[i].Count - this.stockList[i].PreCount).toString(), 'NUMBER'));
          // 月次売上出庫数
          colList.push(CreateColRow('monthly_salse_ship', 'monthly_salse_ship + ' + (this.stockList[i].Count - this.stockList[i].PreCount).toString(), 'NUMBER'));
          // 更新日
          colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
          /* WHERE句 */
          let where_clause = ' WHERE ';
          where_clause += ' month_year = ' + this.controlMasterData.processMonthYear + ' ';
          where_clause += 'AND office_id = ' + this.selectSalesOffice + ' ';
          where_clause += 'AND product_id = ' + this.stockList[i].ProductCode + ' ';
          let updateSql = CreateUpdateSql(colList, 'm_stocks') + where_clause;
          //console.log(updateSql);
          this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
        }
      }
    },
    /* 納品書PDF出力 */
    outputSalesDirectPdf: function() {
      // 得意先元帳PDF出力処理
      //console.log('納品書PDF出力処理');
      let query = {
        receivedOrderId: 0,
        billingNo: this.billingNo,
        monthlyMonthYear: this.monthlyMonthYear,
      };
      let route = this.$router.resolve({ name: 'SALES-DIRECT-NOUHIN', query: query});
      this.newWin.location = route.href
    },
    // キャンセルボタン
    cancel: function() {
      this.$router.push({ name: 'SALES-LIST'});
    },
    // 担当者検索
    showStaffSearchModal: function() {
      this.$bvModal.show('staffSearchModal');
    },
    // 担当者検索モーダルを閉じた時
    closeStaffSearchModal: function(staffItems) {
      //console.log(staffItems);
      // モーダルから渡された値の有無チェック
      if (typeof staffItems != 'undefined') {
        this.orderReceiveStaff.id = staffItems.detail.staff_id;
        this.orderReceiveStaff.text = staffItems.detail.staff_id + '：' + staffItems.detail.staff_name_kanji;
      }
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      const functionName = 'searchClient';
      const result = await searchClientModal(client_id, this.propClientClass, functionName, MODULE_NAME);
      if (typeof result != 'undefined') {
        await this.closeClientSearchModal(result);
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6) {
          // 取引先クリア
          await this.closeClientSearchModal({detail:{}});
        }
      }
    },
    // 取引先検索
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先検索モーダルを閉じた時
    async closeClientSearchModal(clientItems) {
      //console.log(clientItems);
      // モーダルから渡された値の有無チェック
      if (typeof clientItems != 'undefined') {
        // 取引先（得意先）から呼出
        if (clientItems.detail.closing_date == 99) {
          this.suppliers.closingDate = formatDateCalc(('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01', 0, 0, 0, true);
        } else {
          this.suppliers.closingDate = formatDate(('000000' + this.controlMasterData.processMonthYear).slice(-6) + ('00' + clientItems.detail.closing_date).slice(-2));
        }
        this.suppliers.closingDay = clientItems.detail.closing_date;
        this.suppliers.code = clientItems.detail.client_id;
        this.suppliers.name = clientItems.detail.client_name_kanji;
        this.suppliers.address1 = clientItems.detail.address_1;
        this.suppliers.address2 = clientItems.detail.address_2;
        this.suppliers.department1 = clientItems.detail.department_1;
        this.suppliers.department2 = clientItems.detail.department_2;
        this.suppliers.tel = clientItems.detail.phone_number;
        this.suppliers.personInChargeId = clientItems.detail.staff_id;
        this.suppliers.personInCharge = clientItems.detail.staff_id + '：' + clientItems.detail.staff_name_kanji;
        this.suppliers.zipCode = clientItems.detail.zip_code;
        this.suppliers.class = clientItems.detail.client_class;
        this.suppliers.sundriesClass = clientItems.detail.sundries_class;
        this.suppliers.firstDigit = clientItems.detail.client_id_first_digit;
        this.suppliers.taxClass = clientItems.detail.sales_tax_class;
        this.suppliers.taxCalculationClass = clientItems.detail.tax_calculation_class;
        this.suppliers.taxFracClass = clientItems.detail.tax_fractionation_class;
        this.suppliers.check = clientItems.detail.check_contents;
        // 現場クリア
        this.siteClear();
        // 単価をリフレッシュ
        await this.refreshSalesAmount();
      }
    },
    /* 取引先照会 */
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    // 製品コード直接入力 - 製品検索
    async searchProduct(product_id, index) {
      const functionName = 'searchProduct';
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.selectSalesOffice);
      this.productSearchProp.client_id = this.suppliers.code;
      this.productSearchProp.client_class = this.suppliers.class;
      this.productSearchProp.client_name = this.suppliers.name;
      this.productSearchProp.kijunDate = this.dateBillingDate;
      const result = await searchProductsModal(product_id, this.productSearchProp, functionName, MODULE_NAME);
      this.dataIndex = index;
      if (typeof result != 'undefined') {
        this.closeProductSearchModal(result);
      } else {
        // 製品コード8桁かつデータ取得失敗の場合
        if (product_id.length == 8) {
          // 製品コードクリア
          this.productList[index].ProductCode = '';
        }
      }
    },
    // 製品検索
    showProductSearchModal: function(index) {
      //console.log('製品検索モーダル');
      //console.log(index);
      this.dataIndex = index;
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.selectSalesOffice);
      this.productSearchProp.client_id = this.suppliers.code;
      this.productSearchProp.client_class = this.suppliers.class;
      this.productSearchProp.client_name = this.suppliers.name;
      this.productSearchProp.kijunDate = this.dateBillingDate;
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    closeProductSearchModal: function(productItems) {
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if (typeof productItems != 'undefined') {
        let focusFlg = false;
        if (productItems.length == undefined) {
          // 選択ボタン押下でクローズ
          this.setProductSearchModal(this.dataIndex, productItems.detail);
          focusFlg = true;
        } else {
          // 一括選択ボタン押下でクローズ
          if (productItems.length == 1) {
            // 1件の場合のみフォーカスを数量に当てる
            focusFlg = true;
          }
          for (let i = 0; i < productItems.length; i++) {
            if (i == 0) {
              this.setProductSearchModal(this.dataIndex, productItems[i].detail);
            } else {
              this.productList.splice(this.dataIndex + i, 0, this.createNewRow());
              this.setProductSearchModal(this.dataIndex + i, productItems[i].detail);
            }
          }
        }
        // 合計金額、合計仕入額、粗利率を計算
        this.calcTotal();
        // 最終行が空行でない場合は空行追加
        if (this.isListBlankRow(this.productList.length - 1) == false) {
          this.addTableRow();
        }
        if (focusFlg == true) {
          // 製品検索を行った行の数量にフォーカスを移動
          setTimeout(() => {
            if (this.dataIndex < this.productList.length) {
              this.setFocus('productQuantity' + this.dataIndex);
            }
          }, 500);
        }
      }
    },
    // 製品検索モーダルの返却値設定
    async setProductSearchModal(index, productItemsDetail) {
      // 製品コード
      this.productList[index].ProductCode = productItemsDetail.product_id;
      // 製品分類コード
      this.productList[index].ProductClass = productItemsDetail.product_class_id;
      // 製品名（漢字）
      this.productList[index].ProductName = productItemsDetail.product_name_kanji;
      this.productList[index].ProductTaxRateClass = productItemsDetail.product_tax_rate_class_sales;
      // サービス区分
      this.productList[index].ServiceClass = '';
      // 数量
      if (this.selectBillClass == Const.Tradition.salesReturn) {
        // 返品の場合
        // 数量
        this.productList[index].Quantity = -1;
      } else {
        // 返品以外の場合
        // 数量
        this.productList[index].Quantity = 1;
      }
      // 単位
      this.productList[index].Unit = productItemsDetail.unit;
      // 売上単価
      if (productItemsDetail.cp_sales_unit_price != null) {
        this.productList[index].SellingPrice = productItemsDetail.cp_sales_unit_price;
        this.productList[index].ClientAmountClass = '*';
      } else {
        this.productList[index].SellingPrice = productItemsDetail.sales_unit_price;
        this.productList[index].ClientAmountClass = '';
      }
      // 仕入単価
      if (productItemsDetail.is_9A == 0) {
        // 9A製品以外の場合は原価単価
        this.productList[index].PurchasePrime = productItemsDetail.cost_price;
      } else {
        // 9A製品の場合は仕入単価
        this.productList[index].PurchasePrime = productItemsDetail.purchase_price;
      }
      // 諸口区分
      this.productList[index].SundriesClass = productItemsDetail.sundries_class;
      // 在庫管理区分
      this.productList[index].InventoryClass = productItemsDetail.inventory_control_class;
      // ケース換算区分
      this.productList[index].CaseConversion = productItemsDetail.case_conversion_class,
      // 最低発注数
      this.productList[index].MinimumQuantity = productItemsDetail.minimum_quantity;
      // 合計仕入単価
      this.productList[index].TotalPurchasePrime = this.productList[index].Quantity * this.productList[index].PurchasePrime;
      // 金額
      this.productList[index].Money = this.productList[index].Quantity * this.productList[index].SellingPrice;
      // 受注番号
      this.productList[index].OrderReceiveRow = 0;
      // 発注番号
      this.productList[index].OrderId = 0;
      // 発注行番号
      this.productList[index].OrderRow = 0;
      // 在庫管理区分が「0:する」の場合
      if (this.productList[index].InventoryClass == Const.InventoryControlClassDef.inventory) {
        // 製品一覧に追加
        this.addStockList(this.productList[index].ProductCode, 0);
      }
    },
    // 納品指定区分変更時
    inputDeliveryNote: function() {
      if (this.selectDeliveryDesignation != '') {
        // 区分値を納品指定に設定
        this.deliveryNote = getListValue(this.deliveryDesignation, this.selectDeliveryDesignation);
      }
    },
    // 現場検索モーダルを開く
    showSiteSearchModal: function() {
      //console.log('現場検索モーダル');
      this.siteSearchInfo.client_id = this.suppliers.code;
      this.siteSearchInfo.client_name = this.suppliers.name;
      this.$bvModal.show('siteSearchModal');
    },
    // 現場コード直接入力 - 現場検索
    async searchSite(site_id) {
      const functionName = 'searchSite';
      const client_id = this.suppliers.code;
      const result = await searchSiteModal(site_id, client_id, functionName, MODULE_NAME);
      if (typeof result != 'undefined') {
        // 現場情報セット
        this.closeSiteSearchModal(result);
      } else {
        // 現場クリア
        this.siteClear();
      }
    },
    // 現場検索モーダルを閉じた時
    closeSiteSearchModal: function(siteItems) {
      //console.log(siteItems);
      // モーダルから渡された値の有無チェック
      if (typeof siteItems != 'undefined') {
        this.onSite.deliveryCode = siteItems.detail.shipping_code;
        this.onSite.code = siteItems.detail.site_id;
        this.onSite.name = siteItems.detail.client_site_name_kanji;
        this.onSite.zipCode = siteItems.detail.client_site_zip_code;
        this.onSite.address1 = siteItems.detail.client_site_address_1;
        this.onSite.address2 = siteItems.detail.client_site_address_2;
        this.onSite.deliveryPlace = siteItems.detail.delivery_location;
        this.onSite.tel = siteItems.detail.client_site_phone_number;
        this.onSite.clientPrintClass = siteItems.detail.order_print_class;
        this.onSite.clientPrintName = siteItems.detail.order_print_client_name;
        this.onSite.clientPrintNameText = this.onSite.clientPrintClass == 0 ? '' : siteItems.detail.order_print_client_name;
      }
    },
    // 現場クリア
    siteClear: function() {
      //console.log('現場クリア');
      this.onSite.deliveryCode = '';
      this.onSite.code = '';
      this.onSite.name = '';
      this.onSite.zipCode = '';
      this.onSite.address1 = '';
      this.onSite.address2 = '';
      this.onSite.deliveryPlace = '';
      this.onSite.tel = '';
      this.onSite.clientPrintClass = '';
      this.onSite.clientPrintName = '';
      this.onSite.clientPrintNameText = '';
    },
    // 在庫照会モーダルを開く
    openStockModal: function() {
      //console.log('在庫照会モーダル');
      //console.log(this.$bvModal);
      this.stockInfo.office_id = this.selectSalesOffice.toString();
      this.stockInfo.product_id = [];
      for (let i = 0; i < this.productList.length; i++) {
        if (this.productList[i].ProductCode != null) {
          this.stockInfo.product_id.push(this.productList[i].ProductCode.toString());
        }
      }
      this.$bvModal.show('stockModal');
    },
    // 取引先製品単価履歴照会モーダルを開く
    clickInquryClientProduct: function() {
      //console.log('取引先製品単価履歴照会モーダル');

      let productIdCsv = '';
      for (let i = 0; i < this.productList.length; i++) {
        if (getNullStr(this.productList[i].ProductCode) != '' && isNaN(getNullStr(this.productList[i].ProductCode)) == false) {
          if (productIdCsv != '') {
            productIdCsv += ',';
          }
          // 製品コード
          productIdCsv += this.productList[i].ProductCode;
        }
      }
      this.clientProductProp.clientClass = this.suppliers.class;
      this.clientProductProp.clientId = this.suppliers.code;
      this.clientProductProp.clientNameKanji = this.suppliers.name;
      this.clientProductProp.productIdCsv = productIdCsv;

      this.$bvModal.show('clientProductInquiryModal');
    },
    // 再計算イベント
    calc: function(index) {
      // 対象行の項目計算
      this.productList[index].TotalPurchasePrime = this.productList[index].Quantity * this.productList[index].PurchasePrime;
      this.productList[index].Money = this.productList[index].Quantity * this.productList[index].SellingPrice;
      // 合計金額、合計仕入単価、粗利計算
      this.calcTotal();
    },
    // 合計金額、合計仕入単価、粗利計算
    calcTotal: function() {
      this.totalFee = 0;
      this.totalPurchasePrime = 0;
      this.grossProfit = 0;
      let moneySubTotalNormal = 0;
      let moneySubTotalLight = 0;
      for (let i = 0; i < this.productList.length;i++) {
        this.totalFee += this.productList[i].Money;
        this.totalPurchasePrime += this.productList[i].TotalPurchasePrime;
        if (this.productList[i].ProductTaxRateClass == Const.ProductTaxRateClass.normalTax) {
          moneySubTotalNormal += this.productList[i].Money;
        } else if (this.productList[i].ProductTaxRateClass == Const.ProductTaxRateClass.lightTax) {
          moneySubTotalLight += this.productList[i].Money;
        }
      }
      this.subTotalNormal = moneySubTotalNormal;
      this.subTotalLight = moneySubTotalLight;
      this.grossProfit = Math.round((this.totalFee - this.totalPurchasePrime) * 1000 / this.totalFee) / 10;
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = this.createNewRow();
      this.productList.push(newProduct);
    },
    // 行作成
    createNewRow: function() {
      let newProduct = {
        ProductCode: null,
        ProductClass: null,
        ProductName: '',
        ProductTaxRateClass: Const.ProductTaxRateClass.normalTax,
        ServiceClass: '',
        Quantity: 0,
        Unit: '',
        SellingPrice: 0,
        ClientAmountClass: '',
        PurchasePrime: 0,
        SundriesClass: null,
        InventoryClass: null,
        CaseConversion: Const.CaseConversionClassDef.conversion,
        MinimumQuantity: 0,
        TotalPurchasePrime: 0,
        Money: 0,
        OrderReceiveRow: 0,
        OrderId: 0,
        OrderRow: 0,
      };
      return newProduct;
    },
    // テーブル行削除イベント
    deleteBtn: function(rowNum) {
      //console.log(rowNum);
      if (this.productList.length > 1) {
        this.productList.splice(rowNum,1);
        // 合計金額、合計仕入単価、粗利計算
        this.calcTotal();
      }
    },
    // 在庫一覧追加
    addStockList: function(productCode, preCount) {
      for (let i = 0; i < this.stockList.length; i++) {
        if (this.stockList[i].ProductCode == productCode) {
          this.stockList[i].PreCount += preCount;
          return;
        }
      }
      let stock = {
        ProductCode: productCode,
        PreCount: preCount,
        Count: 0,
      };
      this.stockList.push(stock);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* 更新日取得用SELECT文作成 */
    async createUpdatedSelect() {
      let selectSql = '';
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(cumulative_transaction.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      selectSql += 'AND cumulative_transaction.billing_no = ' + this.billingNo + ' ';
      selectSql += 'AND cumulative_transaction.monthly_month_year = ' + this.monthlyMonthYear + ' ';
      this.listUpdatedSelect.push({select: selectSql});

      let dataResult = null;
      for (let i = 0; i < this.listUpdatedSelect.length; i++) {
        dataResult = await executeSelectSql(this.listUpdatedSelect[i].select);
        if (dataResult != null && dataResult.length > 0) {
          this.listUpdatedSelect[i].initUpdated = formatDate(dataResult[0].updated, 'YYYY-MM-DD HH:mm:ss');
        } else {
          this.listUpdatedSelect[i].initUpdated = '0000-01-01 00:00:00';
        }
      }
    },
    // 一覧の指定行が空かどうかチェック
    isListBlankRow: function(index) {
      if (getNullStr(this.productList[index].ProductCode) == '') {
        return true;
      } else {
        return false;
      }
    },
    // 一覧の後ろの空行を削除
    delListLastBlankRow: function() {
      //console.log('空行削除開始');
      for (let i = this.productList.length - 1; i > 0; i--) {
        if (this.isListBlankRow(i) == true) {
          // 後ろにある空行は削除
          this.productList.splice(i, 1);
        } else {
          // 1件でも空行以外の行があった場合は削除処理終了
          break;
        }
      }
      // 最後に合計金額、合計仕入単価、粗利計算
      this.calcTotal();
      //console.log('空行削除終了');
    },
    // 売価をリフレッシュ
    async refreshSalesAmount() {
      const functionName = 'refreshSalesAmount';
      if (this.isUnitPrice == false) {
        // 単価適用チェックがOFFの場合はリフレッシュしない
        return;
      }
      if (getNullStr(this.dateBillingDate) != '') {
        this.$store.commit('setLoading', true);
        try {
          await refreshSalesAmountProductList(this.productList, this.suppliers.class, this.suppliers.code, this.dateBillingDate, 'ProductCode', true, 'SellingPrice', 'ClientAmountClass', true);
          for (let i = 0; i < this.productList.length; i++) {
            this.productList[i].Money = this.productList[i].Quantity * this.productList[i].SellingPrice;
          }
          // 合計金額、合計仕入単価、粗利
          this.calcTotal();
        } catch (error) {
          console.log(error);
          await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        }
        if (this.getMessageFlg == true) {
          scrollTo(0,0);
        }
        this.$store.commit('setLoading', false);
      }
    },
    // 締日よりも過去日確認
    async confirmOldClosingDate() {
      // 売上計上日（伝票日付）が締日よりも過去日かチェック
      if (checkOldClosingDate(this.dateBillingDate, this.suppliers.closingDate) == false) {
        // 売上計上日が締日よりも過去日
        let confirmMessage = '既に締日を過ぎた伝票です。請求済みと思われますが、問題はないですか？';
        let options = {
          title: '警告',
          bodyBgVariant: 'warning',
          headerBgVariant: 'warning',
          footerBgVariant: 'danger',
        };
        if (await this.$bvModal.msgBoxConfirm(confirmMessage, options) == true) {
          return true;
        } else {
          return false;
        }
      } else {
        // 売上計上日が締日と同じ、または、未来
        return true;
      }
    },
    // サービス変更
    async changeService(index) {
      const functionName = 'changeService';
      try {
        if (this.productList[index].ServiceClass != '') {
          // サービス区分が空白以外
          this.productList[index].SellingPrice = 0;
          this.productList[index].Money = 0;
        } else {
          // サービス区分が空白
          if (getNullStr(this.dateBillingDate) != '') {
            // 受注日付が設定されている場合のみ
            if (getNullStr(this.productList[index].ProductCode) != '' && isNaN(getNullStr(this.productList[index].ProductCode)) == false) {
              // 製品コードが数値の場合のみ
              this.$store.commit('setLoading', true);
              // マスタに登録されている製品の売価を取得
              let selectSqlQuery = 'SELECT';
              selectSqlQuery += ' product_id';
              selectSqlQuery += ',sales_unit_price';
              selectSqlQuery += ' FROM ';
              selectSqlQuery += 'm_clients_products ';
              selectSqlQuery += ' WHERE ';
              selectSqlQuery += 'client_class = ' + this.suppliers.class + ' ';
              selectSqlQuery += 'AND client_id = ' + this.suppliers.code + ' ';
              selectSqlQuery += 'AND product_id = ' + this.productList[index].ProductCode + ' ';
              selectSqlQuery += 'AND unit_price_effective_date <= \'' + this.dateBillingDate + '\' ';
              selectSqlQuery += 'ORDER BY unit_price_effective_date DESC ';
              selectSqlQuery += 'LIMIT 1 ';
              // SELECT文
              let selectSql = 'SELECT';
              selectSql += ' products.product_id';
              selectSql += ',IfNull(clients_products_QUERY.sales_unit_price,products.sales_unit_price) AS sales_unit_price';
              selectSql += ' FROM ';
              selectSql += 'm_products AS products ';
              selectSql += 'LEFT JOIN (' + selectSqlQuery + ') AS clients_products_QUERY ';
              selectSql += 'ON clients_products_QUERY.product_id = products.product_id ';
              selectSql += ' WHERE ';
              selectSql += 'products.product_id = ' + this.productList[index].ProductCode + ' ';
              //console.log(selectSql);
              let resultData = await executeSelectSql(selectSql);
              //console.log(resultData);
              if (resultData != null) {
                this.productList[index].SellingPrice = resultData[0].sales_unit_price;
                this.productList[index].Money = this.productList[index].Quantity * this.productList[index].SellingPrice;
              }
            }
          }
        }
        // 合計金額、合計仕入単価、粗利
        this.calcTotal();
      } catch (error) {
        console.log(error);
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
  }
}
</script>
<style scoped>
</style>