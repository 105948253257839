<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
                  <strong> 仕入入力（在庫なし）修正</strong>
                  <b-form-text class="text-muted d-inline-flex">(編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください)</b-form-text>
                </h5>
                <b-button-toolbar>
                  <b-button variant="success" pill v-b-tooltip.hover.noninteractive.bottom="'仕入入力（在庫なし）に遷移する'" @click="clickInputBtn" class="m-0">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="clickInputBtn" class="d-none"></button>
                  </b-button>&nbsp;&nbsp;
                  <b-button pill v-b-tooltip.hover.noninteractive.bottom="'仕入一覧に戻る'" @click="cancel" class="btn-cancel m-0">
                    <span class="oi oi-circle-x"></span> キャンセル
                  </b-button>
                </b-button-toolbar>
              </div>
            </b-media-body>
          </b-media>
          <div class="main-card card">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveOrder();" id="receivedOrderInputForm" class="form-horizontal">
                  <b-row>
                    <!-- 伝票番号 -->
                    <b-col lg="3">
                      <b-form-group
                        label="伝票番号"
                        label-for="billingNo"
                      >
                        <b-form-input id="billingNo" type="text" v-model="billingNo" readonly />
                      </b-form-group>
                    </b-col>
                    <!-- 営業所コードプルダウン -->
                    <b-col lg="3">
                      <b-form-group
                        label="営業所"
                        label-for="selectSalesOffice"
                      >
                        <b-form-input id="selectSalesOffice" type="text" v-model="selectSalesOfficeText" readonly />
                      </b-form-group>
                    </b-col>
                    <!-- 伝票日付 -->
                    <b-col lg="3">
                      <validation-provider name="dateBillingDate" :rules="{required: true, oldProcessMonthYear: controlMasterData.processMonthYear}" v-slot="{ classes,errors }">
                        <b-form-group
                          label="伝票日付"
                          label-for="dateBillingDate"
                        >
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-input-group class="input-daterange">
                                <b-form-datepicker
                                  id="dateBillingDate"
                                  name="dateBillingDate"
                                  v-model="dateBillingDate"
                                  calendar-width="50%"
                                ></b-form-datepicker>
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- 伝票種別区分プルダウン -->
                    <b-col lg="3">
                      <b-form-group
                        label="伝票種別"
                        label-for="selectBillClass"
                      >
                        <b-input-group>
                          <b-form-select
                            id="selectBillClass"
                            v-model="selectBillClass"
                            :options="purchaseBillClassList"
                            value-field="id"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <!-- 取引先コード -->
                    <b-col lg="6">
                      <validation-provider name="suppliersCode" :rules="{numeric: true, min: 6, max: 6, required: true, client_sundries_class: suppliers.sundriesClass}" v-slot="{ classes,errors }">
                        <label for="suppliersCode">取引先コード<b-form-text class="text-muted d-inline-flex">(変更される場合、現場や製品等の関連項目が自動でクリアされます)</b-form-text></label>
                        <b-row :class="classes">
                          <b-col lg="8">
                            <b-input-group>
                              <b-form-input
                                type="tel"
                                id="suppliersCode"
                                name="suppliersCode"
                                @input="searchClient(suppliers.code)"
                                maxlength="6"
                                v-model="suppliers.code"
                              />
                              <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                  <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                                </b-button>
                              </b-input-group-text>
                              <b-button class="mr-1" size="sm" pill variant="success" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが空欄以外の場合、利用可能です。）" :disabled="suppliers.code == 0" @click="clientInfoModal(suppliers.class, suppliers.code)">
                                <span class="oi oi-eye"></span> 情報
                              </b-button>
                            </b-input-group>
                          </b-col>
                          <b-col lg="8" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-row>
                      </validation-provider>
                    </b-col>
                     <!-- 注文番号 -->
                    <b-col lg="3" v-if="orderNumber!=0">
                      <b-form-group
                        label="注文番号"
                        label-for="orderNumber"
                      >
                        <b-form-input id="orderNumber" type="text" v-model="orderNumber" readonly />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 取引先名 -->
                    <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9 form-group pl-3 pr-2">
                      <label class="form-label"><strong>取引先名: </strong>{{suppliers.name}}</label>
                    </div>
                    <!-- 締日 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group pl-3 pr-2">
                      <label class="form-label"><strong>締日: </strong>{{suppliers.closingDate}}</label>
                    </div>
                  </b-row>
                  <b-row class="mt-1">
                    <!-- 郵便番号 + 取引先住所１ + 取引先住所２ -->
                    <div class="col-12 form-group pl-3 pl-3 pr-2">
                      <label class="form-label"><strong>取引先住所: </strong>{{suppliers.zipCode}} {{suppliers.address1}} {{suppliers.address2}}</label>
                    </div>
                  </b-row>
                  <b-row class="mt-1">
                    <!-- 取引先部署１ + 取引先部署２ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group pl-3 pr-2">
                      <label class="form-label"><strong>部署: </strong>{{suppliers.department1}} {{suppliers.department2}}</label>
                    </div>
                    <!-- 取引先TEL -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group pl-3 pr-2">
                      <label class="form-label"><strong>TEL: </strong>{{suppliers.tel}}</label>
                    </div>
                    <!-- FAX -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group pl-3 pr-2">
                      <label class="form-label"><strong>FAX: </strong>{{suppliers.fax}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 製品 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :small="true"
                            :items="productList"
                            :fields="fields"
                          >
                            <template #table-caption>
                              <b-btn-toolbar>
                                <span class="mt-2 mr-2">&nbsp;製品テーブル</span> 
                                <!-- 行追加ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" type="button" @click="addTableRow()">
                                  <span class="oi oi-plus"></span> 行追加
                                </b-button>&nbsp;
                                <!-- 一括削除ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" type="button" @click="bulkDeleteBtn()">
                                  <span class="oi oi-delete"></span> 一括削除
                                </b-button>
                              </b-btn-toolbar>
                            </template>
                            <template #head(Sort)>
                              移<br>動
                            </template>
                            <template #head(Check)>
                              削除<b-check v-model="isAllChecked" @change="clickCheckAll" />
                            </template>
                            <template #cell(Sort)="data">
                              <div                                
                                style="cursor: pointer; height: 100%; width: 100%"
                                draggable
                                @dragstart="onRowMoveDragstart($event, data.index)"
                                @drop="onRowMoveDrop($event, data.index)"
                                @dragover.prevent
                                @dragenter.prevent
                              >
                                <span class="oi oi-move"></span>
                              </div>
                            </template>
                            <template #cell(Check)="data">
                              <b-check v-model="checkedIndexs" :value="data.index" @change="changeCheck"/>
                            </template>
                            <template #cell(ProductCode)="data">
                              <validation-provider :rules="{required: true, numeric: true, min: 8, max: 8, case_conversion_class: {caseConversionClass: data.item.CaseConversion, checkCaseFlg: false}}" v-slot="{ classes,errors }">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-input-group style="width: 125px;">
                                      <b-form-input
                                        type="text"
                                        name="productId"
                                        size="sm"
                                        class="px-1"
                                        @input="searchProduct(data.item.ProductCode, data.index)"
                                        maxlength="8"
                                        :readonly="!checkClientId(suppliers.code)"
                                        v-model="data.item.ProductCode"
                                      />
                                      <b-input-group-text @click="showProductSearchModal(data.index)" v-if="checkClientId(suppliers.code)">
                                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                          <span class="oi oi-magnifying-glass"></span>
                                        </b-button>
                                      </b-input-group-text>
                                    </b-input-group>
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </validation-provider>
                            </template>
                            <template #cell(ProductName)="data">
                              <validation-provider rules="max:35" v-slot="{ classes,errors }">
                                <b-input-group style="width: 220px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <span style="font-size: 20px;" v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark : '' }}</span>
                                        <b-form-input size="sm" class="px-1" type="text" name="productName" v-model="data.item.ProductName" :readonly="data.item.SundriesClass != 1" v-b-tooltip.hover.noninteractive.right="data.item.ProductName" />
                                      </b-input-group>
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(Note)="data">
                              <validation-provider rules="max:30" v-slot="{ classes,errors }">
                                <b-input-group style="width: 200px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="text" name="productNote" v-model="data.item.Note" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(ServiceClass)="data">
                              <b-input-group style="width: 110px;">
                                <b-form-select
                                  size="sm" 
                                  class="px-1"
                                  v-model="data.item.ServiceClass"
                                  :options="serviceList"
                                  value-field="id"
                                  @change="changeService(data.index)"
                                />
                              </b-input-group>
                            </template>
                            <template #cell(Quantity)="data">
                              <validation-provider :rules="{required: true, purchase_between: {billClass: selectBillClass, productSundriesClass: data.item.SundriesClass}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 70px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="number" :id="'productQuantity' + data.index" name="productQuantity" v-model.number="data.item.Quantity" @blur="calc(data.index)" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(Unit)="data">
                              <validation-provider rules="max:5" v-slot="{ classes,errors }">
                                <b-input-group style="width: 45px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="text" name="productUnit" v-model="data.item.Unit" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(PurchasePrime)="data">
                              <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                                <b-input-group style="width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="number" name="productPurchasePrime" v-model.number="data.item.PurchasePrime" @blur="calc(data.index)" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(PurchasePrimeMaster)="data">
                              {{ data.item.PurchasePrimeMaster.toLocaleString() }}
                            </template>
                            <template #cell(TotalPurchasePrime)="data">
                              {{ data.item.TotalPurchasePrime.toLocaleString() }}
                              <validation-provider rules="between:-99999999,99999999" v-slot="{ classes,errors }">
                                <b-input-group style="width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <input type="hidden" name="TotalPurchasePrime" v-model.number="data.item.TotalPurchasePrime" />
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(delete)="data">
                              <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'この行を削除をします。'" @click="deleteBtn(data.index)" class="mr-1">
                                <span class="oi oi-delete"></span> 削除
                              </b-button>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form>
                            <b-form-text label-align="left" class="text-muted">取引先を指定すると製品の検索入力可能になります。<br>直送の場合、発注先の変更が可能です。</b-form-text>
                          </b-form>
                        </b-col>
                        <b-col>
                          <b-form>
                            <b-form-group :label="textTotalMoneyText" label-align="right"></b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 摘要 -->
                    <b-col>
                      <validation-provider name="billingSummary" rules="max:40" v-slot="{ classes, errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="billingSummary">
                              <b-input-group-text  class="px-2">
                                <strong class='text-body'>摘要 </strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input 
                            type="text" 
                            id="billingSummary" 
                            :class="{'border-danger': errors[0]}"
                            name="billingSummary" 
                            v-model="billingSummary" 
                            maxlength="40" 
                            v-shortkey="['alt', '2']"
                            @shortkey="setFocus('billingSummary')"
                            v-b-tooltip.hover.noninteractive
                            title="「ALT+2」ボタンで移動可能"
                          />
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <!-- 保存ボタン -->
                  <b-row class="justify-content-md-center">
                    <b-col lg="2">
                      <b-button pill variant="success" type="submit" form="receivedOrderInputForm" class="btn btn-block" :disabled="statusFlg.editFlg==false" @click="delListLastBlankRow"><span class="oi oi-circle-check"></span> 保存</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●取引先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="htmlConst.ClientClass.supplier"/>
    <!-- ●●●取引先照会モーダル●●● -->
    <CLIENTINQUIRY :clientProp="propClientInquiry"/>
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :product-search-prop="productSearchProp"/>
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '@/store';
import { searchClientModal, searchProductsModal } from '@/assets/js/searchModal.js';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, getControlMaster, checkClientId, dateConsistency, formatDate, formatDateCalc, getListValue, getNullStr, calcTaxNew, escapeQuote, executeSelectSql, CreateInsertSql, CreateColRow, executeTransactSqlList, isSystemEditable, isAfterMonthlyUpdate, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
const MODULE_NAME = 'purchase-edit';
export default {
  name: 'PURCHASE-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    PRODUCTSEARCH,
    CLIENTSEARCH,
    CLIENTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '仕入入力（在庫なし）修正',
      loginId: '',
      // 取引先コード確認関数
      checkClientId: checkClientId,
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOffice: null,
      selectSalesOfficeText: '',
      salesOffice: [],
      // 伝票日付
      dateBillingDate: '',
      // 仕入処理担当者
      orderReceiveStaff: {
        id: '',
        text: '',      
      },
      // 配送プルダウン
      selectDelivery: '',
      deliveryList: [],
      // 伝票種別
      selectBillClass: null,
      selectBillClassText: null,
      purchaseBillClassList: [],
      // 取引先系
      suppliers:{
        closingDate: '',
        code: '',
        name: '',
        class: '',
        address1: '',
        address2: '',
        zipCode: '',
        department1: '',
        department2: '',
        tel: '',
        fax: '',
        sundriesClass: '',
        firstDigit: '',
        taxClass: '',
        taxCalculationClass: '',
        taxFracClass: '',
      },
      // 伝票摘要
      billingSummary: '',
      // テーブル定義
      productList: [],
      dataIndex: 0,
      totalPurchasePrime: 0,
      subTotalNormal: 0,
      subTotalLight: 0,
      // サービス区分
      serviceList: [],
      // 製品検索情報
      productSearchProp: {
        office_id: '',
        office_name: '',
        client_id: '',
        client_class: '',
        client_name: '',
        multiSelectFlg: '1',
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        lightTaxMark: '',
      },
      // ステータスフラグ
      statusFlg: {
        editFlg: true,
      },
      // トランザクションSQLリスト
      transactSqlList:[],
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 確認ダイアログ用
      confirmMessage: [],
      // 注文番号
      orderNumber: 0,
      // パラメータ
      billingNo: 0,
      monthlyMonthYear: 0,
      parentKbn: 0,
      // 各行のチェック状態
      checkedIndexs: [],
      isAllChecked: false,
    }
  },
  computed: {
    fields: function() {
      return [
        {
          key: 'Sort',
          tdClass: 'text-center align-middle',
        },
        {
          key: 'Check',
        },
        {
          key: 'ProductCode',
          label: '製品コード',
        },
        {
          key: 'ProductName',
          label: '製品名',
        },
        {
          key: 'Quantity',
          label: '数量',
        },
        {
          key: 'Unit',
          label: '単位',
        },
        {
          key: 'PurchasePrime',
          label: '仕入単価',
        },
        {
          key: 'PurchasePrimeMaster',
          label: '仕入単価（マスタ）',
          tdClass: 'text-right',
        },
        {
          key: 'TotalPurchasePrime',
          label: '金額',
          tdClass: 'text-right',
        },
        {
          key: 'Note',
          label: '備考',
        },
        {
          key: 'ServiceClass',
          label: 'サービス区分',
        },
        {
          key: 'delete',
          label: ' ',
        },
      ]
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 合計値のテキスト */
    textTotalMoneyText: function() {
      let retTotalMoneyText = '合計仕入単価：' + this.totalPurchasePrime.toLocaleString() + ' 円';
      return retTotalMoneyText;
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.billingNo = this.$route.query.billingNo;
    this.monthlyMonthYear = this.$route.query.monthlyMonthYear;
    this.parentKbn = this.$route.query.parentKbn;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 入力画面からの遷移の場合
        if (this.parentKbn == '1') {
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        }
        // 製品テーブル初期表示データ用意
        this.addTableRow();
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let serviceListData = null;
        let controlData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData, serviceListData, controlData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
          selectOneTable('m_service_classes'),
          getControlMaster(),
        ]);
        //console.log(staffListData);
        //console.log(officeListData);
        for (let i = 0; i < officeListData.length; i++) {
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.salesOffice.push(office);
        }
        // 営業所データ初期値セット
        for (let i = 0; i < this.salesOffice.length; i++) {
          //console.log(this.salesOffice[i].id);
          if (this.salesOffice[i].id == staffListData[0].office_id) {
            this.selectSalesOffice = this.salesOffice[i].id;
            this.selectSalesOfficeText = this.salesOffice[i].text;
            break;
          }
        }
        // 伝票種別プルダウン
        for (let i = 0; i < Const.TraditionList.length; i++) {
          // 「21:仕入」「22:返品」「24:直送」
          if (Const.TraditionList[i].id == Const.Tradition.purchaseNormal ||
          Const.TraditionList[i].id == Const.Tradition.purchaseReturn ||
          Const.TraditionList[i].id == Const.Tradition.purchaseDirect) {
            this.purchaseBillClassList.push(Const.TraditionList[i]);
          }
        }
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        //console.log(serviceListData);
        for(let i = 0; i < serviceListData.length; i++){
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // 現在処理年月
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.taxRate = controlData.tax_rate;
        this.controlMasterData.newTaxRate = controlData.new_tax_rate;
        this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
        this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
        this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 既存データ設定
        await this.setInitData();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      // 伝票日付にフォーカスを移動
      document.getElementById('dateBillingDate').focus();
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null) {
        await this.setResult(dataResult);
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索SQL作成（初期値） */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.office_id';
      selectSql += ',cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.client_class';
      selectSql += ',cumulative_transaction.client_id';
      selectSql += ',cumulative_transaction.bill_class';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.closing_date';
      selectSql += ',clients.zip_code';
      selectSql += ',clients.sundries_class';
      selectSql += ',clients.client_id_first_digit';
      selectSql += ',clients.sales_tax_class';
      selectSql += ',clients.tax_calculation_class';
      selectSql += ',clients.tax_fractionation_class';
      selectSql += ',clients.address_1';
      selectSql += ',clients.address_2';
      selectSql += ',clients.department_1';
      selectSql += ',clients.department_2';
      selectSql += ',clients.phone_number';
      selectSql += ',clients.fax_number';
      selectSql += ',cumulative_transaction.product_id';
      selectSql += ',cumulative_transaction.product_name AS product_name_kanji';
      selectSql += ',cumulative_transaction.product_note';
      selectSql += ',cumulative_transaction.service_class';
      selectSql += ',cumulative_transaction.product_class_id';
      selectSql += ',cumulative_transaction.product_quantity';
      selectSql += ',cumulative_transaction.product_unit AS unit';
      selectSql += ',cumulative_transaction.product_purchase_price';
      selectSql += ',products_details.purchase_price';
      selectSql += ',products.product_check_class';
      selectSql += ',cumulative_transaction.product_mouth_class';
      selectSql += ',cumulative_transaction.inventory_control_class';
      selectSql += ',products_details.case_conversion_class';
      selectSql += ',cumulative_transaction.billing_summary';
      selectSql += ',cumulative_transaction.order_number';
      selectSql += ',products.product_tax_rate_class_purchase';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON cumulative_transaction.client_class = clients.client_class ';
      selectSql += 'AND cumulative_transaction.client_id = clients.client_id ';
      selectSql += 'INNER JOIN m_products AS products ';
      selectSql += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql += 'INNER JOIN m_products_details AS products_details ';
      selectSql += 'ON cumulative_transaction.product_id = products_details.product_id ';
      selectSql += 'AND cumulative_transaction.office_id = products_details.office_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID
      selectSql += ' cumulative_transaction.transaction_id = \'' + Const.TransactionId.stocking + '\' ';
      // 引数の伝票番号で絞り込む
      selectSql += 'AND cumulative_transaction.billing_no = ' + this.billingNo + ' ';
      // 引数の月次年月で絞り込む
      selectSql += 'AND cumulative_transaction.monthly_month_year = ' + this.monthlyMonthYear + ' ';
      // 消費税行は出さない
      selectSql += 'AND cumulative_transaction.product_id <> 0 ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY cumulative_transaction.billing_row ';

      return selectSql;
    },
    async setResult(result) {
      if (result.length > 0) {
        // 営業所プルダウン
        for (let i = 0; i < this.salesOffice.length; i++) {
          if (result[0].office_id == this.salesOffice[i].id) {
            this.selectSalesOffice = result[0].office_id;
            this.selectSalesOfficeText = this.salesOffice[i].text;
          }
        }
        // 伝票日付
        this.dateBillingDate = result[0].billing_date;
        if (dateConsistency(this.controlMasterData.processMonthYear + '01', this.dateBillingDate) == false) {
          // 伝票日付が処理年月よりも過去日付の場合
          this.statusFlg.editFlg = false;
          this.alertWarning.push(DISP_MESSAGES.WARNING['2017']);
        }
        // 伝票種別
        this.selectBillClass = result[0].bill_class;
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        if (result[0].closing_date == 99) {
          this.suppliers.closingDate = formatDateCalc(('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01', 0, 0, 0, true);
        } else {
          this.suppliers.closingDate = formatDate(('000000' + this.controlMasterData.processMonthYear).slice(-6) + ('00' + result[0].closing_date).slice(-2));
        }
        this.suppliers.address1 = result[0].address_1;
        this.suppliers.address2 = result[0].address_2;
        this.suppliers.department1 = result[0].department_1;
        this.suppliers.department2 = result[0].department_2;
        this.suppliers.tel = result[0].phone_number;
        this.suppliers.fax = result[0].fax_number;
        this.suppliers.zipCode = result[0].zip_code;
        this.suppliers.sundriesClass = result[0].sundries_class;
        this.suppliers.firstDigit = result[0].client_id_first_digit;
        this.suppliers.taxClass = result[0].sales_tax_class;
        this.suppliers.taxCalculationClass = result[0].tax_calculation_class;
        this.suppliers.taxFracClass = result[0].tax_fractionation_class;
        // 全件参照して製品一覧を作成
        for(let i = 0; i < result.length; i++){
          if(i == this.productList.length){
            this.addTableRow();
          }
          this.productList[i].ProductCode = result[i].product_id;
          this.productList[i].ProductClass = result[i].product_class_id;
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i]._cellVariants = { ProductName: getListValue(Const.ProductCheckClass, result[i].product_check_class, 'value', 'color') };
          this.productList[i].ProductTaxRateClass = result[i].product_tax_rate_class_purchase;
          this.productList[i].Note = result[i].product_note;
          this.productList[i].ServiceClass = result[i].service_class;
          this.productList[i].Quantity = result[i].product_quantity;
          this.productList[i].Unit = result[i].unit;
          this.productList[i].PurchasePrime = result[i].product_purchase_price;
          this.productList[i].PurchasePrimeMaster = result[i].purchase_price;
          this.productList[i].SundriesClass = result[i].product_mouth_class;
          this.productList[i].InventoryClass = result[i].inventory_control_class;
          this.productList[i].CaseConversion = result[i].case_conversion_class;
          this.productList[i].TotalPurchasePrime = this.productList[i].Quantity * this.productList[i].PurchasePrime;
        }
        this.calcTotal();
        // 最終行を空行とする
        this.addTableRow();
        // 摘要
        this.billingSummary = result[0].billing_summary;
        // 注文番号
        this.orderNumber = result[0].order_number;
      }
    },
    /* 保存ボタンの押下 */
    async saveOrder() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      } else {
        this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      // 仕入データの保存(SQLの作成)
      if (await this.execSave() == true) {
        // 保存成功メッセージを表示
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
      }
      //console.log('保存処理終了');
    },
    /* 保存処理 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      // 仕入データの更新
      await this.updateCumulativeTransaction();

      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }
      // 月次更新後チェック
      if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
        return false;
      }
      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }

      retResult = true;

      return retResult;
    },
    /* 累積トランザクション更新SQL作成 */
    async updateCumulativeTransaction() {
      //console.log('累積トランザクション更新SQL作成');
      // CRUD処理
      // 累積トランザクションの削除SQL
      let deleteSql = 'DELETE FROM ';
      deleteSql += 't_cumulative_transaction';
      deleteSql += ' WHERE ';
      deleteSql += 'billing_no = ' + this.billingNo + ' ';
      deleteSql += 'AND monthly_month_year = ' + this.monthlyMonthYear + ' ';
      deleteSql += 'AND transaction_id = \'' + Const.TransactionId.stocking + '\' ';
      //console.log(deleteSql);
      this.transactSqlList.push(deleteSql);
      // 累積トランザクションの登録SQL
      let bulkInsertSql = '';
      let tax = 0;
      if (this.suppliers.taxCalculationClass == Const.TaxCalculationClass.salesSlip) {
        // 税額計算区分が1の場合、消費税を計算
        tax = calcTaxNew(this.dateBillingDate, this.subTotalNormal, this.subTotalLight, this.controlMasterData.taxRate, this.controlMasterData.newTaxRate, this.controlMasterData.lightTaxRate, this.controlMasterData.newLightTaxRate, this.controlMasterData.newTaxStartDate);
      }
      for(let i = 0; i < this.productList.length + 1; i++) {
        let colList = [];
        // 製品行の行数分＋税額計算区分が1の場合の消費税行分
        if (i < this.productList.length || this.suppliers.taxCalculationClass == Const.TaxCalculationClass.salesSlip) {
          // ﾄﾗﾝｻﾞｸｼｮﾝID
          colList.push(CreateColRow('transaction_id', Const.TransactionId.stocking, 'VARCHAR'));
          // 伝票No
          colList.push(CreateColRow('billing_no', this.billingNo, 'NUMBER'));
          // 行No
          colList.push(CreateColRow('billing_row', i + 1, 'NUMBER'));
          // 伝票日付
          colList.push(CreateColRow('billing_date', this.dateBillingDate, 'DATE'));
          // 伝票種別
          colList.push(CreateColRow('bill_class', this.selectBillClass, 'NUMBER'));
          // 解体組立区分
          colList.push(CreateColRow('dismantling_assembly_class', 0, 'NUMBER'));
          // 営業所コード
          colList.push(CreateColRow('office_id', this.selectSalesOffice, 'NUMBER'));
          // 移動先コード
          colList.push(CreateColRow('destination_id', 0, 'NUMBER'));
          // 取引先区分
          colList.push(CreateColRow('client_class', this.suppliers.class, 'NUMBER'));
          // 取引先コード
          colList.push(CreateColRow('client_id', this.suppliers.code, 'NUMBER'));
          // 現場コード
          colList.push(CreateColRow('site_id', 0, 'NUMBER'));
          // 伝票摘要
          colList.push(CreateColRow('billing_summary', await escapeQuote(this.billingSummary), 'VARCHAR'));
          // 担当者コード
          colList.push(CreateColRow('staff_id', 0, 'NUMBER'));
          // 取引先分類コード
          colList.push(CreateColRow('client_id_first_digit', this.suppliers.firstDigit, 'NUMBER'));
          // 相殺伝票No
          colList.push(CreateColRow('offset_billing_no', 0, 'NUMBER'));
          if (i == this.productList.length) {
            // 消費税行（追加）
            // 製品コード
            colList.push(CreateColRow('product_id', 0, 'NUMBER'));
            // 製品名
            colList.push(CreateColRow('product_name', '', 'VARCHAR'));
            // 製品備考
            colList.push(CreateColRow('product_note', '','VARCHAR'));
            // サービス区分
            colList.push(CreateColRow('service_class', '', 'VARCHAR'));
            // 製品数量
            colList.push(CreateColRow('product_quantity', 0, 'NUMBER'));
            // 製品単位
            colList.push(CreateColRow('product_unit', '', 'VARCHAR'));
            // 製品売上単価
            colList.push(CreateColRow('product_sales_unit_price', 0, 'NUMBER'));
            // 製品仕入単価
            colList.push(CreateColRow('product_purchase_price', 0, 'NUMBER'));
            // 製品売上金額
            colList.push(CreateColRow('product_sales_unit_amount', 0, 'NUMBER'));
            // 製品仕入金額
            colList.push(CreateColRow('product_purchase_amount', 0, 'NUMBER'));
            // 製品粗利額
            colList.push(CreateColRow('product_gross_profit', 0, 'NUMBER'));
            // 製品消費税
            colList.push(CreateColRow('product_tax', tax, 'NUMBER'));
            // 金額
            colList.push(CreateColRow('amount', 0, 'NUMBER'));
            // 製品分類コード
            colList.push(CreateColRow('product_class_id', 0, 'NUMBER'));
            // 製品諸口区分
            colList.push(CreateColRow('product_mouth_class', Const.SundriesClass.normal, 'NUMBER'));
            // 在庫管理区分
            colList.push(CreateColRow('inventory_control_class', Const.InventoryControlClassDef.inventory, 'NUMBER'));
          } else {
            // 製品行
            // 製品コード
            colList.push(CreateColRow('product_id', this.productList[i].ProductCode, 'NUMBER'));
            // 製品名
            colList.push(CreateColRow('product_name', await escapeQuote(this.productList[i].ProductName), 'VARCHAR'));
            // 製品備考
            colList.push(CreateColRow('product_note', await escapeQuote(this.productList[i].Note),'VARCHAR'));
            // サービス区分
            colList.push(CreateColRow('service_class', await escapeQuote(this.productList[i].ServiceClass), 'VARCHAR'));
            // 製品数量
            colList.push(CreateColRow('product_quantity', this.productList[i].Quantity, 'NUMBER'));
            // 製品単位
            colList.push(CreateColRow('product_unit', await escapeQuote(this.productList[i].Unit), 'VARCHAR'));
            // 製品売上単価
            colList.push(CreateColRow('product_sales_unit_price', 0, 'NUMBER'));
            // 製品仕入単価
            colList.push(CreateColRow('product_purchase_price', this.productList[i].PurchasePrime, 'NUMBER'));
            // 製品売上金額
            colList.push(CreateColRow('product_sales_unit_amount', 0, 'NUMBER'));
            // 製品仕入金額
            colList.push(CreateColRow('product_purchase_amount', this.productList[i].TotalPurchasePrime, 'NUMBER'));
            // 製品粗利額
            colList.push(CreateColRow('product_gross_profit', 0, 'NUMBER'));
            // 製品消費税
            colList.push(CreateColRow('product_tax', 0, 'NUMBER'));
            // 金額
            colList.push(CreateColRow('amount', 0, 'NUMBER'));
            // 製品分類コード
            colList.push(CreateColRow('product_class_id', this.productList[i].ProductClass, 'NUMBER'));
            // 製品諸口区分
            colList.push(CreateColRow('product_mouth_class', this.productList[i].SundriesClass, 'NUMBER'));
            // 在庫管理区分
            colList.push(CreateColRow('inventory_control_class', this.productList[i].InventoryClass, 'NUMBER'));
          }
          // 売上課税区分
          colList.push(CreateColRow('sales_tax_class', this.suppliers.taxClass, 'NUMBER'));
          // 税額計算区分
          colList.push(CreateColRow('tax_calculation_class', this.suppliers.taxCalculationClass, 'NUMBER'));
          // 税額端数計算処理区分
          colList.push(CreateColRow('tax_fractionation_class', this.suppliers.taxFracClass, 'NUMBER'));
          // 月次更新フラグ
          colList.push(CreateColRow('is_update_monthly', 0, 'NUMBER'));
          // 月次年月
          colList.push(CreateColRow('monthly_month_year', 0, 'NUMBER'));
          // 締日更新フラグ
          colList.push(CreateColRow('is_update_closing_date', 0, 'NUMBER'));
          // 締次年月
          colList.push(CreateColRow('closing_month_year', 0, 'NUMBER'));
          // 受注番号
          colList.push(CreateColRow('order_receive_id', 0, 'NUMBER'));
          // 受注行番号
          colList.push(CreateColRow('order_receive_row', 0, 'NUMBER'));
          // 発注番号
          colList.push(CreateColRow('order_id', 0, 'NUMBER'));
          // 発注行番号
          colList.push(CreateColRow('order_row', 0, 'NUMBER'));
          // 注文番号
          colList.push(CreateColRow('order_number', this.orderNumber, 'NUMBER'));
          // 注文行番号
          colList.push(CreateColRow('order_number_row', 0, 'NUMBER'));
          // 組合CD発注取引先
          colList.push(CreateColRow('union_cd_order_client', 0, 'NUMBER'));
          // 仕入単価ﾁｪｯｸﾌﾗｸﾞ
          colList.push(CreateColRow('is_check_purchase_price', 0, 'NUMBER'));
          // 作成ユーザー
          colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
          if (bulkInsertSql == '') {
            bulkInsertSql += 'INSERT INTO t_cumulative_transaction (' + CreateInsertSql(colList, 'col', 't_cumulative_transaction') + ') VALUES ';
          } else {
            bulkInsertSql += ',';
          }
          bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_cumulative_transaction') + ')';
          if (bulkInsertSql.length >= Const.SqlMaxLength) {
            this.transactSqlList.push(bulkInsertSql);
            bulkInsertSql = '';
          }
        }
      }
      if (bulkInsertSql != '') {
        //console.log(bulkInsertSql);
        this.transactSqlList.push(bulkInsertSql);
      }
    },
    // キャンセルボタン
    cancel: function() {
      this.$router.push({ name: 'PURCHASE-LIST'});
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      const functionName = 'searchClient';
      const result = await searchClientModal(client_id, Const.ClientClass.supplier, functionName, MODULE_NAME);
      if (typeof result != 'undefined') {
        this.closeClientSearchModal(result);
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6) {
          this.closeClientSearchModal({detail:{}});
        }
      }
    },
    // 取引先検索
    showClientSearchModal:function() {
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal:function(clientItems) {
      //console.log(clientItems);
      // モーダルから渡された値の有無チェック
      if (typeof clientItems != 'undefined') {
        // 取引先（得意先）から呼出
        if (clientItems.detail.closing_date == 99) {
          this.suppliers.closingDate = formatDateCalc(('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01', 0, 0, 0, true);
        } else {
          this.suppliers.closingDate = formatDate(('000000' + this.controlMasterData.processMonthYear).slice(-6) + ('00' + clientItems.detail.closing_date).slice(-2));
        }
        this.suppliers.code = clientItems.detail.client_id;
        this.suppliers.name = clientItems.detail.client_name_kanji;
        this.suppliers.address1 = clientItems.detail.address_1;
        this.suppliers.address2 = clientItems.detail.address_2;
        this.suppliers.department1 = clientItems.detail.department_1;
        this.suppliers.department2 = clientItems.detail.department_2;
        this.suppliers.tel = clientItems.detail.phone_number;
        this.suppliers.fax = clientItems.detail.fax_number;
        this.suppliers.zipCode = clientItems.detail.zip_code;
        this.suppliers.class = clientItems.detail.client_class;
        this.suppliers.sundriesClass = clientItems.detail.sundries_class;
        this.suppliers.firstDigit = clientItems.detail.client_id_first_digit;
        this.suppliers.taxClass = clientItems.detail.sales_tax_class;
        this.suppliers.taxCalculationClass = clientItems.detail.tax_calculation_class;
        this.suppliers.taxFracClass = clientItems.detail.tax_fractionation_class;
      }
    },
    // 取引先照会
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    // 製品コード直接入力 - 製品検索
    async searchProduct(product_id, index) {
      const functionName = 'searchProduct';
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.selectSalesOffice);
      this.productSearchProp.client_id = this.suppliers.code;
      this.productSearchProp.client_class = this.suppliers.class;
      this.productSearchProp.client_name = this.suppliers.name;
      const result = await searchProductsModal(product_id, this.productSearchProp, functionName, MODULE_NAME);
      this.dataIndex = index;
      if (typeof result != 'undefined') {
        this.closeProductSearchModal(result);
      } else {
        // 製品コード8桁かつデータ取得失敗の場合
        if (product_id.length == 8) {
          // 製品コードクリア
          this.productList[index].ProductCode = '';
        }
      }
    },
    // 製品検索
    showProductSearchModal:function(index) {
      //console.log('製品検索モーダル');
      //console.log(index);
      this.dataIndex = index;
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.selectSalesOffice);
      this.productSearchProp.client_id = this.suppliers.code;
      this.productSearchProp.client_class = this.suppliers.class;
      this.productSearchProp.client_name = this.suppliers.name;
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    closeProductSearchModal: function(productItems){
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if (typeof productItems != 'undefined') {
        let focusFlg = false;
        if (productItems.length == undefined) {
          // 選択ボタン押下でクローズ
          this.setProductSearchModal(this.dataIndex, productItems.detail);
          focusFlg = true;
        } else {
          // 一括選択ボタン押下でクローズ
          if (productItems.length == 1) {
            // 1件の場合のみフォーカスを数量に当てる
            focusFlg = true;
          }
          for (let i = 0; i < productItems.length; i++) {
            if (i == 0) {
              this.setProductSearchModal(this.dataIndex, productItems[i].detail);
            } else {
              this.productList.splice(this.dataIndex + i, 0, this.createNewRow());
              this.setProductSearchModal(this.dataIndex + i, productItems[i].detail);
            }
          }
        }
        // 合計仕入額を計算
        this.calcTotal();
        // 最終行が空行でない場合は空行追加
        if (this.isListBlankRow(this.productList.length - 1) == false) {
          this.addTableRow();
        }
        if (focusFlg == true) {
          // 製品検索を行った行の数量にフォーカスを移動
          setTimeout(() => {
            if (this.dataIndex < this.productList.length) {
              this.setFocus('productQuantity' + this.dataIndex);
            }
          }, 500);
        }
      }
    },
    // 製品検索モーダルの返却値設定
    async setProductSearchModal(index, productItemsDetail) {
      // 製品コード
      this.productList[index].ProductCode = productItemsDetail.product_id;
      // 製品分類コード
      this.productList[index].ProductClass = productItemsDetail.product_class_id;
      // 製品名（漢字）
      this.productList[index].ProductName = productItemsDetail.product_name_kanji;
      this.productList[index].ProductTaxRateClass = productItemsDetail.product_tax_rate_class_purchase;
      // 製品備考
      this.productList[index].Note = '';
      // サービス区分
      this.productList[index].ServiceClass = '';
      // 数量
      if (this.selectBillClass == Const.Tradition.purchaseReturn) {
        // 返品の場合
        // 数量
        this.productList[index].Quantity = -1;
      } else {
        // 返品以外の場合
        // 数量
        this.productList[index].Quantity = 1;
      }
      // 単位
      this.productList[index].Unit = productItemsDetail.unit;
      // 製品チェック区分
      this.productList[index]._cellVariants = { ProductName: getListValue(Const.ProductCheckClass, productItemsDetail.product_check_class, 'value', 'color') };
      // 仕入単価
      this.productList[index].PurchasePrime = productItemsDetail.purchase_price;
      // 仕入単価（マスター）
      this.productList[index].PurchasePrimeMaster = productItemsDetail.purchase_price;
      // 諸口区分
      this.productList[index].SundriesClass = productItemsDetail.sundries_class;
      // 在庫管理区分
      this.productList[index].InventoryClass = productItemsDetail.inventory_control_class;
      // ケース換算区分
      this.productList[index].CaseConversion = productItemsDetail.case_conversion_class;
      // 合計仕入単価(1製品)
      this.productList[index].TotalPurchasePrime = productItemsDetail.purchase_price * this.productList[index].Quantity;
    },
    // 再計算イベント
    calc: function(index){
      // 対象行の項目計算
      this.productList[index].TotalPurchasePrime = this.productList[index].Quantity * this.productList[index].PurchasePrime;
      // 合計仕入単価計算
      this.calcTotal();
    },
    // 合計仕入単価計算
    calcTotal: function(){
      this.totalPurchasePrime = 0;
      let moneySubTotalNormal = 0;
      let moneySubTotalLight = 0;
      for(let i = 0; i < this.productList.length;i++){
        this.totalPurchasePrime += this.productList[i].TotalPurchasePrime;
        if (this.productList[i].ProductTaxRateClass == Const.ProductTaxRateClass.normalTax) {
          moneySubTotalNormal += this.productList[i].TotalPurchasePrime;
        } else if (this.productList[i].ProductTaxRateClass == Const.ProductTaxRateClass.lightTax) {
          moneySubTotalLight += this.productList[i].TotalPurchasePrime;
        }
      }
      this.subTotalNormal = moneySubTotalNormal;
      this.subTotalLight = moneySubTotalLight;
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = this.createNewRow();
      this.productList.push(newProduct);
    },
    // 行作成
    createNewRow: function() {
      let newProduct = {
        ProductCode: null,
        ProductClass: null,
        ProductName: '',
        ProductTaxRateClass: Const.ProductTaxRateClass.normalTax,
        Note: '',
        ServiceClass: '',
        Quantity: 0,
        Unit: '',
        PurchasePrime: 0,
        PurchasePrimeMaster: 0,
        SundriesClass: null,
        InventoryClass: null,
        CaseConversion: '',
        TotalPurchasePrime: 0,
      };
      return newProduct;
    },
    // テーブル行削除イベント
    deleteBtn:function(rowNum){
      //console.log(rowNum);
      if(this.productList.length > 1){
        this.productList.splice(rowNum,1);
        // 合計金額、合計仕入単価、粗利計算
        this.calcTotal();
      }
    },
    // テーブル行削除イベント
    async bulkDeleteBtn() {
      let confirmMessage = 'チェックONの製品を全て削除します。';
      confirmMessage += 'よろしいですか？';
      let title = '一括削除確認';
      if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: title}) == true) {
        this.$store.commit('setLoading', true);
        this.checkedIndexs.sort(function(first, second){
          return first - second;
        });
        for (let i = this.checkedIndexs.length - 1; i >= 0; i--) {
          if(this.productList.length > 1){
            this.productList.splice(this.checkedIndexs[i], 1);
            this.checkedIndexs.splice(i, 1);
          }
        }
        // 合計金額、合計仕入単価、粗利計算
        this.calcTotal();
        this.$store.commit('setLoading', false);
      }
    },
    // テーブル初期化処理
    initProductTable: function() {
      this.productList = [];
      this.addTableRow();
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // 一覧の指定行が空かどうかチェック
    isListBlankRow: function(index) {
      if (getNullStr(this.productList[index].ProductCode) == '') {
        return true;
      } else {
        return false;
      }
    },
    // 一覧の後ろの空行を削除
    delListLastBlankRow: function() {
      //console.log('空行削除開始');
      for (let i = this.productList.length - 1; i > 0; i--) {
        if (this.isListBlankRow(i) == true) {
          // 後ろにある空行は削除
          this.productList.splice(i, 1);
        } else {
          // 1件でも空行以外の行があった場合は削除処理終了
          break;
        }
      }
      // 最後に合計金額、合計仕入単価、粗利計算
      this.calcTotal();
      //console.log('空行削除終了');
    },
    // 新規登録ボタン押下時
    async clickInputBtn() {
      this.$router.push({ name: 'PURCHASE-INPUT', query: { propOrderNumber: 0 } });
    },
    // チェックボックス個別選択（全チェックのチェックボックスを合わせる）
    changeCheck: function() {
      this.isAllChecked = this.productList.length == this.checkedIndexs.length;
    },
    // 全チェック選択
    clickCheckAll: function() {
      if (this.isAllChecked == true) {
        // チェックONにした場合
        // 削除チェックを初期化
        this.checkedIndexs = [];
        // 削除チェックを全てON
        for (let i = 0; i < this.productList.length; i++) {
          this.checkedIndexs.push(i);
        }
      } else {
        // チェックOFFにした場合
        // 削除チェックを全てOFF
        this.checkedIndexs = [];
      }
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
    // サービス変更
    changeService: function(index) {
      if (this.productList[index].ServiceClass != '') {
        // サービス区分が空白以外
        this.productList[index].PurchasePrime = 0;
        this.productList[index].TotalPurchasePrime = 0;
      } else {
        this.productList[index].PurchasePrime = this.productList[index].PurchasePrimeMaster;
        this.productList[index].TotalPurchasePrime = this.productList[index].Quantity * this.productList[index].PurchasePrime;
      }
      // 合計金額、合計仕入単価、粗利
      this.calcTotal();
    },
    // 行移動のためのドラッグ開始イベント
    onRowMoveDragstart: function(event, index) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.setData('drag-index', index);
    },
    // 行移動のためのドロップイベント
    onRowMoveDrop(event, index) {
      const moveRowData = this.productList.splice(event.dataTransfer.getData('drag-index'), 1)[0];
      this.productList.splice(index, 0, moveRowData);
    },
  }
}
</script>
<style scoped>
</style>