<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <!-- TODO 現在は印刷時にヘッダーは除外しているが、1ページ目のみに表示されるようにする（タイトルを表示するため） -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <b-card class="border">
              <b-card-header class="mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip" title="クリックすると検索条件表示/非表示できます。">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" style="display: block; float: left;" role="tablist" aria-expanded="true">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                </h5>
              </b-card-header>
              <br>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-2">
                  <b-container>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <b-container>
                        <b-row>
                          <!-- 営業所コードプルダウン -->
                          <b-col lg="6">
                            <b-form-group
                              label="営業所"
                              label-for="selectSalesOffice"
                            >
                              <b-form-select
                                id="selectSalesOffice"
                                v-model="searchConditions.selectSalesOffice"
                                :options="searchConditions.salesOffice"
                                value-field="id"
                              />
                            </b-form-group>
                          </b-col>
                          <!-- 配送種別 -->
                          <b-col lg="6">
                            <b-form-group
                              label="配送種別"
                              label-for="shippingTypeClassStart"
                            >
                              <validation-provider name="shippingTypeClassStart" :rules="{consistency:searchConditions.shippingTypeClassEnd}" v-slot="{ classes,errors }">
                                <b-input-group :class="classes">
                                  <b-form-input type="text" id="shippingTypeClassStart" name="shippingTypeClassStart" v-model="searchConditions.shippingTypeClassStart" @blur="searchConditions.shippingTypeClassEnd = searchConditions.shippingTypeClassStart" />
                                  <b-input-group-text>～</b-input-group-text>
                                  <b-form-input type="text" name="shippingTypeClassEnd" v-model="searchConditions.shippingTypeClassEnd" />
                                </b-input-group>
                                <b-input-group :class="classes">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-input-group>
                              </validation-provider>
                              <b-form-text class="text-muted">配送種別の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- ルート -->
                          <b-col lg="6">
                            <b-form-group
                              label="ルート"
                              label-for="shippingCodeStart"
                            >
                              <validation-provider name="shippingCodeStart" :rules="{consistency:searchConditions.shippingCodeEnd}" v-slot="{ classes,errors }">
                                <b-input-group :class="classes">
                                  <b-form-input type="text" id="shippingCodeStart" name="shippingCodeStart" v-model="searchConditions.shippingCodeStart" @blur="searchConditions.shippingCodeEnd = searchConditions.shippingCodeStart" />
                                  <b-input-group-text>～</b-input-group-text>
                                  <b-form-input type="text" name="shippingCodeEnd" v-model="searchConditions.shippingCodeEnd" />
                                </b-input-group>
                                <b-input-group :class="classes">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-input-group>
                              </validation-provider>
                              <b-form-text class="text-muted">配送コードの範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                          <!-- 置き場所 -->
                          <b-col lg="6">
                            <b-form-group
                              label="置き場所"
                              label-for="placeStart"
                            >
                              <validation-provider name="placeStart" :rules="{consistency:searchConditions.placeEnd}" v-slot="{ classes,errors }">
                                <b-input-group :class="classes">
                                  <b-form-input type="text" id="placeStart" name="placeStart" v-model="searchConditions.placeStart" />
                                  <b-input-group-text>～</b-input-group-text>
                                  <b-form-input type="text" name="placeEnd" v-model="searchConditions.placeEnd" />
                                </b-input-group>
                                <b-input-group :class="classes">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-input-group>
                              </validation-provider>
                              <b-form-text class="text-muted">置き場所（先頭2桁）の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 確定入力日 -->
                          <b-col lg="6">
                            <b-form-group
                              label="確定入力日"
                              label-for="dateConfirmDate"
                            >
                              <b-input-group class="input-daterange">
                                <b-form-datepicker
                                  id="dateConfirmDate"
                                  name="dateConfirmDate"
                                  v-model="searchConditions.dateConfirmDate"
                                  calendar-width="50%"
                                ></b-form-datepicker>
                                <b-input-group-append>
                                  <b-button size="sm" variant="outline-secondary" @click="searchConditions.dateConfirmDate=''">
                                    <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                              <b-form-text class="text-muted">ピッキング製品の出荷確定日を設定してください。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 得意先コード -->
                          <b-col lg="12">
                            <b-form-group
                              label="得意先コード"
                              label-for="clientIdStart"
                            >
                              <b-input-group>
                                <validation-provider name="clientIdStart" :rules="{numeric:true,consistency:searchConditions.clientIdEnd}" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <b-form-input type="text" id="clientIdStart" name="clientIdStart" v-model.number="searchConditions.clientIdStart" />
                                        <b-input-group-text @click="showClientSearchModal(1);" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                            <span class="oi oi-magnifying-glass"></span>
                                            <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(1);" class="d-none"></button>
                                          </b-button>
                                        </b-input-group-text>
                                      </b-input-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                                <b-input-group-text>～</b-input-group-text>
                                <validation-provider name="clientIdEnd" rules="numeric" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <b-input type="text" v-model.number="searchConditions.clientIdEnd" />
                                        <b-input-group-text @click="showClientSearchModal(2);" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                            <span class="oi oi-magnifying-glass"></span>
                                            <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(2);" class="d-none"></button>
                                          </b-button>
                                        </b-input-group-text>
                                      </b-input-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                              </b-input-group>
                              <b-form-text class="text-muted">得意先の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 現場コード -->
                          <b-col lg="12">
                            <b-form-group label="現場コード">
                              <b-input-group>
                                <validation-provider name="siteIdStart" :rules="{numeric:true,consistency:searchConditions.siteIdEnd}" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input name="siteIdStart" type="text" v-model.number="searchConditions.siteIdStart" @blur="searchConditions.siteIdEnd = searchConditions.siteIdStart" />
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                                <b-input-group-text>～</b-input-group-text>
                                <validation-provider name="siteIdEnd" rules="numeric" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input name="siteIdEnd" type="text" v-model.number="searchConditions.siteIdEnd" />
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                              </b-input-group>
                              <b-form-text class="text-muted">現場の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-container>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <b-row class="justify-content-md-center my-2">
                      <b-col lg="3">
                        <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                          <span class="oi oi-magnifying-glass"></span> 検 索 
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <!-- ●●●検索条件●●● -->
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-card-header>
          <b-row class="justify-content-md-center pb-4">
            <b-col lg="12">
              <b-btn-toolbar class="mt-2">
                <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="一覧のPDF出力を行います。" @click="clickOutputPdf" :disabled="totalRows==0">
                  <span class="oi oi-document"></span> 出荷確定ピッキング表PDF出力
                </b-button>&nbsp;
              </b-btn-toolbar>
            </b-col>
          </b-row>
          <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
            <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
              <li>{{error}}</li>
            </ul>
          </b-alert>
        </b-card-header>
        <b-card-body>
          <b-col class="mt-2">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col  lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="5"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-table
                show-empty
                :items="itemList"
                :fields="fields"
                :busy="busy"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
              >
              </b-table>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : totalRows"
                :per-page="perPage == -1 ? totalRows : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-col>
        </b-card-body>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●得意先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class='ClientClass'/>
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import { init, executeSelectSql, formatDate, formatCurDate, addOperationLogs, getNullStr, getControlMaster, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';

const MODULE_NAME = 'ships-confirm-picking';

export default {
  name: 'SHIPS-CONFIRM-PICKING',
  /* コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
  },
  /* データ */
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '出荷確定ピッキング表',
      // アラート
      resultError: [],
      // 検索項目
      searchConditions: {
        // 営業所プルダウン
        selectSalesOffice: null,
        salesOffice: [],
        // 配送種別（開始／終了）
        shippingTypeClassStart: '',
        shippingTypeClassEnd: '',
        // ルート（配送コード）（開始／終了）
        shippingCodeStart: '',
        shippingCodeEnd: '',
        // 場所（開始／終了）
        placeStart: '',
        placeEnd: '',
        // 確定入力日
        dateConfirmDate: '',
        // 取引先コード（開始／終了）
        clientIdStart: '',
        clientIdEnd: '',
        // 現場コード（開始／終了）
        siteIdStart: '',
        siteIdEnd: '',
      },
      // 検索項目
      searchResultDispConditions: {
        // 営業所プルダウン
        selectSalesOffice: null,
        // 配送種別（開始／終了）
        shippingTypeClassStart: '',
        shippingTypeClassEnd: '',
        // ルート（配送コード）（開始／終了）
        shippingCodeStart: '',
        shippingCodeEnd: '',
        // 場所（開始／終了）
        placeStart: '',
        placeEnd: '',
        // 確定入力日
        dateConfirmDate: '',
        // 取引先コード（開始／終了）
        clientIdStart: '',
        clientIdEnd: '',
        // 現場コード（開始／終了）
        siteIdStart: '',
        siteIdEnd: '',
      },
      // 変更後引数
      changeSearchConditions: {
        // 配送種別（開始／終了）
        shippingTypeClassStart: '',
        shippingTypeClassEnd: '',
        // ルート（配送コード）（開始／終了）
        shippingCodeStart: '',
        shippingCodeEnd: '',
        // 場所（開始／終了）
        placeStart: '',
        placeEnd: '',
        // 取引先コード（開始／終了）
        clientIdStart: '',
        clientIdEnd: '',
        // 現場コード（開始／終了）
        siteIdStart: '',
        siteIdEnd: '',
      },
      // 検索結果に表示する検索条件
      bordered: false,
      borderless: true,
      outlined: false,
      // 検索結果
      itemList: [],
      // 得意先区分
      ClientClass :Const.ClientClass.customer,
      // モーダルからの戻り値を反映先
      openModalIndex: 0,
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: 0,
      // フィルタリングデータの総件数
      filterRows: 0,
      // 印刷チェックボックスの総件数
      totalCheckCnt: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'ClientNameKanji',
          label: '仕入先名',
          sortable: true,
        },
        {
          key: 'ProductCode',
          label: '製品コード',
          sortable: true,
        },
        {
          key: 'ProductName',
          label: '製品名',
          sortable: true,
        },
        {
          key: 'ShippingQuantity',
          label: '出荷可能数',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'Unit',
          label: '単位',
          sortable: true,
        },
        {
          key: 'Balance',
          label: '現在庫数',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'QuantityStock',
          label: '入荷予定数',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'StockScheduled',
          label: '最新入荷予定日',
          sortable: true,
        },
        {
          key: 'Place',
          label: '置き場所',
          sortable: true,
        },
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1 || this.perPage == -1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';

      this.$store.commit('setLoading', true);
      // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
      let user = store.getters.user;
      this.loginId = user.username;
      //console.log('ログイン情報');
      //console.log(this.loginId);
      let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
      let staffListData = null;
      try {
        staffListData = await selectOneTable('m_staffs', where_clause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_staffs',
          where_clause: where_clause
        }, error);
        this.resultError.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      //console.log(staffListData);
      // 営業所データ取得
      let officeListData = null;
      try {
        officeListData = await selectOneTable('m_offices');
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_offices',
        }, error);
        this.resultError.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      //console.log(officeListData);
      for(let i = 0; i < officeListData.length; i++){
        let office = {
          id: officeListData[i].office_id,
          text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
          name: officeListData[i].office_name_kanji
        };
        this.searchConditions.salesOffice.push(office);
      }
      // 営業所データ初期値セット
      for(let i = 0; i < this.searchConditions.salesOffice.length; i++){
        //console.log(this.searchConditions.salesOffice[i].id);
        if(this.searchConditions.salesOffice[i].id == staffListData[0].office_id){
          this.searchConditions.selectSalesOffice = this.searchConditions.salesOffice[i].id;
          break;
        }
      }
      // 確定入力日
      this.searchConditions.dateConfirmDate = formatCurDate('YYYY-MM-DD');
      // 現在処理年月取得
      let controlData = null;
      try {
        controlData = await getControlMaster();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control'
        }, error);
        this.resultError.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      this.controlMasterData.processMonthYear = controlData.process_month_year;
      this.$store.commit('setLoading', false);
      // 初期検索
      //await this.search();
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          this.$store.commit('setLoading', true);
          await this.search();
          this.$store.commit('setLoading', false);
        }
      } catch (error) {
        console.log(error);
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      this.itemList = [];
      this.busy = true;
      // ページング機能の初期化
      this.initPaging();
      // 範囲指定の文字列引数の変換（9を「ﾟ」に変換）
      this.changeSearchConditionsStrRange();
      // CRUD処理
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      try {
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        if(resultData != null){
          this.saveSearchConditions();
          await this.setResult(resultData);
          if(resultData.length >= 1000){
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
          //console.log(resultData);
        }else{
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, 'executeSelectSqlでエラーが発生しました。', error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.busy = false;
    },
    /* 範囲指定の文字列引数の変換（9を「ﾟ」に変換） */
    changeSearchConditionsStrRange: function() {
      // ルート（配送コード）
      this.changeSearchConditions.shippingCodeStart = this.searchConditions.shippingCodeStart;
      if (this.changeSearchConditions.shippingCodeStart == '*') {
        this.changeSearchConditions.shippingCodeStart = '';
      }
      this.changeSearchConditions.shippingCodeEnd = this.searchConditions.shippingCodeEnd.replace(/9/g, Const.Char.halfMax);
      this.changeSearchConditions.shippingCodeEnd = this.changeSearchConditions.shippingCodeEnd.padEnd(5, Const.Char.halfMax);
      // 配送種別
      this.changeSearchConditions.shippingTypeClassStart = this.searchConditions.shippingTypeClassStart;
      if (this.changeSearchConditions.shippingTypeClassStart == '*') {
        this.changeSearchConditions.shippingTypeClassStart = '';
      }
      this.changeSearchConditions.shippingTypeClassEnd = this.searchConditions.shippingTypeClassEnd.replace(/9/g, Const.Char.halfMax);
      this.changeSearchConditions.shippingTypeClassEnd = this.changeSearchConditions.shippingTypeClassEnd.padEnd(1, Const.Char.halfMax);
      // 置き場所
      this.changeSearchConditions.placeStart = this.searchConditions.placeStart;
      if (this.changeSearchConditions.placeStart == '*') {
        this.changeSearchConditions.placeStart = '';
      }
      this.changeSearchConditions.placeEnd = this.searchConditions.placeEnd.replace(/9/g, Const.Char.halfMax);
      this.changeSearchConditions.placeEnd = this.changeSearchConditions.placeEnd.padEnd(2, Const.Char.halfMax);
      // 得意先コード
      if (this.searchConditions.clientIdStart == '') {
        this.changeSearchConditions.clientIdStart = '000000';
      } else {
        this.changeSearchConditions.clientIdStart = this.searchConditions.clientIdStart;
      }
      if (this.searchConditions.clientIdEnd == '') {
        this.changeSearchConditions.clientIdEnd = '999999';
      } else {
        this.changeSearchConditions.clientIdEnd = this.searchConditions.clientIdEnd;
      }
      // 現場コード
      if (this.searchConditions.siteIdStart == '') {
        this.changeSearchConditions.siteIdStart = '0000';
      } else {
        this.changeSearchConditions.siteIdStart = this.searchConditions.siteIdStart;
      }
      if (this.searchConditions.siteIdEnd == '') {
        this.changeSearchConditions.siteIdEnd = '9999';
      } else {
        this.changeSearchConditions.siteIdEnd = this.searchConditions.siteIdEnd;
      }
    },
    /* 検索条件を保存 */
    saveSearchConditions: function() {
      this.searchResultDispConditions.selectSalesOffice = this.searchConditions.selectSalesOffice;
      this.searchResultDispConditions.shippingTypeClassStart = this.searchConditions.shippingTypeClassStart;
      this.searchResultDispConditions.shippingTypeClassEnd = this.searchConditions.shippingTypeClassEnd;
      this.searchResultDispConditions.shippingCodeStart = this.searchConditions.shippingCodeStart;
      this.searchResultDispConditions.shippingCodeEnd = this.searchConditions.shippingCodeEnd;
      this.searchResultDispConditions.placeStart = this.searchConditions.placeStart;
      this.searchResultDispConditions.placeEnd = this.searchConditions.placeEnd;
      this.searchResultDispConditions.dateConfirmDate = this.searchConditions.dateConfirmDate;
      this.searchResultDispConditions.clientIdStart = this.searchConditions.clientIdStart;
      this.searchResultDispConditions.clientIdEnd = this.searchConditions.clientIdEnd;
      this.searchResultDispConditions.siteIdStart = this.searchConditions.siteIdStart;
      this.searchResultDispConditions.siteIdEnd = this.searchConditions.siteIdEnd;
    },
    /* 検索条件文字列作成 */
    async makeSelectSql() {
      // 発注データのサブクエリを作成
      let subQuery = 'SELECT';
      subQuery += ' product_id';
      subQuery += ',SUM(IfNull(remaining_quantity,0)) AS remaining_quantity';
      subQuery += ',IfNull(MIN(stock_scheduled),\'0000/00/00\') AS stock_scheduled';
      subQuery += ' FROM ';
      subQuery += ' t_orders ';
      subQuery += ' WHERE ';
      subQuery += ' office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      subQuery += 'AND remaining_quantity > 0 ';
      subQuery += 'AND order_issue_class > 0 ';
      subQuery += 'AND order_class <> ' + Const.OrderClass.direct + ' ';
      subQuery += 'AND is_deleted = 0 ';
      subQuery += 'GROUP BY product_id ';

      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.product_id';
      selectSql += ',products.product_name_kanji';
      selectSql += ',products.unit';
      selectSql += ',SUM(ships.shipping_quantity) AS shipping_quantity';
      selectSql += ',IfNull(stocks.balance,0) AS balance';
      selectSql += ',IfNull(stocks.inventory_reserve_count,0) AS inventory_reserve_count';	
      selectSql += ',IfNull(orders.remaining_quantity,0) AS quantity_stock';
      selectSql += ',IfNull(orders.stock_scheduled,\'0000/00/00\') AS stock_scheduled';
      selectSql += ',IfNull(products_details.place_1,\'\') AS place_1';
      selectSql += ',IfNull(products_details.place_2,\'\') AS place_2';
      selectSql += ',IfNull(products_details.place_3,\'\') AS place_3';
      selectSql += ',IfNull(products_details.place_4,\'\') AS place_4';
      selectSql += ',IfNull(clients.client_name_kanji,\'\') AS client_name_kanji';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN t_ships AS ships ';
      selectSql += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
      selectSql += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
      selectSql += 'INNER JOIN m_products AS products ';
      selectSql += 'ON orders_receives.product_id = products.product_id ';
      selectSql += 'INNER JOIN m_products_details AS products_details ';
      selectSql += 'ON orders_receives.product_id = products_details.product_id ';
      selectSql += 'AND orders_receives.office_id = products_details.office_id ';
      selectSql += 'LEFT JOIN (' + subQuery + ') AS orders ';
      selectSql += 'ON orders_receives.product_id = orders.product_id ';
      selectSql += 'LEFT JOIN m_stocks AS stocks ';
      selectSql += 'ON orders_receives.office_id = stocks.office_id ';
      selectSql += 'AND orders_receives.product_id = stocks.product_id ';
      selectSql += 'AND stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      selectSql += 'LEFT JOIN m_products_orders AS products_orders ';
      selectSql += 'ON products_orders.product_id = products_details.product_id ';
      selectSql += 'AND products_orders.office_id = products_details.office_id ';
      selectSql += 'LEFT JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.supplier + ' ';
      selectSql += 'AND clients.client_id = products_orders.client_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 営業所コード
      selectSql += 'orders_receives.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      // 配送種別
      selectSql += 'AND orders_receives.shipping_type_class BETWEEN \'' + this.changeSearchConditions.shippingTypeClassStart + '\' AND \'' + this.changeSearchConditions.shippingTypeClassEnd + '\' ';
      // ルート（配送コード）
      selectSql += 'AND orders_receives.shipping_code BETWEEN \'' + this.changeSearchConditions.shippingCodeStart + '\' AND \'' + this.changeSearchConditions.shippingCodeEnd + '\' ';
      // 置き場所
      selectSql += 'AND IfNull(orders_receives.place_1,\'\') BETWEEN \'' + this.changeSearchConditions.placeStart + '\' AND \'' + this.changeSearchConditions.placeEnd + '\' ';
      // 確定入力日
      if (getNullStr(this.searchConditions.dateConfirmDate) != '') {
        selectSql += 'AND ships.shipping_confirm_date = \'' + this.searchConditions.dateConfirmDate + '\' ';
      }
      // 得意先
      selectSql += 'AND orders_receives.client_class = 1 ';
      selectSql += 'AND orders_receives.client_id BETWEEN ' + this.changeSearchConditions.clientIdStart + ' AND ' + this.changeSearchConditions.clientIdEnd + ' ';
      // 現場
      selectSql += 'AND orders_receives.site_id BETWEEN ' + this.changeSearchConditions.siteIdStart + ' AND ' + this.changeSearchConditions.siteIdEnd + ' ';
      // 削除されていない製品
      selectSql += 'AND orders_receives.is_deleted = 0 ';
      // ピッキング表印刷区分
      selectSql += 'AND products_details.picking_print_class = ' + Const.PickingPrintClassDef.pick + ' ';
      // 伝票未発行の出荷データ
      selectSql += 'AND ships.sales_issue_class = 0 ';
      // 出荷数がマイナスでない出荷データ
      selectSql += 'AND ships.shipping_quantity >= 0 ';
      /* GROUP BY句 */
      selectSql += 'GROUP BY orders_receives.product_id ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY products_details.place_1, products_details.place_2, products_details.place_3, products_details.place_4, orders_receives.product_id ';
      /* LIMIT */
      selectSql += ' LIMIT 1000';
      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result){
      let strWork = '';
      for(let i = 0; i < result.length; i++){
        // 置場所
        strWork = result[i].place_1.trim() + '-' + result[i].place_2.trim() + '-' + result[i].place_3.trim() + '-' + result[i].place_4.trim();
        let searchResult = {
          ClientNameKanji: result[i].client_name_kanji,
          ProductCode: result[i].product_id,
          ProductName: result[i].product_name_kanji,
          Unit: result[i].unit,
          ShippingQuantity: result[i].shipping_quantity.toLocaleString(),
          Balance: (result[i].balance + result[i].inventory_reserve_count).toLocaleString(),
          QuantityStock: result[i].quantity_stock.toLocaleString(),
          StockScheduled: result[i].stock_scheduled == '0000/00/00' ? result[i].stock_scheduled : formatDate(result[i].stock_scheduled),
          Place: strWork,
        };
        this.itemList.push(searchResult);
      }
      // 伝票の総件数をdataTableの総件数にセット
      this.totalRows = this.itemList.length;
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    /* PDF出力ボタンの押下 */
    async clickOutputPdf(){
      // 得意先元帳PDF出力処理
      //console.log('PDF出力処理');
      let query = {
        selectSalesOffice: this.searchResultDispConditions.selectSalesOffice,
        shippingCodeStart: this.searchResultDispConditions.shippingCodeStart == '' ? '*' : this.searchResultDispConditions.shippingCodeStart,
        shippingCodeEnd: this.searchResultDispConditions.shippingCodeEnd == '' ? '99999' : this.searchResultDispConditions.shippingCodeEnd,
        shippingTypeClassStart: this.searchResultDispConditions.shippingTypeClassStart == '' ? '*' : this.searchResultDispConditions.shippingTypeClassStart,
        shippingTypeClassEnd: this.searchResultDispConditions.shippingTypeClassEnd == '' ? '9' : this.searchResultDispConditions.shippingTypeClassEnd,
        placeStart: this.searchResultDispConditions.placeStart == '' ? '*' : this.searchResultDispConditions.placeStart,
        placeEnd: this.searchResultDispConditions.placeEnd == '' ? '99' : this.searchResultDispConditions.placeEnd,
        dateConfirmDate: this.searchResultDispConditions.dateConfirmDate,
        clientIdStart: this.searchResultDispConditions.clientIdStart == '' ? '000000' : this.searchResultDispConditions.clientIdStart,
        clientIdEnd: this.searchResultDispConditions.clientIdEnd == '' ? '999999' : this.searchResultDispConditions.clientIdEnd,
        siteIdStart: this.searchResultDispConditions.siteIdStart == '' ? '0000' : this.searchResultDispConditions.siteIdStart,
        siteIdEnd: this.searchResultDispConditions.siteIdEnd == '' ? '9999' : this.searchResultDispConditions.siteIdEnd,
      };
      let route = this.$router.resolve({ name: 'SHIPS-CONFIRM-PICKING-PRINT', query: query});
      window.open(route.href, '_blank');
    },
    /**
     * 「得意先コード検索」アイコン押下処理
     */
    showClientSearchModal: function(index) {
      //console.log('得意先コード検索モーダル');
      this.openModalIndex = index;
      this.$bvModal.show('clientSearchModal');
    },
    /**
     * 「得意先コード検索」モーダルのクローズ押下処理
     */
    closeClientSearchModal:function(clientItems){
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined'){
        if (this.openModalIndex == 1) {
          this.searchConditions.clientIdStart = clientItems.detail.client_id;
        } else {
          this.searchConditions.clientIdEnd = clientItems.detail.client_id;
        }
      }
    },
  }
}
</script>