<template>
  <!-- 親取引先マスタ一覧画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-80">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <b-card class="border">
              <b-card-header class="mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip" title="クリックすると検索条件表示/非表示できます。">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" style="display: block; float: left;" role="tablist" aria-expanded="true">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                  <b-button size="sm" v-b-tooltip.hover title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover title="新規登録" @click="clickInputBtn();" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </b-button>
                </h5>
              </b-card-header>
              <br>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-2">
                  <b-container>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <b-row>
                        <!-- 取引先コード（親）入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="clientCodeParent" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group
                              label="取引先コード（親）"
                              label-for="clientCodeParent"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="clientCodeParent" name="clientCodeParent" v-model="searchConditions.clientIdParent" />
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 取引先名（親）入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="取引先名（親）"
                            label-for="clientNameParent"
                          >
                            <b-col lg="12" class="pl-0">
                              <b-form-input type="text" id="clientNameParent" v-model="searchConditions.clientNameParent" />
                            </b-col>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの取引先名（親）（漢字）または取引先名（親）（カナ）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- 取引先名入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="取引先名"
                            label-for="clientName"
                          >
                            <b-col lg="12" class="pl-0">
                              <b-form-input type="text" id="clientName" v-model="searchConditions.clientName" />
                            </b-col>
                            <b-form-text class="text-muted">部分一致検索です。親取引先マスタにおいて登録された確認用の取引先名と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <!-- 取引先コード（子）入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="clientCodeBranch" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group
                              label="取引先コード（子）"
                              label-for="clientCodeBranch"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="clientCodeBranch" name="clientCodeBranch" v-model="searchConditions.clientIdBranch" />
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 取引先名（子）入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="取引先名（子）"
                            label-for="clientNameBranch"
                          >
                            <b-col lg="12" class="pl-0">
                              <b-form-input type="text" id="clientNameBranch" v-model="searchConditions.clientNameBranch" />
                            </b-col>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの取引先名（子）（漢字）または取引先名（子）（カナ）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <b-row class="justify-content-md-center my-2">
                      <b-col lg="3">
                        <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                          <span class="oi oi-magnifying-glass"></span> 検 索 
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table
              show-empty
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <!-- printイベント用のレイアウト -->
              <template v-if="printStatus" #cell(ClientNameKanjiParent)="data">
                <div style="max-width:140px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.ClientNameKanjiParent }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-if="printStatus" #cell(ClientNameKanaParent)="data">
                <div style="max-width:140px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.ClientNameKanaParent }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-if="printStatus" #cell(ClientNameKanji)="data">
                <div style="max-width:140px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.ClientNameKanji }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-if="printStatus" #cell(ClientNameKanjiBranch)="data">
                <div style="max-width:140px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.ClientNameKanjiBranch }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-if="printStatus" #cell(ClientNameKanaBranch)="data">
                <div style="max-width:140px;">
                  <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                    <span>
                      {{ data.item.ClientNameKanaBranch }}
                    </span>
                  </div>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(Operation)="data">
                <div v-show="!printStatus">
                  <!-- 削除ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.left="'同レコードのデータの削除を行います。'" @click="clearAlert(); clickDeleteBtn(data.item.ClientClassParent,data.item.ClientIdParent,data.item.ClientClassBranch,data.item.ClientIdBranch);" class="mr-1">
                    <span class="oi oi-delete"></span> 削除
                  </b-button>&nbsp;
                </div>
              </template>
            </b-table>
          </b-row>
          <b-row>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="['選択された取引先の親子関係を削除します。','よろしいですか？']" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, windowPrint, addOperationLogs, convertSqlLikeSpecialChar, executeTransactSqlList, isSystemEditable, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'clients-parent-child-master-list';

export default {
  name: 'CLIENTS-PARENT-CHILD-MASTER-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  props:['propInsertFlg'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '親取引先マスタ一覧',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // アラート一覧
      alertList: [],
      // 削除対象データ
      delData: {
        ClientClassParent: null,
        ClientIdParent: null,
        ClientClassBranch: null,
        ClientIdBranch: null,
      },
      // 検索条件
      searchConditions: {
        clientIdParent: '',
        clientNameParent: '',
        clientName: '',
        clientIdBranch: '',
        clientNameBranch: '',
      },
      // 検索結果
      itemList: [],
      fields: [
        {
          key: 'ClientIdParent',
          label: '取引先コード(親)',
          sortable: true,
        },
        {
          key: 'ClientNameKanjiParent',
          label: '取引先名(漢字)(親)',
          sortable: true,
        },
        {
          key: 'ClientNameKanaParent',
          label: '取引先名(カナ)(親)',
          sortable: true,
        },
        {
          key: 'ClientNameKanji',
          label: '取引先名',
          sortable: true,
        },
        {
          key: 'ClientIdBranch',
          label: '取引先コード(子)',
          sortable: true,
        },
        {
          key: 'ClientNameKanjiBranch',
          label: '取引先名(漢字)(子)',
          sortable: true,
        },
        {
          key: 'ClientNameKanaBranch',
          label: '取引先名(カナ)(子)',
          sortable: true,
        },
        {
          key: 'Operation',
          label: '操作',
        }
      ],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: 0,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1 || this.perPage == -1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
  },
  /* 関数群 */
  methods: {
    shortkey() {
      this.$router.push({ name: 'CLIENTS-PARENT-CHILD-MASTER-INPUT' })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 初期検索
        //await this.search();
        // 入力画面からの遷移の場合
        if (this.propInsertFlg == true) {
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      let param = {};
      this.itemList = [];
      this.busy = true;
      // ページング機能の初期化
      this.initPaging();
      // CRUD処理
      let where_clause = '';
      where_clause = await this.makeCondition();
      where_clause += 'LIMIT 1000';
      //console.log(where_clause);
      try {
        //console.log('条件取得');
        param = {
          graphqlOperation: 'list_v_clients_parent_child_01',
          where_clause: where_clause
        };
        let resultData = await selectOneTable('v_clients_parent_child_01', where_clause);
        param = {};
        if (resultData != null) {
          await this.setResult(resultData);
          if(resultData.length >= 1000){
            this.resultError.push(DISP_MESSAGES.WARNING['2002']);
          }
          //console.log(resultData);
        }else{
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, param, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result) {
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          ClientClassParent:result[i].client_class_parent,
          ClientIdParent:result[i].client_id_parent,
          ClientNameKanjiParent: result[i].client_name_kanji_parent,
          ClientNameKanaParent: result[i].client_name_kana_parent,
          ClientNameKanji: result[i].client_name_kanji,
          ClientClassBranch:result[i].client_class_branch,
          ClientIdBranch:result[i].client_id_branch,
          ClientNameKanjiBranch: result[i].client_name_kanji_branch,
          ClientNameKanaBranch: result[i].client_name_kana_branch,
          Operation: result[i],
        };
        this.itemList.push(searchResult);
      }
    },
    /* 検索条件文字列作成 */
    async makeCondition() {
      let where_clause = '';
      // 取引先コード（親）
      if (this.searchConditions.clientIdParent != '') {
        where_clause += 'AND client_id_parent = ' + this.searchConditions.clientIdParent + ' ';
      }
      // 取引先名（親）(あいまい検索対象)
      if(this.searchConditions.clientNameParent != '') {
        const clientNameParent = convertSqlLikeSpecialChar(this.searchConditions.clientNameParent);
        where_clause += `AND (REPLACE(REPLACE(CONVERT(client_name_kanji_parent USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientNameParent}%', 'ﾞ', ''), 'ﾟ', '') `;
        where_clause += `OR REPLACE(REPLACE(CONVERT(client_name_kana_parent USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientNameParent}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      // 取引先名(あいまい検索対象)
      if ( this.searchConditions.clientName != '') {
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        where_clause += `AND REPLACE(REPLACE(CONVERT(client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      // 取引先コード（子）
      if (this.searchConditions.clientIdBranch != '') {
        where_clause += 'AND client_id_branch = ' + this.searchConditions.clientIdBranch + ' ';
      }
      // 取引先名（子）(あいまい検索対象)
      if (this.searchConditions.clientNameBranch != '') {
        const clientNameBranch = convertSqlLikeSpecialChar(this.searchConditions.clientNameBranch);
        where_clause += `AND (REPLACE(REPLACE(CONVERT(client_name_kanji_branch USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientNameBranch}%', 'ﾞ', ''), 'ﾟ', '') `;
        where_clause += `OR REPLACE(REPLACE(CONVERT(client_name_kana_branch USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientNameBranch}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }

      return where_clause;
    },
    /* 新規登録ボタン押下時 */
    async clickInputBtn() {
      //console.log('新規登録');
      // 親取引先マスタ入力へ遷移
      this.$router.push({ name: 'CLIENTS-PARENT-CHILD-MASTER-INPUT' });
    },
    /* 削除ボタン押下時 */
    async clickDeleteBtn(clientClassParent,clientIdParent,clientClassBranch,clientIdBranch) {
      //console.log('削除');
      this.delData = { ClientClassParent: clientClassParent, ClientIdParent: clientIdParent, ClientClassBranch: clientClassBranch, ClientIdBranch: clientIdBranch };
      this.$bvModal.show('confirmModal');
    },
    /* 削除実行 */
    async execDeleteBtn() {
      const functionName = 'execDeleteBtn';
      // 取引先親子マスタの削除SQL
      let deleteSql = 'DELETE FROM m_clients_parent_child';
      deleteSql += ' WHERE ';
      // 取引先区分（親）
      deleteSql += 'client_class_parent = ' + this.delData.ClientClassParent + ' ';
      // 取引先コード（親）
      deleteSql += 'AND client_id_parent = ' + this.delData.ClientIdParent + ' ';
      // 取引先区分（子）
      deleteSql += 'AND client_class_branch = ' + this.delData.ClientClassBranch + ' ';
      // 取引先コード（子）
      deleteSql += 'AND client_id_branch = ' + this.delData.ClientIdBranch + ' ';
      //console.log(deleteSql);

      let sqlList = [];
      sqlList.push(deleteSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    /* 削除確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      this.$store.commit('setLoading', true);
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.execDeleteBtn();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>