<template>
  <!-- 取引先コード切替保守画面 -->
  <div>
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="p-4 min-vh-85">
      <b-row class="mt-2">
        <b-col>
          <b-card no-body>
            <b-card-body class="p-2">
              <b-alert v-if="mControl !== null && mControl.switch_client_id_state !== clientCodeSwitchStateClass.RUNNING && mControl.switch_client_id_start_date !== null" class="mx-1 py-2" show variant="info">
                <span class="oi oi-aperture"></span>&nbsp;前回実行結果&emsp;[{{mControl.switch_client_id_start_date}}]&emsp;{{mControl.switch_client_id_state === clientCodeSwitchStateClass.NOT_RUNNING ? '正常終了' : 'エラー終了'}}
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="dangerMessages.length">
                <ul v-for="(message, index) in dangerMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </b-alert>
              <h5>
                <a v-b-toggle.collapse-1 title="クリックすると出力範囲指定を表示/非表示できます。"><span class="oi oi-magnifying-glass"></span> 検索条件</a>
              </h5>
              <b-collapse id="collapse-1" :visible="true" class="mt-4">
                <validation-observer ref="observer">
                  <b-container fluid>
                    <b-row>
                      <b-col md="4">
                        <b-form-group label="取引先区分" description="">
                          <b-select :options="clientClassList" v-model="searchConditions.clientClass">
                            <template #first>
                              <b-form-select-option :value="''">未選択</b-form-select-option>
                            </template>
                          </b-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="8">
                        <b-form-group label="取引先コード" description="取引先コードの範囲を指定します。">
                          <b-container fluid>
                            <b-row>
                              <b-col class="px-0">
                                <validation-provider :rules="{numeric:true,max:6,between:[0,999999],consistency:searchConditions.clientIdTo}" v-slot="{ classes, errors }">
                                  <div :class="classes">
                                    <b-input id="clientIdFrom" v-model="searchConditions.clientIdFrom" maxlength="6"></b-input>
                                    <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                  </div>
                                </validation-provider>
                              </b-col>
                              <b-col md="1" class="d-flex justify-content-center pt-2">～</b-col>
                              <b-col class="px-0">
                                <validation-provider rules="numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                                  <div :class="classes">
                                    <b-input type="text" v-model="searchConditions.clientIdTo" maxlength="6"></b-input>
                                    <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                  </div>
                                </validation-provider>
                              </b-col>
                            </b-row>
                          </b-container>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="my-2 justify-content-md-center">
                      <b-col md="4" lg="3">
                        <!-- 検索ボタン -->
                        <b-button block pill variant="primary" @click="onSearchButtonClick"><span class="oi oi-magnifying-glass"></span> 検索</b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </validation-observer>
              </b-collapse>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
            <b-alert show variant="warning" class="mt-2" v-if="searchErrorMessages.length">
              <ul v-for="(message, index) in searchErrorMessages" :key="index" style="list-style: none;">
                <li>{{ message }}</li>
              </ul>
            </b-alert>
            <div class="row">
              <div class="col-sm-12">
                <b-row>
                  <!-- 1ページあたりの表示選択 -->
                  <b-col lg="6" class="my-1">
                    <b-form-group
                      label="1ページあたりの表示件数"
                      label-for="per-page-select"
                      label-cols-sm="4"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                    </b-form-group>
                  </b-col>
                  <!-- 検索結果検索 -->
                  <b-col lg="6" class="my-1">
                    <b-form-group
                      label="Filter"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- 検索結果 -->
                <b-row>
                  <b-col cols="12" class="px-0">
                    <b-table
                      :bordered="true"
                      :busy="isBusy"
                      :current-page="currentPage"
                      :fields="fields"
                      :filter="filter"
                      :items="items"
                      :per-page="perPage"
                      :responsive="true"
                      show-empty
                    >
                      <!-- テーブル読み込み時表示html -->
                      <template #table-busy>
                        <div class="text-center text-info my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>読み込んでいます...</strong>
                        </div>
                      </template>
                      <template #cell(operation)="data">
                        <b-button size="sm" @click="onEditButtonClick(data)">
                          <span class="oi oi-pencil"></span> 取引先コード切替
                        </b-button>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <!-- 表示レコードをラベル表示 -->
                <b-row>
                  <b-col lg="6">
                    <b-form-group
                      :label="getPagingMessage"
                      class="mt-0 mb-0"
                    />
                  </b-col>
                </b-row>
                <!-- テーブルページネーション -->
                <b-row>
                  <b-col class="my-1">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="filter != null ? filterRows : items.length"
                      :per-page="perPage == -1 ? items.length : perPage"
                      align="center"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>    
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, init, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// モジュール名
const MODULE_NAME = 'client-code-switch-maintenance';
// テーブル最大レコード数
const TABLE_ITEMS_MAX = 1000;

export default {
  name: 'CLIENT-CODE-SWITCH-MAINTENANCE',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      menu_type: 'user',
      title: '取引先コード切替保守',
      clientClassList: Const.ClientClassList,
      clientCodeSwitchStateClass: Const.ClientCodeSwitchStateClass,
      // 検索条件
      searchConditions: {
        clientClass: '',
        clientIdFrom: '',
        clientIdTo: ''
      },
      // エラーメッセージ
      dangerMessages: [],
      // コントロールマスタ
      mControl: null,
      // 検索エラーメッセージ
      searchErrorMessages: [],
      // テーブルに表示するデータ
      items: [],
      // テーブルフィールド定義
      fields: DataTblDef.client_code_switch_table_fields,
      // テーブルビジーフラグ
      isBusy: false,
      // テーブルフィルターキーワード
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage
    };
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.items.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0, 0);
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows = filteredItems.length;
      this.currentPage = DataTblDef.currentPage;
    },
    /**
     * コントロールマスタの値を取得します。
     */
    async fetchData() {
      const functionName = 'fetchData';

      let listMControlResult = null;
      try {
        listMControlResult = await selectOneTable('m_control');
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control'
        }, error);
        this.dangerMessages.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }

      this.mControl = listMControlResult[0];
    },
    /**
     * 検索ボタンクリックイベント処理
     */
    async onSearchButtonClick() {
      if (await this.$refs.observer.validate()) {
        this.isBusy = true;
        await this.search();
        this.isBusy = false;
      }
    },
    /**
     * 取引先を検索します。
     */
    async search() {
      const functionName = 'search';

      // 検索エラーメッセージをクリア
      this.searchErrorMessages = [];

      const whereClauseList = [];
      if (this.searchConditions.clientClass !== '') {
        whereClauseList.push(`client_class = ${this.searchConditions.clientClass}`);
      }
      if (this.searchConditions.clientIdFrom !== '' && this.searchConditions.clientIdTo !== '') {
        whereClauseList.push(`client_id BETWEEN ${this.searchConditions.clientIdFrom} AND ${this.searchConditions.clientIdTo}`);
      } else if (this.searchConditions.clientIdFrom !== '') {
        whereClauseList.push(`client_id >= ${this.searchConditions.clientIdFrom}`);
      } else if (this.searchConditions.clientIdTo !== '') {
        whereClauseList.push(`client_id <= ${this.searchConditions.clientIdTo}`);
      }
      const whereClause = (whereClauseList.length === 0 ? '' : `AND ${whereClauseList.join(' AND ')} `) + `ORDER BY client_class, client_id LIMIT ${TABLE_ITEMS_MAX}`;

      let listMClientsResult = null;
      try {
        listMClientsResult = await selectOneTable('m_clients', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients',
          where_clause: whereClause
        }, error);
        this.searchErrorMessages.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }

      const items = [];
      for (const client of listMClientsResult) {
        items.push({
          id: client.id,
          clientClass: client.client_class,
          clientId: client.client_id,
          clientName: client.client_name_kanji.trim() !== '' ? client.client_name_kanji : client.client_name_kana
        });
      }
      this.items = items;
      if (items.length >= TABLE_ITEMS_MAX) {
        this.searchErrorMessages.push(DISP_MESSAGES.WARNING['2002']);
      }
    },
    /**
     * 編集ボタンクリックイベント処理
     * @param {Object} data - 選択された取引先情報
     */
    onEditButtonClick(data) {
      let route = this.$router.resolve({ name: 'CLIENT-CODE-SWITCH-EDIT', query: { id: data.item.id } });
      window.open(route.href, '_blank');
    }
  }
}
</script>
