<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2">
            <b-card>
              <div class="mb-0 p-2" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive title="クリックで検索条件を表示/非表示できます。">
                    <span class="oi oi-magnifying-glass"></span> 検索条件</a>
                    <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                      <span class="oi oi-print"></span>
                    </b-button>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive title="仕入データの新規登録" @click="clickInputBtn" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="shortkey()" class="d-none"></button>
                  </b-button>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-2">
                  <validation-observer ref="observer">
                    <b-container fluid>
                      <!-- ●●●検索条件●●● -->
                      <b-row class="mt-2">
                        <!-- 営業所入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="営業所"
                            label-for="salesOffice"
                          >
                            <b-form-select
                              id="salesOffice"
                              v-model="searchConditions.selectSalesOffice"
                              :options="searchConditions.salesOffice"
                              value-field="id"
                              text-field="name"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <!-- 伝票種別区分プルダウン -->
                        <b-col lg="6">
                          <b-form-group
                            label="伝票種別"
                            label-for="selectBillClass"
                          >
                            <b-input-group>
                              <b-form-select
                                id="selectBillClass"
                                v-model="searchConditions.selectBillClass"
                                :options="searchConditions.purchaseBillClassList"
                                value-field="id"
                              />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <!-- 伝票番号入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="billingNo" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group
                              label="伝票番号"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" name="billingNo" v-model="searchConditions.billingNo"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 伝票日付入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="orderDateStart" :rules="{dateConsistency:searchConditions.orderDateEnd}" v-slot="{ classes,errors }">
                            <b-form-group
                              label="伝票日付"
                              label-for="datepicker1"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group class="input-daterange" id="datepicker">
                                  <b-form-datepicker id="datepicker1" name="orderDateStart" class="form-control" v-model="searchConditions.orderDateStart"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.orderDateStart=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                  <b-input-group-text>～</b-input-group-text>
                                  <b-form-datepicker id="datepicker2" name="orderDateEnd" class="form-control" v-model="searchConditions.orderDateEnd"
                                    :hide-header = true
                                  ></b-form-datepicker>
                                  <b-input-group-append>
                                    <b-button size="sm" variant="outline-secondary" @click="searchConditions.orderDateEnd=''">
                                      <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                                    </b-button>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                            </b-form-group>
                            <b-form-text class="text-muted">パフォーマンスの関係で過去の全データを表示することは不可能です。日付の範囲は1か月以内としてください。または、伝票番号か取引先コードを指定してください。</b-form-text>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <!-- 取引先コード入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="customerCode" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group
                              label="取引先コード"
                              label-for="customerCode"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="customerCode" name="customerCode" v-model="searchConditions.clientCode"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">完全一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 取引先名入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="取引先名"
                            label-for="customerName"
                          >
                            <b-form-input type="text" id="customerName" name="customerName" v-model="searchConditions.clientName"></b-form-input>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの取引先名（漢字）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2">
                        <!-- 製品コード入力欄 -->
                        <b-col lg="6">
                          <validation-provider name="productCode" rules="numeric" v-slot="{ classes,errors }">
                            <b-form-group
                              label="製品コード"
                              label-for="productCode"
                            >
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-form-input type="text" id="productCode" name="productCode" v-model="searchConditions.productCode"></b-form-input>
                                <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                              </b-col>
                              <b-form-text class="text-muted">前方一致検索です。</b-form-text>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!-- 製品名入力欄 -->
                        <b-col lg="6">
                          <b-form-group
                            label="製品名"
                            label-for="productName"
                          >
                            <b-form-input type="text" id="productName" name="productName" v-model="searchConditions.productName"></b-form-input>
                            <b-form-text class="text-muted">部分一致検索です。登録済みの製品名（漢字）と一致するデータを検索します。</b-form-text>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center my-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </validation-observer>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :items="purchaseList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <!-- 操作列 -->
              <template #cell(Operation)="operation">
                <div v-show="!printStatus">
                  <!-- 編集ボタン -->
                    <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'仕入データの編集を行います。'" @click="clickEditBtn(operation.item.BillingNo, operation.item.MonthlyMonthYear)" class="mr-1">
                      <span class="oi oi-pencil"></span> 編集
                    </b-button>&nbsp;
                  <!-- 削除ボタン -->
                    <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'仕入データの削除を行います。'" @click="clearAlert(); clickDeleteBtn(operation.item.BillingNo, operation.item.MonthlyMonthYear)" :disabled="operation.item.EditFlg==false" class="mr-1">
                      <span class="oi oi-delete"></span> 削除
                    </b-button>&nbsp;
                </div>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, windowPrint, addOperationLogs, formatDate, formatCurDate, convertSqlLikeSpecialChar, getControlMaster, dateConsistency, getListValue, executeSelectSql, executeTransactSqlList, isSystemEditable, isAfterMonthlyUpdate, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'purchase-list';

export default {
  name: 'PURCHASE-LIST',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  props:['parentKbn'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '仕入（在庫なし）一覧',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions:{
        selectSalesOffice: '',
        salesOffice: [],
        selectBillClass: 0,
        purchaseBillClassList: [],
        traditionList: [],
        billingNo: '',
        personInCharge: '',
        orderDateStart: '',
        orderDateEnd: '',
        clientCode: '',
        clientName: '',
        siteName: '',
        productCode: '',
        productName: '',
      },
      // 検索結果
      purchaseList: [],
      busy: false,
      filter: null,
      // 削除確認ダイアログ用
      confirmMessage: [],
      delData: {},
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // 営業所テーブル情報
      mOffices: [],
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchPurchaseListData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'BillingDate',
          label: '伝票日付',
          sortable: true,
        },
        {
          key: 'BillingNo',
          label: '伝票番号',
          sortable: true,
        },
        {
          key: 'BillClass',
          label: '伝票種別',
          sortable: true,
        },
        {
          key: 'SalesOffice',
          label: '営業所名',
          sortable: true,
        },
        {
          key: 'CustomerCode',
          label: '取引先コード',
          sortable: true,
        },
        {
          key: 'CustomerName',
          label: '取引先名',
          sortable: true,
        },
        {
          key: 'Operation',
          label: '操作',
        }
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods:{
    shortkey() {
      this.$router.push({ name: 'PURCHASE-INPUT', query: { propOrderNumber: 0 } })
    },
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /* フェッチ */
    async fetchPurchaseListData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
        ]);
        let loginOfficeId = staffListData[0].office_id;
        //console.log(staffListData);
        //console.log(officeListData);
        // default値挿入
        this.searchConditions.selectSalesOffice = 0;
        // 営業所プルダウン作成
        this.searchConditions.salesOffice.push({id: 0, name: '全て'});
        for (let i = 0; i < officeListData.length; i++) {
          let office = {
            id: officeListData[i].office_id,
            name: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji
          };
          this.searchConditions.salesOffice.push(office);
          this.mOffices.push({id: officeListData[i].office_id, name: officeListData[i].office_name_kanji});
          // default値挿入
          if (loginOfficeId == officeListData[i].office_id) {
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
        // 伝票種別
        this.searchConditions.purchaseBillClassList.push({id: 0, text: '全て'});
        for (let i = 0; i < Const.TraditionList.length; i++) {
          // 「11:通常売上」「12:返品伝票」「14:直送伝票」
          if (Const.TraditionList[i].id == Const.Tradition.purchaseNormal ||
          Const.TraditionList[i].id == Const.Tradition.purchaseReturn ||
          Const.TraditionList[i].id == Const.Tradition.purchaseDirect) {
            this.searchConditions.purchaseBillClassList.push(Const.TraditionList[i]);
          }
        }
        // 伝票日付開始に現在日を設定
        this.searchConditions.orderDateStart = formatCurDate('YYYY-MM-DD');
        // 初期検索
        //await this.search();
        // パラメータによってメッセージを表示
        if (this.parentKbn == 1) {
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        } else {
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      this.purchaseList = [];
      this.busy = true;
      // ページング機能の初期化
      this.initPaging();
      // CRUD処理
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let resultData = null;
      try {
        resultData = await executeSelectSql(selectSql);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      //console.log(resultData);
      if (resultData != null) {
        await this.setResult(resultData);
        if (resultData.length >= 1000) {
          this.resultError.push(DISP_MESSAGES.WARNING['2002']);
        }
      } else {
        // 総件数をdataTableの総件数にセット
        this.totalRows = 0;
      }
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result) {
      // コントロールマスタの現在処理年月の初日を作成
      let controlData = await getControlMaster();
      this.controlMasterData.processMonthYear = controlData.process_month_year;
      let processDate = ('000000' + controlData.process_month_year).slice(-6) + '01';
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          BillingDate: formatDate(result[i].billing_date),
          BillingNo: result[i].billing_no,
          BillClass: getListValue(Const.TraditionList, result[i].bill_class, 'id', 'text'),
          SalesOffice: getListValue(this.mOffices, result[i].office_id),
          CustomerCode: result[i].client_id,
          CustomerName: result[i].client_name_kanji,
          MonthlyMonthYear: result[i].monthly_month_year,
          Operation: result[i],
        };
        // 日付は現在処理年月の初日と同じ、または、未来の場合、編集可
        searchResult.EditFlg = dateConsistency(processDate, searchResult.BillingDate);
        this.purchaseList.push(searchResult);
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql(){
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.billing_no';
      selectSql += ',cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.bill_class';
      selectSql += ',cumulative_transaction.office_id';
      selectSql += ',cumulative_transaction.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',cumulative_transaction.monthly_month_year';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON cumulative_transaction.client_class = clients.client_class ';
      selectSql += 'AND cumulative_transaction.client_id = clients.client_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID
      selectSql += ' cumulative_transaction.transaction_id = ' + '\'' + Const.TransactionId.stocking + '\' ';
      // 伝票種別
      if (this.searchConditions.selectBillClass != 0) {
        selectSql += 'AND cumulative_transaction.bill_class =' + this.searchConditions.selectBillClass + ' ';
      }
      // 営業所コード
      if (this.searchConditions.selectSalesOffice != 0) {
        selectSql += 'AND cumulative_transaction.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      }
      // 伝票番号
      if(this.searchConditions.billingNo != ''){
        selectSql += 'AND cumulative_transaction.billing_no = ' + this.searchConditions.billingNo + ' ';
      }
      // 伝票日付
      if(this.searchConditions.orderDateStart != '' && this.searchConditions.orderDateEnd == ''){
        selectSql += 'AND cumulative_transaction.billing_date >= ' + '\'' + this.searchConditions.orderDateStart + '\' ';
      }else if(this.searchConditions.orderDateStart == '' && this.searchConditions.orderDateEnd != ''){
        selectSql += 'AND cumulative_transaction.billing_date <= ' + '\'' + this.searchConditions.orderDateEnd + '\' ';
      }else if(this.searchConditions.orderDateStart != '' && this.searchConditions.orderDateEnd != ''){
        selectSql += 'AND cumulative_transaction.billing_date BETWEEN ' + '\'' + this.searchConditions.orderDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.orderDateEnd + '\' ';
      }
      // 取引先コード
      if(this.searchConditions.clientCode != ''){
        selectSql += 'AND cumulative_transaction.client_class = ' + Const.ClientClass.supplier + ' ';
        selectSql += 'AND cumulative_transaction.client_id = ' + this.searchConditions.clientCode + ' ';
      }
      // 取引先名(あいまい検索対象)
      if(this.searchConditions.clientName != ''){
        // 特殊文字変換
        const clientName = convertSqlLikeSpecialChar(this.searchConditions.clientName);
        selectSql += `AND (REPLACE(REPLACE(CONVERT(clients.client_name_kanji USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '') `;
        selectSql += `OR REPLACE(REPLACE(CONVERT(clients.client_name_kana USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${clientName}%', 'ﾞ', ''), 'ﾟ', '')) `;
      }
      // 担当者コード
      if(!(this.searchConditions.personInCharge === '')){
        selectSql += 'AND cumulative_transaction.staff_id = ' + this.searchConditions.personInCharge + ' ';
      }
      // 製品コード
      if(this.searchConditions.productCode != ''){
        selectSql += 'AND cumulative_transaction.product_id LIKE \'' + this.searchConditions.productCode + '%\' ';
      }
      // 製品名(あいまい検索対象)
      if(this.searchConditions.productName != ''){
        // 特殊文字変換
        const productName = convertSqlLikeSpecialChar(this.searchConditions.productName);
        selectSql += `AND REPLACE(REPLACE(CONVERT(cumulative_transaction.product_name USING UTF8), 'ﾞ', ''), 'ﾟ', '') COLLATE UTF8_UNICODE_CI LIKE REPLACE(REPLACE('%${productName}%', 'ﾞ', ''), 'ﾟ', '') `;
      }
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' cumulative_transaction.billing_no,cumulative_transaction.monthly_month_year';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.billing_no';
      /* LIMIT */
      selectSql += ' LIMIT 1000';

      return selectSql;
    },
    /* 新規登録ボタン押下時 */
    async clickInputBtn() {
      //console.log('新規登録');
      this.$router.push({ name: 'PURCHASE-INPUT', query: { propOrderNumber: 0 } });
    },
    /* 仕入入力編集ボタン押下時 */
    async clickEditBtn(billingNo, monthlyMonthYear) {
      //console.log('編集');
      //console.log(billingNo);
      let route = this.$router.resolve({ name: 'PURCHASE-EDIT', query: { billingNo: billingNo, monthlyMonthYear: monthlyMonthYear, parentKbn: 0 } });
      window.open(route.href, '_blank');
      //console.log(this.$router);
    },
    /* 削除ボタン押下時 */
    async clickDeleteBtn(billingNo, monthlyMonthYear) {
      //console.log('削除');
      this.delData = { billingNo: billingNo, monthlyMonthYear: monthlyMonthYear };
      this.confirmMessage = [];
      this.confirmMessage.push('選択されたデータを削除します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 削除実行 */
    async execDeleteBtn() {
      const functionName = 'execDeleteBtn';
      // 累積トランザクションの削除SQL
      let deleteSql = 'DELETE FROM ';
      deleteSql += 't_cumulative_transaction';
      deleteSql += ' WHERE ';
      deleteSql += 'billing_no = ' + this.delData.billingNo + ' ';
      deleteSql += 'AND monthly_month_year = ' + this.delData.monthlyMonthYear + ' ';
      deleteSql += 'AND transaction_id = \'' + Const.TransactionId.stocking + '\' ';
      //console.log(deleteSql);

      let sqlList = [];
      sqlList.push(deleteSql);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return false;
      }
      // 月次更新後チェック
      if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
        return false;
      }
      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    // 削除確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.execDeleteBtn();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>