<template>
  <div>
    <div v-if="getMessageFlg">
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="(item, index) of list" v-bind:key="index">
      <TemplateOrderNormal :id="`${constData.chouhyouId}-${index}`" />
    </div>
  </div>
</template>
<script>
import TemplateOrderNormal from '@/assets/svg/order_Normal.svg';
import { setPaperA4, setChouhyouBodyStyle, formatDate, formatCurDate, getFormCounter, addOperationLogs, CreateColRow, CreateUpdateSql, isSystemEditable, getNullStr, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';
import { executeTransactSql } from '@/graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

// モジュール名
const MODULE_NAME = 'purchase-order-direct-delivery';
// 帳票タイトル
const FORM_TITLE = '御 発 注 書';

export default {
  name: 'PURCHASE-ORDER-DIRECT-DELIVERY',
  /* コンポーネント */
  components: {
    TemplateOrderNormal,
  },
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 20,
        chouhyouId: 'idChouhyou',
      },
      // ヘッダ
      menu_type: 'user',
      title: '発注書（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      // ※height:297mmだと2ページ目にはみ出してしまうので微調整
      chouhyouSize: {
        width: '315mm',
        height: '445mm',
      },
      stampFlg: false,
      colStampCX: 0,
      colStampCY: 0,
      addProductCnt: 0,
      // 発注書データ
      list: [],
      // 営業所情報
      officeId: null,
      officeList: [],
      // アラート
      alertDanger: [],
      // ログイン中ユーザー情報
      loginStaffInfo: null,
      // 更新情報データ
      updateDateList: []
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertDanger.length > 0;
    },
  },
  /**
   * beforeMountライフサイクルフック
   */
  beforeMount() {
    this.$store.commit('setLoading', true);
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    this.loginStaffInfo = this.$store.getters.user;
    // 印刷レイアウト設定
    setPaperA4();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    // 初期設定
    await this.fetchData();
    if (this.getMessageFlg) {
      scrollTo(0,0);
    }
    // ウィンドウタイトルを設定「発注書（直送分）_YYYYMMDDHHmmss」
    // 印刷ファイルのデフォルト名 $発注書（特別売上分）_yyyyMMddHHmmss
    const date = new Date();
    let MM = ('0' + (date.getMonth() + 1)).slice(-2);
    let DD = ('0' + date.getDate()).slice(-2);
    let hh = ('0' + date.getHours()).slice(-2);
    let mm = ('0' + date.getMinutes()).slice(-2);
    let ss = ('0' + date.getSeconds()).slice(-2);
    document.title = `発注書（直送分）_${date.getFullYear()}${MM}${DD}${hh}${mm}${ss}`;
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 発注書（直送）データを取得し、発注書のイメージを作成します。
     */
    async fetchData() {
      const functionName = 'createPurchaseOrders';

      try {
        // 強制発行の場合は発注書を作成しません。
        // 発注書発行画面で制御が入っているため、このチェックでエラーになることはないはず。
        if (this.$route.query.orderSlipClass === '3') {
          this.alertDanger.push('強制発行は直送の発注書を発行しません。');
          return;
        }

        // 発注書に出力するデータを取得
        const fetchSqls = [
          this.createSelectPurchaseOrderSQL()
        ];
        const purchaseOrders = (await this.executeSqls(fetchSqls, functionName))[0];
        if (purchaseOrders.length === 0) {
          this.alertDanger.push('出力対象のデータがありません。');
          return;
        }

        // 営業所情報取得
        this.officeId = this.$route.query.salesOffice;
        await this.getOfficesData();

        // 発注毎の一覧を作成
        const productsGroups = await this.groupingClientsProducts(purchaseOrders);
        await this.createPurchaseOrderList(productsGroups);
        // 作成した置換文字データをSVGファイルに設定
        this.setChouhyou();

        // 新規発行の場合は発注データと受注データの更新を行います。
        if (this.$route.query.orderSlipClass === '1') {
          const updateSqls = this.makeUpdateSql();

          // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
          try {
            const msg = await isSystemEditable();
            if (msg !== null) {
              this.alertDanger.push(msg);
              this.list = [];
              this.updateDateList = [];
              return;
            }
          } catch (error) {
            const msg = DISP_MESSAGES.DANGER['3005'];
            await addOperationLogs('Error', MODULE_NAME, functionName, msg, error);
            this.alertDanger.push(msg);
            this.list = [];
            this.updateDateList = [];
            return;
          }

          await this.executeSqls(updateSqls, functionName);
        }
      } catch(error) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        this.list = [];
        this.updateDateList = [];
      }
    },
    /**
     * 発注書データ抽出SQL作成
     * @returns {String} SQL文
     */
    createSelectPurchaseOrderSQL(){
      const query = this.$route.query;

      // 発注データWhere句
      const tOrdersWhereClauses = [];
      // 営業所
      tOrdersWhereClauses.push(`office_id = ${query.salesOffice}`);
      // 発注残数（新規発行の時のみ）
      if (query.orderSlipClass === '1') {
        tOrdersWhereClauses.push('remaining_quantity > 0');
      }
      // 発行区分（新規発行の時「0:未発行」、再発行の時「1:新規発行」）
      tOrdersWhereClauses.push(
        (query.orderSlipClass === '1')
          ? 'order_issue_class = 0'
          : 'order_issue_class > 0'
      );
      // 発注先コード
      if (query.supplierCodeStart && query.supplierCodeStart !== '' && query.supplierCodeEnd && query.supplierCodeEnd !== '') {
        tOrdersWhereClauses.push(`client_id BETWEEN ${query.supplierCodeStart} AND ${query.supplierCodeEnd}`);
      } else if (query.supplierCodeStart && query.supplierCodeStart !== '') {
        tOrdersWhereClauses.push(`client_id >= ${query.supplierCodeStart}`);
      } else if (query.supplierCodeEnd && query.supplierCodeEnd !== '') {
        tOrdersWhereClauses.push(`client_id <= ${query.supplierCodeEnd}`);
      }
      // 注文番号（再発行の場合のみ）
      if (query.orderSlipClass === '2') {
        if ((query.orderNumberStart && query.orderNumberStart !== '') && (query.orderNumberEnd && query.orderNumberEnd !== '')) {
          tOrdersWhereClauses.push(`order_number BETWEEN ${query.orderNumberStart} AND ${query.orderNumberEnd}`);
        } else if (query.orderNumberStart && query.orderNumberStart !== '') {
          tOrdersWhereClauses.push(`order_number >= ${query.orderNumberStart}`);
        } else if (query.orderNumberEnd && query.orderNumberEnd !== '') {
          tOrdersWhereClauses.push(`order_number <= ${query.orderNumberEnd}`);
        }
      }

      return 'SELECT' +
          ' t_orders.order_id' + // 発注番号
          ',t_orders.order_date' + // 発注日付
          ',t_orders.client_id' + // 取引先コード（仕入先コード）
          ',t_orders.product_id' + // 製品コード
          ',t_orders.product_name' + // 製品名
          ',t_orders.service_class' + // サービス区分
          ',t_orders.order_quantity' + // 発注数
          ',t_orders.unit' + // 単位
          ',t_orders.order_amount' + // 発注金額
          ',t_orders.site_id' + // 現場コード
          ',order_issue_class' + // 発注書発行区分
          ',t_orders.order_number' + // 注文番号
          ',t_orders.order_receive_id' + // 受注番号
          ',msc.service_class_name' + // サービス区分名
          ',mpd.supplier_product_id' + // 仕入先製品コード
          ',mc.client_name_kanji' + // 取引先名（漢字）
          ',ms.staff_name_kanji' + // 担当者名（漢字）
          ',ms.staff_name_stamp' + // 担当者名（印鑑）
          ',tor.client_site_name' + // 現場名称
          ',tor.client_site_zip_code' + // 現場郵便番号
          ',tor.client_site_address_1' + // 現場住所１
          ',tor.client_site_address_2' + // 現場住所２
          ',tor.client_site_phone_number' + // 現場TEL
          ',tor.client_name' + // 取引先名称
          ',mcs.order_print_class AS client_print_class' + // 発注書印字区分
          ',mcs.order_print_client_name AS client_print_name' + // 発注書用取引先名
          ',tor.delivery_design' + // 納品指定
          ',mss.supplier_control_site_id ' + // 相手先現場コード
        'FROM ' +
          '(' + // 発注データ
            'SELECT' +
              ' order_id' + // 発注番号
              ',order_date' + // 発注日付
              ',office_id' + // 営業所コード
              ',client_id' + // 取引先コード（仕入先コード）
              ',staff_id' + // 担当者コード
              ',product_id' + // 製品コード
              ',product_name' + // 製品名
              ',service_class' + // サービス区分
              ',SUM(order_quantity) order_quantity' + // 発注数
              ',unit' + // 単位
              ',SUM(order_amount) order_amount' + // 発注金額
              ',order_issue_class' + // 発注書発行区分
              ',site_id' + // 現場コード
              ',order_number' + // 注文番号
              ',order_receive_id ' + // 受注番号
            'FROM ' +
              't_orders ' +
            'WHERE ' +
              'is_deleted = 0 ' +
              'AND order_class = 3 ' +
              `AND ${tOrdersWhereClauses.join(' AND ')} ` +
            'GROUP BY' +
              ' order_id' + // 発注番号
              ',order_date' + // 発注日付
              ',office_id' + // 営業所コード
              ',client_id' + // 取引先コード（仕入先コード）
              ',staff_id' + // 担当者コード
              ',product_id' + // 製品コード
              ',product_name' + // 製品名
              ',service_class' + // サービス区分
              ',unit' + // 単位
              ',site_id' + // 現場コード
              ',order_issue_class' + // 発注書発行区分
              ',order_number' + // 注文番号
              ',order_receive_id' + // 受注番号
          ') t_orders ' +
          'LEFT JOIN m_service_classes msc ' + // サービス区分マスタ
            'ON msc.service_class = t_orders.service_class ' +
          'INNER JOIN m_products_details mpd ' + // 製品詳細マスタ
            'ON mpd.product_id = t_orders.product_id ' +
            'AND mpd.office_id = t_orders.office_id ' +
          'INNER JOIN m_clients mc ' + // 取引先マスタ
            'ON mc.client_class = 2 ' +
            'AND mc.client_id = t_orders.client_id ' +
          'INNER JOIN m_staffs ms ' + // 担当者マスタ
            'ON ms.staff_id = t_orders.staff_id ' +
          'INNER JOIN t_orders_receives tor ' + // 受注データ
            'ON tor.order_receive_row = 1 ' +
            'AND tor.order_receive_id = t_orders.order_receive_id ' +
          'LEFT JOIN m_suppliers_sites mss ' + // 仕入先現場コードマスタ
            'ON mss.supplier_id = t_orders.client_id ' +
            'AND mss.client_id = tor.client_id ' +
            'AND mss.site_id = tor.site_id ' +
          'LEFT JOIN m_clients_sites mcs ' + // 現場マスタ
            'ON mcs.client_id = tor.client_id ' +
            'AND mcs.site_id = tor.site_id ' +
        'ORDER BY' +
          ' t_orders.order_number' +
          ',t_orders.client_id' +
          ',t_orders.order_receive_id' +
          ',t_orders.product_id' +
          ',t_orders.service_class;';
    },
    /**
     * 営業所マスタから本社と横浜事業所の情報を取得します。
     * @returns {Array} 本社と横浜事業の情報
     */
    async getOfficesData() {
      const functionName = 'getOfficesData'

      const whereClause = ' AND office_id = ' + this.officeId + ' ';
      let result = null;
      try {
        result = await selectOneTable('m_offices', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_offices',
          where_clause: whereClause
        }, error);
        throw 'getOfficesData Err';
      }
      for(const mOffices of result) {
        this.officeList.push({
          keyNo : 1,
          officeNameKanji : `${mOffices.office_name_kanji}`,
          zipCode : `〒${getNullStr(mOffices.zip_code)}`,
          address : `${getNullStr(mOffices.address_1)}${getNullStr(mOffices.address_2)}`,
          phoneNumber : `TEL ${getNullStr(mOffices.phone_number)}`,
          faxNumber : `FAX ${getNullStr(mOffices.fax_number)}`,
        });
      }
    },
    /**
     * 製品ごとのデータを注文番号・仕入先・受注番号でグループ分けします。
     * @param {Array} products - 製品リスト
     * @returns {Array} グループ分けされた製品リスト
     */
    async groupingClientsProducts(products) {
      let preOrderNumber = null;
      let clientId = null;
      let orderReceiveId = null;
      let productList = [];
      const groups = [ productList ];
      for (const product of products) {
        if (clientId === null || clientId !== product.client_id || orderReceiveId !== product.order_receive_id || (this.$route.query.orderSlipClass === '2'&& preOrderNumber !== product.order_number)) {
          if (clientId !== null) {
            // 発注データテーブル更新用情報を作成します。
            const updateDate = {
              clientId: productList[0].client_id,
              orderReceiveId: productList[0].order_receive_id
            };
            // 新規発行の場合は注文番号を発行します。
            if (productList[0].order_issue_class === Const.OrderIssueClass.mi) {
              const orderNumber = await this.getOrderNumber();
              for (const product of productList) {
                product.order_number = orderNumber;
              }
              // 発注データテーブル更新用情報に注文番号を追加します。
              updateDate.orderNumber = orderNumber;
            }
            // 発注データテーブル更新用情報リストに発注データテーブル更新用情報を追加します。
            this.updateDateList.push(updateDate);
            productList = [];
            groups.push(productList);
          }
          clientId = product.client_id;
          orderReceiveId = product.order_receive_id;
          if (this.$route.query.orderSlipClass === '2') {
            preOrderNumber = product.order_number;
          }
        }
        productList.push(product);
      }

      // 発注データテーブル更新用情報を作成します。
      const updateDate = {
        clientId: productList[0].client_id,
        orderReceiveId: productList[0].order_receive_id
      };
      // 新規発行の場合は注文番号を発行します。
      if (productList[0].order_issue_class === Const.OrderIssueClass.mi) {
        // 初期ページ、または、取引先コードが前のグループと異なる場合、注文番号を発行
        const orderNumber = await this.getOrderNumber();
        for (const product of productList) {
          product.order_number = orderNumber;
        }
        // 発注データテーブル更新用情報に注文番号を追加します。
        updateDate.orderNumber = orderNumber;
      }
      // 発注データテーブル更新用情報リストに発注データテーブル更新用情報を追加します。
      this.updateDateList.push(updateDate);

      return groups;
    },
    /**
     * 発注書データを作成します。
     * @param {Array} productsGroups - 注文番号・仕入先・受注番号でグループ分けされた製品グループ
     * @returns {Array} 発注書データ
     */
    async createPurchaseOrderList(productsGroups) {
      const purchaseOrderPages = [];
      let preOrderNumber = null;
      let clientId = 0;
      let clientGroup = [];
      for (const products of productsGroups) {
        if (clientId === null || clientId !== products[0].client_id || preOrderNumber !== products[0].order_number) {
          for (let i = 0; i < clientGroup.length; i++) {
            const client = clientGroup[i];
            const pageNo = i + 1;
            client.page = pageNo;
            client.replacements.push({ key: '%P%', value: `${pageNo} / ${clientGroup.length}`, textAnchor: 'end', textLength: 178, chkWidth: false });
          }
          clientGroup = [];
          clientId = products[0].client_id;
          preOrderNumber = products[0].order_number;
        }
        for (const page of await this.createOrdersReceivedPurchaseOrderPages(products)) {
          this.list.push(page);
          clientGroup.push(page);
        }
      }
      for (let i = 0; i < clientGroup.length; i++) {
        const client = clientGroup[i];
        const pageNo = i + 1;
        client.page = pageNo;
        client.replacements.push({ key: '%P%', value: `${pageNo} / ${clientGroup.length}`, textAnchor: 'end', textLength: 178, chkWidth: false });
      }
      return purchaseOrderPages;
    },
    /**
     * 仕入先・受注番号でグループ分けされた製品グループから発注書置換文字列データを作成します。
     * @param {Array} products - 仕入先・受注番号でグループ分けされた製品グループの製品リスト
     * @returns {Array} 発注書置換文字列データ
     */
    async createOrdersReceivedPurchaseOrderPages(products) {
      const ordersReceivedPurchaseOrderPages = [];
      let productCntNokori = products.length;
      let count = 0;
      while (productCntNokori > 0) {
        if (productCntNokori > count * this.constData.cntNormal) {
          ordersReceivedPurchaseOrderPages.push({
            replacements: await this.createReplacementsData(products.slice(count * this.constData.cntNormal, (count + 1) * this.constData.cntNormal))
          });
        } else {
          ordersReceivedPurchaseOrderPages.push({
            replacements: await this.createReplacementsData(products.slice(count * this.constData.cntNormal))
          });
        }
        productCntNokori -= this.constData.cntNormal;
        count++;
      }
      return ordersReceivedPurchaseOrderPages;
    },
    /**
     * 置換文字列データを作成します。
     * @param {Array} products - 仕入先・受注番号でグループ分けされた製品グループの製品リスト
     * @param {Boolean} isProductsBrank - 製品空白フラグ（製品行を表示しない）
     * @returns {Array} 置換文字列データ
     */
    async createReplacementsData(products, isProductsBrank = false) {
      const functionName = 'createReplacementsData';

      const replacements = [];
      for (let i = 0; i < products.length; i++) {
        const product = products[i];

        try {
          // 注文番号
          replacements.push({ key: '%発注番%', value: product.order_number, textAnchor: 'end', textLength: 190, chkWidth: true });
          // 営業
          replacements.push({key: '%営業%', value: this.officeList[0].officeNameKanji, textAnchor: 'start', textLength: 160, chkWidth: true});
          // 発注日付
          if (this.$route.query.orderSlipClass === '1') {
            replacements.push({ key: '%発注日付%', value: formatCurDate(), textAnchor: 'end', textLength: 251, chkWidth: false });
          } else {
            replacements.push({ key: '%発注日付%', value: formatDate(product.order_date), textAnchor: 'end', textLength: 251, chkWidth: false });
          }
          // タイトル
          replacements.push({ key: '%タイトル%', value: FORM_TITLE + '（' + this.officeList[0].officeNameKanji + '）', textAnchor: 'middle', textLength: 925, chkWidth: true });
          // 取引先名（発注書印字区分が「1:印字なし」の場合、発注書用取引先名を指定）
          const clientName = product.order_print_class === 1 ? product.order_print_client_name : product.client_name_kanji;
          replacements.push({key: '%取引先名%', value: clientName, textAnchor: 'start', textLength: 769, chkWidth: true });
          // 営業所情報
          this.setOfficesData(replacements);
          // 担当者名
          const staff_name_kanji = getNullStr(product.staff_name_kanji).trim();
          replacements.push({ key: '%担当者名%', value: staff_name_kanji, textAnchor: 'start', textLength: 416, chkWidth: true });
          // 担当者印
          const staff_name_stamp = getNullStr(product.staff_name_stamp).trim();
          replacements.push({ key: '%社判%', value: staff_name_stamp, textAnchor: 'middle', textLength: 100, chkWidth: false });
          this.stampFlg = (staff_name_stamp != '');
          // 仕入先顧客コード
          const supplierControlSiteId = product.supplier_control_site_id ? product.supplier_control_site_id : '';
          replacements.push({ key: '%仕入先顧客コード%', value: supplierControlSiteId, textAnchor: 'start', textLength: 1320, chkWidth: true });
          // 受注情報（受注番号）
          replacements.push({ key: '%受注情報%', value: `受注番号 ${product.order_receive_id}`, textAnchor: 'start', textLength: 1560, chkWidth: false });
          // 備考：摘要1
          let clientSiteZipCode = getNullStr(product.client_site_zip_code).trim();
          if (clientSiteZipCode != '') {
            clientSiteZipCode = '〒' + clientSiteZipCode + ' ';
          }
          replacements.push({ key: '%摘要1%', value: clientSiteZipCode + getNullStr(product.client_site_address_1).trim() + '  ' + getNullStr(product.client_site_address_2).trim(), textAnchor: 'start', textLength: 1322, chkWidth: true});
          // 備考：摘要2
          let tekiyo2 = getNullStr(product.client_site_name).trim();
          let clientSitePhoneNumber = getNullStr(product.client_site_phone_number).trim();
          if (clientSitePhoneNumber != '') {
            tekiyo2 += '  ' + clientSitePhoneNumber;
          }
          replacements.push({ key: '%摘要2%', value: tekiyo2, textAnchor: 'start', textLength: 1322, chkWidth: true});
          // 備考：摘要3
          if (product.client_print_class == Const.OrderPrintClass.customerNoPrint) {
            // 現場マスタの発注書印字区分が「1：仮伝名称を印字する」の場合、発注書用取引先名
            replacements.push({ key: '%摘要3%', value: getNullStr(product.client_print_name).trim(), textAnchor: 'start', textLength: 1322, chkWidth: true});
          } else {
            // 上記以外の場合は通常の取引先名
            replacements.push({ key: '%摘要3%', value: getNullStr(product.client_name).trim(), textAnchor: 'start', textLength: 1322, chkWidth: true});
          }
          // 備考：摘要4
          replacements.push({ key: '%摘要4%', value: getNullStr(product.delivery_design).trim(), textAnchor: 'end', textLength: 1322, chkWidth: true});

          if (isProductsBrank == true) {
            // 製品空白フラグがtrueの場合、製品行への設定はしない
            break;
          }

          const index = i + 1;
          // 品番
          replacements.push({ key: `%品番${index}%`, value: product.supplier_product_id == null ? '' : product.supplier_product_id, textAnchor: 'start', textLength: 240, chkWidth: true });
          // 商品名
          replacements.push({ key: `%商品名${index}%`, value: product.product_name, textAnchor: 'start', textLength: 710, chkWidth: true });
          // 数量
          replacements.push( {key: `%数量${index}%`, value: `${product.order_quantity} ${getNullStr(product.unit)}`, textAnchor: 'end', textLength: 210, chkWidth: true });
          // 備考（サービス区分名を表示）
          const serviceClassName = product.service_class_name === null ? '' : product.service_class_name;
          replacements.push({key: `%備考${index}%`, value: serviceClassName, textAnchor: 'start', textLength: 360, chkWidth: true});
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            message: '予期しないエラーが発生しました。データ不整合？',
            product: product
          }, error);
          throw 'createReplacementsData';
        }
      }

      // 帳票毎に設定可能な製品の件数
      let productCntByChouhyou = this.constData.cntNormal;

      let productsLength = 0;
      if (isProductsBrank == true) {
        // 製品空白フラグがtrueの場合、全行が空白
        productsLength = 0;
      } else {
        productsLength = products.length;
      }

      // 残りの行が最終行のみの場合は「以上」、それ以外は「以下余白」と空行で埋める
      if (productsLength === productCntByChouhyou - 1) {
        const index = productsLength + 1;
        // 商品名
        replacements.push({ key: `%商品名${index}%`, value: '以上', textAnchor: 'middle', textLength: 710, chkWidth: false });
        // 品番
        replacements.push({ key: `%品番${index}%`, value: '', textAnchor: 'start', textLength: 240, chkWidth: false });
        // 数量
        replacements.push({ key: `%数量${index}%`, value: '', textAnchor: 'end', textLength: 210, chkWidth: false });
        // 備考
        replacements.push({ key: `%備考${index}%`, value: '', textAnchor: 'start', textLength: 360, chkWidth: false });
      } else {
        // 製品
        for (let i = productsLength; i < productCntByChouhyou; i++) {
          const index = i + 1;
          // 商品名
          if (i === productsLength) {
            replacements.push({ key: `%商品名${index}%`, value: '以下余白', textAnchor: 'middle', textLength: 710, chkWidth: false });
          } else {
            replacements.push({ key: `%商品名${index}%`, value: '', textAnchor: 'middle', textLength: 710, chkWidth: false });
          }
          // 品番
          replacements.push({ key: `%品番${index}%`, value: '', textAnchor: 'start', textLength: 240, chkWidth: false });
          // 数量
          replacements.push({ key: `%数量${index}%`, value: '', textAnchor: 'end', textLength: 210, chkWidth: false });
          // 備考
          replacements.push({ key: `%備考${index}%`, value: '', textAnchor: 'start', textLength: 360, chkWidth: false });
        }
      }
      return replacements;
    },
    /* 置換文字列に営業所情報を設定 */
    setOfficesData(replacements) {
      for (const office of this.officeList){
        // 営業名
        replacements.push({ key: `%営業名${office.keyNo}%`, value: office.officeNameKanji, textAnchor: 'start', textLength: 561, chkWidth: true });
        // 郵便
        replacements.push({ key: `%郵便${office.keyNo}%`, value: office.zipCode, textAnchor: 'start', textLength: 501, chkWidth: true });
        // 住所
        replacements.push({ key: `%住所${office.keyNo}%`, value: office.address, textAnchor: 'start', textLength: 501, chkWidth: true });
        // TEL
        replacements.push({ key: `%TEL${office.keyNo}%`, value: office.phoneNumber, textAnchor: 'start', textLength: 501, chkWidth: true });
        // FAX
        replacements.push({ key: `%FAX${office.keyNo}%`, value: office.faxNumber, textAnchor: 'start', textLength: 501, chkWidth: true });
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou() {
      for (let i = 0; i < this.list.length; i++) {
        const svgDoc = document.getElementById(`${this.constData.chouhyouId}-${i}`);
        this.setReplacements(svgDoc, this.list[i].replacements);
        this.setSize(svgDoc);
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements(node, replacements){
      for(let i = 0; i < node.children.length; i++){
        if (node.children[i].tagName == 'text'){
          for (let j = 0; j < node.children[i].children.length; j++) {
            if (node.children[i].children[j].tagName == 'tspan') {
              for (let k = 0; k < replacements.length; k++) {
                if (node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1) {
                  if(replacements[k].key == '%社判%'){
                    this.setStamp(node.children[i], node.children[i].children[j], replacements[k]);
                  }else{
                    this.setTspan(node.children[i], node.children[i].children[j], replacements[k]);
                  }
                  replacements.splice(k, 1);
                  break;
                }
              }
            }
          }
        } else if (node.children[i].tagName == 'circle') {
          // 社判の赤丸
          if (node.children[i].getAttribute('stroke') == 'red') {
            if (this.stampFlg == true) {
              // 社判のテキストに使用するため中心の座標を取得しておく
              this.colStampCX = node.children[i].getAttribute('cx');
              this.colStampCY = node.children[i].getAttribute('cy');
            } else {
              // 赤丸を削除
              node.removeChild(node.children[i]);
              i--; 
            }
          }
        } else if (node.children[i].tagName == 'g'){
          this.setReplacements(node.children[i], replacements);
        }
      }
    },
    /* Textタグ内のテキストを設定 */
    setTspan(tagText, tagTspan, config){
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, getNullStr(config.value));
      // 製品一覧の文字を太字化
      if (getNullStr(config.value).length > 0 &&
      (config.key.indexOf('%商品名') != -1 || config.key.indexOf('%数量') != -1 || config.key.indexOf('%備考') != -1 || config.key.indexOf('%品番') != -1)
      ) {
        tagTspan.setAttribute('font-weight', 'bold');
      }
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if(config.chkWidth == true &&
        tagText.getBBox().width > config.textLength){
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 印鑑を設定 */
    /* ※注：伸ばし棒は縦書きされない（指摘があれば修正） */
    setStamp(tagText, tagTspan, config) {
      switch (config.value.length) {
      case 0:
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
        break;
      case 1:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '65');
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value);
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', 'middle');
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) + 20);
        break;
      case 2:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '40');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', 'middle');
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 5);
        // 2文字目
        tagText.append(this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 35, config.value.substr(1, 1)));
        break;
      case 3:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '30');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', this.colStampCX);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 20);
        // 2文字目
        tagText.append(this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 10, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(this.createStampTspan(this.colStampCX, Number(this.colStampCY) + 40, config.value.substr(2, 1)));
        break;
      case 4:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '35');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', Number(this.colStampCX) + 18);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 8);
        // 2文字目
        tagText.append(this.createStampTspan(Number(this.colStampCX) + 18, Number(this.colStampCY) + 28, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(this.createStampTspan(Number(this.colStampCX) - 18, Number(this.colStampCY) - 8, config.value.substr(2, 1)));
        // 4文字目
        tagText.append(this.createStampTspan(Number(this.colStampCX) - 18, Number(this.colStampCY) + 28, config.value.substr(3, 1)));
        break;
      default:
        // 文字サイズ変更
        tagText.setAttribute('font-size', '28');
        /* 1文字目 */
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.replace(config.key, config.value.substr(0, 1));
        // 中央寄せ
        tagTspan.setAttribute('x', Number(this.colStampCX) + 15);
        tagTspan.setAttribute('text-anchor', config.textAnchor);
        // 文字数と文字サイズから高さを設定
        tagTspan.setAttribute('y', Number(this.colStampCY) - 9);
        // 2文字目
        tagText.append(this.createStampTspan(Number(this.colStampCX) + 15, Number(this.colStampCY) + 19, config.value.substr(1, 1)));
        // 3文字目
        tagText.append(this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) - 18, config.value.substr(2, 1)));
        // 4文字目
        tagText.append(this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) + 10, config.value.substr(3, 1)));
        // 5文字目
        tagText.append(this.createStampTspan(Number(this.colStampCX) - 15, Number(this.colStampCY) + 38, config.value.substr(4, 1)));
        break;
      }
    },
    /* 印鑑用のtspan作成 */
    createStampTspan(x, y, chr) {
      let tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
      tspan.innerHTML = chr;
      tspan.setAttribute('x', x);
      tspan.setAttribute('y', y);
      tspan.setAttribute('text-anchor', 'middle');
      return tspan;
    },
    /* 取得結果セット */
    setSize(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
    /* 伝票番号取得 */
    async getOrderNumber() {
      const functionName = 'getOrderNumber';
      let orderNumber = await getFormCounter(Const.CounterClass.orderNumber, 0, 0, this.loginStaffInfo.login_id, MODULE_NAME, functionName);
      if (orderNumber != null) {
        return orderNumber;
      } else {
        throw '伝票番号の取得に失敗しました。';
      }
    },
    /**
     * executeTransactSql実行用の共通関数
     */
    async executeSqls(sqls, timing) {
      const functionName = `executeSqls (${timing})`;
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        throw error;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        throw result.errors;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          'result.data.executeTransactSql': {
            statusCode: result.data.executeTransactSql.statusCode,
            body: body
          }
        });
        throw body.error;
      }
      return body.data;
    },
    /*
     * 発注データを更新するSQLを生成します。
     * @returns {Array} SQL文のリスト
     */
    makeUpdateSql() {
      const sqls = [];
      for (const updateDate of this.updateDateList) {
        // 取引先コード&受注番号ごとに発注データのUPDATE文を作成します。
        const colList = [];
        // 発注日
        colList.push(CreateColRow('order_date', 'CURRENT_DATE()', 'DATE'));
        // 発注書発行区分
        colList.push(CreateColRow('order_issue_class', Const.OrderIssueClass.sumi, 'NUMBER'));
        // 注文番号
        colList.push(CreateColRow('order_number', updateDate.orderNumber, 'NUMBER'));
        // 発注書発行日付
        colList.push(CreateColRow('order_issue_date', 'CURRENT_DATE()', 'DATE'));
        // 更新日
        colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginStaffInfo.login_id, 'VARCHAR'));
        const sql = CreateUpdateSql(colList, 't_orders') +
          ' WHERE ' +
            `client_id = ${updateDate.clientId} ` + // 取引先コード
            `AND order_receive_id = ${updateDate.orderReceiveId}`; // 受注番号
        sqls.push(sql);
      }
      return sqls;
    },
  },
}
</script>
<style scoped>
</style>