<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mt-2 mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 出荷確定入力</strong></h5>
                <b-button pill v-b-tooltip.hover.noninteractive title="受注入力照会に戻る" @click="cancel" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </div>
            </b-media-body>
          </b-media>
          <b-card class="mb-3">
            <b-card-header v-if="getMessageFlg">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveData();" id="inputForm" class="form-horizontal">
                  <b-row>
                    <b-col>
                      <b-table-simple
                        small
                      >
                        <b-tbody>
                          <b-tr>
                            <b-th>受注番号</b-th>
                            <b-td colspan="7">{{receivedOrderId}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>配送種別</b-th>
                            <b-td>{{selectDeliveryText}}</b-td>
                            <b-th>ルート</b-th>
                            <b-td>{{onSite.deliveryCode}}</b-td>
                            <b-th>伝票種別</b-th>
                            <b-td>{{getBillingClassText}}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 売上計上日 -->
                    <b-col lg="6">
                      <validation-provider name="dateSalesRecord" :rules="{required: true, oldProcessMonthYear: controlMasterData.processMonthYear}" v-slot="{ classes,errors }">
                        <b-form-group
                          label="売上計上日"
                          label-for="dateSalesRecord"
                        >
                          <b-row>
                            <b-col lg="12" :class="classes">
                              <b-input-group class="input-daterange">
                                <b-form-datepicker
                                  id="dateSalesRecord"
                                  name="dateSalesRecord"
                                  v-model="dateSalesRecord"
                                  calendar-width="50%"
                                ></b-form-datepicker>
                              </b-input-group>
                            </b-col>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                          <b-form-text class="text-muted">売上伝票の発行日を設定します。</b-form-text>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- 配送順 -->
                    <b-col lg="6">
                      <validation-provider name="shippingOrderNumber" rules="required|between:1,99" v-slot="{ classes,errors }">
                        <b-form-group
                          label="配送順"
                          label-for="shippingOrderNumber"
                        >
                          <b-row>
                            <b-col lg="8" :class="classes">
                              <b-form-input id="shippingOrderNumber" name="shippingOrderNumber" type="number" v-model.number="shippingOrderNumber" />
                            </b-col>
                            <b-col lg="8" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </b-row>
                          <b-form-text class="text-muted">伝票印刷時の順番を設定します。</b-form-text>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-table-simple
                        small
                      >
                        <b-tbody>
                          <b-tr>
                            <b-th>受注日</b-th>
                            <b-td>{{dateOrderReceiveDate}}</b-td>
                            <b-th>締日</b-th>
                            <b-td colspan="3">{{suppliers.closingDate}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>取引先</b-th>
                            <b-td colspan="5">{{suppliers.code + ' ' + suppliers.name}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>住所１</b-th>
                            <b-td colspan="3">{{suppliers.address1}}</b-td>
                            <b-th>部署１</b-th>
                            <b-td>{{suppliers.department1}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>住所２</b-th>
                            <b-td colspan="3">{{suppliers.address2}}</b-td>
                            <b-th>部署２</b-th>
                            <b-td>{{suppliers.department2}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>郵便番号</b-th>
                            <b-td>{{suppliers.zipCode}}</b-td>
                            <b-th>TEL</b-th>
                            <b-td>{{suppliers.tel}}</b-td>
                            <b-th>担当者</b-th>
                            <b-td>{{suppliers.personInCharge}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>宛名印字区分</b-th>
                            <b-td>{{onSite.clientPrintClass}}</b-td>
                            <b-th>印刷用宛名</b-th>
                            <b-td colspan="3">{{onSite.clientPrintNameText}}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                      <b-form-text class="text-muted">宛名印字区分が1の場合、取引先名に印刷用宛名が印字され、取引先の住所、部署、郵便番号、TELは印字されない。</b-form-text>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 製品 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :items="productList"
                            :fields="fields"
                          >
                            <template #table-caption>製品テーブル</template>
                            <template #cell(ProductName)="data">
                              <span v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark + ' ' : '' }}</span>
                              {{ data.item.ProductName }}
                            </template>
                            <template #cell(ReserveQuantity)="data">
                              {{ data.item.ReserveQuantity }}
                            </template>
                            <template #cell(ShippingQuantity)="data">
                              <validation-provider :rules="{required: true, ships_between: {reserveQuantity: data.item.ReserveQuantity, salesIssueQuantity: 0}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 80px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input type="number" v-model.number="data.item.ShippingQuantity" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-table-simple
                        small
                      >
                        <b-tbody>
                          <b-tr>
                            <b-th>納品指定日</b-th>
                            <b-td colspan="3">{{orderDate + ' ' + selectDeliveryDesignation + ' ' + deliveryNote}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>現場</b-th>
                            <b-td colspan="3">{{onSite.code + ' ' + onSite.deliveryCode + ' ' + onSite.name}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>郵便番号</b-th>
                            <b-td colspan="3">{{onSite.zipCode}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>住所１</b-th>
                            <b-td>{{onSite.address1}}</b-td>
                            <b-th>住所２</b-th>
                            <b-td>{{onSite.address2}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>納品場所</b-th>
                            <b-td colspan="3">{{suppliers.deliveryPlace}}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>チェック</b-th>
                            <b-td>{{suppliers.check}}</b-td>
                            <b-th>TEL</b-th>
                            <b-td>{{onSite.tel}}</b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-col>
                  </b-row>
                  <!-- 保存ボタン -->
                  <b-row class="mt-2 justify-content-md-center">
                    <b-col lg="2">
                      <b-button v-shortkey="['f10']" @shortkey="clearAlert(); saveData();" pill variant="success" type="submit" class="btn btn-block" :disabled="statusFlg.editFlg==false">
                        <span class="oi oi-circle-check"></span> 保存
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, getControlMaster, getNextBusinessDate, formatDate, formatCurDate, formatDateCalc, getListValue, addOperationLogs, executeSelectSql, CreateColRow, CreateInsertSql, executeSqlList, isSystemEditable, isOtherSessionUpdated, checkOldClosingDate, checkInvoiceIssue, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'ships-confirm-input';

export default {
  name: 'SHIPS-CONFIRM-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '出荷確定入力',
      loginId: '',
      listUpdatedSelect: [],
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 営業所コード
      selectSalesOffice: null,
      // 受注日付
      dateOrderReceiveDate: '',
      // 配送プルダウン
      selectDelivery: '',
      selectDeliveryText: '',
      // 伝種プルダウン
      selectBillClass: null,
      selectBillClassText: '',
      // 取引先系
      suppliers: {
        closingDate: '',
        closingDay: 0,
        code: '',
        name: '',
        class: '',
        address1: '',
        address2: '',
        zipCode: '',
        department1: '',
        department2: '',
        tel: '',
        personInChargeId: '',
        personInCharge: '',
        sundriesClass: '',
        check: '',
      },
      // 納品指定日
      orderDate: '',
      // 納品指定区分
      selectDeliveryDesignation: '',
      selectDeliveryDesignationText: '',
      deliveryDesignation: [],
      // 納品メモ
      deliveryNote: '',
      // 現場系
      onSite: {
        deliveryCode: '',
        code: '',
        name: '',
        zipCode: '',
        address1: '',
        address2: '',
        deliveryPlace: '',
        tel: '',
        clientPrintClass: '',
        clientPrintName: '',
        clientPrintNameText: '',
      },
      // 取引先モーダル用の引数
      propClientClass: null,
      // テーブル定義
      productList:[],
      // サービス区分
      serviceList: [],
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        lightTaxMark: '',
      },
      // トランザクションSQLリスト
      transactSqlList:[],
      // ステータスフラグ
      statusFlg: {
        editFlg: true,
      },
      // 売上計上日
      dateSalesRecord: '',
      // 配送順
      shippingOrderNumber: 1,
      // 確認ダイアログ用
      confirmMessage: [],
      // 配送種別区分取得
      shippingTypeClass: Const.ShippingTypeClass,
      // 伝種（受注伝票種別区分）取得
      orderReceiveBillClass: Const.OrderReceiveBillClass,
      // 諸口区分取得
      sundriesClass: Const.SundriesClass,
      // 発注書印字区分取得
      orderPrintClass: Const.OrderPrintClass,
      // 定数（htmlで使用）
      htmlConst: {
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // パラメータ
      receivedOrderId: null,
      tabFlg: '0',
      // 画面を閉じる
      winClose: false,
    }
  },
  computed:{
    fields: function() {
      return [
        {
          key: 'ProductCode',
          label: '製品コード',
        },
        {
          key: 'ProductName',
          label: '製品名',
        },
        {
          key: 'ServiceClassText',
          label: 'サービス区分',
        },
        {
          key: 'Unit',
          label: '単位',
        },
        {
          key: 'Quantity',
          label: '受注数',
          tdClass: 'text-right',
        },
        {
          key: 'RemainingQuantity',
          label: '受注残',
          tdClass: 'text-right',
        },
        {
          key: 'ReserveQuantity',
          label: '引当数',
          tdClass: 'text-right',
        },
        {
          key: 'ShippingQuantity',
          label: '出荷数',
        },
      ]
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 配送種別と伝種毎の伝票の意味のテキスト */
    getBillingClassText: function() {
      // 返品の場合
      if (this.selectBillClass == this.orderReceiveBillClass.return) {
        return getListValue(Const.OrderReceiveBillClassList, this.orderReceiveBillClass.return, 'id', 'text');
      // 特別受注の場合
      } else if (this.selectBillClass == this.orderReceiveBillClass.special) {
        return getListValue(Const.OrderReceiveBillClassList, this.orderReceiveBillClass.special, 'id', 'text');
      } else {
        switch (this.selectDelivery) {
        // 宅配の場合
        case this.shippingTypeClass.delivery:
          return getListValue(Const.DeliveryList, this.shippingTypeClass.delivery, 'id', 'text');
        // 営業持参の場合
        case this.shippingTypeClass.bring:
          return getListValue(Const.DeliveryList, this.shippingTypeClass.bring, 'id', 'text');
        // 直送の場合
        case this.shippingTypeClass.direct:
          return getListValue(Const.DeliveryList, this.shippingTypeClass.direct, 'id', 'text');
        // 通常の場合
        case this.shippingTypeClass.normal:
          return getListValue(Const.OrderReceiveBillClassList, this.orderReceiveBillClass.normal, 'id', 'text');
        default:
          return''
        }
      }
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.receivedOrderId = this.$route.query.receivedOrderId;
    this.tabFlg = this.$route.query.tabFlg;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 更新時の排他制御用の変数作成
        await this.createUpdatedSelect();
        // ログインユーザーの情報(LoginID)
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let deliveryListData = null;
        let serviceListData = null;
        let controlData = null;
        [deliveryListData, serviceListData, this.dateSalesRecord, controlData] = await Promise.all([
          selectOneTable('m_deliverys_designs_classes'),
          selectOneTable('m_service_classes'),
          getNextBusinessDate(formatCurDate('YYYY-MM-DD')),
          getControlMaster(),
        ]);
        // 納品指定区分を取得
        //console.log(deliveryListData);
        this.deliveryDesignation.push({id: '', name: '', text: ''});
        for (let i = 0; i < deliveryListData.length; i++) {
          let deliveryDesignation = {
            id: deliveryListData[i].delivery_design_class,
            name: deliveryListData[i].delivery_design_name,
            text: deliveryListData[i].delivery_design_class + '：' + deliveryListData[i].delivery_design_name,
          };
          this.deliveryDesignation.push(deliveryDesignation);
        }
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        //console.log(serviceListData);
        for (let i = 0; i < serviceListData.length; i++) {
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // 現在処理年月取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 既存データ設定
        await this.setReceivedOrder();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 受注データ設定 */
    async setReceivedOrder() {
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null) {
        await this.setResult(dataResult);
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索SQL作成（初期値） */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.order_receive_row';
      selectSql += ',orders_receives.office_id';
      selectSql += ',orders_receives.order_receive_date';
      selectSql += ',orders_receives.shipping_type_class';
      selectSql += ',orders_receives.order_receive_bill_class';
      selectSql += ',orders_receives.client_class';
      selectSql += ',orders_receives.client_id';
      selectSql += ',orders_receives.client_name AS client_name_kanji';
      selectSql += ',clients.closing_date';
      selectSql += ',orders_receives.zip_code';
      selectSql += ',orders_receives.address_1';
      selectSql += ',orders_receives.address_2';
      selectSql += ',orders_receives.department_1';
      selectSql += ',orders_receives.department_2';
      selectSql += ',orders_receives.phone_number';
      selectSql += ',orders_receives.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',clients.check_contents';
      selectSql += ',orders_receives.delivery_design_date';
      selectSql += ',orders_receives.delivery_design_class';
      selectSql += ',orders_receives.delivery_design';
      selectSql += ',orders_receives.site_id';
      selectSql += ',orders_receives.shipping_code';
      selectSql += ',orders_receives.client_site_name AS client_site_name_kanji';
      selectSql += ',orders_receives.client_site_zip_code';
      selectSql += ',orders_receives.client_site_address_1';
      selectSql += ',orders_receives.client_site_address_2';
      selectSql += ',orders_receives.delivery_location';
      selectSql += ',orders_receives.client_site_phone_number';
      selectSql += ',IfNull(clients_sites.order_print_class,' + Const.OrderPrintClass.customerPrint + ') AS order_print_class';
      selectSql += ',IfNull(clients_sites.order_print_client_name,\'\') AS order_print_client_name';
      selectSql += ',orders_receives.product_id';
      selectSql += ',orders_receives.product_name AS product_name_kanji';
      selectSql += ',orders_receives.service_class';
      selectSql += ',orders_receives.order_receive_quantity';
      selectSql += ',orders_receives.unit';
      selectSql += ',orders_receives.remaining_quantity';
      selectSql += ',orders_receives.reserve_quantity';
      selectSql += ',orders_receives.stock_scheduled_reserve_receive';
      selectSql += ',orders_receives.shipping_quantity';
      selectSql += ',orders_receives.product_manual_input_class';
      selectSql += ',CASE WHEN ships.order_receive_id IS NULL THEN 0 ELSE 1 END AS torokuzumi_flg';
      selectSql += ',products.product_tax_rate_class_sales';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN m_clients AS clients USING (client_class, client_id)';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON orders_receives.staff_id = staffs.staff_id ';
      selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
      selectSql += 'ON clients_sites.client_id = orders_receives.client_id ';
      selectSql += 'AND clients_sites.site_id = orders_receives.site_id ';
      selectSql += 'LEFT JOIN t_ships AS ships ';
      selectSql += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
      selectSql += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON orders_receives.product_id = products.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 引数の受注番号で絞り込む
      selectSql += ' orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      // 削除された製品は表示しない
      selectSql += 'AND orders_receives.is_deleted = 0 ';
      /* GROUP BY句 */
      selectSql += 'GROUP BY orders_receives.order_receive_id,orders_receives.order_receive_row ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY orders_receives.order_receive_id,orders_receives.order_receive_row ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        // 営業所
        this.selectSalesOffice = result[0].office_id;
        // 受注日付
        this.dateOrderReceiveDate = formatDate(result[0].order_receive_date);
        // 配送種別
        this.selectDelivery = result[0].shipping_type_class;
        this.selectDeliveryText = getListValue(Const.DeliveryList, result[0].shipping_type_class, 'id', 'text');
        // 伝種
        this.selectBillClass = result[0].order_receive_bill_class;
        this.selectBillClassText = getListValue(Const.OrderReceiveBillClassList, result[0].order_receive_bill_class, 'id', 'text');
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        if (result[0].closing_date == 99) {
          this.suppliers.closingDate = formatDateCalc(('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01', 0, 0, 0, true);
        } else {
          this.suppliers.closingDate = formatDate(('000000' + this.controlMasterData.processMonthYear).slice(-6) + ('00' + result[0].closing_date).slice(-2));
        }
        this.suppliers.closingDay = result[0].closing_date;
        this.suppliers.address1 = result[0].address_1;
        this.suppliers.address2 = result[0].address_2;
        this.suppliers.department1 = result[0].department_1;
        this.suppliers.department2 = result[0].department_2;
        this.suppliers.tel = result[0].phone_number === 'undefined' ? '' : result[0].phone_number;
        this.suppliers.personInChargeId = result[0].staff_id;
        this.suppliers.personInCharge = result[0].staff_id + '：' + result[0].staff_name_kanji;
        this.suppliers.zipCode = result[0].zip_code === 'undefined' ? '' : result[0].zip_code;
        this.suppliers.check = result[0].check_contents;
        // 現場系
        this.onSite.deliveryCode = result[0].shipping_code;
        this.onSite.code = result[0].site_id;
        this.onSite.name = result[0].client_site_name_kanji;
        this.onSite.zipCode = result[0].client_site_zip_code;
        this.onSite.address1 = result[0].client_site_address_1;
        this.onSite.address2 = result[0].client_site_address_2;
        this.onSite.deliveryPlace = result[0].delivery_location;
        this.onSite.tel = result[0].client_site_phone_number;
        this.onSite.clientPrintClass = Number(result[0].order_print_class);
        this.onSite.clientPrintName = result[0].order_print_client_name;
        this.onSite.clientPrintNameText = this.onSite.clientPrintClass == this.orderPrintClass.customerPrint ? '' : this.onSite.clientPrintName;
        // 納品指定日
        this.orderDate = result[0].delivery_design_date == null ? '' : formatDate(result[0].delivery_design_date);
        // 納品指定区分
        this.selectDeliveryDesignation = result[0].delivery_design_class;
        this.selectDeliveryDesignationText = getListValue(this.deliveryDesignation, this.selectDeliveryDesignation, 'id', 'text');
        // 納品メモ
        this.deliveryNote = result[0].delivery_design;
        // 全件参照して製品一覧を作成
        for (let i = 0; i < result.length; i++) {
          if (i == this.productList.length) {
            this.addTableRow();
          }
          this.productList[i].OrderReceiveRow = result[i].order_receive_row;
          this.productList[i].ProductCode = result[i].product_id;
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i].ProductTaxRateClass = result[i].product_tax_rate_class_sales;
          this.productList[i].ServiceClass = result[i].service_class;
          this.productList[i].ServiceClassText = getListValue(this.serviceList, result[i].service_class, 'id', 'text');
          this.productList[i].Quantity = result[i].order_receive_quantity;
          this.productList[i].Unit = result[i].unit;
          this.productList[i].RemainingQuantity = result[i].remaining_quantity;
          if (this.selectDelivery == this.shippingTypeClass.direct || this.selectBillClass == this.orderReceiveBillClass.special) {
            // 配送種別区分「4：直送」、または、伝種「15：特別受注」
            // 入荷予定引当数受注を引当数として見る
            this.productList[i].ReserveQuantity = result[i].stock_scheduled_reserve_receive;
          } else {
            // 上記以外の場合
            // 引当済数を引当数として見る
            this.productList[i].ReserveQuantity = result[i].reserve_quantity;
          }
          this.productList[i].PreShippingQuantity = result[i].shipping_quantity;
          if (this.productList[i].Quantity >= 0) {
            if (this.productList[i].ReserveQuantity > this.productList[i].RemainingQuantity) {
              this.productList[i].ShippingQuantity = this.productList[i].RemainingQuantity;
            } else {
              this.productList[i].ShippingQuantity = this.productList[i].ReserveQuantity;
            }
          } else {
            if (this.productList[i].ReserveQuantity < this.productList[i].RemainingQuantity) {
              this.productList[i].ShippingQuantity = this.productList[i].RemainingQuantity;
            } else {
              this.productList[i].ShippingQuantity = this.productList[i].ReserveQuantity;
            }
          }
          this.productList[i].SundriesClass = result[i].product_manual_input_class;
          // 出荷データを登録済みの場合は
          if (result[i].torokuzumi_flg == 1) {
            this.statusFlg.editFlg = false;
            if (this.alertWarning.length == 0) {
              this.alertWarning.push(DISP_MESSAGES.WARNING['2032']);
            }
          }
        }
      }
    },
    // キャンセルボタン
    cancel: function() {
      this.$router.push({ name: 'RECEIVED-ORDER-INPUT-INQUIRY', query: { receivedOrderId: this.receivedOrderId, parentKbn: 0, reserveErrFlg: 0 }});
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = {
        OrderReceiveRow: null,
        ProductCode: null,
        ProductName: '',
        ProductTaxRateClass: Const.ProductTaxRateClass.normalTax,
        ServiceClass: '',
        ServiceClassText: '',
        Quantity: 0,
        Unit: '',
        RemainingQuantity: 0,
        ReserveQuantity: 0,
        PreShippingQuantity: 0,
        ShippingQuantity: 0,
        SundriesClass: null,
      };
      this.productList.push(newProduct);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* 保存ボタンの押下 */
    async saveData() {
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      } else {
        // 請求書発行登録済みの場合、削除不可
        let errMessage = await checkInvoiceIssue(this.suppliers.code, this.dateSalesRecord, this.suppliers.closingDay, this.onSite.code);
        if (errMessage != '') {
          this.alertWarning.push(errMessage);
          scrollTo(0,0);
        } else {
          //await this.saveConfirm();
          if (await this.confirmOldClosingDate() == true) {
            await this.closeConfirmModal();
          }
        }
      }
    },
    /* 保存時の確認ダイアログ表示 */
    /*
    async saveConfirm() {
      console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    */
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal() {
      const functionName = 'closeConfirmModal';
      try {
        this.$store.commit('setLoading', true);
        await this.confirmSave();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      if (this.winClose == true) {
        window.close();
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 排他制御チェック
      if (await isOtherSessionUpdated(this.listUpdatedSelect) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2027']);
        return;
      }
      // 保存処理
      //console.log('保存処理開始');
      // 受注データの登録(SQLの作成)
      if (await this.execSave() == true) {
        if (this.tabFlg == '1') {
          this.winClose = true;
        } else {
          // 受注入力照会画面へ遷移
          this.$router.push({ name: 'RECEIVED-ORDER-INPUT-INQUIRY', query: { receivedOrderId: this.receivedOrderId, parentKbn: 1, reserveErrFlg: 0 } });
        }
      }
      //console.log('保存処理終了');
    },
    /* 保存実行 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      try {
        // 出荷データの登録
        this.insertShips();

        //console.log(this.transactSqlList);

        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.alertDanger.push(msg);
            return false;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
          return false;
        }

        // 作成した登録用SQLを全実行
        await executeSqlList(this.transactSqlList);
        retResult = true;
      } catch (error) {
        // 異常系操作ログの登録
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        console.log(error);
      }
      return retResult;
    },
    /* 出荷データ登録処理 */
    insertShips: function(){
      //console.log('出荷データ登録SQL作成');
      // CRUD処理
      let bulkInsertSqlStart = '';
      let bulkInsertSqlSelect = '';
      for(let i = 0; i < this.productList.length; i++) {
        // 出荷数が0以外、または、受注数が0で諸口区分が1の製品の場合、登録
        if (this.productList[i].ShippingQuantity != 0 ||
        (this.productList[i].Quantity == 0 && this.productList[i].SundriesClass == this.sundriesClass.shokuchi)) {
          let colList = [];
          // INSERT文の先頭部分、初回のみ作成
          if (bulkInsertSqlStart == '') {
            // 受注番号
            colList.push(CreateColRow('order_receive_id', null, 'NUMBER'));
            // 受注行番号
            colList.push(CreateColRow('order_receive_row', null, 'NUMBER'));
            // 出荷確定日
            colList.push(CreateColRow('shipping_confirm_date', null, 'DATE'));
            // 売上計上日
            colList.push(CreateColRow('sales_record_date', null, 'DATE'));
            // 営業所コード
            colList.push(CreateColRow('office_id', null, 'NUMBER'));
            // 配送種別区分
            colList.push(CreateColRow('shipping_type_class', null, 'VARCHAR'));
            // 配送コード
            colList.push(CreateColRow('shipping_code', null, 'VARCHAR'));
            // 配送順
            colList.push(CreateColRow('shipping_order_number', null, 'NUMBER'));
            // 取引先区分
            colList.push(CreateColRow('client_class', null, 'NUMBER'));
            // 取引先コード
            colList.push(CreateColRow('client_id', null, 'NUMBER'));
            // 現場コード
            colList.push(CreateColRow('site_id', null, 'NUMBER'));
            // 製品コード
            colList.push(CreateColRow('product_id', null, 'NUMBER'));
            // 出荷数
            colList.push(CreateColRow('shipping_quantity', null, 'NUMBER'));
            // 受注数
            colList.push(CreateColRow('order_receive_quantity', null, 'NUMBER'));
            // 引当数
            colList.push(CreateColRow('reserve_quantity', null, 'NUMBER'));
            // 売上伝票発行区分
            colList.push(CreateColRow('sales_issue_class', null, 'NUMBER'));
            // 売上伝票番号
            colList.push(CreateColRow('sales_number', null, 'NUMBER'));
            // 売上伝票行No
            colList.push(CreateColRow('sales_number_row', null, 'NUMBER'));
            // 在庫置き場所1
            colList.push(CreateColRow('place_1', null, 'VARCHAR'));
            // 在庫置き場所2
            colList.push(CreateColRow('place_2', null, 'VARCHAR'));
            // 在庫置き場所3
            colList.push(CreateColRow('place_3', null, 'VARCHAR'));
            // 在庫置き場所4
            colList.push(CreateColRow('place_4', null, 'VARCHAR'));
            // 明細単位区分
            colList.push(CreateColRow('details_unit_class', null, 'NUMBER'));
            // 作成ユーザー
            colList.push(CreateColRow('created_user', null, 'VARCHAR'));
            // 更新ユーザー
            colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
            bulkInsertSqlStart = 'INSERT INTO t_ships (' + CreateInsertSql(colList, 'col', 't_ships') + ') ';
            //console.log(bulkInsertSqlStart);
          }
          let selectSql = '';
          /* SELECT句 */
          selectSql += 'SELECT ';
          // 受注番号
          selectSql += ' order_receive_id';
          // 受注行番号
          selectSql += ',order_receive_row';
          // 出荷確定日
          selectSql += ',CURDATE()';
          // 売上計上日
          selectSql += ',\'' + this.dateSalesRecord + '\'';
          // 営業所コード
          selectSql += ',office_id';
          // 配送種別区分
          selectSql += ',shipping_type_class';
          // 配送コード
          selectSql += ',shipping_code';
          // 配送順
          selectSql += ',' + this.shippingOrderNumber;
          // 取引先区分
          selectSql += ',client_class';
          // 取引先コード
          selectSql += ',client_id';
          // 現場コード
          selectSql += ',site_id'
          // 製品コード
          selectSql += ',product_id';
          // 出荷数
          selectSql += ',' + this.productList[i].ShippingQuantity;
          // 受注数
          selectSql += ',order_receive_quantity';
          // 引当数
          selectSql += ',' + this.productList[i].ReserveQuantity;
          // 売上伝票発行区分
          selectSql += ',' + Const.SalesIssueClass.mi;
          // 売上伝票番号
          selectSql += ',0';
          // 売上伝票行No
          selectSql += ',0';
          // 在庫置き場所1
          selectSql += ',place_1';
          // 在庫置き場所2
          selectSql += ',place_2';
          // 在庫置き場所3
          selectSql += ',place_3';
          // 在庫置き場所4
          selectSql += ',place_4';
          // 明細単位区分
          selectSql += ',' + Const.DetailsUnitClass.manual;
          // 作成ユーザー
          selectSql += ',\'' + this.loginId + '\'';
          // 更新ユーザー
          selectSql += ',\'' + this.loginId + '\'';
          /* FROM句 */
          selectSql += ' FROM ';
          selectSql += 't_orders_receives ';
          /* WHERE句 */
          selectSql += ' WHERE ';
          selectSql += 'order_receive_id = ' + this.receivedOrderId + ' ';
          selectSql += 'AND order_receive_row = ' + this.productList[i].OrderReceiveRow + ' ';
          //console.log(selectSql);
          if (bulkInsertSqlSelect != '') {
            bulkInsertSqlSelect += 'UNION ';
          }
          bulkInsertSqlSelect += selectSql;
          if (bulkInsertSqlSelect.length >= Const.SqlMaxLength) {
            this.transactSqlList.push({sql: bulkInsertSqlStart + bulkInsertSqlSelect, forUpdateFlg: 0});
            bulkInsertSqlSelect = '';
          }
        }
      }
      if (bulkInsertSqlSelect != '') {
        this.transactSqlList.push({sql: bulkInsertSqlStart + bulkInsertSqlSelect, forUpdateFlg: 0});
      }
    },
    /* 更新日取得用SELECT文作成 */
    async createUpdatedSelect() {
      let selectSql = '';
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(orders_receives.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      this.listUpdatedSelect.push({select: selectSql, checkDelete: false});
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(ships.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_ships AS ships ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' ships.order_receive_id = ' + this.receivedOrderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});

      let dataResult = null;
      for (let i = 0; i < this.listUpdatedSelect.length; i++) {
        dataResult = await executeSelectSql(this.listUpdatedSelect[i].select);
        if (dataResult != null && dataResult.length > 0) {
          this.listUpdatedSelect[i].initUpdated = formatDate(dataResult[0].updated, 'YYYY-MM-DD HH:mm:ss');
        } else {
          this.listUpdatedSelect[i].initUpdated = '0000-01-01 00:00:00';
        }
      }
    },
    // 締日よりも過去日確認
    async confirmOldClosingDate() {
      // 売上計上日（伝票日付）が締日よりも過去日かチェック
      if (checkOldClosingDate(this.dateSalesRecord, this.suppliers.closingDate) == false) {
        // 売上計上日が締日よりも過去日
        let confirmMessage = '既に締日を過ぎた伝票です。請求済みと思われますが、問題はないですか？';
        let options = {
          title: '警告',
          bodyBgVariant: 'warning',
          headerBgVariant: 'warning',
          footerBgVariant: 'danger',
        };
        if (await this.$bvModal.msgBoxConfirm(confirmMessage, options) == true) {
          return true;
        } else {
          return false;
        }
      } else {
        // 売上計上日が締日と同じ、または、未来
        return true;
      }
    },
  }
}
</script>
<style scoped>
</style>