<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="mb-2">
            <b-card>
              <div class="mb-0 p-0" id="heading1">
                <h5 class="mb-0">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" v-b-tooltip.hover.noninteractive.right="'クリックで検索条件を表示/非表示できます。'">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.bottom="'印刷'" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                </h5>
              </div>
              <b-collapse id="collapse-1" visible>
                <b-card-header v-if="getMessageFlg==true">
                  <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                    <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                    <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                  <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                    <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                      <li>{{error}}</li>
                    </ul>
                  </b-alert>
                </b-card-header>
                <b-card-body class="p-0">
                  <b-form @submit.prevent="clearAlert(); searchButton();" id="saveDocumentsListForm" class="form-horizontal">
                    <b-container fluid>
                      <!-- ●●●検索条件●●● -->
                      <b-row>
                        <!-- 営業所入力欄 -->
                        <b-col sm="5" md="4" lg="3">
                          <b-form-group
                            label="営業所"
                            label-for="selectSalesOffice"
                          >
                            <b-form-select
                              id="selectSalesOffice"
                              size="sm"
                              v-model="searchConditions.selectSalesOffice"
                              :options="searchConditions.salesOffice"
                              value-field="id"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <!-- 更新可能行のみチェック入力欄 -->
                        <b-col sm="4" md="3" lg="2">
                          <label for="isOnlyUpdate" v-b-tooltip.hover.noninteractive.bottom="'更新可能行は棚卸済、且つ、更新在庫数（実棚数と現在庫数の差）が0以外、且つ、未更新の行です。'">更新可能行のみ<span class="px-1 oi oi-flag"/></label>
                          <b-form-checkbox
                            id="isOnlyUpdate"
                            size="sm"
                            v-model="searchConditions.isOnlyUpdate"
                            :disabled="searchConditions.selectIsUpdated==2"
                          ></b-form-checkbox>
                        </b-col>
                        <!-- 在庫マスタ更新状況入力欄 -->
                        <b-col sm="5" md="4" lg="3">
                          <b-form-group
                            label="在庫マスタ更新状況"
                            label-for="selectIsUpdated"
                          >
                            <b-form-select
                              id="selectIsUpdated"
                              size="sm"
                              v-model="searchConditions.selectIsUpdated"
                              :options="searchConditions.isUpdatedList"
                              @input="changeSelectIsUpdated"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <!-- 差分あり行のみチェック入力欄 -->
                        <b-col sm="4" md="3" lg="2" v-if="searchConditions.selectIsUpdated==2">
                          <label for="isDifference" v-b-tooltip.hover.noninteractive.bottom="'実棚数と現在庫数の差分がある行のみを表示します。'">差分あり行のみ<span class="px-1 oi oi-flag"/></label>
                          <b-form-checkbox
                            id="isDifference"
                            size="sm"
                            v-model="searchConditions.isDifference"
                          ></b-form-checkbox>
                        </b-col>
                      </b-row>
                      <!-- 検索ボタン -->
                      <b-row class="justify-content-md-center mt-2">
                        <b-col lg="3">
                          <b-button block pill variant="success" type="submit" form="saveDocumentsListForm">
                            <span class="oi oi-magnifying-glass"></span> 検 索 
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-form>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-button pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="チェックONの在庫数を全て更新します。（更新在庫数分）" @click="clearAlert(); saveData();" :disabled="checkedProductId.length==0">
          <span class="oi oi-circle-check"></span> 実棚数へ一括更新
        </b-button>
        <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
          <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
            <li>{{error}}</li>
          </ul>
        </b-alert>
        <b-col lg="12" class="mt-2">
          <b-row>
            <!-- 1ページあたりの表示選択 -->
            <b-col  lg="6" class="my-1 px-0">
              <b-form-group
                label="1ページあたりの表示件数"
                label-for="per-page-select"
                label-cols-sm="5"
                label-size="sm"
                class="mb-0"
              >
                <b-col lg="4" class="px-0">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-col>
              </b-form-group>
            </b-col>
            <!-- 検索結果検索 -->
            <b-col lg="6" class="my-1 px-0">
              <b-form-group
                label="Filter"
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 検索結果 -->
          <b-row>
            <b-table hover
              table-class="datatable"
              show-empty
              :small="true"
              :items="itemList"
              :fields="fields"
              :busy="busy"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              @filtered="onFiltered"
            >
              <!-- テーブル読み込み時表示html -->
              <template #table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>読み込んでいます...</strong>
                </div>
              </template>
              <template #head(Check)>
                <b-check v-model="isAllChecked" @change="clickCheckAll" :disabled="itemList.length == 0" />
              </template>
              <template #cell(Check)="data">
                <b-check v-model="checkedProductId" :value="data.item.ProductId" v-if="data.item.IsUpdatePossible" @change="changeCheck"/>
              </template>
            </b-table>
            <b-col lg="6">
              <b-form-group
                :label="getPagingMessage"
                class="mt-0 mb-0"
              />
            </b-col>
          </b-row>
          <!-- テーブルページネーション -->
          <b-col class="my-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="filter != null ? filterRows : totalRows"
              :per-page="perPage == -1 ? totalRows : perPage"
              align="center"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-col>
      </b-card>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import Const from '@/assets/js/const.js';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, windowPrint, addOperationLogs, createOfficeList, executeSelectSql, isSystemEditable, isAfterMonthlyUpdate, executeTransactSqlList, CreateColRow, CreateUpdateSql, CreateInsertSql, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { formatCurDate } from '../assets/js/common';

const MODULE_NAME = 'inventory-stock-bulk-update';

// 行カラー
const ROW_VARIANT_FINISHED = 'warning'; // 済にした製品
const ROW_VARIANT_ADD = 'info'; // 追加した製品

export default {
  name: 'INVENTORY-STOCK-BULK-UPDATE',
  // コンポーネント
  components: {
    Header,
    Footer,
  },
  // データ
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '棚卸在庫一括更新',
      loginId: this.$store.getters.user.username,
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 検索条件
      searchConditions:{
        selectSalesOffice: 0,
        salesOffice: [],
        isOnlyUpdate: true,
        selectIsUpdated: 0,
        isUpdatedList: [
          {value: 0, text: '全て'},
          {value: 1, text: '未更新'},
          {value: 2, text: '更新済'},
        ],
        isDifference: false,
      },
      // 検索結果表示時に指定された検索項目
      searchResultDispConditions:{
        selectSalesOffice: 0,
        isOnlyUpdate: true,
        selectIsUpdated: 0,
        isDifference: false,
      },
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      inventoryNo: 0,
      processMonthYear: 0,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: [
        {value: 5, text: '5'},
        {value: 10, text: '10'},
        {value: 15, text: '15'},
        {value: 100, text: '100'},
        {value: 1000, text: '1000'},
      ],
      // 表示データの総件数
      totalRows: '',
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // 各行のチェック状態
      checkedProductId: [],
      isAllChecked: false,
      // MAX件数
      maxBulkUpdateCnt: 400,
    }
  },
  // マウント
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    // ページの表示件数
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    // メッセージがあるかどうかの返却
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    // フィールド取得
    fields: function() {
      let retFields = [];
      retFields.push({key: 'Check', label: ''});
      retFields.push({key: 'Finished', label: '棚卸'});
      retFields.push({key: 'ProductId', label: '製品コード'});
      retFields.push({key: 'ProductName', label: '製品名'});
      retFields.push({key: 'UpdateCount', label: '更新在庫数', tdClass: 'text-right'});
      retFields.push({key: 'ShelvesCount', label: '実棚数', tdClass: 'text-right'});
      retFields.push({key: 'InventoryCount', label: '現在庫数', tdClass: 'text-right'});
      retFields.push({key: 'InventoryReserveCount', label: '引当済数', tdClass: 'text-right'});
      retFields.push({key: 'Balance', label: '使用可能在庫数', tdClass: 'text-right'});
      retFields.push({key: 'IsUpdatedText', label: '在庫マスタ更新'});
      return retFields;
    },
  },
  // 関数群
  methods: {
    windowPrint: function() {
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    // フェッチ
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListResult = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListResult] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          createOfficeList(false),
        ]);
        // 営業所プルダウン作成
        this.searchConditions.salesOffice = officeListResult;
        // 営業所データ初期値セット
        if (staffListData != null && staffListData.length > 0) {
          let loginOffice = this.searchConditions.salesOffice.find(el => el.id == staffListData[0].office_id);
          if (loginOffice != undefined) {
            this.searchConditions.selectSalesOffice = loginOffice.id;
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 検索ボタン押下時
    async searchButton() {
      const functionName = 'searchButton';
      try {
        await this.search();
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    // 検索処理
    async search() {
      const functionName = 'search';
      if (this.busy == true) {
        return;
      }
      this.busy = true;
      this.itemList = [];
      this.checkedProductId = [];
      this.isAllChecked = false;
      // ページング機能の初期化
      this.initPaging();
      try {
        // CRUD処理
        let selectSql = await this.makeSelectSql();
        //console.log(selectSql);
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        //console.log(resultData);
        this.saveSearchConditions();
        if (resultData != null && resultData.length > 0) {
          await this.setResult(resultData);
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
        this.totalRows = 0;
      }
      this.busy = false;
    },
    // 検索条件を保存
    saveSearchConditions: function() {
      this.searchResultDispConditions.selectSalesOffice = this.searchConditions.selectSalesOffice;
      this.searchResultDispConditions.isOnlyUpdate = this.searchConditions.isOnlyUpdate;
      this.searchResultDispConditions.selectIsUpdated = this.searchConditions.selectIsUpdated;
      this.searchResultDispConditions.isDifference = this.searchConditions.isDifference;
    },
    // SELECT文字列作成
    async makeSelectSql() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' m_c.process_month_year';
      selectSql += ',t_i.inventory_no';
      selectSql += ',t_i.product_id';
      selectSql += ',t_i.product_name';
      selectSql += ',t_i.shelves_count';
      selectSql += ',m_s.inventory_reserve_count';
      selectSql += ',m_s.balance';
      selectSql += ',t_i.add_flg';
      selectSql += ',t_i.finished_flg';
      selectSql += ',IF(t_i.add_flg = 1, \'' + ROW_VARIANT_ADD + '\', IF(t_i.finished_flg = 1, \'' + ROW_VARIANT_FINISHED + '\', NULL)) AS _rowVariant';
      selectSql += ',m_s.updated';
      selectSql += ',CASE WHEN t_ibuh.inventory_no IS NULL THEN 0 ELSE 1 END AS is_updated';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 't_inventories AS t_i ';
      selectSql += 'INNER JOIN m_control AS m_c ';
      selectSql += 'INNER JOIN m_stocks AS m_s ';
      selectSql += 'ON t_i.product_id = m_s.product_id ';
      selectSql += 'AND t_i.office_id = m_s.office_id ';
      selectSql += 'AND m_c.process_month_year = m_s.month_year ';
      selectSql += 'LEFT JOIN t_inventories_bulk_update_histories AS t_ibuh ';
      selectSql += 'ON t_i.inventory_no = t_ibuh.inventory_no ';
      selectSql += 'AND t_i.office_id = t_ibuh.office_id ';
      selectSql += 'AND t_i.product_id = t_ibuh.product_id ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 't_i.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      selectSql += 'AND t_i.inventory_no = (SELECT MAX(inventory_no) FROM t_inventories_histories WHERE office_id = ' + this.searchConditions.selectSalesOffice + ') ';
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' t_i.add_flg';
      selectSql += ',t_i.sort_order';

      return selectSql;
    },
    // 取得結果セット
    async setResult(result) {
      // 総件数をdataTableの総件数にセット
      this.processMonthYear = result[0].process_month_year;
      this.inventoryNo = result[0].inventory_no;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          Finished: result[i].add_flg == Const.InventoryInputAddFlag.added ? '追加' : result[i].finished_flg == Const.InventoryInputFinishedFlag.finished ? '済' : '未済',
          ProductId: result[i].product_id,
          ProductName: result[i].product_name,
          ShelvesCount: result[i].shelves_count,
          InventoryCount: result[i].inventory_reserve_count + result[i].balance,
          InventoryReserveCount: result[i].inventory_reserve_count,
          Balance: result[i].balance,
          AddFlg: result[i].add_flg,
          FinishedFlg: result[i].finished_flg,
          Updated: result[i].updated,
          _rowVariant: result[i]._rowVariant,
          IsUpdated: result[i].is_updated == 1 ? true: false,
          IsUpdatedText: result[i].is_updated == 1 ? '更新済': '未更新',
        };
        if (searchResult.ShelvesCount >= searchResult.InventoryCount) {
          // 実棚数が現在庫数以上の場合（現在庫数を増やすため、そのまま増やす）
          // 実棚数-現在庫数の値が更新数となる
          searchResult.UpdateCount = searchResult.ShelvesCount - searchResult.InventoryCount;
        } else {
          // 実棚数が現在庫数より少ない場合（現在庫数を減らすため使用可能在庫数と比較して、可能な分だけ減らす）
          if (searchResult.Balance >= searchResult.InventoryCount - searchResult.ShelvesCount) {
            // 現在庫数-実棚数が使用可能在庫数の範囲内の場合
            // 実棚数-現在庫数の値が更新数となる
            searchResult.UpdateCount = searchResult.ShelvesCount - searchResult.InventoryCount;
          } else {
            // 現在庫数-実棚数が使用可能在庫数を超過する場合
            // 使用可能在庫数分を更新数とする
            searchResult.UpdateCount = -searchResult.Balance;
          }
        }
        if (searchResult.UpdateCount != 0 &&
          searchResult.IsUpdated == false &&
          (searchResult.AddFlg == Const.InventoryInputAddFlag.added || searchResult.FinishedFlg == Const.InventoryInputFinishedFlag.finished)) {
          // 以下に該当する行は更新可能（棚卸済、更新在庫数が0以外、在庫マスタ未更新）
          searchResult.IsUpdatePossible = true;
        }
        if (this.searchResultDispConditions.selectIsUpdated == 1) {
          // 在庫マスタ更新状況：未更新
          if (searchResult.IsUpdated == false) {
            // 未更新行
            if (this.searchResultDispConditions.isOnlyUpdate == false) {
              // 更新可能行のみがチェックOFF
              this.itemList.push(searchResult);
            } else {
              // 更新可能行のみがチェックON
              if (searchResult.IsUpdatePossible == true) {
                // 更新可能行
                this.itemList.push(searchResult);
              }
            }
          }
        } else if (this.searchResultDispConditions.selectIsUpdated == 2) {
          // 在庫マスタ更新状況：更新済
          if (searchResult.IsUpdated == true) {
            // 更新行
            if (this.searchResultDispConditions.isDifference == false) {
              // 差分のみチェックOFF
              this.itemList.push(searchResult);
            } else {
              // 差分のみチェックON
              if (searchResult.ShelvesCount != searchResult.InventoryCount) {
                // 実棚数と現在庫数が異なる行のみ
                this.itemList.push(searchResult);
              }
            }
          }
        } else {
          // 在庫マスタ更新状況：全て
          if (this.searchResultDispConditions.isOnlyUpdate == false ||
            searchResult.IsUpdatePossible == true) {
            // 更新可能行のみがチェックOFF、または、更新可能行（棚卸済、更新在庫数が0以外、在庫マスタ未更新）
            this.itemList.push(searchResult);
          }
        }
      }
      this.totalRows = this.itemList.length;
    },
    // チェックボックス個別選択（全チェックのチェックボックスを合わせる）
    changeCheck: function() {
      this.isAllChecked = this.itemList.length == this.checkedProductId.length;
    },
    // 全チェック選択
    clickCheckAll: function() {
      if (this.isAllChecked == true) {
        // チェックONにした場合
        // 削除チェックを初期化
        this.checkedProductId = [];
        // 削除チェックを全てON
        for (let i = 0; i < this.itemList.length; i++) {
          if (this.itemList[i].IsUpdatePossible == true) {
            // 更新可能行のみ
            this.checkedProductId.push(this.itemList[i].ProductId);
          }
        }
      } else {
        // チェックOFFにした場合
        // 削除チェックを全てOFF
        this.checkedProductId = [];
      }
    },
    // 保存ボタンの押下
    async saveData() {
      const functionName = 'saveData';
      try {
        // 保存確認ダイアログを表示
        let confirmMessage = [];
        confirmMessage.push(this.$createElement('div','チェックONの製品を更新在庫数の分だけ更新します。'));
        confirmMessage.push(this.$createElement('div','よろしいですか？'));
        let retConfirm = await this.$bvModal.msgBoxConfirm(confirmMessage, {title: '保存確認'});
        if (retConfirm == true) {
          this.$store.commit('setLoading', true);
          if (await this.execSave() == true) {
            // 成功した場合は検索結果を更新
            await this.searchButton();
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 保存実行
    async execSave() {
      const functionName = 'execSave';
      try {
        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.alertDanger.push(msg);
            return false;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
          return false;
        }
        // 月次更新後チェック
        if (await isAfterMonthlyUpdate(this.processMonthYear) == true) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
          return false;
        }
        // 棚卸Noチェック
        let SelectMaxInventoryNo = 'SELECT MAX(inventory_no) AS inventory_no FROM t_inventories_histories WHERE office_id = ' + this.searchResultDispConditions.selectSalesOffice;
        let resultData = await executeSelectSql(SelectMaxInventoryNo);
        if (resultData[0].inventory_no != this.inventoryNo) {
          // 検索後に棚卸Noが更新されている場合
          this.alertWarning.push(DISP_MESSAGES.WARNING['2084'].replace('%arg1%', '棚卸No'));
          return false;
        }
        // 全体更新実行
        return await this.execAllUpdateSql();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        console.log(error);
        return false;
      }
    },
    // 全体更新実行
    async execAllUpdateSql() {
      let bulkUpdateItemList = [];
      let updateItemList = [];
      for (let i = 0; i < this.checkedProductId.length; i++) {
        let productId = this.checkedProductId[i];
        let item = this.itemList.find(el => el.ProductId == productId);
        updateItemList.push(item);
        if (updateItemList.length >= this.maxBulkUpdateCnt) {
          bulkUpdateItemList.push(updateItemList);
          updateItemList = [];
        }
      }
      if (updateItemList.length > 0) {
        bulkUpdateItemList.push(updateItemList);
      }
      let promiseArray = [];
      for (let i = 0; i < bulkUpdateItemList.length; i++) {
        promiseArray.push(this.execUpdateSql(bulkUpdateItemList[i]));
      }
      //console.log(promiseArray.length);
      let aryUpdateResult = await Promise.all(promiseArray);
      let isSuccess = false;
      let isFailed = false;
      for (let i = 0; i < aryUpdateResult.length; i++) {
        if (aryUpdateResult[i] == true) {
          isSuccess = true;
        } else {
          isFailed = true;
        }
      }
      if (isSuccess == false) {
        // 全件失敗の場合
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      } else {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1003']);
        // 1件以上成功がある場合
        if (isFailed == true) {
          // 1件以上の失敗がある場合
          this.alertDanger.push(DISP_MESSAGES.DANGER['3009'].replace('%arg1%', '更新'));
        }
        return true;
      }
    },
    // 更新実行
    async execUpdateSql(updateItemList) {
      const functionName = 'execUpdateSql';
      let transactSqlList = [];
      // 更新が成功したことの確認のため更新日時を作成
      let updated = formatCurDate('YYYY/MM/DD HH:mm:ss');
      this.updateStocksMaster(transactSqlList, updateItemList, updated);
      this.inserttInventoriesBulkUpdateHistories(transactSqlList, updateItemList, updated);

      // 作成した登録用SQLを全実行
      return await executeTransactSqlList(transactSqlList, MODULE_NAME, functionName);
    },
    // 在庫マスタ更新SQL文
    updateStocksMaster: function(transactSqlList, updateItemList, updated) {
      let updateSelectList = [];
      let colList = [];
      for (let i = 0; i < updateItemList.length; i++) {
        let updateSelect = 'SELECT';
        updateSelect += ' ' + updateItemList[i].ProductId + ' AS product_id';
        updateSelect += ',\'' + updateItemList[i].Updated + '\' AS updated';
        updateSelect += ',' + updateItemList[i].UpdateCount + ' AS add_balance';
        if (updateItemList[i].UpdateCount >= 0) {
          updateSelect += ',' + updateItemList[i].UpdateCount + ' AS add_monthly_tuning_stock';
          updateSelect += ',0 AS add_monthly_tuning_ship';
        } else {
          updateSelect += ',0 AS add_monthly_tuning_stock';
          updateSelect += ',' + (-updateItemList[i].UpdateCount) + ' AS add_monthly_tuning_ship';
        }
        updateSelectList.push(updateSelect);
      }
      let updateQuery = '(' + updateSelectList.join(' UNION ALL ') + ') AS q';
      colList = [];
      // 残高数
      colList.push(CreateColRow('m_s.balance', 'm_s.balance + q.add_balance', 'NUMBER'));
      // 月次調整入庫数
      colList.push(CreateColRow('m_s.monthly_tuning_stock', 'm_s.monthly_tuning_stock + q.add_monthly_tuning_stock', 'NUMBER'));
      // 月次調整出庫数
      colList.push(CreateColRow('m_s.monthly_tuning_ship', 'm_s.monthly_tuning_ship + q.add_monthly_tuning_ship', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('m_s.updated', updated, 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('m_s.updated_user', this.loginId, 'VARCHAR'));
      // WHERE句
      let where_clause = ' WHERE ';
      where_clause += 'm_s.month_year = ' + this.processMonthYear + ' ';
      where_clause += 'AND m_s.office_id = ' + this.searchResultDispConditions.selectSalesOffice + ' ';
      where_clause += 'AND m_s.product_id = q.product_id ';
      where_clause += 'AND m_s.updated = q.updated ';
      let updateSql = CreateUpdateSql(colList, 'm_stocks AS m_s', updateQuery) + where_clause;
      //console.log(updateSql);
      transactSqlList.push(updateSql);
    },
    // 棚卸一括更新履歴INSERT文
    inserttInventoriesBulkUpdateHistories: function(transactSqlList, updateItemList, updated) {
      let bulkInsertSqlStart = '';
      let bulkInsertSqlSelect = '';
      let colList = [];
      // 棚卸No.
      colList.push(CreateColRow('inventory_no', null, 'NUMBER'));
      // 営業所コード
      colList.push(CreateColRow('office_id', null, 'NUMBER'));
      // 製品コード
      colList.push(CreateColRow('product_id', null, 'NUMBER'));
      // 実棚数（更新時）
      colList.push(CreateColRow('shelves_count', null, 'NUMBER'));
      // 在庫引当数（更新時）
      colList.push(CreateColRow('inventory_reserve_count', null, 'NUMBER'));
      // 残高数（更新後）
      colList.push(CreateColRow('balance_new', null, 'NUMBER'));
      // 残高数（更新前）
      colList.push(CreateColRow('balance_old', null, 'NUMBER'));
      // 登録日
      colList.push(CreateColRow('input_date', null, 'DATE'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      bulkInsertSqlStart = 'INSERT INTO t_inventories_bulk_update_histories (' + CreateInsertSql(colList, 'col', 't_inventories_bulk_update_histories') + ') ';
      for (let i = 0; i < updateItemList.length; i++) {
        let selectSql = 'SELECT';
        selectSql += ' ' + this.inventoryNo;
        selectSql += ',' + this.searchResultDispConditions.selectSalesOffice;
        selectSql += ',' + updateItemList[i].ProductId;
        selectSql += ',' + updateItemList[i].ShelvesCount;
        selectSql += ',' + updateItemList[i].InventoryReserveCount;
        selectSql += ',' + (updateItemList[i].Balance + updateItemList[i].UpdateCount).toString();
        selectSql += ',' + updateItemList[i].Balance;
        selectSql += ',CURDATE()';
        selectSql += ',\'' + this.loginId + '\'';
        selectSql += ',\'' + this.loginId + '\'';
        selectSql += ' FROM m_stocks';
        selectSql += ' WHERE ';
        selectSql += 'month_year = ' + this.processMonthYear + ' ';
        selectSql += 'AND office_id = ' + this.searchResultDispConditions.selectSalesOffice + ' ';
        selectSql += 'AND product_id = ' + updateItemList[i].ProductId + ' ';
        selectSql += 'AND updated = \'' + updated + '\' ';
        //console.log(selectSql);
        if (bulkInsertSqlSelect != '') {
          bulkInsertSqlSelect += 'UNION ALL ';
        }
        bulkInsertSqlSelect += selectSql;
        if (bulkInsertSqlSelect.length >= Const.SqlMaxLength) {
          transactSqlList.push(bulkInsertSqlStart + bulkInsertSqlSelect);
          bulkInsertSqlSelect = '';
        }
      }
      if (bulkInsertSqlSelect != '') {
        transactSqlList.push(bulkInsertSqlStart + bulkInsertSqlSelect);
      }
    },
    // 在庫マスタ更新状況変更時処理
    changeSelectIsUpdated: function() {
      if (this.searchConditions.selectIsUpdated==2) {
        // 更新済選択時、更新行のみのチェックOFF
        this.searchConditions.isOnlyUpdate=false;
      }
    },
    // 画面のアラートをクリア
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    // フィルター時のイベント
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    // ページング変数の初期化
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
  },
}
</script>