<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 入出庫入力照会</strong></h5>
                <b-button-toolbar>
                  <b-button variant="success" pill v-b-tooltip.hover.bottom="'入出庫入力に遷移する'" @click="clickInputBtn" class="m-0">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="clickInputBtn" class="d-none"></button>
                  </b-button>&nbsp;&nbsp;
                  <b-button pill v-b-tooltip.hover.bottom="'入出庫一覧に戻る'" @click="cancelPurchaseInput" class="btn-cancel m-0">
                    <span class="oi oi-circle-x"></span> キャンセル
                  </b-button>
                </b-button-toolbar>
              </div>
            </b-media-body>
          </b-media>
          <div class="main-card card">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <validation-observer ref="observer">
                  <b-row>
                    <!-- 営業所コード -->
                    <b-col lg="3">
                      <b-form-group
                        label="営業所"
                        label-for="selectSalesOffice"
                      >
                        <b-form-input id="selectSalesOffice" type="text" v-model="selectSalesOfficeText" readonly />
                      </b-form-group>
                    </b-col>
                    <!-- 伝票日付 -->
                    <b-col lg="3">
                      <b-form-group
                        label="伝票日付"
                      >
                        <b-form-input type="text" readonly v-model="dateOrderReceiveDate"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <!-- 伝票種別区分 -->
                    <b-col lg="3">
                      <b-form-group
                        label="伝票種別"
                        label-for="selectBillClass"
                      >
                        <b-form-input type="text" id="selectBillClass" readonly v-model="selectBillClassText"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <!-- 移動先コード destinationId -->
                    <b-col lg="3">
                      <b-form-group
                        label="移動先"
                        label-for="destinationId"
                      >
                        <b-form-input id="destinationId" type="text" v-model="destinationText" readonly />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- 製品 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :items="productList"
                            :fields="fields"
                            :responsive="true"
                          >
                            <template #table-caption>製品テーブル</template>
                            <template #cell(ProductCode)="data">
                              {{ data.item.ProductCode }}
                              <!-- <validation-provider :rules="{required: true,duplicate:{title:'製品コード',idList:productList,key:'ProductCode',NoCheckList:[0]}}" v-slot="{ classes,errors }">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-input-group style="width: 150px;">
                                      <b-form-input type="text" name="productId" v-model="data.item.ProductCode" readonly style="width: 80px;" />
                                    </b-input-group>
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]"/>
                                  </b-col>
                                </b-row>
                              </validation-provider> -->
                            </template>
                            <template #cell(ProductName)="data">
                              {{ data.item.ProductName }}
                              <!-- <validation-provider rules="max:35" v-slot="{ classes,errors }">
                                <b-input-group style="width: 250px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input type="text" name="productName" v-model="data.item.ProductName" readonly />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider> -->
                            </template>
                            <template #cell(Quantity)="data">
                              {{ data.item.Quantity }}
                              <!-- <validation-provider :rules="{required: true, storage_between: {billClass: selectBillClass, balance: data.item.Balance}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input type="number" name="productQuantity" v-model="data.item.Quantity" readonly />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider> -->
                            </template>
                            <template #cell(StockQuantity)="data">
                              {{ data.item.StockQuantity.toLocaleString() }}
                            </template>
                            <template #cell(Balance)="data">
                              {{ data.item.Balance.toLocaleString() }}
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <!-- 摘要 -->
                      <b-form-group
                        label="摘要"
                        label-for="billingSummary"
                      >
                        <b-form-input type="text" id="billingSummary" v-model="billingSummary" readonly ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
              </validation-observer>
            </b-card-body>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :product-search-prop="productSearchProp"/>
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, getControlMaster, formatDate, getListValue, executeSelectSql, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
const MODULE_NAME = 'loading-unloading-inquiry';
export default {
  name: 'LOADING-UNLOADING-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    PRODUCTSEARCH,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '入出庫入力照会',
      loginId: '',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOffice: null,
      selectSalesOfficeText: '',
      salesOffice: [],
      // 移動先プルダウン
      destinationId: 0,
      destinationText: '',
      destinationList: [],
      // 伝票日付
      dateOrderReceiveDate: '',
      // 伝票種別
      selectBillClass: null,
      selectBillClassText: null,
      purchaseBillClassList: [],
      // 伝票摘要
      billingSummary: '',
      // テーブル定義
      productList:[],
      dataIndex: 0,
      // 製品検索情報
      productSearchProp:{
        office_id: '',
        office_name: '',
        client_id: '',
        client_class: '',
        client_name: '',
      },
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // トランザクションSQLリスト
      transactSqlList:[],
      // 確認ダイアログ用
      confirmMessage: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 伝票種別
        Tradition: {
          // 調整入庫
          storageAdd: Const.Tradition.storageAdd,
          // 調整出庫
          storageDel: Const.Tradition.storageDel,
          // 移動
          storageMove: Const.Tradition.storageMove,
        },
      },
      // パラメータ
      billingNo: 0,
      monthlyMonthYear: 0,
      parentKbn: 0,
      reserveErrFlg: 0,
      errCsvProductId: '',
    }
  },
  computed:{
    fields: function() {
      return [
        {
          key: 'ProductCode',
          label: '製品コード',
        },
        {
          key: 'ProductName',
          label: '製品名',
        },
        {
          key: 'Quantity',
          label: '数量',
        },
        {
          key: 'Unit',
          label: '単位',
        },
        {
          key: 'StockQuantity',
          label: '現在庫数',
        },
        {
          key: 'Balance',
          label: '使用可能在庫数',
        },
      ]
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.billingNo = this.$route.query.billingNo;
    this.monthlyMonthYear = this.$route.query.monthlyMonthYear;
    this.parentKbn = this.$route.query.parentKbn;
    this.reserveErrFlg = this.$route.query.reserveErrFlg;
    this.errCsvProductId = this.$route.query.errCsvProductId;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 製品テーブル初期表示データ用意
        this.addTableRow();
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let controlData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData, controlData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
          getControlMaster(),
        ]);
        //console.log(staffListData);
        //console.log(officeListData);
        // 移動先コードは未選択も選択可能とする。
        this.destinationList.push({id: 0, text: '未選択'});
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.salesOffice.push(office);
          this.destinationList.push(office);
        }
        // 営業所データ初期値セット
        for(let i = 0; i < this.salesOffice.length; i++){
          //console.log(this.salesOffice[i].id);
          if(this.salesOffice[i].id == staffListData[0].office_id){
            this.selectSalesOffice = this.salesOffice[i].id;
            this.selectSalesOfficeText = this.salesOffice[i].text;
            break;
          }
        }
        // 伝票種別プルダウン
        this.purchaseBillClassList.push({id: null, text: '選択してください。'});
        for (let i = 0; i < Const.TraditionList.length; i++) {
          // 「51:調整入庫」「52:調整出庫」「53:移動」
          if (Const.TraditionList[i].id == Const.Tradition.storageAdd ||
          Const.TraditionList[i].id == Const.Tradition.storageDel ||
          Const.TraditionList[i].id == Const.Tradition.storageMove) {
            this.purchaseBillClassList.push(Const.TraditionList[i]);
          }
        }
        // 現在処理年月
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        // 入力画面からの遷移の場合
        if (this.parentKbn == 1) {
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        }
        if (this.reserveErrFlg == 1) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2021'].replace('%arg1%', this.errCsvProductId));
        }
        // 既存データ設定
        await this.setInitData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null) {
        await this.setResult(dataResult);
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索SQL作成（初期値） */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' cumulative_transaction.office_id';
      selectSql += ',cumulative_transaction.billing_date';
      selectSql += ',cumulative_transaction.destination_id';
      selectSql += ',cumulative_transaction.bill_class';
      selectSql += ',cumulative_transaction.product_id';
      selectSql += ',cumulative_transaction.product_name AS product_name_kanji';
      selectSql += ',cumulative_transaction.service_class';
      selectSql += ',cumulative_transaction.product_class_id';
      selectSql += ',cumulative_transaction.product_quantity';
      selectSql += ',cumulative_transaction.product_unit AS unit';
      selectSql += ',cumulative_transaction.billing_summary';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_cumulative_transaction AS cumulative_transaction ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // ﾄﾗﾝｻﾞｸｼｮﾝID
      selectSql += ' cumulative_transaction.transaction_id = \'' + Const.TransactionId.storage + '\' ';
      // 引数の伝票番号で絞り込む
      selectSql += 'AND cumulative_transaction.billing_no = ' + this.billingNo + ' ';
      // 引数の月次年月で絞り込む
      selectSql += 'AND cumulative_transaction.monthly_month_year = ' + this.monthlyMonthYear + ' ';
      // 消費税行は出さない
      selectSql += 'AND cumulative_transaction.product_id <> 0 ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY cumulative_transaction.billing_row ';

      return selectSql;
    },
    async setResult(result) {
      if (result.length > 0) {
        // 営業所
        for (let i = 0; i < this.salesOffice.length; i++) {
          if (result[0].office_id == this.salesOffice[i].id) {
            this.selectSalesOffice = result[0].office_id;
            this.selectSalesOfficeText = this.salesOffice[i].text;
          }
        }
        // 伝票日付
        this.dateOrderReceiveDate = formatDate(result[0].billing_date);
        // 伝票種別
        this.selectBillClass = result[0].bill_class;
        for (let i = 0; i < this.purchaseBillClassList.length; i++){
          if(this.selectBillClass == this.purchaseBillClassList[i].id){
            this.selectBillClassText = this.purchaseBillClassList[i].text;
          }
        }
        // 移動先コード
        for (let i = 0; i < this.destinationList.length; i++) {
          if (result[0].destination_id == this.destinationList[i].id) {
            this.destinationId = result[0].destination_id;
            this.destinationText = this.destinationList[i].text;
          }
        }
        // 全件参照して製品一覧を作成
        for(let i = 0; i < result.length; i++){
          if(i == this.productList.length){
            this.addTableRow();
          }
          this.productList[i].ProductCode = result[i].product_id;
          this.productList[i].ProductClass = result[i].product_class_id;
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i].ServiceClass = result[i].service_class;
          this.productList[i].Quantity = result[i].product_quantity;
          this.productList[i].Unit = result[i].unit;
        }
        // 摘要
        this.billingSummary = result[0].billing_summary;
        // 現在庫数設定
        await this.setStocksBalance();
      }
    },

    // キャンセルボタン
    cancelPurchaseInput:function(){
      this.$router.push({ name: 'LOADING-UNLOADING-LIST'});
    },
    // 製品検索
    showProductSearchModal:function(index) {
      //console.log('製品検索モーダル');
      //console.log(index);
      this.dataIndex = index;
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.selectSalesOffice);
      this.productSearchProp.client_id = null;
      this.productSearchProp.client_class = null;
      this.productSearchProp.client_name = null;
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    async closeProductSearchModal(productItems){
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if(typeof productItems != 'undefined'){
        this.$store.commit('setLoading', true);
        // 製品コード
        this.productList[this.dataIndex].ProductCode = productItems.detail.product_id;
        // 製品分類コード
        this.productList[this.dataIndex].ProductClass = productItems.detail.product_class_id;
        // 製品名（漢字）
        this.productList[this.dataIndex].ProductName = productItems.detail.product_name_kanji;
        // 数量
        this.productList[this.dataIndex].Quantity = 1;
        // 単位
        this.productList[this.dataIndex].Unit = productItems.detail.unit;
        // 現在庫数設定
        await this.setStocksBalance();
        this.$store.commit('setLoading', false);
      }
    },
    // 現在庫数設定
    async setStocksBalance() {
      // 指定された営業所と現在処理年月の在庫マスタを確認
      let where_clause = 'AND month_year = ' + this.controlMasterData.processMonthYear + ' ';
      where_clause += 'AND office_id = ' + this.selectSalesOffice + ' ';
      let csvProductId = '';
      for (let i = 0; i < this.productList.length; i++) {
        if (csvProductId != '') {
          csvProductId += ',';
        }
        csvProductId += this.productList[i].ProductCode;
      }
      where_clause += 'AND product_id IN (' + csvProductId + ') ';
      where_clause += 'ORDER BY product_id ';
      let stocksResultData = await selectOneTable('m_stocks', where_clause);
      //console.log(stocksResultData);
      if (stocksResultData != null && stocksResultData.length > 0) {
        // 製品一覧に設定
        for (let i = 0; i < this.productList.length; i++) {
          for (let j = 0; j < stocksResultData.length; j++) {
            // 製品コードが等しい場合
            if (this.productList[i].ProductCode == stocksResultData[j].product_id) {
              // 現在庫数を設定
              this.productList[i].StockQuantity = stocksResultData[j].balance + stocksResultData[j].inventory_reserve_count;
              // 残高数を設定
              this.productList[i].Balance = stocksResultData[j].balance;
              break;
            }
          }
        }
      }
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = {
        ProductCode: null,
        ProductClass: null,
        ProductName: '',
        Quantity: 0,
        Unit: '',
        StockQuantity: 0,
        Balance: 0,
      };
      this.productList.push(newProduct);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // 新規登録ボタン押下時
    async clickInputBtn() {
      this.$router.push({ name: 'LOADING-UNLOADING-INPUT' });
    },
  }
}
</script>
<style scoped>
</style>