<template>
  <!-- 製品単価グループ保守入力画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 製品単価グループ保守入力</strong></h5>
                <router-link to="/products-prices-groups-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <p>一番若い製品コードが代表コードになります。</p>
              <validation-observer ref="observer">
                <!-- グループテーブル -->
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 form-group">
                    <b-table
                      show-empty
                      :head-variant="headVariant"
                      :responsive="true"
                      :items="productPricesGroupsObj.list"
                      :fields="fields"
                      :busy="busy"
                      :bordered="true"
                      :empty-text="emptyText"
                    >
                      <!-- テーブル読み込み時表示html -->
                      <template #table-busy>
                        <div class="text-center text-info my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>読み込んでいます...</strong>
                        </div>
                      </template>
                      <!-- グループ製品コード -->
                      <template #cell(product_id)="data">
                        <validation-provider rules="required|numeric|max:8|min:8" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <div class="input-group">
                              <input type="text" id="member_product_id" class="form-control" v-model="data.item.product_id" @input="productIdConfirm(data.item.product_id, data)" maxlength="8">
                              <!-- 製品コード検索ボタン -->
                              <b-input-group-text @click="showProductSearchModal(data)">
                                <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                  <span class="oi oi-magnifying-glass"></span>
                                </b-button>
                              </b-input-group-text>
                            </div>
                            <small class="form-text text-muted">必須項目です。手入力の場合は8桁</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <!-- グループ製品名 -->
                      <template #cell(product_name_kanji)="data">
                        <validation-provider rules="required" v-slot="{ classes, errors }">
                          <div :class="classes">
                            <div v-b-tooltip.hover :title="data.item.product_name_kanji">
                              <input type="text" id="product_name_kanji" class="form-control" v-model="data.item.product_name_kanji" readonly>
                            </div>
                            <small class="form-text text-muted">必須項目です。</small>
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </div>
                        </validation-provider>
                      </template>
                      <template #cell(operation)="operation">
                        <!-- 削除ボタン -->
                        <b-button size="sm" @click="delItem(operation)" class="mr-1">
                          <span class="oi oi-delete"></span> 削除
                        </b-button>
                      </template>
                    </b-table>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 form-group">
                    <button type="button" class="btn btn-primary btn-block" @click="addItem"><span class="oi oi-plus"></span> 行追加</button>
                  </div>
                </div>
              </validation-observer>
              <!-- 保存ボタン -->
              <div class="card-footer">
                <div class="row justify-content-md-center pb-4">
                  <div class="col-lg-2">
                   <button type="button" class="btn btn-primary btn-block" @click="save"><span class="oi oi-circle-check"></span> 保存</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :productSearchProp="productSearchProp"/>
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { API, graphqlOperation } from 'aws-amplify';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import { executeTransactSql } from '@/graphql/mutations';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, init, CreateColRow, CreateInsertSql, getUserCol, checkProductId, isSystemEditable, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'products-prices-groups-input';

export default {
  name: 'PRODUCTS-PRICES-GROUPS-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    PRODUCTSEARCH
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '製品単価グループ保守入力',
      busy: false,
      // テーブルのヘッダー色
      headVariant:DataTblDef.headerVariant,
      // 検索結果が0件の場合の表示メッセージ
      emptyText:DataTblDef.emptyText,
      // ユーザ名
      username: this.$store.getters.user.username,
      // 製品検索時のindexを一時保存
      productSearchIndex: 0,
      // 製品単価グループ対象
      productPricesGroupsObj: {},
      // 代表コード
      product_group_id: 0,
      // グループテーブル
      fields: [
        {
          key: 'product_id',
          label: '製品コード',
          thStyle: 'width: 30%;',
          sortable: true
        },
        {
          key: 'product_name_kanji',
          label: '製品名',
          thStyle: 'width: 60%;',
          sortable: true
        },
        {
          key: 'operation',
          label: '操作',
          thStyle: 'width: 10%;'
        },
      ],
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  computed:{
    /* 製品モーダルのパラメータ用 */
    productSearchProp() {
      return {
        office_id: null,
        office_name: null,
        client_class: null,
        client_id: null,
        client_name: null,
      };
    },
  },
  /**
   * beforeMountライフサイクルフック
   */
  async beforeMount() {
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     */
    async fetchData() {
      this.busy = true;
      // 製品単価グループデータをセット,初期データは4行
      const groupProducts = {
        list: [
          {
            product_id: null,
            product_name_kanji: null,
          },
          {
            product_id: null,
            product_name_kanji: null,
          },
          {
            product_id: null,
            product_name_kanji: null,
          },
          {
            product_id: null,
            product_name_kanji: null,
          },
        ]
      }
      this.productPricesGroupsObj = {...groupProducts}
      this.busy = false;
    },
    // 製品検索
    showProductSearchModal({index}) {
      this.errorMessages = []
      this.productSearchIndex = index
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    closeProductSearchModal(productItems){
      // モーダルから渡された値の有無チェック
      if(typeof productItems != 'undefined'){
        this.productPricesGroupsObj.list[this.productSearchIndex].product_id = productItems.productId;
        this.productPricesGroupsObj.list[this.productSearchIndex].product_name_kanji = productItems.productNameKanji;
      }
    },
    /**
     * 製品コード確認処理
     * @param {Int} product_id - 製品コード
     * @param {Object} data - データ行内容
     * @returns {Array<Object>} 検索結果
     */
    async productIdConfirm(product_id, {index}) {
      const functionName = 'productIdConfirm';
      this.errorMessages = []
      // 数字8桁のみ
      if (checkProductId(product_id)) {
        const whereClause = `AND product_id = ${product_id}`
        let result = null;
        try {
          result = await selectOneTable('m_products', whereClause);
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, {
            graphqlOperation: 'list_m_products',
            where_clause: whereClause
          }, error);
          return null;
        }
        if (result != null && result.length == 1) {
          // 製品のコードと製品名をセット
          this.productPricesGroupsObj.list[index].product_id = product_id
          this.productPricesGroupsObj.list[index].product_name_kanji = result[0].product_name_kanji
        } else {
          // 製品のコードと製品名をセット
          this.productPricesGroupsObj.list[index].product_id = product_id
          this.productPricesGroupsObj.list[index].product_name_kanji = ''
          this.errorMessages.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','製品コード'));
          scrollTo(0,0);
        }
      }
    },
    /**
     * 行を追加
     */
    async addItem() {
      this.productPricesGroupsObj.list.push({
        product_id:  null,
        product_name_kanji: null,
      })
    },
    /**
     * 行を削除
     */
    async delItem({index}) {
      this.productPricesGroupsObj.list.splice(index, 1)
    },
    /**
     * 保存ボタンの押下
     */
    async save() {
      this.errorMessages = [];
      if (await this.$refs.observer.validate()) {
        if (await this.$bvModal.msgBoxConfirm('入力された製品単価グループデータを保存します。よろしいですか？', {
          title: '製品単価グループの保存',
        })) {
          this.$store.commit('setLoading', true);
          await this.execInsert();
          if (this.errorMessages.length === 0) {
            this.$router.push({ 
              name: 'PRODUCTS-PRICES-GROUPS-INQUIRY', 
              query: { group_product_id: this.product_group_id },
              params: { successMessages: [DISP_MESSAGES.SUCCESS['1001']] }  
            });
          }
          scrollTo(0,0);
        }
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }
      this.$store.commit('setLoading', false);
    },
    /**
     * 登録処理
     */
    async execInsert() {
      // 製品が1行以下の場合
      if (this.productPricesGroupsObj.list.length <= 1) {
        this.errorMessages.push('最低は二つの製品で構成されます')
        return
      }
      // 重複製品一つにする
      let insertObj = {}
      this.productPricesGroupsObj.list.forEach(element => {
        if (!(element.product_id in insertObj)) {
          insertObj[element.product_id] = element.product_name_kanji
        }
      });
      this.productPricesGroupsObj.list = []
      Object.keys(insertObj).forEach(key => {
        this.productPricesGroupsObj.list.push({
          product_id: key,
          product_name_kanji: insertObj[key],
        })
      });

      // 製品コードすでに登録されたか
      const existProductIds = await this.existProductIds(Object.keys(insertObj))
      if (existProductIds.length != 0) {
        this.errorMessages.push(`製品コード:[${existProductIds}] はすでに単価グループとして登録されています。`);
        return
      }

      // 単価グループマスタを登録
      await this.execInsertPricesGroups()
    },
    /**
     * 登録処理
     */
    async execInsertPricesGroups() {
      const functionName = 'execInsertPricesGroups';

      let sqlInsertCol = ''
      let sqlInsertVal = []

      // 代表コード
      this.product_group_id = Number(this.productPricesGroupsObj.list[0].product_id)
      for (const key in this.productPricesGroupsObj.list) {
        const pricesGroup = this.productPricesGroupsObj.list[key]
        // メンバー製品コード
        const product_id = Number(pricesGroup.product_id)
        // 代表コード飛ばす
        if (this.product_group_id === product_id) continue

        const colList = [];
        // 代表製品コード
        colList.push(CreateColRow('product_group_id', this.product_group_id, 'INT'));
        // 製品コード
        colList.push(CreateColRow('product_id', product_id, 'INT'));
        // 新規と更新ユーザー
        const colBothUser = await getUserCol(this.username, 'both')

        // sql insert カラム部分
        sqlInsertCol = CreateInsertSql(colList.concat(colBothUser), 'col', 'm_products_prices_groups').split(',');
        // sql insert values部分
        sqlInsertVal.push(CreateInsertSql(colList.concat(colBothUser), 'val', 'm_products_prices_groups').split(','));
      }

      let sql = `INSERT INTO m_products_prices_groups (${sqlInsertCol}) VALUES `
      let insertArr = []
      for (const key in sqlInsertVal) {
        insertArr.push(`(${sqlInsertVal[key].join()})`)
      }

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.errorMessages.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }

      sql = `${sql} ${insertArr.join()}`
      const SQLs = [sql];
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
        }, error);
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.errorMessages.push(DISP_MESSAGES.DANGER['3001']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
    },
    /**
     * 製品コードすでに登録確認処理
     * @param {Array<Int>} productIds - 製品コード
    * @return {Array}  登録されてる製品コード
     */
    async existProductIds(productIds) {
      const functionName = 'existProductIds';

      const where_clause = `AND (product_group_id in (${productIds.join()}) OR product_id in (${productIds.join()}))`
      let result = null;
      try {
        result = await selectOneTable('m_products_prices_groups', where_clause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_products_prices_groups',
          where_clause: where_clause
        }, error);
        return null;
      }
      if (result != null && result.length > 0 ) {
        // 一時保存のSet
        let tempArray = new Set()
        result.forEach(element => {
          const match = this.productPricesGroupsObj.list.find((item) => {
            return (item.product_id == element.product_group_id) || (item.product_id == element.product_id) 
          })
          if (match) tempArray.add(match.product_id)
        });
        return Array.from(tempArray)
      }
      return []
    },
  }
}
</script>