import { render, staticRenderFns } from "./staff-list.vue?vue&type=template&id=ef753b04&scoped=true&"
import script from "./staff-list.vue?vue&type=script&lang=js&"
export * from "./staff-list.vue?vue&type=script&lang=js&"
import style0 from "./staff-list.vue?vue&type=style&index=0&id=ef753b04&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef753b04",
  null
  
)

export default component.exports