<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span>
                <strong> 受注修正{{getBillingClassText}}
                  <span class="oi oi-flag" v-b-tooltip.hover.noninteractive title="編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。"></span>
                </strong></h5>
                <b-button pill v-b-tooltip.hover.noninteractive.bottom="'受注入力照会に戻る'" @click="cancel" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </div>
            </b-media-body>
          </b-media>
          <div class="main-card mb-3 card">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="warning" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <div class="card-body">
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveData();" id="receivedOrderInputForm" class="form-horizontal">
                  <b-row>
                    <!-- 受注番号 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <b-input-group>
                        <template #prepend>
                          <label for="receivedOrderId">
                            <b-input-group-text class="px-1">
                              <strong class='text-body'>受注番号</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="receivedOrderId" type="text" v-model="receivedOrderId" readonly />
                      </b-input-group>
                    </div>
                  </b-row>
                  <!-- 営業所コードプルダウン -->
                  <b-row>
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="selectSalesOffice">
                            <b-input-group-text class="px-1">
                              <strong class='text-body'>営業所</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="selectSalesOffice" type="text" v-model="selectSalesOfficeText" readonly />
                      </b-input-group>
                    </div>
                    <!-- 配送種別区分プルダウン -->
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 form-group px-1">
                      <validation-provider name="selectDelivery" :rules="{required: true, billClassCombination: selectBillClass}" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="selectDelivery">
                              <b-input-group-text class="px-1">
                                <strong class='text-body'>配送</strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-select
                            id="selectDelivery"
                            name="selectDelivery"
                            :class="{'border-danger': errors[0]}"
                            v-model="selectDelivery"
                            :options="deliveryList"
                            value-field="id"
                            @change="setInitShippingCode"
                          />
                          <b-col :class="classes" lg="12">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                    <!-- 伝票種別区分プルダウン -->
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="selectBillClass">
                            <b-input-group-text class="px-1">
                              <strong class='text-body'>伝種</strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="selectBillClass" v-model="selectBillClassText" readonly />
                      </b-input-group>
                    </div>
                    <!-- 受注日付 -->
                    <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5 form-group px-1">
                      <validation-provider name="dateOrderReceiveDate" :rules="{required: true, oldProcessMonthYearChange: {processMonthYear: controlMasterData.processMonthYear, dateInit: dateOrderReceiveDateInit}}" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="dateOrderReceiveDate">
                              <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='変更される場合、製品の売価が自動で取り直されます'>
                                <strong class='text-body'>受注日付 <span class="oi oi-flag"/></strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-datepicker
                            id="dateOrderReceiveDate"
                            name="dateOrderReceiveDate"
                            :class="{'border-danger': errors[0]}"
                            v-model="dateOrderReceiveDate"
                            calendar-width="50%"
                            :disabled="statusFlg.shukkaFlg"
                            @input="refreshSalesAmount"
                            class="mb-2"
                          ></b-form-datepicker>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 取引先コード -->
                    <div class="col-sm-12 col-md-6 col-lg-5 col-xl-4 form-group px-1">
                      <validation-provider name="suppliersCode" :rules="{numeric: true, min: 6, max: 6, required: true, client_sundries_class: suppliers.sundriesClass}" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="suppliersCode">
                              <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='検索ボタンから選択してください。変更される場合、現場や製品等の関連項目が自動でクリアされます'>
                                <strong class='text-body'>取引先コード <span class="oi oi-flag"/></strong>
                              </b-input-group-text>
                            </label>
                          </template>
                            <b-form-input 
                              type="tel" 
                              id="suppliersCode" 
                              name="suppliersCode" 
                              :class="{'border-danger': errors[0]}"
                              @input="searchClient(suppliers.code)" 
                              maxlength="6"
                              v-model="suppliers.code"
                              :readonly="statusFlg.shukkaFlg==true"
                            />
                            <b-input-group-text @click="showClientSearchModal()" v-if="statusFlg.shukkaFlg==false" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                              <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                <span class="oi oi-magnifying-glass"></span>
                                <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                              </b-button>
                            </b-input-group-text>
                            <b-button class="mr-1" size="sm" pill :variant="isClientNoteInput == true ? 'danger' : 'success'" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが空欄以外の場合、利用可能です。コメントがある場合、赤色で表示します。）" :disabled="suppliers.code == ''" @click="clientInfoInquiryModal(suppliers.class, suppliers.code)">
                              <span class="oi oi-eye"></span> 情報
                            </b-button>
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                    <!-- 受注処理担当者 -->
                    <div class="col-sm-12 col-md-5 col-lg-5 col-xl-4 form-group px-1">
                      <validation-provider name="orderReceiveStaff" rules="required" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="orderReceiveStaff">
                              <b-input-group-text  class="px-1">
                                <strong class='text-body'>受注処理担当者 </strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input type="text" id="orderReceiveStaff" name="orderReceiveStaff" :class="{'border-danger': errors[0]}" v-model="orderReceiveStaff.text" readonly />
                          <b-input-group-text @click="showStaffSearchModal" v-if="statusFlg.shukkaFlg==false" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                            <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                              <span class="oi oi-magnifying-glass"></span>
                              <button type="button" v-shortkey="['alt', '2']" @shortkey="showStaffSearchModal" class="d-none"></button>
                            </b-button>
                          </b-input-group-text>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 取引先名 -->
                    <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9 form-group px-2">
                      <label class="form-label"><strong>取引先名: </strong>{{suppliers.name}}</label>
                    </div>
                     <!-- 締日 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <label class="form-label"><strong>締日: </strong>{{suppliers.closingDate}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 郵便番号 + 取引先住所１ + 取引先住所２ -->
                    <div class="col-12 form-group px-2">
                      <label class="form-label"><strong>取引先住所: </strong>{{suppliers.zipCode}} {{suppliers.address1}} {{suppliers.address2}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 取引先部署１ + 取引先部署２ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-2">
                      <label class="form-label"><strong>部署: </strong>{{suppliers.department1}} {{suppliers.department2}}</label>
                    </div>
                    <!-- 取引先TEL -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1\2">
                      <label class="form-label"><strong>TEL: </strong>{{suppliers.tel}}</label>
                    </div>
                    <!-- 担当者 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <label class="form-label"><strong>担当者: </strong>{{suppliers.personInCharge}}</label>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 単価登録区分 -->
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-5 form-group px-2">
                      <b-input-group>
                        <template #prepend>
                          <label>
                            <b-input-group-text  class="px-0" v-b-tooltip.hover.noninteractive  title='受注作成時に選択された取引先に単価登録を行うかの設定です(3：親取引先で設定した親子取引先にも単価登録を行う)'>
                              <strong class='text-body'>単価登録区分 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-col sm="8" md="8" lg="8" xl="8">
                          <b-form-radio-group
                            id="checkUnitPriceRegisterClass"
                            v-model="checkUnitPriceRegisterClass"
                            :options="unitPriceRegisterClassList"
                            value-field="id"
                          ></b-form-radio-group>
                          <validation-provider name="checkUnitPriceRegisterClass" rules="required" v-slot="{ classes,errors }">
                            <input name="checkUnitPriceRegisterClass" type="hidden" v-model="checkUnitPriceRegisterClass" />
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                      </b-input-group>
                    </div>
                    <!-- 単価 適用日／備考 -->
                    <div class="col-sm-12 col-md-6 col-lg-5 col-xl-3 form-group px-1">
                      <validation-provider name="dateUnitPriceEffectiveDate" :rules="{required_if: (checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertMine || checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertAll), oldDate: (checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertMine || checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertAll)}" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="dateUnitPriceEffectiveDate">
                              <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive title='登録された単価の適用開始日です。単価登録を行う場合は必ず設定してください'>
                                <strong class='text-body'>適用日 <span class="oi oi-flag"/></strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-datepicker
                            id="dateUnitPriceEffectiveDate"
                            name="dateUnitPriceEffectiveDate"
                            :class="{'border-danger': errors[0]}"
                            v-model="dateUnitPriceEffectiveDate"
                            calendar-width="50%"
                          ></b-form-datepicker>
                          <b-input-group-append>
                            <b-button size="sm" variant="outline-secondary" @click="dateUnitPriceEffectiveDate=''">
                              <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                            </b-button>
                          </b-input-group-append>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-5 col-xl-4 form-group px-1">
                      <validation-provider name="textUnitPriceNote" rules="max:20" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="textUnitPriceNote">
                              <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive title='単価登録に関する備考です。帳票には出力されません'>
                                <strong class='text-body'>備考 <span class="oi oi-flag"/></strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input id="textUnitPriceNote" name="textUnitPriceNote" :class="{'border-danger': errors[0]}" type="text" v-model="textUnitPriceNote" maxlength="20" />
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 登録区分 -->
                    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 form-group pl-2 pr-0">
                      <b-input-group v-if="(checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertMine || checkUnitPriceRegisterClass == htmlConst.UnitPriceRegisterClass.insertAll)">
                        <template #prepend>
                          <label for="checkRegisterClass">
                            <b-input-group-text  class="px-0" v-b-tooltip.hover.noninteractive  title='製品単価情報の登録に同製品グループも含めるかどうかを設定します（0：同製品グループも含め登録、1：自身のみ登録）'>
                              <strong class='text-body'>登録区分 <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-col sm="8" md="8" lg="7" xl="9">
                          <b-form-radio-group
                            id="checkRegisterClass"
                            v-model="checkRegisterClass"
                            :options="registerClassList"
                            value-field="id"
                          ></b-form-radio-group>
                          <validation-provider name="checkRegisterClass" rules="required" v-slot="{ classes,errors }">
                            <input name="checkRegisterClass" type="hidden" v-model="checkRegisterClass" />
                            <b-col lg="12" :class="classes">
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </b-col>
                          </validation-provider>
                        </b-col>
                      </b-input-group>
                    </div>
                    <!-- 起動時の単価登録情報削除チェック -->
                    <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 form-group pl-2 pr-0" v-if="this.openClientProductData.unitPriceRegisterClass != htmlConst.UnitPriceRegisterClass.insertNo">
                      <b-input-group>
                        <template #prepend>
                          <label for="isDelOpenClientProducts">
                            <b-input-group-text  class="px-0" v-b-tooltip.hover.noninteractive  title="チェックONの場合、保存時に画面起動時の単価登録情報（適用日、取引先、親子関係、グループ、製品）を削除します。">
                              <strong class='text-body'>画面起動時の単価登録情報削除チェック <span class="oi oi-flag"/></strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-checkbox
                          id="isDelOpenClientProducts"
                          name="isDelOpenClientProducts"
                          class="ml-2"
                          v-model="isDelOpenClientProducts"
                        >
                        </b-form-checkbox>
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 納品指定日 -->
                    <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="orderDate">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>納品指定日 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-datepicker id="orderDate" v-model="orderDate"></b-form-datepicker>
                        <b-input-group-append>
                          <b-button size="sm" variant="outline-secondary" @click="orderDate=''">
                            <span class="oi oi-circle-x" style="vertical-align: middle;"></span>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <!-- 納品指定区分プルダウン -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="selectDeliveryDesignation">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>納品指定区分 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-select
                          id="selectDeliveryDesignation"
                          v-model="selectDeliveryDesignation"
                          :options="deliveryDesignation"
                          value-field="id"
                          @change="inputDeliveryNote"
                        />
                      </b-input-group>
                    </div>
                    <!-- 納品指定 -->
                    <div class="col-sm-12 col-md-4 col-lg-5 col-xl-5 form-group px-1">
                      <validation-provider name="deliveryNote" rules="max:34" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="deliveryNote">
                              <b-input-group-text  class="px-1">
                                <strong class='text-body'>納品指定 </strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input id="deliveryNote" name="deliveryNote" :class="{'border-danger': errors[0]}" type="text" v-model="deliveryNote" maxlength="34" />
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 現場コード -->
                    <div class="col-sm-12 col-md-5 col-lg-4 col-xl-4 form-group px-1">
                      <validation-provider name="onSiteCode" rules="required|numeric" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="onSiteCode">
                              <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='取引先を指定すると検索入力可能になります。検索ボタンから選択してください。情報ボタンは入力されたコードに紐づく詳細情報を表示します'>
                                <strong class='text-body'>現場コード <span class="oi oi-flag"/></strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input
                            id="onSiteCode"
                            name="onSiteCode"
                            :class="{'border-danger': errors[0]}"
                            @change="searchSite(onSite.code)"
                            type="text"
                            v-model="onSite.code"
                            :readonly="!checkClientId(suppliers.code)||statusFlg.directShukkaFlg==true"
                            maxlength="4"
                          />
                          <b-input-group-text @click="showSiteSearchModal()" v-if="checkClientId(suppliers.code)&&statusFlg.directShukkaFlg==false" v-b-tooltip.hover.noninteractive title="「ALT+3」ボタンで呼出可能">
                            <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                              <span class="oi oi-magnifying-glass"></span>
                              <button type="button" v-shortkey="['alt', '3']" @shortkey="showSiteSearchModal()" class="d-none"></button>
                            </b-button>
                          </b-input-group-text>
                          <b-button class="mr-1" pill size="sm" :variant="isSiteNoteInput == true ? 'danger' : 'success'" title="現場コードに入力された現場の情報を照会します。（現場コードが空欄以外の場合、利用可能です。コメントがある場合、赤色で表示します。）" :disabled="onSite.code == ''" @click="siteInfoInquiryModal(suppliers.code, onSite.code)">
                            <span class="oi oi-eye"></span> 情報
                          </b-button>
                          <b-button class="mr-1" pill size="sm" variant="success" title="現場データの新規登録を行います。" @click="clickSiteInput()">
                            <span class="oi oi-plus"></span> 現登
                          </b-button>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                    <!-- 現場名（漢字） -->
                    <div class="col-sm-12 col-md-4 col-lg-5 col-xl-5 form-group px-1">
                      <validation-provider name="onSiteName" rules="max:50" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="onSiteName">
                              <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='現場コードを指定すると入力中の情報が上書きされます。ご注意下さい。'>
                                <strong class='text-body'>現場名 <span class="oi oi-flag"/></strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input type="text" id="onSiteName" name="onSiteName" v-model="onSite.name" maxlength="50" :readonly="statusFlg.directShukkaFlg" style="color: red;"></b-form-input>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                    <!-- 現場郵便番号 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <validation-provider name="onSiteZipCode" rules="zip_code" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="onSiteZipCode">
                              <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='「nnn-nnnn」形式で入力してください。現場コードを指定すると入力中の情報が上書きされます。ご注意下さい。'>
                                <strong class='text-body'>郵便番号 <span class="oi oi-flag"/></strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input type="text" id="onSiteZipCode" name="onSiteZipCode" :class="{'border-danger': errors[0]}" v-model="onSite.zipCode" :readonly="statusFlg.directShukkaFlg"></b-form-input>
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 配送コード -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="onSiteDeliveryCode">
                            <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='現場コードを指定すると検索入力可能になります。配送、または、現場コードを指定すると入力中の情報が上書きされます。ご注意下さい。'>
                              <strong class='text-body'>配送コード </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="onSiteDeliveryCode" maxlength="5" v-model="onSite.deliveryCode" :readonly="onSite.code == '' || selectDelivery == htmlConst.ShippingTypeClass.direct || selectDelivery == htmlConst.ShippingTypeClass.delivery" @blur="toUpperShippingCode"></b-form-input>
                        <b-input-group-text @click="showRouteSearchModal()" v-if="onSite.code != '' && selectDelivery != htmlConst.ShippingTypeClass.delivery && selectDelivery != htmlConst.ShippingTypeClass.direct" v-b-tooltip.hover.noninteractive title="「ALT+4」ボタンで呼出可能">
                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                            <span class="oi oi-magnifying-glass"></span>
                            <button type="button" v-shortkey="['alt', '4']" @shortkey="showRouteSearchModal()" class="d-none"></button>
                          </b-button>
                        </b-input-group-text>
                      </b-input-group>
                    </div>
                    <!-- 印刷用宛名 -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="clientPrintName">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>印刷用宛名 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="clientPrintName" readonly v-model="onSite.clientPrintNameText"></b-form-input>
                      </b-input-group>
                    </div>
                    <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5 form-group px-1">
                      <!-- チェック -->
                      <b-input-group>
                        <template #prepend>
                          <label for="onSiteCheck">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>チェック内容 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="onSiteCheck" readonly v-model="suppliers.check"></b-form-input>
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 現場住所１ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-1">
                      <validation-provider name="onSiteAddress1" rules="max:34" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="onSiteAddress1">
                              <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='現場コードを指定すると入力中の情報が上書きされます。ご注意下さい。'>
                                <strong class='text-body'>住所１ <span class="oi oi-flag"/></strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input type="text" id="onSiteAddress1" name="onSiteAddress1" :class="{'border-danger': errors[0]}" v-model="onSite.address1" maxlength="34" :readonly="statusFlg.directShukkaFlg" />
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                    <!-- 現場住所２ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-1">
                      <validation-provider name="onSiteAddress2" rules="max:34" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="onSiteAddress2">
                              <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='現場コードを指定すると入力中の情報が上書きされます。ご注意下さい。'>
                                <strong class='text-body'>住所２ <span class="oi oi-flag"/></strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input type="text" id="onSiteAddress2" name="onSiteAddress2" :class="{'border-danger': errors[0]}" v-model="onSite.address2" maxlength="34" :readonly="statusFlg.directShukkaFlg" />
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 納品場所 -->
                    <div class="col-sm-12 col-md-6 col-lg-7 col-xl-7 form-group px-1">
                      <validation-provider name="onSiteDeliveryPlace" rules="max:54" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="onSiteDeliveryPlace">
                              <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='現場コードを指定すると入力中の情報が上書きされます。ご注意下さい。'>
                                <strong class='text-body'>納品場所 <span class="oi oi-flag"/></strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input type="text" id="onSiteDeliveryPlace" name="onSiteDeliveryPlace" :class="{'border-danger': errors[0]}" v-model="onSite.deliveryPlace" maxlength="54" :readonly="statusFlg.directShukkaFlg" />
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                    <!-- 現場TEL -->
                    <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 form-group px-1">
                      <validation-provider name="onSiteTel" :rules="{phone: !statusFlg.directShukkaFlg}" v-slot="{ classes,errors }">
                        <b-input-group>
                          <template #prepend>
                            <label for="onSiteTel">
                              <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive  title='現場コードを指定すると入力中の情報が上書きされます。ご注意下さい。'>
                                <strong class='text-body'>TEL <span class="oi oi-flag"/></strong>
                              </b-input-group-text>
                            </label>
                          </template>
                          <b-form-input type="text" id="onSiteTel" name="onSiteTel" :class="{'border-danger': errors[0]}" v-model="onSite.tel" maxlength="21" :readonly="statusFlg.directShukkaFlg" />
                          <b-col lg="12" :class="classes">
                            <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                          </b-col>
                        </b-input-group>
                      </validation-provider>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 製品 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :items="productList"
                            :fields="fields"
                            :small="true"
                          >
                            <template #table-caption>
                              <b-btn-toolbar>
                                <span class="mt-2 mr-2">&nbsp;製品テーブル</span> 
                                <!-- 行追加ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" type="button" @click="addTableRow()" v-if="statusFlg.orderFlg==false">
                                  <span class="oi oi-plus"></span> 行追加
                                </b-button>&nbsp;
                                <!-- 行追加ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" type="button" @click="addTableRow(5)" v-if="statusFlg.orderFlg==false">
                                  <span class="oi oi-plus"></span> 5行追加
                                </b-button>&nbsp;
                                <!-- 在庫照会ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" title="在庫照会モーダルを表示します。" @click="openStockModal()">
                                  <span class="oi oi-eye"></span> 在庫照会
                                </b-button>&nbsp;
                                <!-- 取引先製品単価履歴照会ボタン -->
                                <b-button pill variant="success" size="sm" class="mr-2" title="取引先製品単価履歴照会モーダルを表示します。" @click="clickInquryClientProduct">
                                  <span class="oi oi-eye"></span> 取引先製品単価履歴照会
                                </b-button>
                              </b-btn-toolbar>
                            </template>
                            <template #cell(ProductCode)="data">
                              <validation-provider :rules="{numeric: true, min: 8, max: 8, required: true, case_conversion_class: {caseConversionClass: data.item.CaseConversion, checkCaseFlg: selectDelivery == htmlConst.ShippingTypeClass.direct}, minimum_quantity: {minimumQuantity: data.item.MinimumQuantity, caseConversionClass: data.item.CaseConversion, inventoryControlClass: data.item.InventoryClass}, case_conversion_product: {caseConversionErr: data.item.CaseConversionErr}}" v-slot="{ classes,errors }">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-input-group style="width: 130px;">
                                      <b-form-input
                                        type="text"
                                        name="productId"
                                        class="px-1"
                                        @input="searchProduct(data.item.ProductCode, data.index)"
                                        :readonly="!checkClientId(suppliers.code) || productListHidden.length > data.index"
                                        v-model="data.item.ProductCode"
                                        maxlength="8"
                                        style="width: 80px;"
                                        size="sm"
                                      />
                                      <b-input-group-text @click="showProductSearchModal(data.index)" v-if="checkClientId(suppliers.code) && productListHidden.length <= data.index">
                                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                          <span class="oi oi-magnifying-glass"></span>
                                        </b-button>
                                      </b-input-group-text>
                                    </b-input-group>
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </validation-provider>
                            </template>
                            <template #cell(ProductName)="data">
                              <validation-provider rules="max:35" v-slot="{ classes,errors }">
                                <b-input-group style="width: 340px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <span style="font-size: 20px;" v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark : '' }}</span>
                                        <b-form-input style="width: 320px;" size="sm" class="px-1" type="text" name="productName" v-model="data.item.ProductName" :readonly="data.item.SundriesClass != htmlConst.SundriesClass.shokuchi||data.item.OrderJokyo==htmlConst.ReceivedOrderOrderJokyo.nyukaZumi||data.item.ShipsClass==htmlConst.ShipsClass.sumi||data.item.IsDeleted==1" v-b-tooltip.hover.noninteractive.right="data.item.ProductName" />
                                      </b-input-group>
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(ServiceClass)="data">
                              <b-input-group style="width: 120px;">
                                <b-form-select
                                  v-model="data.item.ServiceClass"
                                  :options="serviceList"
                                  value-field="id"
                                  :disabled="data.item.IsDeleted==1||data.item.ShipsClass==htmlConst.ShipsClass.sumi||data.item.OrderJokyo==htmlConst.ReceivedOrderOrderJokyo.nyukaZumi"
                                  @change="changeService(data.index)"
                                  size="sm"
                                  class="px-1"
                                />
                              </b-input-group>
                            </template>
                            <template #cell(Quantity)="data">
                              <validation-provider :rules="{required: true, orders_receives_between: {orderReceiveBillClass: selectBillClass, productSundriesClass: data.item.SundriesClass, shippingQuantity: data.item.ShippingQuantity, isDeleted: data.item.IsDeleted}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 70px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="number" :id="'productQuantity' + data.index" name="productQuantity" v-model.number="data.item.Quantity" @blur="calc(data.index)" :readonly="data.item.IsDeleted==1||data.item.OrderJokyo==htmlConst.ReceivedOrderOrderJokyo.nyukaZumi||((selectDelivery == htmlConst.ShippingTypeClass.direct || selectBillClass == htmlConst.OrderReceiveBillClass.special) && data.item.ShipsClass == htmlConst.ShipsClass.sumi)" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(Unit)="data">
                              <validation-provider rules="max:5" v-slot="{ classes,errors }">
                                <b-input-group style="width: 40px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="text" name="productUnit" v-model="data.item.Unit" :readonly="data.item.IsDeleted==1||data.item.ShipsClass==htmlConst.ShipsClass.sumi||data.item.OrderJokyo==htmlConst.ReceivedOrderOrderJokyo.nyukaZumi" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(SalesUnitPrice)="data">
                              {{ data.item.SalesUnitPrice.toLocaleString() }}
                            </template>
                            <template #cell(SellingPrice)="data">
                              <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                                <b-input-group style="width: 120px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group class="d-flex align-items-center">
                                        <b-form-input size="sm" class="px-1" type="number" name="productSellingPrice" v-model.number="data.item.SellingPrice" @blur="checkGrossProfit(data.item, data.index); calc(data.index);" :readonly="data.item.IsDeleted==1||data.item.ShipsClass==htmlConst.ShipsClass.sumi||data.item.OrderJokyo==htmlConst.ReceivedOrderOrderJokyo.nyukaZumi" />
                                        <b style="font-size: 20px;">{{ data.item.ClientAmountClass }}</b>
                                      </b-input-group>
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(PurchasePrime)="data">
                              <validation-provider rules="required|between:-99999999,99999999" v-slot="{ classes,errors }">
                                <b-input-group style="width: 100px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input size="sm" class="px-1" type="number" name="productPurchasePrime" v-model.number="data.item.PurchasePrime" @blur="checkGrossProfit(data.item, data.index); calc(data.index);" :readonly="data.item.IsDeleted==1||data.item.ShipsClass==htmlConst.ShipsClass.sumi||data.item.OrderJokyo==htmlConst.ReceivedOrderOrderJokyo.nyukaZumi" />
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(Money)="data">
                              {{ data.item.Money.toLocaleString() }}
                              <validation-provider :rules="{set_val_between:{min:-99999999,max:99999999,title:'金額'}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 90px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <input type="hidden" name="Money" v-model.number="data.item.Money" />
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                              <validation-provider :rules="{set_val_between:{min:-99999999,max:99999999,title:'仕入金額'}}" v-slot="{ classes,errors }">
                                <b-input-group style="width: 90px;">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <input type="hidden" name="TotalPurchasePrime" v-model.number="data.item.TotalPurchasePrime" />
                                      <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                    </b-col>
                                  </b-row>
                                </b-input-group>
                              </validation-provider>
                            </template>
                            <template #cell(SupplierCode)="data">
                              <validation-provider :rules="{required_if:{target: data.item.IsDeleted, values: 0}, order_supplier_id: {inventoryControlClass: data.item.InventoryClass, caseConversionClass: data.item.CaseConversion}}" v-slot="{ classes,errors }">
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-input-group style="width: 175px;">
                                      <b-form-input size="sm" class="px-1" type="text" name="SupplierCode" v-model="data.item.SupplierCode" readonly style="width: 60px;" />
                                      <b-input-group-text @click="showSuppliersSearchModal(data.index)" v-if="data.item.ProductCode != null && (selectDelivery == htmlConst.ShippingTypeClass.direct || selectBillClass == htmlConst.OrderReceiveBillClass.special) && data.item.IsDeleted == 0 && data.item.OrderJokyo < htmlConst.ReceivedOrderOrderJokyo.nyukaZumi && data.item.InventoryClass == htmlConst.InventoryControlClassDef.inventory">
                                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                          <span class="oi oi-magnifying-glass"></span>
                                        </b-button>
                                      </b-input-group-text>
                                      <b-button class="mr-1" size="sm" pill variant="success" title="発注先コードに入力された仕入先の情報を照会します。" @click="clientInfoModal(htmlConst.ClientClass.supplier, data.item.SupplierCode)" v-if="data.item.SupplierCode != null && data.item.SupplierCode != 0">
                                        <span class="oi oi-eye"></span> 情報
                                      </b-button>
                                    </b-input-group>
                                  </b-col>
                                  <b-col lg="12" :class="classes">
                                    <b-form-group style="color: #EB0600;" v-if="errors[0]" :label="errors[0]" id="error"/>
                                  </b-col>
                                </b-row>
                              </validation-provider>
                            </template>
                            <template #cell(ReserveQuantity)="data">
                              {{ data.item.ReserveQuantity }}
                            </template>
                            <template #cell(SupplierName)="data">
                              <div style="min-width:180px;">
                                <div style="width:100%;overflow: hidden;text-overflow: ellipsis; white-space: normal;">
                                  <span>
                                    {{data.item.SupplierName}}
                                  </span>
                                </div>
                              </div>
                            </template>
                            <template #cell(delete)="data">
                              <b-button size="sm" v-b-tooltip.hover.noninteractive title="この行を削除をします。" @click="deleteBtn(data.index)" v-if="productListHidden.length <= data.index" class="mr-1">
                                <span class="oi oi-delete"></span> 削除
                              </b-button>
                              <b-form-checkbox
                                v-model="data.item.IsDeleted"
                                v-if="productListHidden.length > data.index"
                                :disabled="data.item.ShipsClass==htmlConst.ShipsClass.sumi || data.item.OrderJokyo==htmlConst.ReceivedOrderOrderJokyo.nyukaZumi"
                                value="1"
                                unchecked-value="0"
                                @change="data.item.Quantity=0; calc(data.index)"
                              ></b-form-checkbox>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form>
                            <b-form-text label-align="left" class="text-muted">取引先を指定すると製品の検索入力可能になります。<br>直送、または、特別受注の場合、発注先の変更が可能です。</b-form-text>
                          </b-form>
                        </b-col>
                        <b-col>
                          <b-form>
                            <b-form-group :label="textTotalMoneyText" label-align="right"></b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <!-- 保存ボタン -->
                  <b-row class="mt-2 justify-content-md-center">
                    <b-col lg="2">
                      <b-button pill variant="success" type="submit" form="receivedOrderInputForm" class="btn btn-block" @click="delListLastBlankRow"><span class="oi oi-circle-check"></span> 保存</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●担当者検索モーダル●●● -->
    <STAFFSEARCH @from-child="closeStaffSearchModal"/>
    <!-- ●●●取引先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="propClientClass"/>
    <!-- ●●●取引先照会モーダル●●● -->
    <CLIENTINQUIRY :clientProp="propClientInquiry"/>
    <!-- ●●●取引先情報照会モーダル●●● -->
    <CLIENTINFORMATIONINQUIRY @from-child="closeClientInfoInquiryModal" :clientProp="propClientInquiry"/>
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :product-search-prop="productSearchProp"/>
    <!-- ●●●現場検索モーダル●●● -->
    <SITESEARCH @from-child="closeSiteSearchModal" :client-info="siteSearchInfo" />
    <!-- ●●●現場情報照会モーダル●●● -->
    <SITEINFORMATIONINQUIRY @from-child="closeSiteInfoInquiryModal" :siteProp="propSiteInquiry"/>
    <!-- ●●●ルートマスタ検索モーダル●●● -->
    <ROUTESEARCH @from-child="closeRouteSearchModal"/>
    <!-- 在庫照会モーダル -->
    <STOCKINQUIRY :stock-info="stockInfo" />
    <!-- ●●●取引先製品単価履歴照会モーダル●●● -->
    <CLIENTPRODUCTINQUIRY :clientProductProp="clientProductProp" />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import STAFFSEARCH from '@/components/modal/staff-search.vue';
import { searchProductsModal, searchSiteModal, searchClientModal } from '@/assets/js/searchModal.js';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import SITESEARCH from '@/components/modal/site-search.vue';
import SITEINFORMATIONINQUIRY from '@/components/modal/site-information-inquiry.vue';
import ROUTESEARCH from '@/components/modal/route-search.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import CLIENTINFORMATIONINQUIRY from '@/components/modal/client-information-inquiry.vue';
import STOCKINQUIRY from '@/components/modal/stock-inquiry.vue';
import CLIENTPRODUCTINQUIRY from '@/components/modal/client-product-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, getControlMaster, formatDate, getNullStr, getOrderReceiveClientNote, getOrderReceiveSiteNote, getListValue, isHoliday, checkMasterRoutes, checkClientId, executeSelectSql, CreateInsertSql, CreateUpdateSql, CreateColRow, escapeQuote, executeSqlList, modStockProductIdListCase, reserveStockProductIdList, isSystemEditable, isOtherSessionUpdated, isAfterMonthlyUpdate, refreshSalesAmountProductList, getInsertUnitPriceSqlList, getDeleteUnitPriceSqlList, getBillingMonthYear, getClosingDate, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'received-order-edit';

export default {
  name: 'RECEIVED-ORDER-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    PRODUCTSEARCH,
    SITESEARCH,
    SITEINFORMATIONINQUIRY,
    ROUTESEARCH,
    CLIENTSEARCH,
    CLIENTINQUIRY,
    CLIENTINFORMATIONINQUIRY,
    STOCKINQUIRY,
    STAFFSEARCH,
    CLIENTPRODUCTINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '受注修正',
      loginId: '',
      listUpdatedSelect: [],
      // 取引先コード確認関数
      checkClientId: checkClientId,
      // アラート
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOffice: null,
      selectSalesOfficeText: '',
      salesOffice: [],
      // 受注日付
      dateOrderReceiveDate: '',
      dateOrderReceiveDateInit: '',
      // 受注処理担当者
      orderReceiveStaff: {
        id: '',
        text: '',      
      },
      // 配送プルダウン
      selectDelivery: '',
      deliveryList: [],
      // 伝種プルダウン
      selectBillClass: null,
      selectBillClassText: '',
      // 取引先系
      suppliers: {
        closingDate: '',
        code: '',
        name: '',
        class: '',
        address1: '',
        address2: '',
        zipCode: '',
        department1: '',
        department2: '',
        tel: '',
        personInChargeId: '',
        personInCharge: '',
        sundriesClass: '',
        firstDigit: '',
        taxClass: '',
        taxCalcClass: '',
        taxFracClass: '',
        check: '',
      },
      // 単価登録区分ラジオボタン
      checkUnitPriceRegisterClass: null,
      unitPriceRegisterClassList: Const.UnitPriceRegisterClassList,
      // 登録区分ラジオボタン
      checkRegisterClass: null,
      registerClassList: Const.ClientsProductsMasterRegisterClassList,
      // 単価適用日
      dateUnitPriceEffectiveDate: '',
      // 単価備考
      textUnitPriceNote: '',
      // 取引先製品単価適用の有無
      isDelOpenClientProducts: true,
      // 納品指定日
      orderDate: '',
      // 納品指定区分
      selectDeliveryDesignation: '',
      deliveryDesignation: [],
      // 納品メモ
      deliveryNote: '',
      // 現場系
      onSite: {
        deliveryCode: '',
        siteDeliveryCode: '',
        code: '',
        name: '',
        zipCode: '',
        address1: '',
        address2: '',
        deliveryPlace: '',
        tel: '',
        clientPrintClass: '',
        clientPrintName: '',
        clientPrintNameText: '',
      },
      // 取引先モーダル用の引数
      propClientClass: null,
      // テーブル定義
      productList:[],
      // 製品一覧情報
      dataIndex: 0,
      // 合計情報
      totalFee: 0,
      totalPurchasePrime: 0,
      grossProfit: 0,
      // サービス区分
      serviceList: [],
      // 製品検索情報
      productSearchProp: {
        office_id: '',
        office_name: '',
        client_id: '',
        client_class: '',
        client_name: '',
        multiSelectFlg: '1',
        kijunDate: '',
      },
      // 発注先検索
      orderSearchInfo: {
        office_id: '',
        product_id: '',
      },
      // 在庫照会情報
      stockInfo: {
        office_id: '',
        product_id: [],
      },
      // 現場検索情報
      siteSearchInfo: {
        client_id: '',
        client_name: '',
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 取引先の受注ノートの有無
      isClientNoteInput: false,
      // 現場情報照会に渡すパラメータ
      propSiteInquiry: {
        clientId: null,
        siteId: null,
      },
      // 現場の受注ノートの有無
      isSiteNoteInput: false,
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        lightTaxMark: '',
      },
      // 取引先別製品照会に渡すパラメータ
      clientProductProp: {
        clientClass: null,
        clientId: null,
        clientNameKanji: '',
        productIdCsv: '',
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 取引先区分
        ClientClass: {
          // 得意先
          customer: Const.ClientClass.customer,
          // 仕入先
          supplier: Const.ClientClass.supplier,
        },
        // 配送種別区分
        ShippingTypeClass: {
          // 通常配送
          normal: Const.ShippingTypeClass.normal,
          // 宅配
          delivery: Const.ShippingTypeClass.delivery,
          // 営業持参
          bring: Const.ShippingTypeClass.bring,
          // 直送
          direct: Const.ShippingTypeClass.direct,
        },
        // 伝種（受注伝票種別区分）プルダウン
        OrderReceiveBillClass: {
          normal: Const.OrderReceiveBillClass.normal,
          return: Const.OrderReceiveBillClass.return,
          special: Const.OrderReceiveBillClass.special,
        },
        // 諸口区分
        SundriesClass: {
          normal: Const.SundriesClass.normal,
          shokuchi: Const.SundriesClass.shokuchi,
        },
        // 単価登録区分
        UnitPriceRegisterClass: {
          // 登録しない
          insertNo: Const.UnitPriceRegisterClass.insertNo,
          // 自身のみ登録
          insertMine: Const.UnitPriceRegisterClass.insertMine,
          // 親子全て登録
          insertAll: Const.UnitPriceRegisterClass.insertAll,
        },
        // 受注データの発注状況
        ReceivedOrderOrderJokyo: {
          // 在庫不要
          noStock: Const.ReceivedOrderOrderJokyo.noStock,
          // 在庫管理
          stock: Const.ReceivedOrderOrderJokyo.stock,
          // 発注未
          orderMi: Const.ReceivedOrderOrderJokyo.orderMi,
          // 発注済
          orderZumi: Const.ReceivedOrderOrderJokyo.orderZumi,
          // 入荷済
          nyukaZumi: Const.ReceivedOrderOrderJokyo.nyukaZumi,
        },
        // 出荷確定区分
        ShipsClass: {
          // 未
          mi: Const.ShipsClass.mi,
          // 済
          sumi: Const.ShipsClass.sumi,
        },
        // 在庫管理区分
        InventoryControlClassDef: {
          // する
          inventory: Const.InventoryControlClassDef.inventory,
          // しない
          noInventory: Const.InventoryControlClassDef.noInventory,
        },
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // 入力日
      inputDate: '',
      // 入力担当者コード
      inputStaffId: null,
      // トランザクションSQLリスト
      transactSqlList: [],
      // 裏製品情報（在庫マスタへの引当等で使用）
      productListHidden: [],
      // ステータスフラグ
      statusFlg: {
        shukkaFlg: false,
        directShukkaFlg: false,
        editFlg: true,
        orderFlg: false,
        allDeletedFlg: true,
      },
      // 画面起動時、取引先製品情報
      openClientProductData: [],
      // テーブル構成
      fields: [],
      // 確認ダイアログ用
      confirmMessage: [],
      // パラメータ
      receivedOrderId: 0,
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 配送種別と伝種毎の伝票の意味のテキスト */
    getBillingClassText: function() {
      if ((this.selectDelivery == Const.ShippingTypeClass.direct && (this.selectBillClass == Const.OrderReceiveBillClass.return || this.selectBillClass == Const.OrderReceiveBillClass.special)) ||
      this.selectDelivery == '' || this.selectBillClass == null) {
        return '';
      } else if (this.selectDelivery == Const.ShippingTypeClass.delivery && this.selectBillClass == Const.OrderReceiveBillClass.normal) {
        return '[宅配]';
      } else if (this.selectDelivery == Const.ShippingTypeClass.bring && this.selectBillClass == Const.OrderReceiveBillClass.normal) {
        return '[営業持参]';
      } else if (this.selectDelivery == Const.ShippingTypeClass.direct && this.selectBillClass == Const.OrderReceiveBillClass.normal) {
        return '[直送]';
      } else if (this.selectBillClass == Const.OrderReceiveBillClass.normal) {
        return '[通常配送]';
      } else if (this.selectBillClass == Const.OrderReceiveBillClass.return) {
        return '[返品]';
      } else {
        return '[特別発注(受注)]';
      }
    },
    /* 合計値のテキスト */
    textTotalMoneyText: function() {
      let retTotalMoneyText = '粗利：' + (!Number.isFinite(this.grossProfit) ? 0 : this.grossProfit) + ' %';
      retTotalMoneyText += '　';
      retTotalMoneyText += '合計仕入単価：' + this.totalPurchasePrime.toLocaleString() + ' 円';
      retTotalMoneyText += '　';
      retTotalMoneyText += '合計金額：' + this.totalFee.toLocaleString() + ' 円';
      return retTotalMoneyText;
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.receivedOrderId = this.$route.query.receivedOrderId;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        //console.time('time1');
        // 更新時の排他制御用の変数作成
        await this.createUpdatedSelect();
        // 製品テーブル初期表示データ用意
        this.addTableRow();
        // ログインユーザーの情報(LoginID)
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let officeListData = null;
        let deliveryListData = null;
        let serviceListData = null;
        let controlData = null;
        [officeListData, deliveryListData, serviceListData, controlData] = await Promise.all([
          selectOneTable('m_offices'),
          selectOneTable('m_deliverys_designs_classes'),
          selectOneTable('m_service_classes'),
          getControlMaster(),
        ]);
        // 営業所データ取得
        //console.log(officeListData);
        for (let i = 0; i < officeListData.length; i++) {
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.salesOffice.push(office);
        }
        // 納品指定区分を取得
        //console.log(deliveryListData);
        this.deliveryDesignation.push({id: '', name: '', text: ''});
        for (let i = 0; i < deliveryListData.length; i++) {
          let deliveryDesignation = {
            id: deliveryListData[i].delivery_design_class,
            name: deliveryListData[i].delivery_design_name,
            text: deliveryListData[i].delivery_design_class + '：' + deliveryListData[i].delivery_design_name,
          };
          this.deliveryDesignation.push(deliveryDesignation);
        }
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        //console.log(serviceListData);
        for(let i = 0; i < serviceListData.length; i++){
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // コントロールマスタ
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        //console.timeEnd('time1');
        //console.time('time2');
        // 既存データ設定
        await this.setReceivedOrder();
        //console.timeEnd('time2');
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      // 取引先コードにフォーカスを移動
      this.setFocus('suppliersCode');
      this.$store.commit('setLoading', false);
    },
    /* 受注データ設定 */
    async setReceivedOrder() {
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null) {
        await this.setResult(dataResult);
        // 紐づく発注が注文済み、且つ、編集可能な場合、注意メッセージを表示
        if (this.statusFlg.orderFlg == true && this.statusFlg.editFlg == true) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2026']);
        }
        if (this.statusFlg.allDeletedFlg == true) {
          this.statusFlg.editFlg = false;
          this.alertWarning.push(DISP_MESSAGES.WARNING['2032']);
        }
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索SQL作成 */
    makeSelectSql: function() {
      let selectSql = '';
      // 2つのSQLのUNION結果を取得する
      // ・1：配送種別区分が「4：直送」以外（出荷データや入荷データと外部結合して進捗状況を取得）
      // ・2：配送種別区分が「4：直送」（累積トランザクションと外部結合して進捗状況を取得）
      for (let i = 0; i < 2; i++) {
        if (i != 0) {
          selectSql += ' UNION ALL ';
        }
        /* SELECT句 */
        selectSql += 'SELECT ';
        selectSql += ' orders_receives.order_receive_id';
        selectSql += ',orders_receives.order_receive_row';
        selectSql += ',orders_receives.office_id';
        selectSql += ',orders_receives.order_receive_date';
        selectSql += ',orders_receives.order_receive_staff_id';
        selectSql += ',order_receive_staffs.staff_name_kanji AS order_receive_staff_name_kanji';
        selectSql += ',orders_receives.shipping_type_class';
        selectSql += ',orders_receives.order_receive_bill_class';
        selectSql += ',orders_receives.unit_price_register_class';
        selectSql += ',orders_receives.unit_price_effective_date';
        selectSql += ',orders_receives.unit_price_note';
        selectSql += ',orders_receives.client_class';
        selectSql += ',orders_receives.client_id';
        selectSql += ',orders_receives.client_name AS client_name_kanji';
        selectSql += ',clients.closing_date';
        selectSql += ',orders_receives.zip_code';
        selectSql += ',orders_receives.sundries_class';
        selectSql += ',orders_receives.client_id_first_digit';
        selectSql += ',clients.sales_tax_class';
        selectSql += ',clients.tax_calculation_class';
        selectSql += ',clients.tax_fractionation_class';
        selectSql += ',orders_receives.address_1';
        selectSql += ',orders_receives.address_2';
        selectSql += ',orders_receives.department_1';
        selectSql += ',orders_receives.department_2';
        selectSql += ',orders_receives.phone_number';
        selectSql += ',orders_receives.staff_id';
        selectSql += ',staffs.staff_name_kanji';
        selectSql += ',clients.check_contents';
        selectSql += ',orders_receives.delivery_design_date';
        selectSql += ',orders_receives.delivery_design_class';
        selectSql += ',orders_receives.delivery_design';
        selectSql += ',orders_receives.site_id';
        selectSql += ',orders_receives.shipping_code';
        selectSql += ',orders_receives.client_site_name AS client_site_name_kanji';
        selectSql += ',orders_receives.client_site_zip_code';
        selectSql += ',orders_receives.client_site_address_1';
        selectSql += ',orders_receives.client_site_address_2';
        selectSql += ',orders_receives.delivery_location';
        selectSql += ',orders_receives.client_site_phone_number';
        selectSql += ',IfNull(clients_sites.order_print_class,' + Const.OrderPrintClass.customerPrint + ') AS order_print_class';
        selectSql += ',IfNull(clients_sites.order_print_client_name,\'\') AS order_print_client_name';
        selectSql += ',orders_receives.product_id';
        selectSql += ',orders_receives.product_name AS product_name_kanji';
        selectSql += ',orders_receives.service_class';
        selectSql += ',orders_receives.product_class_id';
        selectSql += ',orders_receives.order_receive_quantity';
        selectSql += ',orders_receives.unit';
        selectSql += ',products.sales_unit_price';
        selectSql += ',orders_receives.order_receive_unit_amount';
        selectSql += ',orders_receives.client_amount_class';
        selectSql += ',orders_receives.order_unit_amount';
        selectSql += ',products.product_check_class';
        selectSql += ',orders_receives.product_manual_input_class';
        selectSql += ',orders_receives.inventory_control_class';
        selectSql += ',orders_receives.place_1';
        selectSql += ',orders_receives.place_2';
        selectSql += ',orders_receives.place_3';
        selectSql += ',orders_receives.place_4';
        selectSql += ',orders_receives.case_conversion_class';
        selectSql += ',orders_receives.set_class AS set_flg';
        selectSql += ',orders_receives.remaining_quantity';
        selectSql += ',orders_receives.reserve_quantity_incomplete';
        selectSql += ',orders_receives.reserve_quantity';
        selectSql += ',orders_receives.stock_scheduled_reserve_receive';
        if (i == 0) {
          selectSql += ',SUM(IfNull(ships.shipping_quantity,0)) AS shipping_quantity';
          selectSql += ',orders_receives.shipping_quantity AS shipping_quantity_receives';
        } else {
          selectSql += ',orders_receives.shipping_quantity';
          selectSql += ',orders_receives.shipping_quantity AS shipping_quantity_receives';
        }
        selectSql += ',orders_receives.order_id';
        selectSql += ',orders_receives.order_row';
        selectSql += ',orders_receives.order_product_id';
        selectSql += ',orders_receives.is_deleted';
        selectSql += ',products_orders.client_id AS supplier_id';
        selectSql += ',suppliers.client_name_kanji AS supplier_name_kanji';
        selectSql += ',orders_receives.change_order_client_id';
        selectSql += ',change_order_client.client_name_kanji AS change_order_client_name_kanji';
        selectSql += ',IfNull(orders.client_id,0) AS order_supplier_id';
        selectSql += ',order_suppliers.client_name_kanji AS order_supplier_name_kanji';
        selectSql += ',IfNull(orders.order_quantity,0) AS order_quantity';
        selectSql += ',orders.stock_scheduled';
        selectSql += ',CASE WHEN orders.stock_scheduled_confirm_class = 1 THEN \'*\' ELSE \'\' END AS stock_scheduled_confirm_class';
        selectSql += ',orders.stock_scheduled_staff_id';
        selectSql += ',order_staffs.staff_name_kanji AS stock_scheduled_staff_name';
        selectSql += ',CASE WHEN orders.order_issue_class > 0 THEN 1 ELSE 0 END AS order_issue_class';
        if (i == 0) {
          selectSql += ',CASE WHEN stocks.order_id IS NULL THEN 0 ELSE 1 END AS stocks_class';
          selectSql += ',CASE WHEN ships.order_receive_id IS NULL THEN 0 ELSE 1 END AS ships_class';
          selectSql += ',CASE WHEN MAX(ships.sales_issue_class) > 0 THEN 1 ELSE 0 END AS sales_issue_class';
        } else {
          selectSql += ',orders_receives.is_bill_return_print AS stocks_class';
          selectSql += ',orders_receives.is_bill_return_print AS ships_class';
          selectSql += ',orders_receives.is_bill_return_print AS sales_issue_class';
        }
        selectSql += ',orders_receives.input_date';
        selectSql += ',orders_receives.input_staff_id';
        selectSql += ',orders_receives.group_register_class';
        selectSql += ',products_orders.minimum_quantity';
        selectSql += ',products.product_tax_rate_class_sales';
        /* FROM句 */
        selectSql += ' FROM ';
        selectSql += 't_orders_receives AS orders_receives ';
        selectSql += 'INNER JOIN m_clients AS clients USING(client_class,client_id)';
        selectSql += 'LEFT JOIN m_staffs AS order_receive_staffs ';
        selectSql += 'ON orders_receives.order_receive_staff_id = order_receive_staffs.staff_id ';
        selectSql += 'LEFT JOIN m_staffs AS staffs ';
        selectSql += 'ON orders_receives.staff_id = staffs.staff_id ';
        selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
        selectSql += 'ON clients_sites.client_id = orders_receives.client_id ';
        selectSql += 'AND clients_sites.site_id = orders_receives.site_id ';
        selectSql += 'INNER JOIN m_products AS products ';
        selectSql += 'ON orders_receives.product_id = products.product_id ';
        selectSql += 'LEFT JOIN m_products_orders AS products_orders ';
        selectSql += 'ON orders_receives.product_id = products_orders.product_id ';
        selectSql += 'AND orders_receives.office_id = products_orders.office_id ';
        selectSql += 'LEFT JOIN m_clients AS suppliers ';
        selectSql += 'ON suppliers.client_class = 2 ';
        selectSql += 'AND products_orders.client_id = suppliers.client_id ';
        selectSql += 'LEFT JOIN m_clients AS change_order_client ';
        selectSql += 'ON change_order_client.client_class = 2 ';
        selectSql += 'AND orders_receives.change_order_client_id = change_order_client.client_id ';
        selectSql += 'LEFT JOIN t_orders AS orders ';
        selectSql += 'ON orders_receives.order_id = orders.order_id ';
        selectSql += 'AND orders_receives.order_row = orders.order_row ';
        selectSql += 'LEFT JOIN m_clients AS order_suppliers ';
        selectSql += 'ON orders.client_class = order_suppliers.client_class ';
        selectSql += 'AND orders.client_id = order_suppliers.client_id ';
        selectSql += 'LEFT JOIN m_staffs AS order_staffs ';
        selectSql += 'ON orders.stock_scheduled_staff_id = order_staffs.staff_id ';
        if (i == 0) {
          selectSql += 'LEFT JOIN t_ships AS ships ';
          selectSql += 'ON orders_receives.order_receive_id = ships.order_receive_id ';
          selectSql += 'AND orders_receives.order_receive_row = ships.order_receive_row ';
          selectSql += 'LEFT JOIN (SELECT order_id,order_row FROM t_stocks GROUP BY order_id,order_row) AS stocks ';
          selectSql += 'ON orders_receives.order_id = stocks.order_id ';
          selectSql += 'AND orders_receives.order_row = stocks.order_row ';
        }
        /* WHERE句 */
        selectSql += ' WHERE ';
        // 引数の受注番号で絞り込む
        selectSql += ' orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
        if (i == 0) {
          // 配送種別（直送は除外）
          selectSql += 'AND orders_receives.shipping_type_class <> \'' + Const.ShippingTypeClass.direct + '\' ';
          /* GROUP BY句 */
          selectSql += 'GROUP BY orders_receives.order_receive_id,orders_receives.order_receive_row ';
        } else {
          // 配送種別（直送）
          selectSql += 'AND orders_receives.shipping_type_class = \'' + Const.ShippingTypeClass.direct + '\' ';
          /* GROUP BY句 */
          selectSql += 'GROUP BY orders_receives.order_receive_id,orders_receives.order_receive_row ';
        }
      }
      /* ORDER BY句 */
      selectSql += 'ORDER BY order_receive_id,order_receive_row ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        //console.time('time3');
        // 営業所プルダウン
        for (let i = 0; i < this.salesOffice.length; i++) {
          if (result[0].office_id == this.salesOffice[i].id) {
            this.selectSalesOffice = result[0].office_id;
            this.selectSalesOfficeText = this.salesOffice[i].text;
          }
        }
        // 入力日
        this.inputDate = result[0].input_date;
        // 入力担当者コード
        this.inputStaffId = result[0].input_staff_id;
        // 受注日付
        this.dateOrderReceiveDate = result[0].order_receive_date;
        this.dateOrderReceiveDateInit = result[0].order_receive_date;
        // 受注処理担当者
        this.orderReceiveStaff.id = result[0].order_receive_staff_id;
        this.orderReceiveStaff.text = result[0].order_receive_staff_id + '：' + result[0].order_receive_staff_name_kanji;
        // 配送種別
        this.selectDelivery = result[0].shipping_type_class;
        // 配送プルダウン
        for (let i = 0; i < Const.DeliveryList.length; i++) {
          if (this.selectDelivery == Const.ShippingTypeClass.direct) {
            // 配送種別が直送以外の場合（直送の選択肢は除外）
            if (Const.DeliveryList[i].id == Const.ShippingTypeClass.direct) {
              this.deliveryList.push(Const.DeliveryList[i]);
            }
          } else {
            // 配送種別が直送の場合（直送以外の選択肢は除外）
            if (Const.DeliveryList[i].id != Const.ShippingTypeClass.direct) {
              this.deliveryList.push(Const.DeliveryList[i]);
            }
          }
        }
        this.fields = this.getFields();
        // 伝種
        this.selectBillClass = result[0].order_receive_bill_class;
        this.selectBillClassText = getListValue(Const.OrderReceiveBillClassList, result[0].order_receive_bill_class, 'id', 'text');
        // 単価登録区分ラジオボタン
        this.checkUnitPriceRegisterClass = result[0].unit_price_register_class;
        // 単価適用日
        if(this.dateUnitPriceEffectiveDate == null) {
          this.dateUnitPriceEffectiveDate = '';
        } else {
          this.dateUnitPriceEffectiveDate = result[0].unit_price_effective_date;
        }
        // 単価備考
        this.textUnitPriceNote = result[0].unit_price_note;
        // 登録区分
        this.checkRegisterClass = result[0].group_register_class;
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        this.setClosingYmd(result[0].closing_date);
        this.suppliers.address1 = result[0].address_1;
        this.suppliers.address2 = result[0].address_2;
        this.suppliers.department1 = result[0].department_1;
        this.suppliers.department2 = result[0].department_2;
        this.suppliers.tel = result[0].phone_number;
        this.suppliers.personInChargeId = result[0].staff_id;
        this.suppliers.personInCharge = result[0].staff_id + '：' + result[0].staff_name_kanji;
        this.suppliers.zipCode = result[0].zip_code;
        this.suppliers.sundriesClass = result[0].sundries_class;
        this.suppliers.firstDigit = result[0].client_id_first_digit;
        this.suppliers.taxClass = result[0].sales_tax_class;
        this.suppliers.taxCalcClass = result[0].tax_calculation_class;
        this.suppliers.taxFracClass = result[0].tax_fractionation_class;
        this.suppliers.check = result[0].check_contents;
        // 現場系
        this.onSite.deliveryCode = result[0].shipping_code;
        this.onSite.code = result[0].site_id;
        this.onSite.name = result[0].client_site_name_kanji;
        this.onSite.zipCode = result[0].client_site_zip_code;
        this.onSite.address1 = result[0].client_site_address_1;
        this.onSite.address2 = result[0].client_site_address_2;
        this.onSite.deliveryPlace = result[0].delivery_location;
        this.onSite.tel = result[0].client_site_phone_number;
        this.onSite.clientPrintClass = Number(result[0].order_print_class);
        this.onSite.clientPrintName = result[0].order_print_client_name;
        this.onSite.clientPrintNameText = this.onSite.clientPrintClass == 0 ? '' : this.onSite.clientPrintName;
        // DBからまとめて取得
        let clientNote = null;
        let siteNote = null;
        let siteResultData = null;
        // 配送コード取得用
        let where_clause = 'AND client_id = ' + this.suppliers.code + ' ';
        where_clause += 'AND site_id = ' + this.onSite.code + ' ';

        [clientNote, siteNote, siteResultData] = await Promise.all([
          getOrderReceiveClientNote(this.suppliers.code),
          getOrderReceiveSiteNote(this.suppliers.code, this.onSite.code),
          selectOneTable('m_clients_sites', where_clause),
        ]);

        if (getNullStr(clientNote) != '' ) {
          this.isClientNoteInput = true;
        } else {
          this.isClientNoteInput = false;
        }
        if (getNullStr(siteNote) != '' ) {
          this.isSiteNoteInput = true;
        } else {
          this.isSiteNoteInput = false;
        }
        if (siteResultData != null && siteResultData.length > 0) {
          this.onSite.siteDeliveryCode = siteResultData[0].shipping_code;
        } else {
          this.onSite.siteDeliveryCode = '';
        }

        // 納品指定日
        this.orderDate = result[0].delivery_design_date==null ? '' : result[0].delivery_design_date;
        // 納品指定区分
        this.selectDeliveryDesignation = result[0].delivery_design_class;
        // 納品メモ
        this.deliveryNote = result[0].delivery_design;
        //console.timeEnd('time3');
        //console.time('time4');
        // 全件参照して製品一覧を作成
        for (let i = 0; i < result.length; i++) {
          if (i == this.productList.length) {
            this.addTableRow();
          }
          this.productList[i].ProductCode = result[i].product_id;
          this.productList[i].ProductClass = result[i].product_class_id;
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i]._cellVariants = { ProductName: getListValue(Const.ProductCheckClass, result[i].product_check_class, 'value', 'color') };
          this.productList[i].ProductTaxRateClass = result[i].product_tax_rate_class_sales;
          this.productList[i].ServiceClass = getNullStr(result[i].service_class);
          this.productList[i].Quantity = result[i].order_receive_quantity;
          this.productList[i].Unit = result[i].unit;
          this.productList[i].SalesUnitPrice = result[i].sales_unit_price;
          this.productList[i].SellingPrice = result[i].order_receive_unit_amount;
          this.productList[i].ClientAmountClass = result[i].client_amount_class;
          this.productList[i].PurchasePrime = result[i].order_unit_amount;
          this.productList[i].SundriesClass = result[i].product_manual_input_class;
          this.productList[i].InventoryClass = result[i].inventory_control_class;
          this.productList[i].Place1 = result[i].place_1;
          this.productList[i].Place2 = result[i].place_2;
          this.productList[i].Place3 = result[i].place_3;
          this.productList[i].Place4 = result[i].place_4;
          this.productList[i].CaseConversion = result[i].case_conversion_class;
          this.productList[i].SetFlg = result[i].set_flg;
          this.productList[i].RemainingQuantity = result[i].remaining_quantity;
          if (this.selectDelivery == Const.ShippingTypeClass.direct || this.selectBillClass == Const.OrderReceiveBillClass.special) {
            // 配送種別区分「4：直送」、または、伝種「15：特別受注」の場合
            // 入荷予定引当数受注を引当数として見る
            this.productList[i].ReserveQuantity = result[i].stock_scheduled_reserve_receive - result[i].shipping_quantity_receives;
          } else {
            // 上記以外の場合
            // 引当済数を引当数として見る
            this.productList[i].ReserveQuantity = result[i].reserve_quantity;
          }
          this.productList[i].ShippingQuantity = result[i].shipping_quantity;
          if (result[i].order_supplier_id != 0) {
            this.productList[i].SupplierCode = result[i].order_supplier_id;
            this.productList[i].SupplierName = result[i].order_supplier_name_kanji;
            this.productList[i].SupplierCodeRight = result[i].supplier_id;
          } else if (result[i].change_order_client_id != null) {
            this.productList[i].SupplierCode = result[i].change_order_client_id;
            this.productList[i].SupplierName = result[i].change_order_client_name_kanji;
            this.productList[i].SupplierCodeRight = result[i].supplier_id;
          } else if (result[i].supplier_id != null) {
            this.productList[i].SupplierCode = result[i].supplier_id;
            this.productList[i].SupplierName = result[i].supplier_name_kanji;
            this.productList[i].SupplierCodeRight = result[i].supplier_id;
          } else {
            this.productList[i].SupplierCode = null;
            this.productList[i].SupplierName = '製品発注マスタ未登録';
            this.productList[i].SupplierCodeRight = null;
          }
          this.productList[i].OrderQuantity = result[i].order_quantity;
          let productStockScheduled = result[i].stock_scheduled == null ? '0000/00/00' : formatDate(result[i].stock_scheduled);
          if (result[i].stock_scheduled_confirm_class != '') {
            productStockScheduled += ' ' + result[i].stock_scheduled_confirm_class;
          }
          this.productList[i].StockScheduled = productStockScheduled;
          let productStockScheduledStaff = '';
          if (result[i].stock_scheduled_staff_id != null && result[i].stock_scheduled_staff_id != '') {
            productStockScheduledStaff = result[i].stock_scheduled_staff_id;
            if (result[i].stock_scheduled_staff_name != null && result[i].stock_scheduled_staff_name != '') {
              productStockScheduledStaff += ' ' + result[i].stock_scheduled_staff_name;
            }
          }
          this.productList[i].StockScheduledStaff = productStockScheduledStaff;
          this.productList[i].IsDeleted = result[i].is_deleted;
          if (this.productList[i].IsDeleted == 0) {
            this.statusFlg.allDeletedFlg = false;
          }
          this.productList[i].OrderId = result[i].order_id;
          this.productList[i].OrderRow = result[i].order_row;
          this.productList[i].OrderJokyo = this.getOrderJokyo(this.selectDelivery, this.selectBillClass, this.productList[i].InventoryClass, result[i].order_issue_class, result[i].stocks_class);
          this.productList[i].OrderJokyoText = getListValue(Const.ReceivedOrderOrderJokyoList, this.productList[i].OrderJokyo);
          this.productList[i].ShipsClass = result[i].ships_class;
          this.productList[i].ShipsClassText = getListValue(Const.ShipsClassList, result[i].ships_class);
          if (result[i].sales_issue_class == Const.SalesIssueClass.sumi) {
            if ((result[i].order_receive_quantity >= 0 &&
              result[i].order_receive_quantity > Number(result[i].shipping_quantity_receives)) ||
              (result[i].order_receive_quantity < 0 &&
              result[i].order_receive_quantity < Number(result[i].shipping_quantity_receives))) {
              // 伝票発行済み、且つ、伝票発行数が受注数に達していない場合（一部済）
              this.productList[i].SalesIssueClass = Const.SalesIssueClass.middle;
              this.productList[i].SalesIssueClassText = getListValue(Const.SalesIssueClassList, Const.SalesIssueClass.middle);              
            } else {
              // 伝票発行済み、且つ、伝票発行数が受注数に達している場合（済）
              this.productList[i].SalesIssueClass = result[i].sales_issue_class;
              this.productList[i].SalesIssueClassText = getListValue(Const.SalesIssueClassList, result[i].sales_issue_class);
            }
          } else {
            // 伝票発行「未」
            this.productList[i].SalesIssueClass = result[i].sales_issue_class;
            this.productList[i].SalesIssueClassText = getListValue(Const.SalesIssueClassList, result[i].sales_issue_class);
          }
          this.productList[i].MinimumQuantity = result[i].minimum_quantity;
          this.productList[i].TotalPurchasePrime = this.productList[i].Quantity * this.productList[i].PurchasePrime;
          this.productList[i].Money = this.productList[i].Quantity * this.productList[i].SellingPrice;

          // 出荷のある製品の有無
          if (result[i].ships_class == Const.ShipsClass.sumi) {
            this.statusFlg.shukkaFlg = true;
            if (this.selectDelivery == Const.ShippingTypeClass.direct) {
              this.statusFlg.directShukkaFlg = true;
            }
          }
          // 表示時の情報を裏製品情報でも保持
          this.addProductListHidden(
            this.productList[i].ProductCode,
            this.productList[i].InventoryClass,
            this.productList[i].Quantity,
            result[i].reserve_quantity_incomplete,
            this.productList[i].ReserveQuantity,
            this.productList[i].ShippingQuantity
          );
          // ケース換算製品の設定
          if (this.productList[i].CaseConversion == Const.CaseConversionClassDef.conversion &&
          this.productList[i].SetFlg == Const.SetClass.noSet) {
            // ケース換算区分「0:する」、且つ、セット品区分「0:セット品でない」
            // 指定されたバラ製品コードを保持する製品単位変換マスタを確認
            let where_clause = 'AND loose_product_id = ' + result[i].product_id + ' ';
            //console.log(where_clause);
            let conversionsResultData = await selectOneTable('m_products_units_conversions', where_clause);
            //console.log(conversionsResultData);
            this.productList[i].SetList.push({productId: conversionsResultData[0].case_product_id });
          } else if (this.productList[i].CaseConversion == Const.CaseConversionClassDef.conversion &&
          this.productList[i].SetFlg == Const.SetClass.set) {
            // ケース換算区分「0:する」、且つ、セット品区分「1:セット品」
            let selectSql = '';
            selectSql = this.makeSelectSqlSet((i + 1).toString());
            //console.log(selectSql);
            //console.log('条件取得');
            let setResult = await executeSelectSql(selectSql);
            //console.log(setResult);
            if (setResult != null) {
              let setOrderJokyo = 0;
              let setOrderJokyoMax = 0;
              for (let j = 0; j < setResult.length; j++) {
                // 直送、または、特別受注の場合のみ
                if (this.selectDelivery == Const.ShippingTypeClass.direct ||
                this.selectBillClass == Const.OrderReceiveBillClass.special) {
                  setOrderJokyo = this.getOrderJokyo(this.selectDelivery, this.selectBillClass, setResult[j].inventory_control_class, setResult[j].order_issue_class, setResult[j].stocks_class);
                  if (setOrderJokyo > setOrderJokyoMax) {
                    setOrderJokyoMax = setOrderJokyo;
                  }
                }
                this.productList[i].SetList.push({productId: setResult[j].product_id });
              }
              if (setOrderJokyoMax > this.productList[i].OrderJokyo) {
                this.productList[i].OrderJokyo = setOrderJokyoMax;
                this.productList[i].OrderJokyoText = getListValue(Const.ReceivedOrderOrderJokyoList, this.productList[i].OrderJokyo);
              }
            } else {
              this.alertWarning.push(DISP_MESSAGES.WARNING['2024'].replace('%arg1%',(i+1).toString() + '行目'));
              this.statusFlg.editFlg = false;
            }
          }
          if (this.productList[i].OrderJokyo >= Const.ReceivedOrderOrderJokyo.orderZumi) {
            this.statusFlg.orderFlg = true;
          }
          // 粗利額を確認して、警告の場合、背景色変更
          this.checkGrossProfit(this.productList[i], i);
        }
        this.calcTotal();
        //console.timeEnd('time4');
        if (this.statusFlg.orderFlg==false) {
          // 最終行を空行とする
          this.addTableRow();
        }
        // 画面起動時の取引先製品情報設定
        this.setOpenClientProductData(result);
      }
    },
    /* 検索SQL作成（セット品） */
    makeSelectSqlSet: function(orderReceiveRow) {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_received_set.product_id';
      selectSql += ',orders_received_set.inventory_control_class';
      selectSql += ',CASE WHEN orders.order_issue_class > 0 THEN 1 ELSE 0 END AS order_issue_class';
      selectSql += ',CASE WHEN stocks.order_id IS NULL THEN 0 ELSE 1 END AS stocks_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_received_set AS orders_received_set ';
      selectSql += 'LEFT JOIN t_orders AS orders ';
      selectSql += 'ON orders_received_set.order_id = orders.order_id ';
      selectSql += 'AND orders_received_set.order_row = orders.order_row ';
      selectSql += 'LEFT JOIN t_stocks AS stocks ';
      selectSql += 'ON orders_received_set.order_id = stocks.order_id ';
      selectSql += 'AND orders_received_set.order_row = stocks.order_row ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' orders_received_set.order_receive_id = ' + this.receivedOrderId + ' ';
      selectSql += 'AND orders_received_set.order_receive_row = ' + orderReceiveRow + ' ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY orders_received_set.order_receive_row_branch ';

      return selectSql;
    },
    // 画面起動時の取引先製品情報設定
    setOpenClientProductData(result) {
      this.openClientProductData.clientId = result[0].client_id;
      this.openClientProductData.unitPriceRegisterClass = result[0].unit_price_register_class;
      this.openClientProductData.registerClass = result[0].group_register_class;
      this.openClientProductData.unitPriceEffectiveDate = result[0].unit_price_effective_date;
      this.openClientProductData.productIdList = [];
      for (let i = 0; i < result.length; i++) {
        let productIndex = this.openClientProductData.productIdList.findIndex(el => el == result[i].product_id);
        if (productIndex == -1) {
          this.openClientProductData.productIdList.push(result[i].product_id);
        }
      }
    },
    /* 保存ボタンの押下 */
    async saveData() {
      const functionName = 'saveData';
      try {
        // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
        const observer = this.$refs.observer;
        const success = await observer.validate();

        // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({block: 'center', inline: 'nearest'});
        } else {
          // 納品指定日が休日でないか確認
          if (await isHoliday(this.orderDate) == false) {
            // 配送コードがルートマスタに登録されているか確認（配送が「2:宅配」「4:直送」の場合を除く）
            if (this.selectDelivery == Const.ShippingTypeClass.delivery ||
            this.selectDelivery == Const.ShippingTypeClass.direct ||
            await checkMasterRoutes(this.onSite.deliveryCode) == true) {
              // 全製品が削除されている場合は登録不可
              if (this.checkAllDelete() == true) {
                // 通常配送時の配送コードチェック
                if (this.checkNormalDeliveryRoutes() == true) {
                  await this.saveConfirm();
                } else {
                  this.alertWarning.push(DISP_MESSAGES.WARNING['2083']);
                }
              } else {
                this.alertWarning.push(DISP_MESSAGES.WARNING['2047']);
              }
            } else {
              this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','配送コード'));
            }
          } else {
            this.alertWarning.push(DISP_MESSAGES.WARNING['2043'].replace('%arg1%','納品指定日'));
          }
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 排他制御チェック
      if (await isOtherSessionUpdated(this.listUpdatedSelect) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2027']);
        return;
      }
      // 保存処理
      //console.log('保存処理開始');
      // 受注データの登録(SQLの作成)
      if (await this.execSave() == true) {
        let reserveErrFlg = 0;
        let errCsvProductId = '';
        if (!(this.selectDelivery == Const.ShippingTypeClass.direct ||
        this.selectBillClass == Const.OrderReceiveBillClass.return ||
        this.selectBillClass == Const.OrderReceiveBillClass.special)) {
          // 配送区分「4:直送」、伝種「12:返品」「15:特別受注」のいずれでもない場合
          // ケース換算製品追加
          let addStockProductIdList = [];
          for (let i = 0; i < this.productList.length; i++) {
            if (this.productList[i].InventoryClass == Const.InventoryControlClassDef.inventory) {
              addStockProductIdList.push(this.productList[i].ProductCode);
            }
          }
          let csvStockProductIdList = await modStockProductIdListCase(addStockProductIdList);
          if (csvStockProductIdList.length > 0) {
            errCsvProductId = await reserveStockProductIdList(this.selectSalesOffice, csvStockProductIdList, this.controlMasterData.processMonthYear, this.loginId, MODULE_NAME, this.receivedOrderId);
            if (errCsvProductId != '') {
              reserveErrFlg = 1;
            }
          }
        }
        // 受注入力照会画面へ遷移
        this.$router.push({ name: 'RECEIVED-ORDER-INPUT-INQUIRY', query: { receivedOrderId: this.receivedOrderId, parentKbn: 1, reserveErrFlg: reserveErrFlg, errCsvProductId: errCsvProductId } });
      }
      //console.log('保存処理終了');
    },
    /* 保存実行 */
    async execSave() {
      const functionName = 'execSave';
      let retResult = false;
      this.transactSqlList = [];
      try {
        // 在庫マスタロック
        this.lockStocks();
        // 受注データロック
        this.lockOrdersReceives();
        // 受注セット品データロック
        this.lockOrdersReceivedSet();
        // 直送、または、特別受注の場合のみ
        if (this.selectDelivery == Const.ShippingTypeClass.direct ||
        this.selectBillClass == Const.OrderReceiveBillClass.special) {
          // 発注データロック
          this.lockOrders();
        }
        // 受注データの更新
        await this.updateOrdersReceives();
        // 受注セット品データの更新
        this.updateOrdersReservedSet();
        // 受注データの登録
        await this.insertOrdersReceives();
        // 受注セット品データの登録
        await this.insertOrderReceivedSet();
        // 直送、または、特別受注の場合のみ
        if (this.selectDelivery == Const.ShippingTypeClass.direct ||
        this.selectBillClass == Const.OrderReceiveBillClass.special) {
          // 発注データの更新
          this.updateOrders();
          // 発注データの登録
          await this.insertOrder();
          // 発注番号等を受注データに反映
          await this.updateOrderReceivesSetOrderId();
          // 発注番号等を受注セット品データに反映
          await this.updateOrderReceivedSetSetOrderId();
          // 換算入数を受注データに反映
          await this.updateOrderReceivesSetConvertedQuantity();
        }
        // 出荷確定済みの場合のみ
        if (this.selectDelivery != Const.ShippingTypeClass.direct &&
        this.statusFlg.shukkaFlg == true) {
          await this.updateShips();
        }
        // 在庫マスタの更新
        this.updateStocksMaster();
        // 単価登録
        await this.insertUnitPrice();

        //console.log(this.transactSqlList);

        // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
        try {
          const msg = await isSystemEditable();
          if (msg !== null) {
            this.alertDanger.push(msg);
            return false;
          }
        } catch (error) {
          await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
          return false;
        }
        // 月次更新後チェック
        if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
          return false;
        }
        // 作成した登録用SQLを全実行
        await executeSqlList(this.transactSqlList);
        retResult = true;
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        console.log(error);
        return false;
      }
      return retResult;
    },
    /* 在庫マスタロックSQL作成 */
    lockStocks: function() {
      //console.log('在庫マスタロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' stocks.month_year';
      selectSql += ',stocks.office_id';
      selectSql += ',stocks.product_id';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_stocks AS stocks ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      selectSql += 'AND stocks.office_id = ' + this.selectSalesOffice + ' ';
      let csvProductId = '';
      for (let i = 0; i < this.productList.length; i++) {
        if (csvProductId != '') {
          csvProductId += ',';
        }
        csvProductId += this.productList[i].ProductCode;
        for (let j = 0; j < this.productList[i].SetList.length; j++) {
          csvProductId += ',' + this.productList[i].SetList[j].productId;
        }
      }
      selectSql += 'AND stocks.product_id IN (' + csvProductId + ') ';

      //console.log(selectSql)
      this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
    },
    /* 受注データロックSQL作成 */
    lockOrdersReceives: function() {
      //console.log('受注データロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.order_receive_id';
      selectSql += ',orders_receives.order_receive_row';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      //console.log(selectSql)
      this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
    },
    /* 受注セット品データロックSQL作成 */
    lockOrdersReceivedSet: function() {
      //console.log('受注セット品データロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_received_set.order_receive_id';
      selectSql += ',orders_received_set.order_receive_row';
      selectSql += ',orders_received_set.order_receive_row_branch';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_received_set AS orders_received_set ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'orders_received_set.order_receive_id = ' + this.receivedOrderId + ' ';
      //console.log(selectSql)
      this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
    },
    /* 発注データロックSQL作成 */
    lockOrders: function() {
      //console.log('発注データロックSQL作成');
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders.order_id';
      selectSql += ',orders.order_row';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'orders.order_receive_id = ' + this.receivedOrderId + ' ';
      //console.log(selectSql)
      this.transactSqlList.push({sql: selectSql, forUpdateFlg: 1});
    },
    /* 受注データ更新SQL作成 */
    async updateOrdersReceives() {
      //console.log('受注データ更新SQL作成');
      let colList = [];
      let strWork = '';
      // 受注日
      colList.push(CreateColRow('orders_receives.order_receive_date', this.dateOrderReceiveDate, 'DATE'));
      // 受注処理担当者コード	
      colList.push(CreateColRow('orders_receives.order_receive_staff_id', this.orderReceiveStaff.id, 'NUMBER'));
      // 配送種別区分
      colList.push(CreateColRow('orders_receives.shipping_type_class', this.selectDelivery, 'VARCHAR'));
      // 取引先区分
      colList.push(CreateColRow('orders_receives.client_class', this.suppliers.class, 'NUMBER'));
      // 取引先コード
      colList.push(CreateColRow('orders_receives.client_id', this.suppliers.code, 'NUMBER'));
      // 取引先名
      colList.push(CreateColRow('orders_receives.client_name', await escapeQuote(this.suppliers.name), 'VARCHAR'));
      // 取引先宛名印字区分
      colList.push(CreateColRow('orders_receives.client_print_class', this.onSite.clientPrintClass, 'NUMBER'));
      // 取引先印刷用宛名
      colList.push(CreateColRow('orders_receives.client_print_name', await escapeQuote(this.onSite.clientPrintName), 'VARCHAR'));
      // 取引先部署1
      colList.push(CreateColRow('orders_receives.department_1', await escapeQuote(this.suppliers.department1), 'VARCHAR'));
      // 取引先部署2
      colList.push(CreateColRow('orders_receives.department_2', await escapeQuote(this.suppliers.department2), 'VARCHAR'));
      // 取引先住所1
      colList.push(CreateColRow('orders_receives.address_1', await escapeQuote(this.suppliers.address1), 'VARCHAR'));
      // 取引先住所2
      colList.push(CreateColRow('orders_receives.address_2', await escapeQuote(this.suppliers.address2), 'VARCHAR'));
      // 取引先郵便番号
      colList.push(CreateColRow('orders_receives.zip_code', await escapeQuote(this.suppliers.zipCode), 'VARCHAR'));
      // 取引先TEL
      colList.push(CreateColRow('orders_receives.phone_number', await escapeQuote(this.suppliers.tel), 'VARCHAR'));
      // 取引先諸口区分
      colList.push(CreateColRow('orders_receives.sundries_class', this.suppliers.sundriesClass, 'NUMBER'));
      // 納品指定日
      colList.push(CreateColRow('orders_receives.delivery_design_date', this.orderDate, 'DATE'));
      // 納品指定区分
      colList.push(CreateColRow('orders_receives.delivery_design_class', this.selectDeliveryDesignation, 'VARCHAR'));
      // 納品指定
      colList.push(CreateColRow('orders_receives.delivery_design', await escapeQuote(this.deliveryNote), 'VARCHAR'));
      // 現場コード
      colList.push(CreateColRow('orders_receives.site_id', this.onSite.code, 'NUMBER'));
      // 配送コード
      colList.push(CreateColRow('orders_receives.shipping_code', this.onSite.deliveryCode, 'VARCHAR'));
      // 現場名称
      colList.push(CreateColRow('orders_receives.client_site_name', await escapeQuote(this.onSite.name), 'VARCHAR'));
      // 現場郵便番号
      colList.push(CreateColRow('orders_receives.client_site_zip_code', await escapeQuote(this.onSite.zipCode), 'VARCHAR'));
      // 現場住所1
      colList.push(CreateColRow('orders_receives.client_site_address_1', await escapeQuote(this.onSite.address1), 'VARCHAR'));
      // 現場住所2
      colList.push(CreateColRow('orders_receives.client_site_address_2', await escapeQuote(this.onSite.address2), 'VARCHAR'));
      // 納品場所
      colList.push(CreateColRow('orders_receives.delivery_location', await escapeQuote(this.onSite.deliveryPlace), 'VARCHAR'));
      // 現場TEL
      colList.push(CreateColRow('orders_receives.client_site_phone_number', await escapeQuote(this.onSite.tel), 'VARCHAR'));
      // 担当者コード
      colList.push(CreateColRow('orders_receives.staff_id', this.suppliers.personInChargeId, 'NUMBER'));
      // 取引先分類コード
      colList.push(CreateColRow('orders_receives.client_id_first_digit', this.suppliers.firstDigit, 'NUMBER'));
      // 製品関係の更新部分
      let strWorkField = 'FIELD(orders_receives.order_receive_row';
      let strWorkProductName = '';
      let strWorkServiceClass = '';
      let strWorkOrderReceiveQuantity = '';
      let strWorkUnit = '';
      let strWorkOrderReceiveUnitAmount = '';
      let strWorkClientAmountClass = '';
      let strWorkOrderUnitAmount = '';
      let strWorkMoney = '';
      let strWorkOrderAmount = '';
      let strWorkGrossProfit = '';
      let strWorkRemainingQuantity = '';
      let strWorkReserveQuantityIncomplete = '';
      let strWorkReserveQuantity = '';
      let strWorkStockScheduledReserve = '';
      let strWorkStockScheduledReserveReceive = '';
      let strWorkChangeOrderClientId = '';
      let strWorkIsDeleted = '';
      for(let i = 0; i < this.productListHidden.length; i++) {
        strWorkField += ',' + (i + 1).toString();
        // 製品名
        strWorkProductName += ',\'' + await escapeQuote(this.productList[i].ProductName) + '\'';
        // サービス区分
        strWorkServiceClass += ',\'' + this.productList[i].ServiceClass + '\'';
        // 受注数
        strWorkOrderReceiveQuantity += ',' + this.productList[i].Quantity;
        // 単位
        strWorkUnit += ',\'' + await escapeQuote(this.productList[i].Unit) + '\'';
        // 受注単価
        strWorkOrderReceiveUnitAmount += ',' + this.productList[i].SellingPrice;
        // 取引先製品単価区分
        if (this.productList[i].ShipsClass == Const.ShipsClass.sumi) {
          // 出荷確定入力済みの場合、取引先製品マスタへの登録を行わない
          strWork = 'orders_receives.client_amount_class';
        } else {
          strWork = '\'' + (this.checkUnitPriceRegisterClass == Const.UnitPriceRegisterClass.insertNo ? this.productList[i].ClientAmountClass : '*') + '\'';
        }
        strWorkClientAmountClass += ',' + strWork;
        // 発注単価
        strWorkOrderUnitAmount += ',' + this.productList[i].PurchasePrime;
        // 受注金額
        strWorkMoney += ',' + this.productList[i].Money;
        // 発注金額
        strWorkOrderAmount += ',' + this.productList[i].TotalPurchasePrime;
        // 粗利額
        strWorkGrossProfit += ',' + (this.productList[i].Money - this.productList[i].TotalPurchasePrime).toString();
        // 受注残数
        strWorkRemainingQuantity += ',orders_receives.remaining_quantity + ' + (this.productList[i].Quantity - this.productListHidden[i].Quantity).toString();
        // 削除済フラグ
        strWorkIsDeleted += ',' + this.productList[i].IsDeleted;
        // 変更発注先コード
        strWorkChangeOrderClientId += ',' + (this.productList[i].SupplierCode == this.productList[i].SupplierCodeRight ? 'NULL' : this.productList[i].SupplierCode);
        if (this.selectDelivery == Const.ShippingTypeClass.direct || this.selectBillClass == Const.OrderReceiveBillClass.special) {
          // 配送種別区分「4：直送」、または、伝種「15：特別受注」の場合
          // 未引当数
          strWorkReserveQuantityIncomplete += ',orders_receives.reserve_quantity_incomplete';
          // 引当済数
          strWorkReserveQuantity += ',orders_receives.reserve_quantity';
          // 入荷予定引当数
          strWorkStockScheduledReserve += ',orders_receives.stock_scheduled_reserve + ' + (this.productList[i].Quantity - this.productListHidden[i].Quantity).toString();
        } else if (this.selectBillClass == Const.OrderReceiveBillClass.return || this.productList[i].InventoryClass == Const.InventoryControlClassDef.noInventory) {
          // 伝種「12：返品」、または、在庫管理区分「1:しない」の場合
          // 未引当数
          strWorkReserveQuantityIncomplete += ',orders_receives.reserve_quantity_incomplete';
          // 引当済数
          strWorkReserveQuantity += ',orders_receives.reserve_quantity + ' + (this.productList[i].Quantity - this.productListHidden[i].Quantity).toString();
          // 入荷予定引当数
          strWorkStockScheduledReserve += ',orders_receives.stock_scheduled_reserve';
        } else {
          // 上記以外（基本的な在庫管理される受注）
          // 未引当数が0になるまでは未引当数から引く
          let addReserveQuantityIncomplete = 0;
          let addReserveQuantity = 0;
          if (this.productListHidden[i].ReserveQuantityIncomplete + (this.productList[i].Quantity - this.productListHidden[i].Quantity) >= 0) {
            addReserveQuantityIncomplete = (this.productList[i].Quantity - this.productListHidden[i].Quantity);
            addReserveQuantity = 0;
            this.productListHidden[i].SubReserveQuantity = 0;
          } else {
            addReserveQuantityIncomplete = -this.productListHidden[i].ReserveQuantityIncomplete;
            addReserveQuantity = this.productListHidden[i].ReserveQuantityIncomplete + (this.productList[i].Quantity - this.productListHidden[i].Quantity);
            this.productListHidden[i].SubReserveQuantity = -addReserveQuantity;
          }
          // 未引当数
          strWorkReserveQuantityIncomplete += ',orders_receives.reserve_quantity_incomplete + ' + addReserveQuantityIncomplete;
          // 引当済数
          strWorkReserveQuantity += ',orders_receives.reserve_quantity + ' + addReserveQuantity;
          // 入荷予定引当数
          strWorkStockScheduledReserve += ',orders_receives.stock_scheduled_reserve';
        }
        // 入荷予定引当数受注
        if (this.selectBillClass == Const.OrderReceiveBillClass.special &&
        this.productList[i].InventoryClass == Const.InventoryControlClassDef.noInventory) {
          // 伝種「15：特別受注」
          // 且つ、在庫管理区分「1:しない」の場合
          strWorkStockScheduledReserveReceive += ',orders_receives.stock_scheduled_reserve_receive + ' + (this.productList[i].Quantity - this.productListHidden[i].Quantity).toString();
        } else {
          strWorkStockScheduledReserveReceive += ',orders_receives.stock_scheduled_reserve_receive';
        }
      }
      strWorkField += ')';
      // 製品名
      colList.push(CreateColRow('orders_receives.product_name', 'ELT(' + strWorkField + strWorkProductName + ')', 'NUMBER'));
      // サービス区分
      colList.push(CreateColRow('orders_receives.service_class', 'ELT(' + strWorkField + strWorkServiceClass + ')', 'NUMBER'));
      // 受注数
      colList.push(CreateColRow('orders_receives.order_receive_quantity', 'ELT(' + strWorkField + strWorkOrderReceiveQuantity + ')', 'NUMBER'));
      // 単位
      colList.push(CreateColRow('orders_receives.unit', 'ELT(' + strWorkField + strWorkUnit + ')', 'NUMBER'));
      // 受注単価
      colList.push(CreateColRow('orders_receives.order_receive_unit_amount', 'ELT(' + strWorkField + strWorkOrderReceiveUnitAmount + ')', 'NUMBER'));
      // 取引先製品単価区分
      colList.push(CreateColRow('orders_receives.client_amount_class', 'ELT(' + strWorkField + strWorkClientAmountClass + ')', 'NUMBER'));
      // 発注単価
      colList.push(CreateColRow('orders_receives.order_unit_amount', 'ELT(' + strWorkField + strWorkOrderUnitAmount + ')', 'NUMBER'));
      // 受注金額
      colList.push(CreateColRow('orders_receives.order_receive_amount', 'ELT(' + strWorkField + strWorkMoney + ')', 'NUMBER'));
      // 発注金額
      colList.push(CreateColRow('orders_receives.order_amount', 'ELT(' + strWorkField + strWorkOrderAmount + ')', 'NUMBER'));
      // 粗利額
      colList.push(CreateColRow('orders_receives.gross_profit', 'ELT(' + strWorkField + strWorkGrossProfit + ')', 'NUMBER'));
      // 受注残数
      colList.push(CreateColRow('orders_receives.remaining_quantity', 'ELT(' + strWorkField + strWorkRemainingQuantity + ')', 'NUMBER'));
      // 未引当数
      colList.push(CreateColRow('orders_receives.reserve_quantity_incomplete', 'ELT(' + strWorkField + strWorkReserveQuantityIncomplete + ')', 'NUMBER'));
      // 引当済数
      colList.push(CreateColRow('orders_receives.reserve_quantity', 'ELT(' + strWorkField + strWorkReserveQuantity + ')', 'NUMBER'));
      // 入荷予定引当数
      colList.push(CreateColRow('orders_receives.stock_scheduled_reserve', 'ELT(' + strWorkField + strWorkStockScheduledReserve + ')', 'NUMBER'));
      // 入荷予定引当数受注
      colList.push(CreateColRow('orders_receives.stock_scheduled_reserve_receive', 'ELT(' + strWorkField + strWorkStockScheduledReserveReceive + ')', 'NUMBER'));
      // 変更発注先コード
      colList.push(CreateColRow('orders_receives.change_order_client_id', 'ELT(' + strWorkField + strWorkChangeOrderClientId + ')', 'NUMBER'));
      // 削除済フラグ
      colList.push(CreateColRow('orders_receives.is_deleted', 'ELT(' + strWorkField + strWorkIsDeleted + ')', 'NUMBER'));
      // 売上課税区分
      colList.push(CreateColRow('orders_receives.sales_tax_class', this.suppliers.taxClass, 'NUMBER'));
      // 税額計算区分
      colList.push(CreateColRow('orders_receives.tax_calculation_class', this.suppliers.taxCalcClass, 'NUMBER'));
      // 税額端数計算処理区分
      colList.push(CreateColRow('orders_receives.tax_fractionation_class', this.suppliers.taxFracClass, 'NUMBER'));
      // 単価登録区分
      colList.push(CreateColRow('orders_receives.unit_price_register_class', this.checkUnitPriceRegisterClass, 'NUMBER'));
      // 単価適用日
      colList.push(CreateColRow('orders_receives.unit_price_effective_date', this.dateUnitPriceEffectiveDate, 'DATE'));
      // 単価備考
      colList.push(CreateColRow('orders_receives.unit_price_note', await escapeQuote(this.textUnitPriceNote), 'VARCHAR'));
      if (this.checkUnitPriceRegisterClass != Const.UnitPriceRegisterClass.insertNo) {
        // 単価登録区分が「1:登録しない」以外の場合、画面の選択値を設定
        // グループ登録区分
        colList.push(CreateColRow('orders_receives.group_register_class', this.checkRegisterClass, 'NUMBER'));
      } else {
        // 単価登録区分が「1:登録しない」の場合、デフォルト値（「0：グループ」）
        // グループ登録区分
        colList.push(CreateColRow('orders_receives.group_register_class', Const.ClientsProductsMasterRegisterClass.group, 'NUMBER'));
      }
      // 更新日
      colList.push(CreateColRow('orders_receives.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders_receives.updated_user', this.loginId, 'VARCHAR'));
      let updateSql = CreateUpdateSql(colList, 't_orders_receives AS orders_receives');
      updateSql += ' WHERE ';
      updateSql += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 受注セット品データ更新SQL作成 */
    updateOrdersReservedSet: function() {
      //console.log('受注セット品データ更新SQL作成');
      let colList = [];
      // 製品関係の更新部分
      let strWorkField = 'FIELD(orders_received_set.order_receive_row';
      let strWorkServiceClass = '';
      let strWorkOrderReceiveQuantity = '';
      let strWorkRemainingQuantity = '';
      let strWorkReserveQuantityIncomplete = '';
      let strWorkReserveQuantity = '';
      let strWorkStockScheduledReserve = '';
      let updateFlg = false;
      for(let i = 0; i < this.productListHidden.length; i++) {
        // ケース換算区分が「0:する」、且つ、セット品の場合、登録
        if (this.productList[i].CaseConversion == Const.CaseConversionClassDef.conversion && this.productList[i].SetFlg == Const.SetClass.set) {
          strWorkField += ',' + (i + 1).toString();
          // サービス区分
          strWorkServiceClass += ',\'' + this.productList[i].ServiceClass + '\'';
          // 受注数
          strWorkOrderReceiveQuantity += ',orders_received_set.order_receive_quantity + productsCompositionsQuery.quantity * ' + (this.productList[i].Quantity - this.productListHidden[i].Quantity).toString();
          // 受注残数
          strWorkRemainingQuantity += ',orders_received_set.remaining_quantity + productsCompositionsQuery.quantity * ' + (this.productList[i].Quantity - this.productListHidden[i].Quantity).toString();
          if (this.selectDelivery == Const.ShippingTypeClass.direct || this.selectBillClass == Const.OrderReceiveBillClass.special) {
            // 配送種別区分「4：直送」、または、伝種「15：特別受注」の場合
            // 未引当数
            strWorkReserveQuantityIncomplete += ',orders_received_set.reserve_quantity_incomplete';
            // 引当済数
            strWorkReserveQuantity += ',orders_received_set.reserve_quantity';
            // 入荷予定引当数
            strWorkStockScheduledReserve += ',orders_received_set.stock_scheduled_reserve + productsCompositionsQuery.quantity * ' + (this.productList[i].Quantity - this.productListHidden[i].Quantity).toString();
          } else if (this.selectBillClass == Const.OrderReceiveBillClass.return || this.productList[i].InventoryClass == Const.InventoryControlClassDef.noInventory) {
            // 伝種「12：返品」、または、在庫管理区分「1:しない」の場合
            // 未引当数
            strWorkReserveQuantityIncomplete += ',orders_received_set.reserve_quantity_incomplete';
            // 引当済数
            strWorkReserveQuantity += ',orders_received_set.reserve_quantity + productsCompositionsQuery.quantity * ' + (this.productList[i].Quantity - this.productListHidden[i].Quantity).toString();
            // 入荷予定引当数
            strWorkStockScheduledReserve += ',orders_received_set.stock_scheduled_reserve';
          } else {
            // 上記以外（基本的な在庫管理される受注）
            // 未引当数
            strWorkReserveQuantityIncomplete += ',orders_received_set.reserve_quantity_incomplete + productsCompositionsQuery.quantity * ' + (this.productList[i].Quantity - this.productListHidden[i].Quantity + this.productListHidden[i].SubReserveQuantity).toString();
            // 引当済数
            strWorkReserveQuantity += ',orders_received_set.reserve_quantity - productsCompositionsQuery.quantity * ' + this.productListHidden[i].SubReserveQuantity;
            // 入荷予定引当数
            strWorkStockScheduledReserve += ',orders_received_set.stock_scheduled_reserve';
          }
          updateFlg = true;
        }
      }
      strWorkField += ')';
      // サービス区分
      colList.push(CreateColRow('orders_received_set.service_class', 'ELT(' + strWorkField + strWorkServiceClass + ')', 'NUMBER'));
      // 受注数
      colList.push(CreateColRow('orders_received_set.order_receive_quantity', 'ELT(' + strWorkField + strWorkOrderReceiveQuantity + ')', 'NUMBER'));
      // 受注残数
      colList.push(CreateColRow('orders_received_set.remaining_quantity', 'ELT(' + strWorkField + strWorkRemainingQuantity + ')', 'NUMBER'));
      // 未引当数
      colList.push(CreateColRow('orders_received_set.reserve_quantity_incomplete', 'ELT(' + strWorkField + strWorkReserveQuantityIncomplete + ')', 'NUMBER'));
      // 引当済数
      colList.push(CreateColRow('orders_received_set.reserve_quantity', 'ELT(' + strWorkField + strWorkReserveQuantity + ')', 'NUMBER'));
      // 入荷予定引当数
      colList.push(CreateColRow('orders_received_set.stock_scheduled_reserve', 'ELT(' + strWorkField + strWorkStockScheduledReserve + ')', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('orders_received_set.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders_received_set.updated_user', this.loginId, 'VARCHAR'));
      if (updateFlg == true) {
        // 更新用の副問い合わせテーブル作成
        let updateQuery = '(';
        updateQuery += 'SELECT';
        updateQuery += ' orders_receives.order_receive_id';
        updateQuery += ',orders_receives.order_receive_row';
        updateQuery += ',orders_received_set.order_receive_row_branch';
        updateQuery += ',products_compositions.quantity';
        updateQuery += ' FROM ';
        updateQuery += 't_orders_receives AS orders_receives ';
        updateQuery += 'INNER JOIN t_orders_received_set AS orders_received_set ';
        updateQuery += 'ON orders_receives.order_receive_id = orders_received_set.order_receive_id ';
        updateQuery += 'AND orders_receives.order_receive_row = orders_received_set.order_receive_row ';
        updateQuery += 'INNER JOIN m_products_compositions AS products_compositions ';
        updateQuery += 'ON orders_receives.product_id = products_compositions.product_id ';
        updateQuery += 'AND orders_received_set.product_id = products_compositions.component_product_id ';
        updateQuery += ' WHERE ';
        updateQuery += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
        updateQuery += ') AS productsCompositionsQuery';
        let updateSql = CreateUpdateSql(colList, 't_orders_received_set AS orders_received_set', updateQuery);
        updateSql += ' WHERE ';
        updateSql += 'orders_received_set.order_receive_id = ' + this.receivedOrderId + ' ';
        updateSql += 'AND orders_received_set.order_receive_id = productsCompositionsQuery.order_receive_id ';
        updateSql += 'AND orders_received_set.order_receive_row = productsCompositionsQuery.order_receive_row ';
        updateSql += 'AND orders_received_set.order_receive_row_branch = productsCompositionsQuery.order_receive_row_branch ';
        //console.log(updateSql);
        this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
      }
    },
    /* 受注データ登録SQL作成 */
    async insertOrdersReceives() {
      //console.log('受注データ登録SQL作成');
      // CRUD処理
      // 製品の分だけループして登録SQLを作成
      let bulkInsertSql = '';
      for (let i = this.productListHidden.length; i < this.productList.length; i++) {
        let colList = [];
        // 受注番号
        colList.push(CreateColRow('order_receive_id', this.receivedOrderId, 'NUMBER'));
        // 受注行番号
        colList.push(CreateColRow('order_receive_row', i + 1, 'NUMBER'));
        // 受注日
        colList.push(CreateColRow('order_receive_date', this.dateOrderReceiveDate, 'DATE'));
        // 受注処理担当者コード	
        colList.push(CreateColRow('order_receive_staff_id', this.orderReceiveStaff.id, 'NUMBER'));
        // 配送種別区分
        colList.push(CreateColRow('shipping_type_class', this.selectDelivery, 'VARCHAR'));
        // 営業所コード
        colList.push(CreateColRow('office_id', this.selectSalesOffice, 'NUMBER'));
        // 取引先区分
        colList.push(CreateColRow('client_class', this.suppliers.class, 'NUMBER'));
        // 取引先コード
        colList.push(CreateColRow('client_id', this.suppliers.code, 'NUMBER'));
        // 取引先名
        colList.push(CreateColRow('client_name', await escapeQuote(this.suppliers.name), 'VARCHAR'));
        // 取引先宛名印字区分
        colList.push(CreateColRow('client_print_class', this.onSite.clientPrintClass, 'NUMBER'));
        // 取引先印刷用宛名
        colList.push(CreateColRow('client_print_name', await escapeQuote(this.onSite.clientPrintName), 'VARCHAR'));
        // 取引先部署1
        colList.push(CreateColRow('department_1', await escapeQuote(this.suppliers.department1), 'VARCHAR'));
        // 取引先部署2
        colList.push(CreateColRow('department_2', await escapeQuote(this.suppliers.department2), 'VARCHAR'));
        // 取引先住所1
        colList.push(CreateColRow('address_1', await escapeQuote(this.suppliers.address1), 'VARCHAR'));
        // 取引先住所2
        colList.push(CreateColRow('address_2', await escapeQuote(this.suppliers.address2), 'VARCHAR'));
        // 取引先郵便番号
        colList.push(CreateColRow('zip_code', await escapeQuote(this.suppliers.zipCode), 'VARCHAR'));
        // 取引先TEL
        colList.push(CreateColRow('phone_number', await escapeQuote(this.suppliers.tel), 'VARCHAR'));
        // 取引先諸口区分
        colList.push(CreateColRow('sundries_class', this.suppliers.sundriesClass, 'NUMBER'));
        // 納品指定日
        colList.push(CreateColRow('delivery_design_date', this.orderDate, 'DATE'));
        // 納品指定区分
        colList.push(CreateColRow('delivery_design_class', await escapeQuote(this.selectDeliveryDesignation), 'VARCHAR'));
        // 納品指定
        colList.push(CreateColRow('delivery_design', await escapeQuote(this.deliveryNote), 'VARCHAR'));
        // 現場コード
        colList.push(CreateColRow('site_id', this.onSite.code, 'NUMBER'));
        // 配送コード
        colList.push(CreateColRow('shipping_code', await escapeQuote(this.onSite.deliveryCode), 'VARCHAR'));
        // 現場名称
        colList.push(CreateColRow('client_site_name', await escapeQuote(this.onSite.name), 'VARCHAR'));
        // 現場郵便番号
        colList.push(CreateColRow('client_site_zip_code', await escapeQuote(this.onSite.zipCode), 'VARCHAR'));
        // 現場住所1
        colList.push(CreateColRow('client_site_address_1', await escapeQuote(this.onSite.address1), 'VARCHAR'));
        // 現場住所2
        colList.push(CreateColRow('client_site_address_2', await escapeQuote(this.onSite.address2), 'VARCHAR'));
        // 納品場所
        colList.push(CreateColRow('delivery_location', await escapeQuote(this.onSite.deliveryPlace), 'VARCHAR'));
        // 現場TEL
        colList.push(CreateColRow('client_site_phone_number', await escapeQuote(this.onSite.tel), 'VARCHAR'));
        // 担当者コード
        colList.push(CreateColRow('staff_id', this.suppliers.personInChargeId, 'NUMBER'));
        // 取引先分類コード
        colList.push(CreateColRow('client_id_first_digit', this.suppliers.firstDigit, 'NUMBER'));
        // 製品コード
        colList.push(CreateColRow('product_id', this.productList[i].ProductCode, 'NUMBER'));
        // 製品名
        colList.push(CreateColRow('product_name', await escapeQuote(this.productList[i].ProductName), 'VARCHAR'));
        // 備考
        colList.push(CreateColRow('note', '','VARCHAR'));
        // サービス区分
        colList.push(CreateColRow('service_class', await escapeQuote(this.productList[i].ServiceClass), 'VARCHAR'));
        // 受注数
        colList.push(CreateColRow('order_receive_quantity', this.productList[i].Quantity, 'NUMBER'));
        // 単位
        colList.push(CreateColRow('unit', this.productList[i].Unit, 'VARCHAR'));
        // 受注単価
        colList.push(CreateColRow('order_receive_unit_amount', this.productList[i].SellingPrice, 'NUMBER'));
        // 発注単価
        colList.push(CreateColRow('order_unit_amount', this.productList[i].PurchasePrime, 'NUMBER'));
        // 受注金額
        colList.push(CreateColRow('order_receive_amount', this.productList[i].Money, 'NUMBER'));
        // 発注金額
        colList.push(CreateColRow('order_amount', this.productList[i].TotalPurchasePrime, 'NUMBER'));
        // 粗利額
        colList.push(CreateColRow('gross_profit', this.productList[i].Money - this.productList[i].TotalPurchasePrime, 'NUMBER'));
        // 消費税
        colList.push(CreateColRow('tax', 0, 'NUMBER'));
        // 製品分類コード
        colList.push(CreateColRow('product_class_id', this.productList[i].ProductClass, 'NUMBER'));
        // 製品手入力区分
        colList.push(CreateColRow('product_manual_input_class', this.productList[i].SundriesClass, 'NUMBER'));
        // 在庫管理区分
        colList.push(CreateColRow('inventory_control_class', this.productList[i].InventoryClass, 'NUMBER'));
        // 取引先製品単価区分
        colList.push(CreateColRow('client_amount_class', (this.checkUnitPriceRegisterClass == Const.UnitPriceRegisterClass.insertNo ? this.productList[i].ClientAmountClass : '*'), 'VARCHAR'));
        // ケース換算区分
        colList.push(CreateColRow('case_conversion_class', this.productList[i].CaseConversion, 'NUMBER'));
        // 受注残数
        colList.push(CreateColRow('remaining_quantity', this.productList[i].Quantity, 'NUMBER'));
        if (this.selectDelivery == Const.ShippingTypeClass.direct || this.selectBillClass == Const.OrderReceiveBillClass.special) {
          // 配送種別区分「4：直送」、または、伝種「15：特別受注」
          // 未引当数
          colList.push(CreateColRow('reserve_quantity_incomplete', 0, 'NUMBER'));
          // 引当済数
          colList.push(CreateColRow('reserve_quantity', 0, 'NUMBER'));
          // 入荷予定引当数
          colList.push(CreateColRow('stock_scheduled_reserve', this.productList[i].Quantity, 'NUMBER'));
        } else if (this.selectBillClass == Const.OrderReceiveBillClass.return || this.productList[i].InventoryClass == Const.InventoryControlClassDef.noInventory) {
          // 伝種「12：返品」、または、在庫管理区分「1:しない」の場合
          // 未引当数
          colList.push(CreateColRow('reserve_quantity_incomplete', 0, 'NUMBER'));
          // 引当済数
          colList.push(CreateColRow('reserve_quantity', this.productList[i].Quantity, 'NUMBER'));
          // 入荷予定引当数
          colList.push(CreateColRow('stock_scheduled_reserve', 0, 'NUMBER'));
        } else {
          // 上記以外（基本的な在庫管理される受注）
          // 未引当数
          colList.push(CreateColRow('reserve_quantity_incomplete', this.productList[i].Quantity, 'NUMBER'));
          // 引当済数
          colList.push(CreateColRow('reserve_quantity', 0, 'NUMBER'));
          // 入荷予定引当数
          colList.push(CreateColRow('stock_scheduled_reserve', 0, 'NUMBER'));
        }
        // 入荷予定引当数受注
        if (this.selectBillClass == Const.OrderReceiveBillClass.special &&
        this.productList[i].InventoryClass == Const.InventoryControlClassDef.noInventory) {
          // 伝種「15：特別受注」
          // 且つ、在庫管理区分「1:しない」の場合
          colList.push(CreateColRow('stock_scheduled_reserve_receive', this.productList[i].Quantity, 'NUMBER'));
        } else {
          colList.push(CreateColRow('stock_scheduled_reserve_receive', 0, 'NUMBER'));
        }
        // 出荷数
        colList.push(CreateColRow('shipping_quantity', 0, 'NUMBER'));
        // 売上課税区分
        colList.push(CreateColRow('sales_tax_class', this.suppliers.taxClass, 'NUMBER'));
        // 税額計算区分
        colList.push(CreateColRow('tax_calculation_class', this.suppliers.taxCalcClass, 'NUMBER'));
        // 税額端数計算処理区分
        colList.push(CreateColRow('tax_fractionation_class', this.suppliers.taxFracClass, 'NUMBER'));
        // 発注番号
        colList.push(CreateColRow('order_id', 0, 'NUMBER'));
        // 発注行番号
        colList.push(CreateColRow('order_row', 0, 'NUMBER'));
        // 発注製品コード
        colList.push(CreateColRow('order_product_id', 0, 'NUMBER'));
        // 変更発注先コード
        colList.push(CreateColRow('change_order_client_id', (this.productList[i].SupplierCode == this.productList[i].SupplierCodeRight ? 'NULL' : this.productList[i].SupplierCode), 'NUMBER'));
        // 換算入数
        colList.push(CreateColRow('converted_quantity', 0, 'NUMBER'));
        // 在庫置き場所1
        colList.push(CreateColRow('place_1', await escapeQuote(this.productList[i].Place1), 'VARCHAR'));
        // 在庫置き場所2
        colList.push(CreateColRow('place_2', await escapeQuote(this.productList[i].Place2), 'VARCHAR'));
        // 在庫置き場所3
        colList.push(CreateColRow('place_3', await escapeQuote(this.productList[i].Place3), 'VARCHAR'));
        // 在庫置き場所4
        colList.push(CreateColRow('place_4', await escapeQuote(this.productList[i].Place4), 'VARCHAR'));
        // セット品区分
        colList.push(CreateColRow('set_class', this.productList[i].SetFlg, 'NUMBER'));
        // 伝票種別
        colList.push(CreateColRow('order_receive_bill_class', this.selectBillClass, 'NUMBER'));
        // 返品伝票発行フラグ
        colList.push(CreateColRow('is_bill_return_print', 0, 'NUMBER'));
        // 返品在庫計上フラグ
        colList.push(CreateColRow('is_bill_return_count', 0, 'NUMBER'));
        // 単価登録区分
        colList.push(CreateColRow('unit_price_register_class', this.checkUnitPriceRegisterClass, 'NUMBER'));
        // 単価適用日
        colList.push(CreateColRow('unit_price_effective_date', this.dateUnitPriceEffectiveDate, 'DATE'));
        // 単価備考
        colList.push(CreateColRow('unit_price_note', await escapeQuote(this.textUnitPriceNote), 'VARCHAR'));
        // 削除済フラグ
        colList.push(CreateColRow('is_deleted', 0, 'NUMBER'));
        // 入力日
        colList.push(CreateColRow('input_date', this.inputDate, 'DATE'));
        // 入力担当者コード
        colList.push(CreateColRow('input_staff_id', this.inputStaffId, 'NUMBER'));
        if (this.checkUnitPriceRegisterClass != Const.UnitPriceRegisterClass.insertNo) {
          // 単価登録区分が「1:登録しない」以外の場合、画面の選択値を設定
          // グループ登録区分
          colList.push(CreateColRow('group_register_class', this.checkRegisterClass, 'NUMBER'));
        } else {
          // 単価登録区分が「1:登録しない」の場合、デフォルト値（「0：グループ」）
          // グループ登録区分
          colList.push(CreateColRow('group_register_class', Const.ClientsProductsMasterRegisterClass.group, 'NUMBER'));
        }
        // 作成ユーザー
        colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
        if (bulkInsertSql == '') {
          bulkInsertSql += 'INSERT INTO t_orders_receives (' + CreateInsertSql(colList, 'col', 't_orders_receives') + ') VALUES ';
        } else {
          bulkInsertSql += ',';
        }
        bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_orders_receives') + ')';
        if (bulkInsertSql.length >= Const.SqlMaxLength) {
          this.transactSqlList.push({sql: bulkInsertSql, forUpdateFlg: 0});
          bulkInsertSql = '';
        }
      }
      if (bulkInsertSql != '') {
        //console.log(bulkInsertSql);
        this.transactSqlList.push({sql: bulkInsertSql, forUpdateFlg: 0});
      }
    },
    /* 受注セット品データ登録処理 */
    async insertOrderReceivedSet() {
      //console.log('受注セット品データ登録SQL作成');
      // CRUD処理
      let bulkInsertSqlStart = '';
      let bulkInsertSqlSelect = '';
      for (let i = this.productListHidden.length; i < this.productList.length; i++) {
        // ケース換算区分が「0:する」、且つ、セット品の場合、登録
        if (this.productList[i].CaseConversion == Const.CaseConversionClassDef.conversion && this.productList[i].SetFlg == Const.SetClass.set) {
          let colList = [];
          // INSERT文の先頭部分、初回のみ作成
          if (bulkInsertSqlStart == '') {
            // 受注番号
            colList.push(CreateColRow('order_receive_id', null, 'NUMBER'));
            // 受注行番号
            colList.push(CreateColRow('order_receive_row', null, 'NUMBER'));
            // 受注行枝番
            colList.push(CreateColRow('order_receive_row_branch', null, 'NUMBER'));
            // 製品コード
            colList.push(CreateColRow('product_id', null, 'NUMBER'));
            // 製品名
            colList.push(CreateColRow('product_name', null, 'VARCHAR'));
            // 備考
            colList.push(CreateColRow('note', '','VARCHAR'));
            // サービス区分
            colList.push(CreateColRow('service_class', null, 'VARCHAR'));
            // 受注数
            colList.push(CreateColRow('order_receive_quantity', null, 'NUMBER'));
            // 単位
            colList.push(CreateColRow('unit', null, 'VARCHAR'));
            // 製品分類コード
            colList.push(CreateColRow('product_class_id', null, 'NUMBER'));
            // 製品手入力区分
            colList.push(CreateColRow('product_manual_input_class', null, 'NUMBER'));
            // 在庫管理区分
            colList.push(CreateColRow('inventory_control_class', null, 'NUMBER'));
            // ケース換算区分
            colList.push(CreateColRow('case_conversion_class', null, 'NUMBER'));
            // 受注残数
            colList.push(CreateColRow('remaining_quantity', null, 'NUMBER'));
            // 未引当数
            colList.push(CreateColRow('reserve_quantity_incomplete', 0, 'NUMBER'));
            // 引当済数
            colList.push(CreateColRow('reserve_quantity', 0, 'NUMBER'));
            // 入荷予定引当数
            colList.push(CreateColRow('stock_scheduled_reserve', 0, 'NUMBER'));
            // 入荷予定引当数受注
            colList.push(CreateColRow('stock_scheduled_reserve_receive', 0, 'NUMBER'));
            // 出荷数
            colList.push(CreateColRow('shipping_quantity', 0, 'NUMBER'));
            // 発注番号
            colList.push(CreateColRow('order_id', 0, 'NUMBER'));
            // 発注行番号
            colList.push(CreateColRow('order_row', 0, 'NUMBER'));
            // 発注製品コード
            colList.push(CreateColRow('order_product_id', 0, 'NUMBER'));
            // 換算入数
            colList.push(CreateColRow('converted_quantity', null, 'NUMBER'));
            // 在庫置き場所1
            colList.push(CreateColRow('place_1', null, 'VARCHAR'));
            // 在庫置き場所2
            colList.push(CreateColRow('place_2', null, 'VARCHAR'));
            // 在庫置き場所3
            colList.push(CreateColRow('place_3', null, 'VARCHAR'));
            // 在庫置き場所4
            colList.push(CreateColRow('place_4', null, 'VARCHAR'));
            // セット品区分
            colList.push(CreateColRow('set_class', null, 'NUMBER'));
            // 作成ユーザー
            colList.push(CreateColRow('created_user', null, 'VARCHAR'));
            // 更新ユーザー
            colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
            bulkInsertSqlStart = 'INSERT INTO t_orders_received_set (' + CreateInsertSql(colList, 'col', 't_orders_received_set') + ') ';
            //console.log(bulkInsertSqlStart);
          }
          let selectSql = '';
          /* SELECT句 */
          selectSql += 'SELECT ';
          // 受注番号
          selectSql += ' orders_receives.order_receive_id';
          // 受注行番号
          selectSql += ',orders_receives.order_receive_row';
          // 受注行枝番
          selectSql += ',(SELECT COUNT(*) + 1 FROM m_products_compositions AS cnt WHERE cnt.product_id = products_compositions.product_id AND cnt.component_product_id < products_compositions.component_product_id) AS order_receive_row_branch';
          // 製品コード
          selectSql += ',products.product_id';
          // 製品名
          selectSql += ',products.product_name_kanji';
          // 備考
          selectSql += ',orders_receives.note';
          // サービス区分
          selectSql += ',orders_receives.service_class';
          // 受注数
          selectSql += ',orders_receives.order_receive_quantity * products_compositions.quantity';
          // 単位
          selectSql += ',products.unit';
          // 製品分類コード
          selectSql += ',products.product_class_id';
          // 製品手入力区分
          selectSql += ',products.sundries_class';
          // 在庫管理区分
          selectSql += ',products.inventory_control_class';
          // ケース換算区分
          selectSql += ',products_details.case_conversion_class';
          // 受注残数
          selectSql += ',orders_receives.remaining_quantity * products_compositions.quantity';
          // 未引当数
          selectSql += ',orders_receives.reserve_quantity_incomplete * products_compositions.quantity';
          // 引当済数
          selectSql += ',orders_receives.reserve_quantity * products_compositions.quantity';
          // 入荷予定引当数
          selectSql += ',orders_receives.stock_scheduled_reserve * products_compositions.quantity';
          // 入荷予定引当数受注
          selectSql += ',0';
          // 出荷数
          selectSql += ',0';
          // 発注番号
          selectSql += ',0';
          // 発注行番号
          selectSql += ',0';
          // 発注製品コード
          selectSql += ',0';
          // 換算入数
          selectSql += ',0';
          // 在庫置き場所1
          selectSql += ',products_details.place_1';
          // 在庫置き場所2
          selectSql += ',products_details.place_2';
          // 在庫置き場所3
          selectSql += ',products_details.place_3';
          // 在庫置き場所4
          selectSql += ',products_details.place_4';
          // セット品区分
          selectSql += ',0';
          // 作成ユーザー
          selectSql += ',\'' + this.loginId + '\'';
          // 更新ユーザー
          selectSql += ',\'' + this.loginId + '\'';
          /* FROM句 */
          selectSql += ' FROM ';
          selectSql += 't_orders_receives AS orders_receives ';
          selectSql += 'INNER JOIN m_products_compositions AS products_compositions ';
          selectSql += 'ON orders_receives.product_id = products_compositions.product_id ';
          selectSql += 'INNER JOIN m_products AS products ';
          selectSql += 'ON products_compositions.component_product_id = products.product_id ';
          selectSql += 'INNER JOIN m_products_details AS products_details ';
          selectSql += 'ON products_compositions.component_product_id = products_details.product_id ';
          selectSql += 'AND orders_receives.office_id = products_details.office_id ';
          /* WHERE句 */
          selectSql += ' WHERE ';
          selectSql += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
          selectSql += 'AND orders_receives.order_receive_row = ' + (i + 1).toString() + ' ';
          //console.log(selectSql);
          if (bulkInsertSqlSelect != '') {
            bulkInsertSqlSelect += 'UNION ';
          }
          bulkInsertSqlSelect += selectSql;
          if (bulkInsertSqlSelect.length >= Const.SqlMaxLength) {
            this.transactSqlList.push({sql: bulkInsertSqlStart + bulkInsertSqlSelect, forUpdateFlg: 0});
            bulkInsertSqlSelect = '';
          }
        }
      }
      if (bulkInsertSqlSelect != '') {
        this.transactSqlList.push({sql: bulkInsertSqlStart + bulkInsertSqlSelect, forUpdateFlg: 0});
      }
    },
    /* 発注データ更新SQL作成 */
    updateOrders: function() {
      //console.log('発注データ更新SQL作成');
      let colList = [];
      let updateSql = '';
      let where_clause = '';
      for (let i = 0; i < this.productListHidden.length; i++) {
        if (this.productList[i].OrderJokyo < Const.ReceivedOrderOrderJokyo.nyukaZumi) {
          // 入荷済み以外の場合
          colList = [];
          // 共通更新箇所
          // サービス区分
          colList.push(CreateColRow('orders.service_class', 'orders_receives.service_class', 'NUMBER'));
          // 取引先コード
          colList.push(CreateColRow('orders.client_id', 'CASE WHEN orders_receives.change_order_client_id IS NULL THEN orders.client_id ELSE orders_receives.change_order_client_id END', 'NUMBER'));
          // 発注数
          colList.push(CreateColRow('orders.order_quantity', 'orders_receives.order_receive_quantity', 'NUMBER'));
          // 発注金額
          colList.push(CreateColRow('orders.order_amount', 'orders.order_quantity * orders.order_unit_amount', 'NUMBER'));
          // 発注残数
          colList.push(CreateColRow('orders.remaining_quantity', 'orders_receives.remaining_quantity', 'NUMBER'));
          // 入荷予定引当数
          colList.push(CreateColRow('orders.stock_scheduled_reserve', 'orders_receives.stock_scheduled_reserve', 'NUMBER'));
          // 現場コード
          colList.push(CreateColRow('orders.site_id', this.onSite.code, 'NUMBER'));
          // 削除済フラグ
          colList.push(CreateColRow('orders.is_deleted', 'orders_receives.is_deleted', 'NUMBER'));
          // 更新日
          colList.push(CreateColRow('orders.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
          // 更新ユーザー
          colList.push(CreateColRow('orders.updated_user', this.loginId, 'VARCHAR'));
          if (this.productList[i].CaseConversion == Const.CaseConversionClassDef.conversion && this.productList[i].SetFlg == Const.SetClass.set) {
            // ケース換算区分が「0:する」、且つ、セット品の場合
            // 更新用の副問い合わせテーブル作成
            let updateQuery = '(';
            updateQuery += 'SELECT';
            updateQuery += ' orders_receives.order_receive_id';
            updateQuery += ',orders_receives.order_receive_row';
            updateQuery += ',orders_receives.service_class';
            updateQuery += ',orders_receives.change_order_client_id';
            updateQuery += ',orders_receives.is_deleted';
            updateQuery += ',orders_received_set.order_id';
            updateQuery += ',orders_received_set.order_row';
            updateQuery += ',orders_received_set.order_receive_quantity';
            updateQuery += ',orders_received_set.remaining_quantity';
            updateQuery += ',orders_received_set.stock_scheduled_reserve';
            updateQuery += ' FROM ';
            updateQuery += 't_orders_receives AS orders_receives ';
            updateQuery += 'INNER JOIN t_orders_received_set AS orders_received_set ';
            updateQuery += 'ON orders_receives.order_receive_id = orders_received_set.order_receive_id ' ;
            updateQuery += 'AND orders_receives.order_receive_row = orders_received_set.order_receive_row ';
            updateQuery += ' WHERE ';
            updateQuery += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
            updateQuery += 'AND orders_receives.order_receive_row = ' + (i + 1).toString() + ' ';
            updateQuery += ') AS orders_receives';
            /* WHERE句 */
            where_clause = ' WHERE ';
            where_clause += 'orders.order_receive_id = ' + this.receivedOrderId + ' ';
            where_clause += 'AND orders.order_receive_row = ' + (i + 1).toString() + ' ';
            where_clause += 'AND orders.order_receive_id = orders_receives.order_receive_id ';
            where_clause += 'AND orders.order_receive_row = orders_receives.order_receive_row ';
            where_clause += 'AND orders.order_id = orders_receives.order_id ';
            where_clause += 'AND orders.order_row = orders_receives.order_row ';
            // Update句とWHERE句でUPDATE文作成
            updateSql = CreateUpdateSql(colList, 't_orders AS orders', updateQuery) + where_clause;
          } else if (this.productList[i].CaseConversion == Const.CaseConversionClassDef.conversion && this.productList[i].SetFlg == Const.SetClass.noSet) {
            // ケース換算区分が「0:する」、且つ、セット品以外の場合
            // 更新用の副問い合わせテーブル作成
            let updateQuery = '(';
            updateQuery += 'SELECT';
            updateQuery += ' orders_receives.order_receive_id';
            updateQuery += ',orders_receives.order_receive_row';
            updateQuery += ',orders_receives.service_class';
            updateQuery += ',orders_receives.change_order_client_id';
            updateQuery += ',orders_receives.is_deleted';
            updateQuery += ',CEILING(orders_receives.order_receive_quantity / products_units_conversions.quantity) AS order_receive_quantity';
            updateQuery += ',CEILING(orders_receives.remaining_quantity / products_units_conversions.quantity) AS remaining_quantity';
            updateQuery += ',CEILING(orders_receives.stock_scheduled_reserve / products_units_conversions.quantity) AS stock_scheduled_reserve';
            updateQuery += ' FROM ';
            updateQuery += 't_orders_receives AS orders_receives ';
            updateQuery += 'INNER JOIN m_products_units_conversions AS products_units_conversions ';
            updateQuery += 'ON orders_receives.order_product_id = products_units_conversions.case_product_id ';
            updateQuery += 'AND orders_receives.product_id = products_units_conversions.loose_product_id ';
            updateQuery += ' WHERE ';
            updateQuery += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
            updateQuery += 'AND orders_receives.order_receive_row = ' + (i + 1).toString() + ' ';
            updateQuery += ') AS orders_receives';
            /* WHERE句 */
            where_clause = ' WHERE ';
            where_clause += 'orders.order_receive_id = ' + this.receivedOrderId + ' ';
            where_clause += 'AND orders.order_receive_row = ' + (i + 1).toString() + ' ';
            where_clause += 'AND orders.order_receive_id = orders_receives.order_receive_id ';
            where_clause += 'AND orders.order_receive_row = orders_receives.order_receive_row ';
            // Update句とWHERE句でUPDATE文作成
            updateSql = CreateUpdateSql(colList, 't_orders AS orders', updateQuery) + where_clause;
          } else {
            // 上記以外（ケース換算しない）
            // 単位
            colList.push(CreateColRow('orders.unit', 'orders_receives.unit', 'NUMBER'));
            /* WHERE句 */
            where_clause = ' WHERE ';
            where_clause += 'orders.order_receive_id = ' + this.receivedOrderId + ' ';
            where_clause += 'AND orders.order_receive_row = ' + (i + 1).toString() + ' ';
            where_clause += 'AND orders.order_receive_id = orders_receives.order_receive_id ';
            where_clause += 'AND orders.order_receive_row = orders_receives.order_receive_row ';
            // Update句とWHERE句でUPDATE文作成
            updateSql = CreateUpdateSql(colList, 't_orders AS orders', 't_orders_receives AS orders_receives') + where_clause;
          }
          //console.log(updateSql)
          this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
        } else {
          // 入荷済みの場合
          colList = [];
          // 現場コード
          colList.push(CreateColRow('orders.site_id', this.onSite.code, 'NUMBER'));
          // 更新日
          colList.push(CreateColRow('orders.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
          // 更新ユーザー
          colList.push(CreateColRow('orders.updated_user', this.loginId, 'VARCHAR'));
          /* WHERE句 */
          where_clause = ' WHERE ';
          where_clause += 'orders.order_receive_id = ' + this.receivedOrderId + ' ';
          where_clause += 'AND orders.order_receive_row = ' + (i + 1).toString() + ' ';
          // Update句とWHERE句でUPDATE文作成
          updateSql = CreateUpdateSql(colList, 't_orders AS orders') + where_clause;
          //console.log(updateSql)
          this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
        }
      }
    },
    /* 発注データ登録処理 */
    async insertOrder() {
      //console.log('発注データ登録SQL作成');
      // CRUD処理
      let bulkInsertSqlStart = '';
      let bulkInsertSqlSelect = '';
      for(let i = this.productListHidden.length; i < this.productList.length; i++) {
        if (this.productList[i].InventoryClass == Const.InventoryControlClassDef.noInventory) {
          // 在庫管理しない場合は発注データ登録しない
          continue;
        }
        let colList = [];
        // INSERT文の先頭部分、初回のみ作成
        if (bulkInsertSqlStart == '') {
          // 発注行番号
          colList.push(CreateColRow('order_row', null, 'NUMBER'));
          // 発注日
          colList.push(CreateColRow('order_date', null, 'DATE'));
          // 発注種別
          colList.push(CreateColRow('order_class', null, 'NUMBER'));
          // 営業所コード
          colList.push(CreateColRow('office_id', null, 'NUMBER'));
          // 取引先区分
          colList.push(CreateColRow('client_class', null, 'NUMBER'));
          // 取引先コード
          colList.push(CreateColRow('client_id', null, 'NUMBER'));
          // 担当者コード
          colList.push(CreateColRow('staff_id', null, 'NUMBER'));
          // 製品コード
          colList.push(CreateColRow('product_id', null, 'NUMBER'));
          // 製品名
          colList.push(CreateColRow('product_name', null, 'VARCHAR'));
          // 備考
          colList.push(CreateColRow('note', null, 'VARCHAR'));
          // サービス区分
          colList.push(CreateColRow('service_class', null, 'VARCHAR'));
          // 発注数
          colList.push(CreateColRow('order_quantity', null, 'NUMBER'));
          // 単位
          colList.push(CreateColRow('unit', null, 'VARCHAR'));
          // 発注単価
          colList.push(CreateColRow('order_unit_amount', null, 'NUMBER'));
          // 発注金額
          colList.push(CreateColRow('order_amount', null, 'NUMBER'));
          // 消費税
          colList.push(CreateColRow('tax', null, 'NUMBER'));
          // 発注残数
          colList.push(CreateColRow('remaining_quantity', null, 'NUMBER'));
          // 入荷数
          colList.push(CreateColRow('stock_quantity', '0', 'NUMBER'));
          // 入荷予定日
          colList.push(CreateColRow('stock_scheduled', null, 'DATE'));
          // 入荷予定日確定区分
          colList.push(CreateColRow('stock_scheduled_confirm_class', null, 'NUMBER'));
          // 入荷予定処理担当者コード
          colList.push(CreateColRow('stock_scheduled_staff_id', null, 'NUMBER'));
          // 入荷予定引当数
          colList.push(CreateColRow('stock_scheduled_reserve', '0', 'NUMBER'));
          // 組合コード
          colList.push(CreateColRow('union_id', null, 'NUMBER'));
          // セット品区分
          colList.push(CreateColRow('set_class', '0', 'NUMBER'));
          // 現場コード
          colList.push(CreateColRow('site_id', '0', 'NUMBER'));
          // 製品分類コード
          colList.push(CreateColRow('product_class_id', null, 'NUMBER'));
          // 製品手入力区分
          colList.push(CreateColRow('product_manual_input_class', null, 'NUMBER'));
          // 在庫管理区分
          colList.push(CreateColRow('inventory_control_class', null, 'NUMBER'));
          // 取引先製品単価区分
          colList.push(CreateColRow('client_amount_class', null, 'VARCHAR'));
          // ケース換算区分
          colList.push(CreateColRow('case_conversion_class', null, 'NUMBER'));
          // 仕入課税区分
          colList.push(CreateColRow('client_tax_class', null, 'NUMBER'));
          // 税額計算区分
          colList.push(CreateColRow('tax_calculation_class', null, 'NUMBER'));
          // 税額端数計算処理区分
          colList.push(CreateColRow('tax_fractionation_class', null, 'NUMBER'));
          // 発注書発行区分
          colList.push(CreateColRow('order_issue_class', '0', 'NUMBER'));
          // 注文番号
          colList.push(CreateColRow('order_number', '0', 'NUMBER'));
          // 受注番号
          colList.push(CreateColRow('order_receive_id', '0', 'NUMBER'));
          // 受注行番号
          colList.push(CreateColRow('order_receive_row', '0', 'NUMBER'));
          // 削除済フラグ
          colList.push(CreateColRow('is_deleted', '0', 'NUMBER'));
          // 作成ユーザー
          colList.push(CreateColRow('created_user', null, 'VARCHAR'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
          bulkInsertSqlStart = 'INSERT INTO t_orders (' + CreateInsertSql(colList, 'col', 't_orders') + ') ';
          //console.log(bulkInsertSqlStart);
        }
        let selectSql = '';
        /* SELECT句 */
        selectSql += 'SELECT ';
        // 発注行番号
        selectSql += ' 1';
        // 発注日
        selectSql += ',CURDATE()';
        // 発注種別
        if (this.selectDelivery == Const.ShippingTypeClass.direct) {
          // 配送種別「4:直送」の場合、発注種別「3:直送」
          selectSql += ',' + Const.OrderClass.direct;
        } else {
          // 上記以外（伝種「15:特別受注」）の場合、発注種別「5:特別発注」
          selectSql += ',' + Const.OrderClass.special;
        }
        // 営業所コード
        selectSql += ',orders_receives.office_id';
        // 取引先区分
        selectSql += ',clients.client_class';
        // 取引先コード
        selectSql += ',clients.client_id';
        // 担当者コード
        selectSql += ',' + this.inputStaffId;
        // 製品コード
        selectSql += ',SUBQUERY.product_id';
        // 製品名
        selectSql += ',products.product_name_kanji';
        // 備考
        selectSql += ',\'\'';
        // サービス区分
        selectSql += ',orders_receives.service_class';
        // 発注数
        selectSql += ',SUBQUERY.order_quantity';
        // 単位
        selectSql += ',products.unit';
        // 発注単価
        selectSql += ',products_details.purchase_price';
        // 発注金額
        selectSql += ',products_details.purchase_price * SUBQUERY.order_quantity';
        // 消費税
        selectSql += ',0';
        // 発注残数
        selectSql += ',SUBQUERY.order_quantity';
        // 入荷数
        selectSql += ',0';
        // 入荷予定日
        selectSql += ',CASE WHEN products_orders.lead_time = 99 THEN \'9999-12-31\' ELSE DATE_ADD(CURDATE(), INTERVAL products_orders.lead_time DAY) END';
        // 入荷予定日確定区分
        selectSql += ',0';
        // 入荷予定処理担当者コード
        selectSql += ',NULL';
        // 入荷予定引当数
        selectSql += ',SUBQUERY.order_quantity';
        // 組合コード
        selectSql += ',clients.union_id';
        // セット品区分
        selectSql += ',' + Const.SetClass.noSet;
        // 現場コード
        selectSql += ',orders_receives.site_id';
        // 製品分類コード
        selectSql += ',products.product_class_id';
        // 製品手入力区分
        selectSql += ',products.sundries_class';
        // 在庫管理区分
        selectSql += ',products.inventory_control_class';
        // 取引先製品単価区分
        selectSql += ',\'\'';
        // ケース換算区分
        selectSql += ',products_details.case_conversion_class';
        // 仕入課税区分
        selectSql += ',clients.sales_tax_class';
        // 税額計算区分
        selectSql += ',clients.tax_calculation_class';
        // 税額端数計算処理区分
        selectSql += ',clients.tax_fractionation_class';
        // 発注書発行区分
        selectSql += ',0';
        // 注文番号
        selectSql += ',0';
        // 受注番号
        selectSql += ',' + this.receivedOrderId;
        // 受注行番号
        selectSql += ',' + (i + 1).toString();
        // 削除済フラグ
        selectSql += ',0';
        // 作成ユーザー
        selectSql += ',\'' + this.loginId + '\'';
        // 更新ユーザー
        selectSql += ',\'' + this.loginId + '\'';
        /* FROM句 */
        selectSql += ' FROM ';
        selectSql += 't_orders_receives AS orders_receives ';
        selectSql += 'INNER JOIN ' + this.createCaseConversionQuery((i + 1).toString()) + ' AS SUBQUERY ';
        selectSql += 'ON orders_receives.order_receive_id = SUBQUERY.order_receive_id ';
        selectSql += 'AND orders_receives.order_receive_row = SUBQUERY.order_receive_row ';
        selectSql += 'INNER JOIN m_products AS products ';
        selectSql += 'ON SUBQUERY.product_id = products.product_id ';
        selectSql += 'INNER JOIN m_products_details AS products_details ';
        selectSql += 'ON SUBQUERY.product_id = products_details.product_id ';
        selectSql += 'AND orders_receives.office_id = products_details.office_id ';
        selectSql += 'INNER JOIN m_clients AS clients ';
        selectSql += 'ON clients.client_class = ' + Const.ClientClass.supplier + ' ';
        selectSql += 'AND SUBQUERY.client_id = clients.client_id ';
        selectSql += 'INNER JOIN m_products_orders AS products_orders ';
        selectSql += 'ON orders_receives.office_id = products_orders.office_id ';
        selectSql += 'AND SUBQUERY.product_id = products_orders.product_id ';
        /* WHERE句 */
        selectSql += ' WHERE ';
        selectSql += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
        selectSql += 'AND orders_receives.order_receive_row = ' + (i + 1).toString() + ' ';
        selectSql += 'AND orders_receives.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
        //console.log(selectSql);
        if (bulkInsertSqlSelect != '') {
          bulkInsertSqlSelect += 'UNION ';
        }
        bulkInsertSqlSelect += selectSql;
        if (bulkInsertSqlSelect.length >= Const.SqlMaxLength) {
          this.transactSqlList.push({sql: bulkInsertSqlStart + bulkInsertSqlSelect, forUpdateFlg: 0});
          bulkInsertSqlSelect = '';
        }
      }
      if (bulkInsertSqlSelect != '') {
        this.transactSqlList.push({sql: bulkInsertSqlStart + bulkInsertSqlSelect, forUpdateFlg: 0});
      }
    },
    /* 発注番号等を受注データに反映 */
    async updateOrderReceivesSetOrderId() {
      //console.log('発注番号等を受注データに反映');
      /* 受注データの更新 */
      // 受注データに発注番号を設定
      let updateSql = '';
      let colList = [];
      // 発注番号
      colList.push(CreateColRow('orders_receives.order_id', 'orders_QUERY.order_id', 'NUMBER'));
      // 発注行番号
      colList.push(CreateColRow('orders_receives.order_row', 'orders_QUERY.order_row', 'NUMBER'));
      // 発注製品コード	
      colList.push(CreateColRow('orders_receives.order_product_id', 'orders_QUERY.product_id', 'NUMBER'));
      // 換算入数
      colList.push(CreateColRow('orders_receives.converted_quantity', 1, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('orders_receives.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders_receives.updated_user', this.loginId, 'VARCHAR'));
      // 更新用の副問い合わせテーブル作成
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' orders.order_id';
      updateQuery += ',orders.order_row';
      updateQuery += ',orders.product_id';
      updateQuery += ',orders.order_receive_id';
      updateQuery += ',orders.order_receive_row';
      updateQuery += ' FROM ';
      updateQuery += 't_orders AS orders ';
      updateQuery += ' WHERE ';
      updateQuery += 'orders.order_receive_id = ' + this.receivedOrderId + ' ';
      updateQuery += ') AS orders_QUERY';
      // 副問い合わせの情報で受注データ更新SQL作成
      updateSql += CreateUpdateSql(colList, 't_orders_receives AS orders_receives', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'orders_receives.order_receive_id = orders_QUERY.order_receive_id ';
      updateSql += 'AND orders_receives.order_receive_row = orders_QUERY.order_receive_row ';
      updateSql += 'AND orders_receives.set_class = ' + Const.SetClass.noSet + ' ';
      updateSql += 'AND orders_receives.order_receive_row > ' + this.productListHidden.length + ' ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 発注番号等を受注セット品データに反映 */
    async updateOrderReceivedSetSetOrderId() {
      //console.log('発注番号等を受注セット品データに反映');
      /* 受注セット品データの更新 */
      // 受注セット品データに発注番号を設定
      let updateSql = '';
      let colList = [];
      // 発注番号
      colList.push(CreateColRow('orders_received_set.order_id', 'orders_QUERY.order_id', 'NUMBER'));
      // 発注行番号
      colList.push(CreateColRow('orders_received_set.order_row', 'orders_QUERY.order_row', 'NUMBER'));
      // 発注製品コード	
      colList.push(CreateColRow('orders_received_set.order_product_id', 'orders_QUERY.product_id', 'NUMBER'));
      // 換算入数
      colList.push(CreateColRow('orders_received_set.converted_quantity', 1, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('orders_received_set.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders_received_set.updated_user', this.loginId, 'VARCHAR'));
      // 更新用の副問い合わせテーブル作成
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' orders.order_id';
      updateQuery += ',orders.order_row';
      updateQuery += ',orders.product_id';
      updateQuery += ',orders.order_receive_id';
      updateQuery += ',orders.order_receive_row';
      updateQuery += ' FROM ';
      updateQuery += 't_orders AS orders ';
      updateQuery += ' WHERE ';
      updateQuery += 'orders.order_receive_id = ' + this.receivedOrderId + ' ';
      updateQuery += ') AS orders_QUERY';
      // 副問い合わせの情報で受注セット品データ更新SQL作成
      updateSql += CreateUpdateSql(colList, 't_orders_received_set AS orders_received_set', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'orders_received_set.order_receive_id = orders_QUERY.order_receive_id ';
      updateSql += 'AND orders_received_set.order_receive_row = orders_QUERY.order_receive_row ';
      updateSql += 'AND orders_received_set.product_id = orders_QUERY.product_id ';
      updateSql += 'AND orders_received_set.order_receive_row > ' + this.productListHidden.length + ' ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* 換算入数を受注データに反映 */
    async updateOrderReceivesSetConvertedQuantity() {
      //console.log('換算入数を受注データに反映');
      /* 受注データの更新 */
      // 受注データに換算入数を設定
      let updateSql = '';
      let colList = [];
      // 換算入数
      colList.push(CreateColRow('orders_receives.converted_quantity', 'orders_receives_QUERY.quantity', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('orders_receives.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('orders_receives.updated_user', this.loginId, 'VARCHAR'));
      // 更新用の副問い合わせテーブル作成
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' orders_receives.order_receive_id';
      updateQuery += ',orders_receives.order_receive_row';
      updateQuery += ',products_units_conversions.quantity';
      updateQuery += ' FROM ';
      updateQuery += 't_orders_receives AS orders_receives ';
      updateQuery += 'INNER JOIN m_products_units_conversions	AS products_units_conversions ';
      updateQuery += 'ON orders_receives.product_id = products_units_conversions.loose_product_id ';
      updateQuery += 'AND orders_receives.order_product_id = products_units_conversions.case_product_id ';
      updateQuery += ' WHERE ';
      updateQuery += 'orders_receives.product_id <> orders_receives.order_product_id ';
      updateQuery += 'AND orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      updateQuery += 'AND orders_receives.order_receive_row > ' + this.productListHidden.length + ' ';
      updateQuery += ') AS orders_receives_QUERY';
      updateSql += CreateUpdateSql(colList, 't_orders_receives AS orders_receives', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'orders_receives.order_receive_id = orders_receives_QUERY.order_receive_id ';
      updateSql += 'AND orders_receives.order_receive_row = orders_receives_QUERY.order_receive_row ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    // 出荷データ更新SQL作成
    async updateShips() {
      //console.log('出荷データ更新SQL作成');
      let colList = [];
      // 配送種別区分
      colList.push(CreateColRow('shipping_type_class', this.selectDelivery, 'VARCHAR'));
      // 配送コード
      colList.push(CreateColRow('shipping_code', await escapeQuote(this.onSite.deliveryCode), 'VARCHAR'));
      // 現場コード
      colList.push(CreateColRow('site_id', this.onSite.code, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      let updateSql = CreateUpdateSql(colList, 't_ships AS ships');
      updateSql += ' WHERE ';
      updateSql += 'order_receive_id = ' + this.receivedOrderId + ' ';
      updateSql += 'AND sales_issue_class = ' + Const.SalesIssueClass.mi + ' ';
      //console.log(updateSql);
      this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    /* ケース換算後SQLクエリ作成 */
    createCaseConversionQuery: function(orderReceiveRow) {
      let selectSql = '(';
      // ケース換算「1:しない」
      /* SELECT句 */
      selectSql += 'SELECT ';
      // 受注番号
      selectSql += ' orders_receives.order_receive_id';
      // 受注行番号
      selectSql += ',orders_receives.order_receive_row';
      // 製品コード
      selectSql += ',orders_receives.product_id';
      // 仕入先コード
      selectSql += ',CASE WHEN orders_receives.change_order_client_id <> 0 THEN orders_receives.change_order_client_id ELSE products_orders.client_id END AS client_id';
      // 発注数
      selectSql += ',orders_receives.remaining_quantity AS order_quantity';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN m_products_orders AS products_orders ';
      selectSql += 'ON orders_receives.office_id = products_orders.office_id ';
      selectSql += 'AND orders_receives.product_id = products_orders.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.noConversion + ' ';
      selectSql += 'AND orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      selectSql += 'AND orders_receives.order_receive_row = ' + orderReceiveRow + ' ';

      selectSql += ' UNION ALL ';
      // ケース換算「0:する」、セット品区分「0:セット品でない」
      /* SELECT句 */
      selectSql += 'SELECT ';
      // 受注番号
      selectSql += ' orders_receives.order_receive_id';
      // 受注行番号
      selectSql += ',orders_receives.order_receive_row';
      // 製品コード
      selectSql += ',products_units_conversions.case_product_id AS product_id';
      // 仕入先コード
      selectSql += ',CASE WHEN orders_receives.change_order_client_id <> 0 THEN orders_receives.change_order_client_id ELSE products_orders.client_id END AS client_id';
      // 発注数
      selectSql += ',CEILING(orders_receives.remaining_quantity / products_units_conversions.quantity) AS order_quantity';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN m_products_units_conversions AS products_units_conversions ';
      selectSql += 'ON orders_receives.product_id = products_units_conversions.loose_product_id ';
      selectSql += 'INNER JOIN m_products_orders AS products_orders ';
      selectSql += 'ON orders_receives.office_id = products_orders.office_id ';
      selectSql += 'AND products_units_conversions.case_product_id = products_orders.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.conversion + ' ';
      selectSql += 'AND orders_receives.set_class = ' + Const.SetClass.noSet + ' ';
      selectSql += 'AND orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      selectSql += 'AND orders_receives.order_receive_row = ' + orderReceiveRow + ' ';

      selectSql += ' UNION ALL ';
      // ケース換算「0:する」、セット品区分「1:セット品」
      /* SELECT句 */
      selectSql += 'SELECT ';
      // 受注番号
      selectSql += ' orders_receives.order_receive_id';
      // 受注行番号
      selectSql += ',orders_receives.order_receive_row';
      // 製品コード
      selectSql += ',orders_received_set.product_id';
      // 仕入先コード
      selectSql += ',CASE WHEN orders_receives.change_order_client_id <> 0 THEN orders_receives.change_order_client_id ELSE products_orders.client_id END AS client_id';
      // 発注数
      selectSql += ',orders_received_set.remaining_quantity AS order_quantity';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'INNER JOIN t_orders_received_set AS orders_received_set ';
      selectSql += 'ON orders_receives.order_receive_id = orders_received_set.order_receive_id ';
      selectSql += 'AND orders_receives.order_receive_row = orders_received_set.order_receive_row ';
      selectSql += 'INNER JOIN m_products_orders AS products_orders ';
      selectSql += 'ON orders_receives.office_id = products_orders.office_id ';
      selectSql += 'AND orders_received_set.product_id = products_orders.product_id ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'orders_receives.case_conversion_class = ' + Const.CaseConversionClassDef.conversion + ' ';
      selectSql += 'AND orders_receives.set_class = ' + Const.SetClass.set + ' ';
      selectSql += 'AND orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      selectSql += 'AND orders_receives.order_receive_row = ' + orderReceiveRow + ' ';
      /* ORDER BY句 */
      selectSql += ' ORDER BY product_id ';
      selectSql += ' ) ';

      return selectSql;
    },
    /* 在庫マスタ更新SQL作成 */
    updateStocksMaster: function() {
      //console.log('在庫マスタ更新SQL作成');
      let updateSql = '';
      let colList = [];
      if (this.selectBillClass == Const.OrderReceiveBillClass.special) {
        // 発注書発行済みの「特別受注」の場合
        // 更新用の副問い合わせテーブル作成
        let updateSubQuery = '';
        for(let i = 0; i < this.productListHidden.length; i++) {
          if (updateSubQuery != '') {
            updateSubQuery += ' UNION ALL ';
          }
          if (this.productList[i].SetFlg == Const.SetClass.set &&
          this.productList[i].CaseConversion == Const.CaseConversionClassDef.conversion) {
            // セット製品（セット品区分:1、且つ、ケース換算区分「0:する」）
            updateSubQuery += 'SELECT';
            updateSubQuery += ' orders.product_id';
            updateSubQuery += ',' + (this.productList[i].Quantity - this.productListHidden[i].Quantity).toString() + ' * products_compositions.quantity AS change_cnt';
            updateSubQuery += ' FROM ';
            updateSubQuery += 't_orders AS orders ';
            updateSubQuery += 'INNER JOIN m_products_compositions AS products_compositions ';
            updateSubQuery += 'ON products_compositions.product_id = ' + this.productList[i].ProductCode + ' ';
            updateSubQuery += 'AND orders.product_id = products_compositions.component_product_id ';
            updateSubQuery += ' WHERE ';
            updateSubQuery += 'orders.order_receive_id = ' + this.receivedOrderId + ' ';
            updateSubQuery += 'AND orders.order_receive_row = ' + (i+1).toString() + ' ';
            updateSubQuery += 'AND orders.order_issue_class > 0 ';
            updateSubQuery += 'AND orders.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
          } else {
            // 上記以外
            // ・ケース換算しない
            // ・バラ⇒ケース製品（セット品区分:0、且つ、ケース換算区分「0:する」）
            updateSubQuery += 'SELECT';
            updateSubQuery += ' orders.product_id';
            updateSubQuery += ',orders.order_quantity - ' + this.productList[i].OrderQuantity.toString() + ' AS change_cnt';
            updateSubQuery += ' FROM ';
            updateSubQuery += 't_orders AS orders ';
            updateSubQuery += ' WHERE ';
            updateSubQuery += 'orders.order_receive_id = ' + this.receivedOrderId + ' ';
            updateSubQuery += 'AND orders.order_receive_row = ' + (i+1).toString() + ' ';
            updateSubQuery += 'AND orders.order_issue_class > 0 ';
            updateSubQuery += 'AND orders.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
          }
        }
        let updateQuery = '(';
        updateQuery += 'SELECT';
        updateQuery += ' ' + this.controlMasterData.processMonthYear + ' AS process_month_year';
        updateQuery += ',' + this.selectSalesOffice + ' AS office_id';
        updateQuery += ',SUB_QUERY.product_id';
        updateQuery += ',SUM(SUB_QUERY.change_cnt) AS change_cnt';
        updateQuery += ' FROM ';
        updateQuery += '(' + updateSubQuery + ') AS SUB_QUERY ';
        updateQuery += 'GROUP BY product_id ';
        updateQuery += ') AS PRODUCT_QUERY';
        // 入荷予定数
        colList.push(CreateColRow('stocks.quantity_stock', 'stocks.quantity_stock + PRODUCT_QUERY.change_cnt', 'NUMBER'));
        // 入荷予定引当数
        colList.push(CreateColRow('stocks.quantity_stock_reserve', 'stocks.quantity_stock_reserve + PRODUCT_QUERY.change_cnt', 'NUMBER'));
        // 更新日
        colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
        updateSql += CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
        updateSql += ' WHERE ';
        updateSql += 'stocks.month_year = PRODUCT_QUERY.process_month_year ';
        updateSql += 'AND stocks.office_id = PRODUCT_QUERY.office_id ';
        updateSql += 'AND stocks.product_id = PRODUCT_QUERY.product_id ';
        //console.log(updateSql);
        this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
      } else if (this.selectDelivery != Const.ShippingTypeClass.direct &&
        this.selectBillClass != Const.OrderReceiveBillClass.return) {
        // 「直送」「返品」以外（通常の在庫管理を行う）
        // 更新用の副問い合わせテーブル作成
        let updateSubQuery = '';
        for (let i = 0; i < this.productListHidden.length; i++) {
          if (updateSubQuery != '') {
            updateSubQuery += ' UNION ALL ';
          }
          if (this.productList[i].SetFlg == Const.SetClass.set &&
          this.productList[i].CaseConversion == Const.CaseConversionClassDef.conversion) {
            // セット製品（セット品区分:1、且つ、ケース換算区分「0:する」）
            updateSubQuery += 'SELECT';
            updateSubQuery += ' orders_received_set.product_id';
            updateSubQuery += ',' + this.productListHidden[i].SubReserveQuantity + ' * products_compositions.quantity AS sub_reserve_cnt';
            updateSubQuery += ' FROM ';
            updateSubQuery += 't_orders_receives AS orders_receives ';
            updateSubQuery += 'INNER JOIN t_orders_received_set AS orders_received_set ';
            updateSubQuery += 'ON orders_receives.order_receive_id = orders_received_set.order_receive_id ';
            updateSubQuery += 'AND orders_receives.order_receive_row = orders_received_set.order_receive_row ';
            updateSubQuery += 'INNER JOIN m_products_compositions AS products_compositions ';
            updateSubQuery += 'ON products_compositions.product_id = ' + this.productList[i].ProductCode + ' ';
            updateSubQuery += 'AND orders_received_set.product_id = products_compositions.component_product_id ';
            updateSubQuery += ' WHERE ';
            updateSubQuery += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
            updateSubQuery += 'AND orders_receives.order_receive_row = ' + (i+1).toString() + ' ';
            updateSubQuery += 'AND orders_receives.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
          } else {
            // 上記以外
            // ・ケース換算しない
            // ・バラ⇒ケース製品（セット品区分:0、且つ、ケース換算区分「0:する」）
            updateSubQuery += 'SELECT';
            updateSubQuery += ' orders_receives.product_id product_id';
            updateSubQuery += ',' + this.productListHidden[i].SubReserveQuantity + ' AS sub_reserve_cnt';
            updateSubQuery += ' FROM ';
            updateSubQuery += 't_orders_receives AS orders_receives ';
            updateSubQuery += ' WHERE ';
            updateSubQuery += 'orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
            updateSubQuery += 'AND orders_receives.order_receive_row = ' + (i+1).toString() + ' ';
            updateSubQuery += 'AND orders_receives.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
          }
        }
        let updateQuery = '(';
        updateQuery += 'SELECT';
        updateQuery += ' ' + this.controlMasterData.processMonthYear + ' AS process_month_year';
        updateQuery += ',' + this.selectSalesOffice + ' AS office_id';
        updateQuery += ',SUB_QUERY.product_id';
        updateQuery += ',SUM(SUB_QUERY.sub_reserve_cnt) AS sub_reserve_cnt';
        updateQuery += ' FROM ';
        updateQuery += '(' + updateSubQuery + ') AS SUB_QUERY ';
        updateQuery += 'GROUP BY product_id ';
        updateQuery += ') AS PRODUCT_QUERY';
        // 在庫引当数
        colList.push(CreateColRow('stocks.inventory_reserve_count', 'stocks.inventory_reserve_count - PRODUCT_QUERY.sub_reserve_cnt', 'NUMBER'));
        // 残高数
        colList.push(CreateColRow('stocks.balance', 'stocks.balance + PRODUCT_QUERY.sub_reserve_cnt', 'NUMBER'));
        // 更新日
        colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
        updateSql += CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
        updateSql += ' WHERE ';
        updateSql += 'stocks.month_year = PRODUCT_QUERY.process_month_year ';
        updateSql += 'AND stocks.office_id = PRODUCT_QUERY.office_id ';
        updateSql += 'AND stocks.product_id = PRODUCT_QUERY.product_id ';
        //console.log(updateSql);
        this.transactSqlList.push({sql: updateSql, forUpdateFlg: 0});
      } else {
        // 上記以外（在庫マスタ更新しない）
        return;
      }
    },
    /* 単価登録処理 */
    async insertUnitPrice() {
      let insertUnitPriceFlg = false;
      let unitPriceProductList = [];
      // 単価登録区分が「1:登録しない」以外の場合は単価登録を行う
      if (this.checkUnitPriceRegisterClass != Const.UnitPriceRegisterClass.insertNo){
        //console.log('自身の単価登録処理');
        // 単価登録用に製品一覧を作り直す（無効な製品と重複製品の除外（重複は後優先））
        for (let i = this.productList.length - 1; i >= 0; i--) {
          // 製品コードが0の場合は登録しない
          if (getNullStr(this.productList[i].ProductCode) != '0') {
            let productIndex = unitPriceProductList.findIndex(el => el.ProductCode == this.productList[i].ProductCode);
            if (productIndex == -1) {
              // 一覧に未登録の製品の場合
              // 一番前に追加
              unitPriceProductList.unshift(this.productList[i]);
            }
          }
        }
        //console.log(unitPriceProductList);
        if (unitPriceProductList.length > 0) {
          // 有効な製品がある場合
          insertUnitPriceFlg = true;
        }
      }
      if (this.openClientProductData.unitPriceRegisterClass != Const.UnitPriceRegisterClass.insertNo &&
      this.isDelOpenClientProducts == true) {
        // 画面起動時の単価登録区分が「1:登録しない」以外の場合
        // かつ、「画面起動時の単価登録情報削除チェック」がONの場合
        if (insertUnitPriceFlg == true) {
          let delSql = '';
          let sqlList = null;
          [delSql, sqlList] = await Promise.all([
            getDeleteUnitPriceSqlList(this.openClientProductData.productIdList, this.openClientProductData.clientId, this.openClientProductData.unitPriceEffectiveDate, this.openClientProductData.registerClass, this.openClientProductData.unitPriceRegisterClass),
            getInsertUnitPriceSqlList(unitPriceProductList, this.suppliers.code, this.dateUnitPriceEffectiveDate, this.checkRegisterClass, this.checkUnitPriceRegisterClass, this.loginId),
          ]);
          //console.log(delSql);
          this.transactSqlList.push({sql: delSql, forUpdateFlg: 0});
          //console.log(sqlList);
          for (let i = 0; i < sqlList.length; i++) {
            this.transactSqlList.push({sql: sqlList[i], forUpdateFlg: 0});
          }
        } else {
          let delSql = await getDeleteUnitPriceSqlList(this.openClientProductData.productIdList, this.openClientProductData.clientId, this.openClientProductData.unitPriceEffectiveDate, this.openClientProductData.registerClass, this.openClientProductData.unitPriceRegisterClass);
          this.transactSqlList.push({sql: delSql, forUpdateFlg: 0});
        }
        // 画面起動時の受注情報の単価登録を削除、これから登録する受注情報の単価登録を登録
      } else if (insertUnitPriceFlg == true) {
        // これから登録する受注情報の単価登録を登録
        // 単価登録用SQL作成
        let sqlList = await getInsertUnitPriceSqlList(unitPriceProductList, this.suppliers.code, this.dateUnitPriceEffectiveDate, this.checkRegisterClass, this.checkUnitPriceRegisterClass, this.loginId);
        for (let i = 0; i < sqlList.length; i++) {
          this.transactSqlList.push({sql: sqlList[i], forUpdateFlg: 0});
        }
      }
    },
    // キャンセルボタン
    cancel: function() {
      this.$router.push({ name: 'RECEIVED-ORDER-INPUT-INQUIRY', query: { receivedOrderId: this.receivedOrderId, parentKbn: 0, reserveErrFlg: 0 }});
    },
    // 担当者検索
    showStaffSearchModal: function() {
      this.$bvModal.show('staffSearchModal');
    },
    // 担当者検索モーダルを閉じた時
    closeStaffSearchModal: function(staffItems) {
      //console.log(staffItems);
      // モーダルから渡された値の有無チェック
      if (typeof staffItems != 'undefined') {
        this.orderReceiveStaff.id = staffItems.detail.staff_id;
        this.orderReceiveStaff.text = staffItems.detail.staff_id + '：' + staffItems.detail.staff_name_kanji;
      }
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      const functionName = 'searchClient';
      this.propClientClass = Const.ClientClass.customer;
      const result = await searchClientModal(client_id, this.propClientClass, functionName, MODULE_NAME);
      if (typeof result != 'undefined') {
        await this.closeClientSearchModal(result);
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (client_id.length == 6) {
          // 取引先クリア
          await this.clientClear();
        }
      }
    },
    // 取引先検索
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.propClientClass = Const.ClientClass.customer;
      this.$bvModal.show('clientSearchModal');
    },
    // 仕入先検索
    showSuppliersSearchModal: function(index) {
      //console.log('仕入先変更モーダル');
      this.propClientClass = Const.ClientClass.supplier;
      this.dataIndex = index;
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先検索モーダルを閉じた時
    async closeClientSearchModal(clientItems) {
      //console.log(clientItems);
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined'){
        if (this.propClientClass == 1) {
          // 取引先（得意先）から呼出
          this.setClosingYmd(clientItems.detail.closing_date);
          this.suppliers.code = clientItems.detail.client_id;
          this.suppliers.name = clientItems.detail.client_name_kanji;
          this.suppliers.address1 = clientItems.detail.address_1;
          this.suppliers.address2 = clientItems.detail.address_2;
          this.suppliers.department1 = clientItems.detail.department_1;
          this.suppliers.department2 = clientItems.detail.department_2;
          this.suppliers.tel = clientItems.detail.phone_number === 'undefined' ? '' : clientItems.detail.phone_number;
          this.suppliers.personInChargeId = clientItems.detail.staff_id;
          this.suppliers.personInCharge = clientItems.detail.staff_id + '：' + clientItems.detail.staff_name_kanji;
          this.suppliers.zipCode = clientItems.detail.zip_code === 'undefined' ? '' : clientItems.detail.zip_code;
          this.suppliers.class = clientItems.detail.client_class;
          this.suppliers.sundriesClass = clientItems.detail.sundries_class;
          this.suppliers.firstDigit = clientItems.detail.client_id_first_digit === 'undefined' ? '' : clientItems.detail.client_id_first_digit;
          this.suppliers.taxClass = clientItems.detail.sales_tax_class;
          this.suppliers.taxCalcClass = clientItems.detail.tax_calculation_class === 'undefined' ? '' : clientItems.detail.tax_calculation_class;
          this.suppliers.taxFracClass = clientItems.detail.tax_fractionation_class === 'undefined' ? '' : clientItems.detail.tax_fractionation_class;
          this.suppliers.check = clientItems.detail.check_contents;
          if (getNullStr(await getOrderReceiveClientNote(this.suppliers.code)) != '' ) {
            this.isClientNoteInput = true;
          } else {
            this.isClientNoteInput = false;
          }
          // 現場クリア
          this.siteClear();
          // 単価をリフレッシュ
          await this.refreshSalesAmount();
        } else {
          // 製品リストの発注先変更から呼出
          // 発注先コード
          this.productList[this.dataIndex].SupplierCode = clientItems.detail.client_id;
          // 発注先名
          this.productList[this.dataIndex].SupplierName = clientItems.detail.client_name_kanji;
        }
      }
    },
    // 取引先クリア
    clientClear: function() {
      //console.log('取引先クリア');
      this.suppliers.closingDate = '';
      this.suppliers.code = '';
      this.suppliers.name = '';
      this.suppliers.address1 = '';
      this.suppliers.address2 = '';
      this.suppliers.department1 = '';
      this.suppliers.department2 = '';
      this.suppliers.tel = '';
      this.suppliers.personInChargeId = '';
      this.suppliers.personInCharge = '';
      this.suppliers.zipCode = '';
      this.suppliers.class = '';
      this.suppliers.sundriesClass = '';
      this.suppliers.firstDigit = '';
      this.suppliers.taxClass = '';
      this.suppliers.taxCalcClass = '';
      this.suppliers.taxFracClass = '';
      this.suppliers.check = '';
      this.isClientNoteInput = false;
      // 受渡場所クリア
      this.siteClear();
    },
    /* 取引先照会 */
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    /* 取引先情報照会 */
    clientInfoInquiryModal: function(clientClass, clientId) {
      //console.log('取引先情報照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInformationInquiryModal');
    },
    // 取引先情報照会モーダルを閉じた時
    closeClientInfoInquiryModal: function(item) {
      // モーダルから渡された値の有無チェック
      if (typeof item != 'undefined') {
        this.isClientNoteInput = item.isNoteInput;
      }
    },
    /* 現場情報照会 */
    siteInfoInquiryModal: function(clientId, siteId) {
      //console.log('現場情報照会');
      this.propSiteInquiry.clientId = clientId;
      this.propSiteInquiry.siteId = siteId;
      this.$bvModal.show('siteInformationInquiryModal');
    },
    // 現場情報照会モーダルを閉じた時
    closeSiteInfoInquiryModal: function(item) {
      // モーダルから渡された値の有無チェック
      if (typeof item != 'undefined') {
        this.isSiteNoteInput = item.isNoteInput;
      }
    },
    // 製品コード直接入力 - 製品検索
    async searchProduct(product_id, index) {
      const functionName = 'searchProduct';
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.selectSalesOffice);
      this.productSearchProp.client_id = this.suppliers.code;
      this.productSearchProp.client_class = this.suppliers.class;
      this.productSearchProp.client_name = this.suppliers.name;
      this.productSearchProp.kijunDate = this.dateOrderReceiveDate;
      const result = await searchProductsModal(product_id, this.productSearchProp, functionName, MODULE_NAME);
      this.dataIndex = index;
      if (typeof result != 'undefined') {
        await this.closeProductSearchModal(result);
      } else {
        // 製品コード8桁かつデータ取得失敗の場合
        if (product_id.length == 8) {
          // 製品コードクリア
          this.productList[index].ProductCode = '';
        }
      }
    },
    // 製品検索
    showProductSearchModal: function(index) {
      //console.log('製品検索モーダル');
      //console.log(index);
      this.dataIndex = index;
      // 変更
      this.productSearchProp.office_id = this.selectSalesOffice;
      this.productSearchProp.office_name = getListValue(this.salesOffice, this.selectSalesOffice);
      this.productSearchProp.client_id = this.suppliers.code;
      this.productSearchProp.client_class = this.suppliers.class;
      this.productSearchProp.client_name = this.suppliers.name;
      this.productSearchProp.kijunDate = this.dateOrderReceiveDate;
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    async closeProductSearchModal(productItems) {
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if (typeof productItems != 'undefined') {
        let promiseArray = [];
        let focusFlg = false;
        if (productItems.length == undefined) {
          // 1件の場合のみフォーカスを数量に当てる
          focusFlg = true;
          // 選択ボタン押下でクローズ
          promiseArray.push(this.setProductSearchModal(this.dataIndex, productItems.detail));
        } else {
          // 一括選択ボタン押下でクローズ
          if (productItems.length == 1) {
            // 1件の場合のみフォーカスを数量に当てる
            focusFlg = true;
          }
          for (let i = 0; i < productItems.length; i++) {
            if (i == 0) {
              promiseArray.push(this.setProductSearchModal(this.dataIndex, productItems[i].detail));
            } else {
              promiseArray.push(this.productList.splice(this.dataIndex + i, 0, this.createNewRow()));
              promiseArray.push(this.setProductSearchModal(this.dataIndex + i, productItems[i].detail));
            }
          }
        }
        // 合計金額、合計仕入額、粗利率を計算
        promiseArray.push(this.calcTotal());
        if (this.statusFlg.orderFlg==false) {
          // 最終行が空行でない場合は空行追加
          if (this.isListBlankRow(this.productList.length - 1) == false) {
            promiseArray.push(this.addTableRow());
          }
        }
        await Promise.all(promiseArray);
        // 製品検索を行った行の数量にフォーカスを移動
        if (focusFlg == true) {
          // 製品検索を行った行の数量にフォーカスを移動
          setTimeout(() => {
            if (this.dataIndex < this.productList.length) {
              this.setFocus('productQuantity' + this.dataIndex);
            }
          }, 500);
        }
      }
    },
    // 製品検索モーダルの返却値設定
    async setProductSearchModal(index, productItemsDetail) {
      // 製品コード
      this.productList[index].ProductCode = productItemsDetail.product_id;
      // 製品分類コード
      this.productList[index].ProductClass = productItemsDetail.product_class_id;
      // 製品名（漢字）
      this.productList[index].ProductName = productItemsDetail.product_name_kanji;
      this.productList[index].ProductTaxRateClass = productItemsDetail.product_tax_rate_class_sales;
      // サービス区分
      this.productList[index].ServiceClass = '';
      // 数量
      if (this.selectBillClass == Const.OrderReceiveBillClass.return) {
        // 返品の場合
        // 数量
        this.productList[index].Quantity = -1;
      } else {
        // 返品以外の場合
        // 数量
        this.productList[index].Quantity = 1;
      }
      // 単位
      this.productList[index].Unit = productItemsDetail.unit;
      // BM価格
      this.productList[index].SalesUnitPrice = productItemsDetail.sales_unit_price;
      // 売上単価
      if(productItemsDetail.cp_sales_unit_price != null){
        this.productList[index].SellingPrice = productItemsDetail.cp_sales_unit_price;
        this.productList[index].ClientAmountClass = '*';
      }else{
        this.productList[index].SellingPrice = productItemsDetail.sales_unit_price;
        this.productList[index].ClientAmountClass = '';
      }
      // 仕入単価
      if (productItemsDetail.is_9A == 0) {
        // 9A製品以外の場合は原価単価
        this.productList[index].PurchasePrime = productItemsDetail.cost_price;
      } else {
        // 9A製品の場合は仕入単価
        this.productList[index].PurchasePrime = productItemsDetail.purchase_price;
      }
      // 製品チェック区分
      this.productList[index]._cellVariants = { ProductName: getListValue(Const.ProductCheckClass, productItemsDetail.product_check_class, 'value', 'color') };
      // 諸口区分
      this.productList[index].SundriesClass = productItemsDetail.sundries_class;
      // 在庫管理区分
      this.productList[index].InventoryClass = productItemsDetail.inventory_control_class;
      // 置き場所1
      this.productList[index].Place1 = productItemsDetail.place_1;
      // 置き場所2
      this.productList[index].Place2 = productItemsDetail.place_2;
      // 置き場所3
      this.productList[index].Place3 = productItemsDetail.place_3;
      // 置き場所4
      this.productList[index].Place4 = productItemsDetail.place_4;
      // ケース換算区分
      this.productList[index].CaseConversion = productItemsDetail.case_conversion_class;
      // 合計仕入単価
      this.productList[index].TotalPurchasePrime = this.productList[index].Quantity * this.productList[index].PurchasePrime;
      // 金額
      this.productList[index].Money = this.productList[index].Quantity * this.productList[index].SellingPrice;
      // 削除済フラグ
      this.productList[index].IsDeleted = 0;
      // 発注データ
      this.productList[index].OrderId = 0;
      this.productList[index].OrderRow = 0;
      // 発注状況
      this.productList[index].OrderJokyo = this.getOrderJokyo(this.selectDelivery, this.selectBillClass, this.productList[index].InventoryClass, 0, 0);
      this.productList[index].OrderJokyoText = getListValue(Const.ReceivedOrderOrderJokyoList, this.productList[index].OrderJokyo);
      // 出荷確定フラグ
      this.productList[index].ShipsClass = Const.ShipsClass.mi;
      this.productList[index].ShipsClassText = getListValue(Const.ShipsClassList, this.productList[index].ShipsClass);
      // 売上伝票発行区分
      this.productList[index].SalesIssueClass = 0;
      this.productList[index].SalesIssueClassText = getListValue(Const.SalesIssueClassList, 0);
      // 発注先コード
      this.productList[index].SupplierCode = productItemsDetail.supplier_id;
      // 発注先名
      this.productList[index].SupplierName = productItemsDetail.supplier_name_kanji;
      // 発注先コード（変更前の製品発注マスタ登録）
      this.productList[index].SupplierCodeRight = productItemsDetail.supplier_id;
      // 最低発注数
      this.productList[index].MinimumQuantity = productItemsDetail.minimum_quantity;
      // 初期化
      this.productList[index].CaseConversionErr = false;
      // ケース換算チェック（「0:換算する」の場合）
      if (this.productList[index].CaseConversion == Const.CaseConversionClassDef.conversion) {
        // セット品かどうか判定
        this.productList[index].SetFlg = productItemsDetail.set_flg;
        if (this.productList[index].SetFlg != Const.SetClass.set) {
          // セット品でない場合、バラ製品
          if (productItemsDetail.loose_flg == 0) {
            // バラ製品として登録されていない場合、エラーとする。
            this.productList[index].CaseConversionErr = true;
          }
        } else if (productItemsDetail.loose_flg == 1) {
          // セット製品、且つ、バラ製品の場合、エラーとする。
          this.productList[index].CaseConversionErr = true;
        }
      } else {
        // ケース換算が「1:しない」の場合、強制的にセット品でないを設定
        this.productList[index].SetFlg = Const.SetClass.noSet;
      }
      // 粗利額を確認して、警告の場合、背景色変更
      this.checkGrossProfit(this.productList[index], index);
    },
    // 納品指定区分変更時
    inputDeliveryNote: function() {
      if (this.selectDeliveryDesignation != '') {
        // 区分値を納品指定に設定
        this.deliveryNote = getListValue(this.deliveryDesignation, this.selectDeliveryDesignation);
      }
    },
    // 現場検索モーダルを開く
    showSiteSearchModal: function() {
      //console.log('現場検索モーダル');
      this.siteSearchInfo.client_id = this.suppliers.code;
      this.siteSearchInfo.client_name = this.suppliers.name;
      this.$bvModal.show('siteSearchModal');
    },
    // 現場コード直接入力 - 現場検索
    async searchSite(site_id) {
      const functionName = 'searchSite';
      const client_id = this.suppliers.code;
      const result = await searchSiteModal(site_id, client_id, functionName, MODULE_NAME);
      if (typeof result != 'undefined') {
        // 現場情報セット
        await this.closeSiteSearchModal(result);
      } else {
        // 現場クリア
        this.siteClear();
      }
    },
    // 現場検索モーダルを閉じた時
    async closeSiteSearchModal(siteItems) {
      //console.log(siteItems);
      // モーダルから渡された値の有無チェック
      if (typeof siteItems != 'undefined') {
        this.onSite.siteDeliveryCode = siteItems.detail.shipping_code;
        this.onSite.code = siteItems.detail.site_id;
        this.onSite.name = siteItems.detail.client_site_name_kanji;
        this.onSite.zipCode = siteItems.detail.client_site_zip_code;
        this.onSite.address1 = siteItems.detail.client_site_address_1;
        this.onSite.address2 = siteItems.detail.client_site_address_2;
        this.onSite.deliveryPlace = siteItems.detail.delivery_location;
        this.onSite.tel = siteItems.detail.client_site_phone_number;
        this.onSite.clientPrintClass = siteItems.detail.order_print_class;
        this.onSite.clientPrintName = siteItems.detail.order_print_client_name;
        this.onSite.clientPrintNameText = this.onSite.clientPrintClass == 0 ? '' : siteItems.detail.order_print_client_name;
        if (getNullStr(await getOrderReceiveSiteNote(this.suppliers.code, this.onSite.code)) != '' ) {
          this.isSiteNoteInput = true;
        } else {
          this.isSiteNoteInput = false;
        }
        this.setInitShippingCode();
      }
    },
    // 現場クリア
    siteClear: function() {
      //console.log('現場クリア');
      this.onSite.siteDeliveryCode = '';
      this.onSite.deliveryCode = '';
      this.onSite.code = '';
      this.onSite.name = '';
      this.onSite.zipCode = '';
      this.onSite.address1 = '';
      this.onSite.address2 = '';
      this.onSite.deliveryPlace = '';
      this.onSite.tel = '';
      this.onSite.clientPrintClass = '';
      this.onSite.clientPrintName = '';
      this.onSite.clientPrintNameText = '';
      this.isSiteNoteInput = false;
    },
    // ルート検索モーダルを開く
    showRouteSearchModal: function() {
      this.$bvModal.show('routeSearchModal');
    },
    // ルート検索モーダルを閉じた時
    closeRouteSearchModal: function(choiceData) {
      if (choiceData != undefined) {
        this.onSite.deliveryCode = choiceData.shippingCode;
      }
    },
    // 配送コード初期値
    setInitShippingCode: function() {
      if (this.onSite.code != '') {
        if (this.selectDelivery == Const.ShippingTypeClass.delivery || this.selectDelivery == Const.ShippingTypeClass.direct) {
          // 配送が「2:宅配」「4:直送」の場合、空白に変更
          this.onSite.deliveryCode = '';
        } else if (this.selectDelivery == Const.ShippingTypeClass.bring) {
          // 配送が「3:営業持参」の場合、担当者コードに変更
          this.onSite.deliveryCode = ('0000' + this.suppliers.personInChargeId).slice(-4);
        } else {
          // 上記以外の場合、現場マスタの設定値に変更
          this.onSite.deliveryCode = this.onSite.siteDeliveryCode;
        }
      }
    },
    // 在庫照会モーダルを開く
    openStockModal: function() {
      //console.log('在庫照会モーダル');
      //console.log(this.$bvModal);
      this.stockInfo.office_id = this.selectSalesOffice.toString();
      this.stockInfo.product_id = [];
      for(let i = 0; i < this.productList.length; i++) {
        if (this.productList[i].ProductCode != null) {
          this.stockInfo.product_id.push(this.productList[i].ProductCode.toString());
        }
      }
      this.$bvModal.show('stockModal');
    },
    // 取引先製品単価履歴照会モーダルを開く
    clickInquryClientProduct: function() {
      //console.log('取引先製品単価履歴照会モーダル');

      let productIdCsv = '';
      for (let i = 0; i < this.productList.length; i++) {
        if (getNullStr(this.productList[i].ProductCode) != '' && isNaN(getNullStr(this.productList[i].ProductCode)) == false) {
          if (productIdCsv != '') {
            productIdCsv += ',';
          }
          // 製品コード
          productIdCsv += this.productList[i].ProductCode;
        }
      }
      this.clientProductProp.clientClass = this.suppliers.class;
      this.clientProductProp.clientId = this.suppliers.code;
      this.clientProductProp.clientNameKanji = this.suppliers.name;
      this.clientProductProp.productIdCsv = productIdCsv;

      this.$bvModal.show('clientProductInquiryModal');
    },
    // 再計算イベント
    calc: function(index) {
      // 対象行の項目計算
      this.productList[index].TotalPurchasePrime = this.productList[index].Quantity * this.productList[index].PurchasePrime;
      this.productList[index].Money = this.productList[index].Quantity * this.productList[index].SellingPrice;
      // 合計金額、合計仕入単価、粗利計算
      this.calcTotal();
    },
    // 合計金額、合計仕入単価、粗利計算
    calcTotal: function() {
      this.totalFee = 0;
      this.totalPurchasePrime = 0;
      this.grossProfit = 0;
      for(let i = 0; i < this.productList.length;i++) {
        this.totalFee += this.productList[i].Money;
        this.totalPurchasePrime += this.productList[i].TotalPurchasePrime;
      }
      this.grossProfit = Math.round((this.totalFee - this.totalPurchasePrime) * 1000 / this.totalFee) / 10;
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = this.createNewRow();
      this.productList.push(newProduct);
    },
    // 行作成
    createNewRow: function() {
      let newProduct = {
        ProductCode: null,
        ProductClass: null,
        ProductName: '',
        ProductTaxRateClass: Const.ProductTaxRateClass.normalTax,
        SupplierCode: null,
        SupplierCodeRight: null,
        SupplierName: '',
        OrderQuantity: 0,
        StockScheduled: '0000/00/00',
        StockScheduledStaff: '',
        ServiceClass: '',
        Quantity: 0,
        Unit: '',
        SalesUnitPrice: 0,
        SellingPrice: 0,
        ClientAmountClass: '',
        PurchasePrime: 0,
        SundriesClass: null,
        InventoryClass: null,
        Place1: '',
        Place2: '',
        Place3: '',
        Place4: '',
        CaseConversion: Const.CaseConversionClassDef.conversion,
        TotalPurchasePrime: 0,
        Money: 0,
        SetFlg: 0,
        MinimumQuantity: 0,
        ReserveQuantity: 0,
        ShippingQuantity: 0,
        IsDeleted: 0,
        OrderId: 0,
        OrderRow: 0,
        OrderJokyo: Const.ReceivedOrderOrderJokyo.noStock,
        OrderJokyoText: '',
        ShipsClass: Const.ShipsClass.mi,
        ShipsClassText: '',
        SalesIssueClass: Const.SalesIssueClass.mi,
        SalesIssueClassText: '',
        SetList: [],
      };
      return newProduct;
    },
    // テーブル行削除イベント
    deleteBtn: function(rowNum) {
      //console.log(rowNum);
      if (this.productList.length > 1) {
        this.productList.splice(rowNum, 1);
        // 合計金額、合計仕入単価、粗利計算
        this.calcTotal();
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* 裏製品情報に追加 */
    addProductListHidden: function(productId, inventoryClass, quantity, reserveQuantityIncomplete, reserveQuantity, shippingQuantity) {
      let hdnProduct = {
        ProductId: productId,
        InventoryClass: inventoryClass,
        Quantity: quantity,
        ReserveQuantityIncomplete: reserveQuantityIncomplete,
        ReserveQuantity: reserveQuantity,
        ShippingQuantity: shippingQuantity,
        SubReserveQuantity: 0,
      };
      this.productListHidden.push(hdnProduct);
    },
    /* 発注状況を取得（const.jsの「ReceivedOrderOrderJokyoList」のidを返却） */
    getOrderJokyo: function(shippingTypeClass, orderReceiveBillClass, inventoryClass, orderIssueClass, stocksClass) {
      if (shippingTypeClass == Const.ShippingTypeClass.direct || orderReceiveBillClass == Const.OrderReceiveBillClass.special) {
        if (inventoryClass == Const.InventoryControlClassDef.inventory) {
          // 配送種別区分「4：直送」、または、伝種「15：特別受注」の場合
          if (orderIssueClass == 0) {
            // 発注未
            return Const.ReceivedOrderOrderJokyo.orderMi;
          } else if (stocksClass == Const.StocksClass.mi) {
            // 発注済み、入荷未
            return Const.ReceivedOrderOrderJokyo.orderZumi;
          } else {
            // 発注済み、入荷済み
            return Const.ReceivedOrderOrderJokyo.nyukaZumi;
          }
        } else {
          // 発注しない、在庫管理から引当もしない
          return Const.ReceivedOrderOrderJokyo.noStock;
        }
      } else {
        // 上記以外の場合、発注データとの紐づけ不要（在庫管理か在庫管理不要かを返却）
        if (inventoryClass == Const.InventoryControlClassDef.inventory) {
          // 在庫管理から引当
          return Const.ReceivedOrderOrderJokyo.stock;
        } else {
          // 発注しない、在庫管理から引当もしない
          return Const.ReceivedOrderOrderJokyo.noStock;
        }
      }
    },
    /* フィールド取得 */
    getFields: function() {
      if (this.selectDelivery != Const.ShippingTypeClass.direct) {
        return [
          {
            key: 'ProductCode',
            label: '製品コード',
          },
          {
            key: 'ProductName',
            label: '製品名',
          },
          {
            key: 'ServiceClass',
            label: 'サービス区分',
          },
          {
            key: 'Quantity',
            label: '数量',
          },
          {
            key: 'Unit',
            label: '単位',
          },
          {
            key: 'SalesUnitPrice',
            label: 'BM価格',
            tdClass: 'text-right',
          },
          {
            key: 'SellingPrice',
            label: '売価',
          },
          {
            key: 'PurchasePrime',
            label: '仕入単価',
          },
          {
            key: 'Money',
            label: '金額',
            tdClass: 'text-right',
          },
          {
            key: 'SupplierCode',
            label: '発注先コード',
          },
          {
            key: 'SupplierName',
            label: '発注先',
          },
          {
            key: 'OrderQuantity',
            label: '発注数',
            tdClass: 'text-right',
          },
          {
            key: 'StockScheduled',
            label: '入荷予定日',
          },
          {
            key: 'StockScheduledStaff',
            label: '入荷予定処理担当者',
          },
          {
            key: 'RemainingQuantity',
            label: '受注残',
            tdClass: 'text-right',
          },
          {
            key: 'ReserveQuantity',
            label: '引当数',
            tdClass: 'text-right',
          },
          {
            key: 'ShippingQuantity',
            label: '出荷数',
            tdClass: 'text-right',
          },
          {
            key: 'OrderJokyoText',
            label: '発注状況',
          },
          {
            key: 'ShipsClassText',
            label: '出荷確定',
          },
          {
            key: 'SalesIssueClassText',
            label: '伝票発行',
          },
          {
            key: 'delete',
            label: '削除',
          },
        ];
      } else {
        return [
          {
            key: 'ProductCode',
            label: '製品コード',
          },
          {
            key: 'ProductName',
            label: '製品名',
          },
          {
            key: 'ServiceClass',
            label: 'サービス区分',
          },
          {
            key: 'Quantity',
            label: '数量',
          },
          {
            key: 'Unit',
            label: '単位',
          },
          {
            key: 'SalesUnitPrice',
            label: 'BM価格',
            tdClass: 'text-right',
          },
          {
            key: 'SellingPrice',
            label: '売価',
          },
          {
            key: 'PurchasePrime',
            label: '仕入単価',
          },
          {
            key: 'Money',
            label: '金額',
            tdClass: 'text-right',
          },
          {
            key: 'SupplierCode',
            label: '発注先コード',
          },
          {
            key: 'SupplierName',
            label: '発注先',
          },
          {
            key: 'OrderQuantity',
            label: '発注数',
            tdClass: 'text-right',
          },
          {
            key: 'StockScheduled',
            label: '入荷予定日',
          },
          {
            key: 'StockScheduledStaff',
            label: '入荷予定処理担当者',
          },
          {
            key: 'RemainingQuantity',
            label: '受注残',
            tdClass: 'text-right',
          },
          {
            key: 'OrderJokyoText',
            label: '発注状況',
          },
          {
            key: 'ShipsClassText',
            label: '直送売上入力',
          },
          {
            key: 'delete',
            label: '削除',
          },
        ];
      }
    },
    /* 更新日取得用SELECT文作成 */
    async createUpdatedSelect() {
      let selectSql = '';
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(orders_receives.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' orders_receives.order_receive_id = ' + this.receivedOrderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(ships.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_ships AS ships ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' ships.order_receive_id = ' + this.receivedOrderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(stocks.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_stocks AS stocks ';
      selectSql += 'INNER JOIN t_orders AS orders ';
      selectSql += 'ON stocks.order_id = orders.order_id ';
      selectSql += 'AND stocks.order_row = orders.order_row ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' orders.order_receive_id = ' + this.receivedOrderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});
      let dataResult = [null, null, null];
      [dataResult[0], dataResult[1], dataResult[2]] = await Promise.all([
        executeSelectSql(this.listUpdatedSelect[0].select),
        executeSelectSql(this.listUpdatedSelect[1].select),
        executeSelectSql(this.listUpdatedSelect[2].select),
      ]);
      for (let i = 0; i < this.listUpdatedSelect.length; i++) {
        if (dataResult[i] != null && dataResult[i].length > 0) {
          this.listUpdatedSelect[i].initUpdated = formatDate(dataResult[i][0].updated, 'YYYY-MM-DD HH:mm:ss');
        } else {
          this.listUpdatedSelect[i].initUpdated = '0000-01-01 00:00:00';
        }
      }
    },
    // 一覧の指定行が空かどうかチェック
    isListBlankRow: function(index) {
      if (getNullStr(this.productList[index].ProductCode) == '') {
        return true;
      } else {
        return false;
      }
    },
    // 一覧の後ろの空行を削除
    delListLastBlankRow: function() {
      //console.log('空行削除開始');
      for (let i = this.productList.length - 1; i > 0; i--) {
        if (this.isListBlankRow(i) == true) {
          // 後ろにある空行は削除
          this.productList.splice(i, 1);
        } else {
          // 1件でも空行以外の行があった場合は削除処理終了
          break;
        }
      }
      // 最後に合計金額、合計仕入単価、粗利計算
      this.calcTotal();
      //console.log('空行削除終了');
    },
    // 全削除チェック
    checkAllDelete: function() {
      for (let i = 0; i < this.productList.length; i++) {
        if (this.productList[i].IsDeleted == 0) {
          // 1件でも削除されていない場合はOK
          return true;
        }
      }
      return false;
    },
    // 売価をリフレッシュ
    async refreshSalesAmount() {
      const functionName = 'refreshSalesAmount';
      if (getNullStr(this.dateOrderReceiveDate) != '') {
        this.$store.commit('setLoading', true);
        try {
          await refreshSalesAmountProductList(this.productList, this.suppliers.class, this.suppliers.code, this.dateOrderReceiveDate, 'ProductCode', true, 'SellingPrice', 'ClientAmountClass', true);
          for (let i = 0; i < this.productList.length; i++) {
            this.productList[i].Money = this.productList[i].Quantity * this.productList[i].SellingPrice;
            // 粗利額を確認して、警告の場合、背景色変更
            this.checkGrossProfit(this.productList[i], i);
          }
          // 合計金額、合計仕入単価、粗利
          this.calcTotal();
        } catch (error) {
          console.log(error);
          await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
          this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        }
        if (this.getMessageFlg == true) {
          scrollTo(0,0);
        }
        this.$store.commit('setLoading', false);
      }
    },
    // サービス変更
    async changeService(index) {
      const functionName = 'changeService';
      try {
        if (this.productList[index].ServiceClass != '') {
          // サービス区分が空白以外
          this.productList[index].SellingPrice = 0;
          this.productList[index].Money = 0;
        } else {
          // サービス区分が空白
          if (getNullStr(this.dateOrderReceiveDate) != '') {
            // 受注日付が設定されている場合のみ
            if (getNullStr(this.productList[index].ProductCode) != '' && isNaN(getNullStr(this.productList[index].ProductCode)) == false) {
              // 製品コードが数値の場合のみ
              this.$store.commit('setLoading', true);
              // マスタに登録されている製品の売価を取得
              let selectSqlQuery = 'SELECT';
              selectSqlQuery += ' product_id';
              selectSqlQuery += ',sales_unit_price';
              selectSqlQuery += ' FROM ';
              selectSqlQuery += 'm_clients_products ';
              selectSqlQuery += ' WHERE ';
              selectSqlQuery += 'client_class = ' + this.suppliers.class + ' ';
              selectSqlQuery += 'AND client_id = ' + this.suppliers.code + ' ';
              selectSqlQuery += 'AND product_id = ' + this.productList[index].ProductCode + ' ';
              selectSqlQuery += 'AND unit_price_effective_date <= \'' + this.dateOrderReceiveDate + '\' ';
              selectSqlQuery += 'ORDER BY unit_price_effective_date DESC ';
              selectSqlQuery += 'LIMIT 1 ';
              // SELECT文
              let selectSql = 'SELECT';
              selectSql += ' products.product_id';
              selectSql += ',IfNull(clients_products_QUERY.sales_unit_price,products.sales_unit_price) AS sales_unit_price';
              selectSql += ' FROM ';
              selectSql += 'm_products AS products ';
              selectSql += 'LEFT JOIN (' + selectSqlQuery + ') AS clients_products_QUERY ';
              selectSql += 'ON clients_products_QUERY.product_id = products.product_id ';
              selectSql += ' WHERE ';
              selectSql += 'products.product_id = ' + this.productList[index].ProductCode + ' ';
              //console.log(selectSql);
              let resultData = await executeSelectSql(selectSql);
              //console.log(resultData);
              if (resultData != null) {
                this.productList[index].SellingPrice = resultData[0].sales_unit_price;
                this.productList[index].Money = this.productList[index].Quantity * this.productList[index].SellingPrice;
              }
            }
          }
        }
        // 粗利額を確認して、警告の場合、背景色変更
        this.checkGrossProfit(this.productList[index], index);
        // 合計金額、合計仕入単価、粗利
        this.calcTotal();
      } catch (error) {
        console.log(error);
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 配送コードの小文字を大文字に置換
    toUpperShippingCode: function() {
      if (getNullStr(this.onSite.deliveryCode) != '') {
        this.onSite.deliveryCode = this.onSite.deliveryCode.toUpperCase();
      }
    },
    // 現場新規登録ボタン押下時
    clickSiteInput: function() {
      let route = this.$router.resolve({ name: 'SITE-INPUT' });
      window.open(route.href, '_blank');
    },
    // フォーカス設定
    setFocus: function(elementId) {
      document.getElementById(elementId).focus();
      document.getElementById(elementId).select();
    },
    // 日付と締日から締切日（YYYY/MM/DD）を取得
    setClosingYmd: function(closingDay) {
      let monthYear = getBillingMonthYear(this.inputDate, closingDay);
      this.suppliers.closingDate = getClosingDate(monthYear, closingDay, false);
    },
    // 粗利（売価-仕入単価）を確認して、マイナスの場合は背景色を赤色に変更
    checkGrossProfit: function(item, index) {
      let rowVariant = '';
      if (item.PurchasePrime > item.SellingPrice) {
        // 仕入単価が売価よりも大きい場合
        rowVariant = 'danger';
      }
      if (item._rowVariant != rowVariant) {
        // 背景色を変更する場合
        item._rowVariant = rowVariant;
        this.productList.splice(index, 1, item);
      }
    },
    // 通常配送時の配送コードチェック
    checkNormalDeliveryRoutes: function() {
      if (this.selectDelivery == Const.ShippingTypeClass.normal) {
        // 配送「1：通常配送」の場合
        // 数値4桁の場合はNGとする
        let regex = /^[0-9]{4}$/;
        let retRegex = regex.test(this.onSite.deliveryCode);
        if (retRegex == true) {
          return false;
        }
        // 「X-」の場合はNGとする
        if (this.onSite.deliveryCode == 'X-') {
          return false;
        }
      }
      return true;
    },
  }
}
</script>
<style scoped>
</style>