<template>
  <!-- 現場保守照会画面 -->
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <div class="container-fluid px-4 py-4 min-vh-85">
      <div class="row d-flex justify-content-center mt-2 mb-2">
        <div class="col-md-12">
          <div class="media">
            <div class="media-body pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 現場保守照会</strong></h5>
                <router-link to="/site-maintenance" class="btn btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </router-link>
              </div>
            </div>
          </div>
          <div class="main-card mb-3 card">
            <div class="card-header">
              <div class="alert alert-danger" role="alert" v-if="errorMessages.length">
                <ul v-for="(message, index) in errorMessages" :key="index" style="list-style: none;">
                  <li>{{ message }}</li>
                </ul>
              </div>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <!-- 取引先コード -->
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 form-group">
                  <label for="clientId" class="form-label"><strong>取引先コード</strong></label>
                  <input type="text" id="clientId" class="form-control" v-model="clientId" readonly>
                </div>
                <!-- 取引先名 -->
                <div class="col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-10 form-group">
                  <label for="clientName" class="form-label"><strong>取引先コード</strong></label>
                  <input type="text" id="clientName" class="form-control" v-model="clientName" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 現場コード -->
                <div class="col form-group">
                  <label for="siteId" class="form-label"><strong>現場コード</strong></label>
                  <input type="text" id="siteId" name="siteId" class="form-control col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2" v-model="siteId" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 配送コード -->
                <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2 form-group">
                  <label for="shippingCode" class="form-label"><strong>配送コード</strong></label>
                  <input type="text" id="shippingCode" class="form-control" v-model="shippingCode" readonly>
                </div>
                <!-- 配送名 -->
                <div class="col-sm-12 col-md-8 col-lg-8 col-xl-9 col-xxl-10 form-group">
                  <label for="shippingName" class="form-label"><strong>配送コード</strong></label>
                  <input type="text" id="shippingName" class="form-control" v-model="shippingName" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 現場名（漢字） -->
                <div class="col form-group">
                  <label for="clientSiteNameKanji" class="form-label"><strong>現場名（漢字）</strong></label>
                  <input type="text" id="clientSiteNameKanji" class="form-control" v-model="clientSiteNameKanji" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 現場名（カナ） -->
                <div class="col form-group">
                  <label for="clientSiteNameKana" class="form-label"><strong>現場名（カナ）</strong></label>
                  <input type="text" id="clientSiteNameKana" class="form-control" v-model="clientSiteNameKana" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 現場郵便番号 -->
                <div class="col form-group">
                  <label for="clientSiteZipCode" class="form-label"><strong>現場郵便番号</strong></label>
                  <input type="text" id="clientSiteZipCode" class="form-control col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2" v-model="clientSiteZipCode" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 現場住所１ -->
                <div class="col form-group">
                  <label for="clientSiteAddress1" class="form-label"><strong>現場住所１</strong></label>
                  <input type="text" id="clientSiteAddress1" class="form-control" v-model="clientSiteAddress1" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 現場住所２ -->
                <div class="col form-group">
                  <label for="clientSiteAddress2" class="form-label"><strong>現場住所２</strong></label>
                  <input type="text" id="clientSiteAddress2" class="form-control" v-model="clientSiteAddress2" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 納品場所 -->
                <div class="col form-group">
                  <label for="deliveryLocation" class="form-label"><strong>納品場所</strong></label>
                  <input type="text" id="deliveryLocation" class="form-control" v-model="deliveryLocation" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- TEL -->
                <div class="col form-group">
                  <label for="clientSitePhoneNumber" class="form-label"><strong>TEL</strong></label>
                  <input type="text" id="clientSitePhoneNumber" class="form-control col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-2" v-model="clientSitePhoneNumber" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 発注書印字区分 -->
                <div class="col form-group col-12 col-md-3">
                  <label for="orderPrintClass" class="form-label"><strong>発注書印字区分</strong></label>
                  <input type="text" id="orderPrintClass" class="form-control" v-model="orderPrintClass" readonly>
                </div>
                <!-- 発注書用取引先名 -->
                <div class="col form-group col-12 col-md-9">
                  <label for="text-input" class="form-label"><strong>発注書用取引先名（※仮伝宛名）</strong></label>
                  <input type="text" id="orderPrintClientName" class="form-control" v-model="orderPrintClientName" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- 請求書印字区分 -->
                <div class="col form-group col-12 col-md-3">
                  <label for="billingsPrintClass" class="form-label"><strong>請求書印字区分</strong></label>
                  <input type="text" id="billingsPrintClass" class="form-control" v-model="billingsPrintClass" readonly>
                </div>
                <!-- 請求書用取引先名 -->
                <div class="col form-group col-12 col-md-9">
                  <label for="billingsPrintClientName" class="form-label"><strong>請求書用取引先名</strong></label>
                  <input type="text" id="billingsPrintClientName" class="form-control" v-model="billingsPrintClientName" readonly>
                </div>
              </div>
              <div class="row mt-2">
                <!-- アストコード -->
                <div class="col form-group col-12 col-md-4">
                  <label for="astId" class="form-label"><strong>アストコード</strong></label>
                  <input type="text" id="astId" class="form-control" v-model="astId" readonly>
                </div>
                <!-- 相手先現場コード -->
                <div class="col form-group col-12 col-md-4">
                  <label for="clientControlSiteId" class="form-label"><strong>相手先現場コード</strong></label>
                  <input type="text" id="clientControlSiteId" class="form-control" v-model="clientControlSiteId" readonly>
                </div>
                <!-- 所管部支店 -->
                <div class="col form-group col-12 col-md-4">
                  <label for="text-input" class="form-label"><strong>所管部支店</strong></label>
                  <input type="text" class="form-control" v-model="departmentBranch" readonly>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ●●●フッター●●● -->
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { addOperationLogs, init, getNullStr, selectOneTable } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

// ログ出力用モジュール名
const MODULE_NAME = 'site-inquiry';

export default {
  name: 'SITE-INPUT',
  /** コンポーネント */
  components: {
    Header,
    Footer
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '現場保守照会',
      // 取引先コード
      clientId: '',
      // 取引先名
      clientName: '',
      // 現場コード
      siteId: '',
      // 配送コード
      shippingCode: '',
      // 配送名
      shippingName: '',
      // 現場名（漢字）
      clientSiteNameKanji: '',
      // 現場名（カナ）
      clientSiteNameKana: '',
      // 現場郵便番号
      clientSiteZipCode: '',
      // 現場住所１
      clientSiteAddress1: '',
      // 現場住所２
      clientSiteAddress2: '',
      // 納品場所
      deliveryLocation: '',
      // TEL
      clientSitePhoneNumber: '',
      // 発注書印字区分
      orderPrintClass: '',
      // 発注書用取引先名
      orderPrintClientName: '',
      // 請求書印字区分
      billingsPrintClass: '',
      // 請求書用取引先名
      billingsPrintClientName: '',
      // アストコード
      astId: '',
      // 相手先現場コード
      clientControlSiteId: '',
      // 所管部支店
      departmentBranch: '',
      // 処理結果エラーメッセージ
      errorMessages: []
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    scrollTo(0,0);
    await this.fetchData(this.$route.query.clientId, this.$route.query.siteId);
    this.$store.commit('setLoading', false);
  },
  methods:{
    /**
     * 画面に表示するデータを取得します。
     * @param {String} clientId - 取引先コード
     * @param {String} siteId - 現場コード
     * @returns {Boolean} 取得できた場合は true、そうでない場合は false を返します。
     */
    async fetchData(clientId, siteId) {
      // 現場マスタ検索
      const site = await this.searchClientsSites(clientId, siteId);
      if (site === null) {
        this.errorMessages.push(DISP_MESSAGES.WARNING['2003']);
        return;
      }

      // 取引先マスタ検索
      const client = await this.searchClients(clientId);

      // ルートマスタ検索
      let route = null;
      if (site.shipping_code !== '') {
        route = await this.searchRoutes(site.shipping_code);
      }

      // 取引先ID
      this.clientId = clientId;
      // 取引先名
      this.clientName = (client === null) ? '取引先マスタに該当なし' : client.client_name_kanji;
      // 配送コード
      this.shippingCode = site.shipping_code;
      // 配送名（移行前データは、' 'の場合あり）
      if (getNullStr(this.shippingCode).trim() !== '') {
        this.shippingName = (route === null) ? 'ルートマスタに該当なし' : route.shipping_name;
      }
      // 現場コード
      this.siteId = siteId;
      // 現場名（漢字）
      this.clientSiteNameKanji = site.client_site_name_kanji;
      // 現場名（カナ）
      this.clientSiteNameKana = site.client_site_name_kana;
      // 現場郵便番号
      this.clientSiteZipCode = site.client_site_zip_code;
      // 現場住所１
      this.clientSiteAddress1 = site.client_site_address_1;
      // 現場住所２
      this.clientSiteAddress2 = site.client_site_address_2;
      // 納品場所
      this.deliveryLocation = site.delivery_location;
      // TEL
      this.clientSitePhoneNumber = site.client_site_phone_number;
      // 発注書印字区分
      const orderPrintClass = String(site.order_print_class);
      this.orderPrintClass = Const.OrderPrintClassList.find(item => item.value === orderPrintClass).text;
      // 発注書用取引先名
      this.orderPrintClientName = site.order_print_client_name;
      // 請求書印字区分
      const billingsPrintClass = String(site.billings_print_class);
      this.billingsPrintClass = Const.BillingPrintClassList.find(item => item.value === billingsPrintClass).text;
      // 請求書用取引先名
      this.billingsPrintClientName = site.billings_print_client_name;
      // アストコード
      this.astId = site.ast_id;
      // 相手先現場コード
      this.clientControlSiteId = site.client_control_site_id;
      // 所管部支店
      this.departmentBranch = site.department_branch;
    },
    /**
     * 現場マスタを検索します。
     * @param {String} clientId - 取引先コード
     * @param {String} siteId - 現場コード
     * @returns {Object} 現場マスタデータ
     */
    async searchClientsSites(clientId, siteId) {
      const functionName = 'searchClientsSites';

      let result = null;
      const whereClause = `AND client_id = ${clientId} AND site_id = ${siteId}`;
      try {
        result = await selectOneTable('m_clients_sites', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients_sites',
          where_clause: whereClause
        }, error);
        return null;
      }
      if (result != null && result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    },
    /**
     * 取引先マスタを検索します。
     * @param {String} clientId - 取引先コード
     * @returns {Object} 取引先マスタデータ
     */
    async searchClients(clientId) {
      const functionName = 'searchClients';

      let result = null;
      const whereClause = `AND client_id = ${clientId} AND client_class = 1 ORDER BY client_id ASC`;
      try {
        result = await selectOneTable('m_clients', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_clients',
          where_clause: whereClause
        }, error);
        return null;
      }
      if (result != null && result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    },
    /**
     * ルートマスタを検索します。
     * @param {String} shipping_code - 配送コード
     * @returns {Object} ルートマスタデータ
     */
    async searchRoutes(shipping_code) {
      const functionName = 'searchRoutes';

      const whereClause = `AND shipping_code = '${shipping_code}' ORDER BY updated DESC`;
      let result = null;
      try {
        result = await selectOneTable('m_routes', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_routes',
          where_clause: whereClause
        }, error);
        return null;
      }
      if (result != null && result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    }
  }
}
</script>
