<template>
<div>
  <Header :type="menu_type" :title="title" />
  <b-container class="px-4 py-4 min-vh-85" fluid>
    <b-row class="d-flex justify-content-center mt-2 mb-2">
      <b-col class="md-12">
        <b-card>
          <b-card-header v-if="getMessageFlg">
            <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
              <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
              <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
            <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
              <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                <li>{{error}}</li>
              </ul>
            </b-alert>
          </b-card-header>
          <b-card-body>
            <b-card-title>
              <h5 v-if="monthYear!='' && updateFlg==false && missMonthYearFlg==false">
                他のジョブが終了しているのを確認してください。<br/>
                {{monthYear}} 分の請求締更新を行います。<br/>
                以下の条件でよろしいですか？
              </h5>
              <h5 v-if="updateFlg==false && missMonthYearFlg==true">
                以下の条件は現在処理年月と異なるため締更新は不可能です。<br/>
                請求前処理（本社）で条件を変更してください。
              </h5>
              <h5 v-if="updateFlg==true">
                以下の条件の請求締更新は実施済みです。<br/>
                請求前処理（本社）で条件を変更してください。
              </h5>
            </b-card-title>
            <b-container>
              <b-row>
                <b-col lg="6">
                  <b-table
                    show-empty
                    empty-text="データが登録されていません。請求前処理（本社）画面で登録できます。"
                    :fields="fields"
                    :items="results"
                  >
                  </b-table>
                </b-col>
              </b-row>
            </b-container>
          </b-card-body>
          <!-- 更新ボタン -->
          <b-card-footer>
            <b-row class="justify-content-md-center pb-4">
              <b-col lg="2">
                <b-button pill block variant="success" v-b-tooltip.hover title="表示中の締日、締切日の請求書の締更新を行います。" @click="clearAlert(); clickUpdateBtn();" :disabled="results.length==0||updateFlg==true||missMonthYearFlg==true">
                  <span class="oi oi-circle-check"></span> 更新
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <Footer />
  <!-- ●●●確認モーダル●●● -->
  <CONFIRM @from-child="closeConfirmModal" :confirmMessage="['表示中の条件で請求締更新を行います。','よろしいですか？']" />
</div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, formatDate, formatDateCalc, executeSelectSql, addOperationLogs, CreateInsertSql, CreateUpdateSql, CreateMergeSql, CreateColRow, executeTransactSqlList, isSystemEditable, getControlMaster, getClosingDate, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'invoice-update';

export default ({
  name: 'INVOICE-UPDATE',
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '請求締更新処理',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 検索結果
      results: [],
      monthYear: '',
      billingMonthYear: '',
      updateFlg: false,
      missMonthYearFlg: false,
      // ログイン情報
      loginId: '',
      // トランザクションSQLリスト
      transactSqlList: [],
      // 更新得意先請求残一覧
      updateRemainingsList: [],
      // 画面起動時の請求前設定範囲
      closingUpdateRange: {
        process_month_year: null,
        closing_date: null,
        client_id_start: null,
        client_id_end: null,
      },
      // 請求前情報
      preBilling: {
        processMonthYear: 0,
        closingDate: 0,
        billingStartDate: '',
        billingEndDate: '',
      },
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
      },
    }
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /** フィールド */
    fields() {
      return [
        {
          key    : 'invoiceCount',
          label  : '件数',
        },
        {
          key     : 'closingDate',
          label   : '締日',
        },
        {
          key     : 'deadlineDate',
          label   : '締切日',
        },
      ];
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // ログインユーザーの情報(LoginID)を保持
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 初期データを設定
        await this.setInitData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 初期データ設定 */
    async setInitData() {
      // 最初に初期化
      this.monthYear = '';
      this.billingMonthYear = '';
      this.updateFlg = false;
      this.results = [];
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      let dataResult = null;
      let controlData = null;
      [dataResult, controlData] = await Promise.all([
        executeSelectSql(selectSql),
        getControlMaster(),
      ]);
      //console.log(dataResult);
      if(dataResult != null){
        await this.setResult(dataResult, controlData);
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' (SELECT COUNT(*) FROM m_billings) AS billings_cnt';
      selectSql += ',closing_update_range.closing_date';
      selectSql += ',closing_update_range.process_month_year';
      selectSql += ',closing_update_range.client_id_start';
      selectSql += ',closing_update_range.client_id_end';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_closing_update_range AS closing_update_range ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += 'closing_update_range.billing_payment_class = ' + Const.BillingPaymentClass.billing + ' ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result, controlData) {
      if (result.length > 0) {
        let deadlineDate = null;
        if (result[0].closing_date == 99) {
          deadlineDate = formatDateCalc(('000000' + result[0].process_month_year).slice(-6) + '01', 0, 0, 0, true);
        } else {
          deadlineDate = formatDate(('000000' + result[0].process_month_year).slice(-6) + ('00' + result[0].closing_date).slice(-2));
        }
        let searchResult = {
          invoiceCount: result[0].billings_cnt.toLocaleString(),
          closingDate: result[0].closing_date,
          deadlineDate: deadlineDate,
        };
        this.results.push(searchResult);
        // 対象の年月を設定
        this.monthYear = formatDate(deadlineDate, 'YYYY/MM');
        this.billingMonthYear = formatDate(deadlineDate, 'YYYY-MM');
        // 画面起動時の請求前処理の設定範囲を保持
        this.closingUpdateRange.process_month_year = result[0].process_month_year;
        this.closingUpdateRange.closing_date = result[0].closing_date;
        this.closingUpdateRange.client_id_start = result[0].client_id_start;
        this.closingUpdateRange.client_id_end = result[0].client_id_end;
        // 更新済みかどうかの値を設定
        this.updateFlg = await this.checkUpdateCompletion(result[0].process_month_year, result[0].closing_date, result[0].client_id_start, result[0].client_id_end);
        // 現在処理年月と等しいかどうかの値を設定
        this.missMonthYearFlg = result[0].process_month_year != controlData.process_month_year;
      }
    },
    // 更新完了チェック
    async checkUpdateCompletion(processMonthYear, closingDate, clientIdStart, clientIdEnd) {
      let where_clause = '';
      where_clause += 'AND billing_payment_class = ' + Const.BillingPaymentClass.billing + ' ';
      where_clause += 'AND process_month_year = ' + processMonthYear + ' ';
      where_clause += 'AND closing_date = ' + closingDate + ' ';
      where_clause += 'ORDER BY client_id_start ';
      let resultData = await selectOneTable('t_closing_update_completion', where_clause);
      //console.log(resultData);
      if (resultData == null || resultData.length == 0) {
        // 指定した年月と締日でまだ締更新を行っていない
        return false;
      }
      // 得意先コードが重複しないかを確認するループ
      for (let i = 0; i < resultData.length; i++) {
        if ((resultData[i].client_id_start <= clientIdStart && clientIdStart <= resultData[i].client_id_end) ||
            (resultData[i].client_id_start <= clientIdEnd && clientIdEnd <= resultData[i].client_id_end) ||
            (clientIdStart <= resultData[i].client_id_start && resultData[i].client_id_start <= clientIdEnd) ||
            (clientIdStart <= resultData[i].client_id_end && resultData[i].client_id_end <= clientIdEnd)) {
          // 締更新済みの取引先コードと重複している部分あり
          return true;
        }
      }

      // 締更新済みの取引先コードと重複している部分なし
      return false;
    },
    /* 更新ボタン押下時 */
    async clickUpdateBtn() {
      //console.log('更新');
      this.$bvModal.show('confirmModal');
    },
    // 更新確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          // 保存処理
          this.$store.commit('setLoading', true);
          if (await this.checkClosingUpdateRange() == true) {
            // 対象データ取得
            await this.getBillingDateInfo();
            // 締日売上伝票枚数を設定
            if (await this.setClosingDateSalesBillingCnt() == false) {
              this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
              scrollTo(0,0);
              this.$store.commit('setLoading', false);
              return;
            }
            //console.log('更新処理開始');
            if (await this.execUpdate() == true) {
              this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1003']);
              await this.setInitData();
            }
            //console.log('更新処理終了');
          } else {
            this.alertWarning.push(DISP_MESSAGES.WARNING['2032']);
          }
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 締更新範囲確認
    async checkClosingUpdateRange() {
      // 検索条件作成
      let where_clause = '';
      where_clause += 'AND billing_payment_class = ' + Const.BillingPaymentClass.billing + ' ';
      //console.log(where_clause);
      let dataResult = await selectOneTable('t_closing_update_range', where_clause);
      //console.log(dataResult);
      if (dataResult != null && dataResult.length > 0) {
        if (this.closingUpdateRange.process_month_year == dataResult[0].process_month_year &&
            this.closingUpdateRange.closing_date == dataResult[0].closing_date &&
            this.closingUpdateRange.client_id_start == dataResult[0].client_id_start &&
            this.closingUpdateRange.client_id_end == dataResult[0].client_id_end) {
          if (await this.checkUpdateCompletion(dataResult[0].process_month_year, dataResult[0].closing_date, dataResult[0].client_id_start, dataResult[0].client_id_end) == false) {
            return true;
          } else {
            return false;
          }
        } else {
          // 画面起動時と請求前の範囲が異なる場合はNG
          return false;
        }
      } else {
        return false;
      }
    },
    // 締日売上伝票枚数を設定
    async setClosingDateSalesBillingCnt() {
      const functionName = 'setClosingDateSalesBillingCnt';
      let sqlList = [];
      let updateSql = '';
      let colList = [];
      // 締日売上伝票枚数
      colList.push(CreateColRow('billings.closing_date_sales_billing_cnt', 'billings_QUERY.billing_cnt', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('billings.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('billings.updated_user', this.loginId, 'VARCHAR'));
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' client_id';
      updateQuery += ',0 AS site_id';
      updateQuery += ',COUNT(*) AS billing_cnt';
      updateQuery += ' FROM ';
      updateQuery += '(';
      updateQuery += 'SELECT';
      updateQuery += ' billings.client_id';
      updateQuery += ',billings.site_id';
      updateQuery += ',cumulative_transaction.billing_no';
      updateQuery += ' FROM ';
      updateQuery += 'm_billings AS billings ';
      updateQuery += 'INNER JOIN (SELECT billing_no,client_id,site_id FROM t_cumulative_transaction ';
      updateQuery += ' WHERE ';
      updateQuery += 'transaction_id = \'' + Const.TransactionId.sales + '\' ';
      updateQuery += 'AND billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      updateQuery += 'AND client_class = ' + Const.ClientClass.customer + ' ';
      updateQuery += 'AND client_id BETWEEN ' + this.closingUpdateRange.client_id_start + ' AND ' + this.closingUpdateRange.client_id_end + ' ';
      updateQuery += 'AND is_update_closing_date = 0 ';
      updateQuery += 'AND closing_month_year = 0 ';
      updateQuery += ') AS cumulative_transaction ';
      updateQuery += 'ON billings.client_id = cumulative_transaction.client_id ';
      updateQuery += 'AND billings.site_id = cumulative_transaction.site_id ';
      updateQuery += 'GROUP BY billings.client_id,billings.site_id,cumulative_transaction.billing_no ';
      updateQuery += ') AS billing_no_01 ';
      updateQuery += 'GROUP BY client_id ';
      updateQuery += ' UNION ALL ';
      updateQuery += 'SELECT';
      updateQuery += ' client_id';
      updateQuery += ',site_id';
      updateQuery += ',COUNT(*) AS billing_cnt';
      updateQuery += ' FROM ';
      updateQuery += '(';
      updateQuery += 'SELECT';
      updateQuery += ' billings.client_id';
      updateQuery += ',billings.site_id';
      updateQuery += ',cumulative_transaction.billing_no';
      updateQuery += ' FROM ';
      updateQuery += 'm_billings AS billings ';
      updateQuery += 'INNER JOIN (SELECT billing_no,client_id,site_id FROM t_cumulative_transaction ';
      updateQuery += ' WHERE ';
      updateQuery += 'transaction_id = \'' + Const.TransactionId.sales + '\' ';
      updateQuery += 'AND billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      updateQuery += 'AND client_class = ' + Const.ClientClass.customer + ' ';
      updateQuery += 'AND client_id BETWEEN ' + this.closingUpdateRange.client_id_start + ' AND ' + this.closingUpdateRange.client_id_end + ' ';
      updateQuery += 'AND is_update_closing_date = 0 ';
      updateQuery += 'AND closing_month_year = 0 ';
      updateQuery += ') AS cumulative_transaction ';
      updateQuery += 'ON billings.client_id = cumulative_transaction.client_id ';
      updateQuery += 'AND billings.site_id = cumulative_transaction.site_id ';
      updateQuery += ' WHERE ';
      updateQuery += 'billings.site_id <> 0 ';
      updateQuery += 'GROUP BY billings.client_id,billings.site_id,cumulative_transaction.billing_no ';
      updateQuery += ') AS billing_no_02 ';
      updateQuery += 'GROUP BY client_id,site_id ';
      updateQuery += ') AS billings_QUERY ';
      updateSql += CreateUpdateSql(colList, 'm_billings AS billings', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'billings.client_id = billings_QUERY.client_id ';
      updateSql += 'AND billings.site_id = billings_QUERY.site_id ';
      //console.log(updateSql);
      sqlList.push(updateSql);

      //console.time('setClosingDateSalesBillingCnt');
      let retUpdate = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      //console.timeEnd('setClosingDateSalesBillingCnt');

      return retUpdate;
    },
    /* 更新処理 */
    async execUpdate() {
      const functionName = 'execUpdate';
      let retResult = false;
      this.transactSqlList = [];
      this.updateRemainingsList = [];
      // 請求残高UPDATESQL作成処理（当月）
      await this.createBillingBalancesCurMonthUpdateSql();
      // 請求残高INSERTSQL作成処理（当月）
      await this.createBillingBalancesCurMonthInsertSql();
      // 請求残高MERGESQL作成処理（当月）
      this.createSeparateBillingBalancesCurMonthMergeSql();
      // 請求残高INSERTSQL作成処理（来月）
      await this.createBillingBalancesNextMonthInsertSql();
      // 累積トランザクションUPDATESQL作成処理
      await this.createCumulativeTransactionUpdateSql();
      // 取引先保守UPDATESQL作成処理
      await this.createClientsUpdateSql();
      // 過去の得意先請求残更新用情報作成
      await this.createUpdateRemainingsList();
      // 得意先請求残INSERTSQL作成処理（入金無し得意先）
      await this.createInsertBillingsRemainingsNoDeposit();
      // 得意先請求残MERGESQL作成処理（入金あり得意先、過去月の更新）
      await this.createMergeBillingsRemainings();
      // 得意先請求残INSERTSQL作成処理（入金あり得意先、最新月の登録）
      await this.createInsertBillingsRemainingsDeposit();
      // 得意先請求残DELETESQL作成処理
      await this.createDeleteBillingsRemainings();
      // 締更新完了登録SQL作成
      await this.insertClosingUpdateCompletion();

      //console.log(this.transactSqlList);

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable(1);
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }

      //console.time('execUpdate');
      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(this.transactSqlList, MODULE_NAME, functionName) == false) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return retResult;
      }
      //console.timeEnd('execUpdate');
      retResult = true;

      return retResult;
    },
    /* 請求残高INSERTSQL作成処理（当月） */
    async createBillingBalancesCurMonthInsertSql() {
      //console.log('請求残高INSERTSQL作成処理（当月）');
      // CRUD処理
      // 登録SQLを作成（INSERT文の前部分のため設定値不要）
      let colList = [];
      // 請求年月
      colList.push(CreateColRow('billing_month_year', null, 'NUMBER'));
      // 得意先コード
      colList.push(CreateColRow('client_id', null, 'NUMBER'));
      // 現場コード
      colList.push(CreateColRow('site_id', null, 'NUMBER'));
      // 営業所コード
      colList.push(CreateColRow('office_id', null, 'NUMBER'));
      // 得意先分類コード
      colList.push(CreateColRow('client_id_first_digit', null, 'NUMBER'));
      // 請求開始日
      colList.push(CreateColRow('billing_start_date', null, 'DATE'));
      // 請求締切日
      colList.push(CreateColRow('billing_end_date', null, 'DATE'));
      // 前回請求税抜残高
      colList.push(CreateColRow('pre_billing_no_tax_balance', null, 'NUMBER'));
      // 前回請求消費税残高
      colList.push(CreateColRow('pre_billing_tax_balance', null, 'NUMBER'));
      // 締日売掛売上
      colList.push(CreateColRow('closing_date_receivable_sales', null, 'NUMBER'));
      // 締日売上伝票枚数
      colList.push(CreateColRow('closing_date_sales_billing_cnt', null, 'NUMBER'));
      // 締日一括消費税額
      colList.push(CreateColRow('closing_date_bulk_tax', null, 'NUMBER'));
      // 締日伝票消費税額
      colList.push(CreateColRow('closing_date_billing_tax', null, 'NUMBER'));
      // 締日消費税額
      colList.push(CreateColRow('closing_date_tax', null, 'NUMBER'));
      // 締日入金額
      colList.push(CreateColRow('closing_date_payment', null, 'NUMBER'));
      // 締日消費税入金
      colList.push(CreateColRow('closing_date_tax_payment', null, 'NUMBER'));
      // 締日繰越税抜額
      colList.push(CreateColRow('closing_date_forward_no_tax', null, 'NUMBER'));
      // 締日繰越消費税額
      colList.push(CreateColRow('closing_date_forward_tax', null, 'NUMBER'));
      // 締日請求税抜額
      colList.push(CreateColRow('closing_date_billing_money_no_tax', null, 'NUMBER'));
      // 締日請求消費税額
      colList.push(CreateColRow('closing_date_billing_money_tax', null, 'NUMBER'));
      // 2回前請求税抜額
      colList.push(CreateColRow('two_pre_billing_money_no_tax', null, 'NUMBER'));
      // 2回前請求消費税額
      colList.push(CreateColRow('two_pre_billing_money_tax', null, 'NUMBER'));
      // 3回前請求税抜額
      colList.push(CreateColRow('three_pre_billing_money_no_tax', null, 'NUMBER'));
      // 3回前請求消費税額
      colList.push(CreateColRow('three_pre_billing_money_tax', null, 'NUMBER'));
      // 入金予定日
      colList.push(CreateColRow('payment_scheduled', null, 'DATE'));
      // 請求書出力単位区分
      colList.push(CreateColRow('billing_output_class', null, 'NUMBER'));
      // 締日通常消費税課税小計
      colList.push(CreateColRow('closing_date_normal_tax_subtotal', null, 'NUMBER'));
      // 締日通常消費税額
      colList.push(CreateColRow('closing_date_normal_tax', null, 'NUMBER'));
      // 締日軽減消費税課税小計
      colList.push(CreateColRow('closing_date_light_tax_subtotal', null, 'NUMBER'));
      // 締日軽減消費税額
      colList.push(CreateColRow('closing_date_light_tax', null, 'NUMBER'));
      // 締日売掛売上_先行除外
      colList.push(CreateColRow('closing_date_receivable_sales_pre_exclude', null, 'NUMBER'));
      // 締日通常消費税課税小計_先行除外
      colList.push(CreateColRow('closing_date_normal_tax_subtotal_pre_exclude', null, 'NUMBER'));
      // 締日通常消費税額_先行除外
      colList.push(CreateColRow('closing_date_normal_tax_pre_exclude', null, 'NUMBER'));
      // 締日軽減消費税課税小計_先行除外
      colList.push(CreateColRow('closing_date_light_tax_subtotal_pre_exclude', null, 'NUMBER'));
      // 締日軽減消費税額_先行除外
      colList.push(CreateColRow('closing_date_light_tax_pre_exclude', null, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      let insertSqlStart = 'INSERT INTO t_billings_balances (' + CreateInsertSql(colList, 'col', 't_billings_balances') + ') ';
      // INSERT用SELECT文
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' AS billing_month_year';
      selectSql += ',billings_QUERY.client_id';
      selectSql += ',billings_QUERY.site_id'; // 現場コード
      selectSql += ',0 AS office_id'; // 営業所コード
      selectSql += ',billings_QUERY.client_id_first_digit';
      selectSql += ',billings_QUERY.billing_start_date';
      selectSql += ',billings_QUERY.billing_end_date';
      selectSql += ',0 AS pre_billing_no_tax_balance';
      selectSql += ',0 AS pre_billing_tax_balance';
      selectSql += ',billings_QUERY.closing_date_receivable_sales';
      selectSql += ',billings.closing_date_sales_billing_cnt';
      selectSql += ',billings_QUERY.closing_date_normal_tax + billings_QUERY.closing_date_light_tax AS closing_date_bulk_tax';
      selectSql += ',0 AS closing_date_billing_tax';
      selectSql += ',billings_QUERY.closing_date_normal_tax + billings_QUERY.closing_date_light_tax AS closing_date_tax';
      selectSql += ',billings_QUERY.closing_date_payment';
      selectSql += ',billings_QUERY.closing_date_tax_payment';
      selectSql += ',CASE WHEN billings_QUERY.site_id = 0 THEN 0 - billings_QUERY.closing_date_payment ELSE 0 END AS closing_date_forward_no_tax';
      selectSql += ',CASE WHEN billings_QUERY.site_id = 0 THEN 0 - billings_QUERY.closing_date_tax_payment ELSE 0 END AS closing_date_forward_tax';
      selectSql += ',(CASE WHEN billings_QUERY.site_id = 0 THEN 0 - billings_QUERY.closing_date_payment ELSE 0 END) + billings_QUERY.closing_date_receivable_sales AS closing_date_billing_money_no_tax';
      selectSql += ',(CASE WHEN billings_QUERY.site_id = 0 THEN 0 - billings_QUERY.closing_date_tax_payment ELSE 0 END) + billings_QUERY.closing_date_normal_tax + billings_QUERY.closing_date_light_tax AS closing_date_billing_money_tax';
      selectSql += ',0 AS two_pre_billing_money_no_tax';
      selectSql += ',0 AS two_pre_billing_money_tax';
      selectSql += ',0 AS three_pre_billing_money_no_tax';
      selectSql += ',0 AS three_pre_billing_money_tax';
      selectSql += ',CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL billings_QUERY.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL billings_QUERY.payment_class MONTH), \'%Y-%m-01\'), INTERVAL billings_QUERY.payment_scheduled - 1 DAY)';
      selectSql += '   THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL billings_QUERY.payment_class MONTH))';
      selectSql += '   ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL billings_QUERY.payment_class MONTH), \'%Y-%m-01\'), INTERVAL billings_QUERY.payment_scheduled - 1 DAY)';
      selectSql += ' END AS payment_scheduled';
      selectSql += ',billings_QUERY.billing_output_class';
      selectSql += ',billings_QUERY.closing_date_normal_tax_subtotal';
      selectSql += ',billings_QUERY.closing_date_normal_tax';
      selectSql += ',billings_QUERY.closing_date_light_tax_subtotal';
      selectSql += ',billings_QUERY.closing_date_light_tax';
      selectSql += ',billings_QUERY.closing_date_receivable_sales AS closing_date_receivable_sales_pre_exclude';
      selectSql += ',billings_QUERY.closing_date_normal_tax_subtotal AS closing_date_normal_tax_subtotal_pre_exclude';
      selectSql += ',billings_QUERY.closing_date_normal_tax AS closing_date_normal_tax_pre_exclude';
      selectSql += ',billings_QUERY.closing_date_light_tax_subtotal AS closing_date_light_tax_subtotal_pre_exclude';
      selectSql += ',billings_QUERY.closing_date_light_tax AS closing_date_light_tax_pre_exclude';
      selectSql += ',\'' + this.loginId + '\'';
      selectSql += ',\'' + this.loginId + '\'';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += '(' + this.makeInsertQuery() + ') AS billings_QUERY ';
      selectSql += 'INNER JOIN m_billings AS billings ';
      selectSql += 'ON billings_QUERY.client_id = billings.client_id ';
      selectSql += 'AND billings_QUERY.site_id = billings.site_id ';

      let insertSql = insertSqlStart + selectSql;
      //console.log(insertSql)
      this.transactSqlList.push(insertSql);
    },
    // INSERT用クエリ作成
    makeInsertQuery: function() {
      /* 取引先毎（請求締更新前の処理月情報あり、前月情報なし）（取引先別） */
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',0 AS site_id'; // 現場コード
      selectSql1 += ',billings.client_id_first_digit';
      selectSql1 += ',billings.billing_start_date';
      selectSql1 += ',billings.billing_end_date';
      selectSql1 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS closing_date_receivable_sales';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_normal_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_light_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      selectSql1 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN 0 ELSE cumulative_transaction.amount END) AS closing_date_payment';
      selectSql1 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN cumulative_transaction.amount ELSE 0 END) AS closing_date_tax_payment';
      selectSql1 += ',billings.billing_output_class';
      selectSql1 += ',clients.payment_class';
      selectSql1 += ',clients.payment_scheduled';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += 'm_billings AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND cumulative_transaction.closing_month_year = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0 OR cumulative_transaction.amount <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\',\'' + Const.TransactionId.deposit + '\') ';
      selectSql1 += 'LEFT JOIN t_billings_balances AS billings_balances ';
      selectSql1 += 'ON billings.client_id = billings_balances.client_id ';
      selectSql1 += 'AND billings_balances.site_id = 0 ';
      selectSql1 += 'AND billings_balances.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql1 += 'INNER JOIN m_clients AS clients ';
      selectSql1 += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = clients.client_id ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      selectSql1 += 'billings_balances.client_id IS NULL ';
      selectSql1 += 'AND billings.closing_date = ' + this.preBilling.closingDate + ' ';
      // 請求書出力単位区分
      selectSql1 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.client + ' ';
      // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外（入金は除外しない）
      selectSql1 += 'AND (billings_issue_input_billing_no.billing_no IS NULL OR cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\') ';
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings.client_id';
      /* 取引先毎（請求締更新前の処理月情報あり、前月情報なし）（現場別） */
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT';
      selectSql2 += ' billings_site_QUERY.client_id';
      selectSql2 += ',0 AS site_id'; // 現場コード
      selectSql2 += ',billings_site_QUERY.client_id_first_digit';
      selectSql2 += ',billings_site_QUERY.billing_start_date';
      selectSql2 += ',billings_site_QUERY.billing_end_date';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_receivable_sales) AS closing_date_receivable_sales';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_normal_tax_subtotal) AS closing_date_normal_tax_subtotal';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_normal_tax) AS closing_date_normal_tax';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_light_tax_subtotal) AS closing_date_light_tax_subtotal';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_light_tax) AS closing_date_light_tax';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_payment) AS closing_date_payment';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_tax_payment) AS closing_date_tax_payment';
      selectSql2 += ',billings_site_QUERY.billing_output_class';
      selectSql2 += ',billings_site_QUERY.payment_class';
      selectSql2 += ',billings_site_QUERY.payment_scheduled';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += '(' + this.makeInsertSiteQuery() + ') AS billings_site_QUERY ';
      /* GROUP BY句 */
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings_site_QUERY.client_id';
      /* 現場毎（請求締更新前の処理月情報あり、前月情報なし） */
      let selectSql3 = '';
      /* SELECT句 */
      selectSql3 += 'SELECT';
      selectSql3 += ' billings.client_id';
      selectSql3 += ',billings.site_id';
      selectSql3 += ',billings.client_id_first_digit';
      selectSql3 += ',billings.billing_start_date';
      selectSql3 += ',billings.billing_end_date';
      selectSql3 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS closing_date_receivable_sales';
      selectSql3 += ',SUM(';
      selectSql3 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql3 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql3 += '   ELSE 0 END)';
      selectSql3 += ' AS closing_date_normal_tax_subtotal';
      selectSql3 += ',TRUNCATE((SUM(';
      selectSql3 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql3 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql3 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql3 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql3 += '   ELSE 0 END) / 100),0)';
      selectSql3 += ' AS closing_date_normal_tax';
      selectSql3 += ',SUM(';
      selectSql3 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql3 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql3 += '   ELSE 0 END)';
      selectSql3 += ' AS closing_date_light_tax_subtotal';
      selectSql3 += ',TRUNCATE((SUM(';
      selectSql3 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql3 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql3 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql3 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql3 += '   ELSE 0 END) / 100),0)';
      selectSql3 += ' AS closing_date_light_tax';
      selectSql3 += ',0 AS closing_date_payment';
      selectSql3 += ',0 AS closing_date_tax_payment';
      selectSql3 += ',billings.billing_output_class';
      selectSql3 += ',clients.payment_class';
      selectSql3 += ',clients.payment_scheduled';
      /* FROM句 */
      selectSql3 += ' FROM ';
      selectSql3 += 'm_billings AS billings ';
      selectSql3 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql3 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql3 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql3 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql3 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql3 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql3 += 'AND cumulative_transaction.closing_month_year = 0 ';
      selectSql3 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0) ';
      selectSql3 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      selectSql3 += 'INNER JOIN m_clients AS clients ';
      selectSql3 += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql3 += 'AND billings.client_id = clients.client_id ';
      selectSql3 += 'LEFT JOIN m_products AS products ';
      selectSql3 += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql3 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSql3 += 'ON billings_issue_input_billing_no.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql3 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      /* WHERE句 */
      selectSql3 += ' WHERE ';
      selectSql3 += 'billings.site_id > 0 ';
      selectSql3 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
      /* GROUP BY句 */
      selectSql3 += ' GROUP BY ';
      selectSql3 += ' billings.client_id';
      selectSql3 += ',billings.site_id';
      let selectSql = selectSql1 + ' UNION ALL ' + selectSql2 + ' UNION ALL ' + selectSql3;
      //console.log(selectSql)
      return selectSql;
    },
    // 登録用クエリ文字列作成（現場別）
    makeInsertSiteQuery: function() {
      // 現場別取引先の売上情報
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',billings.client_id_first_digit';
      selectSql1 += ',billings.billing_start_date';
      selectSql1 += ',billings.billing_end_date';
      selectSql1 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS closing_date_receivable_sales';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_normal_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_light_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      selectSql1 += ',0 AS closing_date_payment';
      selectSql1 += ',0 AS closing_date_tax_payment';
      selectSql1 += ',billings.billing_output_class';
      selectSql1 += ',clients.payment_class';
      selectSql1 += ',clients.payment_scheduled';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += 'm_billings AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND cumulative_transaction.closing_month_year = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      selectSql1 += 'LEFT JOIN t_billings_balances AS billings_balances ';
      selectSql1 += 'ON billings.client_id = billings_balances.client_id ';
      selectSql1 += 'AND billings_balances.site_id = 0 ';
      selectSql1 += 'AND billings_balances.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql1 += 'INNER JOIN m_clients AS clients ';
      selectSql1 += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = clients.client_id ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      selectSql1 += 'billings.closing_date = ' + this.preBilling.closingDate + ' ';
      selectSql1 += 'AND billings_balances.client_id IS NULL ';
      selectSql1 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
      // 請求書出力単位区分
      selectSql1 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',billings.site_id';
      // 現場別取引先の入金情報
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT ';
      selectSql2 += ' billings.client_id';
      selectSql2 += ',billings.client_id_first_digit';
      selectSql2 += ',billings.billing_start_date';
      selectSql2 += ',billings.billing_end_date';
      selectSql2 += ',0 AS closing_date_receivable_sales';
      selectSql2 += ',0 AS closing_date_normal_tax_subtotal';
      selectSql2 += ',0 AS closing_date_normal_tax';
      selectSql2 += ',0 AS closing_date_light_tax_subtotal';
      selectSql2 += ',0 AS closing_date_light_tax';
      selectSql2 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN 0 ELSE cumulative_transaction.amount END) AS closing_date_payment';
      selectSql2 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN cumulative_transaction.amount ELSE 0 END) AS closing_date_tax_payment';
      selectSql2 += ',billings.billing_output_class';
      selectSql2 += ',clients.payment_class';
      selectSql2 += ',clients.payment_scheduled';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += 'm_billings AS billings ';
      selectSql2 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql2 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql2 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql2 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql2 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql2 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql2 += 'AND cumulative_transaction.closing_month_year = 0 ';
      selectSql2 += 'AND (cumulative_transaction.amount <> 0) ';
      selectSql2 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\' ';
      selectSql2 += 'LEFT JOIN t_billings_balances AS billings_balances ';
      selectSql2 += 'ON billings.client_id = billings_balances.client_id ';
      selectSql2 += 'AND billings_balances.site_id = 0 ';
      selectSql2 += 'AND billings_balances.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql2 += 'INNER JOIN m_clients AS clients ';
      selectSql2 += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql2 += 'AND billings.client_id = clients.client_id ';
      /* WHERE句 */
      selectSql2 += ' WHERE ';
      selectSql2 += 'billings.closing_date = ' + this.preBilling.closingDate + ' ';
      selectSql2 += 'AND billings_balances.client_id IS NULL ';
      // 請求書出力単位区分
      selectSql2 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
      // 現場コード
      selectSql2 += 'AND billings.site_id = 0 ';
      /* GROUP BY句 */
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings.client_id';
      let selectSql = '';
      // 2つのSELECT文をUNION
      selectSql = selectSql1 + ' UNION ALL ' + selectSql2;

      return selectSql;
    },
    /* 請求残高UPDATESQL作成処理（当月） */
    async createBillingBalancesCurMonthUpdateSql() {
      //console.log('請求残高UPDATESQL作成処理（当月）');
      let updateQuery = '(' + this.makeUpdateQuery() + ') AS billings_balancesQuery';
      // 更新SQLを作成
      let colList = [];
      // 営業所コード
      colList.push(CreateColRow('billings_balances.office_id', 0, 'NUMBER'));
      // 得意先分類コード
      colList.push(CreateColRow('billings_balances.client_id_first_digit', 'billings_balancesQuery.client_id_first_digit', 'NUMBER'));
      // 請求開始日
      colList.push(CreateColRow('billings_balances.billing_start_date', 'billings_balancesQuery.billing_start_date', 'DATE'));
      // 請求締切日
      colList.push(CreateColRow('billings_balances.billing_end_date', 'billings_balancesQuery.billing_end_date', 'DATE'));
      // 締日売掛売上
      colList.push(CreateColRow('billings_balances.closing_date_receivable_sales', 'billings_balancesQuery.closing_date_receivable_sales', 'NUMBER'));
      // 締日売上伝票枚数
      colList.push(CreateColRow('billings_balances.closing_date_sales_billing_cnt', 'billings_balancesQuery.closing_date_sales_billing_cnt', 'NUMBER'));
      // 締日一括消費税額
      colList.push(CreateColRow('billings_balances.closing_date_bulk_tax', 'billings_balancesQuery.closing_date_normal_tax + billings_balancesQuery.closing_date_light_tax', 'NUMBER'));
      // 締日伝票消費税額
      colList.push(CreateColRow('billings_balances.closing_date_billing_tax', 0, 'NUMBER'));
      // 締日消費税額
      colList.push(CreateColRow('billings_balances.closing_date_tax', 'billings_balancesQuery.closing_date_normal_tax + billings_balancesQuery.closing_date_light_tax', 'NUMBER'));
      // 締日入金額
      colList.push(CreateColRow('billings_balances.closing_date_payment', 'billings_balancesQuery.closing_date_payment', 'NUMBER'));
      // 締日消費税入金
      colList.push(CreateColRow('billings_balances.closing_date_tax_payment', 'billings_balancesQuery.closing_date_tax_payment', 'NUMBER'));
      // 締日繰越税抜額
      colList.push(CreateColRow('billings_balances.closing_date_forward_no_tax', 'billings_balancesQuery.pre_billing_no_tax_balance - billings_balancesQuery.closing_date_payment', 'NUMBER'));
      // 締日繰越消費税額
      colList.push(CreateColRow('billings_balances.closing_date_forward_tax', 'billings_balancesQuery.pre_billing_tax_balance - billings_balancesQuery.closing_date_tax_payment', 'NUMBER'));
      // 締日請求税抜額
      colList.push(CreateColRow('billings_balances.closing_date_billing_money_no_tax', 'billings_balancesQuery.closing_date_receivable_sales + billings_balancesQuery.pre_billing_no_tax_balance - billings_balancesQuery.closing_date_payment', 'NUMBER'));
      // 締日請求消費税額
      colList.push(CreateColRow('billings_balances.closing_date_billing_money_tax', 'billings_balancesQuery.closing_date_normal_tax + billings_balancesQuery.closing_date_light_tax + billings_balancesQuery.pre_billing_tax_balance - billings_balancesQuery.closing_date_tax_payment', 'NUMBER'));
      // 入金予定日
      colList.push(CreateColRow('billings_balances.payment_scheduled', 'billings_balancesQuery.payment_scheduled', 'DATE'));
      // 請求書出力単位区分
      colList.push(CreateColRow('billings_balances.billing_output_class', 'billings_balancesQuery.billing_output_class', 'NUMBER'));
      // 締日通常消費税課税小計
      colList.push(CreateColRow('billings_balances.closing_date_normal_tax_subtotal', 'billings_balancesQuery.closing_date_normal_tax_subtotal', 'NUMBER'));
      // 締日通常消費税額
      colList.push(CreateColRow('billings_balances.closing_date_normal_tax', 'billings_balancesQuery.closing_date_normal_tax', 'NUMBER'));
      // 締日軽減消費税課税小計
      colList.push(CreateColRow('billings_balances.closing_date_light_tax_subtotal', 'billings_balancesQuery.closing_date_light_tax_subtotal', 'NUMBER'));
      // 締日軽減消費税額
      colList.push(CreateColRow('billings_balances.closing_date_light_tax', 'billings_balancesQuery.closing_date_light_tax', 'NUMBER'));
      // 締日売掛売上_先行除外
      colList.push(CreateColRow('billings_balances.closing_date_receivable_sales_pre_exclude', 'billings_balancesQuery.closing_date_receivable_sales', 'NUMBER'));
      // 締日通常消費税課税小計_先行除外
      colList.push(CreateColRow('billings_balances.closing_date_normal_tax_subtotal_pre_exclude', 'billings_balancesQuery.closing_date_normal_tax_subtotal', 'NUMBER'));
      // 締日通常消費税額_先行除外
      colList.push(CreateColRow('billings_balances.closing_date_normal_tax_pre_exclude', 'billings_balancesQuery.closing_date_normal_tax', 'NUMBER'));
      // 締日軽減消費税課税小計_先行除外
      colList.push(CreateColRow('billings_balances.closing_date_light_tax_subtotal_pre_exclude', 'billings_balancesQuery.closing_date_light_tax_subtotal', 'NUMBER'));
      // 締日軽減消費税額_先行除外
      colList.push(CreateColRow('closing_date_light_tax_pre_exclude', 'billings_balancesQuery.closing_date_light_tax', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('billings_balances.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('billings_balances.updated_user', this.loginId, 'VARCHAR'));
      let updateSql = CreateUpdateSql(colList, 't_billings_balances AS billings_balances', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'billings_balances.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      updateSql += 'AND billings_balances.client_id = billings_balancesQuery.client_id ';
      updateSql += 'AND billings_balances.site_id = 0 ';
      //console.log(updateSql)
      this.transactSqlList.push(updateSql);
    },
    // UPDATE用クエリ作成
    makeUpdateQuery: function() {
      /* 取引先毎 */
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' billings_QUERY.client_id';
      selectSql += ',billings_QUERY.client_id_first_digit';
      selectSql += ',billings_QUERY.billing_start_date';
      selectSql += ',billings_QUERY.billing_end_date';
      selectSql += ',SUM(billings_QUERY.closing_date_receivable_sales) AS closing_date_receivable_sales';
      selectSql += ',billings_QUERY.closing_date_sales_billing_cnt';
      selectSql += ',SUM(billings_QUERY.closing_date_normal_tax_subtotal) AS closing_date_normal_tax_subtotal';
      selectSql += ',SUM(billings_QUERY.closing_date_normal_tax) AS closing_date_normal_tax';
      selectSql += ',SUM(billings_QUERY.closing_date_light_tax_subtotal) AS closing_date_light_tax_subtotal';
      selectSql += ',SUM(billings_QUERY.closing_date_light_tax) AS closing_date_light_tax';
      selectSql += ',SUM(billings_QUERY.closing_date_payment) AS closing_date_payment';
      selectSql += ',SUM(billings_QUERY.closing_date_tax_payment) AS closing_date_tax_payment';
      selectSql += ',MAX(billings_QUERY.pre_billing_no_tax_balance) AS pre_billing_no_tax_balance';
      selectSql += ',MAX(billings_QUERY.pre_billing_tax_balance) AS pre_billing_tax_balance';
      selectSql += ',CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL billings_QUERY.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL billings_QUERY.payment_class MONTH), \'%Y-%m-01\'), INTERVAL billings_QUERY.payment_scheduled - 1 DAY)';
      selectSql += '   THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL billings_QUERY.payment_class MONTH))';
      selectSql += '   ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL billings_QUERY.payment_class MONTH), \'%Y-%m-01\'), INTERVAL billings_QUERY.payment_scheduled - 1 DAY)';
      selectSql += ' END AS payment_scheduled';
      selectSql += ',billings_QUERY.billing_output_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += '(' + this.makeUpdateSubQuery() + ') AS billings_QUERY ';
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' billings_QUERY.client_id';
      //console.log(selectSql)
      return selectSql;
    },
    // 更新用サブクエリ文字列作成
    makeUpdateSubQuery: function() {
      // 売上（取引先別）
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',billings.client_id_first_digit';
      selectSql1 += ',billings.billing_start_date';
      selectSql1 += ',billings.billing_end_date';
      selectSql1 += ',SUM(IfNull(cumulative_transaction.product_sales_unit_amount,0)) AS closing_date_receivable_sales';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_normal_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_light_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      selectSql1 += ',0 AS closing_date_payment';
      selectSql1 += ',0 AS closing_date_tax_payment';
      selectSql1 += ',billings_balances.pre_billing_no_tax_balance';
      selectSql1 += ',billings_balances.pre_billing_tax_balance';
      selectSql1 += ',billings.billing_output_class';
      selectSql1 += ',clients.payment_class';
      selectSql1 += ',clients.payment_scheduled';
      selectSql1 += ',MAX(billings.closing_date_sales_billing_cnt) AS closing_date_sales_billing_cnt';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += 't_billings_balances AS billings_balances ';
      selectSql1 += 'INNER JOIN m_billings AS billings ';
      selectSql1 += 'ON billings.client_id = billings_balances.client_id ';
      selectSql1 += 'LEFT JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND cumulative_transaction.closing_month_year = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      selectSql1 += 'INNER JOIN m_clients AS clients ';
      selectSql1 += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings_balances.client_id = clients.client_id ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      selectSql1 += 'billings_balances.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql1 += 'AND billings_balances.site_id = 0 ';
      selectSql1 += 'AND billings.closing_date = ' + this.preBilling.closingDate + ' ';
      selectSql1 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
      // 請求書出力単位区分
      selectSql1 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.client + ' ';
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings_balances.client_id';
      // 入金
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT';
      selectSql2 += ' billings.client_id';
      selectSql2 += ',billings.client_id_first_digit';
      selectSql2 += ',billings.billing_start_date';
      selectSql2 += ',billings.billing_end_date';
      selectSql2 += ',0 AS closing_date_receivable_sales';
      selectSql2 += ',0 AS closing_date_normal_tax_subtotal';
      selectSql2 += ',0 AS closing_date_normal_tax';
      selectSql2 += ',0 AS closing_date_light_tax_subtotal';
      selectSql2 += ',0 AS closing_date_light_tax';
      selectSql2 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN 0 ELSE IfNull(cumulative_transaction.amount,0) END) AS closing_date_payment';
      selectSql2 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN cumulative_transaction.amount ELSE 0 END) AS closing_date_tax_payment';
      selectSql2 += ',billings_balances.pre_billing_no_tax_balance';
      selectSql2 += ',billings_balances.pre_billing_tax_balance';
      selectSql2 += ',billings.billing_output_class';
      selectSql2 += ',clients.payment_class';
      selectSql2 += ',clients.payment_scheduled';
      selectSql2 += ',billings.closing_date_sales_billing_cnt';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += 't_billings_balances AS billings_balances ';
      selectSql2 += 'INNER JOIN m_billings AS billings ';
      selectSql2 += 'ON billings.client_id = billings_balances.client_id ';
      selectSql2 += 'AND billings.site_id = 0 ';
      selectSql2 += 'LEFT JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql2 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql2 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql2 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql2 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql2 += 'AND cumulative_transaction.closing_month_year = 0 ';
      selectSql2 += 'AND (cumulative_transaction.amount <> 0) ';
      selectSql2 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\' ';
      selectSql2 += 'INNER JOIN m_clients AS clients ';
      selectSql2 += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql2 += 'AND billings_balances.client_id = clients.client_id ';
      /* WHERE句 */
      selectSql2 += ' WHERE ';
      selectSql2 += 'billings_balances.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql2 += 'AND billings_balances.site_id = 0 ';
      /* GROUP BY句 */
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings_balances.client_id';
      // 売上（現場別）
      let selectSql3 = '';
      /* SELECT句 */
      selectSql3 += 'SELECT ';
      selectSql3 += ' billings.client_id';
      selectSql3 += ',billings.client_id_first_digit';
      selectSql3 += ',billings.billing_start_date';
      selectSql3 += ',billings.billing_end_date';
      selectSql3 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS closing_date_receivable_sales';
      selectSql3 += ',SUM(';
      selectSql3 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql3 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql3 += '   ELSE 0 END)';
      selectSql3 += ' AS closing_date_normal_tax_subtotal';
      selectSql3 += ',TRUNCATE((SUM(';
      selectSql3 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql3 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql3 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql3 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql3 += '   ELSE 0 END) / 100),0)';
      selectSql3 += ' AS closing_date_normal_tax';
      selectSql3 += ',SUM(';
      selectSql3 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql3 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql3 += '   ELSE 0 END)';
      selectSql3 += ' AS closing_date_light_tax_subtotal';
      selectSql3 += ',TRUNCATE((SUM(';
      selectSql3 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql3 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql3 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql3 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql3 += '   ELSE 0 END) / 100),0)';
      selectSql3 += ' AS closing_date_light_tax';
      selectSql3 += ',0 AS closing_date_payment';
      selectSql3 += ',0 AS closing_date_tax_payment';
      selectSql3 += ',billings_balances.pre_billing_no_tax_balance';
      selectSql3 += ',billings_balances.pre_billing_tax_balance';
      selectSql3 += ',billings.billing_output_class';
      selectSql3 += ',clients.payment_class';
      selectSql3 += ',clients.payment_scheduled';
      selectSql3 += ',MAX(billings.closing_date_sales_billing_cnt) AS closing_date_sales_billing_cnt';
      /* FROM句 */
      selectSql3 += ' FROM ';
      selectSql3 += 't_billings_balances AS billings_balances ';
      selectSql3 += 'INNER JOIN m_billings AS billings ';
      selectSql3 += 'ON billings.client_id = billings_balances.client_id ';
      selectSql3 += 'LEFT JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql3 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql3 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql3 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql3 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql3 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql3 += 'AND cumulative_transaction.closing_month_year = 0 ';
      selectSql3 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0) ';
      selectSql3 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      selectSql3 += 'INNER JOIN m_clients AS clients ';
      selectSql3 += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql3 += 'AND billings_balances.client_id = clients.client_id ';
      selectSql3 += 'LEFT JOIN m_products AS products ';
      selectSql3 += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql3 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSql3 += 'ON billings_issue_input_billing_no.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql3 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      /* WHERE句 */
      selectSql3 += ' WHERE ';
      selectSql3 += 'billings_balances.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql3 += 'AND billings_balances.site_id = 0 ';
      selectSql3 += 'AND billings.closing_date = ' + this.preBilling.closingDate + ' ';
      selectSql3 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
      // 請求書出力単位区分
      selectSql3 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
      /* GROUP BY句 */
      selectSql3 += ' GROUP BY ';
      selectSql3 += ' billings.client_id';
      selectSql3 += ',billings.site_id';
      let selectSql = '';
      // 2つのSELECT文をUNION
      selectSql = selectSql1 + ' UNION ALL ' + selectSql2 + ' UNION ALL ' + selectSql3;

      return selectSql;
    },
    // 請求残高先行伝票MERGESQL作成処理（当月）
    createSeparateBillingBalancesCurMonthMergeSql: function() {
      // マージSQLを作成（INSERT文の前部分のため設定値不要）
      let colList = [];
      // 請求年月
      colList.push(CreateColRow('billing_month_year', null, 'NUMBER'));
      // 得意先コード
      colList.push(CreateColRow('client_id', null, 'NUMBER'));
      // 現場コード
      colList.push(CreateColRow('site_id', null, 'NUMBER'));
      // 営業所コード
      colList.push(CreateColRow('office_id', null, 'NUMBER'));
      // 得意先分類コード
      colList.push(CreateColRow('client_id_first_digit', null, 'NUMBER'));
      // 請求開始日
      colList.push(CreateColRow('billing_start_date', null, 'DATE'));
      // 請求締切日
      colList.push(CreateColRow('billing_end_date', null, 'DATE'));
      // 前回請求税抜残高
      colList.push(CreateColRow('pre_billing_no_tax_balance', null, 'NUMBER'));
      // 前回請求消費税残高
      colList.push(CreateColRow('pre_billing_tax_balance', null, 'NUMBER'));
      // 締日売掛売上
      colList.push(CreateColRow('closing_date_receivable_sales', null, 'NUMBER'));
      // 締日売上伝票枚数
      colList.push(CreateColRow('closing_date_sales_billing_cnt', null, 'NUMBER'));
      // 締日一括消費税額
      colList.push(CreateColRow('closing_date_bulk_tax', null, 'NUMBER'));
      // 締日伝票消費税額
      colList.push(CreateColRow('closing_date_billing_tax', null, 'NUMBER'));
      // 締日消費税額
      colList.push(CreateColRow('closing_date_tax', null, 'NUMBER'));
      // 締日入金額
      colList.push(CreateColRow('closing_date_payment', null, 'NUMBER'));
      // 締日消費税入金
      colList.push(CreateColRow('closing_date_tax_payment', null, 'NUMBER'));
      // 締日繰越税抜額
      colList.push(CreateColRow('closing_date_forward_no_tax', null, 'NUMBER'));
      // 締日繰越消費税額
      colList.push(CreateColRow('closing_date_forward_tax', null, 'NUMBER'));
      // 締日請求税抜額
      colList.push(CreateColRow('closing_date_billing_money_no_tax', null, 'NUMBER'));
      // 締日請求消費税額
      colList.push(CreateColRow('closing_date_billing_money_tax', null, 'NUMBER'));
      // 2回前請求税抜額
      colList.push(CreateColRow('two_pre_billing_money_no_tax', null, 'NUMBER'));
      // 2回前請求消費税額
      colList.push(CreateColRow('two_pre_billing_money_tax', null, 'NUMBER'));
      // 3回前請求税抜額
      colList.push(CreateColRow('three_pre_billing_money_no_tax', null, 'NUMBER'));
      // 3回前請求消費税額
      colList.push(CreateColRow('three_pre_billing_money_tax', null, 'NUMBER'));
      // 入金予定日
      colList.push(CreateColRow('payment_scheduled', null, 'DATE'));
      // 請求書出力単位区分
      colList.push(CreateColRow('billing_output_class', null, 'NUMBER'));
      // 締日通常消費税課税小計
      colList.push(CreateColRow('closing_date_normal_tax_subtotal', null, 'NUMBER'));
      // 締日通常消費税額
      colList.push(CreateColRow('closing_date_normal_tax', null, 'NUMBER'));
      // 締日軽減消費税課税小計
      colList.push(CreateColRow('closing_date_light_tax_subtotal', null, 'NUMBER'));
      // 締日軽減消費税額
      colList.push(CreateColRow('closing_date_light_tax', null, 'NUMBER'));
      // 締日売掛売上_先行除外
      colList.push(CreateColRow('closing_date_receivable_sales_pre_exclude', null, 'NUMBER'));
      // 締日通常消費税課税小計_先行除外
      colList.push(CreateColRow('closing_date_normal_tax_subtotal_pre_exclude', null, 'NUMBER'));
      // 締日通常消費税額_先行除外
      colList.push(CreateColRow('closing_date_normal_tax_pre_exclude', null, 'NUMBER'));
      // 締日軽減消費税課税小計_先行除外
      colList.push(CreateColRow('closing_date_light_tax_subtotal_pre_exclude', null, 'NUMBER'));
      // 締日軽減消費税額_先行除外
      colList.push(CreateColRow('closing_date_light_tax_pre_exclude', null, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      let insertSqlStart = 'INSERT INTO t_billings_balances (' + CreateInsertSql(colList, 'col', 't_billings_balances') + ') ';
      // INSERT用SELECT文
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' AS billing_month_year';
      selectSql += ',billings_QUERY.client_id';
      selectSql += ',billings_QUERY.site_id';
      selectSql += ',0 AS office_id';
      selectSql += ',billings.client_id_first_digit';
      selectSql += ',billings.billing_start_date';
      selectSql += ',billings.billing_end_date';
      selectSql += ',0 AS pre_billing_no_tax_balance';
      selectSql += ',0 AS pre_billing_tax_balance';
      selectSql += ',billings_QUERY.closing_date_receivable_sales';
      selectSql += ',billings.closing_date_sales_billing_cnt';
      selectSql += ',billings_QUERY.closing_date_normal_tax + billings_QUERY.closing_date_light_tax AS closing_date_bulk_tax';
      selectSql += ',0 AS closing_date_billing_tax';
      selectSql += ',billings_QUERY.closing_date_normal_tax + billings_QUERY.closing_date_light_tax AS closing_date_tax';
      selectSql += ',0 AS closing_date_payment';
      selectSql += ',0 AS closing_date_tax_payment';
      selectSql += ',0 AS closing_date_forward_no_tax';
      selectSql += ',0 AS closing_date_forward_tax';
      selectSql += ',billings_QUERY.closing_date_receivable_sales AS closing_date_billing_money_no_tax';
      selectSql += ',billings_QUERY.closing_date_normal_tax + billings_QUERY.closing_date_light_tax AS closing_date_billing_money_tax';
      selectSql += ',0 AS two_pre_billing_money_no_tax';
      selectSql += ',0 AS two_pre_billing_money_tax';
      selectSql += ',0 AS three_pre_billing_money_no_tax';
      selectSql += ',0 AS three_pre_billing_money_tax';
      selectSql += ',CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '   ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += ' END AS payment_scheduled';
      selectSql += ',billings.billing_output_class';
      selectSql += ',billings_QUERY.closing_date_normal_tax_subtotal';
      selectSql += ',billings_QUERY.closing_date_normal_tax';
      selectSql += ',billings_QUERY.closing_date_light_tax_subtotal';
      selectSql += ',billings_QUERY.closing_date_light_tax';
      selectSql += ',0 AS closing_date_receivable_sales_pre_exclude';
      selectSql += ',0 AS closing_date_normal_tax_subtotal_pre_exclude';
      selectSql += ',0 AS closing_date_normal_tax_pre_exclude';
      selectSql += ',0 AS closing_date_light_tax_subtotal_pre_exclude';
      selectSql += ',0 AS closing_date_light_tax_pre_exclude';
      selectSql += ',\'' + this.loginId + '\'';
      selectSql += ',\'' + this.loginId + '\'';
      // FROM句
      selectSql += ' FROM ';
      selectSql += '(' + this.makeMergeQuery() + ') AS billings_QUERY ';
      selectSql += 'INNER JOIN m_billings AS billings ';
      selectSql += 'ON billings_QUERY.client_id = billings.client_id ';
      selectSql += 'AND billings_QUERY.site_id = billings.site_id ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND billings_QUERY.client_id = clients.client_id ';
      // MERGE句
      let mergeSql = ' ON DUPLICATE KEY UPDATE ';
      mergeSql += 'closing_date_receivable_sales = t_billings_balances.closing_date_receivable_sales + VALUES(closing_date_receivable_sales)';
      mergeSql += ',closing_date_bulk_tax = t_billings_balances.closing_date_bulk_tax + VALUES(closing_date_bulk_tax)';
      mergeSql += ',closing_date_tax = t_billings_balances.closing_date_tax + VALUES(closing_date_tax)';
      mergeSql += ',closing_date_billing_money_no_tax = t_billings_balances.closing_date_billing_money_no_tax + VALUES(closing_date_billing_money_no_tax)';
      mergeSql += ',closing_date_billing_money_tax = t_billings_balances.closing_date_billing_money_tax + VALUES(closing_date_billing_money_tax)';
      mergeSql += ',closing_date_normal_tax_subtotal = t_billings_balances.closing_date_normal_tax_subtotal + VALUES(closing_date_normal_tax_subtotal)';
      mergeSql += ',closing_date_normal_tax = t_billings_balances.closing_date_normal_tax + VALUES(closing_date_normal_tax)';
      mergeSql += ',closing_date_light_tax_subtotal = t_billings_balances.closing_date_light_tax_subtotal + VALUES(closing_date_light_tax_subtotal)';
      mergeSql += ',closing_date_light_tax = t_billings_balances.closing_date_light_tax + VALUES(closing_date_light_tax)';
      mergeSql += ',closing_date_receivable_sales_pre_exclude = t_billings_balances.closing_date_receivable_sales_pre_exclude - billings_QUERY.closing_date_receivable_sales_pre_exclude';
      mergeSql += ',closing_date_normal_tax_subtotal_pre_exclude = t_billings_balances.closing_date_normal_tax_subtotal_pre_exclude - billings_QUERY.closing_date_normal_tax_subtotal_pre_exclude';
      mergeSql += ',closing_date_normal_tax_pre_exclude = t_billings_balances.closing_date_normal_tax_pre_exclude - billings_QUERY.closing_date_normal_tax_pre_exclude';
      mergeSql += ',closing_date_light_tax_subtotal_pre_exclude = t_billings_balances.closing_date_light_tax_subtotal_pre_exclude - billings_QUERY.closing_date_light_tax_subtotal_pre_exclude';
      mergeSql += ',closing_date_light_tax_pre_exclude = t_billings_balances.closing_date_light_tax_pre_exclude - billings_QUERY.closing_date_light_tax_pre_exclude';
      let insertSql = insertSqlStart + selectSql + mergeSql;
      //console.log(insertSql)
      this.transactSqlList.push(insertSql);
    },
    // MERGE用クエリ作成
    makeMergeQuery: function() {
      // 請求書発行登録＿伝票番号毎（取引先毎）
      let selectSql1 = '';
      // SELECT句
      selectSql1 += 'SELECT';
      selectSql1 += ' billings_issue_input_billing_no.client_id';
      selectSql1 += ',0 AS site_id';
      selectSql1 += ',SUM(billings_issue_input_billing_no.sales_unit_amount) AS closing_date_receivable_sales';
      selectSql1 += ',SUM(billings_issue_input_billing_no.normal_tax_subtotal) AS closing_date_normal_tax_subtotal';
      selectSql1 += ',SUM(billings_issue_input_billing_no.normal_tax) AS closing_date_normal_tax';
      selectSql1 += ',SUM(billings_issue_input_billing_no.light_tax_subtotal) AS closing_date_light_tax_subtotal';
      selectSql1 += ',SUM(billings_issue_input_billing_no.light_tax) AS closing_date_light_tax';
      selectSql1 += ',0 AS closing_date_receivable_sales_pre_exclude';
      selectSql1 += ',0 AS closing_date_normal_tax_subtotal_pre_exclude';
      selectSql1 += ',0 AS closing_date_normal_tax_pre_exclude';
      selectSql1 += ',0 AS closing_date_light_tax_subtotal_pre_exclude';
      selectSql1 += ',0 AS closing_date_light_tax_pre_exclude';
      // FROM句
      selectSql1 += ' FROM ';
      selectSql1 += '(SELECT ';
      selectSql1 += 'separate_invoice_no';
      selectSql1 += ',client_id';
      selectSql1 += ',MAX(sales_unit_amount) AS sales_unit_amount';
      selectSql1 += ',MAX(normal_tax_subtotal) AS normal_tax_subtotal';
      selectSql1 += ',MAX(normal_tax) AS normal_tax';
      selectSql1 += ',MAX(light_tax_subtotal) AS light_tax_subtotal';
      selectSql1 += ',MAX(light_tax) AS light_tax';
      selectSql1 += ' FROM t_billings_issue_input_billing_no ';
      selectSql1 += ' WHERE billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql1 += ' GROUP BY separate_invoice_no,client_id ';
      selectSql1 += ') AS billings_issue_input_billing_no ';
      // GROUP BY句
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings_issue_input_billing_no.client_id';
      // 請求書発行登録＿伝票番号毎（現場毎）
      let selectSql2 = '';
      // SELECT句
      selectSql2 += 'SELECT';
      selectSql2 += ' billings_issue_input_billing_no.client_id';
      selectSql2 += ',billings_issue_input_billing_no.site_id';
      selectSql2 += ',SUM(billings_issue_input_billing_no.sales_unit_amount) AS closing_date_receivable_sales';
      selectSql2 += ',SUM(billings_issue_input_billing_no.normal_tax_subtotal) AS closing_date_normal_tax_subtotal';
      selectSql2 += ',SUM(billings_issue_input_billing_no.normal_tax) AS closing_date_normal_tax';
      selectSql2 += ',SUM(billings_issue_input_billing_no.light_tax_subtotal) AS closing_date_light_tax_subtotal';
      selectSql2 += ',SUM(billings_issue_input_billing_no.light_tax) AS closing_date_light_tax';
      selectSql2 += ',0 AS closing_date_receivable_sales_pre_exclude';
      selectSql2 += ',0 AS closing_date_normal_tax_subtotal_pre_exclude';
      selectSql2 += ',0 AS closing_date_normal_tax_pre_exclude';
      selectSql2 += ',0 AS closing_date_light_tax_subtotal_pre_exclude';
      selectSql2 += ',0 AS closing_date_light_tax_pre_exclude';
      // FROM句
      selectSql2 += ' FROM ';
      selectSql2 += '(SELECT ';
      selectSql2 += 'separate_invoice_no';
      selectSql2 += ',client_id';
      selectSql2 += ',site_id';
      selectSql2 += ',MAX(sales_unit_amount_site) AS sales_unit_amount';
      selectSql2 += ',MAX(normal_tax_subtotal_site) AS normal_tax_subtotal';
      selectSql2 += ',MAX(normal_tax_site) AS normal_tax';
      selectSql2 += ',MAX(light_tax_subtotal_site) AS light_tax_subtotal';
      selectSql2 += ',MAX(light_tax_site) AS light_tax';
      selectSql2 += ' FROM t_billings_issue_input_billing_no ';
      selectSql2 += ' WHERE billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql2 += 'AND site_id > 0 ';
      selectSql2 += ' GROUP BY separate_invoice_no,client_id,site_id ';
      selectSql2 += ') AS billings_issue_input_billing_no ';
      // GROUP BY句
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings_issue_input_billing_no.client_id';
      selectSql2 += ',billings_issue_input_billing_no.site_id';
      // 請求書発行登録＿現場毎（取引先毎）
      let selectSql3 = '';
      // SELECT句
      selectSql3 += 'SELECT';
      selectSql3 += ' billings_issue_input_site.client_id';
      selectSql3 += ',0 AS site_id';
      selectSql3 += ',0 AS closing_date_receivable_sales';
      selectSql3 += ',0 AS closing_date_normal_tax_subtotal';
      selectSql3 += ',0 AS closing_date_normal_tax';
      selectSql3 += ',0 AS closing_date_light_tax_subtotal';
      selectSql3 += ',0 AS closing_date_light_tax';
      selectSql3 += ',SUM(billings_issue_input_site.sales_unit_amount) AS closing_date_receivable_sales_pre_exclude';
      selectSql3 += ',SUM(billings_issue_input_site.normal_tax_subtotal) AS closing_date_normal_tax_subtotal_pre_exclude';
      selectSql3 += ',SUM(billings_issue_input_site.normal_tax) AS closing_date_normal_tax_pre_exclude';
      selectSql3 += ',SUM(billings_issue_input_site.light_tax_subtotal) AS closing_date_light_tax_subtotal_pre_exclude';
      selectSql3 += ',SUM(billings_issue_input_site.light_tax) AS closing_date_light_tax_pre_exclude';
      // FROM句
      selectSql3 += ' FROM ';
      selectSql3 += '(SELECT ';
      selectSql3 += 'client_id';
      selectSql3 += ',MAX(sales_unit_amount) AS sales_unit_amount';
      selectSql3 += ',MAX(normal_tax_subtotal) AS normal_tax_subtotal';
      selectSql3 += ',MAX(normal_tax) AS normal_tax';
      selectSql3 += ',MAX(light_tax_subtotal) AS light_tax_subtotal';
      selectSql3 += ',MAX(light_tax) AS light_tax';
      selectSql3 += ' FROM t_billings_issue_input_site ';
      selectSql3 += ' WHERE billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql3 += ' GROUP BY client_id,site_id ';
      selectSql3 += ') AS billings_issue_input_site ';
      // GROUP BY句
      selectSql3 += ' GROUP BY ';
      selectSql3 += ' billings_issue_input_site.client_id';
      // 請求書発行登録＿現場毎（現場毎）
      let selectSql4 = '';
      // SELECT句
      selectSql4 += 'SELECT';
      selectSql4 += ' billings_issue_input_site.client_id';
      selectSql4 += ',billings_issue_input_site.site_id';
      selectSql4 += ',0 AS closing_date_receivable_sales';
      selectSql4 += ',0 AS closing_date_normal_tax_subtotal';
      selectSql4 += ',0 AS closing_date_normal_tax';
      selectSql4 += ',0 AS closing_date_light_tax_subtotal';
      selectSql4 += ',0 AS closing_date_light_tax';
      selectSql4 += ',SUM(billings_issue_input_site.sales_unit_amount) AS closing_date_receivable_sales_pre_exclude';
      selectSql4 += ',SUM(billings_issue_input_site.normal_tax_subtotal) AS closing_date_normal_tax_subtotal_pre_exclude';
      selectSql4 += ',SUM(billings_issue_input_site.normal_tax) AS closing_date_normal_tax_pre_exclude';
      selectSql4 += ',SUM(billings_issue_input_site.light_tax_subtotal) AS closing_date_light_tax_subtotal_pre_exclude';
      selectSql4 += ',SUM(billings_issue_input_site.light_tax) AS closing_date_light_tax_pre_exclude';
      // FROM句
      selectSql4 += ' FROM ';
      selectSql4 += '(SELECT ';
      selectSql4 += 'client_id';
      selectSql4 += ',site_id';
      selectSql4 += ',MAX(sales_unit_amount) AS sales_unit_amount';
      selectSql4 += ',MAX(normal_tax_subtotal) AS normal_tax_subtotal';
      selectSql4 += ',MAX(normal_tax) AS normal_tax';
      selectSql4 += ',MAX(light_tax_subtotal) AS light_tax_subtotal';
      selectSql4 += ',MAX(light_tax) AS light_tax';
      selectSql4 += ' FROM t_billings_issue_input_site ';
      selectSql4 += ' WHERE billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql4 += 'AND site_id > 0 ';
      selectSql4 += ' GROUP BY client_id,site_id ';
      selectSql4 += ') AS billings_issue_input_site ';
      // GROUP BY句
      selectSql4 += ' GROUP BY ';
      selectSql4 += ' billings_issue_input_site.client_id';
      selectSql4 += ',billings_issue_input_site.site_id';

      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' SubQuery.client_id';
      selectSql += ',SubQuery.site_id';
      selectSql += ',SUM(SubQuery.closing_date_receivable_sales) AS closing_date_receivable_sales';
      selectSql += ',SUM(SubQuery.closing_date_normal_tax_subtotal) AS closing_date_normal_tax_subtotal';
      selectSql += ',SUM(SubQuery.closing_date_normal_tax) AS closing_date_normal_tax';
      selectSql += ',SUM(SubQuery.closing_date_light_tax_subtotal) AS closing_date_light_tax_subtotal';
      selectSql += ',SUM(SubQuery.closing_date_light_tax) AS closing_date_light_tax';
      selectSql += ',SUM(SubQuery.closing_date_receivable_sales_pre_exclude) AS closing_date_receivable_sales_pre_exclude';
      selectSql += ',SUM(SubQuery.closing_date_normal_tax_subtotal_pre_exclude) AS closing_date_normal_tax_subtotal_pre_exclude';
      selectSql += ',SUM(SubQuery.closing_date_normal_tax_pre_exclude) AS closing_date_normal_tax_pre_exclude';
      selectSql += ',SUM(SubQuery.closing_date_light_tax_subtotal_pre_exclude) AS closing_date_light_tax_subtotal_pre_exclude';
      selectSql += ',SUM(SubQuery.closing_date_light_tax_pre_exclude) AS closing_date_light_tax_pre_exclude';
      // FROM句
      selectSql += ' FROM (';
      selectSql += selectSql1 + ' UNION ALL ' + selectSql2 + ' UNION ALL ' + selectSql3 + ' UNION ALL ' + selectSql4;
      selectSql += ') AS SubQuery ';
      // GROUP BY句
      selectSql += ' GROUP BY ';
      selectSql += ' SubQuery.client_id';
      selectSql += ',SubQuery.site_id';
      //console.log(selectSql)
      return selectSql;
    },
    /* 請求残高INSERTSQL作成処理（来月） */
    async createBillingBalancesNextMonthInsertSql() {
      //console.log('請求残高INSERTSQL作成処理（来月）');
      // 登録SQLを作成（INSERT文の前部分のため設定値不要）
      let colList = [];
      // 請求年月
      colList.push(CreateColRow('billing_month_year', null, 'NUMBER'));
      // 得意先コード
      colList.push(CreateColRow('client_id', null, 'NUMBER'));
      // 現場コード
      colList.push(CreateColRow('site_id', null, 'NUMBER'));
      // 営業所コード
      colList.push(CreateColRow('office_id', null, 'NUMBER'));
      // 得意先分類コード
      colList.push(CreateColRow('client_id_first_digit', null, 'NUMBER'));
      // 前回請求税抜残高
      colList.push(CreateColRow('pre_billing_no_tax_balance', null, 'NUMBER'));
      // 前回請求消費税残高
      colList.push(CreateColRow('pre_billing_tax_balance', null, 'NUMBER'));
      // 締日売掛売上
      colList.push(CreateColRow('closing_date_receivable_sales', null, 'NUMBER'));
      // 締日売上伝票枚数
      colList.push(CreateColRow('closing_date_sales_billing_cnt', null, 'NUMBER'));
      // 締日一括消費税額
      colList.push(CreateColRow('closing_date_bulk_tax', null, 'NUMBER'));
      // 締日伝票消費税額
      colList.push(CreateColRow('closing_date_billing_tax', null, 'NUMBER'));
      // 締日消費税額
      colList.push(CreateColRow('closing_date_tax', null, 'NUMBER'));
      // 締日入金額
      colList.push(CreateColRow('closing_date_payment', null, 'NUMBER'));
      // 締日消費税入金
      colList.push(CreateColRow('closing_date_tax_payment', null, 'NUMBER'));
      // 締日繰越税抜額
      colList.push(CreateColRow('closing_date_forward_no_tax', null, 'NUMBER'));
      // 締日繰越消費税額
      colList.push(CreateColRow('closing_date_forward_tax', null, 'NUMBER'));
      // 締日請求税抜額
      colList.push(CreateColRow('closing_date_billing_money_no_tax', null, 'NUMBER'));
      // 締日請求消費税額
      colList.push(CreateColRow('closing_date_billing_money_tax', null, 'NUMBER'));
      // 2回前請求税抜額
      colList.push(CreateColRow('two_pre_billing_money_no_tax', null, 'NUMBER'));
      // 2回前請求消費税額
      colList.push(CreateColRow('two_pre_billing_money_tax', null, 'NUMBER'));
      // 3回前請求税抜額
      colList.push(CreateColRow('three_pre_billing_money_no_tax', null, 'NUMBER'));
      // 3回前請求消費税額
      colList.push(CreateColRow('three_pre_billing_money_tax', null, 'NUMBER'));
      // 請求書出力単位区分
      colList.push(CreateColRow('billing_output_class', null, 'NUMBER'));
      // 締日通常消費税課税小計
      colList.push(CreateColRow('closing_date_normal_tax_subtotal', null, 'NUMBER'));
      // 締日通常消費税額
      colList.push(CreateColRow('closing_date_normal_tax', null, 'NUMBER'));
      // 締日軽減消費税課税小計
      colList.push(CreateColRow('closing_date_light_tax_subtotal', null, 'NUMBER'));
      // 締日軽減消費税額
      colList.push(CreateColRow('closing_date_light_tax', null, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      let insertSqlStart = 'INSERT INTO t_billings_balances (' + CreateInsertSql(colList, 'col', 't_billings_balances') + ') ';
      /* 取引先毎（当月情報を前月情報として登録） */
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT';
      selectSql1 += ' ' + formatDateCalc(this.billingMonthYear + '-01', 0, 1, 0, false, 'YYYYMM') + ' AS billing_month_year';
      selectSql1 += ',billings_balances.client_id';
      selectSql1 += ',billings_balances.site_id';
      selectSql1 += ',billings_balances.office_id';
      selectSql1 += ',billings_balances.client_id_first_digit';
      selectSql1 += ',billings_balances.closing_date_billing_money_no_tax AS pre_billing_no_tax_balance';
      selectSql1 += ',billings_balances.closing_date_billing_money_tax AS pre_billing_tax_balance';
      selectSql1 += ',0 AS closing_date_receivable_sales';
      selectSql1 += ',0 AS closing_date_sales_billing_cnt';
      selectSql1 += ',0 AS closing_date_bulk_tax';
      selectSql1 += ',0 AS closing_date_billing_tax';
      selectSql1 += ',0 AS closing_date_tax';
      selectSql1 += ',0 AS closing_date_payment';
      selectSql1 += ',0 AS closing_date_tax_payment';
      selectSql1 += ',0 AS closing_date_forward_no_tax';
      selectSql1 += ',0 AS closing_date_forward_tax';
      selectSql1 += ',0 AS closing_date_billing_money_no_tax';
      selectSql1 += ',0 AS closing_date_billing_money_tax';
      selectSql1 += ',billings_balances.pre_billing_no_tax_balance AS two_pre_billing_money_no_tax';
      selectSql1 += ',billings_balances.pre_billing_tax_balance AS two_pre_billing_money_tax';
      selectSql1 += ',billings_balances.two_pre_billing_money_no_tax AS three_pre_billing_money_no_tax';
      selectSql1 += ',billings_balances.two_pre_billing_money_tax AS three_pre_billing_money_tax';
      selectSql1 += ',billings_balances.billing_output_class';
      selectSql1 += ',0 AS closing_date_normal_tax_subtotal';
      selectSql1 += ',0 AS closing_date_normal_tax';
      selectSql1 += ',0 AS closing_date_light_tax_subtotal';
      selectSql1 += ',0 AS closing_date_light_tax';
      selectSql1 += ',\'' + this.loginId + '\'';
      selectSql1 += ',\'' + this.loginId + '\'';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += 'm_billings AS billings ';
      selectSql1 += 'INNER JOIN t_billings_balances AS billings_balances ';
      selectSql1 += 'ON billings.client_id = billings_balances.client_id ';
      selectSql1 += 'AND billings.site_id = billings_balances.site_id ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      selectSql1 += 'billings_balances.site_id = 0 ';
      selectSql1 += 'AND billings_balances.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql1 += 'AND (billings_balances.closing_date_billing_money_no_tax <> 0 OR billings_balances.closing_date_billing_money_tax <> 0 OR billings_balances.pre_billing_no_tax_balance <> 0 OR billings_balances.pre_billing_tax_balance <> 0 OR billings_balances.two_pre_billing_money_no_tax <> 0 OR billings_balances.two_pre_billing_money_tax <> 0) ';
      let insertSql = insertSqlStart + selectSql1;
      //console.log(insertSql)
      this.transactSqlList.push(insertSql);
    },
    /* 累積トランザクションUPDATESQL作成処理 */
    async createCumulativeTransactionUpdateSql() {
      //console.log('累積トランザクションUPDATESQL作成処理');
      // CRUD処理
      // 更新SQLを作成
      let updateSql = '';
      let colList = [];
      // 締日更新フラグ
      colList.push(CreateColRow('cumulative_transaction.is_update_closing_date', 1, 'NUMBER'));
      // 締次年月
      colList.push(CreateColRow('cumulative_transaction.closing_month_year', 'CAST(DATE_FORMAT(billings.billing_end_date,\'%Y%m\') AS SIGNED)', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('cumulative_transaction.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('cumulative_transaction.updated_user', this.loginId, 'VARCHAR'));
      updateSql += CreateUpdateSql(colList, 't_cumulative_transaction AS cumulative_transaction', 'm_billings AS billings');
      updateSql += ' WHERE ';
      updateSql += 'cumulative_transaction.is_update_closing_date = 0 ';
      updateSql += 'AND cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      updateSql += 'AND cumulative_transaction.client_id = billings.client_id ';
      updateSql += 'AND cumulative_transaction.site_id = billings.site_id ';
      updateSql += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      updateSql += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\',\'' + Const.TransactionId.deposit + '\') ';
      //console.log(updateSql)
      this.transactSqlList.push(updateSql);
    },
    /* 取引先マスタUPDATESQL作成処理 */
    async createClientsUpdateSql() {
      //console.log('取引先マスタUPDATESQL作成処理');
      // CRUD処理
      // 更新SQLを作成
      let updateSql = '';
      let colList = [];
      // 前回請求締切日
      colList.push(CreateColRow('clients.previous_billing_closing_date', 'billings.billing_end_date', 'DATE'));
      // 更新日
      colList.push(CreateColRow('clients.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('clients.updated_user', this.loginId, 'VARCHAR'));
      updateSql += CreateUpdateSql(colList, 'm_clients AS clients', 'm_billings AS billings');
      updateSql += ' WHERE ';
      updateSql += 'clients.client_class = ' + Const.ClientClass.customer + ' ';
      updateSql += 'AND clients.client_id = billings.client_id ';
      updateSql += 'AND billings.site_id = 0 ';
      //console.log(updateSql)
      this.transactSqlList.push(updateSql);
    },
    // 得意先請求残更新一覧作成
    async createUpdateRemainingsList() {
      // 入金クエリ
      let selectSqlQuery = '';
      selectSqlQuery += 'SELECT';
      selectSqlQuery += ' billings.client_id';
      selectSqlQuery += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN 0 ELSE cumulative_transaction.amount END) AS closing_date_payment';
      selectSqlQuery += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN cumulative_transaction.amount ELSE 0 END) AS closing_date_tax_payment';
      selectSqlQuery += ' FROM ';
      selectSqlQuery += 'm_billings AS billings ';
      selectSqlQuery += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSqlQuery += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSqlQuery += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSqlQuery += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSqlQuery += 'AND cumulative_transaction.billing_date BETWEEN billings.billing_start_date AND billings.billing_end_date ';
      selectSqlQuery += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSqlQuery += 'AND cumulative_transaction.amount <> 0 ';
      selectSqlQuery += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.deposit + '\') ';
      selectSqlQuery += ' GROUP BY ';
      selectSqlQuery += 'billings.client_id';
      // 入金のある取引先を取得
      let selectSql = 'SELECT * FROM (' + selectSqlQuery + ') AS deposit_QUERY';
      selectSql += ' WHERE '
      selectSql += 'closing_date_payment <> 0 OR closing_date_tax_payment <> 0 '
      selectSql += ' ORDER BY ';
      selectSql += 'client_id';
      //console.log(selectSql);
      let dataDepositResult = await executeSelectSql(selectSql);
      //console.log(dataDepositResult);
      if (dataDepositResult == null || dataDepositResult.length == 0) {
        // 入金情報がない場合は請求残高更新処理はないため、この先は不要
        return;
      }
      // 入金情報のある得意先のCSV作成
      let csvClientId = '';
      for (let i = 0; i < dataDepositResult.length; i++) {
        if (csvClientId != '') {
          csvClientId += ',';
        }
        csvClientId += dataDepositResult[i].client_id;
      }
      // 得意先請求残データ取得
      selectSql = '';
      selectSql += 'SELECT';
      selectSql += ' client_id';
      selectSql += ',billing_date';
      selectSql += ',billing_amount';
      selectSql += ',billing_tax';
      selectSql += ' FROM ';
      selectSql += 't_billings_remainings ';
      selectSql += ' WHERE ';
      selectSql += 'client_id IN (' + csvClientId + ') ';
      selectSql += 'AND (billing_amount <> 0 OR billing_tax <> 0)';
      selectSql += 'ORDER BY client_id,billing_date ';
      //console.log(selectSql);
      let dataRemainingsResult = await executeSelectSql(selectSql);
      //console.log(dataRemainingsResult);
      let drIndex = 0;
      for (let i = 0; i < dataDepositResult.length; i++) {
        if (drIndex < dataRemainingsResult.length && dataDepositResult[i].client_id == dataRemainingsResult[drIndex].client_id) {
          while(drIndex < dataRemainingsResult.length && dataDepositResult[i].client_id == dataRemainingsResult[drIndex].client_id) {
            this.setUpdateRemainingsList(dataDepositResult[i].client_id, dataDepositResult[i].closing_date_payment, dataDepositResult[i].closing_date_tax_payment, dataRemainingsResult[drIndex].billing_date, dataRemainingsResult[drIndex].billing_amount, dataRemainingsResult[drIndex].billing_tax);
            drIndex++;
          }
        } else {
          this.updateRemainingsList.push({clientId: dataDepositResult[i].client_id, deposit: dataDepositResult[i].closing_date_payment, depositTax: dataDepositResult[i].closing_date_tax_payment, remainings: []});
        }
      }
    },
    // 得意先請求残更新一覧にセット
    setUpdateRemainingsList(clientId, deposit, depositTax, billingDate, billingAmount, billingTax) {
      let updateRemainings = this.updateRemainingsList.find((v) => v.clientId == clientId);
      if (updateRemainings == undefined) {
        // 指定した取引先の初期登録の場合
        let workBillingAmount = 0;
        let workDeposit = 0;
        if (billingAmount < 0) {
          billingAmount = 0;
          workDeposit = billingAmount - deposit;
        } else {
          if (billingAmount >= deposit) {
            workBillingAmount = billingAmount - deposit;
            workDeposit = 0;
          } else {
            workBillingAmount = 0;
            workDeposit = deposit - billingAmount;
          }
        }
        let workDepositTax = 0;
        let workBillingTax = 0;
        if (billingTax < 0) {
          workBillingTax = billingTax - depositTax;
          workDepositTax = 0;
        } else {
          if (billingTax >= depositTax) {
            workBillingTax = billingTax - depositTax;
            workDepositTax = 0;
          } else {
            workBillingTax = 0;
            workDepositTax = depositTax - billingTax;
          }
        }
        this.updateRemainingsList.push({clientId: clientId, deposit: workDeposit, depositTax: workDepositTax, remainings: [{billingDate: billingDate, billingAmount: workBillingAmount, billingTax: workBillingTax}]});
      } else {
        // 既に同じ取引先の別の日付の請求残を入れている場合
        // 取引先が既に登録されている場合は別の日付の残高追加
        let zanDeposit = updateRemainings.deposit;
        let zanDepositTax = updateRemainings.depositTax;
        // 入金の残額が0の場合は何もしない
        if (zanDeposit == 0 && zanDepositTax == 0) {
          return;
        }
        // 指定した取引先の初期登録の場合
        let workBillingAmount = 0;
        let workDeposit = 0;
        if (billingAmount < 0) {
          billingAmount = 0;
          workDeposit = billingAmount - zanDeposit;
        } else {
          if (billingAmount >= zanDeposit) {
            workBillingAmount = billingAmount - zanDeposit;
            workDeposit = 0;
          } else {
            workBillingAmount = 0;
            workDeposit = zanDeposit - billingAmount;
          }
        }
        let workDepositTax = 0;
        let workBillingTax = 0;
        if (billingTax < 0) {
          workBillingTax = billingTax - zanDepositTax;
          workDepositTax = 0;
        } else {
          if (billingTax >= zanDepositTax) {
            workBillingTax = billingTax - zanDepositTax;
            workDepositTax = 0;
          } else {
            workBillingTax = 0;
            workDepositTax = zanDepositTax - billingTax;
          }
        }
        updateRemainings.deposit = workDeposit;
        updateRemainings.depositTax = workDepositTax;
        updateRemainings.remainings.push({billingDate: billingDate, billingAmount: workBillingAmount, billingTax: workBillingTax});
      }
    },
    // 得意先請求残INSERTSQL作成処理（入金無し得意先）
    async createInsertBillingsRemainingsNoDeposit() {
      // 入金がなかった得意先については、当月の売上額をそのまま登録
      //console.log('得意先請求残INSERTSQL作成処理（入金無し得意先）');
      let colList = [];
      // 取引先コード
      colList.push(CreateColRow('client_id', null, 'NUMBER'));
      // 請求日
      colList.push(CreateColRow('billing_date', null, 'DATE'));
      // 請求金額
      colList.push(CreateColRow('billing_amount', null, 'NUMBER'));
      // 請求消費税
      colList.push(CreateColRow('billing_tax', null, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      let selectSql = 'INSERT INTO t_billings_remainings (' + CreateInsertSql(colList, 'col', 't_billings_remainings') + ') ';
      selectSql += 'SELECT ';
      selectSql += 'billings_balances.client_id';
      selectSql += ',billings_balances.billing_end_date';
      selectSql += ',billings_balances.closing_date_receivable_sales';
      selectSql += ',billings_balances.closing_date_tax';
      selectSql += ',\'' + this.loginId + '\'';
      selectSql += ',\'' + this.loginId + '\'';
      selectSql += ' FROM t_billings_balances AS billings_balances ';
      selectSql += 'INNER JOIN m_billings AS billings ';
      selectSql += 'ON billings.client_id = billings_balances.client_id ';
      selectSql += 'AND billings.site_id = billings_balances.site_id ';
      selectSql += ' WHERE ';
      selectSql += 'billings_balances.site_id = 0 ';
      selectSql += 'AND billings_balances.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql += 'AND (billings_balances.closing_date_receivable_sales <> 0 OR billings_balances.closing_date_tax <> 0) ';
      selectSql += 'AND billings_balances.closing_date_payment = 0 ';
      selectSql += 'AND billings_balances.closing_date_tax_payment = 0 ';
      //console.log(selectSql)
      this.transactSqlList.push(selectSql);
    },
    // 得意先請求残MERGESQL作成処理（入金あり得意先、過去月の更新）
    async createMergeBillingsRemainings() {
      //console.log('得意先請求残MERGESQL作成処理（入金あり得意先、過去月の更新）');
      // 製品の分だけループして登録SQLを作成
      let bulkInsertSql = '';
      for (let i = 0; i < this.updateRemainingsList.length; i++) {
        for (let j = 0; j < this.updateRemainingsList[i].remainings.length; j++) {
          let colList = [];
          // 取引先コード
          colList.push(CreateColRow('client_id', this.updateRemainingsList[i].clientId, 'NUMBER'));
          // 請求日
          colList.push(CreateColRow('billing_date', this.updateRemainingsList[i].remainings[j].billingDate, 'DATE'));
          // 請求金額
          colList.push(CreateColRow('billing_amount', this.updateRemainingsList[i].remainings[j].billingAmount, 'NUMBER'));
          // 請求消費税
          colList.push(CreateColRow('billing_tax', this.updateRemainingsList[i].remainings[j].billingTax, 'NUMBER'));
          // 作成ユーザー
          colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
          if (bulkInsertSql == '') {
            bulkInsertSql += 'INSERT INTO t_billings_remainings (' + CreateInsertSql(colList, 'col', 't_billings_remainings') + ') VALUES ';
          } else {
            bulkInsertSql += ',';
          }
          bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_estimate') + ')';
          if (bulkInsertSql.length >= Const.SqlMaxLength) {
            colList = [];
            // 売上単価
            colList.push(CreateColRow('billing_amount', 'billing_amount', 'COLUMN'));
            // 理由
            colList.push(CreateColRow('billing_tax', 'billing_tax', 'COLUMN'));
            // 更新日
            colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
            // 更新ユーザー
            colList.push(CreateColRow('updated_user', 'updated_user', 'COLUMN'));
            bulkInsertSql += CreateMergeSql(colList);
            //console.log(bulkInsertSql)
            this.transactSqlList.push(bulkInsertSql);
            bulkInsertSql = '';
          }
        }
      }
      if (bulkInsertSql != '') {
        let colList = [];
        // 売上単価
        colList.push(CreateColRow('billing_amount', 'billing_amount', 'COLUMN'));
        // 理由
        colList.push(CreateColRow('billing_tax', 'billing_tax', 'COLUMN'));
        // 更新日
        colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', 'updated_user', 'COLUMN'));
        bulkInsertSql += CreateMergeSql(colList);
        //console.log(bulkInsertSql)
        this.transactSqlList.push(bulkInsertSql);
      }
    },
    // 得意先請求残INSERTSQL作成処理（入金あり得意先、最新月の登録）
    async createInsertBillingsRemainingsDeposit() {
      //console.log('得意先請求残INSERTSQL作成処理（入金あり得意先、最新月の登録）');
      // 入金のあった得意先分だけループして登録SQLを作成
      let bulkInsertSqlStart = '';
      let bulkInsertSql = '';
      for (let i = 0; i < this.updateRemainingsList.length; i++) {
        if (bulkInsertSqlStart == '') {
          let colList = [];
          // 取引先コード
          colList.push(CreateColRow('client_id', null, 'NUMBER'));
          // 請求日
          colList.push(CreateColRow('billing_date', null, 'DATE'));
          // 請求金額
          colList.push(CreateColRow('billing_amount', null, 'NUMBER'));
          // 請求消費税
          colList.push(CreateColRow('billing_tax', null, 'NUMBER'));
          // 作成ユーザー
          colList.push(CreateColRow('created_user', null, 'VARCHAR'));
          // 更新ユーザー
          colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
          bulkInsertSqlStart = 'INSERT INTO t_billings_remainings (' + CreateInsertSql(colList, 'col', 't_billings_remainings') + ') ';
          //console.log(bulkInsertSqlStart);
        }
        let selectSql = 'SELECT ';
        selectSql += 'billings_balances.client_id';
        selectSql += ',billings_balances.billing_end_date';
        selectSql += ',billings_balances.closing_date_receivable_sales - ' + this.updateRemainingsList[i].deposit;
        selectSql += ',billings_balances.closing_date_tax - ' + this.updateRemainingsList[i].depositTax;
        selectSql += ',\'' + this.loginId + '\'';
        selectSql += ',\'' + this.loginId + '\'';
        selectSql += ' FROM t_billings_balances AS billings_balances ';
        selectSql += 'INNER JOIN m_billings AS billings ';
        selectSql += 'ON billings.client_id = billings_balances.client_id ';
        selectSql += 'AND billings.site_id = billings_balances.site_id ';
        selectSql += ' WHERE ';
        selectSql += 'billings_balances.site_id = 0 ';
        selectSql += 'AND billings_balances.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
        selectSql += 'AND billings_balances.client_id = ' + this.updateRemainingsList[i].clientId + ' ';
        //console.log(selectSql);
        if (bulkInsertSql != '') {
          bulkInsertSql += 'UNION ALL ';
        }
        bulkInsertSql += selectSql;
        if (bulkInsertSql.length >= Const.SqlMaxLength) {
          this.transactSqlList.push(bulkInsertSqlStart + bulkInsertSql);
          bulkInsertSql = '';
        }
      }
      if (bulkInsertSql != '') {
        this.transactSqlList.push(bulkInsertSqlStart + bulkInsertSql);
      }
    },
    // 得意先請求残DELETESQL作成処理
    async createDeleteBillingsRemainings() {
      //console.log('得意先請求残DELETESQL作成処理');
      // 得意先請求残の削除SQL（金額と消費税両方が0のレコードを削除）
      let deleteSql = 'DELETE FROM ';
      deleteSql += 't_billings_remainings';
      deleteSql += ' WHERE ';
      deleteSql += 'billing_amount = 0 ';
      deleteSql += 'AND billing_tax = 0 ';
      //console.log(deleteSql);

      this.transactSqlList.push(deleteSql);
    },
    // 締更新完了登録SQL作成
    async insertClosingUpdateCompletion() {
      //console.log('締更新完了登録SQL作成');
      // 登録SQLを作成
      let colList = [];
      // 請求支払区分
      colList.push(CreateColRow('billing_payment_class', null, 'NUMBER'));
      // 処理年月
      colList.push(CreateColRow('process_month_year', null, 'NUMBER'));
      // 締日
      colList.push(CreateColRow('closing_date', null, 'NUMBER'));
      // 取引先コード開始
      colList.push(CreateColRow('client_id_start', null, 'NUMBER'));
      // 取引先コード終了
      colList.push(CreateColRow('client_id_end', null, 'NUMBER'));
      // 作成ユーザー
      colList.push(CreateColRow('created_user', null, 'VARCHAR'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
      let insertSql = 'INSERT INTO t_closing_update_completion (' + CreateInsertSql(colList, 'col', 't_closing_update_completion') + ') ';
      // 現場と結合
      let selectSql = '';
      selectSql += 'SELECT';
      selectSql += ' billing_payment_class';
      selectSql += ',process_month_year';
      selectSql += ',closing_date';
      selectSql += ',client_id_start';
      selectSql += ',client_id_end';
      selectSql += ',\'' + this.loginId + '\'';
      selectSql += ',\'' + this.loginId + '\'';
      selectSql += ' FROM t_closing_update_range ';
      selectSql += ' WHERE ';
      selectSql += 'billing_payment_class = ' + Const.BillingPaymentClass.billing + ' ';

      insertSql += selectSql;

      //console.log(insertSql)
      this.transactSqlList.push(insertSql);
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    // 請求日（開始日と終了日）の取得
    async getBillingDateInfo() {
      // 各種データ取得（非同期でまとめて取得した方が早いため）
      let preBillingResult = null;
      let controlData = null;
      let where_clause = 'AND billing_payment_class = ' + Const.BillingPaymentClass.billing + ' ';
      [preBillingResult, controlData] = await Promise.all([
        selectOneTable('t_closing_update_range', where_clause),
        getControlMaster(),
      ]);
      this.controlMasterData.processMonthYear = controlData.process_month_year;
      this.controlMasterData.taxRate = controlData.tax_rate;
      this.controlMasterData.newTaxRate = controlData.new_tax_rate;
      this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
      this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
      this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
      if (preBillingResult != null && preBillingResult.length > 0) {
        this.preBilling.processMonthYear = preBillingResult[0].process_month_year;
        this.preBilling.closingDate = preBillingResult[0].closing_date;
        this.preBilling.billingStartDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, true);
        this.preBilling.billingEndDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, false);
      } else {
        this.preBilling.processMonthYear = 0;
        this.preBilling.closingDate = 0;
        this.preBilling.billingStartDate = '';
        this.preBilling.billingEndDate = '';
      }
    },
  },
});
</script>
<style scoped>
</style>