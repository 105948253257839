<template>
  <div>
    <div v-if="getMessageFlg == true">
      <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
        <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="denpyo in listDenpyo" v-bind:key="denpyo.salesNumber">
      <div v-for="billingType in listBillingTypeList" v-bind:key="denpyo.salesNumber + '_' + billingType">
        <div v-if="billingType == constData.billingTypeNohinSei || billingType == constData.billingTypeNohinHikae || billingType == constData.billingTypeSeikyu">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeNohinFuku">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinSecondNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeNohinSeiClient">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinClientNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeNohinFukuClient">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinSecondClientNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeZuryo1 || billingType == constData.billingTypeZuryo2">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinReceiptNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeZuryoClient">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <TemplateNouhinReceiptClientNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeNohinFukuOrder">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <div v-if="denpyo.clientPrintClass == htmlConst.OrderPrintClass.customerPrint">
              <TemplateNouhinSecondNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
            </div>
            <div v-if="denpyo.clientPrintClass != htmlConst.OrderPrintClass.customerPrint">
              <TemplateNouhinSecondOrderNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
            </div>
          </div>
        </div>
        <div v-if="billingType == constData.billingTypeZuryoOrder1 || billingType == constData.billingTypeZuryoOrder2">
          <div v-for="chouhyou in denpyo.listChouhyou" v-bind:key="denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page">
            <div v-if="denpyo.clientPrintClass == htmlConst.OrderPrintClass.customerPrint">
              <TemplateNouhinReceiptNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
            </div>
            <div v-if="denpyo.clientPrintClass != htmlConst.OrderPrintClass.customerPrint">
              <TemplateNouhinReceiptOrderNormal :id="constData.chouhyouId + denpyo.salesNumber + '_' + billingType + '_' + chouhyou.page" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TemplateNouhinNormal from '@/assets/svg/nouhin_Normal.svg';
import TemplateNouhinClientNormal from '@/assets/svg/nouhinClient_Normal.svg';
import TemplateNouhinSecondNormal from '@/assets/svg/nouhinSecond_Normal.svg';
import TemplateNouhinSecondClientNormal from '@/assets/svg/nouhinSecondClient_Normal.svg';
import TemplateNouhinSecondOrderNormal from '@/assets/svg/nouhinSecondOrder_Normal.svg';
import TemplateNouhinReceiptNormal from '@/assets/svg/nouhinReceipt_Normal.svg';
import TemplateNouhinReceiptClientNormal from '@/assets/svg/nouhinReceiptClient_Normal.svg';
import TemplateNouhinReceiptOrderNormal from '@/assets/svg/nouhinReceiptOrder_Normal.svg';
import { setPaperA4, setChouhyouBodyStyle, addOperationLogs, formatDate, formatCurDate, formatDateCalc, getListValue, getNullStr, executeSelectSql, getControlMaster, selectOneTable } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'sales-direct-nouhin';

export default {
  name: 'SALES-DIRECT-NOUHIN',
  /* コンポーネント */
  components: {
    TemplateNouhinNormal,
    TemplateNouhinClientNormal,
    TemplateNouhinSecondNormal,
    TemplateNouhinSecondClientNormal,
    TemplateNouhinSecondOrderNormal,
    TemplateNouhinReceiptNormal,
    TemplateNouhinReceiptClientNormal,
    TemplateNouhinReceiptOrderNormal,
  },
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        productCntByChouhyou: 6,
        chouhyouId: 'idChouhyou',
        addressOfficeId_1: 1,
        addressOfficeId_2: 2,
        billingTypeNohinSei: 1,
        billingTypeNohinFuku: 2,
        billingTypeNohinHikae: 3,
        billingTypeNohinSeiClient: 4,
        billingTypeNohinFukuClient: 5,
        billingTypeSeikyu: 6,
        billingTypeZuryo1: 7,
        billingTypeZuryo2: 8,
        billingTypeZuryoClient: 9,
        billingTypeFusoku: 10,
        billingTypeNohinFukuOrder: 11,
        billingTypeZuryoOrder1: 12,
        billingTypeZuryoOrder2: 13,
        taxId: 0,
        taxName: '消費税',
      },
      // ヘッダ
      menu_type: 'user',
      title: '納品書（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      chouhyouSize: {
        width: '315mm',
        height: '222.5mm',
      },
      listDenpyo: [],
      listBillingTypeList: [],
      // 営業所情報
      officeList: [{
        keyNo:'',
        zipCode: '',
        address: '',
        phoneNumber: '',
        faxNumber: '',
        Email: '',
      },
      {
        keyNo:'',
        zipCode: '',
        address: '',
        phoneNumber: '',
        faxNumber: '',
      }],
      // アラート
      alertWarning: [],
      alertDanger: [],
      // トランザクションSQLリスト
      transactSqlList:[],
      // 伝票種類
      checkBillingTypeClass: 0,
      // パラメータ
      receivedOrderId: null,
      billingNo: null,
      monthlyMonthYear: null,
      isBulkPrintFlg: 0,
      // 定数（htmlで使用）
      htmlConst: {
        // 発注書印字区分
        OrderPrintClass: {
          customerPrint: Const.OrderPrintClass.customerPrint,
          customerNoPrint: Const.OrderPrintClass.customerNoPrint,
        },
      },
      // サービス区分
      serviceList: [],
      // コントロールマスタ
      controlMasterData: {
        lightTaxMark: '',
      },
    }
  },
  /* マウント */
  mounted() {
    // パラメータ取得
    this.receivedOrderId = this.$route.query.receivedOrderId;
    this.billingNo = this.$route.query.billingNo;
    this.monthlyMonthYear = this.$route.query.monthlyMonthYear;
    if (this.$route.query.isBulkPrintFlg == '1') {
      this.isBulkPrintFlg = true;
    } else {
      this.isBulkPrintFlg = false;
    }
    // 印刷レイアウト設定
    setPaperA4();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    // 初期設定
    this.fetchData();
    // 印刷ファイルのデフォルト名
    document.title = '納品書_' + formatCurDate('YYYY-MM-DD');
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* 関数群 */
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let serviceListData = null;
        let controlData = null;
        [serviceListData, controlData] = await Promise.all([
          selectOneTable('m_service_classes'),
          getControlMaster(),
        ]);
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        //console.log(serviceListData);
        for(let i = 0; i < serviceListData.length; i++){
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // コントロールマスタ
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 表示データ設定
        await this.setDispData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        this.listChouhyou = [];
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 表示データ設定 */
    async setDispData() {
      // 検索SQL作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null && dataResult.length > 0) {
        // 伝票種類
        this.checkBillingTypeClass = dataResult[0].billing_type_class;
        // 伝票種類一覧作成
        this.createBillingTypeList();
        // 営業所情報取得
        await this.getOfficesData();
        // 伝票毎の一覧を作成
        await this.initListDenpyo(dataResult);
        // ページ毎の置換文字列設定
        await this.createReplacementsPage(dataResult);
        // 作成した置換文字データをSVGファイルに設定
        this.setChouhyou();
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','納品書'));
        return;
      }
    },
    /* 検索SELECT文字列作成 */
    makeSelectSql: function() {
      let selectSql = '';
      if (this.receivedOrderId != '0') {
        // 受注あり
        /* SELECT句 */
        selectSql += 'SELECT ';
        selectSql += ' orders_receives.zip_code';
        selectSql += ',orders_receives.address_1';
        selectSql += ',orders_receives.address_2';
        selectSql += ',orders_receives.phone_number';
        selectSql += ',clients.fax_number';
        selectSql += ',orders_receives.client_name AS client_name_kanji';
        selectSql += ',orders_receives.department_1';
        selectSql += ',orders_receives.department_2';
        selectSql += ',clients_sites.order_print_class AS client_print_class';
        selectSql += ',clients_sites.order_print_client_name AS client_print_name';
        selectSql += ',orders_receives.client_id';
        selectSql += ',transaction.billing_date AS sales_issue_date';
        selectSql += ',transaction.billing_no AS sales_number';
        selectSql += ',transaction.bill_class';
        selectSql += ',clients.closing_date';
        selectSql += ',orders_receives.staff_id';
        selectSql += ',clients.check_contents';
        selectSql += ',orders_receives.product_id';
        selectSql += ',orders_receives.product_name AS product_name_kanji';
        selectSql += ',transaction.product_quantity AS shipping_quantity';
        selectSql += ',orders_receives.unit';
        selectSql += ',orders_receives.order_receive_unit_amount';
        selectSql += ',orders_receives.order_receive_amount';
        selectSql += ',IfNull(orders_receives.place_1,\'\') AS place_1';
        selectSql += ',IfNull(orders_receives.place_2,\'\') AS place_2';
        selectSql += ',IfNull(orders_receives.place_3,\'\') AS place_3';
        selectSql += ',IfNull(orders_receives.place_4,\'\') AS place_4';
        selectSql += ',transaction.delivery_design_date';
        selectSql += ',transaction.delivery_design_class';
        selectSql += ',transaction.delivery_design';
        selectSql += ',transaction.site_id';
        selectSql += ',transaction.shipping_code';
        selectSql += ',transaction.client_site_name AS client_site_name_kanji';
        selectSql += ',transaction.client_site_zip_code';
        selectSql += ',transaction.client_site_address_1';
        selectSql += ',transaction.client_site_address_2';
        selectSql += ',transaction.delivery_location';
        selectSql += ',transaction.client_site_phone_number';
        selectSql += ',transaction.product_tax';
        selectSql += ',clients.billing_type_class';
        selectSql += ',orders_receives.order_receive_id';
        selectSql += ',orders_receives.order_receive_date';
        selectSql += ',orders_receives.order_receive_staff_id';
        selectSql += ',orders_receives.service_class';
        selectSql += ',orders_receives.product_manual_input_class AS sundries_class';
        selectSql += ',products.product_tax_rate_class_sales';
        /* FROM句 */
        selectSql += ' FROM ';
        selectSql += 't_cumulative_transaction AS transaction ';
        selectSql += 'LEFT JOIN t_orders_receives AS orders_receives ';
        selectSql += 'ON transaction.order_receive_id = orders_receives.order_receive_id ';
        selectSql += 'AND transaction.order_receive_row = orders_receives.order_receive_row ';
        selectSql += 'INNER JOIN m_clients AS clients ';
        selectSql += 'ON transaction.client_class = clients.client_class ';
        selectSql += 'AND transaction.client_id = clients.client_id ';
        selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
        selectSql += 'ON transaction.client_id = clients_sites.client_id ';
        selectSql += 'AND transaction.site_id = clients_sites.site_id ';
        selectSql += 'LEFT JOIN m_products AS products ';
        selectSql += 'ON transaction.product_id = products.product_id ';
        /* WHERE句 */
        selectSql += ' WHERE ';
        // 受注番号
        selectSql += 'transaction.order_receive_id = ' + this.receivedOrderId + ' ';
        // ﾄﾗﾝｻﾞｸｼｮﾝID
        selectSql += 'AND transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
        // 削除済みフラグ
        selectSql += 'AND (orders_receives.is_deleted = 0 OR orders_receives.order_receive_id IS NULL) ';
        /* ORDER BY句 */
        selectSql += 'ORDER BY transaction.billing_row ';
      } else {
        // 受注なし
        /* SELECT句 */
        selectSql += 'SELECT ';
        selectSql += ' clients.zip_code';
        selectSql += ',clients.address_1';
        selectSql += ',clients.address_2';
        selectSql += ',clients.phone_number';
        selectSql += ',clients.fax_number';
        selectSql += ',clients.client_name_kanji';
        selectSql += ',clients.department_1';
        selectSql += ',clients.department_2';
        selectSql += ',clients_sites.order_print_class AS client_print_class';
        selectSql += ',clients_sites.order_print_client_name AS client_print_name';
        selectSql += ',transaction.client_id';
        selectSql += ',transaction.billing_date AS sales_issue_date';
        selectSql += ',transaction.billing_no AS sales_number';
        selectSql += ',transaction.bill_class';
        selectSql += ',clients.closing_date';
        selectSql += ',transaction.staff_id';
        selectSql += ',clients.check_contents';
        selectSql += ',CASE WHEN transaction.product_id = 0 THEN NULL ELSE transaction.product_id END AS product_id';
        selectSql += ',transaction.product_name AS product_name_kanji';
        selectSql += ',transaction.product_quantity AS shipping_quantity';
        selectSql += ',transaction.product_unit AS unit';
        selectSql += ',transaction.product_sales_unit_price AS order_receive_unit_amount';
        selectSql += ',transaction.product_sales_unit_amount AS order_receive_amount';
        selectSql += ',IfNull(products_details.place_1,\'\') AS place_1';
        selectSql += ',IfNull(products_details.place_2,\'\') AS place_2';
        selectSql += ',IfNull(products_details.place_3,\'\') AS place_3';
        selectSql += ',IfNull(products_details.place_4,\'\') AS place_4';
        selectSql += ',transaction.delivery_design_date';
        selectSql += ',transaction.delivery_design_class';
        selectSql += ',transaction.delivery_design';
        selectSql += ',transaction.site_id';
        selectSql += ',transaction.shipping_code';
        selectSql += ',transaction.client_site_name AS client_site_name_kanji';
        selectSql += ',transaction.client_site_zip_code';
        selectSql += ',transaction.client_site_address_1';
        selectSql += ',transaction.client_site_address_2';
        selectSql += ',transaction.delivery_location';
        selectSql += ',transaction.client_site_phone_number';
        selectSql += ',transaction.product_tax';
        selectSql += ',clients.billing_type_class';
        selectSql += ',CASE WHEN transaction.order_receive_id = 0 THEN NULL ELSE transaction.order_receive_id END AS order_receive_id';
        selectSql += ',CASE WHEN transaction.order_receive_id = 0 THEN \'\' ELSE transaction.process_date END AS order_receive_date';
        selectSql += ',transaction.order_receive_staff_id';
        selectSql += ',transaction.service_class';
        selectSql += ',transaction.product_mouth_class AS sundries_class';
        selectSql += ',products.product_tax_rate_class_sales';
        /* FROM句 */
        selectSql += ' FROM ';
        selectSql += 't_cumulative_transaction AS transaction ';
        selectSql += 'INNER JOIN m_clients AS clients ';
        selectSql += 'ON transaction.client_class = clients.client_class ';
        selectSql += 'AND transaction.client_id = clients.client_id ';
        selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
        selectSql += 'ON transaction.client_id = clients_sites.client_id ';
        selectSql += 'AND transaction.site_id = clients_sites.site_id ';
        selectSql += 'LEFT JOIN m_products AS products ';
        selectSql += 'ON transaction.product_id = products.product_id ';
        selectSql += 'LEFT JOIN m_products_details AS products_details ';
        selectSql += 'ON transaction.product_id = products_details.product_id ';
        selectSql += 'AND transaction.office_id = products_details.office_id ';
        /* WHERE句 */
        selectSql += ' WHERE ';
        if (this.isBulkPrintFlg == true) {
          // ﾄﾗﾝｻﾞｸｼｮﾝID
          selectSql += 'transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
          // 伝票番号
          selectSql += 'AND transaction.billing_no IN (' + this.billingNo + ') ';
          // 伝票番号と月次年月の組合せで絞り込む
          let billingNoList = this.billingNo.split(',');
          let monthlyMonthYearList = this.monthlyMonthYear.split(',');
          let whereSql = '';
          for (let i = 0; i < billingNoList.length; i++) {
            if (i > 0) {
              whereSql += ' OR ';
            }
            whereSql += '(transaction.billing_no = ' + billingNoList[i] + ' AND transaction.monthly_month_year = ' + monthlyMonthYearList[i] + ') ';
          }
          selectSql += 'AND (' + whereSql + ') ';
          /* ORDER BY句 */
          selectSql += 'ORDER BY transaction.billing_date,transaction.billing_no,transaction.billing_row ';
        } else {
          // ﾄﾗﾝｻﾞｸｼｮﾝID
          selectSql += 'transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
          // 伝票番号
          selectSql += 'AND transaction.billing_no = ' + this.billingNo + ' ';
          // 月次年月
          selectSql += 'AND transaction.monthly_month_year = ' + this.monthlyMonthYear + ' ';
          /* ORDER BY句 */
          selectSql += 'ORDER BY transaction.billing_row ';
        }
      }

      return selectSql;
    },
    /* 出力する伝票の種類の一覧を作成 */
    createBillingTypeList: function() {
      if (this.checkBillingTypeClass == Const.BillingTypeClass.normal) {
        this.listBillingTypeList.push(this.constData.billingTypeNohinSei);
        this.listBillingTypeList.push(this.constData.billingTypeNohinFuku);
        this.listBillingTypeList.push(this.constData.billingTypeZuryo1);
        this.listBillingTypeList.push(this.constData.billingTypeZuryo2);
      } else if (this.checkBillingTypeClass == Const.BillingTypeClass.special3) {
        this.listBillingTypeList.push(this.constData.billingTypeNohinSei);
        this.listBillingTypeList.push(this.constData.billingTypeNohinFukuOrder);
        this.listBillingTypeList.push(this.constData.billingTypeZuryoOrder1);
        this.listBillingTypeList.push(this.constData.billingTypeZuryoOrder2);
      } else if (this.checkBillingTypeClass == Const.BillingTypeClass.special1) {
        this.listBillingTypeList.push(this.constData.billingTypeNohinHikae);
        this.listBillingTypeList.push(this.constData.billingTypeSeikyu);
        this.listBillingTypeList.push(this.constData.billingTypeNohinSei);
        this.listBillingTypeList.push(this.constData.billingTypeNohinFuku);
        this.listBillingTypeList.push(this.constData.billingTypeZuryo1);
        this.listBillingTypeList.push(this.constData.billingTypeZuryo2);
      } else {
        this.listBillingTypeList.push(this.constData.billingTypeNohinSei);
        this.listBillingTypeList.push(this.constData.billingTypeNohinSeiClient);
        this.listBillingTypeList.push(this.constData.billingTypeNohinFukuClient);
        this.listBillingTypeList.push(this.constData.billingTypeZuryoClient);
      }
    },
    /* 営業所情報を取得 */
    async getOfficesData() {
      // 営業所データ取得
      let officeListData = await selectOneTable('m_offices');
      //console.log(officeListData);
      for(let i = 0; i < officeListData.length; i++) {
        if (this.constData.addressOfficeId_1 == officeListData[i].office_id ||
          this.constData.addressOfficeId_2 == officeListData[i].office_id) {
          if (this.constData.addressOfficeId_1 == officeListData[i].office_id) {
            this.officeList[0].keyNo = '1';
            this.officeList[0].zipCode = '〒' + getNullStr(officeListData[i].zip_code);
            this.officeList[0].address = getNullStr(officeListData[i].address_1) + getNullStr(officeListData[i].address_2);
            this.officeList[0].phoneNumber = 'TEL ' + getNullStr(officeListData[i].phone_number);
            this.officeList[0].faxNumber = 'FAX ' + getNullStr(officeListData[i].fax_number);
            this.officeList[0].Email = officeListData[i].e_mail;
          } else {
            this.officeList[1].keyNo = '2';
            this.officeList[1].zipCode = '〒' + getNullStr(officeListData[i].zip_code);
            this.officeList[1].address = getNullStr(officeListData[i].address_1) + getNullStr(officeListData[i].address_2);
            this.officeList[1].phoneNumber = 'TEL ' + getNullStr(officeListData[i].phone_number);
            this.officeList[1].faxNumber = 'FAX ' + getNullStr(officeListData[i].fax_number);
          }
        }
      }
    },
    /* 伝票毎の一覧を作成 */
    async initListDenpyo(result) {
      if (this.isBulkPrintFlg == true) {
        let productCnt = 0;
        let preSalesNumber = null;
        let preSalesIssueDate = null;
        let preClientPrintClass = null;
        let preClientPrintName = null;
        for (let i = 0; i < result.length; i++) {
          if (preSalesNumber != result[i].sales_number) {
            // 伝票番号が変わった場合
            if (preSalesNumber != null) {
              // 伝票番号が変わった場合、前の伝票の残りを作成
              this.listDenpyo.push({ salesNumber: preSalesNumber, salesIssueDate: formatDate(preSalesIssueDate), listChouhyou: this.createListChouhyou(productCnt), clientPrintClass: preClientPrintClass, clientPrintName: preClientPrintName });
            }
            // 伝票情報を記録
            preSalesNumber = result[i].sales_number;
            preSalesIssueDate = result[i].sales_issue_date;
            preClientPrintClass = result[i].client_print_class;
            preClientPrintName = result[i].client_print_name;
            // 商品数を初期化
            productCnt = 0;
          }
          productCnt++;
        }
        // 最後の伝票の残りを作成
        this.listDenpyo.push({ salesNumber: preSalesNumber, salesIssueDate: formatDate(preSalesIssueDate), listChouhyou: this.createListChouhyou(productCnt), clientPrintClass: preClientPrintClass, clientPrintName: preClientPrintName });
      } else {
        this.listDenpyo.push({ salesNumber: result[0].sales_number, salesIssueDate: formatDate(result[0].sales_issue_date), listChouhyou: this.createListChouhyou(result.length), clientPrintClass: result[0].client_print_class, clientPrintName: result[0].client_print_name });
      }
    },
    /* 帳票リスト作成 */
    createListChouhyou: function(productCnt) {
      //console.log('createListChouhyou');
      let listChouhyou = [];
      let page = 1;
      let productCntNokori = productCnt;
      while (productCntNokori > 0) {
        listChouhyou.push({page: page, replacements: []});
        productCntNokori -= this.constData.productCntByChouhyou;
        page++;
      }
      return listChouhyou;
    },
    /* 伝票毎の置換配列セット */
    async createReplacementsPage(result) {
      //console.log('createReplacementsPage');
      let index = 0;
      let workIndex = 0;
      let strWork = '';
      let totalMoney = 0;
      let chkWidth = false;
      for (let k = 0; k < this.listDenpyo.length; k++) {
        totalMoney = 0;
        for (let i = 0; i < this.listDenpyo[k].listChouhyou.length; i++) {
          if (index < result.length) {
            workIndex = index;
          } else {
            workIndex = result.length - 1;
          }
          // SVGファイルの置換用文字列
          let replacements = [];
          // ページ番号
          replacements.push({key: '%P%', value: this.listDenpyo[k].listChouhyou[i].page + ' / ' + this.listDenpyo[k].listChouhyou.length, textAnchor: 'end', textLength: 178, chkWidth: false});
          // タイトル
          replacements.push({key: '%タイトル%', value: '', textAnchor: 'middle', textLength: 585, chkWidth: false});
          // 返品表示
          if (result[workIndex].bill_class == Const.Tradition.salesReturn) {
            // 伝票種別が返品の場合
            replacements.push({key: '%返品%', value: '返品', textAnchor: 'middle', textLength: 585, chkWidth: false});
          } else {
            // 伝票種別が返品の場合
            replacements.push({key: '%返品%', value: '', textAnchor: 'middle', textLength: 585, chkWidth: false});
          }
          // 受注日付
          strWork = result[workIndex].order_receive_date == null ? '' : formatDate(result[workIndex].order_receive_date);
          replacements.push({key: '%受注日付%', value: strWork, textAnchor: 'start', textLength: 200, chkWidth: false});
          // 受注番号
          strWork = result[workIndex].order_receive_id == null ? '' : result[workIndex].order_receive_id;
          replacements.push({key: '%受注番号%', value: strWork, textAnchor: 'start', textLength: 250, chkWidth: false});
          // 受注処理担当者
          replacements.push({key: '%受注担当%', value: result[workIndex].order_receive_staff_id, textAnchor: 'start', textLength: 180, chkWidth: false});
          // 郵便番号
          replacements.push({key: '%郵便番号%', value: '〒' + getNullStr(result[workIndex].zip_code), textAnchor: 'start', textLength: 300, chkWidth: false});
          // 住所１
          replacements.push({key: '%取住所1%', value: result[workIndex].address_1, textAnchor: 'start', textLength: 720, chkWidth: true});
          // 住所２
          replacements.push({key: '%取住所2%', value: result[workIndex].address_2, textAnchor: 'start', textLength: 720, chkWidth: true});
          // 取引電話
          replacements.push({key: '%取TEL%', value: result[workIndex].phone_number, textAnchor: 'end', textLength: 720, chkWidth: true});
          // 取引先名
          replacements.push({key: '%取引先名%', value: result[workIndex].client_name_kanji, textAnchor: 'start', textLength: 589, chkWidth: true});
          // 部署１
          replacements.push({key: '%部署1%', value: result[workIndex].department_1, textAnchor: 'start', textLength: 450, chkWidth: true});
          // 部署２
          replacements.push({key: '%部署2%', value: result[workIndex].department_2, textAnchor: 'start', textLength: 450, chkWidth: true});
          // 営業所情報
          await this.setOfficesData(replacements);
          // 顧客コード
          replacements.push({key: '%顧客C%', value: result[workIndex].client_id, textAnchor: 'middle', textLength: 170, chkWidth: true});
          // 伝票日付
          replacements.push({key: '%伝日%', value: formatDate(result[workIndex].sales_issue_date), textAnchor: 'start', textLength: 170, chkWidth: true});
          // 伝票番号
          replacements.push({key: '%伝番%', value: this.listDenpyo[k].salesNumber, textAnchor: 'middle', textLength: 170, chkWidth: true});
          // 区分
          replacements.push({key: '%c%', value: result[workIndex].bill_class, textAnchor: 'start', textLength: 60, chkWidth: true});
          // 締日
          if (result[workIndex].closing_date == 99) {
            replacements.push({key: '%締日%', value: formatDateCalc(result[workIndex].sales_issue_date, 0, 0, 0, true, 'DD'), textAnchor: 'start', textLength: 136, chkWidth: false});
          } else {
            replacements.push({key: '%締日%', value: result[workIndex].closing_date, textAnchor: 'start', textLength: 136, chkWidth: false});
          }
          // 担当者
          replacements.push({key: '%担当%', value: result[workIndex].staff_id, textAnchor: 'start', textLength: 120, chkWidth: false});
          // チェック
          replacements.push({key: '%チェック%', value: result[workIndex].check_contents, textAnchor: 'start', textLength: 900, chkWidth: false});
          // 伝票種類が「2：特殊2、4：特殊4」の場合、取引先の追加情報と相手の名称を追加
          if (this.checkBillingTypeClass == Const.BillingTypeClass.special2 || this.checkBillingTypeClass == Const.BillingTypeClass.special4) {
            // 取引先名
            replacements.push({key: '%取営業名%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'clientName'), textAnchor: 'start', textLength: 467, chkWidth: true});
            // 住所
            replacements.push({key: '%取営住所%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'address'), textAnchor: 'start', textLength: 601, chkWidth: true});
            // 取引先TEL
            replacements.push({key: '%取営TEL%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'tel'), textAnchor: 'start', textLength: 457, chkWidth: true});
            // 取引先FAX
            replacements.push({key: '%取営FAX%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'fax'), textAnchor: 'start', textLength: 457, chkWidth: true});
            // 取引先相手名
            if (result[workIndex].client_print_class != Const.OrderPrintClass.customerNoPrint) {
              // 発注書印字区分「0：得意先名を印字する」の場合、固定値
              replacements.push({key: '%取相手名%', value: getListValue(Const.BillingTypeClientDataList, this.checkBillingTypeClass, 'id', 'partnerName'), textAnchor: 'start', textLength: 589, chkWidth: true});
            } else {
              // 発注書印字区分「1：仮伝名称を印字する」の場合、発注書用取引先名
              replacements.push({key: '%取相手名%', value: result[workIndex].client_print_name, textAnchor: 'start', textLength: 589, chkWidth: true});
            }
          } else if (this.checkBillingTypeClass == Const.BillingTypeClass.special3) {
            // 取引先名
            replacements.push({key: '%取営業名%', value: result[workIndex].client_name_kanji, textAnchor: 'start', textLength: 570, chkWidth: true});
            // 郵便番号
            replacements.push({key: '%取営郵便%', value: '〒' + getNullStr(result[workIndex].zip_code), textAnchor: 'start', textLength: 550, chkWidth: false});
            // 住所１
            replacements.push({key: '%取営住所1%', value: result[workIndex].address_1, textAnchor: 'start', textLength: 550, chkWidth: true});
            // 住所２
            replacements.push({key: '%取営住所2%', value: result[workIndex].address_2, textAnchor: 'start', textLength: 550, chkWidth: true});
            // 取引先TEL
            replacements.push({key: '%取営TEL%', value: result[workIndex].phone_number, textAnchor: 'start', textLength: 477, chkWidth: false});
            // 取引先FAX
            replacements.push({key: '%取営FAX%', value: result[workIndex].fax_number, textAnchor: 'start', textLength: 477, chkWidth: false});
            // 取引先相手名
            replacements.push({key: '%取相手名%', value: result[workIndex].client_print_name, textAnchor: 'start', textLength: 589, chkWidth: true});
          }
          // 説明
          replacements.push({key: '%説明%', value: '', textAnchor: 'start', textLength: 585, chkWidth: false});
          // 納品指定
          strWork = result[workIndex].delivery_design_date == null ? '' : formatDate(result[workIndex].delivery_design_date);
          strWork += '    ' + getNullStr(result[workIndex].delivery_design);
          replacements.push({key: '%納品指定%', value: strWork, textAnchor: 'start', textLength: 760, chkWidth: true});
          // 納品指定区分
          replacements.push({key: '%納区%', value: result[workIndex].delivery_design_class, textAnchor: 'end', textLength: 110, chkWidth: true});
          // 現場コード
          replacements.push({key: '%現場C%', value: result[workIndex].site_id, textAnchor: 'start', textLength: 150, chkWidth: false});
          // 配送コード
          replacements.push({key: '%配送C%', value: result[workIndex].shipping_code, textAnchor: 'start', textLength: 204, chkWidth: false});
          // 現場名
          replacements.push({key: '%現場名%', value: result[workIndex].client_site_name_kanji, textAnchor: 'start', textLength: 1351, chkWidth: true});
          // 現場郵便番号
          replacements.push({key: '%現郵便%', value: '〒' + getNullStr(result[workIndex].client_site_zip_code), textAnchor: 'start', textLength: 170, chkWidth: false});
          // 現場住所1
          replacements.push({key: '%現住所1%', value: result[workIndex].client_site_address_1, textAnchor: 'start', textLength: 675, chkWidth: true});
          // 現場住所2
          replacements.push({key: '%現住所2%', value: result[workIndex].client_site_address_2, textAnchor: 'start', textLength: 675, chkWidth: true});
          // 納品場所
          replacements.push({key: '%納品場所%', value: result[workIndex].delivery_location, textAnchor: 'start', textLength: 1000, chkWidth: true});
          // 現場TEL
          replacements.push({key: '%現TEL%', value: result[workIndex].client_site_phone_number, textAnchor: 'end', textLength: 400, chkWidth: true});
          // 帳票毎に設定可能な製品の件数
          let productCntByChouhyou = this.constData.productCntByChouhyou;
          // 製品
          totalMoney = 0;
          for (let j = 0; j < productCntByChouhyou; j++) {
            // テーブルデータの参照が終わった場合
            // 同伝票内の製品の出力が全て終わった場合
            if (result.length <= index ||
            result[index].sales_number != this.listDenpyo[k].salesNumber) {
              /* 空行 */
              // コード
              replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 220, chkWidth: false});
              // 置場所
              replacements.push({key: '%置場所' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 610, chkWidth: false});
              // 製品名
              replacements.push({key: '%製品名' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 610, chkWidth: false});
              // 数量
              replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
              // 単価
              replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
              // 金額
              replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
            } else {
              if (result[index].product_id == null) {
                // 消費税行
                // コード
                replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 220, chkWidth: false});
                // 置場所
                replacements.push({key: '%置場所' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: 610, chkWidth: false});
                // 製品名
                replacements.push({key: '%製品名' + (j + 1).toString() + '%', value: this.constData.taxName, textAnchor: 'start', textLength: 610, chkWidth: false});
                // 数量
                replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 210, chkWidth: false});
                // 単価
                replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: 230, chkWidth: false});
                // 金額
                replacements.push({key: '%金額' + (j + 1).toString() + '%', value: result[index].product_tax.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: false});
                // 合計金額を計算
                totalMoney += result[index].product_tax;
              } else {
                // 上記以外（通常の製品行）
                // コード
                replacements.push({key: '%コード' + (j + 1).toString() + '%', value: result[index].product_id, textAnchor: 'start', textLength: 220, chkWidth: false});
                // 置場所
                if (result[index].place_1.trim() == '' && result[index].place_2.trim() == '' && result[index].place_3.trim() == '' && result[index].place_4.trim() == '') {
                  strWork = '';
                } else {
                  strWork = '';
                  // TODO 常に非表示、但し、後に表示非表示を出しわけるように修正の可能性があるため、コメントアウト
                  //strWork = ('00' + result[index].place_1.trim()).slice(-2) + '-' + ('00' + result[index].place_2.trim()).slice(-2) + '-' + ('00' + result[index].place_3.trim()).slice(-2) + '-' + ('00' + result[index].place_4.trim()).slice(-2);
                }
                replacements.push({key: '%置場所' + (j + 1).toString() + '%', value: strWork, textAnchor: 'start', textLength: 610, chkWidth: false});
                // 製品名
                strWork = result[index].product_name_kanji;
                if (result[index].product_tax_rate_class_sales == Const.ProductTaxRateClass.lightTax) {
                  strWork = this.controlMasterData.lightTaxMark + ' ' + strWork;
                }
                replacements.push({key: '%製品名' + (j + 1).toString() + '%', value: strWork, textAnchor: 'start', textLength: 610, chkWidth: true});
                // 数量
                strWork = result[index].shipping_quantity.toLocaleString();
                if (getNullStr(strWork) == '0' && result[index].sundries_class == Const.SundriesClass.shokuchi) {
                  strWork = '';
                } else {
                  strWork += getNullStr(result[index].unit) == '' ? '' : ' ' + result[index].unit;
                }
                replacements.push({key: '%数量' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: 210, chkWidth: false});
                // 単価
                if (getNullStr(result[index].service_class) != '') {
                  strWork = getNullStr(getListValue(this.serviceList, result[index].service_class, 'id', 'text'));
                  if (strWork.length <= 7) {
                    chkWidth = false;
                  } else {
                    chkWidth = true;
                  }
                  replacements.push({key: '%単価' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: 230, chkWidth: chkWidth});
                } else {
                  if (getNullStr(result[index].shipping_quantity) == '0' && getNullStr(result[index].order_receive_unit_amount) == '0' && result[index].sundries_class == Const.SundriesClass.shokuchi) {
                    strWork = '';
                  } else {
                    strWork = result[index].order_receive_unit_amount.toLocaleString();
                  }
                  replacements.push({key: '%単価' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: 230, chkWidth: false});
                }
                // 金額
                if (getNullStr(result[index].shipping_quantity) == '0' && getNullStr(result[index].order_receive_unit_amount) == '0' && result[index].sundries_class == Const.SundriesClass.shokuchi) {
                  strWork = '';
                } else {
                  strWork = result[index].order_receive_amount.toLocaleString();
                }
                replacements.push({key: '%金額' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: 230, chkWidth: false});
                // 合計金額を計算
                totalMoney += result[index].order_receive_amount;
              }
              index++;
            }
          }
          // 合計
          replacements.push({key: '%合計金額%', value: totalMoney.toLocaleString(), textAnchor: 'end', textLength: 230, chkWidth: false});
          //console.log('replacements');
          //console.log(replacements);
          this.listDenpyo[k].listChouhyou[i].replacements = replacements;
        }
      }
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements) {
      for (let i = 0; i < this.officeList.length; i++) {
        // 郵便
        replacements.push({key: '%郵便' + this.officeList[i].keyNo + '%', value: this.officeList[i].zipCode, textAnchor: 'start', textLength: 150, chkWidth: true});
        // 住所
        replacements.push({key: '%住所' + this.officeList[i].keyNo + '%', value: this.officeList[i].address, textAnchor: 'start', textLength: 287, chkWidth: true});
        // TEL
        replacements.push({key: '%TEL' + this.officeList[i].keyNo + '%', value: this.officeList[i].phoneNumber, textAnchor: 'start', textLength: 225, chkWidth: true});
        // FAX
        replacements.push({key: '%FAX' + this.officeList[i].keyNo + '%', value: this.officeList[i].faxNumber, textAnchor: 'start', textLength: 225, chkWidth: true});
        if (i == 0) {
          // E-mail
          replacements.push({key: '%Mail%', value: this.officeList[i].Email, textAnchor: 'start', textLength: 457, chkWidth: true});
        }
      }
    },
    /* 帳票に各種値セット */
    setChouhyou: function() {
      //console.log('setChouhyou');
      for (let j = 0; j < this.listDenpyo.length; j++) {
        for (let k = 0; k < this.listBillingTypeList.length; k++) {
          for (let i = 0; i < this.listDenpyo[j].listChouhyou.length; i++) {
            let svgDoc = document.getElementById(this.constData.chouhyouId + this.listDenpyo[j].salesNumber + '_' + this.listBillingTypeList[k] + '_' + this.listDenpyo[j].listChouhyou[i].page);
            this.setReplacements(svgDoc, this.listDenpyo[j].listChouhyou[i].replacements, this.listBillingTypeList[k], this.listDenpyo[j].clientPrintClass, this.listDenpyo[j].clientPrintName);
            this.setSize(svgDoc);
          }
        }
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements: function(node, replacements, billingType, clientPrintClass, clientPrintName) {
      //console.log('setReplacements');
      for (let i = 0; i < node.children.length; i++) {
        if (node.children[i].tagName == 'text') {
          for (let j = 0; j < node.children[i].children.length; j++) {
            if (node.children[i].children[j].tagName == 'tspan') {
              for (let k = 0; k < replacements.length; k++) {
                if (node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1) {
                  this.setTspan(node.children[i], node.children[i].children[j], replacements[k], billingType, clientPrintClass, clientPrintName);
                  break;
                }
              }
            }
          }
        } else if (node.children[i].tagName == 'g') {
          this.setReplacements(node.children[i], replacements, billingType, clientPrintClass, clientPrintName);
        }
        /*
        else if (node.children[i].tagName == 'path' && node.children[i].getAttribute('fill') == '#fff') {
          this.setBackgroundColor(node.children[i], billingType);
        }
        */
      }
    },
    /* Textタグ内のテキストを設定 */
    setTspan: function(tagText, tagTspan, config, billingType, clientPrintClass, clientPrintName) {
      let configChkWidth = config.chkWidth;
      // 文字を置換
      if (config.key == '%タイトル%') {
        tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getListValue(Const.BillingTypeNouhinList, billingType, 'id', 'title'));
        tagText.setAttribute('fill', getListValue(Const.BillingTypeNouhinList, billingType, 'id', 'color'));
      } else if (config.key == '%説明%') {
        tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getListValue(Const.BillingTypeNouhinList, billingType, 'id', 'text'));
      } else if (config.key == '%郵便番号%' || config.key == '%取住所1%' || config.key == '%取住所2%' || config.key == '%取TEL%' || config.key == '%取引先名%' || config.key == '%部署1%' || config.key == '%部署2%') {
        if (billingType == this.constData.billingTypeNohinSei || billingType == this.constData.billingTypeSeikyu || clientPrintClass != Const.OrderPrintClass.customerNoPrint) {
          // 納品書（正）、または、得意先名を印字の場合
          // 基本的な動きと同じ
          tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(config.value));
        } else {
          // 上記以外（納品書（正）以外、且つ、「仮伝名称を印字する」）の場合
          // 名称以外は空欄、名称は現場マスタの設定値
          if (config.key == '%郵便番号%' || config.key == '%取住所1%' || config.key == '%取住所2%' || config.key == '%取TEL%' || config.key == '%部署1%' || config.key == '%部署2%') {
            tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, '');
            configChkWidth = false;
          } else {
            tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(clientPrintName));
          }
        }
      } else {
        tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(config.value));
      }
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if (configChkWidth == true &&
        tagTspan.getBBox().width > config.textLength) {
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 背景色を設定 */
    setBackgroundColor(tagPath, billingType) {
      tagPath.setAttribute('fill', getListValue(Const.BillingTypeNouhinList, billingType, 'id', 'backcolor'));
    },
    /* 取得結果セット */
    setSize: function(svgDoc) {
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
    },
  },
}
</script>
<style scoped>
</style>