<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <!-- TODO 現在は印刷時にヘッダーは除外しているが、1ページ目のみに表示されるようにする（タイトルを表示するため） -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col lg="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <b-card class="border">
              <b-card-header class="mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip" title="クリックすると検索条件表示/非表示できます。">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" style="display: block; float: left;" role="tablist" aria-expanded="true">
                    <span class="oi oi-magnifying-glass"></span> 検索条件
                  </a>
                  <b-button size="sm" v-b-tooltip.hover.noninteractive title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                    <span class="oi oi-print"></span>
                  </b-button>
                </h5>
              </b-card-header>
              <br>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-2">
                  <b-container>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <b-container>
                        <b-row>
                          <b-col lg="6">
                            <b-form-group
                              label="受注照会区分"
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio-group
                                id="orderReceiveClass"
                                v-model="searchConditions.checkOrderReceiveClass"
                                :options="searchConditions.orderReceiveClassList"
                                :aria-describedby="ariaDescribedby"
                                value-field="id"
                                text-field="name"
                                @change="changeOffice"
                              ></b-form-radio-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 営業所入力欄 -->
                          <b-col lg="6">
                            <b-form-group
                              label="営業所"
                              label-for="salesOffice"
                            >
                              <b-form-select
                                id="salesOffice"
                                v-model="searchConditions.selectSalesOffice"
                                :options="searchConditions.salesOffice"
                                value-field="id"
                                text-field="name"
                              >
                              </b-form-select>
                              <b-form-text class="text-muted">受注照会区分「製品別」の場合は必須です。</b-form-text>
                            </b-form-group>
                          </b-col>
                          <!-- 入力担当者 -->
                          <b-col lg="6">
                            <b-form-group
                              label="入力担当者"
                              label-for="inputStaff"
                            >
                              <b-input-group>
                                <b-form-input type="text" id="inputStaff" name="inputStaff" v-model="searchConditions.inputStaff.text" readonly />
                                <b-input-group-text @click="showStaffSearchModal" v-if="searchConditions.checkOrderReceiveClass==1" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                  <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                    <span class="oi oi-magnifying-glass"></span>
                                    <button type="button" v-shortkey="['alt', '1']" @shortkey="showStaffSearchModal" class="d-none"></button>
                                  </b-button>
                                </b-input-group-text>
                              </b-input-group>
                              <b-form-text class="text-muted">検索ボタンから選択してください。<br>受注照会区分「チェック用」の場合のみ有効です。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 得意先 -->
                          <b-col lg="6">
                            <validation-provider name="clientId" rules="numeric|min:6" v-slot="{ classes,errors }">
                              <label for="clientId">得意先: {{searchConditions.clientName}}</label>
                              <b-col lg="12" :class="classes" class="pl-0">
                                <b-input-group>
                                  <b-form-input 
                                    type="text" 
                                    id="clientId" 
                                    v-model.number="searchConditions.clientId" 
                                    @input="searchClient(searchConditions.clientId)" 
                                    maxlength="6"
                                  />
                                  <b-input-group-text @click="showClientSearchModal" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                    <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                      <span class="oi oi-magnifying-glass"></span>
                                      <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal" class="d-none"></button>
                                    </b-button>
                                  </b-input-group-text>
                                  <!-- 得意先クリアボタン -->
                                  <b-button size="sm" pill variant="success" title="得意先をクリアします。" @click="clientClear()" class="mr-1">
                                    <span class="oi oi-circle-x"></span> クリア
                                  </b-button>
                                </b-input-group>
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-col>
                              <b-form-text class="text-muted">検索ボタンから選択してください。</b-form-text>
                            </validation-provider>
                          </b-col>
                          <!-- 締日 -->
                          <b-col lg="2">
                            <b-form-group
                              label="締日"
                            >
                              <b-input-group>
                                <b-input-group-text>
                                  {{ searchConditions.closingDate }}
                                </b-input-group-text>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <!-- 担当者 -->
                          <b-col lg="4">
                            <b-form-group
                              label="担当者"
                            >
                              <b-input-group>
                                <b-input-group-text>
                                  {{ searchConditions.staff }}
                                </b-input-group-text>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 現場コード -->
                          <b-col lg="12">
                            <b-form-group
                              label="現場コード"
                              label-for="siteIdStart"
                            >
                              <b-container fluid>
                                <b-row>
                                  <b-col class="px-0">
                                    <validation-provider name="siteIdStart" :rules="{numeric:true,consistency:searchConditions.siteIdEnd}" v-slot="{ classes,errors }">
                                      <div :class="classes">
                                        <b-input-group>
                                          <b-form-input 
                                            type="text" 
                                            id="siteIdStart" 
                                            name="siteIdStart" 
                                            v-model.number="searchConditions.siteIdStart" 
                                            :readonly="!checkClientId(searchConditions.clientId)"
                                            @blur="searchConditions.siteIdEnd = searchConditions.siteIdStart"
                                          />
                                          <b-input-group-text
                                            @click="showSiteSearchModal(1);"
                                            v-if="checkClientId(searchConditions.clientId)"
                                            v-b-tooltip.hover.noninteractive title="「ALT+3」ボタンで呼出可能">
                                            <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                              <span class="oi oi-magnifying-glass"></span>
                                              <button type="button" v-shortkey="['alt', '3']" @shortkey="showSiteSearchModal(1);" class="d-none"></button>
                                            </b-button>
                                          </b-input-group-text>
                                          <!-- 現場クリアボタン -->
                                          <b-button size="sm" pill variant="success" title="現場をクリアします。" @click="siteClear(1)" class="mr-1">
                                            <span class="oi oi-circle-x"></span> クリア
                                          </b-button>
                                        </b-input-group>
                                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                      </div>
                                    </validation-provider>
                                  </b-col>
                                  <b-col md="1" class="d-flex justify-content-center pt-2">～</b-col>
                                  <b-col class="px-0">
                                    <validation-provider name="siteIdEnd" rules="numeric" v-slot="{ classes,errors }">
                                      <div :class="classes">
                                        <b-input-group>
                                          <b-input 
                                            type="text" 
                                            v-model.number="searchConditions.siteIdEnd" 
                                            :readonly="!checkClientId(searchConditions.clientId)"
                                          />
                                          <b-input-group-text
                                            @click="showSiteSearchModal(2);"
                                            v-if="checkClientId(searchConditions.clientId)"
                                            v-b-tooltip.hover.noninteractive title="「ALT+4」ボタンで呼出可能">
                                            <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                              <span class="oi oi-magnifying-glass"></span>
                                              <button type="button" v-shortkey="['alt', '4']" @shortkey="showSiteSearchModal(2);" class="d-none"></button>
                                            </b-button>
                                          </b-input-group-text>
                                          <!-- 現場クリアボタン -->
                                          <b-button size="sm" pill variant="success" title="現場をクリアします。" @click="siteClear(2)" class="mr-1">
                                            <span class="oi oi-circle-x"></span> クリア
                                          </b-button>
                                        </b-input-group>
                                        <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                      </div>
                                    </validation-provider>
                                  </b-col>
                                </b-row>
                              </b-container>
                              <b-form-text class="text-muted">現場の範囲を入力してください。空白の場合は全範囲が設定されます。<br>得意先選択済みの場合のみ有効です。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 製品コード -->
                          <b-col lg="6">
                            <validation-provider name="productId" :rules="{required: searchConditions.checkOrderReceiveClass==htmlConst.OrderReceiveInquiryClass.product}" v-slot="{ classes,errors }">
                              <b-form-group
                                label="製品コード"
                                label-for="productId"
                              >
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <b-input-group>
                                      <b-form-input 
                                        type="text" 
                                        id="productId" 
                                        name="productId" 
                                        v-model.number="searchConditions.productId" 
                                        maxlength="8"
                                      />
                                      <b-input-group-text @click="showProductSearchModal()" v-b-tooltip.hover.noninteractive title="「ALT+5」ボタンで呼出可能">
                                        <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                          <span class="oi oi-magnifying-glass"></span>
                                          <button type="button" v-shortkey="['alt', '5']" @shortkey="showProductSearchModal()" class="d-none"></button>
                                        </b-button>
                                      </b-input-group-text>
                                      <!-- 製品クリアボタン -->
                                      <b-button size="sm" pill variant="success" title="製品をクリアします。" @click="productClear()" class="mr-1">
                                        <span class="oi oi-circle-x"></span> クリア
                                      </b-button>
                                    </b-input-group>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col lg="12" :class="classes">
                                    <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                  </b-col>
                                </b-row>
                                <b-form-text class="text-muted">検索ボタンから選択してください。空白の場合、全製品を検索します。<br>受注照会区分「製品別」の場合は必須です。</b-form-text>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col lg="6">
                            <b-form-group
                              label="残区分"
                              v-slot="{ ariaDescribedby }"
                            >
                              <b-form-radio-group
                                id="remainingClass"
                                v-model="searchConditions.checkRemainingClass"
                                :options="searchConditions.remainingClassList"
                                :aria-describedby="ariaDescribedby"
                                value-field="id"
                                text-field="text"
                              ></b-form-radio-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 入力日 -->
                          <b-col lg="12">
                            <validation-provider name="inputDateStart" :rules="{dateConsistency:searchConditions.inputDateEnd}" v-slot="{ classes,errors }">
                              <b-form-group
                                label="入力日"
                                label-for="inputDateStart"
                              >
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <b-input-group class="input-daterange" id="datepicker">
                                    <b-form-datepicker
                                      id="inputDateStart"
                                      name="inputDateStart"
                                      v-model="searchConditions.inputDateStart"
                                      calendar-width="50%"
                                    ></b-form-datepicker>
                                    <span class="input-group-text">～</span>
                                    <b-form-datepicker
                                      v-model="searchConditions.inputDateEnd"
                                      calendar-width="50%"
                                    ></b-form-datepicker>
                                  </b-input-group>
                                </b-col>
                                <b-col lg="12" :class="classes" class="pl-0">
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </b-col>
                              </b-form-group>
                              <b-form-text class="text-muted">日付は基本的に指定してください。空白の場合は全範囲が設定されます。</b-form-text>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-container>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <b-row class="justify-content-md-center my-2">
                      <b-col lg="3">
                        <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                          <span class="oi oi-magnifying-glass"></span> 検 索 
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <!-- ●●●検索条件●●● -->
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-card-header>
          <b-alert show variant="warning" class="mt-2" v-if="resultError.length">
            <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
              <li>{{error}}</li>
            </ul>
          </b-alert>
        </b-card-header>
        <b-card-body>
          <b-col class="mt-2">
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col  lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="5"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-table
                show-empty
                :items="itemList"
                :fields="fields"
                :busy="busy"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
              >
                <!-- テーブル読み込み時表示html -->
                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>読み込んでいます...</strong>
                  </div>
                </template>
                <template #head(free_col_1)>入力日<br><br>&nbsp;</template>
                <template #head(free_col_2)>受注番号<br>現場コード<br>&nbsp;</template>
                <template #head(free_col_3)>得意先コード<br>配送コード<br>製品コード</template>
                <template #head(free_col_4)>得意先名<br>現場名<br>製品名</template>
                <template #head(free_col_5)>配送<br><br>受注数</template>
                <template #head(free_col_6)>伝種<br><br>受注残</template>
                <template #head(free_col_7)><br><br>引当数</template>
                <template #head(free_col_8)><br><br>入荷予定</template>
                <template #head(free_col_9)><br><br>入荷予定処理担当者</template>
                <template #cell(free_col_1)="data">
                  {{ data.item.inputDate }}<br/>
                </template>
                <template #cell(free_col_2)="data">
                  {{ data.item.orderReceiveId }}<br/>
                  {{ data.item.siteId }}<br/>

                </template>
                <template #cell(free_col_3)="data">
                  {{ data.item.clientId }}<br/>
                  {{ data.item.shippingCode }}<br/>
                  <ul v-for="(productId,index) in data.item.productId" :key="index" style="list-style: none;">
                    <li>{{productId}}</li>
                  </ul>
                </template>
                <template #cell(free_col_4)="data">
                  {{ data.item.clientName }}<br/>
                  {{ data.item.clientSiteName }}<br/>
                  <ul v-for="(productName,index) in data.item.productName" :key="index" style="list-style: none;">
                    <li>{{productName}}</li>
                  </ul>
                </template>
                <template #cell(free_col_5)="data">
                  {{ data.item.shippingTypeClass }}<br/>
                  <br/>
                  <ul v-for="(productQuantity,index) in data.item.productQuantity" :key="index" style="list-style: none;" class="text-right">
                    <li>{{productQuantity}}</li>
                  </ul>
                </template>
                <template #cell(free_col_6)="data">
                  {{ data.item.orderReceiveBillClass }}<br/>
                  <br/>
                  <ul v-for="(productRemainingQuantity,index) in data.item.productRemainingQuantity" :key="index" style="list-style: none;" class="text-right">
                    <li>{{productRemainingQuantity}}</li>
                  </ul>
                </template>
                <template #cell(free_col_7)="data">
                  <br/>
                  <br/>
                  <ul v-for="(productReserveQuantity,index) in data.item.productReserveQuantity" :key="index" style="list-style: none;" class="text-right">
                    <li>{{productReserveQuantity}}</li>
                  </ul>
                </template>
                <template #cell(free_col_8)="data">
                  <br/>
                  <br/>
                  <ul v-for="(productStockScheduled,index) in data.item.productStockScheduled" :key="index" style="list-style: none;">
                    <li>{{productStockScheduled}}</li>
                  </ul>
                </template>
                <!-- 操作列 -->
                <template #cell(operation)="data">
                  <div v-show="!printStatus">
                    <!-- 受注入力照会ボタン -->
                    <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'受注内容の照会と各種操作を行います。'" @click="clickDetailBtn(data.item.orderReceiveId)" class="mr-1">
                      <span class="oi oi-list"></span> 受注入力照会
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : totalRows"
                :per-page="perPage == -1 ? totalRows : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-col>
        </b-card-body>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●担当者検索モーダル●●● -->
    <STAFFSEARCH @from-child="closeStaffSearchModal"/>
    <!-- ●●●取引先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="1"/>
    <!-- ●●●製品検索モーダル●●● -->
    <PRODUCTSEARCH @from-child="closeProductSearchModal" :productSearchProp="productSearchProp"/>
    <!-- ●●●現場検索モーダル●●● -->
    <SITESEARCH @from-child="closeSiteSearchModal" :client-info="siteSearchInfo" />
  </div>
</template>
<script>
import store from '../store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import STAFFSEARCH from '@/components/modal/staff-search.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import PRODUCTSEARCH from '@/components/modal/product-search.vue';
import SITESEARCH from '@/components/modal/site-search.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { searchClientModal } from '@/assets/js/searchModal.js';
import { init, windowPrint, executeSelectSql, formatDate, formatCurDate, formatDateCalc, getListValue, addOperationLogs, checkClientId, getControlMaster, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';

const MODULE_NAME = 'received-order-inquiry';

export default {
  name: 'RECEIVED-ORDER-INQUIRY',
  /* コンポーネント */
  components: {
    Header,
    Footer,
    STAFFSEARCH,
    CLIENTSEARCH,
    PRODUCTSEARCH,
    SITESEARCH,
  },
  /* データ */
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '受注照会',
      // アラート
      resultError: [],
      // 取引先コード確認関数
      checkClientId: checkClientId,
      // 検索項目
      searchConditions: {
        // 受注照会区分
        checkOrderReceiveClass: Const.OrderReceiveInquiryClass.check,
        orderReceiveClassList: Const.OrderReceiveInquiryClassList,
        // 営業所
        selectSalesOffice: '',
        salesOffice: [],
        salesOfficeCheck: [],
        salesOfficeProduct: [],
        loginOfficeId: 0,
        // 入力担当者
        inputStaff: {
          id: '',
          text: '',      
        },
        // 取引先
        clientClass: '',
        clientId: '',
        clientName: '',
        clientText: '',
        closingDate: '',
        staff: '',
        // 現場コード（開始／終了）
        siteIdStart: '',
        siteIdEnd: '',
        // 製品
        productId: '',
        // 残区分
        checkRemainingClass: Const.RemainingClass.all,
        remainingClassList: Const.RemainingClassList,
        // 入力日（開始／終了）
        inputDateStart: '',
        inputDateEnd: '',
      },
      // 現場検索情報
      siteSearchInfo: {
        client_id: '',
        client_name: '',
      },
      // モーダルからの戻り値を反映先
      openModalIndex: 0,
      // 検索結果
      itemList: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: 0,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
      },
      // プリントアウト状態
      printStatus: false,
      // 定数（htmlで使用）
      htmlConst: {
        // 受注照会区分
        OrderReceiveInquiryClass: {
          // チェック用
          check: Const.OrderReceiveInquiryClass.check,
          // 製品別
          product: Const.OrderReceiveInquiryClass.product,
        },
      },
    }
  },
  /* マウント */
  async mounted() {
    this.$store.commit('setLoading', true);
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* 製品モーダルのパラメータ用 */
    productSearchProp: function() {
      return {
        office_id: null,
        office_name: null,
        client_class: this.searchConditions.clientClass,
        client_id: this.searchConditions.clientId,
        client_name: this.searchConditions.clientName,
      };
    },
    /* フィールド */
    fields: function() {
      return [
        {
          key: 'free_col_1',
        },
        {
          key: 'free_col_2',
        },
        {
          key: 'free_col_3',
        },
        {
          key: 'free_col_4',
        },
        {
          key: 'free_col_5',
        },
        {
          key: 'free_col_6',
        },
        {
          key: 'free_col_7',
        },
        {
          key: 'free_col_8',
        },
        {
          key: 'operation',
          label: '操作',
        }
      ];
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1 || this.perPage == -1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
  },
  methods: {
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    async fetchData() {
      const functionName = 'fetchData';
      try {
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let staffZeroData = null;
        let controlData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData, staffZeroData, controlData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
          selectOneTable('m_staffs', 'AND staff_id = 0'),
          getControlMaster(),
        ]);
        let loginOfficeId = staffListData[0].office_id;
        this.searchConditions.loginOfficeId = loginOfficeId;
        //console.log(staffListData);
        //console.log(officeListData);
        // default値挿入
        this.searchConditions.selectSalesOffice = 0;
        // 営業所プルダウン作成
        this.searchConditions.salesOffice.push({id: 0, name: '全て'});
        this.searchConditions.salesOfficeCheck.push({id: 0, name: '全て'});
        for(let i = 0; i < officeListData.length; i++){
          let office = {
            id: officeListData[i].office_id,
            name: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji
          };
          this.searchConditions.salesOffice.push(office);
          this.searchConditions.salesOfficeCheck.push(office);
          this.searchConditions.salesOfficeProduct.push(office);
          // default値挿入
          if (loginOfficeId == officeListData[i].office_id &&
            loginOfficeId != 2) {
            // ログイン社員の所属営業所が横浜の場合はdefaultは「全て」のままとする
            // default値挿入
            this.searchConditions.selectSalesOffice = loginOfficeId;
          }
        }
        // 入力担当者
        if (staffZeroData != null && staffZeroData.length > 0) {
          // 担当者コード0のデータが存在する場合、担当者コード0の社員を初期値とする
          this.searchConditions.inputStaff.id = staffZeroData[0].staff_id;
          this.searchConditions.inputStaff.text = staffZeroData[0].staff_id + '：' + staffZeroData[0].staff_name_kanji;
        } else {
          // 担当者コード0のデータが存在しない場合、ログイン社員コードを初期値とする
          this.searchConditions.inputStaff.id = staffListData[0].staff_id;
          this.searchConditions.inputStaff.text = staffListData[0].staff_id + '：' + staffListData[0].staff_name_kanji;
        }
        // 現在処理年月取得
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        // 入力日に１か月前～現在日を設定
        this.searchConditions.inputDateStart = formatDateCalc(formatCurDate('YYYY-MM-DD'), 0, -2, 0, false, 'YYYY-MM-DD');
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    // 担当者検索
    showStaffSearchModal:function() {
      this.$bvModal.show('staffSearchModal');
    },
    // 担当者検索モーダルを閉じた時
    closeStaffSearchModal:function(staffItems) {
      //console.log(staffItems);
      // モーダルから渡された値の有無チェック
      if(typeof staffItems != 'undefined'){
        this.searchConditions.inputStaff.id = staffItems.detail.staff_id;
        this.searchConditions.inputStaff.text = staffItems.detail.staff_id + '：' + staffItems.detail.staff_name_kanji;
      }
    },
    // 取引先検索
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      if (String(client_id) == '') {
        this.clientClear()
      }
      this.propClientClass = Const.ClientClass.customer;
      const result = await searchClientModal(client_id, this.propClientClass, 'searchClient', MODULE_NAME)
      if (typeof result != 'undefined') {
        this.closeClientSearchModal(result)
      } else {
        // 取引先コード6桁かつデータ取得失敗の場合
        if (String(client_id).length == 6) {
          this.clientClear()
        }
      }
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal: function(clientItems) {
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined'){
        //console.log(clientItems);
        this.searchConditions.clientClass = clientItems.detail.client_class;
        this.searchConditions.clientId = clientItems.detail.client_id;
        this.searchConditions.clientName = clientItems.detail.client_name_kanji;
        this.searchConditions.clientText = clientItems.detail.client_id + ' ' + clientItems.detail.client_name_kanji;
        // 締日
        if (clientItems.detail.closing_date == 99) {
          this.searchConditions.closingDate = formatDateCalc(('000000' + this.controlMasterData.processMonthYear).slice(-6) + '01', 0, 0, 0, true, 'DD');
        } else {
          this.searchConditions.closingDate = clientItems.detail.closing_date;
        }
        this.searchConditions.staff = clientItems.detail.staff_id + ' ' + clientItems.detail.staff_name_kanji;
      }
    },
    // 取引先クリア
    clientClear: function() {
      this.searchConditions.clientClass = '';
      this.searchConditions.clientId = '';
      this.searchConditions.clientName = '';
      this.searchConditions.clientText = '';
      this.searchConditions.closingDate = '';
      this.searchConditions.staff = '';
    },
    // 製品検索
    showProductSearchModal: function() {
      this.$bvModal.show('productSearchModal');
    },
    // 製品検索モーダルを閉じた時
    closeProductSearchModal: function(productItems) {
      //console.log(productItems);
      // モーダルから渡された値の有無チェック
      if(typeof productItems != 'undefined'){
        this.searchConditions.productId = productItems.detail.product_id;
      }
    },
    // 製品クリア
    productClear: function() {
      this.searchConditions.productId = '';
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton';
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      if (this.busy == true) {
        return;
      }
      this.busy = true;
      try {
        this.itemList = [];
        // ページング機能の初期化
        this.initPaging();
        // CRUD処理
        let selectSql = '';
        selectSql = await this.makeSelectSql();
        //console.log(selectSql);
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        if(resultData != null){
          await this.setResult(resultData);
          if(resultData.length >= 5000){
            this.resultError.push(DISP_MESSAGES.WARNING['2009'].replace('%arg1%','5000').replace('%arg2%','製品'));
          }
          //console.log(resultData);
        }else{
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.busy = false;
    },
    /* 検索条件文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders_receives.input_date';
      selectSql += ',orders_receives.order_receive_id';
      selectSql += ',orders_receives.order_receive_row';
      selectSql += ',orders_receives.client_id';
      selectSql += ',orders_receives.client_name';
      selectSql += ',orders_receives.shipping_type_class';
      selectSql += ',orders_receives.order_receive_bill_class';
      selectSql += ',orders_receives.site_id';
      selectSql += ',orders_receives.shipping_code';
      selectSql += ',orders_receives.client_site_name';
      selectSql += ',orders_receives.product_id';
      selectSql += ',orders_receives.product_name';
      selectSql += ',orders_receives.order_receive_quantity';
      selectSql += ',orders_receives.remaining_quantity';
      selectSql += ',orders_receives.reserve_quantity';
      selectSql += ',orders_receives.stock_scheduled_reserve_receive';
      selectSql += ',orders_receives.shipping_quantity';
      selectSql += ',orders_QUERY.stock_scheduled';
      selectSql += ',orders_QUERY.stock_scheduled_staff_id';
      selectSql += ',CASE WHEN orders_QUERY.stock_scheduled_confirm_class = 1 THEN \'*\' ELSE \'\' END AS stock_scheduled_confirm_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders_receives AS orders_receives ';
      selectSql += 'LEFT JOIN (' + this.createSubOrdersQuerySql() + ') AS orders_QUERY ';
      selectSql += 'ON (orders_receives.order_receive_id = orders_QUERY.order_receive_id ';
      selectSql += 'AND orders_receives.order_receive_row = orders_QUERY.order_receive_row ';
      selectSql += 'AND orders_receives.order_id = orders_QUERY.order_id ';
      selectSql += 'AND orders_receives.order_row = orders_QUERY.order_row) ';
      selectSql += 'OR (orders_receives.office_id = orders_QUERY.office_id ';
      selectSql += 'AND orders_receives.product_id = orders_QUERY.product_id ';
      selectSql += 'AND orders_receives.order_id = 0) ';
      /* WHERE句 */
      let where_clause = '';
      where_clause += 'orders_receives.is_deleted = 0 ';
      if (this.searchConditions.checkOrderReceiveClass == 1) {
        // チェック用
        // 入力担当者
        if (this.searchConditions.inputStaff.id != '') {
          where_clause += 'AND orders_receives.input_staff_id = ' + this.searchConditions.inputStaff.id + ' ';
        }
      }
      // 営業所
      if (this.searchConditions.selectSalesOffice != 0) {
        where_clause += 'AND orders_receives.office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      }
      // 得意先コード
      if (this.searchConditions.clientId != '') {
        where_clause += 'AND orders_receives.client_id = ' + this.searchConditions.clientId + ' ';
      }
      // 現場コード
      if (this.searchConditions.siteIdStart != '' && this.searchConditions.siteIdEnd == '') {
        where_clause += 'AND orders_receives.site_id >= ' + this.searchConditions.siteIdStart + ' ';
      }else if (this.searchConditions.siteIdStart == '' && this.searchConditions.siteIdEnd != '') {
        where_clause += 'AND orders_receives.site_id <= ' + this.searchConditions.siteIdEnd + ' ';
      }else if (this.searchConditions.siteIdStart != '' && this.searchConditions.siteIdEnd != '') {
        where_clause += 'AND orders_receives.site_id BETWEEN ' + this.searchConditions.siteIdStart + ' ' + 'AND ' + this.searchConditions.siteIdEnd + ' ';
      }
      if (this.searchConditions.productId != '') {
        // 製品コードが指定された場合は指定された製品コードのレコードのみ
        where_clause += 'AND orders_receives.product_id = ' + this.searchConditions.productId + ' ';
      }
      // 残区分
      if (this.searchConditions.checkRemainingClass == Const.RemainingClass.remaining) {
        // 残件のみの場合、受注残のあるレコードのみ
        where_clause += 'AND orders_receives.remaining_quantity <> 0 ';
      }
      // 入力日
      if(this.searchConditions.inputDateStart != '' && this.searchConditions.inputDateEnd == ''){
        where_clause += 'AND orders_receives.input_date >= ' + '\'' + this.searchConditions.inputDateStart + '\' ';
      }else if(this.searchConditions.inputDateStart == '' && this.searchConditions.inputDateEnd != ''){
        where_clause += 'AND orders_receives.input_date <= ' + '\'' + this.searchConditions.inputDateEnd + '\' ';
      }else if(this.searchConditions.inputDateStart != '' && this.searchConditions.inputDateEnd != ''){
        where_clause += 'AND orders_receives.input_date BETWEEN ' + '\'' + this.searchConditions.inputDateStart + '\' ' + 'AND ' + '\'' + this.searchConditions.inputDateEnd + '\' ';
      }
      if (where_clause != '') {
        selectSql += ' WHERE ' + where_clause;
      }
      /* ORDER BY */
      selectSql += ' ORDER BY ';
      selectSql += ' input_date';
      selectSql += ',order_receive_id';
      selectSql += ',order_receive_row';
      /* LIMIT */
      selectSql += ' LIMIT 5000';
      return selectSql;
    },
    // 対象データSubOrdersQuerySQL作成処理
    createSubOrdersQuerySql: function() {
      // 受注番号に紐づかない発注データ（入荷予定のみ）
      let selectSql1 = 'SELECT';
      selectSql1 += ' 0 AS order_receive_id';
      selectSql1 += ',0 AS order_receive_row';
      selectSql1 += ',0 AS order_id';
      selectSql1 += ',0 AS order_row';
      selectSql1 += ',office_id';
      selectSql1 += ',product_id';
      selectSql1 += ',MIN(stock_scheduled) AS stock_scheduled';
      selectSql1 += ',stock_scheduled_staff_id';
      selectSql1 += ',stock_scheduled_confirm_class';
      selectSql1 += ' FROM ';
      selectSql1 += 't_orders';
      selectSql1 += ' WHERE ';
      selectSql1 += 'order_receive_id = 0 ';
      selectSql1 += 'AND is_deleted = 0 ';
      selectSql1 += 'AND remaining_quantity > 0 ';
      selectSql1 += 'AND order_issue_class > 0 ';
      if (this.searchConditions.checkOrderReceiveClass == 2) {
        // 製品別
        // 営業所
        selectSql1 += 'AND office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      }
      selectSql1 += 'GROUP BY office_id,product_id ';
      // 受注番号と紐づく発注データ（入荷予定のみ）
      let selectSql2 = 'SELECT';
      selectSql2 += ' order_receive_id';
      selectSql2 += ',order_receive_row';
      selectSql2 += ',order_id';
      selectSql2 += ',order_row';
      selectSql2 += ',0 AS office_id';
      selectSql2 += ',0 AS product_id';
      selectSql2 += ',stock_scheduled';
      selectSql2 += ',stock_scheduled_staff_id';
      selectSql2 += ',stock_scheduled_confirm_class';
      selectSql2 += ' FROM ';
      selectSql2 += 't_orders';
      selectSql2 += ' WHERE ';
      selectSql2 += 'order_receive_id > 0 ';
      selectSql2 += 'AND is_deleted = 0 ';
      selectSql2 += 'AND remaining_quantity > 0 ';
      selectSql2 += 'AND order_issue_class > 0 ';
      if (this.searchConditions.checkOrderReceiveClass == 2) {
        // 製品別
        // 営業所
        selectSql2 += 'AND office_id = ' + this.searchConditions.selectSalesOffice + ' ';
      }
      // 2つのSELECT文をUNION
      let selectSql = selectSql1 + ' UNION ALL ' + selectSql2;

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result){
      let searchResult = null;
      for(let i = 0; i < result.length; i++){
        // 伝票番号毎に１件として検索結果に表示するリストを作成
        if (searchResult == null || searchResult.orderReceiveId != result[i].order_receive_id) {
          if (searchResult != null) {
            this.itemList.push(searchResult);
          }
          searchResult = {
            inputDate: formatDate(result[i].input_date),
            orderReceiveId: result[i].order_receive_id,
            clientId: result[i].client_id,
            clientName: result[i].client_name,
            shippingTypeClass: getListValue(Const.DeliveryList, result[i].shipping_type_class, 'id', 'text'),
            orderReceiveBillClass: getListValue(Const.OrderReceiveBillClassList, result[i].order_receive_bill_class, 'id', 'text'),
            siteId: result[i].site_id,
            shippingCode: result[i].shipping_code,
            clientSiteName: result[i].client_site_name,
            productId: [],
            productName: [],
            productQuantity: [],
            productRemainingQuantity: [],
            productReserveQuantity: [],
            productStockScheduled: [],
          };
        }
        searchResult['productId'].push(result[i].product_id);
        searchResult['productName'].push(result[i].product_name);
        searchResult['productQuantity'].push(result[i].order_receive_quantity.toLocaleString());
        searchResult['productRemainingQuantity'].push(result[i].remaining_quantity.toLocaleString());
        if (result[i].shipping_type_class == '4' || result[i].order_receive_bill_class == 15) {
          // 配送種別区分「4：直送」、または、伝種「15：特別受注」
          // 入荷予定引当数受注を引当数として見る
          searchResult['productReserveQuantity'].push((result[i].stock_scheduled_reserve_receive - result[i].shipping_quantity).toLocaleString());
        } else {
          // 上記以外の場合
          // 引当済数を引当数として見る
          searchResult['productReserveQuantity'].push(result[i].reserve_quantity.toLocaleString());
        }
        let productStockScheduled = result[i].stock_scheduled == null ? '0000/00/00' : formatDate(result[i].stock_scheduled);
        if (result[i].stock_scheduled_staff_id != null && result[i].stock_scheduled_staff_id != '') {
          productStockScheduled += ' ' + result[i].stock_scheduled_staff_id;
        }
        if (result[i].stock_scheduled_confirm_class != '') {
          productStockScheduled += ' ' + result[i].stock_scheduled_confirm_class;
        }
        searchResult['productStockScheduled'].push(productStockScheduled);
      }
      if (searchResult != null) {
        this.itemList.push(searchResult);
      }
      // 伝票の総件数をdataTableの総件数にセット
      this.totalRows = this.itemList.length;
    },
    /* 受注入力照会ボタン押下時 */
    async clickDetailBtn(receivedOrderId){
      //console.log('照会');
      //console.log(receivedOrderId);
      let route = this.$router.resolve({ name: 'RECEIVED-ORDER-INPUT-INQUIRY', query: { receivedOrderId: receivedOrderId, parentKbn: 0, reserveErrFlg: 0 } });
      //console.log(this.$router);
      window.open(route.href, '_blank');
    },
    // 現場検索モーダルを開く
    showSiteSearchModal: function(index) {
      //console.log('現場検索モーダル');
      this.siteSearchInfo.client_id = this.searchConditions.clientId;
      this.siteSearchInfo.client_name = this.searchConditions.clientName;
      this.openModalIndex = index;
      this.$bvModal.show('siteSearchModal');
    },
    // 現場検索モーダルを閉じた時
    async closeSiteSearchModal(siteItems) {
      // モーダルから渡された値の有無チェック
      if(typeof siteItems != 'undefined'){
        if (this.openModalIndex == 1) {
          this.searchConditions.siteIdStart = siteItems.detail.site_id;
        } else {
          this.searchConditions.siteIdEnd = siteItems.detail.site_id;
        }
      }
    },
    siteClear: function(index) {
      //console.log('現場クリア');
      if (index == 1) {
        this.searchConditions.siteIdStart = '';
      } else {
        this.searchConditions.siteIdEnd = '';
      }
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    // 営業所プルダウン変更
    changeOffice: function() {
      if (this.searchConditions.checkOrderReceiveClass == 1) {
        this.searchConditions.salesOffice = this.searchConditions.salesOfficeCheck;
      } else {
        this.searchConditions.salesOffice = this.searchConditions.salesOfficeProduct;
        if (this.searchConditions.selectSalesOffice == 0) {
          this.searchConditions.selectSalesOffice = this.searchConditions.loginOfficeId;
        }
      }
    },
  }
}
</script>