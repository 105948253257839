<template>
  <div ref="dlPdf">
    <div v-if="getMessageFlg == true">
      <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
        <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="kokyaku in listKokyaku" v-bind:key="kokyaku.clientId + '_' + kokyaku.siteId">
      <div v-for="chouhyou in kokyaku.listChouhyou" v-bind:key="kokyaku.clientId + '_' + kokyaku.siteId + '_' + chouhyou.page">
        <div v-if="chouhyou.tempKbn == constData.tempKbnStart">
          <TemplateSeikyuStart :id="constData.chouhyouId + kokyaku.clientId + '_' + kokyaku.siteId + '_' + chouhyou.page" />
        </div>
        <div v-if="chouhyou.tempKbn == constData.tempKbnEnd">
          <TemplateSeikyuEnd :id="constData.chouhyouId + kokyaku.clientId + '_' + kokyaku.siteId + '_' + chouhyou.page" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TemplateSeikyuStart from '@/assets/svg/seikyu_Start.svg';
import TemplateSeikyuEnd from '@/assets/svg/seikyu_End.svg';
import { setPaperA4, setChouhyouBodyStyle, addOperationLogs, formatDate, formatCurDate, dateConsistency, executeSelectSql, executeSelectSqlNoLimit, getNullStr, getControlMaster, getClosingDate, getListValue, isSystemEditable, uploadHtmlPdfToS3PageSplit, uploadFileRemoveFromS3Multiple, escapeQuote, CreateColRow, CreateInsertSql, executeTransactSqlList, getFormCounterMultiple, getFormCounterMultipleReturnNo, getBillingDuplicateClass, updateElectronicDocumentsSeikyuDuplicateClass, selectOneTable } from '@/assets/js/common.js';
import Const from '@/assets/js/const.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'seikyu';
// トーストの表示位置
const TOASTER = 'b-toaster-bottom-right';

export default {
  name: 'SEIKYU',
  /* コンポーネント */
  components: {
    TemplateSeikyuStart,
    TemplateSeikyuEnd,
  },
  /* パラメータ */
  props:['propBillingMonthYear','propBillingOutputClass','propSiteIdStart','propSiteIdEnd','propCheckedClientIds','propBillingPaymentClass','propInvoiceIssueWay','propIsSeparate','propSeparateBillingClass','propSeparateBillingNo'],
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntStart: 40,
        cntEnd: 63,
        tempKbnStart: 1,
        tempKbnEnd: 2,
        chouhyouId: 'idChouhyou',
        addressOfficeId_1: 1,
        addressOfficeId_2: 2,
        textLength_110: 110,
        textLength_150: 150,
        textLength_160: 160,
        textLength_170: 170,
        textLength_178: 178,
        textLength_180: 180,
        textLength_190: 190,
        textLength_200: 200,
        textLength_210: 210,
        textLength_225: 225,
        textLength_230: 230,
        textLength_280: 280,
        textLength_287: 287,
        textLength_297: 297,
        textLength_300: 300,
        textLength_450: 450,
        textLength_480: 480,
        textLength_510: 510,
        textLength_589: 589,
        textLength_625: 625,
        textLength_636: 636,
        textLength_720: 720,
        textLength_836: 836,
        selectLimit: 2500,
      },
      // ヘッダ
      menu_type: 'user',
      title: '請求書（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      chouhyouSize: {
        width: '315mm',
        height: '445mm',
      },
      listKokyaku: [],
      csvClientId: '',
      // 営業所情報
      officeList: [{
        keyNo:'',
        zipCode: '',
        address: '',
        phoneNumber: '',
        faxNumber: '',
      },
      {
        keyNo:'',
        zipCode: '',
        address: '',
        phoneNumber: '',
        faxNumber: '',
      }],
      // アラート
      alertWarning: [],
      alertDanger: [],
      // 請求前情報
      preBilling: {
        processMonthYear: 0,
        closingDate: 0,
        billingStartDate: '',
        billingEndDate: '',
      },
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        invoiceInputNo: '',
        lightTaxMark: '',
      },
      // パラメータ
      billingMonthYear: null,
      billingOutputClass: null,
      siteIdStart: null,
      siteIdEnd: null,
      billingPaymentClass: 0,
      defBillingIssueInputDate: formatCurDate(),
      isSeparate: false,
      invoiceIssueWay: Const.InvoiceIssueWay.allIssue,
      separateBillingClass: Const.SeparateBillingClass.billings,
      separateBillingNo: '',
      isUploadCheck: false,
      // テーブル名
      billingsTableName: '',
      // サービス区分
      serviceList: [],
      // pdf帳票サイズ
      pdfChouhyouSize: {
        width: null,
        height: null,
      },
      // ログイン情報
      loginId: this.$store.getters.user.username,
      loginOfficeId: null,
      loginStaffId: null,
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertWarning.length > 0 || this.alertDanger.length > 0
    },
  },
  /* マウント */
  mounted() {
    // 印刷レイアウト設定
    setPaperA4();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    document.body.style['padding-top'] = '0px';
    // 初期設定
    this.fetchData();
    // 印刷ファイルのデフォルト名
    document.title = '請求書_' + this.billingMonthYear;
  },
  /* 関数群 */
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // パラメータ設定
        this.setParams();
        // パラメータチェック
        if (this.billingMonthYear == null) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2001']);
          this.$store.commit('setLoading', false);
          return;
        }
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let serviceListData = null;
        let staffListData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [serviceListData, staffListData] = await Promise.all([
          selectOneTable('m_service_classes'),
          selectOneTable('m_staffs', where_clause),
          this.getBillingDateInfo(),
          this.getOfficesData(),
        ]);
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        //console.log(serviceListData);
        for(let i = 0; i < serviceListData.length; i++){
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // ログイン営業所コードを取得
        this.loginOfficeId = staffListData[0].office_id;
        this.loginStaffId = staffListData[0].staff_id;
        // 表示データ設定
        await this.setDispData();
        // アップロード処理
        if (this.listKokyaku.length > 0 && this.isUploadCheck == true) {
          // 呼出し元のアップロードチェックがONの場合
          await this.execDocumentsUpload();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // パラメータ設定
    setParams: function() {
      // paramsを確認
      if (getNullStr(this.propBillingMonthYear) != '') {
        this.billingMonthYear = this.propBillingMonthYear;
        this.billingOutputClass = this.propBillingOutputClass;
        this.siteIdStart = this.propSiteIdStart;
        this.siteIdEnd = this.propSiteIdEnd;
        for (let i = 0; i < this.propCheckedClientIds.length; i++) {
          if (this.csvClientId != '') {
            this.csvClientId += ',';
          }
          this.csvClientId += this.propCheckedClientIds[i];
        }
        this.isSeparate = this.propIsSeparate == '1';
        this.invoiceIssueWay = this.propInvoiceIssueWay == Const.InvoiceIssueWay.partIssue ? Const.InvoiceIssueWay.partIssue : Const.InvoiceIssueWay.allIssue;
        if (this.isSeparate == true) {
          this.separateBillingClass = this.propSeparateBillingClass;
          this.separateBillingNo = this.propSeparateBillingNo;
        }
        // パラメータ取得
        if (this.propBillingPaymentClass == Const.BillingPaymentClass.billing2) {
          this.billingPaymentClass = Const.BillingPaymentClass.billing2;
          this.billingsTableName = 'm_billings2';
        } else {
          this.billingPaymentClass = Const.BillingPaymentClass.billing;
          this.billingsTableName = 'm_billings';
        }
      } else if (getNullStr(this.$route.query.propBillingMonthYear) != '') {
        this.billingMonthYear = this.$route.query.propBillingMonthYear;
        this.billingOutputClass = this.$route.query.propBillingOutputClass;
        this.siteIdStart = this.$route.query.propSiteIdStart;
        this.siteIdEnd = this.$route.query.propSiteIdEnd;
        this.csvClientId = this.$route.query.propCheckedClientIds;
        this.isSeparate = this.$route.query.propIsSeparate == '1';
        this.invoiceIssueWay = this.$route.query.propInvoiceIssueWay == Const.InvoiceIssueWay.partIssue ? Const.InvoiceIssueWay.partIssue : Const.InvoiceIssueWay.allIssue;
        this.isUploadCheck = this.$route.query.propIsUploadCheck == '1';
        if (this.isSeparate == true) {
          this.separateBillingClass = this.$route.query.propSeparateBillingClass;
          this.separateBillingNo = this.$route.query.propSeparateBillingNo;
        }
        if (this.$route.query.propBillingPaymentClass == Const.BillingPaymentClass.billing2) {
          this.billingPaymentClass = Const.BillingPaymentClass.billing2;
          this.billingsTableName = 'm_billings2';
        } else {
          this.billingPaymentClass = Const.BillingPaymentClass.billing;
          this.billingsTableName = 'm_billings';
        }
      }
    },
    /* 表示データ設定 */
    async setDispData() {
      // 検索SQL作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      try {
        // 伝票情報取得
        let dataResult = await executeSelectSqlNoLimit(selectSql, this.constData.selectLimit);
        //console.log(dataResult);
        if (dataResult != null && dataResult.length > 0) {
          // 取引先毎、または、現場毎の一覧を作成
          this.initListKokyaku(dataResult);
          // ページ毎の置換文字列設定
          await this.createReplacementsPage(dataResult);
        }
        // 顧客（取引先・現場）毎の合計値の置換文字列設定
        await this.createReplacementsTotal();
        if (this.listKokyaku.length > 0) {
          // 作成した置換文字データをSVGファイルに設定
          this.setChouhyou();
        } else {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','請求書'));
          return;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 検索SELECT文字列作成
    makeSelectSql: function() {
      /* 請求締更新前のデータ */
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' billings_QUERY.billing_end_date';
      selectSql += ',billings_QUERY.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.zip_code';
      selectSql += ',clients.address_1';
      selectSql += ',clients.address_2';
      selectSql += ',clients.department_1';
      selectSql += ',clients.department_2';
      selectSql += ',billings_QUERY.site_id';
      selectSql += ',billings_QUERY.client_site_name';
      if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
        selectSql += ',clients_sites.client_site_name_kanji AS client_site_name_master';
      }
      selectSql += ',IfNull(billings_QUERY.payment_scheduled,';
      selectSql += '   CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '     THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '     ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   END';
      selectSql += ' ) AS payment_scheduled';
      selectSql += ',billings_QUERY.billing_no';
      selectSql += ',billings_QUERY.billing_row';
      selectSql += ',billings_QUERY.billing_date';
      selectSql += ',billings_QUERY.product_id';
      selectSql += ',billings_QUERY.product_name';
      selectSql += ',products.product_tax_rate_class_sales';
      selectSql += ',billings_QUERY.product_quantity';
      selectSql += ',billings_QUERY.product_unit';
      selectSql += ',billings_QUERY.product_sales_unit_price';
      selectSql += ',billings_QUERY.product_sales_unit_amount';
      selectSql += ',billings_QUERY.product_tax';
      selectSql += ',billings_QUERY.service_class';
      selectSql += ',billings_QUERY.sundries_class';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += '(' + this.makeMainQuery() + ') AS billings_QUERY ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND billings_QUERY.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN m_products AS products ';
      selectSql += 'ON billings_QUERY.product_id = products.product_id ';
      if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
        // 現場マスタ（現場別の場合のみ）
        selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
        selectSql += 'ON billings_QUERY.client_id = clients_sites.client_id ';
        selectSql += 'AND billings_QUERY.site_id = clients_sites.site_id ';
      }
      /* ORDER BY句 */
      // ソート（取引先別、現場別によって異なる）
      if (this.billingOutputClass == Const.BillingOutputClass.client) {
        // 取引先別
        selectSql += 'ORDER BY billings_QUERY.client_id,billings_QUERY.billing_date,billings_QUERY.billing_no,billings_QUERY.billing_row';
      } else {
        // 現場別
        selectSql += 'ORDER BY billings_QUERY.client_id,billings_QUERY.site_id,billings_QUERY.billing_date,billings_QUERY.billing_no,billings_QUERY.billing_row';
      }
      return selectSql;
    },
    // メインクエリ作成
    makeMainQuery: function() {
      /* 請求締更新前のデータ */
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT';
      selectSql1 += ' billings.billing_end_date';
      selectSql1 += ',billings.client_id';
      selectSql1 += ',billings.site_id';
      selectSql1 += ',cumulative_transaction.client_site_name';
      selectSql1 += ',NULL AS payment_scheduled';
      selectSql1 += ',cumulative_transaction.billing_no';
      selectSql1 += ',cumulative_transaction.billing_row';
      selectSql1 += ',cumulative_transaction.billing_date';
      selectSql1 += ',cumulative_transaction.product_id';
      selectSql1 += ',cumulative_transaction.product_name';
      selectSql1 += ',FORMAT(cumulative_transaction.product_quantity,0) AS product_quantity';
      selectSql1 += ',cumulative_transaction.product_unit';
      selectSql1 += ',FORMAT(cumulative_transaction.product_sales_unit_price,0) AS product_sales_unit_price';
      selectSql1 += ',FORMAT(cumulative_transaction.product_sales_unit_amount,0) AS product_sales_unit_amount';
      selectSql1 += ',FORMAT(cumulative_transaction.product_tax,0) AS product_tax';
      selectSql1 += ',cumulative_transaction.service_class';
      selectSql1 += ',cumulative_transaction.product_mouth_class AS sundries_class';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += this.billingsTableName + ' AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\') ';
      selectSql1 += 'AND cumulative_transaction.product_id <> 0 ';
      if (this.invoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
        // 一部伝票先行発行取引先の場合
        // 請求書発行登録＿伝票番号毎テーブルと結合
        selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
        selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
        if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
          // 現場別の場合
          // 請求書発行登録＿現場毎テーブルと結合
          selectSql1 += 'LEFT JOIN t_billings_issue_input_site AS billings_issue_input_site ';
          selectSql1 += 'ON billings_issue_input_site.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
          selectSql1 += 'AND cumulative_transaction.client_id = billings_issue_input_site.client_id ';
          selectSql1 += 'AND cumulative_transaction.site_id = billings_issue_input_site.site_id ';
        }
      }
      if (this.isSeparate == true && this.separateBillingClass == Const.SeparateBillingClass.sites) {
        // 先行発行（現場別）の場合
        // 請求書発行登録＿伝票番号毎テーブルと結合
        selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
        selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      }
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      // 請求書出力区分
      selectSql1 += 'billings.billing_output_class = ' + this.billingOutputClass + ' ';
      // 得意先コード
      selectSql1 += 'AND billings.client_id IN (' + this.csvClientId + ') ';
      if (this.isSeparate == true) {
        // 伝票別・現場別からの出力
        if (this.separateBillingClass == Const.SeparateBillingClass.billings) {
          // 伝票毎
          selectSql1 += 'AND cumulative_transaction.billing_no IN (' + this.separateBillingNo + ') ';
        } else {
          // 現場毎
          selectSql1 += 'AND cumulative_transaction.site_id IN (' + this.siteIdStart + ') ';
          // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外
          selectSql1 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
        }
      } else {
        // 現場コード（現場別の場合のみ）
        if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
          // 現場別の場合、0は除外（0は取引先全体）
          selectSql1 += 'AND cumulative_transaction.site_id > 0 ';
          if (this.siteIdStart != '' && this.siteIdEnd == '') {
            selectSql1 += 'AND cumulative_transaction.site_id >= ' + this.siteIdStart + ' ';
          }else if (this.siteIdStart == '' && this.siteIdEnd != '') {
            selectSql1 += 'AND cumulative_transaction.site_id <= ' + this.siteIdEnd + ' ';
          }else if (this.siteIdStart != '' && this.siteIdEnd != '') {
            selectSql1 += 'AND cumulative_transaction.site_id BETWEEN ' + this.siteIdStart + ' ' + 'AND ' + this.siteIdEnd + ' ';
          }
        }
      }
      if (this.invoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
        // 一部伝票先行発行取引先の場合
        // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外
        selectSql1 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
        if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
          // 現場別の場合
          // 請求書発行登録＿現場毎テーブルに登録されている現場は除外
          selectSql1 += 'AND billings_issue_input_site.site_id IS NULL ';
        }
      }
      /* 請求締更新後のデータ */
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT';
      selectSql2 += ' billings_balances.billing_end_date';
      selectSql2 += ',billings_balances.client_id';
      selectSql2 += ',billings_balances.site_id';
      selectSql2 += ',cumulative_transaction.client_site_name';
      selectSql2 += ',billings_balances.payment_scheduled';
      selectSql2 += ',cumulative_transaction.billing_no';
      selectSql2 += ',cumulative_transaction.billing_row';
      selectSql2 += ',cumulative_transaction.billing_date';
      selectSql2 += ',cumulative_transaction.product_id';
      selectSql2 += ',cumulative_transaction.product_name';
      selectSql2 += ',FORMAT(cumulative_transaction.product_quantity,0) AS product_quantity';
      selectSql2 += ',cumulative_transaction.product_unit';
      selectSql2 += ',FORMAT(cumulative_transaction.product_sales_unit_price,0) AS product_sales_unit_price';
      selectSql2 += ',FORMAT(cumulative_transaction.product_sales_unit_amount,0) AS product_sales_unit_amount';
      selectSql2 += ',FORMAT(cumulative_transaction.product_tax,0) AS product_tax';
      selectSql2 += ',cumulative_transaction.service_class';
      selectSql2 += ',cumulative_transaction.product_mouth_class AS sundries_class';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += 't_billings_balances AS billings_balances ';
      selectSql2 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql2 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql2 += 'AND billings_balances.client_id = cumulative_transaction.client_id ';
      selectSql2 += 'AND billings_balances.site_id = cumulative_transaction.site_id ';
      selectSql2 += 'AND billings_balances.billing_month_year = cumulative_transaction.closing_month_year ';
      selectSql2 += 'AND cumulative_transaction.is_update_closing_date = 1 ';
      selectSql2 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\') ';
      selectSql2 += 'AND cumulative_transaction.product_id <> 0 ';
      if (this.invoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
        // 一部伝票先行発行取引先の場合
        // 請求書発行登録＿伝票番号毎テーブルと結合
        selectSql2 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
        selectSql2 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql2 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
        if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
          // 現場別の場合
          // 請求書発行登録＿現場毎テーブルと結合
          selectSql2 += 'LEFT JOIN t_billings_issue_input_site AS billings_issue_input_site ';
          selectSql2 += 'ON billings_issue_input_site.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
          selectSql2 += 'AND cumulative_transaction.client_id = billings_issue_input_site.client_id ';
          selectSql2 += 'AND cumulative_transaction.site_id = billings_issue_input_site.site_id ';
        }
      }
      if (this.isSeparate == true && this.separateBillingClass == Const.SeparateBillingClass.sites) {
        // 先行発行（現場別）の場合
        // 請求書発行登録＿伝票番号毎テーブルと結合
        selectSql2 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
        selectSql2 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql2 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      }
      /* WHERE句 */
      selectSql2 += ' WHERE ';
      // 請求年月
      selectSql2 += ' cumulative_transaction.closing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      // 請求開始日
      selectSql2 += 'AND billings_balances.billing_start_date IS NOT NULL ';
      // 請求書出力区分
      selectSql2 += 'AND billings_balances.billing_output_class = ' + this.billingOutputClass + ' ';
      // 得意先コード
      selectSql2 += 'AND billings_balances.client_id IN (' + this.csvClientId + ') ';
      if (this.isSeparate == true) {
        // 伝票別・現場別からの出力
        if (this.separateBillingClass == Const.SeparateBillingClass.billings) {
          // 伝票毎
          selectSql2 += 'AND cumulative_transaction.billing_no IN (' + this.separateBillingNo + ') ';
        } else {
          // 現場毎
          selectSql2 += 'AND cumulative_transaction.site_id IN (' + this.siteIdStart + ') ';
          // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外
          selectSql2 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
        }
      } else {
        // 現場コード（現場別の場合のみ）
        if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
          // 現場別の場合、0は除外（0は取引先全体）
          selectSql2 += 'AND billings_balances.site_id > 0 ';
          if (this.siteIdStart != '' && this.siteIdEnd == '') {
            selectSql2 += 'AND billings_balances.site_id >= ' + this.siteIdStart + ' ';
          }else if (this.siteIdStart == '' && this.siteIdEnd != '') {
            selectSql2 += 'AND billings_balances.site_id <= ' + this.siteIdEnd + ' ';
          }else if (this.siteIdStart != '' && this.siteIdEnd != '') {
            selectSql2 += 'AND billings_balances.site_id BETWEEN ' + this.siteIdStart + ' ' + 'AND ' + this.siteIdEnd + ' ';
          }
        }
      }
      if (this.invoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
        // 一部伝票先行発行取引先の場合
        // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外
        selectSql2 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
        if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
          // 現場別の場合
          // 請求書発行登録＿現場毎テーブルに登録されている現場は除外
          selectSql2 += 'AND billings_issue_input_site.site_id IS NULL ';
        }
      }
      // 2つのSELECT文をUNION
      let selectSql = '';
      if (this.preBilling.processMonthYear > 0 && this.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 画面から選択した年月が請求前処理の年月と同じ場合
        // 2つのSELECT文をUNION
        selectSql = selectSql1 + ' UNION ALL ' + selectSql2;
      } else {
        // 画面から選択した年月が請求前処理の年月と異なる場合
        // 請求残高の登録情報のみを表示
        selectSql = selectSql2;
      }

      return selectSql;
    },
    /* 営業所情報を取得 */
    async getOfficesData() {
      const functionName = 'getOfficesData'
      try {
        // 営業所データ取得
        let officeListData = await selectOneTable('m_offices');
        //console.log(officeListData);
        for(let i = 0; i < officeListData.length; i++){
          if (this.constData.addressOfficeId_1 == officeListData[i].office_id ||
            this.constData.addressOfficeId_2 == officeListData[i].office_id) {
            if (this.constData.addressOfficeId_1 == officeListData[i].office_id) {
              this.officeList[0].keyNo = '1';
              this.officeList[0].zipCode = '〒' + getNullStr(officeListData[i].zip_code);
              this.officeList[0].address = getNullStr(officeListData[i].address_1) + getNullStr(officeListData[i].address_2);
              this.officeList[0].phoneNumber = 'TEL ' + getNullStr(officeListData[i].phone_number);
              this.officeList[0].faxNumber = 'FAX ' + getNullStr(officeListData[i].fax_number);
            } else {
              this.officeList[1].keyNo = '2';
              this.officeList[1].zipCode = '〒' + getNullStr(officeListData[i].zip_code);
              this.officeList[1].address = getNullStr(officeListData[i].address_1) + getNullStr(officeListData[i].address_2);
              this.officeList[1].phoneNumber = 'TEL ' + getNullStr(officeListData[i].phone_number);
              this.officeList[1].faxNumber = 'FAX ' + getNullStr(officeListData[i].fax_number);
            }
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 顧客（取引先、現場）毎の一覧を作成 */
    initListKokyaku: function(result) {
      //console.log('initListKokyaku');
      let preClientId = ''; // 前行の取引先コード
      let preSiteId = ''; // 前行の現場コード
      let preBillingNo = 0; // 前行の伝票番号
      let billingCnt = 0; // 伝票数
      let chouhyouRowCnt = 0; // 帳票の必要行数
      // DBの結果分ループ
      for (let i = 0; i < result.length; i++) {
        // 取引先別で前行も同じ取引先、または、
        // 現場別で前行も同じ取引先－現場
        if ((this.billingOutputClass == Const.BillingOutputClass.client && result[i].client_id == preClientId) ||
            (this.billingOutputClass == Const.BillingOutputClass.clientSite && result[i].client_id == preClientId && result[i].site_id == preSiteId)) {
          // 伝票番号の切り替えを確認
          if (preBillingNo == result[i].billing_no) {
            chouhyouRowCnt++; // 製品行
          } else {
            chouhyouRowCnt += 3; // 製品行、伝票・現場情報行、空行
            billingCnt++;
            preBillingNo = result[i].billing_no;
          }
        } else {
          if (preClientId != '') {
            // 初回以外の場合、別の顧客（取引先、現場）に移ったタイミングでまとめて一覧に追加
            //console.log(billingCnt);
            this.listKokyaku.push(
              { clientId: preClientId, siteId: this.billingOutputClass == Const.BillingOutputClass.client ? 0 : preSiteId, billingCnt: billingCnt, listChouhyou: this.createListChouhyou(chouhyouRowCnt) });
          }
          // 帳票情報の初期化と次の顧客を記録
          chouhyouRowCnt = 2; // 製品行、伝票・現場情報行
          billingCnt = 1;
          preBillingNo = result[i].billing_no;
          preClientId = result[i].client_id;
          preSiteId = result[i].site_id;
        }
      }
      // 初回以外の場合、別の顧客（取引先、現場）に移ったタイミングでまとめて一覧に追加
      //console.log(billingCnt);
      this.listKokyaku.push({ clientId: preClientId, siteId: this.billingOutputClass == Const.BillingOutputClass.client ? 0 : preSiteId, billingCnt: billingCnt, listChouhyou: this.createListChouhyou(chouhyouRowCnt) });
    },
    /* 帳票リスト作成 */
    createListChouhyou: function(productCnt){
      //console.log('createListChouhyou');
      let listChouhyou = [];
      if (productCnt <= this.constData.cntStart) {
        // 製品が通常テンプレートの件数で収まる場合（通常帳票1ページのみ）
        listChouhyou.push({page: 1, tempKbn: this.constData.tempKbnStart, replacements: []});
      } else {
        let productCntNokori = productCnt;
        let page = 1;
        // 開始帳票
        listChouhyou.push({page: page, tempKbn: this.constData.tempKbnStart, replacements: []});
        productCntNokori -= this.constData.cntStart;
        page++;
        // 中間帳票
        while (productCntNokori > this.constData.cntEnd) {
          // 残りの製品件数が最終帳票の件数に収まらない場合、中間帳票を出力し続ける
          listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
          productCntNokori -= this.constData.cntEnd;
          page++;
        }
        // 終了帳票
        listChouhyou.push({page: page, tempKbn: this.constData.tempKbnEnd, replacements: []});
      }
      return listChouhyou;
    },
    /* 顧客（取引先、現場）毎の置換配列セット */
    async createReplacementsPage(result){
      const functionName = 'createReplacementsPage'
      try {
        //console.log('createReplacementsPage');
        let index = 0;
        let preBillingNo = 0;
        let curClientId = 0;
        let curSiteId = 0;
        let billingFlg = true;
        let chkWidth = false;
        let strWork = '';
        for (let k = 0; k < this.listKokyaku.length; k++) {
          // 現在の顧客を保持
          curClientId = this.listKokyaku[k].clientId;
          curSiteId = this.listKokyaku[k].siteId;
          // 顧客（取引先、現場）変更時の初期化
          preBillingNo = 0;
          billingFlg = true;
          for (let i = 0; i < this.listKokyaku[k].listChouhyou.length; i++) {
            // SVGファイルの置換用文字列
            let replacements = [];
            // お客様番号
            replacements.push({key: '%お客様番号%', value: curClientId, textAnchor: 'start', textLength: this.constData.textLength_300, chkWidth: false});
            // ページ番号
            replacements.push({key: '%P%', value: this.listKokyaku[k].listChouhyou[i].page + ' / ' + this.listKokyaku[k].listChouhyou.length, textAnchor: 'end', textLength: this.constData.textLength_178, chkWidth: false});
  
            // 開始帳票
            if (this.listKokyaku[k].listChouhyou[i].tempKbn == this.constData.tempKbnStart) {
              // タイトル
              replacements.push({key: '%タイトル%', value: '請　求　書', textAnchor: 'middle', textLength: this.constData.textLength_636, chkWidth: false});
              // 郵便番号
              replacements.push({key: '%郵便番号%', value: result[index].zip_code, textAnchor: 'start', textLength: this.constData.textLength_300, chkWidth: false});
              // 住所１
              if (getNullStr(result[index].address_1).length > 20) {
                chkWidth = true;
              } else {
                chkWidth = false;
              }
              replacements.push({key: '%住所１%', value: result[index].address_1, textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: chkWidth});
              // 住所２
              if (getNullStr(result[index].address_2).length > 20) {
                chkWidth = true;
              } else {
                chkWidth = false;
              }
              replacements.push({key: '%住所２%', value: result[index].address_2, textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: chkWidth});
              // 取引先名
              if (getNullStr(result[index].client_name_kanji).length > 12) {
                chkWidth = true;
              } else {
                chkWidth = false;
              }
              replacements.push({key: '%取引先名%', value: result[index].client_name_kanji, textAnchor: 'start', textLength: this.constData.textLength_589, chkWidth: chkWidth});
              // 部署１
              if (getNullStr(result[index].department_1).length > 20) {
                chkWidth = true;
              } else {
                chkWidth = false;
              }
              replacements.push({key: '%部署１%', value: result[index].department_1, textAnchor: 'start', textLength: this.constData.textLength_625, chkWidth: chkWidth});
              // 部署２
              if (getNullStr(result[index].department_2).length > 20) {
                chkWidth = true;
              } else {
                chkWidth = false;
              }
              replacements.push({key: '%部署２%', value: result[index].department_2, textAnchor: 'start', textLength: this.constData.textLength_625, chkWidth: chkWidth});
              // 営業所情報
              await this.setOfficesData(replacements);
              // 現場
              if (this.billingOutputClass == Const.BillingOutputClass.client) {
                replacements.push({key: '%現場%', value: '', textAnchor: 'start', textLength: this.constData.textLength_836, chkWidth: false});
              } else {
                if (getNullStr(result[index].client_site_name_master).length > 20) {
                  chkWidth = true;
                } else {
                  chkWidth = false;
                }
                replacements.push({key: '%現場%', value: '現場：' + getNullStr(result[index].client_site_name_master), textAnchor: 'start', textLength: this.constData.textLength_836, chkWidth: chkWidth});
              }
              // 締切日
              replacements.push({key: '%締切日%', value: getNullStr(formatDate(result[index].billing_end_date)) + '締切', textAnchor: 'start', textLength: this.constData.textLength_297, chkWidth: false});
              this.listKokyaku[k].closingYmd = result[index].billing_end_date;
              // 入金予定
              replacements.push({key: '%入金予定%', value: formatDate(result[index].payment_scheduled), textAnchor: 'start', textLength: this.constData.textLength_480, chkWidth: false});
              // 軽減税率の説明
              replacements.push({key: '%税率説明%', value: '「' + this.controlMasterData.lightTaxMark + '」は軽減税率対象項目', textAnchor: 'start', textLength: 440, chkWidth: false});
              // 銀行名
              for (let j = 0; j < Const.bankNameList.length; j++) {
                replacements.push({key: Const.bankNameList[j].key, value: Const.bankNameList[j].value, textAnchor: 'start', textLength: Const.bankNameList[j].textLength, chkWidth: true});
              }
            }
            // 帳票毎に設定可能な製品の件数
            let billingCntByChouhyou = 0;
            if (this.listKokyaku[k].listChouhyou[i].tempKbn == this.constData.tempKbnStart) {
              billingCntByChouhyou = this.constData.cntStart;
            } else {
              billingCntByChouhyou = this.constData.cntEnd;
            }
            // 伝票
            for (let j = 0; j < billingCntByChouhyou; j++) {
              // 先頭以外で伝票番号が切り替わった場合、または、
              // 取引先別で前行も同じ取引先、または、
              // 現場別で前行も同じ取引先－現場、または、
              // テーブルデータの参照が終わった場合
              if (result.length <= index ||
                  (preBillingNo != 0 && preBillingNo != result[index].billing_no) ||
                  (this.billingOutputClass == Const.BillingOutputClass.client && result[index].client_id != curClientId) ||
                  (this.billingOutputClass == Const.BillingOutputClass.clientSite && (result[index].client_id != curClientId || result[index].site_id != curSiteId))) {
                /* 空行 */
                // 日付
                replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_160, chkWidth: false});
                // コード
                replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_170, chkWidth: false});
                // 名称
                replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: false});
                // 数量
                replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_150, chkWidth: false});
                // 単価
                replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: false});
                // 金額
                replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: false});
                if (index < result.length) {
                  preBillingNo = result[index].billing_no;
                }
                billingFlg = true;
              }else{
                if (billingFlg == true) {
                  /* 伝票・現場行 */
                  // 日付
                  replacements.push({key: '%日付' + (j + 1).toString() + '%', value: formatDate(result[index].billing_date).substr(2), textAnchor: 'start', textLength: this.constData.textLength_160, chkWidth: false});
                  // コード
                  if (getNullStr(result[index].billing_no).length > 8) {
                    chkWidth = true;
                  } else {
                    chkWidth = false;
                  }
                  replacements.push({key: '%コード' + (j + 1).toString() + '%', value: result[index].billing_no, textAnchor: 'start', textLength: this.constData.textLength_170, chkWidth: chkWidth});
                  // 名称
                  if (getNullStr(result[index].client_site_name).length > 20) {
                    chkWidth = true;
                  } else {
                    chkWidth = false;
                  }
                  replacements.push({key: '%名称' + (j + 1).toString() + '%', value: result[index].client_site_name, textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: chkWidth});
                  // 数量
                  replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_150, chkWidth: false});
                  // 単価
                  replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: false});
                  // 金額
                  replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: false});
                  billingFlg = false;
                } else {
                  if (result[index].product_id == '0') {
                    /* 消費税行 */
                    // 日付
                    replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_160, chkWidth: false});
                    // コード
                    replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_170, chkWidth: false});
                    // 名称
                    replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '消費税', textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: false});
                    // 数量
                    replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_150, chkWidth: false});
                    // 単価
                    replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: false});
                    // 金額
                    if (getNullStr(result[index].product_tax).length > 9) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%金額' + (j + 1).toString() + '%', value: result[index].product_tax, textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: chkWidth});
                  } else {
                    /* 製品行 */
                    // 日付
                    replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_160, chkWidth: false});
                    // コード
                    if (getNullStr(result[index].product_id).length > 8) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%コード' + (j + 1).toString() + '%', value: result[index].product_id, textAnchor: 'start', textLength: this.constData.textLength_170, chkWidth: chkWidth});
                    // 名称
                    strWork = getNullStr(result[index].product_name);
                    if (getNullStr(result[index].product_tax_rate_class_sales) == Const.ProductTaxRateClass.lightTax) {
                      strWork = this.controlMasterData.lightTaxMark + ' ' + strWork;
                    }
                    if (getNullStr(strWork).length > 20) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%名称' + (j + 1).toString() + '%', value: strWork, textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: chkWidth});
                    // 数量
                    if (getNullStr(result[index].product_quantity) == '0' && result[index].sundries_class == Const.SundriesClass.shokuchi) {
                      strWork = '';
                    } else {
                      strWork = result[index].product_quantity + (getNullStr(result[index].product_unit) == '' ? '' : ' ' + result[index].product_unit);
                    }
                    if (getNullStr(strWork).length > 5) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%数量' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: this.constData.textLength_150, chkWidth: chkWidth});
                    // 単価
                    if (getNullStr(result[index].service_class) != '') {
                      strWork = getNullStr(getListValue(this.serviceList, result[index].service_class, 'id', 'text'));
                      if (strWork.length > 5) {
                        chkWidth = true;
                      } else {
                        chkWidth = false;
                      }
                      replacements.push({key: '%単価' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: chkWidth});
                    } else {
                      if (getNullStr(result[index].product_quantity) == '0' && getNullStr(result[index].product_sales_unit_price) == '0' && result[index].sundries_class == Const.SundriesClass.shokuchi) {
                        strWork = '';
                      } else {
                        strWork = getNullStr(result[index].product_sales_unit_price);
                      }
                      if (strWork.length > 9) {
                        chkWidth = true;
                      } else {
                        chkWidth = false;
                      }
                      replacements.push({key: '%単価' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: chkWidth});
                    }
                    // 金額
                    if (getNullStr(result[index].product_quantity) == '0' && getNullStr(result[index].product_sales_unit_price) == '0' && result[index].sundries_class == Const.SundriesClass.shokuchi) {
                      strWork = '';
                    } else {
                      strWork = getNullStr(result[index].product_sales_unit_amount);
                    }
                    if (strWork.length > 9) {
                      chkWidth = true;
                    } else {
                      chkWidth = false;
                    }
                    replacements.push({key: '%金額' + (j + 1).toString() + '%', value: strWork, textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: chkWidth});
                  }
                  preBillingNo = result[index].billing_no;
                  index++;
                }
              }
            }
            //console.log('replacements');
            //console.log(replacements);
            this.listKokyaku[k].listChouhyou[i].replacements = replacements;
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 置換文字列に営業所情報を設定 */
    async setOfficesData(replacements) {
      for(let i = 0; i < this.officeList.length; i++){
        // 郵便
        replacements.push({key: '%郵便' + this.officeList[i].keyNo + '%', value: this.officeList[i].zipCode, textAnchor: 'start', textLength: this.constData.textLength_150, chkWidth: true});
        // 住所
        replacements.push({key: '%住所' + this.officeList[i].keyNo + '%', value: this.officeList[i].address, textAnchor: 'start', textLength: this.constData.textLength_287, chkWidth: true});
        // TEL
        replacements.push({key: '%TEL' + this.officeList[i].keyNo + '%', value: this.officeList[i].phoneNumber, textAnchor: 'start', textLength: this.constData.textLength_225, chkWidth: true});
        // FAX
        replacements.push({key: '%FAX' + this.officeList[i].keyNo + '%', value: this.officeList[i].faxNumber, textAnchor: 'start', textLength: this.constData.textLength_225, chkWidth: true});
      }
    },
    /* 顧客（取引先、現場）毎の合計値の置換配列セット */
    async createReplacementsTotal() {
      //console.log('createReplacementsTotal');
      let dataResult = null;
      if (this.isSeparate == true) {
        // 伝票別・現場別からの出力
        let selectSql = this.makeSelectSqlTotalSeparate();
        //console.log(selectSql);
        dataResult = await executeSelectSql(selectSql);
      } else {
        // 伝票別・現場別からの出力以外
        if (this.billingOutputClass == Const.BillingOutputClass.client) {
          // 取引先別
          let selectSql = '';
          selectSql = this.makeSelectSqlTotal01();
          //console.log(selectSql);
          dataResult = await executeSelectSql(selectSql);
        } else {
          // 現場別
          let selectSql = '';
          selectSql = this.makeSelectSqlTotal02();
          //console.log(selectSql);
          dataResult = await executeSelectSql(selectSql);
        }
      }
      //console.log(dataResult);
      if (dataResult != null && dataResult.length > 0) {
        let i = 0;
        // 全顧客参照ループ
        for (i = 0; i < this.listKokyaku.length; i++) {
          if (dataResult.length > i) {
            if (this.billingOutputClass == Const.BillingOutputClass.client) {
              // 取引先別の場合
              if (this.listKokyaku[i].clientId < dataResult[i].client_id) {
                // 合計値になくて、データ一覧の方にある取引先の場合は合計値が0として削除
                this.listKokyaku.splice(i, 1);
                i--;
                continue;
              }
            } else {
              // 現場別の場合
              if ((this.listKokyaku[i].clientId < dataResult[i].client_id) ||
              this.listKokyaku[i].clientId == dataResult[i].client_id && this.listKokyaku[i].siteId < dataResult[i].site_id) {
                // 合計値になくて、データ一覧の方にある取引先の場合は合計値が0として削除
                this.listKokyaku.splice(i, 1);
                i--;
                continue;
              }
            }
            if (this.billingOutputClass == Const.BillingOutputClass.client && (this.listKokyaku[i].clientId != dataResult[i].client_id)) {
              // listKokyakuとdataResultの顧客コードが異なる場合、前月のみのデータのため、合計部分以外が空の顧客を追加
              let blankChouhyou = [{page: 1, tempKbn: this.constData.tempKbnStart, replacements: await this.createReplacementsBlank(dataResult[i])}];
              let blankKokyaku = { clientId: dataResult[i].client_id, siteId: 0, billingCnt: 0, closingYmd: dataResult[i].billing_end_date, listChouhyou: blankChouhyou }
              this.listKokyaku.splice(i, 0, blankKokyaku);
            } else if (this.billingOutputClass == Const.BillingOutputClass.clientSite && dataResult[i].billings_print_class == 1) {
              // 請求書出力単位区分が「1:現場別」、且つ、現場マスタ.請求書印字区分が「1:請求書用取引先名を印字する」の場合
              for (let j = 0; j < this.listKokyaku[i].listChouhyou[0].replacements.length; j++) {
                if (this.listKokyaku[i].listChouhyou[0].replacements[j]['key'] == '%郵便番号%' ||
                  this.listKokyaku[i].listChouhyou[0].replacements[j]['key'] == '%住所１%' ||
                  this.listKokyaku[i].listChouhyou[0].replacements[j]['key'] == '%住所２%' ||
                  this.listKokyaku[i].listChouhyou[0].replacements[j]['key'] == '%部署１%' ||
                  this.listKokyaku[i].listChouhyou[0].replacements[j]['key'] == '%部署２%') {
                  // 郵便番号、住所、部署は削除
                  this.listKokyaku[i].listChouhyou[0].replacements[j]['value'] = '';
                } else if (this.listKokyaku[i].listChouhyou[0].replacements[j]['key'] == '%取引先名%') {
                  // 取引先名には「請求書用取引先名」を設定
                  this.listKokyaku[i].listChouhyou[0].replacements[j]['value'] = dataResult[i].billings_print_client_name;
                  // 取引先名
                  if (getNullStr(dataResult[i].billings_print_client_name).length > 12) {
                    this.listKokyaku[i].listChouhyou[0].replacements[j]['chkWidth'] = true;
                  }
                }
              }
            }
            await this.pushReplacementsTotal(dataResult, i);
          } else {
            // 伝票ありの帳票は存在するが、合計帳票が存在しない場合、合計値なしの帳票として削除
            this.listKokyaku.splice(i, 1);
            i--;
            continue;
          }
        }
        // 伝票ありの帳票を全部見ても、合計帳票が存在する場合、伝票無し帳票を追加
        while (this.billingOutputClass == Const.BillingOutputClass.client && i < dataResult.length) {
          let blankChouhyou = [{page: 1, tempKbn: this.constData.tempKbnStart, replacements: await this.createReplacementsBlank(dataResult[i])}];
          let blankKokyaku = { clientId: dataResult[i].client_id, siteId: 0, billingCnt: 0, closingYmd: dataResult[i].billing_end_date, listChouhyou: blankChouhyou }
          this.listKokyaku.push(blankKokyaku);
          await this.pushReplacementsTotal(dataResult, i);
          i++;
        }
      } else {
        if (this.listKokyaku != null && this.listKokyaku.length > 0) {
          // 伝票ありの帳票は存在するが、合計帳票が存在しない場合、合計値なしの帳票として削除
          for (let i = 0; i < this.listKokyaku.length; i++) {
            this.listKokyaku.splice(i, 1);
            i--;
          }
        }
      }
    },
    /* 顧客（取引先、現場）毎の合計値の置換配列セット */
    async pushReplacementsTotal(dataResult, i) {
      let chkWidth = false;
      let preBilling = Number(dataResult[i].pre_billing_no_tax_balance) == 0 ? '' : Number(dataResult[i].pre_billing_no_tax_balance).toLocaleString();
      let preBillingTax = Number(dataResult[i].pre_billing_tax_balance) == 0 ? '' : Number(dataResult[i].pre_billing_tax_balance).toLocaleString();
      let amount = Number(dataResult[i].amount_no_tax) == 0 ? '' : Number(dataResult[i].amount_no_tax).toLocaleString();
      let amountTax = Number(dataResult[i].amount_tax) == 0 ? '' : Number(dataResult[i].amount_tax).toLocaleString();
      let forward = (Number(dataResult[i].pre_billing_no_tax_balance) - Number(dataResult[i].amount_no_tax) == 0 ? '' : (Number(dataResult[i].pre_billing_no_tax_balance) - Number(dataResult[i].amount_no_tax)).toLocaleString());
      let forwardTax = (Number(dataResult[i].pre_billing_tax_balance) - Number(dataResult[i].amount_tax) == 0 ? '' : (Number(dataResult[i].pre_billing_tax_balance) - Number(dataResult[i].amount_tax)).toLocaleString());
      let productAmount = Number(dataResult[i].product_sales_unit_amount).toLocaleString();
      let productAmountTax = Number(dataResult[i].product_tax).toLocaleString();
      let billing = (Number(dataResult[i].pre_billing_no_tax_balance) - Number(dataResult[i].amount_no_tax) + Number(dataResult[i].product_sales_unit_amount)).toLocaleString();
      let billingTax = (Number(dataResult[i].pre_billing_tax_balance) - Number(dataResult[i].amount_tax) + Number(dataResult[i].product_tax)).toLocaleString();
      let billingTotal = (Number(dataResult[i].pre_billing_no_tax_balance) - Number(dataResult[i].amount_no_tax) + Number(dataResult[i].product_sales_unit_amount) + Number(dataResult[i].pre_billing_tax_balance) - Number(dataResult[i].amount_tax) + Number(dataResult[i].product_tax)).toLocaleString();
      this.listKokyaku[i].billingTotal = billingTotal.replace(/,/g, ''); // 全体の合計額を記録
      let normalTaxSubTotal = Number(dataResult[i].closing_date_normal_tax_subtotal).toLocaleString();
      let normalTax = Number(dataResult[i].closing_date_normal_tax).toLocaleString();
      let lightTaxSubTotal = Number(dataResult[i].closing_date_light_tax_subtotal).toLocaleString();
      let lightTax = Number(dataResult[i].closing_date_light_tax).toLocaleString();
      let noTaxSubTotal = (Number(dataResult[i].product_sales_unit_amount) - Number(dataResult[i].closing_date_normal_tax_subtotal) - Number(dataResult[i].closing_date_light_tax_subtotal)).toLocaleString();
      let billingIssueInputDate = dataResult[i].billing_issue_input_date == null ? this.defBillingIssueInputDate : formatDate(dataResult[i].billing_issue_input_date);
      let invoiceInputNo = dataResult[i].invoice_input_no == null ? this.controlMasterData.invoiceInputNo : dataResult[i].invoice_input_no;
      let taxRate = 0;
      let lightTaxRate = 0;
      if (this.controlMasterData.newTaxStartDate == null ||
        dateConsistency(this.controlMasterData.newTaxStartDate, billingIssueInputDate) == false) {
        taxRate = this.controlMasterData.taxRate;
        lightTaxRate = this.controlMasterData.lightTaxRate;
      } else {
        taxRate = this.controlMasterData.newTaxRate;
        lightTaxRate = this.controlMasterData.newLightTaxRate;
      }
      // 前回御請求額
      if (getNullStr(preBilling).length > 10) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%前請税抜%', value: preBilling, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: chkWidth});
      if (getNullStr(preBillingTax).length > 10) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%前請消税%', value: preBillingTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: chkWidth});
      // 御入金額
      if (getNullStr(amount).length > 10) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%入金税抜%', value: amount, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: chkWidth});
      if (getNullStr(amountTax).length > 10) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%入金消税%', value: amountTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: chkWidth});
      // 差引繰越金額
      if (getNullStr(forward).length > 10) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%繰越税抜%', value: forward, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: chkWidth});
      if (getNullStr(forwardTax).length > 10) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%繰越消税%', value: forwardTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: chkWidth});
      // 売上御買上額
      if (getNullStr(productAmount).length > 10) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%売上税抜%', value: productAmount, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: chkWidth});
      if (getNullStr(productAmountTax).length > 10) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%売上消税%', value: productAmountTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: chkWidth});
      // 消費税通常
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税通小頭%', value: '消費税' + taxRate + '%課税 小計', textAnchor: 'end', textLength: 440, chkWidth: false});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税通小%', value: normalTaxSubTotal, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: false});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税通頭%', value: '消費税' + taxRate + '%', textAnchor: 'end', textLength: 210, chkWidth: false});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税通%', value: normalTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: false});
      // 消費税軽減
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税軽小頭%', value: '消費税' + lightTaxRate + '%課税（' + this.controlMasterData.lightTaxMark + '） 小計', textAnchor: 'end', textLength: 440, chkWidth: false});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税軽小%', value: lightTaxSubTotal, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: false});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税軽頭%', value: '消費税' + lightTaxRate + '%', textAnchor: 'end', textLength: 210, chkWidth: false});
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税軽%', value: lightTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: false});
      // 消費税非課税
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%消税非小%', value: noTaxSubTotal, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: false});
      // 今回請求
      if (getNullStr(billing).length > 10) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%今請税抜%', value: billing, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: chkWidth});
      if (getNullStr(billingTax).length > 10) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%今請消税%', value: billingTax, textAnchor: 'end', textLength: this.constData.textLength_210, chkWidth: chkWidth});
      if (getNullStr(billingTotal).length > 10) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%今請合計%', value: billingTotal, textAnchor: 'end', textLength: this.constData.textLength_280, chkWidth: chkWidth});
      // 伝票数
      if (getNullStr(this.listKokyaku[i].billingCnt).length > 3) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%伝数%', value: this.listKokyaku[i].billingCnt, textAnchor: 'end', textLength: this.constData.textLength_110, chkWidth: chkWidth});
      // 請求年月日
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%請求年月日%', value: billingIssueInputDate, textAnchor: 'start', textLength: 267, chkWidth: false});
      // 事業者登録番号
      this.listKokyaku[i].listChouhyou[0].replacements.push({key: '%登録番号%', value: invoiceInputNo, textAnchor: 'start', textLength: 397, chkWidth: false});
    },
    /* 検索SELECT文字列作成（取引先別合計用） */
    makeSelectSqlTotal01: function() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' billings_QUERY.billing_end_date';
      selectSql += ',billings_QUERY.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.zip_code';
      selectSql += ',clients.address_1';
      selectSql += ',clients.address_2';
      selectSql += ',clients.department_1';
      selectSql += ',clients.department_2';
      selectSql += ',billings_QUERY.product_sales_unit_amount';
      selectSql += ',billings_QUERY.closing_date_normal_tax + billings_QUERY.closing_date_light_tax AS product_tax';
      selectSql += ',CASE WHEN clients.billing_class = ' + Const.BillingClass.output + ' THEN billings_QUERY.pre_billing_no_tax_balance ELSE 0 END AS pre_billing_no_tax_balance';
      selectSql += ',CASE WHEN clients.billing_class = ' + Const.BillingClass.output + ' THEN billings_QUERY.pre_billing_tax_balance ELSE 0 END AS pre_billing_tax_balance';
      selectSql += ',CASE WHEN clients.billing_class = ' + Const.BillingClass.output + ' THEN billings_QUERY.amount_no_tax ELSE 0 END AS amount_no_tax';
      selectSql += ',CASE WHEN clients.billing_class = ' + Const.BillingClass.output + ' THEN billings_QUERY.amount_tax ELSE 0 END AS amount_tax';
      selectSql += ',IfNull(billings_QUERY.payment_scheduled,';
      selectSql += '   CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '     THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '     ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   END';
      selectSql += ' ) AS payment_scheduled';
      selectSql += ',billings_QUERY.closing_date_normal_tax_subtotal';
      selectSql += ',billings_QUERY.closing_date_normal_tax';
      selectSql += ',billings_QUERY.closing_date_light_tax_subtotal';
      selectSql += ',billings_QUERY.closing_date_light_tax';
      selectSql += ',billings_issue_input.billing_issue_input_date';
      selectSql += ',billings_issue_input.invoice_input_no';
      // FROM句
      selectSql += ' FROM ';
      selectSql += '(' + this.makeMainQueryTotal01() + ') AS billings_QUERY ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND billings_QUERY.client_id = clients.client_id ';
      selectSql += 'LEFT JOIN t_billings_issue_input AS billings_issue_input ';
      selectSql += 'ON billings_issue_input.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND billings_QUERY.client_id = billings_issue_input.client_id ';
      // WHERE句
      selectSql += ' WHERE ';
      // 請求区分が「0:出力する」の場合、売上金額、または、前回請求額、または、入金額が0以外のレコードを取得
      selectSql += '((clients.billing_class = ' + Const.BillingClass.output + ' ';
      selectSql += 'AND (billings_QUERY.product_sales_unit_amount <> 0 OR billings_QUERY.pre_billing_no_tax_balance <> 0 OR billings_QUERY.pre_billing_tax_balance <> 0 OR billings_QUERY.amount_no_tax <> 0 OR billings_QUERY.amount_tax <> 0)) ';
      // 請求区分が「1:出力しない」の場合、売上金額が0以外のレコードを取得
      selectSql += 'OR (clients.billing_class = ' + Const.BillingClass.noOutput + ' ';
      selectSql += 'AND (billings_QUERY.product_sales_unit_amount <> 0))) ';
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' billings_QUERY.client_id ';

      return selectSql;
    },
    // 検索SELECT文字列作成（取引先別合計用）
    makeMainQueryTotal01: function() {
      /* 請求締更新前の処理月情報あり、前月情報あり */
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT ';
      selectSql1 += ' billings.billing_end_date';
      selectSql1 += ',billings.client_id';
      selectSql1 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql1 += ',IfNull(billings_balances.pre_billing_no_tax_balance,0) AS pre_billing_no_tax_balance';
      selectSql1 += ',IfNull(billings_balances.pre_billing_tax_balance,0) AS pre_billing_tax_balance';
      selectSql1 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN 0 ELSE cumulative_transaction.amount END) AS amount_no_tax';
      selectSql1 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN cumulative_transaction.amount ELSE 0 END) AS amount_tax';
      selectSql1 += ',NULL AS payment_scheduled';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_normal_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_light_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += this.billingsTableName + ' AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0 OR cumulative_transaction.amount <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\',\'' + Const.TransactionId.deposit + '\') ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql1 += 'LEFT JOIN t_billings_balances AS billings_balances ';
      selectSql1 += 'ON billings.client_id = billings_balances.client_id ';
      selectSql1 += 'AND billings_balances.site_id = 0 ';
      selectSql1 += 'AND billings_balances.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql1 += 'AND billings_balances.billing_start_date IS NULL ';
      if (this.invoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
        // 一部伝票先行発行取引先の場合
        // 請求書発行登録＿伝票番号毎テーブルと結合
        selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
        selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      }
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      selectSql1 += 'billings.closing_date = ' + this.preBilling.closingDate + ' ';
      // 請求書出力区分
      selectSql1 += 'AND billings.billing_output_class = ' + this.billingOutputClass + ' ';
      // 得意先コード
      selectSql1 += 'AND billings.client_id IN (' + this.csvClientId + ') ';
      if (this.invoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
        // 一部伝票先行発行取引先の場合
        // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外（入金は除外しない）
        selectSql1 += 'AND (billings_issue_input_billing_no.billing_no IS NULL OR cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\') ';
      }
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings.client_id';
      /* 請求締更新前の処理月情報あり、前月情報あり */
      let selectSql3 = '';
      /* SELECT句 */
      selectSql3 += 'SELECT ';
      selectSql3 += ' billings.billing_end_date';
      selectSql3 += ',billings.client_id';
      selectSql3 += ',0 AS product_sales_unit_amount';
      selectSql3 += ',billings_balances.pre_billing_no_tax_balance';
      selectSql3 += ',billings_balances.pre_billing_tax_balance';
      selectSql3 += ',0 AS amount_no_tax';
      selectSql3 += ',0 AS amount_tax';
      selectSql3 += ',NULL AS payment_scheduled';
      selectSql3 += ',0 AS closing_date_normal_tax_subtotal';
      selectSql3 += ',0 AS closing_date_normal_tax';
      selectSql3 += ',0 AS closing_date_light_tax_subtotal';
      selectSql3 += ',0 AS closing_date_light_tax';
      /* FROM句 */
      selectSql3 += ' FROM ';
      selectSql3 += this.billingsTableName + ' AS billings ';
      selectSql3 += 'LEFT JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql3 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql3 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql3 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql3 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql3 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0 OR cumulative_transaction.amount <> 0) ';
      selectSql3 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\',\'' + Const.TransactionId.deposit + '\') ';
      selectSql3 += 'INNER JOIN t_billings_balances AS billings_balances ';
      selectSql3 += 'ON billings.client_id = billings_balances.client_id ';
      selectSql3 += 'AND billings_balances.site_id = 0 ';
      selectSql3 += 'AND billings_balances.billing_month_year = ' + formatDate(this.billingMonthYear + '-01', 'YYYYMM') + ' ';
      selectSql3 += 'AND (billings_balances.pre_billing_no_tax_balance <> 0 OR billings_balances.pre_billing_tax_balance <> 0) ';
      selectSql3 += 'AND billings_balances.billing_start_date IS NULL ';
      /* WHERE句 */
      selectSql3 += ' WHERE ';
      selectSql3 += 'cumulative_transaction.billing_no IS NULL ';
      selectSql3 += 'AND billings.closing_date = ' + this.preBilling.closingDate + ' ';
      selectSql3 += 'AND billings.site_id = 0 ';
      // 請求書出力区分
      selectSql3 += 'AND billings.billing_output_class = ' + this.billingOutputClass + ' ';
      // 得意先コード
      selectSql3 += 'AND billings.client_id IN (' + this.csvClientId + ') ';
      /* GROUP BY句 */
      selectSql3 += ' GROUP BY ';
      selectSql3 += ' billings.client_id';
      /* 請求締更新後 */
      let selectSql4 = '';
      /* SELECT句 */
      selectSql4 += 'SELECT ';
      selectSql4 += ' billings_balances.billing_end_date';
      selectSql4 += ',billings_balances.client_id';
      selectSql4 += ',billings_balances.closing_date_receivable_sales_pre_exclude AS product_sales_unit_amount';
      selectSql4 += ',billings_balances.pre_billing_no_tax_balance';
      selectSql4 += ',billings_balances.pre_billing_tax_balance';
      selectSql4 += ',billings_balances.closing_date_payment AS amount_no_tax';
      selectSql4 += ',billings_balances.closing_date_tax_payment AS amount_tax';
      selectSql4 += ',billings_balances.payment_scheduled';
      selectSql4 += ',billings_balances.closing_date_normal_tax_subtotal_pre_exclude AS closing_date_normal_tax_subtotal';
      selectSql4 += ',billings_balances.closing_date_normal_tax_pre_exclude AS closing_date_normal_tax';
      selectSql4 += ',billings_balances.closing_date_light_tax_subtotal_pre_exclude AS closing_date_light_tax_subtotal';
      selectSql4 += ',billings_balances.closing_date_light_tax_pre_exclude AS closing_date_light_tax';
      /* FROM句 */
      selectSql4 += ' FROM ';
      selectSql4 += 't_billings_balances AS billings_balances ';
      /* WHERE句 */
      selectSql4 += ' WHERE ';
      // 請求年月
      selectSql4 += 'billings_balances.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      // 請求開始日
      selectSql4 += 'AND billings_balances.billing_start_date IS NOT NULL ';
      // 請求書出力区分
      selectSql4 += 'AND billings_balances.billing_output_class = ' + this.billingOutputClass + ' ';
      // 得意先コード
      selectSql4 += 'AND billings_balances.client_id IN (' + this.csvClientId + ') ';
      // 現場コード
      selectSql4 += 'AND billings_balances.site_id = 0 ';
      // 売上金額、または、前回請求額、または、入金額が0以外のレコードを取得
      selectSql4 += 'AND (billings_balances.closing_date_receivable_sales_pre_exclude <> 0 OR billings_balances.closing_date_normal_tax_subtotal_pre_exclude <> 0 OR billings_balances.closing_date_light_tax_subtotal_pre_exclude <> 0 OR billings_balances.pre_billing_no_tax_balance <> 0 OR billings_balances.pre_billing_tax_balance <> 0 OR billings_balances.closing_date_payment <> 0 OR billings_balances.closing_date_tax_payment <> 0) ';
      /* GROUP BY句 */
      selectSql4 += ' GROUP BY ';
      selectSql4 += ' billings_balances.client_id';

      //console.log(selectSql1);
      //console.log(selectSql3);
      //console.log(selectSql4);

      let selectSql = '';
      if (this.preBilling.processMonthYear > 0 && this.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 画面から選択した年月が請求前処理の年月と同じ場合
        // 3つのSELECT文をUNION
        selectSql = selectSql1 + ' UNION ALL ' + selectSql3 + ' UNION ALL ' + selectSql4;
      } else {
        // 画面から選択した年月が請求前処理の年月と異なる場合
        // 請求残高の登録情報のみを表示
        selectSql = selectSql4;
      }

      return selectSql;
    },
    /* 検索SELECT文字列作成（現場別合計用） */
    makeSelectSqlTotal02: function() {
      /* 請求締更新前 */
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' billings_QUERY.client_id';
      selectSql += ',billings_QUERY.site_id';
      selectSql += ',billings_QUERY.product_sales_unit_amount';
      selectSql += ',billings_QUERY.closing_date_normal_tax + billings_QUERY.closing_date_light_tax AS product_tax';
      selectSql += ',billings_QUERY.pre_billing_no_tax_balance';
      selectSql += ',billings_QUERY.pre_billing_tax_balance';
      selectSql += ',billings_QUERY.amount_no_tax';
      selectSql += ',billings_QUERY.amount_tax';
      selectSql += ',clients_sites.billings_print_class';
      selectSql += ',clients_sites.billings_print_client_name';
      selectSql += ',billings_QUERY.closing_date_normal_tax_subtotal';
      selectSql += ',billings_QUERY.closing_date_normal_tax';
      selectSql += ',billings_QUERY.closing_date_light_tax_subtotal';
      selectSql += ',billings_QUERY.closing_date_light_tax';
      selectSql += ',billings_issue_input.billing_issue_input_date';
      selectSql += ',billings_issue_input.invoice_input_no';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += '(' + this.makeMainQueryTotal02() + ') AS billings_QUERY ';
      selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
      selectSql += 'ON billings_QUERY.client_id = clients_sites.client_id ';
      selectSql += 'AND billings_QUERY.site_id = clients_sites.site_id ';
      selectSql += 'LEFT JOIN t_billings_issue_input AS billings_issue_input ';
      selectSql += 'ON billings_issue_input.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND billings_QUERY.client_id = billings_issue_input.client_id ';
      /* ORDER BY句 */
      selectSql += ' ORDER BY ';
      selectSql += ' billings_QUERY.client_id,billings_QUERY.site_id ';

      return selectSql;
    },
    // 検索SELECT文字列作成（現場別合計用）
    makeMainQueryTotal02: function() {
      /* 請求締更新前 */
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',billings.site_id';
      selectSql1 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql1 += ',0 AS pre_billing_no_tax_balance';
      selectSql1 += ',0 AS pre_billing_tax_balance';
      selectSql1 += ',0 AS amount_no_tax';
      selectSql1 += ',0 AS amount_tax';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_normal_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_light_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += this.billingsTableName + ' AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.site_id <> 0 ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      if (this.invoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
        // 一部伝票先行発行取引先の場合
        // 請求書発行登録＿伝票番号毎テーブルと結合
        selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
        selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
        // 請求書発行登録＿現場毎テーブルと結合
        selectSql1 += 'LEFT JOIN t_billings_issue_input_site AS billings_issue_input_site ';
        selectSql1 += 'ON billings_issue_input_site.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql1 += 'AND cumulative_transaction.client_id = billings_issue_input_site.client_id ';
        selectSql1 += 'AND cumulative_transaction.site_id = billings_issue_input_site.site_id ';
      }
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      // 請求書出力区分
      selectSql1 += 'billings.billing_output_class = ' + this.billingOutputClass + ' ';
      // 得意先コード
      selectSql1 += 'AND billings.client_id IN (' + this.csvClientId + ') ';
      // 現場コード（現場別の場合のみ）
      if (this.siteIdStart != '' && this.siteIdEnd == '') {
        selectSql1 += 'AND billings.site_id >= ' + this.siteIdStart + ' ';
      }else if (this.siteIdStart == '' && this.siteIdEnd != '') {
        selectSql1 += 'AND billings.site_id <= ' + this.siteIdEnd + ' ';
      }else if (this.siteIdStart != '' && this.siteIdEnd != '') {
        selectSql1 += 'AND billings.site_id BETWEEN ' + this.siteIdStart + ' ' + 'AND ' + this.siteIdEnd + ' ';
      }
      // 現場コード0は除外
      selectSql1 += 'AND billings.site_id > 0 ';
      if (this.invoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
        // 一部伝票先行発行取引先の場合
        // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外
        selectSql1 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
        // 請求書発行登録＿現場毎テーブルに登録されている現場は除外
        selectSql1 += 'AND billings_issue_input_site.site_id IS NULL ';
      }
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',billings.site_id';
      /* 請求締更新後 */
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT ';
      selectSql2 += ' billings_balances.client_id';
      selectSql2 += ',billings_balances.site_id';
      selectSql2 += ',billings_balances.closing_date_receivable_sales_pre_exclude AS product_sales_unit_amount';
      selectSql2 += ',billings_balances.pre_billing_no_tax_balance';
      selectSql2 += ',billings_balances.pre_billing_tax_balance';
      selectSql2 += ',billings_balances.closing_date_payment AS amount_no_tax';
      selectSql2 += ',billings_balances.closing_date_tax_payment AS amount_tax';
      selectSql2 += ',billings_balances.closing_date_normal_tax_subtotal_pre_exclude AS closing_date_normal_tax_subtotal';
      selectSql2 += ',billings_balances.closing_date_normal_tax_pre_exclude AS closing_date_normal_tax';
      selectSql2 += ',billings_balances.closing_date_light_tax_subtotal_pre_exclude AS closing_date_light_tax_subtotal';
      selectSql2 += ',billings_balances.closing_date_light_tax_pre_exclude AS closing_date_light_tax';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += 't_billings_balances AS billings_balances ';
      if (this.invoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
        // 一部伝票先行発行取引先の場合
        // 請求書発行登録＿現場毎テーブルと結合
        selectSql2 += 'LEFT JOIN t_billings_issue_input_site AS billings_issue_input_site ';
        selectSql2 += 'ON billings_issue_input_site.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql2 += 'AND billings_balances.client_id = billings_issue_input_site.client_id ';
        selectSql2 += 'AND billings_balances.site_id = billings_issue_input_site.site_id ';
      }
      /* WHERE句 */
      selectSql2 += ' WHERE ';
      selectSql2 += '(billings_balances.closing_date_receivable_sales_pre_exclude <> 0 OR billings_balances.closing_date_normal_tax_subtotal_pre_exclude <> 0 OR billings_balances.closing_date_light_tax_subtotal_pre_exclude <> 0)';
      // 請求年月
      selectSql2 += 'AND billings_balances.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      // 請求書出力区分
      selectSql2 += 'AND billings_balances.billing_output_class = ' + this.billingOutputClass + ' ';
      // 得意先コード
      selectSql2 += 'AND billings_balances.client_id IN (' + this.csvClientId + ') ';
      // 現場コード（現場別の場合のみ）
      if (this.siteIdStart != '' && this.siteIdEnd == '') {
        selectSql2 += 'AND billings_balances.site_id >= ' + this.siteIdStart + ' ';
      }else if (this.siteIdStart == '' && this.siteIdEnd != '') {
        selectSql2 += 'AND billings_balances.site_id <= ' + this.siteIdEnd + ' ';
      }else if (this.siteIdStart != '' && this.siteIdEnd != '') {
        selectSql2 += 'AND billings_balances.site_id BETWEEN ' + this.siteIdStart + ' ' + 'AND ' + this.siteIdEnd + ' ';
      }
      // 現場コード0は除外
      selectSql2 += 'AND billings_balances.site_id > 0 ';
      if (this.invoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
        // 一部伝票先行発行取引先の場合
        // 請求書発行登録＿現場毎テーブルに登録されている現場は除外
        selectSql2 += 'AND billings_issue_input_site.site_id IS NULL ';
      }
      /* GROUP BY句 */
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings_balances.client_id';
      selectSql2 += ',billings_balances.site_id';

      let selectSql = '';
      if (this.preBilling.processMonthYear > 0 && this.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 画面から選択した年月が請求前処理の年月と同じ場合
        // 2つのSELECT文をUNION
        selectSql = selectSql1 + ' UNION ALL ' + selectSql2;
      } else {
        // 画面から選択した年月が請求前処理の年月と異なる場合
        // 請求残高の登録情報のみを表示
        selectSql = selectSql2;
      }

      return selectSql;
    },
    // 検索SELECT文字列作成（先行合計用）
    makeSelectSqlTotalSeparate: function() {
      // 請求締更新前
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' billings_QUERY.client_id';
      selectSql += ',billings_QUERY.site_id';
      selectSql += ',billings_QUERY.product_sales_unit_amount';
      selectSql += ',billings_QUERY.closing_date_normal_tax + billings_QUERY.closing_date_light_tax AS product_tax';
      selectSql += ',0 AS pre_billing_no_tax_balance';
      selectSql += ',0 AS pre_billing_tax_balance';
      selectSql += ',0 AS amount_no_tax';
      selectSql += ',0 AS amount_tax';
      if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
        selectSql += ',clients_sites.billings_print_class';
        selectSql += ',clients_sites.billings_print_client_name';
      } else {
        selectSql += ',NULL AS billings_print_class';
        selectSql += ',NULL AS billings_print_client_name';
      }
      selectSql += ',billings_QUERY.closing_date_normal_tax_subtotal';
      selectSql += ',billings_QUERY.closing_date_normal_tax';
      selectSql += ',billings_QUERY.closing_date_light_tax_subtotal';
      selectSql += ',billings_QUERY.closing_date_light_tax';
      selectSql += ',billings_issue_input.billing_issue_input_date';
      selectSql += ',billings_issue_input.invoice_input_no';
      // FROM句
      selectSql += ' FROM ';
      selectSql += '(' + this.makeMainQuerySeparate() + ') AS billings_QUERY ';
      if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
        selectSql += 'LEFT JOIN m_clients_sites AS clients_sites ';
        selectSql += 'ON billings_QUERY.client_id = clients_sites.client_id ';
        selectSql += 'AND billings_QUERY.site_id = clients_sites.site_id ';
      }
      // 伝票別・現場別からの出力
      if (this.separateBillingClass == Const.SeparateBillingClass.billings) {
        // 伝票毎
        // 請求書発行登録＿伝票番号毎テーブルと結合
        selectSql += 'LEFT JOIN (SELECT ';
        selectSql += 'separate_invoice_no ';
        selectSql += ',client_id ';
        if (this.billingOutputClass == Const.BillingOutputClass.client) {
          selectSql += ',0 AS site_id ';
        } else {
          selectSql += ',site_id ';
        }
        selectSql += ',billing_issue_input_date ';
        selectSql += ',invoice_input_no ';
        selectSql += 'FROM t_billings_issue_input_billing_no WHERE ';
        selectSql += 'billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql += 'AND billing_no IN (' + this.separateBillingNo + ') ';
        selectSql += 'GROUP BY separate_invoice_no ';
        selectSql += ') AS billings_issue_input ';
        selectSql += 'ON billings_QUERY.client_id = billings_issue_input.client_id ';
        if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
          selectSql += 'AND billings_QUERY.site_id = billings_issue_input.site_id ';
        }
      } else {
        // 現場毎
        // 請求書発行登録＿現場毎テーブルと結合
        selectSql += 'LEFT JOIN t_billings_issue_input_site AS billings_issue_input ';
        selectSql += 'ON billings_issue_input.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql += 'AND billings_QUERY.client_id = billings_issue_input.client_id ';
        selectSql += 'AND billings_QUERY.site_id = billings_issue_input.site_id ';
      }
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += 'billings_QUERY.client_id ';
      if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
        selectSql += ',billings_QUERY.site_id ';
      }

      return selectSql;
    },
    // 検索SELECT文字列作成（先行合計用）
    makeMainQuerySeparate: function() {
      // 請求締更新前
      let selectSql1 = '';
      // SELECT句
      selectSql1 += 'SELECT ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',billings.site_id';
      selectSql1 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_normal_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
      selectSql1 += '   ELSE 0 END)';
      selectSql1 += ' AS closing_date_light_tax_subtotal';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      // FROM句
      selectSql1 += ' FROM ';
      selectSql1 += this.billingsTableName + ' AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      if (this.separateBillingClass == Const.SeparateBillingClass.sites) {
        // 先行発行（現場毎）の場合
        // 請求書発行登録＿伝票番号毎テーブルと結合
        selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
        selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      }
      // WHERE句
      selectSql1 += ' WHERE ';
      // 得意先コード
      selectSql1 += 'billings.client_id = ' + this.csvClientId + ' ';
      // 伝票別・現場別からの出力
      if (this.separateBillingClass == Const.SeparateBillingClass.billings) {
        // 伝票毎
        selectSql1 += 'AND cumulative_transaction.billing_no IN (' + this.separateBillingNo + ') ';
      } else {
        // 現場毎
        selectSql1 += 'AND cumulative_transaction.site_id IN (' + this.siteIdStart + ') ';
        // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外
        selectSql1 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
      }
      // GROUP BY句
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings.client_id';
      if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
        selectSql1 += ',billings.site_id';
      }
      // 請求締更新後
      let selectSql2 = '';
      // SELECT句
      selectSql2 += 'SELECT ';
      selectSql2 += ' billings_balances.client_id';
      selectSql2 += ',billings_balances.site_id';
      selectSql2 += ',billings_issue_input.sales_unit_amount AS product_sales_unit_amount';
      selectSql2 += ',billings_issue_input.normal_tax_subtotal AS closing_date_normal_tax_subtotal';
      selectSql2 += ',billings_issue_input.normal_tax AS closing_date_normal_tax';
      selectSql2 += ',billings_issue_input.light_tax_subtotal AS closing_date_light_tax_subtotal';
      selectSql2 += ',billings_issue_input.light_tax AS closing_date_light_tax';
      // FROM句
      selectSql2 += ' FROM ';
      selectSql2 += 't_billings_balances AS billings_balances ';
      if (this.separateBillingClass == Const.SeparateBillingClass.billings) {
        // 請求書発行登録＿伝票番号毎テーブルと結合
        selectSql2 += 'INNER JOIN (SELECT ';
        selectSql2 += 'separate_invoice_no ';
        selectSql2 += ',client_id ';
        if (this.billingOutputClass == Const.BillingOutputClass.client) {
          selectSql2 += ',0 AS site_id ';
        } else {
          selectSql2 += ',site_id ';
        }
        selectSql2 += ',MAX(sales_unit_amount) AS sales_unit_amount ';
        selectSql2 += ',MAX(normal_tax_subtotal) AS normal_tax_subtotal ';
        selectSql2 += ',MAX(normal_tax) AS normal_tax ';
        selectSql2 += ',MAX(light_tax_subtotal) AS light_tax_subtotal ';
        selectSql2 += ',MAX(light_tax) AS light_tax ';
        selectSql2 += 'FROM t_billings_issue_input_billing_no WHERE ';
        selectSql2 += 'billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql2 += 'AND billing_no IN (' + this.separateBillingNo + ') ';
        selectSql2 += 'GROUP BY separate_invoice_no ';
        selectSql2 += ') AS billings_issue_input ';
        selectSql2 += 'ON billings_issue_input.client_id = billings_balances.client_id ';
        selectSql2 += 'AND billings_issue_input.site_id = billings_balances.site_id ';
      } else {
        // 請求書発行登録＿現場毎テーブルと結合
        selectSql2 += 'INNER JOIN t_billings_issue_input_site AS billings_issue_input ';
        selectSql2 += 'ON billings_issue_input.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql2 += 'AND billings_issue_input.client_id = ' + this.csvClientId + ' ';
        selectSql2 += 'AND billings_issue_input.site_id IN (' + this.siteIdStart + ') ';
        selectSql2 += 'AND billings_balances.client_id = billings_issue_input.client_id ';
        selectSql2 += 'AND billings_balances.site_id = billings_issue_input.site_id ';
      }
      // WHERE句
      selectSql2 += ' WHERE ';
      // 請求年月
      selectSql2 += 'billings_balances.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      // 請求開始日
      selectSql2 += 'AND billings_balances.billing_start_date IS NOT NULL ';
      // 得意先コード
      selectSql2 += 'AND billings_balances.client_id = ' + this.csvClientId + ' ';
      // GROUP BY句
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings_balances.client_id';
      if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
        selectSql2 += ',billings_balances.site_id';
      }
      let selectSql = '';
      if (this.preBilling.processMonthYear > 0 && this.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 画面から選択した年月が請求前処理の年月と同じ場合
        // 2つのSELECT文をUNION
        selectSql = selectSql1 + ' UNION ALL ' + selectSql2;
      } else {
        // 画面から選択した年月が請求前処理の年月と異なる場合
        // 請求残高の登録情報のみを表示
        selectSql = selectSql2;
      }

      return selectSql;
    },
    /* 顧客（取引先、現場）の置換配列セット（伝票部分は全て空） */
    async createReplacementsBlank(result){
      //console.log('createReplacementsBlank');
      let chkWidth = false;
      // SVGファイルの置換用文字列
      let replacements = [];
      // お客様番号
      replacements.push({key: '%お客様番号%', value: result.client_id, textAnchor: 'start', textLength: this.constData.textLength_300, chkWidth: false});
      // ページ番号
      replacements.push({key: '%P%', value: '1 / 1', textAnchor: 'end', textLength: this.constData.textLength_178, chkWidth: false});
      // タイトル
      replacements.push({key: '%タイトル%', value: '請　求　書', textAnchor: 'middle', textLength: this.constData.textLength_636, chkWidth: false});
      // 郵便番号
      replacements.push({key: '%郵便番号%', value: result.zip_code, textAnchor: 'start', textLength: this.constData.textLength_300, chkWidth: false});
      // 住所１
      if (getNullStr(result.address_1).length > 20) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      replacements.push({key: '%住所１%', value: result.address_1, textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: chkWidth});
      // 住所２
      if (getNullStr(result.address_2).length > 20) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      replacements.push({key: '%住所２%', value: result.address_2, textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: chkWidth});
      // 取引先名
      if (getNullStr(result.client_name_kanji).length > 12) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      replacements.push({key: '%取引先名%', value: result.client_name_kanji, textAnchor: 'start', textLength: this.constData.textLength_589, chkWidth: chkWidth});
      // 部署１
      if (getNullStr(result.department_1).length > 20) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      replacements.push({key: '%部署１%', value: result.department_1, textAnchor: 'start', textLength: this.constData.textLength_625, chkWidth: chkWidth});
      // 部署２
      if (getNullStr(result.department_2).length > 20) {
        chkWidth = true;
      } else {
        chkWidth = false;
      }
      replacements.push({key: '%部署２%', value: result.department_2, textAnchor: 'start', textLength: this.constData.textLength_625, chkWidth: chkWidth});
      // 営業所情報
      await this.setOfficesData(replacements);
      // 現場
      replacements.push({key: '%現場%', value: '', textAnchor: 'start', textLength: this.constData.textLength_836, chkWidth: false});
      // 締切日
      replacements.push({key: '%締切日%', value: getNullStr(formatDate(result.billing_end_date)) + '締切', textAnchor: 'start', textLength: this.constData.textLength_297, chkWidth: false});
      // 入金予定
      replacements.push({key: '%入金予定%', value: formatDate(result.payment_scheduled), textAnchor: 'start', textLength: this.constData.textLength_480, chkWidth: false});
      // 軽減税率の説明
      replacements.push({key: '%税率説明%', value: '「' + this.controlMasterData.lightTaxMark + '」は軽減税率対象項目', textAnchor: 'start', textLength: 440, chkWidth: false});
      // 銀行名
      for (let j = 0; j < Const.bankNameList.length; j++) {
        replacements.push({key: Const.bankNameList[j].key, value: Const.bankNameList[j].value, textAnchor: 'start', textLength: Const.bankNameList[j].textLength, chkWidth: true});
      }
      // 伝票
      for (let j = 0; j < this.constData.cntStart; j++) {
        // 日付
        replacements.push({key: '%日付' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_160, chkWidth: false});
        // コード
        replacements.push({key: '%コード' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_170, chkWidth: false});
        // 名称
        replacements.push({key: '%名称' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_720, chkWidth: false});
        // 数量
        replacements.push({key: '%数量' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_150, chkWidth: false});
        // 単価
        replacements.push({key: '%単価' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: false});
        // 金額
        replacements.push({key: '%金額' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_170, chkWidth: false});
      }
      return replacements;
    },
    /* 帳票に各種値セット */
    setChouhyou(){
      //console.log('setChouhyou');
      for (let j = 0; j < this.listKokyaku.length; j++) {
        for (let i = 0; i < this.listKokyaku[j].listChouhyou.length; i++){
          let svgDoc = document.getElementById(this.constData.chouhyouId + this.listKokyaku[j].clientId + '_' + this.listKokyaku[j].siteId + '_' + this.listKokyaku[j].listChouhyou[i].page);
          this.setReplacements(svgDoc, this.listKokyaku[j].listChouhyou[i].replacements);
          this.setSize(svgDoc);
        }
      }
    },
    /* 置換値をSVGファイルに設定 */
    setReplacements: function(node, replacements){
      //console.log('setReplacements');
      for(let i = 0; i < node.children.length; i++){
        if(node.children[i].tagName == 'text'){
          for(let j = 0; j < node.children[i].children.length; j++){
            if(node.children[i].children[j].tagName == 'tspan'){
              for(let k = 0; k < replacements.length; k++){
                if(node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1){
                  this.setTspan(node.children[i].children[j], replacements[k]);
                  replacements.splice(k, 1);
                  break;
                }
              }
            }
          }
        } else if(node.children[i].tagName == 'g'){
          this.setReplacements(node.children[i], replacements);
        }
      }
    },
    /* Textタグ内のテキストを設定 */
    setTspan: function(tagTspan, config){
      /*
      // 一覧の名称以外のフォントサイズを小さく
      if (tagTspan.innerHTML.trim().indexOf('%日付') != -1 ||
      tagTspan.innerHTML.trim().indexOf('%コード') != -1 ||
      tagTspan.innerHTML.trim().indexOf('%数量') != -1 ||
      tagTspan.innerHTML.trim().indexOf('%単価') != -1 ||
      tagTspan.innerHTML.trim().indexOf('%金額') != -1) {
        tagTspan.setAttribute('font-size', '26');
      }
      */
      // 文字を置換
      tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(config.value));
      /* 最大長を設定（最大長を超過する場合、自動で縮小） */
      if (config.chkWidth == true &&
        tagTspan.getBBox().width > config.textLength) {
        tagTspan.setAttribute('textLength', config.textLength);
        tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
      }
      let colX = parseFloat(tagTspan.getAttribute('x'));
      /* 中央寄せ、右寄せを設定 */
      // 中央寄せ
      if(config.textAnchor == 'middle'){
        tagTspan.setAttribute('x', colX + config.textLength / 2);
      }
      // 右寄せ
      if(config.textAnchor == 'end'){
        tagTspan.setAttribute('x', colX + config.textLength);
      }
      tagTspan.setAttribute('text-anchor', config.textAnchor);
    },
    /* 取得結果セット */
    setSize: function(svgDoc){
      // viewBoxに元のサイズを設定
      const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
      svgDoc.setAttribute('viewBox', zoomedViewBox);
      // 横幅と高さをパラメータで指定したサイズに修正
      svgDoc.setAttribute('width', this.chouhyouSize.width);
      svgDoc.setAttribute('height', this.chouhyouSize.height);
      // pdf作成のためにサイズを取得
      if (this.pdfChouhyouSize.width == null && this.pdfChouhyouSize.height == null) {
        this.pdfChouhyouSize.width = svgDoc.clientWidth;
        this.pdfChouhyouSize.height = svgDoc.clientHeight;
      }
    },
    // 請求日（開始日と終了日）の取得
    async getBillingDateInfo() {
      // 各種データ取得（非同期でまとめて取得した方が早いため）
      let preBillingResult = null;
      let controlData = null;
      let where_clause = 'AND billing_payment_class = ' + this.billingPaymentClass + ' ';
      [preBillingResult, controlData] = await Promise.all([
        selectOneTable('t_closing_update_range', where_clause),
        getControlMaster(),
      ]);
      this.controlMasterData.processMonthYear = controlData.process_month_year;
      this.controlMasterData.taxRate = controlData.tax_rate;
      this.controlMasterData.newTaxRate = controlData.new_tax_rate;
      this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
      this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
      this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
      this.controlMasterData.invoiceInputNo = controlData.invoice_input_no;
      this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
      if (preBillingResult != null && preBillingResult.length > 0) {
        this.preBilling.processMonthYear = preBillingResult[0].process_month_year;
        this.preBilling.closingDate = preBillingResult[0].closing_date;
        this.preBilling.billingStartDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, true);
        this.preBilling.billingEndDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, false);
      } else {
        this.preBilling.processMonthYear = 0;
        this.preBilling.closingDate = 0;
        this.preBilling.billingStartDate = '';
        this.preBilling.billingEndDate = '';
      }
    },
    // 書類アップロード処理実行
    async execDocumentsUpload() {
      const functionName = 'execDocumentsUpload';
      let selectSql = '';
      if (this.isSeparate == true) {
        // 請求書発行（現場・伝票毎）からの出力
        if (this.separateBillingClass == Const.SeparateBillingClass.billings) {
          // 伝票毎
          // SELECT句
          selectSql += 'SELECT';
          selectSql += ' t_biibn.separate_invoice_no';
          selectSql += ',t_biibn.site_id';
          selectSql += ',t_biibn.billing_issue_input_date';
          // FROM句
          selectSql += ' FROM ';
          selectSql += 't_billings_issue_input_billing_no AS t_biibn ';
          // WHERE句
          selectSql += ' WHERE ';
          selectSql += 't_biibn.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
          selectSql += 'AND t_biibn.client_id = ' + this.csvClientId + ' ';
          selectSql += 'AND t_biibn.billing_no IN (' + this.separateBillingNo + ') ';
          selectSql += 'GROUP BY t_biibn.separate_invoice_no ';
        } else {
          // 現場毎
          // SELECT句
          selectSql += 'SELECT';
          selectSql += ' t_biis.site_id';
          selectSql += ',t_biis.billing_issue_input_date';
          // FROM句
          selectSql += ' FROM ';
          selectSql += 't_billings_issue_input_site AS  t_biis ';
          // WHERE句
          selectSql += ' WHERE ';
          selectSql += 't_biis.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
          selectSql += 'AND t_biis.client_id = ' + this.csvClientId + ' ';
          selectSql += 'AND t_biis.site_id IN (' + this.siteIdStart + ') ';
        }
      } else {
        // 請求一覧からの出力
        // SELECT句
        selectSql += 'SELECT';
        selectSql += ' t_bii.client_id';
        selectSql += ',t_bii.billing_issue_input_date';
        // FROM句
        selectSql += ' FROM ';
        selectSql += 't_billings_issue_input AS t_bii ';
        // WHERE句
        selectSql += ' WHERE ';
        selectSql += 't_bii.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql += 'AND t_bii.client_id IN (' + this.csvClientId + ') ';
      }
      let uploadDataList = []; // アップロードデータリスト（アップロードするデータを保持）
      let clientsList = []; // 取引先リスト（後で取引先名と現場名をマスタから取得するためにアップロードする取引先・現場の保持用）
      let dataResult = await executeSelectSql(selectSql);
      let documentsAllPageCnt = 0; // 帳票の全ページ数（帳票を自動でPDF化する時のheightの算出に必要）
      if (this.isSeparate == true) {
        // 請求書発行（現場・伝票毎）からの出力
        // ※取引先は一つのみ
        if (this.separateBillingClass == Const.SeparateBillingClass.billings) {
          // 伝票毎
          if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
            // 現場別
            let pageStart = 0;
            for (let i = 0; i < this.listKokyaku.length; i++) {
              let row = this.listKokyaku[i];
              let uploadData = {
                clientId: row.clientId,
                clientNameKanji: '',
                clientNameKana: '',
                closingYmd: row.closingYmd,
                siteId: row.siteId,
                clientSiteNameKanji: '',
                separateInvoiceNo: 0,
                billingIssueInputDate: null,
                moneyBillingTotal: row.billingTotal,
                pageStart: pageStart,
                pageCnt: row.listChouhyou.length,
              };
              if (dataResult != null) {
                let dataResultRow = dataResult.find(el => el.site_id == row.siteId);
                if (dataResultRow != undefined) {
                  // 請求書発行登録済みの場合
                  uploadData.separateInvoiceNo = dataResultRow.separate_invoice_no;
                  uploadData.billingIssueInputDate = dataResultRow.billing_issue_input_date;
                }
              }
              uploadDataList.push(uploadData);
              // 取引先リスト作成（現場別のため取引先コード-現場コードを保持）
              if (clientsList.length > 0) {
                clientsList[0].siteIdList.push(row.siteId);
              } else {
                clientsList.push({clientId: row.clientId, siteIdList: [row.siteId]});
              }
              // 書類の何ページ目かを記録
              pageStart += row.listChouhyou.length;
            }
            // 帳票の全体ページ数を記録
            documentsAllPageCnt = pageStart;
          } else {
            // 取引先別（返却値は1件のみ）
            let row = this.listKokyaku[0];
            let uploadData = {
              clientId: row.clientId,
              clientNameKanji: '',
              clientNameKana: '',
              closingYmd: row.closingYmd,
              siteId: 0,
              clientSiteNameKanji: '',
              separateInvoiceNo: 0,
              billingIssueInputDate: null,
              moneyBillingTotal: row.billingTotal,
              pageStart: 0,
              pageCnt: row.listChouhyou.length,
            };
            if (dataResult != null && dataResult.length > 0) {
              // 請求書発行登録済みの場合
              uploadData.separateInvoiceNo = dataResult[0].separate_invoice_no;
              uploadData.billingIssueInputDate = dataResult[0].billing_issue_input_date;
            }
            uploadDataList.push(uploadData);
            // 取引先リスト作成（取引先別のため取引先コードを保持）
            clientsList.push({clientId: row.clientId});
            // 帳票の全体ページ数を記録
            documentsAllPageCnt = row.listChouhyou.length;
          }
        } else {
          // 現場毎
          let pageStart = 0;
          for (let i = 0; i < this.listKokyaku.length; i++) {
            let row = this.listKokyaku[i];
            let uploadData = {
              clientId: row.clientId,
              clientNameKanji: '',
              clientNameKana: '',
              closingYmd: row.closingYmd,
              siteId: row.siteId,
              clientSiteNameKanji: '',
              separateInvoiceNo: 0,
              billingIssueInputDate: null,
              moneyBillingTotal: row.billingTotal,
              pageStart: pageStart,
              pageCnt: row.listChouhyou.length,
            };
            if (dataResult != null) {
              let dataResultRow = dataResult.find(el => el.site_id == row.siteId);
              if (dataResultRow != undefined) {
                // 請求書発行登録済みの場合
                uploadData.billingIssueInputDate = dataResultRow.billing_issue_input_date;
              }
            }
            uploadDataList.push(uploadData);
            // 取引先リスト作成（現場別のため取引先コード-現場コードを保持）
            if (clientsList.length > 0) {
              clientsList[0].siteIdList.push(row.siteId);
            } else {
              clientsList.push({clientId: row.clientId, siteIdList: [row.siteId]});
            }
            // 書類の何ページ目かを記録
            pageStart += row.listChouhyou.length;
          }
          // 帳票の全体ページ数を記録
          documentsAllPageCnt = pageStart;
        }
      } else {
        // 請求一覧からの出力
        // ※取引先は複数ありうる
        let pageStart = 0;
        for (let i = 0; i < this.listKokyaku.length; i++) {
          let row = this.listKokyaku[i];
          let uploadData = {
            clientId: row.clientId,
            clientNameKanji: '',
            clientNameKana: '',
            closingYmd: row.closingYmd,
            siteId: row.siteId,
            clientSiteNameKanji: '',
            separateInvoiceNo: 0,
            billingIssueInputDate: null,
            moneyBillingTotal: row.billingTotal,
            pageStart: pageStart,
            pageCnt: row.listChouhyou.length,
          };
          if (dataResult != null) {
            let dataResultRow = dataResult.find(el => el.client_id == row.clientId);
            if (dataResultRow != undefined) {
              // 請求書発行登録済みの場合
              uploadData.billingIssueInputDate = dataResultRow.billing_issue_input_date;
            }
          }
          uploadDataList.push(uploadData);
          // 取引先リスト作成
          if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
            // 現場別
            if (clientsList.length > 0) {
              let clientRow = clientsList.find(el => el.clientId == row.clientId);
              if (clientRow == undefined) {
                clientsList.push({clientId: row.clientId, siteIdList: [row.siteId]});
              } else {
                clientRow.siteIdList.push(row.siteId);
              }
            } else {
              clientsList.push({clientId: row.clientId, siteIdList: [row.siteId]});
            }
          } else {
            // 取引先別
            // 取引先リスト作成（取引先別のため取引先コードを保持）
            clientsList.push({clientId: row.clientId});
          }
          // 書類の何ページ目かを記録
          pageStart += row.listChouhyou.length;
        }
        // 帳票の全体ページ数を記録
        documentsAllPageCnt = pageStart;
      }
      //console.log(uploadDataList);
      // アップロード確認ダイアログを表示
      let confirmMessage = [];
      confirmMessage.push(this.$createElement('div','表示中の請求書をアップロードしますか？'));
      this.$store.commit('setLoading', false);
      let retConfirm = await this.$bvModal.msgBoxConfirm(confirmMessage, {title: '電子帳票登録確認'});
      if (retConfirm == true) {
        this.$store.commit('setLoading', true);
        if (documentsAllPageCnt <= 5 && (this.isSeparate == true || this.billingOutputClass != Const.BillingOutputClass.clientSite)) {
          // 帳票のページ数が5ページ以下、且つ、（分割あり、または現場別以外）
          if (this.billingOutputClass == Const.BillingOutputClass.clientSite) {
            // 現場別
            let promiseArray = [];
            for (let i = 0; i < clientsList.length; i++) {
              let csvSiteId = '';
              for (let j = 0; j < clientsList[i].siteIdList.length; j++) {
                if (csvSiteId != '') {
                  csvSiteId += ',';
                }
                csvSiteId += clientsList[i].siteIdList[j];
              }
              selectSql = '';
              // SELECT句
              selectSql += 'SELECT';
              selectSql += ' m_c.client_id';
              selectSql += ',m_c.client_name_kanji';
              selectSql += ',m_c.client_name_kana';
              selectSql += ',m_cs.site_id';
              selectSql += ',m_cs.client_site_name_kanji';
              // FROM句
              selectSql += ' FROM ';
              selectSql += 'm_clients AS m_c ';
              selectSql += 'INNER JOIN m_clients_sites AS m_cs ';
              selectSql += 'ON m_c.client_id = m_cs.client_id ';
              // WHERE句
              selectSql += ' WHERE ';
              selectSql += 'm_c.client_class = ' + Const.ClientClass.customer + ' ';
              selectSql += 'AND m_c.client_id = ' + clientsList[i].clientId + ' ';
              selectSql += 'AND m_cs.site_id IN (' + csvSiteId + ') ';
              promiseArray.push(executeSelectSql(selectSql));
            }
            let nameDataResultList = await Promise.all(promiseArray);
            for (let i = 0; i < uploadDataList.length; i++) {
              for (let j = 0; j < nameDataResultList.length; j++) {
                if (nameDataResultList[j] != null && nameDataResultList[j].length > 0) {
                  if (uploadDataList[i].clientId == nameDataResultList[j][0].client_id) {
                    //
                    let nameDataRow = nameDataResultList[j].find(el => el.client_id == uploadDataList[i].clientId && el.site_id == uploadDataList[i].siteId);
                    if (nameDataRow != undefined) {
                      uploadDataList[i].clientNameKanji = nameDataRow.client_name_kanji;
                      uploadDataList[i].clientNameKana = nameDataRow.client_name_kana;
                      uploadDataList[i].clientSiteNameKanji = nameDataRow.client_site_name_kanji;
                    }
                  }
                }
              }
            }
          } else {
            // 取引先別
            let csvClientId = '';
            for (let i = 0; i < clientsList.length; i++) {
              if (csvClientId != '') {
                csvClientId += ',';
              }
              csvClientId += clientsList[i].clientId;
            }
            selectSql = '';
            // SELECT句
            selectSql += 'SELECT';
            selectSql += ' m_c.client_id';
            selectSql += ',m_c.client_name_kanji';
            selectSql += ',m_c.client_name_kana';
            // FROM句
            selectSql += ' FROM ';
            selectSql += 'm_clients AS m_c ';
            // WHERE句
            selectSql += ' WHERE ';
            selectSql += 'm_c.client_class = ' + Const.ClientClass.customer + ' ';
            selectSql += 'AND m_c.client_id IN (' + csvClientId + ') ';
            let nameDataResult = await executeSelectSql(selectSql);
            for (let i = 0; i < nameDataResult.length; i++) {
              let uploadDataRow = uploadDataList.find(el => el.clientId == nameDataResult[i].client_id);
              uploadDataRow.clientNameKanji = nameDataResult[i].client_name_kanji;
              uploadDataRow.clientNameKana = nameDataResult[i].client_name_kana;
            }
          }
          let uploadCheck = true;
          // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
          try {
            const msg = await isSystemEditable();
            if (msg !== null) {
              this.$bvToast.toast(msg, {
                title: 'アップロード失敗',
                toaster: TOASTER,
                variant: 'alert'
              });

              uploadCheck = false;
            }
          } catch (error) {
            await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
            this.$bvToast.toast(DISP_MESSAGES.DANGER['3001'], {
              title: 'アップロード失敗',
              toaster: TOASTER,
              variant: 'alert'
            });
            uploadCheck = false;
          }
          //console.log(uploadCheck);
          if (uploadCheck == true) {
            // S3にファイルをページ分割アップロード
            let filePath = 'SaveDocumentsInput/' + Const.DocumentsKindList.find(el => el.id == Const.DocumentsKind.seikyu).key;
            let listFilePath = []; // ファイルパスの配列
            let listFileName = []; // ファイル名の配列
            let listPage = [];
            let normalSeparateClass = this.isSeparate == false ? Const.NormalSeparateClass.normal : Const.NormalSeparateClass.separate;
            let billingSeparateClass = null;
            if (this.isSeparate == true && this.separateBillingClass == Const.SeparateBillingClass.sites) {
              // 請求書発行（現場・伝票毎）からの出力、且つ、請求書分割区分が「1：現場毎」の場合
              billingSeparateClass = Const.SeparateBillingClass.sites;
            } else {
              // 上記以外の場合は「0：伝票毎」
              billingSeparateClass = Const.SeparateBillingClass.billings;
            }
            // 電子書類データのキー取得
            let eDocumentsFirstId = await getFormCounterMultiple(Const.CounterClass.electronicDocumentsId, Const.DocumentsKind.seikyu, 0, uploadDataList.length, this.loginId, MODULE_NAME, functionName);
            if (eDocumentsFirstId == null) {
              // IDの取得に失敗した場合、エラーとする。（複数人が同時に保存しようとした場合、発生する可能性あり）
              this.$bvToast.toast(DISP_MESSAGES.WARNING['2067'].replace('%arg1%', '電子書類データの登録'), {
                title: 'アップロード失敗',
                toaster: TOASTER,
                variant: 'alert'
              });
            } else {
              for (let i = 0; i < uploadDataList.length; i++) {
                uploadDataList[i].id = getFormCounterMultipleReturnNo(eDocumentsFirstId, i); // アップロードデータ毎の一意のデータを設定
                // 年のディレクトリの下にファイルが入る
                listFilePath.push(filePath + '/' + formatDate(this.billingMonthYear + '-01', 'YYYY'));
                // ファイル名は「請求年月_通常分割区分_取引コード_現場コード_請求書分割区分_分割請求書番号_請求金額_ID.pdf」の形式
                let fileName = this.billingMonthYear.replace(/-/g,'');
                fileName += '_';
                fileName += normalSeparateClass;
                fileName += '_';
                fileName += uploadDataList[i].clientId;
                fileName += '_';
                fileName += uploadDataList[i].siteId;
                fileName += '_';
                fileName += billingSeparateClass;
                fileName += '_';
                fileName += uploadDataList[i].separateInvoiceNo;
                fileName += '_';
                fileName += uploadDataList[i].moneyBillingTotal;
                fileName += '_';
                fileName += uploadDataList[i].id;
                fileName += '.pdf';
                listFileName.push(fileName);
                if (uploadDataList[i].pageCnt > 1) {
                  // 1ページを超過する場合
                  // 「開始ページ-終了ページ（例：3-5）」の形式で指定
                  listPage.push((uploadDataList[i].pageStart + 1).toString() + '-' + (uploadDataList[i].pageStart + uploadDataList[i].pageCnt).toString());
                } else {
                  // 1ページのみの場合
                  // 「開始ページ」の形式で指定
                  listPage.push((uploadDataList[i].pageStart + 1).toString());
                }
              }
              let retUpload = await uploadHtmlPdfToS3PageSplit(this.$refs.dlPdf, this.pdfChouhyouSize.width, this.pdfChouhyouSize.height * documentsAllPageCnt, listFilePath, listFileName, listPage, MODULE_NAME);
              if (retUpload.result == true) {
                if (await this.execSave(uploadDataList) == true) {
                  this.$store.commit('setLoading', false);
                  this.$bvToast.toast(DISP_MESSAGES.SUCCESS['1001'], {
                    title: 'アップロード成功',
                    toaster: TOASTER,
                    variant: 'info'
                  });
                } else {
                  this.$bvToast.toast(DISP_MESSAGES.DANGER['3001'], {
                    title: 'アップロード失敗',
                    toaster: TOASTER,
                    variant: 'alert'
                  });
                  console.log(retUpload.s3KeyList);
                  // 成功したファイルを全て削除
                  await uploadFileRemoveFromS3Multiple(retUpload.s3KeyList, MODULE_NAME);
                }
              } else {
                this.$bvToast.toast(DISP_MESSAGES.DANGER['3001'], {
                  title: 'アップロード失敗',
                  toaster: TOASTER,
                  variant: 'alert'
                });
              }
            }
          }
        } else {
          // 帳票のページ数が5ページを超過する場合
          this.createUploadCsvData(uploadDataList);
          // 保存確認ダイアログを表示
          let confirmMessage = [];
          if (this.isSeparate == false && this.billingOutputClass == Const.BillingOutputClass.clientSite) {
            // 請求一覧表から現場別を印刷
            confirmMessage.push(this.$createElement('div','請求一覧表から現場別の帳票を印刷しているため、CSVファイルを作成します。'));
          } else {
            // 上記以外（5ページ超過）
            confirmMessage.push(this.$createElement('div','帳票のページが5ページを超過しているため、CSVファイルを作成します。'));
          }
          confirmMessage.push(this.$createElement('div','作成されたCSVファイルと表示中の請求書をPDFで印刷して、'));
          confirmMessage.push(this.$createElement('div','保存電子書類入力画面で登録をお願いします。'));
          confirmMessage.push(this.$createElement('div','保存電子書類入力画面を別タブで開きますか？'));
          this.$store.commit('setLoading', false);
          retConfirm = await this.$bvModal.msgBoxConfirm(confirmMessage, {title: '保存電子書類入力画面表示確認'});
          if (retConfirm == true) {
            // 保存書類入力画面へ遷移
            let query = {
              propDocumentsKind: Const.DocumentsKind.seikyu,
              propDocumentsUploadWay: Const.DocumentsUploadWay.pageSplit,
            };
            let route = this.$router.resolve({ name: 'SAVE-DOCUMENTS-INPUT', query: query });
            window.open(route.href, '_blank');
          }
        }
      }
      return true;
    },
    // ページ分割アップロード用のCSVファイル作成
    createUploadCsvData: function(uploadDataList) {
      let csvDataList = [];
      let header = '';
      header += '請求年月';
      header += ',';
      header += '通常分割区分';
      header += ',';
      header += '営業所コード';
      header += ',';
      header += '取引先コード';
      header += ',';
      header += '締切日付';
      header += ',';
      header += '現場コード';
      header += ',';
      header += '請求書分割区分';
      header += ',';
      header += '分割請求書番号';
      header += ',';
      header += '請求金額';
      header += ',';
      header += 'ページ';
      csvDataList.push(header);
      for (let i = 0; i < uploadDataList.length; i++) {
        let row = '';
        // 請求年月
        row += this.billingMonthYear.replace(/-/g,'');
        // 通常分割区分
        row += ',';
        row += this.isSeparate == false ? Const.NormalSeparateClass.normal : Const.NormalSeparateClass.separate;
        // 営業所コード
        row += ',';
        row += this.loginOfficeId;
        // 取引先コード
        row += ',';
        row += uploadDataList[i].clientId;
        // 締切日付
        row += ',';
        row += uploadDataList[i].closingYmd;
        // 現場コード
        row += ',';
        row += uploadDataList[i].siteId;
        // 請求書分割区分
        row += ',';
        if (this.isSeparate == true && this.separateBillingClass == Const.SeparateBillingClass.sites) {
          // 請求書発行（現場・伝票毎）からの出力、且つ、請求書分割区分が「1：現場毎」の場合
          row += Const.SeparateBillingClass.sites;
        } else {
          // 上記以外の場合は「0：伝票毎」
          row += Const.SeparateBillingClass.billings;
        }
        // 分割請求書番号
        row += ',';
        row += uploadDataList[i].separateInvoiceNo;
        // 請求金額
        row += ',';
        row += uploadDataList[i].moneyBillingTotal;
        // ページ
        row += ',';
        if (uploadDataList[i].pageCnt > 1) {
          // 1ページを超過
          row += (uploadDataList[i].pageStart + 1).toString() + '-' + (uploadDataList[i].pageStart + uploadDataList[i].pageCnt).toString();
        } else {
          // 1ページ
          row += (uploadDataList[i].pageStart + 1).toString();
        }
        csvDataList.push(row);
      }
      let csvData = csvDataList.join('\n');
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blobPart = csvData.length === 0 ? [csvData] : [bom, csvData];
      const blob = new Blob(blobPart, { type: 'text/csv' });
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      const download = document.createElement('a');
      download.download = this.billingMonthYear.replace(/-/g,'') + '請求書ページ分割アップロード用CSVデータ.csv';
      download.href = url;
      download.click();
      (window.URL || window.webkitURL).revokeObjectURL(url);
    },
    // 保存処理
    // 帳票の表示には成功しており、表示帳票の邪魔となるため、エラーメッセージは右下に出力
    async execSave(uploadDataList) {
      const functionName = 'execSave';

      let transactSqlList = [];
      // 電子書類データ＿請求書データの登録
      await this.insertElectronicDocumentsBillingsIssue(transactSqlList, uploadDataList);
      // 電子書類データ＿請求書データの請求重複区分の設定
      this.updateElectronicDocumentsBillingsIssue(transactSqlList, uploadDataList);
      //console.log(transactSqlList);

      // 作成した登録用SQLを全実行
      if (await executeTransactSqlList(transactSqlList, MODULE_NAME, functionName) == false) {
        return false;
      }

      return true;
    },
    // 電子書類データ＿請求書データ登録処理
    async insertElectronicDocumentsBillingsIssue(transactSqlList, uploadDataList) {
      //console.log('電子書類データ＿請求書データ登録処理');
      //console.log(uploadDataList);
      for (let i = 0; i < uploadDataList.length; i++) {
        let colList = [];
        // ID
        colList.push(CreateColRow('id', uploadDataList[i].id, 'NUMBER'));
        // 請求年月
        colList.push(CreateColRow('billing_month_year', this.billingMonthYear.replace(/-/g,''), 'NUMBER'));
        // 営業所コード
        colList.push(CreateColRow('office_id', this.loginOfficeId, 'NUMBER'));
        // 締切日付
        colList.push(CreateColRow('closing_ymd', uploadDataList[i].closingYmd, 'DATE'));
        // 通常分割区分
        colList.push(CreateColRow('normal_separate_class', this.isSeparate == false ? Const.NormalSeparateClass.normal : Const.NormalSeparateClass.separate, 'NUMBER'));
        // 取引先コード
        colList.push(CreateColRow('client_id', uploadDataList[i].clientId, 'NUMBER'));
        // 取引先名（漢字）
        colList.push(CreateColRow('client_name_kanji', await escapeQuote(uploadDataList[i].clientNameKanji), 'VARCHAR'));
        // 取引先名（カナ）
        colList.push(CreateColRow('client_name_kana', await escapeQuote(uploadDataList[i].clientNameKana), 'VARCHAR'));
        // 請求書出力単位区分
        colList.push(CreateColRow('billing_output_class', this.billingOutputClass, 'NUMBER'));
        // 現場コード
        colList.push(CreateColRow('site_id', uploadDataList[i].siteId, 'NUMBER'));
        // 現場名（漢字）
        colList.push(CreateColRow('client_site_name_kanji', await escapeQuote(uploadDataList[i].clientSiteNameKanji), 'VARCHAR'));
        // 請求書発行登録日
        colList.push(CreateColRow('billing_issue_input_date', uploadDataList[i].billingIssueInputDate, 'DATE'));
        // 請求書分割区分
        if (this.isSeparate == true && this.separateBillingClass == Const.SeparateBillingClass.sites) {
          // 請求書発行（現場・伝票毎）からの出力、且つ、請求書分割区分が「1：現場毎」の場合
          colList.push(CreateColRow('billing_separate_class', Const.SeparateBillingClass.sites, 'NUMBER'));
        } else {
          // 上記以外の場合は「0：伝票毎」
          colList.push(CreateColRow('billing_separate_class', Const.SeparateBillingClass.billings, 'NUMBER'));
        }
        // 分割請求書番号
        colList.push(CreateColRow('separate_invoice_no', uploadDataList[i].separateInvoiceNo, 'NUMBER'));
        // 請求金額
        colList.push(CreateColRow('money_billing_total', uploadDataList[i].moneyBillingTotal, 'NUMBER'));
        // ファイル拡張子
        colList.push(CreateColRow('file_extension', 'pdf', 'VARCHAR'));
        // 登録日
        colList.push(CreateColRow('input_date', 'CURDATE()', 'DATE'));
        // 登録担当者コード
        colList.push(CreateColRow('input_staff_id', this.loginStaffId, 'NUMBER'));
        // 作成ユーザー
        colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
        // 更新ユーザー
        colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));

        let insertSql = CreateInsertSql(colList, 'full', 't_electronic_documents_seikyu');
        //console.log(insertSql)
        transactSqlList.push(insertSql);
      }
    },
    // 電子書類データ＿請求書データの請求重複区分の設定
    updateElectronicDocumentsBillingsIssue: function(transactSqlList, uploadDataList) {
      // 電子書類データ＿請求書データの請求重複区分のUPDATE文
      let csvBillingMonthYear = this.billingMonthYear.replace(/-/g,'');
      let clientIdList = [];
      for (let i = 0; i < uploadDataList.length; i++) {
        if (clientIdList.findIndex(el => el == uploadDataList[i].clientId) == -1) {
          clientIdList.push(uploadDataList[i].clientId);
        }
      }
      let csvClientid = clientIdList.join(',');
      let normalSeparateClass = this.isSeparate == false ? Const.NormalSeparateClass.normal : Const.NormalSeparateClass.separate;
      let separateBillingClass = null;
      if (this.isSeparate == true && this.separateBillingClass == Const.SeparateBillingClass.sites) {
        separateBillingClass = Const.SeparateBillingClass.sites;
      } else {
        separateBillingClass = Const.SeparateBillingClass.billings;
      }
      let billingDuplicateClass = getBillingDuplicateClass(this.billingOutputClass, normalSeparateClass, separateBillingClass);
      let updateSql = updateElectronicDocumentsSeikyuDuplicateClass(csvBillingMonthYear, csvClientid, billingDuplicateClass, this.loginId);
      transactSqlList.push(updateSql);
    }
  },
}
</script>
<style scoped>
</style>