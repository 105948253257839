<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col cols="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <b-card class="border">
              <b-card-header class="mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip" title="クリックすると請求書出力範囲表示/非表示できます。">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" style="display: block; float: left;" role="tablist" aria-expanded="true">
                    <span class="oi oi-magnifying-glass"></span> 請求書出力範囲
                  </a>
                </h5>
              </b-card-header>
              <br>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-2">
                  <b-container>
                    <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                      <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                        <li>{{error}}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                      <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                        <li>{{error}}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                      <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                        <li>{{error}}</li>
                      </ul>
                    </b-alert>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <b-container>
                        <b-row>
                          <!-- 年月 -->
                          <b-col cols="3">
                            <validation-provider name="billingMonthYear" :rules="{required:true, regex:/^[0-9]{4}-(0[1-9]|1[0-2])$/}" v-slot="{ classes,errors }">
                              <b-form-group label="年月">
                                <b-row>
                                  <b-col lg="8" :class="classes">
                                    <b-form-input
                                      name="billingMonthYear"
                                      type="month"
                                      v-model="searchConditions.billingMonthYear"
                                      @input="calcBillingDate()" 
                                    />
                                  </b-col>
                                  <b-col lg="8" :class="classes">
                                    <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                  </b-col>
                                </b-row>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <!-- 請求書発行登録区分 -->
                          <b-col lg="3">
                            <label for="selectInputIssuedClass">請求書発行登録区分</label>
                            <b-form-select
                              id="selectInputIssuedClass"
                              v-model="searchConditions.selectInputIssuedClass"
                              :options="searchConditions.inputIssuedClassList"
                            >
                            </b-form-select>
                          </b-col>
                          <!-- アップロード済チェック入力欄 -->
                          <b-col lg="2">
                            <label for="isSearchUploadCheck" v-b-tooltip.hover.noninteractive title="チェックONの場合、検索時にアップロード済み列を表示します。（検索時間は増加します。）">アップロード済 <span class="px-1 oi oi-flag"/></label>
                            <b-form-checkbox
                              id="isSearchUploadCheck"
                              name="isSearchUploadCheck"
                              v-model="searchConditions.isSearchUploadCheck"
                            ></b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 得意先コード -->
                          <b-col lg="5">
                            <b-form-group
                              label-for="clientId"
                              label="得意先コード"
                            >
                              <b-input-group>
                                <validation-provider name="clientId" rules="numeric|min:6|required" v-slot="{ classes,errors }">
                                  <b-row :class="classes">
                                    <b-col lg="12">
                                      <b-input-group>
                                        <b-form-input 
                                          type="tel" 
                                          id="clientId" 
                                          name="clientId" 
                                          @input="searchClient(searchConditions.clientId)" 
                                          maxlength="6"
                                          v-model="searchConditions.clientId"
                                        />
                                        <b-input-group-text @click="showClientSearchModal()" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                            <span class="oi oi-magnifying-glass"></span>
                                            <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal()" class="d-none"></button>
                                          </b-button>
                                        </b-input-group-text>
                                        <b-button class="mr-1" size="sm" pill variant="success" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが空欄以外の場合、利用可能です。）" :disabled="searchConditions.clientId == ''" @click="clientInfoModal(propClientClass, searchConditions.clientId)">
                                          <span class="oi oi-eye"></span> 情報
                                        </b-button>
                                      </b-input-group>
                                    </b-col>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 取引先名 -->
                          <b-col lg="5">
                            <label class="form-label"><strong>取引先名: </strong>{{searchConditions.clientName}}</label>
                          </b-col>
                          <!-- 請求書出力区分 -->
                          <b-col lg="3">
                            <label class="form-label"><strong>請求書出力区分: </strong>{{billingOutputClassVal(searchConditions.billingOutputClass.toString())}}</label>
                          </b-col>
                          <!-- 締日 -->
                          <b-col lg="2">
                            <label class="form-label"><strong>締日: </strong>{{searchConditions.closingDate}}</label>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 伝票日付 -->
                          <b-col lg="4">
                            <label class="form-label"><strong>伝票日付: </strong>{{searchConditions.dateBillingDateStart + '～' + searchConditions.dateBillingDateEnd}}</label>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 請求書分割区分 -->
                          <b-col lg="2">
                            <b-form-group label-for="separateBillingClass" label="請求書分割区分">
                              <b-form-select
                                id="separateBillingClass"
                                v-model="searchConditions.separateBillingClass"
                                :options="searchConditions.separateBillingClassList"
                              >
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                          <!-- 分割請求書番号 -->
                          <b-col lg="3" v-if="searchConditions.separateBillingClass==htmlConst.SeparateBillingClass.billings && searchConditions.selectInputIssuedClass!=htmlConst.InvoiceIssueClass.noIssue">
                            <b-form-group label="分割請求書番号">
                              <b-input-group>
                                <validation-provider name="separateInvoiceNo" rules="numeric" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input name="separateInvoiceNo" type="text" v-model.number="searchConditions.separateInvoiceNo"/>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                              </b-input-group>
                              <b-form-text class="text-muted">空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                          <!-- 現場コード -->
                          <b-col lg="6" v-if="searchConditions.billingOutputClass == 1">
                            <b-form-group label="現場コード">
                              <b-input-group>
                                <validation-provider name="siteIdStart" :rules="{numeric:true,consistency:searchConditions.siteIdEnd}" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input name="siteIdStart" type="text" v-model.number="searchConditions.siteIdStart"/>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                                <b-input-group-text>～</b-input-group-text>
                                <validation-provider name="siteIdEnd" rules="numeric" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input name="siteIdEnd" type="text" v-model.number="searchConditions.siteIdEnd"/>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                              </b-input-group>
                              <b-form-text class="text-muted">空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-container>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <b-row class="justify-content-md-center my-2">
                      <b-col lg="3">
                        <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                          <span class="oi oi-magnifying-glass"></span> 検 索 
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <!-- ●●●検索条件●●● -->
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-card-header>
          <b-row class="justify-content-md-center">
            <b-col lg="7">
              <b-input-group>
                <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="一覧のチェックONの得意先の請求書のPDF出力を行います。" @click="clearAlert(); clickOutputBillingPdf();" :disabled="checkedList.length==0">
                  <span class="oi oi-document"></span> 請求書PDF出力
                </b-button>
                <label for="isUploadCheck" v-b-tooltip.hover.noninteractive title="チェックONの場合、請求書PDF出力時に電子書類としてアップロードするかチェックします。">アップロードチェック <span class="px-1 oi oi-flag"/></label>
                <b-form-checkbox
                  id="isUploadCheck"
                  v-model="isUploadCheck"
                ></b-form-checkbox>
              </b-input-group>
            </b-col>
            <b-col lg="5">
              <b-input-group>
                <template #prepend>
                  <label for="invoiceIssueInputDate">
                    <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive title='請求書発行登録ボタン押下時の登録日です。'>
                      <strong class='text-body'>請求書発行登録日 <span class="oi oi-flag"/></strong>
                    </b-input-group-text>
                  </label>
                </template>
                <b-form-datepicker
                  id="invoiceIssueInputDate"
                  name="invoiceIssueInputDate"
                  v-model="invoiceIssueInputDate"
                  calendar-width="50%"
                  :reset-button="false"
                ></b-form-datepicker>
              </b-input-group>
              <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="一覧のチェックONの得意先の請求書の発行登録を行います。（登録済みの得意先の更新はしません。更新したい場合は取消後に再登録して下さい。）" @click="clearAlert(); clickInvoiceIssueInput();" :disabled="checkedList.length==0||editFlg==false||searchResultDispConditions.selectInputIssuedClass==htmlConst.InvoiceIssueClass.issue">
                <span class="oi oi-circle-check"></span> 請求書発行登録
              </b-button>
              <b-button pill size="sm" variant="danger" v-b-tooltip.hover.noninteractive title="一覧のチェックONの得意先の請求書の発行登録を取り消します。" @click="clearAlert(); clickInvoiceIssueCancel();" :disabled="checkedList.length==0||editFlg==false||searchResultDispConditions.selectInputIssuedClass!=htmlConst.InvoiceIssueClass.issue">
                <span class="oi oi-delete"></span> 請求書発行登録取消
              </b-button>
            </b-col>
          </b-row>
          <b-alert show variant="warning" v-if="resultError.length">
            <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
              <li>{{error}}</li>
            </ul>
          </b-alert>
        </b-card-header>
        <b-card-body>
          <b-col>
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col  lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="5"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-table
                show-empty
                :items="itemList"
                :fields="fields"
                :busy="busy"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                :small="true"
                @filtered="onFiltered"
              >
                <!-- テーブル読み込み時表示html -->
                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>読み込んでいます...</strong>
                  </div>
                </template>
                <template #head(BillingCheck)>
                  <b-check v-model="isAllChecked" @change="clickCheckAll" :disabled="totalCheckCnt==0" />
                </template>
                <template #cell(BillingCheck)="data">
                  <b-check v-if="data.item.IsCheck" v-model="checkedList" :value="data.item.BillingNo" @change="changeCheck"/>
                </template>
                <template #cell(isUpload)="data">
                  {{ data.item.isUpload == true ? '★' : '' }}
                </template>
                <template #head(SiteCheck)>
                  <b-check v-model="isAllChecked" @change="clickCheckAll" :disabled="totalCheckCnt==0" />
                </template>
                <template #cell(SiteCheck)="data">
                  <b-check v-if="data.item.IsCheck" v-model="checkedList" :value="data.item.SiteId" @change="changeCheck"/>
                </template>
                <template #cell(BillingNo)="data">
                  {{ data.item.BillingNo }}
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.right="'売上伝票照会画面を別タブで表示します。'" @click="clickSalesSlipBtn(data.item.BillingNo)" class="ml-1">
                    <span class="oi oi-pencil"></span>
                  </b-button>
                </template>
                <template #cell(SalesUnitAmount)="data">
                  {{ data.item.SalesUnitAmount != null ? Number(data.item.SalesUnitAmount).toLocaleString() : '' }}
                </template>
                <template #cell(SeparateSalesUnitAmount)="data">
                  {{ data.item.SeparateSalesUnitAmount != null ? data.item.SeparateSalesUnitAmount.toLocaleString() : '' }}
                </template>
                <template #cell(SeparateSalesUnitTax)="data">
                  {{ data.item.SeparateSalesUnitTax != null ? data.item.SeparateSalesUnitTax.toLocaleString() : '' }}
                </template>
              </b-table>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : totalRows"
                :per-page="perPage == -1 ? totalRows : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-col>
        </b-card-body>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●取引先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="propClientClass"/>
    <!-- ●●●取引先照会モーダル●●● -->
    <CLIENTINQUIRY :clientProp="propClientInquiry"/>
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { searchClientModal } from '@/assets/js/searchModal.js';
import { init, formatDate, formatCurDate, formatDateCalc, dateConsistency, getListValue, executeSelectSql, addOperationLogs, getControlMaster, getClosingDate, getFormCounter, CreateColRow, CreateInsertSql, isSystemEditable, executeTransactSqlList, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';

const MODULE_NAME = 'invoice-list-separate';

export default {
  name: 'INVOICE-LIST-SEPARATE',
  // コンポーネント
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
    CLIENTINQUIRY,
  },
  // データ
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      baseTitle: '請求書発行（現場・伝票毎）',
      title: '',
      loginId: '',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      // 検索項目
      searchConditions: {
        // 請求年月
        billingMonthYear: '',
        // 取引先コード
        clientId: '',
        // 取引先名
        clientName: '',
        // 締日
        closingDate: '',
        // 請求書出力単位区分
        billingOutputClass: '',
        // 伝票日付（開始～終了）
        dateBillingDateStart: '',
        dateBillingDateEnd: '',
        // 請求書発行登録区分
        selectInputIssuedClass: Const.InvoiceIssueClass.all,
        inputIssuedClassList: Const.InvoiceIssueClassList,
        // 請求書分割区分
        separateBillingClass: Const.SeparateBillingClass.billings,
        separateBillingClassList: [],
        // 分割請求書番号
        separateInvoiceNo: '',
        // 現場コード（開始～終了）
        siteIdStart: '',
        siteIdEnd: '',
        // アップロード済チェック
        isSearchUploadCheck: false,
      },
      // 検索結果表示時に指定された検索項目（帳票出力時の引数にはこちらを使用）
      searchResultDispConditions:{
        // 請求年月
        billingMonthYear: '',
        // 取引先コード
        clientId: '',
        // 請求書出力単位区分
        billingOutputClass: '',
        // 伝票日付（開始～終了）
        dateBillingDateStart: '',
        dateBillingDateEnd: '',
        // 請求書発行登録区分
        selectInputIssuedClass: Const.InvoiceIssueClass.all,
        // 請求書分割区分
        separateBillingClass: Const.SeparateBillingClass.billings,
        // 現場コード（開始～終了）
        siteIdStart: '',
        siteIdEnd: '',
        // アップロード済チェック
        isSearchUploadCheck: false,
      },
      // 請求書発行登録日
      invoiceIssueInputDate: formatCurDate('YYYY-MM-DD'),
      // 取引先モーダル用の引数
      propClientClass: Const.ClientClass.customer,
      // 検索結果
      itemList: [],
      fields: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: 0,
      // 印刷チェックボックスの総件数
      totalCheckCnt: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // フィルタリングデータの総件数
      filterRows: 0,
      // 全選択チェックボックス
      isAllChecked: false,
      // 各行のチェック状態
      checkedList: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 請求書発行登録区分
        InvoiceIssueClass: {
          // 全て
          all: Const.InvoiceIssueClass.all,
          // 未
          noIssue: Const.InvoiceIssueClass.noIssue,
          // 済
          issue: Const.InvoiceIssueClass.issue,
        },
        // 請求書分割区分
        SeparateBillingClass: {
          // 伝票毎
          billings: Const.SeparateBillingClass.billings,
          // 現場毎
          sites: Const.SeparateBillingClass.sites,
        },
      },
      // 請求前情報
      preBilling: {
        processMonthYear: 0,
        closingDate: 0,
        billingStartDate: '',
        billingEndDate: '',
      },
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        invoiceInputNo: '',
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // パラメータ
      billingPaymentClass: 0,
      // テーブル名
      billingsTableName: '',
      // タイトル用の固定値
      addTitle1: '（本社）',
      addTitle2: '２（横浜）',
      // 変更フラグ
      editFlg: false,
      // 請求書発行登録済み変数
      isInvoiceIssueClient: false,
      invoiceIssueSiteList: [],
      // 発行登録済みテキスト
      textClientIssue: '同取引先全発行済',
      textSiteIssue: '同現場全発行済',
      // MAX件数
      maxInputClientCnt: 100,
      maxInputSiteCnt: 50,
      maxInputBillingCnt: 150,
      // アップロードチェック
      isUploadCheck: false,
    }
  },
  // マウント
  mounted() {
    init(); // common.jsにて初期化処理
    // ログインユーザーの情報(LoginID)を取得
    let user = store.getters.user;
    this.loginId = user.username;
    // パラメータ取得
    if (this.$route.query.billingPaymentClass == Const.BillingPaymentClass.billing2) {
      this.billingPaymentClass = Const.BillingPaymentClass.billing2;
      this.title = this.baseTitle + this.addTitle2;
      this.billingsTableName = 'm_billings2';
    } else {
      this.billingPaymentClass = Const.BillingPaymentClass.billing;
      this.title = this.baseTitle + this.addTitle1;
      this.billingsTableName = 'm_billings';
    }
    // 帳票のbodyタグのスタイル、center制定後、leftに戻す
    document.body.style['text-align'] = 'left';
    this.$store.commit('setLoading', false);
  },
  computed: {
    // ページの表示件数
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1 || this.perPage == -1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    // メッセージがあるかどうかの返却
    getMessageFlg: function() {
      return this.alertSuccess.length > 0 || this.alertWarning.length > 0 || this.alertDanger.length > 0
    },
  },
  methods: {
    // 取引先検索
    showClientSearchModal: function() {
      //console.log('取引先検索モーダル');
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先照会
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal: function(clientItems) {
      // モーダルから渡された値の有無チェック
      if (typeof clientItems != 'undefined') {
        this.searchConditions.clientId = clientItems.detail.client_id;
        this.searchConditions.clientName = clientItems.detail.client_name_kanji;
        this.searchConditions.closingDate = clientItems.detail.closing_date.toString();
        this.searchConditions.billingOutputClass = clientItems.detail.billing_output_class;
        // 請求書分割区分を設定
        this.searchConditions.separateBillingClass = Const.SeparateBillingClass.billings;
        if (this.searchConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
          this.searchConditions.separateBillingClassList = Const.SeparateBillingClassList;
        } else {
          this.searchConditions.separateBillingClassList = [];
          this.searchConditions.separateBillingClassList.push(Const.SeparateBillingClassList.find(el => el.value == Const.SeparateBillingClass.billings));
        }
        // 伝票日付を更新
        this.calcBillingDate();
        // 現場コードを初期化
        this.searchConditions.siteIdStart = '';
        this.searchConditions.siteIdEnd = '';
      }
    },
    // 伝票日付（開始日、締切日）を計算
    calcBillingDate: function() {
      // 開始日、締切日を計算
      if (this.searchConditions.closingDate == '' || this.searchConditions.billingMonthYear == '') {
        // 計算に必要な値が未入力の場合
        this.searchConditions.dateBillingDateStart = '';
        this.searchConditions.dateBillingDateEnd = '';
      } else if (this.searchConditions.closingDate == '99') {
        // 末日の場合
        // 開始日：決算年月の月初日、締切日：決算年月の月末日
        this.searchConditions.dateBillingDateStart = formatDate(this.searchConditions.billingMonthYear + '-01');
        this.searchConditions.dateBillingDateEnd = formatDateCalc(this.searchConditions.billingMonthYear + '-01', 0, 0, 0, true);
      } else {
        // 末日でない場合
        // 開始日：決算年月の先月の締日の翌日、締切日：決算年月の締日
        this.searchConditions.dateBillingDateStart = formatDateCalc(this.searchConditions.billingMonthYear + '-' + ('00' + this.searchConditions.closingDate).slice(-2), 0, -1, 1);
        this.searchConditions.dateBillingDateEnd = formatDate(this.searchConditions.billingMonthYear + '-' + ('00' + this.searchConditions.closingDate).slice(-2));
      }
    },
    // 取引先コード直接入力 - 取引先検索
    async searchClient(client_id) {
      if (client_id.length == 6) {
        const result = await searchClientModal(client_id, this.propClientClass, 'searchClient', MODULE_NAME)
        if (typeof result != 'undefined') {
          // データ取得成功
          this.closeClientSearchModal(result)
        } else {
          // データ取得失敗
          // 取引先クリア
          this.clientClear()
        }
      }
    },
    // 取引先クリア
    clientClear: function() {
      this.searchConditions.clientId = '';
      this.searchConditions.clientName = '';
      this.searchConditions.closingDate = '';
      this.searchConditions.billingOutputClass = '';
      // 請求書分割区分を設定
      this.searchConditions.separateBillingClass = Const.SeparateBillingClass.billings;
      this.searchConditions.separateBillingClassList = [];
      this.searchConditions.separateBillingClassList.push(Const.SeparateBillingClassList.find(el => el.value == Const.SeparateBillingClass.billings));
      // 伝票日付を更新
      this.calcBillingDate();
    },
    // 出力区分の区分値返却処理
    billingOutputClassVal: function(billingOutputClass) {
      return getListValue(Const.billingOutputClassList, billingOutputClass);
    },
    // 検索ボタン押下時
    async searchButton() {
      const functionName = 'searchButton'
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          this.$store.commit('setLoading', true);
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.$store.commit('setLoading', false);
    },
    // 検索条件を保存
    saveSearchConditions: function() {
      this.searchResultDispConditions.billingMonthYear = this.searchConditions.billingMonthYear;
      this.searchResultDispConditions.clientId = this.searchConditions.clientId;
      if (this.searchConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
        // 検索条件「現場別」を選択している場合は「現場別」
        this.searchResultDispConditions.billingOutputClass = Const.billingOutputClass.clientsSites;
      } else {
        // 検索条件「現場別」以外の場合は「取引先別」とする
        this.searchResultDispConditions.billingOutputClass = Const.billingOutputClass.clients;
      }
      this.searchResultDispConditions.dateBillingDateStart = this.searchConditions.dateBillingDateStart;
      this.searchResultDispConditions.dateBillingDateEnd = this.searchConditions.dateBillingDateEnd;
      if (this.searchConditions.selectInputIssuedClass == Const.InvoiceIssueClass.issue) {
        // 請求書発行登録「済」の場合は「済」とする
        this.searchResultDispConditions.selectInputIssuedClass = Const.InvoiceIssueClass.issue;
      } else {
        // 請求書発行登録「済」以外の場合は「未」とする
        this.searchResultDispConditions.selectInputIssuedClass = Const.InvoiceIssueClass.noIssue;
      }
      this.searchResultDispConditions.separateBillingClass = this.searchConditions.separateBillingClass;
      this.searchResultDispConditions.siteIdStart = this.searchConditions.siteIdStart;
      this.searchResultDispConditions.siteIdEnd = this.searchConditions.siteIdEnd;
      this.searchResultDispConditions.isSearchUploadCheck = this.searchConditions.isSearchUploadCheck;
      // 編集フラグを初期化
      if (this.controlMasterData.processMonthYear <= Number(this.searchResultDispConditions.billingMonthYear.replace('-', ''))) {
        this.editFlg = true;
      } else {
        this.editFlg = false;
      }
    },
    // 検索処理
    async search() {
      const functionName = 'search';
      if (this.busy == true) {
        return;
      }
      this.busy = true;
      try {
        this.isUploadCheck = false;
        this.itemList = [];
        // ページング機能の初期化
        this.initPaging();
        // フィールドを機能選択と合わせる
        this.fields = this.getFields();
        // 請求日（開始日と終了日）の取得
        await this.getBillingDateInfo();
        // CRUD処理
        let selectSql = '';
        selectSql = this.makeSelectSql();
        //console.log(selectSql);
        //console.log('条件取得');
        let resultData = await executeSelectSql(selectSql);
        if (resultData != null) {
          this.saveSearchConditions();
          await this.setResult(resultData);
          //console.log(resultData);
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.busy = false;
    },
    // 取得結果セット
    async setResult(result) {
      // アップロード済取得用
      let siteIdList = [];
      // 印刷チェックを初期化
      this.checkedList = [];
      this.totalCheckCnt = 0;
      this.isAllChecked = false;
      // 総件数をdataTableの総件数にセット
      this.totalRows = result.length;
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          IsCheck: false,
          InvoiceIssueInput: formatDate(result[i].billing_issue_input_date),
          RowInvoiceIssueClass: result[i].billing_issue_input_date == null ? Const.InvoiceIssueClass.noIssue : Const.InvoiceIssueClass.issue,
          SiteId: result[i].site_id,
          SiteName: result[i].client_site_name,
          SalesUnitAmount: result[i].product_sales_unit_amount,
          SeparateSalesUnitAmount: result[i].sales_unit_amount,
          SeparateSalesUnitTax: result[i].normal_tax == null && result[i].light_tax == null ? '' : result[i].normal_tax + result[i].light_tax,
        };
        if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
          searchResult.SeparateInvoiceNo = result[i].separate_invoice_no;
          searchResult.BillingDate = formatDate(result[i].billing_date);
          searchResult.BillingNo = result[i].billing_no;
        }
        if (searchResult.RowInvoiceIssueClass == this.searchResultDispConditions.selectInputIssuedClass) {
          // 請求書発行登録「全て」「未」の場合、請求書発行登録されていない行にチェックあり
          // 請求書発行登録「済」の場合、請求書発行登録された行にチェックあり
          searchResult.IsCheck = true;
        }
        if (searchResult.RowInvoiceIssueClass == Const.InvoiceIssueClass.noIssue) {
          // 請求書発行登録「未」の場合
          if (this.isInvoiceIssueClient == true) {
            // 取引先は既に請求書発行登録済みの場合
            searchResult.IsCheck = false;
            searchResult.InvoiceIssueInput = this.textClientIssue;
          } else if (this.invoiceIssueSiteList.findIndex(el=>el == searchResult.SiteId) >= 0) {
            // 現場は既に請求書発行登録済みの場合
            searchResult.IsCheck = false;
            searchResult.InvoiceIssueInput = this.textSiteIssue;
          }
        }
        if (searchResult.IsCheck == true) {
          this.totalCheckCnt++;
        }
        // アップロード済チェックON、且つ、現場別の場合のみ現場コードリスト作成
        if (this.searchResultDispConditions.isSearchUploadCheck == true &&
          this.searchResultDispConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
          if (siteIdList.findIndex(el => el == result[i].site_id) == -1) {
            // 現場別の取引先の場合
            siteIdList.push(result[i].site_id);
          }
        }
        this.itemList.push(searchResult);
      }
      //console.log(this.itemList);
      if (this.itemList.length > 0 &&
        this.searchResultDispConditions.isSearchUploadCheck == true) {
        await this.setIsUpload(siteIdList);
      }
    },
    // SELECT文字列作成
    makeSelectSql: function() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' billings_QUERY.site_id';
      selectSql += ',billings_QUERY.client_site_name';
      selectSql += ',SUM(billings_QUERY.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql += ',billings_issue_input.billing_issue_input_date';
      selectSql += ',billings_issue_input.sales_unit_amount';
      selectSql += ',billings_issue_input.normal_tax';
      selectSql += ',billings_issue_input.light_tax';
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        selectSql += ',billings_QUERY.billing_no';
        selectSql += ',billings_QUERY.billing_date';
        selectSql += ',billings_issue_input.separate_invoice_no';
      }
      // FROM句
      selectSql += ' FROM ';
      selectSql += '(' + this.makeMainQuery() + ') AS billings_QUERY ';
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        // 請求書分割区分「伝票毎」の場合
        selectSql += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input ';
        selectSql += 'ON billings_issue_input.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql += 'AND billings_QUERY.billing_no = billings_issue_input.billing_no ';
      } else {
        // 請求書分割区分「現場毎」の場合
        selectSql += 'LEFT JOIN t_billings_issue_input_site AS billings_issue_input ';
        selectSql += 'ON billings_issue_input.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql += 'AND billings_issue_input.client_id = ' + this.searchConditions.clientId + ' ';
        selectSql += 'AND billings_QUERY.site_id = billings_issue_input.site_id ';
      }
      // WHERE句
      let whereClause = '';
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        // 請求書分割区分「伝票毎」の場合
        if (this.searchConditions.selectInputIssuedClass == Const.InvoiceIssueClass.noIssue) {
          // 請求書発行登録区分「未」の場合
          whereClause += 'AND billings_issue_input.separate_invoice_no IS NULL ';
        } else {
          // 請求書発行登録区分「未」以外の場合
          if (this.searchConditions.separateInvoiceNo != '') {
            // 分割請求書番号が入力されている場合
            whereClause += 'AND billings_issue_input.separate_invoice_no = ' + this.searchConditions.separateInvoiceNo + ' ';
          } else if (this.searchConditions.selectInputIssuedClass == Const.InvoiceIssueClass.issue) {
            // 請求書発行登録区分「済」の場合
            whereClause += 'AND billings_issue_input.separate_invoice_no IS NOT NULL ';
          }
        }
      } else {
        // 請求書分割区分「現場毎」の場合
        if (this.searchConditions.selectInputIssuedClass == Const.InvoiceIssueClass.noIssue) {
          // 請求書発行登録区分「未」の場合
          whereClause += 'AND billings_issue_input.billing_month_year IS NULL ';
        } else if (this.searchConditions.selectInputIssuedClass == Const.InvoiceIssueClass.issue) {
          // 請求書発行登録区分「済」の場合
          whereClause += 'AND billings_issue_input.billing_month_year IS NOT NULL ';
        }
      }
      if (whereClause != '') {
        selectSql += ' WHERE ' + whereClause.substring(4);
      }
      // GROUP BY句
      selectSql += ' GROUP BY ';
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        selectSql += ' billings_QUERY.billing_no,billings_QUERY.billing_date';
      } else {
        selectSql += ' billings_QUERY.site_id';
      }
      // ORDER BY句
      selectSql += ' ORDER BY ';
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        // 伝票毎
        if (this.searchConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
          // 現場別
          selectSql += ' billings_QUERY.site_id,billings_QUERY.billing_date,billings_QUERY.billing_no ';
        } else {
          // 取引先別
          selectSql += ' billings_QUERY.billing_date,billings_QUERY.billing_no ';
        }
      } else {
        // 現場毎
        selectSql += ' billings_QUERY.site_id ';
      }

      return selectSql;
    },
    // メインクエリ作成
    makeMainQuery: function() {
      // 請求締更新前のデータ
      let selectSql1 = '';
      // SELECT句
      selectSql1 += 'SELECT';
      selectSql1 += ' cumulative_transaction.site_id';
      selectSql1 += ',cumulative_transaction.client_site_name';
      selectSql1 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        selectSql1 += ',cumulative_transaction.billing_no';
        selectSql1 += ',cumulative_transaction.billing_date';
      }
      // FROM句
      selectSql1 += ' FROM ';
      selectSql1 += this.billingsTableName + ' AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND cumulative_transaction.client_id = ' + this.searchConditions.clientId + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.searchConditions.dateBillingDateStart + '\' AND \'' + this.searchConditions.dateBillingDateEnd + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.sites) {
        // 請求書分割区分「現場毎」の場合
        selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
        selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      }
      // WHERE句
      selectSql1 += ' WHERE ';
      // 得意先コード
      selectSql1 += 'billings.client_id = ' + this.searchConditions.clientId + ' ';
      // 現場コード
      if (this.searchConditions.siteIdStart != '' && this.searchConditions.siteIdEnd == '') {
        selectSql1 += 'AND cumulative_transaction.site_id >= ' + this.searchConditions.siteIdStart + ' ';
      }else if (this.searchConditions.siteIdStart == '' && this.searchConditions.siteIdEnd != '') {
        selectSql1 += 'AND cumulative_transaction.site_id <= ' + this.searchConditions.siteIdEnd + ' ';
      }else if (this.searchConditions.siteIdStart != '' && this.searchConditions.siteIdEnd != '') {
        selectSql1 += 'AND cumulative_transaction.site_id BETWEEN ' + this.searchConditions.siteIdStart + ' ' + 'AND ' + this.searchConditions.siteIdEnd + ' ';
      }
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        // 製品コード（特別値引き用の製品コードの行は除外）
        selectSql1 += 'AND cumulative_transaction.product_id NOT IN (' + Const.specialSalesDiscount.productId + ',' + Const.specialSalesDiscount.productIdLightTax + ',' + Const.specialSalesDiscount.productIdNoTax + ') ';
      } else {
        // 請求書分割区分「現場毎」の場合
        // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外
        selectSql1 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
      }
      // GROUP BY句
      selectSql1 += ' GROUP BY ';
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        selectSql1 += ' cumulative_transaction.transaction_id,cumulative_transaction.billing_no';
      } else {
        selectSql1 += ' cumulative_transaction.transaction_id,cumulative_transaction.site_id';
      }

      // 請求締更新後のデータ
      let selectSql2 = '';
      // SELECT句
      selectSql2 += 'SELECT';
      selectSql2 += ' cumulative_transaction.site_id';
      selectSql2 += ',cumulative_transaction.client_site_name';
      selectSql2 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        selectSql2 += ',cumulative_transaction.billing_no';
        selectSql2 += ',cumulative_transaction.billing_date';
      }
      // FROM句
      selectSql2 += ' FROM ';
      selectSql2 += 't_billings_balances AS billings_balances ';
      selectSql2 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql2 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql2 += 'AND cumulative_transaction.client_id = ' + this.searchConditions.clientId + ' ';
      selectSql2 += 'AND billings_balances.client_id = cumulative_transaction.client_id ';
      selectSql2 += 'AND billings_balances.site_id = cumulative_transaction.site_id ';
      selectSql2 += 'AND billings_balances.billing_month_year = cumulative_transaction.closing_month_year ';
      selectSql2 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.searchConditions.dateBillingDateStart + '\' AND \'' + this.searchConditions.dateBillingDateEnd + '\' ';
      selectSql2 += 'AND cumulative_transaction.is_update_closing_date = 1 ';
      selectSql2 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.sites) {
        // 請求書分割区分「現場毎」の場合
        selectSql2 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
        selectSql2 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
        selectSql2 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      }
      // WHERE句
      selectSql2 += ' WHERE ';
      // 請求年月
      selectSql2 += ' cumulative_transaction.closing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql2 += 'AND billings_balances.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      // 請求開始日
      selectSql2 += 'AND billings_balances.billing_start_date IS NOT NULL ';
      // 得意先コード
      selectSql2 += 'AND billings_balances.client_id = ' + this.searchConditions.clientId + ' ';
      // 現場コード
      if (this.searchConditions.siteIdStart != '' && this.searchConditions.siteIdEnd == '') {
        selectSql2 += 'AND billings_balances.site_id >= ' + this.searchConditions.siteIdStart + ' ';
      }else if (this.searchConditions.siteIdStart == '' && this.searchConditions.siteIdEnd != '') {
        selectSql2 += 'AND billings_balances.site_id <= ' + this.searchConditions.siteIdEnd + ' ';
      }else if (this.searchConditions.siteIdStart != '' && this.searchConditions.siteIdEnd != '') {
        selectSql2 += 'AND billings_balances.site_id BETWEEN ' + this.searchConditions.siteIdStart + ' ' + 'AND ' + this.searchConditions.siteIdEnd + ' ';
      }
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        // 製品コード（特別値引き用の製品コードの行は除外）
        selectSql2 += 'AND cumulative_transaction.product_id NOT IN (' + Const.specialSalesDiscount.productId + ',' + Const.specialSalesDiscount.productIdLightTax + ',' + Const.specialSalesDiscount.productIdNoTax + ') ';
      } else {
        // 請求書分割区分「現場毎」の場合
        // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外
        selectSql2 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
      }
      // GROUP BY句
      selectSql2 += ' GROUP BY ';
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        selectSql2 += ' cumulative_transaction.transaction_id,cumulative_transaction.billing_no';
      } else {
        selectSql2 += ' cumulative_transaction.transaction_id,cumulative_transaction.site_id';
      }
      // 2つのSELECT文をUNION
      let selectSql = '';
      if (this.preBilling.processMonthYear > 0 && this.searchConditions.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 画面から選択した年月が請求前処理の年月と同じ場合
        // 2つのSELECT文をUNION
        selectSql = selectSql1 + ' UNION ALL ' + selectSql2;
      } else {
        // 画面から選択した年月が請求前処理の年月と異なる場合
        // 請求残高の登録情報のみを表示
        selectSql = selectSql2;
      }

      return selectSql;
    },
    // アップロード済設定
    async setIsUpload(siteIdList) {
      let selectSql = await this.makeSelectSqlUpload(siteIdList);
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      //console.log(resultData);
      if (resultData != null && resultData.length > 0) {
        if (this.searchResultDispConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
          // 現場別の場合
          for (let i = 0; i < this.itemList.length; i++) {
            let data = resultData.find(el => el.site_id == this.itemList[i].SiteId);
            if (data != undefined) {
              this.itemList[i].isUpload = true;
            }
          }
        } else {
          // 取引先別の場合
          for (let i = 0; i < this.itemList.length; i++) {
            this.itemList[i].isUpload = true;
          }
        }
      }
    },
    // 検索SELECT文字列作成（アップロード済の取引先）
    makeSelectSqlUpload(siteIdList) {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' client_id';
      if (this.searchResultDispConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
        // 現場別の場合
        selectSql += ',site_id';
      }
      // FROM句
      selectSql += ' FROM ';
      selectSql += 't_electronic_documents_seikyu ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += ' billing_month_year = ' + this.searchResultDispConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND client_id IN (' + this.searchResultDispConditions.clientId + ')';
      if (this.searchResultDispConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
        // 現場別の場合
        let csvSiteId = siteIdList.join(',');
        selectSql += 'AND site_id IN (' + csvSiteId + ')';
        if (this.searchResultDispConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
          // 伝票毎（請求重複区分「4:重複（現場別の伝票毎）」）
          selectSql += 'AND billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
          selectSql += 'AND normal_separate_class = ' + Const.NormalSeparateClass.separate + ' ';
          selectSql += 'AND billing_separate_class = ' + Const.SeparateBillingClass.billings + ' ';
        } else {
          // 現場毎（請求重複区分「3:重複（現場別の現場全体）」）
          selectSql += 'AND billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
          selectSql += 'AND (normal_separate_class = ' + Const.NormalSeparateClass.normal + ' ';
          selectSql += 'OR (normal_separate_class = ' + Const.NormalSeparateClass.separate + ' ';
          selectSql += 'AND billing_separate_class = ' + Const.SeparateBillingClass.sites + ')) ';
        }
        // GROUP BY句
        selectSql += 'GROUP BY client_id,site_id';
      } else {
        // 取引先別の場合（請求重複区分「2:重複（取引先別の分割）」）
        selectSql += 'AND billing_output_class = ' + Const.BillingOutputClass.client + ' ';
        selectSql += 'AND normal_separate_class = ' + Const.NormalSeparateClass.separate + ' ';
        // GROUP BY句
        selectSql += 'GROUP BY client_id';
      }

      return selectSql;
    },
    // 請求書PDF出力ボタンの押下
    async clickOutputBillingPdf() {
      if (this.searchResultDispConditions.selectInputIssuedClass != Const.InvoiceIssueClass.issue) {
        // 請求書発行登録区分「済」以外の場合
        if (dateConsistency(this.controlMasterData.processMonthYear + '01', this.searchResultDispConditions.billingMonthYear + '-01') == false) {
          // 年月が処理年月よりも過去の場合
          this.alertWarning.push(DISP_MESSAGES.WARNING['2073']);
          scrollTo(0,0);
          return;
        }
      }
      let csvData = '';
      if (this.searchResultDispConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        // 伝票毎
        if (this.checkedList.length > this.maxInputBillingCnt) {
          // 一度の出力件数が上限を超えている場合
          this.alertWarning.push(DISP_MESSAGES.WARNING['2074'].replace('%arg1%', '取引先毎に').replace('%arg2%', '伝票').replace('%arg3%', this.maxInputBillingCnt));
          scrollTo(0,0);
          return;
        }
        if (this.searchResultDispConditions.selectInputIssuedClass == Const.InvoiceIssueClass.issue) {
          // 請求書発行登録区分「済」の場合
          let separateInvoiceNoList = [];
          for (let i = 0; i < this.checkedList.length; i++) {
            let item = this.itemList.find(el => el.BillingNo == this.checkedList[i]);
            if (item != undefined) {
              if (separateInvoiceNoList.findIndex(el => el == item.SeparateInvoiceNo) == -1) {
                separateInvoiceNoList.push(item.SeparateInvoiceNo);
              }
            }
          }
          let inputList = [];
          for (let i = 0; i < this.itemList.length; i++) {
            // チェックの入った行と同じ分割請求書番号を持つ行のみを対象とする
            if (separateInvoiceNoList.findIndex(el => el == this.itemList[i].SeparateInvoiceNo) != -1) {
              if (this.searchResultDispConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
                // 現場別
                let input = inputList.find(el => el.SiteId == this.itemList[i].SiteId);
                if (input == undefined) {
                  inputList.push({SiteId: this.itemList[i].SiteId, SeparateInvoiceNo: this.itemList[i].SeparateInvoiceNo, BillingNoList: [this.itemList[i].BillingNo]});
                } else {
                  if (input.SeparateInvoiceNo != this.itemList[i].SeparateInvoiceNo) {
                    // 分割請求書番号が異なる場合は、同じ現場に複数の分割請求書番号が存在することになるので、操作エラーとする
                    this.alertWarning.push(DISP_MESSAGES.WARNING['2072'].replace('%arg1%', '取引先－現場毎'));
                    scrollTo(0,0);
                    return;
                  } else {
                    input.BillingNoList.push(this.itemList[i].BillingNo);
                  }
                }
              } else {
                // 取引先別
                if (separateInvoiceNoList.length > 1) {
                  // 分割請求書番号が複数存在する場合は、操作エラーとする
                  this.alertWarning.push(DISP_MESSAGES.WARNING['2072'].replace('%arg1%', '取引先毎'));
                  scrollTo(0,0);
                  return;
                }
                if (separateInvoiceNoList[0] == this.itemList[i].SeparateInvoiceNo) {
                  if (inputList.length == 0) {
                    inputList.push({BillingNoList: [this.itemList[i].BillingNo]});
                  } else {
                    inputList[0].BillingNoList.push(this.itemList[i].BillingNo);
                  }
                }
              }
            }
          }
          for (let i = 0; i < inputList.length; i++) {
            if (csvData != '') {
              csvData += ',';
            }
            csvData += inputList[i].BillingNoList.join(',');
          }
        } else {
          // 請求書発行登録区分「済」以外の場合
          for (let i = 0; i < this.checkedList.length; i++) {
            if (csvData != '') {
              csvData += ',';
            }
            csvData += this.checkedList[i];
          }
        }
      } else {
        // 現場毎
        if (this.checkedList.length > this.maxInputSiteCnt) {
          // 一度の出力件数が上限を超えている場合
          this.alertWarning.push(DISP_MESSAGES.WARNING['2074'].replace('%arg1%', '取引先毎に').replace('%arg2%', '現場').replace('%arg3%', this.maxInputSiteCnt));
          scrollTo(0,0);
          return;
        }
        for (let i = 0; i < this.checkedList.length; i++) {
          if (csvData != '') {
            csvData += ',';
          }
          csvData += this.checkedList[i];
        }
      }
      // 請求書PDF出力処理画面へ遷移
      let query = {
        propBillingMonthYear: this.searchResultDispConditions.billingMonthYear,
        propBillingOutputClass: this.searchResultDispConditions.billingOutputClass,
        propCheckedClientIds: this.searchResultDispConditions.clientId,
        propBillingPaymentClass: this.billingPaymentClass,
        propIsSeparate: '1',
        propSeparateBillingClass: this.searchResultDispConditions.separateBillingClass,
        propIsUploadCheck: this.isUploadCheck == true ? '1' : '0',
      };
      if (this.searchResultDispConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        // 伝票毎
        query.propSeparateBillingNo = csvData;
      } else {
        // 現場毎
        query.propSiteIdStart = csvData;
      }
      let route = this.$router.resolve({ name: 'SEIKYU', query: query });
      window.open(route.href, '_blank');
    },
    // 全チェック選択
    clickCheckAll: function(){
      if (this.isAllChecked == true) {
        // チェックONにした場合
        // 印刷チェックを初期化
        this.checkedList = [];
        // 印刷チェックを全てON
        for (let i = 0; i < this.itemList.length; i++) {
          if (this.itemList[i].IsCheck == true) {
            if (this.searchResultDispConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
              this.checkedList.push(this.itemList[i].BillingNo);
            } else {
              this.checkedList.push(this.itemList[i].SiteId);
            }
          }
        }
      } else {
        // チェックOFFにした場合
        // 印刷チェックを全てOFF
        this.checkedList = [];
      }
    },
    // チェックボックス個別選択（全チェックのチェックボックスを合わせる）
    changeCheck: function(){
      //console.log('changeCheck');
      this.isAllChecked = this.totalCheckCnt == this.checkedList.length;
    },
    // 画面のアラートをクリア
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    // フィルター時のイベント
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    // ページング変数の初期化
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    // 請求日（開始日と終了日）の取得
    async getBillingDateInfo() {
      // 各種データ取得（非同期でまとめて取得した方が早いため）
      let preBillingResult = null;
      let controlData = null;
      let separateResult = null;
      let where_clause = 'AND billing_payment_class = ' + this.billingPaymentClass + ' ';
      let selectSqlSeparate = 'SELECT 0 AS site_id ';
      selectSqlSeparate += 'FROM t_billings_issue_input WHERE billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' AND client_id = ' + this.searchConditions.clientId + ' ';
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings &&
      this.searchConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
        // 伝票毎、且つ、現場別の場合は、現場別の請求書発行登録のデータも取得する
        selectSqlSeparate += 'UNION ALL ';
        selectSqlSeparate += 'SELECT site_id ';
        selectSqlSeparate += 'FROM t_billings_issue_input_site WHERE billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' AND client_id = ' + this.searchConditions.clientId + ' ';
      }
      [preBillingResult, controlData, separateResult] = await Promise.all([
        selectOneTable('t_closing_update_range', where_clause),
        getControlMaster(),
        executeSelectSql(selectSqlSeparate),
      ]);
      this.controlMasterData.processMonthYear = controlData.process_month_year;
      this.controlMasterData.taxRate = controlData.tax_rate;
      this.controlMasterData.newTaxRate = controlData.new_tax_rate;
      this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
      this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
      this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
      this.controlMasterData.invoiceInputNo = controlData.invoice_input_no;
      if (preBillingResult != null && preBillingResult.length > 0) {
        this.preBilling.processMonthYear = preBillingResult[0].process_month_year;
        this.preBilling.closingDate = preBillingResult[0].closing_date;
        this.preBilling.billingStartDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, true);
        this.preBilling.billingEndDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, false);
      } else {
        this.preBilling.processMonthYear = 0;
        this.preBilling.closingDate = 0;
        this.preBilling.billingStartDate = '';
        this.preBilling.billingEndDate = '';
      }
      this.isInvoiceIssueClient = false;
      this.invoiceIssueSiteList = [];
      if (separateResult != null && separateResult.length > 0) {
        for(let i = 0; i < separateResult.length; i++) {
          if (separateResult[i].site_id == 0) {
            this.isInvoiceIssueClient = true;
          } else {
            this.invoiceIssueSiteList.push(separateResult[i].site_id);
          }
        }
      }
    },
    // 機能選択の選択値毎に検索結果のカラムを変更
    getFields: function() {
      let retFields = [];
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        retFields.push({key: 'BillingCheck'});
        if (this.searchConditions.isSearchUploadCheck == true) {
          retFields.push({key: 'isUpload', label: 'アップロード済'});
        }
        retFields.push({key: 'SeparateInvoiceNo', label: '分割請求書番号'});
      } else {
        retFields.push({key: 'SiteCheck'});
        if (this.searchConditions.isSearchUploadCheck == true) {
          retFields.push({key: 'isUpload', label: 'アップロード済'});
        }
      }
      retFields.push({key: 'InvoiceIssueInput', label: '請求書発行登録日'});
      if (this.searchConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        retFields.push({key: 'BillingDate', label: '伝票日付'});
        retFields.push({key: 'BillingNo', label: '伝票番号'});
      }
      retFields.push({key: 'SiteId', label: '現場コード'});
      retFields.push({key: 'SiteName', label: '現場名'});
      retFields.push({key: 'SalesUnitAmount', label: '金額', tdClass: 'text-right'});
      retFields.push({key: 'SeparateSalesUnitAmount', label: '分割請求書金額小計', tdClass: 'text-right'});
      retFields.push({key: 'SeparateSalesUnitTax', label: '分割請求書消費税', tdClass: 'text-right'});

      return retFields;
    },
    // 請求書発行登録ボタンの押下
    async clickInvoiceIssueInput() {
      const functionName = 'clickInvoiceIssueInput';
      try {
        let targetName = '';
        if (this.searchResultDispConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
          targetName = '伝票';
        } else {
          targetName = '現場';
        }
        let confirmMessage = [];
        confirmMessage.push(this.$createElement('div','チェックされた' + targetName + 'の請求書発行登録を行います。'));
        confirmMessage.push(this.$createElement('div','よろしいですか？'));
        if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: '請求書発行登録'}) == true) {
          this.$store.commit('setLoading', true);
          await this.execInput();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 請求書発行登録処理
    async execInput() {
      const functionName = 'execInput';
      let sqlList = null;
      if (this.searchResultDispConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        // 請求書発行チェック（伝票毎）
        if (await this.checkInputBillingNo() == true) {
          sqlList = await this.getInputBillingNoSql();
        }
      } else {
        // 請求書発行チェック（現場毎）
        if (await this.checkInputSite() == true) {
          sqlList = this.getInputSiteSql();
        }
      }
      if (sqlList == null || sqlList.length == 0) {
        return;
      }
      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }

      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
      }
    },
    // 請求書発行登録処理のチェック（伝票毎）
    async checkInputBillingNo() {
      let clientId = this.searchResultDispConditions.clientId;
      let billingMonthYear = this.searchResultDispConditions.billingMonthYear.replace(/-/g,'');
      let siteIdList = [];
      for (let i = 0; i < this.checkedList.length; i++) {
        // チェックの入った行を取得
        let item = this.itemList.find(el => el.BillingNo == this.checkedList[i]);
        if (item != undefined) {
          if (siteIdList.findIndex(el => el == item.SiteId) == -1) {
            siteIdList.push(item.SiteId);
          }
        }
      }
      // 上流工程で請求書発行登録済みでないかチェック用（取引先内、現場内）
      let selectSql = 'SELECT';
      selectSql += ' (SELECT COUNT(*) FROM t_billings_issue_input WHERE ';
      selectSql += ' billing_month_year = ' + billingMonthYear + ' AND client_id = ' + clientId;
      selectSql += ' ) AS billings_issue_input_flg';
      selectSql += ',(SELECT COUNT(*) FROM t_billings_issue_input_site WHERE ';
      selectSql += ' billing_month_year = ' + billingMonthYear + ' AND client_id = ' + clientId + ' AND site_id IN (' + siteIdList.join(',') + ') ';
      selectSql += ' ) AS billings_issue_input_site_cnt';
      selectSql += ',(SELECT COUNT(*) FROM t_billings_issue_input_billing_no WHERE ';
      selectSql += ' billing_month_year = ' + billingMonthYear + ' AND client_id = ' + clientId + ' ';
      selectSql += ' ) AS billings_issue_input_billing_no_cnt';
      selectSql += ' FROM DUAL';
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      if (resultData[0].billings_issue_input_flg == 1) {
        // 請求書の発行登録がある場合（取引先単位）
        this.alertWarning.push(DISP_MESSAGES.WARNING['2068'].replace('%arg1%','取引先'));
        return false;
      } else if (resultData[0].billings_issue_input_site_cnt >= 1) {
        // 請求書の発行登録がある場合（現場単位）
        this.alertWarning.push(DISP_MESSAGES.WARNING['2068'].replace('%arg1%','現場'));
        return false;
      } else if (resultData[0].billings_issue_input_billing_no_cnt + this.checkedList.length > this.maxInputBillingCnt) {
        // 本登録によって請求書先行発行（伝票毎）の発行登録が最大件数を超える場合
        this.alertWarning.push(DISP_MESSAGES.WARNING['2074'].replace('%arg1%', '取引先毎に').replace('%arg2%', '伝票').replace('%arg3%', this.maxInputBillingCnt));
        return false;
      }
      let resultClientBillingNo = null;
      let resultClientSite = null;
      let selectSqlClientBillingNo = 'SELECT client_id FROM t_billings_issue_input_billing_no WHERE ';
      selectSqlClientBillingNo += ' billing_month_year = ' + billingMonthYear + ' ';
      selectSqlClientBillingNo += ' GROUP BY client_id ORDER BY client_id ';
      let selectSqlClientSite = 'SELECT client_id FROM t_billings_issue_input_site WHERE ';
      selectSqlClientSite += ' billing_month_year = ' + billingMonthYear + ' ';
      selectSqlClientSite += ' GROUP BY client_id ORDER BY client_id ';
      [resultClientBillingNo, resultClientSite] = await Promise.all([
        executeSelectSql(selectSqlClientBillingNo),
        executeSelectSql(selectSqlClientSite),
      ]);
      let clientIdList = [];
      if (resultClientBillingNo != null && resultClientBillingNo.length > 0) {
        for (let i = 0; i < resultClientBillingNo.length; i++) {
          clientIdList.push(resultClientBillingNo[i].client_id);
        }
      }
      if (resultClientSite != null && resultClientSite.length > 0) {
        for (let i = 0; i < resultClientSite.length; i++) {
          if (clientIdList.findIndex(el => el == resultClientSite[i].client_id) == -1) {
            clientIdList.push(resultClientSite[i].client_id);
          }
        }
      }
      if (clientIdList.findIndex(el => el == clientId) == -1) {
        // 登録予定の取引先は、請求書発行登録がない場合（新規登録）
        if (clientIdList.length >= this.maxInputClientCnt) {
          // 本登録によって請求書先行発行取引先の発行登録が最大件数を超える場合
          this.alertWarning.push(DISP_MESSAGES.WARNING['2074'].replace('%arg1%', '').replace('%arg2%', '取引先').replace('%arg3%', this.maxInputClientCnt));
          return false;
        }
      }
      return true;
    },
    // 請求書発行登録処理（伝票毎）のSQLを作成
    async getInputBillingNoSql() {
      const functionName = 'execInputBillingNo';
      let sqlList = [];
      let inputList = [];
      for (let i = 0; i < this.itemList.length; i++) {
        // チェックの入った行
        if (this.checkedList.findIndex(el => el == this.itemList[i].BillingNo) != -1) {
          // 伝票日付の過去日チェック
          if (dateConsistency(this.controlMasterData.processMonthYear + '01', this.itemList[i].BillingDate) == false) {
            this.alertWarning.push(DISP_MESSAGES.WARNING['2017']);
            return [];
          }
          if (this.searchResultDispConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
            // 現場別
            let input = inputList.find(el => el.SiteId == this.itemList[i].SiteId);
            if (input == undefined) {
              inputList.push({SiteId: this.itemList[i].SiteId, BillingNoList: [this.itemList[i].BillingNo]});
            } else {
              input.BillingNoList.push(this.itemList[i].BillingNo);
            }
          } else {
            // 取引先別
            if (inputList.length == 0) {
              inputList.push({SiteId: this.itemList[i].SiteId, BillingNoList: [this.itemList[i].BillingNo]});
            } else {
              inputList[0].BillingNoList.push(this.itemList[i].BillingNo);
            }
          }
        }
      }
      // 分割請求書番号を発行
      for (let i = 0; i < inputList.length; i++) {
        inputList[i].SeparateInvoiceNo = await getFormCounter(Const.CounterClass.separateInvoiceNo, 0, 0, this.loginId, MODULE_NAME, functionName);
      }
      // 作成したリストを元にINSERT文を作成
      let bulkInsertSqlStart = '';
      let bulkInsertSqlSelect = '';
      for (let i = 0; i < this.itemList.length; i++) {
        if (this.checkedList.findIndex(el => el == this.itemList[i].BillingNo) != -1) {
          let input = null;
          if (this.searchResultDispConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
            input = inputList.find(el => el.SiteId == this.itemList[i].SiteId)
          } else {
            input = inputList[0];
          }
          if (bulkInsertSqlStart == '') {
            // チェックON
            let colList = [];
            // 請求年月
            colList.push(CreateColRow('billing_month_year', null, 'NUMBER'));
            // 分割請求書番号
            colList.push(CreateColRow('separate_invoice_no', null, 'NUMBER'));
            // 伝票No
            colList.push(CreateColRow('billing_no', null, 'NUMBER'));
            // 得意先コード
            colList.push(CreateColRow('client_id', null, 'NUMBER'));
            // 現場コード
            colList.push(CreateColRow('site_id', null, 'NUMBER'));
            // 請求書発行登録日
            colList.push(CreateColRow('billing_issue_input_date', null, 'DATE'));
            // 事業者登録番号	
            colList.push(CreateColRow('invoice_input_no', null, 'VARCHAR'));
            // 売上金額
            colList.push(CreateColRow('sales_unit_amount', null, 'NUMBER'));
            // 通常消費税課税小計
            colList.push(CreateColRow('normal_tax_subtotal', null, 'NUMBER'));
            // 通常消費税額
            colList.push(CreateColRow('normal_tax', null, 'NUMBER'));
            // 軽減消費税課税小計
            colList.push(CreateColRow('light_tax_subtotal', null, 'NUMBER'));
            // 軽減消費税額
            colList.push(CreateColRow('light_tax', null, 'NUMBER'));
            // 売上金額_現場毎
            colList.push(CreateColRow('sales_unit_amount_site', null, 'NUMBER'));
            // 通常消費税課税小計_現場毎
            colList.push(CreateColRow('normal_tax_subtotal_site', null, 'NUMBER'));
            // 通常消費税額_現場毎
            colList.push(CreateColRow('normal_tax_site', null, 'NUMBER'));
            // 軽減消費税課税小計_現場毎
            colList.push(CreateColRow('light_tax_subtotal_site', null, 'NUMBER'));
            // 軽減消費税額_現場毎
            colList.push(CreateColRow('light_tax_site', null, 'NUMBER'));
            // 作成ユーザー
            colList.push(CreateColRow('created_user', null, 'VARCHAR'));
            // 更新ユーザー
            colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
            bulkInsertSqlStart = 'INSERT INTO t_billings_issue_input_billing_no (' + CreateInsertSql(colList, 'col', 't_billings_issue_input_billing_no') + ') ';
            //console.log(bulkInsertSqlStart);
          }
          let selectSql = '';
          // SELECT句
          selectSql += 'SELECT ';
          selectSql += ' ' + this.searchResultDispConditions.billingMonthYear.replace(/-/g,'');
          selectSql += ',' + input.SeparateInvoiceNo;
          selectSql += ',' + this.itemList[i].BillingNo;
          selectSql += ',' + this.searchResultDispConditions.clientId;
          selectSql += ',' + this.itemList[i].SiteId;
          selectSql += ',\'' + this.invoiceIssueInputDate + '\'';
          selectSql += ',\'' + this.controlMasterData.invoiceInputNo + '\'';
          selectSql += ',SUM(cumulative_transaction.product_sales_unit_amount)';
          selectSql += ',SUM(';
          selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
          selectSql += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
          selectSql += '   ELSE 0 END)';
          selectSql += ',TRUNCATE((SUM(';
          selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
          selectSql += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
          selectSql += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
          selectSql += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
          selectSql += '   ELSE 0 END) / 100),0)';
          selectSql += ',SUM(';
          selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
          selectSql += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
          selectSql += '   ELSE 0 END)';
          selectSql += ',TRUNCATE((SUM(';
          selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
          selectSql += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
          selectSql += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
          selectSql += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
          selectSql += '   ELSE 0 END) / 100),0)';
          selectSql += ',SUM((CASE WHEN cumulative_transaction.site_id = ' + this.itemList[i].SiteId + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END))';
          selectSql += ',SUM(';
          selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
          selectSql += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN (CASE WHEN cumulative_transaction.site_id = ' + this.itemList[i].SiteId + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ELSE 0 END) ';
          selectSql += '   ELSE 0 END)';
          selectSql += ',TRUNCATE((SUM(';
          selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
          selectSql += '     THEN (CASE WHEN cumulative_transaction.site_id = ' + this.itemList[i].SiteId + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
          selectSql += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
          selectSql += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
          selectSql += '   ELSE 0 END) / 100),0)';
          selectSql += ',SUM(';
          selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
          selectSql += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN (CASE WHEN cumulative_transaction.site_id = ' + this.itemList[i].SiteId + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ELSE 0 END) ';
          selectSql += '   ELSE 0 END)';
          selectSql += ',TRUNCATE((SUM(';
          selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
          selectSql += '     THEN (CASE WHEN cumulative_transaction.site_id = ' + this.itemList[i].SiteId + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
          selectSql += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
          selectSql += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
          selectSql += '   ELSE 0 END) / 100),0)';
          selectSql += ',\'' + this.loginId + '\'';
          selectSql += ',\'' + this.loginId + '\'';
          selectSql += ' FROM ';
          selectSql += 't_cumulative_transaction AS cumulative_transaction ';
          selectSql += 'LEFT JOIN m_products AS products ';
          selectSql += 'ON cumulative_transaction.product_id = products.product_id ';
          selectSql += ' WHERE ';
          selectSql += 'cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
          selectSql += 'AND cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
          selectSql += 'AND cumulative_transaction.client_id = ' + this.searchResultDispConditions.clientId + ' ';
          selectSql += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.searchResultDispConditions.dateBillingDateStart + '\' AND \'' + this.searchResultDispConditions.dateBillingDateEnd + '\' ';
          selectSql += 'AND cumulative_transaction.is_update_closing_date = 0 ';
          selectSql += 'AND cumulative_transaction.billing_no IN (' + input.BillingNoList.join(',') + ') ';
          if (bulkInsertSqlSelect != '') {
            bulkInsertSqlSelect += 'UNION ALL ';
          }
          bulkInsertSqlSelect += selectSql;
          if (bulkInsertSqlSelect.length >= Const.SqlMaxLength) {
            sqlList.push(bulkInsertSqlStart + bulkInsertSqlSelect);
            bulkInsertSqlSelect = '';
          }
        }
      }
      if (bulkInsertSqlSelect != '') {
        sqlList.push(bulkInsertSqlStart + bulkInsertSqlSelect);
      }
      return sqlList;
    },
    // 請求書発行登録処理のチェック（現場毎）
    async checkInputSite() {
      let clientId = this.searchResultDispConditions.clientId;
      let billingMonthYear = this.searchResultDispConditions.billingMonthYear.replace(/-/g,'');
      // 上流工程で請求書発行登録済みでないかチェック用（取引先内、現場内）
      let selectSql = 'SELECT';
      selectSql += ' (SELECT COUNT(*) FROM t_billings_issue_input WHERE ';
      selectSql += ' billing_month_year = ' + billingMonthYear + ' AND client_id = ' + clientId;
      selectSql += ' ) AS billings_issue_input_flg';
      selectSql += ',(SELECT COUNT(*) FROM t_billings_issue_input_site WHERE ';
      selectSql += ' billing_month_year = ' + billingMonthYear + ' AND client_id = ' + clientId + ' ';
      selectSql += ' ) AS billings_issue_input_site_cnt';
      selectSql += ' FROM DUAL';
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      if (resultData[0].billings_issue_input_flg == 1) {
        // 請求書の発行登録がある場合（取引先単位）
        this.alertWarning.push(DISP_MESSAGES.WARNING['2068'].replace('%arg1%','取引先'));
        return false;
      } else if (resultData[0].billings_issue_input_site_cnt + this.checkedList.length > this.maxInputSiteCnt) {
        // 本登録によって請求書先行発行（現場毎）の発行登録が最大件数を超える場合
        this.alertWarning.push(DISP_MESSAGES.WARNING['2074'].replace('%arg1%', '取引先毎に').replace('%arg2%', '現場').replace('%arg3%', this.maxInputSiteCnt));
        return false;
      }
      let resultClientBillingNo = null;
      let resultClientSite = null;
      let selectSqlClientBillingNo = 'SELECT client_id FROM t_billings_issue_input_billing_no WHERE ';
      selectSqlClientBillingNo += ' billing_month_year = ' + billingMonthYear + ' ';
      selectSqlClientBillingNo += ' GROUP BY client_id ORDER BY client_id ';
      let selectSqlClientSite = 'SELECT client_id FROM t_billings_issue_input_site WHERE ';
      selectSqlClientSite += ' billing_month_year = ' + billingMonthYear + ' ';
      selectSqlClientSite += ' GROUP BY client_id ORDER BY client_id ';
      [resultClientBillingNo, resultClientSite] = await Promise.all([
        executeSelectSql(selectSqlClientBillingNo),
        executeSelectSql(selectSqlClientSite),
      ]);
      let clientIdList = [];
      if (resultClientBillingNo != null && resultClientBillingNo.length > 0) {
        for (let i = 0; i < resultClientBillingNo.length; i++) {
          clientIdList.push(resultClientBillingNo[i].client_id);
        }
      }
      if (resultClientSite != null && resultClientSite.length > 0) {
        for (let i = 0; i < resultClientSite.length; i++) {
          if (clientIdList.findIndex(el => el == resultClientSite[i].client_id) == -1) {
            clientIdList.push(resultClientSite[i].client_id);
          }
        }
      }
      if (clientIdList.findIndex(el => el == clientId) == -1) {
        // 登録予定の取引先は、請求書発行登録がない場合（新規登録）
        if (clientIdList.length >= this.maxInputClientCnt) {
          // 本登録によって請求書先行発行取引先の発行登録が最大件数を超える場合
          this.alertWarning.push(DISP_MESSAGES.WARNING['2074'].replace('%arg1%', '').replace('%arg2%', '取引先').replace('%arg3%', this.maxInputClientCnt));
          return false;
        }
      }
      return true;
    },
    // 請求書発行登録処理（現場毎）のSQLを作成
    getInputSiteSql: function() {
      let sqlList = [];
      let bulkInsertSqlStart = '';
      let bulkInsertSqlSelect = '';
      for (let i = 0; i < this.itemList.length; i++) {
        if (this.checkedList.findIndex(el => el == this.itemList[i].SiteId) != -1) {
          if (bulkInsertSqlStart == '') {
            // チェックON
            let colList = [];
            // 請求年月
            colList.push(CreateColRow('billing_month_year', null, 'NUMBER'));
            // 得意先コード
            colList.push(CreateColRow('client_id', null, 'NUMBER'));
            // 現場コード
            colList.push(CreateColRow('site_id', null, 'NUMBER'));
            // 請求書発行登録日
            colList.push(CreateColRow('billing_issue_input_date', null, 'DATE'));
            // 事業者登録番号	
            colList.push(CreateColRow('invoice_input_no', null, 'VARCHAR'));
            // 売上金額
            colList.push(CreateColRow('sales_unit_amount', null, 'NUMBER'));
            // 通常消費税課税小計
            colList.push(CreateColRow('normal_tax_subtotal', null, 'NUMBER'));
            // 通常消費税額
            colList.push(CreateColRow('normal_tax', null, 'NUMBER'));
            // 軽減消費税課税小計
            colList.push(CreateColRow('light_tax_subtotal', null, 'NUMBER'));
            // 軽減消費税額
            colList.push(CreateColRow('light_tax', null, 'NUMBER'));
            // 作成ユーザー
            colList.push(CreateColRow('created_user', null, 'VARCHAR'));
            // 更新ユーザー
            colList.push(CreateColRow('updated_user', null, 'VARCHAR'));
            bulkInsertSqlStart = 'INSERT INTO t_billings_issue_input_site (' + CreateInsertSql(colList, 'col', 't_billings_issue_input_site') + ') ';
            //console.log(bulkInsertSqlStart);
          }
          let selectSql = '';
          // SELECT句
          selectSql += 'SELECT ';
          selectSql += ' ' + this.searchResultDispConditions.billingMonthYear.replace(/-/g,'');
          selectSql += ',' + this.searchResultDispConditions.clientId;
          selectSql += ',' + this.itemList[i].SiteId;
          selectSql += ',\'' + this.invoiceIssueInputDate + '\'';
          selectSql += ',\'' + this.controlMasterData.invoiceInputNo + '\'';
          selectSql += ',SUM(cumulative_transaction.product_sales_unit_amount)';
          selectSql += ',SUM(';
          selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
          selectSql += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
          selectSql += '   ELSE 0 END)';
          selectSql += ',TRUNCATE((SUM(';
          selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
          selectSql += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
          selectSql += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
          selectSql += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
          selectSql += '   ELSE 0 END) / 100),0)';
          selectSql += ',SUM(';
          selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
          selectSql += '     THEN (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN cumulative_transaction.product_sales_unit_amount ELSE 0 END) ';
          selectSql += '   ELSE 0 END)';
          selectSql += ',TRUNCATE((SUM(';
          selectSql += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
          selectSql += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
          selectSql += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
          selectSql += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
          selectSql += '   ELSE 0 END) / 100),0)';
          selectSql += ',\'' + this.loginId + '\'';
          selectSql += ',\'' + this.loginId + '\'';
          selectSql += ' FROM ';
          selectSql += 't_cumulative_transaction AS cumulative_transaction ';
          selectSql += 'LEFT JOIN m_products AS products ';
          selectSql += 'ON cumulative_transaction.product_id = products.product_id ';
          selectSql += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
          selectSql += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.searchResultDispConditions.billingMonthYear.replace(/-/g,'') + ' ';
          selectSql += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
          selectSql += ' WHERE ';
          selectSql += 'cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
          selectSql += 'AND cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
          selectSql += 'AND cumulative_transaction.client_id = ' + this.searchResultDispConditions.clientId + ' ';
          selectSql += 'AND cumulative_transaction.site_id = ' + this.itemList[i].SiteId + ' ';
          selectSql += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.searchResultDispConditions.dateBillingDateStart + '\' AND \'' + this.searchResultDispConditions.dateBillingDateEnd + '\' ';
          selectSql += 'AND cumulative_transaction.is_update_closing_date = 0 ';
          selectSql += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
          if (bulkInsertSqlSelect != '') {
            bulkInsertSqlSelect += 'UNION ALL ';
          }
          bulkInsertSqlSelect += selectSql;
          if (bulkInsertSqlSelect.length >= Const.SqlMaxLength) {
            sqlList.push(bulkInsertSqlStart + bulkInsertSqlSelect);
            bulkInsertSqlSelect = '';
          }
        }
      }
      if (bulkInsertSqlSelect != '') {
        sqlList.push(bulkInsertSqlStart + bulkInsertSqlSelect);
      }
      return sqlList;
    },
    // 請求書発行登録取消ボタンの押下
    async clickInvoiceIssueCancel() {
      const functionName = 'clickInvoiceIssueCancel';
      try {
        let confirmMessage = [];
        confirmMessage.push(this.$createElement('div','チェックされた得意先の請求書発行登録取消を行います。'));
        confirmMessage.push(this.$createElement('div','よろしいですか？'));
        if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: '請求書発行登録取消'}) == true) {
          this.$store.commit('setLoading', true);
          await this.execCancel();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 請求書発行登録取消処理
    async execCancel() {
      const functionName = 'execCancel';
      let sqlList = null;
      if (this.searchResultDispConditions.separateBillingClass == Const.SeparateBillingClass.billings) {
        if (await this.checkCancelBillingNo() == true) {
          sqlList = this.getCancelBillingNoSql();
        }
      } else {
        if (await this.checkCancelSite() == true) {
          sqlList = this.getCancelSiteSql();
        }
      }
      if (sqlList == null || sqlList.length == 0) {
        return;
      }
      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }
      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    // 請求書発行登録取消処理のチェック（伝票毎）
    async checkCancelBillingNo() {
      let clientId = this.searchResultDispConditions.clientId;
      let billingMonthYear = this.searchResultDispConditions.billingMonthYear.replace(/-/g,'');
      let siteIdList = [];
      for (let i = 0; i < this.checkedList.length; i++) {
        // チェックの入った行を取得
        let item = this.itemList.find(el => el.BillingNo == this.checkedList[i]);
        if (item != undefined) {
          if (siteIdList.findIndex(el => el == item.SiteId) == -1) {
            siteIdList.push(item.SiteId);
          }
        }
      }
      // 上流工程で請求書発行登録済みでないかチェック用（取引先内、現場内）
      let selectSql = 'SELECT';
      selectSql += ' (SELECT COUNT(*) FROM t_billings_issue_input WHERE ';
      selectSql += ' billing_month_year = ' + billingMonthYear + ' AND client_id = ' + clientId;
      selectSql += ' ) AS billings_issue_input_flg';
      selectSql += ',(SELECT COUNT(*) FROM t_billings_issue_input_site WHERE ';
      selectSql += ' billing_month_year = ' + billingMonthYear + ' AND client_id = ' + clientId + ' AND site_id IN (' + siteIdList.join(',') + ') ';
      selectSql += ' ) AS billings_issue_input_site_cnt';
      selectSql += ' FROM DUAL';
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      if (resultData[0].billings_issue_input_flg == 1) {
        // 請求書の発行登録がある場合（取引先単位）
        this.alertWarning.push(DISP_MESSAGES.WARNING['2068'].replace('%arg1%','取引先'));
        return false;
      } else if (resultData[0].billings_issue_input_site_cnt >= 1) {
        // 請求書の発行登録がある場合（現場単位）
        this.alertWarning.push(DISP_MESSAGES.WARNING['2068'].replace('%arg1%','現場'));
        return false;
      }
      return true;
    },
    // 請求書発行登録取消処理（伝票毎）のSQLを作成
    getCancelBillingNoSql: function() {
      let sqlList = [];
      let separateInvoiceNoList = [];
      for (let i = 0; i < this.checkedList.length; i++) {
        let item = this.itemList.find(el => el.BillingNo == this.checkedList[i]);
        if (item != undefined) {
          if (separateInvoiceNoList.findIndex(el => el == item.SeparateInvoiceNo) == -1) {
            separateInvoiceNoList.push(item.SeparateInvoiceNo);
          }
        }
      }
      for (let i = 0; i < this.itemList.length; i++) {
        if (separateInvoiceNoList.findIndex(el => el == this.itemList[i].SeparateInvoiceNo) >= 0) {
          // 更新対象の分割請求書番号の場合
          // 伝票日付の過去日チェック
          if (dateConsistency(this.controlMasterData.processMonthYear + '01', this.itemList[i].BillingDate) == false) {
            this.alertWarning.push(DISP_MESSAGES.WARNING['2017']);
            return [];
          }
        }
      }
      let deleteSql = 'DELETE FROM t_billings_issue_input_billing_no WHERE ';
      deleteSql += 'billing_month_year = ' + this.searchResultDispConditions.billingMonthYear.replace(/-/g,'') + ' ';
      deleteSql += 'AND separate_invoice_no IN (' + separateInvoiceNoList.join(',') + ')';
      sqlList.push(deleteSql);
      return sqlList;
    },
    // 請求書発行登録取消処理のチェック（現場毎）
    async checkCancelSite() {
      let clientId = this.searchResultDispConditions.clientId;
      let billingMonthYear = this.searchResultDispConditions.billingMonthYear.replace(/-/g,'');
      // 上流工程で請求書発行登録済みでないかチェック用（取引先内、現場内）
      let selectSql = 'SELECT';
      selectSql += ' (SELECT COUNT(*) FROM t_billings_issue_input WHERE ';
      selectSql += ' billing_month_year = ' + billingMonthYear + ' AND client_id = ' + clientId;
      selectSql += ' ) AS billings_issue_input_flg';
      selectSql += ' FROM DUAL';
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      if (resultData[0].billings_issue_input_flg == 1) {
        // 請求書の発行登録がある場合（取引先単位）
        this.alertWarning.push(DISP_MESSAGES.WARNING['2068'].replace('%arg1%','取引先'));
        return false;
      }
      return true;
    },
    // 請求書発行登録取消処理（現場毎）のSQLを作成
    getCancelSiteSql: function() {
      let sqlList = [];
      let siteIdList = [];
      for (let i = 0; i < this.checkedList.length; i++) {
        let item = this.itemList.find(el => el.SiteId == this.checkedList[i]);
        if (item != undefined) {
          if (siteIdList.findIndex(el => el == item.SiteId) == -1) {
            siteIdList.push(item.SiteId);
          }
        }
      }
      let deleteSql = 'DELETE FROM t_billings_issue_input_site WHERE ';
      deleteSql += 'billing_month_year = ' + this.searchResultDispConditions.billingMonthYear.replace(/-/g,'') + ' ';
      deleteSql += 'AND client_id = ' + this.searchResultDispConditions.clientId + ' ';
      deleteSql += 'AND site_id IN (' + siteIdList.join(',') + ')';
      sqlList.push(deleteSql);
      return sqlList;
    },
    // 売上伝票照会ボタン押下時
    async clickSalesSlipBtn(billingNo) {
      let route = this.$router.resolve({ name: 'SALES-SLIP-INQUIRY', query: { propsBillingNo: billingNo } });
      //console.log(this.$router);
      window.open(route.href, '_blank');
    },
  }
}
</script>
<style scoped>
</style>