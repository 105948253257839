<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row>
        <b-col cols="12">
          <!-- ●●●検索条件●●● -->
          <div class="my-2" id="accordion2" role="tablist">
            <b-card class="border">
              <b-card-header class="mb-0 p-2" role="tab" id="heading1">
                <h5 class="mb-0" data-toggle="tooltip" title="クリックすると請求書出力範囲表示/非表示できます。">
                  <a v-b-toggle.collapse-1 class="text-secondary text-body" style="display: block; float: left;" role="tablist" aria-expanded="true">
                    <span class="oi oi-magnifying-glass"></span> 請求書出力範囲
                  </a>
                  <b-button size="sm" pill variant="success" v-b-tooltip.hover.noninteractive.bottom="'請求書発行（現場・伝票毎）画面を別タブで表示します。'" @click="clickSeparateBtn" class="mx-2 float-right" style="font-size: 60%;">
                    <span class="oi oi-plus"></span> 請求書分割発行(F10)
                    <button v-shortkey="['f10']" @shortkey="clickSeparateBtn()" class="d-none"></button>
                  </b-button>
                </h5>
              </b-card-header>
              <br>
              <b-collapse id="collapse-1" visible>
                <b-card-body class="p-2">
                  <b-container>
                    <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                      <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                        <li>{{error}}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                      <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                        <li>{{error}}</li>
                      </ul>
                    </b-alert>
                    <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                      <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                        <li>{{error}}</li>
                      </ul>
                    </b-alert>
                    <!-- ●●●検索条件●●● -->
                    <validation-observer ref="observer">
                      <b-container>
                        <b-row>
                          <!-- 年月 -->
                          <b-col cols="3">
                            <validation-provider name="billingMonthYear" :rules="{required:true, regex:/^[0-9]{4}-(0[1-9]|1[0-2])$/}" v-slot="{ classes,errors }">
                              <b-form-group label="年月">
                                <b-row>
                                  <b-col lg="8" :class="classes">
                                    <b-form-input name="billingMonthYear" type="month" v-model="searchConditions.billingMonthYear" />
                                  </b-col>
                                  <b-col lg="8" :class="classes">
                                    <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                  </b-col>
                                </b-row>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <!-- 請求書出力単位区分入力欄 -->
                          <b-col lg="2">
                            <label for="billingOutputClass">請求書出力単位区分</label>
                            <b-form-select
                              id="billingOutputClass"
                              v-model="searchConditions.billingOutputClass"
                              :options="searchConditions.billingOutputClassList"
                              value-field="id"
                              text-field="name"
                            >
                            </b-form-select>
                          </b-col>
                          <!-- 請求書発行登録区分入力欄 -->
                          <b-col lg="2">
                            <label for="invoiceIssueClass">請求書発行登録区分</label>
                            <b-form-select
                              id="invoiceIssueClass"
                              v-model="searchConditions.invoiceIssueClass"
                              :options="searchConditions.invoiceIssueClassList"
                            >
                            </b-form-select>
                          </b-col>
                          <!-- WEB発行区分入力欄 -->
                          <b-col lg="3">
                            <label for="webIssueClass">WEB発行区分</label>
                            <b-form-select
                              id="webIssueClass"
                              v-model="searchConditions.webIssueClass"
                              :options="searchConditions.webIssueClassList"
                              value-field="id"
                            >
                            </b-form-select>
                          </b-col>
                          <!-- アップロード済チェック入力欄 -->
                          <b-col lg="2">
                            <label for="isSearchUploadCheck" v-b-tooltip.hover.noninteractive title="チェックONの場合、検索時にアップロード済み列を表示します。（検索時間は増加します。）">アップロード済 <span class="px-1 oi oi-flag"/></label>
                            <b-form-checkbox
                              id="isSearchUploadCheck"
                              name="isSearchUploadCheck"
                              v-model="searchConditions.isSearchUploadCheck"
                            ></b-form-checkbox>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 得意先コード -->
                          <b-col lg="7">
                            <b-form-group label="得意先コード">
                              <b-input-group>
                                <validation-provider name="clientIdStart" :rules="{numeric:true,consistency:searchConditions.clientIdEnd}" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <b-form-input name="clientIdStart" type="text" v-model.number="searchConditions.clientIdStart" />
                                        <b-input-group-text @click="showClientSearchModal(1);" v-b-tooltip.hover.noninteractive title="「ALT+1」ボタンで呼出可能">
                                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                            <span class="oi oi-magnifying-glass"></span>
                                            <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(1);" class="d-none"></button>
                                          </b-button>
                                        </b-input-group-text>
                                      </b-input-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                                <b-input-group-text>～</b-input-group-text>
                                <validation-provider name="clientIdEnd" rules="numeric" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-input-group>
                                        <b-form-input name="clientIdEnd" type="text" v-model.number="searchConditions.clientIdEnd" />
                                        <b-input-group-text @click="showClientSearchModal(2);" v-b-tooltip.hover.noninteractive title="「ALT+2」ボタンで呼出可能">
                                          <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                            <span class="oi oi-magnifying-glass"></span>
                                            <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(2);" class="d-none"></button>
                                          </b-button>
                                        </b-input-group-text>
                                      </b-input-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                              </b-input-group>
                              <b-form-text class="text-muted">得意先の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                          <!-- 請求書発行方法 -->
                          <b-col lg="3">
                            <b-form-group label-for="selectInvoiceIssueWay" label="請求書発行方法">
                              <b-form-select
                                id="selectInvoiceIssueWay"
                                v-model="searchConditions.selectInvoiceIssueWay"
                                :options="searchConditions.invoiceIssueWayList"
                                value-field="id"
                              >
                              </b-form-select>
                            </b-form-group>
                            <b-form-text class="text-muted" v-b-tooltip.hover.noninteractive title="請求一覧表（現場毎・伝票毎）で先行的に発行登録をした取引先かどうかを示す区分。">請求一覧表（現場毎・伝票毎）で先行的...</b-form-text>
                          </b-col>
                        </b-row>
                        <b-row>
                          <!-- 現場コード -->
                          <b-col lg="7">
                            <b-form-group label="現場コード">
                              <b-input-group>
                                <validation-provider name="siteIdStart" :rules="{numeric:true,consistency:searchConditions.siteIdEnd}" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input name="siteIdStart" type="text" v-model.number="searchConditions.siteIdStart" :readonly="searchConditions.billingOutputClass!=1"/>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                                <b-input-group-text>～</b-input-group-text>
                                <validation-provider name="siteIdEnd" rules="numeric" v-slot="{ classes,errors }">
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <b-form-input name="siteIdEnd" type="text" v-model.number="searchConditions.siteIdEnd" :readonly="searchConditions.billingOutputClass!=1"/>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="12" :class="classes">
                                      <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                    </b-col>
                                  </b-row>
                                </validation-provider>
                              </b-input-group>
                              <b-form-text class="text-muted">請求書出力単位区分が「現場別」の場合に有効となります。<br>現場の範囲を入力してください。空白の場合は全範囲が設定されます。</b-form-text>
                            </b-form-group>
                          </b-col>
                          <!-- 請求一覧計算区分 -->
                          <b-col lg="4" v-if="searchConditions.selectInvoiceIssueWay==htmlConst.InvoiceIssueWay.all||searchConditions.selectInvoiceIssueWay==htmlConst.InvoiceIssueWay.partIssue">
                            <b-form-group label-for="selectInvoiceListCalcClass" label="請求一覧計算区分">
                              <b-form-select
                                id="selectInvoiceListCalcClass"
                                v-model="searchConditions.selectInvoiceListCalcClass"
                                :options="searchConditions.invoiceListCalcClassList"
                                value-field="id"
                              >
                              </b-form-select>
                            </b-form-group>
                            <b-form-text class="text-muted">請求一覧表の計算にどの合計値を用いるかを示す区分。</b-form-text>
                          </b-col>
                        </b-row>
                      </b-container>
                    </validation-observer>
                    <!-- 検索ボタン -->
                    <b-row class="justify-content-md-center my-2">
                      <b-col lg="3">
                        <b-button block pill variant="success" @click="clearAlert(); searchButton();">
                          <span class="oi oi-magnifying-glass"></span> 検 索 
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <!-- ●●●検索条件●●● -->
        </b-col>
      </b-row>
      <!-- ●●●検索結果●●● -->
      <b-card id="resultArea">
        <b-card-header>
          <b-row class="justify-content-md-center">
            <b-col lg="7">
              <b-input-group>
                <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="一覧の全得意先の請求一覧表のPDF出力を行います。" @click="clickOutputBillingListPdf" :disabled="totalRows==0">
                  <span class="oi oi-document"></span> 請求一覧表PDF出力
                </b-button>
                <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="一覧のチェックONの得意先の請求書のPDF出力を行います。（最大200件として下さい。）" @click="clearAlert(); clickOutputBillingPdfTab();" :disabled="checkedClientIds.length==0">
                  <span class="oi oi-document"></span> 請求書PDF出力
                </b-button>
                <label for="isUploadCheck" v-b-tooltip.hover.noninteractive title="チェックONの場合、請求書PDF出力時に電子書類としてアップロードするかチェックします。">アップロードチェック <span class="px-1 oi oi-flag"/></label>
                <b-form-checkbox
                  id="isUploadCheck"
                  v-model="isUploadCheck"
                ></b-form-checkbox>
              </b-input-group>
            </b-col>
            <b-col lg="5">
              <b-input-group>
                <template #prepend>
                  <label for="invoiceIssueInputDate">
                    <b-input-group-text  class="px-1" v-b-tooltip.hover.noninteractive title='請求書発行登録ボタン押下時の登録日です。'>
                      <strong class='text-body'>請求書発行登録日 <span class="oi oi-flag"/></strong>
                    </b-input-group-text>
                  </label>
                </template>
                <b-form-datepicker
                  id="invoiceIssueInputDate"
                  name="invoiceIssueInputDate"
                  v-model="invoiceIssueInputDate"
                  calendar-width="50%"
                  :reset-button="false"
                ></b-form-datepicker>
              </b-input-group>
              <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="一覧のチェックONの得意先の請求書の発行登録を行います。（登録済みの得意先の更新はしません。更新したい場合は取消後に再登録して下さい。）" @click="clearAlert(); clickInvoiceIssueInput();" :disabled="checkedClientIds.length==0||editFlg==false">
                <span class="oi oi-circle-check"></span> 請求書発行登録
              </b-button>
              <b-button pill size="sm" variant="danger" v-b-tooltip.hover.noninteractive title="一覧のチェックONの得意先の請求書の発行登録を取り消します。" @click="clearAlert(); clickInvoiceIssueCancel();" :disabled="checkedClientIds.length==0||editFlg==false">
                <span class="oi oi-delete"></span> 請求書発行登録取消
              </b-button>
            </b-col>
          </b-row>
          <b-alert show variant="warning" v-if="resultError.length">
            <ul v-for="(error,index) in resultError" :key="index" style="list-style: none;">
              <li>{{error}}</li>
            </ul>
          </b-alert>
        </b-card-header>
        <b-card-body>
          <b-col>
            <b-row>
              <!-- 1ページあたりの表示選択 -->
              <b-col  lg="6" class="my-1">
                <b-form-group
                  label="1ページあたりの表示件数"
                  label-for="per-page-select"
                  label-cols-sm="5"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <!-- 検索結果検索 -->
              <b-col lg="6" class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- 検索結果 -->
            <b-row>
              <b-table
                show-empty
                :items="itemList"
                :fields="fields"
                :busy="busy"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                :small="true"
                @filtered="onFiltered"
              >
                <!-- テーブル読み込み時表示html -->
                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>読み込んでいます...</strong>
                  </div>
                </template>
                <template #head(check)>
                  <b-check v-model="isAllChecked" @change="clickCheckAll" :disabled="totalCheckCnt==0" />
                </template>
                <template #head(isUpload)>アップ<br>ロード済</template>
                <template #head(invoiceIssueInput)>請求書<br>発行登録日</template>
                <template #head(invoiceIssueWayText)>請求書<br>発行方法</template>
                <template #head(salesUnitAmount)>売上額税抜<br>消費税<br>合計</template>
                <template #head(billingMoney)>今回請求税抜<br>消費税<br>合計</template>
                <template #head(preBillingMoney)>1回前請求額<br>2回前請求額<br>3回前請求額</template>
                <template #head(payment)>入金額税抜<br>消費税<br>合計</template>
                <template #head(forward)>繰越額税抜<br>消費税<br>合計</template>
                <template #cell(check)="data">
                  <b-check v-if="data.item.billingOutputClass==searchResultDispConditions.billingOutputClass&&data.item.invoiceIssueWay==searchResultDispConditions.selectInvoiceIssueWay" v-model="checkedClientIds" :value="data.item.clientId" @change="changeCheck"/>
                </template>
                <template #cell(isUpload)="data">
                  {{ data.item.isUpload == true ? '★' : '' }}
                </template>
                <template #cell(billingOutputClass)="data">
                  {{ billingOutputClassVal(data.item.billingOutputClass.toString()) }}<br/>
                </template>
                <template #cell(salesUnitAmount)="data">
                  {{ data.item.productSalesUnitAmount.toLocaleString() }}<br/>
                  {{ data.item.productTax.toLocaleString() }}<br/>
                  {{ (data.item.productSalesUnitAmount + data.item.productTax).toLocaleString() }}
                </template>
                <template #cell(billingMoney)="data">
                  {{ (data.item.productSalesUnitAmount + (data.item.billingClass == htmlConst.BillingClass.output ? (data.item.preBillingNoTaxBalance - data.item.amountNoTax): 0)).toLocaleString() }}<br>
                  {{ (data.item.productTax + (data.item.billingClass == htmlConst.BillingClass.output ? (data.item.preBillingTaxBalance - data.item.amountTax): 0)).toLocaleString() }}<br>
                  {{ (data.item.productSalesUnitAmount + data.item.productTax + (data.item.billingClass == htmlConst.BillingClass.output ? ((data.item.preBillingNoTaxBalance - data.item.amountNoTax) + (data.item.preBillingTaxBalance - data.item.amountTax)): 0)).toLocaleString() }}<br>
                </template>
                <template #cell(preBillingMoney)="data">
                  {{ (data.item.preBillingNoTaxBalance + data.item.preBillingTaxBalance).toLocaleString() }}<br>
                  {{ (data.item.twoPreBillingMoneyNoTax + data.item.twoPreBillingMoneyTax).toLocaleString() }}<br>
                  {{ (data.item.threePreBillingMoneyNoTax + data.item.threePreBillingMoneyTax).toLocaleString() }}
                </template>
                <template #cell(payment)="data">
                  {{ data.item.amountNoTax.toLocaleString() }}<br>
                  {{ data.item.amountTax.toLocaleString() }}<br>
                  {{ (data.item.amountNoTax + data.item.amountTax).toLocaleString() }}
                </template>
                <template #cell(forward)="data">
                  {{ (data.item.preBillingNoTaxBalance - data.item.amountNoTax).toLocaleString() }}<br>
                  {{ (data.item.preBillingTaxBalance - data.item.amountTax).toLocaleString() }}<br>
                  {{ ((data.item.preBillingNoTaxBalance - data.item.amountNoTax) + (data.item.preBillingTaxBalance - data.item.amountTax)).toLocaleString() }}
                </template>
                <!-- 操作列 -->
                <template #cell(operation)="data">
                  <!-- 取引先保守ボタン -->
                  <b-button size="sm" v-b-tooltip.hover.noninteractive.left="'顧客名の変更を行います。'" @click="clickClientsMasterBtn(data.item.clientId)" class="mr-1">
                    <span class="oi oi-pencil"></span> 取引先保守
                  </b-button>
                </template>
              </b-table>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group
                  :label="getPagingMessage"
                  class="mt-0 mb-0"
                />
              </b-col>
            </b-row>
            <!-- テーブルページネーション -->
            <b-col class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="filter != null ? filterRows : totalRows"
                :per-page="perPage == -1 ? totalRows : perPage"
                align="center"
                class="my-0"
              ></b-pagination>
            </b-col>
          </b-col>
        </b-card-body>
      </b-card>
    </b-container>
    <Footer />
    <!-- ●●●取引先検索モーダル●●● -->
    <CLIENTSEARCH @from-child="closeClientSearchModal" :client-class="propClientClass"/>
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTSEARCH from '@/components/modal/client-search.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { init, formatDate, formatCurDate, getListValue, executeSelectSql, addOperationLogs, getControlMaster, getClosingDate, CreateColRow, CreateInsertSql, isSystemEditable, executeTransactSqlList, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';

const MODULE_NAME = 'invoice-list';

export default {
  name: 'INVOICE-LIST',
  /* コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTSEARCH,
  },
  /* データ */
  data() {
    return {
      /* ヘッダメニュー種別 */
      menu_type: 'user',
      /* ヘッダタイトル */
      baseTitle: '請求一覧表',
      title: '',
      loginId: '',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      resultError: [],
      /* 検索項目 */
      searchConditions: {
        /* 請求年月 */
        billingMonthYear: '',
        /* 取引先コード（開始） */
        clientIdStart: '',
        /* 取引先コード（終了） */
        clientIdEnd: '',
        // 取引先名
        clientName: '',
        // 請求書出力単位区分
        billingOutputClass: '',
        billingOutputClassList: [],
        // 請求書発行登録区分
        invoiceIssueClass: Const.InvoiceIssueClass.all,
        invoiceIssueClassList: Const.InvoiceIssueClassList,
        // WEB発行区分
        webIssueClass: '',
        webIssueClassList: [],
        // 請求書発行方法
        selectInvoiceIssueWay: Const.InvoiceIssueWay.all,
        invoiceIssueWayList: Const.InvoiceIssueWayList,
        // 請求一覧計算区分
        selectInvoiceListCalcClass: Const.InvoiceListCalcClass.all,
        invoiceListCalcClassList: Const.InvoiceListCalcClassList,
        /* 現場コード（開始） */
        siteIdStart: '',
        /* 現場コード（終了） */
        siteIdEnd: '',
        // アップロード済チェック
        isSearchUploadCheck: false,
      },
      /* 検索結果表示時に指定された検索項目（帳票出力時の引数にはこちらを使用） */
      searchResultDispConditions:{
        /* 請求年月 */
        billingMonthYear: '',
        /* 取引先コード（開始） */
        clientIdStart: '',
        /* 取引先コード（終了） */
        clientIdEnd: '',
        // 請求書出力単位区分
        billingOutputClass: '',
        propBillingOutputClass: '',
        // 請求書発行登録区分
        invoiceIssueClass: Const.InvoiceIssueClass.all,
        // WEB発行区分
        webIssueClass: '',
        // 請求書発行方法
        selectInvoiceIssueWay: Const.InvoiceIssueWay.all,
        propInvoiceIssueWay: Const.InvoiceIssueWay.all,
        // 請求一覧計算区分
        selectInvoiceListCalcClass: Const.InvoiceListCalcClass.all,
        /* 現場コード（開始） */
        siteIdStart: '',
        /* 現場コード（終了） */
        siteIdEnd: '',
        // アップロード済チェック
        isSearchUploadCheck: false,
      },
      // 請求書発行登録日
      invoiceIssueInputDate: formatCurDate('YYYY-MM-DD'),
      // 取引先モーダル用の引数
      propClientClass: Const.ClientClass.customer,
      // モーダルからの戻り値を反映先
      openModalIndex: 0,
      // 検索結果
      itemList: [],
      fields: [],
      busy: false,
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // 表示データの総件数
      totalRows: 0,
      // 印刷チェックボックスの総件数
      totalCheckCnt: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // フィルタリングデータの総件数
      filterRows: 0,
      /* 全選択チェックボックス */
      isAllChecked:false,
      /* 各行のチェック状態 */
      checkedClientIds: [],
      // 定数（htmlで使用）
      htmlConst: {
        // 請求書区分
        BillingClass: {
          // 出力する
          output: Const.BillingClass.output,
          // 出力しない
          noOutput: Const.BillingClass.noOutput,
        },
        // 請求書発行方法
        InvoiceIssueWay: {
          // 全て
          all: Const.InvoiceIssueWay.all,
          // 全伝票同時発行取引先
          allIssue: Const.InvoiceIssueWay.allIssue,
          // 一部伝票先行発行取引先
          partIssue: Const.InvoiceIssueWay.partIssue,
        },
      },
      // 請求前情報
      preBilling: {
        processMonthYear: 0,
        closingDate: 0,
        billingStartDate: '',
        billingEndDate: '',
      },
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
        invoiceInputNo: '',
      },
      // パラメータ
      billingPaymentClass: 0,
      // テーブル名
      billingsTableName: '',
      // タイトル用の固定値
      addTitle1: '（本社）',
      addTitle2: '２（横浜）',
      // 変更フラグ
      editFlg: false,
      // 請求書先行発行テキスト
      textSeparateIssue: '一部先行',
      // アップロードチェック
      isUploadCheck: false,
    }
  },
  /* マウント */
  mounted() {
    init(); // common.jsにて初期化処理
    // ログインユーザーの情報(LoginID)を取得
    let user = store.getters.user;
    this.loginId = user.username;
    // パラメータ取得
    if (this.$route.query.billingPaymentClass == Const.BillingPaymentClass.billing2) {
      this.billingPaymentClass = Const.BillingPaymentClass.billing2;
      this.title = this.baseTitle + this.addTitle2;
      this.billingsTableName = 'm_billings2';
    } else {
      this.billingPaymentClass = Const.BillingPaymentClass.billing;
      this.title = this.baseTitle + this.addTitle1;
      this.billingsTableName = 'm_billings';
    }
    // 請求書出力区分リスト作成
    this.searchConditions.billingOutputClassList.push({id: '', name: '全て'});
    for (let i = 0; i < Const.billingOutputClassList.length; i++) {
      this.searchConditions.billingOutputClassList.push(Const.billingOutputClassList[i]);
    }
    // WEB発行区分
    this.searchConditions.webIssueClassList.push({id: '', text: '全て'});
    for (let i = 0; i < Const.WebIssueClassList.length; i++) {
      this.searchConditions.webIssueClassList.push(Const.WebIssueClassList[i]);
    }
    // 帳票のbodyタグのスタイル、center制定後、leftに戻す
    document.body.style['text-align'] = 'left';
    this.$store.commit('setLoading', false);
  },
  computed: {
    /* 現場モーダルのパラメータ用 */
    siteSearchProp: function() {
      return {
        client_id: this.searchConditions.clientIdStart,
        client_name: this.searchConditions.clientName,
      };
    },
    /* ページの表示件数 */
    getPagingMessage: function() {
      let tableLength = 0;
      if (this.filter != null) {
        tableLength = this.filterRows;
      } else {
        tableLength = this.totalRows;
      }
      let ret = '';
      if (tableLength == 0) {
        ret = '';
      } else {
        ret += tableLength + ' 件中 ';
        if (this.currentPage==1 || this.perPage == -1) {
          ret += '1';
        } else {
          ret += ((this.currentPage * this.perPage - this.perPage) + 1).toString();
        }
        ret += ' から ';
        if ((tableLength <= ((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1) ||
          this.perPage == -1) {
          ret += tableLength.toString();
        } else {
          ret += (((this.currentPage * this.perPage - this.perPage) + 1) + this.perPage - 1).toString();
        }
        ret += ' まで表示';
      }
      return ret;
    },
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertSuccess.length > 0 || this.alertWarning.length > 0 || this.alertDanger.length > 0
    },
  },
  methods: {
    // 取引先検索
    showClientSearchModal:function(index) {
      //console.log('取引先検索モーダル');
      this.openModalIndex = index;
      this.$bvModal.show('clientSearchModal');
    },
    // 取引先検索モーダルを閉じた時
    closeClientSearchModal:function(clientItems) {
      // モーダルから渡された値の有無チェック
      if(typeof clientItems != 'undefined') {
        if (this.openModalIndex == 1) {
          this.searchConditions.clientIdStart = clientItems.detail.client_id;
        } else {
          this.searchConditions.clientIdEnd = clientItems.detail.client_id;
        }
      }
    },
    /* 検索ボタン押下時 */
    async searchButton() {
      const functionName = 'searchButton'
      try {
        const observer = this.$refs.observer;
        const success = await observer.validate();
        if (!success) {
          const el = document.querySelector('#error:first-of-type');
          el.scrollIntoView({ block: 'center', inline: 'nearest' });
        }else{
          this.$store.commit('setLoading', true);
          await this.search();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.$store.commit('setLoading', false);
    },
    /* 検索条件を保存 */
    saveSearchConditions: function() {
      this.searchResultDispConditions.billingMonthYear = this.searchConditions.billingMonthYear;
      this.searchResultDispConditions.clientIdStart = this.searchConditions.clientIdStart;
      this.searchResultDispConditions.clientIdEnd = this.searchConditions.clientIdEnd;
      if (this.searchConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
        // 検索条件「現場別」を選択している場合は「現場別」
        this.searchResultDispConditions.billingOutputClass = Const.billingOutputClass.clientsSites;
      } else {
        // 検索条件「現場別」以外の場合は「取引先別」とする
        this.searchResultDispConditions.billingOutputClass = Const.billingOutputClass.clients;
      }
      this.searchResultDispConditions.propBillingOutputClass = this.searchConditions.billingOutputClass;
      this.searchResultDispConditions.invoiceIssueClass = this.searchConditions.invoiceIssueClass;
      this.searchResultDispConditions.webIssueClass = this.searchConditions.webIssueClass;
      if (this.searchConditions.selectInvoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
        this.searchResultDispConditions.selectInvoiceIssueWay = Const.InvoiceIssueWay.partIssue;
      } else {
        this.searchResultDispConditions.selectInvoiceIssueWay = Const.InvoiceIssueWay.allIssue;
      }
      this.searchResultDispConditions.propInvoiceIssueWay = this.searchConditions.selectInvoiceIssueWay;
      this.searchResultDispConditions.selectInvoiceListCalcClass = this.searchConditions.selectInvoiceListCalcClass;
      this.searchResultDispConditions.siteIdStart = this.searchConditions.siteIdStart;
      this.searchResultDispConditions.siteIdEnd = this.searchConditions.siteIdEnd;
      this.searchResultDispConditions.isSearchUploadCheck = this.searchConditions.isSearchUploadCheck;
      // 編集フラグを初期化
      if (this.controlMasterData.processMonthYear <= Number(this.searchResultDispConditions.billingMonthYear.replace('-', ''))) {
        this.editFlg = true;
      } else {
        this.editFlg = false;
      }
    },
    /* 検索処理 */
    async search() {
      const functionName = 'search';
      if (this.busy == true) {
        return;
      }
      this.busy = true;
      try {
        this.isUploadCheck = false;
        this.itemList = [];
        // ページング機能の初期化
        this.initPaging();
        // フィールドを機能選択と合わせる
        this.fields = this.getFields();
        // 請求日（開始日と終了日）の取得
        await this.getBillingDateInfo();
        // CRUD処理
        let selectSql = '';
        selectSql = await this.makeSelectSql();
        //console.log(selectSql);
        let resultData = await executeSelectSql(selectSql);
        if (resultData == null) {
          resultData = [];
        }
        await this.setTotalData(resultData);
        if (resultData != null) {
          this.saveSearchConditions();
          await this.setResult(resultData);
          //console.log(resultData);
        } else {
          // 総件数をdataTableの総件数にセット
          this.totalRows = 0;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.resultError.push(DISP_MESSAGES.WARNING['2001']);
      }
      this.busy = false;
    },
    /* 取得結果セット */
    async setResult(result) {
      // 現場数取得用
      let csvClientId = '';
      // アップロード済取得用
      let clientIdListClients = [];
      let clientIdListSites = [];
      // 印刷チェックを/初期化
      this.checkedClientIds = [];
      this.totalCheckCnt = 0;
      // 総件数をdataTableの総件数にセット
      for (let i = 0; i < result.length; i++) {
        let searchResult = {
          clientId: result[i].client_id,
          clientNameKanji: result[i].client_name_kanji,
          invoiceIssueInput: result[i].billing_issue_input_date == null ? '' : formatDate(result[i].billing_issue_input_date),
          rowInvoiceIssueClass: result[i].billing_issue_input_date == null ? Const.InvoiceIssueClass.noIssue : Const.InvoiceIssueClass.issue,
          invoiceIssueWay: result[i].invoice_issue_way,
          invoiceIssueWayText: result[i].invoice_issue_way == Const.InvoiceIssueWay.partIssue ? this.textSeparateIssue : '',
          billingOutputClass: result[i].billing_output_class,
          staffId: result[i].staff_id,
          staffNameNameKanji: result[i].staff_name_kanji,
          productSalesUnitAmount: Number(result[i].product_sales_unit_amount),
          productTax: Number(result[i].product_tax),
          preBillingNoTaxBalance: Number(result[i].pre_billing_no_tax_balance),
          preBillingTaxBalance: Number(result[i].pre_billing_tax_balance),
          twoPreBillingMoneyNoTax: Number(result[i].two_pre_billing_money_no_tax),
          twoPreBillingMoneyTax: Number(result[i].two_pre_billing_money_tax),
          threePreBillingMoneyNoTax: Number(result[i].three_pre_billing_money_no_tax),
          threePreBillingMoneyTax: Number(result[i].three_pre_billing_money_tax),
          amountNoTax: Number(result[i].amount_no_tax),
          amountTax: Number(result[i].amount_tax),
          paymentScheduled: formatDate(result[i].payment_scheduled),
          businessClass: getListValue(Const.businessClassList, result[i].business_class.toString()),
          billingClass: result[i].billing_class,
          operation: result[i],
        };
        if (this.searchConditions.selectInvoiceIssueWay == Const.InvoiceIssueWay.allIssue) {
          // 全伝票同時発行取引先
          if (result[i].invoice_issue_way == Const.InvoiceIssueWay.partIssue) {
            // 先行発行した取引先は除外
            continue;
          }
        } else if (this.searchConditions.selectInvoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
          // 一部伝票先行発行取引先
          if (result[i].invoice_issue_way == Const.InvoiceIssueWay.allIssue) {
            // 先行発行した取引先以外を除外
            continue;
          }
        }
        // 現場別の場合のみ取引先コードのCSV作成と現場数の初期値設定
        if (this.searchResultDispConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
          searchResult.siteCnt = 0;
          if (csvClientId != '') {
            csvClientId += ',';
          }
          csvClientId += result[i].client_id;
        }
        // アップロード済チェックONの場合のみ取引先コードリスト（取引先別・現場別）作成
        if (this.searchResultDispConditions.isSearchUploadCheck == true) {
          if (result[i].billing_output_class == Const.billingOutputClass.clientsSites) {
            // 現場別の取引先の場合
            clientIdListSites.push(result[i].client_id);
          } else {
            // 取引先別の取引先の場合
            clientIdListClients.push(result[i].client_id);
          }
        }
        this.itemList.push(searchResult);
        // チェックを保持する配列に取引先コードを入れる（請求書出力区分が検索条件と等しい場合）
        if (result[i].billing_output_class == this.searchResultDispConditions.billingOutputClass &&
        result[i].invoice_issue_way==this.searchResultDispConditions.selectInvoiceIssueWay) {
          this.checkedClientIds.push(result[i].client_id);
          this.totalCheckCnt++;
        }
      }
      this.totalRows = this.itemList.length;
      // 現場数設定、または、アップロード済設定を実施判定
      if (csvClientId != '' && (clientIdListClients.length > 0 || clientIdListSites.length > 0)) {
        // 現場数設定、アップロード済設定を両方実行
        await Promise.all([
          this.setSiteCnt(csvClientId),
          this.setIsUpload(clientIdListClients, clientIdListSites)
        ]);
      } else if (csvClientId != '') {
        // 現場数設定のみを実行
        await this.setSiteCnt(csvClientId);
      } else if (clientIdListClients.length > 0 || clientIdListSites.length > 0) {
        // アップロード済設定のみを実行
        await this.setIsUpload(clientIdListClients, clientIdListSites);
      }
      //console.log(this.itemList);
      // 全チェックをON
      this.isAllChecked = true;
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' billings_QUERY.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',billings_QUERY.product_sales_unit_amount';
      selectSql += ',billings_QUERY.closing_date_normal_tax + billings_QUERY.closing_date_light_tax AS product_tax';
      selectSql += ',billings_QUERY.pre_billing_no_tax_balance';
      selectSql += ',billings_QUERY.pre_billing_tax_balance';
      selectSql += ',billings_QUERY.two_pre_billing_money_no_tax';
      selectSql += ',billings_QUERY.two_pre_billing_money_tax';
      selectSql += ',billings_QUERY.three_pre_billing_money_no_tax';
      selectSql += ',billings_QUERY.three_pre_billing_money_tax';
      selectSql += ',billings_QUERY.amount_no_tax';
      selectSql += ',billings_QUERY.amount_tax';
      selectSql += ',IfNull(billings_QUERY.payment_scheduled,';
      selectSql += '   CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '     THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '     ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   END';
      selectSql += ' ) AS payment_scheduled';
      selectSql += ',clients.business_class';
      selectSql += ',clients.billing_class';
      selectSql += ',IfNull(billings_QUERY.billing_output_class,clients.billing_output_class) AS billing_output_class';
      selectSql += ',billings_issue_input.billing_issue_input_date';
      selectSql += ',billings_QUERY.closing_date_flg';
      selectSql += ',CASE WHEN billings_issue_input_billing_no.client_id IS NULL AND billings_issue_input_site.client_id IS NULL THEN ' + Const.InvoiceIssueWay.allIssue + ' ELSE ' + Const.InvoiceIssueWay.partIssue + ' END AS invoice_issue_way';
      // FROM句
      selectSql += ' FROM ';
      selectSql += '(' + await this.makeMainQuery() + ') AS billings_QUERY ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND billings_QUERY.client_id = clients.client_id ';
      if (this.searchConditions.billingOutputClass != '') {
        selectSql += 'AND IfNull(billings_QUERY.billing_output_class,clients.billing_output_class) = ' + this.searchConditions.billingOutputClass + ' ';
      }
      if (this.searchConditions.webIssueClass != '') {
        selectSql += 'AND clients.web_issue_class = ' + this.searchConditions.webIssueClass + ' ';
      }
      if (this.searchConditions.invoiceIssueClass == Const.InvoiceIssueClass.issue) {
        selectSql += 'INNER JOIN t_billings_issue_input AS billings_issue_input ';
      } else {
        selectSql += 'LEFT JOIN t_billings_issue_input AS billings_issue_input ';
      }
      selectSql += 'ON billings_issue_input.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND billings_QUERY.client_id = billings_issue_input.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON clients.staff_id = staffs.staff_id ';
      selectSql += 'LEFT JOIN (SELECT client_id FROM t_billings_issue_input_billing_no WHERE ';
      selectSql += 'billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'GROUP BY client_id';
      selectSql += ') AS billings_issue_input_billing_no ';
      selectSql += 'ON billings_QUERY.client_id = billings_issue_input_billing_no.client_id ';
      selectSql += 'LEFT JOIN (SELECT client_id FROM t_billings_issue_input_site WHERE ';
      selectSql += 'billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'GROUP BY client_id';
      selectSql += ') AS billings_issue_input_site ';
      selectSql += 'ON billings_QUERY.client_id = billings_issue_input_site.client_id ';
      // WHERE句
      if (this.searchConditions.invoiceIssueClass == Const.InvoiceIssueClass.noIssue) {
        selectSql += ' WHERE ';
        selectSql += ' billings_issue_input.billing_month_year IS NULL ';
      }
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' billings_QUERY.client_id ';

      return selectSql;
    },
    // 現場数設定
    async setSiteCnt(csvClientId) {
      let selectSql = '';
      selectSql = await this.makeSelectSqlSiteCnt(csvClientId);
      //console.log(selectSql);
      let resultData = await executeSelectSql(selectSql);
      //console.log(resultData);
      if (resultData != null && resultData.length > 0) {
        for (let i = 0; i < resultData.length; i++) {
          let data = this.itemList.find(el => el.clientId == resultData[i].client_id);
          if (data != undefined) {
            data.siteCnt = resultData[i].site_cnt;
          }
        }
      }
    },
    // アップロード済設定
    async setIsUpload(clientIdListClients, clientIdListSites) {
      let selectSqlClients = '';
      let selectSqlSites = '';
      let resultDataClients = null;
      let resultDataSites = null;
      if (clientIdListClients.length > 0) {
        selectSqlClients = await this.makeSelectSqlUpload(true, clientIdListClients);
      }
      if (clientIdListSites.length > 0) {
        selectSqlSites = await this.makeSelectSqlUpload(false, clientIdListSites);
      }
      //console.log(selectSqlClients);
      //console.log(selectSqlSites);
      if (selectSqlClients != '' && selectSqlSites != '') {
        // 取引先別、現場別の両方がある場合
        [resultDataClients, resultDataSites] = await Promise.all([
          executeSelectSql(selectSqlClients),
          executeSelectSql(selectSqlSites)
        ]);
      } else if (selectSqlClients != '') {
        // 取引先別のみの場合
        resultDataClients = await executeSelectSql(selectSqlClients);
      } else {
        // 現場別のみの場合
        resultDataSites = await executeSelectSql(selectSqlSites);
      }
      //console.log(resultDataClients);
      //console.log(resultDataSites);
      if (resultDataClients != null && resultDataClients.length > 0) {
        for (let i = 0; i < resultDataClients.length; i++) {
          let data = this.itemList.find(el => el.clientId == resultDataClients[i].client_id && el.billingOutputClass == Const.billingOutputClass.clients);
          if (data != undefined) {
            data.isUpload = true;
          }
        }
      }
      if (resultDataSites != null && resultDataSites.length > 0) {
        for (let i = 0; i < resultDataSites.length; i++) {
          let data = this.itemList.find(el => el.clientId == resultDataSites[i].client_id && el.billingOutputClass == Const.billingOutputClass.clientsSites);
          if (data != undefined) {
            data.isUpload = true;
          }
        }
      }
    },
    // メインクエリ作成
    async makeMainQuery() {
      /* 請求締更新前の処理月情報あり、または、なし、当月情報あり（取引先別） */
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      selectSql1 += ',0 AS pre_billing_no_tax_balance';
      selectSql1 += ',0 AS pre_billing_tax_balance';
      selectSql1 += ',0 AS two_pre_billing_money_no_tax';
      selectSql1 += ',0 AS two_pre_billing_money_tax';
      selectSql1 += ',0 AS three_pre_billing_money_no_tax';
      selectSql1 += ',0 AS three_pre_billing_money_tax';
      selectSql1 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN 0 ELSE cumulative_transaction.amount END) AS amount_no_tax';
      selectSql1 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN cumulative_transaction.amount ELSE 0 END) AS amount_tax';
      selectSql1 += ',NULL AS payment_scheduled';
      selectSql1 += ',' + Const.BillingOutputClass.client + ' AS billing_output_class';
      selectSql1 += ',1 AS closing_date_flg ';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += this.billingsTableName + ' AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0 OR cumulative_transaction.product_tax <> 0 OR cumulative_transaction.amount <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\',\'' + Const.TransactionId.deposit + '\') ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      selectSql1 += 'billings.closing_date = ' + this.preBilling.closingDate + ' ';
      // 得意先コード
      if (this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd == '') {
        selectSql1 += 'AND billings.client_id >= ' + this.searchConditions.clientIdStart + ' ';
      } else if(this.searchConditions.clientIdStart == '' && this.searchConditions.clientIdEnd != '') {
        selectSql1 += 'AND billings.client_id <= ' + this.searchConditions.clientIdEnd + ' ';
      } else if(this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd != '') {
        selectSql1 += 'AND billings.client_id BETWEEN ' + this.searchConditions.clientIdStart + ' ' + 'AND ' + this.searchConditions.clientIdEnd + ' ';
      }
      // 請求書出力単位区分
      selectSql1 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.client + ' ';
      // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外（入金は除外しない）
      selectSql1 += 'AND (billings_issue_input_billing_no.billing_no IS NULL OR cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\') ';
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings.client_id';
      /* 当月情報あり（現場別） */
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT ';
      selectSql2 += ' billings_site_QUERY.client_id';
      selectSql2 += ',SUM(billings_site_QUERY.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_normal_tax) AS closing_date_normal_tax';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_light_tax) AS closing_date_light_tax';
      selectSql2 += ',0 AS pre_billing_no_tax_balance';
      selectSql2 += ',0 AS pre_billing_tax_balance';
      selectSql2 += ',0 AS two_pre_billing_money_no_tax';
      selectSql2 += ',0 AS two_pre_billing_money_tax';
      selectSql2 += ',0 AS three_pre_billing_money_no_tax';
      selectSql2 += ',0 AS three_pre_billing_money_tax';
      selectSql2 += ',SUM(billings_site_QUERY.amount_no_tax) AS amount_no_tax';
      selectSql2 += ',SUM(billings_site_QUERY.amount_tax) AS amount_tax';
      selectSql2 += ',NULL AS payment_scheduled';
      selectSql2 += ',' + Const.BillingOutputClass.clientSite + ' AS billing_output_class';
      selectSql2 += ',1 AS closing_date_flg ';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += '(' + this.makeSelectSqlSiteQuery() + ') AS billings_site_QUERY ';
      /* GROUP BY句 */
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings_site_QUERY.client_id';
      /* 請求締更新後 */
      let selectSql4 = '';
      /* SELECT句 */
      selectSql4 += 'SELECT ';
      selectSql4 += ' billings_balances.client_id';
      if (this.searchConditions.selectInvoiceListCalcClass == Const.InvoiceListCalcClass.all) {
        // 全請求書合計
        selectSql4 += ',billings_balances.closing_date_receivable_sales AS product_sales_unit_amount';
        selectSql4 += ',billings_balances.closing_date_normal_tax';
        selectSql4 += ',billings_balances.closing_date_light_tax';
      } else {
        // 先行発行請求書を除外した合計
        selectSql4 += ',billings_balances.closing_date_receivable_sales_pre_exclude AS product_sales_unit_amount';
        selectSql4 += ',billings_balances.closing_date_normal_tax_pre_exclude AS closing_date_normal_tax';
        selectSql4 += ',billings_balances.closing_date_light_tax_pre_exclude AS closing_date_light_tax';
      }
      selectSql4 += ',billings_balances.pre_billing_no_tax_balance';
      selectSql4 += ',billings_balances.pre_billing_tax_balance';
      selectSql4 += ',billings_balances.two_pre_billing_money_no_tax';
      selectSql4 += ',billings_balances.two_pre_billing_money_tax';
      selectSql4 += ',billings_balances.three_pre_billing_money_no_tax';
      selectSql4 += ',billings_balances.three_pre_billing_money_tax';
      selectSql4 += ',billings_balances.closing_date_payment AS amount_no_tax';
      selectSql4 += ',billings_balances.closing_date_tax_payment AS amount_tax';
      selectSql4 += ',billings_balances.payment_scheduled';
      selectSql4 += ',billings_balances.billing_output_class';
      selectSql4 += ',0 AS closing_date_flg ';
      /* FROM句 */
      selectSql4 += ' FROM ';
      selectSql4 += 't_billings_balances AS billings_balances ';
      /* WHERE句 */
      selectSql4 += ' WHERE ';
      // 請求年月
      selectSql4 += 'billings_balances.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      // 請求開始日
      selectSql4 += 'AND billings_balances.billing_start_date IS NOT NULL ';
      // 現場コード
      selectSql4 += 'AND billings_balances.site_id = 0 ';
      // 得意先コード
      if(this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd == ''){
        selectSql4 += 'AND billings_balances.client_id >= ' + this.searchConditions.clientIdStart + ' ';
      }else if(this.searchConditions.clientIdStart == '' && this.searchConditions.clientIdEnd != ''){
        selectSql4 += 'AND billings_balances.client_id <= ' + this.searchConditions.clientIdEnd + ' ';
      }else if(this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd != ''){
        selectSql4 += 'AND billings_balances.client_id BETWEEN ' + this.searchConditions.clientIdStart + ' ' + 'AND ' + this.searchConditions.clientIdEnd + ' ';
      }
      if (this.searchConditions.selectInvoiceListCalcClass == Const.InvoiceListCalcClass.exclude) {
        // 先行発行請求書を除外した合計
        selectSql4 += 'AND (billings_balances.pre_billing_no_tax_balance <> 0 OR billings_balances.pre_billing_tax_balance <> 0 OR billings_balances.two_pre_billing_money_no_tax <> 0 OR billings_balances.two_pre_billing_money_tax <> 0 OR billings_balances.three_pre_billing_money_no_tax <> 0 OR billings_balances.three_pre_billing_money_tax <> 0 ';
        selectSql4 += 'OR billings_balances.closing_date_receivable_sales_pre_exclude <> 0 OR billings_balances.closing_date_normal_tax_pre_exclude <> 0 OR billings_balances.closing_date_light_tax_pre_exclude <> 0 OR billings_balances.closing_date_payment <> 0 OR billings_balances.closing_date_tax_payment <> 0) ';
      }
      /* GROUP BY句 */
      selectSql4 += ' GROUP BY ';
      selectSql4 += ' billings_balances.client_id';

      let selectSql = '';
      if (this.preBilling.processMonthYear > 0 && this.searchConditions.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 画面から選択した年月が請求前処理の年月と同じ場合
        // 3つのSELECT文をUNION
        selectSql = selectSql1 + ' UNION ALL ' + selectSql2 + ' UNION ALL ' + selectSql4;
      } else {
        // 画面から選択した年月が請求前処理の年月と異なる場合
        // 請求残高の登録情報のみを表示
        selectSql = selectSql4;
      }

      return selectSql;
    },
    // 検索SELECT文字列作成（現場別クエリ）
    makeSelectSqlSiteQuery: function() {
      // 現場別取引先の売上情報
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      selectSql1 += ',0 AS amount_no_tax';
      selectSql1 += ',0 AS amount_tax';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += this.billingsTableName + ' AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0 OR cumulative_transaction.product_tax <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      selectSql1 += 'LEFT JOIN t_billings_issue_input_site AS billings_issue_input_site ';
      selectSql1 += 'ON billings_issue_input_site.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql1 += 'AND cumulative_transaction.client_id = billings_issue_input_site.client_id ';
      selectSql1 += 'AND cumulative_transaction.site_id = billings_issue_input_site.site_id ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      selectSql1 += 'billings.closing_date = ' + this.preBilling.closingDate + ' ';
      // 得意先コード
      if (this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd == '') {
        selectSql1 += 'AND billings.client_id >= ' + this.searchConditions.clientIdStart + ' ';
      } else if(this.searchConditions.clientIdStart == '' && this.searchConditions.clientIdEnd != '') {
        selectSql1 += 'AND billings.client_id <= ' + this.searchConditions.clientIdEnd + ' ';
      } else if(this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd != '') {
        selectSql1 += 'AND billings.client_id BETWEEN ' + this.searchConditions.clientIdStart + ' ' + 'AND ' + this.searchConditions.clientIdEnd + ' ';
      }
      // 請求書出力単位区分
      selectSql1 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
      // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外
      selectSql1 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
      // 請求書発行登録＿現場毎テーブルに登録されている現場は除外
      selectSql1 += 'AND billings_issue_input_site.site_id IS NULL ';
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',billings.site_id';
      // 現場別取引先の入金情報
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT ';
      selectSql2 += ' billings.client_id';
      selectSql2 += ',0 AS product_sales_unit_amount';
      selectSql2 += ',0 AS closing_date_normal_tax';
      selectSql2 += ',0 AS closing_date_light_tax';
      selectSql2 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN 0 ELSE cumulative_transaction.amount END) AS amount_no_tax';
      selectSql2 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN cumulative_transaction.amount ELSE 0 END) AS amount_tax';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += this.billingsTableName + ' AS billings ';
      selectSql2 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql2 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql2 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql2 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql2 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql2 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql2 += 'AND (cumulative_transaction.amount <> 0) ';
      selectSql2 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\' ';
      /* WHERE句 */
      selectSql2 += ' WHERE ';
      selectSql2 += 'billings.closing_date = ' + this.preBilling.closingDate + ' ';
      // 得意先コード
      if (this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd == '') {
        selectSql2 += 'AND billings.client_id >= ' + this.searchConditions.clientIdStart + ' ';
      } else if(this.searchConditions.clientIdStart == '' && this.searchConditions.clientIdEnd != '') {
        selectSql2 += 'AND billings.client_id <= ' + this.searchConditions.clientIdEnd + ' ';
      } else if(this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd != '') {
        selectSql2 += 'AND billings.client_id BETWEEN ' + this.searchConditions.clientIdStart + ' ' + 'AND ' + this.searchConditions.clientIdEnd + ' ';
      }
      // 請求書出力単位区分
      selectSql2 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
      // 現場コード
      selectSql2 += 'AND billings.site_id = 0 ';
      /* GROUP BY句 */
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings.client_id';
      let selectSql = '';
      // 2つのSELECT文をUNION
      selectSql = selectSql1 + ' UNION ALL ' + selectSql2;

      return selectSql;
    },
    // 検索SELECT文字列作成（現場数）
    makeSelectSqlSiteCnt(csvClientId) {
      /* 請求締更新前のデータ */
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT';
      selectSql += ' billings_QUERY.client_id';
      selectSql += ',COUNT(*) AS site_cnt';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += '(' + this.makeMainQuerySiteCnt(csvClientId) + ') AS billings_QUERY ';
      /* GROUP BY句 */
      selectSql += ' GROUP BY ';
      selectSql += ' billings_QUERY.client_id';
      /* ORDER BY句 */
      selectSql += ' ORDER BY billings_QUERY.client_id';

      return selectSql;
    },
    // メインクエリ作成（現場数）
    makeMainQuerySiteCnt: function(csvClientId) {
      /* 請求締更新前のデータ */
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',billings.site_id';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += this.billingsTableName + ' AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0 OR cumulative_transaction.product_tax <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      // 請求書出力区分
      selectSql1 += 'billings.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
      // 得意先コード
      selectSql1 += 'AND billings.client_id IN (' + csvClientId + ') ';
      // 現場コード
      // 現場別の場合、0は除外（0は取引先全体）
      selectSql1 += 'AND cumulative_transaction.site_id > 0 ';
      if (this.searchConditions.siteIdStart != '' && this.searchConditions.siteIdEnd == '') {
        selectSql1 += 'AND cumulative_transaction.site_id >= ' + this.searchConditions.siteIdStart + ' ';
      }else if (this.searchConditions.siteIdStart == '' && this.searchConditions.siteIdEnd != '') {
        selectSql1 += 'AND cumulative_transaction.site_id <= ' + this.searchConditions.siteIdEnd + ' ';
      }else if (this.searchConditions.siteIdStart != '' && this.searchConditions.siteIdEnd != '') {
        selectSql1 += 'AND cumulative_transaction.site_id BETWEEN ' + this.searchConditions.siteIdStart + ' ' + 'AND ' + this.searchConditions.siteIdEnd + ' ';
      }
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings.client_id,billings.site_id';

      /* 請求締更新後のデータ */
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT';
      selectSql2 += ' billings_balances.client_id';
      selectSql2 += ',billings_balances.site_id';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += 't_billings_balances AS billings_balances ';
      selectSql2 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql2 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql2 += 'AND billings_balances.client_id = cumulative_transaction.client_id ';
      selectSql2 += 'AND billings_balances.site_id = cumulative_transaction.site_id ';
      selectSql2 += 'AND billings_balances.billing_month_year = cumulative_transaction.closing_month_year ';
      selectSql2 += 'AND cumulative_transaction.is_update_closing_date = 1 ';
      selectSql2 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0 OR cumulative_transaction.product_tax <> 0) ';
      selectSql2 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      /* WHERE句 */
      selectSql2 += ' WHERE ';
      // 請求年月
      selectSql2 += ' cumulative_transaction.closing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      // 請求開始日
      selectSql2 += 'AND billings_balances.billing_start_date IS NOT NULL ';
      // 請求書出力区分
      selectSql2 += 'AND billings_balances.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
      // 得意先コード
      selectSql2 += 'AND billings_balances.client_id IN (' + csvClientId + ') ';
      // 現場コード
      // 現場別の場合、0は除外（0は取引先全体）
      selectSql2 += 'AND billings_balances.site_id > 0 ';
      if (this.searchConditions.siteIdStart != '' && this.searchConditions.siteIdEnd == '') {
        selectSql2 += 'AND billings_balances.site_id >= ' + this.searchConditions.siteIdStart + ' ';
      }else if (this.searchConditions.siteIdStart == '' && this.searchConditions.siteIdEnd != '') {
        selectSql2 += 'AND billings_balances.site_id <= ' + this.searchConditions.siteIdEnd + ' ';
      }else if (this.searchConditions.siteIdStart != '' && this.searchConditions.siteIdEnd != '') {
        selectSql2 += 'AND billings_balances.site_id BETWEEN ' + this.searchConditions.siteIdStart + ' ' + 'AND ' + this.searchConditions.siteIdEnd + ' ';
      }
      /* GROUP BY句 */
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings_balances.client_id,billings_balances.site_id';
      // 2つのSELECT文をUNION
      let selectSql = '';
      if (this.preBilling.processMonthYear > 0 && this.searchConditions.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 画面から選択した年月が請求前処理の年月と同じ場合
        // 3つのSELECT文をUNION
        selectSql = selectSql1 + ' UNION ALL ' + selectSql2;
      } else {
        // 画面から選択した年月が請求前処理の年月と異なる場合
        // 請求残高の登録情報のみを表示
        selectSql = selectSql2;
      }

      return selectSql;
    },
    // 検索SELECT文字列作成（アップロード済の取引先）
    makeSelectSqlUpload(isClients, clientIdList) {
      let csvClientId = clientIdList.join(',');
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' client_id';
      // FROM句
      selectSql += ' FROM ';
      selectSql += 't_electronic_documents_seikyu ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += ' billing_month_year = ' + this.searchResultDispConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND client_id IN (' + csvClientId + ')';
      if (isClients == false) {
        // 現場別の場合（請求重複区分「3:重複（現場別の現場全体）」）
        selectSql += 'AND billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
        selectSql += 'AND (normal_separate_class = ' + Const.NormalSeparateClass.normal + ' ';
        selectSql += 'OR (normal_separate_class = ' + Const.NormalSeparateClass.separate + ' ';
        selectSql += 'AND billing_separate_class = ' + Const.SeparateBillingClass.sites + ')) ';
      } else {
        // 取引先別の場合（請求重複区分「1:重複（取引先別の通常）」）
        selectSql += 'AND billing_output_class = ' + Const.BillingOutputClass.client + ' ';
        selectSql += 'AND normal_separate_class = ' + Const.NormalSeparateClass.normal + ' ';
      }
      // GROUP BY句
      selectSql += 'GROUP BY client_id';

      return selectSql;
    },
    /* 請求一覧表PDF出力ボタンの押下 */
    async clickOutputBillingListPdf(){
      // 請求一覧表PDF出力処理
      //console.log('請求一覧表PDF出力処理');
      let query = {
        billingMonthYear: this.searchResultDispConditions.billingMonthYear,
        clientIdStart: this.searchResultDispConditions.clientIdStart == '' ? '000000' : this.searchResultDispConditions.clientIdStart,
        clientIdEnd: this.searchResultDispConditions.clientIdEnd == '' ? '999999' : this.searchResultDispConditions.clientIdEnd,
        billingOutputClass: this.searchResultDispConditions.propBillingOutputClass,
        billingPaymentClass: this.billingPaymentClass,
        invoiceIssueClass: this.searchResultDispConditions.invoiceIssueClass,
        webIssueClass: this.searchResultDispConditions.webIssueClass,
        invoiceIssueWay: this.searchResultDispConditions.propInvoiceIssueWay,
        invoiceListCalcClass: this.searchResultDispConditions.selectInvoiceListCalcClass,
      };
      let route = this.$router.resolve({ name: 'SEIKYU-LIST', query: query });
      window.open(route.href, '_blank');
    },
    /* 請求書PDF出力ボタンの押下 */
    /*
    async clickOutputBillingPdf(){
      //console.log(this.checkedClientIds);
      if (this.checkedClientIds.length <= Const.PdfSeikyuClientMaxCnt) {
        // 請求書PDF出力処理画面へ遷移
        let params = {
          propBillingMonthYear: this.searchResultDispConditions.billingMonthYear,
          propBillingOutputClass: this.searchResultDispConditions.billingOutputClass,
          propSiteIdStart: this.searchResultDispConditions.siteIdStart == '' ? '0000' : this.searchResultDispConditions.siteIdStart,
          propSiteIdEnd: this.searchResultDispConditions.siteIdEnd == '' ? '9999' : this.searchResultDispConditions.siteIdEnd,
          propCheckedClientIds: this.checkedClientIds,
          propBillingPaymentClass: this.billingPaymentClass,
          propInvoiceIssueWay: this.searchResultDispConditions.selectInvoiceIssueWay,
        };
        this.$router.push({ name: 'SEIKYU', params: params });
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2049'].replace('%arg1%', Const.PdfSeikyuClientMaxCnt));
        scrollTo(0,0);
      }
    },
    */
    /* 請求書PDF出力（別タブ）ボタンの押下 */
    async clickOutputBillingPdfTab() {
      //console.log(this.checkedClientIds);
      if (this.checkedClientIds.length <= Const.PdfTabSeikyuClientMaxCnt) {
        let csvClientId = '';
        for (let i = 0; i < this.checkedClientIds.length; i++) {
          if (csvClientId != '') {
            csvClientId += ',';
          }
          csvClientId += this.checkedClientIds[i];
        }
        // 請求書PDF出力処理画面へ遷移
        let query = {
          propBillingMonthYear: this.searchResultDispConditions.billingMonthYear,
          propBillingOutputClass: this.searchResultDispConditions.billingOutputClass,
          propSiteIdStart: this.searchResultDispConditions.siteIdStart == '' ? '0000' : this.searchResultDispConditions.siteIdStart,
          propSiteIdEnd: this.searchResultDispConditions.siteIdEnd == '' ? '9999' : this.searchResultDispConditions.siteIdEnd,
          propCheckedClientIds: csvClientId,
          propBillingPaymentClass: this.billingPaymentClass,
          propInvoiceIssueWay: this.searchResultDispConditions.selectInvoiceIssueWay,
          propIsUploadCheck: this.isUploadCheck == true ? '1' : '0',
        };
        let route = this.$router.resolve({ name: 'SEIKYU', query: query });
        window.open(route.href, '_blank');
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2049'].replace('%arg1%', Const.PdfTabSeikyuClientMaxCnt));
        scrollTo(0,0);
      }
    },
    /* 全チェック選択 */
    clickCheckAll: function(){
      if (this.isAllChecked == true) {
        // チェックONにした場合
        // 印刷チェックを初期化
        this.checkedClientIds = [];
        // 印刷チェックを全てON
        for (let i = 0; i < this.itemList.length; i++) {
          if (this.itemList[i].billingOutputClass == this.searchResultDispConditions.billingOutputClass &&
          this.itemList[i].invoiceIssueWay==this.searchResultDispConditions.selectInvoiceIssueWay) {
            this.checkedClientIds.push(this.itemList[i].clientId);
          }
        }
      } else {
        // チェックOFFにした場合
        // 印刷チェックを全てOFF
        this.checkedClientIds = [];
      }
    },
    /* チェックボックス個別選択（全チェックのチェックボックスを合わせる） */
    changeCheck: function(){
      //console.log('changeCheck');
      this.isAllChecked = this.totalCheckCnt == this.checkedClientIds.length;
    },
    /* 出力区分の区分値返却処理 */
    billingOutputClassVal: function(billingOutputClass) {
      return getListValue(Const.billingOutputClassList, billingOutputClass);
    },
    /* 取引先保守ボタン押下時処理 */
    clickClientsMasterBtn: function(clientId) {
      let route = this.$router.resolve({ name: 'CLIENT-EDIT', query: { clientClass: Const.ClientClass.customer, clientId } });
      window.open(route.href, '_blank');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
      this.resultError = [];
    },
    /* フィルター時のイベント */
    onFiltered: function(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;
    },
    /* ページング変数の初期化 */
    initPaging: function() {
      this.totalRows = 0;
      this.filterRows = 0;
      this.filter = null;
      this.perPage = DataTblDef.perPage,
      this.currentPage = DataTblDef.currentPage;
    },
    // 請求日（開始日と終了日）の取得
    async getBillingDateInfo() {
      // 各種データ取得（非同期でまとめて取得した方が早いため）
      let preBillingResult = null;
      let controlData = null;
      let where_clause = 'AND billing_payment_class = ' + this.billingPaymentClass + ' ';
      [preBillingResult, controlData] = await Promise.all([
        selectOneTable('t_closing_update_range', where_clause),
        getControlMaster(),
      ]);
      this.controlMasterData.processMonthYear = controlData.process_month_year;
      this.controlMasterData.taxRate = controlData.tax_rate;
      this.controlMasterData.newTaxRate = controlData.new_tax_rate;
      this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
      this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
      this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
      this.controlMasterData.invoiceInputNo = controlData.invoice_input_no;
      if (preBillingResult != null && preBillingResult.length > 0) {
        this.preBilling.processMonthYear = preBillingResult[0].process_month_year;
        this.preBilling.closingDate = preBillingResult[0].closing_date;
        this.preBilling.billingStartDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, true);
        this.preBilling.billingEndDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, false);
      } else {
        this.preBilling.processMonthYear = 0;
        this.preBilling.closingDate = 0;
        this.preBilling.billingStartDate = '';
        this.preBilling.billingEndDate = '';
      }
    },
    /* 機能選択の選択値毎に検索結果のカラムを変更 */
    getFields: function() {
      let retFields = [];
      retFields.push({key: 'check'});
      if (this.searchConditions.isSearchUploadCheck == true) {
        retFields.push({key: 'isUpload', label: 'アップロード済'});
      }
      retFields.push({key: 'invoiceIssueInput'});
      retFields.push({key: 'invoiceIssueWayText'});
      retFields.push({key: 'clientId', label: '得意先コード'});
      retFields.push({key: 'clientNameKanji', label: '得意先名'});
      if (this.searchConditions.billingOutputClass == Const.billingOutputClass.clientsSites) {
        retFields.push({key: 'siteCnt', label: '現場数'});
      }
      retFields.push({key: 'billingOutputClass', label: '出力区分'});
      retFields.push({key: 'staffId', label: '担当者コード'});
      retFields.push({key: 'staffNameNameKanji', label: '担当者名'});
      retFields.push({key: 'salesUnitAmount', class: 'text-right'});
      retFields.push({key: 'billingMoney', class: 'text-right'});
      retFields.push({key: 'preBillingMoney', class: 'text-right'});
      retFields.push({key: 'payment', class: 'text-right'});
      retFields.push({key: 'forward', class: 'text-right'});
      retFields.push({key: 'paymentScheduled', label: '入金予定'});
      retFields.push({key: 'businessClass', label: '金種'});
      retFields.push({key: 'operation', label: '操作'});

      return retFields;
    },
    // 請求書発行登録ボタンの押下
    async clickInvoiceIssueInput() {
      const functionName = 'clickInvoiceIssueInput';
      try {
        let confirmMessage = [];
        confirmMessage.push(this.$createElement('div','チェックされた得意先の請求書発行登録を行います。'));
        confirmMessage.push(this.$createElement('div','よろしいですか？'));
        if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: '請求書発行登録'}) == true) {
          this.$store.commit('setLoading', true);
          await this.execInput();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 請求書発行登録処理
    async execInput() {
      const functionName = 'execInput';
      let bulkInsertSql = '';
      let sqlList = [];
      for (let i = 0; i < this.itemList.length; i++) {
        if (this.itemList[i].rowInvoiceIssueClass == Const.InvoiceIssueClass.noIssue) {
          // 請求書発行登録未の場合のみ
          // チェックONの取引先かどうか確認
          let record = this.checkedClientIds.find((element) => {
            return element == this.itemList[i].clientId;
          });
          if (record != null) {
            // チェックONの場合
            // 請求書発行登録を行う
            let colList = [];
            // 請求年月
            colList.push(CreateColRow('billing_month_year', this.searchResultDispConditions.billingMonthYear.replace(/-/g,''), 'NUMBER'));
            // 得意先コード
            colList.push(CreateColRow('client_id', this.itemList[i].clientId, 'NUMBER'));
            // 請求書発行登録日
            colList.push(CreateColRow('billing_issue_input_date', this.invoiceIssueInputDate, 'DATE'));
            // 事業者登録番号	
            colList.push(CreateColRow('invoice_input_no', this.controlMasterData.invoiceInputNo, 'VARCHAR'));
            // 作成ユーザー
            colList.push(CreateColRow('created_user', this.loginId, 'VARCHAR'));
            // 更新ユーザー
            colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
            if (bulkInsertSql == '') {
              bulkInsertSql += 'INSERT INTO t_billings_issue_input (' + CreateInsertSql(colList, 'col', 't_billings_issue_input') + ') VALUES ';
            } else {
              bulkInsertSql += ',';
            }
            bulkInsertSql += '(' + CreateInsertSql(colList, 'val', 't_billings_issue_input') + ')';
            if (bulkInsertSql.length >= Const.SqlMaxLength) {
              sqlList.push(bulkInsertSql);
              bulkInsertSql = '';
            }
          }
        }
      }
      if (bulkInsertSql != '') {
        sqlList.push(bulkInsertSql);
      }
      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable(1);
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }

      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
      }
    },
    // 請求書発行登録取消ボタンの押下
    async clickInvoiceIssueCancel() {
      const functionName = 'clickInvoiceIssueCancel';
      try {
        let confirmMessage = [];
        confirmMessage.push(this.$createElement('div','チェックされた得意先の請求書発行登録取消を行います。'));
        confirmMessage.push(this.$createElement('div','よろしいですか？'));
        if (await this.$bvModal.msgBoxConfirm(confirmMessage, {title: '請求書発行登録取消'}) == true) {
          this.$store.commit('setLoading', true);
          await this.execCancel();
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    // 請求書発行登録取消処理
    async execCancel() {
      const functionName = 'execCancel';
      let csvClientId = '';
      let sqlList = [];
      for (let i = 0; i < this.itemList.length; i++) {
        if (this.itemList[i].rowInvoiceIssueClass == Const.InvoiceIssueClass.issue) {
          // 請求書発行登録済の場合のみ
          // チェックONの取引先かどうか確認
          let record = this.checkedClientIds.find((element) => {
            return element == this.itemList[i].clientId;
          });
          if (record != null) {
            // チェックONの場合
            // 請求書発行登録取消を行う
            if (csvClientId != '') {
              csvClientId += ',';
            }
            csvClientId += this.itemList[i].clientId;
            if (csvClientId.length >= Const.SqlMaxLength) {
              let deleteSql = 'DELETE FROM t_billings_issue_input WHERE billing_month_year = ' + this.searchResultDispConditions.billingMonthYear.replace(/-/g,'') + ' AND client_id IN (' + csvClientId + ')';
              sqlList.push(deleteSql);
              csvClientId = '';
            }
          }
        }
      }
      if (csvClientId != '') {
        let deleteSql = 'DELETE FROM t_billings_issue_input WHERE billing_month_year = ' + this.searchResultDispConditions.billingMonthYear.replace(/-/g,'') + ' AND client_id IN (' + csvClientId + ')';
        sqlList.push(deleteSql);
      }
      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable(1);
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
        return;
      }

      let retResult = await executeTransactSqlList(sqlList, MODULE_NAME, functionName);
      if (retResult == true) {
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1002']);
        await this.searchButton();
      } else {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    // 請求書分割発行ボタン押下時処理
    clickSeparateBtn: function() {
      let name = '';
      if (this.billingPaymentClass == Const.BillingPaymentClass.billing) {
        name = 'INVOICE-LIST-SEPARATE';
      } else {
        name = 'INVOICE-LIST-SEPARATE2';
      }
      let query = {
        billingPaymentClass: this.billingPaymentClass
      };
      let route = this.$router.resolve({ name: name, query: query });
      window.open(route.href, '_blank');
    },
    // 検索SELECT文字列作成（前月情報）
    makeSelectSqlPreBillingBalance: function() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' billings.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',0 AS product_sales_unit_amount';
      selectSql += ',0 AS product_tax';
      selectSql += ',billings_balances.pre_billing_no_tax_balance';
      selectSql += ',billings_balances.pre_billing_tax_balance';
      selectSql += ',billings_balances.two_pre_billing_money_no_tax';
      selectSql += ',billings_balances.two_pre_billing_money_tax';
      selectSql += ',billings_balances.three_pre_billing_money_no_tax';
      selectSql += ',billings_balances.three_pre_billing_money_tax';
      selectSql += ',0 AS amount_no_tax';
      selectSql += ',0 AS amount_tax';
      selectSql += ',CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '   ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += ' END AS payment_scheduled';
      selectSql += ',clients.business_class';
      selectSql += ',clients.billing_class';
      selectSql += ',billings.billing_output_class';
      selectSql += ',billings_issue_input.billing_issue_input_date';
      selectSql += ',1 AS closing_date_flg';
      selectSql += ',' + Const.InvoiceIssueWay.allIssue + ' AS invoice_issue_way';
      // FROM句
      selectSql += ' FROM ';
      selectSql += this.billingsTableName + ' AS billings ';
      selectSql += 'INNER JOIN t_billings_balances AS billings_balances ';
      selectSql += 'ON billings.client_id = billings_balances.client_id ';
      selectSql += 'AND billings_balances.site_id = 0 ';
      selectSql += 'AND billings_balances.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND billings_balances.billing_start_date IS NULL ';
      selectSql += 'AND (billings_balances.pre_billing_no_tax_balance <> 0 OR billings_balances.pre_billing_tax_balance <> 0 OR billings_balances.two_pre_billing_money_no_tax <> 0 OR billings_balances.two_pre_billing_money_tax <> 0 OR billings_balances.three_pre_billing_money_no_tax <> 0 OR billings_balances.three_pre_billing_money_tax <> 0) ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND billings.client_id = clients.client_id ';
      if (this.searchConditions.billingOutputClass != '') {
        selectSql += 'AND billings.billing_output_class = ' + this.searchConditions.billingOutputClass + ' ';
      }
      if (this.searchConditions.webIssueClass != '') {
        selectSql += 'AND clients.web_issue_class = ' + this.searchConditions.webIssueClass + ' ';
      }
      if (this.searchConditions.invoiceIssueClass == Const.InvoiceIssueClass.issue) {
        selectSql += 'INNER JOIN t_billings_issue_input AS billings_issue_input ';
      } else {
        selectSql += 'LEFT JOIN t_billings_issue_input AS billings_issue_input ';
      }
      selectSql += 'ON billings_issue_input.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND billings.client_id = billings_issue_input.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON clients.staff_id = staffs.staff_id ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 'billings.site_id = 0 ';
      // 得意先コード
      if (this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd == '') {
        selectSql += 'AND billings.client_id >= ' + this.searchConditions.clientIdStart + ' ';
      } else if(this.searchConditions.clientIdStart == '' && this.searchConditions.clientIdEnd != '') {
        selectSql += 'AND billings.client_id <= ' + this.searchConditions.clientIdEnd + ' ';
      } else if(this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd != '') {
        selectSql += 'AND billings.client_id BETWEEN ' + this.searchConditions.clientIdStart + ' ' + 'AND ' + this.searchConditions.clientIdEnd + ' ';
      }
      if (this.searchConditions.invoiceIssueClass == Const.InvoiceIssueClass.noIssue) {
        selectSql += 'AND billings_issue_input.billing_month_year IS NULL ';
      }
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' billings.client_id';

      return selectSql;
    },
    // 検索SELECT文字列作成（先行請求書発行情報）
    makeSelectSqlSeparate: function() {
      let selectSqlQuery1 = '';
      // SELECT句
      selectSqlQuery1 += 'SELECT';
      selectSqlQuery1 += ' billings.client_id';
      selectSqlQuery1 += ',MAX(billings_issue_input_billing_no.sales_unit_amount) AS sales_unit_amount';
      selectSqlQuery1 += ',MAX(billings_issue_input_billing_no.normal_tax) AS normal_tax';
      selectSqlQuery1 += ',MAX(billings_issue_input_billing_no.light_tax) AS light_tax';
      selectSqlQuery1 += ',billings.billing_output_class';
      // FROM句
      selectSqlQuery1 += ' FROM ';
      selectSqlQuery1 += this.billingsTableName + ' AS billings ';
      selectSqlQuery1 += 'INNER JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSqlQuery1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSqlQuery1 += 'AND billings.client_id = billings_issue_input_billing_no.client_id ';
      selectSqlQuery1 += 'AND billings.site_id = billings_issue_input_billing_no.site_id ';
      // WHERE句
      // 得意先コード
      let whereSql = '';
      if (this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd == '') {
        whereSql += 'AND billings.client_id >= ' + this.searchConditions.clientIdStart + ' ';
      } else if(this.searchConditions.clientIdStart == '' && this.searchConditions.clientIdEnd != '') {
        whereSql += 'AND billings.client_id <= ' + this.searchConditions.clientIdEnd + ' ';
      } else if(this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd != '') {
        whereSql += 'AND billings.client_id BETWEEN ' + this.searchConditions.clientIdStart + ' ' + 'AND ' + this.searchConditions.clientIdEnd + ' ';
      }
      if (this.searchConditions.billingOutputClass != '') {
        whereSql += 'AND billings.billing_output_class = ' + this.searchConditions.billingOutputClass + ' ';
      }
      if (whereSql != '') {
        selectSqlQuery1 += ' WHERE ';
        selectSqlQuery1 += whereSql.substr(3);
      }
      // GROUP BY句
      selectSqlQuery1 += ' GROUP BY ';
      selectSqlQuery1 += ' billings.client_id';
      selectSqlQuery1 += ',billings_issue_input_billing_no.separate_invoice_no';

      let selectSqlQuery2 = '';
      // SELECT句
      selectSqlQuery2 += 'SELECT';
      selectSqlQuery2 += ' billings.client_id';
      selectSqlQuery2 += ',billings_issue_input_site.sales_unit_amount';
      selectSqlQuery2 += ',billings_issue_input_site.normal_tax';
      selectSqlQuery2 += ',billings_issue_input_site.light_tax';
      selectSqlQuery2 += ',billings.billing_output_class';
      // FROM句
      selectSqlQuery2 += ' FROM ';
      selectSqlQuery2 += this.billingsTableName + ' AS billings ';
      selectSqlQuery2 += 'INNER JOIN t_billings_issue_input_site AS billings_issue_input_site ';
      selectSqlQuery2 += 'ON billings_issue_input_site.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSqlQuery2 += 'AND billings.client_id = billings_issue_input_site.client_id ';
      selectSqlQuery2 += 'AND billings.site_id = billings_issue_input_site.site_id ';
      // WHERE句
      // 得意先コード
      whereSql = '';
      if (this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd == '') {
        whereSql += 'AND billings.client_id >= ' + this.searchConditions.clientIdStart + ' ';
      } else if(this.searchConditions.clientIdStart == '' && this.searchConditions.clientIdEnd != '') {
        whereSql += 'AND billings.client_id <= ' + this.searchConditions.clientIdEnd + ' ';
      } else if(this.searchConditions.clientIdStart != '' && this.searchConditions.clientIdEnd != '') {
        whereSql += 'AND billings.client_id BETWEEN ' + this.searchConditions.clientIdStart + ' ' + 'AND ' + this.searchConditions.clientIdEnd + ' ';
      }
      if (this.searchConditions.billingOutputClass != '') {
        whereSql += 'AND billings.billing_output_class = ' + this.searchConditions.billingOutputClass + ' ';
      }
      if (whereSql != '') {
        selectSqlQuery2 += ' WHERE ';
        selectSqlQuery2 += whereSql.substr(3);
      }

      let selectSqlQuery = selectSqlQuery1 + ' UNION ALL ' + selectSqlQuery2;

      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' subQuery.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',SUM(subQuery.sales_unit_amount) AS product_sales_unit_amount';
      selectSql += ',SUM(subQuery.normal_tax) + SUM(subQuery.light_tax) AS product_tax';
      selectSql += ',0 AS pre_billing_no_tax_balance';
      selectSql += ',0 AS pre_billing_tax_balance';
      selectSql += ',0 AS two_pre_billing_money_no_tax';
      selectSql += ',0 AS two_pre_billing_money_tax';
      selectSql += ',0 AS three_pre_billing_money_no_tax';
      selectSql += ',0 AS three_pre_billing_money_tax';
      selectSql += ',0 AS amount_no_tax';
      selectSql += ',0 AS amount_tax';
      selectSql += ',CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '   ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += ' END AS payment_scheduled';
      selectSql += ',clients.business_class';
      selectSql += ',clients.billing_class';
      selectSql += ',subQuery.billing_output_class';
      selectSql += ',billings_issue_input.billing_issue_input_date';
      selectSql += ',1 AS closing_date_flg';
      selectSql += ',' + Const.InvoiceIssueWay.partIssue + ' AS invoice_issue_way';
      // FROM句
      selectSql += ' FROM ';
      selectSql += '(' + selectSqlQuery + ') AS subQuery ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND subQuery.client_id = clients.client_id ';
      if (this.searchConditions.webIssueClass != '') {
        selectSql += 'AND clients.web_issue_class = ' + this.searchConditions.webIssueClass + ' ';
      }
      if (this.searchConditions.invoiceIssueClass == Const.InvoiceIssueClass.issue) {
        selectSql += 'INNER JOIN t_billings_issue_input AS billings_issue_input ';
      } else {
        selectSql += 'LEFT JOIN t_billings_issue_input AS billings_issue_input ';
      }
      selectSql += 'ON billings_issue_input.billing_month_year = ' + this.searchConditions.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND subQuery.client_id = billings_issue_input.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON clients.staff_id = staffs.staff_id ';
      // WHERE句
      if (this.searchConditions.invoiceIssueClass == Const.InvoiceIssueClass.noIssue) {
        selectSql += ' WHERE ';
        selectSql += ' billings_issue_input.billing_month_year IS NULL ';
      }
      // GROUP BY句
      selectSql += ' GROUP BY ';
      selectSql += ' subQuery.client_id';
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' subQuery.client_id';

      return selectSql;
    },
    // 合計データに設定（前月分データ、先行発行登録伝票データ）
    async setTotalData(dataResultTotal) {
      if (this.preBilling.processMonthYear > 0 && this.searchConditions.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 指定した年月が請求マスタの範囲と同じ場合
        // 請求残高から前月分の情報を取得、請求書発行登録済みの伝票番号情報を取得
        let dataResultPreBillingBalance = null;
        let dataResultSeparate = null;
        let selectSqlPreBillingBalance = this.makeSelectSqlPreBillingBalance();
        //console.log(selectSqlPreBillingBalance);
        let selectSqlSeparate = this.makeSelectSqlSeparate();
        //console.log(selectSqlSeparate);
        [dataResultPreBillingBalance, dataResultSeparate] = await Promise.all([
          executeSelectSql(selectSqlPreBillingBalance),
          executeSelectSql(selectSqlSeparate),
        ]);
        //console.log(dataResultPreBillingBalance);
        //console.log(dataResultSeparate);
        if (dataResultPreBillingBalance != null && dataResultPreBillingBalance.length > 0) {
          // 前月分の情報があった場合
          let totalIndex = 0;
          for (let i = 0; i < dataResultPreBillingBalance.length; i++) {
            if (dataResultTotal != null && dataResultTotal.length > 0) {
              if (totalIndex < dataResultTotal.length) {
                if (dataResultPreBillingBalance[i].client_id == dataResultTotal[totalIndex].client_id) {
                  if (dataResultTotal[totalIndex].closing_date_flg == 1) {
                    dataResultTotal[totalIndex].pre_billing_no_tax_balance = dataResultPreBillingBalance[i].pre_billing_no_tax_balance;
                    dataResultTotal[totalIndex].pre_billing_tax_balance = dataResultPreBillingBalance[i].pre_billing_tax_balance;
                    dataResultTotal[totalIndex].two_pre_billing_money_no_tax = dataResultPreBillingBalance[i].two_pre_billing_money_no_tax;
                    dataResultTotal[totalIndex].two_pre_billing_money_tax = dataResultPreBillingBalance[i].two_pre_billing_money_tax;
                    dataResultTotal[totalIndex].three_pre_billing_money_no_tax = dataResultPreBillingBalance[i].three_pre_billing_money_no_tax;
                    dataResultTotal[totalIndex].three_pre_billing_money_tax = dataResultPreBillingBalance[i].three_pre_billing_money_tax;
                  }
                } else if (dataResultPreBillingBalance[i].client_id < dataResultTotal[totalIndex].client_id) {
                  dataResultTotal.splice(totalIndex, 0, dataResultPreBillingBalance[i]);
                } else {
                  totalIndex++;
                  i--;
                }
              } else {
                dataResultTotal.push(dataResultPreBillingBalance[i]);
              }
            } else {
              dataResultTotal.push(dataResultPreBillingBalance[i]);
            }
          }
        }
        if (dataResultSeparate != null && dataResultSeparate.length > 0) {
          // 前月分の情報があった場合
          if (this.searchConditions.selectInvoiceListCalcClass == Const.InvoiceListCalcClass.all) {
            let totalIndex = 0;
            for (let i = 0; i < dataResultSeparate.length; i++) {
              if (dataResultTotal != null && dataResultTotal.length > 0) {
                if (totalIndex < dataResultTotal.length) {
                  if (dataResultSeparate[i].client_id == dataResultTotal[totalIndex].client_id) {
                    if (dataResultTotal[totalIndex].closing_date_flg == 1) {
                      dataResultTotal[totalIndex].product_sales_unit_amount = Number(dataResultTotal[totalIndex].product_sales_unit_amount) + Number(dataResultSeparate[i].product_sales_unit_amount);
                      dataResultTotal[totalIndex].product_tax = Number(dataResultTotal[totalIndex].product_tax) + Number(dataResultSeparate[i].product_tax);
                    }
                    dataResultTotal[totalIndex].invoice_issue_way = Const.InvoiceIssueWay.partIssue;
                  } else if (dataResultSeparate[i].client_id < dataResultTotal[totalIndex].client_id) {
                    dataResultTotal.splice(totalIndex, 0, dataResultSeparate[i]);
                  } else {
                    totalIndex++;
                    i--;
                  }
                } else {
                  dataResultTotal.push(dataResultSeparate[i]);
                }
              } else {
                dataResultTotal.push(dataResultSeparate[i]);
              }
            }
          } else {
            for (let i = 0; i < dataResultSeparate.length; i++) {
              let data = dataResultTotal.find(el => el.client_id == dataResultSeparate[i].client_id);
              if (data != undefined) {
                // 一部先行であることを設定
                data.invoice_issue_way = Const.InvoiceIssueWay.partIssue;
              }
            }
          }
        }
      }
    },
  }
}
</script>
<style scoped>
</style>