<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-4 min-vh-85">
      <b-row class="d-flex justify-content-center mt-2 mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> {{this.title}}</strong></h5>
                <b-button pill v-b-tooltip.hover title="伝票No管理保守に戻る" @click="cancel" class="btn-cancel m-0">
                  <span class="oi oi-circle-x"></span> キャンセル
                </b-button>
              </div>
            </b-media-body>
          </b-media>
          <b-card class="mb-3">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <p>編集途中の情報は保持されません。編集が終わりましたら、必ず[保存]ボタンを押してください。</p>
              <validation-observer ref="observer">
                <b-form @submit.prevent="clearAlert(); saveData();" id="inputForm" class="form-horizontal">
                  <b-row>
                    <!-- 営業所コード -->
                    <b-col lg="6">
                      <b-form-group
                        label="営業所"
                        label-for="selectSalesOffice"
                      >
                        <b-form-input id="selectSalesOffice" type="text" v-model="selectSalesOfficeText" readonly />
                      </b-form-group>
                    </b-col>
                    <!-- 伝票種類 -->
                    <b-col lg="6">
                      <b-form-group
                        label="伝票種類"
                        label-for="selectBillingType"
                      >
                        <b-form-input id="selectBillingType" type="text" v-model="selectBillingTypeText" readonly />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6">
                      <validation-provider :rules="{required:true, numeric:true, consistency:maxNo, max:billingLength }" v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-form-group
                            label="最小No"
                            label-for="minNo"
                          >
                            <b-form-input type="number" id="minNo" v-model.number="minNo"></b-form-input>
                          </b-form-group>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </b-col>
                    <b-col lg="6">
                      <validation-provider :rules="{required:true, numeric:true, max:billingLength }"  v-slot="{ classes, errors }">
                        <div :class="classes">
                          <b-form-group
                            label="最大No"
                            label-for="maxNo"
                          >
                            <b-form-input type="number" id="maxNo" v-model.number="maxNo"></b-form-input>
                          </b-form-group>
                          <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <!-- 保存ボタン -->
                  <b-row class="mt-2 justify-content-md-center">
                    <b-col lg="2">
                      <b-button pill 
                       variant="success" 
                       type="submit" 
                       form="inputForm" 
                       class="btn btn-block" 
                      :disabled="statusFlg.editFlg==false"><span class="oi oi-circle-check"></span> 保存</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, getListValue, executeSelectSql, CreateUpdateSql, CreateColRow, getUserCol, isSystemEditable, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
const MODULE_NAME = 'slip-no-maintenance-edit';

export default {
  name: 'SLIP-NO-MAINTENANCE-EDIT',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CONFIRM,
  },
  props:['reqSalesOffice','reqTransactionId','reqCounterClass'],
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '伝票No管理保守修正',
      loginId: '',
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 営業所データ
      selectSalesOffice: null,
      selectSalesOfficeText: '',
      // 伝票種類データ
      selectBillingType: null,
      selectBillingTypeText: '',
      selectBillingTypeList: Const.TransactionIdList,
      // カウンター区分データ
      selectCounterClassList: Const.CounterClassList,
      // 最小No
      minNo:null,
      // 最大No
      maxNo:null,
      // 取得結果
      resultData : [],
      // 確認ダイアログ用
      confirmMessage: [],
      // ステータスフラグ
      statusFlg: {
        editFlg: true,
      },
      // ユーザ名
      username: this.$store.getters.user.username,
      // 帳票最大桁数
      billingLength: Const.billingMaxLength,
    }
  },
  computed:{
  /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      // ローディングの開始
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 営業所データ取得
        this.selectSalesOffice = this.$route.query.reqSalesOffice
        this.selectSalesOfficeText = await this.getOfficesData(this.$route.query.reqSalesOffice)
        // 伝票種類データ取得
        this.selectBillingType = this.$route.query.reqTransactionId
        this.selectBillingTypeText = getListValue(this.selectBillingTypeList, this.$route.query.reqTransactionId, 'id', 'name')
        + getListValue(this.selectCounterClassList, this.$route.query.reqCounterClass, 'id', 'name'),
        // 既存データ設定
        await this.setInitData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      // ローディングの解除
      this.$store.commit('setLoading', false);
    },
    /* 営業所情報を取得 */
    async getOfficesData(officeId) {
      // ログ出力用メソッド名
      const functionName = 'getOfficesData';
      let officeListData;
      // 検索条件を設定
      let where_clause = `AND office_id = '${officeId}'`;
      try {
        // 営業所データ取得
        officeListData = await selectOneTable('m_offices', where_clause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, { graphqlOperation: 'list_m_offices'}, 'result.data is null');
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
        return;
      }
      return officeListData[0].office_id + '：' + officeListData[0].office_name_kanji;
    },
    /* 初期データ設定 */
    async setInitData() {
      // 初期化
      this.statusFlg.editFlg = true;
      this.productList = [];
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let dataResult = await executeSelectSql(selectSql);
      if (dataResult != null) {
        await this.setResult(dataResult);
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索SQL作成（初期値） */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' form_counter.count_cur'; // カウント（現在）
      selectSql += ',form_counter.count_min'; // カウント（最小）
      selectSql += ',form_counter.count_max'; // カウント（最大）
      selectSql += ',form_counter.counter_class'; // カウンター区分
      selectSql += ',form_counter.free_class_1'; // フリー区分（営業所）
      selectSql += ',form_counter.free_class_2'; // フリー区分（トランザクション）
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_form_counter AS form_counter ';
      selectSql += `WHERE counter_class = ${this.$route.query.reqCounterClass} ` 
      selectSql += `AND free_class_1 = ${this.$route.query.reqSalesOffice} ` 
      selectSql += `AND free_class_2 = '${this.$route.query.reqTransactionId}' ` 

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        this.maxNo = result[0].count_max
        this.minNo = result[0].count_min
        this.resultData.push({
          countCur: result[0].count_cur,
          counterClass: result[0].counter_class,
          freeClass1: result[0].free_class_1,
          freeClass2: result[0].free_class_2,
        })
      }
    },
    /* 保存ボタンの押下 */
    async saveData(){
      // veeValidateのvalidationObserverが持つ情報をvalidate()で全てバリデーション実行
      const observer = this.$refs.observer;
      const success = await observer.validate();

      // バリデーションが全て通れば、保存処理。そうでないなら保存処理はされず、エラーが発生している要素にスクロールされる。
      if (!success) {
        const el = document.querySelector('#error:first-of-type');
        el.scrollIntoView({block: 'center', inline: 'nearest'});
      }else{
        // 入力チェックが完了した場合、保存確認ダイアログを表示
        await this.saveConfirm();
      }
    },
    /* 保存時の確認ダイアログ表示 */
    async saveConfirm() {
      //console.log('保存');
      this.confirmMessage = [];
      this.confirmMessage.push('入力された情報で保存します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 確認モーダルを閉じた時 */
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      try {
        // モーダルから渡された値の有無チェック
        if (typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          await this.confirmSave();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 確認後保存処理 */
    async confirmSave() {
      // 保存処理
      //console.log('保存処理開始');
      // データの登録(SQLの作成)
      if (await this.execSave() == true) {
        // 保存成功メッセージを表示
        this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        await this.setInitData();
      }
      //console.log('保存処理終了');
    },
    /* 保存実行 */
    async execSave() {
      // 帳票カウンタマスタの登録
      return await this.updateFormCounter();
    },
    /* 帳票カウンタマスタ更新SQL作成 */
    async updateFormCounter() {
      const functionName = 'updateFormCounter';
      //console.log('帳票カウンタマスタ更新SQL作成');
      // 他の営業所の最小No・最大Noの範囲と重複がないか確認
      if (!await this.checkCounter()){
        this.alertWarning.push(DISP_MESSAGES.WARNING['2029']);
        return false
      }
      // 現在Noが更新後の最小Noより小さい場合、現在Noに最小Noを挿入する
      let countCur = this.resultData[0].countCur < this.minNo ? this.minNo : this.resultData[0].countCur;
      // CRUD処理
      let colList = [];
      // カウンタ区分
      colList.push(CreateColRow('counter_class', this.resultData[0].counterClass, 'NUMBER'));
      // カウント（現在）
      colList.push(CreateColRow('count_cur', countCur, 'NUMBER'));
      // カウント（最小）
      colList.push(CreateColRow('count_min', this.minNo, 'NUMBER'));
      // カウント（最大）
      colList.push(CreateColRow('count_max', this.maxNo, 'NUMBER'));
      // フリー区分１
      colList.push(CreateColRow('free_class_1', this.resultData[0].freeClass1, 'NUMBER'));
      // フリー区分２
      colList.push(CreateColRow('free_class_2', this.resultData[0].freeClass2, 'VARCHAR'));
      // 更新ユーザー
      const colUser = await getUserCol(this.username, 'update')
      /* WHERE句 */
      const sql = `${CreateUpdateSql(colList.concat(colUser), 'm_form_counter')} `
                  + `WHERE counter_class = ${this.resultData[0].counterClass} `
                  + `AND free_class_1 = ${this.resultData[0].freeClass1} `
                  + `AND free_class_2 = '${this.resultData[0].freeClass2}' `; 
      const SQLs = [sql];

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return false;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }

      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs
        }, error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: SQLs,
          result: result
        });
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
        return false;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      let logLevel = 'Info';
      if (body.error) {
        logLevel = 'Error';
        this.alertDanger.push(DISP_MESSAGES.DANGER['3003']);
      }
      await addOperationLogs(logLevel, MODULE_NAME, functionName, {
        graphqlOperation: 'executeTransactSql',
        SQLs: SQLs,
        'result.data.executeTransactSql': {
          statusCode: result.data.executeTransactSql.statusCode,
          body: body
        }
      });
      return true;
    },
    /* 帳票カウンタマスタ内重複チェック */
    async checkCounter() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += 'form_counter.count_min'; // カウント（最小）
      selectSql += ',form_counter.count_max'; // カウント（最大）
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 'm_form_counter AS form_counter ';
      selectSql += `WHERE counter_class = ${this.$route.query.reqCounterClass} ` 
      selectSql += `AND free_class_1 <> ${this.$route.query.reqSalesOffice} ` 
      selectSql += `AND free_class_2 = '${this.$route.query.reqTransactionId}' ` 

      let dataResult = await executeSelectSql(selectSql);
      // 別営業所の最小No・最大Noの範囲内の場合はfalseで返す
      for (const data of dataResult) {
        if(((data.count_min <= this.minNo && this.minNo <= data.count_max)
         || (data.count_min <= this.maxNo && this.maxNo <= data.count_max))){
          return false
        }
      }
      return true;
    },
    // キャンセルボタン
    cancel: function() {
      this.$router.push({ name: 'SLIP-NO-MAINTENANCE'});
    },
    /* テーブル初期化処理 */
    initProductTable: function() {
      this.productList = [];
      this.addTableRow();
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertWarning = [];
      this.alertDanger = [];
      this.alertSuccess = [];
    },
  }
}
</script>
<style scoped>
/* アペンドテキストの背景色を透明にする */
.input-group>.input-group-append>.input-group-text{
  background-color: transparent;
}
</style>