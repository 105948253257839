<template>
  <!-- 仕入先別買掛実績表 -->
  <div>
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="p-4 min-vh-85">
      <b-row class="mt-2">
        <b-col>
          <b-card no-body>
            <b-card-body class="p-2">
              <h5>
                <a v-b-toggle.collapse-1 title="クリックすると出力範囲指定を表示/非表示できます。"><span class="oi oi-magnifying-glass"></span> 出力範囲指定</a>
                <b-button size="sm" v-b-tooltip.hover title="印刷" @click="windowPrint" class="ml-2 float-right" style="font-size: 60%; width: 28px;">
                  <span class="oi oi-print"></span>
                </b-button>
              </h5>
              <b-collapse id="collapse-1" :visible="true" class="mt-4">
                <validation-observer ref="observer">
                  <b-container fluid>
                    <b-row v-if="alertSuccess.length">
                      <b-col>
                        <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                          <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                            <li>{{error}}</li>
                          </ul>
                        </b-alert>
                      </b-col>
                    </b-row>
                    <b-row v-if="validationMessage.length">
                      <b-col>
                        <b-alert show variant="warning" class="mt-2">
                          <ul v-for="(message, index) in validationMessage" :key="index" style="list-style: none;">
                            <li>{{ message }}</li>
                          </ul>
                        </b-alert>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="4" lg="3" xl="2">
                        <b-form-group label="年月" description="集計対象年月です。">
                          <validation-provider rules="required" v-slot="{ classes, errors }">
                            <div :class="classes">
                              <b-input type="month" id="monthYear" v-model="searchConditions.monthYear"></b-input>
                              <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                            </div>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="8" lg="9" xl="10">
                        <b-form-group label="仕入先コード" description="仕入先コードの範囲を指定します。">
                          <b-row>
                            <b-col>
                              <validation-provider :rules="{numeric:true,max:6,between:[0,999999],consistency:searchConditions.supplierIdTo}" v-slot="{ classes, errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-input id="supplierIdFrom" v-model="searchConditions.supplierIdFrom" maxlength="6"></b-input>
                                    <b-input-group-text @click="showClientSearchModal(true);" v-b-tooltip.hover title="「ALT+1」ボタンで呼出可能">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                        <button type="button" v-shortkey="['alt', '1']" @shortkey="showClientSearchModal(true);" class="d-none"></button>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-col>
                            <b-col cols="1"><div class="pt-2 text-center">～</div></b-col>
                            <b-col>
                              <validation-provider rules="numeric|max:6|between:0,999999" v-slot="{ classes, errors }">
                                <div :class="classes">
                                  <b-input-group>
                                    <b-input type="text" v-model="searchConditions.supplierIdTo" maxlength="6"></b-input>
                                    <b-input-group-text @click="showClientSearchModal(false);" v-b-tooltip.hover title="「ALT+2」ボタンで呼出可能">
                                      <b-button size="sm" variant="light" style="background: none; border: none; padding: 0px;">
                                        <span class="oi oi-magnifying-glass"></span>
                                        <button type="button" v-shortkey="['alt', '2']" @shortkey="showClientSearchModal(false);" class="d-none"></button>
                                      </b-button>
                                    </b-input-group-text>
                                  </b-input-group>
                                  <span id="error" v-if="errors[0]">{{ errors[0] }}</span>
                                </div>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row align-h="center" class="mt-2">
                      <b-col sm="5" lg="4" xl="3" class="pb-2">
                        <!-- 検索ボタン -->
                        <b-button class="btn-block" pill variant="success" @click="onSearchButtonClick"><span class="oi oi-magnifying-glass"></span> 検索</b-button>
                      </b-col>
                      <b-col sm="5" lg="4" xl="3" class="pb-2">
                        <!-- 作表ボタン -->
                        <b-button class="btn-block" pill variant="success" @click="onPrintButtonClick"><span class="oi oi-document"></span> 作表</b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </validation-observer>
              </b-collapse>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <div id="resultArea" class="border px-4 py-3 mb-2 bg-white">
            <b-alert show variant="warning" class="mt-2" v-if="dangerMessages.length">
              <ul v-for="(message, index) in dangerMessages" :key="index" style="list-style: none;">
                <li>{{ message }}</li>
              </ul>
            </b-alert>
            <div class="row">
              <div class="col-sm-12">
                <b-row>
                  <!-- 1ページあたりの表示選択 -->
                  <b-col lg="6" class="my-1">
                    <b-form-group
                      label="1ページあたりの表示件数"
                      label-for="per-page-select"
                      label-cols-sm="4"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                    </b-form-group>
                  </b-col>
                  <!-- 検索結果検索 -->
                  <b-col lg="6" class="my-1">
                    <b-form-group
                      label="Filter"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input id="filter-input" v-model="filter" type="search"></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- 検索結果 -->
                <b-row>
                  <b-col cols="12" class="px-0">
                    <b-table
                      :bordered="true"
                      :busy="isBusy"
                      :current-page="currentPage"
                      :fields="fields"
                      :filter="filter"
                      foot-clone
                      :items="items"
                      :per-page="perPage"
                      :responsive="true"
                      show-empty
                      @filtered="onFiltered"
                    >
                      <!-- テーブル読み込み時表示html -->
                      <template #table-busy>
                        <div class="text-center text-info my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>読み込んでいます...</strong>
                        </div>
                      </template>
                      <!-- 仕入先 -->
                      <template #head(supplier_id)>
                        <div>仕入先</div>
                      </template>
                      <template #cell(supplier_id)="data">
                        <div>{{data.item.supplier_id}}&emsp;{{data.item.client_name_kanji}}</div>
                      </template>
                      <template #foot(supplier_id)>
                        <div>＜&nbsp;&nbsp;合&nbsp;&nbsp;計&nbsp;&nbsp;＞</div>
                      </template>
                      <!-- 買掛税抜残高・買掛消費税残高・仕入額 -->
                      <template #head(last_month_payable_no_tax_balance_result)>
                        <div>買掛税抜残高</div>
                        <div>買掛消費税残高</div>
                        <div>仕入額</div>
                      </template>
                      <template #cell(last_month_payable_no_tax_balance_result)="data">
                        <div>{{data.item.last_month_payable_no_tax_balance_result.toLocaleString()}}</div>
                        <div>{{data.item.last_month_payable_tax_balance_result.toLocaleString()}}</div>
                        <div>{{data.item.monthly_payable.toLocaleString()}}</div>
                      </template>
                      <template #foot(last_month_payable_no_tax_balance_result)>
                        <div>{{total.last_month_payable_no_tax_balance_result.toLocaleString()}}</div>
                        <div>{{total.last_month_payable_tax_balance_result.toLocaleString()}}</div>
                        <div>{{total.monthly_payable.toLocaleString()}}</div>
                      </template>
                      <!-- 現金支払額・小切手支払額・振込支払額 -->
                      <template #head(monthly_payment)>
                        <div>現金支払額</div>
                        <div>小切手支払額</div>
                        <div>振込支払額</div>
                      </template>
                      <template #cell(monthly_payment)="data">
                        <div>{{data.item.monthly_payment.toLocaleString()}}</div>
                        <div>{{data.item.monthly_check_payment.toLocaleString()}}</div>
                        <div>{{data.item.monthly_transfer_payment.toLocaleString()}}</div>
                      </template>
                      <template #foot(monthly_payment)>
                        <div>{{total.monthly_payment.toLocaleString()}}</div>
                        <div>{{total.monthly_check_payment.toLocaleString()}}</div>
                        <div>{{total.monthly_transfer_payment.toLocaleString()}}</div>
                      </template>
                      <!-- 手形支払額・相殺支払額・その他支払額 -->
                      <template #head(monthly_bill_payment)>
                        <div>手形支払額</div>
                        <div>相殺支払額</div>
                        <div>その他支払額</div>
                      </template>
                      <template #cell(monthly_bill_payment)="data">
                        <div>{{data.item.monthly_bill_payment.toLocaleString()}}</div>
                        <div>{{data.item.monthly_offset_payment.toLocaleString()}}</div>
                        <div>{{data.item.monthly_other_payment.toLocaleString()}}</div>
                      </template>
                      <template #foot(monthly_bill_payment)>
                        <div>{{total.monthly_bill_payment.toLocaleString()}}</div>
                        <div>{{total.monthly_offset_payment.toLocaleString()}}</div>
                        <div>{{total.monthly_other_payment.toLocaleString()}}</div>
                      </template>
                      <!-- 消費税支払額・一括消費税額・伝票消費税額 -->
                      <template #head(monthly_tax_payment)>
                        <div>消費税支払額</div>
                        <div>一括消費税額</div>
                        <div>伝票消費税額</div>
                      </template>
                      <template #cell(monthly_tax_payment)="data">
                        <div>{{data.item.monthly_tax_payment.toLocaleString()}}</div>
                        <div>{{data.item.monthly_bulk_tax.toLocaleString()}}</div>
                        <div>{{data.item.monthly_billing_tax.toLocaleString()}}</div>
                      </template>
                      <template #foot(monthly_tax_payment)>
                        <div>{{total.monthly_tax_payment.toLocaleString()}}</div>
                        <div>{{total.monthly_bulk_tax.toLocaleString()}}</div>
                        <div>{{total.monthly_billing_tax.toLocaleString()}}</div>
                      </template>
                      <!-- 消費税額 -->
                      <template #head(monthly_tax)>
                        <div>消費税額</div>
                      </template>
                      <template #cell(monthly_tax)="data">
                        <div>{{data.item.monthly_tax.toLocaleString()}}</div>
                      </template>
                      <template #foot(monthly_tax)>
                        <div>{{total.monthly_tax.toLocaleString()}}</div>
                      </template>
                      <!-- 操作 -->
                      <template #head(operation)>
                        <div>操作</div>
                      </template>
                      <template #cell(operation)="data">
                        <div>
                          <b-button size="sm" @click="onMentainanceButtonClick(data)"><span class="oi oi-pencil"></span> 買掛実績・残高保守</b-button> 
                        </div>
                      </template>
                      <template #foot(operation)>
                        <!-- 何も出力しません -->
                        {{''}}
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
                <!-- 表示レコードをラベル表示 -->
                <b-row>
                  <b-col lg="6">
                    <b-form-group
                      :label="getPagingMessage"
                      class="mt-0 mb-0"
                    />
                  </b-col>
                </b-row>
                <!-- テーブルページネーション -->
                <b-row>
                  <b-col class="my-1">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="filter != null ? filterRows : items.length"
                      :per-page="perPage == -1 ? items.length : perPage"
                      align="center"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
    <ClientSearch @from-child="closeClientSearchModal" :client-class="2"/>
  </div>    
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import ClientSearch from '@/components/modal/client-search.vue';
import DataTblDef from '@/assets/js/dataTableDef.js';
import { addOperationLogs, windowPrint, init, selectOneTable } from '@/assets/js/common.js';
import { API, graphqlOperation } from 'aws-amplify';
import { executeTransactSql } from '@/graphql/mutations';
import { DISP_MESSAGES } from '@/assets/js/messages';

// モジュール名
const MODULE_NAME = 'accounts-payable-supply-list';
// テーブル項目テンプレート
const TBALE_ITEMS_TEMPLATE = {
  monthYear: '', // 年月
  supplier_id: '', // 仕入先コード
  client_name_kanji: '', // 取引先名（漢字）
  last_month_payable_no_tax_balance_result: 0, // 前月税抜残高
  last_month_payable_tax_balance_result: 0, // 前月消費税残高
  monthly_payable: 0, // 月次仕入額
  monthly_payment: 0, // 現金支払額
  monthly_check_payment: 0, // 小切手支払額
  monthly_transfer_payment: 0, // 振込支払額
  monthly_bill_payment: 0, // 手形支払額
  monthly_offset_payment: 0, // 相殺支払額
  monthly_other_payment: 0, // その他支払額
  monthly_tax_payment: 0, // 消費税支払額
  monthly_bulk_tax: 0, // 一括消費税額
  monthly_billing_tax: 0, // 伝票消費税額
  monthly_tax: 0 // 消費税額
};
// テーブル最大レコード数
const TABLE_ITEMS_MAX = 1000;

export default {
  name: 'ACCOUNTS-PAYABLE-SUPPLY-LIST',
  components: {
    Header,
    Footer,
    ClientSearch
  },
  data() {
    return {
      menu_type: 'user',
      title: '仕入先別買掛実績表',
      // 検索条件
      searchConditions: {
        monthYear: '',
        supplierIdFrom: '',
        supplierIdTo: ''
      },
      // 開始仕入先コード側で取引先選択モーダルを呼び出したかどうかフラグ
      isSupplierIdFrom: true,
      // 成功メッセージ
      alertSuccess: [],
      // バリデーションエラーメッセージ
      validationMessage: [],
      // 検索エラーメッセージ
      dangerMessages: [],
      // テーブルに表示するデータ
      items: [],
      // テーブルフッターに表示する総合計
      total: { ...TBALE_ITEMS_TEMPLATE },
      // テーブルフィールド定義
      fields: DataTblDef.accounts_payable_supply_list_fields,
      // テーブルビジーフラグ
      isBusy: false,
      // テーブルフィルターキーワード
      filter: null,
      // 表示件数のdefault値
      perPage: DataTblDef.perPage,
      // 一ページあたりの表示件数の選択群
      pageOptions: DataTblDef.pageOptions,
      // フィルタリングデータの総件数
      filterRows: 0,
      // ページネーションの初期表示位置
      currentPage: DataTblDef.currentPage,
      // プリントアウト状態
      printStatus: false,
    };
  },
  computed: {
    /* ページの表示件数 */
    getPagingMessage() {
      const tableLength = (this.filter != null) ? this.filterRows : this.items.length;
      if (tableLength === 0) {
        return '';
      }
      let start = 1;
      let end = tableLength;
      if (this.perPage !== -1) {
        end = this.currentPage * this.perPage;
        start = end - this.perPage + 1;
        if (end > tableLength) {
          end = tableLength;
        }
      }
      return `${tableLength} 件中 ${start} から ${end} まで表示`;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  mounted() {
    init(); // common.jsにて初期化処理
    // 保守画面から成功メッセージ
    if (this.$route.query.successMessageId) {
      this.alertSuccess.push(DISP_MESSAGES.SUCCESS[this.$route.query.successMessageId]);
    }
    scrollTo(0, 0);
    this.$store.commit('setLoading', false);
  },
  methods: {
    windowPrint: function() {
      this.printStatus = true
      this.$nextTick(() => {
        windowPrint(document, window, this)
      })
    },
    /**
     * フィルター時のイベント
     * @param {Object} filteredItems - フィルターされた項目
     */
    onFiltered(filteredItems) {
      this.filterRows= filteredItems.length;
      this.currentPage= DataTblDef.currentPage;

      const newTotal = { ...TBALE_ITEMS_TEMPLATE };
      for (const item of filteredItems) {
        newTotal.last_month_payable_no_tax_balance_result += item.last_month_payable_no_tax_balance_result;
        newTotal.last_month_payable_tax_balance_result += item.last_month_payable_tax_balance_result;
        newTotal.monthly_payable += item.monthly_payable;
        newTotal.monthly_payment += item.monthly_payment;
        newTotal.monthly_check_payment += item.monthly_check_payment;
        newTotal.monthly_transfer_payment += item.monthly_transfer_payment;
        newTotal.monthly_bill_payment += item.monthly_bill_payment;
        newTotal.monthly_offset_payment += item.monthly_offset_payment;
        newTotal.monthly_other_payment += item.monthly_other_payment;
        newTotal.monthly_tax_payment += item.monthly_tax_payment;
        newTotal.monthly_bulk_tax += item.monthly_bulk_tax;
        newTotal.monthly_billing_tax += item.monthly_billing_tax;
        newTotal.monthly_tax += item.monthly_tax;
      }
      this.total = newTotal;
    },
    /**
     * 取引先選択モーダルを開きます。
     * @param {Number} isSelectedSupplierId - 開始仕入先コードはtrue、終了仕入先コードはfalse
     */
    showClientSearchModal(isSelectedSupplierId) {
      this.isSelectedSupplierId = isSelectedSupplierId;
      this.$bvModal.show('clientSearchModal');
    },
    /**
     * 取引先選択モーダルを閉じます。
     * @param {Object} item - 選択された取引先データ
     */
    closeClientSearchModal(item){
      if (this.isSelectedSupplierId) {
        this.searchConditions.supplierIdFrom = item.detail.client_id;
      } else {
        this.searchConditions.supplierIdTo = item.detail.client_id;
      }
    },
    /**
     * 検索ボタンクリックイベント処理
     */
    async onSearchButtonClick() {
      this.alertSuccess = [];
      this.validationMessage = [];
      this.dangerMessages = [];

      if (await this.$refs.observer.validate()) {
        this.isBusy = true;
        await this.search();
        this.isBusy = false;
      } else {
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }
    },
    /**
     * 整合性チェックを行います。
     * @returns {Boolean} 整合性に問題が無い場合はtrue、問題がある場合はfalse
     */
    async checkConsistency() {
      // コントロールマスタから現在処理年月を取得
      const processMonthYear = await this.getProcessMonthYear();
      if (processMonthYear === null) {
        this.dangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }

      // 画面で選択された処理年月がコントロールマスタの処理年月以降の場合はNG
      const monthYear = this.getMonthYear();
      if (processMonthYear <= monthYear) {
        const monthYearString = `${Math.floor(monthYear / 100)}年${monthYear % 100}月`;
        this.validationMessage.push(
          DISP_MESSAGES.WARNING['2012'].replace('%arg1%', monthYearString)
        );
        return false;
      }

      return true;
    },
    /**
     * コントロールマスタから現在処理年月を取得します。
     * @returns {Number} 現在処理年月
     */
    async getProcessMonthYear() {
      const functionName = 'getProcessMonthYear';
      let result = null;
      try {
        result = await selectOneTable('m_control');
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control'
        }, error);
        return null;
      }
      if (result == null || result.length == 0) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_control',
          result: result
        });
        return null;
      }
      return result[0].process_month_year;
    },
    /**
     * 画面の検索条件で仕入先別買掛実績一覧データを検索し、テーブルに結果を表示します。
     */
    async search() {
      const functionName = 'search'; 

      // SQL文を作成
      const sql = this.createSQL();
      const sqls = [ sql ];

      // 検索
      let result = null;
      try {
        result = await API.graphql(graphqlOperation(executeTransactSql, { SQLs: sqls }));
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls
        }, error);
        this.dangerMessages.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }
      if (result.errors) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.dangerMessages.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }
      const body = JSON.parse(result.data.executeTransactSql.body);
      if (body.error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'executeTransactSql',
          SQLs: sqls,
          result: result
        });
        this.dangerMessages.push(DISP_MESSAGES.WARNING['2001']);
        return;
      }

      this.setTableData(body.data[0]);
    },
    /**
     * 画面の検索条件で買掛実績・残高マスタを検索するSQL文を作成します。
     * @returns {String} SQL文
     */
    createSQL() {
      const whereClauses = [];
      // 処理年月
      whereClauses.push(`tpbr.month_year = ${this.getMonthYear()}`);
      // 得意先コード
      if (this.searchConditions.supplierIdFrom !== '' && this.searchConditions.supplierIdTo !== '') {
        whereClauses.push(`tpbr.supplier_id BETWEEN ${this.searchConditions.supplierIdFrom} AND ${this.searchConditions.supplierIdTo}`);
      } else if (this.searchConditions.supplierIdFrom !== '') {
        whereClauses.push(`tpbr.supplier_id >= ${this.searchConditions.supplierIdFrom}`);
      } else if (this.searchConditions.supplierIdTo !== '') {
        whereClauses.push(`tpbr.supplier_id <= ${this.searchConditions.supplierIdTo}`);
      }

      return 'SELECT' +
        ' tpbr.month_year' +
        ',tpbr.supplier_id' +
        ',tpbr.last_month_payable_no_tax_balance_result' +
        ',tpbr.last_month_payable_tax_balance_result' +
        ',tpbr.monthly_payable' +
        ',tpbr.monthly_payment' +
        ',tpbr.monthly_check_payment' +
        ',tpbr.monthly_transfer_payment' +
        ',tpbr.monthly_bill_payment' +
        ',tpbr.monthly_offset_payment' +
        ',tpbr.monthly_other_payment' +
        ',tpbr.monthly_tax_payment' +
        ',tpbr.monthly_bulk_tax' +
        ',tpbr.monthly_billing_tax' +
        ',tpbr.monthly_tax' +
        ',mc.client_name_kanji ' +
        'FROM t_payables_balances_results tpbr' +
        ' JOIN m_clients mc ON mc.client_class = 2 AND tpbr.supplier_id = mc.client_id ' +
        `WHERE ${whereClauses.join(' AND ')} ` +
        `ORDER BY tpbr.supplier_id ASC LIMIT ${TABLE_ITEMS_MAX}`;
    },
    /**
     * 買掛実績・残高保守開くボタンクリックイベント処理
     * @param {Object} data - 選択された買掛実績・残高マスタデータ
     */
    onMentainanceButtonClick(data) {
      let route = this.$router.resolve({ name: 'PAYABLE-BALANCE-RESULT-MAINTENANCE', query: { monthYear: data.item.month_year, supplierId: data.item.supplier_id } });
      window.open(route.href, '_blank');
    },
    /**
     * 画面で入力された年月を取得して数値で返します。
     * @returns {Number} 年月
     */
    getMonthYear() {
      return Number(this.searchConditions.monthYear.replace('-', ''));
    },
    /**
     * 作表ボタンクリックイベント処理
     */
    async onPrintButtonClick() {
      this.alertSuccess = [];
      this.validationMessage = [];
      this.dangerMessages = [];
      var newWin = window.open('', '_blank');
      if (await this.$refs.observer.validate()) {
        if (await this.checkConsistency()) {
          // 仕入先別買掛実績表印刷プレビュー画面へ遷移します。
          let route = this.$router.resolve({
            name: 'ACCOUNTS-PAYABLE-SUPPLY-LIST-PRINT',
            query: {
              monthYear: this.getMonthYear(),
              supplierIdFrom: this.searchConditions.supplierIdFrom,
              supplierIdTo: this.searchConditions.supplierIdTo
            }
          });
          newWin.location = route.href
        } else {
          newWin.close()
          // ios safari ための対応
          setTimeout(() => {
            scrollTo(0,0)
          }, 500);
        }
      } else {
        newWin.close()
        document.querySelector('#error:first-of-type').scrollIntoView({
          block: 'center',
          inline: 'nearest'
        });        
      }
    },
    /**
     * 買掛実績・残高マスタから取得したデータでテーブルに表示するデータを作成します。
     * @pram {Array} records - 買掛実績・残高マスタから取得したデータ
     */
    setTableData(records) {
      const total = { ...TBALE_ITEMS_TEMPLATE };

      const newItems = [];
      for (const record of records) {
        // 仕入先コード
        record.supplierId = String(record.supplier_id).padStart(6, '0');
        // 買掛税抜残高
        total.last_month_payable_no_tax_balance_result = record.last_month_payable_no_tax_balance_result;
        // 買掛消費税残高
        total.lastMonthPayableTaxBalanceResult += record.last_month_payable_tax_balance_result;
        // 仕入額
        total.monthly_payable += record.monthly_payable;
        // 現金支払額
        total.monthly_payment += record.monthly_payment;
        // 小切手支払額
        total.monthly_check_payment += record.monthly_check_payment;
        // 振込支払額
        total.monthly_transfer_payment += record.monthly_transfer_payment;
        // 手形支払額
        total.monthly_bill_payment += record.monthly_bill_payment;
        // 相殺支払額
        total.monthly_offset_payment += record.monthly_offset_payment;
        // その他支払額
        total.monthly_other_payment += record.monthly_other_payment;
        // 消費税支払額
        total.monthly_tax_payment += record.monthly_tax_payment;
        // 一括消費税額
        total.monthly_bulk_tax = record.monthly_bulk_tax;
        // 伝票消費税額
        total.monthly_billing_tax += record.monthly_billing_tax;
        // 消費税額
        total.monthly_tax += record.monthly_tax;

        newItems.push(record);
      }

      this.items = newItems;
      this.total = total;

      // 検索結果が1000件の場合は1000件を超えている場合があるのでメッセージを表示します。
      if (newItems.length === TABLE_ITEMS_MAX) {
        this.dangerMessages.push(DISP_MESSAGES.WARNING['2002']);
      }
    }
  }
}
</script>
