<template>
  <div>
    <div v-if="getMessageFlg == true">
      <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
        <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
      <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
        <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
          <li>{{error}}</li>
        </ul>
      </b-alert>
    </div>
    <div v-for="chouhyou in listChouhyou" v-bind:key="chouhyou.page">
      <TemplateSeikyuList :id="constData.chouhyouId + chouhyou.page" />
    </div>
  </div>
</template>
<script>
import TemplateSeikyuList from '@/assets/svg/seikyuList.svg';
import { setPaperA4Landscape, addOperationLogs, setChouhyouBodyStyle, formatDate, formatCurDate, getListValue, executeSelectSql, getNullStr, getControlMaster, getClosingDate, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import Const from '@/assets/js/const.js';

const MODULE_NAME = 'seikyu-list';

export default {
  name: 'SEIKYU-LIST',
  /* コンポーネント */
  components: {
    TemplateSeikyuList,
  },
  /* データ */
  data() {
    return {
      // 定数
      constData: {
        cntNormal: 14,
        addCnt: 1,
        chouhyouId: 'idChouhyou',
        textLength_130: 130,
        textLength_160: 160,
        textLength_180: 180,
        textLength_230: 230,
        textLength_281: 281,
        textLength_338: 338,
        textLength_430: 430,
        textLength_826: 826,
      },
      // ヘッダ
      menu_type: 'user',
      title: '請求一覧表（帳票）',
      // 表示帳票のサイズ（A4）
      // 以下のサイズで画面に表示されるように調整
      chouhyouSize: {
        width: '445mm',
        height: '314mm',
      },
      // 合計値用
      totalData: {
        productSalesUnitAmount: 0,
        productTax: 0,
        billingMoney: 0,
        billingTax: 0,
        preBilling: 0,
        twoPreBilling: 0,
        threePreBilling: 0,
        amount: 0,
        amountTax: 0,
        forward: 0,
        forwardTax: 0,
      },
      addProductCnt: 0,
      listChouhyou: [],
      replacementsCommon: [],
      billingEndDateMax: '',
      // アラート
      alertWarning: [],
      alertDanger: [],
      // パラメータ
      billingMonthYear: null,
      clientIdStart: null,
      clientIdEnd: null,
      billingOutputClass: null,
      billingPaymentClass: 0,
      invoiceIssueClass: 0,
      webIssueClass: 0,
      invoiceIssueWay: Const.InvoiceIssueWay.all,
      invoiceListCalcClass: Const.InvoiceListCalcClass.all,
      // テーブル名
      billingsTableName: '',
      // 請求前情報
      preBilling: {
        processMonthYear: 0,
        closingDate: 0,
        billingStartDate: '',
        billingEndDate: '',
      },
      // コントロールマスタの現在処理年月
      controlMasterData: {
        processMonthYear: 0,
        taxRate: null,
        newTaxRate: null,
        newTaxStartDate: '',
        lightTaxRate: null,
        newLightTaxRate: null,
      },
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      return this.alertWarning.length > 0 || this.alertDanger.length > 0
    },
  },
  /* マウント */
  mounted() {
    // パラメータ取得
    this.billingMonthYear = this.$route.query.billingMonthYear;
    this.clientIdStart = this.$route.query.clientIdStart;
    this.clientIdEnd = this.$route.query.clientIdEnd;
    this.billingOutputClass = this.$route.query.billingOutputClass;
    this.invoiceIssueClass = this.$route.query.invoiceIssueClass;
    this.webIssueClass = this.$route.query.webIssueClass;
    this.invoiceIssueWay = this.$route.query.invoiceIssueWay;
    this.invoiceListCalcClass = this.$route.query.invoiceListCalcClass;
    if (this.$route.query.billingPaymentClass == Const.BillingPaymentClass.billing2) {
      this.billingPaymentClass = Const.BillingPaymentClass.billing2;
      this.billingsTableName = 'm_billings2';
    } else {
      this.billingPaymentClass = Const.BillingPaymentClass.billing;
      this.billingsTableName = 'm_billings';
    }
    // 印刷レイアウト設定
    setPaperA4Landscape();
    // 帳票のbodyタグのスタイル設定
    setChouhyouBodyStyle();
    // 初期設定
    this.fetchData();
    // 印刷ファイルのデフォルト名
    document.title='請求一覧表_' + this.billingMonthYear;
  },
  /* 関数群 */
  methods:{
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 表示データ設定
        await this.setDispData();
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* 表示データ設定 */
    async setDispData() {
      // 請求日（開始日と終了日）の取得
      await this.getBillingDateInfo();
      // 検索条件作成
      let selectSql = '';
      selectSql = await this.makeSelectSql();
      //console.log(selectSql);
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult == null) {
        dataResult = [];
      }
      await this.setTotalData(dataResult);
      if (dataResult != null &&
      dataResult.length > 0) {
        if (this.invoiceIssueWay != Const.InvoiceIssueWay.all) {
          for (let i = 0; i < dataResult.length; i++) {
            if (this.invoiceIssueWay == Const.InvoiceIssueWay.allIssue) {
              // 全伝票同時発行取引先
              if (dataResult[i].invoice_issue_way == Const.InvoiceIssueWay.partIssue) {
                // 先行発行した取引先は除外
                dataResult.splice(i, 1);
                i--;
              }
            } else if (this.invoiceIssueWay == Const.InvoiceIssueWay.partIssue) {
              // 一部伝票先行発行取引先
              if (dataResult[i].invoice_issue_way == Const.InvoiceIssueWay.allIssue) {
                // 先行発行した取引先以外を除外
                dataResult.splice(i, 1);
                i--;
              }
            }
          }
        }
      }
      if (dataResult != null &&
      dataResult.length > 0) {
        // 合計分の件数
        this.addProductCnt = this.constData.addCnt;
        // 件数からどの帳票テンプレートを用いるか、何ページ用意するか等を設定
        await this.initListChouhyou(dataResult.length + this.addProductCnt);
        // 共通の置換文字列設定
        await this.createReplacementsCommon(dataResult);
        // ページ毎の置換文字列設定
        await this.createReplacementsPage(dataResult);
        // 作成した置換文字データをSVGファイルに設定
        await this.setChouhyou();
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2010'].replace('%arg1%','請求一覧表'));
      }
    },
    /* SELECT文字列作成 */
    async makeSelectSql() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT ';
      selectSql += ' billings_QUERY.billing_end_date';
      selectSql += ',billings_QUERY.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',billings_QUERY.product_sales_unit_amount';
      selectSql += ',billings_QUERY.closing_date_normal_tax + billings_QUERY.closing_date_light_tax AS product_tax';
      selectSql += ',billings_QUERY.pre_billing_no_tax_balance';
      selectSql += ',billings_QUERY.pre_billing_tax_balance';
      selectSql += ',billings_QUERY.two_pre_billing_money_no_tax';
      selectSql += ',billings_QUERY.two_pre_billing_money_tax';
      selectSql += ',billings_QUERY.three_pre_billing_money_no_tax';
      selectSql += ',billings_QUERY.three_pre_billing_money_tax';
      selectSql += ',billings_QUERY.amount_no_tax';
      selectSql += ',billings_QUERY.amount_tax';
      selectSql += ',IfNull(billings_QUERY.payment_scheduled,';
      selectSql += '   CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '     THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '     ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   END';
      selectSql += ' ) AS payment_scheduled';
      selectSql += ',clients.business_class';
      selectSql += ',clients.billing_class';
      selectSql += ',billings_QUERY.closing_date_flg';
      selectSql += ',CASE WHEN billings_issue_input_billing_no.client_id IS NULL AND billings_issue_input_site.client_id IS NULL THEN ' + Const.InvoiceIssueWay.allIssue + ' ELSE ' + Const.InvoiceIssueWay.partIssue + ' END AS invoice_issue_way';
      // FROM句
      selectSql += ' FROM ';
      selectSql += '(' + await this.makeMainQuery() + ') AS billings_QUERY ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND billings_QUERY.client_id = clients.client_id ';
      if (this.billingOutputClass != '') {
        selectSql += 'AND IfNull(billings_QUERY.billing_output_class,clients.billing_output_class) = ' + this.billingOutputClass + ' ';
      }
      if (this.webIssueClass != '') {
        selectSql += 'AND clients.web_issue_class = ' + this.webIssueClass + ' ';
      }
      if (this.invoiceIssueClass == Const.InvoiceIssueClass.issue) {
        selectSql += 'INNER JOIN t_billings_issue_input AS billings_issue_input ';
      } else {
        selectSql += 'LEFT JOIN t_billings_issue_input AS billings_issue_input ';
      }
      selectSql += 'ON billings_issue_input.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND billings_QUERY.client_id = billings_issue_input.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON clients.staff_id = staffs.staff_id ';
      selectSql += 'LEFT JOIN (SELECT client_id FROM t_billings_issue_input_billing_no WHERE ';
      selectSql += 'billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'GROUP BY client_id';
      selectSql += ') AS billings_issue_input_billing_no ';
      selectSql += 'ON billings_QUERY.client_id = billings_issue_input_billing_no.client_id ';
      selectSql += 'LEFT JOIN (SELECT client_id FROM t_billings_issue_input_site WHERE ';
      selectSql += 'billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'GROUP BY client_id';
      selectSql += ') AS billings_issue_input_site ';
      selectSql += 'ON billings_QUERY.client_id = billings_issue_input_site.client_id ';
      // WHERE句
      if (this.invoiceIssueClass == Const.InvoiceIssueClass.noIssue) {
        selectSql += ' WHERE ';
        selectSql += ' billings_issue_input.billing_month_year IS NULL ';
      }
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' billings_QUERY.client_id ';

      return selectSql;
    },
    // メインクエリ作成
    async makeMainQuery() {
      /* 請求締更新前の処理月情報あり、または、なし、当月情報あり（取引先別） */
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT ';
      selectSql1 += ' \'' + this.preBilling.billingEndDate + '\' AS billing_end_date';
      selectSql1 += ',billings.client_id';
      selectSql1 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      selectSql1 += ',0 AS pre_billing_no_tax_balance';
      selectSql1 += ',0 AS pre_billing_tax_balance';
      selectSql1 += ',0 AS two_pre_billing_money_no_tax';
      selectSql1 += ',0 AS two_pre_billing_money_tax';
      selectSql1 += ',0 AS three_pre_billing_money_no_tax';
      selectSql1 += ',0 AS three_pre_billing_money_tax';
      selectSql1 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN 0 ELSE cumulative_transaction.amount END) AS amount_no_tax';
      selectSql1 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN cumulative_transaction.amount ELSE 0 END) AS amount_tax';
      selectSql1 += ',NULL AS payment_scheduled';
      selectSql1 += ',' + Const.BillingOutputClass.client + ' AS billing_output_class';
      selectSql1 += ',1 AS closing_date_flg ';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += this.billingsTableName + ' AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0 OR cumulative_transaction.amount <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id IN (\'' + Const.TransactionId.sales + '\',\'' + Const.TransactionId.deposit + '\') ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      selectSql1 += 'billings.closing_date = ' + this.preBilling.closingDate + ' ';
      // 得意先コード
      if (this.clientIdStart != '' && this.clientIdEnd == '') {
        selectSql1 += 'AND billings.client_id >= ' + this.clientIdStart + ' ';
      } else if(this.clientIdStart == '' && this.clientIdEnd != '') {
        selectSql1 += 'AND billings.client_id <= ' + this.clientIdEnd + ' ';
      } else if(this.clientIdStart != '' && this.clientIdEnd != '') {
        selectSql1 += 'AND billings.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      // 請求書出力単位区分
      selectSql1 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.client + ' ';
      // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外（入金は除外しない）
      selectSql1 += 'AND (billings_issue_input_billing_no.billing_no IS NULL OR cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\') ';
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings.client_id';
      /* 当月情報あり（現場別） */
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT ';
      selectSql2 += ' \'' + this.preBilling.billingEndDate + '\' AS billing_end_date';
      selectSql2 += ',billings_site_QUERY.client_id';
      selectSql2 += ',SUM(billings_site_QUERY.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_normal_tax) AS closing_date_normal_tax';
      selectSql2 += ',SUM(billings_site_QUERY.closing_date_light_tax) AS closing_date_light_tax';
      selectSql2 += ',0 AS pre_billing_no_tax_balance';
      selectSql2 += ',0 AS pre_billing_tax_balance';
      selectSql2 += ',0 AS two_pre_billing_money_no_tax';
      selectSql2 += ',0 AS two_pre_billing_money_tax';
      selectSql2 += ',0 AS three_pre_billing_money_no_tax';
      selectSql2 += ',0 AS three_pre_billing_money_tax';
      selectSql2 += ',SUM(billings_site_QUERY.amount_no_tax) AS amount_no_tax';
      selectSql2 += ',SUM(billings_site_QUERY.amount_tax) AS amount_tax';
      selectSql2 += ',NULL AS payment_scheduled';
      selectSql2 += ',' + Const.BillingOutputClass.clientSite + ' AS billing_output_class';
      selectSql2 += ',1 AS closing_date_flg ';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += '(' + this.makeSelectSqlSiteQuery() + ') AS billings_site_QUERY ';
      /* GROUP BY句 */
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings_site_QUERY.client_id';
      /* 請求締更新後 */
      let selectSql4 = '';
      /* SELECT句 */
      selectSql4 += 'SELECT ';
      selectSql4 += ' billings_balances.billing_end_date';
      selectSql4 += ',billings_balances.client_id';
      if (this.invoiceListCalcClass == Const.InvoiceListCalcClass.all) {
        // 全請求書合計
        selectSql4 += ',billings_balances.closing_date_receivable_sales AS product_sales_unit_amount';
        selectSql4 += ',billings_balances.closing_date_normal_tax';
        selectSql4 += ',billings_balances.closing_date_light_tax';
      } else {
        // 先行発行請求書を除外した合計
        selectSql4 += ',billings_balances.closing_date_receivable_sales_pre_exclude AS product_sales_unit_amount';
        selectSql4 += ',billings_balances.closing_date_normal_tax_pre_exclude AS closing_date_normal_tax';
        selectSql4 += ',billings_balances.closing_date_light_tax_pre_exclude AS closing_date_light_tax';
      }
      selectSql4 += ',billings_balances.pre_billing_no_tax_balance';
      selectSql4 += ',billings_balances.pre_billing_tax_balance';
      selectSql4 += ',billings_balances.two_pre_billing_money_no_tax';
      selectSql4 += ',billings_balances.two_pre_billing_money_tax';
      selectSql4 += ',billings_balances.three_pre_billing_money_no_tax';
      selectSql4 += ',billings_balances.three_pre_billing_money_tax';
      selectSql4 += ',billings_balances.closing_date_payment AS amount_no_tax';
      selectSql4 += ',billings_balances.closing_date_tax_payment AS amount_tax';
      selectSql4 += ',billings_balances.payment_scheduled';
      selectSql4 += ',billings_balances.billing_output_class';
      selectSql4 += ',0 AS closing_date_flg ';
      /* FROM句 */
      selectSql4 += ' FROM ';
      selectSql4 += 't_billings_balances AS billings_balances ';
      /* WHERE句 */
      selectSql4 += ' WHERE ';
      // 請求年月
      selectSql4 += 'billings_balances.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      // 請求開始日
      selectSql4 += 'AND billings_balances.billing_start_date IS NOT NULL ';
      // 現場コード
      selectSql4 += 'AND billings_balances.site_id = 0 ';
      // 得意先コード
      if(this.clientIdStart != '' && this.clientIdEnd == ''){
        selectSql4 += 'AND billings_balances.client_id >= ' + this.clientIdStart + ' ';
      }else if(this.clientIdStart == '' && this.clientIdEnd != ''){
        selectSql4 += 'AND billings_balances.client_id <= ' + this.clientIdEnd + ' ';
      }else if(this.clientIdStart != '' && this.clientIdEnd != ''){
        selectSql4 += 'AND billings_balances.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      if (this.invoiceListCalcClass == Const.InvoiceListCalcClass.exclude) {
        // 先行発行請求書を除外した合計
        selectSql4 += 'AND (billings_balances.pre_billing_no_tax_balance <> 0 OR billings_balances.pre_billing_tax_balance <> 0 OR billings_balances.two_pre_billing_money_no_tax <> 0 OR billings_balances.two_pre_billing_money_tax <> 0 OR billings_balances.three_pre_billing_money_no_tax <> 0 OR billings_balances.three_pre_billing_money_tax <> 0 ';
        selectSql4 += 'OR billings_balances.closing_date_receivable_sales_pre_exclude <> 0 OR billings_balances.closing_date_normal_tax_pre_exclude <> 0 OR billings_balances.closing_date_light_tax_pre_exclude <> 0 OR billings_balances.closing_date_payment <> 0 OR billings_balances.closing_date_tax_payment <> 0) ';
      }
      /* GROUP BY句 */
      selectSql4 += ' GROUP BY ';
      selectSql4 += ' billings_balances.client_id';

      let selectSql = '';
      if (this.preBilling.processMonthYear > 0 && this.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 画面から選択した年月が請求前処理の年月と同じ場合
        // 3つのSELECT文をUNION
        selectSql = selectSql1 + ' UNION ALL ' + selectSql2 + ' UNION ALL ' + selectSql4;
      } else {
        // 画面から選択した年月が請求前処理の年月と異なる場合
        // 請求残高の登録情報のみを表示
        selectSql = selectSql4;
      }

      return selectSql;
    },
    // 検索SELECT文字列作成（現場別クエリ）
    makeSelectSqlSiteQuery: function() {
      // 現場別取引先の売上情報
      let selectSql1 = '';
      /* SELECT句 */
      selectSql1 += 'SELECT ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',SUM(cumulative_transaction.product_sales_unit_amount) AS product_sales_unit_amount';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.newTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.normalTax + ' THEN ' + this.controlMasterData.taxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_normal_tax';
      selectSql1 += ',TRUNCATE((SUM(';
      selectSql1 += '   CASE WHEN (cumulative_transaction.sales_tax_class = ' + Const.SalesTaxClass.outTax + ')';
      selectSql1 += '     THEN cumulative_transaction.product_sales_unit_amount * (CASE WHEN \'' + this.controlMasterData.newTaxStartDate + '\' <= cumulative_transaction.billing_date THEN ';
      selectSql1 += '       (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.newLightTaxRate + ' ELSE 0 END)';
      selectSql1 += '     ELSE (CASE products.product_tax_rate_class_sales WHEN ' + Const.ProductTaxRateClass.lightTax + ' THEN ' + this.controlMasterData.lightTaxRate + ' ELSE 0 END) END)';
      selectSql1 += '   ELSE 0 END) / 100),0)';
      selectSql1 += ' AS closing_date_light_tax';
      selectSql1 += ',0 AS amount_no_tax';
      selectSql1 += ',0 AS amount_tax';
      /* FROM句 */
      selectSql1 += ' FROM ';
      selectSql1 += this.billingsTableName + ' AS billings ';
      selectSql1 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql1 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql1 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql1 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql1 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql1 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql1 += 'AND (cumulative_transaction.product_sales_unit_amount <> 0) ';
      selectSql1 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.sales + '\' ';
      selectSql1 += 'LEFT JOIN m_products AS products ';
      selectSql1 += 'ON cumulative_transaction.product_id = products.product_id ';
      selectSql1 += 'LEFT JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSql1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql1 += 'AND cumulative_transaction.billing_no = billings_issue_input_billing_no.billing_no ';
      selectSql1 += 'LEFT JOIN t_billings_issue_input_site AS billings_issue_input_site ';
      selectSql1 += 'ON billings_issue_input_site.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql1 += 'AND cumulative_transaction.client_id = billings_issue_input_site.client_id ';
      selectSql1 += 'AND cumulative_transaction.site_id = billings_issue_input_site.site_id ';
      /* WHERE句 */
      selectSql1 += ' WHERE ';
      selectSql1 += 'billings.closing_date = ' + this.preBilling.closingDate + ' ';
      // 得意先コード
      if (this.clientIdStart != '' && this.clientIdEnd == '') {
        selectSql1 += 'AND billings.client_id >= ' + this.clientIdStart + ' ';
      } else if(this.clientIdStart == '' && this.clientIdEnd != '') {
        selectSql1 += 'AND billings.client_id <= ' + this.clientIdEnd + ' ';
      } else if(this.clientIdStart != '' && this.clientIdEnd != '') {
        selectSql1 += 'AND billings.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      // 請求書出力単位区分
      selectSql1 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
      // 請求書発行登録＿伝票番号毎テーブルに登録されている伝票は除外
      selectSql1 += 'AND billings_issue_input_billing_no.billing_no IS NULL ';
      // 請求書発行登録＿現場毎テーブルに登録されている現場は除外
      selectSql1 += 'AND billings_issue_input_site.site_id IS NULL ';
      /* GROUP BY句 */
      selectSql1 += ' GROUP BY ';
      selectSql1 += ' billings.client_id';
      selectSql1 += ',billings.site_id';
      // 現場別取引先の入金情報
      let selectSql2 = '';
      /* SELECT句 */
      selectSql2 += 'SELECT ';
      selectSql2 += ' billings.client_id';
      selectSql2 += ',0 AS product_sales_unit_amount';
      selectSql2 += ',0 AS closing_date_normal_tax';
      selectSql2 += ',0 AS closing_date_light_tax';
      selectSql2 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN 0 ELSE cumulative_transaction.amount END) AS amount_no_tax';
      selectSql2 += ',SUM(CASE WHEN cumulative_transaction.kind IN (\'' + Const.denomination.offsetTax + '\',\'' + Const.denomination.billsTax + '\') OR (cumulative_transaction.kind = \'' + Const.denomination.tax + '\' AND cumulative_transaction.account_support <> \'' + Const.TaxCommission + '\') THEN cumulative_transaction.amount ELSE 0 END) AS amount_tax';
      /* FROM句 */
      selectSql2 += ' FROM ';
      selectSql2 += this.billingsTableName + ' AS billings ';
      selectSql2 += 'INNER JOIN t_cumulative_transaction AS cumulative_transaction ';
      selectSql2 += 'ON cumulative_transaction.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql2 += 'AND billings.client_id = cumulative_transaction.client_id ';
      selectSql2 += 'AND billings.site_id = cumulative_transaction.site_id ';
      selectSql2 += 'AND cumulative_transaction.billing_date BETWEEN \'' + this.preBilling.billingStartDate + '\' AND \'' + this.preBilling.billingEndDate + '\' ';
      selectSql2 += 'AND cumulative_transaction.is_update_closing_date = 0 ';
      selectSql2 += 'AND (cumulative_transaction.amount <> 0) ';
      selectSql2 += 'AND cumulative_transaction.transaction_id = \'' + Const.TransactionId.deposit + '\' ';
      /* WHERE句 */
      selectSql2 += ' WHERE ';
      selectSql2 += 'billings.closing_date = ' + this.preBilling.closingDate + ' ';
      // 得意先コード
      if (this.clientIdStart != '' && this.clientIdEnd == '') {
        selectSql2 += 'AND billings.client_id >= ' + this.clientIdStart + ' ';
      } else if(this.clientIdStart == '' && this.clientIdEnd != '') {
        selectSql2 += 'AND billings.client_id <= ' + this.clientIdEnd + ' ';
      } else if(this.clientIdStart != '' && this.clientIdEnd != '') {
        selectSql2 += 'AND billings.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      // 請求書出力単位区分
      selectSql2 += 'AND billings.billing_output_class = ' + Const.BillingOutputClass.clientSite + ' ';
      // 現場コード
      selectSql2 += 'AND billings.site_id = 0 ';
      /* GROUP BY句 */
      selectSql2 += ' GROUP BY ';
      selectSql2 += ' billings.client_id';
      let selectSql = '';
      // 2つのSELECT文をUNION
      selectSql = selectSql1 + ' UNION ALL ' + selectSql2;

      return selectSql;
    },
    /* 帳票リスト初期化 */
    async initListChouhyou(productCnt){
      const functionName = 'initListChouhyou';
      try {
        //console.log('initListChouhyou');
        if (productCnt <= this.constData.cntNormal) {
          // 1ページで収まる場合
          this.listChouhyou.push({page: 1, replacements: []});
        } else {
          // 1ページで収まらない場合
          let productCntNokori = productCnt;
          let page = 1;
          // 残り件数がなくなるまでループ
          while (productCntNokori > this.constData.cntNormal) {
            // 残りの製品件数が最終帳票の件数に収まらない場合、中間帳票を出力し続ける
            this.listChouhyou.push({page: page, replacements: []});
            productCntNokori -= this.constData.cntNormal;
            page++;
          }
          // 終了帳票
          this.listChouhyou.push({page: page, replacements: []});
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 共通の置換配列セット */
    async createReplacementsCommon(result){
      const functionName = 'createReplacementsCommon';
      try {
        //console.log('createReplacementsCommon');
        // タイトル
        this.replacementsCommon.push({key: '%タイトル%', value: '請　求　一　覧　表　' + formatDate(this.billingMonthYear + '-01', 'YYYY/MM'), textAnchor: 'middle', textLength: this.constData.textLength_826, chkWidth: false});
        // 締切日
        this.billingEndDateMax = result[0].billing_end_date;
        for (let i = 1; i < result.length; i++) {
          if (this.billingEndDateMax < result[i].billing_end_date) {
            this.billingEndDateMax = result[i].billing_end_date;
          }
        }
        this.replacementsCommon.push({key: '%締切日%', value: formatDate(this.billingEndDateMax), textAnchor: 'start', textLength: this.constData.textLength_281, chkWidth: true});
        // 現在日時
        this.replacementsCommon.push({key: '%現在日時%', value: formatCurDate('YYYY/MM/DD HH:mm'), textAnchor: 'start', textLength: this.constData.textLength_338, chkWidth: true});
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 得意先毎の置換配列セット */
    async createReplacementsPage(result){
      const functionName = 'createReplacementsPage';
      try {
        //console.log('createReplacementsPage');
        let clientsIndex = 0;
        let whiteFlg = false;
        for (let i = 0; i < this.listChouhyou.length; i++) {
          // SVGファイルの置換用文字列
          let replacements = [];
          // ページ番号
          replacements.push({key: '%P%', value: this.listChouhyou[i].page + ' / ' + this.listChouhyou.length, textAnchor: 'end', textLength: this.constData.textLength_160, chkWidth: false});
  
          // 得意先
          for (let j = 0; j < this.constData.cntNormal; j++){
            if(clientsIndex < result.length){
              // 得意先コード
              replacements.push({key: '%得意' + (j + 1).toString() + '%', value: result[clientsIndex].client_id, textAnchor: 'start', textLength: this.constData.textLength_180, chkWidth: false});
              // 得意先名
              replacements.push({key: '%得意先名' + (j + 1).toString() + '%', value: result[clientsIndex].client_name_kanji, textAnchor: 'start', textLength: this.constData.textLength_430, chkWidth: true});
              // 担当者コード
              replacements.push({key: '%担当' + (j + 1).toString() + '%', value: result[clientsIndex].staff_id, textAnchor: 'start', textLength: this.constData.textLength_180, chkWidth: false});
              // 担当者名
              replacements.push({key: '%担当者名' + (j + 1).toString() + '%', value: result[clientsIndex].staff_name_kanji, textAnchor: 'start', textLength: this.constData.textLength_430, chkWidth: true});
              // 売上
              replacements.push({key: '%売上税抜' + (j + 1).toString() + '%', value: Number(result[clientsIndex].product_sales_unit_amount).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              replacements.push({key: '%売上消税' + (j + 1).toString() + '%', value: Number(result[clientsIndex].product_tax).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              replacements.push({key: '%売上合計' + (j + 1).toString() + '%', value: (Number(result[clientsIndex].product_sales_unit_amount) + Number(result[clientsIndex].product_tax)).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              this.totalData.productSalesUnitAmount += Number(result[clientsIndex].product_sales_unit_amount);
              this.totalData.productTax += Number(result[clientsIndex].product_tax);
              // 今回請求
              replacements.push({key: '%今請税抜' + (j + 1).toString() + '%', value: (Number(result[clientsIndex].product_sales_unit_amount) + (Number(result[clientsIndex].billing_class) == Const.BillingClass.output ? Number(result[clientsIndex].pre_billing_no_tax_balance) - Number(result[clientsIndex].amount_no_tax) : 0)).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              replacements.push({key: '%今請消税' + (j + 1).toString() + '%', value: (Number(result[clientsIndex].product_tax) + (Number(result[clientsIndex].billing_class) == Const.BillingClass.output ? Number(result[clientsIndex].pre_billing_tax_balance) - Number(result[clientsIndex].amount_tax) : 0)).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              replacements.push({key: '%今請合計' + (j + 1).toString() + '%', value: ((Number(result[clientsIndex].product_sales_unit_amount) + Number(result[clientsIndex].product_tax)) + (Number(result[clientsIndex].billing_class) == Const.BillingClass.output ? (Number(result[clientsIndex].pre_billing_no_tax_balance) - Number(result[clientsIndex].amount_no_tax)) + (Number(result[clientsIndex].pre_billing_tax_balance) - Number(result[clientsIndex].amount_tax)) : 0)).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              this.totalData.billingMoney += (Number(result[clientsIndex].product_sales_unit_amount) + (Number(result[clientsIndex].billing_class) == Const.BillingClass.output ? Number(result[clientsIndex].pre_billing_no_tax_balance) - Number(result[clientsIndex].amount_no_tax) : 0));
              this.totalData.billingTax += (Number(result[clientsIndex].product_tax) + (Number(result[clientsIndex].billing_class) == Const.BillingClass.output ? Number(result[clientsIndex].pre_billing_tax_balance) - Number(result[clientsIndex].amount_tax) : 0));
              // 前請求
              replacements.push({key: '%１前請求' + (j + 1).toString() + '%', value: (Number(result[clientsIndex].pre_billing_no_tax_balance) + Number(result[clientsIndex].pre_billing_tax_balance)).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              replacements.push({key: '%２前請求' + (j + 1).toString() + '%', value: (Number(result[clientsIndex].two_pre_billing_money_no_tax) + Number(result[clientsIndex].two_pre_billing_money_tax)).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              replacements.push({key: '%３前請求' + (j + 1).toString() + '%', value: (Number(result[clientsIndex].three_pre_billing_money_no_tax) + Number(result[clientsIndex].three_pre_billing_money_tax)).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              this.totalData.preBilling += (Number(result[clientsIndex].pre_billing_no_tax_balance) + Number(result[clientsIndex].pre_billing_tax_balance));
              this.totalData.twoPreBilling += (Number(result[clientsIndex].two_pre_billing_money_no_tax) + Number(result[clientsIndex].two_pre_billing_money_tax));
              this.totalData.threePreBilling += (Number(result[clientsIndex].three_pre_billing_money_no_tax) + Number(result[clientsIndex].three_pre_billing_money_tax));
              // 入金額
              replacements.push({key: '%入金税抜' + (j + 1).toString() + '%', value: Number(result[clientsIndex].amount_no_tax).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              replacements.push({key: '%入金消税' + (j + 1).toString() + '%', value: Number(result[clientsIndex].amount_tax).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              replacements.push({key: '%入金合計' + (j + 1).toString() + '%', value: (Number(result[clientsIndex].amount_no_tax) + Number(result[clientsIndex].amount_tax)).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              this.totalData.amount += Number(result[clientsIndex].amount_no_tax);
              this.totalData.amountTax += Number(result[clientsIndex].amount_tax);
              // 繰越額
              replacements.push({key: '%繰越税抜' + (j + 1).toString() + '%', value: (Number(result[clientsIndex].pre_billing_no_tax_balance) - Number(result[clientsIndex].amount_no_tax)).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              replacements.push({key: '%繰越消税' + (j + 1).toString() + '%', value: (Number(result[clientsIndex].pre_billing_tax_balance) - Number(result[clientsIndex].amount_tax)).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              replacements.push({key: '%繰越合計' + (j + 1).toString() + '%', value: ((Number(result[clientsIndex].pre_billing_no_tax_balance) - Number(result[clientsIndex].amount_no_tax)) + (Number(result[clientsIndex].pre_billing_tax_balance) - Number(result[clientsIndex].amount_tax))).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              this.totalData.forward += (Number(result[clientsIndex].pre_billing_no_tax_balance) - Number(result[clientsIndex].amount_no_tax));
              this.totalData.forwardTax += (Number(result[clientsIndex].pre_billing_tax_balance) - Number(result[clientsIndex].amount_tax));
              // 入金予定
              replacements.push({key: '%入金予定' + (j + 1).toString() + '%', value: formatDate(result[clientsIndex].payment_scheduled), textAnchor: 'start', textLength: this.constData.textLength_230, chkWidth: false});
              // 金種
              replacements.push({key: '%種' + (j + 1).toString() + '%', value: getListValue(Const.businessClassList, result[clientsIndex].business_class.toString()), textAnchor: 'start', textLength: this.constData.textLength_130, chkWidth: false});
              clientsIndex++;
            }else{
              // 得意先コード
              replacements.push({key: '%得意' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_180, chkWidth: false});
              // 得意先名
              replacements.push({key: '%得意先名' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_430, chkWidth: false});
              // 担当者コード
              replacements.push({key: '%担当' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_180, chkWidth: false});
              // 入金予定
              replacements.push({key: '%入金予定' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_230, chkWidth: false});
              // 金種
              replacements.push({key: '%種' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_130, chkWidth: false});
              if (whiteFlg == true) {
                // 担当者名
                replacements.push({key: '%担当者名' + (j + 1).toString() + '%', value: '', textAnchor: 'start', textLength: this.constData.textLength_430, chkWidth: false});
                // 売上
                replacements.push({key: '%売上税抜' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%売上消税' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%売上合計' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                // 今回請求
                replacements.push({key: '%今請税抜' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%今請消税' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%今請合計' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                // 前請求
                replacements.push({key: '%１前請求' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%２前請求' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%３前請求' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                // 入金額
                replacements.push({key: '%入金税抜' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%入金消税' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%入金合計' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                // 繰越額
                replacements.push({key: '%繰越税抜' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%繰越消税' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%繰越合計' + (j + 1).toString() + '%', value: '', textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
              } else {
                // 担当者名
                replacements.push({key: '%担当者名' + (j + 1).toString() + '%', value: '＜　合　計　＞', textAnchor: 'start', textLength: this.constData.textLength_430, chkWidth: false});
                // 売上
                replacements.push({key: '%売上税抜' + (j + 1).toString() + '%', value: this.totalData.productSalesUnitAmount.toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%売上消税' + (j + 1).toString() + '%', value: this.totalData.productTax.toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%売上合計' + (j + 1).toString() + '%', value: (this.totalData.productSalesUnitAmount + this.totalData.productTax).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                // 今回請求
                replacements.push({key: '%今請税抜' + (j + 1).toString() + '%', value: this.totalData.billingMoney.toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%今請消税' + (j + 1).toString() + '%', value: this.totalData.billingTax.toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%今請合計' + (j + 1).toString() + '%', value: (this.totalData.billingMoney + this.totalData.billingTax).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                // 前請求
                replacements.push({key: '%１前請求' + (j + 1).toString() + '%', value: this.totalData.preBilling.toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%２前請求' + (j + 1).toString() + '%', value: this.totalData.twoPreBilling.toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%３前請求' + (j + 1).toString() + '%', value: this.totalData.threePreBilling.toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                // 入金額
                replacements.push({key: '%入金税抜' + (j + 1).toString() + '%', value: this.totalData.amount.toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%入金消税' + (j + 1).toString() + '%', value: this.totalData.amountTax.toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%入金合計' + (j + 1).toString() + '%', value: (this.totalData.amount + this.totalData.amountTax).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                // 繰越額
                replacements.push({key: '%繰越税抜' + (j + 1).toString() + '%', value: this.totalData.forward.toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%繰越消税' + (j + 1).toString() + '%', value: this.totalData.forwardTax.toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                replacements.push({key: '%繰越合計' + (j + 1).toString() + '%', value: (this.totalData.forward + this.totalData.forwardTax).toLocaleString(), textAnchor: 'end', textLength: this.constData.textLength_230, chkWidth: false});
                whiteFlg = true;
              }
            }
          }
          this.listChouhyou[i].replacements = replacements;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 帳票に各種値セット */
    async setChouhyou(){
      const functionName = 'setChouhyou';
      try {
        //console.log('setChouhyou');
        for (let i = 0; i < this.listChouhyou.length; i++){
          let svgDoc = document.getElementById(this.constData.chouhyouId + this.listChouhyou[i].page);
          await this.setReplacements(svgDoc, this.listChouhyou[i].replacements);
          await this.setSize(svgDoc);
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 置換値をSVGファイルに設定 */
    async setReplacements(node, replacements){
      const functionName = 'setReplacements';
      try {
        //console.log('setReplacements');
        for(let i = 0; i < node.children.length; i++){
          if(node.children[i].tagName == 'text'){
            for(let j = 0; j < node.children[i].children.length; j++){
              if(node.children[i].children[j].tagName == 'tspan'){
                for(let k = 0; k < this.replacementsCommon.length; k++){
                  if(node.children[i].children[j].innerHTML.indexOf(this.replacementsCommon[k].key) != -1){
                    await this.setTspan(node.children[i].children[j], this.replacementsCommon[k]);
                    break;
                  }
                }
                for(let k = 0; k < replacements.length; k++){
                  if(node.children[i].children[j].innerHTML.indexOf(replacements[k].key) != -1){
                    await this.setTspan(node.children[i].children[j], replacements[k]);
                    replacements.splice(k, 1);
                    break;
                  }
                }
              }
            }
          } else if(node.children[i].tagName == 'g'){
            await this.setReplacements(node.children[i], replacements);
          }
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* Textタグ内のテキストを設定 */
    async setTspan(tagTspan, config){
      const functionName = 'setTspan';
      try {
        // 文字を置換
        tagTspan.innerHTML = tagTspan.innerHTML.trim().replace(config.key, getNullStr(config.value));
        /* 最大長を設定（最大長を超過する場合、自動で縮小） */
        if (config.chkWidth == true &&
          tagTspan.getBBox().width > config.textLength) {
          tagTspan.setAttribute('textLength', config.textLength);
          tagTspan.setAttribute('lengthAdjust', 'spacingAndGlyphs');
        }
        let colX = parseFloat(tagTspan.getAttribute('x'));
        /* 中央寄せ、右寄せを設定 */
        // 中央寄せ
        if(config.textAnchor == 'middle'){
          tagTspan.setAttribute('x', colX + config.textLength / 2);
        }
        // 右寄せ
        if(config.textAnchor == 'end'){
          tagTspan.setAttribute('x', colX + config.textLength);
        }
        tagTspan.setAttribute('text-anchor', config.textAnchor);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    /* 取得結果セット */
    async setSize(svgDoc){
      const functionName = 'setSize';
      try {
        // viewBoxに元のサイズを設定
        const zoomedViewBox = [0, 0, svgDoc.clientWidth, svgDoc.clientHeight].join(' ');
        svgDoc.setAttribute('viewBox', zoomedViewBox);
        // 横幅と高さをパラメータで指定したサイズに修正
        svgDoc.setAttribute('width', this.chouhyouSize.width);
        svgDoc.setAttribute('height', this.chouhyouSize.height);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
    },
    // 請求日（開始日と終了日）の取得
    async getBillingDateInfo() {
      // 各種データ取得（非同期でまとめて取得した方が早いため）
      let preBillingResult = null;
      let controlData = null;
      let where_clause = 'AND billing_payment_class = ' + this.billingPaymentClass + ' ';
      [preBillingResult, controlData] = await Promise.all([
        selectOneTable('t_closing_update_range', where_clause),
        getControlMaster(),
      ]);
      this.controlMasterData.processMonthYear = controlData.process_month_year;
      this.controlMasterData.taxRate = controlData.tax_rate;
      this.controlMasterData.newTaxRate = controlData.new_tax_rate;
      this.controlMasterData.newTaxStartDate = controlData.new_tax_start_date;
      this.controlMasterData.lightTaxRate = controlData.light_tax_rate;
      this.controlMasterData.newLightTaxRate = controlData.new_light_tax_rate;
      if (preBillingResult != null && preBillingResult.length > 0) {
        this.preBilling.processMonthYear = preBillingResult[0].process_month_year;
        this.preBilling.closingDate = preBillingResult[0].closing_date;
        this.preBilling.billingStartDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, true);
        this.preBilling.billingEndDate = getClosingDate(this.preBilling.processMonthYear, this.preBilling.closingDate, false);
      } else {
        this.preBilling.processMonthYear = 0;
        this.preBilling.closingDate = 0;
        this.preBilling.billingStartDate = '';
        this.preBilling.billingEndDate = '';
      }
    },
    // 検索SELECT文字列作成（前月情報）
    makeSelectSqlPreBillingBalance: function() {
      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' \'' + this.preBilling.billingEndDate + '\' AS billing_end_date';
      selectSql += ',billings.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',0 AS product_sales_unit_amount';
      selectSql += ',0 AS product_tax';
      selectSql += ',billings_balances.pre_billing_no_tax_balance';
      selectSql += ',billings_balances.pre_billing_tax_balance';
      selectSql += ',billings_balances.two_pre_billing_money_no_tax';
      selectSql += ',billings_balances.two_pre_billing_money_tax';
      selectSql += ',billings_balances.three_pre_billing_money_no_tax';
      selectSql += ',billings_balances.three_pre_billing_money_tax';
      selectSql += ',0 AS amount_no_tax';
      selectSql += ',0 AS amount_tax';
      selectSql += ',CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '   ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += ' END AS payment_scheduled';
      selectSql += ',clients.business_class';
      selectSql += ',clients.billing_class';
      selectSql += ',billings.billing_output_class';
      selectSql += ',billings_issue_input.billing_issue_input_date';
      selectSql += ',1 AS closing_date_flg';
      selectSql += ',' + Const.InvoiceIssueWay.allIssue + ' AS invoice_issue_way';
      // FROM句
      selectSql += ' FROM ';
      selectSql += this.billingsTableName + ' AS billings ';
      selectSql += 'INNER JOIN t_billings_balances AS billings_balances ';
      selectSql += 'ON billings.client_id = billings_balances.client_id ';
      selectSql += 'AND billings_balances.site_id = 0 ';
      selectSql += 'AND billings_balances.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND billings_balances.billing_start_date IS NULL ';
      selectSql += 'AND (billings_balances.pre_billing_no_tax_balance <> 0 OR billings_balances.pre_billing_tax_balance <> 0 OR billings_balances.two_pre_billing_money_no_tax <> 0 OR billings_balances.two_pre_billing_money_tax <> 0 OR billings_balances.three_pre_billing_money_no_tax <> 0 OR billings_balances.three_pre_billing_money_tax <> 0) ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND billings.client_id = clients.client_id ';
      if (this.billingOutputClass != '') {
        selectSql += 'AND billings.billing_output_class = ' + this.billingOutputClass + ' ';
      }
      if (this.webIssueClass != '') {
        selectSql += 'AND clients.web_issue_class = ' + this.webIssueClass + ' ';
      }
      if (this.invoiceIssueClass == Const.InvoiceIssueClass.issue) {
        selectSql += 'INNER JOIN t_billings_issue_input AS billings_issue_input ';
      } else {
        selectSql += 'LEFT JOIN t_billings_issue_input AS billings_issue_input ';
      }
      selectSql += 'ON billings_issue_input.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND billings.client_id = billings_issue_input.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON clients.staff_id = staffs.staff_id ';
      // WHERE句
      selectSql += ' WHERE ';
      selectSql += 'billings.site_id = 0 ';
      // 得意先コード
      if (this.clientIdStart != '' && this.clientIdEnd == '') {
        selectSql += 'AND billings.client_id >= ' + this.clientIdStart + ' ';
      } else if(this.clientIdStart == '' && this.clientIdEnd != '') {
        selectSql += 'AND billings.client_id <= ' + this.clientIdEnd + ' ';
      } else if(this.clientIdStart != '' && this.clientIdEnd != '') {
        selectSql += 'AND billings.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      if (this.invoiceIssueClass == Const.InvoiceIssueClass.noIssue) {
        selectSql += 'AND billings_issue_input.billing_month_year IS NULL ';
      }
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' billings.client_id';

      return selectSql;
    },
    // 検索SELECT文字列作成（先行請求書発行情報）
    makeSelectSqlSeparate: function() {
      let selectSqlQuery1 = '';
      // SELECT句
      selectSqlQuery1 += 'SELECT';
      selectSqlQuery1 += ' billings.client_id';
      selectSqlQuery1 += ',MAX(billings_issue_input_billing_no.sales_unit_amount) AS sales_unit_amount';
      selectSqlQuery1 += ',MAX(billings_issue_input_billing_no.normal_tax) AS normal_tax';
      selectSqlQuery1 += ',MAX(billings_issue_input_billing_no.light_tax) AS light_tax';
      selectSqlQuery1 += ',billings.billing_output_class';
      // FROM句
      selectSqlQuery1 += ' FROM ';
      selectSqlQuery1 += this.billingsTableName + ' AS billings ';
      selectSqlQuery1 += 'INNER JOIN t_billings_issue_input_billing_no AS billings_issue_input_billing_no ';
      selectSqlQuery1 += 'ON billings_issue_input_billing_no.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSqlQuery1 += 'AND billings.client_id = billings_issue_input_billing_no.client_id ';
      selectSqlQuery1 += 'AND billings.site_id = billings_issue_input_billing_no.site_id ';
      // WHERE句
      // 得意先コード
      let whereSql = '';
      if (this.clientIdStart != '' && this.clientIdEnd == '') {
        whereSql += 'AND billings.client_id >= ' + this.clientIdStart + ' ';
      } else if(this.clientIdStart == '' && this.clientIdEnd != '') {
        whereSql += 'AND billings.client_id <= ' + this.clientIdEnd + ' ';
      } else if(this.clientIdStart != '' && this.clientIdEnd != '') {
        whereSql += 'AND billings.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      if (this.billingOutputClass != '') {
        whereSql += 'AND billings.billing_output_class = ' + this.billingOutputClass + ' ';
      }
      if (whereSql != '') {
        selectSqlQuery1 += ' WHERE ';
        selectSqlQuery1 += whereSql.substr(3);
      }
      // GROUP BY句
      selectSqlQuery1 += ' GROUP BY ';
      selectSqlQuery1 += ' billings.client_id';
      selectSqlQuery1 += ',billings_issue_input_billing_no.separate_invoice_no';

      let selectSqlQuery2 = '';
      // SELECT句
      selectSqlQuery2 += 'SELECT';
      selectSqlQuery2 += ' billings.client_id';
      selectSqlQuery2 += ',billings_issue_input_site.sales_unit_amount';
      selectSqlQuery2 += ',billings_issue_input_site.normal_tax';
      selectSqlQuery2 += ',billings_issue_input_site.light_tax';
      selectSqlQuery2 += ',billings.billing_output_class';
      // FROM句
      selectSqlQuery2 += ' FROM ';
      selectSqlQuery2 += this.billingsTableName + ' AS billings ';
      selectSqlQuery2 += 'INNER JOIN t_billings_issue_input_site AS billings_issue_input_site ';
      selectSqlQuery2 += 'ON billings_issue_input_site.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSqlQuery2 += 'AND billings.client_id = billings_issue_input_site.client_id ';
      selectSqlQuery2 += 'AND billings.site_id = billings_issue_input_site.site_id ';
      // WHERE句
      // 得意先コード
      whereSql = '';
      if (this.clientIdStart != '' && this.clientIdEnd == '') {
        whereSql += 'AND billings.client_id >= ' + this.clientIdStart + ' ';
      } else if(this.clientIdStart == '' && this.clientIdEnd != '') {
        whereSql += 'AND billings.client_id <= ' + this.clientIdEnd + ' ';
      } else if(this.clientIdStart != '' && this.clientIdEnd != '') {
        whereSql += 'AND billings.client_id BETWEEN ' + this.clientIdStart + ' ' + 'AND ' + this.clientIdEnd + ' ';
      }
      if (this.billingOutputClass != '') {
        whereSql += 'AND billings.billing_output_class = ' + this.billingOutputClass + ' ';
      }
      if (whereSql != '') {
        selectSqlQuery2 += ' WHERE ';
        selectSqlQuery2 += whereSql.substr(3);
      }

      let selectSqlQuery = selectSqlQuery1 + ' UNION ALL ' + selectSqlQuery2;

      let selectSql = '';
      // SELECT句
      selectSql += 'SELECT';
      selectSql += ' \'' + this.preBilling.billingEndDate + '\' AS billing_end_date';
      selectSql += ',subQuery.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',SUM(subQuery.sales_unit_amount) AS product_sales_unit_amount';
      selectSql += ',SUM(subQuery.normal_tax) + SUM(subQuery.light_tax) AS product_tax';
      selectSql += ',0 AS pre_billing_no_tax_balance';
      selectSql += ',0 AS pre_billing_tax_balance';
      selectSql += ',0 AS two_pre_billing_money_no_tax';
      selectSql += ',0 AS two_pre_billing_money_tax';
      selectSql += ',0 AS three_pre_billing_money_no_tax';
      selectSql += ',0 AS three_pre_billing_money_tax';
      selectSql += ',0 AS amount_no_tax';
      selectSql += ',0 AS amount_tax';
      selectSql += ',CASE WHEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH)) <= DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += '   THEN LAST_DAY(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH))';
      selectSql += '   ELSE DATE_ADD(DATE_FORMAT(DATE_ADD(\'' + this.preBilling.billingEndDate + '\', INTERVAL clients.payment_class MONTH), \'%Y-%m-01\'), INTERVAL clients.payment_scheduled - 1 DAY)';
      selectSql += ' END AS payment_scheduled';
      selectSql += ',clients.business_class';
      selectSql += ',clients.billing_class';
      selectSql += ',subQuery.billing_output_class';
      selectSql += ',billings_issue_input.billing_issue_input_date';
      selectSql += ',1 AS closing_date_flg';
      selectSql += ',' + Const.InvoiceIssueWay.partIssue + ' AS invoice_issue_way';
      // FROM句
      selectSql += ' FROM ';
      selectSql += '(' + selectSqlQuery + ') AS subQuery ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON clients.client_class = ' + Const.ClientClass.customer + ' ';
      selectSql += 'AND subQuery.client_id = clients.client_id ';
      if (this.webIssueClass != '') {
        selectSql += 'AND clients.web_issue_class = ' + this.webIssueClass + ' ';
      }
      if (this.invoiceIssueClass == Const.InvoiceIssueClass.issue) {
        selectSql += 'INNER JOIN t_billings_issue_input AS billings_issue_input ';
      } else {
        selectSql += 'LEFT JOIN t_billings_issue_input AS billings_issue_input ';
      }
      selectSql += 'ON billings_issue_input.billing_month_year = ' + this.billingMonthYear.replace(/-/g,'') + ' ';
      selectSql += 'AND subQuery.client_id = billings_issue_input.client_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON clients.staff_id = staffs.staff_id ';
      // WHERE句
      if (this.invoiceIssueClass == Const.InvoiceIssueClass.noIssue) {
        selectSql += ' WHERE ';
        selectSql += ' billings_issue_input.billing_month_year IS NULL ';
      }
      // GROUP BY句
      selectSql += ' GROUP BY ';
      selectSql += ' subQuery.client_id';
      // ORDER BY句
      selectSql += ' ORDER BY ';
      selectSql += ' subQuery.client_id';

      return selectSql;
    },
    // 合計データに設定（前月分データ、先行発行登録伝票データ）
    async setTotalData(dataResultTotal) {
      if (this.preBilling.processMonthYear > 0 && this.billingMonthYear == formatDate(this.preBilling.processMonthYear + '01', 'YYYY-MM')) {
        // 指定した年月が請求マスタの範囲と同じ場合
        // 請求残高から前月分の情報を取得、請求書発行登録済みの伝票番号情報を取得
        let dataResultPreBillingBalance = null;
        let dataResultSeparate = null;
        let selectSqlPreBillingBalance = this.makeSelectSqlPreBillingBalance();
        //console.log(selectSqlPreBillingBalance);
        let selectSqlSeparate = this.makeSelectSqlSeparate();
        //console.log(selectSqlSeparate);
        [dataResultPreBillingBalance, dataResultSeparate] = await Promise.all([
          executeSelectSql(selectSqlPreBillingBalance),
          executeSelectSql(selectSqlSeparate),
        ]);
        //console.log(dataResultPreBillingBalance);
        //console.log(dataResultSeparate);
        if (dataResultPreBillingBalance != null && dataResultPreBillingBalance.length > 0) {
          // 前月分の情報があった場合
          let totalIndex = 0;
          for (let i = 0; i < dataResultPreBillingBalance.length; i++) {
            if (dataResultTotal != null && dataResultTotal.length > 0) {
              if (totalIndex < dataResultTotal.length) {
                if (dataResultPreBillingBalance[i].client_id == dataResultTotal[totalIndex].client_id) {
                  if (dataResultTotal[totalIndex].closing_date_flg == 1) {
                    dataResultTotal[totalIndex].pre_billing_no_tax_balance = dataResultPreBillingBalance[i].pre_billing_no_tax_balance;
                    dataResultTotal[totalIndex].pre_billing_tax_balance = dataResultPreBillingBalance[i].pre_billing_tax_balance;
                    dataResultTotal[totalIndex].two_pre_billing_money_no_tax = dataResultPreBillingBalance[i].two_pre_billing_money_no_tax;
                    dataResultTotal[totalIndex].two_pre_billing_money_tax = dataResultPreBillingBalance[i].two_pre_billing_money_tax;
                    dataResultTotal[totalIndex].three_pre_billing_money_no_tax = dataResultPreBillingBalance[i].three_pre_billing_money_no_tax;
                    dataResultTotal[totalIndex].three_pre_billing_money_tax = dataResultPreBillingBalance[i].three_pre_billing_money_tax;
                  }
                } else if (dataResultPreBillingBalance[i].client_id < dataResultTotal[totalIndex].client_id) {
                  dataResultTotal.splice(totalIndex, 0, dataResultPreBillingBalance[i]);
                } else {
                  totalIndex++;
                  i--;
                }
              } else {
                dataResultTotal.push(dataResultPreBillingBalance[i]);
              }
            } else {
              dataResultTotal.push(dataResultPreBillingBalance[i]);
            }
          }
        }
        if (dataResultSeparate != null && dataResultSeparate.length > 0) {
          if (this.invoiceListCalcClass == Const.InvoiceListCalcClass.all) {
            // 前月分の情報があった場合
            let totalIndex = 0;
            for (let i = 0; i < dataResultSeparate.length; i++) {
              if (dataResultTotal != null && dataResultTotal.length > 0) {
                if (totalIndex < dataResultTotal.length) {
                  if (dataResultSeparate[i].client_id == dataResultTotal[totalIndex].client_id) {
                    if (dataResultTotal[totalIndex].closing_date_flg == 1) {
                      dataResultTotal[totalIndex].product_sales_unit_amount = Number(dataResultTotal[totalIndex].product_sales_unit_amount) + Number(dataResultSeparate[i].product_sales_unit_amount);
                      dataResultTotal[totalIndex].product_tax = Number(dataResultTotal[totalIndex].product_tax) + Number(dataResultSeparate[i].product_tax);
                    }
                    dataResultTotal[totalIndex].invoice_issue_way = Const.InvoiceIssueWay.partIssue;
                  } else if (dataResultSeparate[i].client_id < dataResultTotal[totalIndex].client_id) {
                    dataResultTotal.splice(totalIndex, 0, dataResultSeparate[i]);
                  } else {
                    totalIndex++;
                    i--;
                  }
                } else {
                  dataResultTotal.push(dataResultSeparate[i]);
                }
              } else {
                dataResultTotal.push(dataResultSeparate[i]);
              }
            }
          } else {
            for (let i = 0; i < dataResultSeparate.length; i++) {
              let data = dataResultTotal.find(el => el.client_id == dataResultSeparate[i].client_id);
              if (data != undefined) {
                // 一部先行であることを設定
                data.invoice_issue_way = Const.InvoiceIssueWay.partIssue;
              }
            }
          }
        }
      }
    },
  },
}
</script>
<style scoped>
</style>