<template>
  <div>
    <!-- ●●●上部メニュー●●● -->
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 py-2 min-vh-85">
      <b-row class="d-flex justify-content-center mt-2 mb-2">
        <b-col>
          <b-media class="media">
            <b-media-body class="pb-2">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 発注入力照会</strong></h5>
                <b-button-toolbar>
                  <b-button variant="success" pill v-b-tooltip.hover.noninteractive.bottom="'発注入力に遷移する'" @click="clickInputBtn" class="m-0">
                    <span class="oi oi-plus"></span> 新規登録(F10)
                    <button v-shortkey="['f10']" @shortkey="clickInputBtn" class="d-none"></button>
                  </b-button>&nbsp;&nbsp;
                  <b-button pill v-b-tooltip.hover.noninteractive.bottom="'発注一覧に戻る'" @click="cancelOrderInput" class="btn-cancel m-0">
                    <span class="oi oi-circle-x"></span> キャンセル
                  </b-button>
                </b-button-toolbar>
              </div>
            </b-media-body>
          </b-media>
          <div class="main-card card">
            <b-card-header v-if="getMessageFlg==true">
              <b-alert show variant="success" class="mt-2" v-if="alertSuccess.length">
                <ul v-for="(error,index) in alertSuccess" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="warning" class="mt-2" v-if="alertWarning.length">
                <ul v-for="(error,index) in alertWarning" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
              <b-alert show variant="danger" class="mt-2" v-if="alertDanger.length">
                <ul v-for="(error,index) in alertDanger" :key="index" style="list-style: none;">
                  <li>{{error}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body>
              <validation-observer ref="observer">
                <b-form id="inputForm" class="form-horizontal">
                  <b-row>
                    <!-- ステータス -->
                    <b-col lg="12">
                      <label><strong class='text-body'>ステータス</strong></label>
                        <b-btn-toolbar class="d-inline-flex">
                          <!-- 発注残有無 -->
                          <div v-if="isDeleted=='0'" v-b-tooltip.hover.noninteractive title="対応が終わっていない製品の有無を表示。">
                            <b-button style="pointer-events: none;" pill :variant="statusFlg.zanFlg == true ? 'danger' : 'info'" size="sm" class="mr-2">
                              <span class="oi oi-chat"></span> {{ statusFlg.zanFlg == true ? '発注残あり' : '発注残なし' }}
                            </b-button>
                          </div>
                          <!-- 発注書発行有無 -->
                          <div v-if="isDeleted=='0'" v-b-tooltip.hover.noninteractive title="発注書発行の状況を表示します。">
                            <b-button style="pointer-events: none;" pill :variant="statusFlg.hakkoFlg == true ? 'info' : 'danger'" size="sm" class="mr-2">
                              <span class="oi oi-chat"></span> {{ statusFlg.hakkoFlg == true ? '発注書発行済み' : '発注書発行未' }}
                            </b-button>
                          </div>
                          <!-- 入荷入力有無 -->
                          <div v-if="isDeleted=='0'&&selectOrderClass!=htmlConst.OrderClass.direct" v-b-tooltip.hover.noninteractive title="入荷入力済みの製品の有無を表示します。入荷入力済みの製品がある場合、一部データの変更と削除は不可となります。">
                            <b-button style="pointer-events: none;" pill :variant="statusFlg.nyukaFlg == true ? 'info' : 'danger'" size="sm" class="mr-2">
                              <span class="oi oi-chat"></span> {{ statusFlg.nyukaFlg == true ? '入荷入力製品あり' : '入荷入力製品なし' }}
                            </b-button>
                          </div>
                          <!-- 削除 -->
                          <div v-if="isDeleted=='1'" v-b-tooltip.hover.noninteractive title="削除された発注データです。">
                            <b-button style="pointer-events: none;" pill variant="danger" size="sm" class="mr-2">
                              <span class="oi oi-chat"></span> {{ '削除' }}
                            </b-button>
                          </div>
                        </b-btn-toolbar>
                    </b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 発注番号 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group pl-2 pr-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="orderId">
                            <b-input-group-text  class="px-1">
                              <strong  class='text-body'>発注番号 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="orderId" type="text" v-model="orderId" readonly />
                      </b-input-group>
                    </div>
                    <!-- 注文番号 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="orderNumber">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>注文番号 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="orderNumber" type="text" v-model="orderNumber" readonly />
                      </b-input-group>
                    </div>
                    <!-- 発注種別 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="selectOrderClass">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>発注種別 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="tel" id="selectOrderClass" name="selectOrderClass" v-model="selectOrderClassText" readonly></b-form-input>
                      </b-input-group>
                    </div>
                    <!-- 営業所コードプルダウン -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="selectSalesOffice">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>営業所 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input id="selectSalesOffice" type="text" v-model="selectSalesOfficeText" readonly />
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 取引先コード -->
                    <div class="col-sm-12 col-md-4 col-lg-4 col-xl-3 form-group pl-2 pr-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="suppliersCode">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>取引先コード </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="tel" id="suppliersCode" name="suppliersCode" v-model="suppliers.code" readonly></b-form-input>
                        <b-button class="mr-1" size="sm" pill variant="success" title="取引先コードに入力された取引先の情報を照会します。（取引先コードが空欄以外の場合、利用可能です。）" :disabled="suppliers.code == ''" @click="clientInfoModal(suppliers.class, suppliers.code)">
                          <span class="oi oi-eye"></span> 情報
                        </b-button>
                      </b-input-group>
                    </div>
                    <!-- 発注日付 -->
                    <div class="col-sm-12 col-md-5 col-lg-5 col-xl-4 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="dateOrderDate">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>発注日付 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-datepicker
                          id="dateOrderDate"
                          name="dateOrderDate"
                          class="mb-2"
                          v-model="dateOrderDate"
                          readonly
                        ></b-form-datepicker>
                      </b-input-group>
                    </div>
                    <!-- 担当者 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="staff">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>担当者 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="staff" name="staff" v-model="staff.text" readonly />
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 取引先名 -->
                    <div class="col-sm-12 col-md-9 col-lg-9 col-xl-6 form-group pl-2 pr-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="suppliersName">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>取引先名 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="suppliersName" readonly v-model="suppliers.name"></b-form-input>
                      </b-input-group>
                    </div>
                    <!-- 郵便番号 -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="addressCode">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>郵便番号 </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="addressCode" readonly v-model="suppliers.zipCode"></b-form-input>
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 取引先住所１ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group pl-2 pr-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="suppliersAddress1">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>取引先住所１ </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="suppliersAddress1" readonly v-model="suppliers.address1"></b-form-input>
                      </b-input-group>
                    </div>
                    <!-- 取引先住所２ -->
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="suppliersAddress2">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>取引先住所２ </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="suppliersAddress2" readonly v-model="suppliers.address2"></b-form-input>
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row class="mt-2">
                    <!-- 取引先部署１ -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group pl-2 pr-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="suppliersDepartment1">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>部署１ </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="suppliersDepartment1" readonly v-model="suppliers.department1"></b-form-input>
                      </b-input-group>
                    </div>
                    <!-- 取引先部署２ -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="suppliersDepartment2">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>部署２ </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="suppliersDepartment2" readonly v-model="suppliers.department2"></b-form-input>
                      </b-input-group>
                    </div>
                    <!-- 取引先TEL -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-1">
                      <b-input-group>
                        <template #prepend>
                          <label for="suppliersTel">
                            <b-input-group-text  class="px-1">
                              <strong class='text-body'>TEL </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="suppliersTel" readonly v-model="suppliers.tel"></b-form-input>
                      </b-input-group>
                    </div>
                    <!-- FAX -->
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 form-group px-2">
                      <b-input-group>
                        <template #prepend>
                          <label for="suppliersFax">
                            <b-input-group-text  class="px-2">
                              <strong class='text-body'>FAX </strong>
                            </b-input-group-text>
                          </label>
                        </template>
                        <b-form-input type="text" id="suppliersFax" readonly v-model="suppliers.fax"></b-form-input>
                      </b-input-group>
                    </div>
                  </b-row>
                  <b-row>
                    <!-- 製品 -->
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-table
                            :small="true"
                            :items="productList"
                            :fields="fields"
                          >
                            <template #table-caption>
                              <span class="mt-2 mr-2">製品テーブル</span> 
                              <!-- 在庫照会ボタン -->
                              <b-button pill variant="success" size="sm" class="mr-2" title="在庫照会モーダルを表示します。" @click="openStockModal()">
                                <span class="oi oi-eye"></span> 在庫照会
                              </b-button>
                            </template>
                            <template #cell(ProductCode)="data">
                              <b-input-group style="width: 80px;">
                                <b-form-input size="sm" type="text" name="productId" v-model="data.item.ProductCode" class="px-1" readonly/>
                              </b-input-group>
                            </template>
                            <template #cell(ProductName)="data">
                              <b-input-group style="width: 220px;">
                                <span style="font-size: 20px;" v-b-tooltip.hover.noninteractive :title="htmlConst.LightTaxRateTooltip">{{ data.item.ProductTaxRateClass == htmlConst.ProductTaxRateClass.lightTax ? controlMasterData.lightTaxMark : '' }}</span>
                                <b-form-input size="sm" type="text" name="productName" v-model="data.item.ProductName" class="px-1" readonly v-b-tooltip.hover.noninteractive.right="data.item.ProductName" />
                              </b-input-group>
                            </template>
                            <template #cell(Note)="data">
                              <b-input-group style="width: 150px;">
                                <b-form-input size="sm" type="text" name="productNote" v-model="data.item.Note" class="px-1" readonly />
                              </b-input-group>
                            </template>
                            <template #cell(Quantity)="data">
                              <b-input-group style="width: 60px;">
                                <b-form-input size="sm" type="text" name="productQuantity" v-model="data.item.Quantity" class="px-1" readonly />
                              </b-input-group>
                            </template>
                            <template #cell(Unit)="data">
                              <b-input-group style="width: 40px;">
                                <b-form-input size="sm" type="text" name="productUnit" v-model="data.item.Unit" class="px-1" readonly />
                              </b-input-group>
                            </template>
                            <template #cell(PurchasePrime)="data">
                              <b-input-group style="width: 85px;">
                                <b-form-input size="sm" type="text" name="productPurchasePrime" v-model="data.item.PurchasePrime" class="px-1" readonly />
                              </b-input-group>
                            </template>
                            <template #cell(PurchasePrimeMaster)="data">
                              {{ data.item.PurchasePrimeMaster.toLocaleString() }}
                            </template>
                            <template #cell(TotalPurchasePrime)="data">
                              {{ data.item.TotalPurchasePrime.toLocaleString() }}
                            </template>
                            <template #cell(StockScheduled)="data">
                              <b-input-group style="width: 200px;">
                                <b-input-group class="input-daterange">
                                  <b-form-datepicker
                                    name="productStockScheduled"
                                    v-model="data.item.StockScheduled"
                                    calendar-width="50%"
                                    :disabled="true"
                                    size="sm"
                                  ></b-form-datepicker>
                                </b-input-group>
                              </b-input-group>
                            </template>
                            <template #cell(StockScheduledStaffText)="data">
                              <b-input-group style="width: 130px;">
                                <b-form-input size="sm" type="text" v-model="data.item.StockScheduledStaffText" class="px-1" readonly />
                              </b-input-group>
                            </template>
                            <template #cell(StockScheduledConfirmClass)="data">
                              <b-form-checkbox
                                v-model="data.item.StockScheduledConfirmClass"
                                disabled
                                value="1"
                                unchecked-value="0"
                              ></b-form-checkbox>
                            </template>
                            <template #cell(Delete)="data">
                              <b-form-checkbox
                                v-model="data.item.IsDeleted"
                                disabled
                                value="1"
                                unchecked-value="0"
                              ></b-form-checkbox>
                            </template>
                            <template #cell(StockQuantity)="data">
                              {{ data.item.StockQuantity.toLocaleString() }}
                            </template>
                            <template #cell(StockConfirmQuantity)="data">
                              {{ data.item.StockConfirmQuantity.toLocaleString() }}
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form>
                            <b-form-group :label="textTotalMoneyText" label-align="right"></b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-form>
                <b-row class="justify-content-md-center pb-0">
                  <b-col lg="12">
                    <b-btn-toolbar>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の発注の編集を行います。" @click="clickOrderEdit" :disabled="isDeleted==1">
                        <span class="oi oi-pencil"></span> 発注編集
                      </b-button>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の発注の削除を行います。" @click="clearAlert(); clickOrderDelete();" :disabled="statusFlg.orderReceiveFlg==true||statusFlg.nyukaFlg==true||isDeleted==1">
                        <span class="oi oi-delete"></span> 発注削除
                      </b-button>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="照会中の発注をコピーして発注を新規登録します。" @click="clickOrderCopy">
                        <span class="oi oi-plus"></span> コピー発注
                      </b-button>
                      <b-button class="mr-2" pill size="sm" variant="success" v-b-tooltip.hover.noninteractive title="発注書発行画面を表示します。" @click="clickOrderSlipInput" v-if="selectOrderClass == htmlConst.OrderClass.input&&statusFlg.hakkoFlg == false">
                        <span class="oi oi-print"></span> 発注書発行へ
                      </b-button>
                    </b-btn-toolbar>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-card-body>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- ●●●フッター●●● -->
    <Footer />
    <!-- ●●●取引先照会モーダル●●● -->
    <CLIENTINQUIRY :clientProp="propClientInquiry"/>
    <!-- 在庫照会モーダル -->
    <STOCKINQUIRY :stock-info="stockInfo" />
    <!-- ●●●確認モーダル●●● -->
    <CONFIRM @from-child="closeConfirmModal" :confirmMessage="confirmMessage" />
  </div>
</template>
<script>
import store from '@/store';
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import CLIENTINQUIRY from '@/components/modal/client-inquiry.vue';
import STOCKINQUIRY from '@/components/modal/stock-inquiry.vue';
import CONFIRM from '@/components/modal/confirm.vue';
import Const from '@/assets/js/const.js';
import { init, addOperationLogs, getControlMaster, formatDate, getListValue, executeSelectSql, CreateColRow, CreateUpdateSql, executeSqlList, isSystemEditable, isOtherSessionUpdated, isAfterMonthlyUpdate, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';

const MODULE_NAME = 'order-input-inquiry';

export default {
  name: 'ORDER-INPUT-INQUIRY',
  /** コンポーネント */
  components: {
    Header,
    Footer,
    CLIENTINQUIRY,
    STOCKINQUIRY,
    CONFIRM,
  },
  /** データ */
  data() {
    return {
      // ヘッダ
      menu_type: 'user',
      title: '発注入力照会',
      loginId: '',
      listUpdatedSelect: [],
      // 削除フラグ
      isDeleted: 0,
      // アラート
      alertSuccess: [],
      alertWarning: [],
      alertDanger: [],
      // 営業所プルダウン
      selectSalesOffice: null,
      selectSalesOfficeText: '',
      salesOffice: [],
      // 発注日付
      dateOrderDate: '',
      // 注文番号
      orderNumber: null,
      // ログイン担当者
      loginStaff: {
        id: '',
        text: '',      
      },
      // 担当者
      staff: {
        id: '',
        text: '',      
      },
      // 発注種別
      selectOrderClass: null,
      selectOrderClassText: '',
      // 取引先系
      suppliers: {
        class: '',
        code: '',
        name: '',
        address1: '',
        address2: '',
        zipCode: '',
        department1: '',
        department2: '',
        tel: '',
        fax: '',
        taxClass: '',
        taxCalcClass: '',
        taxFracClass: '',
        unionId: null,
      },
      // 取引先モーダル用の引数
      propClientClass: null,
      // テーブル定義
      productList:[],
      totalPurchasePrime: 0,
      // サービス区分
      serviceList: [],
      // 製品検索情報
      productSearchProp: {
        office_id: '',
        office_name: '',
        client_id: '',
        client_class: '',
        client_name: '',
      },
      // 取引先照会に渡すパラメータ
      propClientInquiry: {
        clientClass: null,
        clientId: null,
      },
      // 在庫照会情報
      stockInfo: {
        office_id: '',
        product_id: [],
      },
      // コントロールマスタ
      controlMasterData: {
        processMonthYear: 0,
        lightTaxMark: '',
      },
      // 定数（htmlで使用）
      htmlConst: {
        // 発注種別
        OrderClass: {
          // 受注発注
          orderReceive: Const.OrderClass.orderReceive,
          // 在庫切れ
          noStock: Const.OrderClass.noStock,
          // 直送発注
          direct: Const.OrderClass.direct,
          // 発注入力
          input: Const.OrderClass.input,
          // 特別発注
          special: Const.OrderClass.special,
        },
        // 製品消費税率区分
        ProductTaxRateClass: {
          // 通常消費税
          normalTax: Const.ProductTaxRateClass.normalTax,
          // 軽減消費税
          lightTax: Const.ProductTaxRateClass.lightTax,
          // 非課税
          noTax: Const.ProductTaxRateClass.noTax,
        },
        // 軽減税率吹き出し
        LightTaxRateTooltip: Const.LightTaxRateTooltip,
      },
      // 確認ダイアログ用
      confirmMessage: [],
      // ステータスフラグ
      statusFlg: {
        zanFlg: false,
        hakkoFlg: false,
        nyukaFlg: false,
        orderReceiveFlg: false,
      },
      // テーブル構成
      fields: [],
      // 紐づいている受注番号
      orderReceiveId: 0,
      // パラメータ
      orderId: 0,
      parentKbn: 0,
    }
  },
  computed: {
    /* メッセージがあるかどうかの返却 */
    getMessageFlg: function() {
      if (this.alertSuccess.length > 0 ||
      this.alertWarning.length > 0 ||
      this.alertDanger.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    /* 合計値のテキスト */
    textTotalMoneyText: function() {
      let retTotalMoneyText = '合計金額：' + this.totalPurchasePrime.toLocaleString() + ' 円';
      return retTotalMoneyText;
    },
  },
  /* マウント */
  async mounted() {
    init(); // common.jsにて初期化処理
    // パラメータ取得
    this.orderId = this.$route.query.orderId;
    this.parentKbn = this.$route.query.parentKbn;
    await this.fetchData();
    this.$store.commit('setLoading', false);
  },
  methods: {
    async fetchData() {
      const functionName = 'fetchData';
      this.$store.commit('setLoading', true);
      try {
        // 更新時の排他制御用の変数作成
        await this.createUpdatedSelect();
        // 製品テーブル初期表示データ用意
        this.addTableRow();
        // ログインユーザーの情報(LoginID)から担当者マスタを検索し、担当者データを取得
        let user = store.getters.user;
        this.loginId = user.username;
        //console.log('ログイン情報');
        //console.log(this.loginId);
        // 各種データ取得（非同期でまとめて取得した方が早いため）
        let staffListData = null;
        let officeListData = null;
        let serviceListData = null;
        let controlData = null;
        let where_clause = 'AND login_id = ' + '\''+ this.loginId + '\'';
        [staffListData, officeListData, serviceListData, controlData] = await Promise.all([
          selectOneTable('m_staffs', where_clause),
          selectOneTable('m_offices'),
          selectOneTable('m_service_classes'),
          getControlMaster(),
        ]);
        //console.log(staffListData);
        //console.log(officeListData);
        for (let i = 0; i < officeListData.length; i++) {
          let office = {
            id: officeListData[i].office_id,
            text: officeListData[i].office_id + '：' + officeListData[i].office_name_kanji,
            name: officeListData[i].office_name_kanji
          };
          this.salesOffice.push(office);
        }
        // ログイン担当者
        this.loginStaff.id = staffListData[0].staff_id;
        this.loginStaff.text = staffListData[0].staff_id + '：' + staffListData[0].staff_name_kanji;
        // サービス区分を取得
        this.serviceList.push({id: '', text: ''});
        //console.log(serviceListData);
        for (let i = 0; i < serviceListData.length; i++) {
          let serviceData = {
            id: serviceListData[i].service_class,
            text: serviceListData[i].service_class + '：' + serviceListData[i].service_class_name
          };
          this.serviceList.push(serviceData);
        }
        // コントロールマスタ
        this.controlMasterData.processMonthYear = controlData.process_month_year;
        this.controlMasterData.lightTaxMark = controlData.light_tax_mark;
        // 初期データ設定
        await this.setData();
        // パラメータによってメッセージを表示
        if (this.parentKbn == 1) {
          this.alertSuccess.push(DISP_MESSAGES.SUCCESS['1001']);
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3005']);
      }
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* データ設定 */
    async setData() {
      // 検索条件作成
      let selectSql = '';
      selectSql = this.makeSelectSql();
      //console.log(selectSql);
      //console.log('条件取得');
      let dataResult = await executeSelectSql(selectSql);
      //console.log(dataResult);
      if (dataResult != null) {
        await this.setResult(dataResult);
        // 他の受注データと紐づいている場合、編集不可
        if (this.statusFlg.orderReceiveFlg == true) {
          this.alertWarning.push(DISP_MESSAGES.WARNING['2023'].replace('%arg1%', this.orderReceiveId));
        }
      } else {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2003']);
      }
    },
    /* 検索SQL作成 */
    makeSelectSql: function() {
      let selectSql = '';
      /* SELECT句 */
      selectSql += 'SELECT ';
      selectSql += ' orders.office_id';
      selectSql += ',orders.order_id';
      selectSql += ',orders.order_date';
      selectSql += ',orders.order_class';
      selectSql += ',orders.client_class';
      selectSql += ',orders.client_id';
      selectSql += ',clients.client_name_kanji';
      selectSql += ',clients.zip_code';
      selectSql += ',orders.client_tax_class AS sales_tax_class';
      selectSql += ',orders.tax_calculation_class';
      selectSql += ',orders.tax_fractionation_class';
      selectSql += ',clients.address_1';
      selectSql += ',clients.address_2';
      selectSql += ',clients.department_1';
      selectSql += ',clients.department_2';
      selectSql += ',clients.phone_number';
      selectSql += ',clients.fax_number';
      selectSql += ',orders.staff_id';
      selectSql += ',staffs.staff_name_kanji';
      selectSql += ',orders.union_id';
      selectSql += ',orders.product_id';
      selectSql += ',orders.product_class_id';
      selectSql += ',orders.product_name AS product_name_kanji';
      selectSql += ',orders.note';
      selectSql += ',orders.service_class';
      selectSql += ',orders.order_quantity';
      selectSql += ',orders.unit';
      selectSql += ',orders.order_unit_amount';
      selectSql += ',products_details.purchase_price';
      selectSql += ',orders.stock_scheduled';
      selectSql += ',orders.stock_scheduled_confirm_class';
      selectSql += ',orders.stock_scheduled_staff_id';
      selectSql += ',stock_scheduled_staffs.staff_name_kanji AS stock_scheduled_staff_name';
      selectSql += ',orders.product_manual_input_class AS product_sundries_class';
      selectSql += ',orders.inventory_control_class';
      selectSql += ',orders.case_conversion_class';
      selectSql += ',orders.is_deleted';
      selectSql += ',orders.order_number';
      selectSql += ',CASE WHEN orders.order_issue_class > 0 THEN 1 ELSE 0 END AS order_issue_class';
      selectSql += ',CASE WHEN stocks.order_id IS NULL THEN 0 ELSE 1 END AS stocks_input_class';
      selectSql += ',SUM(IfNull(stocks.stock_quantity,0)) AS stocks_input_quantity';
      selectSql += ',SUM(CASE WHEN stocks.stock_confirm_class = 1 THEN stocks.stock_quantity ELSE 0 END) AS stocks_confirm_quantity';
      selectSql += ',orders.stock_quantity';
      selectSql += ',orders.order_receive_id';
      selectSql += ',products.product_tax_rate_class_purchase';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      selectSql += 'INNER JOIN m_clients AS clients ';
      selectSql += 'ON orders.client_class = clients.client_class ';
      selectSql += 'AND orders.client_id = clients.client_id ';
      selectSql += 'INNER JOIN m_products AS products ';
      selectSql += 'ON orders.product_id = products.product_id ';
      selectSql += 'INNER JOIN m_products_details AS products_details ';
      selectSql += 'ON orders.product_id = products_details.product_id ';
      selectSql += 'AND orders.office_id = products_details.office_id ';
      selectSql += 'LEFT JOIN m_staffs AS staffs ';
      selectSql += 'ON orders.staff_id = staffs.staff_id ';
      selectSql += 'LEFT JOIN m_staffs AS stock_scheduled_staffs ';
      selectSql += 'ON orders.stock_scheduled_staff_id = stock_scheduled_staffs.staff_id ';
      selectSql += 'LEFT JOIN t_stocks AS stocks ';
      selectSql += 'ON orders.order_id = stocks.order_id ';
      selectSql += 'AND orders.order_row = stocks.order_row ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      // 引数の発注番号で絞り込む
      selectSql += ' orders.order_id = ' + this.orderId + ' ';
      /* GROUP BY句 */
      selectSql += 'GROUP BY orders.order_row ';
      /* ORDER BY句 */
      selectSql += 'ORDER BY orders.order_row ';

      return selectSql;
    },
    /* 取得結果セット */
    async setResult(result) {
      if (result.length > 0) {
        // 営業所プルダウン
        for (let i = 0; i < this.salesOffice.length; i++) {
          if (result[0].office_id == this.salesOffice[i].id) {
            this.selectSalesOffice = result[0].office_id;
            this.selectSalesOfficeText = this.salesOffice[i].text;
          }
        }
        // 注文番号
        this.orderNumber = !result[0].order_number ? null : result[0].order_number;
        // 発注日
        this.dateOrderDate = result[0].order_date;
        // 担当者
        this.staff.id = result[0].staff_id;
        this.staff.text = result[0].staff_id + '：' + result[0].staff_name_kanji;
        // 発注種別
        this.selectOrderClass = result[0].order_class;
        this.selectOrderClassText = getListValue(Const.OrderClassList, this.selectOrderClass, 'id', 'text');
        this.fields = this.getFields();
        // 取引先
        this.suppliers.class = result[0].client_class;
        this.suppliers.code = result[0].client_id;
        this.suppliers.name = result[0].client_name_kanji;
        this.suppliers.zipCode = result[0].zip_code;
        this.suppliers.address1 = result[0].address_1;
        this.suppliers.address2 = result[0].address_2;
        this.suppliers.department1 = result[0].department_1;
        this.suppliers.department2 = result[0].department_2;
        this.suppliers.tel = result[0].phone_number;
        this.suppliers.fax = result[0].fax_number;
        this.suppliers.taxClass = result[0].client_tax_class;
        this.suppliers.taxCalcClass = result[0].tax_calculation_class;
        this.suppliers.taxFracClass = result[0].tax_fractionation_class;
        this.suppliers.unionId = result[0].union_id;
        // 受注番号
        this.orderReceiveId = result[0].order_receive_id;
        if (this.orderReceiveId != 0) {
          // 他の受注データと紐づいている場合
          this.statusFlg.orderReceiveFlg = true;
        }
        // 全件参照して製品一覧を作成
        let allDeleted = 1;
        for (let i = 0; i < result.length; i++) {
          if(i == this.productList.length){
            this.addTableRow();
          }
          this.productList[i].ProductCode = result[i].product_id;
          this.productList[i].ProductClass = result[i].product_class_id;
          this.productList[i].ProductName = result[i].product_name_kanji;
          this.productList[i].ProductTaxRateClass = result[i].product_tax_rate_class_purchase;
          this.productList[i].Note = result[i].note;
          this.productList[i].ServiceClass = result[i].service_class;
          this.productList[i].ServiceClassText = getListValue(this.serviceList, result[i].service_class, 'id', 'text');
          this.productList[i].Quantity = result[i].order_quantity;
          this.productList[i].Unit = result[i].unit;
          this.productList[i].PurchasePrime = result[i].order_unit_amount;
          this.productList[i].PurchasePrimeMaster = result[i].purchase_price;
          this.productList[i].StockScheduled = result[i].stock_scheduled;
          this.productList[i].StockScheduledConfirmClass = result[i].stock_scheduled_confirm_class;
          this.productList[i].StockScheduledStaffId = result[i].stock_scheduled_staff_id;
          this.productList[i].StockScheduledStaffText = result[i].stock_scheduled_staff_id == null ? '' : result[i].stock_scheduled_staff_id + '：' + result[i].stock_scheduled_staff_name;
          this.productList[i].IsDeleted = result[i].is_deleted;
          if (this.productList[i].IsDeleted == 0) {
            allDeleted = 0;
          }
          this.productList[i].OrderNumber = result[i].order_number;
          if (this.selectOrderClass == Const.OrderClass.direct) {
            this.productList[i].StockQuantity = result[i].stock_quantity;
            this.productList[i].StockConfirmQuantity = result[i].stock_quantity;
          } else {
            this.productList[i].StockQuantity = result[i].stocks_input_quantity;
            this.productList[i].StockConfirmQuantity = result[i].stocks_confirm_quantity;
          }
          this.productList[i].SundriesClass = result[i].product_sundries_class;
          this.productList[i].InventoryClass = result[i].inventory_control_class;
          this.productList[i].CaseConversion = result[i].case_conversion_class;
          this.productList[i].TotalPurchasePrime = this.productList[i].Quantity * this.productList[i].PurchasePrime;
          // 発注残確認
          if (this.productList[i].Quantity != this.productList[i].StockConfirmQuantity) {
            this.statusFlg.zanFlg = true;
          }
          // 発注書発行フラグ
          if (result[i].order_issue_class == 1) {
            this.statusFlg.hakkoFlg = true;
          }
          // 入荷フラグ
          if (result[i].stocks_input_class == 1) {
            this.statusFlg.nyukaFlg = true;
          }
        }
        this.calcTotal();
        // 削除フラグ
        this.isDeleted = allDeleted;
      }
    },
    // キャンセルボタン
    cancelOrderInput: function() {
      this.$router.push({ name: 'ORDER-LIST'});
    },
    // 合計金額
    calcTotal: function() {
      this.totalPurchasePrime = 0;
      for (let i = 0; i < this.productList.length; i++) {
        this.totalPurchasePrime += this.productList[i].TotalPurchasePrime;
      }
    },
    // 行追加ボタンイベント
    addTableRow: function() {
      let newProduct = {
        ProductCode: null,
        ProductClass: null,
        ProductName: '',
        ProductTaxRateClass: Const.ProductTaxRateClass.normalTax,
        Note: '',
        ServiceClass: '',
        Quantity: 0,
        Unit: '',
        PurchasePrime: 0,
        PurchasePrimeMaster: 0,
        TotalPurchasePrime: 0,
        StockScheduled: '',
        StockScheduledStaffId: null,
        StockScheduledStaffText: '',
        StockScheduledConfirmClass: 0,
        IsDeleted: 0,
        OrderNumber: 0,
        StockQuantity: 0,
        StockConfirmQuantity: 0,
        SundriesClass: null,
        InventoryClass: null,
        CaseConversion: '',
      };
      this.productList.push(newProduct);
    },
    /* コピー発注ボタン押下時 */
    async clickOrderCopy() {
      //console.log('コピー発注');
      // 発注入力画面へ遷移
      this.$router.push({ name: 'ORDER-INPUT', query: { propOrderId: this.orderId } });
    },
    /* 発注編集ボタン押下時 */
    async clickOrderEdit() {
      //console.log('発注編集');
      // 発注修正画面へ遷移
      this.$router.push({ name: 'ORDER-EDIT', query: { orderId: this.orderId } });
    },
    /* 発注削除ボタン押下時 */
    async clickOrderDelete() {
      //console.log('発注削除');
      this.confirmMessage = [];
      this.confirmMessage.push('照会中の発注データを削除します。');
      this.confirmMessage.push('よろしいですか？');
      this.$bvModal.show('confirmModal');
    },
    /* 発注削除実行 */
    async execOrderDelete() {
      const functionName = 'execOrderDelete';

      // 排他制御チェック
      if (await isOtherSessionUpdated(this.listUpdatedSelect) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2027']);
        return;
      }
      // 実行するSQL保持
      let sqlList = [];
      /* 在庫マスタ更新 */
      this.updateStocksMaster(sqlList);
      /* 発注データ更新 */
      // 削除フラグを立てる
      let updateSql = '';
      let colList = [];
      // 削除済フラグ
      colList.push(CreateColRow('is_deleted', 1, 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('updated_user', this.loginId, 'VARCHAR'));
      updateSql += CreateUpdateSql(colList, 't_orders');
      updateSql += ' WHERE ';
      updateSql += 'order_id = ' + this.orderId + ' ';
      //console.log(updateSql);
      sqlList.push({sql: updateSql, forUpdateFlg: 0});

      // 月次更新・取引先コード切替・製品コード切替などが実行中かどうかを確認します。
      try {
        const msg = await isSystemEditable();
        if (msg !== null) {
          this.alertDanger.push(msg);
          return;
        }
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, '予期しないエラーが発生しました。', error);
        this.alertDanger.push(DISP_MESSAGES.DANGER['3001']);
        return;
      }
      // 月次更新後チェック
      if (await isAfterMonthlyUpdate(this.controlMasterData.processMonthYear) == true) {
        this.alertWarning.push(DISP_MESSAGES.WARNING['2045']);
        return;
      }
      try {
        await executeSqlList(sqlList);
        // 発注一覧画面へ遷移
        this.$router.push({ name: 'ORDER-LIST', params: { parentKbn: 1 } });
      } catch(error) {
        this.alertDanger.push(DISP_MESSAGES.DANGER['3002']);
      }
    },
    /* 在庫マスタ更新SQL作成 */
    updateStocksMaster: function(sqlList) {
      //console.log('在庫マスタ更新SQL作成');
      let updateSql = '';
      let colList = [];
      // 入荷予定数
      colList.push(CreateColRow('stocks.quantity_stock', 'stocks.quantity_stock - orders_QUERY.remaining_quantity', 'NUMBER'));
      // 更新日
      colList.push(CreateColRow('stocks.updated', 'CURRENT_TIMESTAMP()', 'DATETIME'));
      // 更新ユーザー
      colList.push(CreateColRow('stocks.updated_user', this.loginId, 'VARCHAR'));
      let updateQuery = '(';
      updateQuery += 'SELECT';
      updateQuery += ' orders.office_id';
      updateQuery += ',orders.product_id';
      updateQuery += ',SUM(orders.remaining_quantity) AS remaining_quantity';
      updateQuery += ' FROM ';
      updateQuery += 't_orders AS orders ';
      updateQuery += ' WHERE ';
      updateQuery += 'orders.order_id = ' + this.orderId + ' ';
      updateQuery += 'AND orders.order_issue_class > 0 ';
      updateQuery += 'AND orders.is_deleted = 0 ';
      updateQuery += 'AND orders.inventory_control_class = ' + Const.InventoryControlClassDef.inventory + ' ';
      updateQuery += 'GROUP BY orders.office_id,orders.product_id ';
      updateQuery += ') AS orders_QUERY';
      updateSql += CreateUpdateSql(colList, 'm_stocks AS stocks', updateQuery);
      updateSql += ' WHERE ';
      updateSql += 'stocks.month_year = ' + this.controlMasterData.processMonthYear + ' ';
      updateSql += 'AND stocks.office_id = orders_QUERY.office_id ';
      updateSql += 'AND stocks.product_id = orders_QUERY.product_id ';
      //console.log(updateSql);
      sqlList.push({sql: updateSql, forUpdateFlg: 0});
    },
    // 確認モーダルを閉じた時
    async closeConfirmModal(okFlg) {
      const functionName = 'closeConfirmModal';
      //console.log(okFlg);
      try {
        // モーダルから渡された値の有無チェック
        if(typeof okFlg != 'undefined') {
          this.$store.commit('setLoading', true);
          // 発注削除
          await this.execOrderDelete();
        }
      } catch(error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {}, error);
        console.log(error);
        this.alertDanger.push(DISP_MESSAGES.WARNING['3005']);
      }
      // メッセージが１件でもある場合は一番上へスクロール
      if (this.getMessageFlg == true) {
        scrollTo(0,0);
      }
      this.$store.commit('setLoading', false);
    },
    /* テーブル構成取得 */
    getFields: function() {
      if (this.selectOrderClass != Const.OrderClass.direct) {
        // 直送以外
        return [
          {
            key: 'ProductCode',
            label: '製品コード',
          },
          {
            key: 'ProductName',
            label: '製品名',
          },
          {
            key: 'Note',
            label: '備考',
          },
          {
            key: 'ServiceClassText',
            label: 'サービス区分',
          },
          {
            key: 'Quantity',
            label: '数量',
          },
          {
            key: 'Unit',
            label: '単位',
          },
          {
            key: 'PurchasePrime',
            label: '仕入単価',
          },
          {
            key: 'PurchasePrimeMaster',
            label: '仕入単価（マスタ）',
            tdClass: 'text-right',
          },
          {
            key: 'TotalPurchasePrime',
            label: '金額',
            tdClass: 'text-right',
          },
          {
            key: 'StockScheduled',
            label: '入荷予定日',
          },
          {
            key: 'StockScheduledStaffText',
            label: '入荷予定処理担当者',
          },
          {
            key: 'StockScheduledConfirmClass',
            label: '入荷予定日確定',
          },
          {
            key: 'Delete',
            label: '削除',
          },
          {
            key: 'StockQuantity',
            label: '入荷数',
            tdClass: 'text-right',
          },
          {
            key: 'StockConfirmQuantity',
            label: '入荷確定数',
            tdClass: 'text-right',
          },
        ];
      } else {
        // 直送
        return [
          {
            key: 'ProductCode',
            label: '製品コード',
          },
          {
            key: 'ProductName',
            label: '製品名',
          },
          {
            key: 'Note',
            label: '備考',
          },
          {
            key: 'ServiceClassText',
            label: 'サービス区分',
          },
          {
            key: 'Quantity',
            label: '数量',
          },
          {
            key: 'Unit',
            label: '単位',
          },
          {
            key: 'PurchasePrime',
            label: '仕入単価',
          },
          {
            key: 'PurchasePrimeMaster',
            label: '仕入単価（マスタ）',
            tdClass: 'text-right',
          },
          {
            key: 'TotalPurchasePrime',
            label: '金額',
            tdClass: 'text-right',
          },
          {
            key: 'StockScheduled',
            label: '入荷予定日',
          },
          {
            key: 'StockScheduledStaffText',
            label: '入荷予定処理担当者',
          },
          {
            key: 'StockScheduledConfirmClass',
            label: '入荷予定日確定',
          },
          {
            key: 'Delete',
            label: '削除',
          },
          {
            key: 'StockQuantity',
            label: '入荷数',
            tdClass: 'text-right',
          },
        ];
      }
    },
    /* 取引先照会 */
    clientInfoModal: function(clientClass, clientId) {
      //console.log('取引先照会');
      this.propClientInquiry.clientClass = clientClass;
      this.propClientInquiry.clientId = clientId;
      this.$bvModal.show('clientInquiryModal');
    },
    /* 画面のアラートをクリア */
    clearAlert: function() {
      this.alertSuccess = [];
      this.alertWarning = [];
      this.alertDanger = [];
    },
    /* 更新日取得用SELECT文作成 */
    async createUpdatedSelect() {
      let selectSql = '';
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(orders.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_orders AS orders ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' orders.order_id = ' + this.orderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});
      /* SELECT句 */
      selectSql = 'SELECT';
      selectSql += ' MAX(stocks.updated) AS updated';
      /* FROM句 */
      selectSql += ' FROM ';
      selectSql += 't_stocks AS stocks ';
      /* WHERE句 */
      selectSql += ' WHERE ';
      selectSql += ' stocks.order_id = ' + this.orderId + ' ';
      this.listUpdatedSelect.push({select: selectSql});

      let dataResult = null;
      for (let i = 0; i < this.listUpdatedSelect.length; i++) {
        dataResult = await executeSelectSql(this.listUpdatedSelect[i].select);
        if (dataResult != null && dataResult.length > 0) {
          this.listUpdatedSelect[i].initUpdated = formatDate(dataResult[0].updated, 'YYYY-MM-DD HH:mm:ss');
        } else {
          this.listUpdatedSelect[i].initUpdated = '0000-01-01 00:00:00';
        }
      }
    },
    /* 発注書発行（入力分）ボタン押下時 */
    async clickOrderSlipInput() {
      let route = this.$router.resolve({ name: 'ORDER-SLIP-PRINT', query: {menuId: 2} });
      window.open(route.href, '_blank');
    },
    // 在庫照会モーダルを開く
    openStockModal: function() {
      //console.log('在庫照会モーダル');
      //console.log(this.$bvModal);
      this.stockInfo.office_id = this.selectSalesOffice.toString();
      this.stockInfo.product_id = [];
      for (let i = 0; i < this.productList.length; i++) {
        if (this.productList[i].ProductCode != null) {
          this.stockInfo.product_id.push(this.productList[i].ProductCode.toString());
        }
      }
      this.$bvModal.show('stockModal');
    },
    // 新規登録ボタン押下時
    async clickInputBtn() {
      this.$router.push({ name: 'ORDER-INPUT', query: { propOrderId: 0 } });
    },
  }
}
</script>
<style scoped>
</style>