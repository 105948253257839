<template>
  <div>
    <Header :type="menu_type" :title="title" />
    <b-container fluid class="px-4 pt-4 min-vh-85">
      <b-row>
        <b-col>
          <b-media>
            <b-media-body class="pb-3">
              <div class="d-flex justify-content-between">
                <h5 class="text-secondary m-0"><span class="oi oi-brush"></span><strong> 出力範囲指定</strong></h5>
              </div>
            </b-media-body>
          </b-media>
          <b-card>
            <b-card-header v-if="isAlertMessages">
              <b-alert show variant="danger" class="mt-2" v-if="alertDangerMessages.length > 0">
                <ul v-for="(message,index) in alertDangerMessages" :key="index" style="list-style: none;">
                  <li>{{message}}</li>
                </ul>
              </b-alert>
            </b-card-header>
            <b-card-body class="py-0">
              <b-row>
                <b-col>
                  <b-table :fields="stocksAmountTableFields" :items="items" show-empty small>
                    <!-- 営業所 -->
                    <template #cell(office)="data">
                      {{`${data.item.office_id}：${data.item.office_name_kanji}`}}
                    </template>
                    <!-- 棚卸No. -->
                    <template #cell(inventory_no)="data">
                      {{data.item.inventory_no}}
                    </template>
                    <!-- 棚卸日 -->
                    <template #cell(inventory_date)="data">
                      {{data.item.inventory_date}}
                    </template>
                    <!-- 在庫金額合計 -->
                    <template #cell(stocks_amount)="data">
                      {{Number(data.item.stocks_amount).toLocaleString()}}
                    </template>
                    <!-- 帳簿在庫金額合計 -->
                    <template #cell(book_stocks_amount)="data">
                      {{Number(data.item.book_stocks_amount).toLocaleString()}}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row class="pt-3">
                <b-col md="6">
                  <b-form-group description="対象の営業所を選択してください。" label="営業所" label-cols-sm="3" label-cols-xl="2" label-size="sm" size="sm">
                    <b-form-select v-model="searchCondition.officeId" :options="officeList" size="sm"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="justify-content-sm-center ">
                <b-col class="text-center" sm="4" lg="3" xl="2">
                  <b-button block pill size="sm" variant="success" @click="onPrintButtonClick">
                    <span class="oi oi-document"></span> PDF出力
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/navigation/header.vue';
import Footer from '@/components/navigation/footer.vue';
import { addOperationLogs, executeSelectSql, init, selectOneTable } from '@/assets/js/common.js';
import { DISP_MESSAGES } from '@/assets/js/messages';
import DataTblDef from '@/assets/js/dataTableDef.js';
import store from '../store';

// モジュール名
const MODULE_NAME = 'inventory-stock-variance';

export default {
  name: 'INVENTORY-STOCK-VARIANCE',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      // ヘッダメニュー種別
      menu_type: 'user',
      // ヘッダタイトル
      title: '棚卸差異表',
      // 営業所
      officeList: [],
      // 検索条件
      searchCondition: {
        // 営業所コード
        officeId: '',
      },
      stocksAmountTableFields: DataTblDef.stocks_amount_table_fields,
      items: [],
      // Dangerアラートメッセージ
      alertDangerMessages: [],
    };
  },
  computed: {
    /**
     * 表示するアラートメッセージかあるかどうかを確認します。
     * @returns {Boolean} メッセージがある場合はtrue、無い場合はfalse
     */
    isAlertMessages() {
      return this.alertDangerMessages.length > 0;
    }
  },
  /**
   * mountedライフサイクルフック
   */
  async mounted() {
    init(); // common.jsにて初期化処理
    if (await this.setOfficeList() && await this.setOffficeListDefault()) {
      await this.fetchData();
    }
    this.$store.commit('setLoading', false);
  },
  methods: {
    /**
     * 営業所リストを更新します。
     * @returns {Boolean} 正常に終了した場合はtrue、エラーが発生した場合はfalse
     */
    async setOfficeList() {
      const functionName = 'setOfficeList';

      const whereClause = 'ORDER BY office_id';
      let listMOfficesResult = null;
      try {
        listMOfficesResult = await selectOneTable('m_offices', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_offices',
          whereClause: whereClause
        }, error);
        this.alertDangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      for (const mOffice of listMOfficesResult) {
        this.officeList.push({
          value: mOffice.office_id,
          text: `${mOffice.office_id}：${mOffice.office_name_kanji}`
        });
      }
      return true;
    },
    /**
     * 営業所リストの初期値を選択します。
     * @returns {Boolean} 正常に終了した場合はtrue、エラーが発生した場合はfalse
     */
    async setOffficeListDefault() {
      const functionName = 'setOffficeListDefault';

      const whereClause = `AND login_id = '${store.getters.user.username}'`;
      let listMStaffsResult = null;
      try {
        listMStaffsResult = await selectOneTable('m_staffs', whereClause);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_staffs',
          whereClause: whereClause
        }, error);
        this.alertDangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      if (listMStaffsResult == null || listMStaffsResult.length == 0) {
        await addOperationLogs('Error', MODULE_NAME, functionName, {
          graphqlOperation: 'list_m_staffs',
          whereClause: whereClause,
          result: listMStaffsResult
        });
        this.dangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        return false;
      }
      this.searchCondition.officeId = listMStaffsResult[0].office_id;
      return true;
    },
    async fetchData() {
      const functionName = 'onSearchButtonClick';

      const sql = 'SELECT' +
          ' ti.office_id' +
          ',ti.inventory_no' +
          ',tih.preprocess_datetime AS inventoryDate' +
          ',mo.office_name_kanji AS office_name_kanji' +
          ',SUM(mpd.cost_price * ti.shelves_count) AS stocks_amount' +
          ',SUM(mpd.cost_price * ti.inventory_count) AS book_stocks_amount ' +
        'FROM ' +
          't_inventories ti ' +
          'INNER JOIN t_inventories_histories tih ' +
            'ON tih.inventory_no = ti.inventory_no ' +
          'INNER JOIN m_products_details mpd ' +
            'ON mpd.office_id = ti.office_id ' +
            'AND mpd.product_id = ti.product_id ' +
          'INNER JOIN m_offices mo ' +
            'ON mo.office_id = ti.office_id ' +
        'WHERE ' +
          'ti.inventory_no IN ' +
            '(' +
              'SELECT' +
                ' MAX(inventory_no) AS inventory_no ' +
              'FROM ' +
                't_inventories_histories ' +
              'GROUP BY' +
                ' office_id' +
            ') ' +
        'GROUP BY' +
          ' ti.office_id ' +
        'ORDER BY' +
          ' ti.office_id;';

      try {
        this.items = await executeSelectSql(sql);
      } catch (error) {
        await addOperationLogs('Error', MODULE_NAME, functionName, error.message, error);
        this.alertDangerMessages.push(DISP_MESSAGES.DANGER['3005']);
        scrollTo(0,0);
        return;
      }
    },
    /**
     * PDF印刷ボタンクリックイベント処理
     */
    onPrintButtonClick() {
      // 棚卸差異表印刷プレビュー画面へ遷移します。
      const route = this.$router.resolve({
        name: 'INVENTORY-STOCK-VARIANCE-PRINT',
        query: {
          officeId: this.searchCondition.officeId,
          startIndex: -1,
        }
      });
      window.open('', '_blank').location = route.href
    }
  }
}
</script>