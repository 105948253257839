import { render, staticRenderFns } from "./clients-parent-child-master-list.vue?vue&type=template&id=17c36aec&"
import script from "./clients-parent-child-master-list.vue?vue&type=script&lang=js&"
export * from "./clients-parent-child-master-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports